<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <h1 mat-dialog-title>
      <span class="material-icons icon-menu"> email </span>
      {{ 'email.draft' | translate }}
    </h1>
    <form class="form email-form" [formGroup]="form" autocomplete="off">
      <div class="form-row">
        <mat-form-field class="white-back">
          <mat-label>{{ 'EMAIL.TO' | translate }}</mat-label>
          <input
            autocomplete="off"
            type="email"
            matInput
            placeholder="{{ 'EMAIL.TO' | translate }}"
            name="to"
            formControlName="mailTo"
            [(ngModel)]="data.mailTo"
            id="{{ entityName }}-to-input"
            required
          />
          <mat-error
            id="{{ entityName }}-form-email-to-error-required"
            class="input-error"
            *ngIf="form.controls.mailTo.hasError('required')"
          >
            {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: 'EMAIL.TO' | translate } }}
          </mat-error>
          <mat-error
            id="{{ entityName }}-form-email-to-error-invalid"
            class="input-error"
            *ngIf="!form.controls.mailTo.hasError('required') && form.controls.mailTo.errors?.email"
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.TO' | translate } }}
          </mat-error>
          <mat-error
            id="{{ entityName }}-form-email-to-error-invalid-pattern"
            class="input-error"
            *ngIf="
              !form.controls.mailTo.hasError('required') &&
              !form.controls.mailTo.errors?.email &&
              form.controls.mailTo.dirty
            "
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.TO' | translate } }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="white-back">
          <mat-label>{{ 'EMAIL.CC' | translate }}</mat-label>
          <input
            type="email"
            matInput
            placeholder="{{ 'EMAIL.CC' | translate }}"
            name="cc"
            formControlName="mailCc"
            [(ngModel)]="data.mailCc"
            id="{{ entityName }}-cc-input"
          />
          <mat-error
            id="{{ entityName }}-form-email-cc-error-invalid"
            class="input-error"
            *ngIf="form.controls.mailCc.errors?.email"
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.CC' | translate } }}
          </mat-error>
          <mat-error
            id="{{ entityName }}-form-email-cc-error-invalid-pattern"
            class="input-error"
            *ngIf="!form.controls.mailCc.errors?.email && form.controls.mailFrom.dirty"
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.CC' | translate } }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="white-back">
          <mat-label>{{ 'EMAIL.FROM' | translate }}</mat-label>
          <input
            type="email"
            matInput
            placeholder="{{ 'EMAIL.FROM' | translate }}"
            name="mailFrom"
            formControlName="mailFrom"
            [(ngModel)]="data.mailFrom"
            id="{{ entityName }}-from-input"
            required
            [readonly]="readOnlyFrom"
          />
          <mat-error
            id="{{ entityName }}-form-email-from-error-required"
            class="input-error"
            *ngIf="form.controls.mailFrom.hasError('required')"
          >
            {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: 'EMAIL.FROM' | translate } }}
          </mat-error>
          <mat-error
            id="{{ entityName }}-form-email-from-error-invalid"
            class="input-error"
            *ngIf="!form.controls.mailFrom.hasError('required') && form.controls.mailFrom.hasError('email')"
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.FROM' | translate } }}
          </mat-error>
          <mat-error
            id="{{ entityName }}-form-email-from-error-invalid-pattern"
            class="input-error"
            *ngIf="
              !form.controls.mailFrom.hasError('required') &&
              !form.controls.mailFrom.hasError('email') &&
              form.controls.mailFrom.dirty
            "
          >
            {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'EMAIL.FROM' | translate } }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="white-back">
          <mat-label>{{ 'EMAIL.SUBJECT' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'EMAIL.SUBJECT' | translate }}"
            name="from"
            formControlName="subject"
            [(ngModel)]="data.subject"
            id="{{ entityName }}-subject-input"
            required
            type="text"
            [removeHtmlTags]="form.controls.subject"
          />
          <mat-error
            id="{{ entityName }}-form-email-subject-error-required"
            class="input-error"
            *ngIf="form.controls.subject.hasError('required')"
          >
            {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: 'EMAIL.SUBJECT' | translate } }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="white-back">
          <mat-label>{{ 'EMAIL.CONTENT' | translate }}</mat-label>
          <rich-editor
            matInput
            placeholder="{{ 'EMAIL.CONTENT' | translate }}"
            name="content"
            formControlName="content"
            [(ngModel)]="data.content"
            id="{{ entityName }}-content-textarea"
            required
          >
          </rich-editor>
          <mat-error
            id="{{ entityName }}-form-email-content-error-required"
            class="input-error"
            *ngIf="form.controls.content.hasError('required')"
          >
            {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: 'EMAIL.CONTENT' | translate } }}
          </mat-error>
        </mat-form-field>
      </div>
      <div [hidden]="hideAttachment">
        <div class="form-row">
          <span class="attachment-color">
            <span class="material-icons icon-menu"> attachment </span>
            {{ 'EMAIL.ATTACHMENT' | translate }}
          </span>
        </div>
        <div class="form-row">
          <app-file-upload
            [uploadedFiles]="uploadedFiles"
            name="attachment"
            formControlName="attachment"
            [multiple]="true"
          ></app-file-upload>
        </div>
      </div>
    </form>
  </div>

  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false">
      {{ 'CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      [disabled]="!form.valid"
      [mat-dialog-close]="form.value"
      style="margin-right: 0"
    >
      {{ 'SEND' | translate }}
    </button>
  </div>
</div>
