export const obligationFields: any[] = [
  {
    name: 'maximumPaymentTermDomestic',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.MAXIMUM-PAYMENT-TERM-DOMESTIC',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'pastDueDate',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.PAST-DUE-DATE',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'gracePeriodThreatOfDamageDomestic',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.GRACE-PERIOD-THREAT-OF-DAMAGE-DOMESTIC',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'exemptionLimit',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.EXEMPTION-LIMIT',
    maskValue: 'currency',
  },
  {
    name: 'freeLimitCalculation',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.FREE-LIMIT-CALCULATION-TEXT',
    methodName: 'getFreeLimitCalculation',
  },
  {
    name: 'collectionOrder',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.CUT-OFF-PERIOD-COLLECTION-ORDER',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'collectionOrderPeriodCalculation',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.CUT-OFF-PERIOD-COLLECTION-ORDER-PERIOD-CALCULATION-TEXT',
    methodName: 'getCollectionOrderPeriodCalculation',
  },
  {
    name: 'waitingPeriods',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.WAITING-PERIODS',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'furtherDeadlines',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.FURTHER-DEADLINES',
  },
  {
    name: 'collectionOffice',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.COLLECTION-OFFICE',
  },
  {
    name: 'addressStreet',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.ADDRESS-STREET',
  },
  {
    name: 'addressNumber',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.ADDRESS-NUMBER',
  },
  {
    name: 'addressZipCode',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.ADDRESS-ZIP-CODE',
  },
  {
    name: 'addressCity',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.ADDRESS-CITY',
  },
  {
    name: 'addressCountry',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.ADDRESS-COUNTRY',
  },
  {
    name: 'rateOfCompensationNamedDomestic',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.NAMED-DOMESTIC',
    maskValue: 'percent',
  },
  {
    name: 'rateOfCompensationUnnamedDomestic',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.UNNAMED-DOMESTIC',
    maskValue: 'percent',
  },
  {
    name: 'furtherRatesOfCompensation',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.FURTHER-RATES-OF-COMPENSATION',
  },
  {
    name: 'franchiseType',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.FRANCHISE-TYPE-TEXT',
    methodName: 'getFranchiseType',
  },
  {
    name: 'compensationFranchise',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.COMPENSATION-FRANCHISE',
    maskValue: 'currency',
  },
  {
    name: 'compensationPreRisk',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.COMPENSATION-RISK',
    maskValue: 'currency',
  },
  {
    name: 'claimFranchise',
    translateName: 'TCI.CREATE.FORM-OBLIGATION-TAB.CLAIM-FRANCHISE',
    maskValue: 'currency',
  },
  {
    name: 'waitingPeriodCompensationInsuranceDomestic',
    translateName:
      'TCI.CREATE.FORM-OBLIGATION-TAB.WAITING-PERIOD-COMPENSATION-INSURANCE-DOMESTIC',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
];
