import { Injectable, Output, EventEmitter, Input } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { AzureFile } from '../../models/azure-file';
import { Task } from '../../models/task';
import { TaskEventType } from '../../models/task-event-type';
import { NumberSymbol } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.taskService;
  }
  private subscription;
  private intialFilter: any;
  @Input() choice: string;

  private serviceUrl;

  @Output() taskList: EventEmitter<any> = new EventEmitter();
  @Output() allTasksEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchStarted: EventEmitter<any> = new EventEmitter();
  allTasks: any[];
  filteredTasks: any[];
  allColumnFilters = new Map();
  columnFiltered = [];
  currentSortState = null;
  newChoice = null;
  filter;
  pageSize = 20;
  sortOrder = 'ASC';
  sortField = 'id';

  private appliedFilters = new BehaviorSubject<any>([]);
  currentAppliedFilters = this.appliedFilters.asObservable();

  private choiceState = new BehaviorSubject<any>(null);
  curentChoiceState = this.choiceState.asObservable();

  public filters = new BehaviorSubject<any>(null);
  filtersState = this.filters.asObservable();

  private pageIndex = new BehaviorSubject<any>(null);
  currentPageIndex = this.pageIndex.asObservable();

  private editableTask = new BehaviorSubject<boolean>(false);
  currentEditableTask = this.editableTask.asObservable();
  private updateTask = new BehaviorSubject<boolean>(null);
  currentUpdateTask = this.updateTask.asObservable();

  refreshTasksData = new EventEmitter();
  tasksData: Subscription;

  updateChoiceState(choiceState: any) {
    this.choiceState.next(choiceState);
  }

  updateAppliedFilters(appliedFilters: any) {
    this.appliedFilters.next(appliedFilters);
  }

  updateFiltersState(filters: any) {
    this.filters.next(filters);
  }

  updateCurrentPageIndex(pageIndex: any) {
    this.pageIndex.next(pageIndex);
  }

  updateEditableTask(editableTask: boolean) {
    this.editableTask.next(editableTask);
  }

  updateUpdateTask(updateTask: boolean) {
    this.updateTask.next(updateTask);
  }

  changeTasksDataSourceFunction() {
    this.refreshTasksData.emit();
  }

  assignToMe(taskId) {
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskId + '/assignment',
      this.buildHeaders()
    );
  }

  forwardTask(taskIds, forwardOption, name, comment) {
    const request = {
      taskIds,
      forwardOption,
      name,
      comment,
    };
    return this.http.put(
      this.serviceUrl + 'tasks/forwardTask',
      request,
      this.buildHeaders()
    );
  }

  getTask(taskId: any) {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId,
      this.buildHeaders()
    );
  }

  setInitialFilter(value: any) {
    this.intialFilter = value;
  }

  setSorting(sortObject) {
    this.sortOrder = sortObject.direction;
    this.sortField = sortObject.choice;
  }

  dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      if (Number(a[property]) && Number(b[property])) {
        if (Number(a[property]) > Number(b[property])) {
          return 1 * sortOrder;
        }
        if (Number(a[property] < Number(b[property]))) {
          return -1 * sortOrder;
        }
        return 0;
      }
      const translateA = this.translateService.instant(a[property].toString());
      const translateB = this.translateService.instant(b[property].toString());
      if (translateA !== a[property] && translateB !== b[property]) {
        if (sortOrder === -1) {
          return translateB
            .toString()
            .localeCompare(translateA, 'en', { ignorePunctuation: true });
        } else {
          return translateA
            .toString()
            .localeCompare(translateB, 'en', { ignorePunctuation: true });
        }
      } else {
        if (sortOrder === -1) {
          return b[property]
            .toString()
            .localeCompare(a[property], 'en', { ignorePunctuation: true });
        } else {
          return a[property]
            .toString()
            .localeCompare(b[property], 'en', { ignorePunctuation: true });
        }
      }
    };
  }

  columnDateFilter(date, task, attribute) {
    const startDate = date[0];
    const endDate = date[1];
    const creationDate = new Date(task[attribute]);
    startDate.setHours(0, 0, 0);
    endDate.setHours(0, 0, 0);
    creationDate.setHours(0, 0, 0);
    startDate.setMilliseconds(0);
    endDate.setMilliseconds(0);
    creationDate.setMilliseconds(0);
    if (
      (creationDate.getTime() === endDate.getTime()) ===
      startDate.getTime()
    ) {
      return true;
    }
    if (creationDate > endDate || creationDate < startDate) {
      return false;
    }
    return true;
  }

  checkColumnFilters(task) {
    for (const entry of this.allColumnFilters.entries()) {
      const values: any[] = entry[1];
      if (entry[0] === 'dueDate') {
        if (entry[1].length > 0) {
          if (!this.columnDateFilter(entry[1][0], task, 'dueDate')) {
            return false;
          }
        }
      }
      if (entry[0] === 'creationDate') {
        if (entry[1].length > 0) {
          if (!this.columnDateFilter(entry[1][0], task, 'creationDate')) {
            return false;
          }
        }
      }
      if (!values) {
        continue;
      }
      if (values.length === 0) {
        continue;
      }
      if (entry[0] !== 'creationDate' && entry[0] !== 'dueDate') {
        const key = entry[0];
        if (values.indexOf(task[key]) === -1) {
          return false;
        }
      }
    }
    return true;
  }

  filterDate(filter: any, task: any): boolean {
    if (filter.inputForm.value) {
      const startDate = filter.inputForm.value.begin;
      const endDate = filter.inputForm.value.end;
      const creationDate = new Date(task.creationDate);
      startDate.setHours(0, 0, 0);
      endDate.setHours(0, 0, 0);
      creationDate.setHours(0, 0, 0);

      startDate.setMilliseconds(0);
      endDate.setMilliseconds(0);
      creationDate.setMilliseconds(0);

      if (
        (creationDate.getTime() === endDate.getTime()) ===
        startDate.getTime()
      ) {
        return true;
      }
      if (creationDate > endDate || creationDate < startDate) {
        return false;
      }
      return true;
    }
    return true;
  }

  startSearch() {
    this.searchStarted.emit(true);
  }

  getTaskList(pageSize: number, page: number) {
    if (localStorage.getItem('pageIndex')) {
      page = Number(localStorage.getItem('pageIndex'));
    }

    if (localStorage.getItem('pageSize')) {
      pageSize = Number(localStorage.getItem('pageSize'));
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const pageNo = page + 1;
    this.pageSize = pageSize;
    let params = new HttpParams()
      .set('page', pageNo.toString())
      .set('amount', pageSize.toString())
      .set('sortBy', this.sortField)
      .set('sortOrder', this.sortOrder);
    if (this.filter) {
      params = this.addFilterParams(params);
    }
    this.subscription = this.http
      .get<{ content: Task[] }>(this.serviceUrl + 'tasks/filter', {
        params,
        headers: this.buildHeadersTmp(),
      })
      .subscribe((data) => {
        data['page'] = page;
        this.taskList.emit(data);
      });
  }
  buildHeadersTmp() {
    const headers = new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Accept', 'application/json')
      .append('Business-Unit-Id', this.businessUnits.getCurrentBusinessUnit());
    return headers;
  }

  addFilterParams(params) {
    params = params.append('searchString', this.filter.searchString);
    params = params.append('onlyActive', this.filter.onlyActive);
    params = params.append('onlyMyTasks', this.filter.onlyMyTasks);
    params = params.append('onlyLocked', this.filter.onlyLocked);
    if (this.filter.taskType) {
      this.filter.taskType.forEach((element) => {
        params = params.append('type', element);
      });
    }
    if (this.filter['filter-more.priority']) {
      this.filter['filter-more.priority'].forEach((element) => {
        params = params.append('priority', element);
      });
    }
    if (this.filter['filter-more.created_by']) {
      this.filter['filter-more.created_by'].forEach((element) => {
        params = params.append('createdBy', element);
      });
    }
    if (this.filter['filter-more.business_unit_id']) {
      this.filter['filter-more.business_unit_id'].forEach((element) => {
        params = params.append('businessUnitId', element);
      });
    }
    if (this.filter['filter-more.creation_date']) {
      if (this.filter['filter-more.creation_date'].begin) {
        const creationDateBegin = moment(
          this.filter['filter-more.creation_date'].begin
        ).format('YYYY-MM-DD');
        params = params.append('creationDate', creationDateBegin);
      } else {
        params = params.append('creationDate', '');
      }
      if (this.filter['filter-more.creation_date'].end) {
        const creationDateEnd = moment(
          this.filter['filter-more.creation_date'].end
        ).format('YYYY-MM-DD');
        params = params.append('creationDate', creationDateEnd);
      } else {
        params = params.append('creationDate', '');
      }
    }
    if (this.filter['filter-more.due_date']) {
      if (this.filter['filter-more.due_date'].begin) {
        const dueDateBegin = moment(
          this.filter['filter-more.due_date'].begin
        ).format('YYYY-MM-DD');
        params = params.append('dueDate', dueDateBegin);
      } else {
        params = params.append('dueDate', '');
      }
      if (this.filter['filter-more.due_date'].end) {
        const dueDateEnd = moment(
          this.filter['filter-more.due_date'].end
        ).format('YYYY-MM-DD');
        params = params.append('dueDate', dueDateEnd);
      } else {
        params = params.append('dueDate', '');
      }
    }
    return params;
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'tasks/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  setFilter(form: any) {
    this.filter = form;
    localStorage.setItem('pageIndex', String(0));

    this.getTaskList(this.pageSize, 0);
  }

  parseTitle(task: any) {
    const titleSections = task.title.split(' ');
    const title = titleSections[0];
    const id = titleSections[1];
    const indexOfNameStart = task.title.indexOf(
      ' ',
      task.title.indexOf(' ') + 1
    );
    task.taskTitle = title;
    task.objectId = id;
    task.objectName =
      indexOfNameStart > 0 ? task.title.substr(indexOfNameStart + 1) : '';
  }

  complete(taskId: number, commentJson): Observable<any[]> {
    return this.http.post<any[]>(
      this.serviceUrl + 'tasks/' + taskId + '/completion',
      commentJson,
      this.buildHeaders()
    );
  }

  lock(taskId: number): Observable<any[]> {
    return this.http.post<any[]>(
      this.serviceUrl + 'tasks/' + taskId + '/locked',
      {},
      this.buildHeaders()
    );
  }

  unlock(taskId: number): Observable<any[]> {
    return this.http.post<any[]>(
      this.serviceUrl + 'tasks/' + taskId + '/unlocked',
      {},
      this.buildHeaders()
    );
  }

  getUserLockedTasks(): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'tasks/lockedUserTasks',
      this.buildHeaders()
    );
  }
  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.serviceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }
  unlockUserTasks(): Observable<any[]> {
    return this.http.post<any[]>(
      this.serviceUrl + 'tasks/unlockedUserTasks',
      {},
      this.buildHeaders()
    );
  }

  createComment(taskId: number, comment: string) {
    const json = {
      taskId,
      content: comment,
      eventType: TaskEventType.EDIT,
    };
    return this.http.post<any[]>(
      this.serviceUrl + 'comments',
      json,
      this.buildHeaders()
    );
  }

  getTaskTypes(): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'task-types',
      this.buildHeaders()
    );
  }

  uploadFile(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload',
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  uploadFileBatch(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload-batch',
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  deleteFile(file: AzureFile) {
    return this.http.post(
      this.serviceUrl + 'files/delete',
      file,
      this.buildHeaders()
    );
  }

  getFile(fileName: string, taskId: number) {
    return this.http.get(
      this.serviceUrl + 'files/getFile/' + taskId + '?fileName=' + fileName,
      {
        responseType: 'blob',
      }
    );
  }

  listFiles(id: any): Observable<AzureFile[]> {
    return this.http.get<AzureFile[]>(
      this.serviceUrl + 'files/list?taskId=' + id,
      this.buildHeaders()
    );
  }

  rejectTask(id: NumberSymbol, comment: string, taskGuid: string) {
    // taskId: id, will be included to the json after the comment table receive the task guid.
    const json = {
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  countOpenTasks() {
    return this.http.get(
      this.serviceUrl + 'tasks/countOpenTasks',
      this.buildHeaders()
    );
  }

  updateAndComplete(taskId: number, requestJson): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.serviceUrl}tasks/${taskId}/update-and-complete`,
      requestJson,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  getActiveTasksByType(
    pageSize: number,
    page: number,
    type: string,
    sortBy?: string,
    sortOrder?: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('page', (page + 1).toString())
      .set('amount', pageSize.toString())
      .set('sortBy', sortBy ? sortBy : 'id')
      .set('sortOrder', sortOrder ? sortOrder : 'DESC')
      .set('onlyActive', true)
      .set('onlyMyTasks', false)
      .set('onlyLocked', false)
      .set('type', type);
    return this.http.get<any>(this.serviceUrl + 'tasks/filter', {
      params,
      headers: this.buildHeadersTmp(),
    });
  }
}
