export const reportedByOptions: any[] = [
  {
    name: 'TQLFB-1',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTED-BY-OPTIONS.SUPPLIER',
    value: 1,
  },
  {
    name: 'TQLFB-2',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTED-BY-OPTIONS.PURCHASER',
    value: 2,
  },
];
