<div class="dialog-content">
  <form [formGroup]="approvalForm" autocomplete="off">
    <div mat-dialog-content class="background-dark">
      <h1 mat-dialog-title>
        {{ 'task.favFilters.filterName' | translate }}
      </h1>
      <mat-form-field class="white-back">
        <mat-label>{{ 'task.favFilters.filterName' | translate }}</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'task.favFilters.filterName' | translate }}"
          name="favFilterName"
          formControlName="name"
          [(ngModel)]="data.name"
          id="{{ entityName }}-favFilterName-input"
          type="text"
          [removeHtmlTags]="approvalForm.controls.name"
        />
      </mat-form-field>
    </div>
    <div class="buttons">
      <button
        id="{{ entityName }}-favFilterName-close-button"
        mat-button
        class="custom-button-cancel"
        [mat-dialog-close]
      >
        {{ 'cancel' | translate }}
      </button>
      <button
        id="{{ entityName }}-favFilterName-approval-button"
        mat-button
        class="custom-button"
        [disabled]="approvalForm.invalid"
        [mat-dialog-close]="data"
        style="margin-right: 0"
      >
        {{ 'save' | translate }}
      </button>
    </div>
  </form>
</div>
