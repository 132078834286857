import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { classificationRelevantOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/classificationRelevant';
import { transactionTypes } from 'src/app/in-memory-data/bookingCode/formConfiguration/transactionTypes';
import { statusTypes } from 'src/app/in-memory-data/business-unit-transaction-codes/enum/statusTypes';
import { buTransactionCodesColumns } from 'src/app/in-memory-data/business-unit-transaction-codes/table-columns';
import { buTransactionCodesColumnsConf } from 'src/app/in-memory-data/business-unit-transaction-codes/table-columns-configuration';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TransactionCodeSelectionModalComponent } from 'src/app/shared/modals/transaction-code-selection-modal/transaction-code-selection-modal.component';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SharedDataService } from '../services/shared-data.service';
import { TcaStatus } from './tca-status.enum';

@Component({
  selector: 'app-bu-transaction-codes',
  templateUrl: './bu-transaction-codes.component.html',
  styleUrls: ['./bu-transaction-codes.component.less'],
})
export class BuTransactionCodesComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = buTransactionCodesColumnsConf;
  displayedColumns = buTransactionCodesColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  transactionTypes = transactionTypes;
  statusTypes = statusTypes;
  classRelevantOptions = classificationRelevantOptions;

  entityName = 'business-unit-transaction-code';

  dataSource = new MatTableDataSource<any>();

  private CONFIGURAIONT_KEY = 'bu_transaction_codes_table';

  state: any;
  loaderSpinner = false;
  dateFormat: any;
  transactionCodeList: any;

  @Input() entity;

  newData = [];

  dataSourceEdited = false;

  USED = 1;
  NOT_USED = 2;

  constructor(
    private userService: UserService,
    private matDialogService: MatDialog,
    private bookingCodeService: BookingCodeService,
    private sharedDataService: SharedDataService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    private subledgerService: SubLedgerService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.state = window.history.state;
    this.dateFormat = this.userService.getDateFormat();
    this.loaderSpinner = true;
    this.getTableConfiguration();
    this.loadData();
  }

  loadData() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.entity) {
      this.transactionCodeAllocationService
        .retrieveTransactionCodeByEntityId(this.entity.guid)
        .subscribe(
          (data) => {
            this.dataSource.data = data;
            this.loaderSpinner = false;
          },
          (error) => {
            this.loaderSpinner = false;
          }
        );
    } else {
      this.loaderSpinner = false;
    }
  }

  showDeleteButton(tca): boolean {
    return (
      tca &&
      (tca['status'] === TcaStatus.Incomplete ||
        tca['status'] === TcaStatus.NotUsed)
    );
  }

  showSetInactiveButton(tca): boolean {
    return tca && tca['status'] === TcaStatus.Used;
  }

  showSetActiveButton(tca): boolean {
    return tca && tca['status'] === TcaStatus.Inactive;
  }

  showEditButton(tca): boolean {
    return (
      tca &&
      (tca['status'] === TcaStatus.Used || tca['status'] === TcaStatus.NotUsed)
    );
  }

  addTransactionCode() {
    const dialog = this.matDialogService.open(
      TransactionCodeSelectionModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          selectedTransactions: this.dataSource.data,
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        this.newData = [];
        result.forEach((element) => {
          this.newData.push(element);
        });

        this.formatDataAndAddToTable();
      }
    });
  }

  formatDataAndAddToTable() {
    if (this.newData.length > 0) {
      this.newData.forEach((data) => {
        Object.assign(data, {
          transactionCode: data.id,
        });

        Object.assign(data, {
          originalTransactionCodeNameGerman: data.name,
        });

        Object.assign(data, {
          originalTransactionCodeNameEnglish: data.defaultNameEnglish,
        });

        Object.assign(data, {
          originalTransactionCodeDescriptionGerman: data.description,
        });

        Object.assign(data, {
          originalTransactionCodeDescriptionEnglish: data.descriptionEnglish,
        });

        Object.assign(data, {
          status: TcaStatus.Incomplete,
        });

        Object.assign(data, {
          new: true,
        });

        Object.assign(data, { salesTaxAccountGuid: data.salesTaxAccount });
        this.addSaleTaxAccount(data, data.salesTaxAccount);
        this.addObjectAccount(data, data.objectionAccountBeVision);
        this.dataSource.data.push(data);
        this.dataSource.filter = '';
        this.dataSourceEdited = true;
      });
    }
  }

  addSaleTaxAccount(data, salesTaxAccount) {
    if (salesTaxAccount && salesTaxAccount !== 'NOT_RELEVANT') {
      this.subledgerService.findByGuid(salesTaxAccount).subscribe((result) => {
        if (result) {
          Object.assign(data, {
            salesTaxAccount: result['accountNumber'],
          });
          this.dataSource.filter = '';
        }
      });
    } else {
      Object.assign(data, {
        salesTaxAccount,
      });
    }
  }

  addObjectAccount(data, objectionAccountBeVision) {
    if (
      objectionAccountBeVision &&
      objectionAccountBeVision !== 'NOT_RELEVANT'
    ) {
      this.subledgerService
        .findByGuid(objectionAccountBeVision)
        .subscribe((result) => {
          if (result) {
            Object.assign(data, {
              originalObjectAccount: result['accountNumber'],
            });
            this.dataSource.filter = '';
          }
        });
    } else {
      Object.assign(data, {
        originalObjectAccount: objectionAccountBeVision,
      });
    }
  }

  completeTransactionCode(element) {
    this.sharedDataService.updateSharedTransactionCode(element);
    this.sharedDataService.updateSharedEditTransactionMode(true);
  }

  editTransactionCode(element) {
    this.sharedDataService.updateSharedTransactionCode(element);
    this.sharedDataService.updateSharedEditTransactionMode(true);
  }

  setActiveTransactionCode(comment, element) {
    const request = {
      comment,
      transactionCodeAllocationId: element.id,
      taskId: null,
    };

    this.transactionCodeAllocationService
      .setTransactionCodeActive(request)
      .subscribe((result) => {
        this.showSuccessTaskCreated();
      });
  }

  setInactiveTransactionCode(comment, element) {
    const request = {
      comment,
      transactionCodeAllocationId: element.id,
      taskId: null,
    };

    this.transactionCodeAllocationService
      .setTransactionCodeInactive(request)
      .subscribe((result) => {
        this.showSuccessTaskCreated();
      });
  }

  showSuccessTaskCreated() {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      { data: this.translateService.instant('ENTITIES.BU') }
    );

    setTimeout(() => {
      this.router.navigateByUrl('/systemConfiguration/bu', {
        state: { success: true },
      });
    }, 1500);
  }

  showSuccessDelete(tca) {
    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.SUCCESS-MESSAGES.DELETED-ENTRY',
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
    this.dataSource.data = this.dataSource.data.filter(
      (element) => element !== tca
    );
  }

  openConfirmation(element, action) {
    if (action === 0) {
      this.openConfirmationDialog(
        'NOTIFICATION.CONFIRM-INACTIVE-TRANSACTION-CODE',
        element,
        action
      );
    } else {
      this.openConfirmationDialog('notification.SAVE-ENTRIES', element, action);
    }
  }

  openCommentModal(element, action) {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        if (action === 0) {
          this.setInactiveTransactionCode(result.comment, element);
        } else if (action === 1) {
          this.setActiveTransactionCode(result.comment, element);
        }
      }
    });
  }

  openConfirmationDialog(message, element, action) {
    if (action === 0 && element.retentionPeriodExpired) {
      this.openCommentModal(element, action);
    } else {
      const dialog = this.matDialogService.open(
        GeneralDeleteConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: {
            deleteMessage: this.translateService.instant(message),
          },
        }
      );
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.openCommentModal(element, action);
        }
      });
    }
  }

  deleteTransactionCode(element) {
    const dialog = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'notification.DELETE-ENTRIES'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.isNewTca(element)) {
          this.showSuccessDelete(element);
        } else {
          this.transactionCodeAllocationService
            .deleteTransactionCodeAllocation(element.id)
            .subscribe(() => {
              this.showSuccessDelete(element);
            });
        }
      }
    });
  }

  private isNewTca(tca): boolean {
    return tca['new'] === true;
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                buTransactionCodesColumnsConf,
                buTransactionCodesColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = buTransactionCodesColumnsConf;
    this.displayedColumns = buTransactionCodesColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getTransactionType(transactionTypeName) {
    if (!transactionTypeName) {
      return;
    }
    return this.transactionTypes.find(
      (data) => data.name === transactionTypeName
    ).translateName;
  }

  getBooleanTranslate(booleanValue) {
    if (booleanValue == null) {
      return;
    }
    return this.classRelevantOptions.find((data) => data.value === booleanValue)
      .translateName;
  }

  getStatusTranslationCode(status) {
    return this.statusTypes.find((data) => data.name === status).translateName;
  }
}
