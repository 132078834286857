import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class BillingSlaughterhouseService {
  private readonly participantServiceUrl;
  private readonly openItemServiceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.participantServiceUrl = environment.settings.participantService;
    this.openItemServiceUrl = environment.settings.openItemService;
  }

  findAllSlaughterhouse(level) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/level/' + level,
      this.buildHeaders()
    );
  }

  getMasterDataById(id) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/' + id,
      this.buildHeaders()
    );
  }

  createOpenItem(request): Observable<any> {
    return this.http.post(
      this.openItemServiceUrl + 'openItems',
      request,
      this.buildHeaders()
    );
  }

  getBalance(entityFromGuid): Observable<any> {
    return this.http.get(
      this.openItemServiceUrl +
        'openItems/balanceByEntityFrom?entityFromGuid=' +
        entityFromGuid,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
