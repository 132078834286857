<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'GENERAL.SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
      <button mat-button class="custom-button search-button" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <h5 id="bu-search-results-title">{{ 'SEARCH-RESULT' | translate }}</h5>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    id="{{ entityName }}-search-results-table"
    aria-hidden="true"
    class="general-table-alignment"
  >
    <ng-container matColumnDef="businessUnitId">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th number-row-header-sortable id-column"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
      >
        {{ 'BU.BUSINESS-UNIT-ID' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        class="number-row id-column"
      >
        {{ element.businessUnitId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
      >
        {{ 'BU.BU-NAME' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.businessUnitName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
      >
        {{ 'GENERAL.STATUS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ getStatusTypeTranslateName(element.status) | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="general-loader-table" *ngIf="loaderSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th settings"
        id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="appMenu"
          id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-button"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          *ngIf="element.draft"
          class="menu"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ element: element }"
          (click)="$event.stopPropagation()"
          id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span class="material-icons"> more_vert </span>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [hidden]="loaderSpinner"
      class="element-row"
      (click)="clickOnBusinessUnit(row)"
    ></tr>
    <tr
      [hidden]="!loaderSpinner"
      mat-footer-row
      *matFooterRowDef="['loader']"
      class="footer-spinner height-footer"
    ></tr>
  </table>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
</div>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element"> </ng-template>
</mat-menu>
