<div class="content home-container">
  <mat-toolbar class="background">
    <img
      class="logo"
      src="../assets/images/BFS-Finance_Square_Logo_rgb_white.png"
      (click)="goToDashboard()"
      alt="{{ 'VISION25_SLOGAN_1' | translate }}"
    />
    <div class="slogan-container">
      <span class="slogan-1"> {{ 'VISION25-SLOGAN-1' | translate }}</span>
      <span class="slogan-2">{{ 'VISION25-SLOGAN-2' | translate }}</span>
    </div>
    <div class="spacer"></div>
    <div class="menu">
      <div class="business-unit-selection" *hasPermission="'bu.r'">
        <mat-label>{{ 'MASTER-DATA.BU' | translate }}:</mat-label>
        <mat-select [(value)]="selectedBusinessUnit" (selectionChange)="businessUnitChanged()">
          <mat-option
            *ngFor="let b of userBusinessUnits | sortBy: { property: 'name', descending: false }"
            [value]="b.id"
            id="bu-seletion-input"
          >
            {{ b.name }}
          </mat-option>
        </mat-select>
      </div>

      <mat-select class="language-select" [(value)]="selectedLanguage" id="language-dropdown">
        <mat-option
          *ngFor="let lang of createdLanguages | sortBy: { property: 'name', descending: false }"
          [value]="lang.isoCode"
          (click)="useLanguage(lang)"
          id="{{ lang.isoCode }}-language-seletion-input"
        >
          {{ 'GENERAL.LANGUAGE.' + lang.name.toString().toUpperCase() | translate }}
        </mat-option>
      </mat-select>

      <div class="application-version">
        <mat-label>{{ 'GENERAL.APPLICATION-VERSION' | translate }}: </mat-label>
        <span> {{ applicationVersion }} </span>
      </div>

      <button id="logout-button" (click)="logout()" class="button-lang">
        <span translate>MENU.LOGOUT</span>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="main-container">
    <mat-sidenav
      #menuSidenav
      mode="side"
      opened="true"
      (mouseenter)="open()"
      (mouseleave)="close()"
      [@slideInOut]="sideNavOpen"
    >
      <div class="unlock_icon lock" [@lockAnimation]="locked">
        <mat-icon *ngIf="locked == '0'" class="navigation" (click)="lock()" id="open-menu-button">lock_open</mat-icon>
        <mat-icon *ngIf="locked == '1'" class="navigation" (click)="lock()" id="close-menu-button">lock</mat-icon>
      </div>
      <div class="navigation_icons">
        <!-- Menu Item: Dashboard
          Composite Users: Core Internal, Core External
          Order: 10  -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('dashboard')"
          routerLink="/dashboard"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-dashboard-button" (click)="resetTaskList()">
            <mat-icon class="navigation">home</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.DASHBOARD' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Dashboard  -->

        <!-- Menu Item: Reports
          Composite Users:
          Order: n  -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('reports')"
          routerLink="/reports"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-dashboard-button" (click)="resetTaskList()">
            <mat-icon class="navigation"> analytics</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'DASHBOARD.REPORTING' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Reports  -->

        <!-- End Menu Item: AW Interface Transfer Protocol -->

        <!-- Menu Item: Task Management
          Composite Users: Core Internal.
          Order: 20 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('tasks')"
          routerLink="/tasks"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-task-button" (click)="resetTaskList()">
            <mat-icon class="navigation">calendar_today</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.TASKS' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Task Management  -->

        <!-- Menu Item: Core Interface Transfer Protocol
          Composite Users: Core Internal.
          Order: 30 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('interfaceTransferProtocol')"
          routerLink="/interfaceTransferProtocol"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-interfaceTransferProtocol-button" (click)="resetTaskList()">
            <mat-icon class="navigation">save_alt</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{
              'SIDE-NAV.INTERFACE-TRANSFER-PROTOCOL' | translate
            }}</span>
          </button>
        </div>
        <!-- End Menu Item: Core Interface Transfer Protocol  -->

        <!-- Menu Item: Master Data Management
          Composite Users: Animal Welfare External (Operating Company) (Read Only),
          Core Internal
          Order: 40 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('masterdata')"
          routerLink="/masterdata"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-masterData-button" (click)="resetTaskList()">
            <mat-icon class="navigation">account_balance_wallet</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.MASTER-DATA' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Master Data Management  -->

        <!-- Menu Item: Accounting/Limit
          Composite Users: Core Internal
          Order: 50 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('accounting')"
          routerLink="/accounting"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-accounting-button" (click)="resetTaskList()">
            <mat-icon class="navigation">account_balance</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.ACCOUNTING' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Accounting/Limit  -->

        <!-- Menu Item:Open Items Creation (Booking)
        Composite Users: Core Internal
        Order: 60 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('openTransactionBooking')"
          routerLink="/openTransactionBooking"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-openTransactionBooking-button" (click)="resetTaskList()">
            <mat-icon class="navigation">euro_symbol</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.BOOKING' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Open Items Creation -->

        <!-- Menu Item: TCI
          Composite Users: Factoring Internal
          Option: 61  -->
        <ng-container *hasBUProductPermission="'FACTORING'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('tci')"
            routerLink="/tci"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-tci-button" (click)="resetTaskList()">
              <mat-icon class="navigation">account_balance_wallet</mat-icon>
              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.TCI' | translate }}</span>
            </button>
          </div>
        </ng-container>
        <!-- End Menu Item:TCI  -->

        <!-- Menu Item: Livestock Producer
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company),
          Animal Welfare External (Livestock Producer),
          Animal Welfare External (Coordinator),
          Animal Welfare External (Middleman)
          Order: 62 (Internal), 12 (External) -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('livestockProducer')"
            routerLink="/livestockProducer"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-livestockProducer-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">person</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.LIVESTOCK-PRODUCER' | translate }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Livestock Producer -->

        <!-- Menu Item: Sales Entry
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company) (Read Only)
           Order: 63 (Internal), 13 (External) -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('salesEntry')"
            routerLink="/salesEntry"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-salesEntry-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">receipt</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.SALES-ENTRY' | translate }}</span>
            </button>
          </div>
        </ng-container>
        <!-- End Menu Item: Sales Entry -->

        <!-- Menu Item: Liquidity Plan
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company) (TODO: AW CEO and Employee plus),
           Order: 64 (Internal), 14 (External)  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('liquidityPlan')"
            routerLink="/liquidityPlan"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-liquidityPlan-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">query_stats</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.LIQUIDITY-PLAN' | translate }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Liquidity Plan -->

        <!-- Menu Item: Quantity Goods
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company) (Read Only),
          Animal Welfare External (Marketer),
          Animal Welfare External (Coordinator),
          Animal Welfare External (Middleman)
          Order: 65 (Internal), 15 (External)  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('quantityGoods')"
            routerLink="/quantityGoods"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-quantityGoods-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">maps_ugc</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.QUANTITY-GOODS' | translate }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Quantity Goods  -->

        <!-- Menu Item: Labelling
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company) (Read Only)
           Order: 16 (Internal), 16 (External)  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('quantityLabeledGoods')"
            routerLink="/quantityLabeledGoods"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-quantityLabeledGoods-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">shopping_bag</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{
                'SIDE-NAV.QUANTITY-LABELLED-GOODS' | translate
              }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Labelling -->

        <!-- Menu Item: Participation Fee
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company) (Read Only)
           Order: 67 (Internal), 17 (External)  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('awParticipationFee')"
            routerLink="/awParticipationFee"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-awParticipationFee-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">payment</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{
                'SIDE-NAV.AW-PARTICIPATION-FEE' | translate
              }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Participation Fee -->

        <!-- Menu Item: Control Board
          Composite Users: Animal Welfare Internal
          Order: 68 (Internal)  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('quarterlyBilling')"
            routerLink="/quarterlyBilling"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-quarterlyBilling-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">games</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.CONTROL-BOARD' | translate }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Control Board -->

        <!-- Menu Item: Animal Welfare Operating Company
          Composite Users: Animal Welfare Internal
         Order: 69  -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('operatingCompany')"
            routerLink="/operatingCompany"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-operator-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">business</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.OPERATOR' | translate }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: Operating Company -->

        <!-- Menu Item: AW Interface Transfer Protocol
          Composite Users: Animal Welfare External (Operating Company)
          Order: 18 (External) -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('awInterfaceTransferProtocol')"
            routerLink="/awInterfaceTransferProtocol"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-awInterfaceTransferProtocol-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">api</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{
                'SIDE-NAV.AW-INTERFACE-TRANSFER-PROTOCOL' | translate
              }}</span>
            </button>
          </div>
        </ng-container>

        <!-- End Menu Item: AW Interface Transfer Protocol -->

        <!-- Menu Item: AW Document Management
          Composite Users: Animal Welfare Internal,
          Animal Welfare External (Operating Company)
          Order: 18 -->
        <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
          <div
            class="menu_item"
            *ngIf="hasRoleForPath('documentManagement')"
            routerLink="/documentManagement"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <button mat-icon-button id="open-menu-documentManagement-button" (click)="resetTaskList()">
              <mat-icon class="material-icons">description</mat-icon>

              <span class="lead" [ngStyle]="{ opacity: opacity }">{{
                'SIDE-NAV.DOCUMENT-MANAGEMENT' | translate
              }}</span>
            </button>
          </div>
        </ng-container>
        <!-- End Menu Item: AW Interface Transfer Protocol -->

        <!-- Menu Item: System Configuration
          Composite Users: Core Internal, Animal Welfare Internal
          Order: 70  -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('systemConfiguration')"
          routerLink="/systemConfiguration"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-systemConfiguration-button" (click)="resetTaskList()">
            <mat-icon class="navigation">settings</mat-icon>

            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.SYSTEM-CONFIGURATION' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: System Configuration -->

        <!-- Menu Item: Objection Management
          Composite Users: TBD (Factoring Internal at the moment)
          Order: TBD  -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('objectionManagement')"
          routerLink="/objectionManagement"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-objectionManagement-button" (click)="resetTaskList()">
            <mat-icon class="navigation">flag</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.OBJECTION-MANAGEMENT' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Objection Managementn -->

        <!-- Menu Item: User Management
        Composite Users: Core Internal
         Order: 80 -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('userManagement')"
          routerLink="/userManagement"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-user-management-button" (click)="resetTaskList()">
            <mat-icon class="navigation">manage_accounts</mat-icon>
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.USER-MANAGEMENT' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item User Management -->

        <!-- Menu Item:User Profile
          Composite Roles: Core Internal
          Order: 90  -->
        <div
          class="menu_item"
          *ngIf="hasRoleForPath('userProfile')"
          routerLink="/userProfile"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-userProfile-button" (click)="resetTaskList()">
            <mat-icon class="navigation">account_circle</mat-icon>

            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.PROFILE' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: User Profile -->
      </div>
      <!-- Down Menu Items-->
      <div class="down-menu-items" [ngClass]="{ 'hidden-block': sideNavOpen === 'no' }">
        <!-- Menu Item: Website Credits-->
        <div
          class="menu_item"
          routerLink="/websiteCredits"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-websiteCredits-button" (click)="resetTaskList()">
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.WEBSITE-CREDITS' | translate }}</span>
          </button>
        </div>

        <!-- End Menu Item: Website Credits -->

        <!-- Menu Item: Privacy Policy-->
        <div
          class="menu_item w-120"
          routerLink="/privacyPolicy"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-privacyPolicy-button" (click)="resetTaskList()">
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.PRIVACY-POLICY' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Privacy Policy -->

        <!-- Menu Item: Terms of Service-->
        <div
          class="menu_item"
          routerLink="/termsOfService"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <button mat-icon-button id="open-menu-termsOfService-button" (click)="resetTaskList()">
            <span class="lead" [ngStyle]="{ opacity: opacity }">{{ 'SIDE-NAV.TERMS-OF-SERVICE' | translate }}</span>
          </button>
        </div>
        <!-- End Menu Item: Terms of Service -->
      </div>
      <!-- End Down Menu Items -->
    </mat-sidenav>

    <mat-sidenav-content [@slideOutIn]="sideNavOpen" [ngClass]="{ transparentBack: transparentBackground }">
      <div class="spinner-container" *ngIf="loaderSpinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <router-outlet *ngIf="!loaderSpinner"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-container>
  <mat-drawer-container>
    <mat-drawer #drawer class="sidenav" mode="over" position="end">
      <button type="button" mat-button (click)="drawer.close()" class="close-split custom-button-cancel">
        <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
      </button>
      <app-dashboard-faq></app-dashboard-faq>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
