import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function positiveNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const num = Number(control.value);
    const valid = Number.isInteger(num) && num > 0;

    return !valid
      ? { positiveNumberValidationError: { value: control.value } }
      : null;
  };
}
