import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { TaskEventType } from '../../../models/task-event-type';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportingPeriodService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  createReportingPeriod(reportingPeriods, comment, approvalTaskId) {
    const json = {
      reportingPeriods,
      comment,
      taskId: approvalTaskId,
    };
    return this.http.post(
      this.serviceUrl + 'reporting-period',
      json,
      this.buildHeaders()
    );
  }

  findAll() {
    return this.http.get(
      this.serviceUrl + 'reporting-period',
      this.buildHeaders()
    );
  }
}
