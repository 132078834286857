import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { TaskConfigurationService } from '../../../shared/services/task-configuration/task-configuration.service';
import { TaskConfiguration } from '../../../shared/models/task-configuration';
import { Observable, of } from 'rxjs';
import {
  Role,
  TaskType,
} from '../../task-management/task-filter/task-filter.component';
import { UserService } from '../../../shared/services/user/user.service';
import { DismissChangesDialogComponent } from '../../../shared/dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { teamEnum } from '../../../in-memory-data/user-management/enums/team-enum';

@Component({
  selector: 'app-task-configuration-create',
  templateUrl: './task-configuration-create.component.html',
  styleUrls: ['./task-configuration-create.component.less'],
})
export class TaskConfigurationCreateComponent implements OnInit {
  entityName = 'task-configuration';
  tcForm: UntypedFormGroup;
  editMode = false;
  createMode = true;
  isDirty = false;
  errorRequired = 'error-message-required';
  errorInvalid = 'error-message-invalid';
  initialTaskConfiguration: TaskConfiguration;
  tcValue: TaskConfiguration;
  roles: Role[];
  teams = teamEnum;
  selectedRole: Role;
  selectedTeam: any;
  selectedPriority: TaskType;
  priorities: any[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private matDialogService: MatDialog,
    private notificationService: NotificationService,
    private taskConfigurationService: TaskConfigurationService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.tcForm = this.formBuilder.group({
      id: [''],
      objectId: ['', Validators.required],
      object: ['', Validators.required],
      description: ['', Validators.required],
      roleId: ['', Validators.required],
      teamId: [''],
      type: ['', Validators.required],
      dueDate: [
        '',
        [Validators.required, Validators.min(0), Validators.max(1000)],
      ],
      priority: ['', Validators.required],
      embeddedUi: ['', Validators.required],
      embeddedTaskUi: ['', Validators.required],
      guid: [''],
    });
  }

  ngOnInit(): void {
    this.getRoles().subscribe((data) => (this.roles = data));
    this.getPriorities().subscribe((data) => (this.priorities = data));
    this.tcForm.valueChanges.subscribe((val) => (this.tcValue = val));
    this.tcForm.valueChanges.subscribe((e) => (this.isDirty = true));
    const taskConfigurationId = this.route.snapshot.paramMap.get('id');
    if (taskConfigurationId) {
      this.taskConfigurationService
        .getTaskConfiguration(taskConfigurationId)
        .subscribe((data: TaskConfiguration) => {
          this.initialTaskConfiguration = data;
          this.createMode = false;
          this.editMode = false;
        });
    } else {
      this.createMode = true;
      this.editMode = false;
    }
  }

  getRoles(): Observable<Role[]> {
    return this.userService.listRoles();
  }

  changeEditable(val: boolean) {
    this.editMode = val;
    if (val) {
      this.patchTaskConfigurationFormValues(this.initialTaskConfiguration);
      this.tcForm.get('id').disable();
      this.tcForm.get('type').disable();
      this.tcForm.get('object').disable();
      this.tcForm.get('objectId').disable();
      this.tcForm.get('embeddedUi').disable();
      this.tcForm.get('description').disable();
      this.tcForm.get('embeddedTaskUi').disable();
    }
  }

  private patchTaskConfigurationFormValues(
    taskConfiguration: TaskConfiguration
  ) {
    this.tcForm.patchValue(taskConfiguration);
    this.tcForm.markAsPristine();
  }

  getPriorities(): Observable<any[]> {
    return of(this.getPriorityList());
  }

  getPriorityList() {
    return [{ priority: 1 }, { priority: 2 }, { priority: 3 }];
  }

  resetRole() {
    this.tcForm.value.roleId = [];
    this.tcForm.get('roleId').patchValue([]);
    this.taskConfigurationService.filterTaskConfig(this.tcForm.value);
  }

  resetTeam() {
    this.tcForm.value.teamId = [];
    this.tcForm.get('teamId').patchValue([]);
    this.taskConfigurationService.filterTaskConfig(this.tcForm.value);
  }

  resetPriority() {
    this.tcForm.value.priority = [];
    this.tcForm.get('priority').patchValue([]);
    this.taskConfigurationService.filterTaskConfig(this.tcForm.value);
  }

  handleError(error) {
    if (error.error) {
      this.notificationService.showToast(
        'ERROR-MESSAGES.ERROR-BACKEND',
        this.notificationService.MESSAGE_TYPE.ERROR,
        {
          name: error.error.errorId ? error.error.errorId : 'unknown',
          error: error.name,
        }
      );
    }
  }

  submitForm() {
    this.matDialogService
      .open(DismissChangesDialogComponent, {
        panelClass: 'confirmation-task-configuration-popup',
        data: {
          title: 'dialogs.confirmation.title',
          message: 'dialogs.confirmation.message',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (this.editMode && !this.createMode) {
            this.update();
          } else {
            this.save();
          }
        }
      });
  }

  save() {
    this.initialTaskConfiguration =
      this.tcForm.getRawValue() as TaskConfiguration;
    this.taskConfigurationService.save(this.initialTaskConfiguration).subscribe(
      (data) => {
        this.notificationService.showToast(
          'NOTIFICATION.TASK-CONFIGURATION.CREATED',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
        this.router.navigateByUrl('/systemConfiguration/taskConfigurations', {
          state: { success: true },
        });
      },
      (err) => this.handleError(err)
    );
  }

  update() {
    this.initialTaskConfiguration =
      this.tcForm.getRawValue() as TaskConfiguration;
    this.taskConfigurationService
      .update(this.initialTaskConfiguration)
      .subscribe(
        (data) => {
          this.notificationService.showToast(
            'NOTIFICATION.TASK-CONFIGURATON.UPDATED',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
          this.router.navigateByUrl('/systemConfiguration/taskConfigurations', {
            state: { success: true },
          });
        },
        (err) => this.handleError(err)
      );
  }

  getSuffixDays(key) {
    return this.translateService.instant(key);
  }
}
