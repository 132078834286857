<div class="content-container">
  <div class="terms-of-service-container">
    <div class="row title-container underlined">
      <span>Nutzungsbedingungen für BeVision</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Mit Akzeptierung dieser Nutzungsbedingungen beantrage ich die Teilnahme an der Onlinebearbeitung (BeVision,
        nachfolgend das „Online-Portal“) der BFS finance GmbH und verpflichte mich, die Nutzungsbedingungen zu
        beachten.</span
      >
    </div>
    <div class="row text-container mt-row">
      <span
        >Alle von BFS über das Online-Portal versandten E-Mail Nachrichten werden an die im Rahmen der von der
        Initiative Tierwohl übermittelten Ansprechpartnerstammdaten angegebene E-Mailadresse gesendet.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Die BFS finance GmbH, nachfolgend BFS genannt, ist berechtigt, ohne weitere Zustimmung der BeVision Nutzer das
        Online-Portal technisch, rechtlich und durch neue Funktionen weiterzuentwickeln.</span
      >
    </div>
    <div class="row text-container mt-row">
      <span
        >Ich verpflichte mich weiterhin, Einsicht und Verwaltung in dem Online-Portal der BFS finance GmbH nur zu
        vertraglich vereinbarten Zwecken vorzunehmen und in diesem Zusammenhang die datenschutzrechtlichen Anforderungen
        zu beachten, insbesondere, falls aus systemtechnischen Gründen irgendwelche Informationen/Daten über die
        Kundenbeziehung hinaus bekannt werden sollten, Verschwiegenheit zu bewahren und diese nicht an Dritte
        weiterzugeben.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="1">
        <li>Inhalte für BeVision-Teilnehmer</li>
      </ol>
    </div>
    <div class="row title-container mt-row">
      <span>Für Vermarkter</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal die Bewegungsdaten (Mengenmeldungen) seiner ihm zugeordneten
        Schlachtbetriebe für die jeweiligen Tierhalter (landwirtschaftliche Betriebe) einsehen. Weiter besteht die
        Möglichkeit neue Bewegungsdaten für Tierhalter im Namen des zugehörigen Schlachtbetriebs einzugeben.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>Für Landwirtschaftliche Betriebe</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal seine eigenen Bewegungsdaten der vom Bündler und Schlachtbetrieb
        gemeldeten Mengenmeldungen einsehen sowie seine Stammdaten. Weiter können über die Archivfunktion, die für den
        Teilnehmer erstellten Dokumente als PDF aufgerufen und eingesehen werden.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>Für Bündler</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal für teilnehmende Tierhalter an der Initiative Tierwohl folgende
        Bewegungsdaten direkt eingeben:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>VVVO-Nr</li>
        <li>Produktionsart</li>
        <li>Abgabedatum</li>
        <li>Leistungseinheit (Anzahl Tiere oder kg)</li>
        <li>Kommentarfeld</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal seine getätigten Bewegungsdaten (Mengenmeldungen für Tierhalter)
        einsehen sowie bearbeiten.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>Ansicht Tierhalterdaten </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal die ihm zugeordneten Tierhalter nebst VVVO-Nr., Produktionsart sowie
        Standortname in einer tabellarischen Darstellung einsehen. Die Einsichtnahme kann entweder unter Angabe der
        VVVO-Nr. und/oder des Standortnamens erfolgen.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Zusätzlich besteht für den Teilnehmer die Möglichkeit, sich über die Tabelle die Stammdaten des jeweiligen
        Tierhalters aufzurufen. Darin sind folgende Informationen ersichtlich:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>Kontaktdaten des Tierhalters</li>
        <li>Teilnahme- und Budgetinformationen</li>
        <li>Bewegungsdaten</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >Weiterhin kann der Teilnehmer über die tabellarische Darstellung alle zum jeweiligen Tierhalter hinterlegten
        Dokumente durch Herunterladen aus dem Archiv einsehen:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>Quartalsabrechnungen</li>
        <li>Kontoauszüge</li>
        <li>Teilnahmebescheinigung- und Widerrufe</li>
      </ul>
    </div>
    <div class="row title-container mt-row">
      <span>Für Schlachtbetriebe </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Für Schlachtbetriebe Der Teilnehmer kann über das Online-Portal für teilnehmende Tierhalter an der Initiative
        Tierwohl folgende Bewegungsdaten direkt eingeben:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>VVVO-Nr.</li>
        <li>Produktionsart</li>
        <li>Schlachtdatum</li>
        <li>Leistungseinheit (Anzahl Tiere oder kg)</li>
        <li>Kommentarfeld</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer kann über das Online-Portal seine getätigten Bewegungsdaten (Mengenmeldungen für Tierhalter)
        einsehen sowie bearbeiten. Weiter können über die Archivfunktion, die für den Teilnehmer erstellten Dokumente
        als PDF aufgerufen und eingesehen werden.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>Datenerfassung </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer ist berechtigt, Bewegungsdaten für teilnehmende Tierhalter selbständig zu erfassen bzw.
        Stornierungen bei Fehleingaben ebenfalls direkt im Online-Portal durchzuführen. Der Teilnehmer prüft vorher
        sorgfältig im Online-Portal, ob die zu erfassende Bewegungsdaten nicht bereits im System vorhanden ist und trägt
        die alleinige Verantwortung für eine eventuell fehlerhafte Eingabe.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="2">
        <li>Benutzername, Service PIN, Passwort und MFA</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Der Teilnehmer loggt sich mit Hilfe eines persönlichen Benutzernamens, Passworts sowie eines zweiten Faktors in
        seinen geschützten Datenbereich ein. Den Benutzernamen sowie einen persönlichen Erstanmeldungs-Link erhält der
        Teilnehmer per E-Mail nach erfolgreicher Prüfung durch BFS. Um eine größtmögliche Sicherheit zu gewähren, ist
        direkt im Rahmen der Erstanmeldung und danach (gemäß der BFS finance GmbH-Passwort-Richtlinie) aktuell jährlich
        eine Änderung des Passworts durch den Teilnehmer erforderlich. Der Teilnehmer wird hierzu beim Einloggen
        automatisch vom System aufgefordert.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Zusätzlich zum Passwort wird zum Schutz des Accounts in der Ersteinrichtung außerdem ein so genannter ‚zweiter
        Faktor‘ für die Multi-Faktor-Authentifizierung (MFA) konfiguriert. Der durch MFA generierte Tokencode wird bei
        der Anmeldung an das Online-Portal nach dem Passwort eingegeben.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Im Rahmen der Erstanmeldung vergibt der Teilnehmer außerdem einen persönlichen Service PIN, über den BFS den
        Teilnehmer bei Kontaktaufnahme per z.B. E-Mail oder Telefon identifizieren kann. Dieser Service PIN kann durch
        den Teilnehmer jederzeit im persönlichen Profil des Online-Portals geändert werden.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >Die gemeinsame Nutzung eines Zugangs durch mehrere Personen ist untersagt. Im Falle eines Missbrauchs lehnt BFS
        jegliche Garantie der Datenübernahme bzw. deren Richtigkeit ab.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="3">
        <li>Haftung</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >1) Der Teilnehmer ist dafür verantwortlich, dass das zum User gehörende Passwort, der im Rahmen der MFA
        generierte Tokencode sowie die persönliche Service PIN keinem Dritten zugänglich ist. Sie sind geheim zu halten.
        Der User übernimmt die volle Verantwortung für sämtliche Handlungen, die unter Verwendung seines Usernamens in
        Verbindung mit seinem Passwort vorgenommen werden. BFS schließt für schuldhaft verursachte Schäden aus einer
        unberechtigten bzw. unkorrekten Verwendung passwortgeschützter Logins des Users jegliche Haftung aus.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >2) Der Teilnehmer verpflichtet sich, BFS unverzüglich jede missbräuchliche Benutzung seines Users mitzuteilen,
        sobald ihm diese bekannt geworden ist. Im Falle einer missbräuchlichen Benutzung ändert der User sofort sein
        Passwort sowie seine persönliche Service PIN.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >3) Im Falle eines Verstoßes gegen diese Nutzungsbedingungen behält sich BFS vor, dem User den Zugang zum
        Online-Portal zu verweigern.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="4">
        <li>Salvatorische Klausel</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Sollten Vereinbarungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, so wird die
        Wirksamkeit der Nutzungsbedingungen im Übrigen hiervon nicht berührt. Das gilt insbesondere, wenn die
        Unwirksamkeit sich nur auf einzelne Bestandteile bezieht. An die Stelle der unwirksamen Bestimmung tritt
        diejenige Regelung, die dem beabsichtigten wirtschaftlichen Zweck in rechtswirksamer Weise am nächsten kommt.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="5">
        <li>Sonstiges</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Soweit nicht durch vorliegende Nutzungsbedingungen ausdrücklich abgeändert, bleiben die übrigen vertraglichen
        Vereinbarungen der Parteien hiervon unberührt.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="6">
        <li>Gerichtsstand</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Erfüllungsort und Gerichtsstand aller aus dieser Vereinbarung sich ergebenden Streitigkeiten ist, soweit der
        Beteiligte Vollkaufmann ist, Gütersloh.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span>BFS finance GmbH </span>
    </div>
    <div class="row text-container">
      <span>Gütersloher Str. 123 </span>
    </div>
    <div class="row text-container">
      <span>33415 Verl </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="7">
        <li>Contact details</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span>BFS finance GmbH </span>
    </div>
    <div class="row text-container">
      <span>Carl-Bertelsmann Str. 23 </span>
    </div>
    <div class="row text-container">
      <span>33332 Gütersloh </span>
    </div>
    <div class="row text-container">
      <span>Phone: +49 5241 80 - 43333 </span>
    </div>
    <div class="row text-container">
      <span>Fax: +49 5241 80 - 643333 </span>
    </div>
    <div class="row text-container">
      <span>E-Mail: initiative-tierwohl@bertelsmann.de </span>
    </div>
    <div class="row text-container">
      <span>Webseite: bfs-finance.com </span>
    </div>
  </div>
</div>
