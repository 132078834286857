export const userLanguages: any[] = [
  {
    code: 1,
    name: 'en',
    translateName: 'GENERAL.ENGLISH',
  },
  {
    code: 2,
    name: 'de',
    translateName: 'GENERAL.GERMAN',
  },
];
