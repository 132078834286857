export const goodsGroupsColumnsConf: any[] = [
  {
    name: 'nameGerman',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-GERMAN',
    locked: true,
  },
  {
    name: 'nameEnglish',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-ENGLISH',
    locked: true,
  },
  {
    name: 'type',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.GOODS-GROUPS.TYPE',
    locked: true,
  },

  {
    name: 'comment',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.GOODS-GROUPS.COMMENT',
    locked: true,
  },
  {
    version: '1.1',
  },
];
