<div class="filters filters-list" [formGroup]="filterForm">
  <div class="filter-input">
    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-ID'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-ID'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-ID'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.purchaserId"
      [controlName]="'purchaserId'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.purchaserId"
    ></app-mat-input>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-NAME'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-NAME'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-NAME'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.purchaserName"
      [controlName]="'purchaserName'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.purchaserName"
    ></app-mat-input>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-ID'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-ID'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-ID'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.supplierId"
      [controlName]="'supplierId'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.supplierId"
    ></app-mat-input>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-NAME'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-NAME'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-NAME'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.supplierName"
      [controlName]="'supplierName'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.supplierName"
    ></app-mat-input>

    <app-mat-select
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.type"
      [controlName]="'type'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [options]="typeOptions"
      [optionValue]="'name'"
      [translationOption]="'translateName'"
      [optionTranslationText]="''"
      [inline]="true"
    >
    </app-mat-select>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-BY'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-BY'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-BY'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.createdBy"
      [controlName]="'createdBy'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.createdBy"
    ></app-mat-input>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.APPROVED-BY'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.APPROVED-BY'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.APPROVED-BY'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.approvedBy"
      [controlName]="'approvedBy'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.approvedBy"
    ></app-mat-input>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.comment"
      [controlName]="'comment'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
      [removeHtmlTags]="filterForm.controls.comment"
    >
    </app-mat-input>

    <app-mat-select
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.reportingPeriodId"
      [controlName]="'reportingPeriodId'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [options]="existentReportingPeriods"
      [optionValue]="'id'"
      [translationOption]="'id'"
      [optionTranslationText]="''"
      [inline]="true"
      [customOptionNameFunction]="getPeriodDate"
    ></app-mat-select>

    <div class="form-row inline-display" [formGroup]="filterForm">
      <mat-form-field appearance="fill" id="{{ entityName }}-form-goodsGroup">
        <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.GOODS-GROUP' | translate }}</mat-label>
        <mat-select formControlName="goodsGroupId" id="{{ entityName }}-form-goodsGroup-select" multiple>
          <mat-optgroup *ngFor="let group of goodsGroupsOptionsLocal" [label]="group.translateName | translate">
            <mat-option *ngFor="let option of group.options" [value]="option.id">
              <span>
                {{ getGoodsGroupName(option.id) }}
              </span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          id="{{ entityName }}-form-goods-groups-id-clear"
          (click)="clearGoodsGroupSearch(); $event.stopPropagation()"
          *ngIf="filterForm.controls.goodsGroupId.value != null"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-ON'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-ON'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-ON'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.createdOn"
      [controlName]="'createdOn'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
    ></app-mat-input-date>

    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
      [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.approvedOn"
      [controlName]="'approvedOn'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
    ></app-mat-input-date>
    <div class="search-container">
      <button mat-button class="custom-button search-button" (click)="loadTable()" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
      </button>
    </div>
    <div class="inline-display"></div>
  </div>
  <div class="excel">
    <button mat-button class="custom-button" (click)="downloadXslxFile()">Excel</button>
  </div>
  <div class="filter-input button-container-end" *hasPermission="'aw.lg.c'">
    <button (click)="goToEditView()" mat-button class="custom-button" id="{{ entityName }}-create-button">
      <span>
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATE' | translate }}
      </span>
    </button>
  </div>
</div>

<app-default-table
  [entityName]="entityName"
  [configurationKey]="configurationKey"
  [columns]="columns"
  [displayedColumns]="displayedColumns"
  [originalColumns]="columns"
  [originalDisplayedColumns]="displayedColumns"
  [tableDataSource]="originalData"
  [totalCount]="totalCount"
  (filterEvent)="filter($event)"
  [threeDots]="true"
  [listOfActionButtons]="listOfActionButtons"
  [enableFilter]="false"
></app-default-table>

<div class="filters checksum-container" [formGroup]="checkSumForm">
  <div class="filter-input">
    <app-mat-input
      style="margin-left: 40px"
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [entityName]="entityName"
      [form]="checkSumForm"
      [controlObject]="checkSumForm.controls.checksum"
      [controlName]="'checksum'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
    >
    </app-mat-input>
  </div>
</div>

<ng-container *hasPermission="'aw.lg.r.d'">
  <app-quantity-labeled-goods-draft></app-quantity-labeled-goods-draft>
</ng-container>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button
      *hasPermission="'aw.lg.u'"
      mat-menu-item
      (click)="editSelectedRows()"
      id="{{ entityName }}-table-results-action-menu-edit-button"
    >
      <img class="icon-menu" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
      <span translate>GENERAL.EDIT</span>
    </button>
    <button
      *hasPermission="'aw.lg.d'"
      mat-menu-item
      (click)="cancelElement()"
      id="{{ entityName }}-table-results-action-menu-delete-button"
    >
      <span class="material-icons icon-menu"> delete_outline </span>
      <span translate>ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REMOVE</span>
    </button>
  </ng-template>
</mat-menu>
