export const amountPercentOptions: any[] = [
  {
    code: 1,
    name: 'percent',
    translateName: 'TRANSACTION.DEDUCTION_IN_PERCENT',
    codeString: 'DEDUCTION_IN_PERCENT',
  },
  {
    code: 2,
    name: 'amount',
    translateName: 'TRANSACTION.DEDUCTION_AMOUNT',
    codeString: 'DEDUCTION_AMOUNT',
  },
];
