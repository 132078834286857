<app-back-button *ngIf="!isTask" [address]="'userManagement/role'"> </app-back-button>
<app-back-button *ngIf="isTask"> </app-back-button>

<div *ngIf="editMode || isTask" class="tab-container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.ROLE-CONFIGURATION' | translate }}
        </div>
      </ng-template>
      <app-role-configuration [realm]="realm"></app-role-configuration>
    </mat-tab>
    <mat-tab *ngIf="!isTask">
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.ROLE-KNOWN-USER' | translate }}
        </div>
      </ng-template>
      <app-role-known-user *ngIf="!isTask || approvedRole"></app-role-known-user>
    </mat-tab>
  </mat-tab-group>
  <div class="save-container">
    <span *ngIf="RoleConfigurationComponent">
      <button
        (click)="saveRole()"
        *ngIf="editMode"
        [disabled]="RoleConfigurationComponent.frmRole.invalid || state.viewMode"
        class="custom-button save"
        mat-button
      >
        {{ 'GENERAL.SAVE' | translate }}
      </button>
    </span>
  </div>
</div>

<div *ngIf="!editMode" class="stepper-container">
  <app-role-configuration *ngIf="realm" [realm]="realm" [roleType]="realm"></app-role-configuration>
</div>
