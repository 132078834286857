import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessUnitService } from '../../../shared/services/business-unit/business-unit.service';
import { ActivatedRoute } from '@angular/router';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';

@Component({
  selector: 'app-company-general-information',
  templateUrl: './company-general-information.component.html',
  styleUrls: ['./company-general-information.component.less'],
})
export class CompanyGeneralInformationComponent implements OnInit {
  @Input() companyApproved: any;
  @Input() companyTask: any;
  @Input() companyInitial: any;
  @Input() isDraftExists: boolean;
  companyForm: UntypedFormGroup;
  entityName = 'company-general-information';
  errorRequired = 'error-message-required';
  countries$: Observable<ICountry[]>;
  countries: ICountry[] = [];
  filteredCountries: Observable<any[]>;
  selectedCountryCode: any;
  languages: any[];
  countryCode: any;
  editableTask: boolean;
  isTask = false;
  companyValue: any;
  animalWelfareEditView = false;
  state;
  snapshot;
  loaderSpinner = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected countryPicker: CountryPickerService,
    private taskService: TaskService,
    private translateService: TranslateService,
    private businessUnitService: BusinessUnitService,
    private activatedRoute: ActivatedRoute
  ) {
    this.companyFormBuilder();
    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );
  }

  ngOnInit(): void {
    this.setCountriesRules();
    this.loadCountriesAndInitComponent();
  }

  companyFormBuilder() {
    this.companyForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      language: [null],
      draft: [true],
      addressId: [''],
      address: this.formBuilder.group({
        street: ['', Validators.required],
        number: ['', Validators.required],
        city: ['', Validators.required],
        zipCode: ['', Validators.required],
        country: [null, Validators.required],
        timezone: [''],
        additionalInformation: [''],
      }),
      guid: [''],
      visibleId: [''],
      createdExternally: [{ value: null, disabled: true }],
    });
  }

  loadCountriesAndInitComponent() {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.countries$ = of(countries);
      this.countries = countries;
      this.initView();
    });
  }

  initView() {
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    this.loadData();
    if (this.state.contractType) {
      this.animalWelfareEditView = true;
      this.companyForm.disable();
    }
  }

  loadData() {
    if (this.state.company !== undefined) {
      if (this.state.company.draft) {
        this.patchCompanyFormValuesDraft(this.state.company);
        if (this.state.address && this.state.address.country) {
          this.populateLanguageList(this.state.address.country);
        }
      } else {
        this.patchCompanyFormValues(this.companyApproved);
        this.populateLanguageList(this.companyApproved.address.country);
      }
    } else if (this.state.task) {
      this.isTask = true;
      this.patchCompanyFormValues(this.companyTask);
      this.populateLanguageList(this.companyTask.address.country);

      if (
        this.state.task.type === 'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT'
      ) {
        this.animalWelfareEditView = true;
        this.companyForm.disable();
      }
    } else if (this.snapshot.params.guid) {
      this.patchCompanyFormValues(this.companyApproved);
      this.populateLanguageList(this.companyApproved.address.country);
    } else {
      this.loaderSpinner = false;
    }
    this.checkIfFormHasToBeDisabled();
  }

  setCountriesRules() {
    this.companyForm.valueChanges.subscribe((val) => (this.companyValue = val));
    this.countryPicker
      .getCountries()
      .subscribe((data) => this.setToCountries(data));
    this.companyForm.get('address.country').valueChanges.subscribe((val) => {
      this.countryCode = '';
      this.filteredCountries = this.companyForm
        .get('address.country')
        .valueChanges.pipe(
          startWith(''),
          map((value) => (typeof val === 'string' ? val : val.name)),
          map((name) => (name ? this._filter(val) : this.countries.slice()))
        );

      this.populateLanguageList(val);

      if (this.languages.length > 0) {
        if (this.companyForm.get('language').value == null) {
          this.companyForm.get('language').setValue(this.languages[0].code);
        }
        const ct = require('countries-and-timezones');
        const countryCca2 = this.countries.filter(
          (country) => country.cca3 === val
        )[0].cca2;
        const timezones = ct.getTimezonesForCountry(countryCca2);
        this.companyForm.get('address.timezone').setValue(timezones[0].name);
        this.countryCode = val;
      } else {
        this.companyForm.get('language').setValue(null);
      }
    });
  }

  setToCountries(data: ICountry[]) {
    if (!this.countries) {
      this.countries = data;
    }
    if (this.translateService.currentLang === 'de') {
      data.sort((c1, c2) => {
        if (c1.translations.deu.common > c2.translations.deu.common) {
          return 1;
        }
        if (c1.translations.deu.common < c2.translations.deu.common) {
          return -1;
        }
        return 0;
      });
    } else {
      data.sort((c1, c2) => {
        if (c1.name.common > c2.name.common) {
          return 1;
        }
        if (c1.name.common < c2.name.common) {
          return -1;
        }
        return 0;
      });
    }
    this.countries$ = of(data);
  }

  populateLanguageList(countryCode: string) {
    if (this.countries.length > 0) {
      this.selectedCountryCode = '';
      this.languages = [];
      const country = this.countries.filter(
        (countryFilter) => countryFilter.cca3 === countryCode
      )[0];
      if (!country) {
        return;
      }
      const keys = Object.keys(country.languages);
      let german = false;

      for (const key of keys) {
        const obj = { code: key.toUpperCase() };
        if (obj.code === 'DEU') {
          german = true;
          this.languages.push({ code: 'DEU' });
        }
      }

      this.languages.push({ code: 'ENG' });
      if (!german) {
        this.languages.push({ code: 'DEU' });
      }

      this.selectedCountryCode = this.languages[0].code;
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.countries.filter(
      (option) =>
        this.getCountryName(option.cca3).toLowerCase().indexOf(filterValue) ===
        0
    );
  }

  displayFn(value: any) {
    let country;
    if (value !== null && value !== '') {
      country = this.getCountryName(value);
    }

    return country;
  }

  getCountryName(code: string) {
    const country = this.countries.filter(
      (countryFilter) => countryFilter.cca3 === code
    )[0];
    if (this.translateService.currentLang === 'de') {
      return country.translations.deu.common;
    }
    return country.name.common;
  }

  searchCountry = (term: string, item: ICountry) => {
    term = term.toLocaleLowerCase();
    if (this.translateService.currentLang === 'de') {
      return (
        item.translations.deu.official.toLocaleLowerCase().indexOf(term) > -1
      );
    }
    return item.name.official.toLocaleLowerCase().indexOf(term) > -1;
  };

  hasChangedAddress(fieldName: string) {
    if (this.companyApproved && this.companyValue) {
      return (
        this.companyApproved.address[fieldName] !==
        this.companyValue.address[fieldName]
      );
    } else {
      if (this.companyInitial && this.companyValue) {
        return (
          this.companyInitial &&
          this.companyInitial.address[fieldName] !==
            this.companyValue.address[fieldName]
        );
      }
    }
  }

  private patchCompanyFormValues(company: any) {
    this.companyForm.patchValue(company);
    this.companyForm.markAsPristine();
    this.companyForm.addControl(
      'taskId',
      new UntypedFormControl(company.approvalTaskId, Validators.required)
    );
    this.loaderSpinner = false;
  }

  checkIfFormHasToBeDisabled() {
    if (this.isDraftExists || this.isEditDeniedDueToOrigin()) {
      this.companyForm.disable();
      this.companyForm.setErrors(null);
      this.companyForm.updateValueAndValidity();
    }
  }

  private patchCompanyFormValuesDraft(company: any) {
    this.companyForm.patchValue(company);
    this.companyForm.markAsPristine();
    this.loaderSpinner = false;
  }

  getApprovedValue(fieldName, formGroup?) {
    if (this.companyApproved) {
      if (formGroup) {
        return this.companyApproved[formGroup][fieldName]
          ? this.companyApproved[formGroup][fieldName]
          : null;
      } else {
        return this.companyApproved[fieldName]
          ? this.companyApproved[fieldName]
          : null;
      }
    } else if (this.companyInitial) {
      if (formGroup) {
        return this.companyInitial[formGroup][fieldName]
          ? this.companyInitial[formGroup][fieldName]
          : null;
      } else {
        return this.companyInitial[fieldName]
          ? this.companyInitial[fieldName]
          : null;
      }
    }
    return null;
  }

  isEditDeniedDueToOrigin() {
    if (this.companyApproved && this.companyApproved.createdExternally) {
      return (
        this.companyApproved.createdExternally &&
        !this.businessUnitService.getExternallyCreatedRecordEditability()
      );
    }
    return false;
  }
}
