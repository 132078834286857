import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DialogData } from 'src/app/shared/models/dialog-data';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client/client.service';
import { clientColumns } from 'src/app/in-memory-data/debtor/linked-clients/table-columns';
import { SelectionModel } from '@angular/cdk/collections';
import { SortService } from 'src/app/shared/services/sort/sort-service';

@Component({
  selector: 'app-client-selection-modal',
  templateUrl: './client-selection-modal.component.html',
  styleUrls: ['./client-selection-modal.component.less'],
})
export class ClientSelectionModalComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  filterForm: UntypedFormGroup;
  searchInput: any = '';
  clientList: Client[];
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<Client>();
  displayedColumns = [];

  data: any;
  entityName = 'clientSelectionModal';

  noClients = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientSelectionModalComponent>,
    private sortService: SortService
  ) {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
    this.filterForm.valueChanges.subscribe(
      (filters: { searchString: string }) => {
        const searchString = filters.searchString.trim().toUpperCase();

        const filteredList: Client[] = this.clientList.filter(
          (client) =>
            ('' + client.visibleId).indexOf(searchString) >= 0 ||
            client.companyName.toUpperCase().indexOf(searchString) >= 0 ||
            client.platformName.toUpperCase().indexOf(searchString) >= 0
        );
        this.dataSource.data = filteredList;
      }
    );
    this.data = {
      client: null,
      clients: [],
    };
  }

  ngOnInit() {
    if (!this.dialogData.debtor) {
      if (this.dialogData && this.dialogData.multiple) {
        this.selection = new SelectionModel(true, []);
      } else {
        this.selection = new SelectionModel(false, []);
      }
      this.displayedColumns.push('select');
    }

    this.displayedColumns = this.displayedColumns.concat(clientColumns);
    this.loadClientLists();
  }

  private loadClientLists() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id':
          return null;
        case 'centralMasterDataRecord':
          return item.companyName;
        case 'platform':
          return item.platformName;
        default:
          return item[property];
      }
    };
    // this.clientService.listApproved().subscribe((clients) => {
    //   clients.sort((a, b) => (a.id > b.id ? 1 : -1));

    //   this.clientList = this.dataSource.data = clients;
    // });

    if (this.dialogData && this.dialogData.platform) {
      this.clientService
        .retrieveByPlatform(this.dialogData.platform.id)
        .subscribe((clients) => this.sortClientList(clients));
    } else {
      this.clientService
        .listApproved(null, 'id', 'asc', 1, 99999)
        .subscribe((clients) => this.sortClientList(clients));
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();

    if (!sort.active || sort.direction === '') {
      this.dataSource.data = this.clientList;
      return;
    }

    if (sort.active === 'id') {
      let result;
      const splitItems = 2;
      result = this.sortService.sortByVisibleId(data, sort, splitItems);
      this.dataSource.data = result;
    }
  }

  trclicked(element) {
    if (this.dialogData.debtor) {
      this.data.client = element;
      this.dialogRef.close(this.data);
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.data.clients = this.selection.selected;
    } else {
      this.dataSource.data.forEach((row) => {
        this.selectClient(row, this.selection.isSelected(row), true);
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectClient(row, isSelected, flag?) {
    if (flag) {
      this.selection.select(row);
    } else {
      this.selection.toggle(row);
    }

    if (this.dialogData && this.dialogData.multiple) {
      this.data.clients = this.selection.selected;
    } else {
      this.data.client = row;
    }
  }

  private sortClientList(clients) {
    if (clients?.content?.length > 0) {
      clients.content.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.clientList = this.dataSource.data = clients.content;
    } else {
      this.noClients = true;
    }
  }
}
