import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class LivestockProducerService {
  mapOfColumnFilters = [];
  private searchString = '';
  private sortBy = 'production_type';
  private sortDirection = 'ASC';
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private readonly searchUrl: string;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.participantService;
    this.taskServiceUrl = environment.settings.taskService;
    this.searchUrl = environment.settings.searchAPIUrl;
  }

  setSearchString(searchString) {
    this.searchString = searchString;
  }

  getSearchString() {
    return this.searchString;
  }

  setSorting(choice: any, direction: any) {
    this.sortBy = choice;
    this.sortDirection = direction;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getAllLivestockProducers(
    pageIndex,
    pageSize,
    locationNumber?,
    typeOfProduction?
  ): Observable<any> {
    const config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('pageSize', pageSize)
      .append('pageIndex', pageIndex)
      .append('sortField', this.sortBy)
      .append('sortDirection', this.sortDirection);

    if (locationNumber) {
      config['params'] = config['params'].append(
        'location_number',
        locationNumber
      );
    }
    if (typeOfProduction) {
      config['params'] = config['params'].append(
        'production_type',
        typeOfProduction
      );
    }
    if (this.searchString && this.searchString.length > 0) {
      config['params'] = config['params'].append(
        'filterText',
        this.searchString
      );
    }
    config['params'] = this.addColumnFilters(config['params']);

    return this.http.get(this.searchUrl + 'livestock-producer', config);
  }
  addColumnFilters(params: HttpParams): HttpParams {
    params = params.append(
      'filteredValues',
      JSON.stringify(this.mapOfColumnFilters)
    );
    return params;
  }

  findFilterValues(args: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'livestock-producers/filterValues?fieldName=' + args,
      this.buildHeaders()
    );
  }

  getLivestockProducersForCompany(companyId) {
    return this.http.get(
      this.serviceUrl + 'livestock-producers/list/' + companyId,
      this.buildHeaders()
    );
  }

  getLivestockProducer(id) {
    return this.http.get(
      this.serviceUrl + 'livestock-producers/' + id,
      this.buildHeaders()
    );
  }

  getLivestockProducerSearch(id): Observable<any> {
    return this.http.get(
      `${this.searchUrl}livestock-producer/${id}`,
      this.buildHeaders()
    );
  }

  saveDocumentDispatchLocks(request) {
    return this.http.put(
      this.serviceUrl + 'livestock-producers/save-doc-dispatch-locks',
      request,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: this.getHeaders(),
    };
  }

  exists(vvvoNumber: any, productionType, searchDate) {
    return this.http.get(this.serviceUrl + 'livestock-producers/exists', {
      params: new HttpParams()
        .append('vvvoNumber', vvvoNumber)
        .append('productionType', productionType)
        .append('searchDate', searchDate),
      headers: this.getHeaders(),
    });
  }

  private getHeaders() {
    return new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Accept', 'application/json')
      .append('Business-Unit-Id', localStorage.currentBusinessUnitId);
  }
}
