export const openTransactionColumns: string[] = [
  'select',
  'icons',
  'searchPartner',
  'businessPartner',
  'externalId',
  'clientOpenItemId',
  'openItemDate',
  'transactionCode',
  'debitOrCredit',
  'amount',
  'currency',
  'vatAmount',
  'vatPercent',
  'actions',
];
