export const clientInterfaceRejectionReasons: any[] = [
  {
    name: 'Reason 1',
    code: 0,
    translateName: 'CONFIRMATION-MODAL.REASON-1',
  },
  {
    name: 'Reason 2',
    code: 1,
    translateName: 'CONFIRMATION-MODAL.REASON-2',
  },
  {
    name: 'Reason 3',
    code: 2,
    translateName: 'CONFIRMATION-MODAL.REASON-3',
  },
];
