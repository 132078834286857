import { Component, OnInit, ViewChild } from '@angular/core';
import { debtorPaymentAllocationColumnsConf } from 'src/app/in-memory-data/debtor/payment-allocation/table-columns-configuration';
import { debtorPaymentAllocationColumns } from 'src/app/in-memory-data/debtor/payment-allocation/table-columns';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { TransactionSelectionModalComponent } from 'src/app/shared/modals/transaction-selection-modal/transaction-selection-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { AWPaymentAllocationColumnsConf } from 'src/app/in-memory-data/accounting/animal-welfare/table-columns-animal-welfare-allocation-draft-configuration';
import { AWPaymentAllocationColumns } from 'src/app/in-memory-data/accounting/animal-welfare/table-columns-animal-welfare-allocation-draft';
import { AccountingService } from 'src/app/shared/services/accounting/accounting.service';

@Component({
  selector: 'app-debtor-account-payment-allocation',
  templateUrl: './debtor-account-payment-allocation.component.html',
  styleUrls: ['./debtor-account-payment-allocation.component.less'],
})
export class DebtorAccountPaymentAllocationComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'debtor-account-payment-allocation';
  columns;
  displayedColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel(true, []);
  private CONFIGURATION_KEY: string;
  state: any;
  clientId: any;
  selectedDebtors: any = [];
  allocationInfo: any = {};
  debtor: any;
  selectedTransactions: any = [];
  transactionsDeletedFromDraft = [];
  isDraft;
  dateFormat = '';
  routeParamId;
  routeParamType;
  currentBusinessUnit;
  masterData;
  kpiLoaded = false;

  buttonsAreDisabled = {
    save: false,
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private openItemService: OpenItemService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private draftService: DraftService,
    private customErrorHandler: CustomErrorHandler,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitService,
    private accountingService: AccountingService
  ) {}

  ngOnInit() {
    this.getCurrentBusinessUnitAndSetColumns();
    this.state = window.history.state;
    this.routeParamId = this.route.snapshot.paramMap.get('id');
    this.routeParamType = this.route.snapshot.paramMap.get('type');
    this.loadKPIs();
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    if (this.state.selectedDebtors && this.state.selectedDebtors.length > 0) {
      this.selectedDebtors = this.state.selectedDebtors;
      this.isDraft = this.state.draftGuid !== undefined;
      this.loadData();
    } else {
      this.router.navigateByUrl(
        '/accounting/overview/' + this.routeParamId + '/' + this.routeParamType
      );
    }

    this.getTableConfiguration();
  }

  ngOnChange(changes) {
    if ('columns' in changes) {
      this.columns = changes.columns.currentValue;
    }
    if ('displayedColumns' in changes) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  loadKPIs() {
    this.accountingService
      .getEntityByGuidAndType(this.routeParamType, this.routeParamId)
      .subscribe((data) => {
        this.masterData = data;
        this.masterData['companyName'] = data['name'];
        this.masterData['qsid'] = data['externalId'];
        this.loadData();
        this.kpiLoaded = true;
      });
  }

  getCurrentBusinessUnitAndSetColumns() {
    if (this.businessUnitService.getCurrentBusinessUnit() !== '4') {
      this.columns = debtorPaymentAllocationColumnsConf;
      this.displayedColumns = debtorPaymentAllocationColumns;
      this.CONFIGURATION_KEY = 'debtor_payment_allocation_table';
      this.currentBusinessUnit = 'nonAW';
    } else {
      this.columns = AWPaymentAllocationColumnsConf;
      this.displayedColumns = AWPaymentAllocationColumns;
      this.CONFIGURATION_KEY = 'debtor_payment_allocation_table_AW';
      this.currentBusinessUnit = 'AW';
    }
  }

  loadData() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'creditDebitCode':
          return item.creditDebitCode;
        case 'documentationId':
          return item.clientOpenItemId;
        case 'documentationDate':
          return item.openItemDate;
        case 'transactionCode':
          return item.bookingCode;
        case 'financed':
          return item.financedAmount;
        case 'nonFinanced':
          return item.nonFinancedAmount;
        case 'transactionCodeName':
          return item.bookingCodeName;
        case 'allocationAmont':
          return item.allocatedAmount;
        case 'objectionAmount':
          return item.objection.amount;
        case 'objectionReason':
          return item.objection.reason;
        case 'objectionDate':
          return item.objection.date;
        case 'transactionId':
          return item.id;
        case 'clientId':
          return item.clientVisibleId;
        default:
          return item[property];
      }
    };

    if (this.masterData !== undefined) {
      this.formatDataAndAddToTable();
    }
  }

  formatDataAndAddToTable() {
    const selectedDebtorsWithSameCurrency: any = [];
    this.selectedDebtors.forEach((element) => {
      element.client = this.masterData.clientName;
      element.externalDebtorId = this.masterData.externalId;
      element.internalDebtorId = this.masterData.visibleId;
      element.clientVisibleId = this.masterData.clientId;
      if (element.currencyCode === this.getCurrency()) {
        selectedDebtorsWithSameCurrency.push(element);
      }
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = selectedDebtorsWithSameCurrency;
    this.dataSource.filter = '';
  }

  getToday() {
    return new Date();
  }

  getNumberTransactions() {
    if (this.selectedDebtors) {
      return this.selectedDebtors.length;
    } else {
      return 0;
    }
  }

  getAllocationBallance() {
    let result = 0;
    this.selectedDebtors.forEach((item) => {
      if (item.creditDebitCode === 'D') {
        result += item.amount;
      } else if (item.creditDebitCode === 'C') {
        result -= item.amount;
      }
    });

    return result;
  }

  getCurrency() {
    let currency = '';
    if (this.selectedDebtors.length > 0) {
      currency = this.selectedDebtors[0].currencyCode;
    }

    return currency;
  }

  getSmallestAmount() {
    let result = Number.MIN_VALUE;
    this.selectedDebtors.forEach((item) => {
      if (result < item.amount) {
        result = item.amount;
      }
    });
    return result;
  }

  confirmAllocation() {
    if (this.getAllocationBallance() === 0) {
      this.allocateData();
    } else {
      this.openConfirmModal();
    }
  }

  openConfirmModal() {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.START-ALLOCATION';

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.allocateData();
      }
    });
  }

  allocateData() {
    this.buttonsAreDisabled.save = true;
    const transactionIds = [];

    this.dataSource.data.forEach((element) => {
      transactionIds.push(element.id);
    });

    const request = {
      transactionIds,
      clientId: this.clientId,
    };

    this.openItemService.getPaymentAllocationInformation(request).subscribe(
      (data) => {
        if (data == null) {
          this.notificationService.showToast(
            'NOTIFICATION.SUCCESFULL-DEBTOR-ALLOCATION',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );

          this.redirectAfterTimeout(3000);
        }
      },
      (error) => {
        this.buttonsAreDisabled.save = false;
        console.log(error);
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selected) {
    if (!selected) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                debtorPaymentAllocationColumnsConf,
                debtorPaymentAllocationColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };

    this.userService.triggerInsertConfiguration(configuration);
  }

  removeTransaction() {
    for (let i = this.selectedDebtors.length - 1; i >= 0; i--) {
      const item = this.selection.selected.find(
        (data) => data.id === this.selectedDebtors[i].id
      );

      if (item != null) {
        this.selectedDebtors.splice(i, 1);
        if (window.history.state.draftGuid) {
          this.transactionsDeletedFromDraft.push(item.id);
        }
      }
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.selectedDebtors;
    this.masterToggle(false);

    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'NOTIFICATION.NO-ALLOCATION-ITEMS-SELECTED',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  selectRow(element) {
    this.selection.select(element);
  }

  openTransactionSelection() {
    let dialog;
    if (this.businessUnitService.getCurrentBusinessUnit() !== '4') {
      dialog = this.matDialogService.open(TransactionSelectionModalComponent, {
        panelClass: 'confirmation-popup',
        data: {
          selectedDebtors: this.dataSource.data,
          debtor: this.masterData,
        },
      });
    } else {
      dialog = this.matDialogService.open(TransactionSelectionModalComponent, {
        panelClass: 'confirmation-popup',
        data: {
          selectedDebtors: this.dataSource.data,
          masterData: this.masterData,
        },
      });
    }
    dialog.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        result.forEach((element) => {
          this.selectedDebtors.push(element);
        });

        this.masterToggle(false);
        this.formatDataAndAddToTable();
      }
    });
  }

  saveAsDraft() {
    const draftGuid = window.history.state.draftGuid;
    const element = {
      transactions: this.selectedDebtors,
    };
    const request = {
      element,
      entityType: 'ALLOCATION_PAYMENT',
      id: draftGuid,
      entityGuid: this.routeParamId,
    };
    const transactionIds = element.transactions.map(
      (transaction) => transaction.id
    );

    this.draftService.saveUserDraft(request).subscribe(
      (_) => {
        const requestTemp = {
          ids: transactionIds,
          allocationDraft: true,
        };
        this.openItemService
          .updateAllocationDraftForItems(requestTemp)
          .subscribe();
        if (draftGuid) {
          if (this.transactionsDeletedFromDraft.length > 0) {
            const deleteRequest = {
              ids: this.transactionsDeletedFromDraft,
              allocationDraft: false,
            };
            this.openItemService
              .updateAllocationDraftForItems(deleteRequest)
              .subscribe();
          }
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            this.debtor
          );
        } else {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            this.debtor
          );
        }
        this.redirectAfterTimeout(1000);
      },
      (error) => {
        if (error.error) {
          this.customErrorHandler.handleError(error.error, this.debtor);
        }
      }
    );
  }

  deleteDraft() {
    const draftGuid = window.history.state.draftGuid;
    const modal = this.matDialogService.open(ConfirmationPromptModalComponent);
    modal.componentInstance.messageKey = 'notification.CONFIRM-DELETE-DRAFT';
    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.draftService.deleteUserDraft(draftGuid).subscribe(() => {
          const transactionIds = this.selectedDebtors.map(
            (transaction) => transaction.id
          );
          const request = {
            ids: transactionIds,
            allocationDraft: false,
          };
          this.openItemService.updateAllocationDraftForItems(request).subscribe(
            (value) => {
              this.notificationService.showToast(
                'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                value
              );
              this.redirectAfterTimeout(1000);
            },
            (error) =>
              this.customErrorHandler.handleError(
                error,
                'TRANSACTION-OVERVIEW.ACTION.ALLOCATION'
              )
          );
        });
      }
    });
  }

  getDraftExpirationTime(): Date {
    let expirationTime;
    if (window.history.state.operation === 'CREATE') {
      expirationTime = new Date(history.state.createdOn);
    } else if (window.history.state.operation === 'UPDATE') {
      expirationTime = new Date(window.history.state.operationDate);
    }
    expirationTime.setDate(expirationTime.getDate() + 7);
    return expirationTime;
  }

  isTwoDaysLeftTillExpiration() {
    const now = new Date();
    const expirationTime = this.getDraftExpirationTime();
    const daysLeft =
      (expirationTime.getTime() - now.getTime()) / (1000 * 3600 * 24);
    if (daysLeft <= 2) {
      return true;
    } else {
      return false;
    }
  }

  private redirectAfterTimeout(timeout?: number) {
    setTimeout(
      () => {
        this.router.navigateByUrl(
          '/accounting/overview/' +
            this.routeParamId +
            '/' +
            this.routeParamType,
          {
            state: { success: true },
          }
        );
      },
      timeout ? timeout : 3000
    );
  }
}
