export const statusEnum: any[] = [
  {
    name: 'null',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.ALL',
  },
  {
    name: 'APPROVED',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED',
  },
  {
    name: 'CANCELED',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CANCELED',
  },
];
