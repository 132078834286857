<div class="content-container">
  <div class="nav-buttons">
    <app-back-button></app-back-button>
    <span *ngIf="currentTask" class="control-buttons">
      <button mat-button class="custom-button" (click)="complete()">
        {{ 'riskmanagement.tasks.complete' | translate }}
      </button>
    </span>
  </div>
  <app-risk-management-details></app-risk-management-details>
</div>
