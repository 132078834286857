import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenTransactionBookingManagementComponent } from './open-transaction-booking-management/open-transaction-booking-management.component';
import { OpenTransactionBookingKpiOverviewComponent } from './open-transaction-booking-kpi-overview/open-transaction-booking-kpi-overview.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { OpenTransactionBookingSearchPartnerComponent } from './open-transaction-booking-search-partner/open-transaction-booking-search-partner.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { OpenTransactionBookingTableComponent } from './open-transaction-booking-table/open-transaction-booking-table.component';
import { OpenTransactionBookingFunctionalButtonsComponent } from './open-transaction-booking-functional-buttons/open-transaction-booking-functional-buttons.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    OpenTransactionBookingManagementComponent,
    OpenTransactionBookingKpiOverviewComponent,
    OpenTransactionBookingSearchPartnerComponent,
    OpenTransactionBookingTableComponent,
    OpenTransactionBookingFunctionalButtonsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    NgSelectModule,
    BackButtonModule,
    MatTabsModule,
    MatDatepickerModule,
    DragDropModule,
    SharedModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    FormElementsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatExpansionModule,
    MatStepperModule,
    FormElementsModule,
    InfiniteScrollModule,
    MatListModule,
    NgxMatSelectSearchModule,
  ],
})
export class OpenTransactionBookingManagementModule {}
