import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'src/app/shared/services/message/message.service';

import { Subject } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms')),
      transition('expanded <=> void', animate('150ms')),
    ]),
  ],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  unreadMessages = 0;
  filter: UntypedFormGroup;

  subject = new Subject();

  user: any;

  constructor(
    private messageService: MessageService,
    private keycloakService: KeycloakService
  ) {}

  ngAfterViewInit(): void {
    this.geyKeycloakUser();
  }

  ngOnInit(): void {
    this.getMessages();
  }

  /**
   * TODO
   * A loop to check ALL possible messagens if is read or not is not a good pratice.
   * Should be implemented a new enpoint to return a count of unread messages and changed here.
   */
  getMessages() {
    this.messageService.list(99999, 1).then((messageList) => {
      messageList.forEach((message) => {
        if (!message['markedAsRead']) {
          this.unreadMessages++;
        }
      });
    });
  }

  geyKeycloakUser() {
    this.user = this.keycloakService.getKeycloakInstance().profile;
  }
}
