import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { allocationDraftColumnsAW } from 'src/app/in-memory-data/animal-welfare/slaughterhouse-drafts/allocation-draft-table-columns';
import { allocationDraftColumnsConfAW } from 'src/app/in-memory-data/animal-welfare/slaughterhouse-drafts/allocation-draft-table-columns-configuration';
import { allocationDraftColumns } from 'src/app/in-memory-data/debtor/account/allocation-draft-table-columns';
import { allocationDraftColumnsConf } from 'src/app/in-memory-data/debtor/account/allocation-draft-table-columns-configuration';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { AccountingSharedDataService } from 'src/app/shared/services/accounting/accounting-shared-data.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';

@Component({
  selector: 'app-accounting-allocation-draft',
  templateUrl: './accounting-allocation-draft.component.html',
  styleUrls: ['./accounting-allocation-draft.component.less'],
})
export class AccountingAllocationDraftComponent implements OnInit {
  entityName = 'accounting-allocation-draft';
  configurationKey = 'accounting_allocation_draft_table';
  columns;
  displayedColumns;
  loaderSpinner = true;
  hasThreeDots = true;
  dataLoaded = false;
  state;
  routeParamId;
  routeParamType;
  allocationDrafts = [];
  allocationDraftData: any = [];
  listOfActionButtons: TableActionButtonsConfiguration[] = [
    {
      name: 'edit',
      permission: null,
      icon: 'edit',
      translationKey: 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT',
      function: (param) => {
        this.editDraft(param);
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return false;
      },
    },
    {
      name: 'delete',
      permission: null,
      icon: 'delete_outline',
      translationKey: 'GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT',
      function: (param) => {
        this.deleteDraft(param);
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return false;
      },
    },
  ];

  constructor(
    private businessUnitService: BusinessUnitService,
    private draftService: DraftService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialogService: MatDialog,
    private openItemService: OpenItemService,
    private customErrorHandler: CustomErrorHandler,
    private notificationService: NotificationService,
    private accountingSharedDataService: AccountingSharedDataService,
    private tablesService: TablesService
  ) {}

  ngOnInit() {
    this.state = window.history.state;
    this.routeParamId = this.route.snapshot.paramMap.get('id');
    this.routeParamType = this.route.snapshot.paramMap.get('type');
    this.getCurrentBusinessUnitAndColumns();
    this.loadData();
  }

  loadData() {
    this.loaderSpinner = false;
    this.dataLoaded = false;
    this.loadDrafts();
  }

  loadDrafts() {
    const entity = 'ALLOCATION_PAYMENT';
    this.dataLoaded = false;
    this.draftService
      .retrieveDraftsByEntityIdAndType(this.routeParamId, entity)
      .subscribe((data) => {
        if (data) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);
            draft.numberTransactions = object.transactions.length;
            let result = 0;
            object.transactions.forEach((transaction) => {
              if (transaction.debitOrCredit === 1) {
                result += transaction.amount;
              } else if (transaction.debitOrCredit === 2) {
                result -= transaction.amount;
              }
            });
            draft.balance = result;
            draft.currency = object.transactions[0].currency;

            const transactionDraftArray = object.transactions.map(
              this.addDraftGuid(draft.guid, object.transactions)
            );
            transactionDraftArray.forEach((element) => {
              this.allocationDrafts.push(element);
            });
          });
          this.allocationDraftData.content = data;
          this.dataLoaded = true;
        }
      });
  }

  addDraftGuid(draftGuid, transactions) {
    return (entry) => {
      return {
        transactionId: entry.id,
        draftId: draftGuid,
        transactions,
      };
    };
  }

  getCurrentBusinessUnitAndColumns() {
    if (this.businessUnitService.getCurrentBusinessUnit() !== '4') {
      this.columns = allocationDraftColumnsConf;
      this.displayedColumns = allocationDraftColumns;
    } else {
      this.columns = allocationDraftColumnsConfAW;
      this.displayedColumns = allocationDraftColumnsAW;
    }
  }

  editDraft(param) {
    const draftJson = JSON.parse(param.draftJson);
    this.router.navigateByUrl(
      '/accounting/overview/' +
        this.routeParamId +
        '/' +
        this.routeParamType +
        '/paymentAllocation',
      {
        state: {
          success: true,
          selectedDebtors: draftJson.transactions,
          draftGuid: param.guid,
          operation: param.operation,
          createdOn: param.createdOn,
          operationDate: param.operationDate,
        },
      }
    );
  }

  deleteDraft(param) {
    const modal = this.matDialogService.open(ConfirmationPromptModalComponent);
    modal.componentInstance.messageKey = 'NOTIFICATION.CONFIRM-DELETE-DRAFT';
    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.draftService.deleteUserDraft(param.guid).subscribe((_) => {
          const draftJson = JSON.parse(param.draftJson);
          const transactionIds = draftJson.transactions.map(
            (transaction) => transaction.id
          );
          const request = {
            ids: transactionIds,
            allocationDraft: false,
          };
          this.openItemService.updateAllocationDraftForItems(request).subscribe(
            () => {
              this.notificationService.showToast(
                'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
                this.notificationService.MESSAGE_TYPE.SUCCESS
              );
              this.tablesService.updateSharedSelectedRows(null);
              this.callEmitFunction('deletePaymentAllocationDraft');
              this.loadDrafts();
            },
            (error) => {
              this.customErrorHandler.handleError(
                error,
                'TRANSACTION-OVERVIEW.ACTION.ALLOCATION'
              );
              this.tablesService.updateSharedSelectedRows(null);
            }
          );
        });
      } else {
        this.tablesService.updateSharedSelectedRows(null);
      }
    });
  }

  callEmitFunction(functionName) {
    this.accountingSharedDataService.emitFunction(functionName);
  }

  hasDrafts() {
    return (
      this.allocationDraftData.content &&
      this.allocationDraftData.content.length > 0
    );
  }
}
