import { Component, Input } from '@angular/core';
import { productionOptions } from '../../../../../in-memory-data/animal-welfare/livestock-producer-information-box/filterProductionOptions';

@Component({
  selector: 'app-livestock-information-box',
  templateUrl: './livestock-information-box.component.html',
  styleUrls: ['./livestock-information-box.component.less'],
})
export class LivestockInformationBoxComponent {
  @Input() livestockProducer;

  entityName = 'livestock-producer-overview';
  productionTypes = productionOptions;

  getProductionTypeName(productionType) {
    return this.productionTypes.find(
      (item) => item.value === productionType.toString()
    ).translateName;
  }
}
