import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Router } from '@angular/router';
import { translationListColumns } from 'src/app/in-memory-data/system-configuration/translation-list/table-columns';
import { translationListColumnsConf } from 'src/app/in-memory-data/system-configuration/translation-list/table-columns-configuration';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-translation-list',
  templateUrl: './translation-list.component.html',
  styleUrls: ['./translation-list.component.less'],
})
export class TranslationListComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly originalColumns = Object.assign({}, translationListColumnsConf);
  readonly originalDisplayedColumns = Object.assign({}, translationListColumns);
  columns = translationListColumnsConf;
  displayedColumns = translationListColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  entityName = 'app-translation-list';
  private CONFIGURAIONT_KEY = 'translationsData_table';
  translationsData = [];
  filteredKeys: any[] = [];
  dataSource = new MatTableDataSource<any>(this.translationsData);
  searchInput = '';
  editMode: any;
  filter;
  loaderSpinner = true;
  state;
  subject = new Subject();
  totalCount = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private cdref: ChangeDetectorRef
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  ngOnInit() {
    this.state = window.history.state;
    this.loadLanguages();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.translationService
        .filterKeys()
        .subscribe((data) => this.updateData(data));
    });
  }

  ngAfterViewInit() {
    this.translationService.setPaginator(
      this.paginator.pageSize,
      this.paginator.pageIndex
    );
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  paginate(event) {
    this.loaderSpinner = true;
    this.translationService.setPaginator(event.pageSize, event.pageIndex);
    this.translationService
      .filterKeys()
      .subscribe((data) => this.updateData(data));
  }

  getFieldNames = (args): Observable<any> => {
    return this.translationService.findDistinctValues(args);
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.translationService.setColumnFilters(choice, selection);
    this.paginator.pageIndex = 0;
    this.subject.next(undefined);
  };

  searchTranslationList() {
    this.loaderSpinner = true;
    this.translationService.setSearchString(
      this.searchInput.toString().toLowerCase()
    );
    this.subject.next(undefined);
  }

  loadLanguages() {
    this.translationService.getCreatedLanguages().subscribe(
      (data) => {
        if (data && data.length > 0) {
          data.forEach((element) => {
            if (element.name !== 'English' && element.name !== 'German') {
              this.filteredKeys.push(element.name.toString().toLowerCase());
            }
          });
          this.getTableConfiguration();
        } else {
          this.getTableConfiguration();
        }
      },
      (error) => {
        this.getTableConfiguration();
        this.loaderSpinner = false;
      }
    );
  }

  loadData() {
    this.translationService.filterKeys().subscribe(
      (data) => {
        if (data && data['content'].length > 0) {
          this.loaderSpinner = false;
          this.dataSource.data = data['content'];
          this.totalCount = data['total'];
          this.dataSource.filter = '';
        } else {
          this.loaderSpinner = false;
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  ngOnDestroy() {
    this.columns = Object.assign({}, this.originalColumns);
    this.displayedColumns = Object.assign({}, this.originalDisplayedColumns);
  }

  edit(element) {
    this.router.navigateByUrl(
      '/systemConfiguration/translationManagement/translationList/edit/' +
        element.guid,
      {
        state: {
          operation: 'update',
          translationValues: element,
          filteredKeys: this.filteredKeys,
        },
      }
    );
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  create() {
    this.router.navigateByUrl(
      '/systemConfiguration/translationManagement/translationList/create',
      { state: { operation: 'create', filteredKeys: this.filteredKeys } }
    );
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  tableChanged(event?) {
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (!data.columns) {
          const configuration = {
            key: this.CONFIGURAIONT_KEY,
            value: {
              columns: this.columns,
              displayedColumns: this.displayedColumns,
            },
          };
          this.userService.insertConfiguration(configuration).subscribe(() => {
            this.getTableConfiguration();
          });
        }
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
            this.loadFilterConfiguration();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                translationListColumnsConf,
                translationListColumns
              );
            this.setConfiguredColumns(mergedConfig);
            this.loadFilterConfiguration();
          }
        } else {
          this.setDefaultColumnValues();
          this.loadFilterConfiguration();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
        this.loadFilterConfiguration();
      }
    );
  }

  loadFilterConfiguration() {
    if (this.filteredKeys && this.filteredKeys.length > 0) {
      this.filteredKeys.forEach((fk) => {
        const object = {
          name: String(fk),
          index: this.columns.length - 1,
          checked: false,
          translateName: 'GENERAL.LANGUAGE.' + String(fk).toUpperCase(),
          locked: false,
        };
        if (!this.columns.find((obj) => obj.name === object.name)) {
          const lastColumn = this.columns[this.columns.length - 1];
          lastColumn.version = Number(lastColumn.version) + 1;
          this.columns[this.columns.length - 1] = object;
          this.columns.push(lastColumn);
        }
      });
      this.displayedColumnsTemp = this.displayedColumns;
      this.columnsTemp = this.columns;
    }
    this.loadData();
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = translationListColumnsConf;
    this.displayedColumns = translationListColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  private updateData(data: any) {
    this.dataSource.data = data['content'];
    this.totalCount = data['total'];
    this.loaderSpinner = false;
  }
}
