import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { TCI } from '../../models/tci';
import { Observable } from 'rxjs';
import { AzureFile } from '../../models/azure-file';

@Injectable({
  providedIn: 'root',
})
export class TciService {
  serviceUrl: string;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.tciService;
  }

  list(internal): Observable<TCI[]> {
    return this.http.get<TCI[]>(this.serviceUrl + 'contracts', {
      params: {
        internal,
      },
      headers: this.buildHeaders().headers,
    });
  }

  create(contractData) {
    return this.http.post(
      this.serviceUrl + 'contracts',
      contractData,
      this.buildHeaders()
    );
  }

  update(contractData, id) {
    return this.http.put(
      this.serviceUrl + 'contracts/' + id,
      contractData,
      this.buildHeaders()
    );
  }

  updateSubcontract(contractData, id, tciNameId, companyName) {
    return this.http.put(this.serviceUrl + 'subcontracts/' + id, contractData, {
      params: {
        tciNameId,
        clientName: companyName,
      },
      headers: this.buildHeaders().headers,
    });
  }

  emailSentUpdate(sent, subcontractId) {
    return this.http.put(
      this.serviceUrl + 'subcontracts/' + subcontractId + '/update-email-sent',
      null,
      {
        params: {
          emailSent: sent,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  getById(id: number): Observable<TCI> {
    return this.http.get<TCI>(
      this.serviceUrl + 'contracts/' + id,
      this.buildHeaders()
    );
  }

  listHistory(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'contracts/' + id + '/history',
      this.buildHeaders()
    );
  }

  createTciName(name: string): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      this.serviceUrl + 'tci-names?name=' + encodeURI(name),
      {},
      this.buildHeaders()
    );
  }

  completeSubcontractImplementation(id: number, request: any) {
    return this.http.post(
      this.serviceUrl + 'subcontracts/complete-implementation',
      request,
      this.buildHeaders()
    );
  }

  listSubcontracts(masterContractId): Observable<any> {
    return this.http.get(this.serviceUrl + 'subcontracts/', {
      params: {
        masterContractId,
      },
      headers: this.buildHeaders().headers,
    });
  }

  listTciNames(): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'tci-names/',
      this.buildHeaders()
    );
  }

  uploadFile(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload',
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  deleteFile(file: AzureFile) {
    return this.http.post(
      this.serviceUrl + 'files/delete',
      file,
      this.buildHeaders()
    );
  }
  getFile(fileName: string, contractId: number) {
    return this.http.get(
      this.serviceUrl + 'files/getFile/' + contractId + '?fileName=' + fileName,
      {
        responseType: 'blob',
      }
    );
  }
  listFiles(id: any): Observable<AzureFile[]> {
    return this.http.get<AzureFile[]>(
      this.serviceUrl + 'files/list?contractId=' + id,
      this.buildHeaders()
    );
  }

  retrieveByTaskId(id): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl + 'tasks/' + id + '/tci',
      this.buildHeaders()
    );
  }

  retrieveSubcontractByTaskId(id): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl + 'tasks/' + id + '/subcontract',
      this.buildHeaders()
    );
  }

  retrieveSubcontractDraftByTaskId(id): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl + 'drafts/by-task/' + id,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'contracts/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  approveSubcontractTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'subcontracts/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectSubContractTask(
    id: any,
    comment: any,
    clientName: any,
    clientVisibleId: any
  ) {
    const json = {
      comment,
      clientName,
      clientVisibleId,
    };
    return this.http.post(
      this.serviceUrl + 'subcontracts/' + id + '/reject',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any, taskGuid: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'contracts/' + id + '/reject',
      json,
      this.buildHeaders()
    );
  }

  saveDraft(request) {
    if (request.id !== undefined && request.id !== '') {
      return this.http.put(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    } else {
      return this.http.post(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    }
  }

  retrieveUserDraft(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/user-drafts',
      this.buildHeaders()
    );
  }

  retrieveDraft(draftId): Observable<any> {
    return this.http.get(this.serviceUrl + 'drafts', {
      params: {
        id: draftId,
      },
      headers: this.buildHeaders().headers,
    });
  }

  deleteUserDraft(id): Observable<any> {
    return this.http.delete(
      this.serviceUrl + 'drafts/' + id,
      this.buildHeaders()
    );
  }

  getByClientAndTciName(clientId, tciNameId, internal): Observable<any> {
    return this.http.get(this.serviceUrl + 'contracts/by-client-and-tci-name', {
      params: {
        clientId,
        tciNameId,
        internal,
      },
      headers: this.buildHeaders().headers,
    });
  }

  startImplementation(clientId, companyName, clientVisibleId): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'subcontracts/start-implementation',
      {},
      {
        params: {
          clientId,
          clientName: companyName,
          clientVisibleId,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  startImplementationExternal(contractId: any, companyName: any) {
    return this.http.post(
      this.serviceUrl +
        'contracts/' +
        contractId +
        '/start-implementation-external',
      {},
      {
        params: {
          clientName: companyName,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  createSubcontract(contractData) {
    return this.http.post(
      this.serviceUrl + 'subcontracts',
      contractData,
      this.buildHeaders()
    );
  }

  completeImplementationExternalContract(
    id: any,
    comment: any,
    clientName: any
  ) {
    const json = { comment, clientName };

    return this.http.post(
      this.serviceUrl + 'contracts/' + id + '/complete-implementation-external',
      json,
      this.buildHeaders()
    );
  }

  listHistoryNonGermanyReference(): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl + 'non-germany-references/history',
      {
        headers: this.buildHeaders().headers,
      }
    );
  }

  listNonGermanyReferenceApproved(): Observable<any> {
    return this.http.get<any>(this.serviceUrl + 'non-germany-references', {
      headers: this.buildHeaders().headers,
    });
  }

  editNonGermanyReferenceCountries(request): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'non-germany-references',
      request,
      this.buildHeaders()
    );
  }

  getTCIHistory(tciId1, tciId2): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'contracts/selected-history-entries',
      {
        params: {
          idOne: tciId1,
          idTwo: tciId2,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
