import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Quarter as Billing } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/quarter';
import { QuarterlyBillingSimulationParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/simulation-parameters';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationPromptModalComponent } from '../../../../shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { formatDate } from '@angular/common';
import { UserService } from '../../../../shared/services/user/user.service';
import { quaterlyBillingSimulationColumnsConf } from 'src/app/in-memory-data/control-board/quaterly-billing-simulation/columns-configuration';
import { quaterlyBillingSimulationColumns } from 'src/app/in-memory-data/control-board/quaterly-billing-simulation/table-columns';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-quarterly-billing-simulation',
  templateUrl: './quarterly-billing-simulation.component.html',
  styleUrls: ['./quarterly-billing-simulation.component.less'],
})
export class QuarterlyBillingSimulationComponent implements OnInit {
  formGroup: UntypedFormGroup;
  entityName = 'quarterlyBillingSimulation';
  disableQuarterCaps = true;
  disableSimulation = true;

  columns = quaterlyBillingSimulationColumnsConf;
  displayedColumns = quaterlyBillingSimulationColumns;

  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  configurationKey = 'quaterly_billing_simulation_table';
  dataLoaded = false;
  totalCount = 0;
  tableInfo;
  selectedBillings: Billing[] = [];

  constructor(
    private liquidityPlanService: LiquidityPlanService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private matDialogService: MatDialog,
    private userService: UserService,
    private tableService: TablesService
  ) {}

  ngOnInit() {
    this.formBuilder();
    this.tableService.currentSharedSelectedRows.subscribe((selectedRows) => {
      this.selectedBillings = selectedRows;
    });
  }

  formBuilder() {
    this.formGroup = new UntypedFormGroup({
      deadline: new UntypedFormControl({ value: new Date(), disabled: true }),
      target: new UntypedFormControl(null, Validators.required),
      quarterCap: new UntypedFormControl(null),
    });
  }

  changeTargetSelection(newValue: 'PIG' | 'POULTRY') {
    this.disableQuarterCaps = newValue !== 'PIG';

    const quarterCapRadioGroup = this.formGroup.get('quarterCap');
    if (newValue === 'PIG') {
      quarterCapRadioGroup.setValidators(Validators.required);
      quarterCapRadioGroup.updateValueAndValidity();
    } else {
      quarterCapRadioGroup.setValidators(null);
      quarterCapRadioGroup.setValue(null);
      quarterCapRadioGroup.updateValueAndValidity();
    }

    this.liquidityPlanService
      .retrieveBillingsForSimulation(newValue)
      .pipe(first())
      .subscribe((billings) => {
        billings.forEach((item) => {
          // If the deadline field is used directly, the UI breaks in weird ways.
          // That's why this deadline_date is used by the table in the UI.
          // Probably has something to do with the existence of the "deadline" text field here.
          Object.assign(item, {
            deadline_date: item.deadline,
            translated_type: this.translateService.instant(
              'ANIMAL-WELFARE.QUARTERLY-BILLING.QUARTER-TYPES.' + item.type
            ),
            translated_with_quarter_cap: this.withQuarterCapLabel(
              item.withQuarterCap
            ),
          });
        });

        this.tableInfo = {
          content: billings,
          total: billings.length,
          pageSize: 20,
          page: 0,
        };

        this.dataLoaded = true;
      });
  }

  private withQuarterCapLabel(withQuarterCap: boolean | null): string {
    if (withQuarterCap === null) {
      return '';
    } else if (withQuarterCap) {
      return this.translateService.instant('GENERAL.TRUE');
    } else {
      return this.translateService.instant('GENERAL.FALSE');
    }
  }

  /*
   * Only necessary to display a confirmation dialog. If the dialog's message
   * changes, this method might not be necessary anymore.
   */
  private getDeadlineFromSelection() {
    if (!this.selectedBillings && this.selectedBillings.length === 0) {
      return null;
    } else {
      return this.selectedBillings.map((b) => b.deadline).find((d) => !!d);
    }
  }

  startSimulation() {
    const formValue = this.formGroup.value;
    const payload: QuarterlyBillingSimulationParameters = {
      deadline: new Date(),
      withQuarterCap: formValue.quarterCap,
      billingIds: this.selectedBillings.map((b) => b.id),
    };

    this.liquidityPlanService.startSimulation(payload).subscribe((result) => {
      this.notificationService.showToast(
        'ANIMAL-WELFARE.SIMULATION-PROCESS-STARTED',
        this.notificationService.MESSAGE_TYPE.SUCCESS
      );

      setTimeout(() => {
        this.formBuilder();
        this.dataLoaded = false;
      }, 3000);
    });
  }

  confirmToDiscardExistingSimulations() {
    const simulationDate = this.getDeadlineFromSelection();
    if (simulationDate) {
      // If there's a deadline set for any selected billing, we need the user to confirm.
      const formattedDate = formatDate(
        simulationDate,
        this.userService.getDateFormat(),
        'de'
      );
      const dialog = this.matDialogService.open(
        ConfirmationPromptModalComponent
      );
      dialog.componentInstance.messageKey =
        'NOTIFICATION.CONFIRM-DISCARD-BILLING';
      dialog.componentInstance.messageParams['lastSimulationDate'] =
        formattedDate;
      dialog
        .afterClosed()
        .pipe(first())
        .subscribe((data) => {
          if (data) {
            this.startSimulation();
          }
        });
    } else {
      this.startSimulation();
    }
  }
}
