import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingSelectionParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/account-statement-billing-selection';
import { AccountStatementParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/account-statment-parameters';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.less'],
})
export class AccountStatementComponent implements OnInit {
  formGroup: UntypedFormGroup;
  entityName = 'accountStatementData';
  billings: BillingSelectionParameters[] = [];
  disableQuarterCaps = true;
  selection = new SelectionModel<any>(true, []);
  isPig: boolean;
  isPoultry: boolean;

  constructor(
    private liquidityPlanService: LiquidityPlanService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      objectionPeriod: new UntypedFormControl(
        { value: new Date(), disabled: false },
        Validators.required
      ),
      billingIds: new UntypedFormControl('', Validators.required),
    });
    this.isPig = true;
    this.isPoultry = true;
    this.getOpenQuartals();
  }

  getOpenQuartals() {
    this.liquidityPlanService
      .retrieveValidQuartersAccountStatement()
      .subscribe((billings) => {
        this.billings = billings;
      });
  }

  setPig(completed: boolean) {
    this.isPig = completed;
  }

  setPoultry(completed: boolean) {
    this.isPoultry = completed;
  }

  isDisabled() {
    return !this.isPig && !this.isPoultry;
  }

  onChangeQuarter($event) {
    if (!$event.pigId) {
      this.setPig(false);
    }
    if (!$event.poultryId) {
      this.setPoultry(false);
    }
  }

  startSimulation() {
    const formValue = this.formGroup.value;
    let pigId = null;
    let poultryId = null;
    if (this.isPig) {
      pigId = formValue.billingIds.pigId;
    }
    if (this.isPoultry) {
      poultryId = formValue.billingIds.poultryId;
    }

    const payload: AccountStatementParameters = {
      objectionPeriod: formValue.objectionPeriod,
      pigId,
      poultryId,
    };

    this.liquidityPlanService.startAccountStatement(payload).subscribe(
      (result) => {
        this.notificationService.dismissMessage();
        this.notificationService.showToast(
          'NOTIFICATION.SUCCESS',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      },
      (err) => this.handleError(err)
    );
  }

  handleError(error) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: error.error ? error.error.errorId : 'unknown',
        error: error.name,
      }
    );
  }
}
