export const bookingOptions: any[] = [
  {
    code: 1,
    name: 'MANUAL_ONLY',
    translateName: 'BOOKING-CODE.BOOKING-OPTIONS.MANUAL-ONLY',
  },
  {
    code: 2,
    name: 'MECHANICALLY_ONLY',
    translateName: 'BOOKING-CODE.BOOKING-OPTIONS.MECHANICALLY-ONLY',
  },
  {
    code: 3,
    name: 'MANUAL_AND_MECHANICALLY',
    translateName: 'BOOKING-CODE.BOOKING-OPTIONS.MANUAL-AND-MECHANICALLY',
  },
];
