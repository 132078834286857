import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { productEnum } from 'src/app/in-memory-data/business-unit/enums/product-enum';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { isEmpty } from 'src/app/utils/object-util';
import { BusinessUnitPoolService } from '../../../shared/services/business-unit-pool/business-unit-pool.service';
import { BusinessUnitPool } from '../../../shared/models/business-unit-pool';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewBusinessUnitPoolModalComponent } from 'src/app/shared/modals/new-business-unit-pool-modal/new-business-unit-pool-modal.component';

@Component({
  selector: 'app-bu-general-information',
  templateUrl: './bu-general-information.component.html',
  styleUrls: ['./bu-general-information.component.less'],
})
export class BuGeneralInformationComponent implements OnInit {
  @Input() buApproved;
  @Input() buTask;
  @Input() buInitial;
  @Input() isDraftExists;
  @Input() alreadyDraft;
  @Input() operationUser;

  buValue;
  isDirty = false;

  buForm: UntypedFormGroup;
  state: any;

  entityName = 'business-unit';
  editableTask: boolean;
  isTask = false;

  products = productEnum;

  poolsSelectable: BusinessUnitPool[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private taskService: TaskService,
    private businessUnitPoolService: BusinessUnitPoolService,
    private matDialogService: MatDialog
  ) {
    this.buFormBuilder();
  }

  ngOnInit(): void {
    this.state = window.history.state;
    this.buForm.valueChanges.subscribe((val) => (this.buValue = val));
    this.buForm.valueChanges.subscribe((e) => (this.isDirty = true));

    this.businessUnitPoolService
      .getAllBusinessUnitPools()
      .subscribe((pools) => {
        this.poolsSelectable = new Array();
        pools.forEach((pool) => {
          if (
            !pool.exclusiveAllocation ||
            (pool.exclusiveAllocation &&
              this.buApproved &&
              pool.id === this.buApproved.poolAllocationId) ||
            (pool.exclusiveAllocation &&
              this.buTask &&
              pool.id === this.buTask.poolAllocationId)
          ) {
            this.poolsSelectable.push(pool);
          }
        });
      });

    if (this.state.businessUnit) {
      this.patchForm(this.buApproved);
    } else if (this.state.task) {
      this.isTask = true;
      this.patchForm(this.buTask);
      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    }

    if (!this.isTask && this.buApproved) {
      this.buForm.get('ledgerRelevant').disable();
      this.buForm.get('camRelevant').disable();
      this.buForm.get('product').disable();
      this.buForm.get('poolAllocationId').disable();
    }
  }

  patchForm(businessUnit) {
    this.buForm.patchValue(businessUnit);
    this.buForm.markAsPristine();
    if (
      !window.history.state.businessUnit ||
      !window.history.state.businessUnit.draft
    ) {
      this.buForm.addControl(
        'taskId',
        new UntypedFormControl(businessUnit.approvalTaskId, Validators.required)
      );
    }
  }

  buFormBuilder() {
    this.buForm = this.formBuilder.group({
      name: ['', Validators.required],
      ledgerRelevant: [false, []],
      camRelevant: [false],
      id: [''],
      draft: [true],
      guid: [''],
      approvalTaskId: [''],
      product: ['', Validators.required],
      poolAllocationId: ['', Validators.required],
      editingOfExternalRecords: [true],
      manualBookingApproval: [false],
    });
  }

  getUserInfo() {
    return !isEmpty(this.operationUser) ? this.operationUser : 'system-user';
  }

  get getProductName() {
    return this.findProductName.bind(this);
  }

  findProductName(productCode) {
    if (!this.products) {
      return '';
    }

    const product = this.products.find(
      (element) => element.code === productCode
    );
    return product ? product.name : '';
  }

  get getPoolName() {
    return this.findPoolName.bind(this);
  }

  findPoolName(poolId) {
    if (!this.poolsSelectable) {
      return '';
    }
    const pool = this.poolsSelectable.find((element) => element.id === poolId);
    return pool ? pool.name : '';
  }

  addNewPool() {
    const dialog = this.matDialogService.open(
      NewBusinessUnitPoolModalComponent,
      {
        panelClass: 'confirmation-popup',
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result && result.id) {
        this.buForm.get('poolAllocationId').setValue(result.id);
        this.businessUnitPoolService
          .getAllBusinessUnitPools()
          .subscribe((pools) => {
            this.poolsSelectable = new Array();
            pools.forEach((pool) => {
              if (
                !pool.exclusiveAllocation ||
                (pool.exclusiveAllocation && pool.id === result.id)
              ) {
                this.poolsSelectable.push(pool);
              }
            });
          });
      }
    });
  }
}
