import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { documentHistoryModalColumns } from 'src/app/in-memory-data/animal-welfare/document-management/document-history-modal/table-columns';
import { DocumentManagementService } from '../../services/animal-welfare/document-management/document-management.service';
import { DashboardStateService } from 'src/app/modules/accounting-management/services/dashboard-state/dashboard-state.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-document-history-selection-modal',
  templateUrl: './document-history-selection-modal.component.html',
  styleUrls: ['./document-history-selection-modal.component.less'],
})
export class DocumentHistorySelectionModalComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel(true, []);
  originalDataSource = new MatTableDataSource<any>([]);

  displayedColumns = documentHistoryModalColumns;

  filterForm: UntypedFormGroup;
  entityName = 'document-history-selection-modal';
  data: any;

  availableDocumentHistories = true;

  loader = true;

  documentHistoryList: any;
  existentDocuments: any;
  recipientId: any;

  pageSize = 20;
  totalCount;
  sortedBy = 'operation_date';
  sortDirection = 'asc';
  subject = new Subject();
  tableName = 'document';

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: UntypedFormBuilder,
    private documentManagementService: DocumentManagementService,
    private stateService: DashboardStateService
  ) {
    this.filterFormBuilder();
    if (data.recipientId) {
      this.recipientId = data.recipientId;
    }
    this.stateService.initConfig(this.tableName);
  }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getDocumentHistories();
    });
    this.loader = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.filterFormChanges();
    this.getDocumentHistories();
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  filterFormChanges() {
    this.filterForm.valueChanges.subscribe(
      (filters: { searchString: string }) => {
        const searchString = filters.searchString.trim().toUpperCase();
        const filteredList: any[] = this.documentHistoryList.filter(
          (item) =>
            item.name.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.vvvoNr.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.salesTaxId.toString().toUpperCase().indexOf(searchString) >=
              0 ||
            item.street.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.number.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.city.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.zipCode.toString().toUpperCase().indexOf(searchString) >= 0 ||
            item.country.toString().toUpperCase().indexOf(searchString) >= 0
        );
        this.dataSource.data = filteredList;
      }
    );
  }

  filter(event) {
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }
    if (event.filteredValues) {
      this.stateService.configs[this.tableName].filteredValues =
        this.stateService.configs[this.tableName].filteredValues.filter(
          (fv) => fv.choice !== event.filteredValues.choice
        );
      this.stateService.configs[this.tableName].filteredValues.push(
        event.filteredValues
      );
    } else {
      this.stateService.configs[this.tableName] = {
        ...this.stateService.configs[this.tableName],
        ...event,
      };
    }
    this.getDocumentHistories();
  }

  getDocumentHistories() {
    const filterOptions = {
      ...this.stateService.configs[this.tableName],
    };
    this.documentManagementService
      .filterDocumentHistoryList(filterOptions, this.recipientId)
      .subscribe(
        (documentHistoryList) => {
          this.totalCount = documentHistoryList.total;
          this.documentHistoryList = this.dataSource.data =
            documentHistoryList.content;
          this.originalDataSource = this.dataSource;
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  getFieldNames = (args): Observable<any> => {
    return this.documentManagementService.getFilterHistoryFieldNameValues(
      args,
      this.recipientId
    );
  };

  filterData = (choice, selection): void => {
    this.loader = true;
    this.paginator.firstPage();
    // this.subject.next(undefined);
    this.stateService.configs[this.tableName].filteredValues =
      this.stateService.configs[this.tableName].filteredValues.filter(
        (fv) => fv.choice !== choice
      );
    this.stateService.configs[this.tableName].filteredValues.push({
      choice,
      values: selection,
    });
    this.getDocumentHistories();
  };

  sortTable = (choice, direction): void => {
    this.paginator.firstPage();
    // this.subject.next(undefined);
    this.stateService.configs[this.tableName].sortField = choice;
    this.stateService.configs[this.tableName].sortDirection = direction;
    this.getDocumentHistories();
  };

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loader = true;
    this.getDocumentHistories();
  }

  toggleRow(element) {
    this.selection.clear();
    this.selection.toggle(element);
  }
}
