<app-back-button *ngIf="isTask"> </app-back-button>
<div class="edit-task" *ngIf="isTask && !editableTask">
  <button
    id="{{entityName}-edit-task"
    *hasPermission="'sc.asc.u'"
    mat-button
    class="edit-task-button"
    (click)="changeEditTaskMode(true)"
  >
    <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>
<div class="input-wrapper form-container">
  <form class="form" [formGroup]="accountStatementForm" autocomplete="off">
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.RESPONSIBLE-TEAM'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.RESPONSIBLE-TEAM'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.RESPONSIBLE-TEAM'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.responsibleTeam"
      [controlName]="'responsibleTeam'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.responsibleTeam : null"
      [taskValue]="accountStatementTask ? accountStatementTask.responsibleTeam : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    >
    </app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.account"
      [controlName]="'account'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.account : null"
      [taskValue]="accountStatementTask ? accountStatementTask.account : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.NAME'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.NAME'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.NAME'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.name : null"
      [taskValue]="accountStatementTask ? accountStatementTask.name : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-radio
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.BEAS-RELEVANT'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.BEAS-RELEVANT'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlName]="'beasRelevant'"
      [controlObject]="accountStatementForm.controls.beasRelevant"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.beasRelevant : null"
      [taskValue]="accountStatementTask ? accountStatementTask.beasRelevant : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [items]="beasRelevantOptions"
      [optionValue]="'value'"
      [titlecasePipe]="false"
    ></app-mat-radio>
    <app-mat-input
      *ngIf="accountStatementForm.controls.beasRelevant.value == true"
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.FI-ACCOUNT'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.FI-ACCOUNT'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.FI-ACCOUNT'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.fiAccount"
      [controlName]="'fiAccount'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.fiAccount : null"
      [taskValue]="accountStatementTask ? accountStatementTask.fiAccount : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
    >
    </app-mat-input>
    <app-mat-select
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.CURRENCY'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.CURRENCY'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.CURRENCY'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.currency"
      [controlName]="'currency'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.currency : null"
      [taskValue]="accountStatementTask ? accountStatementTask.currency : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="currencyCodes"
      [optionValue]="''"
      [selectedOption]="''"
      [translationOption]="''"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
      [textTransform]="'none'"
    ></app-mat-select>

    <app-mat-select
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.BUSINESS-UNIT-ID'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.BUSINESS-UNIT-ID'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.BUSINESS-UNIT-ID'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.businessUnitId"
      [controlName]="'businessUnitId'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.businessUnitId : null"
      [taskValue]="accountStatementTask ? accountStatementTask.businessUnitId.toString() : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="businessUnitList"
      [optionValue]="'id'"
      [selectedOption]="''"
      [translationOption]="'name'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
    >
    </app-mat-select>

    <app-mat-select
      *ngIf="accountStatementForm.controls.businessUnitId.value != null"
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.PLATFORM-ID'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.PLATFORM-ID'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.PLATFORM-ID'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.platformId"
      [controlName]="'platformId'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.platformId : null"
      [taskValue]="accountStatementTask ? accountStatementTask.platformId.toString() : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="platformList"
      [optionValue]="'id'"
      [selectedOption]="''"
      [translationOption]="'visibleId'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
    >
    </app-mat-select>

    <app-mat-select
      *ngIf="accountStatementForm.controls.platformId.value != null"
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.CLIENT-ID'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.CLIENT-ID'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.CLIENT-ID'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.clientId"
      [controlName]="'clientId'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.clientId : null"
      [taskValue]="accountStatementTask ? accountStatementTask.clientId.toString() : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="clientList"
      [optionValue]="'id'"
      [selectedOption]="''"
      [translationOption]="'visibleId'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
    >
    </app-mat-select>

    <app-mat-select
      *ngIf="accountStatementForm.controls.platformId.value != null"
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.DEBTOR-ID'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.DEBTOR-ID'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.DEBTOR-ID'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.debtorId"
      [controlName]="'debtorId'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.debtorId : null"
      [taskValue]="accountStatementTask ? accountStatementTask.debtorId.toString() : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="debtorList"
      [optionValue]="'id'"
      [selectedOption]="''"
      [translationOption]="'visibleId'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
    >
    </app-mat-select>
    <app-mat-select
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.BTC-MAPPING'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.BTC-MAPPING'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.BTC-MAPPING'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.btcMapping"
      [controlName]="'btcMapping'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.btcMapping : null"
      [taskValue]="accountStatementTask ? accountStatementTask.btcMapping : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="btcMappingList"
      [optionValue]="'name'"
      [selectedOption]="''"
      [translationOption]="'name'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
      [titlecasePipe]="false"
    ></app-mat-select>

    <app-mat-textarea
      [name]="'SYSTEM-CONFIGURATION.CONFIGURATION.COMMENT'"
      [placeholder]="'SYSTEM-CONFIGURATION.CONFIGURATION.COMMENT'"
      [label]="'SYSTEM-CONFIGURATION.CONFIGURATION.COMMENT'"
      [entityName]="entityName"
      [form]="accountStatementForm"
      [controlObject]="accountStatementForm.controls.comment"
      [controlName]="'comment'"
      [approvedValue]="accountStatementApproved ? accountStatementApproved.comment : null"
      [taskValue]="accountStatementTask ? accountStatementTask.comment : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="''"
    ></app-mat-textarea>
  </form>
</div>

<div class="filter-input button-container">
  <button
    *ngIf="isTask && editableTask"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-button"
    (click)="changeEditTaskMode(false)"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>
  <button
    *ngIf="!isTask"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-button"
    (click)="changeEditMode()"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>

  <button
    *ngIf="!isTask || (isTask && editableTask)"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-create-draft-button"
    (click)="saveAsDraft()"
  >
    <span *ngIf="draftId" id="{{ entityName }}-update-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
    </span>
    <span *ngIf="!draftId" id="{{ entityName }}-new-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
    </span>
  </button>

  <ng-container *ngIf="!isTask || (isTask && editableTask)">
    <button
      *ngIf="!edit"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      (click)="onClickSave()"
      [disabled]="!accountStatementForm.valid"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
    <button
      *ngIf="edit"
      mat-button
      class="custom-button"
      [disabled]="!accountStatementForm.valid"
      id="{{ entityName }}-update-button"
      (click)="onClickSave()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.UPDATE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
