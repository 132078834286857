export const productsEnum: any[] = [
  {
    name: 'CENTRAL_SETTLEMENT',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.CENTRAL_SETTLEMENT',
  },
  {
    name: 'FACTORING',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.FACTORING',
  },
  {
    name: 'BACKUP_SERVICING',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.BACKUP_SERVICING',
  },
  {
    name: 'ANIMAL_WELFARE_INITIATIVE',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.ANIMAL_WELFARE_INITIATIVE',
  },
];
