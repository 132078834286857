import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryDataModule } from 'src/app/in-memory-data/in-memory-data.module';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlBoardManagementComponent } from './control-board-management/control-board-management.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';
import { QuarterlyBillingModule } from '../quarterly-billing/quarterly-billing.module';
import { AccountStatementModule } from '../account-statement/account-statement.module';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [ControlBoardManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    NgSelectModule,
    BackButtonModule,
    SharedModule,
    CurrencyMaskModule,
    MatTabsModule,
    InMemoryDataModule,
    MatRadioModule,
    FlexLayoutModule,
    FormElementsModule,
    MatDatepickerModule,
    NgxMaskModule,
    MatSidenavModule,
    MatStepperModule,
    RouterModule,
    QuarterlyBillingModule,
    AccountStatementModule,
  ],
  exports: [ControlBoardManagementComponent],
})
export class ControlBoardManagementModule {}
