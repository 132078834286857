import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Message } from 'src/app/shared/models/message';
import { MessageService } from 'src/app/shared/services/message/message.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms')),
      transition('expanded <=> void', animate('150ms')),
    ]),
  ],
})
export class MessageListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  messageList: TranslatableMessage[] = [];
  expandedMessage: TranslatableMessage = null;

  entityName = 'message';

  displayedColumns: string[] = ['date', 'subject'];

  dataSource: MatTableDataSource<Message> = new MatTableDataSource();
  dateFormat = '';

  loaderSpinner = false;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    this.refresh();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'subject':
          return this.translateService.instant(
            item['subjectTranslationKey'],
            item.params
          );
        default:
          return item[property];
      }
    };
  }

  refresh(): void {
    this.loaderSpinner = true;
    this.messageService.list(99999, 1).then(
      (messageList) => {
        this.messageList = messageList.map(this.makeTranslatable);
        this.messageList.forEach((message) => {
          if (
            (message.params['comment'] &&
              message.params['comment'].includes(
                'IMPORT.TRANSFER_SUCCESSFULLY_COMPLETED'
              )) ||
            (message.params['comment'] &&
              message.params['comment'].includes('IMPORT.CREATION_APPROVED')) ||
            (message.params['comment'] &&
              message.params['comment'].includes(
                'IMPORT.ERROR-MESSAGES.ERROR-DURING-DATA-TRANSFER'
              )) ||
            (message.params['comment'] &&
              message.params['comment'].includes(
                'open-items.messages.errors-during-import'
              ))
          ) {
            const fileId = message.params['comment'].substr(
              message.params['comment'].indexOf(' ') + 1
            );
            const link: any =
              location.origin + '/interfaceTransferProtocol?' + fileId;
            message.params['link'] = link;
          } else if (
            message.params['comment'] &&
            message.params['comment'].includes('TCI_PRECHECK_CREDIT_MANAGER')
          ) {
            const link: any = location.origin + '/masterdata/tciExternal';
            message.params['link'] = link;
          } else if (
            message.params['comment'] &&
            message.params['comment'].includes(
              'ALLOCATION_PAYMENT_DRAFT_EXPIRATION_NOTIFIED'
            )
          ) {
            const entityGuid = message.params['entityGuid'];
            const link: any =
              location.origin +
              '/accounting/overview/' +
              entityGuid +
              '/LIVESTOCK_PRODUCER/paymentAllocation';
            message.params['link'] = link;
          }
        });
        this.dataSource.data = this.messageList;
        this.dataSource.sort = this.sort;
        this.snackBar.dismiss();

        if (messageList.length <= 0) {
          this.snackBar.open(
            this.translateService.instant('ERROR.NO-MESSAGES'),
            'OK',
            {
              duration: 2000,
            }
          );
        }
        this.loaderSpinner = false;
      },
      (error) => {
        this.loaderSpinner = false;
      }
    );
  }

  expand(message: TranslatableMessage) {
    if (this.expandedMessage === message) {
      this.expandedMessage = null; // Close currently open message
    } else {
      this.expandedMessage = message;

      if (!this.expandedMessage.markedAsRead) {
        this.messageService
          .markAsRead(this.expandedMessage.id)
          .then((_) => (this.expandedMessage.markedAsRead = true));
      }
    }
  }

  private makeTranslatable(message: Message): TranslatableMessage {
    return {
      subjectTranslationKey: message.translationKey + '.SUBJECT',
      contentTranslationKey: message.translationKey + '.CONTENT',
      ...message,
    };
  }

  getTranslatedMessage(element) {
    const params = element['params'];
    const paramKeys = Object.keys(params).filter((key) => key !== 'entity');
    paramKeys.forEach((key) => {
      if (params[key] !== '') {
        params[key] = this.translateService.instant(params[key], params);
      }
    });
    if (params['entity']) {
      params['entity'] = this.translateService.instant(
        params['entity'],
        params
      );
    }
    return this.translateService.instant(element.subjectTranslationKey, params);
  }
}

interface TranslatableMessage extends Message {
  subjectTranslationKey: string;
  contentTranslationKey: string;
}
