import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[removeHtmlTagsForNgModel]',
})
export class RemoveHtmlTagsForNgModelDirective {
  @Output() ngModelChange = new EventEmitter();
  pattern = /\<.*?\>/g;
  patternDoubleOpenAngleBrackets = /\<.*?\</g;

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    if (!!value) {
      value = value.replace(this.pattern, '');
      value = value.replace(this.patternDoubleOpenAngleBrackets, '');
      this.ngModelChange.emit(value);
    }
  }
}
