import { Component, Input } from '@angular/core';
import { RouterExtService } from '../../services/router/router-ext.service';
import { TaskService } from '../../services/task/task.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.less'],
})
export class BackButtonComponent {
  @Input() task;
  @Input() address;

  constructor(
    private routerExtService: RouterExtService,
    private taskService: TaskService,
    private kcService: KeycloakService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  async checkTaskLocked(): Promise<any> {
    const task: any = await this.taskService.getTask(this.task.id).toPromise();
    if (
      task &&
      task.isLocked &&
      this.kcService.getUsername() === task.lockedUsername
    ) {
      await this.taskService.unlock(this.task.id).toPromise();
    }
  }

  async goBack() {
    this.snackBar.dismiss();
    const state = window.history.state;
    if (state.hasSalesEntry) {
      this.router.navigateByUrl('salesEntry');
    } else if (state.salesEntryFormNotValid) {
      this.router.navigateByUrl('masterdata/company');
    } else if (state.splitScreen) {
      this.router.navigateByUrl('masterdata/company');
    } else {
      if (this.task && this.task.id) {
        await this.checkTaskLocked();
      }
      this.routerExtService.router.navigateByUrl(
        this.address || this.removeLastDirectoryPart(location.pathname)
      );
    }
  }

  removeLastDirectoryPart(url) {
    const urlArr = url.split('/');
    urlArr.pop();
    return urlArr.join('/');
  }
}
