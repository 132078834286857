export const customFilterConfiguration: any[] = [
  {
    name: 'TASK.ASSIGNED-USER',
    display: 'TASK.ASSIGNED-USER',
    columnProp: 'assigned_user_id',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'user',
  },
  {
    name: 'TASK.CREATED-BY',
    display: 'TASK.CREATED-BY',
    columnProp: 'created_by',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'user',
  },
  {
    name: 'TASK.STATUS',
    display: 'TASK.STATUS',
    columnProp: 'status',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'text',
  },
  {
    name: 'TASK.PRIORITY',
    display: 'TASK.PRIORITY',
    columnProp: 'priority',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'text',
  },
  {
    name: 'BU.TITLE',
    display: 'BU.TITLE',
    columnProp: 'business_unit_id',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'bu',
  },
  {
    name: 'GENERAL.CREATED-ON',
    display: 'GENERAL.CREATED-ON',
    columnProp: 'creation_date',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'date',
  },
  {
    name: 'TASK.DUE-DATE',
    display: 'TASK.DUE-DATE',
    columnProp: 'due_date',
    translatePrefix: '',
    options: [],
    filteredOptions: [],
    type: 'date',
  },
];
