import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { documentCategories } from 'src/app/in-memory-data/client/documents/categories';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.less'],
})
export class UploadFilesModalComponent {
  form: UntypedFormGroup;
  uploadedFiles;
  multiple = true;
  options: any[];
  entityName = 'upload-file-modal';
  selectedOption: any;
  clientDocumentModal = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      attachment: ['', Validators.required],
    });

    if (data.uploadedFiles) {
      this.uploadedFiles = data.uploadedFiles;
    }

    if (data.clientDocument) {
      this.addClientDocumentsValidations();
    }
  }

  addClientDocumentsValidations() {
    this.clientDocumentModal = true;
    this.options = documentCategories;
    this.form.addControl('category', new UntypedFormControl(''));
    this.form.addControl('comment', new UntypedFormControl('', Validators.required));
    this.multiple = false;
  }
}
