export const openItemColumnsConf: any[] = [
  {
    name: 'clientDebtorId',
    index: 1,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CLIENT-DEBTOR-ID',
    locked: true,
  },
  {
    name: 'clientOpenItemId',
    index: 2,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CLIENT-OPEN-ITEM-ID',
    locked: true,
  },
  {
    name: 'openItemDate',
    index: 3,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OPEN-ITEM-DATE',
    locked: true,
  },
  {
    name: 'bookingCode',
    index: 4,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BOOKING-CODE',
    locked: true,
  },
  {
    name: 'card',
    index: 5,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBIT-CREDIT-CODE',
    locked: true,
  },
  {
    name: 'amount',
    index: 6,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.AMOUNT',
    locked: true,
  },
  {
    name: 'currency',
    index: 7,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CURRENCY-CODE',
    locked: true,
  },

  {
    name: 'vatAmount',
    index: 8,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-AMOUNT',
    locked: true,
  },
  {
    name: 'dueDate',
    index: 9,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DUE-DATE',
    locked: true,
  },
  {
    name: 'debtCollection',
    index: 10,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION',
    locked: true,
  },
  {
    name: 'settlementInEUR',
    index: 11,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.SETTLEMENT-IN-EUR',
    locked: false,
  },
  {
    name: 'vatPercent',
    index: 12,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-PERCENT',
    locked: false,
  },
  {
    name: 'vatCode',
    index: 13,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-CODE',
    locked: false,
  },
  {
    name: 'referenceClientsOpenItemId',
    index: 14,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.REFERENCE-CLIENT-OPEN-ITEM-ID',
    locked: false,
  },
  {
    name: 'additionalInformation',
    index: 15,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.ADDITIONAL-INFO',
    locked: false,
  },
  {
    name: 'branchId',
    index: 16,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BRANCH',
    locked: false,
  },
  {
    name: 'iban',
    index: 17,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.IBAN',
    locked: false,
  },
  {
    name: 'bic',
    index: 18,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BIC',
    locked: false,
  },
  {
    name: 'accountHolder',
    index: 19,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.ACCOUNT-HOLDER',
    locked: false,
  },
  {
    name: 'paymentReference',
    index: 20,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-REFERENCE',
    locked: false,
  },
  {
    name: 'paymentScheme',
    index: 21,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-SCHEME',
    locked: false,
  },
  {
    name: 'paymentDate',
    index: 22,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-DATE',
    locked: false,
  },

  {
    name: 'debtCollectionTransferDate',
    index: 23,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION-TRANSFER-DATE',
    locked: false,
  },
  {
    name: 'objectionCode',
    index: 24,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-CODE',
    locked: false,
  },
  {
    name: 'objectionDate',
    index: 25,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-DATE',
    locked: false,
  },
  {
    name: 'objectionAmount',
    index: 26,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-AMOUNT',
    locked: false,
  },
  {
    version: 1,
  },
];

const eso = [
  {
    name: 'clientDebtorId',
    index: 1,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CLIENT-DEBTOR-ID',
    locked: true,
  },
  {
    name: 'clientOpenItemId',
    index: 2,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CLIENT-OPEN-ITEM-ID',
    locked: true,
  },
  {
    name: 'openItemDate',
    index: 3,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OPEN-ITEM-DATE',
    locked: true,
  },
  {
    name: 'bookingCode',
    index: 4,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BOOKING-CODE',
    locked: true,
  },
  {
    name: 'card',
    index: 5,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBIT-CREDIT-CODE',
    locked: true,
  },
  {
    name: 'amount',
    index: 6,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.AMOUNT',
    locked: true,
  },
  {
    name: 'currency',
    index: 7,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.CURRENCY-CODE',
    locked: true,
  },
  {
    name: 'vatAmount',
    index: 8,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-AMOUNT',
    locked: true,
  },
  {
    name: 'dueDate',
    index: 9,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DUE-DATE',
    locked: true,
  },
  {
    name: 'debtCollection',
    index: 10,
    checked: true,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION',
    locked: true,
  },
  {
    name: 'settlementInEUR',
    index: 11,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.SETTLEMENT-IN-EUR',
    locked: false,
  },
  {
    name: 'vatPercent',
    index: 12,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-PERCENT',
    locked: false,
  },
  {
    name: 'vatCode',
    index: 13,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.VAT-CODE',
    locked: false,
  },
  {
    name: 'referenceClientsOpenItemId',
    index: 14,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.REFERENCE-CLIENT-OPEN-ITEM-ID',
    locked: false,
  },
  {
    name: 'additionalInformation',
    index: 15,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.ADDITIONAL-INFO',
    locked: false,
  },
  {
    name: 'branchId',
    index: 16,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BRANCH',
    locked: false,
  },
  {
    name: 'iban',
    index: 17,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.IBAN',
    locked: false,
  },
  {
    name: 'bic',
    index: 18,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.BIC',
    locked: false,
  },
  {
    name: 'accountHolder',
    index: 19,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.ACCOUNT-HOLDER',
    locked: false,
  },
  {
    name: 'paymentReference',
    index: 20,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-REFERENCE',
    locked: false,
  },
  {
    name: 'paymentScheme',
    index: 21,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-SCHEME',
    locked: false,
  },
  {
    name: 'paymentDate',
    index: 22,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.PAYMENT-DATE',
    locked: false,
  },
  {
    name: 'debtCollectionTransferDate',
    index: 23,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION-TRANSFER-DATE',
    locked: false,
  },
  {
    name: 'objectionCode',
    index: 24,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-CODE',
    locked: false,
  },
  {
    name: 'objectionDate',
    index: 25,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-DATE',
    locked: false,
  },
  {
    name: 'objectionAmount',
    index: 26,
    checked: false,
    translateName: 'OPEN-TASK.TABLE-HEADERS.OBJECTION-AMOUNT',
    locked: false,
  },
  {
    version: 1,
  },
];
