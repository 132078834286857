import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { handleError } from 'src/app/utils/error-util';
import { Client } from '../../models/client';
import { DialogData } from '../../models/dialog-data';
import { CompanyService } from '../../services/company/company.service';
import { SortService } from '../../services/sort/sort-service';
import { ClientSelectionModalComponent } from '../client-selection-modal/client-selection-modal.component';
import { contactPersonModalColumns } from '../../../in-memory-data/company/contact-person-selection-modal/table-columns';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-contact-person-link-modal',
  templateUrl: './contact-person-link-modal.component.html',
  styleUrls: ['./contact-person-link-modal.component.less'],
})
export class ContactPersonLinkModalComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  filterForm: UntypedFormGroup;
  searchInput: any = '';
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<Client>();
  loaderSpinner = true;
  displayedColumns = [];
  emailIsEmpty = false;
  data: any;
  entityName = 'contactSelectionModal';

  noContacts = false;
  contactPersonsList: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<ClientSelectionModalComponent>,
    private sortService: SortService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.logicInsideConstructor();
    if (this.dialogData && this.dialogData.multiple) {
      this.selection = new SelectionModel(true, []);
    } else {
      this.selection = new SelectionModel(false, []);
    }
    this.displayedColumns.push('select');
    this.displayedColumns = this.displayedColumns.concat(
      contactPersonModalColumns
    );

    this.getCompanyValuesForExistingCompany(this.dialogData['companyGuid']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  logicInsideConstructor() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
    this.filterForm.valueChanges.subscribe(
      (filters: { searchString: string }) => {
        const searchString = filters.searchString.trim().toUpperCase();

        const filteredList = this.contactPersonsList.filter(
          (contact) =>
            contact.name.toUpperCase().indexOf(searchString) >= 0 ||
            contact.surname.toUpperCase().indexOf(searchString) >= 0 ||
            contact.address.street.toUpperCase().indexOf(searchString) >= 0 ||
            contact.address.number.toUpperCase().indexOf(searchString) >= 0 ||
            contact.address.zipCode.toUpperCase().indexOf(searchString) >= 0 ||
            contact.address.city.toUpperCase().indexOf(searchString) >= 0 ||
            contact.address.country.toUpperCase().indexOf(searchString) >= 0 ||
            contact.telephoneNumber.toUpperCase().indexOf(searchString) >= 0 ||
            contact.email.toUpperCase().indexOf(searchString) >= 0
        );
        this.dataSource.data = filteredList;
      }
    );
    this.data = {
      contact: null,
      contacts: [],
    };
  }

  getCompanyValuesForExistingCompany(companyGuid) {
    const contactLinkListIds = this.dialogData['contactList'].map(
      (element) => element.id
    );
    this.companyService.getCurrentApproved(companyGuid).subscribe(
      (data) => {
        this.dataSource.data = this.contactPersonsList =
          data.contactPeople.filter(
            (element) => contactLinkListIds.indexOf(element.id) === -1
          );
        this.dataSource.data.forEach((contact) => {
          this.userService
            .getCountContactPerson(contact.id)
            .subscribe((numberOfUsers) => {
              contact['numberOfUsers'] = numberOfUsers;
            });
        });
        this.dataSource.sortingDataAccessor = (item, property) => {
          return item[property];
        };
        this.loaderSpinner = false;
      },
      (error) => handleError(error)
    );
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();

    if (!sort.active || sort.direction === '') {
      this.dataSource.data = this.contactPersonsList;
      return;
    }

    if (sort.active === 'id') {
      let result;
      const splitItems = 2;
      result = this.sortService.sortByVisibleId(data, sort, splitItems);
      this.dataSource.data = result;
    }
  }

  ngOnDestroy() {
    if (this.data.contact) {
      this.data.contact.firstName = this.data.contact.name;
      this.data.contact.lastName = this.data.contact.surname;
      this.data.contact.birthday = this.data.contact.dateOfBirth;
    }
  }

  trclicked(element) {
    if (this.dialogData.debtor) {
      this.data.contact = element;
      this.dialogRef.close(this.data);
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.data.contacts = this.selection.selected;
    } else {
      this.dataSource.data.forEach((row) => {
        this.selectContact(row, true);
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectContact(row, flag?) {
    if (flag) {
      this.selection.select(row);
    } else {
      this.selection.toggle(row);
    }

    if (this.dialogData && this.dialogData.multiple) {
      this.data.contacts = this.selection.selected;
    } else {
      this.data.contact = row;
    }
    if (this.router.url.includes('userManagement')) {
      if (
        this.selection.selected.length > 0 &&
        this.selection.selected[0].email === ''
      ) {
        this.emailIsEmpty = true;
      } else {
        this.emailIsEmpty = false;
      }
    }
  }
}
