export const subLedgerAccountsListColumnsConf: any[] = [
  {
    name: 'code',
    index: 0,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NUMBER',
    locked: true,
  },
  {
    name: 'name_german',
    index: 1,
    checked: true,
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-GERMAN',
    locked: true,
  },
  {
    name: 'name_english',
    index: 2,
    checked: true,
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-ENGLISH',
    locked: true,
  },
  {
    name: 'product',
    index: 3,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCT',
    locked: true,
  },
  {
    name: 'service_model',
    index: 4,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODEL',
    locked: true,
  },
  {
    name: 'type',
    index: 5,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE',
    locked: false,
  },
  {
    name: 'sap_sub_ledger_account',
    index: 6,
    checked: true,
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SAP-SUB-LEDGER-ACCOUNT',
    locked: false,
  },
  {
    name: 'sales_tax_account',
    index: 7,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SALES-TAX-ACCOUNT',
    locked: false,
  },
  {
    version: 4,
  },
];
