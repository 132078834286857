import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {
  public previousUrl: string;
  private currentUrl: string;

  constructor(public router: Router, private location: Location) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.previousUrl =
          location.path() && location.isCurrentPathEqualTo(e.url)
            ? this.findPreviousUrl(e.url)
            : '';
        this.currentUrl = location.path();
      });
  }

  private findPreviousUrl(routeArray: string): string {
    return routeArray.substring(0, routeArray.lastIndexOf('/'));
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
}
