import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskEventType } from 'src/app/shared/models/task-event-type';
import { UserSelectModalComponent } from 'src/app/shared/modals/user-select-modal/user-select-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { UserService } from 'src/app/shared/services/user/user.service';
import { User } from 'src/app/shared/models/user';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { DismissChangesDialogComponent } from 'src/app/shared/dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { TaskComment } from '../../../shared/models/task';
import { UploadFilesModalComponent } from 'src/app/shared/modals/upload-files-modal/upload-files-modal.component';
import { AzureFile } from 'src/app/shared/models/azure-file';
import { TranslateService } from '@ngx-translate/core';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TaskConfigurationService } from 'src/app/shared/services/task-configuration/task-configuration.service';
import { TaskConfiguration } from 'src/app/shared/models/task-configuration';
import { DatePipe } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { EmailDraftModalComponent } from 'src/app/shared/modals/email-draft-modal/email-draft-modal.component';
import { Email } from 'src/app/shared/models/email';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { EmailService } from 'src/app/shared/services/email/email.service';
import { EmailFileName } from 'src/app/shared/models/email-file-name';
import { AwInterfaceTransferProtocolService } from 'src/app/shared/services/animal-welfare/aw-interface-transfer-protocol/aw-interface-transfer-protocol.service';
import { taskButtons } from './task-details-buttons/task-details-buttons.component';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.less'],
})
export class TaskDetailsComponent implements OnInit {
  displayedColumns: string[] = [
    'creationDate',
    'createdBy',
    'eventType',
    'comment',
  ];

  displayedColumnsFileTable: string[] = ['fileName', 'date', 'user', 'actions'];
  taskTypesAllowedForCompletionByCreator: string[] = [
    'OBJECTION_MANAGEMENT',
    'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC',
    'PAYOUT_SUGGESTION_LIST_EMAIL',
    'AW_SEPA_EXCEL_EMAIL',
  ];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  bu;
  userList: User[];
  entityName = 'task';
  comments = [];
  uploadedFiles: AzureFile[];
  task;
  isDirectApproveOrReject = false;
  payoutSuggestionListEmail = false;
  taskConfiguration: TaskConfiguration;
  dateFormat = '';
  dataSource = new MatTableDataSource<TaskComment>(this.comments);
  dataSourceAttachments = new MatTableDataSource<AzureFile>([]);
  taskEntity = '';
  userInfo;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
    private kcService: KeycloakService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private taskConfigurationService: TaskConfigurationService,
    private datePipe: DatePipe,
    private emailService: EmailService,
    private awitpService: AwInterfaceTransferProtocolService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);

    const taskId = this.route.snapshot.paramMap.get('taskId');
    this.initTaskData(taskId);
    this.kcService.loadUserProfile().then((data) => {
      this.userInfo = data;
    });
  }

  private async initTaskData(taskId: string) {
    this.taskService.getTask(taskId).subscribe((data) => {
      this.taskService.parseTitle(data);
      this.task = data;
      if (!this.task.params.objectName && this.task.params.fileId) {
        this.awitpService
          .getDocument(data['params'].fileId)
          .subscribe((file) => (this.task.params.objectName = file.filename));
      }
      if (
        this.task.type === 'APPROVAL_PAYOUT_SUGGESTION_LIST' ||
        this.task.type === 'RELEASE_OF_UNUSED_BUDGET_APPROVAL'
      ) {
        this.isDirectApproveOrReject = true;
      }
      if (
        this.task.type === 'PAYOUT_SUGGESTION_LIST_EMAIL' ||
        this.task.type === 'AW_SEPA_EXCEL_EMAIL'
      ) {
        this.payoutSuggestionListEmail = true;
      }
      if (!!this.task?.businessUnitId) {
        this.businessUnitService
          .getCurrentApproved(this.task.businessUnitId)
          .subscribe((buData) => {
            this.bu = buData;
          });
      }

      this.comments = this.task.comments;
      this.comments.sort((a, b) =>
        a.date < b.date ? 1 : a.date > b.date ? -1 : 0
      );
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.data = this.comments;
      this.getTaskConfiguration();
      this.getUploadedFiles();
      this.getEntity();
    });
  }

  getUploadedFiles() {
    this.taskService.listFiles(this.task.id).subscribe((data) => {
      this.uploadedFiles = data;

      this.getFileInformation();

      // this.dataSourceAttachments.data = this.uploadedFiles;
    });
  }

  getTaskConfiguration() {
    this.taskConfigurationService
      .retrieveByType({ type: this.task.type })
      .subscribe((data) => {
        this.taskConfiguration = data;
      });
  }

  getFileInformation() {
    this.comments.forEach((item) => {
      if (item.eventType === 3) {
        this.uploadedFiles.forEach((file) => {
          if (file.fileName === item.content) {
            file.date = item.date;
            file.userId = item.userId;
          }
        });
      }
    });

    this.dataSourceAttachments.sort = this.sort.toArray()[1];
    this.dataSourceAttachments.paginator = this.paginator.toArray()[1];
    this.dataSourceAttachments.data = this.uploadedFiles;
  }

  openFile(file: AzureFile) {
    this.taskService.getFile(file.fileName, file.taskId).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      const anchor = document.createElement('a');
      anchor.download = file.fileName;
      anchor.href = url;
      anchor.click();
    });
  }

  deleteImage(item) {
    const dialogConfirm = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'DELETE-FILE-MODAL.MODAL-TITLE'
          ),
        },
      }
    );
    dialogConfirm.afterClosed().subscribe((result) => {
      if (result) {
        this.taskService.deleteFile(item).subscribe((data) => {
          this.notificationService.showToast(
            'DELETE-FILE-MODAL.SUCCESSFUL-DELETE',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
          this.initTaskData(this.task.id);
        });
      }
    });
  }

  async showDialog() {
    return await this.dialog
      .open(DismissChangesDialogComponent, {
        width: '20em',
        data: {
          message: 'DIALOGS.BUSINESS-UNIT.CHANGE.MESSAGE',
        },
      })
      .afterClosed()
      .toPromise();
  }

  async startTask() {
    sessionStorage.removeItem('taskModify'); // just in case
    if (
      this.task.businessUnitId.toString() !==
      this.businessUnitService.getCurrentBusinessUnit()
    ) {
      const value = await this.showDialog();
      if (value) {
        this.openTaskView();
        this.businessUnitService.emitChanges(this.task.businessUnitId);
      }
    } else {
      this.openTaskView();
    }
  }

  async modifyTask() {
    sessionStorage.setItem('taskModify', 'true');
    if (
      this.task.businessUnitId.toString() !==
      this.businessUnitService.getCurrentBusinessUnit()
    ) {
      const value = await this.showDialog();
      if (value) {
        this.openTaskView();
        this.businessUnitService.emitChanges(this.task.businessUnitId);
      }
    } else {
      this.openTaskView();
    }
  }

  openTaskView() {
    this.taskService.lock(this.task.id).subscribe(() => null);
    if (this.task.assignedUsername !== this.kcService.getUsername()) {
      this.taskService.assignToMe(this.task.id).subscribe(() => null);
    }

    const state = { task: this.task };
    switch (this.task.type) {
      case 'APPROVE_BUSINESS_UNIT_INFORMATION':
      case 'APPROVE_CENTRAL_MASTER_DATA_INFORMATION':
      case 'APPROVE_PLATFORM_INFORMATION':
      case 'APPROVE_CLIENT_INFORMATION':
      case 'APPROVE_CLIENT_GROUP_INFORMATION':
      case 'APPROVE_BOOKING_CODE_INFORMATION':
      case 'SPECIAL_APPROVE_BOOKING_CODE_INFORMATION':
      case 'APPROVE_OBJECTION_CODE_INFORMATION':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION':
      case 'CREATE_DISBURSEMENT':
      case 'APPROVE_DISBURSEMENT':
      case 'CREATE_DISBURSEMENT_GROUP':
      case 'APPROVE_DISBURSEMENT_GROUP':
      case 'CLIENT_INTERFACE_APPROVAL':
      case 'DEBTOR_INTERFACE_APPROVAL':
      case 'PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK':
      case 'CREATION_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'APPROVE_ANIMAL_WELFARE_SYSTEM_PARAMETER':
      case 'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT':
      case 'OBJECTION_MANAGEMENT':
      case 'PRECHECK_TCI_EXTERNAL':
      case 'APPROVAL_ACCOUNT_STATEMENT_CONFIGURATION':
      case 'APPROVAL_BTC_MAPPING':
      case 'APPROVAL_ANIMAL_WELFARE_LIQUIDITY_PLAN':
      case 'APPROVAL_REPORTING_PERIOD':
      case 'APPROVAL_GOODS_GROUP':
      case 'APPROVAL_QUANTITY_LABELLED_GOOD':
      case 'APPROVAL_QUANTITY_GOOD':
      case 'SEPA_MANDATE_CREATION':
      case 'SEPA_MANDATE_CONFIGURATION':
      case 'SEPA_MANDATE_EXPIRED_CREATE_NEW':
      case 'SEPA_MANDATE_EXPIRED_SET_NEW':
      case 'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR':
      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CREATION':
      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CONFIGURATION':
      case 'TRANSLATION_APPROVAL':
      case 'ACTIVATE_MANUAL_S_BLOCK':
      case 'DEACTIVATE_MANUAL_S_BLOCK':
      case 'APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS':
      case 'AW_RESERVE_BUDGET_STATUS':
      case 'AW_ALLOCATE_BUDGET_STATUS':
      case 'APPROVAL_TRANSACTION_CODE_ALLOCATION':
      case 'APPROVAL_NEW_TRANSACTION_CODE_ALLOCATION':
      case 'AW_TERMINATE_DATE_CHANGE':
      case 'AW_TERMINATE_DATE_DELETE':
      case 'AW_TERMINATE_DATE_SET':
      case 'CANCEL_FUND_DEPOSITS':
      case 'APPROVAL_FUND_DEPOSITS':
      case 'APPROVAL_ROLE_CREATION':
      case 'AW_PRINT_AND_SEND_ANNUAL_BILLING':
      case 'APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CHANGES':
      case 'APPROVAL_USER_CHANGES_INTERNAL':
      case 'EDIT_SALES_FIGURES':
      case 'AW_CHANGES_CONTACT_PERSON':
      case 'APPROVAL_SLAUGHTERHOUSE_FIGURES':
      case 'APPROVAL_PERMISSION_CREATION':
      case 'APPROVAL_EXPORTING_PRINTING_SERVICE':
      case 'APPROVAL_SALES_FIGURES':
      case 'APPROVAL_USER_CREATION':
      case 'AW_INTERFACE_FILE_TRIGGERED_RELEVANT_RETURN_CODES':
      case 'EDIT_SLAUGHTERHOUSE_FIGURES':
      case 'APPROVAL_USER_CHANGES_EXTERNAL':
      case 'APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CREATION':
      case 'APPROVAL_USER_CREATION_EXTERNAL':
      case 'APPROVAL_USER_CHANGES':
      case 'APPROVAL_USER_CREATION_INTERNAL':
      case 'AW_APPROVAL_PARTICIPATION_FEE_FUND_DEPOSITS':
      case 'EDIT_FUND_DEPOSITS':
      case 'CANCEL_SLAUGHTERHOUSE_FIGURES':
      case 'CANCEL_SALES_FIGURES':
        this.router.navigateByUrl('/tasks/' + this.task.id + '/form', {
          state: state,
        });
        break;
      case 'PROCESS_ACQUISITION_CLIENT_PRESCORING':
        this.router.navigate([
          'riskmanagement',
          'tasks',
          this.task.id,
          'prescoring',
        ]);
        break;
      case 'PROCESS_CLIENT_RISK_CHECK':
        this.router.navigate([
          'riskmanagement',
          'tasks',
          this.task.id,
          'risk-check',
        ]);
        break;
      case 'AW_XML_GENERAL_TECHNICAL_ERROR':
      case 'AW_ALREADY_PROCESSED_FILE':
      case 'AW_ALREADY_PROCESSED_FILE_DESCRIPTION':
      case 'AW_INCORRECT_PROCESSING_SEQ':
      case 'AW_RECORD_WITH_MISSING_MASTER_DATA':
      case 'AW_RECORD_WITH_CHANGED_VVVO':
      case 'AW_MISSING_MATCHING_ID':
      case 'AW_MATCHING_RECORD_COLLIDING':
      case 'AW_MATCHING_RECORD_USING_CLOSED_QUARTER':
      case 'AW_INVALID_RECORD':
      case 'AW_INVALID_FILE':
        // Let's keep this only for AW ITP error tasks. Maybe we can move it to their own component later.
        this.router.navigateByUrl('/tasks/' + this.task.id + '/form', {
          state: state,
        });
        break;
      case 'AW_FILE_SET_NOT_NEXT_IN_LINE':
      case 'AW_INCOMPLETE_FILE_SET':
      case 'SUPPORT_REQUEST_USER_MANAGEMENT_CREATION':
      case 'SUPPORT_REQUEST_TECHNICAL_INCIDENT_CREATION':
      case 'SUPPORT_REQUEST_BUSINESS_SUPPORT_REQUEST_CREATION':
      case 'SUPPORT_REQUEST_OTHERS_CREATION':
      case 'CHECK_ACCOUNT_STATE_PROCESSING_ERROR':
      case 'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC':
      case 'AW_BUDGET_EQUAL_ZERO':
      case 'APPROVAL_PAYOUT_SUGGESTION_LIST':
      case 'PAYOUT_SUGGESTION_LIST_EMAIL':
      case 'PAYOUT_SUGGESTION_LIST_PAYOUT':
      case 'AW_SEPA_EXCEL_EMAIL':
      case 'AW_SUSPENSION_BLOCK_MODIFIED':
      case 'SUPPORT_REQUEST_MFA_CREATION':
      case 'USER_SUPPORT_TASK':
      case 'AW_TECHNICAL_ERROR':
      case 'V25_RUNTIME_ERROR':
      case 'CONFIRM_UNUSED_BUDGET_RELEASE_APPROVAL':
        this.completeTask();
        break;
      default:
        this.router.navigateByUrl('/tasks/' + this.task.id + '/form', {
          state: state,
        });
        break;
    }
  }

  completeTask() {
    if (this.payoutSuggestionListEmail) {
      const emailTemplate = {
        language: 'GERMAN',
        action: 'aw',
        fileName: EmailFileName.PAYOUT_SUGGESTION_LIST,
        subjectMap: {
          '[QUARTER]': this.task.params.quarter,
          '[BELATED_TEXT]': '[BELATED_TEXT]',
          '[TYPE]': this.task.params.type,
        },
        contentMap: {
          '[QUARTER]': this.task.params.quarter,
          '[YEAR]': this.task.params.year,
          '[TYPE]': this.task.params.type,
          '[Name]': this.userInfo.firstName,
          '[Email]': this.userInfo.email,
          '[Phone]': '[Phone]',
          '[Fax]': '[Fax]',
        },
      };
      this.emailService.find(emailTemplate).subscribe((emailData: Email) => {
        this.matDialogService
          .open(EmailDraftModalComponent, {
            panelClass: 'email-popup',
            data: emailData,
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (!result) {
              this.notificationService.showToast(
                'NOTIFICATION.EMAIL-CANCEL',
                this.notificationService.MESSAGE_TYPE.ERROR
              );
              return;
            }

            result.emailType = 'O_1010 Email Payout Suggestion List.docx';
            result.entityId = null;
            result.emailId = uuidv4();
            result.businessUnitId = this.task.businessUnitId;
            result.attachFilesFromTask = this.task.id;
            this.emailService.send(result).subscribe(() => {
              this.taskService
                .approveTask(this.task.id, result.comment)
                .subscribe((data) => this.successApproveHandler());
            });
          });
      });
    } else {
      const actionData = ConfirmationModalComponent.ACTION_APPROVE_TASK;
      this.matDialogService
        .open(ConfirmationModalComponent, {
          panelClass: 'confirmation-popup',
          data: { action: actionData },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.taskService
              .approveTask(this.task.id, result.comment)
              .subscribe((data) => this.successApproveHandler());
          }
        });
    }
  }

  rejectTask() {
    const actionData = ConfirmationModalComponent.ACTION_APPROVE_TASK;
    this.matDialogService
      .open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
        data: { action: actionData },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.taskService
            .rejectTask(this.task.id, result.comment, this.task.guid)
            .subscribe((data) => {
              this.showMessage('NOTIFICATION.TASK.REJECTED');
              setTimeout(() => {
                this.router.navigateByUrl('/tasks');
              }, 3000);
            });
        }
      });
  }

  handleError(error) {
    for (const oneError of error) {
      this.notificationService.showToast(
        'ERROR.' + oneError.error,
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  promptBeforeDiscardingChanges() {
    return false;
  }

  getEventType(type: number) {
    return TaskEventType[type];
  }

  forwardTask() {
    const dialogSelection = this.matDialogService.open(
      UserSelectModalComponent,
      {
        panelClass: 'confirmation-popup',
      }
    );
    dialogSelection.afterClosed().subscribe((userInfo) => {
      if (!userInfo) {
        return;
      }

      const dialogConfirm = this.matDialogService.open(
        ConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: { action: ConfirmationModalComponent.ACTION_APPROVE_TASK },
        }
      );
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result && result.event === 'save') {
          this.showMessage('NOTIFICATION.SUCCESS');
          let name;
          if (userInfo.forwardOption === 'ROLE') {
            name = userInfo.role;
          } else if (userInfo.forwardOption === 'USER') {
            name = userInfo.user.username;
          } else if (userInfo.forwardOption === 'TEAM') {
            name = userInfo.team;
          }
          this.taskService
            .forwardTask(
              [this.task.id],
              userInfo.forwardOption,
              name,
              result.comment
            )
            .subscribe(() => {
              this.redirectAfterTimeout(3000);
            });
        }
      });
    });
  }

  showMessage(message: string) {
    this.notificationService.dismissMessage();
    this.notificationService.showToast(
      message,
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }

  unlockTask() {
    this.taskService.unlock(this.task.id).subscribe(
      () => {
        this.showMessage('NOTIFICATION.TASK.UNLOCKED');
        this.redirectAfterTimeout(3000);
      },
      (error) =>
        this.notificationService.showToast(
          'NOTIFICATION.ERROR',
          this.notificationService.MESSAGE_TYPE.ERROR,
          error
        )
    );
  }

  startUploadFile() {
    this.taskService.listFiles(this.task.id).subscribe((data) => {
      this.uploadedFiles = data;
      const dialog = this.matDialogService.open(UploadFilesModalComponent, {
        panelClass: 'confirmation-popup',
        data: { uploadedFiles: this.uploadedFiles },
      });
      dialog.afterClosed().subscribe((result) => {
        if (
          result &&
          result.attachment !== undefined &&
          result.attachment !== ''
        ) {
          this.saveFiles(result.attachment, this.task.id);
        } else {
          this.initTaskData(this.task.id);
        }
      });
    });
  }

  saveFiles(attachment: any, taskId) {
    for (const file of attachment) {
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('taskId', taskId);
      this.taskService.uploadFile(formData).subscribe(() => {
        this.initTaskData(this.task.id);
      });
    }
  }

  retrieveFiles(id: any) {
    this.taskService.listFiles(id).subscribe((data) => {
      this.uploadedFiles = data;
    });
  }

  getLink() {
    return this.task.params.link;
  }

  getObjectId() {
    return this.task.params
      ? this.task.params.objectId
      : this.task.type === 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL'
      ? this.task.objectId
      : '';
  }

  getObjectName() {
    return this.task.params && this.task.params.objectName
      ? this.translateService.instant(
          this.task.params.objectName,
          this.getFormatedParams()
        )
      : '';
  }

  getFormatedParams() {
    const params = Object.assign({}, this.task.params);
    Object.entries(this.task.params).forEach((data) => {
      if (data[0].toString().toLowerCase().includes('date')) {
        const date = new Date(this.task.params[data[0]]);

        if (this.dateFormat && date.toString() !== 'Invalid Date') {
          const result = this.datePipe.transform(date, this.dateFormat);
          params[data[0]] = result;
        }
      } else if (this.task.params[data[0]] && data[0] !== 'objectName') {
        params[data[0]] = this.translateService.instant(
          this.task.params[data[0]]
        );
      }
    });

    return params;
  }

  getCommentParams(params: any) {
    return params && params.paramType
      ? ': ' +
          this.translateService.instant(
            'task.action.params.' + params.paramType
          )
      : '';
  }

  getEntity() {
    switch (this.task.type) {
      case 'APPROVE_BUSINESS_UNIT_INFORMATION':
        this.taskEntity = 'ENTITIES.BU';
        break;

      case 'APPROVE_CENTRAL_MASTER_DATA_INFORMATION':
        this.taskEntity = 'ENTITIES.COMPANY';
        break;

      case 'APPROVE_PLATFORM_INFORMATION':
        this.taskEntity = 'ENTITIES.PLATFORM';
        break;

      case 'APPROVE_CLIENT_INFORMATION':
        this.taskEntity = 'ENTITIES.CLIENT';
        break;

      case 'APPROVE_CLIENT_GROUP_INFORMATION':
        this.taskEntity = 'ENTITIES.CLIENT-GROUP';
        break;

      case 'APPROVE_BOOKING_CODE_INFORMATION':
      case 'SPECIAL_APPROVE_BOOKING_CODE_INFORMATION':
        this.taskEntity = 'ENTITIES.BOOKING-CODE';
        break;

      case 'APPROVE_OBJECTION_CODE_INFORMATION':
        this.taskEntity = 'ENTITIES.OBJECTION-CODE';
        break;

      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'PRECHECK_TCI_EXTERNAL':
        this.taskEntity = 'ENTITIES.TRADE-CREDIT-INSURANCE';
        break;

      case 'CREATE_DISBURSEMENT':
      case 'APPROVE_DISBURSEMENT':
        this.taskEntity = 'ENTITIES.DISBURSEMENT';
        break;

      case 'CREATE_DISBURSEMENT_GROUP':
      case 'APPROVE_DISBURSEMENT_GROUP':
        this.taskEntity = 'ENTITIES.DISBURSEMENT-GROUP';
        break;

      case 'CLIENT_INTERFACE_APPROVAL':
        this.taskEntity = 'ENTITIES.CLIENT-INTERFACE';
        break;

      case 'DEBTOR_INTERFACE_APPROVAL':
        this.taskEntity = 'ENTITIES.DEBTOR-INTERFACE';
        break;

      case 'PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK':
        this.taskEntity = 'ENTITIES.ACQUISITION';
        break;

      case 'CREATION_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
        this.taskEntity = 'ENTITIES.TRADE-CREDIT-INSURANCE-SUBCONTRACT';
        break;

      case 'APPROVE_ANIMAL_WELFARE_SYSTEM_PARAMETER':
        this.taskEntity = 'ENTITIES.SYSTEM-PARAMETER';
        break;

      case 'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT':
        this.taskEntity = 'ENTITIES.ANIMAL-WELFARE.SALES-ENTRY';
        break;

      case 'OBJECTION_MANAGEMENT':
        this.taskEntity = 'ENTITIES.OBJECTION-MANAGEMENT';
        break;

      case 'APPROVAL_ACCOUNT_STATEMENT_CONFIGURATION':
        this.taskEntity = 'ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION';
        break;

      case 'APPROVAL_BTC_MAPPING':
        this.taskEntity = 'ENTITIES.BTC-MAPPING';
        break;

      case 'APPROVAL_ANIMAL_WELFARE_LIQUIDITY_PLAN':
        this.taskEntity = 'ENTITIES.LIQUIDITY-PLAN';
        break;

      case 'APPROVAL_REPORTING_PERIOD':
        this.taskEntity = 'ENTITIES.REPORTING-PERIOD';
        break;

      case 'APPROVAL_GOODS_GROUP':
        this.taskEntity = 'ENTITIES.GOODS-GROUP';

        break;

      case 'APPROVAL_QUANTITY_LABELLED_GOOD':
        this.taskEntity = 'ENTITIES.ANIMAL-WELFARE.QUANTITY-LABELLED-GOOD';
        break;

      case 'APPROVAL_QUANTITY_GOOD':
        this.taskEntity = 'ENTITIES.ANIMAL-WELFARE.QUANTITY-GOOD';
        break;

      case 'SEPA_MANDATE_CREATION':
      case 'SEPA_MANDATE_CONFIGURATION':
      case 'SEPA_MANDATE_EXPIRED_CREATE_NEW':
      case 'SEPA_MANDATE_EXPIRED_SET_NEW':
        this.taskEntity = 'ENTITIES.SEPA-MANDATE';
        break;

      case 'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR':
        this.taskEntity = 'ENTITIES.MASTER-DATA-RECORD';
        break;

      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CREATION':
      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CONFIGURATION':
        this.taskEntity = 'ENTITIES.SUB-LEDGER-ACCOUNT';
        break;

      case 'TRANSLATION_APPROVAL':
        this.taskEntity = 'ENTITIES.TRANSLATION';
        break;

      case 'ACTIVATE_MANUAL_S_BLOCK':
      case 'DEACTIVATE_MANUAL_S_BLOCK':
        this.taskEntity = 'ENTITIES.MANUAL-S-BLOCK';
        break;

      case 'APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS':
        this.taskEntity = 'ENTITIES.LIVESTOCK-PRODUCER-DOCUMENT';
        break;

      case 'AW_RESERVE_BUDGET_STATUS':
      case 'AW_ALLOCATE_BUDGET_STATUS':
        this.taskEntity = 'ENTITIES.BUDGET-STATUS';
        break;

      case 'APPROVAL_TRANSACTION_CODE_ALLOCATION':
      case 'APPROVAL_NEW_TRANSACTION_CODE_ALLOCATION':
        this.taskEntity = 'ENTITIES.TRANSACTION-CODE-ALLOCATION';
        break;

      case 'AW_TERMINATE_DATE_CHANGE':
      case 'AW_TERMINATE_DATE_DELETE':
      case 'AW_TERMINATE_DATE_SET':
        this.taskEntity = 'ENTITIES.AW-TERMINATE-DATE';
        break;

      case 'PROCESS_ACQUISITION_CLIENT_PRESCORING':
        this.taskEntity = 'ENTITIES.ACQUISITION-CLIENT-PRESCORING';
        break;
      case 'PROCESS_CLIENT_RISK_CHECK':
        this.taskEntity = 'ENTITIES.CLIENT-RISK-CHECK';
        break;

      case 'AW_XML_GENERAL_TECHNICAL_ERROR':
        this.taskEntity = 'ENTITIES.XML';
        break;

      case 'AW_ALREADY_PROCESSED_FILE':
      case 'AW_ALREADY_PROCESSED_FILE_DESCRIPTION':
      case 'AW_INVALID_FILE':
      case 'AW_FILE_SET_NOT_NEXT_IN_LINE':
      case 'AW_INCOMPLETE_FILE_SET':
        this.taskEntity = 'ENTITIES.FILE';
        break;
      case 'AW_INCORRECT_PROCESSING_SEQ':
        this.taskEntity = 'ENTITIES.SEQ';
        break;

      case 'AW_RECORD_WITH_MISSING_MASTER_DATA':
      case 'AW_RECORD_WITH_CHANGED_VVVO':
      case 'AW_MATCHING_RECORD_COLLIDING':
      case 'AW_MATCHING_RECORD_USING_CLOSED_QUARTER':
      case 'AW_INVALID_RECORD':
      case 'AW_MISSING_MATCHING_ID':
        this.taskEntity = 'ENTITIES.RECORD';
        break;

      case 'CHECK_ACCOUNT_STATE_PROCESSING_ERROR':
      case 'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC':
        this.taskEntity = 'ENTITIES.ACCOUNT-STATEMENT';
        break;

      case 'AW_BUDGET_EQUAL_ZERO':
        this.taskEntity = 'ENTITIES.BUDGET';
        break;

      case 'APPROVAL_PAYOUT_SUGGESTION_LIST':
      case 'PAYOUT_SUGGESTION_LIST_EMAIL':
      case 'PAYOUT_SUGGESTION_LIST_PAYOUT':
        this.taskEntity = 'ENTITIES.PAYOUT-SUGGESTION-LIST';
        break;

      case 'AW_SEPA_EXCEL_EMAIL':
        this.taskEntity = 'ENTITIES.SEPA-EXCEL';
        break;

      case 'AW_SUSPENSION_BLOCK_MODIFIED':
        this.taskEntity = 'ENTITIES.AW-SUSPENSION-BLOCK';
        break;
      default:
        this.taskEntity = 'UNDEFINED';
        break;
    }

    this.taskEntity = this.translateService.instant(this.taskEntity);
  }

  buttonsHandler(action: taskButtons) {
    switch (action) {
      case taskButtons.UNLOCK:
        break;
      case taskButtons.UPLOAD:
        this.startUploadFile();
        break;
      case taskButtons.START:
        this.startTask();
        break;
      case taskButtons.MODIFY:
        this.modifyTask();
        break;
      case taskButtons.FORWARD:
        this.forwardTask();
        break;
      case taskButtons.REJECT:
        this.rejectTask();
        break;
      case taskButtons.COMPLETE:
        this.completeTask();
        break;
      default:
        break;
    }
  }

  private successApproveHandler() {
    this.showMessage('NOTIFICATION.TASK.APPROVED');
    this.redirectAfterTimeout(3000);
  }

  private redirectAfterTimeout(timeout?: number) {
    setTimeout(
      () => {
        this.router.navigateByUrl('/tasks', {
          state: { success: true },
        });
      },
      timeout ? timeout : 3000
    );
  }
}
