export const taskColumns: string[] = [
  'select',
  'id',
  'type',
  'title',
  'status',
  'createdBy',
  'dueDate',
  'priority',
  'assignedUserId',
  'locked',
  'actions',
];
