export const openItemColumnState = {
  clientDebtorId: true,
  clientOpenItemId: true,
  openItemDate: true,
  bookingCode: true,
  card: true,
  amount: true,
  currency: true,
  vatAmount: true,
  vatPercent: false,
  vatCode: false,
  dueDate: true,
  referenceClientsOpenItemId: false,
  additionalInformation: false,
  branchId: false,
  iban: false,
  bic: false,
  accountHolder: false,
  paymentReference: false,
  paymentScheme: false,
  paymentDate: false,
  debtCollection: true,
  debtCollectionTransferDate: false,
  objectionCode: false,
  objectionDate: false,
  objectionAmount: false,
  listOfDeductions: false,
  listOfDunningInformation: false,
  settlementInEUR: false,
};
