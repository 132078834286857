export const quantityLabeledGoodsCreateColumnsConf: any[] = [
  {
    name: 'suppliedTo',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIED-TO-FROM',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.NAME',
    locked: true,
  },
  {
    name: 'goodsGroup',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.GOODS-GROUP',
    locked: true,
  },

  {
    name: 'type',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE',
    locked: true,
  },
  {
    name: 'quantity',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.QUANTITY',
    locked: true,
  },
  {
    name: 'comment',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT',
    locked: true,
  },
  {
    version: '1',
  },
];
