export const statusFields: any[] = [
  {
    name: 'statusTciContract',
    translateName:
      'TCI.CREATE.FORM-STATUS-TAB.STATUS-TRADE-CREDIT-INSURANCE-CONTRACT',
    methodName: 'getStatusTCIContract',
  },
  {
    name: 'connectionCamInterface',
    translateName: 'TCI.CREATE.FORM-STATUS-TAB.CONNECTION-CAM-INTERFACE',
  },
  {
    name: 'updateNotificationToCreditManager',
    translateName:
      'TCI.CREATE.FORM-STATUS-TAB.UPDATE-NOTIFICATION-CREDIT-MANAGER',
  },
];
