export enum EntityType {
  COMPANY = 'COMPANY',
  CLIENT = 'CLIENT',
  PLATFORM = 'PLATFORM',
  BOOKING_CODE = 'BOOKING_CODE',
  CLIENT_GROUP = 'CLIENT_GROUP',
  OBJECTION_CODE = 'OBJECTION_CODE',
  DEBTOR = 'DEBTOR',
  CLIENT_INTERFACE = 'CLIENT_INTERFACE',
  DEBTOR_INTERFACE = 'DEBTOR_INTERFACE',
  AW_SALES_PARTICIPANT = 'AW_SALES_PARTICIPANT',
  TCI_CONTRACT = 'TCI_CONTRACT',
  SEPA_MANDATE = 'SEPA_MANDATE',
  DOCUMENT_DISPATCH_LOCKS = 'DOCUMENT_DISPATCH_LOCKS',
}
