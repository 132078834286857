import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskEventType } from 'src/app/shared/models/task-event-type';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { QuantityGoodDeleteResponse } from './quantity-good-delete-response';
import { QuantityGoodUpdateResponse } from './quantity-good-update-response';

@Injectable({
  providedIn: 'root',
})
export class TransferQuantityGoodService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private readonly participantServiceUrl;
  private readonly searchApiServiceUrl;
  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
  };
  mapOfColumnFilters = [];
  private searchString = '';

  pageSize;
  sortDirection;
  sortOrder;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.taskServiceUrl = environment.settings.taskService;
    this.participantServiceUrl = environment.settings.participantService;
    this.searchApiServiceUrl = environment.settings.searchAPIUrl;
  }

  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  resetFilters() {
    this.configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: '20',
      pageIndex: '0',
    };
  }

  resetSorting() {
    this.configObj.sortDirection = 'DESC';
    this.configObj.sortField = '';
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  createQuantityGood(quantityGoods, comment, qsId, level) {
    const json = {
      quantityGoods,
      comment,
      operation: 'CREATE',
      qsId,
      level,
    };
    return this.http.post(
      this.serviceUrl + 'quantity-good',
      json,
      this.buildHeaders()
    );
  }

  updateQuantityGood(quantityGoods, comment, taskId, qsId, level) {
    const json = {
      quantityGoods,
      comment,
      operation: 'UPDATE',
      taskId,
      qsId,
      level,
    };
    return this.http.put<QuantityGoodUpdateResponse>(
      this.serviceUrl + 'quantity-good',
      json,
      this.buildHeaders()
    );
  }

  deleteQuantityGood(quantityGoods, comment) {
    const json = {
      quantityGoods,
      comment,
      operation: 'DELETE',
    };
    return this.http.put<QuantityGoodDeleteResponse>(
      this.serviceUrl + 'quantity-good/delete-all',
      json,
      this.buildHeaders()
    );
  }

  request(url, config): Observable<HttpEvent<any[]>> {
    return this.http.get<[]>(this.searchApiServiceUrl + url, config);
  }

  getDistinctValues(
    customConfig: any,
    column: string
  ): Observable<HttpEvent<any>> {
    return this.request(
      `quantity-good/distinct-values/${column}`,
      this.getConfigs(customConfig)
    );
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const allConfigs = customConfigObj
      ? { ...this.configObj, ...customConfigObj }
      : this.configObj;
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(allConfigs).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        config['params'] = config['params'].append(
          key,
          JSON.stringify(allConfigs[key])
        );
      } else {
        config['params'] = config['params'].append(key, allConfigs[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);
    return config;
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  filterList(customConfig?: any): Observable<HttpEvent<any[]>> {
    return this.request('quantity-good', this.getConfigs(customConfig, true));
  }

  findAll() {
    return this.http.get(
      this.serviceUrl + 'quantity-good',
      this.buildHeaders()
    );
  }

  findById(id) {
    return this.http.get(
      this.serviceUrl + 'quantity-good/' + id,
      this.buildHeaders()
    );
  }

  findQuantityGoodByGuid(guid) {
    return this.http.get(
      this.searchApiServiceUrl + 'quantity-good/find-by-id/' + guid,
      this.buildHeaders()
    );
  }

  findAllByLivestockProducerId(
    livestockProducerId,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'quantity-good/by-livestock-producer/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      this.getColumnFilters() +
      '&livestockProducerId=' +
      livestockProducerId;
    return this.http.get<any>(url, this.buildHeaders());
  }

  getFilterFieldNameValues(fieldName, livestockProducerId) {
    return this.http.get(
      this.serviceUrl +
        'quantity-good/by-livestock-producer/filter-values?fieldName=' +
        fieldName +
        '&livestockProducerId=' +
        livestockProducerId,
      this.buildHeaders()
    );
  }

  findAllMasterData() {
    return this.http.get(
      this.participantServiceUrl + 'masterdata',
      this.buildHeaders()
    );
  }

  findAllMasterDataCoordinatorsAndSlaughterhouses() {
    return this.http.get(
      this.participantServiceUrl +
        'masterdata/list-coordinators-and-slaughterhouses',
      this.buildHeaders()
    );
  }

  findMasterDataByID(id) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/' + id,
      this.buildHeaders()
    );
  }

  findAllMasterDataByID(guidArray: any[]) {
    const config = this.buildHeaders();
    let params = new HttpParams();
    guidArray.forEach((guid) => {
      params = params.append('uids', guid);
    });
    config['params'] = params;
    return this.http.get(
      this.participantServiceUrl + 'masterdata/listForIds',
      config
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
