import { Component, OnInit } from '@angular/core';
import { TaskService } from '../../../shared/services/task/task.service';
import { ActivatedRoute } from '@angular/router';
import { TaskConfigurationService } from '../../../shared/services/task-configuration/task-configuration.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { updateTaskOptionsEnum } from '../../../in-memory-data/company/contact-person/enums/update-task-options-enums';

@Component({
  selector: 'app-aw-contact-person-update-task',
  templateUrl: './aw-contact-person-update-task.component.html',
  styleUrls: ['./aw-contact-person-update-task.component.less'],
})
export class AwContactPersonUpdateTaskComponent implements OnInit {
  entityName = 'task';
  task;
  taskConfiguration;
  contactPerson;
  radioOptions = updateTaskOptionsEnum;
  form: UntypedFormGroup;

  constructor(
    private taskService: TaskService,
    private route: ActivatedRoute,
    private taskConfigurationService: TaskConfigurationService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      action: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const taskId = this.route.snapshot.paramMap.get('taskId');
    this.initTaskData(taskId);
  }

  initTaskData(taskId: string) {
    this.taskService.getTask(taskId).subscribe((data) => {
      this.taskService.parseTitle(data);
      this.task = data;
      this.contactPerson = data['params']['updatedContact'];
      this.getTaskConfiguration();
    });
  }

  getTaskConfiguration() {
    this.taskConfigurationService
      .retrieveByType({ type: this.task.type })
      .subscribe((data) => {
        this.taskConfiguration = data;
      });
  }
}
