<div class="info-container" id="{{ entityName }}-accounting-kpi-overview">
  <h5 class="title-info" id="{{ entityName }}-accounting-kpi-overview-entity-title">
    {{ 'OPEN-TRANSACTIONS.TITLE' | translate }}
  </h5>

  <div
    class="container general-display-alignment"
    fxLayout="row wrap"
    fxlayout.md="column"
    fxlayout.sm="column"
    fxlayout.xs="column"
    fxLayoutGap="7px grid"
  >
    <div *ngFor="let kpi of kpiList" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="70">
          <div class="label-container">
            <label for="kpiInfo" id="{{ entityName }}-accounting-kpi-overview-entity-{{ kpi.kpi }}-label">{{
              kpi.translateName | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="30">
          <div class="span-container number-alignment">
            <span
              *ngIf="!kpi.type"
              name="kpiInfo"
              class="information"
              id="{{ entityName }}-accounting-kpi-overview-entity-{{ kpi.info }}-value"
              >{{ kpi.value ? kpi.value : 0 }}</span
            >
            <span
              *ngIf="kpi.type && kpi.type == 'currency'"
              name="kpiInfo"
              class="information"
              id="{{ entityName }}-accounting-kpi-overview-entity-{{ kpi.info }}-value"
              >{{ (kpi.value ? kpi.value : 0) | currency: 'EUR':'symbol':'1.2-2':'de' }}</span
            >
            <span
              *ngIf="kpi.type && kpi.type == 'date'"
              name="kpiInfo"
              class="information"
              id="{{ entityName }}-accounting-kpi-overview-entity-{{ kpi.info }}-value"
              >{{ kpi.value | date: dateFormat }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
