import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { CompanyModule } from '../company/company.module';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessUnitModule } from '../business-unit/business-unit.module';
import { MasterDataManagementRoutingModule } from './master-data-management-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [BaseComponent],
  imports: [
    CommonModule,
    MasterDataManagementRoutingModule,
    MatTabsModule,
    BusinessUnitModule,
    CompanyModule,
    TranslateModule,
    SharedModule,
  ],
})
export class MasterDataManagementModule {}
