export const settlementRelevantOptions: any[] = [
  {
    value: true,
    translateName: 'BOOKING-CODE.YES',
  },
  {
    value: false,
    translateName: 'BOOKING-CODE.NO',
  },
];
