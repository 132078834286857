export const planActualValuesEnum: any[] = [
  {
    name: 'null',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.ALL',
  },
  {
    name: 'PLAN',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.ONLY-PLAN-VALUES',
  },
  {
    name: 'ACTUAL',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.ONLY-ACTUAL-VALUES',
  },
];
