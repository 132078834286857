import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { liquidityPlanAssetsColumns } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/liquidity-assets/table-columns';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { kindAssetEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/kind-asset-enums';
import { typeAssetEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/type-asset-enums';
import { monthEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/month-enums';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import * as _moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';

const moment = _rollupMoment || _moment;

export const DATE_FORMATS = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-animal-welfare-liquidity-plan-assets',
  templateUrl: './liquidity-plan-assets.component.html',
  styleUrls: ['./liquidity-plan-assets.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class LiquidityPlanAssetsComponent
  implements OnInit, DirtyComponent, AfterViewInit
{
  entityName = 'animal-welfare-liquidity-plan-assets';
  liquidityPlanAssetsForm: UntypedFormGroup;
  @Input() selectedLiquidityPlan;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = liquidityPlanAssetsColumns;
  editableTask: boolean;
  hideButtons: boolean;
  state;
  kinds = kindAssetEnum;
  types = typeAssetEnum;
  months = monthEnum;
  index = 0;
  liquidityPlanId;
  liquidityPlanMode: 'create' | 'update' | 'cancellation' | 'enterActualValue';
  isTask = false;
  approval = false;
  liquidityPlanAssetsTask;
  liquidityPlanAssetsApproved;
  draftId;
  cancellationView = false;
  snapshot;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private liquidityPlanService: LiquidityPlanService,
    private matDialogService: MatDialog,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private draftService: DraftService,
    private taskService: TaskService,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.translateService.onLangChange.subscribe(() => this.updateLanguage());
  }

  ngOnInit() {
    this.dataSource.data = [];
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    this.liquidityPlanAssetsFormBuilder();
    this.hideOrShowButtons();
    this.addNewAssetChange();
    this.loadTaskView();
    this.getDateFormat();
  }

  ngAfterViewInit() {
    this.updateLanguage();
  }

  getDateFormat() {
    let format;
    this.userService.getSettings().subscribe((data) => {
      if (data && data.profile) {
        format = data.profile.dateFormat;
      }
      if (format === 'GERMAN') {
        this.changeDateFormat('de');
        DATE_FORMATS.display.dateInput = 'MM.YYYY';
        DATE_FORMATS.parse.dateInput = 'MM.YYYY';
        return 'MM.yyyy';
      } else if (format === 'BRITISH') {
        this.changeDateFormat('en-GB');
        DATE_FORMATS.display.dateInput = 'MM/YYYY';
        DATE_FORMATS.parse.dateInput = 'MM/YYYY';
        return 'MM/yyyy';
      } else if (format === 'AMERICAN') {
        this.changeDateFormat('en-US');
        DATE_FORMATS.display.dateInput = 'MM-YYYY';
        DATE_FORMATS.parse.dateInput = 'MM-YYYY';
        return 'MM-yyyy';
      } else {
        return 'MM.yyyy';
      }
    });
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }

  chosenYearHandler(normalizedYear: Moment, element) {
    let formGroup: any = null;
    formGroup = this.liquidityPlanAssetsObjects.controls.find(
      (item) => item.value.index === element.index
    );

    formGroup.get('monthAndYear').setValue(moment());
    const ctrlValue = formGroup.get('monthAndYear').value;
    ctrlValue.year(normalizedYear.year());
    formGroup.get('monthAndYear').setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>,
    element
  ) {
    let formGroup: any = null;
    formGroup = this.liquidityPlanAssetsObjects.controls.find(
      (item) => item.value.index === element.index
    );
    const ctrlValue = formGroup.get('monthAndYear').value;
    ctrlValue.month(normalizedMonth.month());
    formGroup.get('monthAndYear').setValue(ctrlValue);
    datepicker.close();
  }

  formatDate(dateP) {
    if (dateP) {
      const date = new Date(dateP);
      const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1;
      date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);
      return date;
    } else {
      return;
    }
  }

  getLiquidityPlanId() {
    if (this.selectedLiquidityPlan) {
      this.selectedLiquidityPlan.subscribe((value) => {
        if (value) {
          this.liquidityPlanId = value.id;
        }
      });
    } else if (this.state.selectedLiquidityPlan) {
      this.liquidityPlanId = this.state.selectedLiquidityPlan.id;
    }
  }

  liquidityPlanAssetsFormBuilder() {
    this.liquidityPlanAssetsForm = this.formBuilder.group({
      liquidityPlanAssets: new UntypedFormArray([]),
    });
  }

  getFormGroup(element) {
    let formGroup: any = null;
    formGroup = this.liquidityPlanAssetsObjects.controls.find(
      (item) => item.value.index === element.index
    );

    return formGroup;
  }

  get formControls() {
    return this.liquidityPlanAssetsForm.controls;
  }

  get liquidityPlanAssetsObjects() {
    return this.formControls.liquidityPlanAssets as UntypedFormArray;
  }

  hideOrShowButtons() {
    this.liquidityPlanService.currentEditMode.subscribe((value) => {
      this.displayedColumns = [...liquidityPlanAssetsColumns];
      if (value && value.hideButtons) {
        this.hideButtons = true;
      } else {
        this.hideButtons = false;
        this.displayedColumns.push('remove');
      }
    });
  }

  updateLanguage() {
    this.liquidityPlanAssetsObjects.controls.forEach((element) => {
      if (
        element.get('meaning').value === 'Original value' ||
        element.get('meaning').value === 'Ursprünglicher Wert'
      ) {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ORIGINAL'
            )
          );
      } else if (
        element.get('meaning').value === 'Cancellation' ||
        element.get('meaning').value === 'Stornierung'
      ) {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.CANCELLATION-OF-ORIGINAL'
            )
          );
      } else if (
        element.get('meaning').value === 'New value' ||
        element.get('meaning').value === 'Neuer Wert'
      ) {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.UPDATED-VERSION'
            )
          );
      } else if (element.get('meaning').value === 'Plan') {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
            )
          );
      } else if (
        element.get('meaning').value === 'Actual' ||
        element.get('meaning').value === 'Istwert'
      ) {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
            )
          );
      } else if (
        element.get('meaning').value === 'Cancel' ||
        element.get('meaning').value === 'Stornieren'
      ) {
        element
          .get('meaning')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CANCEL'
            )
          );
      }
      if (element.get('planActual').value === 'Plan') {
        element
          .get('planActual')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
            )
          );
      } else if (
        element.get('planActual').value === 'Actual' ||
        element.get('planActual').value === 'Istwert'
      ) {
        element
          .get('planActual')
          .patchValue(
            this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
            )
          );
      }
    });
  }

  hasChanged(element, property, propertyValue?) {
    let formGroup: any = null;
    formGroup = this.liquidityPlanAssetsObjects.controls.find(
      (item) => item.value.index === element.index
    );
    if (formGroup) {
      if (this.liquidityPlanAssetsApproved && !this.liquidityPlanAssetsTask) {
        if (formGroup.value.index !== 0) {
          if (typeof formGroup.getRawValue()[property] === 'object') {
            if (
              this.liquidityPlanAssetsApproved[formGroup.value.index][property][
                propertyValue
              ]
            ) {
              if (
                this.liquidityPlanAssetsApproved[formGroup.value.index][
                  property
                ][propertyValue] !==
                formGroup.getRawValue()[property][propertyValue]
              ) {
                return true;
              } else {
                return false;
              }
            } else if (
              this.liquidityPlanAssetsApproved[formGroup.value.index][
                property
              ] !== formGroup.getRawValue()[property]
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (formGroup.value.index === 1 && property === 'planAmount') {
              return false;
            } else {
              if (
                this.liquidityPlanAssetsApproved[formGroup.value.index][
                  property
                ]
              ) {
                if (
                  this.liquidityPlanAssetsApproved[formGroup.value.index][
                    property
                  ] !== formGroup.getRawValue()[property]
                ) {
                  return true;
                } else {
                  return false;
                }
              } else {
                if (formGroup.getRawValue()[property].length > 0) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          }
        } else {
          return false;
        }
      } else if (
        this.liquidityPlanAssetsTask &&
        !this.liquidityPlanAssetsApproved
      ) {
        if (typeof formGroup.getRawValue()[property] === 'object') {
          if (
            this.liquidityPlanAssetsTask[formGroup.value.index][property] !==
            formGroup.getRawValue()[property][propertyValue]
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            this.liquidityPlanAssetsTask[formGroup.value.index][property] !==
            formGroup.getRawValue()[property]
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else if (
        this.liquidityPlanAssetsTask &&
        this.liquidityPlanAssetsApproved
      ) {
        if (
          formGroup.value.index === 0 ||
          (formGroup.value.index === 1 &&
            property === 'planAmount' &&
            this.state.task.params.request !== 'ADD_ACTUAL')
        ) {
          return false;
        } else {
          if (typeof formGroup.getRawValue()[property] === 'object') {
            if (
              this.liquidityPlanAssetsApproved[0][property] !==
              formGroup.getRawValue()[property][propertyValue]
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              this.liquidityPlanAssetsApproved[0][property] !==
              formGroup.getRawValue()[property]
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loadTaskView() {
    if (this.router.url.includes('/tasks')) {
      this.loadTaskData();
    } else {
      this.liquidityPlanService.updateEditModeState(false);
      this.hideButtons = false;
      this.isTask = false;
    }
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.liquidityPlanAssetsForm.dirty;
  }

  canDeactivate() {
    return this.liquidityPlanAssetsForm.dirty && !this.approval;
  }

  loadTaskData() {
    this.resetForm();
    this.liquidityPlanService.updateEditModeState({
      showAssetChange: true,
      hideButtons: true,
    });
    this.hideButtons = true;
    this.isTask = true;
    if (this.state.task.params.request === 'CANCEL') {
      this.cancellationView = true;
    } else {
      this.cancellationView = false;
    }
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        this.draftId = data.guid;
        this.liquidityPlanAssetsTask = data.assetChanges;
        data.assetChanges.forEach((value, index) => {
          if (value.id) {
            this.liquidityPlanAssetsApproved = [];
            this.liquidityPlanAssetsApproved.push(value);
          }
          let meaning;
          let planOrActualValue;
          if (this.state.task.params.request === 'CREATE') {
            meaning = this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ORIGINAL'
            );
            planOrActualValue = this.translateService.instant(
              'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
            );
          } else if (this.state.task.params.request === 'UPDATE') {
            if (index === 0) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ORIGINAL'
              );
            } else if (index === 1) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.CANCELLATION-OF-ORIGINAL'
              );
            } else if (index === 2) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.UPDATED-VERSION'
              );
            }
          } else if (this.state.task.params.request === 'ADD_ACTUAL') {
            if (index === 0) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
              );
              planOrActualValue = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
              );
            } else if (index === 1) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
              );
              planOrActualValue = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
              );
            }
          } else if (this.state.task.params.request === 'CANCEL') {
            if (index === 1) {
              meaning = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CANCEL'
              );
            }

            if (value.planOrActualValue === 'PLAN') {
              planOrActualValue = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
              );
              if (index === 0) {
                meaning = this.translateService.instant(
                  'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
                );
              }
            } else if (value.planOrActualValue === 'ACTUAL') {
              planOrActualValue = this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
              );
              if (index === 0) {
                meaning = this.translateService.instant(
                  'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
                );
              }
            }
          }
          this.liquidityPlanAssetsObjects.push(
            this.formBuilder.group({
              meaning: [
                {
                  value: meaning,
                  disabled: true,
                },
              ],
              planAmount: [
                {
                  value: value.actualAmount
                    ? value.actualAmount
                    : value.planAmount,
                  disabled: true,
                },
              ],
              kind: [{ value: value.kind, disabled: true }],
              commentOthers: [{ value: value.commentOthers, disabled: true }],
              type: [{ value: value.type, disabled: true }],
              planActual: [{ value: planOrActualValue, disabled: true }],
              monthAndYear: [{ value: value.monthAndYear, disabled: true }],
              comment: [{ value: value.comment, disabled: true }],
              index: [this.index],
              liquidityPlanGuid: value.liquidityPlanGuid,
              id: [value.id],
              status: [value.status],
              operationUser: [value.operationUser],
            })
          );
          this.dataSource.data.push({ index: this.index });
          this.dataSource._updateChangeSubscription();
          this.index++;
        });
      });
  }

  makeEditable() {
    this.editableTask = true;
    this.taskService.updateEditableTask(true);
    this.liquidityPlanAssetsForm.enable();
    this.liquidityPlanAssetsObjects.controls.forEach((item) => {
      this.enableDisableType(item, false);
    });
    if (this.state.task.params.request === 'UPDATE') {
      this.liquidityPlanAssetsObjects.controls[0].get('comment').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('planAmount').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('kind').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('type').disable();
      this.liquidityPlanAssetsObjects.controls[0]
        .get('commentOthers')
        .disable();
      this.liquidityPlanAssetsObjects.controls[0].get('monthAndYear').disable();
      this.liquidityPlanAssetsObjects.controls[1].get('comment').enable();
      this.liquidityPlanAssetsObjects.controls[1].get('planAmount').disable();
      this.liquidityPlanAssetsObjects.controls[1].get('kind').disable();
      this.liquidityPlanAssetsObjects.controls[1].get('type').disable();
      this.liquidityPlanAssetsObjects.controls[1]
        .get('commentOthers')
        .disable();
      this.liquidityPlanAssetsObjects.controls[1].get('monthAndYear').disable();
    } else if (this.state.task.params.request === 'ADD_ACTUAL') {
      this.liquidityPlanAssetsObjects.controls[0].get('comment').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('planAmount').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('kind').disable();
      this.liquidityPlanAssetsObjects.controls[0].get('type').disable();
      this.liquidityPlanAssetsObjects.controls[0]
        .get('commentOthers')
        .disable();
      this.liquidityPlanAssetsObjects.controls[0].get('monthAndYear').disable();
      this.liquidityPlanAssetsObjects.controls[1].get('comment').enable();
      this.liquidityPlanAssetsObjects.controls[1].get('planAmount').enable();
      this.liquidityPlanAssetsObjects.controls[1].get('kind').disable();
      this.liquidityPlanAssetsObjects.controls[1].get('type').disable();
      this.liquidityPlanAssetsObjects.controls[1]
        .get('commentOthers')
        .disable();
      this.liquidityPlanAssetsObjects.controls[1].get('monthAndYear').disable();
    }
  }

  cancelEditable() {
    if (this.liquidityPlanAssetsObjects.touched) {
      const dialog = this.matDialogService.open(
        GeneralDeleteConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: {
            deleteMessage: this.translateService.instant(
              'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
            ),
          },
        }
      );
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.editableTask = false;
          this.taskService.updateEditableTask(false);
          this.loadTaskData();
          this.liquidityPlanAssetsForm.disable();
        }
      });
    } else {
      this.editableTask = false;
      this.taskService.updateEditableTask(false);
      this.loadTaskData();
      this.liquidityPlanAssetsForm.disable();
    }
  }

  saveDraft() {
    const value = this.liquidityPlanAssetsObjects.getRawValue();
    value.forEach((assetChange) => {
      assetChange.inputDate = new Date();
      if (assetChange.planActual === 'Plan') {
        assetChange.planOrActualValue = 'PLAN';
        assetChange.actualAmount = 0;
      } else if (assetChange.planActual === 'Actual') {
        assetChange.planOrActualValue = 'ACTUAL';
        assetChange.actualAmount = assetChange.planAmount;
        assetChange.planAmount = 0;
      }
    });
    const originalValue =
      this.liquidityPlanAssetsForm.getRawValue().liquidityPlanAssets[0];
    this.liquidityPlanService
      .updateAssetChangeDraft(
        value,
        this.state.task.params.request,
        originalValue.id,
        this.state.task.guid
      )
      .subscribe((_) => {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
        setTimeout(() => {
          this.router.navigateByUrl('tasks', {
            state: { success: true },
          });
        }, 1500);
      }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'));
  }

  addNewAssetChange() {
    this.liquidityPlanService.newAssetChange.subscribe((value) => {
      if (value !== null) {
        this.dataSource.data = [];
        this.liquidityPlanAssetsObjects.clear();
        this.add(value);
      } else if (this.snapshot.params.guid) {
        this.liquidityPlanService
          .getAssetChangeByGuid(this.snapshot.params.guid)
          .subscribe((data) => {
            this.dataSource.data = [];
            this.liquidityPlanAssetsObjects.clear();
            this.hideButtons = true;
            this.add(data);
          });
      } else if (
        value === null &&
        !this.state.task &&
        !this.snapshot.params.guid &&
        !this.state.selectedLiquidityPlan
      ) {
        this.router.navigateByUrl('liquidityPlan');
      } else {
        this.dataSource.data = [];
        this.liquidityPlanAssetsObjects.clear();
        this.getLiquidityPlanId();
        this.add();
      }
    });
  }

  returnMonthName(existingMonth) {
    this.months.forEach((month) => {
      if (existingMonth === month.name) {
        existingMonth = month;
      }
    });
    return existingMonth;
  }

  add(value?) {
    if (value) {
      this.index = 0;
      let planOrActualValue;
      if (value.planOrActualValue === 'PLAN') {
        planOrActualValue = this.translateService.instant(
          'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN'
        );
      } else if (value.planOrActualValue === 'ACTUAL') {
        planOrActualValue = this.translateService.instant(
          'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL'
        );
      }
      this.liquidityPlanMode = 'update';
      this.liquidityPlanAssetsObjects.push(
        this.formBuilder.group({
          meaning: [
            {
              value: this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ORIGINAL'
              ),
              disabled: true,
            },
          ],
          planAmount: [
            {
              value: value.planAmount ? value.planAmount : value.actualAmount,
              disabled: true,
            },
          ],
          kind: [{ value: value.kind, disabled: true }],
          commentOthers: [{ value: value.commentOthers, disabled: true }],
          type: [{ value: value.type, disabled: true }],
          planActual: [{ value: planOrActualValue, disabled: true }],
          monthAndYear: [{ value: value.monthAndYear, disabled: true }],
          comment: [{ value: value.comment, disabled: true }],
          index: [this.index],
          liquidityPlanGuid: value.liquidityPlanGuid
            ? value.liquidityPlanGuid
            : this.liquidityPlanId,
          id: [value.id],
        })
      );

      this.dataSource.data.push({ index: this.index });
      this.index++;

      this.liquidityPlanAssetsObjects.push(
        this.formBuilder.group({
          meaning: [
            {
              value: this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.CANCELLATION-OF-ORIGINAL'
              ),
              disabled: true,
            },
          ],
          planAmount: [
            {
              value: value.planAmount
                ? value.planAmount * -1
                : value.actualAmount * -1,
              disabled: true,
            },
          ],
          kind: [{ value: value.kind, disabled: true }],
          commentOthers: [{ value: value.commentOthers, disabled: true }],
          type: [{ value: value.type, disabled: true }],
          planActual: [{ value: planOrActualValue, disabled: true }],
          monthAndYear: [{ value: value.monthAndYear, disabled: true }],
          comment: [
            { value: value.comment, disabled: false },
            Validators.required,
          ],
          index: [this.index],
          liquidityPlanGuid: value.liquidityPlanGuid
            ? value.liquidityPlanGuid
            : this.liquidityPlanId,
          id: [value.id],
        })
      );

      this.dataSource.data.push({ index: this.index });
      this.index++;

      this.liquidityPlanAssetsObjects.push(
        this.formBuilder.group({
          meaning: [
            {
              value: this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.UPDATED-VERSION'
              ),
              disabled: true,
            },
          ],
          planAmount: [
            value.planAmount ? value.planAmount : value.actualAmount,
            Validators.required,
          ],
          kind: [value.kind, Validators.required],
          commentOthers: [
            {
              value: value.commentOthers,
              disabled: value.commentOthers ? false : true,
            },
            Validators.required,
          ],
          type: [
            { value: value.type, disabled: value.commentOthers ? false : true },
            Validators.required,
          ],
          planActual: [{ value: planOrActualValue, disabled: true }],
          monthAndYear: [value.monthAndYear, Validators.required],
          comment: [value.comment],
          index: [this.index],
          liquidityPlanGuid: value.liquidityPlanGuid
            ? value.liquidityPlanGuid
            : this.liquidityPlanId,
        })
      );
      this.dataSource.data.push({ index: this.index });
      this.index++;
      this.liquidityPlanAssetsApproved =
        this.liquidityPlanAssetsObjects.getRawValue();
    } else {
      this.liquidityPlanMode = 'create';
      this.liquidityPlanAssetsObjects.push(
        this.formBuilder.group({
          meaning: [
            {
              value: this.translateService.instant(
                'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ORIGINAL'
              ),
              disabled: true,
            },
          ],
          planAmount: [null, Validators.required],
          kind: ['', Validators.required],
          commentOthers: [{ value: '', disabled: true }, Validators.required],
          type: [{ value: '', disabled: true }, Validators.required],
          planActual: [{ value: 'Plan', disabled: true }],
          monthAndYear: ['', Validators.required],
          comment: [''],
          index: [this.index],
          liquidityPlanGuid: this.liquidityPlanId,
          id: [null],
        })
      );
      const dynamicRows: any = {
        index: this.index,
      };
      this.dataSource.data.push(dynamicRows);
      this.index++;
    }
    this.dataSource.filter = '';
  }

  getRequiredStatus(formGroup) {
    let result = false;
    if (this.liquidityPlanMode === 'update') {
      if (formGroup.value.index === 1) {
        result = true;
      }
    } else if (this.state.task && this.state.task.params.request !== 'CREATE') {
      if (formGroup.value.index === 1) {
        result = true;
      }
    }
    return result;
  }

  save() {
    this.approval = true;
    if (this.liquidityPlanMode === 'create') {
      const value =
        this.liquidityPlanAssetsForm.getRawValue().liquidityPlanAssets;
      value.forEach((assetChange) => {
        assetChange.inputDate = new Date();
        assetChange.monthAndYear = this.formatDate(assetChange.monthAndYear);
      });
      this.liquidityPlanService.createAssetChange(value).subscribe(() => {
        this.liquidityPlanService.updateEditModeState(false);
        this.router.navigateByUrl('liquidityPlan');
        this.resetForm();
        this.notificationService.showToast(
          'NOTIFICATION.CREATED',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'
            ),
          }
        );
      }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'));
    } else if (this.liquidityPlanMode === 'update') {
      const value =
        this.liquidityPlanAssetsForm.getRawValue().liquidityPlanAssets[2];
      const cancelledValue =
        this.liquidityPlanAssetsForm.getRawValue().liquidityPlanAssets[1];
      value.inputDate = new Date();
      this.liquidityPlanService
        .updateAssetChange(value, cancelledValue.comment, cancelledValue.id)
        .subscribe(() => {
          this.liquidityPlanService.updateEditModeState(false);
          if (!this.isTask) {
            this.router.navigateByUrl('liquidityPlan');
          }
          this.resetForm();
          this.notificationService.showToast(
            'NOTIFICATION.CREATED',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'
              ),
            }
          );
        }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'));
    } else {
      console.warn(
        'oooopps! There is an unknown liquidity plan mode!',
        this.liquidityPlanMode
      );
    }
  }

  remove(element) {
    const arrayIndex = this.dataSource.data.findIndex(
      (row) => row.index === element.index
    );
    if (arrayIndex !== -1) {
      this.dataSource.data.splice(arrayIndex, 1);
      this.liquidityPlanAssetsObjects.removeAt(arrayIndex);
      this.dataSource._updateChangeSubscription();
    }
  }

  removeLastDirectoryPart(url) {
    const urlArr = url.split('/');
    urlArr.pop();
    return urlArr.join('/');
  }

  resetForm() {
    this.liquidityPlanAssetsForm.reset();
    this.liquidityPlanAssetsObjects.clear();
    this.dataSource.data = [];
    this.index = 0;
  }

  kindChanged(event, element) {
    const formGroup = this.getFormGroup(element);
    this.enableDisableType(formGroup, true);
  }

  enableDisableType(formGroup, clearType) {
    const value = formGroup.get('kind').value;
    formGroup.get('meaning').disable();
    formGroup.get('planActual').disable();
    if (
      value === 'DEPOSIT_FRT' ||
      value === 'REVERSAL_OF_ACCRUALS' ||
      value === 'NOT_NEEDED_OPERATING_COSTS' ||
      value === 'WITHDRAWAL_LIQUIDITY_FROM_A_PREVIOUS_PROGRAM' ||
      value === 'RECOVERY_FROM_LIVESTOCK_PRODUCERS' ||
      value === 'UNUSED_FONDS_OF_LIVESTOCK_PRODUCERS' ||
      value === 'ACCOUNTING_AUDITOR' ||
      value === 'ACCRUALS_OPERATING_COSTS'
    ) {
      formGroup.get('type').setValue('EARNING');
      formGroup.get('type').disable();
      formGroup.get('commentOthers').disable();
      formGroup.get('commentOthers').setValue('');
    } else if (value === 'OTHERS') {
      formGroup.get('type').enable();
      formGroup.get('commentOthers').enable();
      if (clearType) {
        formGroup.get('type').setValue('');
      }
    } else {
      formGroup.get('type').setValue('EXPENSE');
      formGroup.get('type').disable();
      formGroup.get('commentOthers').disable();
      formGroup.get('commentOthers').setValue('');
    }
  }
}
