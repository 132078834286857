import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransferQuantityLabeledGoodsManagementComponent } from './transfer-quantity-labelled-goods-management/transfer-quantity-labeled-goods-management.component';
import { QuantityLabeledGoodsListComponent } from '../quantity-labeled-goods/quantity-labeled-goods-list/quantity-labeled-goods-list.component';
import { QuantityLabeledGoodsCreateComponent } from '../quantity-labeled-goods/quantity-labeled-goods-create/quantity-labeled-goods-create.component';
import { ReportingPeriodsListComponent } from '../reporting-periods/reporting-periods-list/reporting-periods-list.component';
import { ReportingPeriodsCreateComponent } from '../reporting-periods/reporting-periods-create/reporting-periods-create.component';
import { GoodsGroupsListComponent } from '../goods-groups/goods-groups-list/goods-groups-list.component';
import { GoodsGroupsCreateComponent } from '../goods-groups/goods-groups-create/goods-groups-create.component';
import { DirtyCheckGuard } from 'src/app/shared/guards/dirty-check.guard';

const routes: Routes = [
  {
    path: '',
    component: TransferQuantityLabeledGoodsManagementComponent,
    children: [
      {
        path: 'quantity',
        component: QuantityLabeledGoodsListComponent,
        data: { roles: ['aw.lg.r'] },
      },
      {
        path: 'quantity/create',
        component: QuantityLabeledGoodsCreateComponent,
        data: { roles: ['aw.lg.c'] },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'reportingPeriods',
        component: ReportingPeriodsListComponent,
        data: { roles: ['aw.lg.r'] },
      },
      {
        path: 'reportingPeriods/create',
        component: ReportingPeriodsCreateComponent,
        data: { roles: ['aw.lg.c'] },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'goodsGroups',
        component: GoodsGroupsListComponent,
        data: { roles: ['aw.lg.r'] },
      },
      {
        path: 'goodsGroups/create',
        component: GoodsGroupsCreateComponent,
        data: { roles: ['aw.lg.c'] },
        canDeactivate: [DirtyCheckGuard],
      },
      { path: '', redirectTo: 'quantity', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AwQuantityLabeledGoodsRoutingModule {
  static e = '';
}
