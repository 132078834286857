import { TableTypes } from '../../default-table/table-types';

export const quantityGoodsColumnsConf: any[] = [
  {
    type: TableTypes.DATE,
    name: 'submissionDate',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'measureUnit',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'vvvoNr',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'productionType',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE',
    locked: true,
  },
  {
    type: TableTypes.NUMBER,
    name: 'amountGood',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.AMOUNT',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'qsid',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID',
    locked: true,
  },
  {
    type: TableTypes.TRANSLATED_STRING,
    name: 'transfer',
    index: 6,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER',
    locked: true,
    translatePrefix: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER-OPTIONS.',
  },
  {
    type: TableTypes.STRING,
    name: 'comment',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT',
    locked: true,
  },
  {
    type: TableTypes.TRANSLATED_STRING,
    name: 'status',
    index: 8,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS',
    locked: true,
    translatePrefix: 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.',
  },
  {
    type: TableTypes.STRING,
    name: 'createdBy',
    index: 9,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.CREATED-BY',
    locked: true,
  },
  {
    type: TableTypes.DATE,
    name: 'createdOn',
    index: 10,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.CREATED-ON',
    locked: true,
    dateFormat: 'fullYear',
  },
  {
    type: TableTypes.STRING,
    name: 'approvedBy',
    index: 11,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-BY',
    locked: true,
  },
  {
    type: TableTypes.DATE,
    name: 'approvedOn',
    index: 12,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON',
    locked: true,
    dateFormat: 'fullYear',
  },
  {
    version: 3.2,
  },
];
