<app-back-button></app-back-button>
<div class="tab-container">
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      [routerLink]="['subLedgerAccountsList']"
      *hasPermission="'aw.sl.r'"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-sub-ledger-accounts-list"
      >{{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT-OVERVIEW' | translate }}</a
    >
  </nav>
</div>
<div class="content-container">
  <router-outlet></router-outlet>
</div>
