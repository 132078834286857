<div *ngIf="hasPermissionsForSBlockSetsetActiveOrInactive()" class="input-wrapper form-container s-block-form">
  <form class="form" autocomplete="off" [formGroup]="functionalButtonsFrom">
    <app-mat-slider
      [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.S-BLOCK-BUTTON-LABEL'"
      [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.S-BLOCK-BUTTON-LABEL'"
      [entityName]="entityName"
      [form]="functionalButtonsFrom"
      [approvedValue]="null"
      [taskValue]="null"
      [controlName]="'isSblock'"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
    ></app-mat-slider>
  </form>
</div>
<div class="content-container">
  <div class="task-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      (matSortChange)="sortData($event)"
      aria-hidden="true"
    >
      <ng-container matColumnDef="blocking_id">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-blocking-id"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.ID' | translate }}
          <app-table-menu-sort-filter
            choice="blocking_id"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-blocking-id-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.blockingId | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type_of_blockage">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-type-of-blockage"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.TYPE-OF-BLOCKAGE' | translate }}
          <app-table-menu-sort-filter
            choice="type_of_blockage"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-type-of-blockage-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getTypeOfBlockageString(element.typeOfBlockage) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="start_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-start-date"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.START-DATE' | translate }}
          <app-table-menu-sort-filter
            choice="start_date"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
            [sortableDate]="true"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-start-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.startDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="end_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-end-date"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.END-DATE' | translate }}
          <app-table-menu-sort-filter
            choice="end_date"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
            [sortableDate]="true"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-end-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.endDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-status"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.STATUS' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-status-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getStatusString(element.inactive) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-comment"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.COMMENT' | translate }}
          <app-table-menu-sort-filter
            choice="comment"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.comment | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="$event.stopPropagation()"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
      <tr [hidden]="loaderSpinner" mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="20"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
