<div class="info-container aw-contact-person-update-task" id="{{ entityName }}-general-info">
  <h5 class="title-info">
    {{ 'TASK-CONFIGURATION.DESCRIPTION' | translate }}
  </h5>

  <div
    *ngIf="task"
    class="container"
    fxLayout="row wrap"
    fxlayout.sm="column"
    fxlayout.xs="column"
    fxLayoutGap="7px grid"
  >
    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="15">
          <div class="label-container">
            <label id="{{ entityName }}-general-descripton-label">{{
              'TASK-CONFIGURATION.DESCRIPTION' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="35">
          <div class="span-container">
            <span
              *ngIf="taskConfiguration && taskConfiguration.description"
              name="taskID"
              class="information break-line"
              id="{{ entityName }}-general-info-description-value"
              >{{ taskConfiguration.description | translate: task.params }}</span
            >
          </div>
        </div>
        <div fxFlex="40">
          <div class="label-container">
            <label id="{{ entityName }}-general-descripton-label">{{ 'GENERAL.ACTION' | translate }}</label>
          </div>
          <div class="span-container">
            <form class="form" [formGroup]="form" autocomplete="off">
              <app-mat-radio
                [name]="'GENERAL.ACTION'"
                [label]="'CONTACT-PERSON-TASK.ACTION-LABEL'"
                [entityName]="entityName"
                [form]="form"
                [approvedValue]="form.value.action"
                [taskValue]="form.value.action"
                [controlName]="'action'"
                [controlObject]="form.controls.action"
                [titleTask]="false"
                [items]="radioOptions"
                [optionValue]="'value'"
                [changeFunction]=""
              ></app-mat-radio>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
