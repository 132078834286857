<app-back-button *ngIf="isTask" class="back-button-container"> </app-back-button>
<div class="edit-task">
  <button
    *hasPermission="'aw.lg.u'"
    id="{{entityName}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeTaskState(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>

<div class="table-list" [ngClass]="{ 'back-button-exists': isTask }">
  <div class="existent-dates" *ngIf="existentGoodsGroups && existentGoodsGroups.length > 0">
    <h5 class="title-existent">
      {{ 'ANIMAL-WELFARE.GOODS-GROUPS.EXISTENT-GOODS-GROUPS' | translate }}
    </h5>
    <ul>
      <li *ngFor="let item of existentGoodsGroups">
        <span>{{ item.germanName }} - {{ item.englishName }} - {{ item.type | titlecase }}</span>
      </li>
    </ul>
  </div>
  <table mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-create-table" aria-hidden="true">
    <ng-container matColumnDef="nameGerman">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-name-german"
      >
        {{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-GERMAN' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-name-german-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': hasChanged('germanName', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-GERMAN' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-nameGerman"
              matInput
              type="text"
              formControlName="germanName"
              placeholder="{{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-GERMAN' | translate }}"
              required
              [removeHtmlTags]="getFormGroup(element).controls.name"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="nameEnglish">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-name-english"
      >
        {{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-ENGLISH' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-name-english-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': hasChanged('englishName', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-ENGLISH' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-nameEnglish"
              matInput
              type="text"
              formControlName="englishName"
              placeholder="{{ 'ANIMAL-WELFARE.GOODS-GROUPS.NAME-ENGLISH' | translate }}"
              required
              [removeHtmlTags]="getFormGroup(element).controls.englishName"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-type"
      >
        {{ 'ANIMAL-WELFARE.GOODS-GROUPS.TYPE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            [ngClass]="{ 'val-changed': hasChanged('type', element) }"
            appearance="fill"
            id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-goodsGroup"
            [formGroup]="getFormGroup(element)"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.GOODS-GROUPS.TYPE' | translate }}</mat-label>
            <mat-select
              formControlName="type"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-type-select"
              required
            >
              <mat-option
                *ngFor="let option of typeOptions; let i = index"
                [value]="option.name"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-typeOptions-option-{{ i }}"
              >
                {{ option.translateName | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-comment"
      >
        {{ 'ANIMAL-WELFARE.GOODS-GROUPS.COMMENT' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': hasChanged('comment', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.GOODS-GROUPS.COMMENT' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-comment"
              matInput
              type="text"
              formControlName="comment"
              placeholder="{{ 'ANIMAL-WELFARE.GOODS-GROUPS.COMMENT' | translate }}"
              [removeHtmlTags]="getFormGroup(element).controls.comment"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          *ngIf="!isTask"
          mat-button
          class="remove-button"
          id="{{ entityName }}-delete-button"
          (click)="deleteSelectedRows(element)"
        >
          <mat-icon class="add-icon">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [hidden]="loaderSpinner"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'draft-row': row.draft }"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
  </table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>

<div class="filter-input button-container">
  <ng-container>
    <button
      *ngIf="!isTask || (isTask && editableTask)"
      (click)="cancelEdit()"
      mat-button
      class="custom-button-cancel"
      id="{{ entityName }}-cancel-button"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c'">
    <button
      *ngIf="!isTask"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      (click)="addEmptyRow()"
    >
      <span>
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.ADD-NEW' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c'">
    <button
      *ngIf="!isTask || (isTask && editableTask)"
      [disabled]="getDisabledStatus()"
      (click)="onClickSave()"
      mat-button
      class="custom-button"
      id="{{ entityName }}-save-as-draft-button"
    >
      <span>
        {{ 'GENERAL.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
