<mat-icon id="service-pin-modal-close-button" mat-button [mat-dialog-close]>close</mat-icon>
<div class="dialog-content">
  <div mat-dialog-content class="background-dark margin-container">
    <p class="message-key-one">
      {{ 'SERVICE-PIN.POLICY.MESSAGE-KEY-ONE' | translate }}
    </p>
    <p class="message-key-two">
      {{ 'SERVICE-PIN.POLICY.MESSAGE-KEY-TWO' | translate }}
    </p>
    <p class="message-key-three">
      {{ 'SERVICE-PIN.POLICY.MESSAGE-KEY-THREE' | translate }}
    </p>
    <ul>
      <li class="message-key-four">
        {{ 'SERVICE-PIN.POLICY.MESSAGE-KEY-FOUR' | translate }}
      </li>
      <li class="message-key-five">
        {{ 'SERVICE-PIN.POLICY.MESSAGE-KEY-FIVE' | translate }}
      </li>
    </ul>
  </div>
</div>
