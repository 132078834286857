export const accountingBillingTransactionListColumns: string[] = [
  'select',
  'flags',
  'bookingCode',
  'bookingCodeName',
  'openItemDate',
  'openAmount',
  'id',
  'creditDebitCode',
  'amount',
  'actions',
];
