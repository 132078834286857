import { ComponentLoaderDirective } from './directives/component-loader/component-loader.directive';
import { NotificationService } from './services/notification/notification.service';
import { OnlyNumbersDirective } from './directives/only-numbers/only-numbers.directive';

import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { AddDeductionModalComponent } from './modals/add-deduction-modal/add-deduction-modal.component';
import { AddDunningModalComponent } from './modals/add-dunning-modal/add-dunning-modal.component';
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from 'ng2-currency-mask';
import { TableMenuColumnsComponent } from './table-filters/table-menu-columns/table-menu-columns.component';
import { TableMenuSortFilterComponent } from './table-filters/table-menu-sort-filter/table-menu-sort-filter.component';
import { SplitScreenCentralComponent } from './split-screen/split-central-master-data/split-central.component';
import { SplitScreenCentralAnimalWelfareComponent } from './split-screen/split-central-master-data-animal-welfare/split-central-animal-welfare.component';
import { SplitScreenTransactionsComponent } from './split-screen/split-transactions-data/split-transactions.component';

import { ClientSelectionModalComponent } from './modals/client-selection-modal/client-selection-modal.component';
import { UserSelectModalComponent } from './modals/user-select-modal/user-select-modal.component';
import { SessionExpiredModalComponent } from './modals/session-expired-modal/session-expired-modal.component';
import { FilterPipe } from './filter.pipe';
import { SaveFavoriteFilterModalComponent } from './modals/save-favorite-filter-modal/save-favorite-filter-modal.component';
import { UploadFilesModalComponent } from './modals/upload-files-modal/upload-files-modal.component';
import { FileUploadComponent } from './utils/file-upload/file-upload.component';
import { SortByPipe } from './utils/sortBy/sort-by.pipe';

import { GeneralDeleteConfirmationModalComponent } from './modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { CompanySelectionModalComponent } from './modals/company-selection-modal/company-selection-modal.component';
import { ConfirmationPromptModalComponent } from './modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { EmailDraftModalComponent } from './modals/email-draft-modal/email-draft-modal.component';
import { RichTextEditorComponent } from './utils/rich-text-editor/rich-text-editor.component';
import { FormElementsModule } from '../modules/form-elements/form-elements.module';

import { HasPermissionDirective } from './directives/has-permissions/has-permission.directive';
import { TransactionSelectionModalComponent } from './modals/transaction-selection-modal/transaction-selection-modal.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { CustomSnackBarComponent } from './services/notification/custom-snack-bar/custom-snack-bar.component';
import { CommentsComponent } from './comments/comments.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { SplitTransactionsHistoryComponent } from './split-screen/split-transactions-history/split-transactions-history.component';
import { CountrySelectionModalComponent } from './modals/country-selection-modal/country-selection-modal.component';
import { ShowHistoricalValuesModalComponent } from './modals/animal-welfare-modals/animal-welfare-system-config-show-historical-values-modal/show-historical-values-modal.component';
import { AwItpProtocolDetailsModalComponent } from './modals/animal-welfare-modals/aw-itp-protocol-details-modal/aw-itp-protocol-details-modal.component';
import { AwItpProtocolRequestResponseModalComponent } from './modals/animal-welfare-modals/aw-itp-protocol-request-response-modal/aw-itp-protocol-request-response-modal.component';
import { AWEnterActualValueModalComponent } from './modals/animal-welfare-modals/aw-enter-actual-value-modal/enter-actual-value-modal.component';
import { TciCompareHistoryModalComponent } from './modals/tci-compare-history-modal/tci-compare-history-modal.component';

import { CompareHistoryComponent } from './history/compare-history/compare-history.component';

import { ContactPersonLinkModalComponent } from './modals/contact-person-link-modal/contact-person-link-modal.component';
import { CompanyPlatformConfigurationModalComponent } from './modals/company-platform-configuration-modal/company-platform-configuration-modal.component';
import {
  CustomDateFormatMonthYearDirective,
  CustomDateFormatMonthYearDayDirective,
} from './directives/custom-date-format/custom-date-format.directive';
import { TransactionCodeSelectionModalComponent } from './modals/transaction-code-selection-modal/transaction-code-selection-modal.component';
import { DefaultTableComponent } from './tables/default-table/default-table.component';
import { AwXsdModalComponent } from './modals/animal-welfare-modals/aw-xsd-modal/aw-xsd-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BusinessPartnerSelectionModalComponent } from './modals/business-partner-selection-modal/business-partner-selection-modal.component';
import { DefaultEditableTableComponent } from './tables/default-editable-table/default-editable-table.component';
import { NgxMaskModule } from 'ngx-mask';
import { SortByCurrencyCodesPipe } from './utils/sortBy-currecy-codes/sort-by-currecy-codes.pipe';
import { OpenTransactionMultiEditModalComponent } from './modals/open-transaction-multi-edit-modal/open-transaction-multi-edit-modal.component';
import { DocumentHistorySelectionModalComponent } from './modals/document-history-selection-modal/document-history-selection-modal.component';
import { HasBuProductPermissionDirective } from './directives/has-bu-product-permission/has-bu-product-permission.directive';
import { NewBusinessUnitPoolModalComponent } from './modals/new-business-unit-pool-modal/new-business-unit-pool-modal.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ServicePinPolicyModalComponent } from './modals/service-pin-policy-modal/service-pin-policy-modal.component';
import { PasswordPolicyModalComponent } from './modals/password-policy-modal/password-policy-modal.component';
import { GeneralInfoModalComponent } from './modals/general-info-modal/general-info-modal.component';
import { BusinessRelationshipSelectionModalComponent } from './modals/business-relationship-selection-modal/business-relationship-selection-modal.component';
import { LeadingZerosPipe } from './leading-zero.pipe';
import { EnumToTranslationPipe } from './enum-to-translation.pipe';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { RouterModule } from '@angular/router';
import { RemoveHtmlTagsDirective } from './directives/remove-html-tags/remove-html-tags.directive';
import { RemoveHtmlTagsForNgModelDirective } from './directives/remove-html-tags-for-ng-model/remove-html-tags-for-ng-model.directive';
import { DebounceClickDirective } from './directives/debounce-click/debounce-click.directive';
import { HighLightInfoDirective } from './directives/highlight-info/highlight-info.directive';
import { ChecksumComponent } from './wrappers-components/checksum/checksum.component';
import { SimpleInputComponent } from './generic-components/simple-input/simple-input.component';
import { CurrencyInputComponent } from './wrappers-components/currency-input/currency-input.component';
import { TaskParametersPipe } from './task-parameters.pipe';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    ComponentLoaderDirective,
    OnlyNumbersDirective,
    ConfirmationModalComponent,
    AddDeductionModalComponent,
    AddDunningModalComponent,
    DefaultTableComponent,
    TableMenuColumnsComponent,
    TableMenuSortFilterComponent,
    SplitScreenCentralComponent,
    SplitScreenCentralAnimalWelfareComponent,
    SplitScreenTransactionsComponent,
    ClientSelectionModalComponent,
    UserSelectModalComponent,
    SessionExpiredModalComponent,
    FilterPipe,
    SaveFavoriteFilterModalComponent,
    UploadFilesModalComponent,
    FileUploadComponent,
    SortByPipe,
    SortByCurrencyCodesPipe,
    GeneralDeleteConfirmationModalComponent,
    CompanySelectionModalComponent,
    ConfirmationPromptModalComponent,
    EmailDraftModalComponent,
    RichTextEditorComponent,
    HasPermissionDirective,
    CustomDateFormatMonthYearDirective,
    CustomDateFormatMonthYearDayDirective,
    TransactionSelectionModalComponent,
    SplitTransactionsHistoryComponent,
    CommentsComponent,
    AttachmentsComponent,
    CustomSnackBarComponent,
    CountrySelectionModalComponent,
    ShowHistoricalValuesModalComponent,
    AwItpProtocolDetailsModalComponent,
    AwItpProtocolRequestResponseModalComponent,
    AWEnterActualValueModalComponent,
    TciCompareHistoryModalComponent,
    CompareHistoryComponent,
    ContactPersonLinkModalComponent,
    CompanyPlatformConfigurationModalComponent,
    TransactionCodeSelectionModalComponent,
    DocumentHistorySelectionModalComponent,
    AwXsdModalComponent,
    HasBuProductPermissionDirective,
    NewBusinessUnitPoolModalComponent,
    BusinessPartnerSelectionModalComponent,
    DefaultEditableTableComponent,
    OpenTransactionMultiEditModalComponent,
    ServicePinPolicyModalComponent,
    PasswordPolicyModalComponent,
    GeneralInfoModalComponent,
    BusinessRelationshipSelectionModalComponent,
    LeadingZerosPipe,
    EnumToTranslationPipe,
    NavigationTabsComponent,
    RemoveHtmlTagsDirective,
    RemoveHtmlTagsForNgModelDirective,
    DebounceClickDirective,
    HighLightInfoDirective,
    ChecksumComponent,
    SimpleInputComponent,
    CurrencyInputComponent,
    TaskParametersPipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    TranslateModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    CurrencyMaskModule,
    DragDropModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule,
    NgxFileDropModule,
    MatNativeDateModule,
    FormElementsModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    NgxMaskModule,
    RouterModule,
  ],
  providers: [
    NotificationService,
    DecimalPipe,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
    exports: [
        ComponentLoaderDirective,
        OnlyNumbersDirective,
        DefaultTableComponent,
        DefaultEditableTableComponent,
        TableMenuColumnsComponent,
        TableMenuSortFilterComponent,
        SortByPipe,
        SortByCurrencyCodesPipe,
        SplitScreenCentralComponent,
        SplitScreenCentralAnimalWelfareComponent,
        SplitScreenTransactionsComponent,
        CommentsComponent,
        AttachmentsComponent,
        HasPermissionDirective,
        HasBuProductPermissionDirective,
        CustomDateFormatMonthYearDirective,
        CustomDateFormatMonthYearDayDirective,
        FileUploadComponent,
        LeadingZerosPipe,
        EnumToTranslationPipe,
        NavigationTabsComponent,
        RemoveHtmlTagsDirective,
        RemoveHtmlTagsForNgModelDirective,
        DebounceClickDirective,
        HighLightInfoDirective,
        SimpleInputComponent,
        ChecksumComponent,
        CurrencyInputComponent,
        TaskParametersPipe
    ],
})
export class SharedModule {}
