import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NonGermanyReferenceAllowListComponent } from './non-germany-reference-allow-list/non-germany-reference-allow-list.component';

const routes: Routes = [
  {
    path: '',
    component: NonGermanyReferenceAllowListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NonGermanyReferenceAllowRoutingModule {}
