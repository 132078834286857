<div *ngIf="taskView">
  <app-back-button></app-back-button>
  <span>
    <button
      *hasPermission="'sc.t.u'"
      id="{{ entityName }}-edit-task"
      mat-button
      class="edit-task-button"
      (click)="makeEditable()"
    >
      <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
    </button>
  </span>
</div>
<div class="input-wrapper form-container translation-edit-wrapper">
  <form class="form" [formGroup]="translationEditForm" autocomplete="off">
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ID'"
      [placeholder]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ID'"
      [label]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ID'"
      [entityName]="entityName"
      [form]="translationEditForm"
      [controlObject]="translationEditForm.controls.key"
      [controlName]="'key'"
      [approvedValue]="translationApproved ? translationApproved.key : null"
      [taskValue]="translationTask ? translationTask.key : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
      [maxLength]="maxLengthInputs"
    ></app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.GERMAN'"
      [placeholder]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.GERMAN'"
      [label]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.GERMAN'"
      [entityName]="entityName"
      [form]="translationEditForm"
      [controlObject]="translationEditForm.controls.german"
      [controlName]="'german'"
      [approvedValue]="translationApproved ? translationApproved.translationValues.german : null"
      [taskValue]="translationTask ? translationTask.translationValues.german : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
      [maxLength]="maxLengthInputs"
    ></app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ENGLISH'"
      [placeholder]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ENGLISH'"
      [label]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ENGLISH'"
      [entityName]="entityName"
      [form]="translationEditForm"
      [controlObject]="translationEditForm.controls.english"
      [controlName]="'english'"
      [approvedValue]="translationApproved ? translationApproved.translationValues.english : null"
      [taskValue]="translationTask ? translationTask.translationValues.english : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
      [maxLength]="maxLengthInputs"
    ></app-mat-input>
    <ng-container class="form-row" *ngFor="let filteredKey of filteredKeys">
      <app-mat-input
        *ngIf="showFilteredKey(filteredKey)"
        [name]="filteredKey"
        [placeholder]="filteredKey"
        [label]="filteredKey"
        [entityName]="entityName"
        [form]="translationEditForm"
        [controlObject]="getControlObject(filteredKey)"
        [controlName]="filteredKey"
        [approvedValue]="translationApproved ? getApprovedValue(filteredKey) : null"
        [taskValue]="translationTask ? getTaskValue(filteredKey) : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
        [maxLength]="maxLengthInputs"
      >
      </app-mat-input>
    </ng-container>
    <app-mat-select-multiple
      [name]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.DEVIATING-BU'"
      [placeholder]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.DEVIATING-BU'"
      [label]="'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.DEVIATING-BU'"
      [entityName]="entityName"
      [form]="translationEditForm"
      [controlObject]="translationEditForm.controls.deviatingBU"
      [controlName]="'deviatingBU'"
      [approvedValue]="translationApproved ? translationApproved.deviatingBU : null"
      [taskValue]="translationTask ? translationTask.deviatingBU : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="busineesUnits"
      [optionValue]="'id'"
      [taskOptionsValue]="'name'"
      [selectedOption]="''"
      [translationOption]="'name'"
      [optionTranslationText]="''"
    ></app-mat-select-multiple>
  </form>
</div>

<div class="filter-input button-container" [hidden]="isTask">
  <ng-container>
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" (click)="changeEditMode()">
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <button
      mat-button
      *hasPermission="'sc.t.u'"
      class="custom-button"
      [disabled]="!translationEditForm.valid"
      id="{{ entityName }}-save-button"
      (click)="save()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
