<div class="input-wrapper form-container">
  <form class="form" [formGroup]="translationForm" autocomplete="off">
    <div class="form-row inline-display margin-search-input">
      <mat-form-field appearance="fill" id="{{ entityName }}-form-name">
        <mat-label>{{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.NAME' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.NAME' | translate }}"
          formControlName="name"
          id="{{ entityName }}-form-name-name"
          required
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="{{ 'search' | translate }}"
              noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
              formControlName="nameSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let option of filteredLanguages" [value]="option.isoCode">
            {{ option.name }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          id="{{ entityName }}-form-name-clear"
          (click)="clearOptions('name'); $event.stopPropagation()"
          *ngIf="showRemoveOption('name')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-error
          id="{{ entityName }}-form-name-required"
          class="input-error"
          *ngIf="translationForm.controls.name.hasError('required')"
        >
          {{
            'ERROR-MESSAGES.REQUIRED'
              | translate
                : {
                    name: 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.NAME' | translate
                  }
              | titlecase
          }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="filter-input button-container">
  <ng-container>
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" (click)="changeEditMode()">
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <button
      mat-button
      *hasPermission="'sc.t.c'"
      class="custom-button"
      [disabled]="!translationForm.valid"
      id="{{ entityName }}-create-button"
      (click)="save()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
