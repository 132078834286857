<div class="filters" [formGroup]="filterForm">
  <div class="filter-input">
    <mat-form-field class="example-full-width">
      <mat-label>{{ 'BOOKING-CODE.SEARCH' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'BOOKING-CODE.SEARCH' | translate }}"
        name="name"
        formControlName="searchString"
        class="custom-input"
        [(ngModel)]="searchInput"
        id="{{ entityName }}-search-input-name"
        (keyup)="searchBooking()"
        autocomplete="off"
        type="text"
        [removeHtmlTags]="filterForm.controls.searchString"
      />
    </mat-form-field>
    <button
      mat-button
      class="custom-button search-button"
      (click)="searchBooking()"
      id="{{ entityName }}-search-button"
    >
      <span class="material-icons"> search </span>
    </button>
  </div>
  <div class="filter-input add-button">
    <button
      *hasPermission="'bc.c'"
      mat-button
      class="custom-button"
      (click)="createNew()"
      id="{{ entityName }}-create-button"
    >
      {{ 'BOOKING-CODE.CREATE' | translate }}
    </button>
  </div>
</div>

<mat-menu #appMenu="matMenu" class="select-columns" (close)="tableChanged()">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<div class="booking-code-list">
  <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
  <div class="booking-code-table">
    <table
      aria-hidden="true"
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
    >
      <ng-container matColumnDef="bookingCodeId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header id-column-old"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-bookingCodeId"
          scope="col"
        >
          {{ 'BOOKING-CODE.BOOKING-CODE-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-bookingCodeId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row id-column-old"
        >
          {{ element.id | number: '3.0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultBookingCodeName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-defaultBookingCodeName"
          scope="col"
        >
          {{ 'BOOKING-CODE.DEFAULT-BOOKING-CODE-NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-defaultBookingCodeName-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-description"
          scope="col"
        >
          {{ 'BOOKING-CODE.DESCRIPTION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-description-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transactionType "
          scope="col"
        >
          {{ 'BOOKING-CODE.TRANSACTION-TYPE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionType-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findTransactionType(element.transactionType) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultNameEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-defaultNameEnglish "
          scope="col"
        >
          {{ 'BOOKING-CODE.DEFAULT-NAME-ENGLISH' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-defaultNameEnglish-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.defaultNameEnglish }}
        </td>
      </ng-container>

      <ng-container matColumnDef="descriptionEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-descriptionEnglish "
          scope="col"
        >
          {{ 'BOOKING-CODE.DEFAULT-NAME-ENGLISH' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-descriptionEnglish-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.descriptionEnglish }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transferTransactionCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transferTransactionCode "
          scope="col"
        >
          {{ 'BOOKING-CODE.TRANSFER-TRANSACTION-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transferTransactionCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.transferTransactionCode | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="standard">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-standard "
          scope="col"
        >
          {{ 'BOOKING-CODE.STANDARD' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-standard-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findStandard(element.standard) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cancellation">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-cancellation "
          scope="col"
        >
          {{ 'BOOKING-CODE.CANCELLATION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-cancellation-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.cancellation | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sapBalanceSheetAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sapBalanceSheetAccount "
          scope="col"
        >
          {{ 'BOOKING-CODE.SAP-BALANCE-SHEET-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sapBalanceSheetAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findAccount(element.sapBalanceSheetAccount) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sapPAndLAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sapPAndLAccount "
          scope="col"
        >
          {{ 'BOOKING-CODE.SAP-P-AND-L-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sapPAndLAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findAccount(element.sapPAndLAccount) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="objectionAccountBeVision">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-objectionAccountBeVision "
          scope="col"
        >
          {{ 'BOOKING-CODE.OBJECTION-ACCOUNT-BE-VISION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-objectionAccountBeVision-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.objectionAccountBeVision }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bookingOption">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-bookingOption "
          scope="col"
        >
          {{ 'BOOKING-CODE.BOOKING-OPTION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-bookingOption-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findBookingOption(element.bookingOption) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="isDueDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-isDueDate "
          scope="col"
        >
          {{ 'BOOKING-CODE.IS-DUE-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-isDueDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.isDueDate | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="relevantSales">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-relevantSales "
          scope="col"
        >
          {{ 'BOOKING-CODE.RELEVANT-SALES' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-relevantSales-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.relevantSales | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="generalLedgerAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-generalLedgerAccount "
          scope="col"
        >
          {{ 'BOOKING-CODE.GENERAL-LEDGER-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-generalLedgerAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findAccount(element.generalLedgerAccount) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="generalLedgerCounterAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-generalLedgerCounterAccount "
          scope="col"
        >
          {{ 'BOOKING-CODE.GENERAL-LEDGER-COUNTER-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-generalLedgerCounterAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.generalLedgerCounterAccount | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="salesTaxAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-salesTaxAccount "
          scope="col"
        >
          {{ 'BOOKING-CODE.SALES-TAX-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-salesTaxAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.salesTaxAccount | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="inHouseClearingItemCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-inHouseClearingItemCode "
          scope="col"
        >
          {{ 'BOOKING-CODE.IN-HOUSE-CLEARING-ITEM-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-inHouseClearingItemCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ findInHouseItemCode(element.inHouseClearingItemCode) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="displayExternally">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-displayExternally "
          scope="col"
        >
          {{ 'BOOKING-CODE.DISPLAY-EXTERNALLY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-displayExternally-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.displayExternally | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" scope="col">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button
            *ngIf="element.draft"
            class="menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ element: element }"
            (click)="$event.stopPropagation()"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <span class="material-icons"> more_vert </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        [ngClass]="{ 'draft-row': element.draft }"
        class="element-row"
        (click)="edit(element)"
      ></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="edit(element)" id="{{ entityName }}-table-results-action-menu-edit-draft-button">
      <img
        class="icon-menu"
        src="assets/images/ic_edit.svg"
        alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
      />

      <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
    </button>
    <button
      mat-menu-item
      (click)="deleteDraft(element)"
      id="{{ entityName }}-table-results-action-menu-delete-draft-button"
    >
      <span class="material-icons icon-menu"> delete_outline </span>
      <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
    </button>
  </ng-template>
</mat-menu>
