<div>
  <button mat-button class="close-button-snackbar" (click)="snackBarRef.dismiss()">
    <span class="material-icons close-icon">clear</span>
  </button>
</div>

<div>
  <span class="material-icons">info_outline</span>
  <span class="message-snackbar"> {{ data.messageKey | translate }} </span>
</div>

<div>
  <button
    *ngIf="showRedirectButton"
    mat-button
    class="custom-button-blue action-snackbar"
    (click)="redirectToUserDraft()"
  >
    {{ 'GENERAL-ENTITY.CREATE.MESSAGES.TOAST_BUTTON_REDIRECT_DRAFT' | translate }}
  </button>
</div>
