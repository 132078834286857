<app-back-button *ngIf="isTask" class="back-button-container"> </app-back-button>
<div class="edit-task">
  <button
    *hasPermission="'aw.lg.u'"
    id="{{entityName}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeTaskState(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>

<div class="table-list" [ngClass]="{ 'back-button-exists': isTask }">
  <div class="existent-dates" *ngIf="existentDates && existentDates.length > 0">
    <h5 class="title-existent">
      {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.EXISTENT-PERIODS' | translate }}
    </h5>
    <ul>
      <li *ngFor="let item of existentDates">
        <span>{{ item.startDate | date: dateFormat }} - {{ item.endDate | date: dateFormat }}</span>
      </li>
    </ul>
  </div>

  <table mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table" aria-hidden="true">
    <ng-container matColumnDef="startDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-start-date"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-start-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            [formGroup]="getFormGroup(element)"
            appearance="fill"
            [ngClass]="{ 'val-changed': hasChanged('startDate', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE' | translate }}</mat-label>
            <input
              #input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-start-date"
              matInput
              placeholder="{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE' | translate }}"
              name="{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE' | translate }}"
              formControlName="startDate"
              [matDatepicker]="datePicker"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <div class="input-error" *ngIf="checkSameDate(element)">
            {{ 'ERROR-MESSAGES.INVALID-DATE-SAME' | translate | titlecase }}
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-name-english"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-name-english-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            [formGroup]="getFormGroup(element)"
            appearance="fill"
            [ngClass]="{ 'val-changed': hasChanged('endDate', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE' | translate }}</mat-label>
            <input
              #input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-end-date"
              matInput
              placeholder="{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE' | translate }}"
              name="{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE' | translate }}"
              formControlName="endDate"
              [matDatepicker]="datePicker"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <div class="input-error" *ngIf="checkErrorDate(element)">
            {{ 'ERROR-MESSAGES.INVALID-DATE' | translate | titlecase }}
          </div>
          <div class="input-error" *ngIf="checkSameDate(element)">
            {{ 'ERROR-MESSAGES.INVALID-DATE-SAME' | translate | titlecase }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-comment"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.COMMENT' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': hasChanged('comment', element) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.COMMENT' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-comment"
              matInput
              type="text"
              formControlName="comment"
              placeholder="{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.COMMENT' | translate }}"
              [removeHtmlTags]="getFormGroup(element).controls.comment"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          *ngIf="!isTask"
          mat-button
          class="remove-button"
          (click)="deleteSelectedRows(element)"
          id="{{ entityName }}-delete-button"
        >
          <mat-icon class="add-icon">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [hidden]="loaderSpinner"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'draft-row': row.draft }"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
  </table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>

<div class="filter-input button-container">
  <ng-container>
    <button
      *ngIf="!isTask || (isTask && editableTask)"
      (click)="cancelEdit()"
      mat-button
      class="custom-button-cancel"
      id="{{ entityName }}-cancel-button"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c'">
    <button
      *ngIf="!isTask"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      (click)="addEmptyRow()"
    >
      <span>
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.ADD-NEW' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container>
    <button
      *ngIf="!isTask || (isTask && editableTask)"
      [disabled]="getDisabledStatus()"
      (click)="onClickSave()"
      mat-button
      class="custom-button"
      id="{{ entityName }}-save-as-draft-button"
    >
      <span>
        {{ 'GENERAL.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
