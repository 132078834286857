import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DismissChangesDialogComponent } from 'src/app/shared/dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { ClientService } from 'src/app/shared/services/client/client.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-prescoring-task',
  templateUrl: './prescoring-task.component.html',
  styleUrls: ['./prescoring-task.component.less'],
})
export class PrescoringTaskComponent implements OnInit {
  currentTask;
  canProceed: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private taskService: TaskService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog
  ) {}

  ngOnInit() {
    const taskId = this.route.snapshot.paramMap.get('taskId');
    this.taskService.getTask(taskId).subscribe((task) => {
      this.currentTask = task;
      if (this.currentTask.status !== 'OPEN') {
        this.router.navigateByUrl('/tasks/' + this.currentTask.id);
      }
    });
  }

  complete() {
    this.matDialogService
      .open(DismissChangesDialogComponent, {
        width: '25em',
        panelClass: 'prescoring-popup',
        data: {
          title: 'riskmanagement.dialogs.should-prescoring-proceed.title',
          message: 'riskmanagement.dialogs.should-prescoring-proceed.content',
        },
      })
      .afterClosed()
      .subscribe((canProceed) => {
        if (canProceed !== undefined && canProceed !== null) {
          this.canProceed = canProceed;
          this.showCommentDialog();
        }
      });
  }

  showCommentDialog() {
    this.matDialogService
      .open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
        data: {
          positiveLabel: 'save',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result || result.event !== 'save') {
          return;
        }
        this.clientService
          .finishPrescoring(
            this.currentTask.id,
            result.comment,
            this.canProceed
          )
          .subscribe(
            () => {
              this.router.navigateByUrl('/tasks');
              this.showSnackBar(
                'riskmanagement.notifications.status-set-to-' +
                  (this.canProceed ? 'offer' : 'lost')
              );
            },
            (err) => console.log(err)
          );
      });
  }

  showSnackBar(message: string) {
    this.notificationService.dismissMessage();
    this.notificationService.showToast(
      message,
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }
}
