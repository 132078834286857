import { OnChanges, ViewChild } from '@angular/core';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { accountingMasterDataColumns } from 'src/app/in-memory-data/debtor/account/table-columns';
import { accountingMasterDataColumnsConf } from 'src/app/in-memory-data/debtor/account/table-master-data-columns-configuration';
import { AccountingService } from 'src/app/shared/services/accounting/accounting.service';
import { RouteEditorService } from 'src/app/shared/services/default-table/editors/route-editor-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { DashboardStateService } from '../services/dashboard-state/dashboard-state.service';
import { DefaultTableComponent } from '../../../shared/tables/default-table/default-table.component';

@Component({
  selector: 'app-accounting-master-data-list',
  templateUrl: './master-data-list.component.html',
  styleUrls: ['./master-data-list.component.less'],
})
export class AccountingMasterDataListComponent implements OnInit, OnChanges {
  @Input() active;
  @Input() searchString;
  entityName = 'accounting-master-data-account-list';
  configurationKey = 'accounting_master_data_account_table';
  columns = accountingMasterDataColumnsConf;
  displayedColumns = accountingMasterDataColumns;
  accountingMasterDataAccountData: any;
  dataLoaded = false;
  loaderSpinner = true;
  tableName = 'company';
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  constructor(
    private accountingService: AccountingService,
    private notificationService: NotificationService,
    public tableEditor: RouteEditorService,
    private stateService: DashboardStateService
  ) {
    this.tableEditor.route = 'accounting/overview';
    stateService.initConfig(this.tableName);
  }

  ngOnInit() {
    this.stateService.configs[this.tableName].filterText = this.searchString;
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('active' in changes) {
      this.active = changes.active.currentValue;
      if (this.active) {
        this.loadData();
      }
    }
    if ('searchString' in changes) {
      this.stateService.configs[this.tableName].filterText =
        changes.searchString.currentValue;
      this.loadData();
    }
  }

  loadData() {
    if (!this.active) {
      return;
    }
    const filterOptions = {
      ...this.stateService.configs[this.tableName],
      ...{
        filteredValues:
          this.stateService.configs[this.tableName].filteredValues,
      },
    };
    this.accountingService.filterCompanyAccountingList(filterOptions).subscribe(
      (data) => {
        if (data) {
          data['content'].forEach((item) => {
            item.dataType = 'masterData';
          });
          this.accountingMasterDataAccountData = data;
          this.loaderSpinner = false;
          this.dataLoaded = true;
          if (this.defaultTableComponent) {
            this.defaultTableComponent.setLoaderSpinner(false);
          }
        }
      },
      (error) => {
        this.handleError(error);
        this.accountingMasterDataAccountData = { total: 0 };
        this.loaderSpinner = false;
        this.dataLoaded = true;
        this.defaultTableComponent.setLoaderSpinner(false);
      }
    );
  }

  filter(event) {
    this.defaultTableComponent.setLoaderSpinner(true);
    if (event.filteredValues) {
      this.stateService.configs[this.tableName].filteredValues =
        this.stateService.configs[this.tableName].filteredValues.filter(
          (fv) => fv.choice !== event.filteredValues.choice
        );
      this.stateService.configs[this.tableName].filteredValues.push(
        event.filteredValues
      );
    } else {
      this.stateService.configs[this.tableName] = {
        ...this.stateService.configs[this.tableName],
        ...event,
      };
      if (event.choice) {
        this.stateService.configs[this.tableName].sortField = event.choice;
      }
      if (event.direction) {
        this.stateService.configs[this.tableName].sortDirection =
          event.direction;
      }
    }
    this.loadData();
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  getDistinctValues = (choice, column) => {
    const filterOptions = {
      ...this.stateService.configs[this.tableName],
      ...{
        filteredValues: JSON.stringify(
          this.stateService.configs[this.tableName].filteredValues
        ),
      },
    };
    return this.accountingService.getDistinctValues(
      filterOptions,
      this.tableName,
      choice
    );
  };
}
