import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  openItemsKPIs
} from 'src/app/in-memory-data/open-transaction/enum/kpiList';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-open-transaction-booking-kpi-overview',
  templateUrl: './open-transaction-booking-kpi-overview.component.html',
  styleUrls: ['./open-transaction-booking-kpi-overview.component.less'],
})
export class OpenTransactionBookingKpiOverviewComponent
  implements OnInit, OnDestroy {
  kpiList = openItemsKPIs;
  entityName = 'open-transaction-booking-kpi';

  dateFormat: any;
  sharedOpenTransactionRows;

  constructor(
    private userService: UserService,
    private sharedDataService: SharedDataService,
    private tableService: TablesService
  ) {
    this.sharedDataService.currenSharedOpenTransactionRows.subscribe(
      (sharedOpenTransactionRows) =>
        (this.sharedOpenTransactionRows = sharedOpenTransactionRows)
    );

    this.tableService.suscriptionChangesInFormEmitter =
      this.tableService.changesInFormFunction.subscribe((data) => {
        this.checkChangesInRows(data);
      });
  }

  ngOnDestroy() {
    this.tableService.suscriptionChangesInFormEmitter.unsubscribe();
  }

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
  }

  checkChangesInRows(data) {
    this.updateNumberTransactions();
    this.updateBalance();
    this.updateBalanceDebit();
    this.updateBalanceCredit();
  }

  updateNumberTransactions() {
    const kpi = this.kpiList.find(
      (kpiResult) => kpiResult.name === 'NUMBER_TRANSACTIONS'
    );

    kpi.value = this.sharedOpenTransactionRows.controls.length;
  }

  updateBalance() {
    const kpi = this.kpiList.find((kpiResult) => kpiResult.name === 'BALANCE');
    if (kpi) {
      let result = 0;

      this.sharedOpenTransactionRows.controls.forEach((fg) => {
        const formGroup: UntypedFormGroup = fg;

        const amount = Number(formGroup.get('amount').value);
        const debitCreditCode = formGroup.get('debitOrCredit').value;

        if (amount) {
          if (debitCreditCode === 'DEBIT' || debitCreditCode === 1) {
            result += amount;
          } else if (debitCreditCode === 'CREDIT' || debitCreditCode === 2) {
            result -= amount;
          }
        }
      });

      kpi.value = result;
    }
  }

  updateBalanceCredit() {
    const kpi = this.kpiList.find(
      (kpiResult) => kpiResult.name === 'BALANCE_CREDIT'
    );
    if (kpi) {
      let result = 0;

      this.sharedOpenTransactionRows.controls.forEach((fg) => {
        const formGroup: UntypedFormGroup = fg;

        const amount = formGroup.get('amount').value;
        const debitCreditCode = formGroup.get('debitOrCredit').value;

        if (amount) {
          if (debitCreditCode === 'CREDIT' || debitCreditCode === 1) {
            result += amount;
          }
        }
      });

      kpi.value = result;
    }
  }

  updateBalanceDebit() {
    const kpi = this.kpiList.find(
      (kpiResult) => kpiResult.name === 'BALANCE_DEBIT'
    );
    if (kpi) {
      let result = 0;

      this.sharedOpenTransactionRows.controls.forEach((fg) => {
        const formGroup: UntypedFormGroup = fg;

        const amount = formGroup.get('amount').value;
        const debitCreditCode = formGroup.get('debitOrCredit').value;

        if (amount) {
          if (debitCreditCode === 'DEBIT' || debitCreditCode === 0) {
            result += amount;
          }
        }
      });

      kpi.value = result;
    }
  }
}
