import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { userLanguages } from 'src/app/in-memory-data/user-profile-settings/enums/languages';
import { UserService } from 'src/app/shared/services/user/user.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { dateFormatsEnum } from '../../../in-memory-data/user-profile-settings/enums/date-formats';
import { DateAdapter } from '@angular/material/core';
import { titlesEnum } from 'src/app/in-memory-data/user-management/enums/title-enum';
import { academicTitlesEnum } from 'src/app/in-memory-data/user-management/enums/academic-title-enum';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ServicePinPolicyModalComponent } from 'src/app/shared/modals/service-pin-policy-modal/service-pin-policy-modal.component';
import {
  GeneralValidator,
  ServicePinConditionsValidator,
} from 'src/app/shared/validators/general-validator';
import {
  PasswordConditionsValidator,
  PasswordLengthValidator,
  UserNameInNewPasswordValidator,
} from 'src/app/shared/validators/password-validator';
import { GeneralInfoModalComponent } from 'src/app/shared/modals/general-info-modal/general-info-modal.component';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { first } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.less'],
})
export class UserSettingsComponent implements OnInit {
  entityName = 'user-settings';
  userProfileForm: UntypedFormGroup;
  titles = titlesEnum;
  academicTitles = academicTitlesEnum;
  userLanguages = userLanguages;
  dateFormats = dateFormatsEnum;
  editMode = true;
  approvedValue;
  taskValue;
  isTask = true;
  internalUser = false;
  disableSaveButton = true;
  currentUser;
  externalSource;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private matDialogService: MatDialog,
    private generalValidator: GeneralValidator,
    private translationService: TranslationService,
    private router: Router
  ) {
    this.userProfileFormBuilder();
  }

  userProfileFormBuilder() {
    this.userProfileForm = this.formBuilder.group(
      {
        userId: [],
        title: [{ value: null, disabled: true }],
        academicTitle: [{ value: null, disabled: true }],
        firstName: [{ value: null, disabled: true }],
        lastName: [{ value: null, disabled: true }],
        mainEmail: [{ value: null, disabled: true }],
        faxNumber: [{ value: null, disabled: true }],
        telephoneNumber: [{ value: null, disabled: true }],
        servicePin: [
          { value: null, disabled: true },
          [ServicePinConditionsValidator()],
        ],
        newServicePin: [
          { value: null, disabled: false },
          [ServicePinConditionsValidator()],
        ],
        confirmNewServicePin: [{ value: null, disabled: false }],
        oldPassword: [{ value: null, disabled: false }],
        newPassword: [{ value: null, disabled: false }],
        confirmNewPassword: [{ value: null, disabled: true }],
        birthday: [{ value: null, disabled: true }],
        language: [{ value: null, disabled: false }, Validators.required],
        dateFormat: [{ value: null, disabled: false }, Validators.required],
        emailConfig: [{ value: null, disabled: false }],
        emailMyTasks: [{ value: null, disabled: false }],
        emailTaskOfMyTeam: [{ value: null, disabled: false }],
        emailTaskOfMyRole: [{ value: null, disabled: false }],
      },
      {
        validators: [
          this.generalValidator.sameTextValidation(
            'newServicePin',
            'confirmNewServicePin'
          ),
        ],
      }
    );
  }

  private disableFieldsForExternalSource() {
    this.userProfileForm.get('faxNumber').disable();
    this.userProfileForm.get('telephoneNumber').disable();
    this.userProfileForm.get('birthday').disable();
  }

  ngOnInit() {
    this.notificationService.dismissMessage();
    this.getInitialSettings();
    this.userProfileForm.get('dateFormat').valueChanges.subscribe((data) => {
      if (data === 'BRITISH') {
        this.changeDateFormat('en-GB');
        this.userService.dateFormat = 'BRITISH';
      }
      if (data === 'AMERICAN') {
        this.changeDateFormat('en-US');
        this.userService.dateFormat = 'AMERICAN';
      }
      if (data === 'GERMAN') {
        this.changeDateFormat('de');
        this.userService.dateFormat = 'GERMAN';
      }
    });
  }

  loadData() {
    this.enableSaveButton();
  }

  get getDateFormatTranslation() {
    return this.findDateFormatTranslation.bind(this);
  }

  findDateFormatTranslation(dateFormatTranslations) {
    if (!this.dateFormats) {
      return '';
    }

    const dateFormatTranlation = this.dateFormats.find(
      (element) => element.name === dateFormatTranslations
    );
    return dateFormatTranlation ? dateFormatTranlation.translateName : '';
  }

  getInitialSettings() {
    this.userService.getCurrentUser().subscribe((data) => {
      if (data) {
        this.currentUser = data;
        this.internalUser = data.internalUser;
        this.userProfileForm.patchValue(data);
        this.userProfileForm.get('mainEmail').patchValue(data.email);
        this.taskValue = data;
        this.approvedValue = data;
        if (!this.internalUser) {
          this.showInfoPopup();
          this.userService.getServicePin().subscribe((servicePin) => {
            if (servicePin) {
              this.userProfileForm.get('servicePin').patchValue(servicePin);
            }
          });
        }
      }
    });
    this.userService.getSettings().subscribe((data) => {
      this.currentUser = {
        ...data.profile,
        ...this.currentUser,
      };
      this.userProfileForm.patchValue(this.currentUser);
      if (
        data.profile.language === 'en' ||
        data.profile.language === 'ENGLISH'
      ) {
        this.userProfileForm.get('language').patchValue('en');
      } else {
        this.userProfileForm.get('language').patchValue('de');
      }
      this.addPasswordValidator();
    });
    this.loadData();
  }

  addPasswordValidator() {
    this.userProfileForm
      .get('newPassword')
      .setValidators([
        UserNameInNewPasswordValidator(
          this.userProfileForm.get('firstName').value,
          this.userProfileForm.get('lastName').value
        ),
        PasswordConditionsValidator(),
        PasswordLengthValidator(),
      ]);

    this.userProfileForm.updateValueAndValidity();
  }

  saveSettings() {
    const settings = {
      profile: this.userProfileForm.value,
      user: { ...this.userProfileForm.value, internalUser: this.internalUser },
    };

    if (!this.userProfileForm.get('language').pristine) {
      if (this.userProfileForm.value) {
        const profileLang = this.userProfileForm.value.language;

        if (profileLang === 'en') {
          this.translate.use('en');
          this.translationService.setCurrentLanguage('en');
          this.translationService.updateCurrentSelectedLanguage('en');
          this.translate.currentLang = 'en';
          this.translationService.updateCurrentSelectedLanguage('en');
          this.translationService.updateCurrentSharedSelectedLanguage('en');
        } else {
          this.translate.use('de');
          this.translationService.setCurrentLanguage('de');
          this.translationService.updateCurrentSelectedLanguage('de');
          this.translationService.updateCurrentSharedSelectedLanguage('de');
          this.translate.currentLang = 'de';
          this.translationService.updateCurrentSelectedLanguage('de');
        }
      }
    }

    this.userService.saveSettings(settings).subscribe(() => {
      this.router.navigateByUrl('/');
      this.notificationService.showToast(
        'NOTIFICATION.SUCCESS',
        this.notificationService.MESSAGE_TYPE.SUCCESS
      );
    });
  }

  changeDateFormat(language: any): void {
    this.dateAdapter.setLocale(language);
  }

  showServicePinPolicy() {
    const dialog = this.matDialogService.open(ServicePinPolicyModalComponent, {
      panelClass: 'confirmation-popup',
    });
  }

  askComment() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
      data: {},
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.userService.askForHelp({ comment: result.comment }).subscribe(
            () => {
              this.notificationService.showToast(
                'NOTIFICATIONS.USER-SETTINGS.HELP-REQUEST-SENT',
                this.notificationService.MESSAGE_TYPE.SUCCESS
              );
            },
            (error) => {
              this.handleError(error);
            }
          );
        }
      });
  }

  enableSaveButton() {
    this.userProfileForm.valueChanges.subscribe((_) => {
      this.disableSaveButton = this.userProfileForm.pristine;
    });
  }

  private showInfoPopup() {
    if (this.currentUser.externalSource) {
      this.externalSource = this.currentUser.externalSource;
      this.disableFieldsForExternalSource();
      const dialog = this.matDialogService.open(GeneralInfoModalComponent, {
        panelClass: 'confirmation-popup',
        data: {
          messageKeyOne: 'NOTIFICATIONS.USER-SETTINGS.EDIT-ENABLED',
        },
      });
    }
  }

  resetPassword() {
    this.userService
      .resetPassword()
      .pipe(first())
      .subscribe(
        () => {
          this.notificationService.showToast(
            'NOTIFICATIONS.USER-SETTINGS.PASSWORD-RESET-REQUEST-SENT',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }
}
