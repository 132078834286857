import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OpenTransactionBookingTableService {
  constructor() {}

  /**
   * This method is used to calculate the Vat Percentage based in a value.
   * I move this from the controller to the services.
   */
  calculateVatPercent(grossAmount: number, vatAmount: number): number {
    grossAmount = this.roundToTwoDecimals(grossAmount);
    vatAmount = this.roundToTwoDecimals(vatAmount);

    const vatPerResult: number = this.roundToTwoDecimals(
      (vatAmount * 100) / (grossAmount - vatAmount)
    );

    return isNaN(vatPerResult) ? 0.00 : vatPerResult;
  }

  roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
