import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AccountStatementConfigurationService } from 'src/app/shared/services/account-statement-configuration/account-statement-configuration.service';
import { accountStatementConfigurationColumnsConf } from 'src/app/in-memory-data/system-configuration/account-statement-configuration/table-columns-configuration';
import { accountStatementConfigurationColumns } from 'src/app/in-memory-data/system-configuration/account-statement-configuration/table-columns';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from '../../../shared/services/draft/draft.service';

@Component({
  selector: 'app-account-statement-configuration-list',
  templateUrl: './account-statement-configuration-list.component.html',
  styleUrls: ['./account-statement-configuration-list.component.less'],
})
export class AccountStatementConfigurationListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = accountStatementConfigurationColumnsConf;
  displayedColumns = accountStatementConfigurationColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'accountStatementConfigurationData_table';
  accountStatementConfigurationData = [];
  dataSource = new MatTableDataSource<any>(
    this.accountStatementConfigurationData
  );
  searchInput: any;
  editMode: any;
  entityName = 'accountStatementConfigurationData';

  filter;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private accountStatementConfigurationService: AccountStatementConfigurationService,
    private notificationService: NotificationService,
    private userService: UserService,
    private matDialogService: MatDialog,
    private draftService: DraftService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  editAccountStatementConfiguration(element) {
    const state = { accountStatementConfiguration: element };
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/accountStatementConfiguration/create',
      { state }
    );
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }
  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                accountStatementConfigurationColumnsConf,
                accountStatementConfigurationColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  create() {
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/accountStatementConfiguration/create',
      { state: {} }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = accountStatementConfigurationColumnsConf;
    this.displayedColumns = accountStatementConfigurationColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.getAllAccountStatements();
  }

  getAllAccountStatements() {
    this.accountStatementConfigurationService
      .getAccountStatements()
      .subscribe((data) => {
        this.notificationService.dismissMessage();
        this.accountStatementConfigurationData = data;
        this.dataSource.data = data;
        this.getUserDrafts();
      });
  }

  searchAccountStatement() {
    const foundASCs = new Array();
    const input = this.searchInput.toString().toLowerCase();
    this.accountStatementConfigurationData.forEach((asc) => {
      if (
        asc.visibleSeqId.toString().toLowerCase().includes(input) ||
        (asc.name && asc.name.toString().toLowerCase().includes(input)) ||
        (asc.account && asc.account.toString().toLowerCase().includes(input)) ||
        (asc.beasRelevant &&
          asc.beasRelevant.toString().toLowerCase().includes(input)) ||
        (asc.fiAccount &&
          asc.fiAccount.toString().toLowerCase().includes(input)) ||
        (asc.currency &&
          asc.currency.toString().toLowerCase().includes(input)) ||
        (asc.businessUnitId &&
          asc.businessUnitId.toString().toLowerCase().includes(input) &&
          this.displayedColumns.indexOf('businessUnitId') > 0) ||
        (asc.platformId &&
          asc.platformId.toString().toLowerCase().includes(input) &&
          this.displayedColumns.indexOf('platformId') > 0) ||
        (asc.clientId &&
          asc.clientId.toString().toLowerCase().includes(input) &&
          this.displayedColumns.indexOf('clientId') > 0) ||
        (asc.debtorId &&
          asc.debtorId.toString().toLowerCase().includes(input) &&
          this.displayedColumns.indexOf('debtorId') > 0) ||
        (asc.btcMapping &&
          asc.btcMapping.toString().toLowerCase().includes(input) &&
          this.displayedColumns.indexOf('btcMapping') > 0)
      ) {
        foundASCs.push(asc);
      }
    });
    this.dataSource.data = foundASCs;
  }

  deleteAccountStatement(element) {
    if (!element.draft) {
      const dialog = this.matDialogService.open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          const request = {
            id: element.id,
            comment: data.comment,
          };
          this.accountStatementConfigurationService
            .deleteAccountStatementConfiguration(request)
            .subscribe(() => {
              this.notificationService.showToast(
                'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-ENTRY-TASK',
                this.notificationService.MESSAGE_TYPE.SUCCESS
              );

              setTimeout(() => {
                this.router.navigateByUrl('/tasks');
              }, 2000);
            });
        }
      });
    } else {
      this.deleteUserDraft(element.draftId);
    }
  }

  getUserDrafts() {
    this.draftService
      .retrieveLocalDrafts('ACCOUNT_STATEMENT_CONFIGURATION')
      .subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach((draft) => {
              const ascDraft = JSON.parse(draft.draftJson);
              ascDraft.draft = true;
              ascDraft.draftId = draft.guid;
              ascDraft.operationUser = draft.operationUser;
              this.dataSource.data.unshift(ascDraft);
            });
            this.dataSource.filter = '';
          } else {
            this.showNoDataFound();
          }
        },
        (error) => {
          this.showNoDataFound();
        }
      );
  }

  deleteUserDraft(draftId) {
    this.draftService.deleteUserDraft(draftId).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          null
        );
      }
    });
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO,
        null
      );
    }
  }
}
