<div class="info-container">
  <h5 class="title-info">{{ client.name }}</h5>

  <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="street">{{ 'ADDRESS.STREET' | translate }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="street" class="information">{{ client.address.street }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="number">{{ 'ADDRESS.NUMBER' | translate }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="number" class="information">{{ client.address.number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="zipCode">{{ 'ADDRESS.ZIP-CODE' | translate }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="zipCode" class="information">{{ client.address.zipCode }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="city">{{ 'ADDRESS.CITY' | translate }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="city" class="information">{{ client.address.city }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="country">{{ 'ADDRESS.COUNTRY' | translate }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="country" class="information">{{ client.address.country }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <p>&nbsp;</p>
  <mat-tab-group>
    <mat-tab label="{{ 'riskmanagement.overallRating' | translate }}">
      <div class="content-container">
        <h5>
          {{ 'RISK-MANAGEMENT.OVERALL-RATING' | translate }}
        </h5>
        <app-rating-bar [grade]="grade"></app-rating-bar>

        <div
          class="container container-risk-info"
          fxLayout="row wrap"
          fxlayout.sm="column"
          fxlayout.xs="column"
          fxLayoutGap="7px grid"
        >
          <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
            <div fxLayout="row">
              <div fxFlex="20">
                <div class="label-container">
                  <label> {{ 'RISK-MANAGEMENT.LAST-RATING' | translate }}</label>
                </div>
              </div>
              <div fxFlex="80">
                <div class="span-container">
                  <span class="information">{{ client.lastRating }}</span>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
            <div fxLayout="row">
              <div fxFlex="20">
                <div class="label-container">
                  <label>{{ 'RISK-MANAGEMENT.TOTAL-LIMIT' | translate }}</label>
                </div>
              </div>
              <div fxFlex="80">
                <div class="span-container">
                  <span class="information">{{ client.totalLimit }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <table mat-table [dataSource]="dataSource" matSort aria-hidden="true">
            <ng-container matColumnDef="ratingModule">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">
                {{ 'riskmanagement.ratingModules' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <i class="{{ element.iconName }}" aria-hidden="true"></i>
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dateLastRating">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">
                {{ 'RISK-MANAGEMENT.DATE-LAST-RATING' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container matColumnDef="singleRating">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">
                {{ 'RISK-MANAGEMENT.SINGLE-RATING' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <app-rating-bar [grade]="element.singleRating"></app-rating-bar>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'riskmanagement.creditreForm' | translate }}">
      <div class="content-container credit-info">
        <div class="col business-info-col">
          <h5>{{ 'RISK-MANAGEMENT.BUSINESS-INFO' | translate }}</h5>

          <div
            class="container container-risk-info"
            fxLayout="row wrap"
            fxlayout.sm="column"
            fxlayout.xs="column"
            fxLayoutGap="7px grid"
          >
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CREFO-ID' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.crefoId }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.TYPE' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.type }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.DATE' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.date }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.SUPPLEMENTARY-PERIOD' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.supplementaryPeriod }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.MONITORING' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.monitoring }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.INDEX' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.index }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.PAYMENT-METHOD' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.paymentMethod }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.PROBABILITY-DEFAULT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.probabilityDefault }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CREDIT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.credit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.DIT-JUDGEMENT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.creditJudgement }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.EXPERTISE' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.expertise }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CREDIT-WORTHINESS' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.creditWorthiness }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CREDIT-LIMIT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ credit.creditLimit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <h5>{{ 'RISK-MANAGEMENT.HISTORY' | translate }}</h5>
          <table aria-hidden="true" mat-table [dataSource]="pdfDataSource" matSort class="pdf-table">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">
                {{ 'riskmanagement.date' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container matColumnDef="pdfNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">PDF</th>
              <td mat-cell *matCellDef="let element">
                {{ element.pdfNumber }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pdfDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: pdfDisplayedColumns" class="element-row"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'riskmanagement.eulerHermes' | translate }}">
      <div class="content-container credit-info">
        <div class="col business-info-col">
          <h5>{{ 'RISK-MANAGEMENT.BUSINESS-INFO' | translate }}</h5>

          <div
            class="container container-risk-info"
            fxLayout="row wrap"
            fxlayout.sm="column"
            fxlayout.xs="column"
            fxLayoutGap="7px grid"
          >
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.EH-ID' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.ehId }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.DATE' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.date }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CURRENT-CLIENT-LIMIT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.currentClientLimit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.LIMITATION' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.limitation }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.ADDITIONAL-LIMIT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.additionalLimit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.APPROVAL-SUM' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.approvalSum }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.DESCISION-TEXT' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.decisionText }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.EH-RATING' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.ehRating }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.TCI-INFO' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.tciInfo }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.GROUP' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.group }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.CAM-RATING' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.camRating }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="row">
                <div fxFlex="30">
                  <div class="label-container">
                    <label>{{ 'RISK-MANAGEMENT.EXPERTISE' | translate }}</label>
                  </div>
                </div>
                <div fxFlex="70">
                  <div class="span-container">
                    <span class="information">{{ euler.expertise }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <h5>{{ 'RISK-MANAGEMENT.HISTORY' | translate }}</h5>
          <table aria-hidden="true" mat-table [dataSource]="pdfDataSource" matSort class="pdf-table">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">
                {{ 'RISK-MANAGEMENT.DATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container matColumnDef="pdfNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" scope="col">PDF</th>
              <td mat-cell *matCellDef="let element">
                {{ element.pdfNumber }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pdfDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: pdfDisplayedColumns" class="element-row"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
