import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { contactTypeEnum } from 'src/app/in-memory-data/company/contact-person/enums/contact-types-enums';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { contactPersonColumnsConf } from 'src/app/in-memory-data/company/contact-person/table-columns-configuration';
import { contactPersonColumns } from 'src/app/in-memory-data/company/contact-person/table-columns';
import { SearchService } from 'src/app/shared/services/search/search-service';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';
import { Observable, of } from 'rxjs';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { v4 as uuidv4 } from 'uuid';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user/user.service';
import { preferredContactTypeEnum } from '../../../in-memory-data/contact-person-link/enums/preferred-contact-types-enums';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';

@Component({
  selector: 'app-company-contact-person',
  templateUrl: './company-contact-person.component.html',
  styleUrls: ['./company-contact-person.component.less'],
})
export class CompanyContactPersonComponent implements OnInit {
  entityName = 'company-contact-person';
  contactPersonForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  showForm = false;
  noExistingData = true;
  isTask = false;
  editableTask = false;
  isApproved = false;
  contactTypes = contactTypeEnum;
  searchInput: any = '';
  contactPersonsList = [];
  approvedContactPeople = [];
  contactPersonTask;
  contactPersonApproved;
  contactTypePreferences = preferredContactTypeEnum;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() isDraftExists: boolean;
  @Input() companyGuid: string;
  @Input() companyApproved;
  @Input() readOnly: boolean;
  dataSource = new MatTableDataSource<any>(this.contactPersonsList);
  columns = contactPersonColumnsConf;
  displayedColumns = contactPersonColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  CONFIGURAIONT_KEY = 'company_contact_person_table';
  countries: ICountry[] = [];
  languages: any[];
  countries$: Observable<ICountry[]>;
  countryCode;
  state;
  snapshot;
  animalWelfareEditView = false;
  disableSave = false;
  totalCount = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private countryPicker: CountryPickerService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private router: Router,
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private activatedRoute: ActivatedRoute
  ) {
    this.contactPersonFormBuilder();
    this.filterFormBuilder();
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.dataSource.sort = this.sort;
    this.countryCode = '';
    this.pickCountries();
    this.getCountryCode();
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    this.loadData();
    if (this.state.contractType) {
      this.animalWelfareEditView = true;
      if (this.state.company.companyGuid) {
        this.getCompanyValuesForExistingCompany(this.state.company.companyGuid);
      }
      this.contactPersonForm.disable();
    }
    if (this.companyGuid) {
      this.getCompanyValuesForExistingCompany(this.companyGuid);
      this.animalWelfareEditView = true;
      this.contactPersonForm.disable();
    }
    if (this.router.url.includes('operator')) {
      this.disableSave = true;
    }
  }

  contactPersonFormBuilder() {
    this.contactPersonForm = this.formBuilder.group({
      title: [null, Validators.required],
      surname: [null, Validators.required],
      name: [null, Validators.required],
      contactType: [null, Validators.required],
      telephoneNumber: [null, Validators.required],
      faxNumber: [null],
      mobileNumber: [null],
      email: [null, [Validators.required, Validators.email]],
      dateOfBirth: [null],
      language: [null, Validators.required],
      addressId: [null],
      address: this.formBuilder.group({
        street: [null, Validators.required],
        number: [null, Validators.required],
        city: [null, Validators.required],
        zipCode: [null, Validators.required],
        country: [null, Validators.required],
        timezone: [null],
      }),
      countryCode: [null],
      postOfficeBox: [null],
      id: [null],
      additionalInfo: [null],
      defaultContact: [null],
      preferredContactType: [null],
      contactPersonSubstituteId: [null],
      representative: [null],
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  loadData() {
    if (this.state.task) {
      this.noExistingData = false;
      this.isTask = true;
      this.getCompanyValuesForTask();
    } else if (this.state.company) {
      if (this.state.company.guid) {
        this.isApproved = true;
        this.getCompanyValuesForExistingCompany(this.state.company.guid);
      }
    } else if (this.snapshot.params.guid) {
      this.isApproved = true;
      this.getContactPersonsForCompanyByCompanyGuid(this.companyApproved);
      this.totalCount = this.companyApproved?.contactPeople?.length;
    }
  }

  getContactPersonsForCompanyByCompanyGuid(data) {
    this.noExistingData = false;
    this.contactPersonsList = data.contactPeople;
    if (this.contactPersonsList && this.contactPersonsList.length > 0) {
      this.contactPersonForm.get('representative').disable();
    } else {
      this.contactPersonForm.get('contactPersonSubstituteId').disable();
    }
    for (const contactPerson of data.contactPeople) {
      this.userService
        .getCountContactPerson(contactPerson.id)
        .subscribe((numberOfUsers) => {
          contactPerson['numberOfUsers'] = numberOfUsers;
        });
      contactPerson['fullName'] =
        contactPerson.title +
        ' ' +
        contactPerson.name +
        ' ' +
        contactPerson.surname;
    }
    this.dataSource.data = data.contactPeople;
    this.approvedContactPeople = Object.assign([], data.contactPeople);
  }

  getCompanyValuesForExistingCompany(companyGuid) {
    this.companyService.getCurrentApproved(companyGuid).subscribe(
      (data) => {
        this.noExistingData = false;
        this.contactPersonsList = data.contactPeople;
        this.totalCount = data.contactPeople?.length;
        if (this.contactPersonsList && this.contactPersonsList.length > 0) {
          this.contactPersonForm.get('representative').disable();
        } else {
          this.contactPersonForm.get('contactPersonSubstituteId').disable();
        }
        for (const contactPerson of data.contactPeople) {
          this.userService
            .getCountContactPerson(contactPerson.id)
            .subscribe((numberOfUsers) => {
              contactPerson['numberOfUsers'] = numberOfUsers;
            });
          contactPerson['fullName'] =
            contactPerson.title +
            ' ' +
            contactPerson.name +
            ' ' +
            contactPerson.surname;
        }
        this.dataSource.data = data.contactPeople;
        this.approvedContactPeople = Object.assign([], data.contactPeople);
      },
      (err) => console.log('ERROR FINDING')
    );
  }

  getCompanyValuesForTask() {
    this.companyService
      .getCompanyWithTaskId(window.history.state.task.id)
      .subscribe((data) => {
        this.dataSource.data = data.contactPeople;
        if (data.contactPeople && data.contactPeople.length > 1) {
          for (const contactPerson of data.contactPeople) {
            this.userService
              .getCountContactPerson(contactPerson.id)
              .subscribe((numberOfUsers) => {
                contactPerson['numberOfUsers'] = numberOfUsers;
              });
          }
        }
        this.contactPersonsList = data.contactPeople;
        this.companyService
          .getCurrentApproved(data.guid)
          .subscribe((company) => {
            if (company.contactPeople && company.contactPeople.length > 1) {
              for (const contactPerson of company.contactPeople) {
                this.userService
                  .getCountContactPerson(contactPerson.id)
                  .subscribe((numberOfUsers) => {
                    contactPerson['numberOfUsers'] = numberOfUsers;
                  });
              }
            }
            this.approvedContactPeople = Object.assign(
              [],
              company.contactPeople
            );
          });
      });
    if (
      window.history.state.task.type ===
      'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT'
    ) {
      this.animalWelfareEditView = true;
    }
    this.taskService.currentEditableTask.subscribe((editableTask) => {
      this.editableTask = editableTask;
      if (!this.editableTask && this.showForm) {
        this.clear();
      }
    });
  }

  pickCountries() {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.countries$ = of(countries);
      this.countries = countries;
    });
  }

  getCountryCode() {
    this.contactPersonForm
      .get('address.country')
      .valueChanges.subscribe((code) => {
        this.countryCode = code;
        this.contactPersonForm.get('countryCode').patchValue(code);
        this.populateLanguageList(code);
      });
  }

  populateLanguageList(countryCode: string) {
    if (this.countries.length > 0) {
      this.languages = [];
      const country = this.countries.filter(
        (countryFilter) => countryFilter.cca3 === countryCode
      )[0];
      if (!country) {
        return;
      }
      const keys = Object.keys(country.languages);
      let german = false;

      for (const key of keys) {
        const obj = { code: key.toUpperCase() };
        if (obj.code === 'DEU') {
          german = true;
          this.languages.push({ code: 'DEU' });
        }
      }

      this.languages.push({ code: 'ENG' });
      if (!german) {
        this.languages.push({ code: 'DEU' });
      }
    }
  }

  showContactPersonForm() {
    this.contactPersonApproved = null;
    this.showForm = true;
    this.contactPersonForm.controls.id.setValue(uuidv4());
  }

  searchContactPerson() {
    this.searchService.search(
      this.contactPersonsList,
      this.searchInput,
      this.dataSource
    );
  }

  saveContactPerson() {
    this.noExistingData = false;
    if (this.dataSource.data.length === 0) {
      this.dataSource.data.push(this.contactPersonForm.value);
      this.contactPersonsList = this.dataSource.data;
    } else {
      const contactPersonIndex = this.dataSource.data.findIndex(
        (item) => item.id === this.contactPersonForm.controls.id.value
      );
      if (contactPersonIndex !== -1) {
        const contactPersonFormObject = this.contactPersonForm.value;
        this.userService
          .getCountContactPerson(this.contactPersonForm.value.id)
          .subscribe((numberOfUsers) => {
            contactPersonFormObject['numberOfUsers'] = numberOfUsers;
          });
        this.dataSource.data[contactPersonIndex] = contactPersonFormObject;
      } else {
        this.dataSource.data.push(this.contactPersonForm.value);
        this.contactPersonsList = this.dataSource.data;
      }
    }
    this.dataSource.filter = '';
    this.contactPersonForm.reset();
    this.showForm = false;
  }

  clear() {
    this.contactPersonForm.reset();
    this.showForm = false;
  }

  edit(row) {
    if (!this.animalWelfareEditView) {
      if (this.state.company) {
        this.contactPersonApproved = this.approvedContactPeople.find(
          (element) => element.id === row.id
        );

        if (this.contactPersonApproved === -1) {
          this.contactPersonApproved = row;
        }
      }
    }
    if (
      (!this.isTask || (this.isTask && this.editableTask)) &&
      !this.isDraftExists &&
      !(this.isEditDeniedDueToOrigin() && row.uneditableExternalSource)
    ) {
      this.showForm = true;
      this.contactPersonForm.patchValue({
        ...row,
        address: row.address || {
          street: null,
          number: null,
          city: null,
          zipCode: null,
          country: null,
          timezone: null,
        },
      });
      if (row.uneditableExternalSource) {
        this.disableSave = true;
      }
      this.checkNumberofUsers(row);
    }
  }

  checkNumberofUsers(row) {
    if (row?.numberOfUsers === 0) {
      this.contactPersonForm.get('email').clearValidators();
      this.contactPersonForm.get('email').setValidators([Validators.email]);
      this.contactPersonForm.get('email').updateValueAndValidity();
    } else {
      this.contactPersonForm.get('email').clearValidators();
      this.contactPersonForm
        .get('email')
        .setValidators([Validators.required, Validators.email]);
      this.contactPersonForm.get('email').updateValueAndValidity();
    }
  }

  hasContactPersonChanged(element, property) {
    if (
      this.approvedContactPeople &&
      this.approvedContactPeople.length > 0 &&
      this.contactPersonsList
    ) {
      const contactIndex = this.contactPersonsList.findIndex((item) => {
        return item.id === element.id;
      });
      const contact = this.contactPersonsList[contactIndex];

      const approvedContactIndex = this.approvedContactPeople.findIndex(
        (item) => {
          return item.id === element.id;
        }
      );
      if (approvedContactIndex === -1) {
        return true;
      }

      const contactApproved = this.approvedContactPeople[approvedContactIndex];

      if (property === 'address' && contact.address) {
        for (const [key, value] of Object.entries(contact.address)) {
          if (
            key !== 'operation' &&
            key !== 'operationUser' &&
            key !== 'timeZone' &&
            key !== 'countryCode' &&
            key !== 'id' &&
            key !== null
          ) {
            return (
              contactApproved.address &&
              contact.address[key] !== contactApproved.address[key]
            );
          }
        }
      } else if (property === 'name') {
        if (contact.name === contactApproved.name) {
          return contact.surname !== contactApproved.surname;
        } else {
          return true;
        }
      } else {
        return contact[property] !== contactApproved[property];
      }
    } else {
      return false;
    }
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                contactPersonColumnsConf,
                contactPersonColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setDefaultColumnValues() {
    this.columns = contactPersonColumnsConf;
    this.displayedColumns = contactPersonColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getSubstituteName(substituteId) {
    if (!this.contactPersonsList) {
      return '';
    }
    const substitute = this.contactPersonsList.find(
      (element) => element.id === substituteId
    );
    return substitute
      ? substitute.title + ' ' + substitute.name + ' ' + substitute.surname
      : '';
  }

  isMainContactPersonYesOrNo(isMainContactPerson) {
    if (isMainContactPerson) {
      return 'GENERAL.TRUE';
    } else {
      return 'GENERAL.FALSE';
    }
  }

  isEditDeniedDueToOrigin() {
    if (this.companyApproved && this.companyApproved.createdExternally) {
      return (
        this.companyApproved.createdExternally &&
        !this.businessUnitService.getExternallyCreatedRecordEditability()
      );
    }
    return false;
  }

  getStreetAndNumber(address) {
    if (address.street !== null && address.number !== null) {
      return `${address.street} ${address.number}`;
    }
    return '';
  }

  getZipCodeAndCity(address) {
    if (address.zipCode !== null && address.city !== null) {
      return `${address.zipCode} ${address.city}`;
    }
    return '';
  }

  getCountry(address) {
    if (address.country !== null) {
      return `${address.country}`;
    }
    return '';
  }
}
