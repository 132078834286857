export const liquidityPlanAssetChangeColumns: string[] = [
  'select',
  'planAmount',
  'actualAmount',
  'kind',
  'type',
  'monthAndYear',
  'slider',
  'comment',
  'status',
  'createdBy',
  'createdOn',
  'approvedBy',
  'approvedOn',
  'actions',
];
