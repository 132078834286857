import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessUnitModule } from '../business-unit/business-unit.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { SystemConfigurationComponent } from './system-configuration/system-configuration.component';
import { AwInterfaceTransferProtocolComponent } from './aw-interface-transfer-protocol-management/aw-interface-transfer-protocol.component';
import { LiquidityPlanComponent } from './liquidity-plan/liquidity-plan.component';
import { LiquidityPlanFilterComponent } from './liquidity-plan/liquidity-plan-filter/liquidity-plan-filter.component';
import { LiquidityPlanAssetResultsComponent } from './liquidity-plan/liquidity-plan-asset-results/liquidity-plan-asset-results.component';
import { LiquidityPlanAssetsComponent } from './liquidity-plan/liquidity-plan-assets/liquidity-plan-assets.component';
import { DateValidator } from 'src/app/shared/validators/date-validator';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TransferQuantityGoodsListComponent } from './transfer-quantity-goods/transfer-quantity-goods-list/transfer-quantity-goods-list.component';
import { TransferQuantityGoodsCreateComponent } from './transfer-quantity-goods/transfer-quantity-goods-create/transfer-quantity-goods-create.component';
import { TransferQuantityGoodsDraftComponent } from './transfer-quantity-goods/transfer-quantity-goods-draft/transfer-quantity-goods-draft.component';
import { SalesEntryModule } from '../sales-entry/sales-entry.module';
import { VVVONrValidator } from './transfer-quantity-goods/validator/general-validator';
import { ControlBoardManagementModule } from './control-board-management/control-board-management.module';
import { GeneralValidator } from 'src/app/shared/validators/general-validator';
import { LivestockBlockingIndicatorsComponent } from './livestock-producer/livestock-producer-overview/livestock-blocking-indicators/livestock-blocking-indicators.component';
import { LivestockMovementDataComponent } from './livestock-producer/livestock-producer-overview/livestock-movement-data/livestock-movement-data.component';
import { LivestockFunctionalButtonsComponent } from './livestock-producer/livestock-producer-overview/livestock-functional-buttons/livestock-functional-buttons.component';
import { ManualSBlockTaskComponent } from './livestock-producer/tasks/manual-s-block-task/manual-s-block-task.component';
import { LivestockProducerOverviewComponent } from './livestock-producer/livestock-producer-overview/livestock-producer-overview.component';
import { LivestockProducerListComponent } from './livestock-producer/livestock-producer-list/livestock-producer-list.component';
import { LivestockDocumentsComponent } from './livestock-producer/livestock-producer-overview/livestock-documents/livestock-documents.component';
import { LivestockInformationBoxComponent } from './livestock-producer/livestock-producer-overview/livestock-information-box/livestock-information-box.component';
import { LivestockSettlementPeriodsComponent } from './livestock-producer/livestock-producer-overview/livestock-settlement-periods/livestock-settlement-periods.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TransferQuantityLabeledGoodsManagementModule } from './transfer-quantity-labeled-goods-management/transfer-quantity-labeled-goods-management.module';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { MultipleReturnCodesPerTaskComponent } from './multiple-return-codes-per-task/multiple-return-codes-per-task.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { AwContactPersonUpdateTaskComponent } from './aw-contact-person-update-task/aw-contact-person-update-task.component';
import { ReleaseUnusedBudgetComponent } from './release-unused-budget/release-unused-budget.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
  declarations: [
    SystemConfigurationComponent,
    AwInterfaceTransferProtocolComponent,
    DocumentManagementComponent,
    LiquidityPlanComponent,
    LiquidityPlanFilterComponent,
    LiquidityPlanAssetResultsComponent,
    LiquidityPlanAssetsComponent,
    TransferQuantityGoodsListComponent,
    TransferQuantityGoodsCreateComponent,
    TransferQuantityGoodsDraftComponent,
    LivestockProducerOverviewComponent,
    LivestockProducerListComponent,
    LivestockInformationBoxComponent,
    LivestockDocumentsComponent,
    LivestockBlockingIndicatorsComponent,
    LivestockSettlementPeriodsComponent,
    LivestockMovementDataComponent,
    LivestockFunctionalButtonsComponent,
    ManualSBlockTaskComponent,
    MultipleReturnCodesPerTaskComponent,
    AwContactPersonUpdateTaskComponent,
    ReleaseUnusedBudgetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    BusinessUnitModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgSelectModule,
    SharedModule,
    BackButtonModule,
    MatRadioModule,
    CurrencyMaskModule,
    DragDropModule,
    SharedModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    NgxMaskModule,
    MatStepperModule,
    FormElementsModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    SalesEntryModule,
    ControlBoardManagementModule,
    MatSidenavModule,
    TransferQuantityLabeledGoodsManagementModule,
    MatListModule,
    PowerBIEmbedModule,
  ],
  providers: [DateValidator, VVVONrValidator, GeneralValidator],
})
export class AnimalWelfareManagementModule {}
