import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { btcMappingCodeColumns } from 'src/app/in-memory-data/system-configuration/business-transaction-code-mapping/table-columns';
import { btcMappingCodeColumnsConf } from 'src/app/in-memory-data/system-configuration/business-transaction-code-mapping/table-columns-configuration';
import { BusinessTransactionCodeMappingService } from 'src/app/shared/services/business-transaction-code-mapping/business-transaction-code-mapping.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { AccountStatementConfigurationService } from 'src/app/shared/services/account-statement-configuration/account-statement-configuration.service';
import { DraftService } from '../../../shared/services/draft/draft.service';

@Component({
  selector: 'app-btc-mapping-list',
  templateUrl: './btc-mapping-list.component.html',
  styleUrls: ['./btc-mapping-list.component.less'],
})
export class BtcMappingListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = btcMappingCodeColumnsConf;
  displayedColumns = btcMappingCodeColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'businessTransactionCodeMapping_table';
  businessTransactionCodeMappingData = [];
  dataSource = new MatTableDataSource<any>();
  searchInput: any;
  editMode: any;
  entityName = 'business-transaction-code-mapping';
  filter: UntypedFormGroup;
  accountStatementsConfigurations: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private searchService: SearchService,
    private matDialogService: MatDialog,
    private businessTransactionCodeMappingService: BusinessTransactionCodeMappingService,
    private accountStatementConfigurationService: AccountStatementConfigurationService,
    private draftService: DraftService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.getAllBusinessTransactionCodeMapping();
    this.getAllAccountStatementsConfigurations();
  }

  getAllAccountStatementsConfigurations() {
    this.accountStatementConfigurationService
      .getAccountStatements()
      .subscribe((data) => {
        this.accountStatementsConfigurations = data;
      });
  }

  editBtcMapping(element) {
    const state = { btcMapping: element };
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/btcMapping/create',
      { state }
    );
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                btcMappingCodeColumnsConf,
                btcMappingCodeColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  create() {
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/btcMapping/create',
      { state: {} }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = btcMappingCodeColumnsConf;
    this.displayedColumns = btcMappingCodeColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getAllBusinessTransactionCodeMapping() {
    this.businessTransactionCodeMappingService
      .getBusinessTransactionCodesMapping()
      .subscribe((data) => {
        this.notificationService.dismissMessage();
        this.businessTransactionCodeMappingData = data;
        this.dataSource.data = data;
        this.getUserDrafts();
      });
  }

  searchBusinessTransactionData() {
    const foundBtcMappings = new Array();
    this.businessTransactionCodeMappingData.forEach((btcMapping) => {
      if (
        btcMapping.mappingId
          .toString()
          .toLowerCase()
          .includes(this.searchInput.toString().toLowerCase()) ||
        btcMapping.name
          .toString()
          .toLowerCase()
          .includes(this.searchInput.toString().toLowerCase())
      ) {
        foundBtcMappings.push(btcMapping);
      }
    });
    this.dataSource.data = foundBtcMappings;
  }

  deleteBTCMapping(element) {
    if (!element.draft) {
      if (this.checkAccountStatementConfiguration(element)) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.TOAST-MESSAGE-ACCOUNT-STATEMENT-CONFIGURATION-EXISTS',
          this.notificationService.MESSAGE_TYPE.ERROR
        );
      } else {
        const dialog = this.matDialogService.open(ConfirmationModalComponent, {
          panelClass: 'confirmation-popup',
        });
        dialog.afterClosed().subscribe((data) => {
          if (data) {
            const request = {
              id: element.id,
              comment: data.comment,
            };
            this.businessTransactionCodeMappingService
              .deleteBusinessTransactionCodeMapping(request)
              .subscribe(() => {
                this.notificationService.showToast(
                  'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-ENTRY-TASK',
                  this.notificationService.MESSAGE_TYPE.SUCCESS
                );

                setTimeout(() => {
                  this.router.navigateByUrl('/tasks');
                }, 2000);
              });
          }
        });
      }
    } else {
      this.deleteUserDraft(element.draftId);
    }
  }

  checkAccountStatementConfiguration(element) {
    let result = false;
    if (
      this.accountStatementsConfigurations &&
      this.accountStatementsConfigurations.length > 0
    ) {
      this.accountStatementsConfigurations.forEach((item) => {
        if (item.btcMapping === element.name) {
          result = true;
        }
      });
    } else {
      result = false;
    }

    return result;
  }

  getUserDrafts() {
    this.draftService.retrieveLocalDrafts('BTC_MAPPING').subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const btcDraft = JSON.parse(draft.draftJson);
            btcDraft.draft = true;
            btcDraft.draftId = draft.guid;
            btcDraft.operationUser = draft.operationUser;
            this.dataSource.data.unshift(btcDraft);
          });
          this.dataSource.filter = '';
        } else {
          this.showNoDataFound();
        }
      },
      (error) => {
        this.showNoDataFound();
      }
    );
  }

  deleteUserDraft(draftId) {
    this.draftService.deleteUserDraft(draftId).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }
}
