<div class="dialog-content">
  <div [hidden]="loader">
    <div [hidden]="availableDocumentHistories != true">
      <div class="filters" [formGroup]="filterForm">
        <div class="table-container">
          <table
            aria-hidden="true"
            mat-table
            [dataSource]="dataSource"
            matSort
            id="{{ entityName }}-search-results-table"
            class="general-table-alignment"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleRow(element) : null"
                  [checked]="selection.isSelected(element)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th number-row-header"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-name"
              >
                {{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.NAME' | translate }}
                <app-table-menu-sort-filter
                  choice="name"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
                class="number-row"
              >
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="vvvoNr">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-vvvoNr"
              >
                {{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VVVO-NR' | translate }}
                <app-table-menu-sort-filter
                  choice="location_number"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-vvvoNr-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.locationNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="salesTaxId">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-salesTaxId"
              >
                {{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.SALES-TAX-ID' | translate }}
                <app-table-menu-sort-filter
                  choice="sales_tax_id"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-salesTaxId-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.salesTaxId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="street">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-street"
              >
                {{ 'address.street' | translate }}
                <app-table-menu-sort-filter
                  choice="street"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-street-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.street }}
              </td>
            </ng-container>
            <ng-container matColumnDef="number">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-number"
              >
                {{ 'address.number' | translate }}
                <app-table-menu-sort-filter
                  choice="number"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-number-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.number }}
              </td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-city"
              >
                {{ 'address.city' | translate }}
                <app-table-menu-sort-filter
                  choice="city"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-city-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.city }}
              </td>
            </ng-container>
            <ng-container matColumnDef="zipCode">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-zipCode"
              >
                {{ 'address.zipCode' | translate }}
                <app-table-menu-sort-filter
                  choice="zip_code"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-zipCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.zipCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="country">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-country"
              >
                {{ 'address.country' | translate }}
                <app-table-menu-sort-filter
                  choice="country"
                  [dataSource]="dataSource"
                  [originalDataSource]="originalDataSource"
                  [pageIndex]="paginator.pageIndex"
                  [pageSize]="pageSize"
                  [sortDirection]="sortDirection"
                  [callback]="filterData"
                  [getDistinctValues]="getFieldNames"
                  [sortTable]="sortTable"
                ></app-table-menu-sort-filter>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-country-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.country }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>
          </table>
          <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>

    <div class="no-transactions-container" [hidden]="availableDocumentHistories == true">
      <div mat-dialog-content class="background-dark">
        <span>
          {{ 'ADD-TRANSACTION-CODE-MODAL.EMPTY-RESULTS' | translate }}
        </span>
      </div>
    </div>

    <div class="buttons">
      <button
        id="{{ entityName }}-transactions-modal-close-button"
        mat-button
        class="custom-button-cancel"
        [mat-dialog-close]
      >
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
      <button
        id="{{ entityName }}-transactions-modal-submit-button"
        mat-button
        class="custom-button"
        id="acquire"
        [disabled]="selection.isEmpty()"
        [mat-dialog-close]="selection.selected"
        style="margin-right: 0"
      >
        {{ 'GENERAL.CONFIRM' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="loader" class="loader-container">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
