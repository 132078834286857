<ng-container *ngIf="!disableSortByColumn">
  <button mat-button [matMenuTriggerFor]="menu" id="sort-btn" (click)="getAdditionalOptions()">
    <img
      src="../../../../assets/fonts/custom-icons/icons/{{ imageSrc }}.svg"
      id="sort-image"
      class="sort-icon sort-icon-main sort-image"
      alt="sort and filter icon"
    />
  </button>
</ng-container>
<mat-menu #menu="matMenu">
  <p class="header">{{ 'SORT' | translate }}</p>
  <button [disabled]="sortDisabled" mat-menu-item class="asce" (click)="setAsce()" id="{{ entityName }}-asce-button">
    <img
      src="../../../../assets/fonts/custom-icons/icons/ic_sort_ascending.svg"
      class="sort-icon"
      alt="sort and filter icon"
    />
    {{ 'ASCENDING' | translate }}
  </button>
  <button [disabled]="sortDisabled" mat-menu-item class="desc" (click)="setDesc()" id="{{ entityName }}-desc-button">
    <img
      src="../../../../assets/fonts/custom-icons/icons/ic_sort_abusive.svg"
      class="sort-icon"
      alt="sort and filter icon"
    />
    {{ 'DESCENDING' | translate }}
  </button>
  <ng-container *ngIf="!disableFilterByColumn">
    <p class="header" *ngIf="(sidebar || getDistinctValues) && enableFilter">
      {{ 'FILTER' | translate }}
    </p>
    <div class="filter-input" *ngIf="(sidebar || getDistinctValues) && enableFilter">
      <input
        type="text"
        class="search-input"
        placeholder="{{ 'SEARCH' | translate }}"
        *ngIf="!date"
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchValueChanged()"
        (keydown.enter)="$event.stopPropagation()"
        (click)="$event.stopPropagation()"
        id="{{ entityName }}-search-input"
        removeHtmlTagsForNgModel
      />
      <button
        mat-button
        class="custom-button search-button"
        *ngIf="!date"
        (click)="$event.stopPropagation()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons">search</span>
      </button>
      <form name="additional-filters" *ngIf="!date" autocomplete="off" [formGroup]="form">
        <cdk-virtual-scroll-viewport *ngIf="choice" itemSize="48" class="additional-filters">
          <span class="dynamic-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
          <span *ngIf="!loaderSpinner">
            <mat-option class="options" *cdkVirtualFor="let item of options" (click)="$event.stopPropagation()">
              <mat-checkbox
                [disabled]="filterDisabled"
                name="{{ item }}-checkbox"
                class="checkbox"
                (change)="applyFilter(item, $event)"
                [checked]="isValueApplied(item)"
              >
                <span *ngIf="!sidebar && !sortableDate">
                  {{ translatePrefix + getitem(item) | translate }}
                </span>
                <span *ngIf="!sidebar && sortableDate">
                  {{ item | date: dateFormat }}
                </span>
                <span *ngIf="sidebar">
                  {{ item.translateKey | translate: getValueForSplitScreenFilters(item) }}
                </span>
              </mat-checkbox>
            </mat-option>
          </span>
        </cdk-virtual-scroll-viewport>
      </form>
      <button
        mat-button
        class="custom-button clear-button"
        (click)="clearFilters()"
        id="{{ entityName }}-clear-filters"
      >
        <span>
          {{ 'Clear filters' | translate }}
        </span>
      </button>
    </div>
  </ng-container>
</mat-menu>
