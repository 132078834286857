import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.less'],
})
export class CurrencyInputComponent {
  @Input() control: UntypedFormControl;
  @Input() labelKey = '';
  @Input() isDisabled = false;
  @Input() suffix: string;
  mask = 'separator.2';
  thousandSeparator = '.';
  decimalMarker = ',';
  type = 'text';
}
