import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { OpenItem } from 'src/app/shared/models/open-item';
import { BehaviorSubject } from 'rxjs';

import {
  customCreditDebitCardValidator,
  customSettlementValidator,
  vatAmoutValidator,
  ibanValidator,
  customAmountValidator,
  debtCollectionTransferDateValidator,
  bicValidator,
  paymentReferenceValidator,
  paymentSchemeValidator,
  accountHolderValidator,
  paymentDateValidator,
} from '../validators/custom-validators';
import { openItemPaymentScheme } from 'src/app/in-memory-data/open-item/open-item-payment-scheme';
import { openItemObjectionCode } from 'src/app/in-memory-data/open-item/open-item-objection-code';
import { openItemEmptyObject } from 'src/app/in-memory-data/open-item/open-item-empty-object';
import { openItemColumns } from 'src/app/in-memory-data/open-item/table-columns';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { AddDeductionModalComponent } from 'src/app/shared/modals/add-deduction-modal/add-deduction-modal.component';
import { AddDunningModalComponent } from 'src/app/shared/modals/add-dunning-modal/add-dunning-modal.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { ClientService } from 'src/app/shared/services/client/client.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserService } from '../../../shared/services/user/user.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const debitOrCreditStandard = 3;

@Component({
  selector: 'app-open-item-create',
  templateUrl: './open-item-create.component.html',
  styleUrls: ['./open-item-create.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class OpenItemCreateComponent implements OnInit {
  /*Info added to display table wth new style */

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() tableData: any;
  entityName = 'open-item-create';
  validators: any;

  dataSource = new MatTableDataSource<any>();

  bookingCodeExists = true;
  bookingCodeInput: any;

  errorOpenItemDate = false;
  errorDueDate = false;
  errorObjectionDate = false;
  errorClientOpenItemId = false;

  selection = new SelectionModel<any>(true, []);
  displayedColumns = openItemColumns;

  clientDebtorIds = new Array();
  branchIds = new Array();
  paymentSchemes: any;
  objectionCode: any;

  /*End Data for testing purposes*/

  openTasks: any = [];
  formGroup: UntypedFormGroup;
  titleAlert = 'This field is required';
  post: any = '';
  result: any;

  data: OpenItem[] = openItemEmptyObject;

  editableDataSource = new BehaviorSubject<any[]>([]);
  rows: UntypedFormArray = this.formBuilder.array([]);
  form: UntypedFormGroup = this.formBuilder.group({ objects: this.rows });
  companyData: any;
  currencyCodes: any;

  minDate: Date;
  maxDate: Date = new Date();

  sharedTableData: any;
  isIE = false;

  clientBookingCodes: any[];

  NOT_USED = 1;
  USED = 2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private openItemService: OpenItemService,
    private notificationService: NotificationService,
    private bookingCodeService: BookingCodeService,
    private clientService: ClientService,
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private transactionCodeAllocationService: TransactionCodeAllocationService
  ) {
    this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
  }

  ngOnInit() {
    const userSpecifiedDateFormat = this.userService
      .getDateFormat()
      .toUpperCase();
    DATE_FORMATS.display.dateInput = userSpecifiedDateFormat;
    DATE_FORMATS.parse.dateInput = userSpecifiedDateFormat;

    this.openItemService.currentSharedDisplayedColumns.subscribe(
      (displayedColumns) => (this.displayedColumns = displayedColumns)
    );

    this.data.forEach((d: OpenItem) => this.addRow(d, false));
    this.updateView();

    const cc = require('currency-codes');
    this.currencyCodes = cc.codes();

    this.paymentSchemes = openItemPaymentScheme;
    this.objectionCode = openItemObjectionCode;

    this.openItemService.currentSharedTableData.subscribe(
      (sharedTableData) => (this.sharedTableData = sharedTableData)
    );

    this.openItemService.currentClientDebtorIds.subscribe(
      (clientDebtorIds) => (this.clientDebtorIds = clientDebtorIds)
    );

    this.form.markAsUntouched();
    this.rows.markAsUntouched();
  }

  get formControls() {
    return this.form.controls;
  }
  get openItemsControls() {
    return this.formControls.objects as UntypedFormArray;
  }
  get objectRows() {
    return this.openItemsControls.controls[0];
  }
  get bookingCodeControl() {
    return this.objectRows.get('bookingCode');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  addRow(d?: OpenItem, noUpdate?: boolean) {
    const row = this.formBuilder.group(
      {
        debtorGuid: [''],
        clientDebtorId: ['', Validators.required],
        clientOpenItemId: ['', Validators.required],
        openItemDate: ['', Validators.required],
        bookingCode: ['', [Validators.required]],
        debitOrCredit: ['', Validators.required],
        amount: ['', Validators.required],
        currency: ['', Validators.required],
        vatAmount: ['', Validators.required],
        vatPercent: [d && d.vatPercent ? d.vatPercent : null, []],
        vatCode: [d && d.vatCode ? d.vatCode : null, []],
        dueDate: ['', Validators.required],
        referenceClientsOpenItemId: [],
        additionalInformation: [
          d && d.additionalInformation ? d.additionalInformation : null,
          [],
        ],
        branchId: [d && d.branchId ? d.branchId : null, []],
        iban: [d && d.payment.iban ? d.payment.iban : null, []],
        bic: [d && d.payment.bic ? d.payment.bic : null, []],
        accountHolder: [
          d && d.payment.accountHolder ? d.payment.accountHolder : null,
          [],
        ],
        paymentReference: [
          d && d.payment.paymentReference ? d.payment.paymentReference : null,
          [],
        ],
        paymentScheme: [
          d && d.payment.paymentScheme ? d.payment.paymentScheme : null,
          [],
        ],
        paymentDate: [],
        debtCollection: ['', Validators.required],
        debtCollectionTransferDate: [],
        objectionCode: [d && d.objectionCode ? d.objectionCode : null, []],
        objectionDate: [],
        objectionAmount: [
          d && d.objectionAmount ? d.objectionAmount : null,
          [],
        ],
        deductions: [d && d.deductions ? d.deductions : null, []],
        dunningInfo: [d && d.dunningInfo ? d.dunningInfo : null, []],
        settlementInEUR: [
          d && d.settlementInEUR ? d.settlementInEUR : null,
          [],
        ],
      },
      {
        validators: [
          customAmountValidator,
          customCreditDebitCardValidator,
          customSettlementValidator,
          vatAmoutValidator,
          debtCollectionTransferDateValidator,
          ibanValidator,
          bicValidator,
          paymentReferenceValidator,
          paymentSchemeValidator,
          accountHolderValidator,
          paymentDateValidator,
        ],
      }
    );
    this.rows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
    this.validators = row;
  }

  checkVatValidators() {
    const vatPercent =
      this.form.controls.objects['controls'][0].get('vatPercent');
    const vatCode = this.form.controls.objects['controls'][0].get('vatCode');

    if (vatPercent.untouched && vatCode.untouched) {
      vatCode.setValidators([Validators.required]);
    } else if (!vatPercent.untouched && vatPercent.value != null) {
      vatCode.setValidators(null);
    } else if (!vatCode.untouched && vatCode.value != null) {
      vatPercent.setValidators(null);
    }

    vatCode.updateValueAndValidity();
    vatPercent.updateValueAndValidity();
  }

  emptyTable() {
    this.rows = this.formBuilder.array([]);
    this.form = this.formBuilder.group({ objects: this.rows });
    this.data.forEach((d: OpenItem) => this.addRow(d, false));
    this.updateView();
    this.form.markAsUntouched();
    this.rows.markAsUntouched();

    Object.keys(this.form.controls.objects['controls'][0].controls).forEach(
      (key) => {
        this.form.controls.objects['controls'][0].controls[
          key
        ].markAsUntouched();
        this.form.controls.objects['controls'][0].controls[
          key
        ].updateValueAndValidity();
        this.form.controls.objects['controls'][0].controls[
          key
        ].markAsPristine();
        this.form.controls.objects['controls'][0].controls[
          key
        ].clearAsyncValidators();
        this.form.controls.objects['controls'][0].controls[
          key
        ].clearValidators();
      }
    );
  }

  updateView() {
    this.editableDataSource.next(this.rows.controls);
  }

  addNewItem() {
    if (this.rows.controls[0].value.objectionDate) {
      this.rows.controls[0].value.objectionDate = this.formatDate(
        this.rows.controls[0].value.objectionDate
      );
    }

    if (this.rows.controls[0].value.paymentDate) {
      this.rows.controls[0].value.paymentDate = this.formatDate(
        this.rows.controls[0].value.paymentDate
      );
    }

    this.rows.controls[0].value.dueDate = this.formatDate(
      this.rows.controls[0].value.dueDate
    );

    this.rows.controls[0].value.openItemDate = this.formatDate(
      this.rows.controls[0].value.openItemDate
    );

    const formGroup: UntypedFormGroup = this.rows.controls[0] as UntypedFormGroup;

    const request = {
      businessUnitId: this.openItemService.getBuId(),
      clientId: this.openItemService.getClientId(),
      openItems: [formGroup.getRawValue()],
    };

    this.validateOpenItemTranactionCodeAllocationAndCrerateOpenItem(request);
  }

  formatDate(dateP) {
    const date = new Date(dateP);
    const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1; // convert to positive value.
    date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);

    return date;
  }

  handleError(error) {
    for (const oneError of error.error) {
      this.notificationService.showToast(
        'OPEN-TASK.ERROR-MESSAGES.' + oneError.error.toUpperCase(),
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  getOpenTasks() {
    this.openItemService.getAllOpenTasks().subscribe(
      (data) => {
        this.openTasks = data;
        this.dataSource.data = data;
        if (this.openTasks.length === 0) {
          console.log('No task found');
        }
      },
      (error) => console.log(error)
    );
  }

  getClientDebtorIds() {
    for (const data of this.tableData) {
      this.clientDebtorIds.push(data.clientDebtorId);
    }
  }

  getBranchIds() {
    for (const data of this.tableData) {
      this.branchIds.push(data.branchId);
    }
  }

  checkAllOpenItemsId(value) {
    if (this.tableData) {
      for (const data of this.tableData) {
        if (data.clientOpenItemId === value) {
          this.errorClientOpenItemId = true;
          break;
        } else {
          this.errorClientOpenItemId = false;
        }
      }
    }
  }

  checkOpenItemDate(date: any) {
    const today = new Date();
    const dateParam = new Date(date);
    if (dateParam > today) {
      this.errorOpenItemDate = true;
    } else {
      this.errorOpenItemDate = false;
    }
  }

  checkDueDate() {
    const dueDate = new Date(this.form.value.objects[0].dueDate);
    const openItemDate = new Date(this.form.value.objects[0].openItemDate);

    if (dueDate < openItemDate) {
      this.errorDueDate = true;
    } else {
      this.errorDueDate = false;
    }
  }

  checkObjectionDate() {
    const dateParam = new Date(this.form.value.objects[0].objectionDate);
    const openItemDate = new Date(this.form.value.objects[0].openItemDate);

    if (
      openItemDate < dateParam &&
      this.form.controls.objects['controls'][0].controls.objectionDate.touched
    ) {
      this.errorObjectionDate = true;
    } else {
      this.errorObjectionDate = false;
    }
  }

  addDeduction() {
    const dialogRef = this.dialog.open(AddDeductionModalComponent, {
      data: {
        deductions: this.rows.controls[0].value.deductions,
        create: true,
      },
      panelClass: 'custom-tabs',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        this.rows.controls[0].value.deductions.push(data.data);
      }
    });
  }

  addDunningInfo() {
    const dialogRef = this.dialog.open(AddDunningModalComponent, {
      data: {
        dunningInfo: this.rows.controls[0].value.dunningInfo,
        create: true,
      },
      panelClass: 'custom-tabs',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        this.rows.controls[0].value.dunningInfo.push(data.data);
      }
    });
  }

  checkBookingCode(value?) {
    if (value !== undefined) {
      this.bookingCodeInput = value;
    }
    if (this.bookingCodeInput) {
      this.bookingCodeService.retrieveApproved(this.bookingCodeInput).subscribe(
        (data) => {
          if (data) {
            this.bookingCodeExists = true;
            this.enableDisableCreditDebitInputAndFillDependOn(
              data.cancellation,
              data.standard
            );
          } else {
            this.bookingCodeExists = false;
            this.resetCreditDebit();
          }
        },
        (error) => {
          this.bookingCodeExists = false;
          this.resetCreditDebit();
        }
      );
    } else {
      this.bookingCodeExists = false;
    }
  }

  private enableDisableCreditDebitInputAndFillDependOn(
    cancellation: boolean,
    bookingCodeStandard: number
  ) {
    if (!cancellation && bookingCodeStandard !== debitOrCreditStandard) {
      const debitOrCreditControl =
        this.openItemsControls.controls[0].get('debitOrCredit');
      debitOrCreditControl.setValue(bookingCodeStandard.toString());
      debitOrCreditControl.disable();
    } else {
      this.resetCreditDebit();
    }
  }

  private resetCreditDebit() {
    const debitOrCreditControl =
      this.openItemsControls.controls[0].get('debitOrCredit');
    debitOrCreditControl.enable();
    debitOrCreditControl.setValue('');
  }

  private createOpenItem(request) {
    this.openItemService.saveOpenItem(request).subscribe(
      (data) => {
        if (data.ids != null && data.ids !== undefined) {
          this.openItemService.getOpenItemssFunction(this.rows.controls);
          this.emptyTable();
        }
      },
      (error) => this.handleError(error)
    );
  }

  validateOpenItemTranactionCodeAllocationAndCrerateOpenItem(request) {
    request.openItems.forEach((element) => {
      this.businessUnitService
        .getCurrentApproved(request.businessUnitId)
        .subscribe(
          (data) => {
            this.transactionCodeAllocationService
              .retrieveTransactionCodeAllocation(data.guid, element.bookingCode)
              .subscribe(
                (transacionCodeAllocation) => {
                  if (
                    transacionCodeAllocation.status === this.NOT_USED ||
                    transacionCodeAllocation.status === this.USED
                  ) {
                    this.createOpenItem(request);
                  } else {
                    this.notificationService.showToast(
                      'ERROR-MESSAGES.TCA-STATUS-NOT-VALID',
                      this.notificationService.MESSAGE_TYPE.ERROR
                    );
                  }
                },
                (error) => {
                  this.notificationService.showToast(
                    'ERROR-MESSAGES.TCA-NOT-FOUND',
                    this.notificationService.MESSAGE_TYPE.ERROR
                  );
                }
              );
          },
          (error) => {
            this.notificationService.showToast(
              'ERROR-MESSAGES.BU-NOT-FOUND',
              this.notificationService.MESSAGE_TYPE.ERROR
            );
          }
        );
    });
  }
}
