export const debitCreditCodeOptions: any[] = [
  {
    name: 'DEBIT',
    value: 'D',
    translateName: 'OPEN-TRANSACTIONS.DEBIT-CREDIT-OPTIONS.DEBIT',
  },
  {
    name: 'CREDIT',
    value: 'C',
    translateName: 'OPEN-TRANSACTIONS.DEBIT-CREDIT-OPTIONS.CREDIT',
  },
];
