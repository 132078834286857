import { ChangeDetectionStrategy, Component } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskOverviewComponent {
  userList: User[] = [];
  buList: [] = [];
  constructor(private businessUnitService: BusinessUnitService) {
    this.businessUnitService
      .getAllBusinessUnits('id', 'asc', 1, 99999)
      .subscribe((data) => {
        this.buList = data.content;
      });
  }
}
