<section [ngClass]="{ 'content-container': !isTask }">
  <app-back-button *ngIf="!isTask" [address]="'liquidityPlan'"></app-back-button>
  <app-back-button *ngIf="isTask"></app-back-button>
  <span *ngIf="isTask && !editableTask && !cancellationView">
    <button
      *hasPermission="'aw.l.u'"
      id="{{ entityName }}-edit-task"
      mat-button
      class="edit-task-button"
      (click)="makeEditable()"
      [disabled]="cancellationView"
    >
      <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
    </button>
  </span>
  <span class="action-buttons-container">
    <button
      mat-button
      class="custom-button save-button"
      (click)="save()"
      [disabled]="liquidityPlanAssetsObjects.invalid || dataSource.data.length == 0"
      *ngIf="!isTask"
      id="{{ entityName }}-save-button-not-approval"
    >
      <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.SAVE</span>
    </button>
    <button
      mat-button
      class="custom-button add-button"
      (click)="add()"
      *ngIf="!hideButtons"
      id="{{ entityName }}-add-button-is-approval"
    >
      <mat-icon class="add-icon">add_circle</mat-icon>
      <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ADD-ASSET-CHANGE</span>
    </button>
  </span>

  <div class="animal-welfare-liquidity-plan-assets form-container">
    <form class="form" [formGroup]="liquidityPlanAssetsForm" autocomplete="off">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        id="{{ entityName }}-liquidity-plan-assets-table"
        multiTemplateDataRows
        aria-hidden="true"
      >
        <ng-container matColumnDef="meaning">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            id="{{ entityName }}-table-liquidity-plan-assets-meaning"
          >
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.MEANING' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-meaning-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <mat-form-field
                appearance="fill"
                [formGroup]="getFormGroup(element)"
                style="margin-right: 10px"
                id="{{ entityName }}-meaning-{{ i }}"
              >
                <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.MEANING' | translate }}</mat-label>
                <input
                  autocomplete="off"
                  matInput
                  type="text"
                  formControlName="meaning"
                  placeholder="{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.MEANING' | translate }}"
                  [removeHtmlTags]="getFormGroup(element).controls.meaning"
                />
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="planAmount">
          <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-liquidity-plan-assets-amount">
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.AMOUNT' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-amount-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <mat-form-field
                appearance="fill"
                [formGroup]="getFormGroup(element)"
                [ngClass]="{ 'val-changed': hasChanged(element, 'planAmount') }"
                id="{{ entityName }}-plan-actual-amount-{{ i }}"
              >
                <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.AMOUNT' | translate }}</mat-label>
                <input
                  autocomplete="off"
                  matInput
                  type="text"
                  formControlName="planAmount"
                  placeholder="{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.AMOUNT' | translate }}"
                  currencyMask
                  [options]="{ suffix: ' €', thousands: '.', decimal: ',' }"
                  required
                  maxlength="17"
                />
                <mat-error
                  id="{{ entityName }}-form-planAmount-error-required"
                  class="input-error"
                  *ngIf="getFormGroup(element) && getFormGroup(element).controls.planAmount.hasError('required')"
                >
                  {{
                    'ERROR-MESSAGES.REQUIRED'
                      | translate
                        : {
                            name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.AMOUNT' | translate
                          }
                      | titlecase
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="kind" class="kind-container">
          <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-liquidity-plan-assets-kind">
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.KIND' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-kind-{{ i }}"
          >
            <div class="kind-selection">
              <div class="form-row" *ngIf="getFormGroup(element) != undefined" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  class="selection"
                  [ngClass]="{ 'val-changed': hasChanged(element, 'kind') }"
                  id="{{ entityName }}-kind-{{ i }}"
                >
                  <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.KIND' | translate }}</mat-label>
                  <mat-select formControlName="kind" required (selectionChange)="kindChanged($event, element)">
                    <mat-optgroup *ngFor="let group of kinds" [label]="group.group | translate">
                      <mat-option
                        *ngFor="let option of group.options | sortBy: { property: 'translateName', descending: false }"
                        [value]="option.name"
                      >
                        {{ option.translateName | translate }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                  <mat-error
                    id="{{ entityName }}-form-kind-errorReqired"
                    class="input-error"
                    *ngIf="getFormGroup(element) && getFormGroup(element).controls.kind.hasError('required')"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.KIND' | translate
                            }
                        | titlecase
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="commentOthers">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            id="{{ entityName }}-table-liquidity-plan-assets-commentOthers"
          >
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT-OTHERS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-commentOthers-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <mat-form-field
                appearance="fill"
                [formGroup]="getFormGroup(element)"
                [ngClass]="{
                  'val-changed': hasChanged(element, 'commentOthers')
                }"
                id="{{ entityName }}-comment-others-{{ i }}"
              >
                <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT-OTHERS' | translate }}</mat-label>
                <input
                  autocomplete="off"
                  matInput
                  type="text"
                  formControlName="commentOthers"
                  placeholder="{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT-OTHERS' | translate }}"
                  required
                  [removeHtmlTags]="getFormGroup(element).controls.commentOthers"
                />
                <mat-error
                  id="{{ entityName }}-form-commentOthers-errorReqired"
                  class="input-error"
                  *ngIf="getFormGroup(element) && getFormGroup(element).controls.commentOthers.hasError('required')"
                >
                  {{
                    'ERROR-MESSAGES.REQUIRED'
                      | translate
                        : {
                            name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT-OTHERS' | translate
                          }
                      | titlecase
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-liquidity-plan-assets-type">
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.TYPE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-type-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  class="selection"
                  [ngClass]="{ 'val-changed': hasChanged(element, 'type') }"
                  id="{{ entityName }}-type-{{ i }}"
                >
                  <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.TYPE' | translate }}</mat-label>
                  <mat-select formControlName="type" id="{{ entityName }}-form-{{ i }}-type-select" required>
                    <mat-option
                      *ngFor="
                        let option of types | sortBy: { property: 'translateName', descending: false };
                        let i = index
                      "
                      [value]="option.name"
                      id="{{ entityName }}-form-{{ i }}-types-option-{{ i }}"
                    >
                      {{ option.translateName | translate | titlecase }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    id="{{ entityName }}-form-type-error-required"
                    class="input-error"
                    *ngIf="getFormGroup(element) && getFormGroup(element).controls.type.hasError('required')"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.TYPE' | translate
                            }
                        | titlecase
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="planActual">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            id="{{ entityName }}-table-liquidity-plan-assets-plan-actual"
          >
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN-ACTUAL-VALUE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-plan-actual-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field appearance="fill" class="selection" id="{{ entityName }}-plan-actual-value-{{ i }}">
                  <mat-label>{{
                    'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN-ACTUAL-VALUE' | translate
                  }}</mat-label>
                  <input
                    autocomplete="off"
                    matInput
                    type="text"
                    formControlName="planActual"
                    placeholder="{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN-ACTUAL-VALUE' | translate }}"
                  />
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="monthAndYear">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            id="{{ entityName }}-table-liquidity-plan-assets-month-year"
          >
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.MONTH-YEAR' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-month-year-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row month-year-date" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  [formGroup]="getFormGroup(element)"
                  [ngClass]="{
                    'val-changed': hasChanged(element, 'monthAndYear')
                  }"
                  id="{{ entityName }}-month-year-{{ i }}"
                >
                  <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.MONTH-YEAR' | translate }}</mat-label>
                  <input matInput [matDatepicker]="monthAndYeardp" formControlName="monthAndYear" required />
                  <mat-datepicker-toggle matSuffix [for]="monthAndYeardp"></mat-datepicker-toggle>
                  <mat-datepicker
                    #monthAndYeardp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, element)"
                    (monthSelected)="chosenMonthHandler($event, monthAndYeardp, element)"
                  >
                  </mat-datepicker>
                  <mat-error
                    id="{{ entityName }}-form-month-year-errorReqired"
                    class="input-error"
                    *ngIf="getFormGroup(element) && getFormGroup(element).controls.monthAndYear.hasError('required')"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.MONTH-YEAR' | translate
                            }
                        | titlecase
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            id="{{ entityName }}-table-liquidity-plan-assets-comment"
          >
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-comment-{{ i }}"
          >
            <div class="form-row comment-field" *ngIf="getFormGroup(element) != undefined">
              <mat-form-field
                appearance="fill"
                [formGroup]="getFormGroup(element)"
                [ngClass]="{ 'val-changed': hasChanged(element, 'comment') }"
                id="{{ entityName }}-comment-{{ i }}"
              >
                <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT' | translate }}</mat-label>
                <input
                  autocomplete="off"
                  matInput
                  type="text"
                  formControlName="comment"
                  placeholder="{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT' | translate }}"
                  [required]="getRequiredStatus(getFormGroup(element))"
                  [removeHtmlTags]="getFormGroup(element).controls.comment"
                />

                <mat-error
                  id="{{ entityName }}-form-assetComment-errorReqired"
                  class="input-error"
                  *ngIf="getFormGroup(element) && getFormGroup(element).controls.comment.hasError('required')"
                >
                  {{
                    'ERROR-MESSAGES.REQUIRED'
                      | translate
                        : {
                            name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT' | translate
                          }
                      | titlecase
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-liquidity-plan-assets-remove">
            {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.REMOVE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-liquidity-plan-assets-remove-{{ i }}"
          >
            <div class="form-row">
              <button mat-button class="remove-button" (click)="remove(element)">
                <mat-icon class="add-icon">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = index" class="element-row"></tr>
      </table>
    </form>
  </div>
  <div class="task-action-buttons-container" *ngIf="editableTask && isTask">
    <button
      mat-button
      class="custom-button save-button"
      (click)="saveDraft()"
      id="{{ entityName }}-save-draft-button"
      [disabled]="liquidityPlanAssetsObjects.invalid"
    >
      <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.SAVE</span>
    </button>
    <button
      mat-button
      class="custom-button-cancel task-cancel"
      id="{{ entityName }}-cancel-button"
      (click)="cancelEditable()"
    >
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
  </div>
</section>
