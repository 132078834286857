import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AnimalWelfareFile } from '../../../models/animal-welfare-file';

@Component({
  selector: 'app-aw-xsd-modal',
  templateUrl: './aw-xsd-modal.component.html',
  styleUrls: ['./aw-xsd-modal.component.less'],
})
export class AwXsdModalComponent {
  awFile: AnimalWelfareFile;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    if (data.awFile) {
      this.awFile = data.awFile;
    }
  }
}
