export const serviceModelEnum: any[] = [
  {
    name: 'NOT_RELEVANT',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.NOT_RELEVANT',
    product: ['BACKUP_SERVICING', 'ANIMAL_WELFARE_INITIATIVE'],
  },
  {
    name: 'FULL_SERVICE_FACTORING',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.FULL_SERVICE_FACTORING',
    product: ['FACTORING'],
  },
  {
    name: 'INHOUSE_FACTORING',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.INHOUSE_FACTORING',
    product: ['FACTORING'],
  },
  {
    name: 'INHOUSE_CS',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.INHOUSE_CS',
    product: ['CENTRAL_SETTLEMENT'],
  },
  {
    name: 'FULL_SERVICE_CS',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.FULL_SERVICE_CS',
    product: ['CENTRAL_SETTLEMENT'],
  },
  {
    name: 'CENTRAL_BILLING',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.CENTRAL_BILLING',
    product: ['CENTRAL_SETTLEMENT'],
  },
];
