<div class="data-container">
  <div
    class="data-row"
    *ngFor="let attachment of attachments; index as i"
    [ngClass]="{
      'new-row': this.isNewRow(attachment),
      'deleted-row': this.isDeletedRow(attachment)
    }"
  >
    <span class="delete-button">
      <button
        *ngIf="!deleteFileHidden"
        mat-button
        (click)="deleteAttachment(attachment)"
        id="{{ entityName }}-delete-button-{{ i }}"
      >
        <img src="../../../assets/images/ic_trash.svg" alt="delete icon" class="trash-icon" />
      </button>
    </span>
    <div class="item-value">
      <div class="user-data">
        <span class="user-id">{{ getUserInfo(attachment) | translate }}</span>
        <span class="date">
          {{ attachment.uploadDate | date: dateFormat + ' HH:mm:ss' }}
        </span>
      </div>
      <button
        mat-button
        class="content"
        (click)="downloadAttachment(attachment)"
        id="{{ entityName }}-attachment-{{ i }}"
      >
        <span class="file-name" *ngIf="attachment.fileName">{{ attachment.fileName }}</span>
        <span class="file-name" *ngIf="!attachment.fileName">{{ attachment }}</span>
      </button>
    </div>
  </div>
</div>
