export const awTypes: any[] = [
  {
    name: 'livestockProducer',
    value: 'LIVESTOCK_PRODUCER',
    translateName: 'OPEN-TRANSACTIONS.AW-TYPES.LIVESTOCK-PRODUCER',
  },
  {
    name: 'slaughterhouse',
    value: 'SLAUGHTERHOUSE',
    translateName: 'OPEN-TRANSACTIONS.AW-TYPES.SLAUGHTERHOUSE',
  },
  {
    name: 'frt',
    value: 'FRT',
    translateName: 'OPEN-TRANSACTIONS.AW-TYPES.FRT',
  },
  {
    name: 'gastronomy ',
    value: 'GASTRONOMY',
    translateName: 'OPEN-TRANSACTIONS.AW-TYPES.GASTRONOMY',
  },
];
