<div class="filters" [formGroup]="filterForm">
  <div class="filter-input">
    <app-mat-input-autocomplete
      [name]="'NON-GERMANY-REFERENCE-ALLOW-LIST.SEARCH'"
      [placeholder]="'NON-GERMANY-REFERENCE-ALLOW-LIST.SEARCH'"
      [label]="'NON-GERMANY-REFERENCE-ALLOW-LIST.SEARCH'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.country"
      [controlName]="'country'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [options]="countries"
      [filterName]="'cca3'"
      [optionValue]="'cca3'"
      [translationOption]="'cca3'"
      [optionTranslationText]="''"
      [inline]="true"
    ></app-mat-input-autocomplete>
    <button
      mat-button
      class="custom-button search-button"
      (click)="searchCountry()"
      id="{{ entityName }}-search-button"
    >
      <span class="material-icons"> search </span>
    </button>
  </div>
</div>

<div class="table-list" [formGroup]="form">
  <h5 id="bu-search-results-title">{{ 'SEARCH-RESULT' | translate }}</h5>
  <table aria-hidden="true" mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
        <mat-checkbox
          id="{{ entityName }}-table-results-select-all"
          (change)="$event ? masterToggle(true) : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
          [disabled]="disableAllSelects"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox
          id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
          [aria-label]="checkboxLabel(element)"
          [disabled]="disableAllSelects"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-country"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.COUNTRY' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-country-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ getCountryName(element.countryCode) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="countryCode">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-countryCode"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.ISO-CODE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-countryCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.countryCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="decisionCompliance">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-decisionCompliance"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-decisionCompliance-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionCompliance"
            [formGroup]="getFormGroup(element)"
          >
            <mat-label>{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE' | translate }}</mat-label>
            <mat-select
              formControlName="decisionCompliance"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionCompliance-select"
              required
            >
              <mat-option
                *ngFor="let option of decisionComplianceOptions; let i = index"
                [value]="option.value"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionCompliance-option-{{ i }}"
              >
                {{ option.translateName | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="decisionComplianceReason">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-decisionComplianceReason"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE-REASON' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-decisionComplianceReason-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field appearance="fill" [formGroup]="getFormGroup(element)">
            <mat-label>{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE-REASON' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionComplianceReason"
              matInput
              type="text"
              formControlName="decisionComplianceReason"
              placeholder="{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE-REASON' | translate }}"
              [removeHtmlTags]="getFormGroup(element).controls.decisionComplianceReason"
              type="text"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="decisionRisk">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-decisionRisk"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-decisionRisk-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionRisk"
            [formGroup]="getFormGroup(element)"
          >
            <mat-label>{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK' | translate }}</mat-label>
            <mat-select
              formControlName="decisionRisk"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionRisk-select"
              required
            >
              <mat-option
                *ngFor="let option of decisionRiskOptions; let i = index"
                [value]="option.value"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionRisk-option-{{ i }}"
              >
                {{ option.translateName | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="decisionRiskReason">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-comment"
      >
        {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK-REASON' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field appearance="fill" [formGroup]="getFormGroup(element)">
            <mat-label>{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK-REASON' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-decisionRiskReason"
              matInput
              type="text"
              formControlName="decisionRiskReason"
              placeholder="{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK-REASON' | translate }}"
              [removeHtmlTags]="getFormGroup(element).controls.decisionRiskReason"
              type="text"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          class="menu"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ element: element }"
          (click)="$event.stopPropagation(); selectTheRow(element)"
          id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span class="material-icons"> more_vert </span>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="loader">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="general-loader-table" *ngIf="loaderSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [hidden]="loaderSpinner"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'draft-row': row.draft }"
    ></tr>
    <tr
      [hidden]="!loaderSpinner"
      mat-footer-row
      *matFooterRowDef="['loader']"
      class="footer-spinner height-footer"
    ></tr>
  </table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button
      *ngIf="selection.selected.length > 1 && !saveMultiple"
      mat-menu-item
      (click)="showConfirmationStartModal(element, true)"
      id="{{ entityName }}-table-results-action-menu-edit-row-button"
    >
      <img class="icon-menu" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
      <span translate>GENERAL.EDIT</span>
    </button>

    <button
      *ngIf="!saveMultiple"
      [disabled]="!getFormValidity(element)"
      mat-menu-item
      (click)="saveSingleCountry(element)"
      id="{{ entityName }}-table-results-action-save-row-buton"
    >
      <span class="material-icons icon-menu"> done </span>
      <span translate>GENERAL.SAVE</span>
    </button>
    <button
      *ngIf="saveMultiple"
      [disabled]="!getFormValidity(element) && saveMultiple"
      mat-menu-item
      (click)="saveMultipleCountries(element)"
      id="{{ entityName }}-table-results-action-save-row-buton"
    >
      <span class="material-icons icon-menu"> done </span>
      <span translate>GENERAL.SAVE</span>
    </button>

    <button
      *ngIf="saveMultiple && getFormGroup(element).enabled"
      mat-menu-item
      (click)="cancelEdit(element)"
      id="{{ entityName }}-table-results-action-save-row-buton"
    >
      <span class="material-icons icon-menu"> clear </span>
      <span translate>GENERAL.CANCEL</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
