import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-dismiss-changes-dialog',
  templateUrl: './dismiss-changes-dialog.component.html',
  styleUrls: ['./dismiss-changes-dialog.component.less'],
})
export class DismissChangesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DismissChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string | null;
      message: string;
      variable: string;
    }
  ) {}
}
