<div class="tab-container">
  <nav mat-tab-nav-bar>
    <ng-container *ngFor="let tab of tabs">
      <a
        mat-tab-link
        [routerLink]="[tab?.link]"
        *hasPermission="tab?.permission"
        routerLinkActive
        #rla="routerLinkActive"
        routerLinkActive="active-link"
        [active]="rla.isActive"
        id="{{ entityName }}-menu-btc"
      >
        {{ tab?.title | translate }}
      </a>
    </ng-container>
  </nav>
</div>
