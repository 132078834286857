<div class="info-container" id="{{ entityName }}-general-info">
  <h3 class="title-info" id="{{ entityName }}-general-info--header">
    {{ livestockProducer.locationName }}
  </h3>

  <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="accountNumber" id="{{ entityName }}-general-info-account-number-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.ACCOUNT-NUMBER' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="accountNumber" class="information" id="{{ entityName }}-general-info-account-number-value">{{
              livestockProducer.accountNumber
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="contactPerson" id="{{ entityName }}-general-info-contact-person-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.CONTACT-PERSON' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="contactPerson"
              class="information"
              id="{{ entityName }}-general-info-contact-person-value"
              *ngIf="livestockProducer && livestockProducer.company && livestockProducer.company.mainContactPerson"
              >{{ livestockProducer.company.mainContactPerson.title }}
              {{ livestockProducer.company.mainContactPerson.name }}
              {{ livestockProducer.company.mainContactPerson.surname }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="taxRate" id="{{ entityName }}-general-info-tax-rate-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TAX-RATE' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="taxRate" class="information" id="{{ entityName }}-general-info-tax-rate-value"
              >{{ livestockProducer.taxRate }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="vvvoNr" id="{{ entityName }}-general-info-vvvo-nr-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.VVVO-NR' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="vvvoNr" class="information" id="{{ entityName }}-general-info-vvvo-nr-value">
              {{ livestockProducer.locationNumber }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="email" id="{{ entityName }}-general-info-email-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.EMAIL' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="email"
              class="information"
              id="{{ entityName }}-general-info-company-value"
              *ngIf="
                livestockProducer &&
                livestockProducer.company &&
                livestockProducer.company.mainContactPerson &&
                livestockProducer.company.mainContactPerson.email
              "
              >{{ livestockProducer.company.mainContactPerson.email }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="email" id="{{ entityName }}-general-info-email-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.SALES-TAX-ID' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="email" class="information" id="{{ entityName }}-general-info-company-value">{{
              livestockProducer.salesTaxId
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="productionType" id="{{ entityName }}-general-info-production-type-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TYPE-OF-PRODUCTION' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span name="productionType" class="information" id="{{ entityName }}-general-info-production-type-value">
              {{ livestockProducer.productionType }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="address" id="{{ entityName }}-general-info-address-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.ADDRESS' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="address"
              class="information"
              id="{{ entityName }}-general-info-address-value"
              *ngIf="livestockProducer && livestockProducer.company"
              >{{ livestockProducer.company.formattedAddress }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="iban" id="{{ entityName }}-general-info-iban-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.IBAN' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="iban"
              class="information"
              id="{{ entityName }}-general-info-iban-value"
              *ngIf="
                livestockProducer &&
                livestockProducer.company &&
                livestockProducer.company.mainBankAccount &&
                livestockProducer.company.mainBankAccount.iban
              "
              >{{ livestockProducer.company.mainBankAccount.iban }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="productionTypeName" id="{{ entityName }}-general-info-production-type-name-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.NAME-OF-TYPE-PRODUCTION' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="productionTypeName"
              class="information"
              id="{{ entityName }}-general-info-production-type-name-value"
              >{{ getProductionTypeName(livestockProducer.productionType) | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="phone" id="{{ entityName }}-general-info-phone-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.PHONE' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="phone"
              class="information"
              id="{{ entityName }}-general-info-phone-value"
              *ngIf="
                livestockProducer &&
                livestockProducer.company &&
                livestockProducer.company.mainContactPerson &&
                livestockProducer.company.mainContactPerson.mobileNumber
              "
              >{{ livestockProducer.company.mainContactPerson.mobileNumber }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="bic" id="{{ entityName }}-general-info-bic-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BIC' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="bic"
              class="information"
              id="{{ entityName }}-general-info-bic-value"
              *ngIf="
                livestockProducer &&
                livestockProducer.company &&
                livestockProducer.company.mainBankAccount &&
                livestockProducer.company.mainBankAccount.bic
              "
              >{{ livestockProducer.company.mainBankAccount.bic }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" fxFlexOffset="33" fxFlexOffset.sm="0">
      <div fxLayout="row">
        <div fxFlex="50">
          <div class="label-container">
            <label for="fax" id="{{ entityName }}-general-info-company-label">{{
              'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FAX' | translate
            }}</label>
          </div>
        </div>
        <div fxFlex="50">
          <div class="span-container">
            <span
              name="fax"
              class="information"
              id="{{ entityName }}-general-info-fax-value"
              *ngIf="
                livestockProducer &&
                livestockProducer.company &&
                livestockProducer.company.mainContactPerson &&
                livestockProducer.company.mainContactPerson.faxNumber
              "
              >{{ livestockProducer.company.mainContactPerson.faxNumber }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
