<section class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <div class="form-container">
      <form action="" class="form" [formGroup]="configurationForm" autocomplete="on">
        <app-mat-select
          [name]="'ENTITIES.PLATFORM'"
          [placeholder]="'ENTITIES.PLATFORM'"
          [label]="'ENTITIES.PLATFORM'"
          [entityName]="entityName"
          [form]="configurationForm"
          [controlObject]="configurationForm.controls.platformId"
          [controlName]="'platformId'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="platforms"
          [optionValue]="'id'"
          [selectedOption]="null"
          [translationOption]="'name'"
          [optionTranslationText]="''"
        ></app-mat-select>

        <app-mat-input
          [name]="'debtor.clientDebtorId'"
          [placeholder]="'debtor.clientDebtorId'"
          [label]="'debtor.clientDebtorId'"
          [entityName]="entityName"
          [form]="configurationForm"
          [controlObject]="configurationForm.controls.clientDebtorId"
          [controlName]="'clientDebtorId'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="true"
          [editableTask]="true"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
      </form>
    </div>
  </div>
  <div class="buttons">
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" [mat-dialog-close]>
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      *ngIf="configurationForm"
      [disabled]="!configurationForm.valid"
      id="{{ entityName }}-save-button"
      (click)="save()"
      [mat-dialog-close]
    >
      {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
    </button>
  </div>
</section>
