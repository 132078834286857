<app-back-button class="back-button-container"> </app-back-button>
<div class="documents-wrapper">
  <h5 *ngIf="dataLoaded" class="subheading">{{ 'DOCUMENTS' | translate }}</h5>
  <h4 *ngIf="dataLoaded" class="subheading">
    {{ 'DOCUMENTS.INSPECTED' | translate }}: {{ documentsInspected.length }}
  </h4>
  <app-default-table
    *ngIf="dataLoaded"
    [entityName]="entityName"
    [configurationKey]="configurationKey"
    [columns]="columnConfiguration"
    [displayedColumns]="columns"
    [originalColumns]="columnConfiguration"
    [originalDisplayedColumns]="columns"
    [tableDataSource]="documentList"
    [customFunction]="true"
    (filterEvent)="filter($event)"
    [enableFilter]="false"
  ></app-default-table>
</div>
