<div class="content-container create-buttons">
  <div class="buttons-container">
    <button
      *hasPermission="'tca.c'"
      mat-button
      class="custom-button"
      (click)="addTransactionCode()"
      id="{{ entityName }}-create-button"
    >
      {{ 'TRANSACTION-CODE-ALLOCATION.ADD-TRANSACTION-CODE' | translate }}
    </button>
  </div>
</div>

<div class="content-container">
  <div class="bu-transaction-codes-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
      aria-hidden="true"
    >
      <ng-container matColumnDef="transactionCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transactionCode"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.transactionCode | number: '3.0' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="statusIcon">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th status-icon-row"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transactionCode"
        ></th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="status-icon-row"
        >
          <span class="material-icons red-icon" *ngIf="element && element.status == 0"> warning_amber </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="originalTransactionCodeNameGerman">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalTransactionCodeNameGerman"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.DEFAULT-TRANSACTION-CODE-NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalTransactionCodeNameGerman-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.originalTransactionCodeNameGerman }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalTransactionCodeNameEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalTransactionCodeNameEnglish"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.DEFAULT-NAME-ENGLISH' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalTransactionCodeNameEnglish-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.originalTransactionCodeNameEnglish }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalTransactionCodeDescriptionGerman">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalTransactionCodeDescriptionGerman"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.DESCRIPTION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalTransactionCodeDescriptionGerman-{{ paginator.pageIndex + 1 }}-{{
            i
          }}"
        >
          {{ element.originalTransactionCodeDescriptionGerman }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalTransactionCodeDescriptionEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalTransactionCodeDescriptionEnglish"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.DESCRIPTION-ENGLISH' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalTransactionCodeDescriptionEnglish-{{ paginator.pageIndex + 1 }}-{{
            i
          }}"
        >
          {{ element.originalTransactionCodeDescriptionEnglish }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionCodeNameGerman">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-buTransactionCodeNameGerman"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-GERMAN-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-buTransactionCodeNameGerman-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.transactionCodeNameGerman }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionCodeNameEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-buTransactionCodeNameEnglish"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ENGLISH-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-buTransactionCodeNameEnglish-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.transactionCodeNameEnglish }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionCodeDescriptionGerman">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-buTransactionCodeDescriptionGerman"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-GERMAN-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-buTransactionCodeDescriptionGerman-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.transactionCodeDescriptionGerman }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionCodeDescriptionEnglish">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-buTransactionCodeDescriptionEnglish"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-ENGLISH-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-buTransactionCodeDescriptionEnglish-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.transactionCodeDescriptionEnglish }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transactionType"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.TRANSACTION-TYPE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionType-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getTransactionType(element.transactionType) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transferTransactionCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-transferTransactionCode"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.TRANSFER-TRANSACTION-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transferTransactionCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ getBooleanTranslate(element.transferTransactionCode) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="standard">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-standard"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.STANDARD' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-standard-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.standard }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cancellation">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-cancellation"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.CANCELLATION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-cancellation-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.cancellation) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalSapLedgerAccountCredit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalSapLedgerAccountCredit"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalSapLedgerAccountCredit-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.originalSapLedgerAccountCredit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalSapLedgerAccountDebit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalSapLedgerAccountDebit"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalSapLedgerAccountDebit-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.originalSapLedgerAccountDebit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sapLedgerAccountCredit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sapLedgerAccountCredit"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sapLedgerAccountCredit-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.sapLedgerAccountCredit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sapLedgerAccountDebit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sapLedgerAccountDebit"
        >
          {{
            'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT-ENTITY'
              | translate: { entity: 'ENTITIES.BU' | translate }
          }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sapLedgerAccountDebit-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.sapLedgerAccountDebit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="objectAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-objectAccount"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-objectAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.objectAccount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalObjectAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-originalObjectAccount"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY' | translate: { entity: 'ENTITIES.BU' | translate } }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalObjectAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.originalObjectAccount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="bookingOption">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-bookingOption"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-bookingOption-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.bookingOption }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-dueDate"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.IS-DUE-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-dueDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dueDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="classificationRelevant">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-classificationRelevant"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.CLASSIFICATION-RELEVANT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-classificationRelevant-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.classificationRelevant) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="relevantForRemainingLimitCalc">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-relevantForRemainingLimitCalc"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.RELEVANT-FOR-REMAINING-LIMIT-CALC' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-relevantForRemainingLimitCalc-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.relevantForRemainingLimitCalc) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="settlementRelevant">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-settlementRelevant"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.SETTLEMENT-RELEVANT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-settlementRelevant-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.settlementRelevant) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="relevantSales">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-relevantSales"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.RELEVANT-SALES' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-relevantSales-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.relevantSales) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="salesTaxAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-salesTaxAccount"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.SALES-TAX-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-salesTaxAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.salesTaxAccount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="balancingTransactionCodeInhouse">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-balancingTransactionCodeInhouse"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.IN-HOUSE-BALANCING-ITEM-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-balancingTransactionCodeInhouse-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.balancingTransactionCodeInhouse }}
        </td>
      </ng-container>
      <ng-container matColumnDef="displayExternally">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-displayExternally"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.DISPLAY-EXTERNALLY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-displayExternally-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBooleanTranslate(element.displayExternally) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-status"
        >
          {{ 'TRANSACTION-CODE-ALLOCATION.STATUS' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-status-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getStatusTranslationCode(element.status) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <button
            class="menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex }}-{{ i }}"
            [matMenuTriggerData]="{ element: element }"
            (click)="$event.stopPropagation()"
          >
            <span class="material-icons"> more_vert </span>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [hidden]="loaderSpinner"
        mat-row
        *matRowDef="let element; columns: displayedColumns; let i = index"
        class="element-row"
        [ngClass]="{
          'inactive-row': element && element['status'] === 'INACTIVE'
        }"
      ></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container *hasPermission="'tca.u'">
      <button
        *ngIf="element && element['status'] === 'INCOMPLETE'"
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-complete-transaction-code-button"
        (click)="completeTransactionCode(element)"
      >
        <span translate>TRANSACTION-CODE-ALLOCATION.COMPLETE-TRANSACTION-CODE</span>
      </button>
    </ng-container>
    <ng-container *hasPermission="'tca.u'">
      <button
        *ngIf="element && (element['status'] === 'USED' || element['status'] === 'NOT_USED') && element['new'] != true"
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-edit-transaction-code-button"
        (click)="editTransactionCode(element)"
      >
        <span translate>TRANSACTION-CODE-ALLOCATION.EDIT-TRANSACTION-CODE</span>
      </button>
    </ng-container>
    <ng-container *hasPermission="'tca.u'">
      <button
        *ngIf="element && element['status'] === 'INACTIVE' && element['new'] != true"
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-set-active-transaction-code-button"
        (click)="openConfirmation(element, 1)"
      >
        <span translate>TRANSACTION-CODE-ALLOCATION.SET-ACTIVE-TRANSACTION-CODE</span>
      </button>
    </ng-container>
    <ng-container *hasPermission="'tca.u'">
      <button
        *ngIf="element && element['status'] === 'USED' && element['new'] != true"
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-set-inactive-transaction-code-button"
        (click)="openConfirmation(element, 0)"
      >
        <span translate>TRANSACTION-CODE-ALLOCATION.SET-INACTIVE-TRANSACTION-CODE</span>
      </button>
    </ng-container>
    <ng-container *hasPermission="'tca.d'">
      <button
        *ngIf="
          element && (element['status'] === 'INCOMPLETE' || element['status'] == 'NOT_USED') && element['new'] != true
        "
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-delete-transaction-code-button"
        (click)="deleteTransactionCode(element)"
      >
        <span translate>TRANSACTION-CODE-ALLOCATION.DELETE-TRANSACTION-CODE</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
