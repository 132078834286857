import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskConfigurationListComponent } from './task-configuration-list/task-configuration-list.component';
import { TaskConfigurationCreateComponent } from './task-configuration-create/task-configuration-create.component';

const routes: Routes = [
  {
    path: '',
    component: TaskConfigurationListComponent,
  },
  {
    path: 'create',
    component: TaskConfigurationCreateComponent,
  },
  {
    path: ':id',
    component: TaskConfigurationCreateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskConfigurationRoutingModule {}
