export const statusOptions: any[] = [
  {
    value: 'OPEN',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.OPEN',
  },
  {
    value: 'BLOCKED',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.BLOCKED',
  },
  {
    value: 'SETTLED',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.SETTLED',
  },
  {
    value: 'USED IN ANNUAL BILLING',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.USED_IN_ANNUAL_BILLING',
  },
];
