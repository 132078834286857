<div class="content-container">
  <h5 id="task-config-search-title">{{ 'GENERAL.SEARCH' | translate }}</h5>
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-string">
        <mat-label>{{ 'TASK-CONFIGURATION.SEARCH' | translate }}</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'TASK-CONFIGURATION.SEARCH' | translate }}"
          name="searchString"
          id="{{ entityName }}-search-input-name"
          formControlName="searchString"
          [(ngModel)]="searchInput"
          (keyup)="search()"
          type="text"
          removeHtmlTagsForNgModel
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-object-id">
        <mat-label>{{ 'TASK-CONFIGURATION.OBJECT-ID' | translate }}</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'TASK-CONFIGURATION.OBJECT-ID' | translate }}"
          name="objectId"
          id="{{ entityName }}-search-input-name-object-id"
          formControlName="objectId"
          [(ngModel)]="objectId"
          (keydown.enter)="search()"
          type="text"
          removeHtmlTagsForNgModel
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-object">
        <mat-label>{{ 'TASK-CONFIGURATION.OBJECT' | translate }}</mat-label>
        <input
          autocomplete="off"
          matInput
          placeholder="{{ 'TASK-CONFIGURATION.OBJECT' | translate }}"
          name="object"
          id="{{ entityName }}-search-input-name-object"
          formControlName="object"
          [(ngModel)]="object"
          (keydown.enter)="search()"
          type="text"
          removeHtmlTagsForNgModel
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-role">
        <mat-label>{{ 'TASK-CONFIGURATION.ROLE' | translate }}</mat-label>
        <mat-select
          formControlName="role"
          multiple
          placeholder="{{ 'select' | translate }}}"
          name="role"
          [(value)]="selectedRoles"
        >
          <mat-option *ngFor="let item of roles | async" [value]="item.role">
            {{ item.role }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="selectedRoles"
          id="{{ entityName }}-search-input-role-clear"
          (click)="selectedRoles = undefined; resetRole(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-team">
        <mat-label>{{ 'TASK-CONFIGURATION.TEAM-ID' | translate }}</mat-label>
        <mat-select
          formControlName="team"
          multiple
          placeholder="{{ 'select' | translate }}}"
          name="team"
          [(value)]="selectedTeams"
        >
          <mat-option *ngFor="let item of teams" [value]="item">
            {{ item.translateName | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="selectedTeams"
          id="{{ entityName }}-search-input-team-clear"
          (click)="selectedTeams = undefined; resetTeam(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-taskType">
        <mat-label>{{ 'taskType' | translate }}</mat-label>
        <mat-select
          formControlName="taskType"
          multiple
          placeholder="{{ 'select' | translate }}"
          name="taskType"
          [(value)]="selectedTaskTypes"
        >
          <mat-option
            *ngFor="let item of taskTypes | async | sortBy: { property: null, descending: false }"
            [value]="item"
          >
            {{ item.toString() | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="selectedTaskTypes"
          (click)="selectedTaskTypes = undefined; resetTaskType(); $event.stopPropagation()"
          id="{{ entityName }}-search-input-taskType-clear"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input button-container">
      <button mat-button class="custom-button" (click)="search()" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
        {{ 'GENERAL.SEARCH' | translate }}
      </button>
    </div>
  </div>

  <mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <div class="task-config-list">
    <h5 id="task-config-search-results-title">
      {{ 'SEARCH-RESULT' | translate }}
    </h5>
    <table
      aria-hidden="true"
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
        >
          {{ 'id' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
        >
          {{ 'TASK-CONFIGURATION.TYPE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.type | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="objectId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
        >
          {{ 'TASK-CONFIGURATION.OBJECT-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.objectId | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="object">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
        >
          {{ 'TASK-CONFIGURATION.OBJECT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.object | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="roleId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[4] }}"
        >
          {{ 'TASK-CONFIGURATION.ROLE-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.roleId | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="teamId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[5] }}"
        >
          {{ 'TASK-CONFIGURATION.TEAM-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[5] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.teamId | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[6] }}"
        >
          {{ 'task-configuration.description' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[6] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.description | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[7] }}"
        >
          {{ 'TASK-CONFIGURATION.DUE-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[7] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dueDate }} {{ 'TASK-CONFIGURATION.DAYS' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[8] }}"
        >
          {{ 'TASK-CONFIGURATION.PRIORITY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[8] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ 'PRIORITIES.' + element.priority | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="embeddedTaskUi">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[10] }}"
        >
          {{ 'task-configuration.embeddedTaskUi' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[10] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.embeddedTaskUi | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="embeddedUi">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumns[11] }}"
        >
          {{ 'task-configuration.embeddedUi' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumns[11] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.embeddedUi | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th settings"
          id="{{ entityName }}-table-results-{{ displayedColumns[9] }}"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="appMenu"
            id="{{ entityName }}-table-results-{{ displayedColumns[9] }}-button"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickOnRow(row)"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>
</div>
