import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslationHttpLoader implements TranslateLoader {
  langArray: any[] = [];
  serviceUrl;
  private isoCodeDE = 'de';

  constructor(private httpClient: HttpClient) {
    this.serviceUrl = environment.settings.translationService;
  }

  public getTranslation(lang: string): Observable<any> {
    if (lang == null) {
      lang = this.isoCodeDE;
    }
    const serviceUrl = this.serviceUrl + 'languages/translations/';
    this.langArray.push(lang);
    const urls = {};

    if (this.langArray && this.langArray.length > 0) {
      this.langArray.forEach((item) => {
        urls[item] = serviceUrl + lang;
      });
    }

    const observer = new Observable((observerTemp) => {
      this.httpClient.get(urls[lang]).subscribe(
        (data) => {
          observerTemp.next(data);
          observerTemp.complete();
        },
        (error) => {
          console.log(
            'Translations data not coming in! Check translations service!'
          );
        }
      );
    });
    return observer;
  }
}
