import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemConfigurationManagementComponent } from './system-configuration-management/system-configuration-management.component';
import { CanAuthenticationGuard } from '../../utils/can.authentication.guard';
import { AccountStatementProcessingComponent } from '../account-statement-processing/account-statement-processing/account-statement-processing.component';
import { DirtyCheckGuard } from 'src/app/shared/guards/dirty-check.guard';
import { TaskConfigurationListComponent } from '../task-configuration/task-configuration-list/task-configuration-list.component';
import { TaskConfigurationCreateComponent } from '../task-configuration/task-configuration-create/task-configuration-create.component';
import { BtcListComponent } from '../business-transaction-code/btc-list/btc-list.component';
import { BtcCreateComponent } from '../business-transaction-code/btc-create/btc-create.component';
import { BtcMappingListComponent } from '../business-transaction-code-mapping/btc-mapping-list/btc-mapping-list.component';
import { BtcMappingCreateComponent } from '../business-transaction-code-mapping/btc-mapping-create/btc-mapping-create.component';
import { AccountStatementConfigurationListComponent } from '../account-statement-configuration/account-statement-configuration-list/account-statement-configuration-list.component';
import { AccountStatementConfigurationCreateComponent } from '../account-statement-configuration/account-statement-configuration-create/account-statement-configuration-create.component';
import { AccountStatementFileProcessingComponent } from '../account-statement-file-processing/account-statement-file-processing/account-statement-file-processing.component';
import { SubLedgerAccountsOverviewComponent } from '../sub-ledger-accounts-overview/sub-ledger-accounts-overview/sub-ledger-accounts-overview.component';
import { SubLedgerAccountListComponent } from '../sub-ledger-accounts-overview/sub-ledger-account/sub-ledger-account-list/sub-ledger-account-list.component';
import { SubLedgerAccountCreateComponent } from '../sub-ledger-accounts-overview/sub-ledger-account/sub-ledger-account-create/sub-ledger-account-create.component';
import { TranslationManagementComponent } from '../translation-management/translation-management/translation-management.component';
import { TranslationListComponent } from '../translation-management/translation/translation-list/translation-list.component';
import { TranslationCreateComponent } from '../translation-management/translation/translation-create/translation-create.component';
import { TranslationEditComponent } from '../translation-management/translation/translation-edit/translation-edit.component';
import { BookingCodeListComponent } from '../booking-code/booking-code-list/booking-code-list.component';
import { BookingCodeCreateComponent } from '../booking-code/booking-code-create/booking-code-create.component';
import { SystemConfigurationComponent } from '../animal-welfare-management/system-configuration/system-configuration.component';
import { BuListComponent } from '../business-unit/bu-list/bu-list.component';
import { BuCreateComponent } from '../business-unit/bu-create/bu-create.component';
const routes: Routes = [
  {
    path: '',
    component: SystemConfigurationManagementComponent,
    children: [
      {
        path: 'bu',
        component: BuListComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['bu.r'] },
      },
      {
        path: 'bu/create',
        component: BuCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['bu.c', 'bu.u', 'bu.r'],
        },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'accountStatement',
        component: AccountStatementProcessingComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['sc.btc.r', 'sc.btc.m.r', 'sc.asc.r'],
        },
        children: [
          {
            path: 'btc',
            component: BtcListComponent,
            canActivate: [CanAuthenticationGuard],
            data: { roles: ['sc.btc.r'] },
          },
          {
            path: 'btc/create',
            component: BtcCreateComponent,
            canActivate: [CanAuthenticationGuard],
            data: { roles: ['sc.btc.c'] },
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: 'btcMapping',
            component: BtcMappingListComponent,
            canActivate: [CanAuthenticationGuard],
            data: { roles: ['sc.btc.m.r'] },
          },
          {
            path: 'btcMapping/create',
            component: BtcMappingCreateComponent,
            canActivate: [CanAuthenticationGuard],
            data: { roles: ['sc.btc.m.c'] },
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: 'accountStatementConfiguration',
            component: AccountStatementConfigurationListComponent,
            canActivate: [CanAuthenticationGuard],
            data: {
              roles: ['sc.asc.r'],
            },
          },
          {
            path: 'accountStatementConfiguration/create',
            component: AccountStatementConfigurationCreateComponent,
            canActivate: [CanAuthenticationGuard],
            data: {
              roles: ['sc.asc.c'],
            },
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: 'upload',
            component: AccountStatementFileProcessingComponent,
          },
          { path: '', redirectTo: '', pathMatch: 'full' },
        ],
      },
      {
        path: 'taskConfigurations',
        component: TaskConfigurationListComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['sc.tc.r'],
        },
      },
      {
        path: 'taskConfigurations/:id',
        component: TaskConfigurationCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['sc.tc.u'],
        },
      },
      {
        path: 'booking',
        component: BookingCodeListComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['bc.r'] },
      },
      {
        path: 'booking/create/:guid',
        component: BookingCodeCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['bc.c', 'bc.u'],
        },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'booking/create',
        component: BookingCodeCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['bc.c', 'bc.u'],
        },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'subLedgerAccounts',
        component: SubLedgerAccountsOverviewComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['aw.sl.r'],
        },
        children: [
          {
            path: 'subLedgerAccountsList',
            component: SubLedgerAccountListComponent,
            data: { roles: ['aw.sl.r'] },
          },
          {
            path: 'subLedgerAccountsList/create',
            component: SubLedgerAccountCreateComponent,
            data: { roles: ['aw.sl.c'] },
            canDeactivate: [DirtyCheckGuard],
          },
          { path: '', redirectTo: 'subLedgerAccountsList', pathMatch: 'full' },
        ],
      },
      {
        path: 'translationManagement',
        component: TranslationManagementComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['sc.t.r'],
        },
        children: [
          {
            path: 'translationList',
            component: TranslationListComponent,
            data: { roles: ['sc.t.r'] },
          },
          {
            path: 'translationList/create',
            component: TranslationCreateComponent,
            data: { roles: ['sc.t.c'] },
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: 'translationList/edit/:guid',
            component: TranslationEditComponent,
            data: { roles: ['sc.t.u'] },
            canDeactivate: [DirtyCheckGuard],
          },
          { path: '', redirectTo: 'translationList', pathMatch: 'full' },
        ],
      },
      {
        path: 'systemConfiguration',
        component: SystemConfigurationComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['aw.sp.r'] },
      },
      { path: '', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemConfigurationManagementRoutingModule {
  static e = '';
}
