import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { INavigationTab } from 'src/app/shared/navigation-tabs/navigation-tabs.model';
@Component({
  selector: 'app-control-board-management',
  templateUrl: './control-board-management.component.html',
  styleUrls: ['./control-board-management.component.less'],
})
export class ControlBoardManagementComponent implements OnInit {
  entityName = 'control-board-management';
  tabs: INavigationTab[] = [];

  constructor(
    private router: Router,
    private kcService: KeycloakService,
    private route: ActivatedRoute
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.setTabs();
    this.navigateToAccessible();
  }

  setTabs() {
    this.tabs = [
      {
        title: 'ANIMAL-WELFARE.TABS.QUARTERLY-BILLING',
        link: 'quarterlyBillingSimulation',
        permission: ['aw.cb.r'],
      },
      {
        title: 'ANIMAL-WELFARE.TABS.QUARTERLY-BILLING-PAYOUT',
        link: 'quarterlyBillingPayout',
        permission: ['aw.cb.r'],
      },
      {
        title: 'ANIMAL-WELFARE.TABS.ACCOUNT-STATEMENT',
        link: 'accountStatement',
        permission: ['aw.cb.r'],
      },
      {
        title: 'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.TITLE',
        link: 'releaseUnusedBudget',
        permission: ['aw.cb.r'],
      },
    ];
  }

  navigateToAccessible() {
    if (!this.route.firstChild.component) {
      const baseRoutes = this.route.routeConfig.children.filter((el) =>
        this.canNavigate(el)
      );
      if (baseRoutes.length === 0) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl('/masterdata/' + baseRoutes[0].path);
      }
    }
  }

  canNavigate(el: Route): unknown {
    if (el.path === '') {
      return false;
    }
    if ((el.path.match(/\//g) || []).length > 0) {
      return false;
    }
    return el.data.roles.some((role) => this.kcService.isUserInRole(role));
  }
}
