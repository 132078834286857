<app-back-button></app-back-button>
<div class="input-wrapper form-container">
  <div class="non-editable-info-container ng-star-inserted">
    <div class="ng-star-inserted">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">
            {{ 'name' | translate }}
          </span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span class="object-value"> {{ livestockProducer?.masterDataLivestockProducer?.locationName }}</span>
        </div>
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.VVVO-NR' | translate }}
          </span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span class="object-value"> {{ livestockProducer?.masterDataLivestockProducer?.locationNumber }}</span>
        </div>
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TYPE-OF-PRODUCTION' | translate }}
          </span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span class="object-value">
            {{ productionTypeTranslateName | translate }}
          </span>
        </div>
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.ACCOUNT-NUMBER' | translate }}
          </span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span class="object-value"> {{ livestockProducer?.accountNumber }}</span>
        </div>
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.S-BLOCK-BUTTON-LABEL' | translate }}
          </span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span class="object-value">{{ sBlockStatusChanged }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
