export const exportPrintingTaskListColumnsConf: any[] = [
  {
    name: 'recipientName',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.NAME',
    locked: true,
  },
  {
    name: 'filename',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.PARTICIPATION-FEE.DOCUMENT-NAME',
    locked: true,
  },
  {
    name: 'type',
    index: 2,
    checked: true,
    translateName: 'GENERAL.TYPE',
    locked: true,
  },
  {
    name: 'status',
    index: 3,
    checked: true,
    translateName: 'GENERAL.STATUS',
    locked: true,
  },
  {
    version: '1',
  },
];
