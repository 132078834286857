<div class="content-container">
  <section class="liquidity-asset-change-container" [hidden]="editMode">
    <form class="form" [formGroup]="liquidityPlanSelectionForm" autocomplete="off">
      <app-mat-select
        [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.LIQUIDITY-PLAN-SELECTION'"
        [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.LIQUIDITY-PLAN-SELECTION'"
        [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.LIQUIDITY-PLAN-SELECTION'"
        [entityName]="entityName"
        [form]="liquidityPlanSelectionForm"
        [controlObject]="liquidityPlanSelectionForm.controls.liquidityPlan"
        [controlName]="'liquidityPlan'"
        [approvedValue]="liquidityPlanApproved ? liquidityPlanApproved.liquidityPlanSelection : null"
        [taskValue]="liquidityPlanTask ? liquidityPlanTask.liquidityPlanSelection : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
        [options]="liquidityPlans"
        [optionValue]="''"
        [selectedOption]="null"
        [translationOption]="'name'"
        [optionTranslationText]="''"
        [customOptionNameFunction]=""
        [customTaskValueFunction]=""
      ></app-mat-select>
    </form>
    <div [hidden]="!showLiquidityPlanAssetResult">
      <app-animal-welfare-liquidity-plan-filter [selectedLiquidityPlan]="selectedLiquidityPlan">
      </app-animal-welfare-liquidity-plan-filter>
      <button mat-button class="custom-button add-button" (click)="add()" *hasPermission="'aw.l.u'">
        <mat-icon class="add-icon">add_circle</mat-icon>
        <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ADD-ASSET-CHANGE</span>
      </button>
      <app-animal-welfare-liquidity-plan-asset-results [selectedLiquidityPlan]="selectedLiquidityPlan">
      </app-animal-welfare-liquidity-plan-asset-results>
    </div>
  </section>
</div>
