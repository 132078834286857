export function isEmpty(obj): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function isPresentInInput(obj, searchInput): boolean {
  return (
    obj &&
    obj.toLocaleUpperCase().indexOf(searchInput.toLocaleUpperCase()) !== -1
  );
}

export function contains(list: any[], searchElement): boolean {
  return list && searchElement && list.indexOf(searchElement) !== -1;
}

export function keys(obj): Array<string> {
  if (!obj) {
    return [];
  }
  const result = new Array<string>();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result.push(key);
    }
  }
  return result;
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: contentType });
}

export function addWorkDays(startDate, days) {
  if (isNaN(days)) {
    console.log('Value provided for "days" was not a number');
    return;
  }
  if (!(startDate instanceof Date)) {
    console.log('Value provided for "startDate" was not a Date object');
    return;
  }
  const dow = startDate.getDay();
  let daysToAdd = parseInt(days, 10);
  if (dow === 0) {
    daysToAdd++;
  }
  if (dow + daysToAdd >= 6) {
    const remainingWorkDays = daysToAdd - (5 - dow);
    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      if (remainingWorkDays % 5 === 0) {
        daysToAdd -= 2;
      }
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd);
  return startDate;
}
export function formatQuantity(quantity) {
  const langCode = localStorage.currentSelectedLanguage;
  if (langCode) {
    return parseFloat(quantity).toLocaleString(langCode);
  }
  return quantity;
}
