import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-competences',
  templateUrl: './user-competences.component.html',
  styleUrls: ['./user-competences.component.less'],
})
export class UserCompetencesComponent implements OnInit {
  frmUserCompetences;
  frmAdditionalUserCompetences;
  entityName = 'user-role-data-visibility';
  @Input() userId: string;
  @Input() userEmail: string;
  @Input() isTask: boolean;
  @Input() userTask;
  @Input() approvedValue;
  state;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.userCompetenceFormBuilder();
    this.additionalUserCompetenceFormBuilder();
  }

  ngOnInit(): void {
    this.state = window.history.state;
    if (this.state.viewMode) {
      this.frmUserCompetences.disable();
    }
  }

  userCompetenceFormBuilder() {
    this.frmUserCompetences = this.formBuilder.group({
      disbursementDebitSideFirst: [{ value: null, disabled: false }],
      disbursementDebitSideSecond: [{ value: null, disabled: false }],
      masterCompetenceDebit: [{ value: null, disabled: false }],
      disbursementCreditSideFirst: [{ value: null, disabled: false }],
      disbursementCreditSideSecond: [{ value: null, disabled: false }],
      masterCompetenceCredit: [{ value: null, disabled: false }],
      limitApprovalFirst: [{ value: null, disabled: false }],
      limitApprovalSecond: [{ value: null, disabled: false }],
      masterLimitApproval: [{ value: null, disabled: false }],
    });
  }

  additionalUserCompetenceFormBuilder() {
    this.frmAdditionalUserCompetences = this.formBuilder.group({
      userName: [{ value: null, disabled: true }],
      email: [{ value: null, disabled: true }],
    });
  }
}
