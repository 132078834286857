import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compare-history',
  templateUrl: './compare-history.component.html',
  styleUrls: ['./compare-history.component.less'],
})
export class CompareHistoryComponent {
  @Input() fieldsConfiguration;
  @Input() oldEntity;
  @Input() newEntity;

  entityName = 'compare-history';

  constructor(private translateService: TranslateService) {}

  callMethod(methodName: string, value) {
    return this[methodName](value);
  }

  getStatus(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CONTRACT-STATUS.OFFER'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CONTRACT-STATUS.CONTRACT'
        );
      case 2:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CONTRACT-STATUS.PROLONGATION'
        );
      case 3:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CONTRACT-STATUS.CHANGE'
        );
      default:
        return '';
    }
  }

  getPremiumCalculationBasis(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-PREMIUM-CALCULATION-BASIS.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-PREMIUM-CALCULATION-BASIS.NAME-2'
        );

      default:
        return '';
    }
  }

  getMaximumCompensationBasis(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-MAXIMUM-COMPENSATION-BASIS.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-MAXIMUM-COMPENSATION-BASIS.NAME-2'
        );

      default:
        return '';
    }
  }

  getPremiumCalculationType(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-PREMIUM-CALCULATION-TYPE.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-PREMIUM-CALCULATION-TYPE.NAME-2'
        );

      default:
        return '';
    }
  }

  getFlatRateCheckFeeBasis(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-FLAT-RATE-CHECK-FEE-BASIS.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-FLAT-RATE-CHECK-FEE-BASIS.NAME-2'
        );

      default:
        return '';
    }
  }

  getStatusTCIContract(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-STATUS-TAB.DEFAULT-STATUS-TRADE-CREDIT-INSURANCE-CONTRACT-INTERNAL.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-STATUS-TAB.DEFAULT-STATUS-TRADE-CREDIT-INSURANCE-CONTRACT.NAME-1'
        );
      case 2:
        return this.translateService.instant(
          'TCI.CREATE.FORM-STATUS-TAB.DEFAULT-STATUS-TRADE-CREDIT-INSURANCE-CONTRACT.NAME-2'
        );
      case 3:
        return this.translateService.instant(
          'TCI.CREATE.FORM-STATUS-TAB.DEFAULT-STATUS-TRADE-CREDIT-INSURANCE-CONTRACT.NAME-3'
        );

      default:
        return '';
    }
  }

  getFreeLimitCalculation(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.FREE-LIMIT-CALCULATION.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.FREE-LIMIT-CALCULATION.NAME-2'
        );

      default:
        return '';
    }
  }

  getFranchiseType(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.FRANCHISE-TYPES.NAME-1'
        );
      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.FRANCHISE-TYPES.NAME-2'
        );

      default:
        return '';
    }
  }

  getCollectionOrderPeriodCalculation(value) {
    switch (value) {
      case 0:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.CUT-OFF-PERIOD-COLLECTION-ORDER-PERIOD-CALCULATION.NAME-1'
        );

      case 1:
        return this.translateService.instant(
          'TCI.CREATE.FORM-OBLIGATION-TAB.CUT-OFF-PERIOD-COLLECTION-ORDER-PERIOD-CALCULATION.NAME-2'
        );

      default:
        return '';
    }
  }

  getCreditAgencies(value) {
    const agencies = value.split(',');
    let result = '';

    agencies.forEach((element, index) => {
      switch (element) {
        case 'CREDITREFORM':
          result += 'Creditreform';
          if (index !== agencies.length - 1) {
            result += ', ';
          }
          break;
        case 'SCHUFA':
          result += 'Schufa';
          if (index !== agencies.length - 1) {
            result += ', ';
          }
          break;
        case 'BISNODE':
          result += 'Bisnode';
          if (index !== agencies.length - 1) {
            result += ', ';
          }
          break;
        case 'CRIF_BURGEL':
          result += 'Crif Bürgel';
          if (index !== agencies.length - 1) {
            result += ', ';
          }
          break;
        case 'CREDITSAFE':
          result += 'Creditsafe';
          if (index !== agencies.length - 1) {
            result += ', ';
          }
          break;

        default:
          return '';
      }
    });

    return result;
  }

  allValuesNull(entity) {
    let result = true;

    Object.keys(entity).forEach((key, index) => {
      if (entity.hasOwnProperty(key)) {
        if (entity[key] != null) {
          result = false;
        }
      }
    });

    return result;
  }

  specialDisplayValidation(item, methodName) {
    if (item.specialDisplayMethod) {
      return this[methodName]();
    } else {
      return true;
    }
  }

  checkInternalTCI() {
    if (this.oldEntity.internal) {
      return false;
    } else {
      return true;
    }
  }
}
