import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ClientGroup } from '../../models/client-group';
import { IdAndTask } from '../../models/id-task';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class ClientGroupService {
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.companyService;
  }
  private serviceUrl: string;
  mapOfColumnFilters = [];
  private searchString = '';
  pageSize;
  sortDirection;
  sortOrder;
  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  retrieveByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/clientgroup',
      this.buildHeaders()
    );
  }

  retrieveApproved(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clientgroups/' + id,
      this.buildHeaders()
    );
  }

  retrieveApprovedByGuid(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + `clientgroups/retrieveByGuid/${guid}`,
      this.buildHeaders()
    );
  }

  approveTask(id: number, comment: string): Observable<void> {
    const body = { comment };
    return this.http.post<void>(
      this.serviceUrl + 'clientgroups/drafts/' + id + '/approval',
      body,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string) {
    const body = { comment };
    return this.http.post(
      this.serviceUrl + 'clientgroups/drafts/' + id + '/rejection',
      body,
      this.buildHeaders()
    );
  }

  create(clientGroup: {
    clientGroup: ClientGroup;
    comment: string;
    draftId: string;
  }): Observable<IdAndTask> {
    return this.http.post<IdAndTask>(
      this.serviceUrl + 'clientgroups/',
      clientGroup,
      this.buildHeaders()
    );
  }

  update(clientGroup: {
    clientGroup: ClientGroup;
    comment: string;
  }): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'clientgroups',
      clientGroup,
      this.buildHeaders()
    );
  }

  updateDraft(clientGroup: {
    taskId: number;
    clientGroup: ClientGroup;
    comment: string;
  }): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'clientgroups/drafts',
      clientGroup,
      this.buildHeaders()
    );
  }

  listClientGroup(
    companyId: number | null = null,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'clientgroups/filter';
    if (companyId !== null) {
      url += 'forcompany/' + companyId;
    }
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  filter(
    companyId: number | null = null,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'clientgroups/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'clientgroups/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }
}
