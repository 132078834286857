export const buColumns: string[] = [
  'id',
  'name',
  'mainBooking',
  'camBooking',
  'product',
  'poolAllocation',
  'editingOfExternalRecords',
  'actions',
];
