<div class="tab-container">
  <mat-tab-group *ngIf="!loaderSpinner">
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-contract-overview-tab">
          {{ 'TCI.CREATE.TABS.CONTRACT-OVERVIEW' | translate }}
        </div>
      </ng-template>

      <div *ngIf="tciNames">
        <app-compare-history
          [fieldsConfiguration]="contractOverviewFields"
          [oldEntity]="oldEntity"
          [newEntity]="newEntity"
        ></app-compare-history>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-limit-conditions-tab">
          {{ 'TCI.CREATE.TABS.LIMIT-CONDITIONS' | translate }}
        </div>
      </ng-template>

      <div *ngIf="tciNames">
        <app-compare-history
          [fieldsConfiguration]="limitConditionFields"
          [oldEntity]="oldEntity"
          [newEntity]="newEntity"
        ></app-compare-history>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-obligations-tab">
          {{ 'TCI.CREATE.TABS.OBLIGATIONS' | translate }}
        </div>
      </ng-template>

      <div *ngIf="tciNames">
        <app-compare-history
          [fieldsConfiguration]="obligationFields"
          [oldEntity]="oldEntity"
          [newEntity]="newEntity"
        ></app-compare-history>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-particularites-tab">
          {{ 'TCI.CREATE.TABS.PARTICULARITIES' | translate }}
        </div>
      </ng-template>

      <div *ngIf="tciNames">
        <app-compare-history
          [fieldsConfiguration]="particularitiesFields"
          [oldEntity]="oldEntity"
          [newEntity]="newEntity"
        ></app-compare-history>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-foreign-country-values-tab">
          {{ 'TCI.CREATE.TABS.FOREIGN-COUNTRY-VALUES' | translate }}
        </div>
      </ng-template>
      <div *ngIf="tciNames">
        <div *ngFor="let item of countries">
          <app-compare-history
            [fieldsConfiguration]="foreignCountryFields"
            [oldEntity]="item[0]"
            [newEntity]="item[1]"
          ></app-compare-history>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-status-tab">
          {{ 'TCI.CREATE.TABS.STATUS' | translate }}
        </div>
      </ng-template>
      <div *ngIf="tciNames">
        <app-compare-history
          [fieldsConfiguration]="statusFields"
          [oldEntity]="oldEntity"
          [newEntity]="newEntity"
        ></app-compare-history>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="loader-container" *ngIf="loaderSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
