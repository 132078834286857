<div
  *ngIf="(editableTask && isTask) || !isTask"
  [ngClass]="{
    'inline-display': inline
  }"
>
  <div
    class="form-row"
    [formGroup]="formGroup"
    [ngClass]="{
      'form-row-alternative': sideMenu
    }"
  >
    <mat-form-field appearance="{{ appearance ? appearance : 'fill' }}" [ngClass]="{ 'val-changed': changedValue }">
      <mat-label>
        <span>
          {{ label | translate }}
        </span>
        <span *ngIf="labelSuffix">({{ labelSuffix | translate }}) </span>
      </mat-label>
      <input
        #input
        autocomplete="off"
        matInput
        placeholder=" {{ placeholder | translate }}"
        name="{{ name | translate }}"
        formControlName="{{ controlName }}"
        id="{{ entityName }}-form-{{ controlName }}{{ elementIndex ? '-' + elementIndex : '' }}"
        [required]="requiredValidator"
        min="{{ minDate }}"
        max="{{ maxDate }}"
        [matDatepicker]="datePicker"
        (dateChange)="onDateChange()"
        [readonly]="disabled"
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker" *ngIf="!isFieldDisabled"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="inline-button"
        aria-label="Clear"
        id="{{ entityName }}-form-{{ controlName }}-clear{{ elementIndex ? '-' + elementIndex : '' }}"
        (click)="clearOptions(); $event.stopPropagation()"
        *ngIf="showRemoveOption() && !isFieldDisabled() && !requiredValidator"
      >
        <mat-icon>close</mat-icon>
      </button>

      <mat-error
        *ngIf="controlObject.hasError('required')"
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}{{ elementIndex ? '-' + elementIndex : '' }}"
        class="input-error"
      >
        {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: input.name | translate } }}
      </mat-error>
      <mat-error
        *ngIf="!controlObject.hasError('required') && controlObject.hasError('invalid')"
        id="{{ entityName }}-form-{{ controlName }}-{{ errorInvalid }}{{ elementIndex ? '-' + elementIndex : '' }}"
        class="input-error"
      >
        {{ 'ERROR-MESSAGES.INVALID' | translate: { name: input.name | translate } }}
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
      >
        {{ taskValue }}
      </span>
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="!sideMenu">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="!sideMenu">
          <span
            *ngIf="
              compareMode &&
              approvedValue &&
              taskValue &&
              taskValue != 'null' &&
              approvedValue != 'null' &&
              approvedValue != taskValue
            "
            class="mr-10 deleted-date"
          >
            {{ approvedValue | date: dateFormat }}
          </span>
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': hasTaskValueChanged()
            }"
            ><span *ngIf="timeStamp && !stringDate">{{ taskValue | date: dateFormat + ' HH:mm:ss' }}</span
            ><span *ngIf="taskValue != 'null' && !timeStamp && !stringDate">{{ taskValue | date: dateFormat }}</span>
            <span *ngIf="taskValue == 'null' && approvedValue && !timeStamp && !stringDate" class="deleted-date">{{
              approvedValue | date: dateFormat
            }}</span>
            <span *ngIf="!timeStamp && stringDate">{{ taskValue }}</span>
          </span>
        </div>
        <div fxFlex="50" fxFlex.sm="30" fxFlex.xs="100" *ngIf="sideMenu">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="100" *ngIf="sideMenu">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue && approvedValue != taskValue
            }"
            ><span *ngIf="timeStamp">{{ taskValue | date: dateFormat + ' HH:mm:ss' }}</span
            ><span *ngIf="!timeStamp">{{ taskValue | date: dateFormat }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
