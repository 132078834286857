import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { isEmpty } from 'src/app/utils/object-util';
import { TransactionService } from '../services/transaction/transaction.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { OpenItemService } from '../services/open-item/open-item.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less'],
})
export class CommentsComponent implements OnInit {
  @Input() comments: any[];
  @Input() transactionId;
  @Input() commentType;
  @Input() selectedTransactions;
  entityName = 'comments';
  dateFormat = '';

  constructor(
    private userService: UserService,
    private transactionService: TransactionService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private openItemService: OpenItemService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
  }

  deleteComment(comment) {
    const dialog = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'DELETE-FILE-MODAL.DELETE-COMMENT'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        switch (this.commentType) {
          case 'transaction-objection':
            return this.openItemService
              .deleteTransactionComments(comment.id)
              .subscribe(() => {
                this.transactionService
                  .getMultipleTransactionComments(this.transactionId)
                  .subscribe((comments) => {
                    this.removeComment(comment.id);
                  });
              });
          case 'transaction-debtor':
            return this.transactionService
              .deleteCommentsForMultipleTransactions(comment.id)
              .subscribe(() => {
                this.transactionService
                  .getMultipleTransactionComments(this.transactionId)
                  .subscribe((comments: any) => {
                    this.comments = comments;
                  });
              });
        }
      }
    });
  }

  checkMultipleSelected() {
    return this.selectedTransactions && this.selectedTransactions.length > 1;
  }

  removeComment(commentId) {
    const indexComment = this.comments.findIndex(
      (element) => element.id === commentId
    );

    this.comments.splice(indexComment, 1);
  }

  getUserInfo(comment) {
    if (comment) {
      return !isEmpty(comment.firstName) && !isEmpty(comment.lastName)
        ? comment.lastName +
            ', ' +
            comment.firstName +
            ' (' +
            comment.username +
            ')'
        : '';
    }
  }
}
