import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class OpenItemService {
  private readonly taskServiceUrl = environment.settings.taskService;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.openItemService;
    this.searchApiUrl = environment.settings.searchAPIUrl;
  }
  private sharedTableData = new BehaviorSubject<any>(null);
  currentSharedTableData = this.sharedTableData.asObservable();

  private sharedDisplayedColumns = new BehaviorSubject<any>(null);
  currentSharedDisplayedColumns = this.sharedDisplayedColumns.asObservable();

  private sharedSelectedBalance = new BehaviorSubject<any>(null);
  currentSelectedBalance = this.sharedSelectedBalance.asObservable();

  public sharedSplitScreenData = new BehaviorSubject<any>(null);
  currentSharedSplitScreenData = this.sharedSplitScreenData.asObservable();

  public debtorOpenItemListRefreshEvents = new ReplaySubject();
  currentDebtorOpenItemListRefreshEvents =
    this.debtorOpenItemListRefreshEvents.asObservable();

  public debtorOpenItemListRefreshAmountsEvents = new ReplaySubject();
  currentDebtorOpenItemListRefreshAmountsEvents =
    this.debtorOpenItemListRefreshAmountsEvents.asObservable();

  private buId: number;
  private clientId: number;
  private clientDebtorIds = new BehaviorSubject<any>(null);
  currentClientDebtorIds = this.clientDebtorIds.asObservable();
  @Output() searchStarted: EventEmitter<any> = new EventEmitter();

  private sortField = 'id';
  private sortOrder = 'ASC';
  private searchString = '';
  private pageSize = '20';
  private pageIndex = '0';
  private mapOfColumnFilters = [];

  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
  };
  private serviceUrl: string;
  private readonly searchApiUrl: string;

  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;

  updatedClientId = new EventEmitter();
  checkTrasactionCodeSub: Subscription;

  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  getAllOpenTasks(): Observable<any> {
    return this.http.get(this.serviceUrl + 'openItems', this.buildHeaders());
  }

  getBalanceByDebtor(debtorId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'openItems/balance?debtorId=' + debtorId,
      this.buildHeaders()
    );
  }

  setBuID(buId: number) {
    this.buId = buId;
  }

  setClientId(clientId: number) {
    this.clientId = clientId;
  }

  getBuId() {
    return this.buId;
  }

  getClientId() {
    return this.clientId;
  }

  saveOpenItem(request): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'openItems',
      request,
      this.buildHeaders()
    );
  }

  updateOpenItem(request): Observable<any> {
    return this.http.put(
      `${this.serviceUrl}openItems/draft`,
      request,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      `${this.taskServiceUrl}tasks/${id}/approve`,
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      `${this.taskServiceUrl}tasks/${taskGuid}/reject`,
      json,
      this.buildHeaders()
    );
  }

  updateSharedTableData(sharedTableData: any) {
    this.sharedTableData.next(sharedTableData);
  }

  updateSharedSelectedBalance(sharedSelectedBalance: any) {
    this.sharedSelectedBalance.next(sharedSelectedBalance);
  }

  updateSharedDisplayedColumns(sharedDisplayedColumns: any) {
    this.sharedDisplayedColumns.next(sharedDisplayedColumns);
  }

  updateClientDebtorIds(clientDebtorIds: any) {
    this.clientDebtorIds.next(clientDebtorIds);
  }

  updateSharedSplitScreenData(sharedSplitScreenData: any) {
    this.sharedSplitScreenData.next(sharedSplitScreenData);
  }

  updateDebtorOpenItemListRefreshEvents(debtorOpenItemListRefreshEvents?: any) {
    this.debtorOpenItemListRefreshEvents.next(debtorOpenItemListRefreshEvents);
  }

  updateDebtorOpenItemListRefreshAmountsEvents(
    debtorOpenItemListRefreshAmountsEvents?: any
  ) {
    this.debtorOpenItemListRefreshAmountsEvents.next(
      debtorOpenItemListRefreshAmountsEvents
    );
  }

  getOpenItemssFunction(newRow: any) {
    this.invokeFirstComponentFunction.emit(newRow);
  }

  checkTransactionCode(clientId) {
    this.updatedClientId.emit();
  }

  findAllByDebtor(
    debtorId,
    transactionState,
    debitCreditCode
  ): Observable<any> {
    let url = this.serviceUrl + 'openItems/byDebtor?debtorId=' + debtorId;
    if (transactionState != null) {
      url += '&isOpen=' + transactionState;
    }
    if (debitCreditCode != null) {
      url += '&debitCreditCode=' + debitCreditCode;
    }

    return this.http.get(url, this.buildHeaders());
  }

  findAllByEntityFrom(
    entityFromGuid,
    transactionState,
    debitCreditCode
  ): Observable<any> {
    let url =
      this.serviceUrl +
      'openItems/byEntityFrom?entityFromGuid=' +
      entityFromGuid;
    if (transactionState != null) {
      url += '&isOpen=' + transactionState;
    }
    if (debitCreditCode != null) {
      url += '&debitCreditCode=' + debitCreditCode;
    }

    return this.http.get(url, this.buildHeaders());
  }

  findByTransactionId(transactionId): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'openItems/by-allocation?transactionId=' +
        transactionId,
      this.buildHeaders()
    );
  }

  findAllByObjection(objectionId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'openItems/byObjection?objectionId=' + objectionId,
      this.buildHeaders()
    );
  }

  getPaymentAllocationInformation(request): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'allocations',
      request,
      this.buildHeaders()
    );
  }

  getCountByDebtor(debtorId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'openItems/countByDebtor?debtorId=' + debtorId,
      this.buildHeaders()
    );
  }

  getTotalsByDebtor(debtorId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'openItems/totalsByDebtor?debtorId=' + debtorId,
      this.buildHeaders()
    );
  }

  getTransactionHistory(transactionId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'openItems/' + transactionId + '/history',
      this.buildHeaders()
    );
  }

  findObjectionsByClientId(clientId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'objection-management/objections?clientId=' + clientId,
      this.buildHeaders()
    );
  }

  findObjectionManagementKPIs(clientId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'objection-management/kpis?clientId=' + clientId,
      this.buildHeaders()
    );
  }

  addTransactionFlag(transactionId): Observable<any> {
    return this.http.post(
      this.serviceUrl +
        'objection-management/transactions/' +
        transactionId +
        '/flag',
      this.buildHeaders()
    );
  }

  deleteTransactionFlag(transactionId): Observable<any> {
    return this.http.delete(
      this.serviceUrl +
        'objection-management/transactions/' +
        transactionId +
        '/flag',
      this.buildHeaders()
    );
  }

  sendObjectionCommentBatch(request) {
    return this.http.post<any[]>(
      this.serviceUrl + 'objection-management/comments/batch',
      request,
      this.buildHeaders()
    );
  }

  updateAllocationDraftForItems(request): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'openItems/updateAllocationDrafts',
      request,
      this.buildHeaders()
    );
  }

  getObjectionComments(itemInObjectionId) {
    return this.http.get(
      this.serviceUrl +
        'objection-management/' +
        itemInObjectionId +
        '/comments',
      this.buildHeaders()
    );
  }

  deleteTransactionComments(commentId) {
    return this.http.delete(
      this.serviceUrl + 'objection-management/comments/' + commentId,
      this.buildHeaders()
    );
  }

  getObjectionTransactionHistory(itemPerObjectionId): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'objection-management/' +
        itemPerObjectionId +
        '/history',
      {
        headers: this.buildHeaders().headers,
      }
    );
  }

  updateClassification(transactionId, classificationName): Observable<any> {
    const requestJson = {
      id: transactionId,
      classification: classificationName,
    };
    return this.http.put(
      this.serviceUrl + 'openItems/updateClassification',
      requestJson,
      this.buildHeaders()
    );
  }

  createOrUpdateDeduction(transactionId, deductions): Observable<any> {
    const requestJson = {
      id: transactionId,
      deductions,
    };
    return this.http.put(
      this.serviceUrl + 'openItems/updateConditionAndObjection',
      requestJson,
      this.buildHeaders()
    );
  }

  deleteDeduction(deductionId) {
    return this.http.delete(
      this.serviceUrl + 'openItems/deductions/' + deductionId,
      this.buildHeaders()
    );
  }

  listDeductionsForOpenItem(openItemId) {
    return this.http.get(
      this.serviceUrl + 'openItems/deductions/list/' + openItemId,
      this.buildHeaders()
    );
  }

  getFilterFieldNameValues(fieldName: string, debtorGuid) {
    return this.http.get(
      this.serviceUrl +
        'openItems/filter-values?fieldName=' +
        fieldName +
        '&debtorGuid=' +
        debtorGuid,
      this.buildHeaders()
    );
  }

  startSearch() {
    this.searchStarted.emit(true);
  }

  setSort(choice: any, direction: any) {
    this.sortOrder = direction;
    this.sortField = choice;
  }

  setPaginator(amountPerPage, pageNr) {
    this.pageSize = amountPerPage;
    this.pageIndex = pageNr;
  }

  filterAllByDebtorGuid(
    debtorId,
    transactionState,
    debitCreditCode
  ): Observable<any> {
    let url = this.serviceUrl + 'openItems/filter?debtorGuid=' + debtorId;
    if (transactionState != null) {
      url += '&isOpen=' + transactionState;
    }
    if (debitCreditCode) {
      url += '&credit_debit_code=' + debitCreditCode;
    }

    this.startSearch();
    let params = new HttpParams()
      .append('itemSearchString', this.searchString)
      .append('sortOrder', this.sortOrder)
      .append('sortField', this.sortField)
      .append('page', this.pageIndex)
      .append('pageSize', this.pageSize);
    params = this.addColumnFilters(params);
    return this.http.get<[]>(url, {
      params,
      headers: this.buildHeaders().headers,
    });
  }

  filterTotalsByDebtorGuid(
    debtorId,
    transactionState,
    debitCreditCode
  ): Observable<any> {
    let url = this.serviceUrl + 'openItems/filterTotals?debtorGuid=' + debtorId;
    if (transactionState != null) {
      url += '&isOpen=' + transactionState;
    }
    if (debitCreditCode) {
      url += '&credit_debit_code=' + debitCreditCode;
    }

    this.startSearch();
    let params = new HttpParams()
      .append('itemSearchString', this.searchString)
      .append('sortOrder', this.sortOrder)
      .append('sortField', this.sortField)
      .append('page', this.pageIndex)
      .append('pageSize', this.pageSize);
    params = this.addColumnFilters(params);
    return this.http.get<[]>(url, {
      params,
      headers: this.buildHeaders().headers,
    });
  }

  request(url, config): Observable<HttpEvent<any[]>> {
    return this.http.get<[]>(this.searchApiUrl + url, config);
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const allConfigs = customConfigObj
      ? { ...this.configObj, ...customConfigObj }
      : this.configObj;
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(allConfigs).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        config['params'] = config['params'].append(
          key,
          JSON.stringify(allConfigs[key])
        );
      } else {
        config['params'] = config['params'].append(key, allConfigs[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);

    return config;
  }

  listPartners(
    customConfig?: any,
    jsonFilteredValues: boolean = false
  ): Observable<HttpEvent<any[]>> {
    return this.request(
      'openitems/partners',
      this.getConfigs(customConfig, jsonFilteredValues)
    );
  }

  clearColumnFilters() {
    this.mapOfColumnFilters = [];
  }

  clearSortAndPagination() {
    this.setSort('id', 'ASC');
    this.setPaginator('20', '0');
  }

  setColumnFilters(choice: any, selection: any) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getSearchString() {
    return this.searchString;
  }

  getColumnFilters() {
    return this.mapOfColumnFilters.filter(
      (e) => e.values && e.values.length > 0
    );
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters
      .filter((e) => e.values && e.values.length > 0)
      .forEach((entry) => {
        params = params.append(entry.choice, entry.values);
      });
    return params;
  }
}
