import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TableConfiguration } from './table-configuration';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { NotificationService } from '../../../../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

const moment = _rollupMoment || _moment;
export const DATE_FORMATS = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-editable-list-form',
  templateUrl: './editable-list-form.component.html',
  styleUrls: ['./editable-list-form.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class EditableListFormComponent implements OnInit, AfterViewChecked {
  @Output() selectionChanged = new EventEmitter();
  @Input() entityName: string;
  @Input() displayedColumns: string[];
  @Input() tableConfiguration: TableConfiguration[];
  @Input() getNewObject: (index) => any;
  @Input() getFormRow: (object: any) => UntypedFormGroup;
  @Input() form: UntypedFormGroup;
  @Input() entities: any[] = [];
  @Input() mode: 'CREATE' | 'UPDATE';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel(true, []);
  deletedRowsIndexes: any[] = [];
  index = 0;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.entities.length < 1) {
      this.addEmptyRow();
    } else {
      this.addRowFormEntity(this.entities);
    }
  }

  addRowFormEntity(entities) {
    let i = 0;
    for (const entityObject of entities) {
      const entity = entityObject.data;
      entity.index = i;
      this.dataSource.data.push(entity);
      const formRow = this.getFormRow(entity);
      formRow.patchValue(entity);
      if (entityObject.disabled) {
        formRow.disable();
      }
      this.getFormObjects.push(formRow);
      i++;
    }
  }

  reloadEditableList() {
    this.getFormObjects.clear();
    this.dataSource.data = [];
    this.addRowFormEntity(this.entities);
    this.dataSource._updateChangeSubscription();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  addEmptyRow() {
    const object = this.getNewObject(this.index);
    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.index++;
  }

  get formControls() {
    return this.form.controls;
  }

  get getFormObjects() {
    return this.formControls.objects as UntypedFormArray;
  }

  addFormRow(object) {
    this.getFormObjects.push(this.getFormRow(object));
  }

  getFormGroup(element) {
    let formGroup: any = null;
    formGroup = this.getFormObjects.controls.find(
      (item) => item.value.index === element.index
    );

    return formGroup;
  }

  hasChanged(fieldName: string, element) {
    return false;
  }

  isFieldRequired(element, fieldName: string) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    if (formGroup) {
      const control = formGroup.get(fieldName);
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (!validator) {
          return false;
        }
        return validator.required;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  chosenYearHandler(normalizedYear: Moment, element, fieldName) {
    let formGroup: any = null;
    formGroup = this.getFormObjects.controls.find(
      (item) => item.value.index === element.index
    );

    formGroup.get(fieldName).setValue(moment());
    const ctrlValue = formGroup.get(fieldName).value;
    ctrlValue.year(normalizedYear.year());
    formGroup.get(fieldName).setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker,
    element,
    fieldName,
    entityName
  ) {
    let formGroup: any = null;
    formGroup = this.getFormObjects.controls.find(
      (item) => item.value.index === element.index
    );
    const ctrlValue = formGroup.get(fieldName).value;
    ctrlValue.month(normalizedMonth.month());
    formGroup.get(fieldName).setValue(ctrlValue);
    formGroup.get(fieldName).patchValue(ctrlValue);
    datepicker.close();

    this.checkReportingDate(element, entityName);
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(this.getTranslateKey()),
      }
    );
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.getFormObjects.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        let removed = false;
        if (
          this.getFormObjects.value[i] &&
          item === this.getFormObjects.value[i].index &&
          !removed
        ) {
          this.getFormObjects.removeAt(i);
          removed = true;
        }
      });
    }
  }

  getErrors(element, columnName: string) {
    const formGroup = this.getFormGroup(element);
    const currentControl = formGroup.get(columnName);
    if (currentControl.errors) {
      return Object.keys(currentControl.errors);
    }
    return [];
  }

  checkReportingDate(element, entityName) {
    if (entityName === 'salesFigures' || entityName === 'fundDeposits') {
      const formGroup: UntypedFormGroup = this.getFormGroup(element);
      if (formGroup.get('auditorValue').value === true) {
        if (
          formGroup.get('reportingDate').value !== null &&
          formGroup.get('reportingDate').value !== undefined
        ) {
          const reportingDateYear = new Date(
            formGroup.get('reportingDate').value
          ).getFullYear();
          const currentYear = new Date().getFullYear();

          if (reportingDateYear >= currentYear) {
            formGroup.get('reportingDate').setValue(null);
            this.notificationService.showToast(
              'ERROR.PARTICIPATION-FEE.REPORTING-DATE',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { error: 'unknown', errorId: 'unknown' }
            );
          }
        }
      }
    }
  }

  onSelectChange(index: number, controlName: string, value: any) {
    this.selectionChanged.emit({ index, controlName, value });
  }

  private getTranslateKey(): string {
    switch (this.entityName) {
      case 'fundDeposits':
        return 'ANIMAL-WELFARE.PARTICIPATION-FEE.FUND-DEPOSITS';
      case 'slaughterhouseFigures':
        return 'ANIMAL-WELFARE.PARTICIPATION-FEE.SETTLE-SLAUGHTERHOUSE-FIGURES';
      case 'salesFigures':
        return 'ANIMAL-WELFARE.PARTICIPATION-FEE.SALES-FIGURES';
      case 'foodProcessingCompanyFigures':
        return 'ANIMAL-WELFARE.PARTICIPATION-FEE.FOOD-PROCESSING-COMPANY-FIGURES';
      default:
        return 'ANIMAL-WELFARE.PARTICIPATION-FEE.FUND-DEPOSITS';
    }
  }
}
