import { ValidatorFn, UntypedFormGroup, AbstractControl } from '@angular/forms';

export class PasswordValidator {
  public sameConfirmPasswordValidation(
    text1p: string,
    text2p: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const text1 = formGroup.get(text1p).value;
      const text2 = formGroup.get(text2p).value;
      return text1 && text2 && text1.toString() !== text2.toString()
        ? { differentConfirmPassword: true }
        : null;
    };
  }

  public sameNewOldPasswordValidation(
    text1p: string,
    text2p: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const text1 = formGroup.get(text1p).value;
      const text2 = formGroup.get(text2p).value;
      return text1 && text2 && text1.toString() !== text2.toString()
        ? { differentNewOldPassword: true }
        : null;
    };
  }
}

export function UserNameInNewPasswordValidator(username, surname): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = true;

    if (
      control.value !== null &&
      control.value !== undefined &&
      control.value !== ''
    ) {
      const lenStrName = username.length;
      const lenStrSurname = username.length;
      let possibleCombinationsName = [];
      let possibleCombinationsSurname = [];
      let indexName = 0;
      let indexSurname = 0;

      while (indexName < lenStrName) {
        const char = username.charAt(indexName);

        const arrTemp = [char];
        for (const x of possibleCombinationsName) {
          arrTemp.push('' + possibleCombinationsName[x] + char);
        }

        possibleCombinationsName = possibleCombinationsName.concat(arrTemp);
        indexName++;
      }

      while (indexSurname < lenStrSurname) {
        const char = surname.charAt(indexSurname);

        const arrTemp = [char];
        for (const x of possibleCombinationsSurname) {
          arrTemp.push('' + possibleCombinationsSurname[x] + char);
        }

        possibleCombinationsSurname =
          possibleCombinationsSurname.concat(arrTemp);
        indexSurname++;
      }

      possibleCombinationsName = possibleCombinationsName.filter(
        (arr) => arr.length >= 4 && username.includes(arr)
      );
      possibleCombinationsSurname = possibleCombinationsSurname.filter(
        (arr) => arr.length >= 4 && surname.includes(arr)
      );

      if (possibleCombinationsName.length > 0) {
        for (
          let i = 0;
          i < possibleCombinationsName.length && valid === true;
          i++
        ) {
          if (control.value.includes(possibleCombinationsName[i])) {
            valid = false;
          }
        }
      }

      if (possibleCombinationsName.length > 0) {
        for (
          let i = 0;
          i < possibleCombinationsSurname.length && valid === true;
          i++
        ) {
          if (control.value.includes(possibleCombinationsSurname[i])) {
            valid = false;
          }
        }
      }
    }

    return !valid ? { passwordConditionsInvalid: true } : null;
  };
}

export function PasswordLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value != null &&
      control.value !== undefined &&
      control.value !== '' &&
      control.value.length < 15
      ? { passwordConditionsInvalid: true }
      : null;
  };
}

export function PasswordConditionsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid: boolean[] = [];

    if (
      control.value !== null &&
      control.value !== undefined &&
      control.value !== ''
    ) {
      if (control.value) {
        valid.push(/^(?=.*[A-Z]).+$/.test(control.value));
        valid.push(/^(?=.*[a-z]).+$/.test(control.value));
        valid.push(/^(?=.*\d).+$/.test(control.value));
        valid.push(/^(?=.*[-+_!@#$%^&*., ?]).+$/.test(control.value));
      }
    }

    if (valid.length > 0) {
      let contitionsMatched = 0;
      valid.forEach((cond) => {
        if (cond) {
          contitionsMatched++;
        }
      });

      return contitionsMatched < 3 ? { passwordConditionsInvalid: true } : null;
    }

    return null;
  };
}
