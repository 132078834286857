import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-email-draft-modal',
  templateUrl: './email-draft-modal.component.html',
  styleUrls: ['./email-draft-modal.component.less'],
})
export class EmailDraftModalComponent implements OnInit {
  data: any;
  entityName = 'email-draft-modal';
  form: UntypedFormGroup;
  uploadedFiles: any;
  readOnlyFrom: false;
  hideAttachment = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.data = {
      fileName: '',
      mailTo: '',
      mailCc: '',
      mailFrom: '',
      subject: '',
      content: '',
      attachment: '',
    };

    if (dialogData) {
      this.data = dialogData;
      this.readOnlyFrom = dialogData.readOnlyFrom
        ? dialogData.readOnlyFrom
        : true;
      this.uploadedFiles = dialogData.uploadedFiles
        ? dialogData.uploadedFiles
        : '';
    } else {
    }
  }

  ngOnInit() {
    if (
      this.data.state &&
      (this.data.state.type === 'PAYOUT_SUGGESTION_LIST_EMAIL' ||
        this.data.state.type === 'AW_SEPA_EXCEL_EMAIL')
    ) {
      this.hideAttachment = true;
    }
    this.form = this.formBuilder.group({
      mailTo: [
        '',
        [
          Validators.required,
          this.validateMultiEmail,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      mailCc: [
        '',
        [
          this.validateMultiEmail,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      mailFrom: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      subject: ['', Validators.required],
      content: ['', Validators.required],
      attachment: [''],
    });
  }

  validateMultiEmail = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    if (control.value) {
      const emails = control.value.split(';').map((e) => e.trim());
      const forbidden = emails.some((emailData) =>
        Validators.email(new UntypedFormControl(emailData))
      );
      return forbidden ? { email: forbidden } : null;
    }
    return null;
  };
}
