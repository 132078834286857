<div class="contact-us-container">
  <div class="container full-height" fxLayout="row wrap" fxLayoutGap="7px grid">
    <div fxFlex="100">
      <div class="centered-row">
        <div class="texts-container-row">
          <div>{{ 'HOMEPAGE.CONTACT-US.TEXT-1' | translate }}</div>
          <div>initiative-tierwohl@bertelsmann.de</div>
          <div class="phone">{{ 'HOMEPAGE.WELCOME.CONTACT-US.PHONE-1' | translate }}</div>

          <div class="margin-top">{{ 'HOMEPAGE.CONTACT-US.TEXT-2' | translate }}</div>
          <div>anfragen@initiative-tierwohl.de</div>
          <div class="phone">{{ 'HOMEPAGE.WELCOME.CONTACT-US.PHONE-2' | translate }}</div>

          <div class="stepper-container">
            <mat-horizontal-stepper
              #stepper
              (click)="stepperChange($event.target)"
              *ngIf="!media.isActive('xs') && !media.isActive('sm')"
            >
              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.WEBSITE-CREDITS' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>

              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.PRIVACY-POLICY' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.TERMS-OF-SERVICE' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>
            </mat-horizontal-stepper>

            <mat-vertical-stepper
              #stepper
              (click)="stepperChange($event.target)"
              *ngIf="media.isActive('xs') || media.isActive('sm')"
            >
              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.WEBSITE-CREDITS' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>

              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.PRIVACY-POLICY' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>
                  <span>{{ 'SIDE-NAV.TERMS-OF-SERVICE' | translate | titlecase }}</span>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
