import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlockingIndicatorService {
  private sortField = 'blocking_id';
  private sortOrder = 'DESC';
  private searchText = '';
  private pageSize = '20';
  private pageIndex = '0';
  private readonly serviceUrl;
  private mapOfColumnFilters = [];
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.liquidityPlanService;
  }

  setColumnFilters(choice: any, selection: any) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  setPaginator(amountPerPage, pageNr) {
    this.pageSize = amountPerPage;
    this.pageIndex = pageNr;
  }

  filterAccounts(livestockProducerId: any): Observable<any[]> {
    let config = {};
    config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('filterText', this.searchText)
      .append('sortDirection', this.sortOrder)
      .append('sortField', this.sortField)
      .append('pageIndex', this.pageIndex)
      .append('pageSize', this.pageSize)
      .append('livestockProducerId', livestockProducerId);
    config['params'] = this.addColumnFilters(config['params']);
    return this.http.get<[]>(
      this.serviceUrl + 'blockingIndicator/filter',
      config
    );
  }

  findDistinctValues(args: any, livestockProducerId: any): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'blockingIndicator/distinctValues?livestockProducerId=' +
        livestockProducerId +
        '&fieldName=' +
        args,
      this.buildHeaders()
    );
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }

  setSort(field, direction) {
    this.sortOrder = direction;
    this.sortField = field;
  }
}
