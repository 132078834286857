export const salesDocumentsListColumnsConf: any[] = [
  {
    name: 'recipientName',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.PARTICIPATION-FEE.SALES.ACCOUNT-NUMBER',
    locked: true,
  },
  {
    name: 'documentTime',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.PARTICIPATION-FEE.REPORTED-ON',
    locked: true,
  },
  {
    name: 'documentName',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.PARTICIPATION-FEE.DOCUMENT-NAME',
    locked: true,
  },
  {
    name: 'status',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.PARTICIPATION-FEE.STATUS',
    locked: true,
  },
  {
    version: '3',
  },
];
