import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { livestockProducerDocumentsColumns } from 'src/app/in-memory-data/animal-welfare/livestock-producer-documents/table-columns';
import { livestockProducerDocumentsColumnsConf } from 'src/app/in-memory-data/animal-welfare/livestock-producer-documents/table-columns-configuration';
import { DocumentManagementService } from 'src/app/shared/services/animal-welfare/document-management/document-management.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-livestock-documents',
  templateUrl: './livestock-documents.component.html',
  styleUrls: ['./livestock-documents.component.less'],
})
export class LivestockDocumentsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() livestockProducer;

  filterForm: UntypedFormGroup;
  columns = livestockProducerDocumentsColumnsConf;
  displayedColumns = livestockProducerDocumentsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'livestock_producer_overview_documents_table';

  entityName = 'livestockProcerData';
  dataSource = new MatTableDataSource<any>([]);

  dateFormat: any;

  originalDataSource = new MatTableDataSource<any>([]);

  totalCount: number;
  pageSize = 20;
  sortedBy = 'operationDate';
  sortDirection = 'asc';
  subject = new Subject();

  loaderSpinner = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private documentManagementService: DocumentManagementService
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.loaderSpinner = true;
    this.dateFormat = this.userService.getDateFormat();
    this.getTableConfiguration();
    this.loadTable();

    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.documentManagementService
        .findAllByLivestockProducerId(
          this.livestockProducer.id,
          this.sortedBy,
          this.sortDirection,
          this.paginator.pageIndex,
          this.pageSize,
          this.filterForm.get('searchString').value
        )
        .subscribe(
          (data: any) => {
            this.dataSource.data = data.content;
            this.totalCount = data.total;
            this.originalDataSource = data.content;
            this.dataSource.filter = '';
            this.loaderSpinner = false;
          },
          (error) => {
            this.loaderSpinner = false;
          }
        );
    });
  }

  loadTable() {
    this.documentManagementService
      .findAllByLivestockProducerId(
        this.livestockProducer.id,
        this.sortedBy,
        this.sortDirection,
        this.paginator.pageIndex,
        this.pageSize,
        this.filterForm.get('searchString').value
      )
      .subscribe(
        (data: any) => {
          if (data.content) {
            this.dataSource.data = data.content;
            this.originalDataSource = data.content;
          } else {
            this.dataSource.data = [];
          }
          this.dataSource.filter = '';
          this.totalCount = data.total;
          this.loaderSpinner = false;
        },
        (error) => {
          this.loaderSpinner = false;
        }
      );
  }

  getFieldNames = (args): Observable<any> => {
    return this.documentManagementService.getFilterFieldNameValues(args);
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.paginator.firstPage();
    this.documentManagementService.setColumnFilter(choice, selection);
    this.subject.next(undefined);
  };

  sortTable = (sortTableEvent): void => {
    this.paginator.firstPage();
    this.sortedBy = sortTableEvent.choice;
    this.sortDirection = sortTableEvent.direction;
    this.subject.next(undefined);
  };

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    this.loadTable();
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  searchLivestockProducerDocuments() {
    this.loaderSpinner = true;
    this.subject.next(undefined);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }
  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                livestockProducerDocumentsColumnsConf,
                livestockProducerDocumentsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = livestockProducerDocumentsColumnsConf;
    this.displayedColumns = livestockProducerDocumentsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  downloadAttachment(element) {
    this.documentManagementService
      .download(element.id, element.filename)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.download = element.filename;
        anchor.href = url;
        anchor.click();
      });
  }
}
