import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientInterfaceApprovalService {
  private serviceUrl: string;

  private sharedClientInterfaceApprovalForm = new BehaviorSubject<any>(null);
  currentSharedClientInterfaceApprovalForm =
    this.sharedClientInterfaceApprovalForm.asObservable();

  updatesharedClientInterfaceApprovalForm(
    sharedClientInterfaceApprovalForm: any
  ) {
    this.sharedClientInterfaceApprovalForm.next(
      sharedClientInterfaceApprovalForm
    );
  }

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.clientService;
  }

  approveTask(id: number, comment: string): Observable<void> {
    const body = { comment };
    return this.http.post<void>(
      this.serviceUrl + 'clients/drafts/' + id + '/approval-imported-client',
      body,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string) {
    const body = { comment };
    return this.http.post(
      this.serviceUrl + 'clients/drafts/' + id + '/rejection-imported-client',
      body,
      this.buildHeaders()
    );
  }

  approveTaskInterface(
    id: number,
    comment: string,
    company: any
  ): Observable<void> {
    const body = { comment, company };
    return this.http.post<void>(
      this.serviceUrl + 'clients/drafts/' + id + '/approval-imported-client',
      body,
      this.buildHeaders()
    );
  }

  rejectTaskInterface(id: number, comment: string, code: string) {
    const body = { rejectionReasonCode: code, comment };
    return this.http.post(
      this.serviceUrl + 'clients/drafts/' + id + '/rejection-imported-client',
      body,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
