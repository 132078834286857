export const documentHistoryModalColumns: string[] = [
  'select',
  'name',
  'vvvoNr',
  'salesTaxId',
  'street',
  'number',
  'city',
  'zipCode',
  'country',
];
