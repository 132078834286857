import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { RealmSelectionModalComponent } from '../realm-selection-modal/realm-selection-modal.component';
import { RoleConfigurationComponent } from './role-configuration/role-configuration.component';
import { DraftService } from '../../../../shared/services/draft/draft.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { first } from 'rxjs/operators';
import { SharedDataService } from './services/shared-data.service';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.less'],
})
export class RoleCreateComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
  entityName = 'role-create';
  isTask = false;
  editMode = false;
  realm;
  state;
  role: {};
  @ViewChild(RoleConfigurationComponent)
  RoleConfigurationComponent: RoleConfigurationComponent;
  private canUnlockRole: boolean;
  private internalRole: boolean;
  approvedRole;

  constructor(
    private matDialogService: MatDialog,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private draftService: DraftService,
    private cdref: ChangeDetectorRef,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.approvedRole.subscribe((data) => {
      this.approvedRole = data;
    });
  }

  get frmRole() {
    return this.RoleConfigurationComponent
      ? this.RoleConfigurationComponent.frmRole
      : null;
  }

  ngOnInit(): void {
    this.state = window.history.state;
    this.role = this.state.param;
    if (!this.state.viewMode) {
      this.canUnlockRole = true;
    }
    if (this.state.param && this.state.param['internalRole'] !== null) {
      this.internalRole = this.state.param['internalRole'];
    }
    if (!this.state.editMode && !this.state.copied && !this.state.task) {
      const dialog = this.matDialogService.open(RealmSelectionModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.realm = result;
        } else {
          this.router.navigateByUrl('/userManagement/role');
        }
      });
    } else if (this.state.task) {
      this.loadTaskData();
    }
  }

  ngOnDestroy(): void {
    this.unlockPermission();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.unlockPermission();
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.state.editMode) {
      this.editMode = true;
    }
  }

  saveRole() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          if (this.RoleConfigurationComponent) {
            this.RoleConfigurationComponent.setUpdateInfo();
            const requestJson = {
              comment: result.comment,
              roleDto: {
                ...this.RoleConfigurationComponent.frmRole.getRawValue(),
                ...{ internalRole: this.internalRole },
              },
            };
            this.userService.updateRole(requestJson).subscribe((data) => {
              this.canUnlockRole = false;
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                { data: this.translateService.instant('ENTITIES.ROLE') }
              );
              this.router.navigateByUrl('/userManagement/role');
            });
          }
        }
      });
  }

  loadTaskData() {
    this.isTask = true;
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        /**
         * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
         * to much of empty function. So this comment is to makes the sonarqube happy.
         * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
         */
      });
  }

  private unlockPermission() {
    if (this.canUnlockRole && this.role && this.role['id']) {
      const realm = this.role['internalRole'] ? 'internal' : 'external';
      this.userService
        .unLockPermission(this.role['id'], realm)
        .subscribe((data) => {
          /**
           * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
           * to much of empty function. So this comment is to makes the sonarqube happy.
           * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
           */
        });
    }
  }
}
