export const livestockProducerDocumentsColumnsConf: any[] = [
  {
    name: 'creationDate',
    index: 0,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.CREATION-DATE',
    locked: true,
  },
  {
    name: 'documentType',
    index: 1,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.DOCUMENT-TYPE',
    locked: true,
  },
  {
    name: 'name',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.NAME',
    locked: true,
  },
  {
    version: 2,
  },
];
