<div class="filters row-buttons-select row">
  <div class="search-filter col-xl-6 col-lg-12">
    <mat-form-field>
      <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'GENERAL.SEARCH' | translate }}"
        name="itemSearch"
        class="custom-input"
        id="{{ entityName }}-search-input-name"
        [formControl]="searchForm.get('freeFormSearch')"
        [removeHtmlTags]="searchForm.get('freeFormSearch')"
        type="text"
      />
    </mat-form-field>
    <button mat-button class="custom-button search-button" id="{{ entityName }}-search-button">
      <span class="material-icons">search</span>
    </button>
    <!-- Period filter -->
    <app-period-filter [searchTerm]="searchTerm"></app-period-filter>
  </div>

  <div class="input-controls col-xl-6 col-lg-12">
    <div class="nav-buttons">
      <div class="control-buttons">
        <div class="button-container-favs" *ngFor="let b of favButtons">
          <div *ngIf="b.checked && b.name != 'paymentAllocation' && b.name != 'objection'">
            <button
              mat-button
              class="custom-button"
              id="{{ entityName }}-{{ b.name }}-button"
              (click)="getFunction(b)"
              [disabled]="getDisabled(b)"
            >
              {{ b.translateName | translate }}
            </button>
          </div>
          <div *ngIf="b.checked && b.name == 'paymentAllocation' && allocationsAllowed">
            <button
              *hasPermission="'acc.a.c'"
              mat-button
              class="custom-button"
              id="{{ entityName }}-{{ b.name }}-button"
              (click)="getFunction(b)"
              [disabled]="getDisabled(b)"
            >
              {{ b.translateName | translate }}
            </button>
          </div>
          <div *ngIf="b.checked && b.name == 'objection'">
            <button
              *hasPermission="['o.c']"
              mat-button
              class="custom-button"
              id="{{ entityName }}-{{ b.name }}-button"
              (click)="getFunction(b)"
              [disabled]="getDisabled(b)"
            >
              {{ b.translateName | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="fav-functions-container">
      <button class="custom-button" id="{{ entityName }}-favFunctions-button" mat-button [matMenuTriggerFor]="appMenu">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #appMenu="matMenu" class="select-columns menu-actions" (close)="favButtonsChanged()">
  <ng-template matMenuContent let-element="element">
    <div class="border-row" *ngFor="let b of favButtons">
      <div>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [checked]="b.checked"
          (change)="modifyColumn(b.name, $event)"
          class="config-table-checkbox"
          [disabled]="b.disabled"
          id="{{ entityName }}-favFunctionsCheckBox-{{ b.name }}-button"
        >
          {{ b.translateName | translate }}
        </mat-checkbox>
      </div>
    </div>
  </ng-template>
</mat-menu>
