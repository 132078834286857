<app-back-button *ngIf="isTask" class="back-button-container"> </app-back-button>
<div class="edit-task">
  <button
    (click)="changeTaskState(true)"
    *hasPermission="'aw.lg.u'"
    class="edit-task-button"
    id="{{entityName}-edit-task"
    mat-button
  >
    <img
      *ngIf="!editableTask && isTask && !isDeleteTask"
      alt="{{ 'GENERAL.EDIT' | translate }}"
      src="assets/images/ic_edit.svg"
    />
  </button>
</div>

<div class="input-wrapper form-container" [ngClass]="{ 'back-button-exists': isTask }">
  <form class="form" [formGroup]="commonForm" autocomplete="off">
    <app-mat-radio
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTED-BY'"
      [approvedValue]="quantityLabelledGoodsApproved[0] ? quantityLabelledGoodsApproved[0].reportedBy : null"
      [changeFunction]="clearName"
      [controlName]="'reportedBy'"
      [controlObject]="commonForm.controls.reportedBy"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="commonForm"
      [isTask]="false"
      [items]="reportedByOptions"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTED-BY'"
      [optionValue]="'value'"
      [taskValue]="quantityLabelledGoodsTask[0] ? quantityLabelledGoodsTask[0].reportedBy : null"
      [titleTask]="false"
    ></app-mat-radio>

    <ng-container *ngIf="commonForm.controls.reportedBy.value == 1">
      <div class="form-row">
        <mat-form-field appearance="fill" [ngClass]="{ 'val-changed': hasSupplierOrPurchasedChanged('supplier') }">
          <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER' | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER' | translate }}"
            formControlName="supplier"
            [required]="isFieldRequiredCommonForm('supplier')"
          >
            <mat-option>
              <ngx-mat-select-search
                formControlName="supplierSearch"
                noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                placeholderLabel="{{ 'search' | translate }}"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredSuppliers" [value]="option.id">
              {{ option.qsid }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="commonForm.controls.reportedBy.value == 2">
      <div class="form-row">
        <mat-form-field appearance="fill" [ngClass]="{ 'val-changed': hasSupplierOrPurchasedChanged('receiver') }">
          <mat-select
            placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER' | translate }}"
            formControlName="receiver"
            [required]="isFieldRequiredCommonForm('receiver')"
          >
            <mat-option>
              <ngx-mat-select-search
                formControlName="receiverSearch"
                noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                placeholderLabel="{{ 'search' | translate }}"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let se of filteredReceivers" [value]="se.id">
              {{ se.accountNumber ? se.accountNumber : se.qsid }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="form-row table-list">
      <mat-form-field appearance="fill" [ngClass]="{ 'val-changed': checkHighLightField('reportingPeriodId', 0) }">
        <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD' | translate }}"
          formControlName="reportingPeriod"
        >
          <mat-option *ngFor="let option of existentReportingPeriods" [value]="option.id">
            {{ option.startDate }} - {{ option.endDate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="table-list" [formGroup]="form">
  <table [dataSource]="dataSource" aria-hidden="true" id="{{ entityName }}-search-results-table" mat-table matSort>
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select" mat-header-cell>
        <mat-checkbox
          (change)="$event ? masterToggle(true) : null"
          [aria-label]="checkboxLabel()"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          id="{{ entityName }}-table-results-select-all"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox
          (change)="$event ? selection.toggle(element) : null"
          (click)="$event.stopPropagation()"
          [aria-label]="checkboxLabel(element)"
          [checked]="selection.isSelected(element)"
          id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="suppliedTo">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        [hidden]="commonForm.controls.reportedBy.value != 1"
        class="v25-th"
        id="{{ entityName }}-table-results-supplied-to"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        [hidden]="commonForm.controls.reportedBy.value != 1"
        id="{{ entityName }}-table-results-supplied-to-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('suppliedTo', i) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER' | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER' | translate }}"
              formControlName="suppliedTo"
              [required]="isFieldRequired(element, 'suppliedTo')"
              (selectionChange)="changeReceiverNameTable(element)"
            >
              <mat-option>
                <ngx-mat-select-search
                  formControlName="receiverSearch"
                  noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                  placeholderLabel="{{ 'search' | translate }}"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let se of element.filteredReceivers" [value]="se.id">
                {{ se.accountNumber ? se.accountNumber : se.qsid }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="suppliedFrom">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        [hidden]="commonForm.controls.reportedBy.value != 2"
        class="v25-th"
        id="{{ entityName }}-table-results-supplied-from"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        [hidden]="commonForm.controls.reportedBy.value != 2"
        id="{{ entityName }}-table-results-supplied-from-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('suppliedFrom', i) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER' | translate }}</mat-label>
            <mat-select
              (selectionChange)="changeNameTable(element)"
              [required]="isFieldRequired(element, 'suppliedFrom')"
              formControlName="suppliedFrom"
              placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER' | translate }}"
            >
              <mat-option>
                <ngx-mat-select-search
                  formControlName="suppliedFromSearch"
                  noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                  placeholderLabel="{{ 'search' | translate }}"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of filteredSuppliers" [value]="option.id">
                {{ option.qsid }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-name"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.NAME' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('name', i) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.NAME' | translate }}</mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-name"
              matInput
              type="text"
              formControlName="name"
              [readonly]="true"
              placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.NAME' | translate }}"
              required
              removeHtmlTags
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="goodsGroup">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-goods-group"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.GOODS-GROUP' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-goods-group-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('goodsGroupId', i) }"
            appearance="fill"
            id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-goodsGroup"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.GOODS-GROUP' | translate }}</mat-label>
            <mat-select
              formControlName="goodsGroupId"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-goodsGroup-select"
              required
              (selectionChange)="onGoodGroupChange($event, element)"
            >
              <mat-optgroup *ngFor="let group of goodsGroupsOptionsLocal" [label]="group.translateName | translate">
                <mat-option *ngFor="let option of group.options" [value]="option.id">
                  <span>
                    {{ getGoodsGroupNameSelect(option.id) }}
                  </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-type"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('type', i) }"
            appearance="fill"
            id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-goodsGroup"
            required
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE' | translate }}</mat-label>
            <mat-select
              formControlName="type"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-type-select"
              required
            >
              <mat-option
                *ngFor="let option of typeOptions; let i = index"
                [value]="option.name"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-typeOptions-option-{{ i }}"
              >
                {{ option.translateName | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-quantity"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.QUANTITY' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-quantity-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{ 'val-changed': checkHighLightField('quantity', i) }"
          >
            <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.QUANTITY' | translate }}</mat-label>
            <input
              (change)="changeQuantity(element)"
              autocomplete="off"
              formControlName="quantity"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-quantity"
              matInput
              placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.QUANTITY' | translate }}"
              required
              type="number"
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-comment"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <mat-form-field
            appearance="fill"
            [formGroup]="getFormGroup(element)"
            [ngClass]="{
              'val-changed': checkHighLightField('comment', i)
            }"
          >
            <mat-label>
              {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT' | translate }}
            </mat-label>
            <input
              autocomplete="off"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-comment"
              matInput
              type="text"
              formControlName="comment"
              placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT' | translate }}"
              removeHtmlTags
            />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="remove">
      <th
        (click)="paginator.firstPage()"
        *matHeaderCellDef
        class="v25-th"
        id="{{ entityName }}-table-results-remove"
        mat-header-cell
        mat-sort-header
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REMOVE' | translate }}
      </th>
      <td
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-remove-{{ paginator.pageIndex + 1 }}-{{ i }}"
        mat-cell
      >
        <div *ngIf="getFormGroup(element) != undefined">
          <div [formGroup]="getFormGroup(element)">
            <mat-slide-toggle
              formControlName="remove"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-remove"
            >
              <span class="material-icons material-check-icon"> done </span>
            </mat-slide-toggle>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions" mat-header-cell>
        <button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          (click)="deleteSelectedRows(element)"
          *ngIf="checkIfNegativeRow(element)"
          class="remove-button"
          id="{{ entityName }}-delete-button"
          mat-button
        >
          <mat-icon class="add-icon">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr [hidden]="loaderSpinner" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" [pageSize]="20" showFirstLastButtons></mat-paginator>

<div class="filters checksum-container" [formGroup]="checkSumForm">
  <div class="filter-input">
    <app-mat-input
      [approvedValue]="null"
      [controlName]="'checksum'"
      [controlObject]="checkSumForm.controls.checksum"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="checkSumForm"
      [isTask]="false"
      [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
      [taskValue]="null"
      [titleTask]="false"
      style="margin-left: 40px"
    >
    </app-mat-input>
  </div>
</div>

<div class="filter-input button-container">
  <ng-container>
    <button
      (click)="cancelEdit()"
      *ngIf="!isTask || (isTask && editableTask)"
      class="custom-button-cancel"
      id="{{ entityName }}-cancel-button"
      mat-button
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c'">
    <button
      *ngIf="(!isTask && !editMode) || (editMode && isDraft)"
      mat-button
      class="custom-button"
      (click)="addEmptyRow()"
      id="{{ entityName }}-create-button"
    >
      <span>
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.ADD-NEW' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c'">
    <button
      *ngIf="!isTask || (isTask && editableTask)"
      mat-button
      [disabled]="getDisabledStatus()"
      (click)="onClickSave(false)"
      class="custom-button"
      id="{{ entityName }}-save-button"
    >
      <span>
        {{ 'GENERAL.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lg.c.d'">
    <button
      *ngIf="!isTask"
      [disabled]="dataSource.data.length == 0 || getDisabledStatusDraft()"
      (click)="onClickSave(true)"
      class="custom-button"
      id="{{ entityName }}-save-as-draft-button"
      mat-button
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
      </span>
    </button>
  </ng-container>
</div>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button
      mat-menu-item
      (click)="deleteSelectedRows(element)"
      id="{{ entityName }}-table-results-action-menu-delete-button"
    >
      <span class="material-icons icon-menu"> delete_outline </span>
      <span translate>GENERAL.DELETE</span>
    </button>
  </ng-template>
</mat-menu>
