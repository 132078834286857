import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AnimalWelfareFile } from '../../../models/animal-welfare-file';
import xmlFormat from 'xml-formatter';

@Component({
  selector: 'app-aw-itp-protocol-request-response-modal',
  templateUrl: './aw-itp-protocol-request-response-modal.component.html',
  styleUrls: ['./aw-itp-protocol-request-response-modal.component.less'],
})
export class AwItpProtocolRequestResponseModalComponent implements OnInit {
  awFile: AnimalWelfareFile;
  isRequest: boolean;
  isProcessingFinished: boolean;
  beautifiedRequestXMLString: string;
  beautifiedResponseXMLString: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    if (data.awFile) {
      this.awFile = data.awFile;
    }
    this.isRequest = data.isRequest;
    this.isProcessingFinished = data.awFile.progress === 'COMPLETED';
  }

  ngOnInit() {
    this.setPrettyOutput();
  }

  setPrettyOutput() {
    const formatOptions = {
      indentation: '  ',
      filter: (node) => node.type !== 'Comment',
      collapseContent: true,
      lineSeparator: '\n',
    };

    this.beautifiedRequestXMLString = this.isCsv()
      ? this.awFile.request
      : xmlFormat(this.awFile.request, formatOptions);
    this.beautifiedResponseXMLString = this.isCsv()
      ? this.awFile.response
      : xmlFormat(this.awFile.response, formatOptions);
  }

  isCsv() {
    return this.awFile.filename.toLowerCase().endsWith('.csv');
  }
}
