import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { AccountStatementFileProcessingService } from 'src/app/shared/services/account-statement-file-processing/account-statement-file-processing.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { allowedMediaTypes } from 'src/app/shared/utils/file-upload/allowed-media-types';


interface ProcessFileType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-account-statement-file-processing',
  templateUrl: './account-statement-file-processing.component.html',
  styleUrls: ['./account-statement-file-processing.component.less'],
})
export class AccountStatementFileProcessingComponent {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'account-statement-file-processing';
  fileToUpload: File = null;
  selectedItem: string;

  fileTypes: ProcessFileType[] = [
    { value: '1', viewValue: 'MT940' },
    { value: '2', viewValue: 'CAMT054' },
    { value: '3', viewValue: 'PACS008' },
  ];

  filter;
  constructor(
    private accountStatementFileProcessingService: AccountStatementFileProcessingService,
    private notificationService: NotificationService
  ) {}

  handleFileInput(files: FileList) {
    const file = files.item(0)
    if (allowedMediaTypes.includes(file.type)) {
      this.fileToUpload = file;
    }
  }

  uploadFile() {
    this.accountStatementFileProcessingService
      .uploadFile(this.fileToUpload, this.selectedItem)
      .subscribe(
        (data) => {
          this.notificationService.dismissMessage();
          this.notificationService.showToast(
            'NOTIFICATION.UPLOAD-SUCCESS',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
        },
        (err) => this.handleError(err)
      );
  }

  handleError(error) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: error.error ? error.error.errorId : 'unknown',
        error: error.name,
      }
    );
  }
}
