import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultTableComponent } from '../../shared/tables/default-table/default-table.component';
import { DocumentManagementService } from '../../shared/services/animal-welfare/document-management/document-management.service';
import { TablesService } from '../../shared/tables/tables.service';
import { exportPrintingTaskListColumns } from '../../in-memory-data/export-printing-task/table-columns';
import { exportPrintingTaskListColumnsConf } from '../../in-memory-data/export-printing-task/table-columns-configuration';
import { DashboardStateService } from '../accounting-management/services/dashboard-state/dashboard-state.service';

@Component({
  selector: 'app-exporting-printing-task',
  templateUrl: './exporting-printing-task.component.html',
  styleUrls: ['./exporting-printing-task.component.less'],
})
export class ExportingPrintingTaskComponent implements OnInit {
  configurationKey = 'exportPrintingDocuments';
  columns = exportPrintingTaskListColumns;
  columnConfiguration = exportPrintingTaskListColumnsConf;
  documentList = {};
  dataLoaded = false;
  entityName = 'export-printing-documents';
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  documentsInspected = [];
  totalCount: number;
  tableName = 'documentOutput';

  filterObject = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    dateFormat: '',
  };

  constructor(
    private documentManagementService: DocumentManagementService,
    private tableService: TablesService,
    private stateService: DashboardStateService
  ) {
    this.tableService.customEventSubscriptionEmitter =
      this.tableService.customEvent.subscribe((data) =>
        this.downloadFile(data)
      );
  }

  ngOnInit(): void {
    this.loadTable();
  }

  loadTable() {
    const filterOptions = {
      ...this.stateService.configs[this.tableName],
      ...this.filterObject,
    };
    this.documentManagementService
      .findByPrintTaskId(filterOptions, window.history.state.task.guid)
      .subscribe((data) => {
        this.documentList = data;
        this.dataLoaded = true;
      });
  }

  private downloadFile(element: any) {
    this.documentManagementService
      .download(element.id, element.filename)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.download = element.filename;
        anchor.href = url;
        anchor.click();
        if (!this.documentsInspected.includes(element.filename)) {
          this.documentsInspected.push(element.filename);
        }
      });
  }

  filter(event) {
    if (event.sortOrder) {
      this.filterObject.sortDirection = event.sortOrder;
    }
    this.filterObject = { ...this.filterObject, ...event };
    this.loadTable();
  }
}
