export const transactionTypesObjection: any[] = [
  {
    name: 'COMMENT',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.COMMENT',
  },
  {
    name: 'ATTACHMENT',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.ATTACHMENT',
  },
];
