import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { quantityGoodsDraftColumns } from 'src/app/in-memory-data/animal-welfare/quantity-goods-draft/table-columns';
import { quantityGoodsDraftColumnsConf } from 'src/app/in-memory-data/animal-welfare/quantity-goods-draft/table-columns-configuration';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-quantity-goods-draft',
  templateUrl: './transfer-quantity-goods-draft.component.html',
  styleUrls: [
    './transfer-quantity-goods-draft.component.less',
    './transfer-quantity-goods-draft-md.component.less',
  ],
})
export class TransferQuantityGoodsDraftComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'quantity-goods';

  periods: any[] = [];

  private CONFIGURAIONT_KEY = 'quantity_goods_draft_table';
  private ENTITY_TYPE = 'QUANTITY_GOOD';

  columns = quantityGoodsDraftColumnsConf;
  displayedColumns = quantityGoodsDraftColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;
  existentGoodsGroups: any;
  dateFormat: any;
  filterForm: UntypedFormGroup;
  searchableDrafts = [];

  constructor(
    private draftService: DraftService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private matDialogService: MatDialog
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
    this.getTableConfiguration();
    this.loadTable();
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.searchDraft());
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchTerm: null,
    });
  }

  loadTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.draftService
      .retrieveLocalDrafts(this.ENTITY_TYPE)
      .subscribe((data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);

            draft.quantityGoods = object;
            draft.draft = true;
            draft.draftId = draft.id;
            draft.draftGuid = draft.guid;

            this.addObjectToDraftTable(draft);
            this.searchableDrafts.push(draft);
          });
        }
      });
  }

  addObjectToDraftTable(object) {
    this.dataSource.data.push(object);
    this.dataSource.filter = '';
  }

  confirmationDeleteDraft(draftGuid) {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.CONFIRM-DELETE-ENTRY';
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.deleteDraft(draftGuid);
      }
    });
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe((draft) => {
      if (draft == null) {
        const index = this.dataSource.data.findIndex(
          (data) => data.draftGuid === draftGuid
        );
        this.dataSource.data.splice(index, 1);
        this.dataSource.filter = '';

        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );

        this.selection = new SelectionModel(true, []);
      }
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  editSelectedRows(element) {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
      state: { draft: true, draftObject: element },
    });
  }

  searchDraft() {
    if (this.filterForm.get('searchTerm').value) {
      this.searchService.search(
        this.searchableDrafts,
        this.filterForm.get('searchTerm').value,
        this.dataSource
      );
    }
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                quantityGoodsDraftColumnsConf,
                quantityGoodsDraftColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = quantityGoodsDraftColumnsConf;
    this.displayedColumns = quantityGoodsDraftColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
