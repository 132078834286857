export const classificationsSelectionEnum: any[] = [
  {
    name: 'STATUS_BEFORE_CLASSIFICATION',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.STATUS-BEFORE-CLASSIFICATION',
    enable: true,
  },
  {
    name: 'AUTOMATIC_CLASSIFICATION',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.AUTOMATIC-CLASSIFICATION',
    enable: true,
  },
  {
    name: 'MANUAL_CLASSIFICATION_WITH_TCI',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.MANUAL-CLASSIFICATION-WITH-TCI',
    enable: false,
  },
  {
    name: 'MANUAL_CLASSIFICATION_WITHOUT_TCI',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.MANUAL-CLASSIFICATION-WITHOUT-TCI',
    enable: false,
  },
  {
    name: 'MANUAL_CLASSIFICATION_WITH_TCI_SILO',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.MANUAL-CLASSIFICATION-WITH-TCI-SILO',
    enable: false,
  },
  {
    name: 'MANUAL_CLASSIFICATION_WITHOUT_TCI_SILO',
    translateName:
      'TRANSACTION.CLASSIFICATION-SELECTION.MANUAL-CLASSIFICATION-WITHOUT-TCI-SILO',
    enable: false,
  },
  {
    name: 'NO_CLASSIFICATION',
    translateName: 'TRANSACTION.CLASSIFICATION-SELECTION.NO-CLASSIFICATION',
    enable: false,
  },
];
