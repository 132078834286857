import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.less'],
})
export class SimpleInputComponent {
  @Input() control: UntypedFormControl;
  @Input() label = '';
  @Input() disabled = false;
  @Input() appearance = 'fill';
  @Input() classes = '';
  @Input() type = 'text';
  @Input() mask: string;
  @Input() suffix: string;
  @Input() thousandSeparator: string;
  @Input() decimalMarker: string;
  constructor(private translateService: TranslateService) {}

  getErrorMessage() {
    if (this.control.errors && Object.keys(this.control.errors).length > 0) {
      // Only one error is displayed at a time in the UI.
      const firstError = Object.keys(this.control.errors)[0];
      switch (firstError) {
        case 'required':
          return this.translateService.instant('ERROR-MESSAGES.REQUIRED', {
            name: this.translateService.instant(this.label),
          });
        case 'incorrect':
          return this.translateService.instant(
            'ERROR-MESSAGES.INCORRECT-VALUE',
            { name: this.translateService.instant(this.label) }
          );
        default:
          break;
      }
    }
  }
}
