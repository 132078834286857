export const bookingCodeColumnsConf: any[] = [
  {
    name: 'bookingCodeId',
    index: 0,
    checked: true,
    translateName: 'BOOKING-CODE.BOOKING-CODE-ID',
    locked: true,
  },
  {
    name: 'defaultBookingCodeName',
    index: 1,
    checked: true,
    translateName: 'BOOKING-CODE.DEFAULT-BOOKING-CODE-NAME',
    locked: true,
  },
  {
    name: 'description',
    index: 2,
    checked: true,
    translateName: 'BOOKING-CODE.DESCRIPTION',
    locked: true,
  },
  {
    name: 'transactionType',
    index: 3,
    checked: true,
    translateName: 'BOOKING-CODE.TRANSACTION-TYPE',
    locked: true,
  },
  {
    name: 'defaultNameEnglish',
    index: 4,
    checked: true,
    translateName: 'BOOKING-CODE.DEFAULT-NAME-ENGLISH',
    locked: false,
  },
  {
    name: 'descriptionEnglish',
    index: 5,
    checked: true,
    translateName: 'BOOKING-CODE.DESCRIPTION-ENGLISH',
    locked: false,
  },
  {
    name: 'transferTransactionCode',
    index: 6,
    checked: false,
    translateName: 'BOOKING-CODE.TRANSFER-TRANSACTION-CODE',
    locked: false,
  },
  {
    name: 'standard',
    index: 7,
    checked: false,
    translateName: 'BOOKING-CODE.STANDARD',
    locked: false,
  },
  {
    name: 'cancellation',
    index: 8,
    checked: false,
    translateName: 'BOOKING-CODE.CANCELLATION',
    locked: false,
  },
  {
    name: 'sapBalanceSheetAccount',
    index: 9,
    checked: false,
    translateName: 'BOOKING-CODE.SAP-BALANCE-SHEET-ACCOUNT',
    locked: false,
  },
  {
    name: 'sapPAndLAccount',
    index: 10,
    checked: false,
    translateName: 'BOOKING-CODE.SAP-P-AND-L-ACCOUNT',
    locked: false,
  },
  {
    name: 'objectionAccountBeVision',
    index: 11,
    checked: false,
    translateName: 'BOOKING-CODE.OBJECTION-ACCOUNT-BE-VISION',
    locked: false,
  },
  {
    name: 'bookingOption',
    index: 12,
    checked: false,
    translateName: 'BOOKING-CODE.BOOKING-OPTION',
    locked: false,
  },
  {
    name: 'isDueDate',
    index: 13,
    checked: false,
    translateName: 'BOOKING-CODE.IS-DUE-DATE',
    locked: false,
  },
  {
    name: 'relevantSales',
    index: 14,
    checked: false,
    translateName: 'BOOKING-CODE.RELEVANT-SALES',
    locked: false,
  },
  {
    name: 'generalLedgerAccount',
    index: 15,
    checked: false,
    translateName: 'BOOKING-CODE.GENERAL-LEDGER-ACCOUNT',
    locked: false,
  },
  {
    name: 'generalLedgerCounterAccount',
    index: 16,
    checked: false,
    translateName: 'BOOKING-CODE.GENERAL-LEDGER-COUNTER-ACCOUNT',
    locked: false,
  },
  {
    name: 'salesTaxAccount',
    index: 17,
    checked: false,
    translateName: 'BOOKING-CODE.SALES-TAX-ACCOUNT',
    locked: false,
  },
  {
    name: 'inHouseClearingItemCode',
    index: 18,
    checked: false,
    translateName: 'BOOKING-CODE.IN-HOUSE-CLEARING-ITEM-CODE',
    locked: false,
  },
  {
    name: 'displayExternally',
    index: 19,
    checked: false,
    translateName: 'BOOKING-CODE.DISPLAY-EXTERNALLY',
    locked: false,
  },
  {
    version: 2,
  },
];
