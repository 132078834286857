import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SortService } from '../../services/sort/sort-service';
import { SalesEntryService } from '../../services/animal-welfare/sales-entry/sales-entry.service';
import { LivestockProducerService } from '../../services/livestock-producer/livestock-producer.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-split-central-animal-welfare',
  templateUrl: './split-central-animal-welfare.component.html',
  styleUrls: ['./split-central-animal-welfare.component.less'],
})
export class SplitScreenCentralAnimalWelfareComponent {
  @Input() splitData;
  @Input() originalData;
  @Input() companyName;
  currentRow;
  company;

  constructor(
    private sortService: SortService,
    private router: Router,
    private salesEntryService: SalesEntryService,
    private livestockProducerService: LivestockProducerService
  ) {
    this.sortService.dataListInSplitScreen.subscribe(
      (dataList) => (this.splitData = dataList)
    );
  }

  getValueForRow(item) {
    return {
      id: item.id,
      name: item.type,
      productionType: item.productionType,
      locationNumber: item.locationNumber,
      level: item.level,
      qsId: item.qsId,
    };
  }

  goToRowDetail(row) {
    this.currentRow = row;
    this.company = row;
    if (row.type === 'FRT') {
      this.getFRT(row.companyGuid);
    }
    if (row.type === 'GASTRONOMY') {
      this.getGastronomy(row.companyGuid);
    }
    if (row.type === 'livestockProducer') {
      this.getLivestockProducer(row);
    }
  }

  getFRT(guid) {
    this.salesEntryService.findByCompany(guid).subscribe((frtList) => {
      const findFRTList = frtList['frtList'].find((item) => {
        return String(item.id) === String(this.currentRow.object.id);
      });

      if (findFRTList) {
        if (this.router.url === '/masterdata/salesEntry/create') {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/masterdata/salesEntry/create'], {
            state: {
              salesEntry: findFRTList,
              splitScreen: true,
              splitData: this.splitData,
              contractType: true,
              originalData: this.originalData,
              companyName: this.companyName,
              company: this.company,
            },
          });
        }
        this.router.navigateByUrl('/masterdata/salesEntry/create', {
          state: {
            salesEntry: findFRTList,
            splitScreen: true,
            splitData: this.splitData,
            contractType: true,
            originalData: this.originalData,
            companyName: this.companyName,
            company: this.company,
          },
        });
      }
    });
  }

  getGastronomy(guid) {
    this.salesEntryService.findByCompany(guid).subscribe((gastronomyList) => {
      const findGastronomyList = gastronomyList['gastronomyList'].find(
        (item) => {
          return String(item.id) === String(this.currentRow.object.id);
        }
      );

      if (findGastronomyList) {
        if (this.router.url === '/masterdata/salesEntry/create') {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/masterdata/salesEntry/create'], {
            state: {
              salesEntry: findGastronomyList,
              splitScreen: true,
              splitData: this.splitData,
              contractType: true,
              originalData: this.originalData,
              companyName: this.companyName,
              company: this.company,
            },
          });
        }
        this.router.navigateByUrl('/masterdata/salesEntry/create', {
          state: {
            salesEntry: findGastronomyList,
            splitScreen: true,
            splitData: this.splitData,
            contractType: true,
            originalData: this.originalData,
            companyName: this.companyName,
            company: this.company,
          },
        });
      }
    });
  }

  getLivestockProducer(livestockProducer) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.livestockProducerService
      .getLivestockProducerSearch(livestockProducer.id)
      .pipe(first())
      .subscribe((livestockProducerResponse) => {
        this.router.navigate(['/livestockProducer/overview'], {
          state: {
            livestockProducer: livestockProducerResponse,
            splitScreen: true,
            splitData: this.splitData,
            originalData: this.originalData,
            companyName: this.companyName,
          },
        });
      });
  }
}
