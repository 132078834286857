export enum TaskEventType {
  CREATE,
  EDIT,
  FORWARD,
  ADD_ATTACHMENT,
  CREATE_COMMENT,
  COMPLETE_TASK,
  DELETE_ATTACHMENT,
  MODIFY,
}
