export const quantityLabeledGoodsColumnsConf: any[] = [
  {
    name: 'purchaserId',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-ID',
    locked: true,
  },
  {
    name: 'purchaserName',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PURCHASER-NAME',
    locked: true,
  },
  {
    name: 'supplierId',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-ID',
    locked: true,
  },
  {
    name: 'supplierName',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.SUPPLIER-NAME',
    locked: true,
  },
  {
    name: 'goodsGroup',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.GOODS-GROUP',
    locked: true,
  },

  {
    name: 'type',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TYPE',
    locked: true,
  },
  {
    name: 'quantity',
    index: 6,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.QUANTITY',
    locked: true,
    number: true,
  },
  {
    name: 'comment',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.COMMENT',
    locked: true,
  },
  {
    name: 'reportingPeriod',
    index: 8,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD',
    locked: true,
  },
  {
    name: 'createdBy',
    index: 9,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-BY',
    locked: true,
  },
  {
    name: 'createdOn',
    index: 10,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CREATED-ON',
    locked: true,
  },
  {
    name: 'approvedBy',
    index: 11,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.APPROVED-BY',
    locked: true,
  },
  {
    name: 'approvedOn',
    index: 12,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.APPROVED-ON',
    locked: true,
  },
  {
    version: '1.3',
  },
];
