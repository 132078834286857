import { Component, OnInit, ViewChild } from '@angular/core';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { SalesEntryContractInformationComponent } from '../sales-entry-contract-information/sales-entry-contract-information.component';
import { DraftService } from '../../../shared/services/draft/draft.service';
import { SalesEntryService } from '../../../shared/services/animal-welfare/sales-entry/sales-entry.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';
import { Observable, of } from 'rxjs';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-entry-create',
  templateUrl: './sales-entry-create.component.html',
  styleUrls: ['./sales-entry-create.component.less'],
})
export class SalesEntryCreateComponent implements OnInit {
  @ViewChild(SalesEntryContractInformationComponent)
  salesEntryContractInformationComponent: SalesEntryContractInformationComponent;

  entityName = 'company';
  errorRequired = 'error-message-required';

  edit = false;
  readOnly = false;
  editableTask = false;
  disableContractInfo = false;
  save = true;
  companyApproved: any;
  contract;
  isTask = false;
  salesEntryApproved;
  salesEntryTask;
  entitiesLoaded: any;
  approval = false;
  displaySplit = false;
  showSpecialNotification = false;
  state;
  validForm;
  splitData;
  originalData;
  companyName;
  draftExists = false;

  countries: ICountry[] = [];
  countries$: Observable<ICountry[]>;

  constructor(
    private taskService: TaskService,
    private router: Router,
    private companyService: CompanyService,
    private draftService: DraftService,
    private salesEntryService: SalesEntryService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    protected countryPicker: CountryPickerService,
    private liquidityPlanService: LiquidityPlanService,
    private kcService: KeycloakService
  ) {
    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );
  }

  ngOnInit(): void {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.countries$ = of(countries);
      this.countries = countries;
    });

    this.state = window.history.state;
    if (this.state.splitScreen) {
      this.displaySplit = true;
      this.splitData = this.state.splitData;
      this.originalData = this.state.originalData;
      this.companyName = this.state.companyName;
    }
    if (this.state.task) {
      this.edit = true;
      this.isTask = true;
      this.retrieveByDraft(this.state.task);
      if (
        this.state.task.type === 'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR'
      ) {
        this.disableContractInfo = true;
      }
      if (sessionStorage.getItem('taskModify') === 'true') {
        this.taskService.updateEditableTask(true);
      }
    } else if (this.state.salesEntry) {
      this.edit = true;
      this.readOnly = !this.kcService.isUserInRole('aw.s.u');
      this.salesEntryApproved = Object.assign({}, this.state.salesEntry);
      this.validateDraftExistence();
      this.getCompanyData(this.state.salesEntry.companyGuid);
    } else if (this.state.company) {
      const company = this.state.company;
      if (company.companyGuid) {
        this.getCompanyData(company.companyGuid);
      } else {
        this.getCompanyData(company.guid);
      }
    } else if (this.router.url.includes('operatingCompany')) {
      this.liquidityPlanService.getOperator().subscribe((operator) => {
        if (operator && operator.id == null) {
          this.showSpecialNotification = true;
        } else {
          this.getCompanyData(operator.companyGuid);
        }
      });
      this.salesEntryApproved = {};
      this.salesEntryTask = {};
      this.disableContractInfo = true;
    } else {
      this.router.navigateByUrl('salesEntry');
    }
    setTimeout(() => {
      if (!this.router.url.includes('operator') && !this.state.task) {
        if (
          this.salesEntryContractInformationComponent &&
          this.salesEntryContractInformationComponent.contractInformationForm
        ) {
          this.validForm =
            this.salesEntryContractInformationComponent.contractInformationForm;
        }
      } else if (
        this.state.task &&
        this.state.task.type !== 'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR'
      ) {
        this.validForm =
          this.salesEntryContractInformationComponent?.contractInformationForm; // avoid undefined errors with ? check
      }
    }, 1000);
  }

  private getCompanyData(companyGuid: any) {
    this.companyService.getCurrentApproved(companyGuid).subscribe(
      (data) => {
        this.companyApproved = data;
        this.entitiesLoaded = true;
      },
      (err) => {
        console.log('ERROR FINDING'), (this.entitiesLoaded = true);
      }
    );
  }

  retrieveByDraft(task: any) {
    if (!environment.isInternal) {
      return;
    }

    this.draftService.retrieveDraftForTask(task.guid).subscribe((data) => {
      this.salesEntryTask = data;
      this.getCompanyData(data.companyGuid);
    });
  }

  canDeactivate() {
    if (
      this.salesEntryContractInformationComponent &&
      this.salesEntryContractInformationComponent.contractInformationForm
    ) {
      return (
        this.salesEntryContractInformationComponent.contractInformationForm
          .dirty && !this.approval
      );
    } else {
      return false;
    }
  }

  onClickSave() {
    const salesEntry =
      this.salesEntryContractInformationComponent.contractInformationForm.value;
    salesEntry.billingRate = salesEntry?.billingRate?.toString().replace(/[,]/g, '.');
    if (
      salesEntry.billingRate &&
      salesEntry.billingRate.slice(salesEntry.billingRate.length - 1) === '.'
    ) {
      salesEntry.billingRate = salesEntry.billingRate.replace(/[.]/g, '');
    }

    salesEntry.companyGuid = this.companyApproved.guid;
    salesEntry.type = salesEntry.type.toUpperCase();
    salesEntry.contractDate = this.formatDate(salesEntry.contractDate);
    salesEntry.contractDateCattle = this.formatDate(
      salesEntry.contractDateCattle
    );
    if (this.isTask) {
      this.saveApprovalTask(salesEntry, this.state.task.guid);
    } else {
      this.saveApprovalTask(salesEntry, null);
    }
  }

  formatDate(dateP) {
    if (!!dateP) {
      const date = new Date(dateP);
      const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1; // convert to positive value.
      date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);
      return date;
    }
    return null;
  }

  private saveApprovalTask(salesEntry: any, taskId: string) {
    this.salesEntryService
      .saveApprovalTask(salesEntry, this.companyApproved.name, taskId)
      .subscribe((data) => {
        this.approval = true;
        this.notificationService.showToast(
          'NOTIFICATION.CREATED',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ENTITIES.ANIMAL-WELFARE.SALES-ENTRY'
            ),
          }
        );

        setTimeout(() => {
          this.router.navigateByUrl('/tasks', {
            state: { success: true },
          });
        }, 1500);
      });
  }

  changeEditable(value: boolean) {
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.router.navigateByUrl(
        this.removeLastDirectoryPart(location.pathname)
      );
      this.taskService.updateEditableTask(value);
    } else {
      this.taskService.updateEditableTask(value);
    }
  }

  removeLastDirectoryPart(url) {
    const urlArr = url.split('/');
    urlArr.pop();
    return urlArr.join('/');
  }

  validateDraftExistence() {
    if (!this.edit || this.isTask || !environment.isInternal) {
      return;
    }

    this.draftService
      .approvalDraftExistsForEntityId(this.salesEntryApproved.id)
      .subscribe((exists: boolean) => {
        this.draftExists = exists;
        if (this.draftExists) {
          this.salesEntryContractInformationComponent.contractInformationForm.disable(
            { onlySelf: true, emitEvent: false }
          );
          this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        }
      });
  }

  getCountry(isoCode) {
    if (isoCode && this.countries && this.countries.length > 0) {
      const country = this.countries.filter(
        (count) => count.cca3 === isoCode
      )[0];
      if (this.translateService.currentLang === 'de') {
        return country.translations.deu.common;
      }
      return country.name.common;
    } else {
      return '';
    }
  }

  getLanguage(langCode) {
    return this.translateService.instant('LANGUAGES.' + langCode);
  }
}
