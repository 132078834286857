import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CompanyService } from '../../services/company/company.service';
import { PlatformService } from '../../services/platform/platform.service';

@Component({
  selector: 'app-company-platform-configuration-modal',
  templateUrl: './company-platform-configuration-modal.component.html',
  styleUrls: ['./company-platform-configuration-modal.component.less'],
})
export class CompanyPlatformConfigurationModalComponent implements OnInit {
  entityName: 'company-platform-configuration-modal';
  configurationForm: UntypedFormGroup;
  platforms = [];
  platformOptions = [];
  state;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private platformService: PlatformService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompanyPlatformConfigurationModalComponent>
  ) {}

  ngOnInit() {
    this.configurationFormBuilder();
    this.state = window.history.state;
    this.getPlatforms();
    this.getValues(this.data.row);
  }

  configurationFormBuilder() {
    this.configurationForm = this.formBuilder.group({
      platformId: ['', Validators.required],
      clientDebtorId: ['', Validators.required],
    });
  }

  getValues(data) {
    this.configurationForm.get('platformId').patchValue(data.platformId);
    this.configurationForm
      .get('clientDebtorId')
      .patchValue(data.clientDebtorId);
  }

  getPlatforms() {
    this.platformService
      .listApproved(null, 'id', 'asc', 1, 99999)
      .subscribe((platformList) => {
        this.platforms = platformList.content;
        this.companyService
          .getPlatformConfigurationList(this.state.company.guid)
          .subscribe((list: any) => {
            const configList = list;
            configList.forEach((element) => {
              const platform = this.platforms.find(
                (plat) =>
                  element.platformId === plat.id &&
                  element.platformId !== this.data.row.platformId
              );
              if (platform) {
                this.platforms = this.platforms.filter(
                  (plat) => plat.id !== platform.id
                );
              }
            });
          });
      });
  }

  save() {
    this.dialogRef.close({
      newValue: this.configurationForm.value,
      originalValue: this.data.row,
    });
  }
}
