import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-livestock-producer-overview',
  templateUrl: './livestock-producer-overview.component.html',
  styleUrls: ['./livestock-producer-overview.component.less'],
})
export class LivestockProducerOverviewComponent implements OnInit {
  state: any;

  livestockProducer: any;
  livestockProducerId: number;

  entityName = 'livestock-producer-overview';
  displaySplit = false;

  splitData: any;
  originalData: any;
  companyTitle: any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.state = window.history.state;

    if (this.state && this.state.livestockProducer) {
      this.livestockProducerId = this.state.livestockProducer.id;

      this.livestockProducer = this.state.livestockProducer;
      this.livestockProducer.company = {
        formattedAddress: this.livestockProducer.address,
        mainContactPerson: {
          mobileNumber: this.livestockProducer.telephoneNumber,
          faxNumber: this.livestockProducer.fax,
          email: this.livestockProducer.email,
          title: this.livestockProducer.contact_person_title,
          name: this.livestockProducer.contact_person_name,
          surname: this.livestockProducer.contact_person_surname,
        },
        mainBankAccount: {
          iban: this.livestockProducer.iban,
          bic: this.livestockProducer.bic,
        },
      };
    } else {
      this.router.navigateByUrl('/livestockProducer');
    }

    if (this.state.splitScreen) {
      this.displaySplit = true;
    }

    this.splitData = this.state.splitData;
    this.originalData = this.state.originalData;
    this.companyTitle = this.state.companyName;
  }
}
