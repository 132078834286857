<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field class="white-back">
        <mat-label>{{ 'SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
      <button mat-button class="custom-button search-button" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <mat-menu #appMenu="matMenu" (closed)="tableChanged()" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        id="{{ entityName }}-search-results-table"
        class="general-table-alignment"
        aria-hidden="true"
      >
        <ng-container matColumnDef="account_number">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th number-row-header-sortable"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-account-number"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.ACCOUNT-NUMBER' | translate }}
            <app-table-menu-sort-filter
              choice="account_number"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [sortTable]="sortTable"
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-account-number-{{ paginator.pageIndex + 1 }}-{{ i }}"
            class="number-row"
          >
            {{ element.accountNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="locationNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th number-row-header-sortable"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-location-number"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.LOCATIONNUMBER' | translate }}
            <app-table-menu-sort-filter
              choice="location_number"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [sortTable]="sortTable"
              [callback]="filterData"
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-location-number-{{ paginator.pageIndex + 1 }}-{{ i }}"
            class="number-row"
          >
            {{ element.locationNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="typeOfProduction">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th number-row-header-sortable"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-production-type"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.TYPE-OF-PRODUCTION' | translate }}
            <app-table-menu-sort-filter
              choice="production_type"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [sortTable]="sortTable"
              [callback]="filterData"
              [getDistinctValues]="getFieldNames"
            >
            </app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-production-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
            class="number-row"
          >
            {{ element.productionType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="centralMasterDataRecord">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-company"
          >
            {{ 'MASTER-DATA.COMPANY' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-company-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.companyName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-name"
          >
            {{ 'NAME' | translate }}
            <app-table-menu-sort-filter
              choice="location_name"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [callback]="filterData"
              [sortTable]="sortTable"
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.locationName | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="loader">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="general-loader-table" *ngIf="loaderSpinner">
              <mat-spinner [diameter]="50"></mat-spinner>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
              {{ 'ERROR.NO-DATA-FOUND' | translate }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [hidden]="loaderSpinner"
          class="element-row"
          (click)="goToOverview(row)"
        ></tr>
        <tr
          [hidden]="!loaderSpinner"
          mat-footer-row
          *matFooterRowDef="['loader']"
          class="footer-spinner height-footer"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalCount"
      (page)="paginate($event)"
    ></mat-paginator>
  </div>
</div>
