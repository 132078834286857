import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.less'],
})
export class CustomSnackBarComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
  showRedirectButton: true;

  ngOnInit(): void {
    if (this.data.showRedirectButton) {
      this.showRedirectButton = this.data.showRedirectButton;
    }
  }

  redirectToUserDraft() {
    this.snackBarRef.dismissWithAction();
  }
}
