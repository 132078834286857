import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-mat-select-multiple',
  templateUrl: './mat-select-multiple.component.html',
  styleUrls: ['./mat-select-multiple.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatSelectMultipleComponent implements OnInit {
  @Input() name;
  @Input() placeholder;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() formGroupCustomName;
  @Input() options;
  @Input() optionValue;
  @Input() selectedOption;
  @Input() translationOption;
  @Input() optionTranslationText;
  @Input() changeFunction;
  @Input() taskShowMultipleAttributes;
  @Input() taskOptionsValue;
  @Input() compareApprovedAttribute;
  @Input() compareTaskAttribute;
  @Input() appearance;
  @Input() disabled;
  @Input() titleCaseEnabled;
  @Input() selectAll;

  filteredOptions;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  errorRequired = 'error-message-required';
  newValue: any;
  nestedForm: any;
  formGroup: any;

  parentF: UntypedFormGroup;
  requiredValidator = false;
  changedValue = false;

  @ViewChild('allSelected') public allSelected: MatOption;

  constructor(private parentForm: FormGroupDirective) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    this.parentF = this.parentForm.form;

    if (this.parentForm.form.controls[this.formGroupCustomName]) {
      this.formGroup = this.parentForm.form.controls[this.formGroupCustomName];
    } else {
      this.formGroup = this.parentForm.form;
    }

    if (this.controlObject && this.controlObject.validator) {
      const validator = this.controlObject.validator({} as AbstractControl);
      if (validator) {
        this.requiredValidator = validator.required;
      }
    }

    this.changedValue = this.hasChanged(this.controlName);
  }

  hasChanged(fieldName: string) {
    let oldValue;
    if (this.approvedValue) {
      oldValue = this.approvedValue;
    } else if (this.taskValue) {
      oldValue = this.taskValue;
    }
    if (oldValue && this.newValue) {
      if (this.formGroupCustomName) {
        if (!this.newValue[this.formGroupCustomName][fieldName]) {
          return false;
        }
        return oldValue !== this.newValue[this.formGroupCustomName][fieldName];
      } else {
        if (!this.newValue[fieldName]) {
          return false;
        } else {
          if (oldValue.length !== this.newValue[fieldName].length) {
            return true;
          } else {
            if (oldValue.length > 0) {
              let exist = true;

              this.newValue[fieldName].forEach((element) => {
                if (exist === true) {
                  const data = oldValue.find(
                    (ob) =>
                      ob[this.compareApprovedAttribute] ===
                      element[this.compareTaskAttribute]
                  );
                  if (data == null) {
                    exist = false;
                  }
                }
              });

              return !exist;
            }
          }
        }
      }
    } else {
      return false;
    }
  }

  getItem(item) {
    let result;
    if (item) {
      Object.keys(item).forEach((key, index) => {
        if (key === this.translationOption) {
          result = item[key];
        }
      });
      if (result) {
        return result;
      } else {
        if (this.translationOption) {
          return this.options.find(
            (data) => data[this.taskOptionsValue] === item
          )[this.translationOption];
        } else {
          return item;
        }
      }
    }
  }

  getItemValue(item) {
    let result;

    Object.keys(item).forEach((key, index) => {
      if (key === this.optionValue) {
        result = item[key];
      }
    });

    if (result) {
      return result;
    } else {
      if (item.id) {
        return item.id;
      } else {
        return item;
      }
    }
  }

  getTaskOptionValue(item) {
    let result;
    Object.keys(item).forEach((key, index) => {
      if (key === this.taskOptionsValue) {
        result = item[key];
      }
    });
    if (result) {
      return result;
    } else {
      return item;
    }
  }

  change($event) {
    if (this.changeFunction) {
      this.changeFunction($event);
    }
  }

  openChange($event: boolean) {
    if ($event && this.cdkVirtualScrollViewPort) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }

  showRemoveOption() {
    let result = false;
    if (
      this.form.value[this.controlName] &&
      this.form.value[this.controlName].length > 0 &&
      !this.form.get(this.controlName).disabled
    ) {
      result = true;
    }
    return result;
  }

  clearOptions() {
    this.form.value[this.controlName] = [];
    this.form.get(this.controlName).patchValue([]);
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.formGroup.controls[this.controlName].patchValue([
        ...this.options.map((item) => item.name),
        0,
      ]);
    } else {
      this.formGroup.controls[this.controlName].patchValue([]);
    }
  }

  togglePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (
      this.formGroup.controls[this.controlName].value.length ===
      this.options.length
    ) {
      this.allSelected.select();
    }
  }
}
