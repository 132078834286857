import { OpenItem } from 'src/app/shared/models/open-item';
import { Deduction } from 'src/app/shared/models/deduction';
import { DunningInfo } from 'src/app/shared/models/dunning-info';

export const openItemEmptyObject: OpenItem[] = [
  {
    clientDebtorId: '',
    clientOpenItemId: '',
    openItemDate: new Date(),
    bookingCode: 0,
    debitOrCredit: 0,
    amount: 0,
    currency: '',
    vatAmount: 0,
    vatPercent: 0,
    vatCode: 0,
    dueDate: new Date(),
    referenceClientsOpenItemId: '',
    additionalInformation: '',
    branchId: '',
    payment: {
      iban: '',
      bic: '',
      accountHolder: '',
      paymentReference: '',
      paymentScheme: '',
      paymentDate: new Date(),
    },
    debtCollection: 0,
    debtCollectionTransferDate: new Date(),
    objectionDate: new Date(),
    objectionCode: 0,
    objectionAmount: 0,
    deductions: new Array<Deduction>(),
    dunningInfo: new Array<DunningInfo>(),
    settlementInEUR: 0,
    financedAmount: 0,
    nonFinancedAmount: 0,
  },
];
