export const typeOptions: any[] = [
  {
    value: 0,
    translateName: 'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL.0',
  },
  {
    value: 1,
    translateName: 'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL.1',
  },
  {
    value: 2,
    translateName: 'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL.2',
  },
  {
    value: 3,
    translateName: 'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL.3',
  },
  {
    value: 4,
    translateName: 'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL.4',
  },
];
