import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { documentManagementTableColumnsConf } from 'src/app/in-memory-data/animal-welfare/document-management/table-columns-configuration';
import { documentManagementTableColumns } from 'src/app/in-memory-data/animal-welfare/document-management/table-columns';
import { DocumentManagementService } from 'src/app/shared/services/animal-welfare/document-management/document-management.service';
import { DocumentHistorySelectionModalComponent } from 'src/app/shared/modals/document-history-selection-modal/document-history-selection-modal.component';
import { DismissChangesDialogComponent } from 'src/app/shared/dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { DocumentManagementFile } from 'src/app/shared/models/document-management';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import moment from 'moment';

@Component({
  selector: 'app-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.less'],
})
export class DocumentManagementComponent implements OnInit, AfterViewInit {
  entityName = 'document-management';
  documentManagementData = {};
  columns = documentManagementTableColumnsConf;
  displayedColumns = documentManagementTableColumns;
  configurationKey = 'document_management_table';
  documents: DocumentManagementFile[];
  entities: [] = [];
  loaderSpinner = true;
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  totalCount;
  subject = new Subject();
  sharedSelectedRow: any[] = [];
  filterForm: UntypedFormGroup;
  pageSize = 20;
  searchQsId: any = '';
  searchVvvoNr: any = '';
  searchDocumentType: any;
  searchTypeOfProduction: any;
  searchAccountNr: any = '';
  searchDocumentationId: any = '';
  searchName: any = '';
  searchDocumentName: any = '';
  documentTypes: string[];
  typeOfProductions: string[];
  creationTimestampRange: any;
  newData = [];
  isExternalUser: boolean;
  configs = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
  };
  listOfActionButtons: TableActionButtonsConfiguration[] = [
    {
      name: 'downloadAttachment',
      permission: 'dm.r',
      icon: '',
      translationKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.DISPLAY',
      function: (param) => {
        this.downloadAttachment(param);
      },
      selectionType: DefaultTableSelectionType.single,
      specialDisable: (document) => {
        return !document.downloaded;
      },
    },
    {
      name: 'displayDocumentHistory',
      permission: 'dm.c',
      icon: '',
      translationKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.REPRINT',
      function: (param) => {
        this.displayDocumentHistory(param);
      },
      selectionType: DefaultTableSelectionType.single,
      specialDisable: () => {
        return false;
      },
    },
    {
      name: 'hideDocument',
      permission: 'dm.u',
      icon: '',
      translationKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.HIDE',
      function: () => {
        this.hideDocument();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: (element) => {
        return !this.isDocumentVisible(element);
      },
    },
    {
      name: 'unhideDocument',
      permission: 'dm.u',
      icon: '',
      translationKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.UNDO-HIDE',
      function: () => {
        this.unhideDocument();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: (element) => {
        return this.isDocumentVisible(element);
      },
    },
  ];

  visibilityOptions = [
    {value: 1, translateKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY.VISIBLE'},
    {value: 0, translateKey: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY.HIDDEN'},
  ];
  hiddenDocumentsConfiguration = {
    label: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY.TOTAL_OF_HIDDEN_DOCUMENTS',
    disabled: true,
    type: 'number',
    total: new UntypedFormControl(0),
    classes: 'mat-form-field-appearance-fill mat-form-field-flex'
  }


  constructor(
    private documentManagementService: DocumentManagementService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private kcService: KeycloakService,
    private tablesService: TablesService
  ) {
    this.filterFormBuilder();
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      qsid: ['', []],
      vvvoNumber: ['', []],
      documentType: ['', []],
      typeOfProduction: ['', []],
      accountNumber: ['', []],
      documentationId: ['', []],
      creationTime: new UntypedFormGroup({
        startDate: new UntypedFormControl(null, []),
        endDate: new UntypedFormControl(null, []),
        fieldName: new UntypedFormControl('creationTime'),
      }),
      name: ['', []],
      documentName: ['', []],
      visible: ['']
    });
  }

  ngOnInit(): void {
    this.getSelectedRow();
    this.isExternalUser = this.kcService.isUserInRole('ext.aw');
    this.documentManagementService
      .getFilterFieldNameValues('type')
      .subscribe((data) => {
        this.documentTypes = data;
      });
    this.typeOfProductions = ['2001', '2004', '2008', '3001', '3004'];
    this.filterForm.controls.creationTime.valueChanges.subscribe((value) => {
      if (value.end) {
        this.creationTimestampRange = value;
      }
    });
  }

  ngAfterViewInit() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loadDocuments();
    });
    this.loadDocuments();
  }

  filter(event) {
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }
    if (event.choice && event.direction) {
      this.configs['sortField'] = event.choice;
      this.configs['sortDirection'] = event.direction;
    }
    if (event.filteredValues) {
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
      if (event.choice) {
        this.configs.sortField = event.choice;
      }
    }
    this.loadDocuments();
  }

  getDistinctValues = (choice) => {
    return this.documentManagementService.getFilterParametersManagementFieldNameValues(
      choice
    );
  };

  getSelectedRow() {
    this.tablesService.currentSharedSelectedRows.subscribe((param) => {
      this.sharedSelectedRow = [];
      this.sharedSelectedRow = param;
    });
  }

  loadDocuments() {
    this.loaderSpinner = true;
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    const request = this.getRequestData();
    this.documentManagementService.findDocumentManagements(request).subscribe(
      (documents) => {
        this.hiddenDocumentsConfiguration.total.setValue(documents['totalHiddenDocuments']);
        this.totalCount = documents['total'];
        this.documentManagementData = documents;
        this.loaderSpinner = false;
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      },
      () => {
        this.loaderSpinner = false;
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      }
    );
  }

  getTypeOfProductionString(typeOfProductionInfo) {
    if (typeOfProductionInfo != null && typeOfProductionInfo !== '') {
      return typeOfProductionInfo;
    } else {
      return '';
    }
  }

  getDocumentTypeString(documentTypeInfo) {
    if (documentTypeInfo != null && documentTypeInfo !== '') {
      return (
        'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-TYPES.' +
        documentTypeInfo.replace(/_/g, '-').toUpperCase()
      );
    } else {
      return '';
    }
  }

  getFieldNames = (args): Observable<any> => {
    return this.documentManagementService.getFilterParametersManagementFieldNameValues(
      args
    );
  };

  getOutputFieldNames = (args): Observable<any> => {
    return this.documentManagementService.getFilterOutputManagementFieldNameValues(
      args
    );
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.documentManagementService.setColumnFilter(choice, selection);
    this.subject.next(undefined);
  };

  resetDocumentTypeFilter() {
    this.filterForm.value.documentType = undefined;
    this.filterForm.get('documentType').patchValue(undefined);
  }

  resetTypeOfProductFilter() {
    this.filterForm.value.typeOfProduction = undefined;
    this.filterForm.get('typeOfProduction').patchValue(undefined);
  }

  clearCreationTimestampFilter() {
    this.filterForm.controls.creationTime.get('startDate').reset();
    this.filterForm.controls.creationTime.get('endDate').reset();
    this.creationTimestampRange = undefined;
  }

  downloadAttachment(element) {
    this.documentManagementService
      .download(element.id, element.documentName)
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.download = element.documentName;
        anchor.href = url;
        anchor.click();
      });
  }

  resetVisibilityFilter() {
    this.filterForm.value.visible = '';
    this.filterForm.get('visible').patchValue('');
  }

  searchDocument() {
    this.loaderSpinner = true;
    const request = this.getRequestData(true);
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    this.documentManagementService
      .findDocumentManagements(request)
      .pipe(first())
      .subscribe((documents) => {
        this.hiddenDocumentsConfiguration.total.setValue(documents['totalHiddenDocuments']);
        this.totalCount = documents['total'];
        this.documentManagementData = documents;
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
        this.loaderSpinner = false;
      });
  }

  private getRequestData(searchTrigger?) {
    if (searchTrigger) {
      this.configs = {
        sortField: '',
        sortDirection: 'DESC',
        filterText: '',
        pageSize: '20',
        pageIndex: '0',
        filteredValues: [],
      };
    }
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };

    const form = Object.assign({}, this.filterForm.value);
    if (form.creationTime && form.creationTime.startDate != null) {
      form.creationTime.startDate = moment(form.creationTime.startDate)
        .toDate()
        .setHours(0, 0, 0, 0);
      form.creationTime.startDate = moment(form.creationTime.startDate).format(
        'YYYY-MM-DD'
      );
    }
    if (form.creationTime && form.creationTime.endDate != null) {
      form.creationTime.endDate = moment(form.creationTime.endDate)
        .toDate()
        .setHours(23, 59, 59);
      form.creationTime.endDate = moment(form.creationTime.endDate)
        .toDate()
        .setMilliseconds(0);

      form.creationTime.endDate = moment(form.creationTime.endDate).format(
        'YYYY-MM-DD'
      );
    }

    filterOptions['creationTime'] = JSON.stringify(form.creationTime);
    form.creationTime = null;
    filterOptions['searchFilters'] = JSON.stringify(form);
    return filterOptions;
  }

  displayDocumentHistory(element) {
    const dialog = this.matDialogService.open(
      DocumentHistorySelectionModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          recipientId: element.recipientId,
        },
      }
    );

    dialog.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        this.newData = [];
        result.forEach((row) => {
          this.newData.push(row);
        });
        this.confirmationForm(element.documentationId);
      }
    });
  }

  confirmationForm(documentationId) {
    this.matDialogService
      .open(DismissChangesDialogComponent, {
        panelClass: 'confirmation-reprint-popup',
        data: {
          title:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DIALOGS.REPRINT-CONFIRMATION.TITLE',
          message:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DIALOGS.REPRINT-CONFIRMATION.CONTENT',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.documents.forEach((element) => {
            if (element.documentationId === documentationId) {
              const request = this.newData[0];
              request.documentationId = documentationId;
              this.documentManagementService
                .editDocumentParameters(request)
                .subscribe(() => {
                  this.loadDocuments();
                  this.showSuccess();
                  this.tablesService.updateSharedSelectedRows(null);
                });
            }
          });
        }
      });
  }

  showSuccess() {
    this.notificationService.showToast(
      'REPRINT-DONE',
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }

  hideConfirmationForm() {
    this.matDialogService
      .open(DismissChangesDialogComponent, {
        panelClass: 'confirmation-reprint-popup',
        data: {
          title:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.DIALOGS.HIDE-CONFIRMATION.TITLE',
          message:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.DIALOGS.HIDE-CONFIRMATION.CONTENT',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const documentIds = this.sharedSelectedRow.map((a) => a.id);
          const request = {
            ids: documentIds,
          };

          this.documentManagementService
            .hideDocuments(request)
            .subscribe(() => {
              this.loadDocuments();
              this.hideShowSuccess();
              this.tablesService.updateSharedSelectedRows(null);
            });
        }
      });
  }

  hideShowSuccess() {
    this.notificationService.showToast(
      'HIDE-DONE',
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }

  unhideConfirmationForm() {
    this.matDialogService
      .open(DismissChangesDialogComponent, {
        panelClass: 'confirmation-reprint-popup',
        data: {
          title:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.DIALOGS.UNHIDE-CONFIRMATION.TITLE',
          message:
            'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.BUTTON.DIALOGS.UNHIDE-CONFIRMATION.CONTENT',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const documentIds = this.sharedSelectedRow.map((a) => a.id);
          const request = {
            ids: documentIds,
          };
          this.documentManagementService
            .unhideDocuments(request)
            .subscribe(() => {
              this.loadDocuments();
              this.unhideShowSuccess();
              this.tablesService.updateSharedSelectedRows(null);
            });
        }
      });
  }

  unhideShowSuccess() {
    this.notificationService.showToast(
      'UNHIDE-DONE',
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }

  hideDocument() {
    this.hideConfirmationForm();
  }

  unhideDocument() {
    this.unhideConfirmationForm();
  }

  isDocumentVisible(element) {
    return element?.visible === true;
  }
}
