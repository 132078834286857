<section class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <div class="input-wrapper form-container">
      <div class="non-editable-info-container">
        <div class="object-title"></div>
        <div class="multiple-objects-row">
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.REQUEST-NAME' | translate }}:
            </span>
            <span class="object-value">{{ awFile.filename }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.CREATION-DATE' | translate }}:
            </span>
            <span class="object-value">{{ awFile.creationDate | date: dateFormat }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.TRANSACTION-ID' | translate }}:
            </span>
            <span class="object-value">{{ awFile.transactionId }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{
                'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.IMPORT-TIMESTAMP' | translate
              }}:
            </span>
            <span class="object-value">{{ awFile.importDate | date: dateFormat + ' HH:mm:ss' }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{
                'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.PROCESSING-TIMESTAMP' | translate
              }}:
            </span>
            <span class="object-value">{{ awFile.processingDate | date: dateFormat + ' HH:mm:ss' }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.STATUS' | translate }}:
            </span>
            <span class="object-value">{{ statusString | translate }} </span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.PROGRESS' | translate }}:
            </span>
            <span class="object-value">{{ progressString | translate }}</span>
          </div>
          <div class="object-row">
            <span class="object-label"
              >{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.RETURN-CODES' | translate }}:
            </span>
            <span class="object-value">{{ awFile.returnCode }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      id="response-request-dialog-comment-close-button"
      mat-button
      class="custom-button-cancel"
      [mat-dialog-close]
    >
      {{ 'GENERAL.CLOSE' | translate }}
    </button>
  </div>
</section>
