import { transferOptions } from '../../../quantity-goods-create/enum/transferOptions';

export const displayedColumns = ['qsId', 'netAmount', 'positive', 'actions'];

export const tableConfiguration = [
  {
    type: 'input',
    translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.QSID',
    columnName: 'qsId',
  },
  {
    type: 'input',
    inputType: 'number',
    translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.NET-AMOUNT',
    columnName: 'netAmount',
  },
  {
    type: 'select',
    translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.TRANSFER',
    columnName: 'positive',
    options: transferOptions,
  },
  {
    type: 'actions',
    columnName: 'actions',
  },
];
