import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AbstractInputDirective } from '../abstract-input.directive';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';

@Component({
  selector: 'app-mat-input-autocomplete',
  templateUrl: './mat-input-autocomplete.component.html',
  styleUrls: ['./mat-input-autocomplete.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatInputAutocompleteComponent
  extends AbstractInputDirective
  implements OnInit, OnDestroy
{
  @Input() name;
  @Input() placeholder;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() formGroupCustomName;
  @Input() options;
  @Input() filterName;
  @Input() optionValue;
  @Input() optionTranslationText;
  @Input() translationOption;
  @Input() appearance;
  @Input() disabled;
  @Input() inline;

  filteredOptions: Observable<any[]>;

  newValue: any;
  formGroup: any;

  requiredValidator = true;
  changedValue = false;

  currentLang = 'de';

  constructor(
    private parentForm: FormGroupDirective,
    private translationService: TranslationService
  ) {
    super();
    this.requiredValidator = true;
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    if (this.parentForm.form.controls[this.formGroupCustomName]) {
      this.formGroup = this.parentForm.form.controls[this.formGroupCustomName];
    } else if (
      this.formGroupCustomName != null &&
      this.formGroupCustomName !== ''
    ) {
      this.formGroup = this.formGroupCustomName;
    } else {
      this.formGroup = this.parentForm.form;
    }

    this.filteredOptions = this.controlObject.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    this.changedValue = this.hasChanged(this.controlName);
    this.initAndUpdateRequiredValidator(this.controlObject);

    this.translationService.currentSelectedLanguage.subscribe((lang) => {
      if (!!lang) {
        this.currentLang = lang;
      }
    });
  }

  hasChanged(fieldName: string) {
    if (this.formGroupCustomName) {
      if (
        this.form &&
        this.form.controls[this.formGroupCustomName] &&
        this.form.controls[this.formGroupCustomName][fieldName] &&
        !this.form.controls[this.formGroupCustomName].controls[fieldName]
          .enabled
      ) {
        return false;
      }
    } else {
      if (
        this.form &&
        this.form.controls[fieldName] &&
        !this.form.controls[fieldName].enabled
      ) {
        return false;
      }
    }

    if (this.taskValue && this.approvedValue) {
      let newFieldValue;
      if (this.newValue) {
        if (this.formGroupCustomName) {
          newFieldValue = this.newValue[this.formGroupCustomName][fieldName];
        } else {
          newFieldValue = this.newValue[fieldName];
        }
      }
      if (this.newValue && newFieldValue) {
        return newFieldValue !== this.approvedValue;
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (this.approvedValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.approvedValue !==
          this.newValue[this.formGroupCustomName][fieldName]
        );
      } else {
        return this.approvedValue !== this.newValue[fieldName];
      }
    } else if (!this.approvedValue && this.taskValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !== this.taskValue
        );
      } else {
        return this.newValue[fieldName] !== this.taskValue;
      }
    } else if (this.approvedValue === '') {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !==
          this.approvedValue
        );
      } else {
        if (this.newValue) {
          return (
            (this.newValue[fieldName] ? this.newValue[fieldName] : '') !==
            this.approvedValue
          );
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  displayFn(value: any) {
    let result;
    if (value !== null && value !== '') {
      result = this.getOptionName(value);
    }

    return result;
  }

  getOptionName(value: string) {
    if (this.options.length > 0) {
      const result = this.options.filter(
        (option) => option[this.filterName] === value
      )[0];
      if (this.controlName === 'country') {
        if (this.currentLang === 'de') {
          return result.translations.deu.common;
        }
        return result.name.common;
      } else {
        if (result) {
          return result[this.translationOption];
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  }

  private _filter(val: any): any[] {
    if (val) {
      const filterValue = val.toLowerCase();
      return this.options.filter((option) =>
        this.getOptionName(option[this.filterName])
          .toLowerCase()
          .includes(filterValue)
      );
    }
  }

  getItemValue(item) {
    if (this.optionTranslationText === undefined) {
      this.optionTranslationText = '';
    }
    let result = '';

    Object.keys(item).forEach((key) => {
      if (key === this.optionValue) {
        result = item[key];
      }
    });

    return result;
  }

  getItem(item) {
    let result = '';
    Object.keys(item).forEach((key) => {
      if (this.controlName === 'country') {
        if (this.currentLang === 'de') {
          result = item.translations?.deu?.common;
        } else {
          result = item.name?.common;
        }
      } else if (key === this.translationOption) {
        result = item[key];
      }
    });

    return result;
  }
}
