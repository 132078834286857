import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyRoutingModule } from './company-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../shared/shared.module';
import { BackButtonModule } from '../../shared/dialogs/back-button/back-button.module';
import { BusinessUnitModule } from '../business-unit/business-unit.module';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CompanyBankAccountComponent } from './company-bank-account/company-bank-account.component';
import { CompanyContactPersonComponent } from './company-contact-person/company-contact-person.component';
import { CompanyGeneralInformationComponent } from './company-general-information/company-general-information.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@NgModule({
    declarations: [
        CompanyCreateComponent,
        CompanyListComponent,
        CompanyContactPersonComponent,
        CompanyBankAccountComponent,
        CompanyGeneralInformationComponent,
    ],
    imports: [
        CommonModule,
        CompanyRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatDialogModule,
        MatPaginatorModule,
        MatButtonModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        BusinessUnitModule,
        MatMenuModule,
        MatIconModule,
        MatCheckboxModule,
        NgSelectModule,
        SharedModule,
        BackButtonModule,
        MatRadioModule,
        CurrencyMaskModule,
        DragDropModule,
        MatAutocompleteModule,
        FormElementsModule,
        MatSidenavModule,
        FlexLayoutModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatStepperModule,
    ],
    exports: [
        CompanyGeneralInformationComponent,
        CompanyBankAccountComponent,
        CompanyContactPersonComponent,
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ]
})
export class CompanyModule {}
