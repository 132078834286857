import { Component, Input, HostListener, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AzureFile } from 'src/app/shared/models/azure-file';
import { TaskService } from '../../services/task/task.service';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { allowedMediaTypes } from './allowed-media-types';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  onChange: (args) => void;
  file: Array<File> = [];
  @Input() uploadedFiles: AzureFile[];
  @Input() multiple;
  @Input() transaction;
  @Input() required;

  entityName = 'file-upload';
  forbiddenFiles = false;
  multipleFilesError = false;

  public files: NgxFileDropEntry[] = [];

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (!this.file) {
      this.file = [];
    }
    if (this.transaction) {
      this.file = [];
      this.files = [];
    }
    for (let i = 0; i < event.length; i++) {
      const allowed = this.reviewAllowedFileType(event.item(i));
      if (allowed) {
        this.file.push(event && event.item(i));
        this.onChange(this.file);
      } else {
        this.forbiddenFiles = true;
      }
    }
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private taskService: TaskService
  ) {}

  writeValue(value: null) {
    // clear file input

    this.host.nativeElement.value = '';
    this.file = null;
  }
  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    /**
     * This function is required by ControlValueAccessor implemented by this controller
     * The sonarqube don't like to much of empty function. So this comment is to makes the sonarqube happy.
     * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
     */
  }

  openFile(file: AzureFile) {
    this.taskService.getFile(file.fileName, file.taskId).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      const anchor = document.createElement('a');
      anchor.download = file.fileName;
      anchor.href = url;
      anchor.click();
    });
  }

  deleteFile(file: AzureFile) {
    this.taskService.deleteFile(file).subscribe((data) => {
      this.taskService.listFiles(file.taskId).subscribe((fileData) => {
        this.uploadedFiles = fileData;
      });
    });
  }

  reviewAllowedFileType(file: File): boolean {
    return allowedMediaTypes.includes(file.type)
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (!this.file) {
      this.file = [];
    }

    if (!this.multiple && files.length > 1) {
      this.multipleFilesError = true;
    } else {
      if (!this.multiple) {
        this.files = [];
      }
      if (this.transaction) {
        this.file = [];
        this.files = [];
      }
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((singFile: File) => {
            // Here you can access the real file
            const allowed = this.reviewAllowedFileType(singFile);
            if (allowed) {
              this.file.push(singFile);
              this.onChange(this.file);
              this.files.push(droppedFile);
            } else {
              this.forbiddenFiles = true;
            }
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }
  }
  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  removeFromNewFiles(file) {
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].relativePath === file.relativePath) {
          this.file.splice(i, 1);
          this.files.splice(i, 1);
        }
      }
    }
  }
}
