import { Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-dashboard-contact-us',
  templateUrl: './dashboard-contact-us.component.html',
  styleUrls: [
    './dashboard-contact-us.component.less',
    './dashboard-contact-us-sm.component.less',
  ],
})
export class DashboardContactUsComponent {
  @ViewChild('stepper') stepper: MatStepper;
  showStep = false;

  constructor(private router: Router, public media: MediaObserver) {}

  stepperChange(value) {
    switch (value?.innerHTML) {
      case 'Website Credits':
      case 'Impressum':
        this.router.navigateByUrl('/websiteCredits');
        break;
      case 'Privacy Policy':
      case 'Datenschutzhinweis':
        this.router.navigateByUrl('/privacyPolicy');
        break;
      case 'Terms Of Service':
      case 'Nutzungsbedingungen':
        this.router.navigateByUrl('/termsOfService');
        break;
    }
  }
}
