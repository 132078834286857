<app-back-button *ngIf="task"> </app-back-button>
<div class="documents-wrapper">
  <div *ngIf="!task && dataLoaded">
    <mat-form-field appearance="fill" class="search-for-entries content-search-container">
      <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'GENERAL.SEARCH' | translate }}"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="searchString"
        (ngModelChange)="startSearch()"
        type="text"
        removeHtmlTagsForNgModel
      />
    </mat-form-field>
  </div>
  <h5 *ngIf="dataLoaded" class="subheading">{{ 'DOCUMENTS' | translate }}</h5>
  <app-default-table
    *ngIf="dataLoaded"
    [entityName]="configurationKey"
    [configurationKey]="configurationKey"
    [columns]="columnConfiguration"
    [displayedColumns]="columns"
    [originalColumns]="columnConfiguration"
    [originalDisplayedColumns]="columns"
    [tableDataSource]="documentList"
    (filterEvent)="filterEvent($event)"
    [selectionAction]="null"
    [customFunction]="true"
  ></app-default-table>
</div>
