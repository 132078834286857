export const updateTaskOptionsEnum: any[] = [
  {
    translateName: 'CONTACT-PERSON-TASK.UPDATE-USER',
    value: 'UPDATE_USER',
  },
  {
    translateName: 'CONTACT-PERSON-TASK.CREATE-NEW',
    value: 'SET_INACTIVE',
  },
];
