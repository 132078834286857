import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function maxLengthValidator(maxLength): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && control.value.toString().length > maxLength
      ? { invalid: true }
      : null;
  };
}

export function minLengthValidator(minLength): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && control.value.toString().length < minLength
      ? { invalid: true }
      : null;
  };
}

export function consecutiveNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = true;

    let characters: any[] = [];

    if (control.value) {
      characters = control.value.toString().split('');
    }

    if (characters.length > 1) {
      let prevCharacter;

      for (let i = 0; i <= characters.length && valid === true; i++) {
        if (!prevCharacter) {
          prevCharacter = characters[i];
        } else {
          if (
            prevCharacter === characters[i] ||
            Number(prevCharacter) === Number(characters[i]) + 1 ||
            Number(prevCharacter) === Number(characters[i]) - 1
          ) {
            valid = false;
            return { invalid: true };
          } else {
            prevCharacter = characters[i];
          }
        }
      }
    }
    return null;
  };
}

export function valueInListValidator(list, property): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const exists = list.find((item) => item[property] === control.value);
    return !exists ? { inexistent: true } : null;
  };
}

export class GeneralValidator {
  public upperLimitValidation(upperLimit: string, value: string): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const upperLimitValue = Number(formGroup.get(upperLimit).value);
      const numberValue = Number(formGroup.get(value).value);

      return upperLimitValue && numberValue && numberValue >= upperLimitValue
        ? { limitError: true }
        : null;
    };
  }

  public sameTextValidation(text1p: string, text2p: string): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const text1 = formGroup.get(text1p).value;
      const text2 = formGroup.get(text2p).value;

      return text1 && text2 && text1.toString() !== text2.toString()
        ? { differentText: true }
        : null;
    };
  }
}

export function ServicePinConditionsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid: boolean[] = [];

    if (
      control.value !== null &&
      control.value !== undefined &&
      control.value !== ''
    ) {
      if (control.value) {
        valid.push(/^(?=.*\d).+$/.test(control.value));

        valid.push(
          control.value.toString().length >= 4 &&
            control.value.toString().length <= 8
        );
      }
    }

    if (valid.length > 0) {
      let contitionsMatched = 0;
      valid.forEach((cond) => {
        if (cond) {
          contitionsMatched++;
        }
      });

      return contitionsMatched < 2
        ? { servicePinConditionsInvalid: true }
        : null;
    }

    return null;
  };
}
