<div class="checksum-container">
  <app-simple-input
    [control]="checksum"
    [disabled]="true"
    [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
    [type]="'text'"
    [classes]="'mat-form-field-appearance-fill mat-form-field-flex'"
    [decimalMarker]="','"
    [thousandSeparator]="'.'"
    [mask]="'separator.2'"
  >
  </app-simple-input>
</div>
