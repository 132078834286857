export const openTransactionColumnsConf: any[] = [
  {
    name: 'businessPartner',
    index: 2,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.BUSINESS-PARTNER',
    locked: true,
  },
  {
    name: 'businessRelationship',
    index: 3,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.BUSINESS-RELATIONSHIP',
    locked: true,
  },
  {
    name: 'externalId',
    index: 4,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.EXTERNAL-ID',
    locked: false,
  },
  {
    name: 'clientOpenItemId',
    index: 5,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-ID',
    locked: true,
  },
  {
    name: 'openItemDate',
    index: 6,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-DATE',
    locked: true,
  },
  {
    name: 'transactionCode',
    index: 7,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.TRANSACTION-CODE',
    locked: true,
  },
  {
    name: 'debitOrCredit',
    index: 8,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBIT-CREDIT-CODE',
    locked: true,
  },
  {
    name: 'amount',
    index: 9,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.AMOUNT',
    locked: true,
  },
  {
    name: 'currency',
    index: 10,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.CURRENCY-CODE',
    locked: true,
  },
  {
    name: 'vatAmount',
    index: 11,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.VAT-AMOUNT',
    locked: true,
  },
  {
    name: 'vatPercent',
    index: 12,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.TABLE.VAT-PERCENT',
    locked: true,
  },
  {
    name: 'dueDate',
    index: 13,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DUE-DATE',
    locked: false,
  },
  {
    name: 'debtCollection',
    index: 14,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION',
    locked: false,
  },
  {
    name: 'debtCollectionTransferDate',
    index: 15,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION-TRANSFER-DATE',
    locked: false,
  },
  {
    name: 'settlementInEur',
    index: 16,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.SETTLEMENT-EUR',
    locked: false,
  },
  {
    name: 'referenceDocumentationId',
    index: 17,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.REFERENCE-DOCUMENTATION-ID',
    locked: false,
  },
  {
    name: 'additionalInformation',
    index: 18,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.ADDITIONAL-INFORMATION',
    locked: false,
  },
  {
    name: 'branchId',
    index: 19,
    checked: false,
    translateName: 'OPEN-TRANSACTIONS.TABLE.BRANCH-ID',
    locked: false,
  },
  {
    version: 7.3,
  },
];
