<app-back-button></app-back-button>
<span *ngIf="taskView && !editableTask && mode !== 'CANCEL'">
  <button id="{{ entityName }}-edit-task" mat-button class="edit-task-button" (click)="makeEditable()">
    <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</span>
<div class="content-container">
  <app-editable-list-form
    *ngIf="entities"
    [entityName]="entityName"
    [displayedColumns]="displayedColumns"
    [getFormRow]="getFormRow"
    [getNewObject]="getNewObject"
    [tableConfiguration]="tableConfiguration"
    [form]="form"
    [entities]="entities"
    [mode]="mode"
  ></app-editable-list-form>
  <app-checksum></app-checksum>
  <div class="button-container buttons" *ngIf="!taskView">
    <button
      mat-button
      class="custom-button"
      [disabled]="mode === 'UPDATE'"
      id="{{ entityName }}-add-button"
      *hasPermission="'aw.pf.fd.c'"
      (click)="addEmptyRow()"
    >
      {{ 'GENERAL.ADD' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      id="{{ entityName }}-save-button"
      *hasPermission="['aw.pf.fd.c', 'aw.pf.fd.u']"
      [disabled]="form.invalid && getDisabledStatus()"
      (click)="save()"
    >
      {{ 'GENERAL.SAVE' | translate }}
    </button>
  </div>
  <div class="button-container buttons" *ngIf="editableTask && taskView">
    <button
      mat-button
      class="custom-button save-button"
      (click)="saveDraft()"
      *hasPermission="['aw.pf.fd.c', 'aw.pf.fd.u']"
      id="{{ entityName }}-save-draft-button"
      [disabled]="editableListFormComponent.getFormObjects.invalid"
    >
      <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.SAVE</span>
    </button>
    <button
      mat-button
      class="custom-button-cancel task-cancel"
      id="{{ entityName }}-cancel-button"
      (click)="cancelEditable()"
    >
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
  </div>
</div>
