export const contractOverviewFields: any[] = [
  {
    name: 'tciName',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.NAME',
  },
  {
    name: 'clientId',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.CLIENT-ID',
    specialDisplayMethod: 'checkInternalTCI',
  },
  {
    name: 'contractNumber',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.CONTRACT-NUMBER',
  },
  {
    name: 'furtherId',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.FURTHER-ID',
  },
  {
    name: 'listDomesticNumber',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.LIST-DOMESTIC-NUMBER',
  },
  {
    name: 'listForeignNumber',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.LIST-FOREIGN-COUNTRY-NUMBER',
  },
  {
    name: 'currency',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.CURRENCY',
  },
  {
    name: 'contractStatus',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.CONTRACT-STATUS',
    methodName: 'getStatus',
  },
  {
    name: 'broker',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.BROKER',
  },
  {
    name: 'brokerName',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.BROKER-NAME',
  },
  {
    name: 'brokerContactPerson',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.BROKER-CONTACT-PERSON',
  },
  {
    name: 'brokerPhoneNumber',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.BROKER-PHONE-NUMBER',
  },
  {
    name: 'brokerEmail',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.BROKER-EMAIL',
  },
  {
    name: 'durationFrom',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.DURATION-FROM',
  },
  {
    name: 'durationTo',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.DURATION-TO',
  },
  {
    name: 'premiumRate',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.PREMIUM-RATE',
    maskValue: 'percent',
  },
  {
    name: 'premiumCalculationBasis',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.PREMIUM-CALCULATION-BASIS',
    methodName: 'getPremiumCalculationBasis',
  },
  {
    name: 'vatCoinsured',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.VAT-CO-INSURED',
  },
  {
    name: 'vatRateDomestic',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.VAT-RATE-DOMESTIC',
    maskValue: 'percent',
  },
  {
    name: 'vatRateForeign',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.VAT-RATE-FOREIGN-COUNTRY',
    maskValue: 'percent',
  },
  {
    name: 'premiumCalculationType',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.PREMIUM-CALCULATION-TYPE',
    methodName: 'getPremiumCalculationType',
  },
  {
    name: 'premiumAmount',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.PREMIUM-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'minimumPremium',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.MINIMUM-PREMIUM',
  },
  {
    name: 'minimumPremiumAmount',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.MINIMUM-PREMIUM-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'maximumCompensationBasis',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.MAXIMUM-COMPENSATION-BASIS',
    methodName: 'getMaximumCompensationBasis',
  },
  {
    name: 'maximumCompensationMultiplier',
    translateName:
      'TCI.CREATE.FORM-OVERVIEW-TAB.MAXIMUM-COMPENSATION-MULTIPLIER',
  },
  {
    name: 'maximumCompensationRate',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.MAXIMUM-COMPENSATION-RATE',
    maskValue: 'percent',
  },
  {
    name: 'maximumCompensationAmount',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.MAXIMUM-COMPENSATION-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'currentLargestIndividualLimitDomestic',
    translateName:
      'TCI.CREATE.FORM-OVERVIEW-TAB.GREATEST-POSSIBLE-DOMESTIC-LIMIT',
  },
  {
    name: 'checkFeeTypes',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.CHECK-FEE-TYPES',
  },
  {
    name: 'singleCheckFeeAmountGermany',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.SINGLE-CHECK-FEE-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'singleCheckFeeGermany',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.SINGLE-CHECK-FEE',
    maskValue: 'currency',
  },
  {
    name: 'flatRateCheckFeeAmount',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.FLAT-RATE-CHECK-FEE-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'flatRateCheckFeeBasis',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.FLAT-RATE-CHECK-FEE-BASIS',
    methodName: 'getFlatRateCheckFeeBasis',
  },
  {
    name: 'reliability',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.RELIABILITY',
    maskValue: 'TCI.CREATE.MASKS.MONTH',
  },
  {
    name: 'liability',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.LIABILITY',
    maskValue: 'TCI.CREATE.MASKS.MONTH',
  },
  {
    name: 'protractedDefault',
    translateName: 'TCI.CREATE.FORM-OVERVIEW-TAB.PROTRACTED-DEFAULT',
  },
  {
    name: 'protractedDefaultWaitingPeriodDomestic',
    translateName:
      'TCI.CREATE.FORM-OVERVIEW-TAB.PROTRACTED-DEFAULT-WAITING-PERIOD-DOMESTIC',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
];
