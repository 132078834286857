import { ValidatorFn, AbstractControl } from '@angular/forms';
import { BIC } from 'ibankit';
export function bicValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }
    if (BIC.isValid(control.value)) {
      return null;
    } else {
      return { BICInvalid: true };
    }
  };
}
