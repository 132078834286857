import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { models } from 'powerbi-client';
import { PowerbiService } from 'src/app/shared/services/powerbi/powerbi.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { IReportEmbedConfiguration } from 'embed';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit, OnDestroy {
  entityName = 'report';
  reports;
  form;
  reportConfig: IReportEmbedConfiguration;
  reportClass = 'report-container';
  reportSelectedType: 'PowerBIReport' | 'PaginatedReport' | 'None' = 'None';
  private subscription: Subscription;

  constructor(
    private powerBIService: PowerbiService,
    private formBuilder: UntypedFormBuilder,
    private translationService: TranslationService
  ) {
    this.subscription = new Subscription();
    this.reportConfig = {
      type: 'report',
      embedUrl: '',
      accessToken: '',
      viewMode: models.ViewMode.View,
      tokenType: models.TokenType.Embed,
      settings: {},
      hostname: 'https://app.powerbi.com',
    };
    this.form = this.formBuilder.group({ report: [] });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.loadReports(this.translationService.getCurrentLanguage());
    this.subscription.add(
      this.translationService.currentSelectedLanguage.subscribe((newLang) => {
        this.form.get('report').patchValue(null);
        this.loadReports(newLang);
        this.reportSelectedType = 'None';
      })
    );
    this.form.get('report').valueChanges.subscribe((data) => {
      this.loadEmbedPBI(data);
    });
  }

  private loadEmbedPBI(data) {
    if (data) {
      this.powerBIService
        .getEmbededToken(data.reportId, data.groupId, data.datasetId)
        .pipe(first())
        .subscribe((embedToken) => {
          this.reportSelectedType = data.type;
          this.reportConfig = this.buildEmbedConfiguration(embedToken, data);
        });
    }
  }

  private buildEmbedConfiguration(embedToken, data) {
    const accessToken = embedToken['embedToken'];
    return {
      type: 'report',
      reportId: data.reportId,
      embedUrl: data.embedUrl,
      accessToken,
      viewMode: models.ViewMode.View,
      tokenType: models.TokenType.Embed,
      settings: {
        localeSettings: {
          formatLocale:
            this.translationService.getCurrentLanguage() === 'de' ? 'de' : 'us',
          language: this.translationService.getCurrentLanguage(),
        },
        panes: {
          pageNavigation: { visible: embedToken['showNavigationPane'] },
          filters: {
            expanded: false,
            visible: false,
          },
        },
        background: models.BackgroundType.Transparent,
      },
      hostname: 'https://app.powerbi.com',
    };
  }

  private loadReports(language: string) {
    this.reports = [];
    this.powerBIService
      .listAllReports(language)
      .pipe(first())
      .subscribe((reports) => {
        this.reports = reports;
      });
  }
}
