import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Router } from '@angular/router';
import { subLedgerAccountsListColumns } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/table-columns';
import { subLedgerAccountsListColumnsConf } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/table-columns-configuration';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { SortTableEvent } from 'src/app/shared/table-filters/table-menu-sort-filter/sort-table-event';

@Component({
  selector: 'app-sub-ledger-account-list',
  templateUrl: './sub-ledger-account-list.component.html',
  styleUrls: ['./sub-ledger-account-list.component.less'],
})
export class SubLedgerAccountListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columns = subLedgerAccountsListColumnsConf;
  displayedColumns = subLedgerAccountsListColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'subLedgerAccountsData_table';
  subLedgerAccountsData = [];
  dataSource = new MatTableDataSource<any>(this.subLedgerAccountsData);
  searchInput: any;
  editMode: any;
  entityName = 'sub-ledger-account-list';
  filter;
  state;
  subject = new Subject();
  totalCount = 0;
  loaderSpinner = true;
  sortTable = (event: SortTableEvent): void => {
    this.loaderSpinner = true;
    this.subLedgerService.setSort(event);
    this.subject.next(undefined);
  };

  getFieldNames = (args): Observable<any> => {
    return this.subLedgerService.findDistinctValues(args);
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private subLedgerService: SubLedgerService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private matDialogService: MatDialog,
    private draftService: DraftService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.subLedgerService.setColumnFilters(choice, selection);
    this.paginator.pageIndex = 0;
    this.subject.next(undefined);
  };

  ngOnInit() {
    this.state = window.history.state;
    this.getTableConfiguration();
    this.dataSource.data = [];
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.setPaginatorToFirstPage();
      this.subLedgerService.filterAccounts().subscribe((data) => {
        this.updateData(data);
      });
    });
  }

  private setPaginatorToFirstPage() {
    this.paginator.pageIndex = 0;
    this.subLedgerService.setPaginator(
      this.paginator.pageSize,
      this.paginator.pageIndex
    );
  }

  ngAfterViewInit() {
    this.subLedgerService.setPaginator(
      this.paginator.pageSize,
      this.paginator.pageIndex
    );
    this.getAccounts();
  }

  getAccounts() {
    this.subLedgerService.filterAccounts().subscribe((accounts) => {
      this.subLedgerAccountsData = accounts['content'];
      this.dataSource.data = accounts['content'];
      this.totalCount = accounts['total'];
      this.loaderSpinner = false;
      this.getDrafts();
    });
  }

  getDrafts() {
    const entity = 'SUB_LEDGER_ACCOUNT';
    this.draftService.retrieveLocalDrafts(entity).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);
            object.draft = true;
            object.draftId = draft.id;
            object.draftGuid = draft.guid;
            object.operationUser = draft.operationUser;
            this.dataSource.data.unshift(object);
          });
          this.dataSource.filter = '';
        } else {
          this.showNoDataFound();
        }
      },
      (error) => {
        this.showNoDataFound();
      }
    );
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  editSubLedgerAccount(element) {
    element.product = [element.product];
    element.serviceModel = [element.serviceModel];
    const state = { subLedgerAccount: element, operation: 'update' };
    this.router.navigateByUrl(
      '/systemConfiguration/subLedgerAccounts/subLedgerAccountsList/create',
      { state }
    );
  }

  editDraft(element) {
    element.product = [element.product];
    element.serviceModel = [element.serviceModel];
    const state = {
      subLedgerAccount: element,
      operation: 'create',
      draft: true,
    };
    this.router.navigateByUrl(
      '/systemConfiguration/subLedgerAccounts/subLedgerAccountsList/create',
      { state }
    );
  }

  deleteDraft(element) {
    let foundDraft;
    if (element.draftGuid) {
      foundDraft = this.dataSource.data.findIndex(
        (item) => item.draftGuid === element.draftGuid
      );
    }
    this.draftService.deleteUserDraft(element.draftGuid).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(foundDraft, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  setToInactive(element) {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.subLedgerService
          .setAccountToInactive(element.guid, result)
          .subscribe(
            (_) => {
              this.successTogleActive();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }
    });
  }

  setToActive(element) {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.subLedgerService
          .setAccountToActive(element.guid, result)
          .subscribe(
            (_) => {
              this.successTogleActive();
            },
            (error) => {
              this.handleError(error);
            }
          );
      }
    });
  }

  isAccountActive(account) {
    if (account === true) {
      return true;
    } else {
      return false;
    }
  }

  getSalesTaxAccount(salesTaxAccount) {
    if (salesTaxAccount === true) {
      return 'true';
    } else {
      return 'false';
    }
  }

  getSapSubLedgerAccount(sapSubLedgerAccount) {
    if (sapSubLedgerAccount === true) {
      return 'true';
    } else {
      return 'false';
    }
  }

  searchSubLedgerAccounts() {
    this.loaderSpinner = true;
    this.subLedgerService.setSearchString(this.searchInput.toString());
    this.subject.next(undefined);
  }

  create() {
    this.router.navigateByUrl(
      '/systemConfiguration/subLedgerAccounts/subLedgerAccountsList/create',
      { state: { operation: 'create' } }
    );
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                subLedgerAccountsListColumnsConf,
                subLedgerAccountsListColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = subLedgerAccountsListColumnsConf;
    this.displayedColumns = subLedgerAccountsListColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  paginate(event) {
    this.loaderSpinner = true;
    this.subLedgerService.setPaginator(event.pageSize, event.pageIndex);
    this.subLedgerService.filterAccounts().subscribe((data) => {
      this.updateData(data);
    });
  }

  sortData(event) {
    this.loaderSpinner = true;
    let fieldName = '';
    switch (event.active) {
      case 'accountNumber':
        fieldName = 'code';
        break;
      case '':
        fieldName = 'active_account';
        break;
      default:
        fieldName = event.active;
    }

    this.subLedgerService.setSort(event);
    this.subject.next(undefined);
  }

  private successTogleActive() {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
        ),
      }
    );
  }

  private updateData(data) {
    this.dataSource.data = data['content'];
    this.totalCount = data['total'];
    this.loaderSpinner = false;
  }
}
