<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <form class="form" [formGroup]="newBUPoolForm" autocomplete="off">
      <div class="center-input">
        <app-mat-input
          [name]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-NAME'"
          [placeholder]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-NAME'"
          [label]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-NAME'"
          [entityName]="entityName"
          [form]="newBUPoolForm"
          [controlObject]="newBUPoolForm.controls.name"
          [controlName]="'name'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
      </div>
      <div class="center-input">
        <app-mat-slider
          [name]="'BUSINESS-UNIT.POOL-ALLOCATION.EXCLUSIVE-ALLOCATION'"
          [label]="'BUSINESS-UNIT.POOL-ALLOCATION.EXCLUSIVE-ALLOCATION'"
          [entityName]="entityName"
          [form]="newBUPoolForm"
          [controlName]="'exclusiveAllocation'"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
        ></app-mat-slider>
      </div>
    </form>
  </div>

  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false">
      {{ 'BUSINESS-UNIT.POOL-ALLOCATION.CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      [disabled]="!newBUPoolForm.valid"
      [mat-dialog-close]="newBUPoolForm.value"
      (click)="save()"
      style="margin-right: 0"
    >
      {{ 'BUSINESS-UNIT.POOL-ALLOCATION.SAVE' | translate }}
    </button>
  </div>
</div>
