import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { Role } from '../../models/role';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private serviceUrl: string;
  private readonly searchApiUrl: string;
  dateFormat: any;
  private mapOfColumnFilters = [];
  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
  };

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.userService;
    this.searchApiUrl = environment.settings.searchAPIUrl;

    this.getSettings().subscribe((data) => {
      if (data && data.profile) {
        this.dateFormat = data.profile.dateFormat;
      }
    });
  }

  getAccountManagerName(userName: string, userList: User[]) {
    if (userList) {
      return userList.find((el) => el.username === userName);
    }
  }

  getDateFormat(withTime?: boolean) {
    const format = this.dateFormat;
    switch (format) {
      case 'GERMAN' :
        return withTime ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy';
      case 'BRITISH' :
        return withTime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy';
      case 'AMERICAN' :
        return withTime ? 'MM-dd-yyyy HH:mm:ss' : 'MM-dd-yyyy';
      default:
        return withTime ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy';
    }
  }

  getMonthAndYearFormat() {
    const format = this.dateFormat;
    if (format === 'GERMAN') {
      return 'MM.yyyy';
    }
    if (format === 'BRITISH') {
      return 'MM/yyyy';
    }
    if (format === 'AMERICAN') {
      return 'MM-yyyy';
    } else {
      return 'MM.yyyy';
    }
  }

  listAll(): Observable<User[]> {
    console.log('user.service.listAll listing all users:   ');
    const url = this.serviceUrl + 'internal-users';

    return this.http.get<User[]>(url, this.buildHeaders());
  }

  listInRole(userRole: string): Observable<User[]> {
    const url = this.serviceUrl + 'users?role=' + userRole;

    return this.http.get<User[]>(url, this.buildHeaders());
  }

  listRoles(): Observable<Role[]> {
    const url = this.serviceUrl + 'roles';
    return this.http.get<Role[]>(url, this.buildHeaders());
  }

  getConfiguration(key: string): Observable<any> {
    const url = this.serviceUrl + 'preferences/' + key;
    return this.http.get(url, this.buildHeaders());
  }

  insertConfiguration(configuration) {
    const url = this.serviceUrl + 'preferences';
    return this.http.post(url, configuration, this.buildHeaders());
  }

  mergeConfiguration(mergedConfiguration) {
    const url = this.serviceUrl + 'preferences/merge';
    return this.http.post(url, mergedConfiguration, this.buildHeaders());
  }

  listUsersInTeam(team: string): Observable<User[]> {
    const url = this.serviceUrl + 'users/in-team?team=' + team;
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  getSettings(): Observable<any> {
    const url = this.serviceUrl + 'profiles';
    return this.http.get(url, this.buildHeaders());
  }

  getCurrentUser(): Observable<any> {
    const url = this.searchApiUrl + 'profile/getCurrentUser';
    return this.http.get(url, this.buildHeaders());
  }

  getCurrentUserExtended(external: boolean): Observable<any> {
    const url = this.serviceUrl + 'users/getCurrentUser?external=' + external;
    return this.http.get(url, this.buildHeaders());
  }

  saveSettings(settings): Observable<any> {
    const url = this.serviceUrl + 'profiles';
    return this.http.put(url, settings, this.buildHeaders());
  }

  saveLanguage(language): Observable<any> {
    const url = this.serviceUrl + 'profiles/putLanguage';
    return this.http.put(url, language, this.buildHeaders());
  }

  createInternalUser(requestJson: { userObjectDto: any; comment: any }) {
    const url = this.serviceUrl + 'internal-users/create';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  createExternalUser(requestJson: { userObjectDto: any; comment: any }) {
    const url = this.serviceUrl + 'external-users/create';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  listCompositeInternalRoles(): Observable<Role[]> {
    const url = this.serviceUrl + 'roles?composite=true&realm=internal';
    return this.http.get<Role[]>(url, this.buildHeaders());
  }

  listCompositeExternalRoles(): Observable<Role[]> {
    const url = this.serviceUrl + 'roles?composite=true&realm=external';
    return this.http.get<Role[]>(url, this.buildHeaders());
  }

  createMFA(requestJson: { userObjectDto: any }) {
    const url = `${this.serviceUrl}mfa/create`;
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  deleteMFAById(guid: any) {
    const url = this.serviceUrl + 'mfa/delete-by-id' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  deleteMFAByKeycloakUserId(guid: any) {
    const url = this.serviceUrl + 'mfa/delete-by-keycloak-user-id/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  listAllMFAs(pageSize: number, pageIndex: number, searchString: any) {
    const config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('sortField', 'keycloakUserId')
      .append('pageIndex', String(pageIndex))
      .append('pageSize', String(pageSize))
      .append('filterText', searchString);
    const url = `${this.searchApiUrl}mfa/list`;
    return this.http.get<User[]>(url, config);
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }

  mergeTableConfigurationsFromUIIfNeeded(
    configurationKey,
    dataFromDB,
    columnsDefOnUI: any[],
    columnsOnUI: any[]
  ): any {
    let mergeNeeded = false;
    if (
      columnsDefOnUI[columnsDefOnUI.length - 1].hasOwnProperty('version') &&
      dataFromDB.columns[dataFromDB.columns.length - 1].hasOwnProperty(
        'version'
      )
    ) {
      const versionFromUICode =
        columnsDefOnUI[columnsDefOnUI.length - 1].version;
      const versionFromDB =
        dataFromDB.columns[dataFromDB.columns.length - 1].version;
      if (versionFromUICode.toString() !== versionFromDB.toString()) {
        mergeNeeded = true;
      }
    } else {
      mergeNeeded = true;
    }
    if (mergeNeeded) {
      const displayedColumnsNamesSetOnDB = dataFromDB.displayedColumns;
      const displayedColumnsNamesSetOnUI = columnsOnUI;

      const mergedDisplayedColumnsNames = this.getColumnsNames(
        displayedColumnsNamesSetOnUI,
        displayedColumnsNamesSetOnDB
      );

      const configurationMergedFromUICodes = {
        key: configurationKey,
        value: {
          columns: columnsDefOnUI,
          displayedColumns: mergedDisplayedColumnsNames,
        },
      };

      this.mergeConfiguration(configurationMergedFromUICodes).subscribe(
        (data) => {
          /**
           * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
           * to much of empty function. So this comment is to makes the sonarqube happy.
           * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
           */
        }
      );

      return configurationMergedFromUICodes.value;
    }
    return dataFromDB;
  }

  getColumnsNames(UINames, DBNames) {
    let result = UINames;

    const columnsDeletedFromUI: string[] = [];
    DBNames.forEach((element) => {
      if (
        UINames.indexOf(element) < 0 &&
        element !== 'select' &&
        element !== 'actions'
      ) {
        columnsDeletedFromUI.push(element);
      }
    });

    result = result.filter((item) => columnsDeletedFromUI.indexOf(item) < 0);

    return result;
  }

  getUser(guid: any) {
    const url = this.serviceUrl + 'users/get/' + guid;
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  getInternalUser(guid: any, viewMode?: boolean) {
    let url = '';
    if (viewMode) {
      url =
        this.serviceUrl +
        'internal-users/get/' +
        guid +
        '?viewMode=' +
        viewMode;
    } else {
      url = this.serviceUrl + 'internal-users/get/' + guid;
    }
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  getExternalUser(guid: any, viewMode?: boolean) {
    let url = '';
    if (viewMode) {
      url =
        this.serviceUrl +
        'external-users/get/' +
        guid +
        '?viewMode=' +
        viewMode;
    } else {
      url = this.serviceUrl + 'external-users/get/' + guid;
    }
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  getCountContactPerson(guid: any) {
    const url =
      this.serviceUrl + 'users/countForContactPerson?contactPersonId=' + guid;
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  blockExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/block/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  setInActiveExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/block/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  blockInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/block/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  setInActiveInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/setInActive/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  unBlockExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/unblock/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  unBlockInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/unblock/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  deleteInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/delete/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  deleteExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/delete/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  deletePermission(guid: any, realm) {
    const url =
      this.serviceUrl + 'roles/permissions/delete/' + guid + '?realm=' + realm;
    return this.http.post(url, this.buildHeaders());
  }

  deleteRole(guid: any, realm) {
    const url = this.serviceUrl + 'roles/delete/' + guid + '?realm=' + realm;
    return this.http.post(url, this.buildHeaders());
  }

  sendPasswordResetInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/reset-password/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  sendPasswordResetExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/reset-password/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  sendIniLinkInternalUser(guid: any) {
    const url = this.serviceUrl + 'internal-users/ini-link/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  sendIniLinkExternalUser(guid: any) {
    const url = this.serviceUrl + 'external-users/ini-link/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  logOutUserById(guid: any, realm) {
    const url = this.serviceUrl + realm + '-users/logOutUserById/' + guid;
    return this.http.post(url, this.buildHeaders());
  }

  listAllNew(pageSize: number, pageIndex: number, searchString: any) {
    const config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('sortField', 'id')
      .append('pageIndex', String(pageIndex))
      .append('pageSize', String(pageSize))
      .append('filterText', searchString);
    const url = this.searchApiUrl + 'users/list';
    return this.http.get<User[]>(url, config);
  }

  findAll(filters) {
    const headers = this.buildHeaders();
    return this.http.get<User[]>(this.searchApiUrl + 'users/list', {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  getDistinctValues(
    customConfig: any,
    table: string,
    column: string
  ): Observable<HttpEvent<any>> {
    return this.request(
      `users/distinct-values/${table}/${column}`,
      this.getConfigs(customConfig)
    );
  }

  request(url, config): Observable<HttpEvent<any[]>> {
    return this.http.get<[]>(this.searchApiUrl + url, config);
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const allConfigs = customConfigObj
      ? { ...this.configObj, ...customConfigObj }
      : this.configObj;
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(allConfigs).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        config['params'] = config['params'].append(
          key,
          JSON.stringify(allConfigs[key])
        );
      } else {
        config['params'] = config['params'].append(key, allConfigs[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);
    return config;
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  getSearchApiUser(guid: any) {
    const url = this.searchApiUrl + 'users/get/' + guid;
    return this.http.get<User[]>(url, this.buildHeaders());
  }

  listRlsEntries(
    amount: number,
    lastAmount: number,
    searchString: string
  ): Observable<any[]> {
    const config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('amount', String(amount))
      .append('offset', String(lastAmount));
    if (searchString) {
      config['params'] = config['params'].append('searchString', searchString);
    }
    const url = this.searchApiUrl + 'rls/list';
    return this.http.get<any[]>(url, config);
  }

  getRlsEntries(user: any) {
    const url = this.searchApiUrl + 'rls/list?userGuid=' + user;
    return this.http.get<any[]>(url, this.buildHeaders());
  }

  getRlsEntryNames(rlsIdList: any) {
    const url = this.searchApiUrl + 'rls/getRlsNames?rlsIdList=' + rlsIdList;
    return this.http.get<any[]>(url, this.buildHeaders());
  }

  listRolesV2Filter(filters) {
    const headers = this.buildHeaders();
    return this.http.get<any[]>(this.serviceUrl + 'roles/v2/filter', {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  getPermissionsFilter(filters, count, realm?) {
    const headers = this.buildHeaders();
    const url = this.serviceUrl + 'roles/v2/permissions/filter?count=' + count;

    return this.http.get<any[]>(url, {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  getPermissions(count, realm?) {
    const headers = this.buildHeaders();
    let url = this.serviceUrl + 'roles/v2/permissions?count=' + count;
    if (realm) {
      url += '&realm=' + realm;
    }
    return this.http.get<any[]>(url, {
      params: {},
      ...headers,
    });
  }

  getPermissionKnownRoles(filters, guid) {
    const headers = this.buildHeaders();
    const url = `${this.serviceUrl}roles/v2/permissions/knownRoles/${guid}`;
    return this.http.get<any[]>(url, {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  getRoleKnownUsers(filters, roleName: string) {
    const headers = this.buildHeaders();
    const url = `${this.serviceUrl}users/knownUsers?roleName=${encodeURI(
      roleName
    )}`;
    return this.http.get<any[]>(url, {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  createRole(requestJson: { roleDto: any; comment: any }) {
    const url = this.serviceUrl + 'roles/create';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  findRole(guid: any, realm: string, viewMode?: boolean) {
    let url = '';
    if (viewMode) {
      url = `${this.serviceUrl}roles/v2/byGuid/${guid}?realm=${realm}&viewMode=${viewMode}`;
    } else {
      url = `${this.serviceUrl}roles/v2/byGuid/${guid}?realm=${realm}`;
    }

    return this.http.get<any>(url, this.buildHeaders());
  }

  createPermission(requestJson: { permissionDto: any; comment: any }) {
    const url = this.serviceUrl + 'roles/permissions/create';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  updatePermission(requestJson: { permissionDto: any }) {
    const url = this.serviceUrl + 'roles/permissions/update';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  updateRole(requestJson: { roleDto: any }) {
    const url = this.serviceUrl + 'roles/update';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  findPermission(guid: any, realm: string, viewMode?: boolean) {
    let url = '';
    if (viewMode) {
      url = `${this.serviceUrl}roles/v2/permissions/byGuid/${guid}?realm=${realm}&viewMode=${viewMode}`;
    } else {
      url = `${this.serviceUrl}roles/v2/permissions/byGuid/${guid}?realm=${realm}`;
    }
    return this.http.get<any>(url, this.buildHeaders());
  }

  unLockPermission(guid: any, realm: string) {
    const url =
      this.serviceUrl +
      'roles/v2/attribute/un-lock/byGuid/' +
      guid +
      '?realm=' +
      realm;
    return this.http.get<any>(url, this.buildHeaders());
  }

  unLockUser(guid: any, realm: string) {
    const url =
      this.serviceUrl +
      realm +
      '-users/attribute/un-lock/' +
      guid +
      '?realm=' +
      realm;
    return this.http.get<any>(url, this.buildHeaders());
  }

  createExcludedPermission(requestJson: {
    comment: any;
    excludedPermissionDto: any;
  }) {
    const url = this.serviceUrl + 'excluded-permissions/create';
    return this.http.post(url, requestJson, this.buildHeaders());
  }

  getExcludedPermissions(filters) {
    const headers = this.buildHeaders();
    const url = this.serviceUrl + 'excluded-permissions/list';

    return this.http.get<any[]>(url, {
      params: {
        ...filters,
      },
      ...headers,
    });
  }

  findExclusion(guid: any) {
    const url = this.serviceUrl + 'excluded-permissions/byGuid/' + guid;
    return this.http.get(url, this.buildHeaders());
  }

  getServicePin() {
    const url = this.serviceUrl + 'users/getServicePin';
    return this.http.get(url, this.buildHeaders());
  }

  askForHelp(param: { comment: string }) {
    const url = this.serviceUrl + 'users/askForHelp';
    return this.http.post(url, param, this.buildHeaders());
  }

  resetPassword() {
    const url = this.serviceUrl + 'users/resetMyPassword';
    return this.http.put(url, this.buildHeaders());
  }

  isUsernameTaken(username: string, external: boolean) {
    const url =
      this.serviceUrl +
      'users/isUsernameTaken/' +
      username +
      '?external=' +
      external;
    return this.http.get(url, this.buildHeaders());
  }

  updateLastLogin() {
    const url = `${this.serviceUrl}users/updateLastLogin`;
    return this.http.put(url, this.buildHeaders());
  }

  resetOtp(keycloakUserId, external: string) {
    const url = this.serviceUrl + 'external-users/reset-otp/' + keycloakUserId;
    return this.http.post(url, this.buildHeaders());
  }

  findExternalByUsername(username: any) {
    const url = `${this.serviceUrl}external-users/find-id-by-username/${username}`;
    return this.http.get(url, this.buildHeaders());
  }

  triggerInsertConfiguration(configuration: any): void {
    this.insertConfiguration(configuration).subscribe(() => {
      /**
       * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
       * to much of empty function. So this comment is to makes the sonarqube happy.
       * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
       */
    });
  }
}
