<div class="filter-input button-container">
  <ng-container>
    <button
      mat-button
      class="custom-button-cancel"
      id="{{ entityName }}-delete-button"
      [disabled]="isDeleteRowsDisabled"
      (click)="deleteSelectedRows()"
    >
      {{ 'OPEN-TRANSACTIONS.DELETE-ROWS' | translate }}
    </button>
  </ng-container>
  <ng-container>
    <button
      mat-button
      class="custom-button-cancel"
      id="{{ entityName }}-cancel-button"
      *ngIf="isTask"
      (click)="cancelEditMode()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </ng-container>
  <ng-container>
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" (click)="clearAll()">
      {{ 'OPEN-TRANSACTIONS.CLEAR-RESULTS' | translate }}
    </button>
  </ng-container>
  <ng-container>
    <button
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      (click)="isTask ? updateFormObjectsTask() : saveFormObjects()"
      [disabled]="loaderAdd || checkIfThereIsInvalidForm()"
      [ngClass]="{ 'margin-button-loader': loaderAdd }"
    >
      <span *ngIf="!loaderAdd">
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
      <span *ngIf="loaderAdd">
        <mat-spinner class="spinner-position" [diameter]="20"></mat-spinner>
      </span>
    </button>
  </ng-container>
</div>
