export const contractTypes: any[] = [
  {
    name: 'FRT',
    translateName: 'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-TYPES.FRT',
  },
  {
    name: 'GASTRONOMY',
    translateName: 'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-TYPES.GASTRONOMY',
  },
];
