export const accountingRelationshipColumns: string[] = [
  'platformId',
  'platformName',
  'clientId',
  'clientName',
  'visibleId',
  'debtorName',
  'externalId',
  'balance',
  'actions',
];

export const accountingMasterDataColumns: string[] = [
  'visibleId',
  'name',
  'address',
  'actions',
];

export const accountingPartnerColumns: string[] = [
  'visibleId',
  'name',
  'type',
  'externalId',
  'balance',
  'actions',
];

export const accountingLedgerAccountColumns: string[] = [
  'accountNumber',
  'subLedgerAccountName',
  'balance',
  'actions',
];
