export const businessRelationshipSelectionColumnsConf: any[] = [
  {
    name: 'visibleId',
    index: 1,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.ID',
    locked: true,
  },
  {
    name: 'name',
    index: 2,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.NAME',
    locked: false,
  },
  {
    name: 'formatAddress',
    index: 3,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.ADDRESS',
    locked: false,
  },
  {
    version: '2',
  },
];
