<span class="company-name">{{ companyName }}</span>
<section class="split-screen-wrapper">
  <mat-list role="list" class="main-list">
    <div class="list-content">
      <mat-list-item>
        <div class="list-header">
          <span class="entities">{{ 'ENTITIES.ENTITIES' | translate }}</span>
          <app-table-menu-sort-filter
            [dataSource]="originalData"
            choice="translateKey"
            [sidebar]="true"
          ></app-table-menu-sort-filter>
        </div>
      </mat-list-item>
      <div class="data-container">
        <div *ngFor="let option of splitData">
          <mat-list-item (click)="goToRowDetail(option)">
            <span class="item-value">
              {{ option.translateKey | translate: getValueForRow(option) }}
            </span>
            <span class="goto-button">
              <mat-icon aria-hidden="false" aria-label="east icon">east</mat-icon>
            </span>
          </mat-list-item>
        </div>
      </div>
    </div>
  </mat-list>
</section>
