import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Message } from '../../models/message';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.messageService + 'messages';
  }

  markAsRead(messageId: number): Promise<void> {
    const url = this.serviceUrl + messageId + '/read';
    return this.http.post<void>(url, null, this.buildHeaders()).toPromise();
  }

  retrieve(messageId: number): Promise<Message> {
    const url = this.serviceUrl + messageId;
    return this.http.get<Message>(url, this.buildHeaders()).toPromise();
  }

  async list(pageSize: number, page: number): Promise<Message[]> {
    const url = this.serviceUrl + '?page=' + page + '&pageSize=' + pageSize;
    const message = await this.http
      .get<SearchResults<Message>>(url, this.buildHeaders())
      .toPromise();
    return message.content;
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  /**
   * Should be implemented an new endpoint to return a count of unread messages
   * the 'unread' on url is just to ilustrate
   */
  getNumberOfUnreadMessages() {
    return this.http.get(this.serviceUrl + 'unread');
  }
}

interface SearchResults<T> {
  content: T[];
  page: number;
  pageSize: number;
  total: number;
}
