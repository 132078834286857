import { TableTypes } from '../../default-table/table-types';

export const documentManagementTableColumnsConf: any[] = [
  {
    type: TableTypes.TRANSLATED_STRING,
    name: 'documentType',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-TYPE',
    locked: true,
    translatePrefix: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-TYPES.',
  },
  {
    type: TableTypes.STRING,
    name: 'qsid',
    index: 2,
    checked: true,
    locked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.QSID',
  },
  {
    type: TableTypes.STRING,
    name: 'vvvoNumber',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VVVO-NR',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'typeOfProduction',
    index: 4,
    checked: true,
    locked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.PRODUCTION-TYPE',
  },
  {
    type: TableTypes.NUMBER,
    name: 'accountNumber',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.ACCOUNT-NUMBER',
    locked: true,
  },
  {
    type: TableTypes.STRING,
    name: 'documentationId',
    index: 6,
    checked: true,
    locked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENTATION-ID',
  },
  {
    type: TableTypes.DATE,
    name: 'creationTime',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.CREATION-TIME',
    locked: true,
    dateFormat: 'fullYearWithHoursMinutesSeconds',
    sortableDate: true,
  },
  {
    type: TableTypes.STRING,
    name: 'name',
    index: 8,
    checked: true,
    locked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.NAME',
  },
  {
    type: TableTypes.STRING,
    name: 'documentName',
    index: 9,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-NAME',
    locked: true,
  },
  {
    type: TableTypes.BOOLEAN,
    name: 'visible',
    index: 10,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY',
    locked: false,
  },
  {
    version: 3.5,
  },
];
