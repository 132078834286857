import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwQuantityLabeledGoodsRoutingModule } from './transfer-quantity-labeled-goods-management-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormElementsModule } from '../../form-elements/form-elements.module';
import { QuantityLabeledGoodsListComponent } from '../quantity-labeled-goods/quantity-labeled-goods-list/quantity-labeled-goods-list.component';
import { QuantityLabeledGoodsCreateComponent } from '../quantity-labeled-goods/quantity-labeled-goods-create/quantity-labeled-goods-create.component';
import { ReportingPeriodsListComponent } from '../reporting-periods/reporting-periods-list/reporting-periods-list.component';
import { ReportingPeriodsCreateComponent } from '../reporting-periods/reporting-periods-create/reporting-periods-create.component';
import { GoodsGroupsListComponent } from '../goods-groups/goods-groups-list/goods-groups-list.component';
import { GoodsGroupsCreateComponent } from '../goods-groups/goods-groups-create/goods-groups-create.component';
import { DateValidator } from 'src/app/shared/validators/date-validator';
import { GeneralValidator } from 'src/app/shared/validators/general-validator';
import { VVVONrValidator } from '../transfer-quantity-goods/validator/general-validator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BusinessUnitModule } from '../../business-unit/business-unit.module';
import { QuantityLabeledGoodsDraftComponent } from '../quantity-labeled-goods/quantity-labeled-goods-draft/quantity-labeled-goods-draft.component';
import { TransferQuantityLabeledGoodsManagementComponent } from './transfer-quantity-labelled-goods-management/transfer-quantity-labeled-goods-management.component';

@NgModule({
  declarations: [
    QuantityLabeledGoodsListComponent,
    QuantityLabeledGoodsCreateComponent,
    QuantityLabeledGoodsDraftComponent,
    ReportingPeriodsListComponent,
    ReportingPeriodsCreateComponent,
    GoodsGroupsListComponent,
    GoodsGroupsCreateComponent,
    TransferQuantityLabeledGoodsManagementComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AwQuantityLabeledGoodsRoutingModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    BusinessUnitModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgSelectModule,
    SharedModule,
    BackButtonModule,
    MatRadioModule,
    CurrencyMaskModule,
    DragDropModule,
    SharedModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    NgxMaskModule,
    MatStepperModule,
    FormElementsModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatSidenavModule,
  ],
  providers: [DateValidator, VVVONrValidator, GeneralValidator],
})
export class TransferQuantityLabeledGoodsManagementModule {}
