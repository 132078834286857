<div class="content-container general-container">
  <div class="filters" [formGroup]="filterForm">
    <div class="filter-input">
      <mat-form-field class="white-back" appearance="fill">
        <mat-label>{{ 'SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchLivestockProducerDocuments()"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.searchString"
        />
      </mat-form-field>
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchLivestockProducerDocuments()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <div class="documents-container">
      <table
        aria-hidden="true"
        mat-table
        [dataSource]="dataSource"
        id="{{ entityName }}-search-results-table"
        class="general-table-alignment"
      >
        <ng-container matColumnDef="creationDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-creation-date"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.CREATION-DATE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-creation-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.operationDate | date: dateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="documentType">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-document-type"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.DOCUMENT-TYPE' | translate }}
            <app-table-menu-sort-filter
              choice="type"
              [dataSource]="dataSource"
              [originalDataSource]="originalDataSource"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [sortDirection]="sortDirection"
              [callback]="filterData"
              [getDistinctValues]="getFieldNames"
              [sortTable]="sortTable"
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-document-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-name"
          >
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.DOCUMENTS.NAME' | translate }}
            <app-table-menu-sort-filter
              choice="filename"
              [dataSource]="dataSource"
              [originalDataSource]="originalDataSource"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="pageSize"
              [sortDirection]="sortDirection"
              [callback]="filterData"
              [getDistinctValues]="getFieldNames"
              [sortTable]="sortTable"
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.filename }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
            <button
              class="align-settings"
              mat-icon-button
              [matMenuTriggerFor]="appMenu"
              id="{{ entityName }}-table-results-actions-button"
            >
              <mat-icon>settings</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="download-column">
            <i
              class="icon-table icon-menu icon-ic_attachment"
              aria-hidden="true"
              (click)="downloadAttachment(element)"
            ></i>
          </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
              {{ 'ERROR.NO-DATA-FOUND' | translate }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="loader">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="general-loader-table" *ngIf="loaderSpinner">
              <mat-spinner [diameter]="50"></mat-spinner>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [hidden]="loaderSpinner" *matRowDef="let row; columns: displayedColumns" class="element-row"></tr>
        <tr
          [hidden]="!loaderSpinner"
          mat-footer-row
          *matFooterRowDef="['loader']"
          class="footer-spinner height-footer"
        ></tr>
        <tr [hidden]="loaderSpinner" mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      </table>
    </div>

    <mat-paginator
      class="mat-paginator-sticky"
      [pageSize]="pageSize"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalCount"
      (page)="paginate($event)"
    ></mat-paginator>
  </div>

  <mat-menu #appMenu="matMenu" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>
</div>
