<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <h5>{{ 'upload-file' | translate }}</h5>
    <form class="form" [formGroup]="form" autocomplete="off">
      <div class="form-row">
        <div class="form-row margin-row">
          <app-file-upload
            [uploadedFiles]="uploadedFiles"
            formControlName="attachment"
            [multiple]="multiple"
          ></app-file-upload>
        </div>
      </div>
      <div class="center-input">
        <app-mat-select
          class="form-element"
          *ngIf="clientDocumentModal"
          [name]="'CLIENT.DOCUMENTS.CATEGORY'"
          [placeholder]="'CLIENT.DOCUMENTS.CATEGORY'"
          [label]="'CLIENT.DOCUMENTS.CATEGORY'"
          [entityName]="entityName"
          [form]="form"
          [controlObject]="form.controls.category"
          [controlName]="'category'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="options"
          [optionValue]="'name'"
          [selectedOption]="selectedOption"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
        ></app-mat-select>
      </div>
      <div class="center-input">
        <app-mat-textarea
          class="form-element"
          *ngIf="clientDocumentModal"
          [name]="'CLIENT.DOCUMENTS.COMMENT'"
          [placeholder]="'CLIENT.DOCUMENTS.COMMENT'"
          [label]="'CLIENT.DOCUMENTS.COMMENT'"
          [entityName]="entityName"
          [form]="form"
          [controlObject]="form.controls.comment"
          [controlName]="'comment'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
        ></app-mat-textarea>
      </div>
    </form>
  </div>

  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false">
      {{ 'cancel' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      [disabled]="!form.valid"
      [mat-dialog-close]="form.value"
      style="margin-right: 0"
    >
      {{ 'upload' | translate }}
    </button>
  </div>
</div>
