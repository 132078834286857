import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class DraftService {
  private serviceUrl;
  private companyServiceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.draftService;
    this.companyServiceUrl = environment.settings.companyService;
  }

  retrieveUserDraft(entity): Observable<any> {
    return this.http.get(this.serviceUrl + 'drafts/user-drafts', {
      params: {
        entityType: entity,
      },
      headers: this.buildHeaders().headers,
    });
  }

  retrieveLocalDrafts(entity): Observable<any> {
    return this.http.get(this.serviceUrl + 'drafts/local-drafts', {
      params: {
        entityType: entity,
      },
      headers: this.buildHeaders().headers,
    });
  }

  deleteUserDraft(id): Observable<any> {
    return this.http.delete(
      this.serviceUrl + 'drafts/' + id,
      this.buildHeaders()
    );
  }

  saveUserDraft(request) {
    if (request.id) {
      return this.http.put(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    } else {
      return this.http.post(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    }
  }

  retrieveEntityDraft(entityType, entityId): Observable<any> {
    return this.http.get(this.companyServiceUrl + 'drafts/entity-draft', {
      params: {
        entityType,
        entityId,
      },
      headers: this.buildHeaders().headers,
    });
  }

  validateDraftExistence(entityType, entityId, entityGuid): Observable<any> {
    return this.http.get(this.companyServiceUrl + 'drafts/validate-existence', {
      params: {
        entityType,
        entityId,
        entityGuid,
      },
      headers: this.buildHeaders().headers,
    });
  }

  retrieveLocalEntityDraft(entityGuid): Observable<any> {
    return this.http.get(this.serviceUrl + 'drafts/local-entity-draft', {
      params: {
        entityId: entityGuid,
      },
      headers: this.buildHeaders().headers,
    });
  }

  retrieveInitialEntityDraft(entityType, entityId): Observable<any> {
    return this.http.get(
      this.companyServiceUrl + 'drafts/initial-entity-draft',
      {
        params: {
          entityType,
          entityId,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  retrieveDraftsByEntityIdAndType(entityId, entityType): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/list-by-entity-id-and-type',
      {
        params: {
          entityType,
          entityId,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  retrieveUserApprvalDraftsByEntityIdAndType(
    entityId,
    entityType
  ): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/list-user-drafts-by-entity-id-and-type',
      {
        params: {
          entityType,
          entityId,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  retrieveDraftForTask(guid: any) {
    return this.http.get<any>(this.serviceUrl + 'drafts/approvalDraft', {
      params: {
        taskId: guid,
      },
      headers: this.buildHeaders().headers,
    });
  }

  retrieveDraftObjectForTask(guid: any) {
    return this.http.get<any>(this.serviceUrl + 'drafts/approvalDraftObject', {
      params: {
        taskId: guid,
      },
      headers: this.buildHeaders().headers,
    });
  }

  approvalDraftExistsForEntityId(entityId: any) {
    return this.http.get(this.serviceUrl + 'drafts/approvalDraftExists', {
      params: {
        entityId,
      },
      headers: this.buildHeaders().headers,
    });
  }
}
