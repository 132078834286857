import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormGroupDirective,
  ControlContainer,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'app-mat-select',
  templateUrl: './mat-select.component.html',
  styleUrls: ['./mat-select.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatSelectComponent implements OnInit, AfterContentChecked {
  @Input() name;
  @Input() placeholder;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() formGroupCustomName;
  @Input() options;
  @Input() optionValue;
  @Input() selectedOption;
  @Input() translationOption;
  @Input() optionTranslationText;
  @Input() customOptionNameFunction;
  @Input() customTaskValueFunction;
  @Input() appearance;
  @Input() textTransform;
  @Input() disabled;
  @Input() titlecasePipe;
  @Input() noSort;
  @Input() sideMenu;
  @Input() inline;
  @Input() elementIndex;
  @Input() allowNullSelection;

  @Input() extraOption;
  @Input() extraOptionValue;
  @Input() extraOptionTranslateText;
  @Input() nameParamsValue;
  @Input() twoColumns;

  errorRequired = 'error-message-required';
  newValue: any;
  formGroup: any;

  parentF: UntypedFormGroup;

  requiredValidator = false;
  changedValue = false;

  constructor(
    private parentForm: FormGroupDirective,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    this.parentF = this.parentForm.form;

    if (this.parentForm.form.controls[this.formGroupCustomName]) {
      this.formGroup = this.parentForm.form.controls[this.formGroupCustomName];
    } else {
      this.formGroup = this.parentForm.form;
    }

    if (this.controlObject && this.controlObject.validator) {
      const validator = this.controlObject.validator({} as AbstractControl);
      if (validator) {
        this.requiredValidator = validator.required;
      }
    }

    this.changedValue = this.hasChanged(this.controlName);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  hasChanged(fieldName: string) {
    if (this.formGroupCustomName) {
      if (
        this.form &&
        this.form.controls[this.formGroupCustomName] &&
        this.form.controls[this.formGroupCustomName][fieldName] &&
        !this.form.controls[this.formGroupCustomName][fieldName].enabled
      ) {
        return false;
      }
    } else {
      if (
        this.form &&
        this.form.controls[fieldName] &&
        !this.form.controls[fieldName].enabled
      ) {
        return false;
      }
    }

    if (this.taskValue && this.approvedValue) {
      if (this.newValue && this.newValue[fieldName]) {
        if (this.formGroupCustomName) {
          return (
            this.newValue[this.formGroupCustomName][fieldName] !==
            this.approvedValue
          );
        } else {
          return this.newValue[fieldName] !== this.approvedValue;
        }
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (this.approvedValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.approvedValue !==
          this.newValue[this.formGroupCustomName][fieldName]
        );
      } else {
        return this.approvedValue !== this.newValue[fieldName];
      }
    } else if (!this.approvedValue && this.taskValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !== this.taskValue
        );
      } else {
        return (
          this.newValue[fieldName].toString() !== this.taskValue.toString()
        );
      }
    } else if (this.approvedValue === '') {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !==
          this.approvedValue
        );
      } else {
        if (this.newValue) {
          return (
            this.newValue[fieldName] &&
            this.newValue[fieldName] !== this.approvedValue
          );
        }
      }
    } else {
      return false;
    }
  }

  getItem(item) {
    let result = null;
    Object.keys(item).forEach((key, index) => {
      if (key === this.translationOption) {
        result = item[key];
      }
    });

    if (result) {
      return result;
    } else {
      return item;
    }
  }

  getItemValue(item) {
    let result = null;

    Object.keys(item).forEach((key, index) => {
      if (key === this.optionValue) {
        result = item[key];
      }
    });

    if (result != null) {
      return result;
    } else if (result === false) {
      return result;
    } else {
      return item;
    }
  }

  getCustomName(item) {
    return this.customOptionNameFunction(item);
  }

  getCustomTaskName(item) {
    return this.customTaskValueFunction(item);
  }

  showRemoveOption() {
    let result = false;
    if (
      this.form.value[this.controlName] !== undefined &&
      this.form.value[this.controlName] !== null &&
      this.form.get(this.controlName).enabled
    ) {
      result = true;
    }
    return result;
  }

  clearOptions() {
    this.form.value[this.controlName] = null;
    this.form.get(this.controlName).patchValue(null);
    this.form.get(this.controlName).markAsTouched();
  }

  isFieldDisabled() {
    return this.disabled || (this.controlObject && this.controlObject.disabled);
  }
}
