<div class="input-wrapper form-container">
  <span class="dynamic-loader-table" *ngIf="loaderSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </span>
  <form class="form" *ngIf="!loaderSpinner" [formGroup]="companyForm" autocomplete="off">
    <app-mat-input
      [name]="'COMPANY.CREATE.NAME'"
      [placeholder]="'COMPANY.CREATE.NAME'"
      [label]="'COMPANY.CREATE.NAME'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="getApprovedValue('name')"
      [taskValue]="companyTask ? companyTask.name : null"
      [titleTask]="true"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>

    <app-mat-input
      [name]="'COMPANY.CREATE.ADDRESS.STREET'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.STREET'"
      [label]="'COMPANY.CREATE.ADDRESS.STREET'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].street"
      [controlName]="'street'"
      [approvedValue]="getApprovedValue('street', 'address')"
      [taskValue]="companyTask ? companyTask.address.street : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
    ></app-mat-input>

    <app-mat-input
      [name]="'COMPANY.CREATE.ADDRESS.NUMBER'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.NUMBER'"
      [label]="'COMPANY.CREATE.ADDRESS.NUMBER'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].number"
      [controlName]="'number'"
      [approvedValue]="getApprovedValue('number', 'address')"
      [taskValue]="companyTask ? companyTask.address.number : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
    ></app-mat-input>
    <app-mat-input
      [name]="'COMPANY.CREATE.ADDRESS.ZIP'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.ZIP'"
      [label]="'COMPANY.CREATE.ADDRESS.ZIP'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].zipCode"
      [controlName]="'zipCode'"
      [approvedValue]="getApprovedValue('zipCode', 'address')"
      [taskValue]="companyTask ? companyTask.address.zipCode : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
    ></app-mat-input>
    <app-mat-input
      [name]="'COMPANY.CREATE.ADDRESS.CITY'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.CITY'"
      [label]="'COMPANY.CREATE.ADDRESS.CITY'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].city"
      [controlName]="'city'"
      [approvedValue]="getApprovedValue('city', 'address')"
      [taskValue]="companyTask ? companyTask.address.city : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
    >
    </app-mat-input>

    <app-mat-input-autocomplete
      [name]="'COMPANY.CREATE.ADDRESS.COUNTRY'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.COUNTRY'"
      [label]="'COMPANY.CREATE.ADDRESS.COUNTRY'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].country"
      [controlName]="'country'"
      [approvedValue]="getApprovedValue('country', 'address')"
      [taskValue]="companyTask ? companyTask.address.country : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
      [options]="countries"
      [filterName]="'cca3'"
      [optionValue]="'cca3'"
      [translationOption]="'cca3'"
      [optionTranslationText]="''"
    >
    </app-mat-input-autocomplete>

    <div class="form-row" *ngIf="!isTask">
      <label for="countryCode">{{ 'ADDRESS.COUNTRY-CODE' | translate }}</label>
      <div [ngClass]="{ 'val-changed': hasChangedAddress('country') }" name="countryCode" class="div-custom-input">
        {{ countryCode }}
      </div>
    </div>

    <app-mat-select
      [name]="'COMPANY.CREATE.LANGUAGE'"
      [placeholder]="'COMPANY.CREATE.LANGUAGE'"
      [label]="'COMPANY.CREATE.LANGUAGE'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.language"
      [controlName]="'language'"
      [approvedValue]="getApprovedValue('language')"
      [taskValue]="companyTask ? companyTask.language : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="languages"
      [optionValue]="'code'"
      [selectedOption]="selectedCountryCode"
      [translationOption]="'code'"
      [optionTranslationText]="''"
      [disabled]="isDraftExists"
    ></app-mat-select>

    <app-mat-slider
      [name]="'COMPANY.BANK-ACCOUNT.IS-CREATED-EXTERNALLY'"
      [label]="'COMPANY.BANK-ACCOUNT.IS-CREATED-EXTERNALLY'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.createdExternally"
      [controlName]="'createdExternally'"
      [approvedValue]="companyApproved ? companyApproved.createdExternally : null"
      [taskValue]="companyTask ? companyTask.createdExternally : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [disabled]="true"
    ></app-mat-slider>
    <app-mat-input
      [name]="'COMPANY.CREATE.ADDRESS.ADDITIONAL-INFORMATION'"
      [placeholder]="'COMPANY.CREATE.ADDRESS.ADDITIONAL-INFORMATION'"
      [label]="'COMPANY.CREATE.ADDRESS.ADDITIONAL-INFORMATION'"
      [entityName]="entityName"
      [form]="companyForm"
      [controlObject]="companyForm.controls.address['controls'].additionalInformation"
      [controlName]="'additionalInformation'"
      [approvedValue]="getApprovedValue('additionalInformation', 'address')"
      [taskValue]="companyTask ? companyTask.address.additionalInformation : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="'address'"
    >
    </app-mat-input>
  </form>
</div>
