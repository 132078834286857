<span class="dynamic-loader-table" *ngIf="loaderSpinner">
  <mat-spinner [diameter]="50"></mat-spinner>
</span>
<app-default-table
  *ngIf="dataLoaded && !loaderSpinner"
  [entityName]="entityName"
  [configurationKey]="configurationKey"
  [columns]="columns"
  [displayedColumns]="displayedColumns"
  [originalColumns]="columns"
  [originalDisplayedColumns]="displayedColumns"
  [tableDataSource]="relationshipData"
  [editorStrategy]="tableEditor"
  (filterEvent)="filter($event)"
  [totalCount]="relationshipData.total"
  [distinctValues]="getDistinctValues"
></app-default-table>
