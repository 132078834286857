export const transactionTypes: any[] = [
  {
    name: 'OBJECTION',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.OBJECTION',
  },
  {
    name: 'CLASSIFICATION',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.CLASSIFICATION',
  },
  {
    name: 'ALLOCATION',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.ALLOCATION',
  },
  {
    name: 'COMMENT',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.COMMENT',
  },
  {
    name: 'ATTACHMENT',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.ATTACHMENT',
  },
  {
    name: 'DEDUCTION',
    translateName: 'TRANSACTION.HISTORY.FILTERS.TYPES.DEDUCTION',
  },
];
