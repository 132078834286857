import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountStatementFileProcessingService {
  private readonly serviceUrl;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.openItemService;
  }

  uploadFile(fileToUpload: File, selectedItem: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('fileType', selectedItem);
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'accountStatement/upload',
      formData
    );
    return this.http.request(req);
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
