export const systemConfigurationsColumnsConf: any[] = [
  {
    name: 'visibleId',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-ID',
    locked: false,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-NAME',
    locked: false,
  },
  {
    name: 'description',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DESCRIPTION',
    locked: false,
  },
  {
    name: 'defaultValue',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.CURRENT-VALUE',
    locked: false,
  },
  {
    name: 'validFrom',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM',
    locked: false,
  },
  {
    name: 'editedBy',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.EDITED-BY',
    locked: false,
  },
  {
    name: 'lastEdit',
    index: 6,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.LAST-EDIT',
    locked: false,
  },
  {
    name: 'approvedBy',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-BY',
    locked: false,
  },
  {
    name: 'approvedOn',
    index: 8,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-ON',
    locked: false,
  },
  {
    version: 1,
  },
];
