import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-password-policy-modal',
  templateUrl: './password-policy-modal.component.html',
  styleUrls: ['./password-policy-modal.component.less'],
})
export class PasswordPolicyModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PasswordPolicyModalComponent>
  ) {}
}
