<div class="search-container">
  <div class="search-row" [formGroup]="searchForm">
    <div class="infinite-scroll-search-wrapper">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
        <mat-select
          formControlName="partner"
          (infiniteScroll)="onScroll()"
          msInfiniteScroll
          [(value)]="selectedPartner"
          (openedChange)="_openChanged($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="{{ 'search' | translate }}"
              noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
              formControlName="partnerSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let option of options$ | async" [value]="option" (click)="selectedPartner = option">
            <span> {{ option.visibleId }} {{ option.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button
      mat-button
      class="custom-button search-button"
      id="{{ entityName }}-search-button"
      (click)="openSearchDialog()"
      [disabled]="disabledButton"
    >
      <span class="material-icons"> search </span>
    </button>
  </div>
</div>
