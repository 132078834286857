<div class="dialog-content">
  <div [hidden]="loader">
    <div [hidden]="availableTransactions != true">
      <div class="filters" [formGroup]="filterForm">
        <div class="filter-input">
          <mat-form-field appearance="fill" id="{{ entityName }}-search-client">
            <mat-label>{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.MODAL.TRANSACTIONS' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'search' | translate }}"
              name="name"
              formControlName="searchString"
              type="text"
              [removeHtmlTags]="filterForm.controls.searchString"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <table
            aria-hidden="true"
            mat-table
            [dataSource]="dataSource"
            matSort
            id="{{ entityName }}-search-results-table"
            class="general-table-alignment"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"
                  [aria-label]="checkboxLabel(element)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="client">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th number-row-header"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-client"
              >
                {{ 'MASTER-DATA.CLIENT' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-client-{{ paginator.pageIndex + 1 }}-{{ i }}"
                class="number-row"
              >
                {{ element.clientDebtorId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="bookingCode">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th number-row-header"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-bookingCde"
              >
                {{ 'MASTER-DATA.BOOKING-CODE' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-bookingCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
                class="number-row"
              >
                {{ element.bookingCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="openItemId">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-openItemId"
              >
                {{ 'DEBTOR.ACCOUNT-OVERVIEW.DOCUMENT-NUMBER' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-openItemId-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.clientOpenItemId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="transactionId">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-openItemId"
              >
                {{ 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-transactionId-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{ element.id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="openAmount">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th number-row-header"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-openAmount"
              >
                {{ 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-openItemId-{{ paginator.pageIndex + 1 }}-{{ i }}"
                class="number-row"
              >
                {{ element.openAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="debitOrCredit">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="v25-th"
                (click)="paginator.firstPage()"
                id="{{ entityName }}-table-results-debitOrCredit"
              >
                {{ 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                id="{{ entityName }}-table-results-debitOrCredit-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                {{
                  'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT-VALUES.' + '' + element.creditDebitCode.toString()
                    | translate
                }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>
          </table>
          <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>

    <div class="no-transactions-container" [hidden]="availableTransactions == true">
      <div mat-dialog-content class="background-dark">
        <span>
          {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.MODAL.EMPTY-RESULTS' | translate }}
        </span>
      </div>
    </div>

    <div class="buttons">
      <button
        id="{{ entityName }}-transactions-modal-close-button"
        mat-button
        class="custom-button-cancel"
        [mat-dialog-close]
      >
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
      <button
        id="{{ entityName }}-transactions-modal-submit-button"
        mat-button
        class="custom-button"
        id="acquire"
        [disabled]="selection.isEmpty()"
        [mat-dialog-close]="selection.selected"
        style="margin-right: 0"
      >
        {{ 'GENERAL.CONFIRM' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="loader" class="loader-container">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
