import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await keycloak.init({
            config: environment.keycloak,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false,
            },
            bearerExcludedUrls: [],
            bearerPrefix: 'Bearer',
          })
        );
      } catch (error) {
        reject(error);
      }
    });
  };
}
