import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { DashboardManagementRoutingModule } from './dashboard-management-routing.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DashboardUserGeneralInformationComponent } from './dashboard/dashboard-user-general-information/dashboard-user-general-information.component';
import { DashboardWelcomeComponent } from './dashboard/dashboard-welcome/dashboard-welcome.component';
import { DashboardContactUsComponent } from './dashboard/dashboard-contact-us/dashboard-contact-us.component';
import { DashboardQuaterlySettlementComponent } from './dashboard/dashboard-quaterly-settlement/dashboard-quaterly-settlement.component';
import { DashboardCalendarComponent } from './dashboard/dashboard-calendar/dashboard-calendar.component';
import { DashboardManualComponent } from './dashboard/dashboard-manual/dashboard-manual.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [
    DashboardManagementComponent,
    DashboardComponent,
    MessageListComponent,
    DashboardUserGeneralInformationComponent,
    DashboardWelcomeComponent,
    DashboardContactUsComponent,
    DashboardQuaterlySettlementComponent,
    DashboardCalendarComponent,
    DashboardManualComponent,
  ],
  imports: [
    CommonModule,
    DashboardManagementRoutingModule,
    MatTabsModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSidenavModule,
    MatIconModule,
    MatStepperModule,
  ],
})
export class DashboardManagementModule {}
