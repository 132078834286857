<div class="task-filters-container">
  <mat-accordion class="margin-accordion">
    <mat-expansion-panel id="{{ entityName }}-expansionPanel" [expanded]="favFName == ''">
      <mat-expansion-panel-header id="{{ entityName }}-expansionPanel-header">
        <mat-panel-title>
          <span class="subheading filters-title">{{ 'GENERAL.SEARCH' | translate }}</span>
        </mat-panel-title>
        <mat-panel-description>
          <span *ngIf="favFName == ''">{{ 'GENERAL.SELECT-FILTER' | translate }}</span>
          <span *ngIf="favFName != ''">{{ favFName }}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="filter-params fav-filters" [formGroup]="filter">
        <div class="header">
          <div class="fav-filter-param">
            <mat-form-field
              id="{{ entityName }}-favFilters-input-favFilterName"
              *ngIf="favFiltersNames && favFiltersNames.length > 0"
            >
              <mat-label>{{ 'TASK.FAV-FILTERS.FILTER-NAME' | translate | uppercase }}</mat-label>
              <mat-select
                formControlName="name"
                placeholder="{{ 'task.favFilters.filterName' | translate | uppercase }}"
                name="favFilterName"
                (selectionChange)="changeFilterName($event)"
              >
                <mat-select-trigger>
                  {{ filter.value.name | translate }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let item of favFiltersNames | sortBy: { property: null, descending: false }"
                  [value]="item"
                >
                  <div>
                    {{ item | translate }}
                    <button class="fav-filter-star" mat-button matSuffix mat-icon-button *ngIf="checkIfFavorite(item)">
                      <mat-icon>grade</mat-icon>
                    </button>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button
            mat-button
            id="{{ entityName }}-favFilters-button-save"
            class="custom-button custom-button-favorite"
            (click)="saveFavorite()"
            *ngIf="save"
          >
            <span>{{ 'SAVE' | translate }}</span>
          </button>
          <button
            mat-button
            id="{{ entityName }}-favFilters-button-saveAs"
            class="custom-button custom-button-favorite"
            (click)="saveAsFavorite()"
            *ngIf="saveAs && !save"
          >
            <span>{{ 'GENERAL.SAVE-AS' | translate }}</span>
          </button>
          <button
            *ngIf="favFiltersNames && favFiltersNames.length > 0"
            id="{{ entityName }}-favFilters-more"
            class="custom-cancel-button"
            mat-button
            [matMenuTriggerFor]="menu"
          >
            <span class="material-icons"> more_vert </span>
          </button>
          <div class="fav-filter-param">
            <button
              title="{{ 'task.favFilters.tooltipFavorite' | translate }}"
              class="button favButton"
              id="{{ entityName }}-favFilters-button-favorites"
            >
              <mat-icon class="material-icons" *ngIf="favoriteFilter" (click)="changeFavorite($event, false)"
                >grade</mat-icon
              >
              <mat-icon class="material-icons-outlined" *ngIf="!favoriteFilter" (click)="changeFavorite($event, true)"
                >grade</mat-icon
              >
            </button>
          </div>
        </div>
        <mat-menu #menu="matMenu">
          <button
            id="{{ entityName }}-favFilters-button-saveAs"
            mat-menu-item
            *ngIf="saveAs && save"
            (click)="saveAsFavorite()"
          >
            <span>{{ 'GENERAL.SAVE-AS' | translate }}</span>
          </button>

          <button
            id="{{ entityName }}-favFilters-button-delete"
            mat-menu-item
            (click)="deleteFilter()"
            *ngIf="favFiltersNames && favFiltersNames.length > 0"
          >
            <span>{{ 'DELETE' | translate }}</span>
          </button>
        </mat-menu>

        <div class="filters">
          <div class="filter-input">
            <mat-form-field>
              <mat-label>{{ 'TASK.SEARCH' | translate }}</mat-label>
              <input
                autocomplete="off"
                (change)="checkFiltersGeneric()"
                id="{{ entityName }}-search-input-name"
                matInput
                placeholder="{{ 'search' | translate }}"
                formControlName="searchString"
                type="text"
                [removeHtmlTags]="filter.controls.searchString"
              />
            </mat-form-field>
          </div>
          <div class="filter-input longer-input">
            <mat-form-field id="{{ entityName }}-search-input-taskType">
              <mat-label>{{ 'TASK.TYPE' | translate }}</mat-label>
              <mat-select
                formControlName="taskType"
                multiple
                placeholder="{{ 'select' | translate }}"
                name="taskType"
                #typeSelect
                (openedChange)="opened($event, 'type')"
              >
                <mat-option
                  class="loader-option"
                  Pgen
                  [ngStyle]="{
                    display: filterInitialized['type'] ? 'none' : 'block'
                  }"
                >
                  <mat-spinner [diameter]="20"></mat-spinner>
                </mat-option>
                <mat-option
                  [ngStyle]="{
                    display: !filterInitialized['type'] ? 'none' : 'flex'
                  }"
                  *ngFor="let item of allFilters['type'] | sortBy: { property: null, descending: false }"
                  [value]="item"
                  id="{{ entityName }}-task-type-{{ item }}"
                  (onSelectionChange)="checkOriginalFilters($event)"
                >
                  {{ item | translate }}
                </mat-option>
              </mat-select>
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                *ngIf="selectedTaskType"
                (click)="selectedTaskType = undefined; resetTaskType(); $event.stopPropagation()"
                id="{{ entityName }}-search-input-taskType-clear"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="filter-input">
            <mat-form-field id="{{ entityName }}-custom-filter-team-input">
              <mat-label>{{ 'TASK.TEAM' | translate }}</mat-label>
              <mat-select
                placeholder="{{ 'TASK.TEAM' | translate }}"
                multiple
                formControlName="teamId"
                (openedChange)="opened($event, 'team_id')"
              >
                <mat-option
                  class="loader-option"
                  [ngStyle]="{
                    display: filterInitialized['team_id'] ? 'none' : 'block'
                  }"
                  ><span> <mat-spinner [diameter]="20"></mat-spinner> </span
                ></mat-option>
                <mat-option
                  [ngStyle]="{
                    display: !filterInitialized['team_id'] ? 'none' : 'flex'
                  }"
                  *ngFor="let option of allFilters['team_id']"
                  [value]="option"
                  (onSelectionChange)="checkOriginalFilters($event)"
                >
                  {{ option | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="filter-input">
            <mat-form-field id="{{ entityName }}-search-input-custom-filters">
              <mat-label>{{ 'TASK.MORE' | translate }}</mat-label>

              <mat-select
                formControlName="customFilters"
                multiple
                placeholder="{{ 'select' | translate }}"
                name="customFilters"
              >
                <mat-option
                  (onSelectionChange)="change($event)"
                  *ngFor="let item of filterSelectObj | sortBy: { property: 'name', descending: false }"
                  [value]="item"
                >
                  {{ item.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngFor="let item of filter.get('customFilters').value">
            <div class="filter-input">
              <mat-form-field id="{{ entityName }}-custom-filter-{{ item.columnProp }}-input">
                <mat-label>{{ item.name | translate }}</mat-label>
                <mat-select
                  *ngIf="item.type == 'text'"
                  placeholder="{{ item.name | translate }}"
                  multiple
                  formControlName="filter-more.{{ item.columnProp }}"
                  (openedChange)="opened($event, item.columnProp)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="{{ 'search' | translate }}"
                      noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                      formControlName="filter-more.searchString.{{ item.columnProp }}"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    class="loader-option"
                    [ngStyle]="{
                      display: filterInitialized[item.columnProp] ? 'none' : 'block'
                    }"
                    ><span>
                      <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                  </mat-option>
                  <mat-option
                    [ngStyle]="{
                      display: !filterInitialized[item.columnProp] ? 'none' : 'flex'
                    }"
                    *ngFor="let option of allFilters[item.columnProp]"
                    [value]="option"
                    (onSelectionChange)="checkOriginalFilters($event)"
                  >
                    {{ item.translatePrefix + option | translate }}
                  </mat-option>
                </mat-select>

                <mat-select
                  *ngIf="item.type == 'user'"
                  placeholder="{{ item.name | translate }}"
                  multiple
                  formControlName="filter-more.{{ item.columnProp }}"
                  (openedChange)="opened($event, item.columnProp)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="{{ 'search' | translate }}"
                      noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                      formControlName="filter-more.searchString.{{ item.columnProp }}"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let option of allFilters[item.columnProp] | sortBy: { property: null, descending: false }"
                    [value]="option"
                    (onSelectionChange)="checkOriginalFilters($event)"
                  >
                    {{ getUserInfo(option) }}
                  </mat-option>
                </mat-select>

                <mat-select
                  *ngIf="item.type == 'bu'"
                  placeholder="{{ item.name | translate }}"
                  multiple
                  formControlName="filter-more.{{ item.columnProp }}"
                  (openedChange)="opened($event, item.columnProp)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="{{ 'search' | translate }}"
                      noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                      formControlName="filter-more.searchString.{{ item.columnProp }}"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let option of allFilters[item.columnProp]"
                    [value]="option"
                    (onSelectionChange)="checkOriginalFilters($event)"
                  >
                    {{ getBusinessUnit(option) }}
                  </mat-option>
                </mat-select>

                <div *ngIf="item.type == 'date'">
                  <mat-date-range-input
                    [rangePicker]="datePicker"
                    id="{{ entityName }}-{{ item.columnProp }}-custom-filter-duration-input-start-end"
                    [formGroup]="creationDateFilterForm.controls.searchDate"
                  >
                    <input
                      matStartDate
                      class="begin"
                      formControlName="begin"
                      id="{{ entityName }}-{{ item.columnProp }}-custom-filter-duration-input-start"
                      placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      id="{{ entityName }}-{{ item.columnProp }}-custom-filter-duration-input-end"
                      placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="datePicker"
                    class="creation-date-toggler"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #datePicker></mat-date-range-picker>
                </div>
              </mat-form-field>
            </div>
          </div>
          <div class="filter-input">
            <label for="onlyMySlider">{{ 'TASK.ONLY-MINE' | translate }}</label>
            <mat-slide-toggle
              (change)="checkFiltersGeneric()"
              name="onlyMySlider"
              formControlName="onlyMyTasks"
              id="{{ entityName }}-search-input-onlyMyTasks"
              [ngClass]="{ iePositionIcon: isIE }"
            >
              <span class="material-icons material-check-icon"> done </span>
            </mat-slide-toggle>
          </div>

          <div class="filter-input">
            <label for="onlyActiveSlider">{{ 'TASK.ONLY-OPEN' | translate }}</label>
            <mat-slide-toggle
              (change)="checkFiltersGeneric()"
              name="onlyActiveSlider"
              formControlName="onlyActive"
              id="{{ entityName }}-search-input-onlyActivity"
              [ngClass]="{ iePositionIcon: isIE }"
            >
              <span class="material-icons material-check-icon"> done </span>
            </mat-slide-toggle>
          </div>

          <div class="filter-input">
            <label for="onlyLockedSlider">{{ 'TASK.ONLY-LOCKED' | translate }}</label>
            <mat-slide-toggle
              (change)="checkFiltersGeneric()"
              name="onlyLockedSlider"
              formControlName="onlyLocked"
              id="{{ entityName }}-search-input-onlyLocked"
              [ngClass]="{ iePositionIcon: isIE }"
            >
              <span class="material-icons material-check-icon"> done </span>
            </mat-slide-toggle>
          </div>

          <div class="filter-input button-container">
            <button mat-button class="custom-button" (click)="resetForm()" id="{{ entityName }}-search-clear">
              {{ 'RESET' | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
