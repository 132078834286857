export const businessPartnerSelectionColumnsConf: any[] = [
  {
    name: 'visibleId',
    index: 1,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.ID',
    locked: true,
  },
  {
    name: 'externalId',
    index: 2,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.EXTERNAL-ID',
    locked: true,
  },
  {
    name: 'name',
    index: 3,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.NAME',
    locked: false,
  },
  {
    name: 'address',
    index: 4,
    checked: true,
    translateName: 'OPEN-TRANSACTIONS.PARTNER-TYPE-MODAL-TABLE.ADDRESS',
    locked: false,
  },
  {
    version: '2',
  },
];
