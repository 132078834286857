export const transactionCodeOptions: any[] = [
  {
    value: 'CREDIT',
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.CREDIT-LETTER',
  },
  {
    value: 'DEBIT',
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.DEBIT-LETTER',
  },
];
