export const budgetStatusOptions: any[] = [
  {
    value: 'RESERVED',
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-OPTIONS.RESERVED',
  },
  {
    value: 'ALLOCATED',
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-OPTIONS.ALLOCATED',
  },
];
