export const foreignCountryFields: any[] = [
  {
    name: 'country',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.COUNTRY',
  },
  {
    name: 'isoCode',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.ISO-CODE',
  },
  {
    name: 'rateOfCompensationNamed',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.RATE-OF-COMPENSATION-NAMED',
    maskValue: 'percent',
  },
  {
    name: 'rateOfCompensationUnnamed',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.RATE-OF-COMPENSATION-UNNAMED',
    maskValue: 'percent',
  },
  {
    name: 'unnamedArea',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.UNNAMED-AREA',
    maskValue: 'currency',
  },
  {
    name: 'protractedDefault',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.PROTRACTED-DEFAULT',
  },
  {
    name: 'waitingPeriodProtractedDefault',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.WAITING-PERIOD-PROTRACTED-DEFAULT',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'premiumRate',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.PREMIUM-RATE',
    maskValue: 'percent',
  },
  {
    name: 'maximumTermOfPayment',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.MAXIMUM-TERM-OF-PAYMENT',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'creditLimitExceeded',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.CREDIT-LIMIT-EXCEEDED',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'gracePeriodThreatOfDamage',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.GRACE-PERIOD-THREAT-DAMAGE',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'cutOffPeriodCollectionOrder',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.CUT-OFF-PERIOD-COLLECTION-ORDER',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'waitingPeriodCompensationInsurance',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.WAITING-PERIOD-COMPENSATION-INSURANCE',
    maskValue: 'TCI.CREATE.MASKS.DAY',
  },
  {
    name: 'approvalLimit',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.APPROVAL-LIMIT',
    maskValue: 'currency',
  },
  {
    name: 'creditCheck',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.CREDIT-CHECK',
    maskValue: 'currency',
  },
  {
    name: 'singleCheckFeeAmount',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.SINGLE-CHECK-FEE-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'singleCheckFee',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.SINGLE-CHECK-FEE',
    maskValue: 'currency',
  },
  {
    name: 'informationCurrentLargestIndividualLimit',
    translateName:
      'TCI.CREATE.FOREIGN-COUNTRY-VALUES.INFORMATION-CURRENT-LARGEST-INDIVIDUAL-LIMIT',
    maskValue: 'currency',
  },
  {
    name: 'decisionContract',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.DECISION-CONTRACT',
  },
  {
    name: 'decisionReason',
    translateName: 'TCI.CREATE.FOREIGN-COUNTRY-VALUES.DECISION-REASON',
  },
  {
    name: 'comment',
    translateName: 'GENERAL.COMMENT',
  },
];
