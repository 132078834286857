export const transactionTypes: any[] = [
  {
    code: 1,
    name: 'CREDIT_NOTE',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.CREDIT-NOTE',
  },
  {
    code: 2,
    name: 'INVOICE',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.INVOICE',
  },
  {
    code: 3,
    name: 'SETTLEMENT',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.SETTLEMENT',
  },
  {
    code: 4,
    name: 'DEDUCTIONS',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.DEDUCTIONS',
  },
  {
    code: 5,
    name: 'WRITE_OFF',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.WRITE_OFF',
  },
  {
    code: 6,
    name: 'REVENUES',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.REVENUES',
  },
  {
    code: 7,
    name: 'FEES',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.FEES',
  },
  {
    code: 8,
    name: 'ANNUAL_SETTLEMENT',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.ANNUAL_SETTLEMENT',
  },
  {
    code: 9,
    name: 'CONDITION',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.CONDITION',
  },
  {
    code: 10,
    name: 'CANCELLATION_INHOUSE',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.CANCELLATION_INHOUSE',
  },
  {
    code: 11,
    name: 'TRANSFER',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.TRANSFER',
  },
  {
    code: 12,
    name: 'OUTGOING_PAYMENT',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.OUTGOING_PAYMENT',
  },
  {
    code: 13,
    name: 'INCOMING_PAYMENT',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.INCOMING_PAYMENT',
  },
  {
    code: 14,
    name: 'INTEREST',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.INTEREST',
  },
  {
    code: 15,
    name: 'CLAIM_SETTLEMENT',
    translateName: 'BOOKING-CODE.TRANSACTION-TYPES.CLAIM_SETTLEMENT',
  },
];
