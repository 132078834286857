export const AWPaymentAllocationColumns: string[] = [
  'select',
  'creditDebitCode',
  'transactionCode',
  'transactionCodeName',
  'documentationDate',
  'amount',
  'vatPercent',
  'vatAmount',
  'dueDate',
  'actions',
];
