import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { beasRelevantOptions } from 'src/app/in-memory-data/system-configuration/account-statement-configuration/formConfiguration/beasRelevant';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { AccountStatementConfigurationService } from 'src/app/shared/services/account-statement-configuration/account-statement-configuration.service';
import { BusinessTransactionCodeMappingService } from 'src/app/shared/services/business-transaction-code-mapping/business-transaction-code-mapping.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { ClientService } from 'src/app/shared/services/client/client.service';
import { DebtorService } from 'src/app/shared/services/debtor/debtor.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { PlatformService } from 'src/app/shared/services/platform/platform.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { DirtyComponent } from '../../../shared/models/dirtyComponent';

@Component({
  selector: 'app-account-statement-configuration-create',
  templateUrl: './account-statement-configuration-create.component.html',
  styleUrls: ['./account-statement-configuration-create.component.less'],
})
export class AccountStatementConfigurationCreateComponent
  implements OnInit, DirtyComponent
{
  entityName = 'account-statement-configuration';
  accountStatementForm: UntypedFormGroup;
  edit = false;
  draftId: number;
  editableTask: boolean;
  isTask = false;
  approval = false;
  draftSave: boolean;
  operationUser: any;
  userList: any;
  editMode: any;
  currencyCodes: any;
  accountStatementApproved: any;
  accountStatements: any[];
  beasRelevantOptions = beasRelevantOptions;
  accountStatementTask: any;
  btcMappingList: any;
  businessUnitList: any;
  clientList: any;
  platformList: any;
  debtorList: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private matDialogService: MatDialog,
    private accountStatementConfigurationService: AccountStatementConfigurationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private userService: UserService,
    private draftService: DraftService,
    private taskService: TaskService,
    private businessTransactionCodeMappingService: BusinessTransactionCodeMappingService,
    private buService: BusinessUnitService,
    private platformService: PlatformService,
    private clientService: ClientService,
    private debtorService: DebtorService
  ) {
    const cc = require('currency-codes');
    this.currencyCodes = cc.codes();

    this.accountStatementForm = this.formBuilder.group({
      id: [null],
      responsibleTeam: [''],
      account: ['', Validators.required],
      name: ['', Validators.required],
      beasRelevant: ['', Validators.required],
      fiAccount: [null],
      currency: ['EUR', Validators.required],
      businessUnitId: [null, Validators.required],
      platformId: [null],
      clientId: [null],
      debtorId: [null],
      btcMapping: ['', Validators.required],
      comment: [''],
      visibleSeqId: [null],
    });

    this.accountStatementConfigurationService
      .getAccountStatements()
      .subscribe((data) => {
        this.accountStatements = data;
      });

    this.getAllBussinessTransactionCodeMapping();
    this.getAllBusinessUnits();

    this.accountStatementForm
      .get('businessUnitId')
      .valueChanges.subscribe((data) => {
        this.loadPlatforms(data);
      });

    this.accountStatementForm
      .get('platformId')
      .valueChanges.subscribe((data) => {
        this.loadClients(data);
        this.loadDebtors(data);
      });
  }

  ngOnInit(): void {
    const state = window.history.state;

    if (state.accountStatementConfiguration) {
      this.accountStatementApproved = state.accountStatementConfiguration;
      this.edit = true;
      this.accountStatementForm.patchValue(state.accountStatementConfiguration);

      this.draftService
        .approvalDraftExistsForEntityId(this.accountStatementApproved.id)
        .subscribe(
          (doesDraftExist) => {
            if (doesDraftExist) {
              this.accountStatementForm.disable({
                onlySelf: true,
                emitEvent: false,
              });
              this.notificationService.showToastMessage(
                'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
                this.notificationService.MESSAGE_TYPE.INFO
              );
            } else {
              this.draftService
                .retrieveLocalEntityDraft(this.accountStatementApproved.id)
                .subscribe((draft) => {
                  this.accountStatementForm.disable({
                    onlySelf: true,
                    emitEvent: false,
                  });
                  this.notificationService.showToastMessage(
                    'GENERAL-ENTITY.CREATE.MESSAGES.TOAST-MESSAGE-DRAFT-EXISTS',
                    this.notificationService.MESSAGE_TYPE.INFO
                  );
                });
            }
          },
          (err) => this.handleError(err)
        );

      if (this.accountStatementApproved.draft) {
        this.draftId = this.accountStatementApproved.draftId;
      }
    } else if (state.task) {
      this.isTask = true;

      if (state.task.params && state.task.params.entityId) {
        this.accountStatementConfigurationService
          .retrieveApproved(state.task.params.entityId)
          .subscribe((result) => {
            this.accountStatementTask = result;
            this.accountStatementForm.patchValue(this.accountStatementTask);
          });
      } else {
        this.draftService
          .retrieveDraftForTask(state.task.guid)
          .subscribe((result) => {
            this.accountStatementTask = result;
            this.accountStatementForm.patchValue(this.accountStatementTask);

            if (this.accountStatementTask.id) {
              this.accountStatementConfigurationService
                .retrieveApproved(this.accountStatementTask.id)
                .subscribe((accountStatement) => {
                  this.accountStatementApproved = accountStatement;
                });
            }
          });
      }

      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );

      if (sessionStorage.getItem('taskModify') === 'true') {
        this.taskService.updateEditableTask(true);
      }
    }

    this.accountStatementForm
      .get('beasRelevant')
      .valueChanges.subscribe((data) => {
        this.setFIAccount(data);
      });
  }

  canDeactivate() {
    return this.accountStatementForm.dirty && !this.approval && !this.draftSave;
  }

  setFIAccount(data) {
    if (!data) {
      this.accountStatementForm.get('fiAccount').setValue(null);
      this.accountStatementForm.get('fiAccount').clearValidators();
    } else {
      this.accountStatementForm
        .get('fiAccount')
        .setValidators([Validators.required]);
    }
    this.accountStatementForm.get('fiAccount').updateValueAndValidity();
  }

  changeEditMode() {
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/accountStatementConfiguration',
      { state: {} }
    );
  }

  onClickSave() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        this.approval = true;
        const json = {
          comment: result.comment,
          accountStatementConfiguration: this.accountStatementForm.value,
        };

        if (!this.edit || this.draftId) {
          this.accountStatementConfigurationService
            .saveAccountStatement(json)
            .subscribe((_) => {
              this.approval = true;
              if (this.draftId) {
                this.draftService.deleteUserDraft(this.draftId).subscribe();
              }
              this.notificationService.showToast(
                this.notificationService.DEFAULT_MESSAGES.CREATE_ENTITY,
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant(
                    'ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION'
                  ),
                }
              );
              setTimeout(() => {
                this.changeEditMode();
              }, 1500);
            }, this.customErrorHandler._errorHandler('ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION'));
        } else {
          this.accountStatementConfigurationService
            .updateAccountStatement(json)
            .subscribe((_) => {
              this.approval = true;
              if (this.draftId) {
                this.draftService.deleteUserDraft(this.draftId).subscribe();
              }
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant(
                    'ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION'
                  ),
                }
              );
              setTimeout(() => {
                this.changeEditMode();
              }, 1500);
            }, this.customErrorHandler._errorHandler('ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION'));
        }
      }
    });
  }

  getAllBussinessTransactionCodeMapping() {
    this.businessTransactionCodeMappingService
      .getBusinessTransactionCodesMapping()
      .subscribe((data) => {
        this.btcMappingList = data;
      });
  }

  getAllBusinessUnits() {
    this.buService
      .getAllBusinessUnits('id', 'asc', 1, 99999)
      .subscribe((data) => {
        this.businessUnitList = data.content;
      });
  }

  loadPlatforms(businessUnitId) {
    this.platformService.listApprovedByBU(businessUnitId).subscribe((data) => {
      this.platformList = data;
    });
  }

  loadClients(platformId) {
    this.clientService.retrieveByPlatform(platformId).subscribe((data) => {
      this.clientList = data;
    });
  }

  loadDebtors(platformId) {
    this.debtorService.getDebtorsForPlatform(platformId).subscribe((data) => {
      this.debtorList = data;
    });
  }

  deleteAccountStatement() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        const request = {
          id: this.accountStatementApproved.id,
          comment: data.comment,
        };
        this.accountStatementConfigurationService
          .deleteAccountStatementConfiguration(request)
          .subscribe(() => {
            this.notificationService.showToast(
              'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-ENTRY-TASK',
              this.notificationService.MESSAGE_TYPE.SUCCESS
            );

            setTimeout(() => {
              this.router.navigateByUrl('/tasks');
            }, 2000);
          });
      }
    });
  }

  changeEditTaskMode(value: boolean) {
    this.taskService.updateEditableTask(value);
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  saveAsDraft() {
    const draftId = this.draftId;
    const element = this.accountStatementForm.value;
    const request = {
      entityId: element.id,
      entityType: 'ACCOUNT_STATEMENT_CONFIGURATION',
      element,
      id: draftId,
      entityGuid: this.accountStatementApproved
        ? this.accountStatementApproved.id
        : null,
    };
    const update = !!draftId;
    this.draftService.saveUserDraft(request).subscribe((_) => {
      this.draftSave = true;
      if (update) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          null
        );
      } else {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
      setTimeout(() => {
        this.changeEditMode();
      }, 1500);
    }, this.customErrorHandler._errorHandler('ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION'));
  }
}
