<div class="table-list">
  <h5 id="{{ entityName }}-search-results-title-draft">
    {{ 'GENERAL.DRAFTS' | translate }}
  </h5>
  <div class="filters" [formGroup]="filterForm">
    <div class="filter-input">
      <app-mat-input
        [name]="'GENERAL.CREATED-BY'"
        [placeholder]="'GENERAL.CREATED-BY'"
        [label]="'GENERAL.CREATED-BY'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.createdBy"
        [controlName]="'createdBy'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
      ></app-mat-input>
    </div>

    <div class="filter-input">
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-BY'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-BY'"
        [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-BY'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.editedBy"
        [controlName]="'editedBy'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
      >
      </app-mat-input>
    </div>

    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-FROM' | translate }}</mat-label>
        <input
          autocomplete="off"
          id="form-last-edited-from"
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-FROM' | translate }}"
          name="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.DRAFTS.LAST-EDITED-FROM' | translate }}"
          formControlName="editedFrom"
          [matDatepicker]="datePickerFrom"
        />
        <mat-datepicker-toggle matSuffix [for]="datePickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #datePickerFrom></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-TO' | translate }}</mat-label>
        <input
          autocomplete="off"
          id="form-last-edited-to"
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-TO' | translate }}"
          name="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.DRAFTS.LAST-EDITED-TO' | translate }}"
          formControlName="editedTo"
          [matDatepicker]="datePickerTo"
        />
        <mat-datepicker-toggle matSuffix [for]="datePickerTo"></mat-datepicker-toggle>
        <mat-datepicker #datePickerTo></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="filter-input">
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchDraft()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>
  <table
    aria-hidden="true"
    mat-table
    [dataSource]="dataSource"
    matSort
    id="{{ entityName }}-search-results-table-draft"
    class="general-table-alignment"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
        <mat-checkbox
          id="{{ entityName }}-table-results-select-draft-all"
          (change)="$event ? masterToggle(true) : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox
          id="{{ entityName }}-table-results-select-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
          [aria-label]="checkboxLabel(element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-created-by-draft"
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CREATED-BY' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-created-by-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.createdBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checkSumQuantity">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th number-row-header"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-check-sum-quantity-draft"
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CHECK-SUM-QUANTITY' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-check-sum-quantity-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
        class="number-row"
      >
        {{ getCheckSumQuantity(element) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="checkSum">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th number-row-header"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-check-sum-draft"
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CHECK-SUM' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-check-sum-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
        class="number-row"
      >
        {{ this.getCheckSum(element) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastEditedOn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-last-edited-on-draft"
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-ON' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-last-edited-on-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.operationDate | date: dateFormat + ' HH:mm:ss' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastEditedBy">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-last-edited-by-draft"
      >
        {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-BY' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-last-edited-by-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.operationUser }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions-draft">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-draft-button">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          class="menu"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ element: element }"
          (click)="$event.stopPropagation(); selectTheRow(element)"
          id="{{ entityName }}-table-results-actions-draft-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span class="material-icons"> more_vert </span>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr [hidden]="loaderSpinner" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
  </table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container>
      <button
        mat-menu-item
        (click)="confirmationDeleteDraft(element.draftGuid)"
        id="{{ entityName }}-table-results-action-menu-delete-draft-button"
      >
        <span class="material-icons icon-menu"> delete_outline </span>
        <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
      </button>
    </ng-container>
    <ng-container>
      <ng-container>
        <button
          mat-menu-item
          (click)="editSelectedRows(element)"
          id="{{ entityName }}-table-results-action-menu-edit-draft-button"
        >
          <img
            class="icon-menu"
            src="assets/images/ic_edit.svg"
            alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
          />
          <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
