import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientDisbursementService {
  private serviceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.openItemService;
  }

  findAllByClient(clientId): Observable<any> {
    return this.http.get(this.serviceUrl + 'disbursements', {
      params: {
        clientId,
      },
      headers: this.buildHeaders().headers,
    });
  }

  findById(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'disbursements/' + id,
      this.buildHeaders()
    );
  }

  findAllHistoryById(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'disbursements/' + id + '/history',
      this.buildHeaders()
    );
  }

  findByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId,
      this.buildHeaders()
    );
  }

  createDisbursement(taskId: number, data: any): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskId + '/complete-task-33',
      data,
      this.buildHeaders()
    );
  }

  approveDisbursement(taskId: number, data: any): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskId + '/complete-task-35',
      data,
      this.buildHeaders()
    );
  }

  lockDisbursement(id: number): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'disbursements/' + id + '/lock',
      this.buildHeaders()
    );
  }

  unlockUserDisbursement(): Observable<any[]> {
    return this.http.post<any[]>(
      this.serviceUrl + 'disbursements/unlockedUserAllLocked',
      {},
      this.buildHeaders()
    );
  }

  unlockDisbursement(id: number): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'disbursements/' + id + '/unlock',
      this.buildHeaders()
    );
  }

  approveTask(id, comment): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + id + '/complete-task-35',
      comment,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + id + '/reject-task-35',
      { comment },
      this.buildHeaders()
    );
  }

  // Client group
  findClientGroupDisbursementsByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/list/' + taskId,
      this.buildHeaders()
    );
  }

  findByClientGroup(id): Observable<any> {
    return this.http.get(this.serviceUrl + 'disbursements/for-group', {
      params: {
        clientGroup: id,
      },
      headers: this.buildHeaders().headers,
    });
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
