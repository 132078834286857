import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LivestockProducerService } from 'src/app/shared/services/livestock-producer/livestock-producer.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { productionOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/filterProductionOptions';

@Component({
  selector: 'app-manual-s-block-task',
  templateUrl: './manual-s-block-task.component.html',
  styleUrls: ['./manual-s-block-task.component.less'],
})
export class ManualSBlockTaskComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private translate: TranslateService,
    private livestockProducerService: LivestockProducerService
  ) {}

  sBlockStatusChanged: string;
  livestockProducer: any;
  productionTypes = productionOptions;
  productionTypeTranslateName = '';

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.taskService.getTask(params['taskId']).subscribe((task) => {
        const newStatus = JSON.parse(task['params']['status']);
        const livestockProducerId = task['params']['livestockProducerId'];
        this.translate
          .get([
            'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.ACTIVE',
            'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.INACTIVE',
          ])
          .subscribe((translations) => {
            const active =
              translations[
                'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.ACTIVE'
              ];
            const inactive =
              translations[
                'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.INACTIVE'
              ];
            this.sBlockStatusChanged = newStatus
              ? `${inactive} \u2192 ${active}`
              : `${active} \u2192 ${inactive}`;
          });

        this.livestockProducerService
          .getLivestockProducer(livestockProducerId)
          .subscribe((livestockProducer) => {
            this.livestockProducer = livestockProducer;
            this.productionTypeTranslateName = this.getProductionTypeName(
              this.livestockProducer?.masterDataLivestockProducer
                ?.productionType
            );
          });
      });
    });
  }

  getProductionTypeName(productionType) {
    return this.productionTypes.find(
      (item) => item.value === productionType.toString()
    )?.translateName;
  }
}
