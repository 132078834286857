<div class="content-container">
  <div class="privacy-policy-container">
    <div class="row mt-row title-container underlined">
      <span>Privacy Policy</span>
    </div>
    <div class="row mt-row text-container">
      <span>Do you have questions about data protection? Here you will find the appropriate information.</span>
    </div>
    <div class="row mt-row title-container underlined">
      <span>Privacy policy for our website</span>
    </div>
    <div class="row mt-row">
      <ul>
        <li>Information about the use of our website</li>
      </ul>
    </div>
    <div class="row mt-row title-container">
      <span>Privacy Policy for our Website</span>
    </div>
    <div class="row mt-row title-container">
      <span>04/2021</span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="1">
        <li>Who is responsible for the processing of personal data?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span>The</span>
    </div>
    <div class="address-container mt-row">
      <div class="row text-container">
        <span>BFS finance GmbH</span>
      </div>
      <div class="row text-container">
        <span>Carl-Bertelsmann-Str.23</span>
      </div>
      <div class="row text-container">
        <span>33332 Gütersloh</span>
      </div>
      <div class="row text-container">
        <span><a href="mailto:info@bfs-finance.com">info@bfs-finance.com</a></span>
      </div>
      <div class="row text-container">
        <span>phone.: 05241 – 80 43199</span>
      </div>
      <div class="row text-container">
        <span>telefax: 05241 – 80 43130</span>
      </div>
    </div>
    <div class="row mt-row text-container">
      <span
        >(in the following called „we“ or „<span class="title-container">BFS</span>“) are responsible for the processing
        of personal data on this website.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >You can contact our data protection officer via the above stated postal address and via the following e-mail
        address: <a href="mailto:datenschutz@bfs-finance.com">datenschutz@bfs-finance.com</a> with the addition "To the
        data protection officer".</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="2">
        <li>What are the purposes of data processing?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >If you visit this website, certain information is automatically collected by the calling computer (hereinafter
        "<span class="title-container">access data</span>"). This access data includes server log files, which usually
        consist of information about the browser type and version, the operating system, the Internet service provider,
        the date and time of use of the website, the previously visited web pages and newly accessed web pages via the
        website and the IP address of the computer. With the exception of the IP address, the server log files are not
        personally identifiable.
      </span>
    </div>
    <div class="row mt-row text-container">
      <span
        >When you use the services of the website, pseudonymous usage profiles and/or the data you enter on the website
        (e.g. search words, login data, ratings, form or contract entries, click data) are processed.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Regardless of whether you use our website as a guest or as a logged-in participant, we process the access data
        described above for the following purposes:</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >To <span class="title-container">provide the website</span> and
        <span class="title-container">ensure technical security</span>, in particular to correct technical errors and to
        ensure that unauthorized persons do not gain access to the website's systems.</span
      >
    </div>
    <div class="ordered-list-container list-2 title-container row mt-row">
      <ol start="2">
        <li>Data processing in case of logged-in participants</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >As soon as you log in to our website, we process your <span class="title-container">subscriber data</span> in
        addition to the access data listed in section 2. This data processing is necessary in order to create a user
        account and to enable you to participate in the creation, use, maintenance as well as overview of your
        data.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="3">
        <li>Legal basis of the data processing</li>
      </ol>
    </div>
    <div class="ordered-list-container list-3 title-container row mt-row">
      <ol start="3">
        <li>Provision of our website</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Our legal basis for the collection of server log files is Article 6 (1) lit. f GDPR. Our legitimate interests
        lie in the functionality of the website, the performance of security analyses and the defense against
        threats.</span
      >
    </div>
    <div class="ordered-list-container list-4 title-container row mt-row">
      <ol start="3">
        <li>Usage of the website of logged-in participants</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >After you have logged in, all functions provided by us via our website are freely available for you to use. Our
        legal basis for the processing of data is Article 6 (1) lit. b GDPR for contract performance if you act as a
        corporate customer yourself, or Article 6 (1) lit. f GDPR if you act as a contact person for a corporate
        customer.</span
      >
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          <span class="title-container">Correspondence via contact form, e-mail or telephone contact</span>: You are
          given the opportunity via this website to contact us via a contact form, e-mail address, telephone or fax
          number. If you make use of this option, the data you provide (e.g. your e-mail address and/or your telephone
          number as well as your request) will be transmitted to us. Depending on the request (e.g. assertion of data
          subject rights), your contact data will be processed to handle your request. If necessary, your information
          may also be forwarded to third parties (e.g. partner companies).
        </li>
        <li>
          If you use this website as a contact person of a contractual partner (hereinafter "contact person"), a
          <span class="title-container">user account</span> for contact persons of the contractual partner (hereinafter
          "contact person data") will be created. This data set consists of the following mandatory data: Contact Data,
          Business Address and Login Data. Furthermore, additional business contact data can optionally be stored.
        </li>
        <li>
          Contact person(s) / BFS create(s) a corporate customer record within our BeVision system. Depending on the
          legal form of the contractual partner, this record may contain varying mandatory data, which are marked as
          such within the BeVision system and are entered/stored jointly by BFS and the corporate customer/contact
          person. The data categories to be processed for the respective service can be found in the corresponding data
          protection notices.
        </li>
      </ul>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="4">
        <li>Duration of storage</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >After calling up the website, the server log files are stored on the web server and the IP address contained
        therein is deleted after 30 days at the latest. An evaluation during this storage period takes place in the
        event of an attack or for troubleshooting.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="5">
        <li>Categories of recipients regarding your data</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >At BFS, those parties receive access to data that need it to fulfill the purposes outlined in section 2.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Service providers (hereinafter referred to as "<span class="title-container">data processors</span>") used by
        us may also receive access to your data (e.g. data processing center). To ensure that our service providers are
        bound by instructions, data security and confidential handling of your data, we only act according to the
        relevant data processing agreements.
      </span>
    </div>
    <div class="row mt-row text-container">
      <span
        >For identification as well as completion and validation, we may, if necessary for the processing activity, make
        inquiries to <span class="title-container">data providers</span> to whom we transfer the required minimum data
        set.
      </span>
    </div>
    <div class="row mt-row text-container">
      <span
        >Further recipients of the data stored in the BeVision system may be internal and external auditors or as well
        as authorities (e.g. BaFin) or auditors commissioned by them (collectively referred to as "<span
          class="title-container"
          >auditors</span
        >").
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="6">
        <li>Is my data being processed outside the EU or EEA (third country transfer)?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Insofar as the recipients named in section 4 process your data outside the EU or EEA, this may result in your
        data being transferred to a country that does not guarantee the same data protection standard as the European
        Union. In this case, except in the case of an application of Article 49 GDPR, we will ensure that the recipients
        guarantee an equivalent level of data protection by contract or otherwise. You can request a copy of these
        guarantees via the contact address mentioned in section 7.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="7">
        <li>What rights of data protection do I have?</li>
      </ol>
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          <span class="title-container">Right to information / access of data:</span> You have the right to obtain
          information about the personal data we process about you at any time.
        </li>
        <li>
          <span class="title-container">Right to rectification:</span> If your personal data is incorrect or no longer
          up to data, you have the right to request its rectification.
        </li>
        <li>
          <span class="title-container">Right to erasure or restriction of data processing:</span> You also have the
          right to request the erasure or restriction of the processing of your data in accordance with Articles 17 or
          18 GDPR.
        </li>
        <li>
          <span class="title-container">Right to data portability:</span> where you have provided us with data, you have
          the right to receive the data relating to you in a structured, commonly used and machine-readable format and
          you have the right to transfer the data to another controller to whom the data has been provided, provided
          that our processing is based on your consent or on a contract with you and the processing is carried out with
          the aid of automated procedures.
        </li>
        <li>
          <span class="title-container">Right to object:</span> In accordance with Article 21(1) of the GDPR, you have
          the right to object at any time, on grounds relating to your particular situation, to the processing of your
          data which is carried out on the legal basis of Article 6(1)(f) of the GDPR.
        </li>
      </ul>
    </div>
    <div class="row mt-row text-container">
      <span
        >To assert your data protection rights, please contact:
        <a href="mailto:datenschutz@bfs-finance.com">datenschutz@bfs-finance.com</a>.</span
      >
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          In addition, you have the option of contacting a data protection authority and filing a
          <span class="title-container">complaint </span>
          there. The authority responsible for the company is the North Rhine-Westphalia State Commissioner for Data
          Protection and Freedom of Information, Kavalleriestraße 2-4, 40213 Düsseldorf. However, you can also contact
          the data protection authority responsible for your place of residence.
        </li>
      </ul>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="8">
        <li>Closing / versioning information</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >In the course of further development of the website and the implementation of new technologies to improve our
        service for you, changes to this data protection notice may become necessary. Therefore, we recommend that you
        read this privacy policy again from time to time. The status of the Privacy Notice is displayed here:</span
      >
    </div>
    <div class="row mt-row text-container">
      <span>04/2021</span>
    </div>
  </div>
</div>
