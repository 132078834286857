import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { scan } from 'rxjs/operators';
import { BusinessPartnerSelectionModalComponent } from 'src/app/shared/modals/business-partner-selection-modal/business-partner-selection-modal.component';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-open-transaction-booking-search-partner',
  templateUrl: './open-transaction-booking-search-partner.component.html',
  styleUrls: ['./open-transaction-booking-search-partner.component.less'],
})
export class OpenTransactionBookingSearchPartnerComponent
  implements OnInit, OnDestroy
{
  entityName = 'user-role-data-visibility';

  searchRunning = false;
  partners: any = [];

  scrollUpDistance = 2;
  pageSize = 25;
  pageIndex = 0;
  searchString;
  selectedPermissions;
  showScroll = false;
  selectedObjects = [];

  searchForm: UntypedFormGroup;
  showInfiniteScrollList = false;

  businessUnits: any;
  businessUnitProduct: any;

  searchTerm = '';

  disabledButton = false;

  protected onDestroy = new Subject<void>();

  @ViewChild('inputScroll') toggleButton: ElementRef;
  @ViewChild('infiniteScrollContainer') menu: ElementRef;

  options = new BehaviorSubject<any[]>([]);
  options$: Observable<any[]>;

  selectedPartner: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private matDialogService: MatDialog,
    private openItemService: OpenItemService,
    private renderer: Renderer2,
    private businessUnitService: BusinessUnitService,
    private shareDataService: SharedDataService
  ) {
    this.searchFormBuilder();
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return curr;
      }, [])
    );

    this.shareDataService.disableFieldsEmitter =
      this.shareDataService.disableFieldsFunction.subscribe((data) => {
        this.disableSearchInput(data);
      });

    this.shareDataService.suscriptionClearTableEmitter =
      this.shareDataService.clearTableFunction.subscribe((data) => {
        this.clearAll();
      });
  }

  ngOnDestroy() {
    this.shareDataService.suscriptionClearTableEmitter.unsubscribe();
    this.shareDataService.disableFieldsEmitter.unsubscribe();
    this.shareDataService.updateSharedCommonForm(null);
  }

  ngOnInit(): void {
    this.businessUnits = this.businessUnitService.getAllBusinessUnitsLocal();
    this.businessUnitProduct = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).product;

    this.searchForm.get('partnerSearch').valueChanges.subscribe((data) => {
      if (data !== '') {
        this.searchTerm = data;
        this.searchFromSelect();
      }
    });

    this.searchForm.get('partner').valueChanges.subscribe((data) => {
      this.updateCommonForm();
    });

    this.searchFromSelect();
  }

  clearAll() {
    this.searchForm.get('partner').patchValue(null);
    this.searchForm.get('partner').enable();
    this.searchForm.updateValueAndValidity();
    this.disabledButton = false;
  }

  updateCommonForm() {
    this.shareDataService.updateSharedCommonForm(this.searchForm);
    this.shareDataService.changesInCommonForm();
  }

  _openChanged($event) {
    if ($event) {
      this.searchForm.get('partner').patchValue(null);
      this.shareDataService.updateSharedCommonForm(this.searchForm);
      this.shareDataService.changesInCommonForm();
      this.searchFromSelect();
    }
  }

  disableSearchInput(data) {
    this.searchForm.disable();
    this.searchForm.updateValueAndValidity();
    this.disabledButton = true;
  }

  searchFromSelect($event?) {
    this.pageIndex = 0;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.searchForm.get('partnerSearch').value
        ? this.searchForm.get('partnerSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      product: this.businessUnitProduct,
    };
    this.openItemService.listPartners(configObj).subscribe((data) => {
      this.partners = data['content'];
      this.options.next(this.partners);
    });
  }

  searchFormBuilder() {
    this.searchForm = this.formBuilder.group({
      partner: [],
      partnerSearch: null,
    });
  }

  onScroll() {
    this.pageIndex += this.pageSize;

    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.searchForm.get('partnerSearch').value
        ? this.searchForm.get('partnerSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      product: this.businessUnitProduct,
    };

    this.openItemService.listPartners(configObj).subscribe((data) => {
      for (const partner of data['content']) {
        this.partners.push(partner);
      }
      this.options.next(this.partners);
    });
  }

  openSearchDialog() {
    const dialog = this.matDialogService.open(
      BusinessPartnerSelectionModalComponent
    );

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.partners.push(data.result);
        this.options.next(this.partners);
        this.searchForm.get('partner').patchValue(data.result);
        this.searchForm.updateValueAndValidity();
      }
    });
  }
}
