import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { Task } from 'src/app/shared/models/task';

@Component({
  selector: 'app-release-unused-budget',
  templateUrl: './release-unused-budget.component.html',
  styleUrls: ['./release-unused-budget.component.less'],
})
export class ReleaseUnusedBudgetComponent implements OnInit {
  entityName = 'releaseUnusedBudget';
  isDisabled = false;
  isRunning = false;
  unusedBudgetTask: Task = null;

  constructor(
    private liquidityPlanService: LiquidityPlanService,
    private notificationService: NotificationService,
    private taskService: TaskService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkCalculationExists();
  }

  checkCalculationExists() {
    this.liquidityPlanService
      .isTheReleaseUnusedBudgetProcessRunning()
      .subscribe(isItRunning => {
        this.isDisabled = this.isDisabled || isItRunning;
        this.isRunning = isItRunning;
      });

    this.taskService
      .getActiveTasksByType(1, 0, 'RELEASE_OF_UNUSED_BUDGET_APPROVAL')
      .subscribe((data) => {
        if (data?.content?.length > 0) {
          this.isDisabled = true;
          this.unusedBudgetTask = data.content[0];
        }
      });
  }

  startCalculation() {
    if (!this.isDisabled) {
      this.isDisabled = true;
      this.isRunning = true;
      this.liquidityPlanService
        .startCalculationForReleaseUnusedBudgets()
        .subscribe(
          (response) => {
            this.notificationService.showToast(
              'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.MESSAGE.SUCCESS',
              this.notificationService.MESSAGE_TYPE.SUCCESS
            );
          },
          (error) => {
            this.isDisabled = false;
            this.isRunning = false;
            this.notificationService.showToast(
              'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.MESSAGE.ERROR',
              this.notificationService.MESSAGE_TYPE.ERROR
            );
          }
        );
    }
  }

  visitTask() {
    this.router.navigate(['/tasks', this.unusedBudgetTask.id]);
  }
}
