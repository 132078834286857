import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { livestockOverviewMovementDataColumns } from 'src/app/in-memory-data/animal-welfare/livestock-movement-data/table-columns';
import { livestockOverviewMovementDataColumnsConf } from 'src/app/in-memory-data/animal-welfare/livestock-movement-data/table-columns-configuration';
import { typeOptions } from 'src/app/in-memory-data/animal-welfare/livestock-movement-data/enum/typeOptions';
import { measureUnitOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods/enum/measureUnitOptions';
import { TransferQuantityGoodService } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/transfer-quantity-good.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-livestock-movement-data',
  templateUrl: './livestock-movement-data.component.html',
  styleUrls: ['./livestock-movement-data.component.less'],
})
export class LivestockMovementDataComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  CONFIGURATION_KEY = 'livestock_overview_movement_data_table';

  columns = livestockOverviewMovementDataColumnsConf;
  displayedColumns = livestockOverviewMovementDataColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  typeOptions = typeOptions;

  dataSource: any = new MatTableDataSource<any>();
  originalDataSource: any;

  entityName = 'livestock-producer-movement-data';

  dateFormat: any;

  measureUnitOptions: any[] = measureUnitOptions;

  @Input() livestockProducer;
  subject = new Subject();

  pageSize = 20;
  sortDirection = 'desc';
  sortedBy = 'submission_date';
  totalCount: number;

  loaderSpinner = false;

  constructor(
    private userService: UserService,
    private transferQuantityGoodService: TransferQuantityGoodService
  ) {}

  ngOnInit(): void {
    this.loaderSpinner = true;
    this.dateFormat = this.userService.getDateFormat();
    this.getTableConfiguration();
    this.loadTable();

    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loadTable();
    });
  }

  formatData() {
    this.dataSource.data.forEach((row) => {
      row.translateMeasureUnit = this.getMeasureTranslateName(
        this.measureUnitOptions,
        row.measureUnit
      );
    });
  }

  getFieldNames = (args): Observable<any> => {
    return this.transferQuantityGoodService.getFilterFieldNameValues(
      args,
      this.livestockProducer.id
    );
  };

  sortTable = (sortTableEvent): void => {
    this.loaderSpinner = true;
    this.paginator.firstPage();
    this.sortedBy = sortTableEvent.choice;
    this.sortDirection = sortTableEvent.direction;
    this.subject.next(undefined);
  };

  loadTable() {
    this.transferQuantityGoodService
      .findAllByLivestockProducerId(
        this.livestockProducer.id,
        this.sortedBy,
        this.sortDirection,
        this.paginator.pageIndex + 1,
        this.pageSize
      )
      .subscribe(
        (data: any) => {
          if (data.content && data.content.length > 0) {
            data.content.forEach((element) => {
              Object.assign(element, {
                name: this.livestockProducer.locationName,
              });
            });
          }
          this.totalCount = data.total;
          this.dataSource.data = data.content;
          this.originalDataSource = data.content;
          this.dataSource.filter = '';
          this.loaderSpinner = false;
          this.formatData();
        },
        (error) => {
          this.loaderSpinner = false;
        }
      );
  }

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    setTimeout(() => {
      this.paginator.firstPage();
      this.transferQuantityGoodService.setColumnFilter(choice, selection);
      this.subject.next(undefined);
    }, 5000);
  };

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                livestockOverviewMovementDataColumnsConf,
                livestockOverviewMovementDataColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = livestockOverviewMovementDataColumnsConf;
    this.displayedColumns = livestockOverviewMovementDataColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  paginate(event) {
    this.loaderSpinner = true;
    this.pageSize = event.pageSize;
    this.loadTable();
  }

  getType(value) {
    return this.typeOptions.find((data) => data.value === value)
      ? this.typeOptions.find((data) => data.value === value).translateName
      : null;
  }

  getFundingRateColumnTitle(): string {
    if (this.livestockProducer.productionType.toString().startsWith('2')) {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.ANIMAL-WELFARE-GRANT';
    } else {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.FUNDING-RATE';
    }
  }

  private getMeasureTranslateName(
    measureUnitOptionsValues: any[],
    measureUnit: string
  ) {
    const translateName = measureUnitOptionsValues.find(
      (unit) =>
        unit.name === measureUnit ||
        unit?.alternativeNames?.find(
          (alternativeName) => alternativeName === measureUnit
        )
    )?.translateNameList;
    return !!translateName ? translateName : measureUnit;
  }
}
