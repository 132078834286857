<div class="data-container">
  <div class="data-row" *ngFor="let comment of comments; index as i">
    <span class="delete-button">
      <button
        mat-button
        (click)="deleteComment(comment)"
        *ngIf="!checkMultipleSelected()"
        id="{{ entityName }}-delete-button-{{ i }}"
      >
        <img src="../../../assets/images/ic_trash.svg" alt="delete icon" class="trash-icon" />
      </button>
    </span>
    <div class="item-value">
      <div class="user-data">
        <span class="user-id">{{ getUserInfo(comment) | translate }}</span
        ><span class="date">
          {{ comment.date | date: dateFormat + ' HH:mm:ss' }}
        </span>
      </div>
      <span class="content">{{ comment.content }}</span>
    </div>
  </div>
</div>
