export enum EmailFileName {
  TCI_CONTRACT_INTERNAL_EULER = 'Email_Trade Credit Insurance Contract Implementation (Internal)_Euler Hermes',
  TCI_SMARTLINK_INTERNAL_EULER = 'Email_Trade Credit Insurance SmartLink (Internal)_Euler Hermes',
  TCI_IMPLEMENTATION_INTERNAL_ATRADIUS = 'Email_Trade Credit Insurance Contract Implementation (Internal)_Atradius',
  TCI_CONTRACT_EXTERNAL_EULER = 'O_0005 Email_Trade Credit Insurance Contract Implementation (External)_Euler Hermes',
  TCI_CONTRACT_EXTERNAL_ATRADIUS = 'O_0009 Email_Trade Credit Insurance Contract Implementation (External)_Atradius',
  TCI_ONLINE_CONNECTION_EXTERNAL_EULER = 'Email_Trade Credit Insurance Online Connection (External)_Euler Hermes',
  TCI_SECURE_MAIL_EXTERNAL_EULER = 'O_0007 Email_Trade Credit Insurance Secure Mail (External)_Euler Hermes',
  TCI_SMARTLINK_EXTERNAL_EULER = 'Email_Trade Credit Insurance Smart Link (External)_Euler Hermes',
  TCI_IMPLEMENTATION_EXTERNAL_ATRADIUS = 'Email_Trade Credit Insurance Implementation (External)_Atradius',
  TCI_ONLINE_CONNECTION_EXTERNAL_ATRADIUS = 'Email_Trade Credit Insurance Online Connection (External)_Atradius',
  TCI_CAM_CONNECTION_EXTERNAL_ATRADIUS = 'O_0011 Email_Trade Credit Insurance CAM Connection (External)_Atradius',
  TCI_CAM_CONNECTION_EXTERNAL_EULER_HERMES = 'O_0011 Email_Trade Credit Insurance CAM Connection (External)_Euler Hermes',
  TCI_CONTRACT_INTERNAL_OTHERS = 'Email_Trade Credit Insurance Contract Implementation (Internal)_Others',
  TCI_CONTRACT_EXTERNAL_OTHERS = 'O_0014 Email_Trade Credit Insurance Contract Implementation (External)_Others',
  PAYOUT_SUGGESTION_LIST = 'O_1010 Email Payout Suggestion List',
}
