import { TableTypes } from '../../default-table/table-types';

export const accountingMasterDataColumnsConf: any[] = [
  {
    name: 'visibleId',
    index: 0,
    checked: true,
    translateName: 'ACCOUNTING.MASTER-DATA-TABLE.VISIBLE-ID',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'ACCOUNTING.MASTER-DATA-TABLE.NAME',
    locked: true,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'address',
    index: 2,
    checked: true,
    translateName: 'ACCOUNTING.MASTER-DATA-TABLE.ADDRESS',
    locked: true,
    number: false,
    type: TableTypes.STRING,
  },
  {
    version: 2,
  },
];
