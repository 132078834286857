import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-user-general-information',
  templateUrl: './dashboard-user-general-information.component.html',
  styleUrls: [
    './dashboard-user-general-information.component.less',
    './dashboard-user-general-information-sm.component.less',
    './dashboard-user-general-information-xs.component.less',
  ],
})
export class DashboardUserGeneralInformationComponent
  implements OnInit
{
  @Input() unreadMessages;
  @Input() user;
  internalEnv: boolean;
  smallStatRequired = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.internalEnv = environment.isInternal;
    this.smallStatRequired = this.checkIsSmallStatRequired();
  }

  goToMessageCenter() {
    this.router.navigateByUrl('/dashboard/messageCenter');
  }

  goToTasks() {
    this.router.navigateByUrl('/tasks');
  }

  checkIsSmallStatRequired() {
    return this.unreadMessages.toString()?.length > 6
  }
}

