import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { liquidityPlanAssetChangeColumns } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/liquidity-asset-change/table-columns';
import { liquidityPlanAssetChangeColumnsConf } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/liquidity-asset-change/table-columns-configuration';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { AWEnterActualValueModalComponent } from 'src/app/shared/modals/animal-welfare-modals/aw-enter-actual-value-modal/enter-actual-value-modal.component';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-animal-welfare-liquidity-plan-asset-results',
  templateUrl: './liquidity-plan-asset-results.component.html',
  styleUrls: ['./liquidity-plan-asset-results.component.less'],
})
export class LiquidityPlanAssetResultsComponent
  implements OnInit, AfterViewInit
{
  entityName = 'animal-welfare-liquidity-plan-asset-results';
  liquidityPlanList = {};
  selection = new SelectionModel(true, []);
  @Input() selectedLiquidityPlan;
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  columns = liquidityPlanAssetChangeColumnsConf;
  displayedColumns = liquidityPlanAssetChangeColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  configurationKey = 'animal_welfare_liquidity_plan_asset_result_table';
  editableTask: boolean;
  isTask = false;
  loadSpinner = true;
  state;
  dateFormat = '';
  canceled;
  liquidityPlanId = null;
  sharedSelectedRow: any[] = [];
  total;
  updatedSearchFilters;
  configs: any = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    liquidityPlanGuid: null,
  };
  listOfActionButtons: TableActionButtonsConfiguration[] = [
    {
      name: 'cancel',
      permission: 'aw.l.u',
      icon: 'cancel',
      translationKey: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CANCEL',
      function: (param) => {
        this.cancelAsset();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return this.checkIfAssetIsCancelled();
      },
    },
    {
      name: 'edit',
      permission: 'aw.l.u',
      icon: 'edit',
      translationKey: 'GENERAL.EDIT',
      function: (param) => {
        this.edit(param);
      },
      selectionType: DefaultTableSelectionType.single,
      specialDisable: () => {
        return false;
      },
    },
    {
      name: 'enterActualValue',
      permission: 'aw.l.u',
      icon: 'keyboard_tab',
      translationKey:
        'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ENTER-ACTUAL-VALUE',
      function: (param) => {
        this.enterActualValue(param);
      },
      selectionType: DefaultTableSelectionType.single,
      specialDisable: () => {
        return false;
      },
    },
  ];

  constructor(
    private translateService: TranslateService,
    private liquidityPlanService: LiquidityPlanService,
    private matDialogService: MatDialog,
    private customErrorHandler: CustomErrorHandler,
    private notificationService: NotificationService,
    private tablesService: TablesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.state = window.history.state;
    this.getSelectedRow();
  }

  ngAfterViewInit() {
    this.cancelSelection();
    this.getSelectedLiquidityPlan();
    this.applyCurrentAppliedFilters();
  }

  applyCurrentAppliedFilters() {
    this.liquidityPlanService.currentAppliedFilters.subscribe((value) => {
      this.updatedSearchFilters = value;
      if (value) {
        this.loadFilteredAssetChanges();
      }
    });
  }

  getSelectedLiquidityPlan() {
    this.selectedLiquidityPlan.subscribe((value) => {
      if (value) {
        this.liquidityPlanId = value.id;
        this.loadAssetChanges();
      }
    });
  }

  loadFilteredAssetChanges() {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    if (this.liquidityPlanService.listOfFilteredAssetChanges) {
      this.liquidityPlanList =
        this.liquidityPlanService.listOfFilteredAssetChanges;
      if (this.defaultTableComponent) {
        this.defaultTableComponent.setLoaderSpinner(false);
      }
    }
  }

  loadAssetChanges() {
    if (this.liquidityPlanId) {
      this.loadSpinner = true;
      this.configs.liquidityPlanGuid = this.selectedLiquidityPlan.value.id;

      this.searchAssetChanges();
    }
  }

  searchAssetChanges() {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    this.liquidityPlanService.searchAssetChange(filterOptions).subscribe(
      (data) => {
        data.content.forEach((assetChange) => {
          assetChange.cancelled =
            assetChange.status === 'CANCELED' || assetChange.cancellation
              ? true
              : false;

          assetChange['createdOn'] = assetChange['inputDate'];
        });
        this.liquidityPlanList = data;
        this.total = data.total;
        this.loadSpinner = false;
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      },
      (error) => {
        this.loadSpinner = false;
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      }
    );
  }

  checkIfAssetIsCancelled() {
    if (
      this.sharedSelectedRow &&
      this.sharedSelectedRow[0] &&
      this.sharedSelectedRow[0].canceled === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSelectedRow() {
    this.tablesService.currentSharedSelectedRows.subscribe((param) => {
      this.sharedSelectedRow = [];
      this.sharedSelectedRow = param;
    });
  }

  cancelSelection() {
    this.liquidityPlanService.currentEditMode.subscribe((value) => {
      if (value) {
        this.tablesService.updateSharedSelectedRows(null);
      } else {
        this.loadAssetChanges();
      }
    });
  }

  edit(element) {
    this.router.navigateByUrl(`/liquidityPlan/create/${element.id}`, {
      state: { showAssetChange: true, hideButtons: false },
    });
    this.tablesService.updateSharedSelectedRows(null);
  }

  cancelAsset() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        deleteMessage: this.translateService.instant(
          'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
        ),
      },
    });
    dialog.afterClosed().subscribe((result) => {
      const guids = this.sharedSelectedRow.map((item) => item.id);
      if (result) {
        this.liquidityPlanService
          .cancelAssetChange(result.comment, guids)
          .subscribe(() => {
            this.successHandler();
          }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'));
      } else {
        this.tablesService.updateSharedSelectedRows(null);
      }
    });
  }

  enterActualValue(element) {
    const dialog = this.matDialogService.open(
      AWEnterActualValueModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const guid = this.sharedSelectedRow[0].id;
        this.liquidityPlanService
          .addActualValue(guid, result.actualValue, result.comment)
          .subscribe(() => {
            this.successHandler();
          }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'));
      } else {
        this.tablesService.updateSharedSelectedRows(null);
      }
    });
  }

  filter(event) {
    this.configs = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: '20',
      pageIndex: '0',
      filteredValues: [],
      liquidityPlanGuid: this.selectedLiquidityPlan.value.id,
    };
    if (
      this.updatedSearchFilters !== undefined &&
      this.updatedSearchFilters !== null
    ) {
      this.configs = this.updatedSearchFilters;
    }
    if (event.filteredValues) {
      if (event.filteredValues.choice === 'createdOn') {
        event.filteredValues.choice = 'inputDate';
      }
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
      if (event.choice) {
        if (event.choice === 'createdOn') {
          this.configs.sortField = 'inputDate';
        } else if (event.choice === 'slider') {
          this.configs.sortField = 'cancellation';
        } else {
          this.configs.sortField = event.choice;
        }
      }
      if (event.direction) {
        this.configs.sortDirection = event.direction;
      }
    }
    this.searchAssetChanges();
  }

  getDistinctValues = (column) => {
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    if (column === 'createdOn') {
      filterOptions['column'] = 'inputDate';
    } else {
      filterOptions['column'] = column;
    }
    return this.liquidityPlanService.getAssetChangeDistinctValues(
      filterOptions
    );
  };

  private successHandler() {
    this.tablesService.updateSharedSelectedRows(null);
    this.loadAssetChanges();
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ENTITIES.ANIMAL-WELFARE.ASSET-CHANGE'
        ),
      }
    );
  }
}
