export const businessTransactionCodeColumnsConf: any[] = [
  {
    name: 'btc',
    index: 0,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.BTC.BTC',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.BTC.NAME',
    locked: true,
  },
  {
    version: 1,
  },
];
