<div class="ratings">
  <div class="grade grade-aa" [class.active]="grade == 'AA'">AA</div>
  <div class="grade grade-a" [class.active]="grade == 'A'">A</div>
  <div class="grade grade-bbb" [class.active]="grade == 'BBB'">BBB</div>
  <div class="grade grade-bb" [class.active]="grade == 'BB'">BB</div>
  <div class="grade grade-b" [class.active]="grade == 'B'">B</div>
  <div class="grade grade-ccc" [class.active]="grade == 'CCC'">CCC</div>
  <div class="grade grade-cc" [class.active]="grade == 'CC'">CC</div>
  <div class="grade grade-c" [class.active]="grade == 'C'">C</div>
  <div class="grade grade-d" [class.active]="grade == 'D'"><span>D</span></div>
  <div class="grade grade-w" [class.active]="grade == 'W'"><span>W</span></div>
  <div class="grade grade-n" [class.active]="grade == 'N'"><span>N</span></div>
</div>
