import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { CompanyBoundEntities } from '../../models/company-bound-entities';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.companyService;
    this.participantServiceUrl = environment.settings.participantService;
  }

  private serviceUrl;
  mapOfColumnFilters = [];
  private searchString = '';
  private countryFilter = '';
  private participantServiceUrl;
  pageSize;
  sortDirection;
  sortOrder;
  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  setCountryFilter(countryFilter: any) {
    this.countryFilter = countryFilter;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  getBoundEntities(companyId): Observable<CompanyBoundEntities> {
    return this.http.get<CompanyBoundEntities>(
      this.serviceUrl + 'companies/retrieveBoundEntities/' + companyId,
      this.buildHeaders()
    );
  }

  getCompaniesWithId(guidArray: any[]) {
    const config = this.buildHeaders();
    let params = new HttpParams();
    guidArray.forEach((guid) => {
      params = params.append('uids', guid);
    });
    config['params'] = params;
    return this.http.get(this.serviceUrl + 'companies/listForIds', config);
  }

  getClientGroupsForCompany(companyId: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clientgroups/forcompany/' + companyId,
      this.buildHeaders()
    );
  }

  updateCompany(company) {
    return this.http.put(
      this.serviceUrl + 'companies',
      company,
      this.buildHeaders()
    );
  }

  saveCompany(company) {
    return this.http.post(
      this.serviceUrl + 'companies',
      company,
      this.buildHeaders()
    );
  }

  updateDraft(company) {
    return this.http.put(
      this.serviceUrl + 'companies/drafts',
      company,
      this.buildHeaders()
    );
  }

  getCompanyWithTaskId(id: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + id + '/company',
      this.buildHeaders()
    );
  }

  getCurrentApproved(id: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'companies/' + id,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'companies/drafts/' + id + '/approval',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'companies/drafts/' + id + '/rejection',
      json,
      this.buildHeaders()
    );
  }

  getAllCompanies(sortedBy, sortDirection, page, pageSize): Observable<any> {
    let url = this.serviceUrl + 'companies/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      '&countryFilter=' +
      this.countryFilter +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  saveDraftForApproval(request) {
    return this.http.post(
      this.serviceUrl + 'companies-drafts/approval',
      request,
      this.buildHeaders()
    );
  }

  getPlatformConfigurationList(id: any) {
    return this.http.get(
      this.serviceUrl + 'companies/' + id + '/platform-configuration-list',
      this.buildHeaders()
    );
  }

  getMasterDataCompanyEntries(companyId) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/list/' + companyId,
      this.buildHeaders()
    );
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'companies/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  getCompanyById(id) {
    return this.http.get(
      this.serviceUrl + 'companies/findById/' + id,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    const businessUnitId = this.businessUnits.getCurrentBusinessUnit();
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', businessUnitId)
        .append(
          'Pool-Id',
          this.businessUnits.getCurrentBusinessUnitPoolId(businessUnitId)
        ),
    };
  }
}
