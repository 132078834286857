import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { TaskEventType } from '../../../models/task-event-type';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuatityLabelledGoodService {
  private readonly searchUrl;
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private readonly participantServiceUrl;
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.taskServiceUrl = environment.settings.taskService;
    this.participantServiceUrl = environment.settings.participantService;
    this.searchUrl = environment.settings.searchAPIUrl;
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  createQuantityLabelledGood(quantityLabelledGoods, comment, taskId) {
    const json = {
      quantityLabelledGoods,
      comment,
      operation: 'CREATE',
      taskId,
    };
    return this.http.post(
      this.serviceUrl + 'quantity-labelled-good',
      json,
      this.buildHeaders()
    );
  }

  updateQuantityLabelledGood(quantityLabelledGoods, comment, taskId) {
    const json = {
      quantityLabelledGoods,
      comment,
      operation: 'UPDATE',
      taskId,
    };
    return this.http.put(
      this.serviceUrl + 'quantity-labelled-good',
      json,
      this.buildHeaders()
    );
  }

  cancelQuantityLabelledGood(quantityLabelledGoods, comment) {
    const json = {
      quantityLabelledGoods,
      comment,
      operation: 'CANCEL',
    };
    return this.http.put(
      this.serviceUrl + 'quantity-labelled-good/cancel',
      json,
      this.buildHeaders()
    );
  }

  downloadXslxFile(filters, columns, language) {
    const headers = this.buildHeaders();
    return this.http.get(
      this.searchUrl + 'quantity-labelled-good/download/xslx',
      {
        params: {
          ...filters,
          columns,
          language,
        },
        ...headers,
        responseType: 'blob',
      }
    );
  }

  findAll(filters, columns, language) {
    const headers = this.buildHeaders();
    return this.http.get(this.searchUrl + 'quantity-labelled-good', {
      params: {
        ...filters,
        columns,
        language,
      },
      ...headers,
    });
  }

  findById(id) {
    return this.http.get(
      this.serviceUrl + 'quantity-labelled-good/' + id,
      this.buildHeaders()
    );
  }

  findSupplierByLevel(level) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/level/' + level,
      this.buildHeaders()
    );
  }

  findSupplierByID(id) {
    return this.http.get(
      this.participantServiceUrl + 'masterdata/' + id,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
