export const debtorPaymentAllocationColumns: string[] = [
  'select',
  'creditDebitCode',
  'transactionCode',
  'documentationId',
  'documentationDate',
  'client',
  'externalDebtorId',
  'internalDebtorId',
  'amount',
  'vatPercent',
  'vatAmount',
  'discountDate',
  'discountPercent',
  'discountAmount',
  'dueDate',
  'actions',
];
