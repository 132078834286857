<div class="history-container table">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="filters-title">{{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.HISTORY' | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-container">
        <table aria-hidden="true" mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-results-table">
          <ng-container matColumnDef="creationDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="v25-th"
              id="{{ entityName }}-table-results-creationDate"
              (click)="paginator.firstPage()"
            >
              {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.DATE' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              id="{{ entityName }}-table-results-creationDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
            >
              {{ element.operationDate | date: dateFormat + ' HH:mm:ss' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="author">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="v25-th"
              id="{{ entityName }}-table-results-author"
              (click)="paginator.firstPage()"
            >
              {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.AUTHOR' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              id="{{ entityName }}-table-results-author-{{ paginator.pageIndex + 1 }}-{{ i }}"
            >
              {{ getUserInfo(element.operationUser) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
