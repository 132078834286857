export const historicalValuesColumnsConf: any[] = [
  {
    name: 'defaultValue',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.CURRENT-VALUE',
    locked: false,
  },
  {
    name: 'validFrom',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM',
    locked: false,
  },
  {
    version: 1,
  },
];
