import { TableTypes } from '../../default-table/table-types';

export const accountingLedgerAccountColumnsConf: any[] = [
  {
    name: 'accountNumber',
    index: 0,
    checked: true,
    translateName: 'ACCOUNTING.LEDGER-ACCOUNT-TABLE.ACCOUNT-NUMBER',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'subLedgerAccountName',
    index: 1,
    checked: true,
    translateName: 'ACCOUNTING.LEDGER-ACCOUNT-TABLE.SUB-LEDGER-ACCOUNT-NAME',
    locked: true,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'balance',
    index: 2,
    checked: true,
    translateName: 'ACCOUNTING.LEDGER-ACCOUNT-TABLE.BALANCE',
    locked: true,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    version: 4,
  },
];
