import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { BookingCode } from '../../models/booking-code';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class BookingCodeService {
  private serviceUrl: string;
  bookingCodesForPlatform = new EventEmitter();
  ents: Subscription;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.companyService;
  }

  create(bookingCode) {
    if (bookingCode.taskId) {
      return this.http.put(
        this.serviceUrl + 'bookingcodes/drafts',
        bookingCode,
        this.buildHeaders()
      );
    } else {
      return this.http.post(
        this.serviceUrl + 'bookingcodes',
        bookingCode,
        this.buildHeaders()
      );
    }
  }

  update(bookingCode) {
    return this.http.put(
      this.serviceUrl + 'bookingcodes',
      bookingCode,
      this.buildHeaders()
    );
  }

  updateDraft(bookingCode: any) {
    return this.http.put(
      this.serviceUrl + 'bookingcodes/drafts',
      bookingCode,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'bookingcodes/drafts/' + id + '/approval',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'bookingcodes/drafts/' + id + '/rejection',
      json,
      this.buildHeaders()
    );
  }

  getBookingCodes(platformId: number) {
    this.bookingCodesForPlatform.emit(platformId);
  }

  listAll(): Observable<BookingCode[]> {
    return this.http.get<BookingCode[]>(
      this.serviceUrl + 'bookingcodes',
      this.buildHeaders()
    );
  }

  retrieveByTaskId(id): Observable<BookingCode> {
    return this.http.get<BookingCode>(
      this.serviceUrl + 'tasks/' + id + '/bookingcode',
      this.buildHeaders()
    );
  }

  retrieveApproved(bookingCodeId: number): Observable<BookingCode> {
    return this.http.get<BookingCode>(
      this.serviceUrl + 'bookingcodes/' + bookingCodeId + '/approved',
      this.buildHeaders()
    );
  }

  retrieveApprovedByGuid(bookingCodeGuid: string): Observable<BookingCode> {
    return this.http.get<BookingCode>(
      this.serviceUrl + 'bookingcodes/by-guid/' + bookingCodeGuid + '/approved',
      this.buildHeaders()
    );
  }

  listAllForPlatform(platformId: number): Observable<BookingCode[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'bookingcodes/for-platform/' + platformId,
      this.buildHeaders()
    );
  }

  listAllForClient(clientId: number): Observable<BookingCode[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'bookingcodes/for-client/' + clientId,
      this.buildHeaders()
    );
  }

  findAllBookingCodeByID(idArray: any[]) {
    const config = this.buildHeaders();
    let params = new HttpParams();
    idArray.forEach((id) => {
      params = params.append('ids', id);
    });
    config['params'] = params;
    return this.http.get(this.serviceUrl + 'bookingcodes/listForIds', config);
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
