export const cancelationSelectionEnum: any[] = [
  {
    name: 'WITHOUT_CANCELLATION',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.WITHOUT-CANCELATION',
  },
  {
    name: 'WITH_CANCELLATION',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.WITH-CANCELATION',
  },
  {
    name: 'ONLY_CANCELLATION',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.ONLY-CANCELATION',
  },
];
