export const taskConfigColumns: string[] = [
  'id',
  'type',
  'objectId',
  'object',
  'roleId',
  'teamId',
  'dueDate',
  'priority',
  'actions',
];
