import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';
import { Observable, of } from 'rxjs';
import { statusEnum } from 'src/app/in-memory-data/client/enums/StatusEnum';
import { contractOverviewFields } from 'src/app/in-memory-data/tci/history-table/history-fields/contractOverviewFields';
import { foreignCountryFields } from 'src/app/in-memory-data/tci/history-table/history-fields/foreignCountryFields';
import { limitConditionFields } from 'src/app/in-memory-data/tci/history-table/history-fields/limitConditionFields';
import { obligationFields } from 'src/app/in-memory-data/tci/history-table/history-fields/obligationFields';
import { particularitiesFields } from 'src/app/in-memory-data/tci/history-table/history-fields/particularitiesFields';
import { statusFields } from 'src/app/in-memory-data/tci/history-table/history-fields/statusFields';
import { TCICountry } from '../../models/tci-country';
import { TciService } from '../../services/tci/tci.service';

@Component({
  selector: 'app-tci-compare-history-modal',
  templateUrl: './tci-compare-history-modal.component.html',
  styleUrls: ['./tci-compare-history-modal.component.less'],
})
export class TciCompareHistoryModalComponent implements OnInit {
  tciList: any;
  newEntity: any;
  oldEntity: any;
  entityName = 'tci_history_compare_modal';

  allCountries: ICountry[] = [];
  allCountries$: Observable<ICountry[]>;

  contractOverviewFields = contractOverviewFields;
  limitConditionFields = limitConditionFields;
  obligationFields = obligationFields;
  particularitiesFields = particularitiesFields;
  statusFields = statusFields;
  foreignCountryFields = foreignCountryFields;

  countries: any[] = [];

  statuses = statusEnum;
  tciNames: any;
  tciNames$: any;

  emptyCountryObject: TCICountry = {
    country: null,
    isoCode: null,
    rateOfCompensationNamed: null,
    rateOfCompensationUnnamed: null,
    unnamedArea: null,
    protractedDefault: null,
    waitingPeriodProtractedDefault: null,
    premiumRate: null,
    maximumTermOfPayment: null,
    creditLimitExceeded: null,
    gracePeriodThreatOfDamage: null,
    cutOffPeriodCollectionOrder: null,
    waitingPeriodCompensationInsurance: null,
    approvalLimit: null,
    creditCheck: null,
    singleCheckFeeAmount: null,
    singleCheckFee: null,
    informationCurrentLargestIndividualLimit: null,
    decisionContract: null,
    decisionReason: null,
    comment: null,
  };

  loaderSpinner = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tciService: TciService,
    private translateService: TranslateService,
    protected countryPicker: CountryPickerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAllCountries();
  }

  async getAllCountries() {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.allCountries$ = of(countries);
      this.allCountries = countries;
      if (this.data.historyObjects && this.data.historyObjects.length === 2) {
        this.getHistoryTCI();
      }
    });
  }

  getHistoryTCI() {
    this.tciService
      .getTCIHistory(
        this.data.historyObjects[0].id,
        this.data.historyObjects[1].id
      )
      .subscribe((data) => {
        this.tciList = data;
        this.formatTCIList();
      });
  }

  async formatTCIList() {
    await this.loadTCINames();
    await this.formatTCIDates();
    await this.formatTCINames();
    await this.formatFields();
    await this.setNewerOlderDate();
    await this.formatForeignCountries();
    this.loaderSpinner = false;
  }

  async formatTCIDates() {
    this.tciList.forEach((element) => {
      element.operationDateObject = new Date(element.operationDate);
    });
  }

  async formatTCINames() {
    this.tciList.forEach((element) => {
      element.tciName = this.getTCIName(element.tciNameId);
    });
  }

  async loadTCINames(): Promise<any> {
    const tciNames: any = await this.tciService.listTciNames().toPromise();
    this.tciNames$ = of(tciNames);
    this.tciNames = tciNames;
  }

  getTCIName(id) {
    const tciNameObject = this.tciNames.find((element) => element.id === id);

    if (tciNameObject) {
      return tciNameObject.name;
    } else {
      return '';
    }
  }

  async setNewerOlderDate() {
    if (
      this.tciList[1].operationDateObject > this.tciList[0].operationDateObject
    ) {
      this.newEntity = this.tciList[1];
      this.oldEntity = this.tciList[0];
    } else {
      this.newEntity = this.tciList[0];
      this.oldEntity = this.tciList[1];
    }
  }

  getValue(index, property) {
    if (index === 0) {
      return this.newEntity[property];
    } else {
      return this.oldEntity[property];
    }
  }

  async formatFields() {
    this.tciList.forEach((element) => {
      if (element.brokerName) {
        element.broker = true;
      } else {
        element.broker = false;
      }

      if (element.minimumPremiumAmount) {
        element.minimumPremium = true;
      } else {
        element.minimumPremium = false;
      }

      if (element.singleCheckFeeAmountGermany) {
        element.checkFeeTypes = this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CHECK-FEE-TYPES.NAME-1'
        );
      } else {
        element.checkFeeTypes = this.translateService.instant(
          'TCI.CREATE.FORM-OVERVIEW-TAB.DEFAULT-CHECK-FEE-TYPES.NAME-2'
        );
      }

      if (element.maximumTotalAmountOfTheLimit) {
        element.maxTotalLimitGranted = true;
      } else {
        element.maxTotalLimitGranted = false;
      }

      if (
        element.foreignCountryTciValues &&
        element.foreignCountryTciValues.length > 0
      ) {
        element.foreignCountryTciValues.forEach((item) => {
          item.country = this.getCountryName(item.isoCode);
        });
      }
    });
  }

  async formatForeignCountries() {
    if (
      this.newEntity.foreignCountryTciValues &&
      this.newEntity.foreignCountryTciValues.length > 0
    ) {
      this.newEntity.foreignCountryTciValues.forEach((element) => {
        const result: any[] = [];
        const oldCountryValues = this.oldEntity.foreignCountryTciValues.find(
          (item) => item.isoCode === element.isoCode
        );

        if (oldCountryValues) {
          result.push(oldCountryValues);
          result.push(element);
        } else {
          result.push(this.emptyCountryObject);
          result.push(element);
        }
        this.countries.push(result);
      });
    }

    if (this.oldEntity.foreignCountryTciValues.length > this.countries.length) {
      this.oldEntity.foreignCountryTciValues.forEach((element) => {
        const result: any[] = [];
        const found = this.countries.find(
          (item) => item[0].isoCode === element.isoCode
        );

        if (found === undefined) {
          result.push(element);
          result.push(this.emptyCountryObject);
          this.countries.push(result);
        }
      });
    }
  }

  getCountryName(isoCode) {
    if (isoCode && this.allCountries && this.allCountries.length > 0) {
      const country = this.allCountries.filter(
        (countryP) => countryP.cca3 === isoCode
      )[0];
      if (this.translateService.currentLang === 'de') {
        return country.translations.deu.common;
      }
      return country.name.common;
    } else {
      return '';
    }
  }
}
