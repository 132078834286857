<div class="content-container">
  <div class="website-credits-container">
    <div class="row title-container">
      <span>Website Credits</span>
    </div>
    <div class="row mt-row text-container">
      <span>Website Credits according to § 5 Telemediengesetz (TMG):</span>
    </div>
    <div class="address-container mt-row">
      <div class="row title-container">
        <span>BFS finance GmbH</span>
      </div>
      <div class="row text-container">
        <span>Carl-Bertelsmann-Str. 23</span>
      </div>
      <div class="row text-container">
        <span>33332 Gütersloh</span>
      </div>
      <div class="row text-container">
        <span>Deutschland</span>
      </div>
    </div>

    <div class="phones-container mt-row">
      <div class="row text-container">
        <span>Phone: +49 (0)5241 80-43199</span>
      </div>
      <div class="row text-container">
        <span>Fax: +49 (0)5241 80-43130</span>
      </div>
      <div class="row text-container">
        <span>E-Mail: <a href="mailto:info@bfs-finance.com">info@bfs-finance.com</a> </span>
      </div>
    </div>

    <div class="vat-container mt-row">
      <div class="row text-container">
        <span>VAT no.: DE 812731086</span>
      </div>
      <div class="row text-container">
        <span>Registry court / no.: Gütersloh district court, HRB 3823</span>
      </div>
    </div>

    <div class="row mt-row text-container">
      <span>Managing Directors:</span>
    </div>
    <div class="row text-container">
      <span>Rudolf Gellrich, Hendrik Lamers</span>
    </div>

    <div class="row mt-row text-container">
      <span>Qualified person within the meaning of Section 12 (4) RDG: Stefan Lang</span>
    </div>
    <div class="mt-row">
      <div class="row text-container">
        <span
          >Supervisory authority/registration authority within the meaning of the Legal Services Act is the President of
          the Higher Regional Court of Hamm, Heßlerstraße 53, 59065 Hamm, Germany.</span
        >
      </div>
    </div>
    <div class="row mt-row text-container">
      <span
        >BFS finance GmbH is authorized to provide financial services by the German Federal Financial Supervisory
        Authority (BaFin), Graurheindorfer Straße 108, 53117 Bonn, Germany.</span
      >
    </div>
    <div class="row mt-row title-container">
      <span>Disclaimer</span>
    </div>
    <div class="row mt-row text-container">
      <span
        >All information on this website has been carefully checked. We make every effort to continually expand and
        update the information it contains, but cannot guarantee its completeness, accuracy and complete up-to-dateness.
        BFS finance GmbH provides this information without any assurances or warranties of any kind, either express or
        implied. BFS finance GmbH excludes all liability for damages arising directly or indirectly from the use of this
        website, provided they are not based on intent or gross negligence on the part of BFS finance GmbH. Our websites
        contain links to other websites. These links serve a purely informational purpose. We have no control over the
        future development of the content on these linked websites. Therefore, the operators of the linked sites are
        solely responsible for their content.As a content provider within the meaning of § 7 (1) TMG, BFS finance GmbH
        is responsible in accordance with the general laws for its own content which is made available for use on
        tierwohl.BeVison.app. Cross-references (“links”) to content provided by other parties are to be distinguished
        from this “own” content. By providing such links, BFS finance GmbH makes third-party content available for use.
        This external content was checked for possible civil or criminal liability when the link was first created.
        However, it cannot be ruled out that the content is subsequently changed by its respective provider. BFS finance
        GmbH does not constantly check the content to which it links in its website(s) for changes that might give rise
        to new liability. If you believe that a linked external site violates applicable law or includes content that is
        otherwise inappropriate, please let us know.</span
      >
    </div>
    <div class="row mt-row title-container">
      <span>Copyright</span>
    </div>
    <div class="row mt-row text-container">
      <span
        >Copyright © 2019 BFS finance GmbH. All rights reserved. All content (text, images, graphics, sound, video and
        animation files as well as their grouping etc.) on the BFS finance GmbH website is protected by copyright and
        other protective laws. This legal protection also extends to databases and similar facilities. The contents are
        freely usable only for their intended purpose of being viewed on the Internet. Beyond the limits of copyright
        law, the content of this website may not be reproduced, disseminated, altered or made accessible to third
        parties in any form without the written permission of the BFS finance GmbH. Some sections of the BFS finance
        GmbH website also contain pictures that are subject to third-party copyrights. Unless otherwise specified, all
        brand names and logos on the BFS finance GmbH website are trademarked.</span
      >
    </div>
  </div>
</div>
