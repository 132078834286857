import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { academicTitlesEnum } from 'src/app/in-memory-data/user-management/enums/academic-title-enum';
import { teamEnum } from 'src/app/in-memory-data/user-management/enums/team-enum';
import { titlesEnum } from 'src/app/in-memory-data/user-management/enums/title-enum';
import { ServicePinPolicyModalComponent } from 'src/app/shared/modals/service-pin-policy-modal/service-pin-policy-modal.component';
import { ServicePinConditionsValidator } from 'src/app/shared/validators/general-validator';
import { contactTypeEnum } from '../../../../../in-memory-data/company/contact-person/enums/contact-types-enums';
import { take } from 'rxjs/operators';
import { UserService } from '../../../../../shared/services/user/user.service';

@Component({
  selector: 'app-general-user-info-external',
  templateUrl: './general-user-info-external.component.html',
  styleUrls: ['./general-user-info-external.component.less'],
})
export class GeneralUserInfoExternalComponent implements OnInit, AfterViewInit {
  entityName = 'user-general-info-external';
  generalUserInfoExternalForm: UntypedFormGroup;
  titles = titlesEnum;
  academicTitles = academicTitlesEnum;
  contactTypes = contactTypeEnum;
  teams = teamEnum;
  @Input() isTask: boolean;
  @Input() taskValue;
  @Input() approvedValue;
  state;
  currentUser: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.generalUserInfoExternalFormBuilder();
    this.onUsernameChange();
    this.state = window.history.state;
    if (this.state.viewMode) {
      this.generalUserInfoExternalForm.disable();
    }
  }

  ngAfterViewInit() {
    if (
      this.approvedValue &&
      this.approvedValue.contact &&
      this.approvedValue.contact.id
    ) {
      this.generalUserInfoExternalForm.patchValue(this.approvedValue.contact);
      this.generalUserInfoExternalForm.get('id').patchValue(null);
      this.generalUserInfoExternalForm
        .get('companyGuid')
        .patchValue(this.approvedValue.company.guid);
      const centralMasterData =
        this.approvedValue.company.name +
        ', ' +
        this.approvedValue.company.address.street +
        ', ' +
        this.approvedValue.company.address.number +
        ', ' +
        this.approvedValue.company.address.zipCode +
        ', ' +
        this.approvedValue.company.address.city +
        ', ' +
        this.approvedValue.company.address.country;
      this.generalUserInfoExternalForm
        .get('centralMasterData')
        .patchValue(centralMasterData);
      this.getCuurentUserName();
      this.getCurrentDate();
      this.generalUserInfoExternalForm
        .get('contactPersonId')
        .patchValue(this.approvedValue.contact.id);
    }
  }

  generalUserInfoExternalFormBuilder() {
    this.generalUserInfoExternalForm = this.formBuilder.group({
      id: [{ value: null, disabled: true }],
      companyGuid: [{ value: null, disabled: true }],
      userName: [{ value: null, disabled: false }, Validators.required],
      email: [{ value: null, disabled: true }],
      title: [{ value: null, disabled: true }],
      academicTitle: [{ value: null, disabled: false }],
      firstName: [{ value: null, disabled: true }],
      lastName: [{ value: null, disabled: true }],
      telephoneNumber: [{ value: null, disabled: true }],
      faxNumber: [{ value: null, disabled: true }],
      birthday: [{ value: null, disabled: true }],
      contactType: [{ value: null, disabled: true }],
      team: [{ value: null, disabled: true }],
      centralMasterData: [{ value: null, disabled: true }],
      servicePin: [
        { value: null, disabled: true },
        [ServicePinConditionsValidator()],
      ],
      createdOn: [{ value: null, disabled: true }],
      createdBy: [{ value: null, disabled: true }],
      updatedOn: [{ value: null, disabled: true }],
      updatedBy: [{ value: null, disabled: true }],
      status: [{ value: null, disabled: true }],
      lastLogin: [{ value: null, disabled: true }],
      validTo: [{ value: null, disabled: false }],
      termsAndServicesAccepted: [{ value: null, disabled: true }],
      inIniProcess: [{ value: null, disabled: true }],
      externalSource: [{ value: null, disabled: false }],
      contactPersonId: [{ value: null, disabled: false }],
    });
  }

  getCuurentUserName() {
    this.userService.getSettings().subscribe((data) => {
      this.currentUser = data.user.username;
      this.generalUserInfoExternalForm
        .get('createdBy')
        .patchValue(this.currentUser);
    });
  }

  getCurrentDate() {
    const currentDate = new Date();
    this.generalUserInfoExternalForm.get('createdOn').patchValue(currentDate);
  }

  showServicePinPolicy() {
    this.matDialogService.open(ServicePinPolicyModalComponent, {
      panelClass: 'confirmation-popup',
    });
  }

  onUsernameChange(): void {
    this.generalUserInfoExternalForm
      .get('userName')
      .valueChanges.subscribe((username) => {
        this.checkUsernameTaken(username);
      });
  }

  private checkUsernameTaken(username) {
    if (username) {
      this.userService
        .isUsernameTaken(username, true)
        .pipe(take(1))
        .subscribe((isUserNameTaken) => {
          if (isUserNameTaken) {
            this.generalUserInfoExternalForm
              .get('userName')
              .setErrors({ usernameTaken: true });
          }
        });
    }
  }
}
