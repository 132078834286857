import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TransactionSearchFilter } from '../accounting-overview-functional-buttons/accounting-overview-functional-buttons.component';

@Component({
  selector: 'app-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.less'],
})
export class AccountPeriodFilterComponent implements OnInit {
  searchForm: UntypedFormGroup;
  @Input() searchTerm: BehaviorSubject<TransactionSearchFilter>;

  constructor() {}

  ngOnInit(): void {
    this.searchFormBuilder();
    this.searchFormValueChanges();
  }

  searchFormBuilder() {
    this.searchForm = new UntypedFormGroup({
      freeFormSearch: new UntypedFormControl('', []),
      documentDate: new UntypedFormGroup({
        startDate: new UntypedFormControl(null, []),
        endDate: new UntypedFormControl(null, []),
        fieldName: new UntypedFormControl('openItemDate'),
      }),
    });
  }

  searchFormValueChanges() {
    this.searchForm.valueChanges.pipe(debounceTime(700)).subscribe((value) => {
      if (
        value &&
        value.documentDate.startDate != null &&
        value.documentDate.endDate != null
      ) {
        value.documentDate.startDate = moment(value.documentDate.startDate)
          .toDate()
          .setHours(0, 0, 0);
        value.documentDate.startDate = moment(value.documentDate.startDate)
          .toDate()
          .setMilliseconds(0);
        value.documentDate.endDate = moment(value.documentDate.endDate)
          .toDate()
          .setHours(0, 0, 0);
        value.documentDate.endDate = moment(value.documentDate.endDate)
          .toDate()
          .setMilliseconds(0);
        value.documentDate.startDate = moment(
          value.documentDate.startDate
        ).format('YYYY-MM-DD');
        value.documentDate.endDate = moment(value.documentDate.endDate).format(
          'YYYY-MM-DD'
        );
        this.searchTerm.next(value);
      }
    });
  }

  clearPeriodFilter() {
    this.searchForm.controls.documentDate.get('startDate').reset();
    this.searchForm.controls.documentDate.get('endDate').reset();
    this.searchTerm.next(this.searchForm.value);
  }
}
