export const kindFilterEnum: any[] = [
  {
    name: 'null',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.0',
  },
  {
    name: 'DEPOSIT_FRT',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.DEPOSIT-FRT',
  },
  {
    name: 'REVERSAL_OF_ACCRUALS',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.REVERSAL-OF-ACCRUALS',
  },
  {
    name: 'NOT_NEEDED_OPERATING_COSTS',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.NOT-NEEDED-OPERATING-COSTS',
  },
  {
    name: 'WITHDRAWAL_LIQUIDITY_FROM_A_PREVIOUS_PROGRAM',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.WITHDRAWAL-LIQUIDITY-FROM-A-PREVIOUS-PROGRAM',
  },
  {
    name: 'RECOVERY_FROM_LIVESTOCK_PRODUCERS',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.RECOVERY-FROM-LIVESTOCK-PRODUCERS',
  },
  {
    name: 'UNUSED_FONDS_OF_LIVESTOCK_PRODUCERS',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.UNUSED-FONDS-OF-LIVESTOCK-PRODUCERS',
  },
  {
    name: 'NOT_NEEDED_REVERSAL_OF_ACCRUALS',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.NOT-NEEDED-REVERSAL-OF-ACCRUALS',
  },
  {
    name: 'ACCOUNTING_AUDITOR',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ACCOUNTING-AUDITOR',
  },
  {
    name: 'PAYOUT_AGRICULTURE',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.PAYOUT-AGRICULTURE',
  },
  {
    name: 'ACCRUALS',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ACCRUALS',
  },
  {
    name: 'OPERATING_COSTS',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.OPERATING-COSTS',
  },
  {
    name: 'ACCRUALS_OPERATING_COSTS',
    translateName:
      'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.ACCRUALS-OPERATING-COSTS',
  },
  {
    name: 'OTHERS',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.OTHERS',
  },
];
