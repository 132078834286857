import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AnimalWelfareFile } from '../../../models/animal-welfare-file';

@Component({
  selector: 'app-aw-itp-protocol-details-modal',
  templateUrl: './aw-itp-protocol-details-modal.component.html',
  styleUrls: ['./aw-itp-protocol-details-modal.component.less'],
})
export class AwItpProtocolDetailsModalComponent implements OnInit {
  awFile: AnimalWelfareFile;

  statusString: string;
  progressString: string;
  dateFormat: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, userService: UserService) {
    if (data.awFile) {
      this.awFile = data.awFile;
    }
    this.dateFormat = userService.getDateFormat();
  }

  ngOnInit() {
    if (this.awFile.status != null && this.awFile.status !== '') {
      this.statusString =
        'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.STATUS-VALUES.' +
        this.awFile.status;
    } else {
      this.statusString = '';
    }

    if (this.awFile.progress != null && this.awFile.progress !== '') {
      this.progressString =
        'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.PROGRESS-VALUES.' +
        this.awFile.progress;
    } else {
      this.progressString = '';
    }
  }
}
