<div class="animal-welfare-liquidity-plan-asset-result-list">
  <div class="loader" *ngIf="loadSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>

<app-default-table
  *ngIf="!loadSpinner"
  [columns]="columns"
  [configurationKey]="configurationKey"
  [displayedColumns]="displayedColumns"
  [entityName]="entityName"
  [originalColumns]="columns"
  [originalDisplayedColumns]="displayedColumns"
  [tableDataSource]="liquidityPlanList"
  [threeDots]="true"
  [listOfActionButtons]="listOfActionButtons"
  (filterEvent)="filter($event)"
  [distinctValues]="getDistinctValues"
  [totalCount]="total"
>
</app-default-table>
