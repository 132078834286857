import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ChecksumService } from './checksum.service';

@Component({
  selector: 'app-checksum',
  templateUrl: './checksum.component.html',
  styleUrls: ['./checksum.component.less'],
})
export class ChecksumComponent implements OnInit {
  checksum = new UntypedFormControl(0);

  constructor(private checksumService: ChecksumService) {}

  ngOnInit(): void {
    this.checksumService.checksumValueChanged.subscribe((newValue) => {
      if (!isNaN(newValue)) {
        this.checksum.setValue(newValue);
      }
    });
  }
}
