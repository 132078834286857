import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { buColumnsConf } from 'src/app/in-memory-data/business-unit/table-columns-configuration';
import { buColumns } from 'src/app/in-memory-data/business-unit/table-columns';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { productEnum } from '../../../in-memory-data/business-unit/enums/product-enum';
import { BusinessUnitPool } from '../../../shared/models/business-unit-pool';
import { BusinessUnitPoolService } from '../../../shared/services/business-unit-pool/business-unit-pool.service';

@Component({
  selector: 'app-bu-list',
  templateUrl: './bu-list.component.html',
  styleUrls: ['./bu-list.component.less'],
})
export class BuListComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private buService: BusinessUnitService,
    private notificationService: NotificationService,
    private userService: UserService,
    private draftService: DraftService,
    private businessUnitPoolService: BusinessUnitPoolService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
    this.buService.setSearchString('');
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  products = productEnum;
  poolsSelectable: BusinessUnitPool[] = [];

  columns = buColumnsConf;
  displayedColumns = buColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  draftId: any;
  private CONFIGURAIONT_KEY = 'bu_table';

  buData = [];
  dataSource = new MatTableDataSource<any>(this.buData);
  searchInput: any;
  originalDataSource;
  entityName = 'business-unit';

  totalCount: number;
  pageSize = 20;
  sortedBy = 'id';
  sortDirection = 'asc';

  loaderSpinner = true;
  subject = new Subject();

  filter;

  getFieldNames = (args): Observable<any> => {
    return this.buService.getFilterFieldNameValues(args);
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.paginator.firstPage();
    this.buService.setColumnFilter(choice, selection);
    this.subject.next(undefined);
  };

  sortTable = (choice, direction): void => {
    this.loaderSpinner = true;
    this.paginator.firstPage();
    this.sortedBy = choice;
    this.sortDirection = direction;
    this.subject.next(undefined);
  };

  createNew() {
    this.router.navigateByUrl('systemConfiguration/bu/create');
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                buColumnsConf,
                buColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = buColumnsConf;
    this.displayedColumns = buColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.buService
        .getAllBusinessUnits(
          this.sortedBy,
          this.sortDirection,
          this.paginator.pageIndex + 1,
          this.pageSize
        )
        .subscribe((data) => {
          this.loaderSpinner = false;
          this.originalDataSource = data.content;
          this.buData = this.dataSource.data = data.content;
          this.totalCount = data.total;
          if (data.content.length === 0) {
            this.notificationService.showToast(
              'ERROR.NO-BU-FOUND',
              this.notificationService.MESSAGE_TYPE.INFO
            );
          }
          this.getDrafts();
        });
    });
    this.getAllBusinessUnits();
    this.getBusinessUnitPools();
  }

  getAllBusinessUnits() {
    const page = this.paginator.pageIndex + 1;
    const pageSize = this.pageSize;
    const sortedBy = this.sortedBy;
    const sortDirection = this.sortDirection;
    this.buService
      .getAllBusinessUnits(sortedBy, sortDirection, page, pageSize)
      .subscribe((data) => {
        this.loaderSpinner = false;
        this.notificationService.dismissMessage();
        this.originalDataSource = data.content;
        this.buData = this.dataSource.data = data.content;
        this.totalCount = data.total;

        if (data.content.length === 0) {
          this.notificationService.showToast(
            'ERROR.NO-BU-FOUND',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        }
        this.getDrafts();
      });
  }

  getBusinessUnitPools() {
    this.businessUnitPoolService
      .getAllBusinessUnitPools()
      .subscribe((pools) => {
        pools.forEach((pool) => {
          this.poolsSelectable.push(pool);
        });
      });
  }

  getDrafts() {
    this.draftService.retrieveLocalDrafts('BUSINESS_UNIT').subscribe((data) => {
      if (data != null) {
        data.forEach((draft) => {
          const businessUnit = JSON.parse(draft.draftJson);
          businessUnit.draft = true;
          businessUnit.draftId = draft.guid;
          businessUnit.operationUser = draft.operationUser;

          this.dataSource.data.unshift(businessUnit);
        });
        this.dataSource.filter = '';
      }
    });
  }

  clickOnBusinessUnit(businessUnit) {
    const state = { businessUnit };
    if (businessUnit['draft']) {
      state['draftId'] = businessUnit.draftId;
      state['operationUser'] = businessUnit.operationUser;
    }
    this.router.navigateByUrl('systemConfiguration/bu/create', {
      state,
    });
  }

  searchBU() {
    this.loaderSpinner = true;
    const searchString =
      this.filter.get('searchString').value != null
        ? this.filter.get('searchString').value
        : '';
    this.buService.setSearchString(searchString);
    this.paginator.firstPage();
    this.subject.next(undefined);
  }

  findProductName(productCode) {
    if (!this.products) {
      return '';
    }

    const product = this.products.find(
      (element) => element.code === productCode
    );
    return product ? product.name : '';
  }

  findPoolName(poolId) {
    if (!this.poolsSelectable) {
      return '';
    }
    const pool = this.poolsSelectable.find((element) => element.id === poolId);
    return pool ? pool.name : '';
  }

  deleteDraft(draftId) {
    this.draftService.deleteUserDraft(draftId).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    this.getAllBusinessUnits();
  }
}
