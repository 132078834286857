import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-service-pin-policy-modal',
  templateUrl: './service-pin-policy-modal.component.html',
  styleUrls: ['./service-pin-policy-modal.component.less'],
})
export class ServicePinPolicyModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ServicePinPolicyModalComponent>
  ) {}
}
