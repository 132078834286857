<div class="input-wrapper form-container">
  <form class="form" [formGroup]="btcForm" autocomplete="off">
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.BTC.BTC'"
      [placeholder]="'SYSTEM-CONFIGURATION.BTC.BTC'"
      [label]="'SYSTEM-CONFIGURATION.BTC.BTC'"
      [entityName]="entityName"
      [form]="btcForm"
      [controlObject]="btcForm.controls.code"
      [controlName]="'code'"
      [approvedValue]="btcApproved ? btcApproved.code : null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'uppercase'"
    ></app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.BTC.NAME'"
      [placeholder]="'SYSTEM-CONFIGURATION.BTC.NAME'"
      [label]="'SYSTEM-CONFIGURATION.BTC.NAME'"
      [entityName]="entityName"
      [form]="btcForm"
      [controlObject]="btcForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="btcApproved ? btcApproved.name : null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
    ></app-mat-input>
  </form>
</div>

<div class="filter-input button-container">
  <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" (click)="changeEditMode()">
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>

  <button mat-button class="custom-button-cancel" id="{{ entityName }}-create-draft-button" (click)="saveAsDraft()">
    <span *ngIf="draftId" id="{{ entityName }}-update-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
    </span>
    <span *ngIf="!draftId" id="{{ entityName }}-new-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
    </span>
  </button>

  <ng-container>
    <button
      *ngIf="!edit"
      mat-button
      class="custom-button"
      [disabled]="!btcForm.valid"
      id="{{ entityName }}-create-button"
      (click)="onClickApproval()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>

    <button
      *ngIf="edit"
      mat-button
      class="custom-button"
      [disabled]="!btcForm.valid"
      id="{{ entityName }}-update-button"
      (click)="onClickApproval()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
