<div class="dialog-content enter-actual-value-container">
  <form [formGroup]="enterActualValueForm" autocomplete="off">
    <div mat-dialog-content class="background-dark">
      <h1 mat-dialog-title>
        {{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ENTER-ACTUAL-VALUE' | translate }}
      </h1>
      <app-currency-input
        [labelKey]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ENTER-ACTUAL-VALUE'"
        [control]="enterActualValueForm.controls.actualValue"
        [suffix]="'€'"
        >
      </app-currency-input>
      <mat-form-field class="white-back">
        <mat-label>{{ 'comment' | translate }}</mat-label>
        <textarea
          matInput
          placeholder="{{ 'comment' | translate }}"
          name="comment"
          formControlName="comment"
          id="{{ entityName }}-comment-textarea"
          required="true"
        ></textarea>
        <mat-error
          id="{{ entityName }}-form-comment-errorRequired"
          class="input-error"
          *ngIf="enterActualValueForm.controls.comment.hasError('required')"
        >
          {{
            'ERROR-MESSAGES.REQUIRED'
              | translate
                : {
                    name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.COMMENT' | translate
                  }
              | titlecase
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="buttons">
      <button id="{{ entityName }}-comment-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
        {{ 'cancel' | translate }}
      </button>
      <button
        id="{{ entityName }}-comment-approval-button"
        mat-button
        class="custom-button"
        [disabled]="enterActualValueForm.invalid"
        (click)="save()"
      >
        {{ 'submit' | translate }}
      </button>
    </div>
  </form>
</div>
