import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'sortByCurrencyCodes',
  pure: false,
})
export class SortByCurrencyCodesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any[], criteria: SortCriteriaCurrencies): any[] {
    if (!value || !criteria) {
      return value;
    }

    const sortFn: (a: any, b: any) => any = (a, b) => {
      let valueTemp = 0;

      if (a === undefined || this.translateService.instant(a) === undefined) {
        valueTemp = -1;
      } else if (
        b === undefined ||
        this.translateService.instant(b) === undefined
      ) {
        valueTemp = 1;
      } else {
        valueTemp =
          this.translateService.instant(a) > this.translateService.instant(b)
            ? 1
            : this.translateService.instant(b) >
              this.translateService.instant(a)
            ? -1
            : 0;
      }
      return criteria.descending ? valueTemp * -1 : valueTemp;
    };

    value.sort(sortFn);

    return value;
  }
}

export interface SortCriteriaCurrencies {
  descending?: boolean;
}
