export const deductionReasonsSelectionEnum: any[] = [
  {
    code: 1,
    name: 'DISCOUNT',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.DISCOUNT',
    enable: false,
  },
  {
    code: 2,
    name: 'PROMOTION_DEDUCTION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.PROMOTION_DEDUCTION',
    enable: false,
  },
  {
    code: 3,
    name: 'BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.BONUS',
    enable: false,
  },
  {
    code: 4,
    name: 'PAYMENT_DEDUCTION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.PAYMENT_DEDUCTION',
    enable: false,
  },
  {
    code: 5,
    name: 'OTHER_DEDUCTION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.OTHER_DEDUCTION',
    enable: false,
  },
  {
    code: 6,
    name: 'CENTRAL_SETTLEMENT_FEE_OR_COMPENSATION',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.CENTRAL_SETTLEMENT_FEE_OR_COMPENSATION',
    enable: false,
  },
  {
    code: 7,
    name: 'ALLIANCE_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.ALLIANCE_BONUS',
    enable: false,
  },
  {
    code: 8,
    name: 'BANK_FEE',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.BANK_FEE',
    enable: false,
  },
  {
    code: 9,
    name: 'EXECUTIVE_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.EXECUTIVE_BONUS',
    enable: false,
  },
  {
    code: 10,
    name: 'DEL_CREDERE_CHARGE_OR_LEVY',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.DEL_CREDERE_CHARGE_OR_LEVY',
    enable: false,
  },
  {
    code: 11,
    name: 'PRIVATE_BRAND_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.PRIVATE_BRAND_BONUS',
    enable: false,
  },
  {
    code: 12,
    name: 'WARRANTY_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.WARRANTY_BONUS',
    enable: false,
  },
  {
    code: 13,
    name: 'SEMI-ANNUAL_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.SEMI-ANNUAL_BONUS',
    enable: false,
  },
  {
    code: 14,
    name: 'HOUSE_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.HOUSE_BONUS',
    enable: false,
  },
  {
    code: 15,
    name: 'ANNUAL_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.ANNUAL_BONUS',
    enable: false,
  },
  {
    code: 16,
    name: 'ACCOUNT_MAINTENANCE_FEE',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.ACCOUNT_MAINTENANCE_FEE',
    enable: false,
  },
  {
    code: 17,
    name: 'CONCENTRATION_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.CONCENTRATION_BONUS',
    enable: false,
  },
  {
    code: 18,
    name: 'EXCHANGE_RATE_DIFFERENCE',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.EXCHANGE_RATE_DIFFERENCE',
    enable: false,
  },
  {
    code: 19,
    name: 'STOCK_VALUE_COMPENSATION-STOCK_CLEARING',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.STOCK_VALUE_COMPENSATION-STOCK_CLEARING',
    enable: false,
  },
  {
    code: 20,
    name: 'MARKETING_FEE',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.MARKETING_FEE',
    enable: false,
  },
  {
    code: 21,
    name: 'PACKAGE_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.PACKAGE_BONUS',
    enable: false,
  },
  {
    code: 22,
    name: 'PRICE_DIFFERENCE',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.PRICE_DIFFERENCE',
    enable: false,
  },
  {
    code: 23,
    name: 'COMMISSION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.COMMISSION',
    enable: false,
  },
  {
    code: 24,
    name: 'QUARTERLY_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.QUARTERLY_BONUS',
    enable: false,
  },
  {
    code: 25,
    name: 'REDUCTION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.REDUCTION',
    enable: false,
  },
  {
    code: 26,
    name: 'ROUNDING_REFERENCE',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.ROUNDING_REFERENCE',
    enable: false,
  },
  {
    code: 27,
    name: 'IMMEDIATE_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.IMMEDIATE_BONUS',
    enable: false,
  },
  {
    code: 28,
    name: 'SPECIAL_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.SPECIAL_BONUS',
    enable: false,
  },
  {
    code: 29,
    name: 'INCREASE',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.INCREASE',
    enable: false,
  },
  {
    code: 30,
    name: 'SYSTEM_SUBMISSION',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.SYSTEM_SUBMISSION',
    enable: false,
  },
  {
    code: 31,
    name: 'TOP_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.TOP_BONUS',
    enable: false,
  },
  {
    code: 32,
    name: 'ASSOCIATION_LEVY',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.ASSOCIATION_LEVY',
    enable: false,
  },
  {
    code: 33,
    name: 'DISTRIBUTION_SERVICES_LEVY',
    translateName:
      'TRANSACTION.DEDUCTION_REASON_SELECTION.DISTRIBUTION_SERVICES_LEVY',
    enable: false,
  },
  {
    code: 34,
    name: 'TARGET_BONUS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.TARGET_BONUS',
    enable: false,
  },
  {
    code: 35,
    name: 'OTHER_CONDITIONS',
    translateName: 'TRANSACTION.DEDUCTION_REASON_SELECTION.OTHER_CONDITIONS',
    enable: false,
  },
];
