import { Component, OnInit, Input } from '@angular/core';
import { OpenItemService } from '../../services/open-item/open-item.service';
import { UserService } from '../../services/user/user.service';
import { isEmpty } from 'src/app/utils/object-util';
import { AzureFile } from '../../models/azure-file';
import { FileType } from '../../models/file-type';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { transactionTypes } from 'src/app/in-memory-data/debtor/history/transactionTypes';
import { FileService } from '../../services/file/file.service';
import { transactionTypesObjection } from 'src/app/in-memory-data/objection-management/history/transactionTypes';
import { classificationsSelectionEnum } from '../../../in-memory-data/split-screen/split-transaction-data/enums/classifications-enum';
import { deductionReasonsSelectionEnum } from 'src/app/in-memory-data/split-screen/split-transaction-data/enums/deduction-reasons-enum ';

@Component({
  selector: 'app-split-transactions-history',
  templateUrl: './split-transactions-history.component.html',
  styleUrls: ['./split-transactions-history.component.less'],
})
export class SplitTransactionsHistoryComponent implements OnInit {
  @Input() transactionId;
  @Input() itemPerObjectionId;
  panelOpenState = false;
  transactionHistory: any[] = [];
  originalHistory: any[] = [];
  loader = true;

  transactionTypes: any;
  entityName = 'transactionHistory';

  filter: UntypedFormGroup;
  dateFormat = '';

  deductionReasons = deductionReasonsSelectionEnum;

  constructor(
    private openItemsService: OpenItemService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService
  ) {
    this.filter = this.formBuilder.group({
      type: ['', []],
      dateRange: new UntypedFormGroup({
        begin: new UntypedFormControl(null, []),
        end: new UntypedFormControl(null, []),
      }),
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    if (this.itemPerObjectionId) {
      this.transactionTypes = transactionTypesObjection;
    } else {
      this.transactionTypes = transactionTypes;
    }

    this.getTransactionHistory();
    this.onFilterChange();
  }

  clearFilters() {
    this.filter.controls.dateRange.get('begin').reset();
    this.filter.controls.dateRange.get('end').reset();
    this.getTransactionHistory();
    this.onFilterChange();
  }

  onFilterChange(): void {
    this.filter.valueChanges.subscribe((val) => {
      let result: any[] = [];

      if (this.originalHistory.length === 0) {
        return;
      } else {
        result = this.filterByType(this.originalHistory, val);
        result = this.filterByDateRange(result, val);
      }

      result = this.removeRepeated(result);

      this.transactionHistory = result;
    });
  }

  filterByType(data, val) {
    let result: any[] = [];

    data.forEach((transaction: any) => {
      if (val.type.length > 0) {
        val.type.forEach((type) => {
          if (
            type.toString().toLowerCase() ===
            transaction.type.toString().toLowerCase()
          ) {
            result.push(transaction);
          }
        });
      }
    });

    if (result.length === 0 && val.type.length === 0) {
      result = this.originalHistory;
    }

    return result;
  }

  filterByDateRange(data, val) {
    let result: any[] = [];

    data.forEach((transaction: any) => {
      if (val.dateRange !== '') {
        if (val.dateRange.end) {
          const transactionDate = new Date(transaction.operationDate);
          transactionDate.setHours(0, 0, 0);
          transactionDate.setMilliseconds(0);

          val.dateRange.begin = new Date(val.dateRange.begin).setHours(0, 0, 0);
          val.dateRange.begin = new Date(val.dateRange.begin).setMilliseconds(
            0
          );

          val.dateRange.end = new Date(val.dateRange.end).setHours(0, 0, 0);
          val.dateRange.end = new Date(val.dateRange.end).setMilliseconds(0);

          if (
            transactionDate.getTime() <= val.dateRange.end &&
            transactionDate.getTime() >= val.dateRange.begin
          ) {
            result.push(transaction);
          }
        }
      }
    });

    if (
      result.length === 0 &&
      val.dateRange === '' &&
      val.dateRange.begin === undefined &&
      val.dateRange.end === undefined
    ) {
      result = data;
    }

    return result;
  }

  removeRepeated(data) {
    return data.filter(
      (v, i, a) => a.findIndex((t) => t.index === v.index) === i
    );
  }

  getTransactionHistory() {
    if (this.itemPerObjectionId) {
      this.openItemsService
        .getObjectionTransactionHistory(this.itemPerObjectionId)
        .subscribe((data) => this.updateData(data));
    } else {
      this.openItemsService
        .getTransactionHistory(this.transactionId)
        .subscribe((data) => this.updateData(data));
    }
  }

  getUserInfo(transaction) {
    if (transaction) {
      return !isEmpty(transaction.firstName) && !isEmpty(transaction.lastName)
        ? transaction.lastName +
            ', ' +
            transaction.firstName +
            ' (' +
            transaction.operationUser +
            ')'
        : 'system-user';
    }
  }

  formatDataDetails() {
    this.transactionHistory.forEach((item: any, index) => {
      item.detailsObject = JSON.parse(item.details);
      item.index = index;
    });
    this.loader = false;
  }

  downloadAttachment(file: AzureFile) {
    let fileType;

    if (this.itemPerObjectionId) {
      fileType = FileType.objection;
    } else {
      fileType = FileType.transaction;
    }

    this.fileService
      .getFile(fileType, file.fileName, file['id'])
      .subscribe((data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');
        anchor.download = file.fileName;
        anchor.href = url;
        anchor.click();
      });
  }

  getClassificationNameTranslation(classificationName): string {
    let classificationNameTranslation = '';
    classificationsSelectionEnum.forEach((element) => {
      if (element.name === classificationName) {
        classificationNameTranslation = element.translateName;
      }
    });
    return classificationNameTranslation;
  }

  getDeductionReasonTranslation(deductionReason): string {
    let deductionReasonTranslation = '';
    this.deductionReasons.forEach((element) => {
      if (element.code === deductionReason) {
        deductionReasonTranslation = element.translateName;
      }
    });
    return deductionReasonTranslation;
  }

  private updateData(data) {
    this.transactionHistory = data;
    this.originalHistory = Object.assign([], this.transactionHistory);
    this.formatDataDetails();
  }
}
