import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { permissionOverviewKnownRolesColumns } from 'src/app/in-memory-data/user-management/permission/permission-known-roles/table-columns';
import { permissionOverviewKnownRolesColumnsConf } from 'src/app/in-memory-data/user-management/permission/permission-known-roles/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import { ActivatedRoute } from '@angular/router';
import { DraftService } from 'src/app/shared/services/draft/draft.service';

@Component({
  selector: 'app-permission-known-roles',
  templateUrl: './permission-known-roles.component.html',
  styleUrls: ['./permission-known-roles.component.less'],
})
export class PermissionKnownRolesComponent implements AfterViewInit {
  configurationKey = 'user-management-permission-known-roles';
  displayedColumns = permissionOverviewKnownRolesColumns;
  columns = permissionOverviewKnownRolesColumnsConf;
  rolesList: any;
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  dataLoaded = false;
  loaderSpinner = true;
  state;
  snapshot;
  configs = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    realm: '',
  };
  usersLoaded;
  searchString: any = '';
  subject = new Subject();

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private draftService: DraftService
  ) {}

  ngAfterViewInit() {
    this.state = window.history.state;
    this.snapshot = this.route.snapshot;
    this.loadData();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loadData();
    });
  }

  startSearch() {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    this.subject.next(undefined);
  }

  filter(event) {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }
    if (event.filteredValues) {
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
    }
    this.loadData();
  }

  loadData() {
    if (this.searchString !== undefined) {
      this.configs['filterText'] = this.searchString;
    }
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }

    if (
      this.snapshot.params &&
      this.snapshot.params.guid &&
      this.state.editMode
    ) {
      if (!this.state.param.internalPermission) {
        this.configs.realm = 'external';
      } else {
        this.configs.realm = 'internal';
      }
      this.loadPermissionsKnowRoles(this.snapshot.params.guid);
    } else {
      this.getGUIDbyTaskInDraftService();
    }
  }

  getGUIDbyTaskInDraftService() {
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        this.configs.realm = data['internalPermission']
          ? 'internal'
          : 'external';
        if (!!data.id) this.loadPermissionsKnowRoles(data.id);
      });
  }

  loadPermissionsKnowRoles(guid: string) {
    this.userService
      .getPermissionKnownRoles(
        {
          ...this.configs,
          filteredValues: JSON.stringify(this.configs.filteredValues),
        },
        guid
      )
      .subscribe(
        (data) => {
          this.rolesList = data;
          this.dataLoaded = true;
          this.loaderSpinner = false;
          if (this.defaultTableComponent) {
            this.defaultTableComponent.setLoaderSpinner(false);
          }
        },
        (error) => {
          this.dataLoaded = true;
          this.loaderSpinner = false;
        }
      );
  }
}
