import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private sharedOpenTransactionRows = new BehaviorSubject<any>(null);
  currenSharedOpenTransactionRows =
    this.sharedOpenTransactionRows.asObservable();

  private sharedCommonForm = new BehaviorSubject<any>(null);
  currenSharedCommonForm = this.sharedCommonForm.asObservable();

  changesInCommonFormFunction = new EventEmitter();
  changesInFormFunction: Subscription;

  disableFieldsFunction = new EventEmitter();
  disableFieldsEmitter: Subscription;

  clearTableFunction = new EventEmitter();
  suscriptionClearTableEmitter: Subscription;

  deleteSelectedRowsFunction = new EventEmitter();
  suscriptionDeleteSelectedRowsEmitter: Subscription;

  private updateDataSet = new BehaviorSubject<any>(null);
  currentDataSet = this.updateDataSet.asObservable();

  updateDataServiceInfo(sharedOpenTransactionRows: any) {
    this.sharedOpenTransactionRows.next(sharedOpenTransactionRows);
  }

  updateSharedCommonForm(sharedCommonForm: any) {
    this.sharedCommonForm.next(sharedCommonForm);
  }

  changesInCommonForm() {
    this.changesInCommonFormFunction.emit();
  }

  disableFields(data) {
    this.disableFieldsFunction.emit(data);
  }

  clearTable() {
    this.clearTableFunction.emit();
  }

  deleteSelectedRows() {
    this.deleteSelectedRowsFunction.emit();
  }

  setDataSet(data) {
    this.updateDataSet.next(data);
  }
}
