<div class="entry-list" id="{{ entityName }}-table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    id="{{ entityName }}-search-results-table"
    class="general-table-alignment"
    multiTemplateDataRows
    aria-hidden="true"
  >
    <ng-container *ngIf="displayedColumns && displayedColumns.length > 0">
      <ng-container
        *ngFor="let displayedColumn of displayedColumns"
        [matColumnDef]="displayedColumn"
        [sticky]="isSticky(displayedColumn)"
        [stickyEnd]="isStickyEnd(displayedColumn)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          [ngClass]="{
            'number-row-header-sortable': isColumnNumber(displayedColumn),
            'small-column': isColumnSmall(displayedColumn) ? true : false
          }"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-{{ displayedColumn }}"
        >
          <span *ngIf="displayedColumn != 'actions' && displayedColumn != 'select' && displayedColumn != 'flags'">
            {{ getColumnHeaderValue(displayedColumn) | translate }}
            <app-table-menu-sort-filter
              *ngIf="
                tableDataSource &&
                tableDataSource['content'] &&
                tableDataSource['content'].length > 0 &&
                !defaultAngularSorting
              "
              [choice]="displayedColumn"
              [sortTable]="sortTable"
              [callback]="filterValues"
              [getDistinctValues]="getDistinctValues"
              [translatePrefix]="getTranslatePrefixForColumnFilter(displayedColumn)"
              [filterEvent]="filterEvent"
              [enableFilter]="enableFilter"
              [pageIndex]="paginator.pageIndex"
              [pageSize]="paginator.pageSize"
              [disableFilterByColumn]="isDisabledFilter(displayedColumn)"
              [disableSortByColumn]="isDisabledSort(displayedColumn)"
            ></app-table-menu-sort-filter>
          </span>
          <span *ngIf="displayedColumn == 'actions'">
            <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
              <mat-icon>settings</mat-icon>
            </button>
          </span>
          <span *ngIf="displayedColumn == 'select'">
            <mat-checkbox
              #ref
              (change)="$event ? masterToggle(ref) : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [disabled]="disableAllSelects"
            >
            </mat-checkbox>
          </span>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-{{ displayedColumn }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          [ngClass]="{
            'number-row': isColumnNumber(displayedColumn),
            'small-column': isColumnSmall(displayedColumn),
            'checkbox-table': displayedColumn === 'select'
          }"
        >
          <span
            *ngIf="
              displayedColumn != 'actions' &&
              displayedColumn != 'select' &&
              displayedColumn != 'flags' &&
              displayedColumn != 'slider' &&
              displayedColumn != 'visible'
            "
            [ngClass]="getColumnClass(element, displayedColumn)"
            [innerHtml]="getColumnValue(element, displayedColumn)"
          >
          </span>
          <span *ngIf="displayedColumn == 'actions' && threeDots">
            <button
              class="menu"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ element: element }"
              (click)="$event.stopPropagation(); selectTheRow(element, selection.isSelected(element))"
              id="{{ entityName }}-table-liquidity-plan-asset-result-{{ i }}"
              [disabled]="element.cancelled || !hasValidItems(element)"
            >
              <span class="material-icons"> more_horiz </span>
            </button>
          </span>
          <span *ngIf="displayedColumn == 'select'">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null; isMoreThanOneSelected(); changeSharedSelectedRows()"
              [checked]="selection.isSelected(element)"
              [aria-label]="checkboxLabel(element)"
              [disabled]="disableAllSelects"
              class="checkbox-table"
            >
            </mat-checkbox>
          </span>
          <span *ngIf="displayedColumn == 'flags'">
            <span *ngFor="let actionIcon of listOfActionIcons">
              <span
                class="{{ actionIcon.class }}"
                *ngIf="element[actionIcon.name]"
                (click)="
                  $event.stopPropagation();
                  selectTheRow(element, selection.isSelected(element));
                  actionIcon.function(element)
                "
                >{{ actionIcon.icon }}</span
              >
            </span>
          </span>
          <span *ngIf="displayedColumn == 'slider' || displayedColumn == 'visible'">
            <span>
              <mat-slide-toggle [checked]="getSliderValue(element, displayedColumn)" [disabled]="true">
              </mat-slide-toggle>
            </span>
          </span>
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="noResults" *ngIf="displayedColumns && displayedColumns.length > 0">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="dynamic-loader-table" *ngIf="loaderSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" class="expanded-table-padding" [attr.colspan]="displayedColumns.length">
        <div
          *ngIf="rowExpanded(element)"
          class="element-detail"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
        >
          <ng-container *ngComponentOutlet="dynamicComponent"></ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{ hidden: expandedComponent }"></tr>

    <tr
      mat-row
      [hidden]="loaderSpinner"
      *matRowDef="let element; columns: displayedColumns; let i = index"
      class="element-row"
      [ngClass]="getClass(element)"
      [class.expanded-row]="expandedElement === element"
      (click)="edit(element)"
    ></tr>

    <tr
      [hidden]="loaderSpinner || dataSource.data.length > 0"
      mat-footer-row
      *matFooterRowDef="['noResults']"
      class="height-footer"
    ></tr>

    <tr
      [hidden]="!loaderSpinner"
      mat-footer-row
      *matFooterRowDef="['loader']"
      class="footer-spinner height-footer"
    ></tr>

    <tr mat-row [hidden]="loaderSpinner" *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>
<div [hidden]="expandedComponent">
  <mat-paginator
    [hidden]="defaultAngularPagination"
    [pageSize]="20"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container *ngFor="let button of listOfActionButtons">
      <div *ngIf="button.permission">
        <button
          mat-menu-item
          (click)="$event.preventDefault(); button.function(element)"
          id="{{ entityName }}-table-{{ configurationKey }}-action-menu-{{ button }}-button"
          [disabled]="
            (button.selectionType == 'single' && isMoreThanOneSelected()) ||
            (button.selectionType == 'multiple' && !isMoreThanOneSelected()) ||
            (button.selectionType != 'singleAndMultiple' &&
              button.selectionType != 'single' &&
              button.selectionType != 'multiple') ||
            checkSpecialDisable(button, element)
          "
          *hasPermission="button.permission"
        >
          <span *ngIf="button.icon" class="material-icons icon-menu button-icon"> {{ button.icon }}</span>
          <span>{{ button.translationKey | translate }}</span>
        </button>
      </div>
      <div *ngIf="!button.permission">
        <button
          mat-menu-item
          (click)="$event.preventDefault(); button.function(element)"
          id="{{ entityName }}-table-{{ configurationKey }}-action-menu-{{ button }}-button"
          [disabled]="
            (button.selectionType == 'single' && isMoreThanOneSelected()) ||
            (button.selectionType == 'multiple' && !isMoreThanOneSelected()) ||
            (button.selectionType != 'singleAndMultiple' &&
              button.selectionType != 'single' &&
              button.selectionType != 'multiple') ||
            checkSpecialDisable(button, element)
          "
        >
          <span *ngIf="button.icon" class="material-icons icon-menu button-icon">{{ button.icon }}</span>
          <span>{{ button.translationKey | translate }}</span>
        </button>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
