import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { models } from 'powerbi-client';
import { PowerbiService } from '../../../../../shared/services/powerbi/powerbi.service';
import { first } from 'rxjs/operators';
import { IReportEmbedConfiguration } from 'embed';

@Component({
  selector: 'app-livestock-payout-blocked',
  templateUrl: './livestock-payout-blocked.component.html',
  styleUrls: ['./livestock-payout-blocked.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LivestockPayoutBlockedComponent implements OnInit {
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: '',
    accessToken: '',
    viewMode: models.ViewMode.View,
    tokenType: models.TokenType.Embed,
    settings: {},
    hostname: 'https://app.powerbi.com',
  };
  reportClass = 'report-container';

  constructor(private powerBIService: PowerbiService) {}

  ngOnInit(): void {
    this.loadPowerBiReportForLanguage();
  }

  /**
   * Embeds the PowerBI report for Settlement Periods in its designated HTML
   * place for the given language. Available versions of this report per
   * language can be found in the PowerBIAPI project's database or ask Volker
   * Greaves for assistance in this matter.
   *
   * @param language
   *   An ISO- two-letter language identifier (e.g. `en` for English, `de` for
   *   German). It should be included in the `reportSuffixesByLanguage`;
   *   otherwise, the English report will be used.
   */
  private loadPowerBiReportForLanguage() {
    this.powerBIService
      .getEmbeddedTokenByName('emb_aw_SblockedLivestockProducer_GER')
      .pipe(first())
      .subscribe((embeddedToken) => {
        this.reportConfig = this.buildEmbedConfiguration(
          embeddedToken['embedUrl'],
          embeddedToken['embedToken']
        );
      });
  }

  private buildEmbedConfiguration(
    embedUrl: string,
    embedToken: string
  ): IReportEmbedConfiguration {
    return {
      type: 'report',
      embedUrl: embedUrl,
      accessToken: embedToken,
      viewMode: models.ViewMode.View,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
      },
      hostname: 'https://app.powerbi.com',
    };
  }
}
