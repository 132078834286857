<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchSubLedgerAccounts()"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchSubLedgerAccounts()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
    <div class="filter-input button-container">
      <button
        *hasPermission="'aw.sl.c'"
        mat-button
        class="custom-button"
        id="{{ entityName }}-create-button"
        (click)="create()"
      >
        {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.CREATE' | translate }}
      </button>
    </div>
  </div>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      (matSortChange)="sortData($event)"
      aria-hidden="true"
    >
      <ng-container matColumnDef="code">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-account-number"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NUMBER' | translate }}
          <app-table-menu-sort-filter choice="code" [sortTable]="sortTable"></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-account-number-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.accountNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name_german">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-account-name-german"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-GERMAN' | translate }}
          <app-table-menu-sort-filter choice="name_german" [sortTable]="sortTable"></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-account-name-german-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.accountNameGerman }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name_english">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-account-name-english"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-ENGLISH' | translate }}
          <app-table-menu-sort-filter choice="name_english" [sortTable]="sortTable"></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-account-name-english-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.accountNameEnglish }}
        </td>
      </ng-container>
      <ng-container matColumnDef="product">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-product"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCT' | translate }}
          <app-table-menu-sort-filter
            choice="product"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
            [translatePrefix]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.'"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-product-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.product | enumToTranslation: 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS' | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="service_model">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-service-model"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODEL' | translate }}
          <app-table-menu-sort-filter
            choice="service_model"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
            [translatePrefix]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.'"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-service-model-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODELS.' + element.serviceModel | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-type"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE' | translate }}
          <app-table-menu-sort-filter
            choice="type"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
            [translatePrefix]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.'"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.' + element.type | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sap_sub_ledger_account">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sap-sub-ledger-account"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SAP-SUB-LEDGER-ACCOUNT' | translate }}
          <app-table-menu-sort-filter
            choice="sap_sub_ledger_account"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
            [callback]="filterData"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sap-sub-ledger-account-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getSapSubLedgerAccount(element.sapSubLedgerAccount) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sales_tax_account">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-sales-tax-account"
        >
          {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SALES-TAX-ACCOUNT' | translate }}
          <app-table-menu-sort-filter
            choice="sales_tax_account"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-sales-tax-account-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getSalesTaxAccount(element.salesTaxAccount) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [hidden]="loaderSpinner"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          'draft-row': row.draft,
          'gray-background': !row.draft && !row.isActive
        }"
      ></tr>
      <tr [hidden]="loaderSpinner" mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
    </table>
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalCount"
      (page)="paginate($event)"
    ></mat-paginator>
  </div>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container *hasPermission="'aw.sl.u'">
      <button
        mat-menu-item
        (click)="editSubLedgerAccount(element)"
        *ngIf="!element.draft"
        id="{{ entityName }}-table-results-action-menu-edit-button"
      >
        <mat-icon>edit</mat-icon> {{ 'edit' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'aw.sl.u'">
      <button
        mat-menu-item
        (click)="setToInactive(element)"
        *ngIf="!element.draft"
        [disabled]="!isAccountActive(element.isActive)"
        id="{{ entityName }}-table-results-action-menu-set-to-inactive-button"
      >
        <mat-icon>flaky</mat-icon>
        {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SET-TO-INACTIVE' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'aw.sl.u'">
      <button
        mat-menu-item
        (click)="setToActive(element)"
        *ngIf="!element.draft"
        [disabled]="isAccountActive(element.isActive)"
        id="{{ entityName }}-table-results-action-menu-set-to-active-button"
      >
        <mat-icon>flaky</mat-icon>
        {{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SET-TO-ACTIVE' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'aw.sl.u'">
      <button
        mat-menu-item
        (click)="editDraft(element)"
        *ngIf="element.draft"
        id="{{ entityName }}-table-results-action-menu-edit-draft-button"
      >
        <img
          class="icon-menu"
          src="assets/images/ic_edit.svg"
          alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
        />
        <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
      </button>
    </ng-container>
    <ng-container *hasPermission="'aw.sl.u'">
      <button
        mat-menu-item
        (click)="deleteDraft(element)"
        *ngIf="element.draft"
        id="{{ entityName }}-table-results-action-menu-delete-draft-button"
      >
        <span class="material-icons icon-menu"> delete_outline </span>
        <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
