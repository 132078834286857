<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchBusinessTransactionData()"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchBusinessTransactionData()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
    <div class="filter-input button-container">
      <button
        *hasPermission="'sc.btc.c'"
        mat-button
        class="custom-button"
        id="{{ entityName }}-create-button"
        (click)="create()"
      >
        {{ 'SYSTEM-CONFIGURATION.BTC.CREATE' | translate }}
      </button>
    </div>
  </div>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <table aria-hidden="true" mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table">
      <ng-container matColumnDef="btc">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-btc"
        >
          {{ 'SYSTEM-CONFIGURATION.BTC.BTC' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-btc-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.code }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-name"
        >
          {{ 'SYSTEM-CONFIGURATION.BTC.NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'draft-row': row.draft }"></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container>
      <button
        *hasPermission="'sc.btc.u'"
        mat-menu-item
        (click)="editBtc(element)"
        id="{{ entityName }}-table-results-action-menu-edit-button"
      >
        <mat-icon>edit</mat-icon>
        <span *ngIf="!element.draft">{{ 'edit' | translate }}</span>
        <span *ngIf="element.draft">{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}</span>
      </button>
      <button
        *hasPermission="'sc.btc.m.d'"
        mat-menu-item
        (click)="deleteBtc(element)"
        id="{{ entityName }}-table-results-action-menu-delete-button"
      >
        <mat-icon>delete</mat-icon>
        <span *ngIf="!element.draft">{{ 'delete' | translate }}</span>
        <span *ngIf="element.draft">{{ 'GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
