import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskEventType } from 'src/app/shared/models/task-event-type';
import { QuarterlyBillingSimulationParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/simulation-parameters';
import { Quarter } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/quarter';
import { AccountStatementParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/account-statment-parameters';
import { BillingSelectionParameters } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/account-statement-billing-selection';

@Injectable({
  providedIn: 'root',
})
export class LiquidityPlanService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private readonly searchApiServiceUrl;
  listOfFilteredAssetChanges;
  mapOfColumnFilters = [];

  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    liquidityPlanGuid: null,
  };

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.taskServiceUrl = environment.settings.taskService;
    this.searchApiServiceUrl = environment.settings.searchAPIUrl;
  }

  private editModeState = new BehaviorSubject<any>(null);
  currentEditMode = this.editModeState.asObservable();

  private addNewAssetChange = new BehaviorSubject<any>(null);
  newAssetChange = this.addNewAssetChange.asObservable();

  private appliedFilter = new BehaviorSubject<any>(null);
  currentAppliedFilters = this.appliedFilter.asObservable();

  updateEditModeState(editModeState: any) {
    this.editModeState.next(editModeState);
  }

  addNewAssetChangeState(addNewAssetChange: any) {
    this.addNewAssetChange.next(addNewAssetChange);
  }

  updateAppliedFilters(AppliedFilter: any) {
    this.appliedFilter.next(AppliedFilter);
  }

  retrieveLiquidityPlans(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'liquidity-plan',
      this.buildHeaders()
    );
  }

  retrieveApprovedBy(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'asset-change/approved-by',
      this.buildHeaders()
    );
  }

  retrieveCreatedBy(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'asset-change/created-by',
      this.buildHeaders()
    );
  }

  searchAssetChange(request): Observable<any> {
    const url = this.searchApiServiceUrl + 'asset-change';
    return this.http.get<any[]>(url, this.getConfigs(request));
  }

  createAssetChange(assetChanges) {
    const json = { assetChanges, taskId: null, comment: '' };
    return this.http.post(
      this.serviceUrl + 'asset-change',
      json,
      this.buildHeaders()
    );
  }

  updateAssetChange(assetChange, cancellationComment, guid) {
    const json = {
      assetChanges: [assetChange],
      guid,
      taskId: null,
      comment: '',
      cancellationComment,
    };
    return this.http.put(
      this.serviceUrl + 'asset-change',
      json,
      this.buildHeaders()
    );
  }

  updateAssetChangeDraft(assetChange, param, guid, taskId) {
    const json = {
      assetChanges: assetChange,
      guid,
      taskId,
      comment: '',
      param,
    };
    return this.http.put(
      this.serviceUrl + 'asset-change/draft',
      json,
      this.buildHeaders()
    );
  }

  addActualValue(guid, amount, comment) {
    const json = { guid, amount, comment };
    return this.http.post(
      this.serviceUrl + 'asset-change/add-actual',
      json,
      this.buildHeaders()
    );
  }

  cancelAssetChange(comment, guids) {
    const json = { comment, guids };
    return this.http.post(
      this.serviceUrl + 'asset-change/cancel',
      json,
      this.buildHeaders()
    );
  }

  listAssetChanges(id): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'asset-change/' + id,
      this.buildHeaders()
    );
  }

  getAssetChangeByGuid(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + `asset-change/findByGuid/${guid}`,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  retrieveBillingsForSimulationAndPayout(
    target: 'PIG' | 'POULTRY'
  ): Observable<Quarter[]> {
    return this.http.get<Quarter[]>(
      this.serviceUrl + 'billing?target=' + target,
      this.buildHeaders()
    );
  }

  retrieveBillingsForSimulation(
    target: 'PIG' | 'POULTRY'
  ): Observable<Quarter[]> {
    return this.http.get<Quarter[]>(
      this.serviceUrl + 'billing/quarter/for-simulation?target=' + target,
      this.buildHeaders()
    );
  }

  retrieveBillingsForPayout(target: 'PIG' | 'POULTRY'): Observable<Quarter[]> {
    return this.http.get<Quarter[]>(
      this.serviceUrl + 'billing/quarter/for-payout?target=' + target,
      this.buildHeaders()
    );
  }

  startSimulation(params: QuarterlyBillingSimulationParameters) {
    return this.http.post(
      this.serviceUrl + 'billing/quarter/simulate',
      params,
      this.buildHeaders()
    );
  }

  startPayout(billingIds: string[]) {
    return this.http.post(this.serviceUrl + 'billing/quarter/payouts', null, {
      headers: this.buildHeaders().headers,
      params: {
        billingId: billingIds,
      },
    });
  }

  createOperatorDraft(guid) {
    const json = { companyGuid: guid };
    return this.http.post(
      this.serviceUrl + 'operator/create-draft',
      json,
      this.buildHeaders()
    );
  }

  getOperator(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'operator/get-operator',
      this.buildHeaders()
    );
  }

  checkIfExistsOperator(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'operator/is-operator-set',
      this.buildHeaders()
    );
  }

  retrieveValidQuartersAccountStatement(): Observable<
    BillingSelectionParameters[]
  > {
    return this.http.get<BillingSelectionParameters[]>(
      this.serviceUrl + 'billing/quarter/accountStatement',
      this.buildHeaders()
    );
  }

  startAccountStatement(params: AccountStatementParameters) {
    return this.http.post(
      this.serviceUrl + 'billing/quarter/accountStatement',
      params,
      this.buildHeaders()
    );
  }

  createBlockingIndicatorForLivestockProducer(
    livestockProducerId,
    sBlockStatus,
    accountNumber,
    comment,
    locationNumber,
    typeOfProduction
  ) {
    return this.http.post(
      this.serviceUrl + 'livestock-producer-s-block-management',
      {
        livestockProducerId,
        sBlockStatus,
        accountNumber,
        comment,
        locationNumber,
        typeOfProduction,
      },
      this.buildHeaders()
    );
  }

  getActiveSBlockByLivestockProducerId(livestockProducerId) {
    return this.http.get(
      this.serviceUrl + 'blockingIndicator/' + livestockProducerId,
      this.buildHeaders()
    );
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const allConfigs = customConfigObj
      ? { ...this.configObj, ...customConfigObj }
      : this.configObj;
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(allConfigs).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        console.log(allConfigs[key]);
        config['params'] = config['params'].append(
          key,
          JSON.stringify(allConfigs[key])
        );
      } else {
        config['params'] = config['params'].append(key, allConfigs[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);
    return config;
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  getAssetChangeDistinctValues(filterOptions): Observable<HttpEvent<any>> {
    const url = this.searchApiServiceUrl + 'asset-change/distinct-values';
    return this.http.get<any[]>(url, this.getConfigs(filterOptions));
  }

  isTheReleaseUnusedBudgetProcessRunning(): Observable<boolean> {
    return this.http.get(
      this.serviceUrl + 'unused-budgets/release/status',
      this.buildHeaders()
    ).pipe(map(response => response['isProcessRunning']));
  }

  startCalculationForReleaseUnusedBudgets() {
    return this.http.post(
      this.serviceUrl + 'unused-budgets/release',
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
