<div class="dialog-content">
  <div class="background-dark" mat-dialog-content>
    <h1 mat-dialog-title>
      {{ 'USER-MANAGEMENT.REALM-SELECTION' | translate }}
    </h1>
  </div>
  <div class="buttons">
    <button
      [mat-dialog-close]="'external'"
      class="custom-button"
      id="realm-external"
      mat-button
      style="margin-right: 10px"
    >
      {{ 'USER-MANAGEMENT.EXTERNAL-REALM' | translate }}
    </button>
    <button
      [mat-dialog-close]="'internal'"
      class="custom-button"
      id="realm-internal"
      mat-button
      style="margin-right: 0"
    >
      {{ 'USER-MANAGEMENT.INTERNAL-REALM' | translate }}
    </button>
  </div>

  <button mat-button></button>
</div>
