import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SortService } from '../../services/sort/sort-service';
import { ClientService } from '../../services/client/client.service';
import { ClientGroupService } from '../../services/client-group/client-group.service';

@Component({
  selector: 'app-split-central',
  templateUrl: './split-central.component.html',
  styleUrls: ['./split-central.component.less'],
})
export class SplitScreenCentralComponent {
  @Input() splitData;
  @Input() originalData;
  @Input() companyName;
  currentRow;

  constructor(
    private sortService: SortService,
    private router: Router,
    private clientService: ClientService,
    private clientGroupService: ClientGroupService
  ) {
    this.sortService.dataListInSplitScreen.subscribe(
      (dataList) => (this.splitData = dataList)
    );
  }

  getValueForRow(item) {
    return {
      id: item.id,
      name: item.name,
      visibleId: item.visibleId,
    };
  }

  goToRowDetail(row) {
    this.currentRow = row;
    if (row.type === 'client') {
      this.getClient(row.dbId);
    }
    if (row.type === 'clientGroup') {
      this.getClientGroup(row.id);
    }
  }

  getClient(id) {
    this.clientService.retrieveApprovedExtended(id).subscribe((client) => {
      client.centralMasterDataRecord =
        client.companyName +
        ', ' +
        client.address.street +
        ', ' +
        client.address.number +
        ', ' +
        client.address.zipCode +
        ', ' +
        client.address.city;
      client.platform = client.platformName;

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/masterdata/client/create'], {
        state: {
          client,
          splitScreen: true,
          splitData: this.splitData,
          originalData: this.originalData,
          companyName: this.companyName,
        },
      });
    });
  }

  getClientGroup(id) {
    this.clientGroupService.retrieveApproved(id).subscribe((clientGroup) => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/masterdata/clientGroup/create'], {
        state: {
          clientGroup,
          splitScreen: true,
          splitData: this.splitData,
          originalData: this.originalData,
          companyName: this.companyName,
        },
      });
    });
  }
}
