import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenItemListComponent } from './open-item-list/open-item-list.component';
import { OpenItemCreateComponent } from './open-item-create/open-item-create.component';
import { OpenItemManagementComponent } from './open-item-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { OpenItemHeaderComponent } from './open-item-header/open-item-header.component';
import { AddDeductionModalComponent } from 'src/app/shared/modals/add-deduction-modal/add-deduction-modal.component';
import { AddDunningModalComponent } from 'src/app/shared/modals/add-dunning-modal/add-dunning-modal.component';

@NgModule({
    declarations: [
        OpenItemListComponent,
        OpenItemCreateComponent,
        OpenItemManagementComponent,
        OpenItemHeaderComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatPaginatorModule,
        MatButtonModule,
        MatGridListModule,
        MatSlideToggleModule,
        TranslateModule,
        MatSnackBarModule,
        MatMenuModule,
        MatCheckboxModule,
        MatIconModule,
        SharedModule,
        BackButtonModule,
        MatInputModule,
        MatSelectModule,
        NgSelectModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatListModule,
        MatSidenavModule,
        MatAutocompleteModule,
    ],
    exports: [MatExpansionModule, MatListModule, MatSidenavModule],
    providers: [OpenItemService, MatListModule, MatSidenavModule]
})
export class OpenItemManagementModule {}
