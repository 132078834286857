import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { IdAndTask } from '../../models/id-task';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { AzureFile } from '../../models/azure-file';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.clientService;
  }

  private sharedTableDocuments = new BehaviorSubject<any>(null);
  currentSharedTableDocuments = this.sharedTableDocuments.asObservable();

  private searchString = '';
  private serviceUrl: string;
  mapOfColumnFilters = [];

  clientSelected;

  pageSize;
  sortDirection;
  sortOrder;

  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  updateSharedTableDocuments(sharedTableDocuments: any) {
    this.sharedTableDocuments.next(sharedTableDocuments);
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  create(client): Observable<IdAndTask> {
    return this.http.post<IdAndTask>(
      this.serviceUrl + 'clients',
      client,
      this.buildHeaders()
    );
  }

  uploadDocument(formData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'clients/upload',
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  deleteFile(file: AzureFile) {
    return this.http.post(
      this.serviceUrl + 'clients/delete',
      file,
      this.buildHeaders()
    );
  }

  editDocuments(taskId: any, files: any) {
    return this.http.post<IdAndTask>(
      this.serviceUrl + 'clients/drafts/' + taskId + '/documentcheck',
      files,
      this.buildHeaders()
    );
  }

  getDocuments(clientId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/list-files?clientId=' + clientId,
      this.buildHeaders()
    );
  }

  update(client): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'clients',
      client,
      this.buildHeaders()
    );
  }

  updateDraft(client): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'clients/drafts',
      client,
      this.buildHeaders()
    );
  }

  retrieveByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/client',
      this.buildHeaders()
    );
  }

  retrieveImportedClientByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/imported-client',
      this.buildHeaders()
    );
  }

  retrieveApproved(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/' + id,
      this.buildHeaders()
    );
  }

  retrieveApprovedExtended(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/' + id + '/extended',
      this.buildHeaders()
    );
  }

  retrieveApprovedExtendedByGuid(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + `clients/retrieveByGuid/${guid}/extended`,
      this.buildHeaders()
    );
  }

  approveTask(id: number, comment: string): Observable<void> {
    const body = { comment };
    return this.http.post<void>(
      this.serviceUrl + 'clients/drafts/' + id + '/approval',
      body,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string) {
    const body = { comment };
    return this.http.post(
      this.serviceUrl + 'clients/drafts/' + id + '/rejection',
      body,
      this.buildHeaders()
    );
  }

  listApproved(
    clientId: number | null = null,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'clients/filter';
    if (clientId !== null) {
      url += '?clientId=' + clientId;
    }
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  retrieveByPlatform(platformId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/platform/' + platformId,
      this.buildHeaders()
    );
  }

  retrieveByClientGroup(clientGroupId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/clientgroup/' + clientGroupId,
      this.buildHeaders()
    );
  }

  finishPrescoring(id: number, comment: string, canProceed: boolean) {
    const body = {
      comment,
      canProceed,
    };
    return this.http.post(
      this.serviceUrl + 'clients/drafts/' + id + '/prescoring',
      body,
      this.buildHeaders()
    );
  }

  retrieveByObjectionCode(objectionCodeId: any): Observable<any> {
    return this.http.get(this.serviceUrl + 'clients/objection-code', {
      params: {
        objectionCodeId,
      },
      headers: this.buildHeaders().headers,
    });
  }

  retrieveByDebtor(debtorId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'clients/debtor/' + debtorId,
      this.buildHeaders()
    );
  }

  getFilesByTask(taskId: any) {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/clientFiles',
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  getTemplates(name: string, language: string): Observable<any> {
    return this.http.get(this.serviceUrl + 'clients/list-templates', {
      params: {
        tciType: name,
        language,
      },
      headers: this.buildHeaders().headers,
    });
  }

  getTemplatesByType(
    name: string,
    onboarding,
    language: string
  ): Observable<any> {
    return this.http.get(this.serviceUrl + 'clients/list-templates-by-type', {
      params: {
        tciType: name,
        onboarding,
        language,
      },
      headers: this.buildHeaders().headers,
    });
  }

  getFile(fileName: string, clientId: number) {
    return this.http.get(
      this.serviceUrl + 'clients/getFile/' + clientId + '?fileName=' + fileName,
      {
        responseType: 'blob',
      }
    );
  }

  getFileWithPath(fileName: string, path: string) {
    return this.http.get(
      this.serviceUrl +
        'clients/getFileFromPath' +
        '?fileName=' +
        fileName +
        '&path=' +
        path,
      {
        responseType: 'blob',
      }
    );
  }

  updateDocument(element: any) {
    return this.http.put(
      this.serviceUrl + 'clients/updateFile',
      element,
      this.buildHeaders()
    );
  }

  filter(sortedBy, sortDirection, page, pageSize): Observable<any> {
    let url = this.serviceUrl + 'clients/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'clients/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }
}
