import { Component, Input, OnInit } from '@angular/core';
import { pageStatuses } from 'src/app/in-memory-data/user-management/enums/status';

@Component({
  selector: 'app-role-task-details',
  templateUrl: './role-task-details.component.html',
  styleUrls: ['./role-task-details.component.less'],
})
export class RoleTaskDetailsComponent implements OnInit {
  @Input() roleTask;
  @Input() roleApproved;

  newRoles;
  sameRoles;
  deletedRoles;

  status = pageStatuses.CREATE;

  ngOnInit(): void {
    if (this.roleApproved) {
      this.status = pageStatuses.UPDATE;
    }
    this.formatRoles();
  }

  formatRoles() {
    if (this.status === pageStatuses.UPDATE) {
      this.sameRoles = this.getSameRoles();
      this.deletedRoles = this.getDeletedRoles();
    }

    this.newRoles = this.getNewRoles();
  }

  getSameRoles() {
    const result = this.roleTask.permissions.filter((permissions) =>
      this.roleApproved.permissions.find(
        (permission) => permission === permissions
      )
    );
    return this.formatArrayToString(result);
  }

  getDeletedRoles() {
    const result = this.roleApproved.permissions.filter(
      (permissions) =>
        !this.roleTask.permissions.find(
          (permission) => permission === permissions
        )
    );
    return this.formatArrayToString(result);
  }

  getNewRoles() {
    if (this.status === pageStatuses.CREATE) {
      return this.formatArrayToString(this.roleTask.permissions);
    } else {
      const result = this.roleTask.permissions.filter(
        (permissions) =>
          !this.roleApproved.permissions.find(
            (permission) => permission === permissions
          )
      );
      return this.formatArrayToString(result);
    }
  }

  formatArrayToString(array) {
    let result = '';
    array.forEach((item, index) => {
      if (index === array.length - 1) {
        result += item;
      } else {
        result += item + ', ';
      }
    });

    return result;
  }
}
