<div class="input-wrapper form-container">
  <form class="form" [formGroup]="frmRole" autocomplete="off">
    <app-mat-input
      [name]="'USER-MANAGEMENT.ROLE-NAME'"
      [placeholder]="'USER-MANAGEMENT.ROLE-NAME'"
      [label]="'USER-MANAGEMENT.ROLE-NAME'"
      [entityName]="entityName"
      [form]="frmRole"
      [controlObject]="frmRole.controls.roleName"
      [controlName]="'roleName'"
      [approvedValue]="approvedValue ? approvedValue.roleName : null"
      [taskValue]="roleTask ? roleTask.roleName : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-select
      [name]="'USER-MANAGEMENT.ROLE-TYPE'"
      [placeholder]="'USER-MANAGEMENT.ROLE-TYPE'"
      [label]="'USER-MANAGEMENT.ROLE-TYPE'"
      [entityName]="entityName"
      [form]="frmRole"
      [controlObject]="frmRole.controls.roleType"
      [controlName]="'roleType'"
      [approvedValue]="approvedValue ? approvedValue.roleType : null"
      [taskValue]="roleTask ? roleTask.roleType : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="roleTypes"
      [optionValue]="'name'"
      [selectedOption]="null"
      [optionTranslationText]="''"
      [translationOption]="'translateName'"
    >
    </app-mat-select>
    <app-mat-textarea
      [name]="'USER-MANAGEMENT.DESCRIPTION'"
      [placeholder]="'USER-MANAGEMENT.DESCRIPTION'"
      [label]="'USER-MANAGEMENT.DESCRIPTION'"
      [entityName]="entityName"
      [form]="frmRole"
      [controlObject]="frmRole.controls.description"
      [controlName]="'description'"
      [approvedValue]="approvedValue ? approvedValue.description : null"
      [taskValue]="roleTask ? roleTask.description : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="isTask"
    >
    </app-mat-textarea>
    <span>
      <app-mat-input-date
        [name]="'USER-MANAGEMENT.CREATED-ON'"
        [placeholder]="'USER-MANAGEMENT.CREATED-ON'"
        [label]="'USER-MANAGEMENT.CREATED-ON'"
        [entityName]="entityName"
        [form]="frmRole"
        [controlObject]="frmRole.controls.createdOn"
        [controlName]="'createdOn'"
        [approvedValue]="approvedValue ? approvedValue.createdOn : null"
        [taskValue]="roleTask ? roleTask.createdOn : null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      ></app-mat-input-date>
      <app-mat-input
        [name]="'USER-MANAGEMENT.CREATED-BY'"
        [placeholder]="'USER-MANAGEMENT.CREATED-BY'"
        [label]="'USER-MANAGEMENT.CREATED-BY'"
        [entityName]="entityName"
        [form]="frmRole"
        [controlObject]="frmRole.controls.createdBy"
        [controlName]="'createdBy'"
        [approvedValue]="approvedValue ? approvedValue.createdBy : null"
        [taskValue]="roleTask ? roleTask.createdBy : null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      ></app-mat-input>
      <app-mat-input-date
        [name]="'USER-MANAGEMENT.UPDATED-ON'"
        [placeholder]="'USER-MANAGEMENT.UPDATED-ON'"
        [label]="'USER-MANAGEMENT.UPDATED-ON'"
        [entityName]="entityName"
        [form]="frmRole"
        [controlObject]="frmRole.controls.updatedOn"
        [controlName]="'updatedOn'"
        [approvedValue]="approvedValue ? approvedValue.updatedOn : null"
        [taskValue]="roleTask ? roleTask.updatedOn : null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      ></app-mat-input-date>
      <app-mat-input
        [name]="'USER-MANAGEMENT.UPDATED-BY'"
        [placeholder]="'USER-MANAGEMENT.UPDATED-BY'"
        [label]="'USER-MANAGEMENT.UPDATED-BY'"
        [entityName]="entityName"
        [form]="frmRole"
        [controlObject]="frmRole.controls.updatedBy"
        [controlName]="'updatedBy'"
        [approvedValue]="approvedValue ? approvedValue.updatedBy : null"
        [taskValue]="roleTask ? roleTask.updatedBy : null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      ></app-mat-input>
    </span>
    <div *ngIf="!isTask">
      <div class="all-permissions">
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchStringAll"
              (keyup)="searchInputChangedPermissions()"
              type="text"
              removeHtmlTagsForNgModel
            />
          </mat-form-field>
          <div class="entries">
            <span *ngIf="searchRunning">
              <mat-spinner [diameter]="20"></mat-spinner>
            </span>
            <mat-selection-list
              *ngIf="!searchRunning"
              [(ngModel)]="selectedPermissions"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="state.viewMode"
              #selectedGroups
            >
              <mat-list-option
                [disabled]="isSelected(permission)"
                [selected]="isSelected(permission)"
                [value]="permission.permissionId"
                *ngFor="let permission of permissions"
              >
                {{ permission.permissionId }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div class="buttons">
          <button mat-button class="add-button" (click)="addToUser()" [disabled]="state.viewMode">
            {{ 'GENERAL.ADD' | translate }}
          </button>
          <button mat-button class="add-button" (click)="removeFromUser()" [disabled]="state.viewMode">
            {{ 'GENERAL.REMOVE' | translate }}
          </button>
        </div>
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchStringActive"
              (keyup)="searchInputChangedActivePermissions()"
              type="text"
              removeHtmlTagsForNgModel
            />
          </mat-form-field>
          <div class="entries">
            <mat-selection-list
              #selectedGroups
              [disabled]="state.viewMode"
              (selectionChange)="onGroupsChange(selectedGroups.selectedOptions.selected)"
            >
              <mat-list-option *ngFor="let permission of renderedPermissionList" [value]="permission">
                {{ permission }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTask && finishLoaded">
      <app-role-task-details [roleTask]="roleTask" [roleApproved]="approvedValue"></app-role-task-details>
    </div>
  </form>
</div>

<div class="create-container">
  <button
    mat-button
    class="custom-button create"
    [disabled]="frmRole.invalid"
    (click)="createRole()"
    *ngIf="!isTask && !state.editMode"
  >
    {{ 'GENERAL.CREATE' | translate }}
  </button>
</div>
