import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-container',
  templateUrl: './info-container.component.html',
  styleUrls: ['./info-container.component.less'],
})
export class InfoContainerComponent {
  @Input() entity;
}
