import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class AccountStatementConfigurationService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.openItemService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  getAccountStatements(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'accountStatementConfiguration',
      this.buildHeaders()
    );
  }

  saveAccountStatement(json): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'accountStatementConfiguration',
      json,
      this.buildHeaders()
    );
  }

  updateAccountStatement(json): Observable<any> {
    return this.http.put(
      this.serviceUrl + 'accountStatementConfiguration',
      json,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid?: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  deleteAccountStatementConfiguration(request) {
    return this.http.delete(
      this.serviceUrl + 'accountStatementConfiguration/' + request.id,
      {
        params: {
          comment: request.comment,
        },
        headers: this.buildHeaders().headers,
      }
    );
  }

  retrieveApproved(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'accountStatementConfiguration/' + id,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
