<div class="kpi-container">
  <div>
    <h5 class="title-info" id="accounting-kpi-overview-entity-title">
      {{ entity?.name }}
    </h5>
    <div class="address">
      <div class="grid-content">
        <div
          id="accounting-kpi-overview-entity-address"
          name="address"
          class="blue-label"
          style="white-space: pre-line"
        >
          <div class="address-container">
            <p>{{ entity?.address?.street + ' ' + entity?.address?.number }}</p>
            <p>{{ entity?.address?.zipCode + ' ' + entity?.address?.city }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="type-info">
    <p>
      <span>{{ 'ACCOUNTING.PARTNER-TABLE.FILTER-ELEMENTS.' + entity?.kpis?.type | translate }}</span>
    </p>
    <p>
      {{ 'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER' | translate }} <span>{{ entity?.accountNumber }}</span>
    </p>
  </div>
</div>
