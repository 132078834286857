<div class="filters" [formGroup]="filter">
  <div class="filter-input" style="padding-left: 0">
    <mat-form-field>
      <mat-label>{{ 'COMPANY.SEARCH' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'COMPANY.SEARCH' | translate }}"
        name="name"
        id="{{ entityName }}-search-input-name"
        formControlName="searchString"
        [(ngModel)]="searchInput"
        (keydown.enter)="searchCompany()"
        (keyup)="searchCompany()"
        autocomplete="off"
        [removeHtmlTags]="filter.controls.searchString"
        type="text"
      />
    </mat-form-field>
  </div>
  <div class="filter-input">
    <mat-form-field id="{{ entityName }}-search-input-country">
      <mat-label>{{ 'ADDRESS.COUNTRY' | translate }}</mat-label>
      <mat-select
        formControlName="country"
        placeholder="{{ 'ADDRESS.COUNTRY' | translate }}"
        name="country"
        [(ngModel)]="searchCountryTerm"
        (selectionChange)="setCountryFilter()"
      >
        <mat-option *ngFor="let c of countries" [value]="c">
          {{ (currentLang === 'de' ? c?.translations?.deu?.common : c?.name?.common) | translate }}
        </mat-option>
      </mat-select>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="searchCountryTerm"
        id="{{ entityName }}-search-input-role-clear"
        (click)="searchCountryTerm = undefined; resetCountry(); $event.stopPropagation()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-button class="custom-button" (click)="searchCompany()" id="{{ entityName }}-search-button">
      <span class="material-icons"> search </span>
    </button>
  </div>
  <div class="filter-input button-container">
    <button
      *hasPermission="'md.c'"
      mat-button
      class="custom-button"
      (click)="createNew()"
      id="{{ entityName }}-create-button"
    >
      {{ 'COMPANY.CREATE' | translate }}
    </button>
  </div>
</div>

<mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<div class="company-list">
  <h5>{{ 'SEARCH-RESULT' | translate }}</h5>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    id="{{ entityName }}-search-results-table"
    class="general-table-alignment"
    aria-hidden="true"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th number-row-header-sortable id-column"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-id"
      >
        ID
        <app-table-menu-sort-filter
          choice="visible_id"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [sortTable]="sortTable"
        >
        </app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-id-{{ paginator.pageIndex + 1 }}-{{ i }}"
        class="number-row id-column"
      >
        {{ element.visibleId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-address"
      >
        {{ 'COMPANY.ADDRESS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-address-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <div class="address-container">
          <div>{{ element.name }}</div>
          <div>{{ element.address.street + ' ' + element.address.number }}</div>
          <div>{{ element.address.zipCode + ' ' + element.address.city }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-country"
      >
        {{ 'ADDRESS.COUNTRY' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-country-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ getCountryName(element.address.country) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="isInternalRecord">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-isInternalRecord"
      >
        {{ 'COMPANY.INTERNAL-RECORD' | translate }}
        <app-table-menu-sort-filter
          choice="is_created_externally"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-isInternalRecord-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <mat-slide-toggle [checked]="!element.createdExternally" [disabled]="true"> </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="countryCode">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-countryCode"
      >
        {{ 'ADDRESS.COUNTRY-CODE' | translate }}
        <app-table-menu-sort-filter
          choice="country"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        >
        </app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-countryCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.address.country }}
      </td>
    </ng-container>

    <ng-container matColumnDef="language">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-language"
      >
        {{ 'COMPANY.LANGUAGE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-language-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ 'LANGUAGES.' + element.language | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="timezone">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-timezone"
      >
        {{ 'ADDRESS.TIMEZONE' | translate }}
        <app-table-menu-sort-filter
          choice="timezone"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-timezone-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.address.timezone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="test">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-timezone"
      >
        {{ 'ADDRESS.TIMEZONE' | translate }}
        <app-table-menu-sort-filter
          choice="timezone"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        id="{{ entityName }}-table-results-timezone-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.address.timezone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="general-loader-table" *ngIf="loaderSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        (click)="$event.stopPropagation()"
        class="three-dot-actions"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="action"
          [matMenuTriggerData]="{ element: element }"
          id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="content">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="element == expandedCompany ? 'expanded' : 'collapsed'">
          <div class="element-description">
            <ul>
              <li *ngFor="let platform of platforms">Platform {{ platform.id }}: {{ platform.name }}</li>
            </ul>
            <ul>
              <li *ngFor="let debtor of debtors">{{ 'DEBTOR.DEBTOR' | translate }} {{ debtor.visibleId }}</li>
            </ul>
            <ul>
              <li *ngFor="let client of clients">{{ 'MASTER-DATA.CLIENT' | translate }} {{ client.id }}</li>
            </ul>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [hidden]="loaderSpinner"
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedCompany === element"
      [ngClass]="{ 'draft-row': element.draft }"
      debounceClick (debounceClick)="getData(element)" [debounceTime]="500"
    ></tr>
    <tr [hidden]="loaderSpinner" mat-row *matRowDef="let row; columns: ['content']" class="detail-row"></tr>
    <tr
      [hidden]="!loaderSpinner"
      mat-footer-row
      *matFooterRowDef="['loader']"
      class="footer-spinner height-footer"
    ></tr>
  </table>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
  <ng-container>
    <mat-drawer-container>
      <mat-drawer #drawer class="sidenav" mode="over" position="end">
        <button
          type="button"
          id="{{ entityName }}-split-central-close-button"
          mat-button
          (click)="drawer.close()"
          class="close-split"
        >
          <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
        </button>
        <span *ngIf="!animalWelfareBU">
          <app-split-central [splitData]="splitScreenList" [originalData]="splitScreenList" [companyName]="companyName">
          </app-split-central>
        </span>
        <span *ngIf="animalWelfareBU">
          <app-split-central-animal-welfare
            [splitData]="splitScreenList"
            [originalData]="splitScreenList"
            [companyName]="companyName"
          >
          </app-split-central-animal-welfare>
        </span>
      </mat-drawer>
    </mat-drawer-container>
  </ng-container>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <div *ngIf="!animalWelfareBU">
      <ng-container *hasPermission="'md.u'">
        <button
          mat-menu-item
          (click)="edit(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-edit-button"
        >
          <mat-icon>edit</mat-icon> {{ 'EDIT' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'c.c'">
        <button
          mat-menu-item
          (click)="createClient(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-create-client-button"
        >
          <mat-icon>add</mat-icon> {{ 'COMPANY.CREATE-CLIENT' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'cg.c'">
        <button
          mat-menu-item
          (click)="createClientGroup(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-create-client-group-button"
        >
          <mat-icon>add</mat-icon>
          {{ 'COMPANY.CREATE-CLIENT-GROUP' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="['md.u', 'md.c']">
        <button
          mat-menu-item
          (click)="edit(element)"
          *ngIf="element.draft"
          id="{{ entityName }}-table-results-action-menu-edit-draft-button"
        >
          <img
            class="icon-menu"
            src="assets/images/ic_edit.svg"
            alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
          />
          <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
        </button>
      </ng-container>
      <ng-container *hasPermission="['md.u', 'md.c']">
        <button
          mat-menu-item
          (click)="deleteDraft(element.draftGuid)"
          *ngIf="element.draft"
          id="{{ entityName }}-table-results-action-menu-delete-draft-button"
        >
          <span class="material-icons icon-menu"> delete_outline </span>
          <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
        </button>
      </ng-container>
    </div>
    <div *ngIf="animalWelfareBU">
      <ng-container *hasPermission="'aw.s.c'">
        <button
          mat-menu-item
          (click)="createFoodRetailTrader(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-create-food-retail-trader"
        >
          <mat-icon>add</mat-icon>
          {{ 'ANIMAL-WELFARE.SALES-ENTRY.CREATE-FOOD-RETAIL-ORDER' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'aw.s.c'">
        <button
          mat-menu-item
          (click)="createGastronomy(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-create-gastronomy"
        >
          <mat-icon>add</mat-icon>
          {{ 'ANIMAL-WELFARE.SALES-ENTRY.CREATE-GASTRONOMY' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'md.u'">
        <button
          mat-menu-item
          (click)="edit(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-aw-menu-edit-button"
        >
          <mat-icon>edit</mat-icon> {{ 'EDIT' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'aw.o.c'">
        <button
          mat-menu-item
          (click)="createLinkOperator(element)"
          *ngIf="!element.draft"
          id="{{ entityName }}-table-results-action-menu-create-link-operator-button"
          [disabled]="disableOperatorButton"
        >
          <mat-icon>create</mat-icon>
          {{ 'ANIMAL-WELFARE.OPERATOR.CREATE-LINK-OPERATOR' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="['md.u', 'md.c']">
        <button
          mat-menu-item
          (click)="edit(element)"
          *ngIf="element.draft"
          id="{{ entityName }}-table-results-action-aw-menu-edit-draft-button"
        >
          <img
            class="icon-menu"
            src="assets/images/ic_edit.svg"
            alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
          />
          <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
        </button>
      </ng-container>
      <ng-container *hasPermission="['md.u', 'md.c']">
        <button
          mat-menu-item
          (click)="deleteDraft(element.draftGuid)"
          *ngIf="element.draft"
          id="{{ entityName }}-table-results-action-aw-menu-delete-draft-button"
        >
          <span class="material-icons icon-menu"> delete_outline </span>
          <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>
