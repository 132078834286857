<section class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <ng-container *ngIf="isRequest; else isResponse">
      <span class="xml-text">{{ beautifiedRequestXMLString }}</span>
    </ng-container>
    <ng-template #isResponse>
      <ng-container *ngIf="awFile.response; else noResponse">
        <span class="xml-text">{{ beautifiedResponseXMLString }}</span>
      </ng-container>
      <ng-template #noResponse>
        <ng-container *ngIf="isProcessingFinished; else notCompleted">
          {{ awFile.returnCode }}
        </ng-container>
        <ng-template #notCompleted>
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.REQUEST-RESPONSE-MODAL.NO-RESPONSE-YET' | translate }}
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
  <div class="buttons">
    <button
      id="response-request-dialog-comment-close-button"
      mat-button
      class="custom-button-cancel"
      [mat-dialog-close]
    >
      {{ 'GENERAL.CLOSE' | translate }}
    </button>
  </div>
</section>
