import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-account-statement-processing',
  templateUrl: './account-statement-processing.component.html',
  styleUrls: ['./account-statement-processing.component.less'],
})
export class AccountStatementProcessingComponent implements OnInit {
  entityName = 'account-statement-processing-management';
  constructor(
    private router: Router,
    private kcService: KeycloakService,
    private route: ActivatedRoute
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.navigateToAccessible();
  }

  navigateToAccessible() {
    if (!this.route.firstChild.component) {
      const baseRoutes = this.route.routeConfig.children.filter((el) =>
        this.canNavigate(el)
      );
      if (baseRoutes.length === 0) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl(
          '/systemConfiguration/accountStatement/' + baseRoutes[0].path
        );
      }
    }
  }

  canNavigate(el: Route): unknown {
    if (el.path === '') {
      return false;
    }
    if ((el.path.match(/\//g) || []).length > 0) {
      return false;
    }

    if (el && el.data) {
      return el.data.roles.some((role) => this.kcService.isUserInRole(role));
    } else {
      return true;
    }
  }
}
