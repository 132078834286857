import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskParameters'
})
export class TaskParametersPipe implements PipeTransform {

  transform(value: string, taskParameters: any): string {
    if(taskParameters.param23){
      return value.replace('{{param23}}', taskParameters.param23);
    } else {
      return value;
    }
  }

}
