export const AWPaymentAllocationColumnsConf: any[] = [
  {
    name: 'creditDebitCode',
    index: 0,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT',
    locked: true,
  },
  {
    name: 'transactionCode',
    index: 1,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE',
    locked: true,
  },
  {
    name: 'documentationDate',
    index: 2,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-DATE',
    locked: true,
  },
  {
    name: 'amount',
    index: 3,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.AMOUNT',
    locked: true,
  },
  {
    name: 'vatPercent',
    index: 4,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-PERCENT',
    locked: true,
  },
  {
    name: 'vatAmount',
    index: 5,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-AMOUNT',
    locked: true,
  },

  {
    name: 'dueDate',
    index: 6,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUE-DATE',
    locked: true,
  },
  {
    name: 'financed',
    index: 7,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.FINANCED',
    locked: false,
  },
  {
    name: 'nonFinanced',
    index: 8,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.NON-FINANCED',
    locked: false,
  },

  {
    name: 'transactionCodeName',
    index: 9,
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE-NAME',
    locked: false,
  },
  {
    name: 'openAmount',
    index: 10,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT',
    locked: false,
  },

  {
    name: 'entryDate',
    index: 11,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ENTRY-DATE',
    locked: false,
  },

  {
    name: 'lastChangeDate',
    index: 12,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.LAST-CHANGE-DATE',
    locked: false,
  },
  {
    name: 'transactionId',
    index: 13,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID',
    locked: false,
  },

  {
    name: 'allocationNumber',
    index: 14,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-NUMBER',
    locked: false,
  },
  {
    name: 'currency',
    index: 15,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY',
    locked: false,
  },
  {
    name: 'currencyAmount',
    index: 16,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY-AMOUNT',
    locked: false,
  },
  {
    name: 'allocationTimestamp',
    index: 17,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-TIMESTAMP',
    locked: false,
  },
  {
    name: 'allocationAmount',
    index: 18,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-AMOUNT',
    locked: false,
  },
  {
    version: '2',
  },
];
