import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaskEventType } from '../../models/task-event-type';
import { SubledgerAccountValidationRequest } from './subledger-account-validation-request';
import { SortTableEvent } from '../../table-filters/table-menu-sort-filter/sort-table-event';

@Injectable({
  providedIn: 'root',
})
export class SubLedgerService {
  private sortField = 'active_account';
  private sortOrder = 'DESC';
  private searchText = '';
  private pageSize = '20';
  private pageIndex = '0';
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private mapOfColumnFilters = [];
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.subLedgerService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  setColumnFilters(choice: any, selection: any) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getAccountsList(): Observable<any> {
    return this.http.get(this.serviceUrl + 'v2/accounts', this.buildHeaders());
  }

  setAccountToActive(accountGuid, comment): Observable<any> {
    return this.http.put(
      this.serviceUrl + 'v2/accounts/' + accountGuid + '/set-active',
      comment.comment,
      this.buildHeaders()
    );
  }

  setAccountToInactive(accountGuid, comment): Observable<any> {
    return this.http.put(
      this.serviceUrl + 'v2/accounts/' + accountGuid + '/set-inactive',
      comment.comment,
      this.buildHeaders()
    );
  }

  create(account) {
    return this.http.post<any[]>(
      this.serviceUrl + 'v2/accounts',
      account,
      this.buildHeaders()
    );
  }

  update(account) {
    return this.http.put(
      this.serviceUrl + 'v2/accounts',
      account,
      this.buildHeaders()
    );
  }

  updateDraft(account, taskGuid) {
    return this.http.put(
      this.serviceUrl + 'v2/accounts/' + taskGuid,
      account,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid?: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  setSearchString(searchString) {
    this.searchText = searchString;
  }

  setPaginator(amountPerPage, pageNr) {
    this.pageSize = amountPerPage;
    this.pageIndex = pageNr;
  }

  filterAccounts(): Observable<any[]> {
    let config = {};
    config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('filterText', this.searchText)
      .append('sortDirection', this.sortOrder)
      .append('sortField', this.sortField)
      .append('pageIndex', this.pageIndex)
      .append('pageSize', this.pageSize);
    config['params'] = this.addColumnFilters(config['params']);
    return this.http.get<[]>(this.serviceUrl + 'v2/accounts/filter', config);
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  findDistinctValues(args: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'v2/accounts/distinctValues?fieldName=' + args,
      this.buildHeaders()
    );
  }

  findSubledgerAccountsNotAw(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'v2/accounts/findSubLedgerAccountsExceptAW',
      this.buildHeaders()
    );
  }

  findSalesTaxAccounts(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'v2/accounts/findSalesTaxAccounts',
      this.buildHeaders()
    );
  }

  findObjectAccounts(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'v2/accounts/object-accounts',
      this.buildHeaders()
    );
  }

  findByGuid(guid) {
    return this.http.get(
      this.serviceUrl + 'v2/accounts/' + guid,
      this.buildHeaders()
    );
  }

  findByCode(code) {
    return this.http.get(
      this.serviceUrl + `v2/accounts/by-account-number/${code}`,
      this.buildHeaders()
    );
  }

  validateAccountCodeProductsAndServiceModelCombinations(
    request: SubledgerAccountValidationRequest
  ) {
    let config = {};
    config = this.buildHeaders();
    config['params'] = {
      accountNumber: request.accountNumber.toString(),
      products: request.products,
      serviceModels: request.serviceModels,
    };

    return this.http.get(
      `${this.serviceUrl}v2/accounts/validate-account-code-products-and-service-model-combinations`,
      config
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }

  setSort(event: SortTableEvent) {
    this.sortOrder = event.direction;
    this.sortField = event.choice;
  }
}
