import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { Observable } from 'rxjs';
import { BusinessUnitPool } from '../../models/business-unit-pool';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitPoolService {
  private serviceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.buService;
  }

  getAllBusinessUnitPools(): Observable<BusinessUnitPool[]> {
    const url = this.serviceUrl + 'business-unit-pools';
    return this.http.get<BusinessUnitPool[]>(url, this.buildHeaders());
  }

  createBusinessUnitPool(request): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'business-unit-pools',
      request,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
