export const livestockProducerColumnsConf: any[] = [
  {
    name: 'account_number',
    index: 0,
    checked: true,
    translateName: 'id',
    locked: true,
  },
  {
    name: 'centralMasterDataRecord',
    index: 1,
    checked: true,
    translateName: 'MASTER-DATA.COMPANY',
    locked: true,
  },
  {
    name: 'name',
    index: 2,
    checked: true,
    translateName: 'name',
    locked: true,
  },
  {
    version: 2,
  },
];
