<app-back-button *ngIf="isTask"></app-back-button>

<span *ngIf="isTask && !editableTask">
  <button
    *hasPermission="'op.c'"
    id="{{ entityName }}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="makeEditable()"
  >
    <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</span>
<div class="info-container" id="{{ entityName }}-accounting-table-overview" [formGroup]="tabularForm">
  <div class="booking-row tabular-form-container">
    <div [formGroup]="tabularForm">
      <app-mat-input
        [name]="'OPEN-TRANSACTIONS.NUMBER-NEW-LINES'"
        [placeholder]="'OPEN-TRANSACTIONS.NUMBER-NEW-LINES'"
        [label]="'OPEN-TRANSACTIONS.NUMBER-NEW-LINES'"
        [entityName]="entityName"
        [form]="tabularForm"
        [controlObject]="tabularForm.controls.newLines"
        [controlName]="'newLines'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [type]="'number'"
      ></app-mat-input>
    </div>

    <button
      mat-button
      class="custom-button custom-button-add"
      id="{{ entityName }}-add-multi-lines"
      (click)="addLines()"
    >
      <mat-icon class="material-icons">add</mat-icon>
    </button>

    <button
      mat-button
      class="custom-button multi-edit-button"
      id="{{ entityName }}-multi-edit-transaction-modal"
      (click)="openMultiEditTransactionModal()"
      [disabled]="!sharedSelectedRows || sharedSelectedRows?.length == 0"
    >
      <span translate>OPEN-TRANSACTIONS.MULTI-EDIT</span>
    </button>
  </div>
  <div class="table-container">
    <app-default-editable-table
      *ngIf="entitiesLoaded === true"
      [entityName]="entityName"
      [displayedColumns]="displayedColumns"
      [columns]="columns"
      [tableKey]="CONFIGURATION_KEY"
      [getFormRow]="getFormRow"
      [getNewObject]="getNewObject"
      [tableFieldsConfiguration]="tableFieldsConfiguration"
      [form]="form"
      [entities]="entities"
      [mode]="mode"
      [listOfActionsButtons]="listOfActionsButtons"
      [sharedDataService]="sharedDataService"
    >
    </app-default-editable-table>
  </div>
</div>

<app-open-transaction-booking-functional-buttons *ngIf="isTask && editableTask">
</app-open-transaction-booking-functional-buttons>
