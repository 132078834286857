import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-animal-welfare-liquidity-plan',
  templateUrl: './liquidity-plan.component.html',
  styleUrls: ['./liquidity-plan.component.less'],
})
export class LiquidityPlanComponent implements OnInit, AfterContentChecked {
  entityName = 'animal-welfare-liquidity-plan';
  liquidityPlanSelectionForm: UntypedFormGroup;
  liquidityPlanApproved;
  liquidityPlanTask;
  editableTask: boolean;
  isTask = false;
  showLiquidityPlanAssetResult = false;
  editMode = false;
  state;
  liquidityPlans = [];
  selectedLiquidityPlan = new BehaviorSubject<any>(null);
  selectedLiquidityPlanInState;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private liquidityPlanService: LiquidityPlanService,
    private cdref: ChangeDetectorRef,
    private router: Router
  ) {
    this.liquidityPlanSelectionFormBuilder();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.state = window.history.state;
    this.liquidityPlanService.updateEditModeState(false);
    this.showLiquidityPlan();
    this.listenToEditMode();
    this.getLiquidityPlans();
    this.getSelectedLiquidityPlan();
  }

  getLiquidityPlans() {
    this.liquidityPlanService.retrieveLiquidityPlans().subscribe((data) => {
      this.liquidityPlans = data;
      if (this.liquidityPlans.length === 1) {
        const firstLiquidityPlan = this.liquidityPlans[0];
        this.liquidityPlanSelectionForm
          .get('liquidityPlan')
          .patchValue(firstLiquidityPlan);
      }
    });
  }

  getSelectedLiquidityPlan() {
    this.liquidityPlanSelectionForm
      .get('liquidityPlan')
      .valueChanges.subscribe((value) => {
        this.selectedLiquidityPlan.next(value);
        this.selectedLiquidityPlanInState = value;
      });
  }

  showLiquidityPlan() {
    this.liquidityPlanSelectionForm
      .get('liquidityPlan')
      .valueChanges.subscribe((data) => {
        if (data != null) {
          this.showLiquidityPlanAssetResult = true;
        } else {
          this.showLiquidityPlanAssetResult = false;
        }
      });
  }

  add() {
    this.router.navigateByUrl('/liquidityPlan/create/', {
      state: {
        showAssetChange: true,
        hideButtons: false,
        selectedLiquidityPlan: this.selectedLiquidityPlanInState,
      },
    });
  }

  listenToEditMode() {
    this.liquidityPlanService.currentEditMode.subscribe((value) => {
      if (value) {
        this.editMode = true;
      } else {
        this.editMode = false;
      }
    });
  }

  liquidityPlanSelectionFormBuilder() {
    this.liquidityPlanSelectionForm = this.formBuilder.group({
      liquidityPlan: [''],
    });
  }
}
