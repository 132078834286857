/**
 *
 * This service could be get a better name in the future.
 * For now it will be used to group repeated code into several different components.
 *
 */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GenericUtilsService {
  checkOneItemValue(object: any) {
    let result = false;
    Object.entries(object).forEach((key, value) => {
      if (!result) {
        if (
          key[0] === 'taskId' ||
          key[0] === 'id' ||
          key[0] === 'companyGuid' ||
          key[0] === 'companyName'
        ) {
          result = false;
        } else {
          switch (typeof key[1]) {
            case 'string':
              if (key[1] !== '') {
                result = true;
              } else {
                result = false;
              }
              break;
            case 'object':
              if (key[1] != null && !Array.isArray(key[1])) {
                result = this.checkOneItemValue(key[1]);
              } else if (Array.isArray(key[1])) {
                if (key[1].length > 0) {
                  result = true;
                }
              }
              break;
            case 'number':
              if (key[1].toString() != null && key[1].toString() !== '') {
                result = true;
              }
              break;
            case 'undefined':
              if (key[1] !== '') {
                result = true;
              } else {
                result = false;
              }
          }
        }
      }
    });
    return result;
  }
}
