<div class="unused-budget-container">
  <button
    *hasPermission="'aw.cb.c'"
    mat-button
    class="custom-button unused-budget-button"
    [disabled]="isDisabled"
    (click)="startCalculation()"
    id="{{ entityName }}-release-unused-budget-button"
  >
    {{ 'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.CALCULATE_BUTTON' | translate }}
  </button>
  <ng-container *ngIf="isRunning">
    <p class="non-editable-info-container">
      {{ 'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.MESSAGE.SUCCESS' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="unusedBudgetTask">
    <p class="non-editable-info-container">
      <span>{{ 'ANIMAL_WELFARE.RELEASE_UNUSED_BUDGET.TASK_ALREADY_CREATED' | translate }}</span>
      <a class="unused-budget-task-link" (click)="visitTask()">#{{ unusedBudgetTask?.id }}</a>
    </p>
  </ng-container>
</div>
