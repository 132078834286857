<div class="dialog-content">
  <div>
    <div class="filters" [formGroup]="filterForm">
      <div></div>
      <div class="row table-container-modal">
        <div class="spinner-container" *ngIf="!dataLoaded">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <app-default-table
          *ngIf="dataLoaded"
          [entityName]="entityName"
          [configurationKey]="configurationKey"
          [columns]="columns"
          [displayedColumns]="displayedColumns"
          [originalColumns]="columns"
          [originalDisplayedColumns]="displayedColumns"
          [tableDataSource]="transactionData"
          [threeDots]="false"
          [expanded]="false"
          [selectionAction]="null"
          [insideDialog]="true"
          [singleSelection]="true"
        ></app-default-table>
      </div>
    </div>
  </div>
</div>
