<div class="content-container">
  <div class="title-form">
    <h5 class="title-h5" id="{{ entityName }}-form-title">Email Receiving Mock</h5>
  </div>
  <div class="input-wrapper form-container">
    <form class="form" [formGroup]="emailForm" autocomplete="off">
      <app-mat-input
        [name]="'TCI Email ID'"
        [placeholder]="'TCI Email ID'"
        [label]="'TCI Email ID'"
        [entityName]="entityName"
        [form]="emailForm"
        [controlObject]="emailForm.controls.tciId"
        [controlName]="'tciId'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
      ></app-mat-input>
    </form>
  </div>

  <div class="button-container">
    <button mat-button class="custom-button" [disabled]="!emailForm.valid" (click)="receiveEmail()">
      <span> Send </span>
    </button>
  </div>
</div>
