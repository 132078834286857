import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaskEventType } from 'src/app/shared/models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class ParticipationPeriodService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  getLivestockProducerParticipationPeriod(id, today) {
    const config = this.buildHeaders();
    config['params'] = new HttpParams().append('date', today);

    return this.http.get(
      this.serviceUrl + 'participation-period/' + id,
      config
    );
  }

  setBudgetAllocate(request, id) {
    return this.http.put(
      this.serviceUrl + 'participation-period/' + id + '/allocate',
      request,
      this.buildHeaders()
    );
  }

  setBudgetReserve(request, id) {
    return this.http.put(
      this.serviceUrl + 'participation-period/' + id + '/reserve',
      request,
      this.buildHeaders()
    );
  }

  setTerminationDates(request, id) {
    return this.http.put(
      this.serviceUrl + 'participation-period/' + id + '/terminate',
      request,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
