<div class="dialog-content">
  <span class="dynamic-loader-table" *ngIf="loaderSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </span>
  <div class="filters" [formGroup]="filterForm" *ngIf="!loaderSpinner">
    <div class="filter-input">
      <mat-form-field appearance="fill" id="{{ entityName }}-search-compan">
        <mat-label>{{ 'COMPANY.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'COMPANY.SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          type="text"
          [removeHtmlTags]="filterForm.controls.searchString"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
        aria-hidden="true"
        id="{{ entityName }}-search-results-table"
        class="general-table-alignment"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col"></th>
          <td
            mat-cell
            *matCellDef="let row; let element; let i = index"
            id="{{ entityName }}-table-results-checkbox-{{ i }}"
          >
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectCompany(row) : null"
              [aria-label]="checkboxLabel(row)"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="visibleId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th number-row-header"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
          >
            ID
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = dataIndex"
            id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ i }}"
            class="number-row"
          >
            {{ element.visibleId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="centralMasterDataRecord">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
          >
            {{ 'COMPANY.ADDRESS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = dataIndex"
            id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ i }}"
          >
            {{ getAddressString(element) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="contactPeopleCount">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
          >
            {{ 'COMPANY.CONTACT-PEOPLE.COUNT' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = dataIndex"
            id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-{{ i }}"
          >
            {{ element.contactPeopleCount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="limit">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[4] }}"
          >
            {{ 'COMPANY.LIMIT.LIMITS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = dataIndex"
            id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ i }}"
          >
            {{ element.limit }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"></tr>
      </table>
      <mat-paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[20, 50, 100, 200]"
        showFirstLastButtons
        [length]="totalCount"
        (page)="paginate($event)"
      ></mat-paginator>
    </div>
  </div>

  <div class="buttons">
    <button id="{{ entityName }}-client-modal-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
      {{ 'CANCEL' | translate }}
    </button>
    <button
      id="{{ entityName }}-client-modal-submit-button"
      mat-button
      class="custom-button"
      id="acquire"
      [disabled]="selection.isEmpty()"
      [mat-dialog-close]="data"
      style="margin-right: 0"
    >
      {{ 'DEBTOR.ACQUIRE' | translate }}
    </button>
  </div>
</div>
