<div class="user-profile-back-button">
  <app-back-button [address]="'/'"></app-back-button>
</div>

<div class="title-form">
  <h5 id="{{ entityName }}-form-title">{{ 'GENERAL.SETTINGS' | translate }}</h5>
</div>
<div class="input-wrapper form-container settings-container">
  <form class="form" [formGroup]="userProfileForm" autocomplete="off">
    <div class="settings-group-row">
      <span *ngIf="editMode && this.currentUser">
        <div class="header-nontask">{{ this.currentUser.firstName + ' ' + this.currentUser.lastName }}</div>
      </span>
      <span *ngIf="!editMode && this.currentUser">
        <div class="header">{{ this.currentUser.firstName + ' ' + this.currentUser.lastName }}</div>
      </span>
      <div class="settings">
        <app-mat-select
          [hidden]="this.internalUser"
          [name]="'USER-MANAGEMENT.TITLE'"
          [placeholder]="'USER-MANAGEMENT.TITLE'"
          [label]="'USER-MANAGEMENT.TITLE'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.title"
          [controlName]="'title'"
          [approvedValue]="approvedValue ? approvedValue.title : null"
          [taskValue]="taskValue ? taskValue.title : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [options]="titles"
          [optionValue]="'name'"
          [selectedOption]="null"
          [optionTranslationText]="''"
          [translationOption]="'translateName'"
          [titlecasePipe]="false"
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-select
          [hidden]="this.internalUser"
          [name]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
          [placeholder]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
          [label]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.academicTitle"
          [controlName]="'academicTitle'"
          [approvedValue]="approvedValue ? approvedValue.academicTitle : null"
          [taskValue]="taskValue ? taskValue.academicTitle : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [options]="academicTitles"
          [optionValue]="'name'"
          [selectedOption]="null"
          [optionTranslationText]="''"
          [translationOption]="'translateName'"
        ></app-mat-select>
        <app-mat-input
          [hidden]="this.internalUser"
          [name]="'USER-MANAGEMENT.FIRST-NAME'"
          [placeholder]="'USER-MANAGEMENT.FIRST-NAME'"
          [label]="'USER-MANAGEMENT.FIRST-NAME'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.firstName"
          [controlName]="'firstName'"
          [approvedValue]="approvedValue ? approvedValue.firstName : null"
          [taskValue]="taskValue ? taskValue.firstName : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input
          [hidden]="this.internalUser"
          [name]="'USER-MANAGEMENT.LAST-NAME'"
          [placeholder]="'USER-MANAGEMENT.LAST-NAME'"
          [label]="'USER-MANAGEMENT.LAST-NAME'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.lastName"
          [controlName]="'lastName'"
          [approvedValue]="approvedValue ? approvedValue.lastName : null"
          [taskValue]="taskValue ? taskValue.lastName : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input
          [name]="'USER-MANAGEMENT.EMAIL'"
          [placeholder]="'USER-MANAGEMENT.EMAIL'"
          [label]="'USER-MANAGEMENT.EMAIL'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.mainEmail"
          [controlName]="'mainEmail'"
          [type]="'email'"
          [approvedValue]="approvedValue ? approvedValue.email : null"
          [taskValue]="taskValue ? taskValue.email : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input
          [name]="'USER-MANAGEMENT.FAX-NUMBER'"
          [placeholder]="'USER-MANAGEMENT.FAX-NUMBER'"
          [label]="'USER-MANAGEMENT.FAX-NUMBER'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.faxNumber"
          [controlName]="'faxNumber'"
          [approvedValue]="approvedValue ? approvedValue.faxNumber : null"
          [taskValue]="taskValue ? taskValue.faxNumber : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input
          [name]="'USER-MANAGEMENT.PHONE-NUMBER'"
          [placeholder]="'USER-MANAGEMENT.PHONE-NUMBER'"
          [label]="'USER-MANAGEMENT.PHONE-NUMBER'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.telephoneNumber"
          [controlName]="'telephoneNumber'"
          [approvedValue]="approvedValue ? approvedValue.telephoneNumber : null"
          [taskValue]="taskValue ? taskValue.telephoneNumber : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input
          [hidden]="!editMode || this.internalUser"
          [name]="'USER-MANAGEMENT.SERVICE-PIN'"
          [placeholder]="'USER-MANAGEMENT.SERVICE-PIN'"
          [label]="'USER-MANAGEMENT.SERVICE-PIN'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.servicePin"
          [controlName]="'servicePin'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [hasSuffixIcon]="true"
          [type]="'password'"
        ></app-mat-input>
        <span
          *ngIf="editMode && !this.internalUser"
          class="extra-info"
          mat-icon-button
          id="{{ entityName }}-service-pin-info"
          (click)="showServicePinPolicy()"
        >
          <mat-icon>info</mat-icon>
        </span>
        <app-mat-input
          [hidden]="!editMode || this.internalUser"
          [name]="'USER-PROFILE.NEW-SERVICE-PIN'"
          [placeholder]="'USER-PROFILE.NEW-SERVICE-PIN'"
          [label]="'USER-PROFILE.NEW-SERVICE-PIN'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.newServicePin"
          [controlName]="'newServicePin'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [type]="'number'"
        ></app-mat-input>
        <app-mat-input
          [hidden]="!editMode || this.internalUser"
          [name]="'USER-PROFILE.CONFIRM-NEW-SERVICE-PIN'"
          [placeholder]="'USER-PROFILE.CONFIRM-NEW-SERVICE-PIN'"
          [label]="'USER-PROFILE.CONFIRM-NEW-SERVICE-PIN'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.confirmNewServicePin"
          [controlName]="'confirmNewServicePin'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [type]="'number'"
        ></app-mat-input>
        <div
          *ngIf="
            userProfileForm.errors?.differentText &&
            (userProfileForm.touched || userProfileForm.dirty) &&
            !userProfileForm.get('confirmNewServicePin').hasError('required') &&
            !userProfileForm.get('confirmNewServicePin').hasError('invalid') &&
            !userProfileForm.get('confirmNewServicePin').hasError('pattern')
          "
          class="input-error"
        >
          {{ 'ERROR-MESSAGES.SAME-VALUES-SERVICE-PINS' | translate }}
        </div>
        <app-mat-input-date
          [name]="'USER-MANAGEMENT.BIRTHDAY'"
          [placeholder]="'USER-MANAGEMENT.BIRTHDAY'"
          [label]="'USER-MANAGEMENT.BIRTHDAY'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.birthday"
          [controlName]="'birthday'"
          [approvedValue]="approvedValue ? approvedValue.birthday : null"
          [taskValue]="taskValue ? taskValue.birthday : null"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        ></app-mat-input-date>
        <app-mat-radio
          [name]="'USER-PROFILE.SETTINGS.LANGUAGE'"
          [label]="'USER-PROFILE.SETTINGS.LANGUAGE'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [approvedValue]="userProfileForm.value.language"
          [taskValue]="userProfileForm.value.language"
          [controlName]="'language'"
          [controlObject]="userProfileForm.controls.language"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [items]="userLanguages"
          [optionValue]="'name'"
          [changeFunction]=""
        ></app-mat-radio>
        <app-mat-select
          [name]="'USER-PROFILE.SETTINGS.DATE-FORMAT'"
          [placeholder]="'USER-PROFILE.SETTINGS.DATE-FORMAT'"
          [label]="'USER-PROFILE.SETTINGS.DATE-FORMAT'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [controlObject]="userProfileForm.controls.dateFormat"
          [controlName]="'dateFormat'"
          [approvedValue]="userProfileForm.value.dateFormat"
          [taskValue]="userProfileForm.value.dateFormat"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [options]="dateFormats"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="getDateFormatTranslation"
        ></app-mat-select>
      </div>
    </div>

    <div *ngIf="internalUser" class="settings-group-row">
      <span *ngIf="editMode">
        <div class="header-nontask">
          {{ 'USER-PROFILE.SETTINGS.EMAIL-TITLE' | translate }}
        </div>
      </span>
      <span *ngIf="!editMode">
        <div class="header">
          {{ 'USER-PROFILE.SETTINGS.EMAIL-TITLE' | translate }}
        </div>
      </span>
      <div class="settings">
        <app-mat-slider
          [name]="'USER-PROFILE.SETTINGS.EMAIL'"
          [label]="'USER-PROFILE.SETTINGS.EMAIL'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [approvedValue]="userProfileForm.value.emailConfig"
          [taskValue]="userProfileForm.value.emailConfig"
          [controlName]="'emailConfig'"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [textTransform]="'none'"
        ></app-mat-slider>

        <app-mat-slider
          *ngIf="userProfileForm.value.emailConfig == true"
          [name]="'USER-PROFILE.SETTINGS.MY-TASKS-EMAIL'"
          [label]="'USER-PROFILE.SETTINGS.MY-TASKS'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [approvedValue]="userProfileForm.value.emailMyTasks"
          [taskValue]="userProfileForm.value.emailMyTasks"
          [controlName]="'emailMyTasks'"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [textTransform]="'none'"
        ></app-mat-slider>

        <app-mat-slider
          *ngIf="userProfileForm.value.emailConfig == true"
          [name]="'USER-PROFILE.SETTINGS.TEAM-TASKS-EMAIL'"
          [label]="'USER-PROFILE.SETTINGS.TEAM-TASKS'"
          [entityName]="entityName"
          [form]="userProfileForm"
          [approvedValue]="userProfileForm.value.emailTaskOfMyTeam"
          [taskValue]="userProfileForm.value.emailTaskOfMyTeam"
          [controlName]="'emailTaskOfMyTeam'"
          [titleTask]="false"
          [editableTask]="editMode"
          [isTask]="isTask"
          [textTransform]="'none'"
        ></app-mat-slider>
      </div>
    </div>
    <div *ngIf="!internalUser" class="functional-buttons">
      <div>
        <button
          mat-button
          class="custom-button"
          *ngIf="editMode"
          id="{{ entityName }}-extra-info-button"
          (click)="askComment()"
        >
          {{ 'USER-PROFILE.SHARE-WITH-US' | translate }}
        </button>
      </div>
      <div>
        <button
          mat-button
          class="custom-button"
          *ngIf="editMode"
          id="{{ entityName }}-reset-password-button"
          (click)="resetPassword()"
        >
          {{ 'USER-PROFILE.PASSWORD-RESET' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="filter-input button-container">
  <ng-container *hasPermission="'pr.u'">
    <button
      mat-button
      class="custom-button"
      *ngIf="editMode"
      [disabled]="userProfileForm.invalid || disableSaveButton"
      id="{{ entityName }}-create-button"
      (click)="saveSettings()"
    >
      {{ 'GENERAL.SAVE' | translate }}
    </button>
  </ng-container>
</div>
