import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnimalWelfareFile } from '../../../models/animal-welfare-file';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { TaskEventType } from 'src/app/shared/models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class AwInterfaceTransferProtocolService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.awInterfaceTransferProtocolService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  private documents = new BehaviorSubject<any>(null);
  currentDocuments = this.documents.asObservable();

  updateDocuments(documents: any) {
    this.documents.next(documents);
  }

  getDocuments(pageIndex, pageSize): Observable<AnimalWelfareFile[]> {
    return this.http.get<AnimalWelfareFile[]>(
      this.serviceUrl + 'files?page=' + pageIndex + '&pageSize=' + pageSize,
      this.buildHeaders()
    );
  }

  getDocument(id: any): Observable<AnimalWelfareFile> {
    return this.http.get<AnimalWelfareFile>(
      this.serviceUrl + 'files/' + id,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any): Observable<any> {
    // No rejection for AW ITP things for now.
    return new BehaviorSubject(true);
  }

  downloadRequestResponse(fileIds: any[], zipName) {
    const config = this.buildHeadersForFileDownload();
    config['params'] = new HttpParams()
      .append('zipName', zipName)
      .append('fileIds', fileIds.join(','));
    config['responseType'] = 'blob';
    const url = this.serviceUrl + 'files/download';
    return this.http.get(url, config);
  }

  filter(request): Observable<any> {
    return this.http.get(this.serviceUrl + 'files/filter', {
      params: request,
      headers: this.buildHeaders().headers,
    });
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  private buildHeadersForFileDownload() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', '*/*'),
    };
  }
}
