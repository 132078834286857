<div class="content-container">
  <app-back-button></app-back-button>

  <div class="title-form">
    <h5 *ngIf="!editMode && !createMode" id="{{ entityName }}-form-title-info">
      {{ 'task-configuration.title.info' | translate }}
    </h5>
    <h5 *ngIf="editMode && !createMode" id="{{ entityName }}-form-title-edit">
      {{ 'task-configuration.title.edit' | translate }}
    </h5>
    <h5 *ngIf="!editMode && createMode" id="{{ entityName }}-form-title-create">
      {{ 'task-configuration.title.create' | translate }}
    </h5>
  </div>

  <div class="input-wrapper form-container">
    <ng-container *hasPermission="'sc.tc.u'">
      <button
        id="{{ entityName }}-edit-task-configuration"
        mat-button
        class="edit-task-button"
        (click)="changeEditable(true)"
      >
        <img *ngIf="!editMode && !createMode" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
      </button>
    </ng-container>
    <form class="form dark-background" [formGroup]="tcForm" autocomplete="off">
      <app-mat-input
        *ngIf="!editMode && !createMode"
        [name]="'id'"
        [placeholder]="'id'"
        [label]="'id'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.id"
        [controlName]="'id'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.id : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
        [type]="'number'"
      ></app-mat-input>

      <app-mat-input
        [name]="'TASK-CONFIGURATION.OBJECT-ID'"
        [placeholder]="'TASK-CONFIGURATION.OBJECT-ID'"
        [label]="'TASK-CONFIGURATION.OBJECT-ID'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.objectId"
        [controlName]="'objectId'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.objectId : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>

      <app-mat-input
        [name]="'TASK-CONFIGURATION.OBJECT'"
        [placeholder]="'TASK-CONFIGURATION.OBJECT'"
        [label]="'TASK-CONFIGURATION.OBJECT'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.object"
        [controlName]="'object'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.object : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>

      <app-mat-input
        [name]="'TASK-CONFIGURATION.TYPE'"
        [placeholder]="'TASK-CONFIGURATION.TYPE'"
        [label]="'TASK-CONFIGURATION.TYPE'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.type"
        [controlName]="'type'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.type : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>

      <app-mat-input
        [name]="'task-configuration.description'"
        [placeholder]="'task-configuration.description'"
        [label]="'task-configuration.description'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.description"
        [controlName]="'description'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.description : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>

      <app-mat-select
        [name]="'TASK-CONFIGURATION.ROLE'"
        [placeholder]="'TASK-CONFIGURATION.ROLE'"
        [label]="'TASK-CONFIGURATION.ROLE'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.roleId"
        [controlName]="'roleId'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.roleId : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
        [options]="roles"
        [optionValue]="'role'"
        [selectedOption]="null"
        [translationOption]="'role'"
        [optionTranslationText]="''"
        [customOptionNameFunction]="null"
        [customTaskValueFunction]="null"
      >
      </app-mat-select>

      <app-mat-select
        [name]="'TASK-CONFIGURATION.TEAM-ID'"
        [placeholder]="'TASK-CONFIGURATION.TEAM-ID'"
        [label]="'TASK-CONFIGURATION.TEAM-ID'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.teamId"
        [controlName]="'teamId'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.teamId : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
        [options]="teams"
        [optionValue]="'name'"
        [selectedOption]="null"
        [translationOption]="'translateName'"
        [optionTranslationText]="''"
        [customOptionNameFunction]="null"
        [customTaskValueFunction]="null"
      >
      </app-mat-select>

      <app-mat-select
        [name]="'TASK-CONFIGURATION.PRIORITY'"
        [placeholder]="'TASK-CONFIGURATION.PRIORITY'"
        [label]="'TASK-CONFIGURATION.PRIORITY'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.priority"
        [controlName]="'priority'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.priority : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
        [options]="priorities"
        [optionValue]="'priority'"
        [selectedOption]="null"
        [translationOption]="'priority'"
        [optionTranslationText]="'priorities'"
        [customOptionNameFunction]="null"
        [customTaskValueFunction]="null"
      >
      </app-mat-select>

      <app-mat-input
        [name]="'TASK-CONFIGURATION.DUE-DATE'"
        [placeholder]="'TASK-CONFIGURATION.DUE-DATE'"
        [label]="'TASK-CONFIGURATION.DUE-DATE'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.dueDate"
        [controlName]="'dueDate'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.dueDate : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
        [maskValue]="'separator'"
        [suffix]="getSuffixDays('TASK-CONFIGURATION.DAYS')"
        [thousandSeparator]="' '"
      >
      </app-mat-input>

      <app-mat-input
        [name]="'task-configuration.embeddedTaskUi'"
        [placeholder]="'task-configuration.embeddedTaskUi'"
        [label]="'task-configuration.embeddedTaskUi'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.embeddedTaskUi"
        [controlName]="'embeddedTaskUi'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.embeddedTaskUi : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>

      <app-mat-input
        [name]="'task-configuration.embeddedUi'"
        [placeholder]="'task-configuration.embeddedUi'"
        [label]="'task-configuration.embeddedUi'"
        [entityName]="entityName"
        [form]="tcForm"
        [controlObject]="tcForm.controls.embeddedUi"
        [controlName]="'embeddedUi'"
        [approvedValue]="null"
        [taskValue]="initialTaskConfiguration ? initialTaskConfiguration.embeddedUi : ''"
        [titleTask]="false"
        [editableTask]="editMode"
        [isTask]="!createMode"
        [formGroupCustomName]="null"
      >
      </app-mat-input>
    </form>
  </div>

  <div class="filter-input button-container" *ngIf="editMode || createMode">
    <button
      mat-button
      class="custom-button-cancel"
      id="{{ entityName }}-cancel-button"
      *ngIf="editMode && !createMode"
      (click)="changeEditable(false)"
    >
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>

    <button
      *hasPermission="['sc.tc.u', 'sc.tc.r']"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      (click)="submitForm()"
      [disabled]="!tcForm.valid"
    >
      <span>{{ 'save' | translate }}</span>
    </button>
  </div>
</div>
