<mat-accordion *ngIf="hasDrafts()">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="subheading filters-title">{{ 'GENERAL.DRAFTS' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="draft-list">
      <span class="dynamic-loader-table" *ngIf="loaderSpinner">
        <mat-spinner [diameter]="50"></mat-spinner>
      </span>
      <app-default-table
        *ngIf="dataLoaded && !loaderSpinner"
        [entityName]="entityName"
        [configurationKey]="configurationKey"
        [columns]="columns"
        [displayedColumns]="displayedColumns"
        [originalColumns]="columns"
        [originalDisplayedColumns]="displayedColumns"
        [tableDataSource]="allocationDraftData"
        [threeDots]="hasThreeDots"
        [listOfActionButtons]="listOfActionButtons"
      ></app-default-table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
