import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { cancelationSelectionEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/cancelation-selection-enums';
import { statusEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/status-enums';
import { planActualValuesEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/plan-actual-value-selection-enums';
import { kindFilterEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/kind-filter-enums';
import { typeFilterEnum } from 'src/app/in-memory-data/animal-welfare/liquidity-plan/enums/type-filter-enums';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-animal-welfare-liquidity-plan-filter',
  templateUrl: './liquidity-plan-filter.component.html',
  styleUrls: ['./liquidity-plan-filter.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class LiquidityPlanFilterComponent implements OnInit {
  @Input() selectedLiquidityPlan;
  entityName = 'animal-welfare-liquidity-plan-filter';
  panelOpenState = false;
  liquidityPlanFilterForm: UntypedFormGroup;
  state;
  cancelationSelections = cancelationSelectionEnum;
  statuses = statusEnum;
  planActualValues = planActualValuesEnum;
  kinds = kindFilterEnum;
  types = typeFilterEnum;
  approvedByList = [];
  createdByList = [];
  loadSpinner = false;
  configs = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    liquidityPlanGuid: null,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private liquidityPlanService: LiquidityPlanService
  ) {
    this.liquidityPlanFilterFormBuilder();
    this.getApprovedAndCreatedBy();
  }

  ngOnInit() {
    this.state = window.history.state;
    this.resetFormValues();
    this.checkDateRangeInputs();
  }

  getApprovedAndCreatedBy() {
    this.liquidityPlanService.retrieveApprovedBy().subscribe((value) => {
      if (value) {
        this.approvedByList = value;
      }
    });
    this.liquidityPlanService.retrieveCreatedBy().subscribe((value) => {
      if (value) {
        this.createdByList = value;
      }
    });
  }

  resetFormValues() {
    this.liquidityPlanService.currentEditMode.subscribe((value) => {
      if (!value) {
        this.liquidityPlanFilterForm.reset();
        this.getApprovedAndCreatedBy();
      }
    });
  }

  liquidityPlanFilterFormBuilder() {
    this.liquidityPlanFilterForm = this.formBuilder.group({
      kind: [{ value: null }, Validators.required],
      type: [{ value: null }, Validators.required],
      monthAndYear: new UntypedFormGroup({
        startDate: new UntypedFormControl({ value: null }, []),
        endDate: new UntypedFormControl({ value: null }, []),
        fieldName: new UntypedFormControl({ value: 'monthAndYear' }, []),
      }),
      comment: [{ value: null }, []],
      cancellation: [{ value: null }, Validators.required],
      status: [{ value: null }, Validators.required],
      planOrActualValue: [{ value: null }, Validators.required],
      createdBy: [{ value: null }],
      inputDate: new UntypedFormGroup({
        startDate: new UntypedFormControl({ value: null }, []),
        endDate: new UntypedFormControl({ value: null }, []),
        fieldName: new UntypedFormControl({ value: 'inputDate' }, []),
      }),
      approvedBy: [{ value: null }],
      approvedOn: new UntypedFormGroup({
        startDate: new UntypedFormControl({ value: null }, []),
        endDate: new UntypedFormControl({ value: null }, []),
        fieldName: new UntypedFormControl({ value: 'approvedOn' }, []),
      }),
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.liquidityPlanFilterForm
      .get('monthAndYear')
      .get('startDate')
      .setValue(moment());
    const ctrlValue = this.liquidityPlanFilterForm
      .get('monthAndYear')
      .get('startDate').value;
    ctrlValue.year(normalizedYear.year());
    this.liquidityPlanFilterForm
      .get('monthAndYear')
      .get('startDate')
      .setValue(ctrlValue);
  }

  checkDateRangeInputs() {
    this.liquidityPlanFilterForm
      .get('monthAndYear')
      .valueChanges.subscribe((_) => {
        if (
          (this.liquidityPlanFilterForm.get('monthAndYear').get('startDate')
            .value &&
            this.liquidityPlanFilterForm.get('monthAndYear').get('endDate')
              .value == null) ||
          (this.liquidityPlanFilterForm.get('monthAndYear').get('startDate')
            .value == null &&
            this.liquidityPlanFilterForm.get('monthAndYear').get('endDate')
              .value)
        ) {
          this.liquidityPlanFilterForm
            .get('monthAndYear')
            .get('endDate')
            .setErrors({ required: true });
        } else if (
          this.liquidityPlanFilterForm.get('monthAndYear').get('startDate')
            .value == null ||
          this.liquidityPlanFilterForm.get('monthAndYear').get('endDate').value
        ) {
          this.liquidityPlanFilterForm
            .get('monthAndYear')
            .get('endDate')
            .setErrors(null);
        }
      });

    this.liquidityPlanFilterForm
      .get('inputDate')
      .valueChanges.subscribe((_) => {
        if (
          (this.liquidityPlanFilterForm.get('inputDate').get('startDate')
            .value &&
            this.liquidityPlanFilterForm.get('inputDate').get('endDate')
              .value == null) ||
          (this.liquidityPlanFilterForm.get('inputDate').get('startDate')
            .value == null &&
            this.liquidityPlanFilterForm.get('inputDate').get('endDate').value)
        ) {
          this.liquidityPlanFilterForm
            .get('inputDate')
            .get('endDate')
            .setErrors({ required: true });
        } else if (
          this.liquidityPlanFilterForm.get('inputDate').get('startDate')
            .value == null ||
          this.liquidityPlanFilterForm.get('inputDate').get('endDate').value
        ) {
          this.liquidityPlanFilterForm
            .get('inputDate')
            .get('endDate')
            .setErrors(null);
        }
      });

    this.liquidityPlanFilterForm
      .get('approvedOn')
      .valueChanges.subscribe((_) => {
        if (
          (this.liquidityPlanFilterForm.get('approvedOn').get('startDate')
            .value &&
            this.liquidityPlanFilterForm.get('approvedOn').get('endDate')
              .value == null) ||
          (this.liquidityPlanFilterForm.get('approvedOn').get('startDate')
            .value == null &&
            this.liquidityPlanFilterForm.get('approvedOn').get('endDate').value)
        ) {
          this.liquidityPlanFilterForm
            .get('approvedOn')
            .get('endDate')
            .setErrors({ required: true });
        } else if (
          this.liquidityPlanFilterForm.get('approvedOn').get('startDate')
            .value == null ||
          this.liquidityPlanFilterForm.get('approvedOn').get('endDate').value
        ) {
          this.liquidityPlanFilterForm
            .get('approvedOn')
            .get('endDate')
            .setErrors(null);
        }
      });
  }

  search() {
    const request = this.buildSearchRequest();
    this.loadSpinner = true;
    this.liquidityPlanService
      .searchAssetChange(request)
      .subscribe((filteredValues) => {
        filteredValues.content.map((val) => (val.createdOn = val.inputDate));
        this.liquidityPlanService.listOfFilteredAssetChanges = filteredValues;
        request.filteredValues = JSON.parse(request.filteredValues);
        this.liquidityPlanService.updateAppliedFilters(request);
        this.loadSpinner = false;
      });
    this.panelOpenState = !this.panelOpenState;
  }

  private buildSearchRequest() {
    this.configs.liquidityPlanGuid = this.selectedLiquidityPlan.value.id;
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    this.liquidityPlanFilterForm.controls.inputDate
      .get('fieldName')
      .setValue('inputDate');
    this.liquidityPlanFilterForm.controls.monthAndYear
      .get('fieldName')
      .setValue('monthAndYear');
    this.liquidityPlanFilterForm.controls.approvedOn
      .get('fieldName')
      .setValue('approvedOn');
    const formValues = Object.assign({}, this.liquidityPlanFilterForm.value);
    formValues.kind =
      formValues.kind && formValues.kind !== 'null' ? formValues.kind : null;
    formValues.type =
      formValues.type && formValues.type !== 'null' ? formValues.type : null;
    formValues.status =
      formValues.status && formValues.status !== 'null'
        ? formValues.status
        : null;
    formValues.planOrActualValue =
      formValues.planOrActualValue && formValues.planOrActualValue !== 'null'
        ? formValues.planOrActualValue
        : null;
    formValues.comment = formValues.comment ? formValues.comment : null;
    formValues.createdBy = formValues.createdBy ? formValues.createdBy : null;

    formValues.approvedBy = formValues.approvedBy
      ? formValues.approvedBy
      : null;

    if (
      formValues.inputDate.startDate &&
      formValues.inputDate.startDate !== 'Invalid date'
    ) {
      this.formatDateRangeField(formValues.inputDate, true);
      filterOptions['inputDate'] = JSON.stringify(formValues.inputDate);
    }
    formValues.inputDate = null;

    if (
      formValues.approvedOn.startDate &&
      formValues.approvedOn.startDate !== 'Invalid date'
    ) {
      this.formatDateRangeField(formValues.approvedOn, true);
      filterOptions['approvedOn'] = JSON.stringify(formValues.approvedOn);
    }
    formValues.approvedOn = null;

    if (
      formValues.monthAndYear.startDate &&
      formValues.monthAndYear.startDate !== 'Invalid date'
    ) {
      this.formatDateRangeField(formValues.monthAndYear);
      filterOptions['monthAndYear'] = JSON.stringify(formValues.monthAndYear);
    }
    formValues.monthAndYear = null;

    filterOptions['searchFilters'] = JSON.stringify(formValues);
    return filterOptions;
  }

  formatDateRangeField(dateRangeField: any, formatHours: boolean = false) {
    if (formatHours) {
      dateRangeField.startDate = moment(dateRangeField.startDate)
        .toDate()
        .setHours(0, 0, 0);
      dateRangeField.startDate = moment(dateRangeField.startDate)
        .toDate()
        .setMilliseconds(0);
      dateRangeField.endDate = moment(dateRangeField.endDate)
        .toDate()
        .setHours(23, 59, 59);
      dateRangeField.endDate = moment(dateRangeField.endDate)
        .toDate()
        .setMilliseconds(0);

      dateRangeField.startDate = moment(dateRangeField.startDate).format(
        'YYYY-MM-DD HH:mm:ss.sss'
      );
      dateRangeField.endDate = moment(dateRangeField.endDate).format(
        'YYYY-MM-DD HH:mm:ss.sss'
      );
    } else {
      dateRangeField.startDate = moment(dateRangeField.startDate).toDate();
      dateRangeField.endDate = moment(dateRangeField.endDate).toDate();
      dateRangeField.startDate = moment(dateRangeField.startDate).format(
        'YYYY-MM-DD'
      );
      dateRangeField.endDate = moment(dateRangeField.endDate).format(
        'YYYY-MM-DD'
      );
    }
  }
}
