export const paymentMethodTypes: any[] = [
  {
    name: 'M',
    translateName: 'ANIMAL-WELFARE.SALES-ENTRY.PAYMENT-METHOD.MONTHLY',
  },
  {
    name: 'Q',
    translateName: 'ANIMAL-WELFARE.SALES-ENTRY.PAYMENT-METHOD.QUARTERLY',
  },
];
