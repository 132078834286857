<div class="dashboard-container">
  <div class="container-fluid" >
    <div class="row margin-bottom-row">
      <app-dashboard-user-general-information [unreadMessages]="unreadMessages" [user]="user">
      </app-dashboard-user-general-information>
    </div>

    <div class="row margin-bottom-row">
      <div class="col-md-8">
        <app-dashboard-welcome></app-dashboard-welcome>
      </div>
      <div class="col-md-4">
        <div class="row margin-bottom-row">
          <div class="col-12">
            <app-dashboard-calendar></app-dashboard-calendar>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-dashboard-quaterly-settlement></app-dashboard-quaterly-settlement>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-bottom-row">
      <div class="col-md-8">
        <app-dashboard-contact-us></app-dashboard-contact-us>
      </div>
      <div class="col-md-4">
        <app-dashboard-manual></app-dashboard-manual>
      </div>
    </div>
  </div>
</div>
