export const quantityLabeledGoodsColumns: string[] = [
  'select',
  'purchaserId',
  'purchaserName',
  'supplierId',
  'supplierName',
  'goodsGroup',
  'type',
  'quantity',
  'comment',
  'reportingPeriod',
  'createdBy',
  'createdOn',
  'approvedBy',
  'approvedOn',
  'actions',
];
