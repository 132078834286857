<app-back-button *ngIf="isTask"> </app-back-button>

<div class="edit-task">
  <button
    *hasPermission="'tca.u'"
    id="{{entityName}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeEditable(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>

<div class="input-wrapper form-container">
  <form class="form" [formGroup]="transactionCodeForm" autocomplete="off">
    <div
      class="container general-display-alignment"
      fxLayout="row wrap"
      fxlayout.md="column"
      fxlayout.sm="column"
      fxlayout.xs="column"
      fxLayoutGap="7px grid"
    >
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ID'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ID'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ID'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCode"
              [controlName]="'transactionCode'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionCode : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCode : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [type]="'number'"
              [twoColumns]="true"
              (keypress)="restrictIdInputToPositiveIntegers($event)"
            >
            </app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-TRANSACTION-CODE-NAME'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-TRANSACTION-CODE-NAME'"
              [label]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-TRANSACTION-CODE-NAME'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeNameGerman"
              [controlName]="'transactionCodeNameGerman'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionCodeNameGerman : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeNameGerman : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-NAME-ENGLISH'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-NAME-ENGLISH'"
              [label]="'TRANSACTION-CODE-ALLOCATION.DEFAULT-NAME-ENGLISH'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeNameEnglish"
              [controlName]="'transactionCodeNameEnglish'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionCodeNameEnglish : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeNameEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION'"
              [label]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeDescriptionGerman"
              [controlName]="'transactionCodeDescriptionGerman'"
              [approvedValue]="
                transactionCodeApproved ? transactionCodeApproved.transactionCodeDescriptionGerman : null
              "
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeDescriptionGerman : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION-ENGLISH'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION-ENGLISH'"
              [label]="'TRANSACTION-CODE-ALLOCATION.DESCRIPTION-ENGLISH'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeDescriptionEnglish"
              [controlName]="'transactionCodeDescriptionEnglish'"
              [approvedValue]="
                transactionCodeApproved ? transactionCodeApproved.transactionCodeDescriptionEnglish : null
              "
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeDescriptionEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-GERMAN-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-GERMAN-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-GERMAN-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeNameGerman"
              [controlName]="'transactionCodeNameGerman'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionCodeNameGerman : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeNameGerman : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ENGLISH-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ENGLISH-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ENGLISH-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeNameEnglish"
              [controlName]="'transactionCodeNameEnglish'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionCodeNameEnglish : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeNameEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-GERMAN-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-GERMAN-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-GERMAN-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeDescriptionGerman"
              [controlName]="'transactionCodeDescriptionGerman'"
              [approvedValue]="
                transactionCodeApproved ? transactionCodeApproved.transactionCodeDescriptionGerman : null
              "
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeDescriptionGerman : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-ENGLISH-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-ENGLISH-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-ENGLISH-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionCodeDescriptionEnglish"
              [controlName]="'transactionCodeDescriptionEnglish'"
              [approvedValue]="
                transactionCodeApproved ? transactionCodeApproved.transactionCodeDescriptionEnglish : null
              "
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionCodeDescriptionEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-TYPE'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-TYPE'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSACTION-TYPE'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.transactionType"
              [controlName]="'transactionType'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transactionType : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transactionType : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="transactionTypes"
              [optionValue]="'code'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getTransactionType"
              [twoColumns]="true"
            ></app-mat-select>
          </div>
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.TRANSFER-TRANSACTION-CODE'"
              [label]="'TRANSACTION-CODE-ALLOCATION.TRANSFER-TRANSACTION-CODE'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlName]="'transferTransactionCode'"
              [controlObject]="transactionCodeForm.controls.transferTransactionCode"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.transferTransactionCode : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.transferTransactionCode : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.STANDARD'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.STANDARD'"
              [label]="'TRANSACTION-CODE-ALLOCATION.STANDARD'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.standard"
              [controlName]="'standard'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.standard : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.standard : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="standards"
              [optionValue]="'code'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getStandard"
              [twoColumns]="true"
            ></app-mat-select>
          </div>
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.CANCELLATION'"
              [label]="'TRANSACTION-CODE-ALLOCATION.CANCELLATION'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlName]="'cancellation'"
              [controlObject]="transactionCodeForm.controls.cancellation"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.cancellation : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.cancellation : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.originalSapLedgerAccountCredit"
              [controlName]="'originalSapLedgerAccountCredit'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.originalSapLedgerAccountCredit : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.originalSapLedgerAccountCredit : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
            ></app-mat-select>
          </div>

          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.originalSapLedgerAccountDebit"
              [controlName]="'originalSapLedgerAccountDebit'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.originalSapLedgerAccountDebit : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.originalSapLedgerAccountDebit : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [twoColumns]="true"
            >
            </app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.sapLedgerAccountCredit"
              [controlName]="'sapLedgerAccountCredit'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.sapLedgerAccountCredit : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.sapLedgerAccountCredit : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-select>
          </div>

          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.sapLedgerAccountDebit"
              [controlName]="'sapLedgerAccountDebit'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.sapLedgerAccountDebit : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.sapLedgerAccountDebit : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.objectAccount"
              [controlName]="'objectAccount'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.objectAccount : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.objectAccount : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'name'"
              [optionTranslationText]="''"
              [twoColumns]="true"
              [customTaskValueFunction]="getAccount"
            >
            </app-mat-select>
          </div>
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.originalObjectAccount"
              [controlName]="'originalObjectAccount'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.originalObjectAccount : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.originalObjectAccount : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'name'"
              [optionTranslationText]="''"
              [nameParamsValue]="'ENTITIES.BU'"
              [twoColumns]="true"
              [customTaskValueFunction]="getAccount"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.BOOKING-OPTION'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.BOOKING-OPTION'"
              [label]="'TRANSACTION-CODE-ALLOCATION.BOOKING-OPTION'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.bookingOption"
              [controlName]="'bookingOption'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.bookingOption : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.bookingOption : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="bookingOptions"
              [optionValue]="'code'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getBookingOption"
              [twoColumns]="true"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.IS-DUE-DATE'"
              [label]="'TRANSACTION-CODE-ALLOCATION.IS-DUE-DATE'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlName]="'dueDate'"
              [controlObject]="transactionCodeForm.controls.dueDate"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.dueDate : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.dueDate : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.CLASSIFICATION-RELEVANT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.CLASSIFICATION-RELEVANT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlName]="'classificationRelevant'"
              [controlObject]="transactionCodeForm.controls.classificationRelevant"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.classificationRelevant : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.classificationRelevant : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.RELEVANT-FOR-REMAINING-LIMIT-CALC'"
              [label]="'TRANSACTION-CODE-ALLOCATION.RELEVANT-FOR-REMAINING-LIMIT-CALC'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlName]="'relevantForRemainingLimitCalc'"
              [controlObject]="transactionCodeForm.controls.relevantForRemainingLimitCalc"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.relevantForRemainingLimitCalc : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.relevantForRemainingLimitCalc : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="relevantForRemainingLimitCalcOptions"
              [optionValue]="'value'"
              [titlecasePipe]="false"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.SETTLEMENT-RELEVANT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SETTLEMENT-RELEVANT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.settlementRelevant : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.settlementRelevant : null"
              [controlName]="'settlementRelevant'"
              [controlObject]="transactionCodeForm.controls.settlementRelevant"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="settlementRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.RELEVANT-SALES'"
              [label]="'TRANSACTION-CODE-ALLOCATION.RELEVANT-SALES'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.relevantSales : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.relevantSales : null"
              [controlName]="'relevantSales'"
              [controlObject]="transactionCodeForm.controls.relevantSales"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'TRANSACTION-CODE-ALLOCATION.SALES-TAX-ACCOUNT'"
              [placeholder]="'TRANSACTION-CODE-ALLOCATION.SALES-TAX-ACCOUNT'"
              [label]="'TRANSACTION-CODE-ALLOCATION.SALES-TAX-ACCOUNT'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [controlObject]="transactionCodeForm.controls.salesTaxAccount"
              [controlName]="'salesTaxAccount'"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.salesTaxAccount : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.salesTaxAccount : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="salesTaxAccounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'account'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getTaxAccount"
              [twoColumns]="true"
            >
            </app-mat-select>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'TRANSACTION-CODE-ALLOCATION.DISPLAY-EXTERNALLY'"
              [label]="'TRANSACTION-CODE-ALLOCATION.DISPLAY-EXTERNALLY'"
              [entityName]="entityName"
              [form]="transactionCodeForm"
              [approvedValue]="transactionCodeApproved ? transactionCodeApproved.displayExternally : null"
              [taskValue]="transactionCodeTask ? transactionCodeTask.displayExternally : null"
              [controlName]="'displayExternally'"
              [controlObject]="transactionCodeForm.controls.displayExternally"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [twoColumns]="true"
            ></app-mat-radio>
          </div>
        </div>
      </div>
      <div
        fxFlex="100"
        fxFlex.sm="100"
        fxFlex.xs="100"
        *ngIf="
          state &&
          state.task &&
          state.task.type == 'APPROVAL_TRANSACTION_CODE_ALLOCATION' &&
          (state.task.params.eventType == 'INACTIVATE' || state.task.params.eventType == 'ACTIVATE') &&
          transactionCodeTask
        "
      >
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="non-editable-info-container">
              <div class="object-row">
                <div
                  class="container"
                  fxLayout="row wrap"
                  fxlayout.sm="column"
                  fxlayout.xs="column"
                  fxLayoutGap="7px grid"
                >
                  <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100">
                    <span class="object-label">{{ 'BOOKING-CODE.STATUS' | translate }}:</span>
                  </div>
                  <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100">
                    <span class="object-value val-changed">{{
                      getStatusTask(transactionCodeTask.status) | translate
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="filter-input button-container"
  *ngIf="!isTask || (isTask && editableTask)"
  [ngClass]="{ rightAlignment: isTask }"
>
  <ng-container>
    <button
      mat-button
      class="custom-button-cancel margin-button"
      id="{{ entityName }}-cancel-button"
      (click)="cancelCompleteTransactionCode()"
    >
      <span>
        {{ 'GENERAL.CANCEL' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container>
    <button
      mat-button
      class="custom-button"
      id="{{ entityName }}-save-button"
      [disabled]="(transactionCodeForm && !transactionCodeForm.valid) || draftExists == true"
      (click)="completeTransactionCode()"
    >
      <span>
        {{ 'GENERAL.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
