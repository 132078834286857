import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DialogData } from '../../models/dialog-data';
import { SelectionModel } from '@angular/cdk/collections';
import { transactionCodeModalColumns } from 'src/app/in-memory-data/bookingCode/transaction-code-modal/table-columns';
import { BookingCodeService } from '../../services/booking-code/booking-code.service';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-transaction-code-selection-modal',
  templateUrl: './transaction-code-selection-modal.component.html',
  styleUrls: ['./transaction-code-selection-modal.component.less'],
})
export class TransactionCodeSelectionModalComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel(true, []);

  displayedColumns = transactionCodeModalColumns;

  filterForm: UntypedFormGroup;
  entityName = 'transaction-code-selection-modal';
  data: any;

  availableTransactionsCodes = true;

  loader = true;

  bookingCodeList: any;
  existentTransactions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    private bookingCodeService: BookingCodeService,
    private decimalPipe: DecimalPipe
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.loader = true;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'transactionCodeId') {
        return item.id;
      } else if (property === 'defaultBookingCodeName') {
        return item.name;
      } else {
        return item[property];
      }
    };
    this.dataSource.paginator = this.paginator;
    if (this.dialogData.selectedTransactions) {
      this.existentTransactions = this.dialogData.selectedTransactions;
    }

    this.filterFormChanges();
    this.getTransactionCodes();
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  filterFormChanges() {
    this.filterForm.valueChanges.subscribe(
      (filters: { searchString: string }) => {
        const searchString = filters.searchString.trim().toUpperCase();
        const filteredList: any[] = this.bookingCodeList.filter(
          (item) =>
            (item.id &&
              this.decimalPipe
                .transform(item.id, '3.0')
                .toString()
                .toUpperCase()
                .indexOf(searchString) >= 0) ||
            (item.name &&
              item.name.toString().toUpperCase().indexOf(searchString) >= 0) ||
            (item.defaultNameEnglish &&
              item.defaultNameEnglish
                .toString()
                .toUpperCase()
                .indexOf(searchString) >= 0)
        );
        this.dataSource.data = filteredList;
      }
    );
  }

  getTransactionCodes() {
    this.bookingCodeService.listAll().subscribe(
      (bookingCodeList) => {
        bookingCodeList.sort((a, b) => (a.id > b.id ? 1 : -1));
        this.bookingCodeList = this.dataSource.data = bookingCodeList;
        this.removeTransactions();
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  async removeTransactions() {
    let resultData;
    resultData = await this.removeExistentTransactions();
    this.dataSource.data = resultData;
    if (this.dataSource.data.length === 0) {
      this.availableTransactionsCodes = false;
    } else {
      this.availableTransactionsCodes = true;
    }
    this.loader = false;
  }

  async removeExistentTransactions() {
    const resultData: any = Object.assign([], this.bookingCodeList);
    for (let i = this.bookingCodeList.length - 1; i >= 0; i--) {
      const item = this.existentTransactions.find(
        (data) => data.transactionCode === this.bookingCodeList[i].id
      );
      if (item != null) {
        resultData.splice(i, 1);
      }
    }
    return resultData;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
