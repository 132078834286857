<div class="content-container">
  <div class="filters filters-list" [formGroup]="filterForm">
    <div class="filter-input">
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.locationNumber"
        [controlName]="'locationNumber'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      >
      </app-mat-input>
      <div class="form-row inline-display margin-search-input">
        <mat-form-field appearance="legacy" id="{{ entityName }}-form-production-type">
          <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate }}"
            formControlName="productionType"
            id="{{ entityName }}-form-production-type-select"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="{{ 'search' | translate }}"
                noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                formControlName="productionTypeSearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredProductionTypes" [value]="option.value">
              {{ option.value }} {{ option.translateName | translate }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            id="{{ entityName }}-form-production-type-clear"
            (click)="clearOptions('productionType'); $event.stopPropagation()"
            *ngIf="showRemoveOption('productionType')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <app-mat-input-date
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.ENTRY-DATE'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.ENTRY-DATE'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.ENTRY-DATE'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.createdOn"
        [controlName]="'createdOn'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input-date>

      <div class="form-row inline-display margin-search-input">
        <mat-form-field appearance="legacy">
          <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate }}</mat-label>
          <mat-date-range-input
            [formGroup]="filterForm.controls.submissionDate"
            [rangePicker]="picker"
            id="{{ entityName }}-datepicker-begin-end"
          >
            <input
              matStartDate
              formControlName="startDate"
              id="{{ entityName }}-datepicker-begin"
              class="date-picker-start"
              placeholder="{{ 'GENERAL.START-DATE' | translate }}"
              [formControl]="filterForm.controls.submissionDate.get('startDate')"
            />
            <input
              matEndDate
              formControlName="endDate"
              id="{{ entityName }}-datepicker-end"
              class="date-picker-end"
              placeholder="{{ 'GENERAL.END-DATE' | translate }}"
              [formControl]="filterForm.controls.submissionDate.get('endDate')"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            id="{{ entityName }}-form-submissionDate-clear"
            (click)="clearPeriodFilter(); $event.stopPropagation()"
            *ngIf="showRemoveOption('submissionDate.startDate') || showRemoveOption('submissionDate.endDate')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.QSID'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.QSID'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.QSID'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.qsid"
        [controlName]="'qsid'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input>
      <app-mat-radio
        class="width-radio"
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlName]="'measureUnit'"
        [controlObject]="filterForm.controls.measureUnit"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [items]="measureUnitOptions"
        [optionValue]="'name'"
        [approvedValue]="null"
        [taskValue]="null"
        [inline]="true"
      ></app-mat-radio>
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.amountGood"
        [controlName]="'amountGood'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
        [maskValue]="'separator.3'"
        [thousandSeparator]="getThousandSeperator()"
      >
      </app-mat-input>
      <app-mat-select
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.transfer"
        [controlName]="'transfer'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [options]="transferOptions"
        [optionValue]="'value'"
        [translationOption]="'translateName'"
        [optionTranslationText]="''"
        [inline]="true"
        [customOptionNameFunction]="null"
        [appearance]="'legacy'"
      ></app-mat-select>
      <app-mat-select
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.status"
        [controlName]="'status'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [options]="statusOptions"
        [optionValue]="'value'"
        [translationOption]="'translateName'"
        [optionTranslationText]="''"
        [inline]="true"
        [customOptionNameFunction]="null"
        [appearance]="'legacy'"
      ></app-mat-select>
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.CREATED-BY'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.CREATED-BY'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.CREATED-BY'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.createdBy"
        [controlName]="'createdBy'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input>
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-BY'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-BY'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-BY'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.approvedBy"
        [controlName]="'approvedBy'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input>
      <app-mat-input-date
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.APPROVED-ON'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.approvedOn"
        [controlName]="'approvedOn'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input-date>
      <app-mat-input
        [name]="'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT'"
        [label]="'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT'"
        [entityName]="entityName"
        [form]="filterForm"
        [controlObject]="filterForm.controls.comment"
        [controlName]="'comment'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [inline]="true"
        [appearance]="'legacy'"
      ></app-mat-input>
      <div class="filter-input inline-display-with-Form">
        <button mat-button class="custom-button" (click)="searchData()" id="{{ entityName }}-search-button">
          <span class="material-icons"> search </span>
        </button>
      </div>
    </div>
    <div class="filter-input button-container-end">
      <button
        *hasPermission="'aw.g.c'"
        (click)="goToEditView()"
        mat-button
        class="custom-button"
        id="{{ entityName }}-create-button"
      >
        <span>
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.CREATE' | translate }}
        </span>
      </button>
    </div>
  </div>

  <div class="table-list">
    <h5 id="{{ entityName }}-search-results-title">
      {{ 'SEARCH-RESULT' | translate }}
    </h5>
    <app-default-table
      [entityName]="entityName"
      [columns]="columns"
      [displayedColumns]="displayedColumns"
      [originalColumns]="columns"
      [originalDisplayedColumns]="displayedColumns"
      [configurationKey]="configurationKey"
      [tableDataSource]="transferQuantityGoodsList"
      [threeDots]="true"
      [listOfActionButtons]="listOfActionButtons"
      (filterEvent)="filter($event)"
      [distinctValues]="getDistinctValues"
      [totalCount]="totalCount"
    >
    </app-default-table>
  </div>

  <div class="filters checksum-container" [formGroup]="checkSumForm">
    <div class="filter-input">
      <app-mat-input
        style="margin-left: 40px"
        [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
        [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
        [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
        [entityName]="entityName"
        [form]="checkSumForm"
        [controlObject]="checkSumForm.controls.checksum"
        [controlName]="'checksum'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
      >
      </app-mat-input>
    </div>
  </div>

  <div class="drafts-container" *hasPermission="'aw.g.r.d'">
    <app-transfer-quantity-goods-draft></app-transfer-quantity-goods-draft>
  </div>
</div>
