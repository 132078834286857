export const accountStatementConfigurationColumnsConf: any[] = [
  {
    name: 'createAccountStatementConfigurationId',
    index: 0,
    checked: true,
    translateName:
      'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT-STATEMENT-CONFIGURATION-ID',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.NAME',
    locked: true,
  },
  {
    name: 'account',
    index: 2,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT',
    locked: true,
  },
  {
    name: 'beasRelevant',
    index: 3,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.BEAS-RELEVANT',
    locked: true,
  },
  {
    name: 'fiAccount',
    index: 4,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.FI-ACCOUNT',
    locked: true,
  },
  {
    name: 'businessUnitId',
    index: 5,
    checked: false,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.BUSINESS-UNIT-ID',
    locked: false,
  },
  {
    name: 'platformId',
    index: 6,
    checked: false,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.PLATFORM-ID',
    locked: false,
  },
  {
    name: 'clientId',
    index: 7,
    checked: false,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.CLIENT-ID',
    locked: false,
  },
  {
    name: 'debtorId',
    index: 8,
    checked: false,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.DEBTOR-ID',
    locked: false,
  },
  {
    name: 'btcMapping',
    index: 9,
    checked: false,
    translateName: 'SYSTEM-CONFIGURATION.CONFIGURATION.BTC-MAPPING',
    locked: false,
  },
  {
    version: 4,
  },
];
