import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitService {
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.buService;
  }
  private serviceUrl;

  mapOfColumnFilters = [];
  private searchString = '';

  businessUnitSelected: EventEmitter<any> = new EventEmitter();
  currentBusinessUnit;
  allBusinessUnits;

  pageSize;
  sortDirection;
  sortOrder;

  refreshBUData = new EventEmitter();
  bus: Subscription;
  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  refreshBusFunction() {
    this.refreshBUData.emit();
  }

  getBusinessUnitWithTaskId(id: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + id + '/business-unit',
      this.buildHeaders()
    );
  }

  getCurrentApproved(id: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'business-units/' + id,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'business-units/drafts/' + id + '/approval',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'business-units/drafts/' + id + '/rejection',
      json,
      this.buildHeaders()
    );
  }

  getAllBusinessUnits(
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'business-units/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();
    return this.http.get<any>(url, this.buildHeaders());
  }

  saveDraftForApproval(request) {
    return this.http.post(
      this.serviceUrl + 'drafts/approval',
      request,
      this.buildHeaders()
    );
  }

  retrieveUserDraft(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/user-drafts',
      this.buildHeaders()
    );
  }

  retrieveInitialDraft(buId): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/initial-drafts/' + buId,
      this.buildHeaders()
    );
  }

  validateDraftExistence(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'drafts/validate-existence/' + guid,
      this.buildHeaders()
    );
  }

  deleteUserDraft(id): Observable<any> {
    return this.http.delete(
      this.serviceUrl + 'drafts/' + id,
      this.buildHeaders()
    );
  }

  saveDraftBusinessUnit(request) {
    if (request.id !== undefined && request.id !== '') {
      return this.http.put(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    } else {
      return this.http.post(
        this.serviceUrl + 'drafts',
        request,
        this.buildHeaders()
      );
    }
  }

  listForUser(): Observable<IdAndName[]> {
    const header = new HttpHeaders().append('Content-type', 'application/json');
    return this.http.get<IdAndName[]>(
      this.serviceUrl + 'business-units/for-current-user',
      { headers: header }
    );
  }

  setCurrentBusinessUnit(id: number) {
    localStorage.currentBusinessUnitId = id.toString();
  }

  setAllBusinessUnits(BUs: any) {
    localStorage.allBusinessUnits = JSON.stringify(BUs);
  }

  getAllBusinessUnitsLocal(): any {
    if (localStorage.allBusinessUnits) {
      return JSON.parse(localStorage.allBusinessUnits);
    } else {
      return [];
    }
  }

  emitChanges(id: number) {
    this.setCurrentBusinessUnit(id);
    this.businessUnitSelected.emit(id);
  }

  getCurrentBusinessUnit(): string {
    return localStorage.currentBusinessUnitId || '0';
  }

  setAnimalWelfareBusinessUnit(id: any) {
    if (id.toString() === environment.settings.animalWelfareBU.toString()) {
      localStorage.animalWelfareBU = true;
    } else {
      localStorage.animalWelfareBU = false;
    }
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'business-units/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  getCurrentBusinessUnitPoolId(businessUnitId): string {
    const allBusinessUnitsArray = JSON.parse(localStorage.allBusinessUnits);
    const businessUnitWithThisId = allBusinessUnitsArray.find(
      (i) => i.id.toString() === businessUnitId.toString()
    );
    if (businessUnitWithThisId.poolAllocationId) {
      return businessUnitWithThisId.poolAllocationId;
    } else {
      return '';
    }
  }

  getExternallyCreatedRecordEditability(): boolean {
    const allBusinessUnitsArray = JSON.parse(localStorage.allBusinessUnits);
    return allBusinessUnitsArray.find(
      (i) => i.id.toString() === localStorage.currentBusinessUnitId.toString()
    ).editingOfExternalRecords;
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}

interface IdAndName {
  id: number;
  name: string;
}
