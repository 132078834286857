export const allocationDraftColumnsAW: string[] = [
  'select',
  'operationDate',
  'operationUser',
  'avisReference',
  'balance',
  'currency',
  'numberTransactions',
  'actions',
];
