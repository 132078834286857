export const particularitiesFields: any[] = [
  {
    name: 'countryRisks',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.COUNTRY-RISKS',
  },
  {
    name: 'bonusMalusRule',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.BONUS-MALUS-RULE',
  },
  {
    name: 'capPolicy',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.CAP-POLICY',
  },
  {
    name: 'reservationOfProprietaryRights',
    translateName:
      'TCI.CREATE.FORM-PARTICULARITIES-TAB.RESERVATION-OF-PROPRIETARY-RIGHTS',
  },
  {
    name: 'riskOfSelfCollection',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.RISK-SELF-COLLECTION',
  },
  {
    name: 'fabricationRisk',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.FABRICATION-RISK',
  },
  {
    name: 'policyType',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.POLICY-TYPE',
  },
  {
    name: 'gpc',
    translateName: 'TCI.CREATE.FORM-PARTICULARITIES-TAB.GPC',
  },
];
