import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-mat-slider',
  templateUrl: './mat-slider.component.html',
  styleUrls: ['./mat-slider.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatSliderComponent implements OnInit {
  @Input() name;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() disabled;
  @Input() textTransform;

  errorRequired = 'error-message-required';
  newValue: any;
  changedValue = false;

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    this.changedValue = this.hasChanged(this.controlName);
  }

  hasChanged(fieldName: string) {
    if (this.taskValue != null && this.approvedValue != null) {
      if (this.newValue && this.newValue[fieldName]) {
        return this.newValue[fieldName] !== this.approvedValue;
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (
      this.approvedValue != null &&
      this.newValue != null &&
      this.newValue[fieldName] != null
    ) {
      return this.approvedValue !== this.newValue[fieldName];
    } else {
      return false;
    }
  }
}
