export const dateFormatsEnum: any[] = [
  {
    code: 1,
    name: 'GERMAN',
    translateName: 'USER-PROFILE.SETTINGS.DATE-FORMATS.GERMAN',
  },
  {
    code: 2,
    name: 'BRITISH',
    translateName: 'USER-PROFILE.SETTINGS.DATE-FORMATS.BRITISH',
  },
  {
    code: 3,
    name: 'AMERICAN',
    translateName: 'USER-PROFILE.SETTINGS.DATE-FORMATS.AMERICAN',
  },
];
