import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, from, Observable } from 'rxjs';
import { DraftService } from '../services/draft/draft.service';
import { NotificationService } from '../services/notification/notification.service';
import { CustomErrorHandler } from '../utils/error-handler/custom-error-handler';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApprovalTaskInterceptor implements HttpInterceptor {
  constructor(
    private draftService: DraftService,
    private notificationService: NotificationService,
    private errorHandler: CustomErrorHandler,
    private translateService: TranslateService
  ) {}

  private static isTaskCreationNeeded(request): boolean {
    return request.method !== 'GET' && environment.isInternal;
  }

  private static transformRequestBody(requestBody) {
    if (!requestBody) {
      return;
    }
    return Array.isArray(requestBody) ? requestBody[0] : requestBody;
  }

  private static findEntityId(requestBody): string {
    if (requestBody) {
      if (requestBody.guid) {
        return requestBody.guid;
      } else if (requestBody.id) {
        return requestBody.id;
      } else if (requestBody.quantityGoods) {
        return requestBody.quantityGoods[0].id;
      } else {
        return;
      }
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleRequestWithCheckDraftExits(request, next));
  }

  async handleRequestWithCheckDraftExits(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (ApprovalTaskInterceptor.isTaskCreationNeeded(request)) {
      const requestBody = ApprovalTaskInterceptor.transformRequestBody(
        request.body
      );
      const entityId = ApprovalTaskInterceptor.findEntityId(requestBody);
      if (entityId) {
        const data = await this.makeRequest(entityId);
        if (data.error) {
          if (data.error.error) {
            this.errorHandler.handleError(
              data.error.error,
              this.translateService.instant('ERROR.GENERAL-ERROR')
            );
          } else {
            this.errorHandler.handleError(
              null,
              this.translateService.instant('ERROR.GENERAL-ERROR')
            );
          }
          return;
        }
        if (data === true) {
          this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
          return EMPTY.toPromise();
        } else {
          return next.handle(request).toPromise();
        }
      } else {
        return next.handle(request).toPromise();
      }
    } else {
      return next.handle(request).toPromise();
    }
  }

  async makeRequest(entityId) {
    const data = await this.draftService
      .approvalDraftExistsForEntityId(entityId)
      .toPromise()
      .catch((err) => {
        return err;
      });
    return data;
  }
}
