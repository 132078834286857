export const bankAccountColumnsConf: any[] = [
  {
    name: 'accountHolder',
    index: 0,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.ACCOUNT-HOLDER',
    locked: false,
  },
  {
    name: 'iban',
    index: 1,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.IBAN',
    locked: false,
  },
  {
    name: 'bic',
    index: 2,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.BIC',
    locked: false,
  },
  {
    name: 'bank',
    index: 3,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.BANK',
    locked: false,
  },
  {
    name: 'validFrom',
    index: 4,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.VALID-FROM',
    locked: false,
  },
  {
    name: 'validTo',
    index: 5,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.VALID-TO',
    locked: false,
  },
  {
    name: 'disbursementType',
    index: 6,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.DISBURSEMENT-TYPE',
    locked: false,
  },
  {
    name: 'currency',
    index: 7,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.CURRECY',
    locked: false,
  },
  {
    name: 'attachment',
    index: 8,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.ATTACHMENT',
    locked: false,
  },
  {
    name: 'isCreatedExternally',
    index: 9,
    checked: true,
    translateName: 'COMPANY.BANK-ACCOUNT.IS-CREATED-EXTERNALLY',
    locked: false,
  },
  {
    version: 2,
  },
];
