import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IdAndTask } from '../../models/id-task';
import { Platform } from '../../models/platform';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.companyService;
  }
  private serviceUrl: string;
  mapOfColumnFilters = [];
  private searchString = '';
  pageSize;
  sortDirection;
  sortOrder;
  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  create(platform: {
    platform: Platform;
    comment: string;
    draftId: string;
  }): Observable<IdAndTask> {
    return this.http.post<IdAndTask>(
      this.serviceUrl + 'platforms',
      platform,
      this.buildHeaders()
    );
  }

  update(platform: {
    platform: Platform;
    comment: string;
  }): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'platforms',
      platform,
      this.buildHeaders()
    );
  }

  updateDraft(platform: {
    taskId: number;
    platform: Platform;
    comment: string;
  }): Observable<IdAndTask> {
    return this.http.put<IdAndTask>(
      this.serviceUrl + 'platforms/drafts',
      platform,
      this.buildHeaders()
    );
  }

  retrieveByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/platform',
      this.buildHeaders()
    );
  }

  retrieveByClientId(clientId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'platforms/client/' + clientId,
      this.buildHeaders()
    );
  }

  retrieveApproved(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'platforms/' + id,
      this.buildHeaders()
    );
  }

  retrieveApprovedByGuid(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + `platforms/retrieveByGuid/${guid}`,
      this.buildHeaders()
    );
  }

  approveTask(id: number, comment: string): Observable<void> {
    const body = { comment };
    return this.http.post<void>(
      this.serviceUrl + 'platforms/drafts/' + id + '/approval',
      body,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string) {
    const body = { comment };
    return this.http.post(
      this.serviceUrl + 'platforms/drafts/' + id + '/rejection',
      body,
      this.buildHeaders()
    );
  }

  listApproved(
    companyId: number | null = null,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'platforms/filter';
    if (companyId !== null) {
      url += '?companyId=' + companyId;
    }
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  listApprovedByBU(businessUnitId): Observable<any> {
    const url = this.serviceUrl + 'platforms';

    return this.http.get<any>(url, this.buildHeadersBU(businessUnitId));
  }

  listForObjectionCode(objectionCode: number): Observable<Platform[]> {
    const url =
      this.serviceUrl +
      'platforms/objection-code?objectionCodeId=' +
      objectionCode;
    return this.http.get<Platform[]>(url, this.buildHeaders());
  }

  filter(
    companyId: number | null = null,
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'platforms/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'platforms/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  private buildHeadersBU(businessUnitId) {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', businessUnitId.toString()),
    };
  }
}
