export const statusEnum: any[] = [
  { code: 1, name: 'acquisition', translateName: 'CLIENT.ACQUISITION' },
  { code: 2, name: 'onboarding', translateName: 'CLIENT.ONBOARDING' },
  { code: 3, name: 'active', translateName: 'CLIENT.ACTIVE' },
  { code: 4, name: 'offboarding', translateName: 'CLIENT.OFFBOARDING' },
  { code: 5, name: 'offer', translateName: 'CLIENT.OFFER' },
  { code: 6, name: 'lost', translateName: 'CLIENT.LOST' },
  { code: 7, name: 'checkDocuments', translateName: 'CLIENT.CHECK-DOCUMENTS' },
  { code: 8, name: 'offerAccepted', translateName: 'CLIENT.OFFER-ACCEPTED' },
  {
    code: 9,
    name: 'createCreditProposal',
    translateName: 'CLIENT.CREATE-CREDIT-PROPOSAL',
  },
];
