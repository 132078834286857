import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-permission-configuration',
  templateUrl: './permission-configuration.component.html',
  styleUrls: ['./permission-configuration.component.less'],
})
export class PermissionConfigurationComponent implements OnInit {
  frmPermission;
  approvedValue;
  isTask = false;
  entityName = 'permission-configuration';
  task;
  taskValue;
  state;
  currentUser: any;
  @Input() realm;
  private internalPermission: boolean;
  finishLoaded = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog,
    private userService: UserService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private draftService: DraftService
  ) {
    this.formPermissionBuilder();
  }

  ngOnInit(): void {
    if (this.realm === 'external') {
      this.internalPermission = false;
    } else {
      this.internalPermission = true;
    }
    this.state = window.history.state;
    const snapshot = this.route.snapshot;
    if (snapshot.params && snapshot.params.guid && this.state.editMode) {
      if (!this.state.param.internalPermission) {
        this.userService
          .findPermission(snapshot.params.guid, 'external', this.state.viewMode)
          .pipe(first())
          .subscribe((data) => this.updateApprovedValueAndPermissionForm(data));
      } else {
        this.userService
          .findPermission(snapshot.params.guid, 'internal', this.state.viewMode)
          .pipe(first())
          .subscribe((data) => this.updateApprovedValueAndPermissionForm(data));
      }
    } else if (this.state.task) {
      this.task = window.history.state.task;
      this.draftService
        .retrieveDraftForTask(this.task.guid)
        .subscribe((data) => {
          const realm = data['internalPermission'] ? 'internal' : 'external';
          if (data.id) {
            this.userService
              .findPermission(data.id, realm, this.state.viewMode)
              .pipe(first())
              .subscribe((approvedData) => {
                this.approvedValue = approvedData;
              });
          }
          this.taskValue = data;
          this.isTask = true;
        });
    } else {
      this.getCuurentUserName();
      this.getCurrentDate();
    }
  }

  formPermissionBuilder() {
    this.frmPermission = this.formBuilder.group({
      permissionId: ['', Validators.required],
      permissionName: ['', Validators.required],
      description: [''],
      id: [{ value: '', disabled: true }],
      createdOn: [{ value: '', disabled: true }],
      createdBy: [{ value: '', disabled: true }],
      updatedOn: [{ value: '', disabled: true }],
      updatedBy: [{ value: '', disabled: true }],
    });
  }

  getCuurentUserName() {
    this.userService.getSettings().subscribe((data) => {
      this.currentUser = data.user.username;
      this.frmPermission.get('createdBy').patchValue(this.currentUser);
    });
  }

  getCurrentDate() {
    const currentDate = new Date();
    this.frmPermission.get('createdOn').patchValue(currentDate);
  }

  createPermission() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const requestJson = {
          comment: result.comment,
          permissionDto: {
            ...this.frmPermission.getRawValue(),
            ...{ internalPermission: this.internalPermission },
          },
        };

        this.userService.createPermission(requestJson).subscribe(
          (data) => {
            this.notificationService.showToast(
              'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              { data: this.translateService.instant('ENTITIES.PERMISSION') }
            );
            this.router.navigateByUrl('/userManagement/permission');
          },
          (error) => {
            if (error.status === 409) {
              this.notificationService.showToast(
                'NOTIFICATION.PERMISSION.EXISTS',
                this.notificationService.MESSAGE_TYPE.ERROR
              );
            }
          }
        );
      }
    });
  }

  private updateApprovedValueAndPermissionForm(data) {
    this.approvedValue = data;
    this.frmPermission.patchValue(data);
    this.frmPermission.get('permissionId').disable();
    if (this.state.viewMode) {
      this.frmPermission.disable();
    }
  }
}
