<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <h5>{{ 'TASK.FORWARD' | translate }}</h5>
    <form class="form" [formGroup]="userSelect" autocomplete="off">
      <div class="form-row">
        <mat-radio-group formControlName="forwardOption">
          <mat-radio-button id="{{ entityName }}-form-team-option" value="TEAM">{{
            'TASK.TEAM' | translate
          }}</mat-radio-button>
          <mat-radio-button id="{{ entityName }}-form-user-option" value="USER">{{
            'TASK.USER' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form-row" *ngIf="isUserOption()">
        <mat-form-field id="{{ entityName }}-form-select-user">
          <mat-label>{{ 'TASK.USER' | translate }}</mat-label>
          <input
            #user
            placeholder="{{ 'select' | translate }}"
            type="text"
            matInput
            formControlName="user"
            name="user"
            id="{{ entityName }}-form-select-user"
            [matAutocomplete]="auto"
            required
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let item of filteredUsers | async" [value]="item">
              {{ item.lastName }}, {{ item.firstName }} ({{ item.username }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="isTeamOption()">
        <mat-form-field id="{{ entityName }}-form-select-team">
          <mat-label>{{ 'TASK.TEAM' | translate }}</mat-label>
          <mat-select formControlName="team" placeholder="{{ 'select' | translate }}" name="team" required>
            <mat-option *ngFor="let item of teams" [value]="item">
              {{ item.translateName | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="buttons">
        <button id="{{ entityName }}-comment-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
          {{ 'cancel' | translate }}
        </button>
        <button
          id="{{ entityName }}-submit-button"
          mat-button
          class="custom-button"
          [disabled]="userSelect.invalid"
          [mat-dialog-close]="userSelect.value"
          style="margin-right: 0"
        >
          {{ 'forward' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
