<div class="dialog-content">
  <div [hidden]="noClients">
    <div class="filters" [formGroup]="filterForm">
      <div class="filter-input">
        <mat-form-field appearance="fill" id="{{ entityName }}-search-client">
          <mat-label>{{ 'CLIENT.SEARCH' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'CLIENT.SEARCH' | translate }}"
            name="name"
            formControlName="searchString"
            type="text"
            [removeHtmlTags]="filterForm.controls.searchString"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <table
          aria-hidden="true"
          (matSortChange)="sortData($event)"
          mat-table
          [dataSource]="dataSource"
          matSort
          id="{{ entityName }}-search-results-table"
          class="general-table-alignment"
        >
          <ng-container matColumnDef="select" *ngIf="!this.dialogData.debtor">
            <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let row; let element; let i = index"
              id="{{ entityName }}-table-results-checkbox-{{ paginator.pageIndex + 1 }}-{{ i }}"
            >
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selectClient(row, selection.isSelected(row)) : null"
                [aria-label]="checkboxLabel(row)"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="v25-th number-row-header id-column-old"
              (click)="paginator.firstPage()"
              id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
              scope="col"
            >
              ID
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
              class="number-row id-column-old"
            >
              {{ element.visibleId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="centralMasterDataRecord">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="v25-th"
              (click)="paginator.firstPage()"
              id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
              scope="col"
            >
              {{ 'name' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
            >
              {{ element.companyName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="platform">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="v25-th"
              (click)="paginator.firstPage()"
              id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
              scope="col"
            >
              {{ 'CLIENT.PLATFORM' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
            >
              {{ element.platformName }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            class="element-row"
            (click)="trclicked(element)"
          ></tr>
        </table>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>

  <div class="no-clients-container" [hidden]="!noClients">
    <div mat-dialog-content class="background-dark">
      <span>
        {{ 'CLIENT.ERROR-MESSAGES.NO-AVAILABLE-CLIENT' | translate }}
      </span>
    </div>
  </div>

  <div class="buttons">
    <button id="{{ entityName }}-client-modal-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
      {{ 'cancel' | translate }}
    </button>
    <button
      id="{{ entityName }}-client-modal-submit-button"
      mat-button
      class="custom-button"
      id="acquire"
      [disabled]="selection.isEmpty()"
      [mat-dialog-close]="data"
      style="margin-right: 0"
      *ngIf="!this.dialogData.debtor"
    >
      {{ 'DEBTOR.ACQUIRE' | translate }}
    </button>
  </div>
</div>
