export const bookOptionsBTCs: any[] = [
  {
    value: true,
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.BOOK.YES',
    checked: null,
  },
  {
    value: false,
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.BOOK.NO',
    checked: null,
  },
];
