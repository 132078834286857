import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { companyColumnsConf } from 'src/app/in-memory-data/company/table-columns-configuration';
import { companyColumns } from 'src/app/in-memory-data/company/table-columns';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SalesEntryService } from '../../../shared/services/animal-welfare/sales-entry/sales-entry.service';
import { LivestockProducerService } from 'src/app/shared/services/livestock-producer/livestock-producer.service';
import { Observable, Subject } from 'rxjs';
import { SepaMandateService } from 'src/app/shared/services/sepa-mandate/sepa-mandate.service';
import { KeycloakService } from 'keycloak-angular';
import { debounceTime } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { LiquidityPlanService } from '../../../shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { validate as isValidUUID } from 'uuid';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms')),
      transition('expanded <=> void', animate('150ms')),
    ]),
  ],
})
export class CompanyListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('appMenu') clickHoverMenuTrigger: MatMenuTrigger;
  @ViewChild('drawer') drawer: MatDrawer;
  entityName = 'company-list';
  columns = companyColumnsConf;
  displayedColumns = companyColumns;
  splitScreenList = [];
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'company_table';
  selection = new SelectionModel(true, []);
  countries: ICountry[];
  countries$: ICountry[];
  draftId: any;
  draftGuid: any;
  companyData = [];
  expandedCompany;
  dataSource = new MatTableDataSource<any>(this.companyData);
  originalDataSource;
  filter;
  companyName;
  searchInput: any = '';
  searchCountryTerm: any;
  platforms = [];
  clients = [];
  debtors = [];
  clientGroups = [];
  foodRetailTrader = [];
  gastronomy = [];
  livestockProducer = [];
  masterDataCompanyEntries = [];
  animalWelfareBU = true;
  disableOperatorButton = false;
  totalCount: number;
  pageSize = 20;
  sortedBy = 'visible_id';
  sortDirection = 'asc';
  subject = new Subject();
  loaderSpinner = false;

  currentLang = '';

  getFieldNames = (args): Observable<any> => {
    return this.companyService.getFilterFieldNameValues(args);
  };

  filterData = (choice, selection): void => {
    this.paginator.firstPage();
    this.loaderSpinner = true;

    this.companyService.setColumnFilter(choice, selection);
    this.subject.next(undefined);
  };

  sortTable = (sortEvent): void => {
    this.sortDirection = sortEvent?.direction;
    this.sortedBy = sortEvent?.choice;
    this.subject.next(undefined);
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private companyService: CompanyService,
    protected countryPicker: CountryPickerService,
    private translateService: TranslateService,
    private userService: UserService,
    private notificationService: NotificationService,
    private draftService: DraftService,
    private kcService: KeycloakService,
    private salesEntryService: SalesEntryService,
    private livestockProducerService: LivestockProducerService,
    private sepaMandateService: SepaMandateService,
    private matDialogService: MatDialog,
    private liquidityPlanService: LiquidityPlanService,
    private translationService: TranslationService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
      country: ['', []],
    });
    this.companyService.setSearchString('');
    this.companyService.setCountryFilter('');
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                companyColumnsConf,
                companyColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = companyColumnsConf;
    this.displayedColumns = companyColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.loaderSpinner = true;
    this.currentLang = this.translationService.getCurrentLanguage();
    this.countryPicker.getCountries().subscribe((data) => {
      this.countries = data;
      this.formatCountries();
      this.setToCountries(data);
    });
    this.translationService.currentSelectedLanguage.subscribe((lang) => {
      this.currentLang = lang;
      this.setToCountries(this.countries);
    });

    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loaderSpinner = true;
      this.companyService
        .getAllCompanies(
          this.sortedBy,
          this.sortDirection,
          this.paginator.pageIndex + 1,
          this.pageSize
        )
        .subscribe((data) => {
          this.loaderSpinner = false;
          this.originalDataSource = data.content;
          this.companyData = this.dataSource.data = data.content;
          this.totalCount = data.total;
        });
    });

    this.getCompanies();

    this.checkAnimalWelfareBU();
    this.checkIfExistsOperator();
  }

  getCompanies() {
    const page = this.paginator.pageIndex + 1;
    const pageSize = this.pageSize;
    const sortedBy = this.sortedBy;
    const sortDirection = this.sortDirection;
    this.companyService
      .getAllCompanies(sortedBy, sortDirection, page, pageSize)
      .subscribe((data) => {
        this.notificationService.dismissMessage();
        this.loaderSpinner = false;
        this.dataSource.data = data.content;
        this.originalDataSource = data.content;
        this.companyData = data.content;
        this.totalCount = data.total;
        this.getDrafts();
      });
    this.checkAnimalWelfareBU();
  }

  formatCountries() {
    this.countries.forEach((element) => {
      element['translateName'] = 'countries.' + element.cca3;
    });
  }

  getDrafts() {
    const entity = 'COMPANY';
    this.draftService.retrieveLocalDrafts(entity).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);
            object.draft = true;
            object.draftId = draft.id;
            object.draftGuid = draft.guid;
            object.operationUser = draft.operationUser;
            this.dataSource.data.unshift(object);
          });
          this.dataSource.filter = '';
        } else {
          this.showNoDataFound();
        }
      },
      (error) => {
        this.showNoDataFound();
      }
    );
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  createNew() {
    this.router.navigateByUrl('masterdata/company/create');
  }

  searchCompany() {
    this.loaderSpinner = true;
    const searchString =
      this.filter.get('searchString').value != null
        ? this.filter.get('searchString').value
        : '';
    this.companyService.setSearchString(searchString);
    this.paginator.firstPage();
    this.subject.next(undefined);
  }

  setCountryFilter() {
    const countryFilter =
      this.searchCountryTerm !== undefined ? this.searchCountryTerm.cca3 : '';
    this.companyService.setCountryFilter(countryFilter);
    this.paginator.firstPage();
    const page = this.paginator.pageIndex + 1;
    const pageSize = this.pageSize;
    const sortedBy = this.sortedBy;
    const sortDirection = this.sortDirection;
    this.companyService
      .getAllCompanies(sortedBy, sortDirection, page, pageSize)
      .subscribe((companyList) => {
        this.loaderSpinner = false;
        this.notificationService.dismissMessage();
        this.originalDataSource = companyList.content;
        this.companyData = this.dataSource.data = companyList.content;
        this.totalCount = companyList.total;
        this.getDrafts();
      });
  }

  resetCountry() {
    this.filter.value.country = undefined;
    this.filter.get('country').patchValue(undefined);
    this.companyService.setCountryFilter('');
    this.searchCompany();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  setToCountries(data: ICountry[]) {
    if (!data) {
      return;
    }
    if (!this.countries) {
      this.countries = data;
    }
    let translated;
    if (this.translateService.currentLang === 'de') {
      translated = data.sort((c1, c2) => {
        if (c1.translations.deu.common > c2.translations.deu.common) {
          return 1;
        }
        if (c1.translations.deu.common < c2.translations.deu.common) {
          return -1;
        }
        return 0;
      });
    } else {
      translated = data.sort((c1, c2) => {
        if (c1.name.common > c2.name.common) {
          return 1;
        }
        if (c1.name.common < c2.name.common) {
          return -1;
        }
        return 0;
      });
    }
    this.countries$ = translated;
  }

  edit(companyData) {
    const pageIndex = Number(this.paginator.pageIndex) + 1;
    const pageSize = Number(this.paginator.pageSize);

    if (this.kcService.isUserInRole('d.sm.r')) {
      this.sepaMandateService
        .getSepaMandatesByCompany(companyData.guid, pageIndex, pageSize)
        .subscribe(
          (data) => {
            const stateTemp = {
              company: companyData,
              listOfSepaMandates: data,
            };
            if (companyData.draft) {
              stateTemp['draftId'] = companyData.draftId;
              stateTemp['draftGuid'] = companyData.draftGuid;
              stateTemp['operationUser'] = companyData.operationUser;
            }
            this.router.navigateByUrl(
              'masterdata/company/create/' + companyData.guid,
              {
                state: stateTemp,
              }
            );
          },
          (error) => {
            const stateTemp = {
              company: companyData,
              listOfSepaMandates: [],
            };
            if (companyData.draft) {
              stateTemp['draftId'] = companyData.draftId;
              stateTemp['draftGuid'] = companyData.draftGuid;
              stateTemp['operationUser'] = companyData.operationUser;
            }
            this.router.navigateByUrl(
              'masterdata/company/create/' + companyData.guid,
              {
                state: stateTemp,
              }
            );
          }
        );
    } else {
      const stateTemp = {
        company: companyData,
      };
      if (companyData.draft) {
        stateTemp['draftId'] = companyData.draftId;
        stateTemp['draftGuid'] = companyData.draftGuid;
        stateTemp['operationUser'] = companyData.operationUser;
      }
      this.router.navigateByUrl(
        'masterdata/company/create/' + companyData.guid,
        {
          state: stateTemp,
        }
      );
    }
  }

  createClient(companyData) {
    this.router.navigateByUrl('/masterdata/client/create', {
      state: { company: companyData },
    });
  }

  createClientGroup(companyData) {
    this.router.navigateByUrl('/masterdata/clientGroup/create', {
      state: { company: companyData },
    });
  }

  getCountryName(code: string) {
    if (code != null) {
      if (!this.countries) {
        return code;
      }
      const country = this.countries.filter(
        (countryData) => countryData.cca3 === code
      )[0];
      if (country != null) {
        if (this.translateService.currentLang === 'de') {
          return country.translations.deu.common;
        }
        if (country) {
          return country.name.common;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }

  getCompanyName(element) {
    this.companyName = element.name;
  }

  getData(row) {
    this.getCompanyName(row);
    const companyGuid = row.guid;

    if (this.animalWelfareBU && isValidUUID(companyGuid)) {
      this.foodRetailTrader = [];
      this.gastronomy = [];
      this.livestockProducer = [];
      this.masterDataCompanyEntries = [];
      this.splitScreenList = [];

      this.companyService
        .getMasterDataCompanyEntries(companyGuid)
        .subscribe((data) => {
          data['masterDataCompanyEntries'] = data;
          this.masterDataCompanyEntries = data['masterDataCompanyEntries'];
          this.masterDataCompanyEntries.forEach((masterDataCompanyEntries) => {
            masterDataCompanyEntries.company =
              row.name +
              ', ' +
              row.address.street +
              ', ' +
              row.address.number +
              ', ' +
              row.address.zipCode +
              ', ' +
              row.address.city;
            const json = {
              company: row,
              type: 'masterDataCompanyEntries',
              id: masterDataCompanyEntries.id,
              productionType: masterDataCompanyEntries.productionType,
              locationNumber: masterDataCompanyEntries.locationNumber,
              level: masterDataCompanyEntries.level,
              object: masterDataCompanyEntries,
              companyGuid: row.guid,
              qsId: masterDataCompanyEntries.qsid,
              translateKey:
                'ANIMAL-WELFARE.MASTER-DATA-COMPANY-ENTRIES.LEVEL-WITH-QSID.' +
                masterDataCompanyEntries.level,
            };
            this.splitScreenList.push(json);
          });
        });

      this.livestockProducerService
        .getLivestockProducersForCompany(companyGuid)
        .subscribe((data) => {
          data['livestockProducer'] = data;
          this.livestockProducer = data['livestockProducer'];
          this.livestockProducer.forEach((livestockProducer) => {
            livestockProducer.company =
              row.name +
              ', ' +
              row.address.street +
              ', ' +
              row.address.number +
              ', ' +
              row.address.zipCode +
              ', ' +
              row.address.city;
            const json = {
              company: row,
              type: 'livestockProducer',
              id: livestockProducer.id,
              productionType: livestockProducer.productionType,
              locationNumber: livestockProducer.locationNumber,
              object: livestockProducer,
              companyGuid: row.guid,
              translateKey:
                'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.LIVESTOCK-PRODUCER',
            };
            this.splitScreenList.push(json);
          });
        });

      this.salesEntryService.findByCompany(companyGuid).subscribe((data) => {
        this.foodRetailTrader = data['frtList'];
        this.gastronomy = data['gastronomyList'];
        this.foodRetailTrader.forEach((frt) => {
          frt.company =
            row.name +
            ', ' +
            row.address.street +
            ', ' +
            row.address.number +
            ', ' +
            row.address.zipCode +
            ', ' +
            row.address.city;
          const json = {
            company: row,
            type: frt.type,
            id: frt.accountNumber,
            object: frt,
            companyGuid: row.guid,
            translateKey: 'ANIMAL-WELFARE.SALES-ENTRY.FRT',
          };
          this.splitScreenList.push(json);
        });

        this.gastronomy.forEach((gastronomy) => {
          gastronomy.company =
            row.name +
            ', ' +
            row.address.street +
            ', ' +
            row.address.number +
            ', ' +
            row.address.zipCode +
            ', ' +
            row.address.city;
          const json = {
            type: gastronomy.type,
            id: gastronomy.accountNumber,
            object: gastronomy,
            companyGuid: row.guid,
            translateKey: 'ANIMAL-WELFARE.SALES-ENTRY.GASTRONOMY',
          };
          this.splitScreenList.push(json);
        });
      });
    } else {
      this.platforms = [];
      this.clients = [];
      this.debtors = [];
      this.clientGroups = [];
      this.splitScreenList = [];

      this.companyService
        .getClientGroupsForCompany(row.guid)
        .subscribe((clientGroups) => {
          this.clientGroups = clientGroups;
          clientGroups.forEach((clientGroup) => {
            const json = {
              type: 'clientGroup',
              id: clientGroup.id,
              visibleId: Number(clientGroup.visibleId),
              name: clientGroup.name,
              object: clientGroup,
              translateKey: 'companySidebar.clientGroup',
              companyGuid: clientGroup.companyGuid,
            };
            this.splitScreenList.push(json);
          });
        });

      this.companyService.getBoundEntities(row.guid).subscribe((data) => {
        this.platforms = data.platforms;
        this.clients = data.clients;
        this.debtors = data.debtors;

        data.platforms.forEach((platform) => {
          const json = {
            type: 'platform',
            id: platform['visibleId'],
            dbId: platform['id'],
            name: platform['name'],
            object: platform,
            translateKey: 'companySidebar.platform',
            companyGuid: platform['companyGuid'],
          };
          this.splitScreenList.push(json);
        });

        data.clients.forEach((client) => {
          const json = {
            type: 'client',
            id: client.visibleId,
            dbId: client.id,
            name: '',
            object: client,
            translateKey: 'companySidebar.client',
            companyGuid: client.companyGuid,
          };
          this.splitScreenList.push(json);
        });

        data.debtors.forEach((debtor) => {
          const json = {
            type: 'debtor',
            id: debtor.visibleId,
            name: '',
            object: debtor,
            translateKey: 'companySidebar.debtor',
            companyGuid: debtor.companyGuid,
          };
          this.splitScreenList.push(json);
        });
      });
    }
    this.drawer.open();
    const element = document.getElementsByClassName('sort-image');
    const array = Array.prototype.slice.call(element);

    for (const item of array) {
      item.setAttribute(
        'src',
        '../../../../assets/fonts/custom-icons/icons/ic_sort.svg'
      );
    }
  }

  getAddressString(element) {
    return (
      element.name +
      ', ' +
      element.address.street +
      ', ' +
      element.address.number +
      ', ' +
      element.address.zipCode +
      ', ' +
      element.address.city
    );
  }
  searchCountry = (term: string, item: ICountry) => {
    term = term.toLocaleLowerCase();
    if (this.translateService.currentLang === 'de') {
      return (
        item.translations.deu.official.toLocaleLowerCase().indexOf(term) > -1
      );
    }
    return item.name.official.toLocaleLowerCase().indexOf(term) > -1;
  };

  checkAnimalWelfareBU() {
    const isTrueSet = localStorage.animalWelfareBU === 'true';
    if (isTrueSet) {
      this.animalWelfareBU = true;
    } else {
      this.animalWelfareBU = false;
    }
  }

  checkIfExistsOperator() {
    this.liquidityPlanService.checkIfExistsOperator().subscribe((operator) => {
      if (operator) {
        this.disableOperatorButton = true;
      }
    });
  }

  createFoodRetailTrader(item) {
    const stateTemp = { company: item };
    stateTemp['contractType'] = 'FRT';

    this.router.navigateByUrl('/masterdata/salesEntry/create', {
      state: stateTemp,
    });
  }

  createGastronomy(item) {
    const stateTemp = { company: item };
    stateTemp['contractType'] = 'GASTRONOMY';

    this.router.navigateByUrl('/masterdata/salesEntry/create', {
      state: stateTemp,
    });
  }

  createLinkOperator(item) {
    const dialog = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'ANIMAL-WELFARE.SPECIAL-NOTIFICATIONS.UI-1003-ID-1'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.liquidityPlanService.createOperatorDraft(item.guid).subscribe(
          (value) => {
            this.disableOperatorButton = true;
            this.notificationService.showToast(
              'ANIMAL-WELFARE.OPERATOR.APPROVAL-TASK-NOTIFICATION',
              this.notificationService.MESSAGE_TYPE.SUCCESS
            );
          },
          (error) => {
            this.notificationService.showToast(
              'ERROR-MESSAGES.ERROR-BACKEND',
              this.notificationService.MESSAGE_TYPE.ERROR,
              {
                name: error.error.errorId ? error.error.errorId : 'unknown',
                error: error.message,
              }
            );
          }
        );
      }
    });
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    this.getCompanies();
  }
}
