<div class="welcome-container">
  <div class="full-height" >
      <div class="row centered-row">
        <div class="col-12 text-container">
          <div class="text-big no-margin-top">
            <span>{{ 'HOMEPAGE.WELCOME.TEXT-1' | translate }}</span>
            <span class="bold-text">{{ 'HOMEPAGE.WELCOME.BE-VISION' | translate }}</span>
          </div>
          <div class="text-normal">{{ 'HOMEPAGE.WELCOME.TEXT-2' | translate }}</div>
          <div class="text-normal">{{ 'HOMEPAGE.WELCOME.TEXT-3' | translate }}</div>
          <div class="text-normal">{{ 'HOMEPAGE.WELCOME.TEXT-4' | translate }}</div>
          <div class="text-normal">{{ 'HOMEPAGE.WELCOME.TEXT-5' | translate }}</div>
          <div class="text-warning" *ngIf="warningDisplayed">
            {{
              'HOMEPAGE.WELCOME.TEXT-6'
                | translate
                  : {
                      date: errorDate | date: dateFormat,
                      hourStart: hourStart | date: 'HH:mm',
                      hourEnd: hourEnd | date: 'HH:mm'
                    }
            }}
          </div>
        </div>
      </div>
    </div>
</div>
