export const typeOptions: any[] = [
  {
    value: 'PIG',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PIG',
    availableIn: ['salesFigures', 'slaughterhouseFigures'],
  },
  {
    value: 'POULTRY',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.POULTRY',
    availableIn: ['salesFigures', 'slaughterhouseFigures'],
  },
  {
    value: 'CATTLE',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CATTLE',
    availableIn: ['salesFigures'],
  },
];
