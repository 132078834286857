export const openItemObjectionCode = [
  '001',
  '002',
  '003',
  '004',
  '005',
  '006',
  '007',
  '008',
  '009',
  '010',
  '011',
  '012',
  '013',
  '014',
  '015',
  '016',
  '017',
  '018',
  '019',
  '020',
  '021',
  '023',
  '024',
  '025',
  '026',
  '027',
  '028',
  '029',
  '030',
  '031',
  '032',
  '033',
  '034',
  '036',
  '037',
  '038',
  '039',
  '040',
  '041',
  '045',
  '050',
  '057',
  '058',
  '060',
  '061',
  '084',
  '085',
  '086',
  '087',
  '088',
  '089',
  '111',
  '222',
  '996',
];
