<div class="task-buttons-container">
  <div class="left">
    <ng-container *hasPermission="'tm.ul'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.UNLOCK)"
        id="unlock-button"
        *ngIf="availableButtons.UNLOCK"
      >
        <mat-icon>lock_open</mat-icon>
        {{ 'TASK.UNLOCK' | translate }}
      </button>
    </ng-container>
  </div>
  <div class="right">
    <ng-container *hasPermission="'tm.ft'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.FORWARD)"
        id="forward-button"
        *ngIf="availableButtons.FORWARD"
      >
        {{ 'TASK.FORWARD' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.uf.c'">
      <button
        *ngIf="task"
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.UPLOAD)"
        id="upload-button"
      >
        {{ 'UPLOAD-FILE' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.START)"
        id="start-button"
        *ngIf="availableButtons.START"
      >
        {{ 'TASK.START-TASK' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.MODIFY)"
        id="modify-button"
        *ngIf="availableButtons.MODIFY"
      >
        {{ 'TASK.MODIFY-TASK' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.REJECT)"
        id="reject-button"
        *ngIf="availableButtons.REJECT"
      >
        {{ 'GENERAL.REJECT' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        mat-button
        class="custom-button"
        (click)="buttomClick(taskButtons.COMPLETE)"
        id="approve-button"
        *ngIf="availableButtons.COMPLETE"
      >
        {{ 'GENERAL.ACCEPT' | translate }}
      </button>
    </ng-container>
  </div>
</div>
