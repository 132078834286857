import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DefaultTableConfiguration } from './table-configuration';
import { SelectionModel } from '@angular/cdk/collections';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification/notification.service';
import { TablesService } from '../tables.service';
import { UserService } from '../../services/user/user.service';
import { Subscription } from 'rxjs';
import { MyErrorStateMatcher } from './custom-error-state-matcher.component';
import { SharedDataService } from 'src/app/modules/open-transaction-booking-management/services/shared-data.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

const moment = _rollupMoment || _moment;

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-default-editable-table',
  templateUrl: './default-editable-table.component.html',
  styleUrls: ['./default-editable-table.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class DefaultEditableTableComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() entityName: string;
  @Input() displayedColumns: string[];
  @Input() columns: string[];
  @Input() tableFieldsConfiguration: DefaultTableConfiguration[];
  @Input() getNewObject: (index) => any;
  @Input() getFormRow: (object: any) => UntypedFormGroup;
  @Input() form: UntypedFormGroup;
  @Input() entities: any[] = [];
  @Input() mode: 'CREATE' | 'UPDATE';
  @Input() tableKey: string;
  @Input() listOfActionsButtons: any;
  @Input() sharedDataService;

  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel(true, []);
  deletedRowsIndexes: any[] = [];
  index = 0;
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];
  displayedColumnsTemp = [];
  columnsTemp = [];
  state;
  formArrayVar;
  editableTable = true;
  loaderSpinner: boolean;
  loaderScroll: boolean;
  oldFormGroup: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  tableState = false;
  errorMatcher = new MyErrorStateMatcher();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private tableService: TablesService,
    private userService: UserService,
    private sharedDataServiceBooking: SharedDataService
  ) {
    this.listenToSubscriptions();
  }

  ngOnDestroy(): void {
    this.tableService.suscriptionEmitter.unsubscribe();
    this.tableService.suscriptionClearTableEmitter.unsubscribe();
    this.tableService.suscriptionPatchSelectedRowsEmitter.unsubscribe();
  }

  ngOnInit() {
    this.state = window.history.state;
    this.tableService.currentSharedSelectedRows.subscribe((data) => {
      if (data == null) {
        this.selection.clear();
      }
    });
    this.getTableConfiguration();
    this.formArrayVar = this.form.get('objects') as UntypedFormArray;
    this.loadData();
    this.updateDataSource();
  }

  loadData() {
    if (this.entities.length < 1) {
      this.addEmptyRow();
      this.formChanges();
    } else {
      this.addRowFormEntity(this.entities);
    }
  }

  listenToEditMode() {
    this.tableService.currentSharedForm.subscribe((data) => {
      if (data !== undefined && data !== null) {
        if (data.disable) {
          this.formArrayVar.disable();
          this.editableTable = false;
        } else {
          this.formArrayVar.enable();
          this.editableTable = true;
        }
      }
    });
  }

  listenToSubscriptions() {
    this.tableService.suscriptionEmitter =
      this.tableService.eventEmiterFunction.subscribe((data) => {
        this.addNewLines(data);
      });

    this.tableService.suscriptionClearTableEmitter =
      this.tableService.clearTableFunction.subscribe((data) => {
        this.clearTable();
      });

    this.tableService.suscriptionPatchSelectedRowsEmitter =
      this.tableService.patchSelectedRowsFunction.subscribe((data) => {
        this.patchSelectedRows(data);
      });

    this.tableService.subscriptionResetOriginalDataEmitter =
      this.tableService.resetOriginalDataFunction.subscribe((data) => {
        this.patchOriginalData(data);
      });

    this.tableService.currentSharedSpinnerState.subscribe(
      (sharedSpinnerState) => (this.loaderSpinner = sharedSpinnerState)
    );

    this.tableService.currentSharedScrollSpinnerState.subscribe(
      (sharedScrollSpinnerState) =>
        (this.loaderScroll = sharedScrollSpinnerState)
    );

    this.tableService.currentSharedForm.subscribe(
      (tableState) => (this.tableState = tableState)
    );
  }

  trackByFunction(index, item) {
    if (!item) {
      return null;
    }
    return index;
  }

  clearTable() {
    this.index = 0;
    this.dataSource = new MatTableDataSource<any>([]);
    this.tableService.updateSharedDataSource(this.dataSource);
    this.tableService.updateSharedSelectedRows(null);
    this.selection = new SelectionModel(true, []);
    this.formArrayVar = null;
    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.formArrayVar = this.form.get('objects') as UntypedFormArray;
      this.addEmptyRow();
      this.formChanges();
      this.tableService.updateSharedSpinnerState(false);
      this.tableService.changesInForm(null);
    }, 2000);
  }

  formChanges() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
    this.formArrayVar.controls.forEach((control) => {
      this.checkChangesInForm(control.value);
    });
  }

  selectRow(element) {
    this.selection.select(element);
  }

  isMoreThanOneSelected() {
    const numSelected = this.selection.selected.length;
    if (numSelected > 1) {
      return true;
    } else {
      return false;
    }
  }

  selectTheRow(row: any, isSelected: any) {
    if (this.selection.selected.length === 0) {
      this.toggleRow(row, isSelected);
    } else if (this.selection.selected.length > 0) {
      this.selection.clear();
      this.selectTheRow(row, false);
    }

    this.changeSharedSelectedRows();
  }

  toggleRow(row: any, isSelected: any) {
    if (!isSelected) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.changeSharedSelectedRows();
  }

  changeSharedSelectedRows() {
    this.tableService.updateSharedSelectedRows(this.selection.selected);
  }

  checkChangesInForm(data) {
    const formGroup: UntypedFormGroup = this.formArrayVar.controls[data.index];

    Object.keys(formGroup.controls).forEach((key) => {
      this.subscriptions.push(
        formGroup.get(key).valueChanges.subscribe(() => {
          let subField;
          let field = this.tableFieldsConfiguration.find(
            (fieldP) => fieldP.columnName === key
          );

          if (!field) {
            const fieldName = key.replace('Selection', '');

            field = this.tableFieldsConfiguration.find(
              (fieldP) => fieldP.columnName === fieldName
            );

            if (field && field['subControls']?.length > 0) {
              subField = field['subControls'][formGroup.get('index').value];
            }
          }

          const dataSend = {
            formGroup,
            key,
            field,
            subField,
            value: formGroup.get(key).value,
          };
          this.tableService.changesInForm(dataSend);
        })
      );
      this.sharedDataService.updateDataServiceInfo(this.formArrayVar);
    });
  }

  showInfiniteScroll(field, element, $event, scroll) {
    this.tableService.updateScrollSpinnerState(true);
    if ($event) {
      const formGroup: UntypedFormGroup = this.formArrayVar.controls[element.index];
      const object = {
        formGroup,
        field,
        scroll,
      };
      this.tableService.changesInfiniteScroll(object);
    }
  }

  openSearchDialog(element) {
    const formGroup: UntypedFormGroup = this.formArrayVar.controls[element.index];
    this.tableService.openInsideSearch(formGroup);
  }

  keydownFunction(
    field: any,
    formGroup?: UntypedFormGroup,
    columnName?: string,
    $event?: Event
  ) {
    switch (field.keyDownSpecialFunction) {
      case 'NEW_LINE':
        this.keydownFunctionNewLine(field);
        break;
      case 'NO_EMPTY':
        this.keydownFunctionNoEmpty(field, formGroup, columnName, $event);
        break;
      default:
      // Do nothing.
    }
  }

  keydownFunctionNewLine(field: any) {
    const columnIndex = this.displayedColumns.findIndex(
      (column) => column === field.columnName
    );
    if (columnIndex === this.displayedColumns.length - 2) {
      this.addEmptyRow();
      this.formChanges();
      this.addSubControlToConfigurations();
    }
  }

  keydownFunctionNoEmpty(
    field: any,
    formGroup: UntypedFormGroup,
    columnName: string,
    $event: Event
  ) {
    // TODO process numbers the correct way.
    if (
      field.min === 0 &&
      columnName &&
      Number(formGroup.get(columnName).value).toString().length === 1
    ) {
      formGroup.get(columnName).patchValue(0);
      $event.preventDefault();
      this.changeDetectorRef.detectChanges();
    }
  }

  checkRules(fieldConfig: { rules: string[] }, $event: any) {
    if (!fieldConfig.rules) {
      return;
    }
    if (this.specialKeys.indexOf($event.key) !== -1) {
      return;
    }

    fieldConfig.rules.forEach((rule) => {
      if (rule === 'TWO_DECIMALS') {
        // TODO process numbers the correct way
        const numbers = $event.target.value.split('.');
        if (numbers.length > 1 && numbers[1].length === 2) {
          $event.preventDefault();
        }
      }
    });
  }

  addNewLines(data) {
    if (data && data > 0) {
      for (let i = 0; i < data; i++) {
        this.addEmptyRow();
        this.formChanges();
        this.addSubControlToConfigurations();
        if (i === data - 1) {
          this.tableService.updateSharedSpinnerState(false);
        }
      }
    } else {
      setTimeout(() => {
        this.tableService.updateSharedSpinnerState(false);
      }, 500);
    }
  }

  addSubControlToConfigurations() {
    this.tableFieldsConfiguration.forEach((fieldConf) => {
      if (fieldConf.type === 'selectInfiniteScroll') {
        const object = {
          pageIndex: 0,
          pageSize: 25,
          subControlName: fieldConf['subControls'][0]['subControlName'],
          selectedOption: null,
          showValue: fieldConf['subControls'][0]['showValue'],
          rowNumber: fieldConf['subControls'].length,
          options: [],
        };

        fieldConf['subControls'].push(object);
      }
    });
  }

  patchSelectedRows(data) {
    this.selection.selected.forEach((row) => {
      const formGroup = this.formArrayVar.controls[row.index];

      Object.keys(data).forEach((key, index) => {
        if (data[key] !== '' && data[key] != null && data[key] !== undefined) {
          formGroup.get(key).patchValue(data[key], { emitEvent: false });
        }
      });

      this.patchInfiniteScrollOptions(formGroup, data);
      this.changeDetectorRef.detectChanges();
    });
  }

  patchInfiniteScrollOptions(formGroup: UntypedFormGroup, data) {
    this.tableFieldsConfiguration.forEach((conf) => {
      if (conf.type === 'selectInfiniteScroll') {
        if (
          data[conf.columnName] !== '' &&
          data[conf.columnName] !== null &&
          data[conf.columnName] !== undefined
        ) {
          conf['subControls'][formGroup.get('index').value]['options'].push(
            data[conf['columnName']]
          );
        }
      }
    });
  }

  patchOriginalData(data) {
    if (data.length > 0) {
      data.forEach((openTransaction) => {
        this.patchTransaction(openTransaction.data);
      });
    }
  }

  patchTransaction(openTransaction) {
    const formGroup: UntypedFormGroup =
      this.formArrayVar.controls[openTransaction.index];
    formGroup.patchValue(openTransaction);
    this.changeDetectorRef.detectChanges();
  }

  getOptionValue(option, field) {
    if (field.optionValue) {
      return option[field.optionValue];
    } else if (option.value != null || option.value !== undefined) {
      return option.value;
    } else {
      return option;
    }
  }

  getOptionDisplayProperty(option, field) {
    if (field.displayOption) {
      let displayText = option[field.displayOption];
      if (field.formatDisplayOption) {
        displayText = field.formatDisplayOption(displayText);
      }
      return displayText;
    } else if (option.translateName) {
      return option.translateName;
    } else {
      return option;
    }
  }

  getFieldSubControlName(field, i) {
    if (
      field.subControls &&
      field.subControls[i] &&
      field.subControls[i].subControlName
    ) {
      return (
        field.subControls[i].subControlName ??
        field.subControls[i].subControlName
      );
    }
  }

  getOptionDisplayValues(option, field, i) {
    if (field && !Array.isArray(field.subControls[i].showValue)) {
      if (option[field.subControls[i].showValue]) {
        return option[field.subControls[i].showValue];
      }
    } else if (field && Array.isArray(field.subControls[i].showValue)) {
      let result = '';
      field.subControls[i].showValue.forEach((element) => {
        if (option[element]) {
          result += option[element] + ' ';
        }
      });

      return result;
    }
    return option;
  }

  addRowFormEntity(entities) {
    let i = 0;

    for (const entityObject of entities) {
      const entity = entityObject.data;
      entity.index = i;
      this.dataSource.data.push(entity);
      const formRow = this.getFormRow(entity);

      this.formArrayVar.push(formRow);
      this.formChanges();
      this.addSubControlToConfigurations();
      formRow.patchValue(entity);

      if (this.tableState) {
        formRow.disable({ emitEvent: false });
      }
      this.dataSource.filter = '';
      i++;
    }
  }

  reloadEditableList() {
    this.formArrayVar.clear();
    this.dataSource.data = [];
    this.addRowFormEntity(this.entities);
    this.dataSource._updateChangeSubscription();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  addEmptyRow() {
    const object = this.getNewObject(this.index);
    this.dataSource.data.push(object);
    this.addFormRow(object);
    this.index++;
    this.dataSource.filter = '';
  }

  addFormRow(object) {
    this.formArrayVar.push(this.getFormRow(object));
    this.formArrayVar.controls[
      this.formArrayVar.length - 1
    ].updateValueAndValidity();
    this.form.updateValueAndValidity();

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }

  showIcon(element) {
    const fg = this.formArrayVar.controls[element.index];
    return fg.get('icon').value;
  }

  isFieldRequired(element, fieldName: string) {
    const formGroup: UntypedFormGroup = this.formArrayVar.controls[element.index];
    if (formGroup) {
      const control = formGroup.get(fieldName);
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (!validator) {
          return false;
        }
        return validator.required;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  chosenYearHandler(normalizedYear: Moment, element, fieldName) {
    let formGroup: any = null;
    formGroup = this.formArrayVar.controls[element.index];

    formGroup.get(fieldName).setValue(moment());
    const ctrlValue = formGroup.get(fieldName).value;
    ctrlValue.year(normalizedYear.year());
    formGroup.get(fieldName).setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker, element, fieldName) {
    let formGroup: any = null;
    formGroup = this.formArrayVar.controls[element.index];
    const ctrlValue = formGroup.get(fieldName).value;
    ctrlValue.month(normalizedMonth.month());
    formGroup.get(fieldName).setValue(ctrlValue);
    formGroup.get(fieldName).patchValue(ctrlValue);
    datepicker.close();
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ANIMAL-WELFARE.PARTICIPATION-FEE.FUND-DEPOSITS'
        ),
      }
    );
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.formArrayVar.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        let removed = false;
        if (
          this.formArrayVar.value[i] &&
          item === this.formArrayVar.value[i].index &&
          !removed
        ) {
          this.formArrayVar.removeAt(i);
          removed = true;
        }
      });
    }
  }

  getErrors(element, columnName: string, fg: UntypedFormGroup) {
    const formGroup = fg;
    const currentControl = formGroup.get(columnName);

    if (currentControl.errors) {
      return Object.keys(currentControl.errors);
    }
    return [];
  }

  getSpecialErrors(element, columnName: string) {
    const result = [];
    const formGroup: UntypedFormGroup = this.formArrayVar.controls[element.index];
    if (formGroup.errors) {
      const fieldConf = this.tableFieldsConfiguration.find(
        (conf) => conf.columnName === columnName
      );

      if (fieldConf['formError'] && fieldConf['columnName'] === columnName) {
        if (formGroup.errors[fieldConf['formError']]) {
          result.push(fieldConf['formError']);
        }
      }
    }
    return result;
  }

  showError(field, error, element, index) {
    let result = false;
    if (
      field &&
      field.columnName === this.tableFieldsConfiguration[index].columnName &&
      field.formError &&
      field.formError === error
    ) {
      result = true;
    }

    return result;
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    this.tableService.setDisplayedColumns(this.displayedColumns);
    const configuration = {
      key: this.tableKey,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.tableKey).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.tableKey,
                data,
                this.columns,
                this.displayedColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.tableService.setDisplayedColumns(this.displayedColumns);
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  /**
   * This method subscribe to changes in currentDataSet.
   * This method was created to be triggered when there is a change in the table rows
   * performed through the form.
   *
   * Why?
   *
   * The responsible for the form is not the editable table (a generic component), the owner of the form
   * should be responsible to control these changes.
   *
   * 1. Set the current data to the dataSource.data (required by the material table);
   * 2. Set the current formArrayVar to get the current FormGroup objectcs;
   * 3. Update the current index to avoid issues on add new lines;
   */
  updateDataSource() {
    this.sharedDataServiceBooking.currentDataSet.subscribe((data) => {
      if (!!data) {
        this.dataSource.data = data;
        this.formArrayVar = this.form.get('objects') as UntypedFormArray;
        this.index = data.length;
      }
    });
  }
}
