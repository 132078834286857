export const systemDefaultLanguages: any[] = [
  {
    name: 'english',
    code: 'en',
  },
  {
    name: 'german',
    code: 'de',
  },
];
