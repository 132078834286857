export enum TableTypes {
  STRING = 'string',
  CURRENCY = 'currency',
  DATE = 'date',
  NUMBER = 'number',
  TRANSLATED_STRING = 'translatedString',
  ICON = 'icon',
  PERCENT = 'percent',
  SLIDER = 'slider',
  BOOLEAN = 'boolean',
  MULTI_TRANSLATED_STRING= 'multiTranslatedString',
}
