import { NgModule } from '@angular/core';
import { BackButtonComponent } from './back-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { DismissChangesDialogComponent } from '../dismiss-changes-dialog/dismiss-changes-dialog.component';
import { DismissChangesDialogModule } from '../dismiss-changes-dialog/dismiss-changes-dialog.module';

@NgModule({
    declarations: [BackButtonComponent],
    imports: [
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        DismissChangesDialogModule,
        MatIconModule,
    ],
    exports: [BackButtonComponent]
})
export class BackButtonModule {}
