import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'sortBy',
  pure: false,
})
export class SortByPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any[], criteria: SortCriteria): any[] {
    if (!value || !criteria) {
      return value;
    }
    const property: string = criteria.property;
    if (criteria.noSort) {
      return value;
    }
    if (property) {
      const usedProperty = property.split('.');
      if (usedProperty.length > 1) {
        const sortFn: (a: any, b: any) => any = (a, b) => {
          let valueTemp = 0;
          if (
            a[usedProperty[0]][usedProperty[1]] === undefined ||
            this.translateService.instant(
              a[usedProperty[0]][usedProperty[1]]
            ) === undefined
          ) {
            valueTemp = -1;
          } else if (
            b[usedProperty[0]][usedProperty[1]] === undefined ||
            this.translateService.instant(
              b[usedProperty[0]][usedProperty[1]]
            ) === undefined
          ) {
            valueTemp = 1;
          } else {
            valueTemp =
              this.translateService.instant(
                a[usedProperty[0]][usedProperty[1]]
              ) >
              this.translateService.instant(b[usedProperty[0]][usedProperty[1]])
                ? 1
                : this.translateService.instant(
                    b[usedProperty[0]][usedProperty[1]]
                  ) >
                  this.translateService.instant(
                    a[usedProperty[0]][usedProperty[1]]
                  )
                ? -1
                : 0;
          }
          return criteria.descending ? valueTemp * -1 : valueTemp;
        };
        value.sort(sortFn);
      } else {
        const sortFn: (a: any, b: any) => any = (a, b) => {
          let valueTemp = 0;
          if (
            a[property] === undefined ||
            this.translateService.instant(a[property].toString()) === undefined
          ) {
            valueTemp = -1;
          } else if (
            b[property] === undefined ||
            this.translateService.instant(b[property].toString()) === undefined
          ) {
            valueTemp = 1;
          } else {
            valueTemp =
              this.translateService.instant(a[property].toString()) >
              this.translateService.instant(b[property].toString())
                ? 1
                : this.translateService.instant(b[property].toString()) >
                  this.translateService.instant(a[property].toString())
                ? -1
                : 0;
          }
          return criteria.descending ? valueTemp * -1 : valueTemp;
        };
        value.sort(sortFn);
      }
    } else {
      const sortFn: (a: any, b: any) => any = (a, b) => {
        let valueTemp = 0;
        if (a === undefined || this.translateService.instant(a) === undefined) {
          valueTemp = -1;
        } else if (
          b === undefined ||
          this.translateService.instant(b) === undefined
        ) {
          valueTemp = 1;
        } else {
          valueTemp =
            this.translateService.instant(a) > this.translateService.instant(b)
              ? 1
              : this.translateService.instant(b) >
                this.translateService.instant(a)
              ? -1
              : 0;
        }
        return criteria.descending ? valueTemp * -1 : valueTemp;
      };
      value.sort(sortFn);
    }
    return value;
  }
}

export interface SortCriteria {
  property: string;
  descending?: boolean;
  noSort?: boolean;
}
