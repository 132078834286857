import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-system-configuration-management',
  templateUrl: './system-configuration-management.component.html',
  styleUrls: ['./system-configuration-management.component.less'],
})
export class SystemConfigurationManagementComponent implements OnInit {
  entityName = 'system-configuration-management';

  constructor(
    private kcService: KeycloakService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this.navigateToAccessible();
  }

  navigateToAccessible() {
    if (!this.route.firstChild.component) {
      const baseRoutes = this.route.routeConfig.children.filter((el) =>
        this.canNavigate(el)
      );

      if (baseRoutes.length === 0) {
        this.router.navigateByUrl('/');
      } else {
        if (baseRoutes[0].children && baseRoutes[0].children.length > 0) {
          const childrenRoutes = baseRoutes[0].children.filter((el) =>
            this.canNavigate(el)
          );

          if (childrenRoutes.length > 0) {
            this.router.navigateByUrl(
              '/systemConfiguration/' +
                baseRoutes[0].path +
                '/' +
                childrenRoutes[0].path
            );
          } else {
            this.router.navigateByUrl('/');
          }
        } else {
          this.router.navigateByUrl(
            '/systemConfiguration/' + baseRoutes[0].path
          );
        }
      }
    }
  }

  canNavigate(el: Route): unknown {
    if (el.path === '') {
      return false;
    }
    if ((el.path.match(/\//g) || []).length > 0) {
      return false;
    }

    if (el && el.data) {
      return el.data.roles.some((role) => this.kcService.isUserInRole(role));
    } else {
      return true;
    }
  }

  hasRoleForPath(route: string) {
    const currentRoute = this.route.routeConfig.children.find(
      (el) => el.path === route
    );
    if (!currentRoute) {
      return false;
    }
    return currentRoute.data.roles.some((role) =>
      this.kcService.isUserInRole(role)
    );
  }
}
