export const nonGermanyReferenceAllowCountriesColumns: string[] = [
  'select',
  'country',
  'countryCode',
  'decisionCompliance',
  'decisionComplianceReason',
  'decisionRisk',
  'decisionRiskReason',
  'actions',
];
