import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountKpiService {
  // Original KPIs
  private originalKpis = new BehaviorSubject<any>('');
  updateOringialKpis = this.originalKpis.asObservable();

  setOriginalKpis(data: any) {
    this.originalKpis.next(data);
  }
}
