import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardStateService {
  public activeTab = 0;
  public configs = [];

  initConfig = (table: string) => {
    this.configs[table] = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: '20',
      pageIndex: '0',
      filteredValues: [],
    };
  };
}
