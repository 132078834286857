import {
  AfterViewInit,
  Input,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { blockingIndicatorListColumns } from 'src/app/in-memory-data/animal-welfare/blocking_indicator/table-columns';
import { blockingIndicatorListColumnsConf } from 'src/app/in-memory-data/animal-welfare/blocking_indicator/table-columns-configuration';
import { BlockingIndicatorService } from 'src/app/shared/services/animal-welfare/blocking_indicator/blocking-indicator.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { debounceTime } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-livestock-blocking-indicators',
  templateUrl: './livestock-blocking-indicators.component.html',
  styleUrls: ['./livestock-blocking-indicators.component.less'],
})
export class LivestockBlockingIndicatorsComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columns = blockingIndicatorListColumnsConf;
  displayedColumns = blockingIndicatorListColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'blockingIndicatorData_table';
  blockingIndicatorData = [];
  dataSource = new MatTableDataSource<any>(this.blockingIndicatorData);
  searchInput: any;
  editMode: any;
  entityName = 'blocking-indicator-list';
  filter;
  state;
  subject = new Subject();
  totalCount = 0;
  loaderSpinner = true;
  typeT = 'T';
  dateFormat = '';

  functionalButtonsFrom: UntypedFormGroup;
  showConfirmationDialog = true;

  @Input() livestockProducer: any;
  @Input() livestockProducerId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private blockingIndicatorService: BlockingIndicatorService,
    private matDialogService: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private liquidityPlanService: LiquidityPlanService,
    private kcService: KeycloakService
  ) {
    this.functionalButtonsFrom = formBuilder.group({
      isSblock: false,
    });
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  ngOnInit(): void {
    this.checkForSBlocks();
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    this.state = window.history.state;
    this.getTableConfiguration();
    this.dataSource.data = [];
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.blockingIndicatorService
        .filterAccounts(this.livestockProducerId)
        .subscribe((data) => {
          this.updateData(data);
        });
    });
  }

  hasPermissionsForSBlockSetsetActiveOrInactive(): boolean {
    return this.kcService.getUserRoles().includes('aw.lp.sf.u');
  }

  checkForSBlocks() {
    this.liquidityPlanService
      .getActiveSBlockByLivestockProducerId(this.livestockProducerId)
      .subscribe(
        (result) => {
          if (!!result) {
            this.functionalButtonsFrom
              .get('isSblock')
              .setValue(true, { emitEvent: true });
          } else {
            this.functionalButtonsFrom
              .get('isSblock')
              .setValue(false, { emitEvent: true });
          }
          this.subscribeToiIsSblockControlChange();
        },
        (error) => {
          this.functionalButtonsFrom
            .get('isSblock')
            .setValue(false, { emitEvent: true });
          this.subscribeToiIsSblockControlChange();
        }
      );
  }

  private subscribeToiIsSblockControlChange() {
    this.functionalButtonsFrom
      .get('isSblock')
      .valueChanges.subscribe((sBlockStatus) => {
        if (this.showConfirmationDialog) {
          const dialog = this.matDialogService.open(
            ConfirmationModalComponent,
            {
              panelClass: 'confirmation-popup',
            }
          );

          dialog.afterClosed().subscribe((result) => {
            if (result && result.event === 'save') {
              this.liquidityPlanService
                .createBlockingIndicatorForLivestockProducer(
                  this.livestockProducer.id,
                  sBlockStatus,
                  this.livestockProducer.accountNumber,
                  result.comment,
                  this.livestockProducer.locationNumber,
                  this.livestockProducer.productionType
                )
                .subscribe((res) => {
                  this.notificationService.showToast(
                    'NOTIFICATION.CREATED',
                    this.notificationService.MESSAGE_TYPE.SUCCESS,
                    {
                      data: this.translateService.instant(
                        'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.S-BLOCK'
                      ),
                    }
                  );
                });
            } else {
              this.showConfirmationDialog = false;
              this.functionalButtonsFrom
                .get('isSblock')
                .setValue(!sBlockStatus, { emitEvent: true });
            }
          });
        } else {
          this.showConfirmationDialog = true;
        }
      });
  }

  sortTable = (sortTableEvent): void => {
    this.loaderSpinner = true;
    this.blockingIndicatorService.setSort(
      sortTableEvent.choice,
      sortTableEvent.direction
    );
    this.subject.next(undefined);
  };

  getFieldNames = (args): Observable<any> => {
    return this.blockingIndicatorService.findDistinctValues(
      args,
      this.livestockProducerId
    );
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.blockingIndicatorService.setColumnFilters(choice, selection);
    this.paginator.pageIndex = 0;
    this.subject.next(undefined);
  };

  ngAfterViewInit() {
    this.blockingIndicatorService.setPaginator(
      this.paginator.pageSize,
      this.paginator.pageIndex
    );
    this.getBlockingIndicators();
  }

  getBlockingIndicators() {
    this.blockingIndicatorService
      .filterAccounts(this.livestockProducerId)
      .subscribe((accounts) => {
        this.blockingIndicatorData = accounts['content'];
        this.dataSource.data = accounts['content'];
        this.totalCount = accounts['total'];
        this.loaderSpinner = false;
      });
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                blockingIndicatorListColumnsConf,
                blockingIndicatorListColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = blockingIndicatorListColumnsConf;
    this.displayedColumns = blockingIndicatorListColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  paginate(event) {
    this.loaderSpinner = true;
    this.blockingIndicatorService.setPaginator(event.pageSize, event.pageIndex);
    this.blockingIndicatorService
      .filterAccounts(this.livestockProducerId)
      .subscribe((data) => {
        this.updateData(data);
      });
  }

  sortData(event) {
    this.loaderSpinner = true;
    let fieldName = '';
    switch (event.active) {
      case 'accountNumber':
        fieldName = 'code';
        break;
      case '':
        fieldName = 'active_account';
        break;
      default:
        fieldName = event.active;
    }

    this.blockingIndicatorService.setSort(fieldName, event.direction);
    this.subject.next(undefined);
  }

  getTypeOfBlockageString(type) {
    if (type === this.typeT) {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.T-BLOCK';
    } else {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.S-BLOCK';
    }
  }

  getStatusString(status) {
    if (status) {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.INACTIVE';
    } else {
      return 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.ACTIVE';
    }
  }

  private updateData(data) {
    this.dataSource.data = data['content'];
    this.totalCount = data['total'];
    this.loaderSpinner = false;
  }
}
