import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-transfer-quantity-labeled-goods-management',
  templateUrl: './transfer-quantity-labeled-goods-management.component.html',
  styleUrls: ['./transfer-quantity-labeled-goods-management.component.less'],
})
export class TransferQuantityLabeledGoodsManagementComponent implements OnInit {
  entityName = 'transfer-quantity-label-goods';

  constructor(
    private router: Router,
    private kcService: KeycloakService,
    private route: ActivatedRoute
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this.navigateToAccessible();
  }

  navigateToAccessible() {
    if (!this.route.firstChild.component) {
      const baseRoutes = this.route.routeConfig.children.filter((el) =>
        this.canNavigate(el)
      );
      if (baseRoutes.length === 0) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl(
          '/quantityLabeledGoods/' + baseRoutes[0].path
        );
      }
    }
  }

  canNavigate(el: Route): unknown {
    if (el.path === '') {
      return false;
    }
    if ((el.path.match(/\//g) || []).length > 0) {
      return false;
    }

    if (el && el.data) {
      return el.data.roles.some((role) => this.kcService.isUserInRole(role));
    } else {
      return true;
    }
  }

  hasRoleForPath(route: string) {
    const currentRoute = this.route.routeConfig.children.find(
      (el) => el.path === route
    );
    if (!currentRoute) {
      return false;
    }
    return currentRoute.data.roles.some((role) =>
      this.kcService.isUserInRole(role)
    );
  }
}
