import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { historicalValuesColumns } from 'src/app/in-memory-data/animal-welfare/historical-values/table-columns';
import { historicalValuesColumnsConf } from 'src/app/in-memory-data/animal-welfare/historical-values/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SystemParameterService } from '../../../services/animal-welfare/system-parameter/system-parameter.service';

@Component({
  selector: 'app-show-historical-values-modal',
  templateUrl: './show-historical-values-modal.component.html',
  styleUrls: ['./show-historical-values-modal.component.less'],
})
export class ShowHistoricalValuesModalComponent
  implements OnInit, AfterViewInit
{
  entityName: 'show-historical-values';
  valuesList = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.valuesList);
  columns = historicalValuesColumnsConf;
  displayedColumns = historicalValuesColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  dateFormat = '';

  constructor(
    private systemparameterService: SystemParameterService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.loadSystemParametersHistory(this.data.uuid);
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadSystemParametersHistory(uuid) {
    this.systemparameterService
      .retrieveSystemParameterHistories(uuid)
      .subscribe((data) => {
        this.dataSource.data = data;
        this.valuesList = data;
      });
  }
}
