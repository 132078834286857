import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { TaskEventType } from '../../../models/task-event-type';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesEntryService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private readonly serviceUrlSearchApi;
  private mapOfColumnFilters = [];
  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
  };
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.participantService;
    this.serviceUrlSearchApi = environment.settings.searchAPIUrl;
    this.taskServiceUrl = environment.settings.taskService;
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  saveApprovalTask(formValue: any, companyName: string, taskId: string) {
    const json = {
      companyName,
      salesEntry: formValue,
      taskId,
    };
    return this.http.post(
      this.serviceUrl + 'sales-entry',
      json,
      this.buildHeaders()
    );
  }

  isAccountNumberAvailable(accountNumber: number) {
    let url;
    url =
      this.serviceUrl +
      'sales-entry/isAccountNumberAvailable?accountNumber=' +
      accountNumber;

    return this.http.get(url, this.buildHeaders());
  }

  findAll(customConfig: any): Observable<HttpEvent<any[]>> {
    return this.requestSearchApi(
      'sales-entry/list',
      this.getConfigs(customConfig)
    );
  }

  findByCompany(guid) {
    return this.http.get(
      this.serviceUrl + 'sales-entry/listByCompany/' + guid,
      this.buildHeaders()
    );
  }

  getSalesEntry(guid) {
    return this.http.get(
      this.serviceUrl + 'sales-entry/' + guid,
      this.buildHeaders()
    );
  }

  getNextAvailableManualAccountNumber() {
    return this.http.get(
      this.serviceUrl + 'sales-entry/nextAvailableManualAccountNumber',
      this.buildHeaders()
    );
  }

  getForAccountNumber(accountNr) {
    return this.http.get(
      this.serviceUrl + 'sales-entry/byAccountNr/' + accountNr,
      this.buildHeaders()
    );
  }

  getDistinctValues(
    customConfig: any,
    column: string
  ): Observable<HttpEvent<any>> {
    return this.requestSearchApi(
      `sales-entry/distinct-values/${column}`,
      this.getConfigs(customConfig)
    );
  }

  requestSearchApi(url, config): Observable<HttpEvent<any[]>> {
    return this.http.get<[]>(this.serviceUrlSearchApi + url, config);
  }

  request(url, config): Observable<HttpEvent<any[]>> {
    return this.http.get<[]>(this.serviceUrl + url, config);
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(customConfigObj).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        config['params'] = config['params'].append(
          key,
          JSON.stringify(customConfigObj[key])
        );
      } else {
        config['params'] = config['params'].append(key, customConfigObj[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);
    return config;
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }
}
