export const taskConfigColumnsConf: any[] = [
  {
    name: 'id',
    index: 0,
    checked: false,
    translateName: 'id',
    locked: true,
  },
  {
    name: 'type',
    index: 1,
    checked: false,
    translateName: 'type',
    locked: true,
  },
  {
    name: 'objectId',
    index: 2,
    checked: false,
    translateName: 'TASK-CONFIGURATION.OBJECT-ID',
    locked: true,
  },
  {
    name: 'object',
    index: 3,
    checked: true,
    translateName: 'TASK-CONFIGURATION.OBJECT',
    locked: false,
  },
  {
    name: 'roleId',
    index: 4,
    checked: true,
    translateName: 'TASK-CONFIGURATION.ROLE-ID',
    locked: false,
  },
  {
    name: 'teamId',
    index: 5,
    checked: true,
    translateName: 'TASK-CONFIGURATION.TEAM-ID',
    locked: false,
  },
  {
    name: 'description',
    index: 6,
    checked: false,
    translateName: 'TASK-CONFIGURATION.DESCRIPTION',
    locked: false,
  },
  {
    name: 'dueDate',
    index: 7,
    checked: true,
    translateName: 'TASK-CONFIGURATION.DUE-DATE',
    locked: false,
  },
  {
    name: 'priority',
    index: 8,
    checked: true,
    translateName: 'TASK-CONFIGURATION.PRIORITY',
    locked: false,
  },
  {
    name: 'embeddedTaskUi',
    index: 9,
    checked: false,
    translateName: 'TASK-CONFIGURATION.EMBEDDED-TASK-UI',
    locked: false,
  },
  {
    name: 'embeddedUi',
    index: 9,
    checked: false,
    translateName: 'TASK-CONFIGURATION.EMBEDDED-UI',
    locked: false,
  },
  {
    version: 1,
  },
];
