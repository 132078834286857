export const quaterlyBillingSimulationColumnsConf: any[] = [
  {
    name: 'quarter',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.QUARTER',
    locked: true,
    number: false,
    translatePrefix: 'Q',
    multiValueColumn: 'year',
    multiValueColumnSeparator: ' / ',
  },
  {
    name: 'deadline_date',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.DEADLINE',
    locked: true,
    number: false,
  },
  {
    name: 'translated_type',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.QUARTER-TYPE',
    locked: true,
    number: false,
  },
  {
    name: 'translated_with_quarter_cap',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.QUARTER-CAP.WITH',
    locked: true,
    number: false,
  },
  {
    version: 3,
  },
];
