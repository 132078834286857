import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { ComponentItem } from 'src/app/shared/models/component-item';
import { BuCreateComponent } from 'src/app/modules/business-unit/bu-create/bu-create.component';
import { CompanyCreateComponent } from 'src/app/modules/company/company-create/company-create.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { PlatformService } from 'src/app/shared/services/platform/platform.service';
import { ClientService } from 'src/app/shared/services/client/client.service';
import { Observable } from 'rxjs';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { ComponentLoaderDirective } from 'src/app/shared/directives/component-loader/component-loader.directive';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { BookingCodeCreateComponent } from '../../booking-code/booking-code-create/booking-code-create.component';
import { ClientGroupService } from 'src/app/shared/services/client-group/client-group.service';
import { UserSelectModalComponent } from 'src/app/shared/modals/user-select-modal/user-select-modal.component';
import { TciService } from 'src/app/shared/services/tci/tci.service';
import { ClientDisbursementService } from 'src/app/shared/services/client-disbursement/client-disbursement.service';
import { UploadFilesModalComponent } from 'src/app/shared/modals/upload-files-modal/upload-files-modal.component';
import { ClientGroupDisbursementService } from 'src/app/shared/client-group-disbursement.service';
import { ClientInterfaceApprovalService } from 'src/app/shared/services/client-interface-approval/client-interface-approval.service';
import { DebtorInterfaceApprovalService } from 'src/app/shared/services/debtor-interface-approval/debtor-interface-approval.service';
import { SystemConfigurationComponent } from '../../animal-welfare-management/system-configuration/system-configuration.component';
import { SystemParameterService } from 'src/app/shared/services/animal-welfare/system-parameter/system-parameter.service';
import { SalesEntryService } from '../../../shared/services/animal-welfare/sales-entry/sales-entry.service';
import { SalesEntryCreateComponent } from '../../sales-entry/sales-entry-create/sales-entry-create.component';
import { AwInterfaceTransferProtocolComponent } from '../../animal-welfare-management/aw-interface-transfer-protocol-management/aw-interface-transfer-protocol.component';
import { AwInterfaceTransferProtocolService } from 'src/app/shared/services/animal-welfare/aw-interface-transfer-protocol/aw-interface-transfer-protocol.service';
import { AccountStatementConfigurationService } from 'src/app/shared/services/account-statement-configuration/account-statement-configuration.service';
import { BusinessTransactionCodeMappingService } from 'src/app/shared/services/business-transaction-code-mapping/business-transaction-code-mapping.service';
import { BtcMappingCreateComponent } from '../../business-transaction-code-mapping/btc-mapping-create/btc-mapping-create.component';
import { AccountStatementConfigurationCreateComponent } from '../../account-statement-configuration/account-statement-configuration-create/account-statement-configuration-create.component';
import { LiquidityPlanAssetsComponent } from '../../animal-welfare-management/liquidity-plan/liquidity-plan-assets/liquidity-plan-assets.component';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { ReportingPeriodsCreateComponent } from '../../animal-welfare-management/reporting-periods/reporting-periods-create/reporting-periods-create.component';
import { ReportingPeriodService } from 'src/app/shared/services/animal-welfare/reporting-period/reporting-period.service';
import { GoodsGroupService } from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import { GoodsGroupsCreateComponent } from '../../animal-welfare-management/goods-groups/goods-groups-create/goods-groups-create.component';
import { QuantityLabeledGoodsCreateComponent } from '../../animal-welfare-management/quantity-labeled-goods/quantity-labeled-goods-create/quantity-labeled-goods-create.component';
import { QuatityLabelledGoodService } from 'src/app/shared/services/animal-welfare/quantity-labelled-good/quatity-labelled-good.service';
import { TransferQuantityGoodsCreateComponent } from '../../animal-welfare-management/transfer-quantity-goods/transfer-quantity-goods-create/transfer-quantity-goods-create.component';
import { TransferQuantityGoodService } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/transfer-quantity-good.service';
import { DebtorService } from 'src/app/shared/services/debtor/debtor.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { ChangeDetectorRef } from '@angular/core';
import { SubLedgerAccountCreateComponent } from '../../sub-ledger-accounts-overview/sub-ledger-account/sub-ledger-account-create/sub-ledger-account-create.component';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { ManualSBlockTaskComponent } from 'src/app/modules/animal-welfare-management/livestock-producer/tasks/manual-s-block-task/manual-s-block-task.component';
import { LivestockFunctionalButtonsComponent } from '../../animal-welfare-management/livestock-producer/livestock-producer-overview/livestock-functional-buttons/livestock-functional-buttons.component';
import { LivestockProducerService } from 'src/app/shared/services/livestock-producer/livestock-producer.service';
import { TranslationEditComponent } from '../../translation-management/translation/translation-edit/translation-edit.component';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { FundDepositsCreateComponent } from '../../aw-participation-fee/aw-participation-fee-management/fund-deposits-create/fund-deposits-create.component';
import { FundDepositsService } from '../../../shared/services/participation-fee/fund-deposits.service';
import { ParticipationPeriodService } from 'src/app/shared/services/animal-welfare/participation-period/participation-period.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';
import { BuTransactionCodesCreateComponent } from '../../business-unit/bu-transaction-codes-create/bu-transaction-codes-create.component';
import { SalesFiguresCreateComponent } from '../../aw-participation-fee/aw-participation-fee-management/sales-figures-create/sales-figures-create.component';
import { SlaughterhouseFiguresCreateComponent } from '../../aw-participation-fee/aw-participation-fee-management/slaughterhouse-figures-create/slaughterhouse-figures-create.component';
import { SlaughterhouseFiguresService } from '../../../shared/services/participation-fee/slaughterhouse-figures.service';
import { SalesFiguresService } from '../../../shared/services/participation-fee/sales-figures.service';
import { AwParticipationFeeDocumentsComponent } from '../../aw-participation-fee/aw-participation-fee-management/shared/aw-participation-fee-documents/aw-participation-fee-documents.component';
import { UserCreateComponent } from '../../user-management/user-management/user-create/user-create.component';
import { RoleCreateComponent } from '../../user-management/user-management/role-create/role-create.component';
import { PermissionCreateComponent } from '../../user-management/user-management/permission-create/permission-create.component';
import { ExcludedPermissionCreateComponent } from '../../user-management/user-management/excluded-permission-create/excluded-permission-create.component';
import { MultipleReturnCodesPerTaskComponent } from '../../animal-welfare-management/multiple-return-codes-per-task/multiple-return-codes-per-task.component';
import { ExportingPrintingTaskComponent } from '../../exporting-printing-task/exporting-printing-task.component';
import { KeycloakService } from 'keycloak-angular';
import { OpenTransactionBookingTableComponent } from '../../open-transaction-booking-management/open-transaction-booking-table/open-transaction-booking-table.component';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { AwContactPersonUpdateTaskComponent } from '../../animal-welfare-management/aw-contact-person-update-task/aw-contact-person-update-task.component';
import { FoodProcessingCompanyFiguresCreateComponent } from '../../aw-participation-fee/aw-participation-fee-management/food-processing-company-figures-create/food-processing-company-figures-create.component';
import { FoodProcessingCompanyFiguresService } from '../../../shared/services/participation-fee/food-processing-company-figures.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.less'],
})
export class TaskEditComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  currentTask;
  entityName = 'task';
  form: any;
  tciTask;

  @ViewChild(ComponentLoaderDirective, { static: true })
  componentLoader: ComponentLoaderDirective;

  @Input() components: ComponentItem[] = [
    new ComponentItem(BuCreateComponent, 'APPROVE_BUSINESS_UNIT_INFORMATION'),
    new ComponentItem(
      CompanyCreateComponent,
      'APPROVE_CENTRAL_MASTER_DATA_INFORMATION'
    ),
    new ComponentItem(
      BookingCodeCreateComponent,
      'APPROVE_BOOKING_CODE_INFORMATION'
    ),
    new ComponentItem(
      BookingCodeCreateComponent,
      'SPECIAL_APPROVE_BOOKING_CODE_INFORMATION'
    ),
    new ComponentItem(
      SystemConfigurationComponent,
      'APPROVE_ANIMAL_WELFARE_SYSTEM_PARAMETER'
    ),
    new ComponentItem(
      SalesEntryCreateComponent,
      'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT'
    ),
    new ComponentItem(BtcMappingCreateComponent, 'APPROVAL_BTC_MAPPING'),
    new ComponentItem(
      AccountStatementConfigurationCreateComponent,
      'APPROVAL_ACCOUNT_STATEMENT_CONFIGURATION'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_XML_GENERAL_TECHNICAL_ERROR'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_ALREADY_PROCESSED_FILE'
    ),
    new ComponentItem(AwInterfaceTransferProtocolComponent, 'AW_INVALID_FILE'),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_INCORRECT_PROCESSING_SEQ'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_RECORD_WITH_MISSING_MASTER_DATA'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_RECORD_WITH_CHANGED_VVVO'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_MISSING_MATCHING_ID'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_MATCHING_RECORD_COLLIDING'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_MATCHING_RECORD_USING_CLOSED_QUARTER'
    ),
    new ComponentItem(
      AwInterfaceTransferProtocolComponent,
      'AW_INVALID_RECORD'
    ),
    new ComponentItem(
      LiquidityPlanAssetsComponent,
      'APPROVAL_ANIMAL_WELFARE_LIQUIDITY_PLAN'
    ),
    new ComponentItem(
      ReportingPeriodsCreateComponent,
      'APPROVAL_REPORTING_PERIOD'
    ),
    new ComponentItem(GoodsGroupsCreateComponent, 'APPROVAL_GOODS_GROUP'),
    new ComponentItem(
      QuantityLabeledGoodsCreateComponent,
      'APPROVAL_QUANTITY_LABELLED_GOOD'
    ),
    new ComponentItem(
      TransferQuantityGoodsCreateComponent,
      'APPROVAL_QUANTITY_GOOD'
    ),
    new ComponentItem(
      SalesEntryCreateComponent,
      'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR'
    ),
    new ComponentItem(
      SubLedgerAccountCreateComponent,
      'APPROVAL_SUB_LEDGER_ACCOUNT_CREATION'
    ),
    new ComponentItem(
      SubLedgerAccountCreateComponent,
      'APPROVAL_SUB_LEDGER_ACCOUNT_CONFIGURATION'
    ),
    new ComponentItem(ManualSBlockTaskComponent, 'ACTIVATE_MANUAL_S_BLOCK'),
    new ComponentItem(ManualSBlockTaskComponent, 'DEACTIVATE_MANUAL_S_BLOCK'),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS'
    ),
    new ComponentItem(TranslationEditComponent, 'TRANSLATION_APPROVAL'),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'AW_RESERVE_BUDGET_STATUS'
    ),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'AW_ALLOCATE_BUDGET_STATUS'
    ),
    new ComponentItem(
      BuTransactionCodesCreateComponent,
      'APPROVAL_NEW_TRANSACTION_CODE_ALLOCATION'
    ),
    new ComponentItem(
      BuTransactionCodesCreateComponent,
      'APPROVAL_TRANSACTION_CODE_ALLOCATION'
    ),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'AW_TERMINATE_DATE_CHANGE'
    ),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'AW_TERMINATE_DATE_DELETE'
    ),
    new ComponentItem(
      LivestockFunctionalButtonsComponent,
      'AW_TERMINATE_DATE_SET'
    ),
    new ComponentItem(FundDepositsCreateComponent, 'APPROVAL_FUND_DEPOSITS'),
    new ComponentItem(FundDepositsCreateComponent, 'EDIT_FUND_DEPOSITS'),
    new ComponentItem(FundDepositsCreateComponent, 'CANCEL_FUND_DEPOSITS'),
    new ComponentItem(
      SlaughterhouseFiguresCreateComponent,
      'APPROVAL_SLAUGHTERHOUSE_FIGURES'
    ),
    new ComponentItem(
      SlaughterhouseFiguresCreateComponent,
      'EDIT_SLAUGHTERHOUSE_FIGURES'
    ),
    new ComponentItem(
      SlaughterhouseFiguresCreateComponent,
      'CANCEL_SLAUGHTERHOUSE_FIGURES'
    ),
    new ComponentItem(SalesFiguresCreateComponent, 'APPROVAL_SALES_FIGURES'),
    new ComponentItem(SalesFiguresCreateComponent, 'EDIT_SALES_FIGURES'),
    new ComponentItem(SalesFiguresCreateComponent, 'CANCEL_SALES_FIGURES'),
    new ComponentItem(
      FoodProcessingCompanyFiguresCreateComponent,
      'APPROVAL_FOOD_PROCESSING_COMPANY_FIGURES'
    ),
    new ComponentItem(
      FoodProcessingCompanyFiguresCreateComponent,
      'EDIT_FOOD_PROCESSING_COMPANY_FIGURES'
    ),
    new ComponentItem(
      FoodProcessingCompanyFiguresCreateComponent,
      'CANCEL_FOOD_PROCESSING_COMPANY_FIGURES'
    ),
    new ComponentItem(
      AwParticipationFeeDocumentsComponent,
      'AW_APPROVAL_PARTICIPATION_FEE_FUND_DEPOSITS'
    ),
    new ComponentItem(
      AwParticipationFeeDocumentsComponent,
      'AW_PRINT_AND_SEND_ANNUAL_BILLING'
    ),
    new ComponentItem(
      MultipleReturnCodesPerTaskComponent,
      'AW_INTERFACE_FILE_TRIGGERED_RELEVANT_RETURN_CODES'
    ),
    new ComponentItem(UserCreateComponent, 'APPROVAL_USER_CREATION_INTERNAL'),
    new ComponentItem(UserCreateComponent, 'APPROVAL_USER_CHANGES_INTERNAL'),
    new ComponentItem(UserCreateComponent, 'APPROVAL_USER_CREATION_EXTERNAL'),
    new ComponentItem(UserCreateComponent, 'APPROVAL_USER_CHANGES_EXTERNAL'),
    new ComponentItem(RoleCreateComponent, 'APPROVAL_ROLE_CREATION'),
    new ComponentItem(RoleCreateComponent, 'APPROVAL_ROLE_CHANGES'),
    new ComponentItem(
      PermissionCreateComponent,
      'APPROVAL_PERMISSION_CREATION'
    ),
    new ComponentItem(PermissionCreateComponent, 'APPROVAL_PERMISSION_CHANGES'),
    new ComponentItem(
      ExcludedPermissionCreateComponent,
      'APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CREATION'
    ),
    new ComponentItem(
      ExcludedPermissionCreateComponent,
      'APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CHANGES'
    ),
    new ComponentItem(
      ExportingPrintingTaskComponent,
      'APPROVAL_EXPORTING_PRINTING_SERVICE'
    ),
    new ComponentItem(
      OpenTransactionBookingTableComponent,
      'APPROVE_OPEN_ITEM_MANUAL_BOOKING'
    ),
    new ComponentItem(
      AwContactPersonUpdateTaskComponent,
      'AW_CHANGES_CONTACT_PERSON'
    ),
  ];

  service: ApproveAndReject;
  services: { [key: string]: ApproveAndReject } = {
    APPROVE_BUSINESS_UNIT_INFORMATION: this.buService,
    APPROVE_ANIMAL_WELFARE_SYSTEM_PARAMETER: this.systemParameterService,
    APPROVE_CENTRAL_MASTER_DATA_INFORMATION: this.companyService,
    APPROVE_PLATFORM_INFORMATION: this.platformService,
    APPROVE_CLIENT_INFORMATION: this.clientService,
    APPROVE_BOOKING_CODE_INFORMATION: this.bookingCodeService,
    SPECIAL_APPROVE_BOOKING_CODE_INFORMATION: this.bookingCodeService,
    APPROVE_CLIENT_GROUP_INFORMATION: this.clientGroupService,
    APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION:
      this.tciService,
    UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION:
      this.tciService,
    APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION:
      this.tciService,
    APPROVE_DISBURSEMENT: this.disbursementService,
    CLIENT_INTERFACE_APPROVAL: this.clientInterfaceApprovalService,
    DEBTOR_INTERFACE_APPROVAL: this.debtorInterfaceApprovalService,
    PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK: this.clientService,
    APPROVAL_ACCOUNT_STATEMENT_CONFIGURATION:
      this.accountStatementConfigurationService,
    CHECK_ACCOUNT_STATE_PROCESSING_ERROR:
      this.accountStatementConfigurationService,
    PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC:
      this.accountStatementConfigurationService,
    APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT: this.salesEntryService,
    AW_XML_GENERAL_TECHNICAL_ERROR: this.awInterfaceTransferProtocolService,
    AW_ALREADY_PROCESSED_FILE: this.awInterfaceTransferProtocolService,
    AW_INVALID_FILE: this.awInterfaceTransferProtocolService,
    AW_INCORRECT_PROCESSING_SEQ: this.awInterfaceTransferProtocolService,
    AW_RECORD_WITH_MISSING_MASTER_DATA: this.salesEntryService,
    AW_RECORD_WITH_CHANGED_VVVO: this.awInterfaceTransferProtocolService,
    AW_MISSING_MATCHING_ID: this.awInterfaceTransferProtocolService,
    AW_MATCHING_RECORD_COLLIDING: this.awInterfaceTransferProtocolService,
    AW_MATCHING_RECORD_USING_CLOSED_QUARTER:
      this.awInterfaceTransferProtocolService,
    AW_INVALID_RECORD: this.awInterfaceTransferProtocolService,
    APPROVAL_BTC_MAPPING: this.businessTransactionCodeMappingService,
    APPROVAL_ANIMAL_WELFARE_LIQUIDITY_PLAN: this.liquidityPlanService,
    APPROVAL_REPORTING_PERIOD: this.reportingPeriodService,
    APPROVAL_GOODS_GROUP: this.goodsGroupService,
    APPROVAL_QUANTITY_LABELLED_GOOD: this.quantityLabelledGoodService,
    APPROVAL_QUANTITY_GOOD: this.transferQuantityGoodService,
    SEPA_MANDATE_CREATION: this.debtorService,
    SEPA_MANDATE_CONFIGURATION: this.debtorService,
    SEPA_MANDATE_EXPIRED_CREATE_NEW: this.debtorService,
    SEPA_MANDATE_EXPIRED_SET_NEW: this.debtorService,
    MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR: this.salesEntryService,
    APPROVAL_SUB_LEDGER_ACCOUNT_CREATION: this.subLedgerService,
    APPROVAL_SUB_LEDGER_ACCOUNT_CONFIGURATION: this.subLedgerService,
    TRANSLATION_APPROVAL: this.translationService,
    ACTIVATE_MANUAL_S_BLOCK: this.taskService,
    DEACTIVATE_MANUAL_S_BLOCK: this.taskService,
    APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS:
      this.livestockProducerService,
    AW_RESERVE_BUDGET_STATUS: this.participationPeriodService,
    AW_ALLOCATE_BUDGET_STATUS: this.participationPeriodService,
    APPROVAL_TRANSACTION_CODE_ALLOCATION: this.transactionCodeAllocationService,
    APPROVAL_NEW_TRANSACTION_CODE_ALLOCATION:
      this.transactionCodeAllocationService,
    AW_TERMINATE_DATE_CHANGE: this.participationPeriodService,
    AW_TERMINATE_DATE_DELETE: this.participationPeriodService,
    AW_TERMINATE_DATE_SET: this.participationPeriodService,
    APPROVAL_FUND_DEPOSITS: this.fundDepositsService,
    EDIT_FUND_DEPOSITS: this.fundDepositsService,
    CANCEL_FUND_DEPOSITS: this.fundDepositsService,
    APPROVAL_SLAUGHTERHOUSE_FIGURES: this.slaughterhouseFiguresService,
    EDIT_SLAUGHTERHOUSE_FIGURES: this.slaughterhouseFiguresService,
    CANCEL_SLAUGHTERHOUSE_FIGURES: this.slaughterhouseFiguresService,
    APPROVAL_SALES_FIGURES: this.salesFiguresService,
    EDIT_SALES_FIGURES: this.salesFiguresService,
    CANCEL_SALES_FIGURES: this.salesFiguresService,
    AW_APPROVAL_PARTICIPATION_FEE_FUND_DEPOSITS: this.taskService,
    AW_PRINT_AND_SEND_ANNUAL_BILLING: this.taskService,
    AW_INTERFACE_FILE_TRIGGERED_RELEVANT_RETURN_CODES: this.taskService,
    APPROVAL_USER_CREATION_INTERNAL: this.taskService,
    APPROVAL_USER_CHANGES_INTERNAL: this.taskService,
    APPROVAL_USER_CREATION_EXTERNAL: this.taskService,
    APPROVAL_USER_CHANGES_EXTERNAL: this.taskService,
    APPROVAL_ROLE_CREATION: this.taskService,
    APPROVAL_ROLE_CHANGES: this.taskService,
    APPROVAL_PERMISSION_CREATION: this.taskService,
    APPROVAL_PERMISSION_CHANGES: this.taskService,
    APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CREATION: this.taskService,
    APPROVAL_EXCLUDED_PERMISSION_COMBINATION_CHANGES: this.taskService,
    APPROVAL_EXPORTING_PRINTING_SERVICE: this.taskService,
    AW_CHANGES_CONTACT_PERSON: this.taskService,
    APPROVE_OPEN_ITEM_MANUAL_BOOKING: this.openItemService,
    APPROVAL_FOOD_PROCESSING_COMPANY_FIGURES:
      this.foodProcessingCompanyFiguresService,
    EDIT_FOOD_PROCESSING_COMPANY_FIGURES:
      this.foodProcessingCompanyFiguresService,
    CANCEL_FOOD_PROCESSING_COMPANY_FIGURES:
      this.foodProcessingCompanyFiguresService,
  };

  serviceInterface: ApproveAndRejectInterfaces;
  servicesInterfaces: { [key: string]: ApproveAndRejectInterfaces } = {
    CLIENT_INTERFACE_APPROVAL: this.clientInterfaceApprovalService,
    DEBTOR_INTERFACE_APPROVAL: this.debtorInterfaceApprovalService,
  };
  uploadedFiles;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private buService: BusinessUnitService,
    private matDialogService: MatDialog,
    private notificationService: NotificationService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private platformService: PlatformService,
    private clientService: ClientService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private bookingCodeService: BookingCodeService,
    private clientGroupService: ClientGroupService,
    private tciService: TciService,
    private disbursementService: ClientDisbursementService,
    private clientGroupDisbursementService: ClientGroupDisbursementService,
    private clientInterfaceApprovalService: ClientInterfaceApprovalService,
    private debtorInterfaceApprovalService: DebtorInterfaceApprovalService,
    private systemParameterService: SystemParameterService,
    private salesEntryService: SalesEntryService,
    private subLedgerService: SubLedgerService,
    private translationService: TranslationService,
    private awInterfaceTransferProtocolService: AwInterfaceTransferProtocolService,
    private accountStatementConfigurationService: AccountStatementConfigurationService,
    private businessTransactionCodeMappingService: BusinessTransactionCodeMappingService,
    private liquidityPlanService: LiquidityPlanService,
    private reportingPeriodService: ReportingPeriodService,
    private goodsGroupService: GoodsGroupService,
    private quantityLabelledGoodService: QuatityLabelledGoodService,
    private transferQuantityGoodService: TransferQuantityGoodService,
    private debtorService: DebtorService,
    private fileService: FileService,
    private customErrorHandler: CustomErrorHandler,
    private cdref: ChangeDetectorRef,
    private livestockProducerService: LivestockProducerService,
    private participationPeriodService: ParticipationPeriodService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    private fundDepositsService: FundDepositsService,
    private slaughterhouseFiguresService: SlaughterhouseFiguresService,
    private salesFiguresService: SalesFiguresService,
    private kcService: KeycloakService,
    private openItemService: OpenItemService,
    private tablesService: TablesService,
    private foodProcessingCompanyFiguresService: FoodProcessingCompanyFiguresService
  ) {}

  editableTask: boolean;
  objectionManagementTask: boolean;
  updateTask: boolean;
  files: any;
  isApprovalTask: boolean;
  taskComponent: any;
  sepaMandateSetNewTask = false;

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.tablesService.updateEntitiesLoadedState(false);
  }

  ngOnInit() {
    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );

    this.taskService.currentUpdateTask.subscribe(
      (updateTask) => (this.updateTask = updateTask)
    );

    this.clientService.currentSharedTableDocuments.subscribe(
      (sharedTableData) => (this.files = sharedTableData)
    );

    this.taskService.updateEditableTask(false);
    this.taskService.updateUpdateTask(false);

    const taskId = this.route.snapshot.paramMap.get('taskId');
    this.taskService.getTask(taskId).subscribe((task) => {
      this.currentTask = task;

      this.isApprovalTask = this.checkIfTaskTypeIsAnApproval(
        this.currentTask.type
      );

      if (this.currentTask.type === 'OBJECTION_MANAGEMENT') {
        this.objectionManagementTask = true;
      }
      if (
        this.currentTask.type ===
          'CREATION_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL' ||
        this.currentTask.type ===
          'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL' ||
        this.currentTask.type ===
          'APPROVE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL' ||
        this.currentTask.type ===
          'UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION'
      ) {
        this.tciTask = true;
      }
      if (this.currentTask.type === 'CLIENT_INTERFACE_APPROVAL') {
        this.clientInterfaceApprovalService.currentSharedClientInterfaceApprovalForm.subscribe(
          (form) => (this.form = form)
        );
      } else {
        this.debtorInterfaceApprovalService.currentSharedDebtorInterfaceApprovalForm.subscribe(
          (form) => (this.form = form)
        );
      }

      if (this.currentTask.type === 'SEPA_MANDATE_EXPIRED_SET_NEW') {
        this.sepaMandateSetNewTask = true;
      }

      if (
        this.currentTask.businessUnitId &&
        this.currentTask.businessUnitId !==
          Number(this.buService.getCurrentBusinessUnit())
      ) {
        this.router.navigateByUrl('/tasks/' + this.currentTask.id);
      }
      if (this.currentTask.status !== 'OPEN') {
        this.router.navigateByUrl('/tasks/' + this.currentTask.id);
      }

      window.history.state.task = task;
      const componentItem = this.components.filter(
        (comp) => comp.type === this.currentTask.type
      )[0];
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          componentItem.component
        );

      const viewContainerRef = this.componentLoader.viewContainerRef;
      viewContainerRef.clear();
      this.taskComponent = viewContainerRef.createComponent(componentFactory);
    });
  }

  get isLoggedInUserIsTaskCreator(): boolean {
    const isSameUpdatedBy =
      this.kcService.getUsername() === this.currentTask.updatedBy;
    const isSameUpdatedByUsername =
      this.kcService.getUsername() === this.currentTask.updatedByUsername;
    return isSameUpdatedBy || isSameUpdatedByUsername;
  }

  get showApproveRejectButtons(): boolean {
    return (
      !this.updateTask &&
      !this.isCreateDisbursementTask() &&
      this.isApprovalTask &&
      !this.isLoggedInUserIsTaskCreator &&
      !this.isAwContactPersonUpdateTask()
    );
  }

  acceptTask() {
    let actionData = ConfirmationModalComponent.ACTION_APPROVE_TASK;
    if (
      this.currentTask.type === 'APPROVE_DISBURSEMENT' ||
      this.currentTask.type === 'APPROVE_DISBURSEMENT_GROUP'
    ) {
      actionData =
        ConfirmationModalComponent.ACTION_APPROVE_DISBURSEMENT_APPROVAL_TASK;
    }
    this.matDialogService
      .open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
        data: { action: actionData },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.event === 'save') {
          this.showSnackBar('NOTIFICATION.TASK.APPROVED');
          if (
            this.currentTask.type === 'APPROVE_DISBURSEMENT' ||
            this.currentTask.type === 'APPROVE_DISBURSEMENT_GROUP'
          ) {
            this.handleDisbursementApproval(result);
          } else if (
            this.currentTask.type === 'CLIENT_INTERFACE_APPROVAL' ||
            this.currentTask.type === 'DEBTOR_INTERFACE_APPROVAL'
          ) {
            this.handleClientDebtorInterfaceApproval(result);
          } else if (
            this.currentTask.type ===
            'APPROVE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL'
          ) {
            this.handleTciSubcontractApproval(result);
          } else if (
            this.currentTask.type ===
            'PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK'
          ) {
            this.updateDocuments(this.currentTask.id);
          } else if (this.isAwContactPersonUpdateTask()) {
            this.completeContactPersonChangesTask(result);
          } else {
            this.approveTask(result);
          }
        }
      });
  }

  approveTask(result) {
    this.services[this.currentTask.type]
      .approveTask(this.currentTask.id, result.comment)
      .subscribe(
        () => {
          if (this.currentTask.type === 'APPROVE_BUSINESS_UNIT_INFORMATION') {
            this.reloadBUs();
          }

          if (this.currentTask.type === 'SEPA_MANDATE_CONFIGURATION') {
            this.manageTaskFiles(true);
          }
          if (this.currentTask.type === 'TRANSLATION_APPROVAL') {
            this.reloadAndGoBackToTask();
          } else {
            setTimeout(() => {
              this.router.navigateByUrl('/tasks');
            }, 3000);
          }
        },
        (err) => this.handleError(err)
      );
  }

  isCompleteButtonDisabled() {
    if (this.isAwContactPersonUpdateTask()) {
      const component: AwContactPersonUpdateTaskComponent =
        this.taskComponent.instance;
      return component.form.invalid;
    }
    return false;
  }

  completeContactPersonChangesTask(result) {
    if (this.currentTask.params['updatedContact']) {
      const form = this.taskComponent.instance.form;
      const updatedContact = JSON.parse(
        this.currentTask.params['updatedContact']
      );
      updatedContact.sendUserStatus = form.controls.action.value;
      this.currentTask.params['updatedContact'] =
        JSON.stringify(updatedContact);
    }
    const request = {
      params: this.currentTask.params,
      comment: result.comment,
    };
    this.taskService.updateAndComplete(this.currentTask.id, request).subscribe(
      () => this.router.navigateByUrl('/tasks'),
      (err) => this.handleError(err)
    );
  }
  reloadAndGoBackToTask() {
    setTimeout(() => {
      this.router.navigateByUrl('/tasks');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }, 3000);
  }

  handleTciSubcontractApproval(result) {
    this.tciService
      .approveSubcontractTask(this.currentTask.id, result.comment)
      .subscribe(
        () => this.router.navigateByUrl('/tasks'),
        (err) => this.handleError(err)
      );
  }

  handleClientDebtorInterfaceApproval(result) {
    this.servicesInterfaces[this.currentTask.type]
      .approveTaskInterface(
        this.currentTask.id,
        result.comment,
        this.form.value
      )
      .subscribe(
        () => this.router.navigateByUrl('/tasks'),
        (err) => this.handleError(err)
      );
  }

  manageTaskFiles(accepted) {
    if (accepted) {
      if (this.taskComponent?.instance?.deletedFiles?.length > 0) {
        this.deleteListOfFiles(this.taskComponent.instance.deletedFiles);
      }
    } else {
      if (this.taskComponent?.instance?.draftFiles?.length > 0) {
        this.deleteListOfFiles(this.taskComponent.instance.draftFiles);
      }
    }
  }

  handleDisbursementApproval(result: any) {
    const data = {
      masterCompetence: result.masterCompetence,
      comment: result.comment,
    };
    if (this.currentTask.type === 'APPROVE_DISBURSEMENT') {
      this.disbursementService
        .approveDisbursement(this.currentTask.id, data)
        .subscribe(
          () => this.router.navigateByUrl('/tasks'),
          (err) => this.handleError(err)
        );
    } else {
      this.clientGroupDisbursementService
        .approveDisbursement(this.currentTask.id, data)
        .subscribe(
          () => this.router.navigateByUrl('/tasks'),
          (err) => this.handleError(err)
        );
    }
  }

  updateDocuments(idTask) {
    this.clientService.editDocuments(idTask, this.files).subscribe((result) => {
      this.router.navigateByUrl('/tasks');
    });
  }

  reloadBUs() {
    this.buService.refreshBusFunction();
  }

  showSnackBar(message: string) {
    this.notificationService.dismissMessage();
    this.notificationService.showToast(
      message,
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }

  handleError(error) {
    if (
      error.error &&
      error.error[0] &&
      error.error[0].error === 'own-task' &&
      error.error[0].field === 'loggedInUser'
    ) {
      this.customErrorHandler.handleError(error.error, null);
    } else {
      this.notificationService.showToast(
        'ERROR-MESSAGES.ERROR-BACKEND',
        this.notificationService.MESSAGE_TYPE.ERROR,
        {
          name: error.error ? error.error.errorId : 'unknown',
          error: error.name ? error.name : 'unknown',
        }
      );
    }
  }

  forwardTask() {
    this.matDialogService
      .open(UserSelectModalComponent, {
        panelClass: 'confirmation-popup',
      })
      .afterClosed()
      .subscribe((userInfo) => {
        if (!userInfo) {
          return;
        }

        const dialog = this.matDialogService.open(ConfirmationModalComponent, {
          panelClass: 'confirmation-popup',
          data: { action: ConfirmationModalComponent.ACTION_APPROVE_TASK },
        });
        dialog.afterClosed().subscribe((result) => {
          if (result && result.event === 'save') {
            this.notificationService.showToast(
              'task.completed',
              this.notificationService.MESSAGE_TYPE.SUCCESS
            );
            let name;
            if (userInfo.forwardOption === 'ROLE') {
              name = userInfo.role;
            } else if (userInfo.forwardOption === 'USER') {
              name = userInfo.user.username;
            } else if (userInfo.forwardOption === 'TEAM') {
              name = userInfo.team;
            }

            this.taskService
              .forwardTask(
                [this.currentTask.id],
                userInfo.forwardOption,
                name,
                result.comment
              )
              .subscribe(() => {
                setTimeout(() => {
                  this.router.navigateByUrl('/tasks', {
                    state: { success: true },
                  });
                }, 3000);
              });
          }
        });
      });
  }

  rejectTask() {
    this.matDialogService
      .open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
        data: { type: this.currentTask.type },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.event === 'save') {
          let snackBarMessage;
          if (
            this.currentTask.type === 'CLIENT_INTERFACE_APPROVAL' ||
            this.currentTask.type === 'DEBTOR_INTERFACE_APPROVAL'
          ) {
            if (this.currentTask.type === 'CLIENT_INTERFACE_APPROVAL') {
              snackBarMessage = 'CLIENT-INTERFACE.NOTIFICATIONS.REJECTED';
            } else {
              snackBarMessage = 'DEBTOR-INTERFACE.NOTIFICATIONS.REJECTED';
            }

            this.showSnackBar(snackBarMessage);

            this.servicesInterfaces[this.currentTask.type]
              .rejectTaskInterface(
                this.currentTask.id,
                result.comment,
                result.rejectionReasonCode
              )
              .subscribe(
                () => this.router.navigateByUrl('/tasks'),
                (err) => this.handleError(err)
              );
          } else if (
            this.currentTask.type ===
              'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT' ||
            this.currentTask.type === 'APPROVAL_BTC_MAPPING'
          ) {
            // TODO THIS IF WILL BE THE ONLY ONE FOR REJECTING TASKS THAT DON'T NEED CUSTOM DATA IN THE FUTURE.
            snackBarMessage = 'NOTIFICATION.TASK.REJECTED';
            this.showSnackBar(snackBarMessage);
            this.taskService
              .rejectTask(
                this.currentTask.id,
                result.comment,
                this.currentTask.guid
              )
              .subscribe(
                () => this.router.navigateByUrl('/tasks'),
                (err) => this.handleError(err)
              );
          } else {
            snackBarMessage = 'NOTIFICATION.TASK.REJECTED';

            this.showSnackBar(snackBarMessage);
            this.services[this.currentTask.type]
              .rejectTask(
                this.currentTask.id,
                result.comment,
                this.currentTask.guid
              )
              .subscribe(
                () => {
                  if (this.currentTask.type === 'SEPA_MANDATE_CONFIGURATION') {
                    this.manageTaskFiles(false);
                  }

                  this.router.navigateByUrl('/tasks');
                },
                (err) => this.handleError(err)
              );
          }
        }
      });
  }

  isCreateDisbursementTask() {
    return (
      this.currentTask.type === 'CREATE_DISBURSEMENT' ||
      this.currentTask.type === 'CREATE_DISBURSEMENT_GROUP'
    );
  }

  isAwContactPersonUpdateTask() {
    return this.currentTask.type === 'AW_CHANGES_CONTACT_PERSON';
  }

  startUploadFile() {
    const taskId = window.history.state.task.id;
    this.taskService.listFiles(taskId).subscribe((data) => {
      this.uploadedFiles = data;
      const dialog = this.matDialogService.open(UploadFilesModalComponent, {
        panelClass: 'confirmation-popup',
        data: { uploadedFiles: this.uploadedFiles },
      });
      dialog.afterClosed().subscribe((result) => {
        if (result.attachment !== undefined && result.attachment !== '') {
          this.saveFiles(result.attachment, taskId);
          this.notificationService.showToast(
            'NOTIFICATION.SUCCESS',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
        }
      });
    });
  }

  saveFiles(attachment: any, taskId) {
    for (const file of attachment) {
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('taskId', taskId);
      this.taskService.uploadFile(formData).subscribe(() => {
        /**
         * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
         * to much of empty function. So this comment is to makes the sonarqube happy.
         * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
         */
      });
    }
  }

  retrieveFiles(id: any) {
    this.taskService.listFiles(id).subscribe((data) => {
      this.uploadedFiles = data;
    });
  }

  checkIfTaskTypeIsAnApproval(type: string): boolean {
    switch (type) {
      case 'AW_XML_GENERAL_TECHNICAL_ERROR':
      case 'AW_ALREADY_PROCESSED_FILE':
      case 'AW_ALREADY_PROCESSED_FILE_DESCRIPTION':
      case 'AW_INCORRECT_PROCESSING_SEQ':
      case 'AW_RECORD_WITH_MISSING_MASTER_DATA':
      case 'AW_RECORD_WITH_CHANGED_VVVO':
      case 'AW_MISSING_MATCHING_ID':
      case 'AW_MATCHING_RECORD_COLLIDING':
      case 'AW_MATCHING_RECORD_USING_CLOSED_QUARTER':
      case 'AW_INVALID_RECORD':
      case 'CHECK_ACCOUNT_STATE_PROCESSING_ERROR':
      case 'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC':
      case 'PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK':
      case 'AW_INTERFACE_FILE_TRIGGERED_RELEVANT_RETURN_CODES':
      case 'AW_CHANGES_CONTACT_PERSON':
        return false;
      default:
        return true;
    }
  }

  private deleteListOfFiles(fileList: []) {
    fileList.forEach((element) => {
      this.fileService.deleteFile(element).subscribe((_) => {
        /**
         * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
         * to much of empty function. So this comment is to makes the sonarqube happy.
         * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
         */
      });
    });
  }
}

interface ApproveAndReject {
  approveTask: (id: number, comment: string) => Observable<any>;
  rejectTask: (id: number, comment: string, guid?: string) => Observable<any>;
}

interface ApproveAndRejectInterfaces {
  approveTaskInterface: (
    id: number,
    comment: string,
    formValue: any
  ) => Observable<any>;
  rejectTaskInterface: (
    id: number,
    comment: string,
    rejectionReasonCode: any
  ) => Observable<any>;
}
