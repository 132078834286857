export const monthEnum: any[] = [
  {
    name: 'JANUARY',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.01',
  },
  {
    name: 'FEBRUARY',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.02',
  },
  {
    name: 'MARCH',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.03',
  },
  {
    name: 'APRIL',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.04',
  },
  {
    name: 'MAY',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.05',
  },
  {
    name: 'JUNE',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.06',
  },
  {
    name: 'JULY',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.07',
  },
  {
    name: 'AUGUST',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.08',
  },
  {
    name: 'SEPTEMBER',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.09',
  },
  {
    name: 'OCTOBER',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.10',
  },
  {
    name: 'NOVEMBER',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.11',
  },
  {
    name: 'DECEMBER',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.MONTHS.12',
  },
];
