<div class="dialog-content">
  <div>
    <div class="filters" [formGroup]="filterForm">
      <div>
        <app-mat-select
          *ngIf="businessUnitProduct != 0"
          [name]="'OPEN-TRANSACTIONS.PARTNER-TYPE'"
          [placeholder]="'OPEN-TRANSACTIONS.PARTNER-TYPE'"
          [label]="'OPEN-TRANSACTIONS.PARTNER-TYPE'"
          [entityName]="entityName"
          [form]="filterForm"
          [controlObject]="filterForm.controls.businessPartnerType"
          [controlName]="'businessPartnerType'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="types"
          [optionValue]="'value'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
        ></app-mat-select>

        <app-mat-input
          [name]="'search'"
          [placeholder]="'search'"
          [label]="'search'"
          [entityName]="entityName"
          [form]="filterForm"
          [controlObject]="filterForm.controls.searchString"
          [controlName]="'searchString'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
      </div>
      <div class="row table-container-modal">
        <div class="spinner-container" *ngIf="!dataLoaded">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <app-default-table
          *ngIf="dataLoaded"
          [entityName]="entityName"
          [configurationKey]="configurationKey"
          [columns]="columns"
          [displayedColumns]="displayedColumns"
          [originalColumns]="columns"
          [originalDisplayedColumns]="displayedColumns"
          [tableDataSource]="transactionData"
          [threeDots]="false"
          [expanded]="false"
          [selectionAction]="null"
          [insideDialog]="true"
          (filterEvent)="filter($event)"
          [singleSelection]="true"
        ></app-default-table>
      </div>
    </div>
  </div>
</div>
