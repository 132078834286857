import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { isEmpty } from 'src/app/utils/object-util';
import { AzureFile } from '../models/azure-file';
import { FileType } from '../models/file-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../services/file/file.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.less'],
})
export class AttachmentsComponent implements OnInit {
  @Input() attachments;
  @Input() transactionId;
  @Input() attachmentType;
  @Input() deleteFileHidden;
  @Input() fileTypes;
  @Input() selectedTransactions;
  entityName = 'attachments';
  dateFormat = '';

  fileTypesObject: any;

  constructor(
    private userService: UserService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);

    if (this.fileTypes) {
      this.fileTypesObject = JSON.parse(this.fileTypes);
    }
  }

  deleteAttachment(attachment) {
    const dialog = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'DELETE-FILE-MODAL.MODAL-TITLE'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.selectedTransactions && this.selectedTransactions.length > 1) {
          const transactionIds = [];
          this.selectedTransactions.forEach((t) =>
            transactionIds.push(
              FileType.objection === this.findFileType()
                ? t.itemPerObjectionId
                : t.id
            )
          );
          this.fileService
            .deleteFileForMultipleIds(attachment, transactionIds)
            .subscribe(() => {
              this.fileService.emitFunction();
            });
        } else {
          this.fileService.deleteFile(attachment).subscribe(() => {
            this.fileService.emitFunction();
          });
        }
      }
    });
  }

  downloadAttachment(file: AzureFile) {
    const fileType = this.findFileType();
    if (file['fileName'] && file['id']) {
      this.fileService
        .getFile(fileType, file['fileName'], file['id'])
        .subscribe((data) => {
          const url = window.URL.createObjectURL(data);
          const anchor = document.createElement('a');
          anchor.download = file.fileName;
          anchor.href = url;
          anchor.click();
        });
    }
  }

  findFileType() {
    switch (this.attachmentType) {
      case 'transaction-objection':
        return FileType.objection;
      case 'transaction':
        return FileType.transaction;
      case 'companyBankAccount':
        return FileType.companyBankAccount;
      case 'sepaMandate':
        return FileType.sepaMandate;
    }
  }

  getUserInfo(attachment) {
    if (attachment) {
      return !isEmpty(attachment.firstName) && !isEmpty(attachment.lastName)
        ? attachment.lastName +
            ', ' +
            attachment.firstName +
            ' (' +
            attachment.uploadUser +
            ')'
        : '';
    }
  }

  isDeletedRow(attachment) {
    if (!this.fileTypesObject) {
      return;
    }
    if (this.fileTypesObject.deletedFiles.length > 0) {
      return this.fileTypesObject.deletedFiles.find(
        (r) => r === attachment.fileName
      );
    }
  }

  isNewRow(attachment) {
    if (!this.fileTypesObject) {
      return;
    }
    if (this.fileTypesObject.draftFiles.length > 0) {
      return this.fileTypesObject.draftFiles.find((r) => r === attachment);
    }
  }
}
