import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputComponent } from './mat-input/mat-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { MatSliderComponent } from './mat-slider/mat-slider.component';
import { MatInputAutocompleteComponent } from './mat-input-autocomplete/mat-input-autocomplete.component';
import { MatSelectComponent } from './mat-select/mat-select.component';
import { MatRadioComponent } from './mat-radio/mat-radio.component';
import { MatTextareaComponent } from './mat-textarea/mat-textarea.component';
import { SortByPipe } from './pipes/sortBy/sort-by.pipe';
import { MatSelectMultipleComponent } from './mat-select-multiple/mat-select-multiple.component';
import { MatInputDateComponent } from './mat-input-date/mat-input-date.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatErrorsComponent } from './mat-errors/mat-errors.component';

@NgModule({
  declarations: [
    MatInputComponent,
    MatSliderComponent,
    MatInputAutocompleteComponent,
    MatSelectComponent,
    MatRadioComponent,
    MatTextareaComponent,
    SortByPipe,
    MatSelectMultipleComponent,
    MatInputDateComponent,
    MatErrorsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    NgSelectModule,
    BackButtonModule,
    MatRadioModule,
    CurrencyMaskModule,
    DragDropModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    NgxMaskModule,
    MatStepperModule,
    MatSliderModule,
    CurrencyMaskModule,
    ScrollingModule,
  ],
  exports: [
    MatInputComponent,
    MatSliderComponent,
    MatInputAutocompleteComponent,
    MatSelectComponent,
    MatRadioComponent,
    MatTextareaComponent,
    MatSelectMultipleComponent,
    MatInputDateComponent,
  ],
  providers: [],
})
export class FormElementsModule {}
