export const openItemColumns = [
  'select',
  'clientDebtorId',
  'clientOpenItemId',
  'openItemDate',
  'bookingCode',
  'card',
  'amount',
  'currency',
  'vatAmount',
  'dueDate',
  'debtCollection',
  'actions',
];
