import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingZeros',
})
export class LeadingZerosPipe implements PipeTransform {
  transform(value: any, targetLength: number) {
    return value.toString().padStart(targetLength, '0');
  }
}
