import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from 'src/app/shared/models/dialog-data';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-save-favorite-filter-modal',
  templateUrl: './save-favorite-filter-modal.component.html',
  styleUrls: ['./save-favorite-filter-modal.component.less'],
})
export class SaveFavoriteFilterModalComponent implements OnInit {
  data: any;
  entityName = 'saveFavFilter';
  approvalForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.data = {
      name: '',
      event: 'save',
    };
  }

  ngOnInit() {
    this.approvalForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
}
