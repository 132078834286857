import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { TitleBlinker } from '../../services/utils/title-blinker.service';

export interface SessionExpiredDialogData {
  timeout: number;
}

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
  styleUrls: ['./session-expired-modal.component.less'],
})
export class SessionExpiredModalComponent implements OnInit, OnDestroy {
  timeout = 120;
  timeoutString = '1:20';
  interval;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SessionExpiredDialogData,
    private dialogRef: MatDialogRef<SessionExpiredModalComponent>,
    private titleBlinker: TitleBlinker,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.dialogData) {
      this.timeout = this.dialogData.timeout;
    }
    this.timeoutString = moment.utc(this.timeout * 1000).format('mm:ss');
    this.observableTimer();
  }

  observableTimer() {
    this.titleBlinker.blink(
      this.translateService.instant('DIALOGS.SESSION-EXPIRES.MESSAGE', {
        timeout: moment.utc(this.timeout * 1000).format('mm:ss'),
      }),
      10
    );
    this.interval = setInterval(() => {
      if (this.timeout > 0) {
        this.timeout--;
        this.timeoutString = moment.utc(this.timeout * 1000).format('mm:ss');
      } else {
        this.dialogRef.close(false);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
