import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormGroup } from '@angular/forms';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { DebtorService } from 'src/app/shared/services/debtor/debtor.service';
import { Debtor } from 'src/app/shared/models/debtor';
import { openItemColumns } from 'src/app/in-memory-data/open-item/table-columns';
import { OpenItem } from 'src/app/shared/models/open-item';
import { openItemEmptyObject } from 'src/app/in-memory-data/open-item/open-item-empty-object';
import { openItemColumnState } from 'src/app/in-memory-data/open-item/open-item-column-state';
import { AddDeductionModalComponent } from 'src/app/shared/modals/add-deduction-modal/add-deduction-modal.component';
import { AddDunningModalComponent } from 'src/app/shared/modals/add-dunning-modal/add-dunning-modal.component';
import { openItemColumnsConf } from 'src/app/in-memory-data/open-item/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';

@Component({
  selector: 'app-open-item-list',
  templateUrl: './open-item-list.component.html',
  styleUrls: ['./open-item-list.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class OpenItemListComponent implements OnInit {
  /*Info added to display table wth new style */

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() tableData: any;
  businessUnit: any;
  clientId: any;
  sharedTableData: any;

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  displayedColumns;
  columns = openItemColumnsConf;
  entityName = 'open-item';

  clientDebtorIds = new Array<any>();
  openTasks: any = [];
  formGroup: UntypedFormGroup;
  post: any = '';
  result: any;
  debtors: Debtor[] = [];
  data: OpenItem[] = openItemEmptyObject;

  columnState: any = {};
  displayedColumnsTemp = [];
  columnsTemp = [];
  dateFormat = '';
  private CONFIGURATION_KEY = 'open_item_list';
  constructor(
    public dialog: MatDialog,
    private openItemService: OpenItemService,
    private debtorService: DebtorService,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.notificationService.dismissMessage();
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    this.dataSource.sort = this.sort;
    this.openItemService.currentSharedTableData.subscribe(
      (sharedTableData) => (this.sharedTableData = sharedTableData)
    );

    this.openItemService.currentSharedDisplayedColumns.subscribe(
      (displayedColumns) => (this.displayedColumns = displayedColumns)
    );

    this.openItemService.updateSharedDisplayedColumns(openItemColumns);

    if (this.openItemService.subsVar === undefined) {
      this.openItemService.subsVar =
        this.openItemService.invokeFirstComponentFunction.subscribe(
          (newRow: any) => {
            this.getOpenItems(newRow);
          }
        );
    }

    this.getTableConfiguration();

    this.columnState = openItemColumnState;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getOpenItems(newRow: any) {
    this.dataSource.data.push(newRow[0].value);
    this.dataSource.filter = '';
  }

  getClientDebtorIds() {
    for (const debtor of this.debtors) {
      this.clientDebtorIds.push(debtor.clientDebtorId);
    }

    this.openItemService.updateClientDebtorIds(this.clientDebtorIds);
  }

  searchClient() {
    this.debtorService.getDebtorsForClient(this.clientId).subscribe(
      (data) => {
        this.debtors = data;
        this.getClientDebtorIds();
      },
      (error) => console.log(error)
    );
  }

  listDeduction(i: any) {
    const dialogRef = this.dialog.open(AddDeductionModalComponent, {
      data: {
        deductions: this.dataSource.data[i].deductions,
        create: false,
      },
      minWidth: '300px',
      panelClass: 'custom-tabs',
    });

    dialogRef.afterClosed().subscribe((data) => {
      // This need to be checked because the modal is passing some values and this values
      // at this moment are not used 'data'
    });
  }

  listDunningInfo(i: any) {
    const dialogRef = this.dialog.open(AddDunningModalComponent, {
      data: {
        dunningInfo: this.dataSource.data[i].dunningInfo,
        create: false,
      },
      minWidth: '300px',
      panelClass: 'custom-tabs',
    });

    dialogRef.afterClosed().subscribe((data) => {
      // This need to be checked because the modal is passing some values and this values
      // at this moment are not used 'data'
    });
  }

  tableChanged() {
    this.columnsTemp.forEach((val) => {
      this.columns.push(Object.assign({}, val));
    });

    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);

    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };

    this.userService.insertConfiguration(configuration).subscribe(() => {
      this.openItemService.updateSharedDisplayedColumns(this.displayedColumns);
    });
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                openItemColumnsConf,
                openItemColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);

    this.openItemService.updateSharedDisplayedColumns(this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = openItemColumnsConf;
    this.displayedColumns = openItemColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.openItemService.updateSharedDisplayedColumns(this.displayedColumns);
  }
}
