import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentManagementService } from '../../../../../shared/services/animal-welfare/document-management/document-management.service';
import { salesDocumentsListColumns } from '../../../../../in-memory-data/animal-welfare/participation-fee/table-configuration/sales-documents/table-columns';
import { salesDocumentsListColumnsConf } from '../../../../../in-memory-data/animal-welfare/participation-fee/table-configuration/sales-documents/table-columns-configuration';
import { DefaultTableComponent } from '../../../../../shared/tables/default-table/default-table.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TablesService } from '../../../../../shared/tables/tables.service';
import { NotificationService } from '../../../../../shared/services/notification/notification.service';

@Component({
  selector: 'app-aw-participation-fee-documents',
  templateUrl: './aw-participation-fee-documents.component.html',
  styleUrls: ['./aw-participation-fee-documents.component.less'],
})
export class AwParticipationFeeDocumentsComponent implements OnInit, OnDestroy {
  @Input() configurationKey = 'aw_participation_fee_documents';
  columns = salesDocumentsListColumns;
  columnConfiguration = salesDocumentsListColumnsConf;
  documentList;
  dataLoaded = false;
  entityName = 'aw-participation-fee-documents';
  task = false;
  subject = new Subject();
  searchString: any = '';
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  docName = [
    'AW_ParticipationFeeFundDepositsSales',
    'AW_ParticipationFeeSales',
    'AW_AnnualBilling_Poultry',
    'AW_AnnualBilling_Pig',
  ];

  constructor(
    private documentManagementService: DocumentManagementService,
    private tableService: TablesService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.documentManagementService.resetFilters();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.filterDocuments();
    });
    if (window.history.state.task) {
      this.task = true;
      const onlyVisibleDocuments = false;
      this.documentManagementService
        .findAllForTask(
          window.history.state.task.guid,
          0,
          20,
          onlyVisibleDocuments
        )
        .subscribe((data) => {
          this.documentList = data;
          this.dataLoaded = true;
        });
    } else {
      this.documentManagementService
        .findAllByFilter(this.searchString, this.docName, 0, 20)
        .subscribe((data) => {
          this.documentList = data;
          this.dataLoaded = true;
        });
    }
    this.tableService.customEventSubscriptionEmitter =
      this.tableService.customEvent
        .pipe(debounceTime(500))
        .subscribe((data) => this.downloadFile(data));
  }

  private downloadFile(element: any) {
    this.documentManagementService
      .download(element.id, element.documentName)
      .subscribe(
        (data) => {
          if (!data || data.size === 0) {
            this.notificationService.showToast(
              'ANIMAL-WELFARE.PARTICIPATION-FEE.DOWNLOAD-FILE.INFO',
              this.notificationService.MESSAGE_TYPE.INFO
            );
          } else {
            const url = window.URL.createObjectURL(data);
            const anchor = document.createElement('a');
            anchor.download = element.documentName;
            anchor.href = url;
            anchor.click();
          }
        },
        (error) => this.handleError(error)
      );
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.name,
      }
    );
  }

  filterEvent(event: any) {
    const pageIndex = event.pageIndex ? event.pageIndex : 0;
    const pageSize = event.pageSize ? event.pageSize : 20;
    if (event['sortOrder']) {
      event['sortDirection'] = event['sortOrder'];
    }
    if (event.filteredValues) {
      this.documentManagementService.configObj.filteredValues =
        this.documentManagementService.configObj.filteredValues.filter(
          (fv) => fv.choice !== event.filteredValues.choice
        );
      this.documentManagementService.configObj.filteredValues.push(
        event.filteredValues
      );
    } else {
      this.documentManagementService.configObj = {
        ...this.documentManagementService.configObj,
        ...event,
      };
      if (event.choice) {
        this.documentManagementService.configObj.sortField = event.choice;
      }
      if (event.direction) {
        this.documentManagementService.configObj.sortDirection =
          event.direction;
      }
    }
    if (this.task) {
      const onlyVisibleDocuments =
        window.history.state.task.type !== 'AW_PRINT_AND_SEND_ANNUAL_BILLING';
      this.documentManagementService
        .findAllForTask(
          window.history.state.task.guid,
          pageIndex,
          pageSize,
          onlyVisibleDocuments
        )
        .subscribe((data) => {
          this.documentList = data;
          this.dataLoaded = true;
        });
    } else {
      this.documentManagementService
        .findAllByFilter(this.searchString, this.docName, pageIndex, pageSize)
        .subscribe((data) => {
          this.documentList = data;
          this.dataLoaded = true;
        });
    }
  }

  startSearch() {
    this.defaultTableComponent.setLoaderSpinner(true);
    this.subject.next(undefined);
  }

  private filterDocuments() {
    this.documentManagementService
      .findAllByFilter(this.searchString, this.docName, 0, 20)
      .subscribe((data) => {
        this.documentList = data;
        this.dataLoaded = true;
        this.defaultTableComponent.setLoaderSpinner(true);
      });
  }

  ngOnDestroy(): void {
    this.tableService.customEventSubscriptionEmitter.unsubscribe();
  }
}
