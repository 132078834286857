<nav mat-tab-nav-bar>
  <a
    mat-tab-link
    [routerLink]="['bu']"
    *ngIf="hasRoleForPath('bu')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-bu"
    >{{ 'MASTER-DATA.BU' | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['accountStatement']"
    *ngIf="hasRoleForPath('accountStatement')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-account-statement-processing"
    >{{ 'SYSTEM-CONFIGURATION.ACCOUNT-STATEMENT-PROCESSING' | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['taskConfigurations']"
    *ngIf="hasRoleForPath('taskConfigurations')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-task-configurations"
    >{{ 'SYSTEM-CONFIGURATION.TASK-CONFIGURATION' | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['booking']"
    *ngIf="hasRoleForPath('booking')"
    routerLinkActive
    #rla="routerLinkActive"
    id="{{ entityName }}-menu-bookingCode"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    >{{ 'MASTER-DATA.BOOKING-CODE' | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['subLedgerAccounts']"
    *ngIf="hasRoleForPath('subLedgerAccounts')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-sub-ledger-accounts-overview"
    >{{ 'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT-OVERVIEW' | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['translationManagement']"
    *ngIf="hasRoleForPath('translationManagement')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-translation-management"
    >{{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATIONS' | translate }}</a
  >

  <ng-container *hasBUProductPermission="'ANIMAL_WELFARE'">
    <a
      mat-tab-link
      [routerLink]="['systemConfiguration']"
      *ngIf="hasRoleForPath('systemConfiguration')"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-systemConfiguration"
      >{{ 'ANIMAL-WELFARE.TABS.SYSTEM-CONFIGURATION' | translate }}</a
    >
  </ng-container>

  <a
    mat-tab-link
    [routerLink]="['objection']"
    *ngIf="hasRoleForPath('objection')"
    routerLinkActive
    #rla="routerLinkActive"
    id="{{ entityName }}-menu-objectionCode"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    >{{ 'MASTER-DATA.OBJECTION-CODE' | translate }}</a
  >
</nav>
<div class="content-container">
  <router-outlet></router-outlet>
</div>
