/* eslint-disable @angular-eslint/no-input-rename */
import {
  AfterViewInit,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({ selector: '[appHighlightDirective]' })
export class HighLightInfoDirective implements AfterViewInit, OnChanges {
  @Input('appHighlightDirective') currentValue: any;
  // tslint:disable-next-line:no-input-rename
  @Input('originalValue') originalValue: any;
  // Used to set the val-changed class to the html element
  @HostBinding('class') classes = '';

  ngAfterViewInit(): void {
    this.classes = this.setClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.setClassOnChangeValues(changes.currentValue);
  }

  setClass(): string {
    return this.currentValue !== this.originalValue ? 'val-changed' : '';
  }

  setClassOnChangeValues(changes) {
    return changes.currentValue !== changes.previousValue &&
      changes.currentValue !== this.originalValue
      ? 'val-changed'
      : '';
  }
}
