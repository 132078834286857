import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultTableComponent } from '../../shared/tables/default-table/default-table.component';
import { EmailService } from 'src/app/shared/services/email/email.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-email-attachment-download',
  templateUrl: './email-attachment-download.component.html',
  styleUrls: ['./email-attachment-download.component.less'],
})
export class EmailAttachmentDownloadComponent implements OnInit {
  configurationKey = 'emailAttachmentDownload';
  documentList = {};
  dataLoaded = false;
  entityName = 'email-attachment-download';
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  documentsInspected = [];

  constructor(
    private emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.downloadFile(id);
  }

  private downloadFile(id: any) {
    this.emailService.download(id).subscribe((data) => {
      const fileName = this.getFileNameFromHttpResponse(
        data.headers.get('content-disposition')
      );
      console.log(fileName);
      const url = window.URL.createObjectURL((data as any).body);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
      if (!this.documentsInspected.includes(fileName)) {
        this.documentsInspected.push(fileName);
      }
      this.router.navigate(['/']);
    });
  }

  getFileNameFromHttpResponse(contentDispositionHeader) {
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }
}
