import {
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import Quill from 'quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const SELECTOR = 'rich-editor';

@Component({
  selector: SELECTOR,
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: RichTextEditorComponent,
    },
  ],
})
export class RichTextEditorComponent
  implements
    OnInit,
    DoCheck,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<any>
{
  get value(): any {
    return this.valueVar;
  }
  set value(value) {
    this.valueVar = value;
    this.editor.setContents(this.valueVar);
    this.onChange(value);
    this.stateChanges.next(undefined);
  }
  @Input()
  get placeholder() {
    return this.placeholderVar;
  }
  set placeholder(plh) {
    this.placeholderVar = plh;
    this.stateChanges.next(undefined);
  }
  @Input()
  get required() {
    return this.requiredVar;
  }
  set required(req) {
    this.requiredVar = coerceBooleanProperty(req);
    this.stateChanges.next(undefined);
  }
  @Input()
  get disabled() {
    return this.disabledVar;
  }
  set disabled(disabled) {
    this.disabledVar = coerceBooleanProperty(disabled);
    this.stateChanges.next(undefined);
  }
  get empty() {
    const text = this.editor.getText().trim();
    return !text;
  }
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  constructor(
    public elRef: ElementRef,
    public injector: Injector,
    public fm: FocusMonitor
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next(undefined);
    });
  }

  static nextId = 0;
  @HostBinding() id = `rich-editor-${RichTextEditorComponent.nextId++}`;
  @ViewChild('container', { read: ElementRef, static: true })
  container: ElementRef;
  stateChanges = new Subject<void>();
  quill: any = Quill;
  editor: any;
  controlType = 'rich-editor';
  errorState = false;
  ngControl: any;
  touched = false;
  focused = false;
  private writingValue = false;
  private defaultOptions = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'], // toggled buttons

        [{ list: 'ordered' }, { list: 'bullet' }],

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        // ['clean']
      ],
    },
  };
  public valueVar: any;
  public placeholderVar: string;
  public requiredVar = false;
  public disabledVar = false;

  @Input()
  options: any = null;
  @HostBinding('attr.aria-describedby') describedBy = '';

  private static isEmpty(contents: any): boolean {
    if (contents.ops.length > 1) {
      return false;
    }
    const opsTypes: Array<string> = Object.keys(contents.ops[0]);
    if (opsTypes.length > 1) {
      return false;
    }
    if (opsTypes[0] !== 'insert') {
      return false;
    }
    return contents.ops[0].insert === '\n';
  }
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  ngOnInit(): void {
    // avoid Cyclic Dependency
    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    const editorRef = this.container.nativeElement.querySelector('.editor');
    const options = this.options || this.defaultOptions;
    if (typeof options.theme === 'undefined') {
      options.theme = 'snow';
    }
    this.editor = new Quill(editorRef, options);
    this.editor.on('text-change', () => {
      if (!this.writingValue) {
        this.onChange(this.getValue());
      }
    });
  }
  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState =
        this.ngControl.invalid && this.ngControl.touched && !this.focused;
      this.stateChanges.next(undefined);
    }
  }
  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
  writeValue(content: any): void {
    if (this.editor && content) {
      this.writingValue = true;
      const delta = this.editor.clipboard.convert(content); // convert html to delta
      this.editor.setContents(delta);
      this.valueVar = content;
      this.writingValue = false;
    }
  }
  onChange = (value: any) => {
    /**
     * This function is required by ControlValueAccessor implemented by this controller
     * The sonarqube don't like to much of empty function. So this comment is to makes the sonarqube happy.
     * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
     */
  };
  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  onTouched = () => {
    /**
     * This function is required by ControlValueAccessor implemented by this controller
     * The sonarqube don't like to much of empty function. So this comment is to makes the sonarqube happy.
     * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
     */
  };

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  onContainerClick(event: MouseEvent) {
    if (!this.focused) {
      this.editor.focus();
      this.focused = true;
      this.stateChanges.next(undefined);
    }
  }
  private getValue(): any | undefined {
    if (!this.editor) {
      return undefined;
    }
    const delta: any = this.editor.getContents();
    if (RichTextEditorComponent.isEmpty(delta)) {
      return undefined;
    }
    const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
    return converter.convert();
  }
}
