import { AfterViewInit, Component } from '@angular/core';
import { systemDefaultLanguages } from 'src/app/in-memory-data/languages/system-default-languages';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';

@Component({
  selector: 'app-website-credits',
  templateUrl: './website-credits.component.html',
  styleUrls: ['./website-credits.component.less'],
})
export class WebsiteCreditsComponent implements AfterViewInit {
  systemLangauges = systemDefaultLanguages;

  currentLanguage: string;

  constructor(private translationService: TranslationService) {}

  ngAfterViewInit(): void {
    this.translationService.currentSelectedLanguage.subscribe((lang) => {
      this.currentLanguage = lang;
    });
  }

  isEnglishLang() {
    const currentLang = this.systemLangauges.find(
      (lang) => lang.code === this.currentLanguage
    );
    if (currentLang?.code === 'en') {
      return true;
    } else {
      return false;
    }
  }
}
