import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { awTypes } from 'src/app/in-memory-data/business-partner/enum/awTypes';
import { factoringTypes } from 'src/app/in-memory-data/business-partner/enum/factoring-types';
import { businessPartnerSelectionColumns } from 'src/app/in-memory-data/business-partner/table-columns';
import { businessPartnerSelectionColumnsConf } from 'src/app/in-memory-data/business-partner/table-columns-configuration';
import { productEnum } from 'src/app/in-memory-data/business-unit/enums/product-enum';
import { Client } from '../../models/client';
import { DialogData } from '../../models/dialog-data';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { OpenItemService } from '../../services/open-item/open-item.service';

@Component({
  selector: 'app-business-partner-selection-modal',
  templateUrl: './business-partner-selection-modal.component.html',
  styleUrls: ['./business-partner-selection-modal.component.less'],
})
export class BusinessPartnerSelectionModalComponent implements OnInit {
  filterForm: UntypedFormGroup;
  searchInput: any = '';
  clientList: Client[];
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<Client>();

  columns = businessPartnerSelectionColumnsConf;
  displayedColumns = businessPartnerSelectionColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  types = [];
  transactionData: any;

  threeDots = false;
  data: any;
  entityName = 'business-partner-selection-modal';
  configurationKey = 'business-partner-selection-modal-table';

  noClients = false;

  errorBU = false;

  productTypes = productEnum;

  businessUnits: any;
  businessUnitProduct: any;

  sharedDataSource: any;
  dataLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<BusinessPartnerSelectionModalComponent>,
    private businessUnitService: BusinessUnitService,
    private openItemService: OpenItemService
  ) {
    this.filterFormBuilder();
    this.setFilterFormOptions();
  }

  ngOnInit() {
    this.selection = new SelectionModel(true, []);
    this.businessUnits = this.businessUnitService.getAllBusinessUnitsLocal();
    this.businessUnitProduct = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).product;
    this.loadPartners();

    this.filterForm.valueChanges.subscribe((data) => {
      this.dataLoaded = false;
      this.loadPartners();
    });
  }

  loadPartners(event?) {
    let configObj;
    if (event) {
      configObj = {
        filterText: this.filterForm.get('searchString').value
          ? this.filterForm.get('searchString').value
          : '',
        type: this.filterForm.get('businessPartnerType').value
          ? this.filterForm.get('businessPartnerType').value
          : '',
        product: this.businessUnitProduct,
        pageSize: event.pageSize,
        pageIndex: event.pageIndex,
        fullSearch: true,
        sortField: event.choice,
        sortDirection: event.direction.toUpperCase(),
      };
    } else {
      configObj = {
        filterText: this.filterForm.get('searchString').value
          ? this.filterForm.get('searchString').value
          : '',
        type: this.filterForm.get('businessPartnerType').value
          ? this.filterForm.get('businessPartnerType').value
          : '',
        product: this.businessUnitProduct,
        fullSearch: true,
      };
    }

    this.openItemService.listPartners(configObj).subscribe((data) => {
      this.transactionData = data;
      this.dataLoaded = true;
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
      businessPartnerType: null,
    });
  }

  setFilterFormOptions() {
    if (this.businessUnitService.getAllBusinessUnitsLocal().length > 0) {
      const currentBU = this.businessUnitService
        .getAllBusinessUnitsLocal()
        .find(
          (bu) =>
            bu.id.toString() ===
            this.businessUnitService.getCurrentBusinessUnit()
        );
      if (currentBU) {
        if (
          currentBU.product != null &&
          this.productTypes.find((prod) => prod.code === currentBU.product)
            .value === 'FACTORING'
        ) {
          this.types = factoringTypes;
        } else if (
          currentBU.product != null &&
          this.productTypes.find((prod) => prod.code === currentBU.product)
            .value === 'ANIMAL_WELFARE'
        ) {
          this.types = awTypes;
        } else {
          this.errorBU = true;
        }
      } else {
        this.errorBU = true;
      }
    } else {
    }
  }

  filter(event) {
    if (!event) {
      event = {};
    }
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }

    this.loadPartners(event);
  }
}
