import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiple-return-codes-per-task',
  templateUrl: './multiple-return-codes-per-task.component.html',
  styleUrls: ['./multiple-return-codes-per-task.component.less'],
})
export class MultipleReturnCodesPerTaskComponent implements OnInit {
  task;

  ngOnInit(): void {
    if (window.history.state.task) {
      this.task = window.history.state.task;
    }
    if (!this.task.params.returnCode && this.task.params.objectId) {
      this.task.params.returnCode = this.task.params.objectId;
    }
  }
  get returnCodes() {
    const returnCodeList = this.task.params.returnCode
      .split(',')
      .map((item) => item.trim());
    const count = (returnCodes) =>
      returnCodes.reduce((a, b) => ({ ...a, [b]: (a[b] || 0) + 1 }), []);
    return count(returnCodeList);
  }
}
