import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { userManagementRoleKnownUserColumns } from 'src/app/in-memory-data/user-management/role/role-known-user/table-columns';
import { userManagementRoleKnownUserColumnsConf } from 'src/app/in-memory-data/user-management/role/role-known-user/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../services/shared-data.service';
import { realmsEnum } from 'src/app/in-memory-data/user-management/enums/realm-enum';

@Component({
  selector: 'app-role-known-user',
  templateUrl: './role-known-user.component.html',
  styleUrls: ['./role-known-user.component.less'],
})
export class RoleKnownUserComponent implements AfterViewInit {
  configurationKey = 'user-management-role-known-user';
  displayedColumns = userManagementRoleKnownUserColumns;
  columns = userManagementRoleKnownUserColumnsConf;
  userList = [];
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  dataLoaded = false;
  loaderSpinner = true;
  state;
  configs = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    realm: '',
  };
  usersLoaded;
  searchString: any = '';
  subject = new Subject();

  approvedRole;

  realms = realmsEnum;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.approvedRole.subscribe((data) => {
      this.approvedRole = data;
    });
  }

  getCustomColumnClass = (element, column): any => {
    const customClasses = [];
    if (column === 'status') {
      if (this.getColumnValue(element)) {
        customClasses.push(this.getColumnValue(element));
      }
    }
    if (column === 'online') {
      if (this.getOnlineColumnValue(element)) {
        customClasses.push(this.getOnlineColumnValue(element));
      }
    }
    return customClasses;
  };

  ngAfterViewInit() {
    this.loadData();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loadData();
    });
  }

  getColumnValue(columnValue) {
    switch (columnValue.status) {
      case 'ACTIVE':
        return 'status-green';
      case 'IN_INITIALISATION':
        return 'status-yellow';
      case 'BLOCKED_PASSWORD':
        return 'status-orange';
      case 'BLOCKED':
      case 'BLOCKED_MANUALLY':
      case 'BLOCKED_BY_INACTIVITY':
        return 'status-red';
      default:
        return '';
    }
  }

  getOnlineColumnValue(columnValue) {
    if (columnValue.online === true) {
      return 'status-green';
    } else {
      return 'status-red';
    }
  }

  startSearch() {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    this.subject.next(undefined);
  }

  filter(event) {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }
    if (event.filteredValues) {
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
    }
    this.loadData();
  }

  loadData() {
    this.configs.filterText = this.searchString;
    this.loaderSpinner = true;
    this.defaultTableComponent.setLoaderSpinner(true);
    this.state = window.history.state;
    const snapshot = this.route.snapshot;
    let realm;
    let roleName;
    if (snapshot.params && snapshot.params.guid && this.state.editMode) {
      if (
        !this.state.param.internalRole ||
        (this.state.task &&
          this.state.task.params &&
          !this.state.task.params.realm)
      ) {
        realm = this.realms[1].name.toLowerCase();
      } else {
        realm = this.realms[0].name.toLowerCase();
      }
      roleName = this.state.param.roleName;
    } else if (this.state.task) {
      if (this.state.task.params?.realm === 'internal') {
        realm = this.realms[0].name.toLowerCase();
      } else {
        realm = this.realms[1].name.toLowerCase();
      }

      roleName = this.approvedRole.roleName;
    }
    this.configs.realm = realm;
    this.userService
      .getRoleKnownUsers(
        {
          ...this.configs,
          filteredValues: JSON.stringify(this.configs.filteredValues),
        },
        roleName
      )
      .pipe(first())
      .subscribe((roleUsersData) => {
        this.usersLoaded = [];
        for (let i = 0; i < roleUsersData['content'].length; i++) {
          const role = roleUsersData['content'][i];
          this.userService
            .getSearchApiUser(role.keycloakUserId)
            .pipe(first())
            .subscribe((result) => {
              roleUsersData['content'][i] = {
                ...result,
                ...role,
              };
              this.usersLoaded.push(i);
              if (this.usersLoaded.length === roleUsersData['content'].length) {
                this.userList = roleUsersData;
                this.dataLoaded = true;
              }
            });
        }
        this.defaultTableComponent.setLoaderSpinner(false);
      });
  }
}
