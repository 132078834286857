<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)"
  [multiple]="multiple"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <span *ngIf="!transaction">
      {{ 'GENERAL.DRAG-DROP' | translate }}
    </span>
    <span *ngIf="transaction">
      {{ 'GENERAL.DRAG-DROP-TRANSACTION' | translate }}
    </span>
    <span *ngIf="required">&nbsp;*</span>
    <button type="button" class="custom-button" id="{{ entityName }}-add-file-button" (click)="openFileSelector()">
      {{ 'GENERAL.BROWSE-FILES' | translate }}
    </button>
  </ng-template>
</ngx-file-drop>

<div class="files-container" *ngIf="files && files.length > 0 && !transaction">
  <div class="title-files">
    {{ 'FILE-UPLOAD.TITLE-NEW-FILES' | translate }}
  </div>
  <div class="container-files">
    <div *ngFor="let item of files; let i = index" class="attachment-entry">
      <div class="name-container">
        <span class="file" (click)="openFile(item)">{{ item.relativePath | lowercase }}</span>
      </div>
      <mat-icon class="icon-button" (click)="removeFromNewFiles(item)">clear</mat-icon>
    </div>
  </div>
</div>

<span class="error-format" *ngIf="forbiddenFiles">
  {{ 'ERROR-MESSAGES.FILE-FORMAT-FILE-UPLOAD' | translate }}
</span>

<span class="error-format" *ngIf="multipleFilesError">
  {{ 'ERROR-MESSAGES.FORBIDEEN-MULTIPLE-FILES' | translate }}
</span>
