import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class AccountingSharedDataService {
  private sharedTab = new BehaviorSubject<any>(0);
  currentTab = this.sharedTab.asObservable();

  private sharedSpinnerState = new BehaviorSubject<boolean>(true);
  currentSharedSpinnerState = this.sharedSpinnerState.asObservable();

  sharedAccountingOpenTransactionsColumnsStateAW;
  sharedAccountingOpenTransactionsDisplayedColumnsStateAW;
  sharedAccountingOpenTransactionsColumnsStateNonAW;
  sharedAccountingOpenTransactionsDisplayedColumnsStateNonAW;

  eventEmiterFunction = new EventEmitter();
  suscriptionEmitter: Subscription;

  updateSharedTab(sharedTab: any) {
    this.sharedTab.next(sharedTab);
  }

  emitFunction(functionName) {
    this.eventEmiterFunction.emit(functionName);
  }

  updateSharedSpinnerState(sharedSpinnerState: any) {
    this.sharedSpinnerState.next(sharedSpinnerState);
  }
}
