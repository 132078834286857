import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private sharedEditTransactionMode = new BehaviorSubject<any>(null);
  currentSharedEditTransactionMode =
    this.sharedEditTransactionMode.asObservable();

  private sharedTransactionCode = new BehaviorSubject<any>(null);
  currentSharedTransactionCode = this.sharedTransactionCode.asObservable();

  updateSharedEditTransactionMode(sharedEditTransactionMode: any) {
    this.sharedEditTransactionMode.next(sharedEditTransactionMode);
  }

  updateSharedTransactionCode(sharedTransactionCode: any) {
    this.sharedTransactionCode.next(sharedTransactionCode);
  }
}
