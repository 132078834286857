<div class="input-wrapper form-container">
  <form [formGroup]="frmPermission" autocomplete="off" class="form">
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.id : null"
      [controlName]="'id'"
      [controlObject]="frmPermission.controls.id"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.ID'"
      [name]="'USER-MANAGEMENT.ID'"
      [placeholder]="'USER-MANAGEMENT.ID'"
      [taskValue]="taskValue ? taskValue.id : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.permissionId : null"
      [controlName]="'permissionId'"
      [controlObject]="frmPermission.controls.permissionId"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.PERMISSION-ID'"
      [name]="'USER-MANAGEMENT.PERMISSION-ID'"
      [placeholder]="'USER-MANAGEMENT.PERMISSION-ID'"
      [taskValue]="taskValue ? taskValue.permissionId : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.permissionName : null"
      [controlName]="'permissionName'"
      [controlObject]="frmPermission.controls.permissionName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.PERMISSION-NAME'"
      [name]="'USER-MANAGEMENT.PERMISSION-NAME'"
      [placeholder]="'USER-MANAGEMENT.PERMISSION-NAME'"
      [taskValue]="taskValue ? taskValue.permissionName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-textarea
      [approvedValue]="approvedValue ? approvedValue.description : null"
      [controlName]="'description'"
      [controlObject]="frmPermission.controls.description"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.DESCRIPTION'"
      [name]="'USER-MANAGEMENT.DESCRIPTION'"
      [placeholder]="'USER-MANAGEMENT.DESCRIPTION'"
      [taskValue]="taskValue ? taskValue.description : null"
      [titleTask]="false"
    ></app-mat-textarea>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.createdOn : null"
      [controlName]="'createdOn'"
      [controlObject]="frmPermission.controls.createdOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-ON'"
      [name]="'USER-MANAGEMENT.CREATED-ON'"
      [placeholder]="'USER-MANAGEMENT.CREATED-ON'"
      [taskValue]="taskValue ? taskValue.createdOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.createdBy : null"
      [controlName]="'createdBy'"
      [controlObject]="frmPermission.controls.createdBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-BY'"
      [name]="'USER-MANAGEMENT.CREATED-BY'"
      [placeholder]="'USER-MANAGEMENT.CREATED-BY'"
      [taskValue]="taskValue ? taskValue.createdBy : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.updatedOn : null"
      [controlName]="'updatedOn'"
      [controlObject]="frmPermission.controls.updatedOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.UPDATED-ON'"
      [name]="'USER-MANAGEMENT.UPDATED-ON'"
      [placeholder]="'USER-MANAGEMENT.UPDATED-ON'"
      [taskValue]="taskValue ? taskValue.updatedOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.updatedBy : null"
      [controlName]="'updatedBy'"
      [controlObject]="frmPermission.controls.updatedBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.UPDATED-BY'"
      [name]="'USER-MANAGEMENT.UPDATED-BY'"
      [placeholder]="'USER-MANAGEMENT.UPDATED-BY'"
      [taskValue]="taskValue ? taskValue.updatedBy : null"
      [titleTask]="false"
    ></app-mat-input>
  </form>
</div>

<div class="create-container">
  <button
    (click)="createPermission()"
    *ngIf="!isTask && !state.editMode"
    [disabled]="frmPermission.invalid"
    class="custom-button create"
    mat-button
  >
    {{ 'GENERAL.CREATE' | translate }}
  </button>
</div>
