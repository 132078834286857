<div *ngIf="(editableTask && isTask) || !isTask">
  <div class="form-row">
    <mat-slide-toggle
      [ngClass]="{ 'val-changed': changedValue }"
      formControlName="{{ controlName }}"
      name="{{ name }}"
      id="{{ entityName }}-form-{{ controlName }}"
      [disabled]="disabled"
    >
      <span class="material-icons material-check-icon"> done </span>
    </mat-slide-toggle>
    <label class="label-slide-toggle" for="{{ name }}">{{ label | translate }}</label>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
      >
        {{ taskValue }}
      </span>
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <span
        class="material-icons icon icon-good"
        [ngClass]="{
          'val-changed': approvedValue && taskValue != approvedValue
        }"
        *ngIf="taskValue"
      >
        done
      </span>

      <span
        class="material-icons icon"
        [ngClass]="{
          'val-changed': approvedValue && taskValue != approvedValue
        }"
        *ngIf="!taskValue"
      >
        remove
      </span>
      <span class="object-label">{{ label | translate }}</span>
    </div>
  </div>
</div>
