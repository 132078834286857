<div class="input-wrapper form-container">
  <form [formGroup]="generalUserInfoExternalForm" autocomplete="off" class="form">
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.keycloakUserId : null"
      [controlName]="'id'"
      [controlObject]="generalUserInfoExternalForm.controls.id"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [taskValue]="taskValue ? taskValue.keycloakUserId : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.userName : null"
      [controlName]="'userName'"
      [controlObject]="generalUserInfoExternalForm.controls.userName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [taskValue]="taskValue ? taskValue.userName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.email : null"
      [controlName]="'email'"
      [controlObject]="generalUserInfoExternalForm.controls.email"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.EMAIL'"
      [name]="'USER-MANAGEMENT.EMAIL'"
      [placeholder]="'USER-MANAGEMENT.EMAIL'"
      [taskValue]="taskValue ? taskValue.email : null"
      [titleTask]="false"
      [type]="'email'"
    ></app-mat-input>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.title : null"
      [controlName]="'title'"
      [controlObject]="generalUserInfoExternalForm.controls.title"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.TITLE'"
      [name]="'USER-MANAGEMENT.TITLE'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="titles"
      [placeholder]="'USER-MANAGEMENT.TITLE'"
      [selectedOption]="null"
      [taskValue]="taskValue ? taskValue.title : null"
      [textTransform]="'none'"
      [titleTask]="false"
      [titlecasePipe]="false"
      [translationOption]="'translateName'"
    ></app-mat-select>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.academicTitle : null"
      [controlName]="'academicTitle'"
      [controlObject]="generalUserInfoExternalForm.controls.academicTitle"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [name]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="academicTitles"
      [placeholder]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [selectedOption]="null"
      [taskValue]="taskValue ? taskValue.academicTitle : null"
      [textTransform]="'none'"
      [titleTask]="false"
      [titlecasePipe]="false"
      [translationOption]="'translateName'"
    ></app-mat-select>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.firstName : null"
      [controlName]="'firstName'"
      [controlObject]="generalUserInfoExternalForm.controls.firstName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.FIRST-NAME'"
      [name]="'USER-MANAGEMENT.FIRST-NAME'"
      [placeholder]="'USER-MANAGEMENT.FIRST-NAME'"
      [taskValue]="taskValue ? taskValue.firstName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.lastName : null"
      [controlName]="'lastName'"
      [controlObject]="generalUserInfoExternalForm.controls.lastName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.LAST-NAME'"
      [name]="'USER-MANAGEMENT.LAST-NAME'"
      [placeholder]="'USER-MANAGEMENT.LAST-NAME'"
      [taskValue]="taskValue ? taskValue.lastName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.telephoneNumber : null"
      [controlName]="'telephoneNumber'"
      [controlObject]="generalUserInfoExternalForm.controls.telephoneNumber"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [name]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [placeholder]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [taskValue]="taskValue ? taskValue.telephoneNumber : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.faxNumber : null"
      [controlName]="'faxNumber'"
      [controlObject]="generalUserInfoExternalForm.controls.faxNumber"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.FAX-NUMBER'"
      [name]="'USER-MANAGEMENT.FAX-NUMBER'"
      [placeholder]="'USER-MANAGEMENT.FAX-NUMBER'"
      [taskValue]="taskValue ? taskValue.faxNumber : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.birthday : null"
      [controlName]="'birthday'"
      [controlObject]="generalUserInfoExternalForm.controls.birthday"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.BIRTHDAY'"
      [maxDate]="null"
      [minDate]="null"
      [name]="'USER-MANAGEMENT.BIRTHDAY'"
      [placeholder]="'USER-MANAGEMENT.BIRTHDAY'"
      [taskValue]="taskValue ? taskValue.birthday : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.contactType : null"
      [controlName]="'contactType'"
      [controlObject]="generalUserInfoExternalForm.controls.contactType"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CONTACT-TYPE'"
      [name]="'USER-MANAGEMENT.CONTACT-TYPE'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="contactTypes"
      [placeholder]="'USER-MANAGEMENT.CONTACT-TYPE'"
      [selectedOption]="null"
      [taskValue]="taskValue ? taskValue.contactType : null"
      [textTransform]="'none'"
      [titleTask]="false"
      [titlecasePipe]="false"
      [translationOption]="'translateName'"
    >
    </app-mat-select>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.team : null"
      [controlName]="'team'"
      [controlObject]="generalUserInfoExternalForm.controls.team"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.TEAM'"
      [name]="'USER-MANAGEMENT.TEAM'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="teams"
      [placeholder]="'USER-MANAGEMENT.TEAM'"
      [selectedOption]="null"
      [taskValue]="taskValue ? taskValue.team : null"
      [textTransform]="'none'"
      [titleTask]="false"
      [titlecasePipe]="false"
      [translationOption]="'translateName'"
    ></app-mat-select>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.centralMasterData : null"
      [controlName]="'centralMasterData'"
      [controlObject]="generalUserInfoExternalForm.controls.centralMasterData"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CENTRAL-MASTER-DATA'"
      [name]="'USER-MANAGEMENT.CENTRAL-MASTER-DATA'"
      [placeholder]="'USER-MANAGEMENT.CENTRAL-MASTER-DATA'"
      [taskValue]="taskValue ? taskValue.centralMasterData : null"
      [titleTask]="false"
    >
    </app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.servicePin : null"
      [controlName]="'servicePin'"
      [controlObject]="generalUserInfoExternalForm.controls.servicePin"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.SERVICE-PIN'"
      [name]="'USER-MANAGEMENT.SERVICE-PIN'"
      [placeholder]="'USER-MANAGEMENT.SERVICE-PIN'"
      [taskValue]="taskValue ? taskValue.servicePin : null"
      [titleTask]="false"
    ></app-mat-input>
    <span
      (click)="showServicePinPolicy()"
      *ngIf="!isTask"
      class="extra-info"
      id="{{ entityName }}-service-pin-info"
      mat-icon-button
    >
      <mat-icon>info</mat-icon>
    </span>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.createdOn : null"
      [controlName]="'createdOn'"
      [controlObject]="generalUserInfoExternalForm.controls.createdOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-ON'"
      [name]="'USER-MANAGEMENT.CREATED-ON'"
      [placeholder]="'USER-MANAGEMENT.CREATED-ON'"
      [taskValue]="taskValue ? taskValue.createdOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.createdBy : null"
      [controlName]="'createdBy'"
      [controlObject]="generalUserInfoExternalForm.controls.createdBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-BY'"
      [name]="'USER-MANAGEMENT.CREATED-BY'"
      [placeholder]="'USER-MANAGEMENT.CREATED-BY'"
      [taskValue]="taskValue ? taskValue.createdBy : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.updatedOn : null"
      [controlName]="'updatedOn'"
      [controlObject]="generalUserInfoExternalForm.controls.updatedOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CHANGED-ON'"
      [maxDate]="null"
      [minDate]="null"
      [name]="'USER-MANAGEMENT.CHANGED-ON'"
      [placeholder]="'USER-MANAGEMENT.CHANGED-ON'"
      [taskValue]="taskValue ? taskValue.updatedOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.updatedBy : null"
      [controlName]="'updatedBy'"
      [controlObject]="generalUserInfoExternalForm.controls.updatedBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CHANGED-BY'"
      [name]="'USER-MANAGEMENT.CHANGED-BY'"
      [placeholder]="'USER-MANAGEMENT.CHANGED-BY'"
      [taskValue]="taskValue ? taskValue.updatedBy : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.validTo : null"
      [controlName]="'validTo'"
      [controlObject]="generalUserInfoExternalForm.controls.validTo"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.VALID-TO'"
      [maxDate]="null"
      [minDate]="null"
      [name]="'USER-MANAGEMENT.VALID-TO'"
      [placeholder]="'USER-MANAGEMENT.VALID-TO'"
      [taskValue]="taskValue ? taskValue.validTo : null"
      [titleTask]="false"
    >
    </app-mat-input-date>
    <app-mat-slider
      [approvedValue]="
        approvedValue && approvedValue.termsAndServicesAccepted ? approvedValue.termsAndServicesAccepted : null
      "
      [controlName]="'termsAndServicesAccepted'"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.TERMS-AND-SERVICES-ACCEPTED'"
      [name]="'USER-MANAGEMENT.TERMS-AND-SERVICES-ACCEPTED'"
      [taskValue]="taskValue && taskValue.termsAndServicesAccepted ? taskValue.termsAndServicesAccepted : null"
      [titleTask]="false"
    >
    </app-mat-slider>
    <app-mat-slider
      [approvedValue]="approvedValue && approvedValue.inIniProcess ? approvedValue.inIniProcess : null"
      [controlName]="'inIniProcess'"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.IN-INI-PROCESS'"
      [name]="'USER-MANAGEMENT.IN-INI-PROCESS'"
      [taskValue]="taskValue && taskValue.inIniProcess ? taskValue.inIniProcess : null"
      [titleTask]="false"
    ></app-mat-slider>
    <app-mat-slider
      [approvedValue]="approvedValue && approvedValue.externalSource ? approvedValue.externalSource : null"
      [controlName]="'externalSource'"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="generalUserInfoExternalForm"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.EXTERNAL-SOURCE'"
      [name]="'USER-MANAGEMENT.EXTERNAL-SOURCE'"
      [taskValue]="taskValue && taskValue.externalSource ? taskValue.externalSource : null"
      [titleTask]="false"
    ></app-mat-slider>
  </form>
</div>
