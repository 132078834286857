export const blockingIndicatorListColumnsConf: any[] = [
  {
    name: 'blocking_id',
    index: 0,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.ID',
    locked: true,
  },
  {
    name: 'type_of_blockage',
    index: 1,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.TYPE-OF-BLOCKAGE',
    locked: true,
  },
  {
    name: 'start_date',
    index: 2,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.START-DATE',
    locked: true,
  },
  {
    name: 'end_date',
    index: 3,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.END-DATE',
    locked: true,
  },
  {
    name: 'status',
    index: 4,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.STATUS',
    locked: true,
  },
  {
    name: 'comment',
    index: 5,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.BLOCKING-INDICATOR.COMMENT',
    locked: false,
  },
  {
    version: 3,
  },
];
