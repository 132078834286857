import { LeadingZerosPipe } from 'src/app/shared/leading-zero.pipe';
import { TableTypes } from '../../default-table/table-types';

export const accountingBillingTransactionListColumnsConf: any[] = [
  {
    name: 'flags',
    index: 1,
    checked: true,
    translateName: 'INFO-FLAG',
    locked: true,
    number: false,
    type: TableTypes.ICON,
  },
  {
    name: 'bookingCode',
    index: 2,
    checked: true,
    translateName: 'MASTER-DATA.BOOKING-CODE',
    locked: true,
    number: false,
    formatValueFunc: (value) => {
      const leadingZeroPipe = new LeadingZerosPipe();
      return leadingZeroPipe.transform(value, 3);
    },
    type: TableTypes.STRING,
  },
  {
    name: 'bookingCodeName',
    index: 3,
    checked: true,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.BOOKING-CODE-NAME',
    locked: true,
    number: false,
    columnEnglish: 'bookingCodeNameEn',
    columnGerman: 'bookingCodeNameDe',
    type: TableTypes.MULTI_TRANSLATED_STRING,
  },
  {
    name: 'openItemDate',
    index: 4,
    checked: true,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.OPEN-ITEM-DATE',
    locked: true,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'openAmount',
    index: 5,
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT',
    locked: true,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'id',
    index: 6,
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'creditDebitCode',
    index: 7,
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT',
    locked: true,
    number: false,
    translatePrefix: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT-VALUES.',
    type: TableTypes.TRANSLATED_STRING,
  },
  {
    name: 'amount',
    index: 8,
    checked: true,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.AMOUNT',
    locked: true,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'dueDate',
    index: 9,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.DUE-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'creationDate',
    index: 10,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.PAYMENT-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'processingEnd',
    index: 11,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PROCESSING-END',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },

  {
    name: 'lastChangeDate',
    index: 12,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.LAST-CHANGE-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'vatPercent',
    index: 13,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-PERCENT',
    locked: false,
    number: false,
    type: TableTypes.PERCENT,
  },
  {
    name: 'vatAmount',
    index: 14,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-AMOUNT',
    locked: false,
    number: false,
    type: TableTypes.CURRENCY,
  },

  {
    name: 'referenceDocumentation',
    index: 15,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-DOCUMENTATION',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'referenceText',
    index: 16,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-TEXT',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'originalAccount',
    index: 17,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ORIGINAL-ACCOUNT',
    locked: false,
    number: false,
    type: TableTypes.NUMBER,
  },
  {
    name: 'allocationNumber',
    index: 18,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-NUMBER',
    locked: false,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'currencyCode',
    index: 19,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'currencyAmount',
    index: 20,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'allocationTimestamp',
    index: 21,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-TIMESTAMP',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'allocatedAmount',
    index: 22,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATED-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'clientOpenItemId',
    index: 23,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-ID',
    locked: false,
    number: true,
    type: TableTypes.STRING,
  },
  {
    name: 'additionalInformation',
    index: 24,
    checked: false,
    translateName: 'TRANSACTION.ADDITIONAL-INFORMATION',
    locked: false,
    number: true,
    type: TableTypes.STRING,
  },
  {
    version: 5,
  },
];
