<div class="content-container">
  <div class="terms-of-service-container">
    <div class="row title-container underlined">
      <span>Terms of Service for BeVision</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >By accepting these terms of service I hereby apply for the usage of the online processing system (BeVision, in
        the following also called the “online portal”) of the BFS finance GmbH and commit myself to observing the terms
        of service.</span
      >
    </div>
    <div class="row text-container mt-row">
      <span
        >All e-mail messages sent by BFS via the online portal will be sent to the e-mail address provided as part of
        the contact person master data submitted by the Animal Welfare Initiative.</span
      >
    </div>
    <div class="row text-container mt-row">
      <span
        >BFS finance GmbH, hereinafter referred to as BFS, is entitled to further develop the online portal technically,
        legally and by adding new functions without the further consent of BeVision users.</span
      >
    </div>
    <div class="row text-container mt-row">
      <span
        >I am also obliged only to access and manage the information in the BFS finance GmbH Online portal for
        contractually agreed purposes and to observe the requirements of the relevant data protection and privacy laws
        in this regard, in particular in the event that for technical, system-related reasons, information/data becomes
        known beyond the customer relationship, to observe confidentiality and not pass information to any third
        parties.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="1">
        <li>Contents for BeVision participants</li>
      </ol>
    </div>
    <div class="row title-container mt-row">
      <span>For marketer</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant can access and change the data of their livestock owner and their quantities. Furthermore, it
        is possible to enter new movement data for livestock owners in the name of the associated abattoir.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>For livestock owner</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant can access their own master data and movement data reported by the coordinator and abattoir.
        The documents prepared for participants can also be accessed and called up as PDF files
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>For coordinators</span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant is able to enter the following movement data via the online-portal for the participating
        livestock owner directly:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>VVVO-No. (Registration number according to the German Livestock Transport Regulation)</li>
        <li>Production scope</li>
        <li>Reporting date</li>
        <li>Unit of measure (amount of animals or kg)</li>
        <li>Comments</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant is able to see or edit the movement data (transfer of quantity of goods for the livestock
        owners) via the online portal.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>View of data of livestock owners </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant is able to see the livestock owners, which are matched to him, via the online portal. He can
        also see their VVVO-No., their production scope and the name of the location in a structured table. The
        inspection can be made either by setting the VVVO-No. and/or the location name.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >In addition, the participant has the possibility to access the master data of the respective livestock owner
        via the table. The following information can be seen there:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>Contact data of the livestock owner</li>
        <li>Participation- and budget information</li>
        <li>Movement data</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >Furthermore, the participant can view all documents deposited for the respective livestock owner via the
        tabular display by downloading them from the archive:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>Quarterly billings</li>
        <li>Account statements</li>
        <li>Certificate of participation and revocation of participation</li>
      </ul>
    </div>
    <div class="row title-container mt-row">
      <span>For abattoirs </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant can directly enter the following movement data via the online portal for participating
        livestock owners:
      </span>
    </div>
    <div class="unordered-list-container-1 row mt-row">
      <ul>
        <li>VVVO-No. (Registration number according to the German Livestock Transport Regulation)</li>
        <li>Production scope</li>
        <li>Submission date</li>
        <li>Unit of measure (amount of animals or kg)</li>
        <li>Comments</li>
      </ul>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant can view and edit his movement data (reported quantities for livestock owners) via the online
        portal. Furthermore, the documents created for the participant can be accessed and viewed as PDF-files via the
        archive function.
      </span>
    </div>
    <div class="row title-container mt-row">
      <span>Data entry </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The participant is authorized to enter movement data for participating livestock owners independently or to
        carry out cancellations in the event of incorrect entries, also directly in BeVision. The participant checks
        carefully in BeVision beforehand whether the movement data to be entered does not already exist in the system
        and bears the sole responsibility for any incorrect entry.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="2">
        <li>User ID and password</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Each participant can log in to his/her protected data area with the help of a personal user ID, password and
        his personal second factor. The participant receives the user name and a personal initial registration link by
        e-mail after successful verification by BFS. In order to ensure the greatest possible security, the participant
        is required to change the password directly during the initial registration and thereafter (in accordance with
        the BFS finance GmbH password policy) on an currently annual basis. The system automatically prompts the
        participant to do this when logging in.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >In addition to the password, a so-called 'second factor' is also configured for multi-factor authentication
        (MFA) to protect the account in the initial setup. The token code generated by MFA is entered after the password
        when logging on to the online portal.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >As part of the initial registration, the participant also assigns a personal service PIN, which BFS can use to
        identify the participant when contact is made, e.g. by e-mail or telephone. This service PIN can be changed by
        the participant at any time in the personal profile of the online portal.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >The shared usage of one account by several people is prohibited. In the event of misuse, BFS refuses to accept
        a guarantee of any kind for the data transfer and their correctness.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="3">
        <li>Liability</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >1) The participant is responsible for ensuring that the password belonging to the user, the token code
        generated as part of the MFA and the personal service PIN are not accessible to third parties. They must be kept
        secret. The user assumes full responsibility for all actions taken using his/her user name in connection with
        his/her password. BFS excludes any liability for culpably caused damage resulting from unauthorized or incorrect
        use of password-protected logins of the user.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >2) The participant undertakes to inform BFS immediately of any misuse of his user as soon as he becomes aware
        of it. In the event of misuse, the user must immediately change his password and his personal service PIN.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span
        >3) In the event of a breach of these terms of service, BFS reserves the right to deny the user access to the
        online portal.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="4">
        <li>Severability clause</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Should any of the provisions of these terms of service be or become invalid in full or in part, this will not
        affect the validity of the remaining terms of service. This will apply in particular if the invalidity only
        relates to individual components. The invalid provision will be replaced by a provision whose content comes
        closest to fulfilling the intended economic purpose of the invalid provision in a legally effective manner.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="5">
        <li>Miscellaneous</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Unless expressly amended in these terms of service, the other contractual agreements between the parties remain
        unaffected by them.
      </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="6">
        <li>Place of jurisdiction</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span
        >Provided that the party involved is entered as a merchant in the commercial register, the place of fulfilment
        and jurisdiction for all disputes arising from this agreement will be Gütersloh.
      </span>
    </div>
    <div class="row text-container mt-row">
      <span>BFS finance GmbH </span>
    </div>
    <div class="row text-container">
      <span>Gütersloher Str. 123 </span>
    </div>
    <div class="row text-container">
      <span>33415 Verl </span>
    </div>
    <div class="row text-container">
      <span>Germany </span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="7">
        <li>Contact details</li>
      </ol>
    </div>
    <div class="row text-container mt-row">
      <span>BFS finance GmbH </span>
    </div>
    <div class="row text-container">
      <span>Carl-Bertelsmann Str. 23 </span>
    </div>
    <div class="row text-container">
      <span>33332 Gütersloh </span>
    </div>
    <div class="row text-container">
      <span>Germany </span>
    </div>
    <div class="row text-container">
      <span>Phone: +49 5241 80 - 43333 </span>
    </div>
    <div class="row text-container">
      <span>Fax: +49 5241 80 - 643333 </span>
    </div>
    <div class="row text-container">
      <span>E-Mail: initiative-tierwohl@bertelsmann.de </span>
    </div>
    <div class="row text-container">
      <span>Website: bfs-finance.com </span>
    </div>
  </div>
</div>
