import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { businessTransactionCodeColumnsConf } from 'src/app/in-memory-data/system-configuration/business-transaction-code/table-columns-configuration';
import { businessTransactionCodeColumns } from 'src/app/in-memory-data/system-configuration/business-transaction-code/table-columns';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { BusinessTransactionCodeService } from 'src/app/shared/services/business-transaction-code/business-transaction-code.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { BusinessTransactionCodeMappingService } from 'src/app/shared/services/business-transaction-code-mapping/business-transaction-code-mapping.service';
import { Router } from '@angular/router';
import { DraftService } from '../../../shared/services/draft/draft.service';

@Component({
  selector: 'app-btc-list',
  templateUrl: './btc-list.component.html',
  styleUrls: ['./btc-list.component.less'],
})
export class BtcListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = businessTransactionCodeColumnsConf;
  displayedColumns = businessTransactionCodeColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURAIONT_KEY = 'businessTransactionCodeData_table';
  businessTransactionCodeData = [];
  dataSource = new MatTableDataSource<any>(this.businessTransactionCodeData);
  searchInput: any;
  editMode: any;
  entityName = 'business-transaction-code';
  btcMappings: any;

  filter;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private businessTransactionCodeService: BusinessTransactionCodeService,
    private notificationService: NotificationService,
    private userService: UserService,
    private searchService: SearchService,
    private matDialogService: MatDialog,
    private businessTransactionCodeMappingService: BusinessTransactionCodeMappingService,
    private router: Router,
    private draftService: DraftService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.getAllBusinessTransactionCode();
    this.getAllBusinessTransactionCodeMappings();
  }

  getAllBusinessTransactionCodeMappings() {
    this.businessTransactionCodeMappingService
      .getBusinessTransactionCodesMapping()
      .subscribe((data) => {
        this.btcMappings = data;
      });
  }

  editBtc(element) {
    const state = { btc: element };
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/btc/create',
      { state }
    );
  }

  checkBTCMapping(btc) {
    let result = false;
    if (this.btcMappings && this.btcMappings.length > 0) {
      this.btcMappings.forEach((element) => {
        if (element.btcS && element.btcS.length > 0) {
          const btcMapping = element.btcS.find(
            (item) => item.code === btc.code
          );
          if (btcMapping) {
            result = true;
          }
        }
      });
    } else {
      result = false;
    }

    return result;
  }

  deleteBtc(element) {
    if (!element.draft) {
      if (this.checkBTCMapping(element)) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.TOAST-MESSAGE-BTC-MAPPING-EXISTS',
          this.notificationService.MESSAGE_TYPE.ERROR
        );
      } else {
        const dialog = this.matDialogService.open(
          ConfirmationPromptModalComponent
        );
        dialog.componentInstance.messageKey =
          'NOTIFICATION.CONFIRM-DELETE-ENTRY';

        dialog.afterClosed().subscribe((data) => {
          if (data) {
            this.businessTransactionCodeService
              .deleteBtc(element.id)
              .subscribe(() => {
                this.notificationService.showToast(
                  'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-ENTRY',
                  this.notificationService.MESSAGE_TYPE.SUCCESS
                );
                this.getAllBusinessTransactionCode();
              });
          }
        });
      }
    } else {
      this.deleteUserDraft(element.draftId);
    }
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                businessTransactionCodeColumnsConf,
                businessTransactionCodeColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  create() {
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/btc/create',
      { state: {} }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = businessTransactionCodeColumnsConf;
    this.displayedColumns = businessTransactionCodeColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getAllBusinessTransactionCode() {
    this.businessTransactionCodeService
      .getBusinessTransactionCodes()
      .subscribe((data) => {
        this.businessTransactionCodeData = data;
        this.businessTransactionCodeData.sort((a, b) => (a.id > b.id ? 1 : -1));
        this.dataSource.data = data;
        this.getUserDrafts();
      });
  }

  searchBusinessTransactionData() {
    const foundBtcs = new Array();
    this.businessTransactionCodeData.forEach((btc) => {
      if (
        btc.code
          .toString()
          .toLowerCase()
          .includes(this.searchInput.toString().toLowerCase()) ||
        btc.name
          .toString()
          .toLowerCase()
          .includes(this.searchInput.toString().toLowerCase())
      ) {
        foundBtcs.push(btc);
      }
    });
    this.dataSource.data = foundBtcs;
  }

  getUserDrafts() {
    this.draftService
      .retrieveLocalDrafts('BUSINESS_TRANSACTION_CODE')
      .subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach((draft) => {
              const btcDraft = JSON.parse(draft.draftJson);
              btcDraft.draft = true;
              btcDraft.draftId = draft.guid;
              btcDraft.operationUser = draft.operationUser;
              this.dataSource.data.unshift(btcDraft);
            });
            this.dataSource.filter = '';
          } else {
            this.showNoDataFound();
          }
        },
        (error) => {
          this.showNoDataFound();
        }
      );
  }

  deleteUserDraft(draftId) {
    this.draftService.deleteUserDraft(draftId).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }
}
