import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '../../../../../shared/services/notification/notification.service';
import { ConfirmationModalComponent } from '../../../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LivestockProducerService } from '../../../../../shared/services/livestock-producer/livestock-producer.service';
import { Router } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { budgetStatusOptions } from 'src/app/in-memory-data/animal-welfare/livestock-producer-functional-buttons/enum/budget-status-options';
import { ParticipationPeriodService } from 'src/app/shared/services/animal-welfare/participation-period/participation-period.service';
import moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DateValidator } from 'src/app/shared/validators/date-validator';
import { KeycloakService } from 'keycloak-angular';
import { first } from 'rxjs/operators';

const RESERVED = 'RESERVED';

@Component({
  selector: 'app-livestock-functional-buttons',
  templateUrl: './livestock-functional-buttons.component.html',
  styleUrls: ['./livestock-functional-buttons.component.less'],
})
export class LivestockFunctionalButtonsComponent implements OnInit {
  @Input() livestockProducer;

  editableTask = true;
  isTask = false;

  livestockFunctionalButtonsForm: UntypedFormGroup;
  generalForm: UntypedFormGroup;
  participationPeriodForm: UntypedFormGroup;
  beginningOfTypeOfProduction: string;
  entityName = 'livestock-producer';
  oneFalse: boolean;

  state: any;
  draftObject: any;
  livestockProducerApproved: any;
  draftExists: any = false;

  budgetStatusOptions = budgetStatusOptions;

  participationPeriodExists = false;
  isTerminated = false;

  participationPeriod: any;

  originalLivestockFunctionalButtonsFormValue: any;
  originalParticipationPeriodFormValue: any;
  taskType: any;

  oldStatus: any = '';
  newStatus: any = '';
  dateFormat: any;

  participationPeriodTask: any;
  accountNumber: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private livestockProducerService: LivestockProducerService,
    private router: Router,
    private taskService: TaskService,
    private draftService: DraftService,
    private participationPeriodService: ParticipationPeriodService,
    private translateService: TranslateService,
    private userService: UserService,
    private dateValidator: DateValidator,
    private kcService: KeycloakService
  ) {
    this.livestockFunctionalButtonsFormBuilder();
    this.generalFormBuilder();
    this.participationPeriodFormBuilder();
    this.setAutomaticallyReleaseDateWhenStatusReserved();
  }

  private setAutomaticallyReleaseDateWhenStatusReserved(): void {
    this.participationPeriodFormReleaseExecutionDateControl.valueChanges.subscribe(
      () => {
        if (
          this.participationPeriodFormStatusControl.value === RESERVED &&
          !this.participationPeriod.releaseDate &&
          this.participationPeriodFormReleaseExecutionDateControl.value
        ) {
          this.participationPeriodFormReleaseDateControl.patchValue(
            this.participationPeriod.dateOfImplementation
          );
        }
      }
    );

    this.participationPeriodFormStatusControl.valueChanges.subscribe(
      (participationPeriodStatus) => {
        if (
          participationPeriodStatus === RESERVED &&
          this.participationPeriodFormReleaseExecutionDateControl.value &&
          !this.participationPeriod.releaseDate
        ) {
          this.participationPeriodFormReleaseDateControl.patchValue(
            this.participationPeriod.dateOfImplementation
          );
        }
      }
    );
  }

  get participationPeriodFormReleaseExecutionDateControl(): AbstractControl {
    return this.participationPeriodForm.get('releaseExecutionDate');
  }

  get participationPeriodFormReleaseDateControl(): AbstractControl {
    return this.participationPeriodForm.get('releaseDate');
  }

  get participationPeriodFormStatusControl(): AbstractControl {
    return this.participationPeriodForm.get('status');
  }

  ngOnInit(): void {
    this.state = window.history.state;
    this.dateFormat = this.userService.getDateFormat();
    this.checkChangesInForm();
    if (this.state.task) {
      this.isTask = true;
      this.taskType = this.state.task.type;

      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
      if (
        this.taskType === 'AW_RESERVE_BUDGET_STATUS' ||
        this.taskType === 'AW_ALLOCATE_BUDGET_STATUS'
      ) {
        this.addControlsToLivestockFunctionalButtonsForm();
        this.setOldAndNewStatusTask();
      } else if (
        this.taskType === 'AW_TERMINATE_DATE_CHANGE' ||
        this.taskType === 'AW_TERMINATE_DATE_DELETE' ||
        this.taskType === 'AW_TERMINATE_DATE_SET'
      ) {
        this.setReleaseDateTask();
      } else {
        this.retrieveDraftObjectTask(this.state.task);
      }
    } else {
      const typeOfProduction = this.livestockProducer.productionType;
      this.beginningOfTypeOfProduction = typeOfProduction.toString().charAt(0);
      this.patchFormValuesFromLivestock();
      this.livestockProducerApproved = this.livestockProducer;
      this.getParticipationPeriod();
    }
  }

  addControlsToLivestockFunctionalButtonsForm() {
    this.livestockFunctionalButtonsForm.addControl(
      'locationNumber',
      new UntypedFormControl('')
    );
    this.retrieveLivestockProducerTask(
      this.state.task.params.livestockProducerId
    );
  }

  checkApprovalDraft() {
    this.draftService
      .approvalDraftExistsForEntityId(this.livestockProducer.id)
      .subscribe(
        (data) => {
          this.draftExists = data;
          if (this.draftExists) {
            this.livestockFunctionalButtonsForm.disable();
            this.participationPeriodForm.disable();
            this.notificationService.showToastMessage(
              'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
              this.notificationService.MESSAGE_TYPE.INFO
            );
          }
        },
        (err) => console.log('ERROR VALIDATING DRAFT', err)
      );
  }

  retrieveLivestockProducerTask(id) {
    this.livestockProducerService.getLivestockProducer(id).subscribe((data) => {
      this.accountNumber = data['accountNumber'];
      this.livestockProducerApproved = data['masterDataLivestockProducer'];
      this.patchGeneralForm();
      const typeOfProduction = this.livestockProducerApproved.productionType;
      this.beginningOfTypeOfProduction = typeOfProduction.toString().charAt(0);
    });
  }

  patchGeneralForm() {
    this.generalForm.patchValue(this.livestockProducerApproved);
  }

  retrieveDraftObjectTask(task: any) {
    this.draftService
      .retrieveDraftObjectForTask(task.guid)
      .subscribe((data) => {
        this.draftObject = data;
        Object.assign(this.draftObject, {
          draft: JSON.parse(this.draftObject.draftJson),
        });
        this.patchFormValuesFromTaskObject(this.draftObject.draft);
        this.retrieveLivestockProducerTask(this.draftObject.entityGuid);
      });
  }

  livestockFunctionalButtonsFormBuilder() {
    this.livestockFunctionalButtonsForm = this.formBuilder.group({
      dispatchedDocuments: [true, []],
      accountStatement: [true, []],
      certificateOfParticipation: [true, []],
      participationOfRevocation: [true, []],
      quarterlyBilling: [true, []],
    });
  }

  participationPeriodFormBuilder() {
    this.participationPeriodForm = this.formBuilder.group(
      {
        status: ['', [Validators.required]],
        releaseExecutionDate: ['', []],
        releaseDate: ['', []],
      },
      {
        validators: [
          this.dateValidator.validateDateNullAndNotInThePast(
            'releaseExecutionDate',
            'releaseDate'
          ),
        ],
      }
    );
  }

  generalFormBuilder() {
    this.generalForm = this.formBuilder.group({
      locationNumber: [{ value: '', disabled: true }],
      productionType: [{ value: '', disabled: true }],
      locationName: [{ value: '', disabled: true }],
    });
  }

  checkChangesInForm() {
    this.livestockFunctionalButtonsForm
      .get('dispatchedDocuments')
      .valueChanges.subscribe((data) => {
        this.controlMasterSlider();
      });

    this.livestockFunctionalButtonsForm
      .get('accountStatement')
      .valueChanges.subscribe((data) => {
        this.controlSliders('accountStatement');
      });
    this.livestockFunctionalButtonsForm
      .get('certificateOfParticipation')
      .valueChanges.subscribe((data) => {
        this.controlSliders('certificateOfParticipation');
      });
    this.livestockFunctionalButtonsForm
      .get('participationOfRevocation')
      .valueChanges.subscribe((data) => {
        this.controlSliders('participationOfRevocation');
      });
    this.livestockFunctionalButtonsForm
      .get('quarterlyBilling')
      .valueChanges.subscribe((data) => {
        this.controlSliders('quarterlyBilling');
      });
  }

  checkLivestockFormWithOriginalObject() {
    if (
      this.originalLivestockFunctionalButtonsFormValue &&
      _.isEqual(
        this.originalLivestockFunctionalButtonsFormValue,
        this.livestockFunctionalButtonsForm.getRawValue()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkParticipationPeriodFormWithOriginalObject() {
    if (
      this.participationPeriodForm &&
      this.originalParticipationPeriodFormValue &&
      _.isEqual(
        this.originalParticipationPeriodFormValue,
        this.participationPeriodForm.getRawValue()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkValidityParticipationPeriodForm() {
    if (this.participationPeriodForm && !this.participationPeriodForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  controlMasterSlider() {
    const value = this.livestockFunctionalButtonsForm.get(
      'dispatchedDocuments'
    ).value;
    this.livestockFunctionalButtonsForm.get('accountStatement').setValue(value);
    this.livestockFunctionalButtonsForm
      .get('certificateOfParticipation')
      .setValue(value);
    this.livestockFunctionalButtonsForm
      .get('participationOfRevocation')
      .setValue(value);
    this.livestockFunctionalButtonsForm.get('quarterlyBilling').setValue(value);
  }

  controlSliders(controlName) {
    if (this.livestockFunctionalButtonsForm.get(controlName).value === false) {
      this.livestockFunctionalButtonsForm
        .get('dispatchedDocuments')
        .setValue(false, { emitEvent: false });
    }

    if (this.checkAllTrue()) {
      this.livestockFunctionalButtonsForm
        .get('dispatchedDocuments')
        .setValue(true, { emitEvent: false });
    }
  }

  checkAllTrue() {
    let result = false;
    if (
      this.livestockFunctionalButtonsForm.get('accountStatement').value ===
        true &&
      this.livestockFunctionalButtonsForm.get('certificateOfParticipation')
        .value === true &&
      this.livestockFunctionalButtonsForm.get('participationOfRevocation')
        .value === true &&
      this.livestockFunctionalButtonsForm.get('quarterlyBilling').value === true
    ) {
      result = true;
    }

    return result;
  }

  saveDocumentDispatchLockSettings() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        if (
          !this.checkLivestockFormWithOriginalObject() &&
          this.taskType !== 'AW_TERMINATE_DATE_CHANGE' &&
          this.taskType !== 'AW_TERMINATE_DATE_DELETE' &&
          this.taskType !== 'AW_TERMINATE_DATE_SET'
        ) {
          this.saveDocumentsDispatch(result);
        } else if (
          !this.checkParticipationPeriodFormWithOriginalObject() &&
          this.taskType !== 'AW_TERMINATE_DATE_CHANGE' &&
          this.taskType !== 'AW_TERMINATE_DATE_DELETE' &&
          this.taskType !== 'AW_TERMINATE_DATE_SET'
        ) {
          this.saveParticipationPeriodStatus(result);
        } else {
          this.saveParticipationPeriodDates(result);
        }
      }
    });
  }

  saveDocumentsDispatch(result) {
    const json = this.livestockFunctionalButtonsForm.value;
    const request = {
      comment: result.comment,
      documentDispatchLocks: json,
      id: this.livestockProducer
        ? this.livestockProducer.id
        : this.draftObject
        ? this.draftObject.entityGuid
        : null,
      taskId: this.state && this.state.task ? this.state.task.guid : null,
    };
    this.livestockProducerService
      .saveDocumentDispatchLocks(request)
      .subscribe(() => {
        if (!this.checkParticipationPeriodFormWithOriginalObject()) {
          this.saveParticipationPeriodStatus(result);
        } else {
          this.successCreatedDependOnExternalOrInternalUser();
        }
      });
  }

  saveParticipationPeriodStatus(result) {
    const request = {
      comment: result.comment,
      accountNumber: this.livestockProducer.accountNumber,
      locationName: this.livestockProducer.locationName,
    };

    if (
      this.participationPeriodForm &&
      this.participationPeriodForm.get('status').value !==
        this.originalParticipationPeriodFormValue.status
    ) {
      if (this.participationPeriodForm.get('status').value === 'ALLOCATED') {
        this.participationPeriodService
          .setBudgetAllocate(request, this.participationPeriod.id)
          .subscribe(() => {
            this.successHandler(result);
          });
      } else {
        this.participationPeriodService
          .setBudgetReserve(request, this.participationPeriod.id)
          .subscribe(() => {
            this.successHandler(result);
          });
      }
    } else {
      this.saveParticipationPeriodDates(result);
    }
  }

  saveParticipationPeriodDates(result) {
    if (this.participationPeriodForm) {
      const idParticipationPeriod = this.participationPeriod
        ? this.participationPeriod.id
        : this.state.task.params
        ? this.state.task.params.id
        : null;

      const request = {
        comment: result.comment,
        accountNumber: this.livestockProducer
          ? this.livestockProducer.accountNumber
          : this.state.task.params
          ? this.state.task.params.accountNumber
          : null,
        locationName: this.livestockProducer
          ? this.livestockProducer.locationName
          : this.state.task.params
          ? this.state.task.params.locationName
          : null,
        newReleaseDate: this.formatDate(
          this.participationPeriodForm.get('releaseDate').value
        ),
        newTerminationDate: this.formatDate(
          this.participationPeriodForm.get('releaseExecutionDate').value
        ),
        id: idParticipationPeriod,
        taskId: this.state && this.state.task ? this.state.task.guid : null,
      };
      this.participationPeriodService
        .setTerminationDates(request, idParticipationPeriod)
        .pipe(first())
        .subscribe((data) => {
          this.successTasksCreated();
        });
    } else {
      this.successTasksCreated();
    }
  }

  checkParticipationPeriodDatesFormWithOriginalObject() {
    if (
      this.participationPeriodForm &&
      this.originalParticipationPeriodFormValue &&
      (this.participationPeriodForm.get('releaseExecutionDate').value !==
        this.originalParticipationPeriodFormValue.releaseExecutionDate ||
        this.participationPeriodForm.get('releaseDate').value !==
          this.originalParticipationPeriodFormValue.releaseDate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  successTasksCreated() {
    if (!this.isExternalUser()) {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        this.translateService.instant(
          'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.ENTRIES.LIVESTOCK-PRODUCER'
        )
      );
    }

    this.redirectView(3000);
  }

  successCreatedDependOnExternalOrInternalUser() {
    if (!this.isExternalUser()) {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        {
          data: this.translateService.instant(
            'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.ENTRIES.LIVESTOCK-PRODUCER'
          ),
        }
      );
    }

    this.redirectView(3000);
  }

  isExternalUser(): boolean {
    return this.kcService.getUserRoles().includes('ext.aw');
  }

  patchFormValuesFromLivestock() {
    this.livestockFunctionalButtonsForm
      .get('quarterlyBilling')
      .patchValue(this.livestockProducer.dispatchQuarterlyBilling);
    this.livestockFunctionalButtonsForm
      .get('participationOfRevocation')
      .patchValue(this.livestockProducer.dispatchParticipationRevocation);
    this.livestockFunctionalButtonsForm
      .get('certificateOfParticipation')
      .patchValue(this.livestockProducer.dispatchCertificateOfParticipation);
    this.livestockFunctionalButtonsForm
      .get('accountStatement')
      .patchValue(this.livestockProducer.dispatchAccountStatement);

    this.originalLivestockFunctionalButtonsFormValue =
      this.livestockFunctionalButtonsForm.getRawValue();
  }

  patchFormValuesFromTaskObject(taskObject) {
    this.livestockFunctionalButtonsForm.patchValue(taskObject);
    this.originalLivestockFunctionalButtonsFormValue =
      this.livestockFunctionalButtonsForm.getRawValue();
  }

  setOldAndNewStatusTask() {
    this.oldStatus =
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-OPTIONS.' +
      this.state.task.params.oldStatus;
    this.newStatus =
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-OPTIONS.' +
      this.state.task.params.newStatus;
  }

  setReleaseDateTask() {
    this.retrieveLivestockProducerTask(
      this.state.task.params.livestockProducerId
    );
    this.participationPeriodTask = this.state.task.params;
    this.participationPeriodForm.removeControl('status');

    this.draftService
      .retrieveDraftObjectForTask(this.state.task.guid)
      .subscribe((result) => {
        this.draftObject = result;
        Object.assign(this.draftObject, {
          participationPeriod: JSON.parse(this.draftObject.draftJson),
        });

        this.participationPeriodForm
          .get('releaseExecutionDate')
          .patchValue(
            this.draftObject.participationPeriod.releaseExecutionDate
          );
        this.participationPeriodForm
          .get('releaseDate')
          .patchValue(this.draftObject.participationPeriod.releaseDate);
      });
  }

  changeTaskState(state) {
    this.taskService.updateEditableTask(state);
  }

  getParticipationPeriod() {
    const today = moment().format('YYYY-MM-DD');

    this.participationPeriodService
      .getLivestockProducerParticipationPeriod(this.livestockProducer.id, today)
      .subscribe((data) => {
        if (data) {
          this.participationPeriodExists = true;
          this.participationPeriod = data;

          this.patchParticipationPeriodForm();
        } else {
          this.participationPeriodForm = null;
        }
      });
  }

  patchParticipationPeriodForm() {
    this.participationPeriodForm.patchValue(this.participationPeriod);
    this.checkParticipationStatus();
    this.originalParticipationPeriodFormValue =
      this.participationPeriodForm.getRawValue();
  }

  checkParticipationStatus() {
    if (this.participationPeriodForm.get('status').value === 'TERMINATED') {
      this.isTerminated = true;
      this.participationPeriodForm.disable();
    }
  }

  formatDate(dateP) {
    if (dateP) {
      const date = new Date(dateP);
      const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1; // convert to positive value.
      date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);

      return date;
    } else {
      return;
    }
  }

  private successHandler(result) {
    if (this.checkParticipationPeriodDatesFormWithOriginalObject()) {
      this.saveParticipationPeriodDates(result);
    } else {
      this.successTasksCreated();
    }
  }

  private redirectView(timeout: number) {
    setTimeout(() => {
      this.router.navigateByUrl('/livestockProducer', {
        state: { success: true },
      });
    }, timeout);
  }
}
