import { Injectable, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  sortedList: any[] = [];
  sortedListOnTables: any[] = [];
  sortedListInSplitScreen: any[] = [];
  columnFiltered = [];
  allColumnFilters = new Map();
  @Output() dataList: EventEmitter<any> = new EventEmitter();
  @Output() dataListInSplitScreen: EventEmitter<any[]> = new EventEmitter();
  @Output() dataListOnTables: EventEmitter<any[]> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      if (typeof a[property] === 'number' && typeof b[property] === 'number') {
        if (Number(a[property]) > Number(b[property])) {
          return 1 * sortOrder;
        }
        if (Number(a[property] < Number(b[property]))) {
          return -1 * sortOrder;
        }
        return 0;
      }
      if (property === 'country' || property === 'countryCode') {
        a[property] = a.address.country;
        b[property] = b.address.country;
      }
      if (property === 'timezone') {
        a[property] = a.address.timezone;
        b[property] = b.address.timezone;
      }
      if (a[property] || b[property]) {
        const translateA = this.translateService.instant(
          a[property].toString()
        );
        const translateB = this.translateService.instant(
          b[property].toString()
        );

        if (translateA !== a[property] && translateB !== b[property]) {
          if (sortOrder === -1) {
            return translateB
              .toString()
              .localeCompare(translateA, 'en', { ignorePunctuation: true });
          } else {
            return translateA
              .toString()
              .localeCompare(translateB, 'en', { ignorePunctuation: true });
          }
        } else {
          if (sortOrder === -1) {
            return b[property]
              .toString()
              .localeCompare(a[property], 'en', { ignorePunctuation: true });
          } else {
            return a[property]
              .toString()
              .localeCompare(b[property], 'en', { ignorePunctuation: true });
          }
        }
      }
    };
  }

  sortAsce(key: any, dataSource: any) {
    this.sortedList = dataSource.sort(this.dynamicSort(key));
    if (this.columnFiltered.length > 0) {
      this.dataList.emit(this.sortedList);
    } else {
      return this.sortedList;
    }
  }

  sortDesc(key: any, dataSource: any) {
    this.sortedList = dataSource.sort(this.dynamicSort('-' + key));
    if (this.columnFiltered.length > 0) {
      this.dataList.emit(this.sortedList);
    } else {
      return this.sortedList;
    }
  }

  sortAsceInSplitScreen(key: any, dataSource: any) {
    this.sortedListInSplitScreen = dataSource.sort(this.dynamicSort(key));
    this.dataListInSplitScreen.emit(this.sortedListInSplitScreen);
  }

  sortDescInSplitScreen(key: any, dataSource: any) {
    this.sortedListInSplitScreen = dataSource.sort(this.dynamicSort('-' + key));
    this.dataListInSplitScreen.emit(this.sortedListInSplitScreen);
  }

  sortAsceOnTables(key: any, dataSource: any) {
    this.sortedListOnTables = dataSource.sort(this.dynamicSort(key));
    this.dataListOnTables.emit(this.sortedListOnTables);
  }

  sortDescOnTables(key: any, dataSource: any) {
    this.sortedListOnTables = dataSource.sort(this.dynamicSort('-' + key));
    this.dataListOnTables.emit(this.sortedListOnTables);
  }

  applyColumnFilters(choice: string, appliedFilters: any[]) {
    this.allColumnFilters.set(choice, appliedFilters);
    const columnFiltered = this.sortedList.filter((value) =>
      this.checkColumnFilters(value)
    );
    this.columnFiltered = columnFiltered;
    this.dataList.emit(columnFiltered);
  }

  applyColumnFiltersInSplitScreen(
    choice: string,
    appliedFilters: any[],
    dataSource: any
  ) {
    this.allColumnFilters.set(choice, appliedFilters);
    let columnFiltered;
    if (this.dataListInSplitScreen.length > 0) {
      columnFiltered = this.sortedListInSplitScreen.filter((value) =>
        this.checkColumnFilters(value)
      );
    } else {
      columnFiltered = dataSource.filter((value) =>
        this.checkColumnFilters(value)
      );
    }
    this.columnFiltered = columnFiltered;
    this.dataListInSplitScreen.emit(columnFiltered);
  }

  applyColumnFiltersOnTables(
    choice: string,
    appliedFilters: any[],
    dataSource: any
  ) {
    this.allColumnFilters.set(choice, appliedFilters);
    let columnFiltered;
    if (this.dataListOnTables.length > 0) {
      columnFiltered = this.sortedListOnTables.filter((value) =>
        this.checkColumnFilters(value)
      );
    } else {
      columnFiltered = dataSource.filter((value) =>
        this.checkColumnFilters(value)
      );
    }
    this.columnFiltered = columnFiltered;
    this.dataListOnTables.emit(columnFiltered);
  }

  checkColumnFilters(task) {
    for (const entry of this.allColumnFilters.entries()) {
      const values: any[] = entry[1];
      if (!values) {
        continue;
      }
      if (values.length === 0) {
        continue;
      }
      const key = entry[0];
      if (values.indexOf(task[key]) === -1) {
        return false;
      }
    }
    return true;
  }

  sortByVisibleId(data, sort, splitItems) {
    if (splitItems === 2) {
      return data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const aSplit = a.visibleId.split(' ');
        const bSplit = b.visibleId.split(' ');

        let result = this.compare(Number(aSplit[0]), Number(bSplit[0]), isAsc);

        if (result !== 0) {
          return result;
        }
        return (result = this.compare(
          Number(aSplit[1]),
          Number(bSplit[1]),
          isAsc
        ));
      });
    } else if (splitItems === 3) {
      return data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const aSplit = a.visibleId.split(' ');
        const bSplit = b.visibleId.split(' ');

        let result = this.compare(Number(aSplit[0]), Number(bSplit[0]), isAsc);

        if (result !== 0) {
          return result;
        }

        result = this.compare(Number(aSplit[1]), Number(bSplit[1]), isAsc);

        if (result !== 0) {
          return result;
        }

        return (result = this.compare(
          Number(aSplit[2]),
          Number(bSplit[2]),
          isAsc
        ));
      });
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a < b) {
      if (isAsc) {
        return -1 * 1;
      } else {
        return -1 * -1;
      }
    } else if (b < a) {
      if (isAsc) {
        return 1 * 1;
      } else {
        return 1 * -1;
      }
    } else if (a === b) {
      return 0;
    }
  }
}
