import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaskEventType } from '../../models/task-event-type';
import { TranslationValueSaveRequest } from './translation-value-save-request';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;
  private searchText = '';
  private pageSize = '20';
  private pageIndex = '0';
  private mapOfColumnFilters = [];

  private languageChange = new BehaviorSubject<any>(null);
  currentLanguageChange = this.languageChange.asObservable();

  private selectedLanguage = new BehaviorSubject<any>(null);
  currentSelectedLanguage = this.selectedLanguage.asObservable();

  private currentSharedselectedLanguage = new BehaviorSubject<any>(null);
  currentSharedSelectedLanguage =
    this.currentSharedselectedLanguage.asObservable();

  updateNewLanguages(languageChange: any) {
    this.languageChange.next(languageChange);
  }

  updateCurrentSelectedLanguage(selectedLanguage: any) {
    this.selectedLanguage.next(selectedLanguage);
  }

  updateCurrentSharedSelectedLanguage(currentSharedselectedLanguage: any) {
    this.currentSharedselectedLanguage.next(currentSharedselectedLanguage);
  }

  setCurrentLanguage(lang: string) {
    localStorage.currentSelectedLanguage = lang;
  }

  getCurrentLanguage() {
    return localStorage.currentSelectedLanguage;
  }

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.translationService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  setColumnFilters(choice: any, selection: any) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  setSearchString(searchString) {
    this.searchText = searchString;
  }

  setPaginator(amountPerPage, pageNr) {
    this.pageSize = amountPerPage;
    this.pageIndex = pageNr;
  }

  filterKeys(): Observable<any[]> {
    let config = {};
    config = this.buildHeaders();
    config['params'] = new HttpParams()
      .append('filterText', this.searchText)
      .append('pageIndex', this.pageIndex)
      .append('pageSize', this.pageSize);
    config['params'] = this.addColumnFilters(config['params']);
    return this.http.get<[]>(
      this.serviceUrl + 'languages/translations',
      config
    );
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  findDistinctValues(args: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/translations?fieldName=' + args,
      this.buildHeaders()
    );
  }

  getLanguageList(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/available-languages',
      this.buildHeaders()
    );
  }

  getTranslationsByIsoCode(isoCode): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/translations/' + isoCode,
      this.buildHeaders()
    );
  }

  getCreatedLanguages(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/created-languages',
      this.buildHeaders()
    );
  }

  getTranslationsByGuid(guid): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/edit/' + guid,
      this.buildHeaders()
    );
  }

  createLanguage(language) {
    return this.http.post<any[]>(
      this.serviceUrl + 'languages/create',
      language,
      this.buildHeaders()
    );
  }

  editKey(request: TranslationValueSaveRequest) {
    return this.http.post<any[]>(
      this.serviceUrl + 'translation-values/save',
      request,
      this.buildHeaders()
    );
  }

  getTranslations(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/translations',
      this.buildHeaders()
    );
  }

  getApprovedTranslations(key): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'languages/approved-translations/' + key,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid?: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
