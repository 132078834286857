import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { EntityType } from '../../../shared/models/entity-type';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import {
  CustomSnackBarComponent
} from 'src/app/shared/services/notification/custom-snack-bar/custom-snack-bar.component';
import { CompanyBankAccountComponent } from '../company-bank-account/company-bank-account.component';
import { CompanyContactPersonComponent } from '../company-contact-person/company-contact-person.component';
import {
  CompanyGeneralInformationComponent
} from '../company-general-information/company-general-information.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.less'],
})
export class CompanyCreateComponent
    implements OnInit, DirtyComponent, AfterContentChecked, OnDestroy {
  @ViewChild(CompanyBankAccountComponent)
  bankAccountComponent: CompanyBankAccountComponent;
  @ViewChild(CompanyContactPersonComponent)
  contactPersonComponent: CompanyContactPersonComponent;
  @ViewChild(CompanyGeneralInformationComponent)
  generalInformationComponent: CompanyGeneralInformationComponent;

  entityName = 'company';
  errorRequired = 'error-message-required';
  edit = false;
  taskView = false;
  companyApproved: any;
  alreadyDraft = false;
  draftId: number;
  draftGuid: string;
  companyTask: any;
  editableTask: boolean;
  isTask = false;
  save = false;
  approval = false;
  require: any;
  companyInitial: any;
  isDraftExists: false;
  data: string;
  localEntityDraft: any;
  operationUser: any;
  showStepper = false;
  entitiesLoaded = false;
  state: { company: { draft: any; }; task: { id: any; }; draftId: number; draftGuid: string; };
  snapshot: ActivatedRouteSnapshot;
  company: any;

  constructor(
      private router: Router,
      private matDialogService: MatDialog,
      private companyService: CompanyService,
      private translateService: TranslateService,
      private notificationService: NotificationService,
      private draftService: DraftService,
      private taskService: TaskService,
      private customErrorHandler: CustomErrorHandler,
      private snackBar: MatSnackBar,
      private changeDetectorRef: ChangeDetectorRef,
      private activatedRoute: ActivatedRoute
  ) {
  }

  get companyId() {
    if (this.companyApproved) {
      return this.companyApproved.guid;
    }
    return '';
  }

  get frmGeneralInformation() {
    return this.generalInformationComponent
        ? this.generalInformationComponent.companyForm
        : null;
  }

  get frmBankAccount() {
    return this.bankAccountComponent &&
    this.bankAccountComponent.showForm === true
        ? this.bankAccountComponent.bankAccountForm
        : null;
  }

  get frmContactPerson() {
    return this.contactPersonComponent &&
    this.contactPersonComponent.showForm === true
        ? this.bankAccountComponent.bankAccountForm
        : null;
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('taskModify');
  }

  isModify() {
    if (sessionStorage.getItem('taskModify') === 'true') {
      return true;
    }
  }

  ngOnInit() {
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    if (this.state.company !== undefined) {
      this.loadDataFromCompanyState(this.company);
    } else if (this.state.task) {
      this.loadTaskData();
    } else if (this.snapshot.params.guid) {
      this.loadDataFromCompanyGuid();
    } else {
      this.showStepper = true;
    }

    if (this.state.draftId) {
      this.draftId = this.state.draftId;
    }
    if (this.state.draftGuid) {
      this.draftGuid = this.state.draftGuid;
    }
  }

  canDeactivate() {
    if (
        this.generalInformationComponent &&
        this.generalInformationComponent.companyForm
    ) {
      return (
          this.generalInformationComponent.companyForm.dirty && !this.approval
      );
    } else {
      return false;
    }
  }

  async redirectToUserDraft() {
    const company = JSON.parse(this.localEntityDraft.draftJson);
    company.draft = true;
    company.draftId = this.localEntityDraft.id;
    company.draftGuid = this.localEntityDraft.guid;
    company.operationUser = this.localEntityDraft.operationUser;

    const state = {company};
    state['draftId'] = company['draftId'];
    state['draftGuid'] = company['draftGuid'];

    this.router.onSameUrlNavigation = 'reload';
    await this.router.navigateByUrl('/masterdata/company/create', {
      state,
    });
  }

  loadDataFromCompanyState(company: any) {
    if (this.state.company.draft) {
      this.alreadyDraft = true;
    }
    this.save = true;
    if (this.state.company.draft) {
      company = this.state.company;
      this.operationUser = company.operationUser;
      if (company.guid) {
        this.companyService.getCurrentApproved(company.guid).subscribe((data) => {
          this.companyApproved = data;
        });
      }
      this.entitiesLoaded = true;
    } else {
      this.edit = true;
      company = this.state.company;
      this.validateDraftExistence(company.id, company.guid);
      this.companyService.getCurrentApproved(company.guid).subscribe(data => {
        this.companyApproved = data;

        this.draftService.retrieveLocalEntityDraft(data.guid).subscribe((draft) => {
          this.localEntityDraft = draft;
          const snackBarObject = this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: 'GENERAL-ENTITY.CREATE.MESSAGES.TOAST_MESSAGE_DRAFT_EXISTS',
            duration: undefined,
            verticalPosition: 'top',
            panelClass: [
              this.notificationService.MESSAGE_TYPE.INFO + '-snackbar',
              'custom-snackbar',
            ],
          });

          snackBarObject.afterDismissed().subscribe((result) => {
            if (result.dismissedByAction) {
              this.redirectToUserDraft().then();
            }
          });
        });
      });
      this.retrieveInitialDraft(company.id);
    }
  }

  loadTaskData() {
    this.isTask = true;
    this.edit = true;
    this.taskView = true;
    this.save = true;
    this.companyService.getCompanyWithTaskId(this.state.task.id).subscribe(data => {
      this.company = data;
      this.setCompanyTask(this.company);
      this.companyService.getCurrentApproved(this.company.guid).subscribe({
        next: (companyApp) => {
          this.companyApproved = companyApp;
        },
        error: () => {
          this.entitiesLoaded = true;
        }
      });
      this.retrieveInitialDraft(this.company.id);
    });

    this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
    );
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.taskService.updateEditableTask(true);
    }
  }

  loadDataFromCompanyGuid() {
    this.save = true;
    this.companyService.getCurrentApproved(this.snapshot.params.guid).subscribe({
      next: (data) => {
        this.company = data;
        this.validateDraftExistence(data.id, data.guid);
        this.companyApproved = data;
        this.entitiesLoaded = true;
        this.edit = true;
        this.draftService
            .retrieveLocalEntityDraft(data.guid)
            .subscribe((draft) => {
              this.localEntityDraft = draft;
              this.notificationService.showToast(
                  'GENERAL-ENTITY.CREATE.MESSAGES.TOAST_MESSAGE_DRAFT_EXISTS',
                  this.notificationService.MESSAGE_TYPE.INFO
              );
            });
        this.retrieveInitialDraft(data.id);
      },
      error: (err) => {
        this.handleError(err);
      }
    });
  }

  retrieveInitialDraft(id: number) {
    this.draftService
        .retrieveInitialEntityDraft(EntityType.COMPANY, id)
        .subscribe(data => (this.companyInitial = JSON.parse(data.draftJson)));
  }

  validateDraftExistence(id: number, guid: string) {
    this.draftService.validateDraftExistence(EntityType.COMPANY, id, guid).subscribe((data) => {
      this.isDraftExists = data;
      if (this.isDraftExists) {
        this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
        );
      }
    });
  }

  setCompanyTask(company: any) {
    this.companyTask = company;
  }

  changeEditable(value: boolean) {
    const state = window.history.state;
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.router.navigateByUrl('/tasks/' + state.task.id, {state}).then();
    } else {
      this.taskService.updateEditableTask(value);
    }
  }

  onClickSave() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        this.approval = true;
        if (this.draftGuid) {
          this.draftService.deleteUserDraft(this.draftGuid).subscribe();
        }
        const json = this.generalInformationComponent.companyForm.value;
        json.bankAccounts = this.bankAccountComponent.dataSource.data;
        json.contactPeople = this.contactPersonComponent.dataSource.data;
        if (this.taskView) {
          const jsonBody = {
            company: json,
            comment: result.comment,
            taskId: json.taskId,
            draftId: this.draftGuid,
          };
          this.companyService.updateDraft(jsonBody).subscribe({
            next: () => {
              this.successSaveHandler();
              setTimeout(() => {
                if (this.isTask) {
                  this.router.navigateByUrl('/tasks').then();
                } else {
                  this.router.navigateByUrl('/masterdata/company', {state: {success: true}}).then();
                }
              }, 1500);
            },
            error: this.customErrorHandler._errorHandler('ENTITIES.COMPANY')
          });
        } else {
          const jsonBody = {
            company: json,
            comment: result.comment,
            draftId: this.draftGuid,
          };
          if (this.edit) {
            this.companyService.updateCompany(jsonBody).subscribe({
              next: () => {
                this.notificationService.showToast(
                    'NOTIFICATION.CREATED',
                    this.notificationService.MESSAGE_TYPE.SUCCESS,
                    {data: this.translateService.instant('ENTITIES.COMPANY')}
                );
                this.redirectAfterTimeout(3000);
              },
              error: (error) => {
                if (
                    error.status === 409 &&
                    error.error &&
                    error.error.translationKey
                ) {
                  this.notificationService.showToast(
                      error.error.translationKey,
                      this.notificationService.MESSAGE_TYPE.ERROR,
                      {data: this.translateService.instant('ENTITIES.COMPANY')}
                  );
                  this.redirectAfterTimeout(3000);
                } else if (error.error) {
                  this.customErrorHandler.handleError(
                      error.error,
                      this.translateService.instant('ENTITIES.COMPANY')
                  );
                }
              }
            });
          } else {
            this.companyService.saveCompany(jsonBody).subscribe({
              next: () => {
                this.successSaveHandler();
                this.redirectAfterTimeout(3000);
              },
              error: this.customErrorHandler._errorHandler('ENTITIES.COMPANY')
            });
          }
        }
      }
    });
  }

  saveAsDraft() {
    this.approval = true;
    const draftGuid = this.draftGuid;
    const companyFormValue = this.generalInformationComponent.companyForm.value;
    const request = {
      element: companyFormValue,
      entityType: 'COMPANY',
      id: draftGuid,
      entityId: companyFormValue.id,
      entityGuid: companyFormValue.guid,
    };
    let update = false;
    if (draftGuid) {
      update = true;
    }
    this.draftService.saveUserDraft(request).subscribe({
      next: () => {
        if (update) {
          this.notificationService.showToast(
              'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {data: this.translateService.instant('ENTITIES.COMPANY')}
          );
        } else {
          this.notificationService.showToast(
              'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {data: this.translateService.instant('ENTITIES.COMPANY')}
          );
        }
        this.redirectAfterTimeout(3000);
      },
      error: this.customErrorHandler._errorHandler('ENTITIES.COMPANY')
    });
  }

  checkOneItemValue(object: object) {
    let result = false;
    Object.values(object).forEach((value) => {
      if (!result) {
        switch (typeof value) {
          case 'string':
            result = value !== '';
            break;
          case 'object': {
            if (value !== null) {
              result = this.checkOneItemValue(value);
            }
          }
        }
      }
    });

    return result;
  }

  getErrorTab(indexTab: number) {
    if (
        indexTab === 0 &&
        this.generalInformationComponent &&
        !this.isDraftExists
    ) {
      return this.generalInformationComponent.companyForm.invalid;
    } else {
      return false;
    }
  }

  stepperChange($event: StepperSelectionEvent) {
    const previousStep = $event.previouslySelectedStep;
    if (
        !previousStep.stepControl ||
        previousStep.stepControl.status !== 'INVALID'
    ) {
      previousStep._completedOverride = true;
      previousStep._displayDefaultIndicatorType = false;
    }
  }

  handleError(err: any) {
    this.notificationService.showToast(
        'ERROR-MESSAGES.ERROR-BACKEND',
        this.notificationService.MESSAGE_TYPE.ERROR,
        {
          name: err.error.errorId ? err.error.errorId : 'unknown',
          error: err.message,
        }
    );
  }

  saveButtonShouldBeDisabled() {
    const formIsInvalid = this.generalInformationComponent
        ? !this.generalInformationComponent.companyForm.valid
        : false;
    return formIsInvalid || this.isDraftExists;
  }

  private redirectAfterTimeout(timeout?: number) {
    setTimeout(
        () => {
          this.router.navigateByUrl('/masterdata/company', {state: {success: true}}).then();
        },
        timeout ? timeout : 3000
    );
  }

  private successSaveHandler() {
    this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        {data: this.translateService.instant('ENTITIES.COMPANY')}
    );
  }
}
