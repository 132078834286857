<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <p>
      <strong>{{ 'DIALOGS.SESSION-EXPIRES.TITLE' | translate }}</strong>
    </p>
    <p>
      {{ 'DIALOGS.SESSION-EXPIRES.MESSAGE' | translate: { timeout: timeoutString } }}
    </p>
  </div>
  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false">
      {{ 'MENU.LOGOUT' | translate }}
    </button>
    <button mat-button class="custom-button" [mat-dialog-close]="true" style="margin-right: 0">
      {{ 'DIALOGS.SESSION-EXPIRES.EXTEND-SESSION' | translate }}
    </button>
  </div>
</div>
