import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchApiTransaction } from '../../models/open-item';
import { SearchPage } from '../../models/search-page';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  private readonly serviceUrl;
  private mapOfColumnFilters = [];
  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
  };

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.searchAPIUrl;
  }

  resetFilters() {
    this.configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: '20',
      pageIndex: '0',
    };
  }

  setFilterText(filterText: any) {
    this.configObj.filterText = filterText;
  }

  setColumnFilters(choice: any, selection: any) {
    selection = JSON.stringify(selection);
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getRequestParams(
    customConfigObj: { [param: string]: string },
    jsonFilteredValues: boolean = false
  ): { [param: string]: string } {
    const requestParams = {};
    const allConfigs = { ...this.configObj, ...customConfigObj };

    this.addColumnFilters(requestParams);
    Object.keys(allConfigs).forEach((key) => {
      requestParams[key] =
        key === 'filteredValues' && jsonFilteredValues
          ? JSON.stringify(allConfigs[key])
          : allConfigs[key];
    });

    return requestParams;
  }

  request(
    urlPath,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        }
  ): Observable<any[]> {
    return this.http.get<[]>(this.serviceUrl + urlPath, {
      params: params,
      headers: this.buildHeaders(),
      observe: 'body',
    });
  }

  filterCompanyAccountingList(customConfig: any): Observable<any[]> {
    return this.request(
      'accounting/company',
      this.getRequestParams(customConfig, true)
    );
  }

  filterPartnerList(customConfig: any): Observable<any[]> {
    return this.request(
      'accounting/partner',
      this.getRequestParams(customConfig)
    );
  }

  filterRelationshipList(customConfig: any): Observable<any[]> {
    return this.request(
      'accounting/relationship',
      this.getRequestParams(customConfig)
    );
  }

  filterLedgerAccountList(customConfig: any): Observable<any[]> {
    return this.request(
      'accounting/ledger-account',
      this.getRequestParams(customConfig, true)
    );
  }

  getTotalRecordsForSearch(customConfig: any): Observable<any[]> {
    return this.request(
      'accounting/total-records',
      this.getRequestParams(customConfig)
    );
  }

  getDistinctValues(
    customConfig: any,
    table: string,
    column: string
  ): Observable<any> {
    return this.request(
      `accounting/distinct-values/${table}/${column}`,
      this.getRequestParams(customConfig)
    );
  }

  getDistinctValuesByEntity(
    customConfig: any,
    column: string
  ): Observable<any> {
    return this.request(
      `openitems/distinct-values-by-entity/${column}`,
      this.getRequestParams(customConfig, true)
    );
  }

  filterTransactionList(
    customConfig: any
  ): Observable<SearchPage<SearchApiTransaction>> {
    return this.http.get<SearchPage<SearchApiTransaction>>(
      this.serviceUrl + 'openitems/by-entity',
      {
        observe: 'body',
        responseType: 'json',
        params: this.getRequestParams(customConfig, true),
        headers: this.buildHeaders(),
      }
    );
  }

  getKPIsByEntity(
    entityType,
    entityGuid,
    displayedColumns,
    closedTransaction?,
    creditTransaction?
  ): Observable<any[]> {
    const config = {
      fields: displayedColumns,
      entityType,
      entityGuid,
      closedTransactions: closedTransaction ? closedTransaction : false,
      creditTransactions: creditTransaction ? creditTransaction : false,
    };
    return this.http.get<any[]>(this.serviceUrl + 'openitems/kpis-by-entity', {
      observe: 'body',
      responseType: 'json',
      params: this.getRequestParams(config),
      headers: this.buildHeaders(),
    });
  }

  getEntityByGuidAndType(entityType, entityGuid): Observable<any[]> {
    return this.http.get<[]>(
      this.serviceUrl + 'accounting/' + entityGuid + '/' + entityType,
      this.buildWrappedHeaders()
    );
  }

  addColumnFilters(params: { [param: string]: string }): void {
    this.mapOfColumnFilters.forEach(
      (entry) => (params[entry.choice] = entry.values)
    );
  }

  private buildWrappedHeaders() {
    return {
      headers: this.buildHeaders(),
    };
  }

  private buildHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Accept', 'application/json')
      .append('Business-Unit-Id', localStorage.currentBusinessUnitId);
  }
}
