export const debtorPaymentAllocationColumnsConf: any[] = [
  {
    name: 'creditDebitCode',
    index: 0,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT',
    locked: true,
  },
  {
    name: 'transactionCode',
    index: 1,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE',
    locked: true,
  },
  {
    name: 'documentationId',
    index: 2,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-ID',
    locked: true,
  },
  {
    name: 'documentationDate',
    index: 3,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-DATE',
    locked: true,
  },
  {
    name: 'client',
    index: 4,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CLIENT',
    locked: true,
  },
  {
    name: 'externalDebtorId',
    index: 5,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.EXTERNAL-DEBTOR-ID',
    locked: true,
  },
  {
    name: 'internalDebtorId',
    index: 6,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.INTERNAL-DEBTOR-ID',
    locked: true,
  },
  {
    name: 'amount',
    index: 7,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.AMOUNT',
    locked: true,
  },
  {
    name: 'vatPercent',
    index: 8,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-PERCENT',
    locked: true,
  },
  {
    name: 'vatAmount',
    index: 9,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-AMOUNT',
    locked: true,
  },
  {
    name: 'discountDate',
    index: 10,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-DATE',
    locked: true,
  },
  {
    name: 'discountPercent',
    index: 11,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-PERCENT',
    locked: true,
  },
  {
    name: 'discountAmount',
    index: 12,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-AMOUNT',
    locked: true,
  },
  {
    name: 'dueDate',
    index: 13,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUE-DATE',
    locked: true,
  },
  {
    name: 'financed',
    index: 14,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.FINANCED',
    locked: false,
  },
  {
    name: 'approvalCode',
    index: 15,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.APPROVAL-CODE',
    locked: false,
  },
  {
    name: 'nonFinanced',
    index: 16,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.NON-FINANCED',
    locked: false,
  },
  {
    name: 'objectionAmount',
    index: 17,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-AMOUNT',
    locked: false,
  },
  {
    name: 'objectionReason',
    index: 18,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-REASON',
    locked: false,
  },
  {
    name: 'objectionDate',
    index: 19,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-DATE',
    locked: false,
  },
  {
    name: 'discountRate',
    index: 20,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-RATE',
    locked: false,
  },
  {
    name: 'paymentDeductionAmount',
    index: 21,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PAYMENT-DEDUCTION-AMOUNT',
    locked: false,
  },
  {
    name: 'dunningBlockDate',
    index: 22,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUNNING-BLOCK-DATE',
    locked: false,
  },
  {
    name: 'dunningLevel',
    index: 23,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUNNING-LEVEL',
    locked: false,
  },
  {
    name: 'debtCollectionAgency',
    index: 24,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-AGENCY',
    locked: false,
  },
  {
    name: 'debtCollectionStatus',
    index: 25,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-STATUS',
    locked: false,
  },
  {
    name: 'debtCollectionTransferDate',
    index: 26,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-TRANSFER-DATE',
    locked: false,
  },
  {
    name: 'processingEnd',
    index: 27,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PROCESSING-END',
    locked: false,
  },
  {
    name: 'transactionCodeName',
    index: 28,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE-NAME',
    locked: false,
  },
  {
    name: 'openAmount',
    index: 29,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT',
    locked: false,
  },
  {
    name: 'clientId',
    index: 30,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CLIENT-ID',
    locked: false,
  },
  {
    name: 'entryDate',
    index: 31,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ENTRY-DATE',
    locked: false,
  },
  {
    name: 'branchId',
    index: 32,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.BRANCH-ID',
    locked: false,
  },
  {
    name: 'lastChangeDate',
    index: 33,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.LAST-CHANGE-DATE',
    locked: false,
  },
  {
    name: 'transactionId',
    index: 34,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID',
    locked: false,
  },
  {
    name: 'referenceDocumentation',
    index: 35,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-DOCUMENTATION',
    locked: false,
  },
  {
    name: 'referenceText',
    index: 36,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-TEXT',
    locked: false,
  },
  {
    name: 'originalAccount',
    index: 37,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ORIGINAL-ACCOUNT',
    locked: false,
  },
  {
    name: 'allocationNumber',
    index: 38,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-NUMBER',
    locked: false,
  },
  {
    name: 'currency',
    index: 39,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY',
    locked: false,
  },
  {
    name: 'currencyAmount',
    index: 40,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY-AMOUNT',
    locked: false,
  },
  {
    name: 'repurchasingReason',
    index: 41,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REPURCHASING-REASON',
    locked: false,
  },
  {
    name: 'invoiceAmount',
    index: 42,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.INVOICE-AMOUNT',
    locked: false,
  },
  {
    name: 'allocationTimestamp',
    index: 43,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-TIMESTAMP',
    locked: false,
  },
  {
    name: 'allocationAmount',
    index: 44,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-AMOUNT',
    locked: false,
  },
  {
    version: 1,
  },
];
