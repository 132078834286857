import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChecksumService {
  private checksumValue = new BehaviorSubject<number>(0.00);
  checksumValueChanged = this.checksumValue.asObservable();

  updateChecksumData(
    data: UntypedFormArray,
    amountControlName: string,
    debitOrCreditControlName: string
  ) {
    let amountChecksum = 0.00;
    const rawData = data.getRawValue();

    rawData.forEach((element) => {
      const amount: number = element[amountControlName];
      const debitOrCredit: string = element[debitOrCreditControlName];

      switch (debitOrCredit) {
        case 'C':
          amountChecksum += amount;
          break;
        case 'D':
          amountChecksum -= amount;
          break;
        default:
          // Do nothing.
      }
    });

    this.checksumValue.next(amountChecksum);
  }
}
