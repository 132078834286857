export const debitOrCreditOptions: any[] = [
  {
    value: '2',
    translateName:
      'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DEBIT-OR-CREDIT-OPTIONS.CREDIT',
  },
  {
    value: '1',
    translateName:
      'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DEBIT-OR-CREDIT-OPTIONS.DEBIT',
  },
];
