<section class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <p>{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.SHOW-VALIDATION-ERROR-MODAL.ERROR' | translate }}</p>
    <span class="xml-text">{{ awFile.validationError }}</span>
  </div>
  <div class="buttons">
    <button
      id="response-request-dialog-comment-close-button"
      mat-button
      class="custom-button-cancel"
      [mat-dialog-close]
    >
      {{ 'GENERAL.CLOSE' | translate }}
    </button>
  </div>
</section>
