export const bankAccountColumns: string[] = [
  'accountHolder',
  'iban',
  'bic',
  'bank',
  'validFrom',
  'validTo',
  'disbursementType',
  'currency',
  'attachment',
  'isCreatedExternally',
  'actions',
];
