<div class="general-information-container">
  <div class="row">
    <div class="col-md-9 col-sm-12">
      <div class="left-div">
        <div class="logo-image">
          <img
            class="logo"
            src="../assets/images/Tierwohl_Logo.png"
            alt="{{ 'USER_MANAGEMENT.TEAM_VALUES.ANIMAL_WELFARE' | translate }}"
          />
        </div>
        <div class="name-container">
          <div class="names">
            <div class="general-name name-1">{{ 'HOMEPAGE.WELCOME' | translate }}</div>
            <div class="general-name name-2" *ngIf="user">{{ user.firstName }} {{ user.lastName }}</div>
            <div class="general-name name-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-12 justify-content-center">
      <div [ngClass]="{ smallStat: smallStatRequired }">
        <div class="full-height" *ngIf="internalEnv">
          <div class="stat-container">
            <div class="stats" (click)="goToMessageCenter()">
              <div class="stat-number">{{ unreadMessages }}</div>
              <div class="stat-text">
                <span>{{ 'HOMEPAGE.NEWS' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
