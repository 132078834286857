export const liquidityPlanAssetsColumns: string[] = [
  'meaning',
  'planAmount',
  'kind',
  'commentOthers',
  'type',
  'planActual',
  'monthAndYear',
  'comment',
];
