import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ibanValidator } from 'src/app/shared/validators/ibanvalidator.directive';
import { bicValidator } from 'src/app/shared/validators/bicvalidator.directive';
import { AzureFile } from 'src/app/shared/models/azure-file';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { bankAccountColumnsConf } from 'src/app/in-memory-data/company/bank-account/table-columns-configuration';
import { bankAccountColumns } from 'src/app/in-memory-data/company/bank-account/table-columns';
import { disbursementEnum } from 'src/app/in-memory-data/company/bank-account/enums/disbursement-enums';
import { UserService } from 'src/app/shared/services/user/user.service';
import { currenciesEnum } from 'src/app/in-memory-data/company/bank-account/enums/currencies-enums';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { v4 as uuidv4 } from 'uuid';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { FileType } from 'src/app/shared/models/file-type';
import { FileService } from 'src/app/shared/services/file/file.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-bank-account',
  templateUrl: './company-bank-account.component.html',
  styleUrls: ['./company-bank-account.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms')),
      transition('expanded <=> void', animate('150ms')),
    ]),
  ],
})
export class CompanyBankAccountComponent implements OnInit {
  entityName = 'company-bank-account';
  bankAccountForm: UntypedFormGroup;
  disbursementTypes = disbursementEnum;
  currencies = currenciesEnum;
  uploadProgress = false;
  noExistingData = true;
  showForm = false;
  multiple = true;
  showAttachments = true;
  companyBankAccount = true;
  isTask = false;
  editableTask = false;
  isApproved = false;
  uploadedFiles: AzureFile[];
  attachments = [];
  bankAccountsList = [];
  bankAccountId;
  state;
  private CONFIGURATION_KEY = 'company_bank_account_table';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() isDraftExists: boolean;
  @Input() companyGuid;
  @Input() companyApproved;
  @Input() readOnly: boolean;
  dataSource = new MatTableDataSource<any>(this.bankAccountsList);
  columns = bankAccountColumnsConf;
  displayedColumns = bankAccountColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  expandedElement: any;
  approvedBankAccounts = [];
  bankAccountTask;
  bankAccountApproved;
  dateFormat = '';
  animalWelfareEditView = false;
  snapshot;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private fileService: FileService,
    private businessUnitService: BusinessUnitService,
    private activatedRoute: ActivatedRoute
  ) {
    this.bankAccountFormBuilder();
  }

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
    this.getTableConfiguration();
    this.dataSource.sort = this.sort;
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    this.loadData();
    this.getAttachments();
    this.fileService.suscriptionEmitter =
      this.fileService.eventEmiterFunction.subscribe(() => {
        this.listAllFiles();
      });

    if (this.state.contractType) {
      this.animalWelfareEditView = true;
      if (this.state.company.companyGuid) {
        this.getCompanyValuesForExistingCompany(this.state.company.companyGuid);
      }
      this.bankAccountForm.disable();
    }
    if (this.companyGuid) {
      this.animalWelfareEditView = true;
      this.getCompanyValuesForExistingCompany(this.companyGuid);
      this.bankAccountForm.disable();
    }
  }

  getAttachments() {
    this.bankAccountForm
      .get('attachment')
      .valueChanges.subscribe(() => this.uploadFilesWithValidation());
  }

  loadAttachments(row) {
    if (row) {
      this.bankAccountId = row.id;
    }
    this.listAllFiles();
  }

  loadData() {
    if (this.state.task) {
      this.isTask = true;
      this.noExistingData = false;
      this.getCompanyValuesForTask();
    } else if (this.state.company) {
      if (this.state.company.guid) {
        this.isApproved = true;
        this.getCompanyValuesForExistingCompany(this.state.company.guid);
      }
    } else if (this.snapshot.params.guid) {
      this.getBankAccountsForCompanyByCompanyGuid(this.companyApproved);
    }
  }

  getBankAccountsForCompanyByCompanyGuid(data) {
    this.isApproved = true;
    this.noExistingData = false;
    data.bankAccounts = this.addAttachments(data.bankAccounts);
    this.dataSource.data = data.bankAccounts;
    this.bankAccountsList = data.bankAccounts;
    this.approvedBankAccounts = Object.assign([], data.bankAccounts);
  }

  getCompanyValuesForExistingCompany(companyGuid) {
    this.companyService.getCurrentApproved(companyGuid).subscribe(
      (data) => {
        if (data && data.bankAccounts && data.bankAccounts.length > 0) {
          this.noExistingData = false;
          data.bankAccounts = this.addAttachments(data.bankAccounts);
          this.dataSource.data = data.bankAccounts;
          this.bankAccountsList = data.bankAccounts;
          this.approvedBankAccounts = Object.assign([], data.bankAccounts);
        }
      },
      (err) => console.log('ERROR FINDING')
    );
  }

  getCompanyValuesForTask() {
    this.companyService
      .getCompanyWithTaskId(this.state.task.id)
      .subscribe((data) => {
        if (data.bankAccounts) {
          data.bankAccounts = this.addAttachments(data.bankAccounts);
          this.dataSource.data = data.bankAccounts;
          this.bankAccountsList = data.bankAccounts;
          this.companyService
            .getCurrentApproved(data.guid)
            .subscribe((company) => {
              this.approvedBankAccounts = Object.assign(
                [],
                company.bankAccounts
              );
            });
        }
      });
    if (
      window.history.state.task.type ===
      'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT'
    ) {
      this.animalWelfareEditView = true;
    }
    this.taskService.currentEditableTask.subscribe((editableTask) => {
      this.editableTask = editableTask;
      if (!this.editableTask && this.showForm) {
        this.clear();
      }
    });
  }

  bankAccountFormBuilder() {
    this.bankAccountForm = this.formBuilder.group({
      id: [''],
      accountHolder: ['', Validators.required],
      iban: ['', [ibanValidator(), Validators.required]],
      bic: ['', [bicValidator(), Validators.required]],
      bank: ['', Validators.required],
      validFrom: ['', Validators.required],
      validTo: [''],
      disbursementType: ['', Validators.required],
      currency: ['', Validators.required],
      attachment: [[]],
    });
  }

  showBankAccountForm() {
    this.bankAccountApproved = null;
    this.bankAccountForm.reset();
    this.showForm = true;
    this.bankAccountForm.controls.id.setValue(uuidv4());
    this.bankAccountId = this.bankAccountForm.controls.id.value;
    this.bankAccountForm.value.attachment = [];
    this.attachments = [];
    this.uploadProgress = false;
  }

  saveBankAccount() {
    this.noExistingData = false;
    if (this.dataSource.data.length === 0) {
      this.dataSource.data.push(this.bankAccountForm.value);
      this.bankAccountsList = this.dataSource.data;
    } else {
      const bankAccountIndex = this.dataSource.data.findIndex(
        (item) => item.id === this.bankAccountForm.controls.id.value
      );
      if (bankAccountIndex !== -1) {
        this.dataSource.data[bankAccountIndex] = this.bankAccountForm.value;
      } else {
        this.dataSource.data.push(this.bankAccountForm.value);
        this.bankAccountsList = this.dataSource.data;
      }
    }
    this.dataSource.filter = '';
    this.showForm = false;
  }

  edit(row) {
    if (this.state.company) {
      this.bankAccountApproved = this.approvedBankAccounts.find(
        (element) => element.id === row.id
      );

      if (this.bankAccountApproved === -1) {
        this.bankAccountApproved = row;
      }
    }
    this.bankAccountForm.get('id').patchValue(row.id);
    this.bankAccountForm.get('accountHolder').patchValue(row.accountHolder);
    this.bankAccountForm.get('iban').patchValue(row.iban);
    this.bankAccountForm.get('bic').patchValue(row.bic);
    this.bankAccountForm.get('bank').patchValue(row.bank);
    this.bankAccountForm.get('validFrom').patchValue(row.validFrom);
    this.bankAccountForm.get('validTo').patchValue(row.validTo);
    this.bankAccountForm
      .get('disbursementType')
      .patchValue(row.disbursementType);
    this.bankAccountForm.get('currency').patchValue(row.currency);
    this.bankAccountForm.get('attachment').patchValue(row.attachment);
    if (row) {
      this.bankAccountId = row.id;
    }
    this.listAllFiles();
    this.showForm = true;
  }

  clear() {
    this.bankAccountForm.reset();
    this.showForm = false;
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };

    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                bankAccountColumnsConf,
                bankAccountColumns
              );

            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = bankAccountColumnsConf;
    this.displayedColumns = bankAccountColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  addAttachments(dataList) {
    dataList.forEach((item, index) => {
      this.fileService
        .listAllFiles(FileType.companyBankAccount, item.id)
        .subscribe((data: any) => {
          if (data.length > 0) {
            item.attachment = data;
          } else {
            item.attachment = [];
          }
        });
    });
    dataList.forEach((item) => {
      if (!item.attachment) {
        item.attachment = [];
      }
    });
    return dataList;
  }

  uploadFilesWithValidation() {
    let attachments = this.bankAccountForm.get('attachment').value;
    if (!attachments) {
      attachments = [];
    }
    const atLeastOneExists = attachments.some((file) =>
      this.checkIfExistingFile(file.name, this.attachments)
    );

    if (atLeastOneExists) {
      this.notificationService.showToast(
        'NOTIFICATION.TRANSACTION-HISTORY.FILE-EXISTS',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    } else {
      this.uploadFiles(attachments, this.bankAccountId);
    }
  }

  checkIfExistingFile(nameKey, fileList) {
    for (const file of fileList) {
      if (file.fileName === nameKey) {
        return true;
      } else {
        return false;
      }
    }
  }

  uploadFiles(attachments: any, bankAccountId) {
    this.uploadProgress = true;
    for (const file of attachments) {
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('fileType', FileType.companyBankAccount);
      formData.append('id', bankAccountId);
      this.fileService.uploadFile(formData).subscribe(
        () => this.listAllFiles(),
        (err) => this.handleError(err)
      );
    }
  }

  listAllFiles() {
    this.fileService
      .listAllFiles(FileType.companyBankAccount, this.bankAccountId)
      .subscribe(
        (data: any) => {
          this.attachments = data;
          this.uploadProgress = false;
        },
        (err) => this.handleError(err)
      );
  }

  hasBankAccountChanged(element, property) {
    if (
      this.approvedBankAccounts &&
      this.approvedBankAccounts.length > 0 &&
      this.bankAccountsList
    ) {
      const bankIndex = this.bankAccountsList.findIndex((item) => {
        return item.id === element.id;
      });
      const bank = this.bankAccountsList[bankIndex];

      const approvedBankIndex = this.approvedBankAccounts.findIndex((item) => {
        return item.id === element.id;
      });

      if (approvedBankIndex === -1) {
        return true;
      }

      const bankApproved = this.approvedBankAccounts[approvedBankIndex];

      if (property === 'address') {
        for (const [key, value] of Object.entries(bank.address)) {
          return bank.address[key] !== bankApproved.address[key];
        }
      } else if (property === 'name') {
        if (bank.name === bankApproved.name) {
          return bank.surname !== bankApproved.surname;
        } else {
          return true;
        }
      } else {
        return bank[property] !== bankApproved[property];
      }
    } else {
      return false;
    }
  }

  handleError(error) {
    this.uploadProgress = false;
    this.notificationService.showToast(
      'NOTIFICATION.ERROR',
      this.notificationService.MESSAGE_TYPE.ERROR,
      error
    );
  }

  isEditDeniedDueToOrigin() {
    if (this.companyApproved && this.companyApproved.createdExternally) {
      return (
        this.companyApproved.createdExternally &&
        !this.businessUnitService.getExternallyCreatedRecordEditability()
      );
    }
    return false;
  }
}
