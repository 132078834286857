<app-back-button *ngIf="isTask"> </app-back-button>

<div class="edit-task" *ngIf="isTask && !editableTask">
  <button
    id="{{ entityName }}-edit-task"
    *hasPermission="'sc.btc.m.u'"
    mat-button
    class="edit-task-button"
    (click)="changeEditTaskMode(true)"
  >
    <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>
<div class="input-wrapper form-container">
  <form class="form" [formGroup]="btcMappingForm" autocomplete="off">
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.BTC-MAPPING.NAME'"
      [placeholder]="'SYSTEM-CONFIGURATION.BTC-MAPPING.NAME'"
      [label]="'SYSTEM-CONFIGURATION.BTC-MAPPING.NAME'"
      [entityName]="entityName"
      [form]="btcMappingForm"
      [controlObject]="btcMappingForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="btcMappingApproved ? btcMappingApproved.name : null"
      [taskValue]="btcMappingTask ? btcMappingTask.name : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
    ></app-mat-input>

    <div class="table-list table-btc-mapping">
      <table
        class="editable-btcMapping-table"
        mat-table
        [dataSource]="dataSourceBTCs"
        matSort
        id="{{ entityName }}-btc-booking-editable-table"
        aria-hidden="true"
      >
        <ng-container matColumnDef="code">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-code"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC.BTC' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-code-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-name"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC.NAME' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="booked">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-booked"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.BOOK.TITLE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-booked-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  id="{{ entityName }}-booked"
                  [ngClass]="{ 'val-changed': hasChanged(element, 'booked') }"
                  appearance="fill"
                >
                  <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.BOOK.SELECT' | translate }}</mat-label>
                  <mat-select
                    formControlName="booked"
                    id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-booked-select"
                    (selectionChange)="onBookChange($event, element)"
                  >
                    <mat-option [value]="undefined">{{
                      'SYSTEM-CONFIGURATION.BTC-MAPPING.BOOK.NONE' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="
                        let option of bookedOptions | sortBy: { property: 'translateName', descending: false };
                        let i = index
                      "
                      [value]="option.value"
                      id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-booked-option-{{ i }}"
                    >
                      {{ option.translateName | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="creditTransactionCodeId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-creditTransactionCodeId"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.CREDIT-TRANSACTION-CODE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-creditTransactionCodeId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  id="{{ entityName }}-creditTransactionCodeId"
                  [ngClass]="{
                    'val-changed': hasChanged(element, 'creditTransactionCodeId')
                  }"
                >
                  <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.CREDIT-TRANSACTION-CODE' | translate }}</mat-label>
                  <mat-select
                    formControlName="creditTransactionCodeId"
                    id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-creditTransactionCodeId-select"
                    [disabled]="getDisabledStatus(element)"
                    [required]="getRequiredStatus(element)"
                  >
                    <mat-option
                      *ngFor="let option of transactionCodes; let i = index"
                      [value]="option.id"
                      id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-creditTransactionCodeId-option-{{
                        i
                      }}"
                    >
                      {{ createName(option) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="creditOption">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-creditOption"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.CREDIT-OPTIONS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-creditOption-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  id="{{ entityName }}-creditOption"
                  [ngClass]="{
                    'val-changed': hasChanged(element, 'creditOption')
                  }"
                >
                  <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.CREDIT-OPTIONS' | translate }}</mat-label>
                  <mat-select
                    formControlName="creditOption"
                    id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-creditOption-select"
                    [disabled]="getDisabledStatus(element)"
                    [required]="getRequiredStatus(element)"
                  >
                    <mat-option
                      *ngFor="
                        let option of transactionOptions | sortBy: { property: 'translateName', descending: false };
                        let i = index
                      "
                      [value]="option.value"
                      id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-creditOption-option-{{ i }}"
                    >
                      {{ option.translateName | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="debitTransactionCodeId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-debitTransactionCodeId"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.DEBIT-TRANSACTION-CODE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-debitTransactionCodeId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  id="{{ entityName }}-debitTransactionCodeId"
                  [ngClass]="{
                    'val-changed': hasChanged(element, 'debitTransactionCodeId')
                  }"
                >
                  <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.DEBIT-TRANSACTION-CODE' | translate }}</mat-label>
                  <mat-select
                    formControlName="debitTransactionCodeId"
                    id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-debitTransactionCodeId-select"
                    [disabled]="getDisabledStatus(element)"
                    [required]="getRequiredStatus(element)"
                  >
                    <mat-option
                      *ngFor="let option of transactionCodes; let i = index"
                      [value]="option.id"
                      id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-debitTransactionCodeId-option-{{
                        i
                      }}"
                    >
                      {{ createName(option) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="debitOption">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-debitOption"
          >
            {{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.DEBIT-OPTIONS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-debitOption-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div *ngIf="getFormGroup(element) != undefined">
              <div class="form-row" [formGroup]="getFormGroup(element)">
                <mat-form-field
                  appearance="fill"
                  id="{{ entityName }}-debitOption"
                  [ngClass]="{
                    'val-changed': hasChanged(element, 'debitOption')
                  }"
                >
                  <mat-label>{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.DEBIT-OPTIONS' | translate }}</mat-label>
                  <mat-select
                    formControlName="debitOption"
                    id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-creditOption-select"
                    [disabled]="getDisabledStatus(element)"
                    [required]="getRequiredStatus(element)"
                  >
                    <mat-option
                      *ngFor="
                        let option of transactionOptions | sortBy: { property: 'translateName', descending: false };
                        let i = index
                      "
                      [value]="option.value"
                      id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-debitOption-option-{{ i }}"
                    >
                      {{ option.translateName | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsEditableBTC"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsEditableBTC"
          [ngClass]="{ 'draft-row': row.draft }"
        ></tr>
      </table>
      <mat-paginator
        [hidden]="true"
        class="paginator-editable"
        [pageSize]="100"
        [pageSizeOptions]="[100, 200]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </form>
</div>

<div class="filter-input button-container">
  <button
    *ngIf="isTask && editableTask"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-button"
    (click)="changeEditTaskMode(false)"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>
  <button
    *ngIf="!isTask"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-btc-button"
    (click)="changeEditMode()"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>

  <button
    *ngIf="!isTask || (isTask && editableTask)"
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-create-draft-button"
    (click)="saveAsDraft()"
  >
    <span *ngIf="draftId" id="{{ entityName }}-update-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
    </span>
    <span *ngIf="!draftId" id="{{ entityName }}-new-draft-button-span">
      {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
    </span>
  </button>

  <ng-container *ngIf="!isTask || (isTask && editableTask)">
    <button
      *ngIf="!edit"
      mat-button
      class="custom-button"
      [disabled]="!btcMappingForm.valid"
      id="{{ entityName }}-create-button"
      (click)="onClickSave()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
    <button
      *ngIf="edit"
      mat-button
      class="custom-button"
      [disabled]="!btcMappingForm.valid"
      id="{{ entityName }}-update-button"
      (click)="onClickSave()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
