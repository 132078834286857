import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { CompanyListComponent } from '../company/company-list/company-list.component';
import { CompanyCreateComponent } from '../company/company-create/company-create.component';
import { CanAuthenticationGuard } from '../../utils/can.authentication.guard';
import { DirtyCheckGuard } from 'src/app/shared/guards/dirty-check.guard';
import { SalesEntryCreateComponent } from '../sales-entry/sales-entry-create/sales-entry-create.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'company',
        component: CompanyListComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['md.r'] },
      },
      {
        path: 'company/create',
        component: CompanyCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['md.c', 'md.u'] },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'company/create/:guid',
        component: CompanyCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['md.c', 'md.u'] },
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: 'salesEntry/create',
        component: SalesEntryCreateComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['aw.sp.u'] },
        canDeactivate: [DirtyCheckGuard],
      },
      { path: '', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterDataManagementRoutingModule {
  static e = '';
}
