import { Component, Input } from '@angular/core';
import { INavigationTab } from './navigation-tabs.model';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.less'],
})
export class NavigationTabsComponent {
  @Input()
  entityName = '';
  @Input()
  tabs: INavigationTab[] = [];
}
