export const quantityGoodsCreateColumnsConf: any[] = [
  {
    name: 'qsid',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID',
    locked: true,
  },
  {
    name: 'vvvoNr',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR',
    locked: true,
  },
  {
    name: 'productionType',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE',
    locked: true,
  },
  {
    name: 'submissionDate',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE',
    locked: true,
  },
  {
    name: 'measureUnit',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT',
    locked: true,
  },

  {
    name: 'amount',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.AMOUNT',
    locked: true,
  },
  {
    name: 'transfer',
    index: 6,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER',
    locked: true,
  },
  {
    name: 'comment',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT',
    locked: true,
  },
  {
    version: '1',
  },
];
