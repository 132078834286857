import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { AccountKpiService } from '../services/account-kpi.service';

@Component({
  selector: 'app-financial-container',
  templateUrl: './financial-container.component.html',
  styleUrls: ['./financial-container.component.less'],
})
export class FinancialContainerComponent implements OnInit, OnDestroy {
  @Input() kpis;
  originalKpis;
  currentLanguage = 'de';

  constructor(
    private accountKpiService: AccountKpiService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.accountKpiService.updateOringialKpis.subscribe(
      (originalKpis) => (this.originalKpis = originalKpis)
    );

    this.setCurrentLanguage();
  }

  ngOnDestroy(): void {
    this.accountKpiService.setOriginalKpis(null);
  }

  setCurrentLanguage() {
    this.translationService.currentSelectedLanguage.subscribe((lang) => {
      if (!!lang) {
        this.currentLanguage = lang;
      }
    });
  }
}
