import { Component, OnInit, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mat-radio',
  templateUrl: './mat-radio.component.html',
  styleUrls: ['./mat-radio.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatRadioComponent implements OnInit {
  @Input() name;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() items;
  @Input() optionValue;
  @Input() required;
  @Input() changeFunction;
  @Input() titlecasePipe;
  @Input() disabled;
  @Input() sideMenu;
  @Input() inline;
  @Input() elementIndex;
  @Input() twoColumns;

  errorRequired = 'error-message-required';
  newValue: any;
  requiredValidator = false;
  changedValue = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    if (this.controlObject && this.controlObject.validator) {
      const validator = this.controlObject.validator({} as AbstractControl);
      if (validator) {
        this.requiredValidator = validator.required;
      }
    }

    this.changedValue = this.hasChanged(this.controlName);
  }

  hasChanged(fieldName: string) {
    if (
      this.form &&
      this.form.controls[fieldName] &&
      !this.form.controls[fieldName].enabled
    ) {
      return false;
    }

    if (this.taskValue && this.approvedValue) {
      let newFieldValue;
      if (this.newValue) {
        newFieldValue = this.newValue[fieldName];
      }
      if (this.newValue && newFieldValue) {
        return newFieldValue !== this.approvedValue;
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (this.approvedValue && this.newValue) {
      return this.approvedValue !== this.newValue[fieldName];
    } else if (!this.approvedValue && this.taskValue && this.newValue) {
      return this.newValue[fieldName].toString() !== this.taskValue.toString();
    } else if (this.approvedValue === '') {
      if (this.newValue) {
        return (
          (this.newValue[fieldName] ? this.newValue[fieldName] : '') !==
          this.approvedValue
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getTranslatedValue() {
    let result = this.taskValue;
    if (!!this.taskValue && this.items) {
      this.items.forEach((element) => {
        if (element[this.optionValue] === this.taskValue) {
          result = this.translateService.instant(element.translateName);
        }
      });
    }

    return result;
  }

  getItemValue(item) {
    let result;
    Object.keys(item).forEach((key, index) => {
      if (key === this.optionValue) {
        result = item[key];
      }
    });
    return result;
  }

  change($event) {
    if (this.changeFunction) {
      return this.changeFunction($event);
    }
  }

  showRemoveOption() {
    let result = false;
    if (
      this.form.value[this.controlName] != null &&
      this.form.get(this.controlName).enabled
    ) {
      result = true;
    }
    return result;
  }

  isFieldDisabled() {
    if (this.controlObject && this.controlObject.disabled) {
      return true;
    } else {
      return false;
    }
  }

  clearOptions() {
    this.form.value[this.controlName] = null;
    this.form.get(this.controlName).patchValue(null);
  }
}
