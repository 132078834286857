import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { TaskEventType } from 'src/app/shared/models/task-event-type';

@Component({
  selector: 'app-risk-check-task',
  templateUrl: './risk-check-task.component.html',
  styleUrls: ['./risk-check-task.component.less'],
})
export class RiskCheckTaskComponent implements OnInit {
  currentTask;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private taskService: TaskService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog
  ) {}

  ngOnInit() {
    const taskId = this.route.snapshot.paramMap.get('taskId');
    this.taskService.getTask(taskId).subscribe((task) => {
      this.currentTask = task;
      if (this.currentTask.status !== 'OPEN') {
        this.router.navigateByUrl('/tasks/' + this.currentTask.id);
      }
    });
  }

  complete() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        action: ConfirmationModalComponent.ACTION_APPROVE_TASK,
        positiveLabel: 'save',
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        const commentJson = {
          taskId: this.currentTask.id,
          content: result.comment,
          eventType: TaskEventType.COMPLETE_TASK,
        };
        this.taskService.complete(this.currentTask.id, commentJson).subscribe(
          () => {
            this.router.navigateByUrl('/tasks');
            this.showSnackBar('NOTIFICATION.TASK.COMPETED');
          },
          (err) => console.log(err)
        );
      }
    });
  }

  cancel() {
    const commentJson = {
      taskId: this.currentTask.id,
      content: '',
      eventType: TaskEventType.COMPLETE_TASK,
    };
    this.taskService.complete(this.currentTask.id, commentJson).subscribe();
    this.router.navigate(['/tasks']).then((navigated: boolean) => {
      if (navigated) {
        this.showSnackBar('riskmanagement.notifications.canceled-task');
      }
    });
  }

  showSnackBar(message: string) {
    this.notificationService.dismissMessage();
    this.notificationService.showToast(
      message,
      this.notificationService.MESSAGE_TYPE.SUCCESS
    );
  }
}
