import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AddDeductionModalComponent } from '../add-deduction-modal/add-deduction-modal.component';
import { DialogData } from '../../models/dialog-data';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-add-dunning-modal',
  templateUrl: './add-dunning-modal.component.html',
  styleUrls: ['./add-dunning-modal.component.less'],
})
export class AddDunningModalComponent implements OnInit {
  form: UntypedFormGroup;
  dunningDate: any;
  showTabContent: boolean;
  dateFormat = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddDeductionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = this.formBuilder.group({
      dunningDate: ['', Validators.required],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
  }

  saveDunningDateCloseDialog() {
    const newDunning = {
      dunningDate: this.dunningDate,
    };

    this.dialogRef.close({ data: newDunning });
  }

  reloadTabs() {
    this.showTabContent = false;
    setTimeout(() => (this.showTabContent = true), 5000);
  }

  removeDunning(index) {
    this.data.dunningInfo.splice(index, 1);
  }
}
