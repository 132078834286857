export const standards: any[] = [
  {
    code: 'DEBIT',
    name: 'DEBIT',
    translateName: 'BOOKING-CODE.STANDARDS.DEBIT',
  },
  {
    code: 'CREDIT',
    name: 'CREDIT',
    translateName: 'BOOKING-CODE.STANDARDS.CREDIT',
  },
  {
    code: 'DEBIT_OR_CREDIT',
    name: 'DEBIT_AND_CREDIT',
    translateName: 'BOOKING-CODE.STANDARDS.DEBIT-AND-CREDIT',
  },
];
