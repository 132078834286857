import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentManagementService {
  private readonly serviceUrl;
  private readonly searchServiceUrl;

  mapOfColumnFilters = [];

  public configObj = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
  };

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.awDocumentManagementService;
    this.searchServiceUrl = environment.settings.searchAPIUrl;
  }

  findAllByLivestockProducerId(
    livestockProducerId,
    sortedBy,
    sortDirection,
    page,
    pageSize,
    searchTerm
  ): Observable<any> {
    let url = this.serviceUrl + 'documents/filter';
    url +=
      '?sortField=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&pageIndex=' +
      page +
      '&pageSize=' +
      pageSize +
      '&filterText=' +
      searchTerm +
      this.getColumnFilters() +
      '&recipientId=' +
      livestockProducerId;
    return this.http.get<any>(url, this.buildHeaders());
  }

  findAllByFilter(
    searchString,
    docNameList: string[],
    pageIndex: number,
    pageSize: number
  ) {
    const url = this.serviceUrl + 'documents/filter';
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    config['params'] = config['params']
      .append('pageIndex', pageIndex)
      .append('pageSize', pageSize);
    for (const docName of docNameList) {
      config['params'] = config['params'].append('type', docName);
    }
    if (searchString) {
      config['params'] = config['params'].append('filterText', searchString);
    }
    if (this.configObj.sortField) {
      config['params'] = config['params']
        .append('sortField', this.configObj.sortField)
        .append('sortDirection', this.configObj.sortDirection);
    }
    return this.http.get<any>(url, config);
  }

  findAllByParams(httpParams: HttpParams, pageIndex, pageSize) {
    const url = this.serviceUrl + 'documents/filter';
    const config = this.buildHeaders();
    config['params'] = httpParams
      .append('pageIndex', pageIndex)
      .append('pageSize', pageSize);
    return this.http.get<any>(url, config);
  }

  findDocumentManagement(request) {
    return this.http.get(this.serviceUrl + 'documents/management', {
      params: request,
      headers: this.buildHeaders().headers,
    });
  }

  findAllForTask(
    guid,
    pageIndex: number,
    pageSize: number,
    onlyVisibleDocuments: boolean
  ) {
    const url = this.serviceUrl + 'documents/filter';
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    config['params'] = config['params']
      .append('pageIndex', pageIndex)
      .append('pageSize', pageSize)
      .append('taskGuid', guid)
      .append('onlyVisibleDocuments', onlyVisibleDocuments);
    return this.http.get<any>(url, config);
  }

  getFilterFieldNameValues(fieldName): Observable<string[]> {
    return this.http.get<string[]>(
      this.serviceUrl + 'documents/distinctValues?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  getFilterParametersManagementFieldNameValues(fieldName) {
    return this.http.get(
      this.serviceUrl +
        'documents/parameters-management-distinctValues?fieldName=' +
        fieldName,
      this.buildHeaders()
    );
  }

  getFilterOutputManagementFieldNameValues(fieldName) {
    return this.http.get(
      this.serviceUrl +
        'documents/management-distinctValues?fieldName=' +
        fieldName,
      this.buildHeaders()
    );
  }

  getFilterHistoryFieldNameValues(fieldName, recipientId) {
    return this.http.get(
      this.searchServiceUrl +
        'document-management/history-distinctValues?recipientId=' +
        recipientId +
        '&fieldName=' +
        fieldName,
      this.buildHeaders()
    );
  }

  retrieveDocumentHistoryByRecipientId(recipientId): Observable<any> {
    return this.http.get<any>(
      this.searchServiceUrl + 'document-management/' + recipientId,
      this.buildHeaders()
    );
  }

  editDocumentParameters(request): Observable<any> {
    return this.http.put<any>(
      this.serviceUrl + 'documents/reprint-parameters/edit',
      request,
      this.buildHeaders()
    );
  }

  findDocumentManagements(request) {
    const url = this.serviceUrl + 'documents/filter-management';
    return this.http.get<any[]>(url, this.getConfigs(request));
  }

  addColumnFilters(params: HttpParams): HttpParams {
    this.mapOfColumnFilters.forEach((entry) => {
      params = params.append(entry.choice, entry.values);
    });
    return params;
  }

  getConfigs(customConfigObj: any, jsonFilteredValues = false): any {
    const allConfigs = customConfigObj
      ? { ...this.configObj, ...customConfigObj }
      : this.configObj;
    const config = this.buildHeaders();
    config['params'] = new HttpParams();
    Object.keys(allConfigs).forEach((key) => {
      if (key === 'filteredValues' && jsonFilteredValues) {
        console.log(allConfigs[key]);
        config['params'] = config['params'].append(
          key,
          JSON.stringify(allConfigs[key])
        );
      } else {
        config['params'] = config['params'].append(key, allConfigs[key]);
      }
    });
    config['params'] = this.addColumnFilters(config['params']);
    return config;
  }

  request(url, config): Observable<any> {
    return this.http.get<[]>(this.searchServiceUrl + url, config);
  }

  filterDocumentHistoryList(
    customConfig: any,
    recipientId: any
  ): Observable<any> {
    return this.request(
      'document-management/filter' + '?recipientId=' + recipientId,
      this.getConfigs(customConfig, true)
    );
  }

  hideDocuments(request): Observable<any> {
    return this.http.put<any>(
      this.serviceUrl + 'documents/hide',
      request,
      this.buildHeaders()
    );
  }

  unhideDocuments(request): Observable<any> {
    return this.http.put<any>(
      this.serviceUrl + 'documents/unhide',
      request,
      this.buildHeaders()
    );
  }

  findByPrintTaskId(customConfig: any, printTaskId: any): Observable<any> {
    return this.http.get<[]>(
      this.serviceUrl +
        'documents/by-print-task-id' +
        '?printTaskId=' +
        printTaskId,
      this.getConfigs(customConfig, true)
    );
  }

  download(id: any, filename: string) {
    return this.http.get(
      this.serviceUrl + 'documents/download/' + id + '?filename=' + filename,
      {
        responseType: 'blob',
        headers: this.buildHeadersForFileDownload().headers,
      }
    );
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice: choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice: choice,
        values: selection,
      });
    }
  }

  resetFilters() {
    this.configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: '20',
      pageIndex: '0',
      filteredValues: [],
    };
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  private buildHeadersForFileDownload() {
    return {
      headers: new HttpHeaders().append('Content-type', 'application/json'),
    };
  }
}
