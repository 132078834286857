export const measureUnitOptions: any[] = [
  {
    name: 'Kg',
    alternativeNames: ['kilogram', 'Kilogram'],
    translateName:
      'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT-OPTIONS.OPTION-1',
    translateNameList:
      'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT-OPTIONS.LIST-OPTION-1',
  },
  {
    name: 'Unit',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT-OPTIONS.OPTION-2',
    translateNameList:
      'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT-OPTIONS.LIST-OPTION-2',
  },
];
