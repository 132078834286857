<app-back-button [address]="'systemConfiguration/booking'"></app-back-button>
<div class="edit-task">
  <button
    *hasPermission="'bc.u'"
    id="{{ entityName }}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeEditable(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>
<div class="title-form">
  <h3 class="title-info title-h5" *ngIf="!editMode" id="{{ entityName }}-form-title-create">
    {{ 'BOOKING-CODE.CREATION' | translate }}
  </h3>
  <h3 class="title-info title-h5" *ngIf="editMode" id="{{ entityName }}-form-title-edit">
    {{ 'BOOKING-CODE.COFIGURATION' | translate }}
  </h3>
</div>

<div class="tab-container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div [ngClass]="{ 'error-tab': getErrorTab(0) }" id="{{ entityName }}-general-information-tab">
          {{ 'GENERAL-INFORMATION' | translate }}
        </div>
      </ng-template>
      <div class="input-wrapper form-container">
        <div class="draft-author draft-no-tabs" *ngIf="alreadyDraft">
          <span class="draft-author-label">{{ 'GENERAL.CREATED-BY' | translate }}:</span
          ><span class="draft-author-value">{{ getUserInfo() }}</span>
        </div>
        <app-booking-code-general-information
          *ngIf="bookingCodeApproved || entitiesLoaded"
          [bookingCodeApproved]="bookingCodeApproved"
          [bookingCodeTask]="bookingCodeTask"
          [bookingCodeInitial]="bookingCodeInitial"
          [isDraftExists]="isDraftExists"
        >
        </app-booking-code-general-information>
      </div>
    </mat-tab>
    <mat-tab *ngIf="bookingCodeApproved && !bookingCodeTask">
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-bu-overview-tab">
          {{ 'BOOKING-CODE.BUSINESS-UNIT-OVERVIEW' | translate }}
        </div>
      </ng-template>
      <app-booking-code-bu-overview [bookingCodeId]="bookingCodeApproved.id"></app-booking-code-bu-overview>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="filter-input button-container" *ngIf="editableTask || !isTask">
  <button
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-button"
    *ngIf="editableTask && isTask"
    (click)="changeEditable(false)"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>
  <ng-container *hasPermission="'bc.u'">
    <button
      *ngIf="editMode && bookingCodeGeneralInformationComponent && !isModify()"
      mat-button
      class="custom-button-cancel"
      [disabled]="!checkOneItemValue(bookingCodeGeneralInformationComponent.bookingCodeForm.value) || isDraftExists"
      (click)="saveAsDraft()"
      id="{{ entityName }}-create-draft-button"
    >
      <span *ngIf="alreadyDraft" id="{{ entityName }}-update-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
      </span>
      <span *ngIf="!alreadyDraft" id="{{ entityName }}-new-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
      </span>
    </button>
    <button
      *ngIf="editMode && bookingCodeGeneralInformationComponent"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      [disabled]="!bookingCodeGeneralInformationComponent.bookingCodeForm.valid || isDraftExists"
      (click)="onClickSave()"
    >
      <span *ngIf="editMode && !alreadyDraft">
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
      <span *ngIf="alreadyDraft">
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'bc.c'">
    <button
      *ngIf="!editMode && bookingCodeGeneralInformationComponent && !isModify()"
      mat-button
      class="custom-button-cancel"
      [disabled]="!checkOneItemValue(bookingCodeGeneralInformationComponent.bookingCodeForm.value)"
      (click)="saveAsDraft()"
      id="{{ entityName }}-create-draft-button"
    >
      <span *ngIf="alreadyDraft" id="{{ entityName }}-update-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
      </span>
      <span *ngIf="!alreadyDraft" id="{{ entityName }}-new-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
      </span>
    </button>
    <button
      *ngIf="!editMode && bookingCodeGeneralInformationComponent"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
      [disabled]="!bookingCodeGeneralInformationComponent.bookingCodeForm.valid"
      (click)="onClickSave()"
    >
      <span *ngIf="!editMode">
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
