<app-back-button *ngIf="isTask"> </app-back-button>

<div class="edit-task">
  <button
    *hasPermission="'aw.lp.u'"
    id="{{entityName}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeTaskState(true)"
  >
    <img
      *ngIf="
        !editableTask && isTask && taskType != 'AW_RESERVE_BUDGET_STATUS' && taskType != 'AW_ALLOCATE_BUDGET_STATUS'
      "
      src="assets/images/ic_edit.svg"
      alt="{{ 'GENERAL.EDIT' | translate }}"
    />
  </button>
</div>
<div class="title-form" *ngIf="isTask">
  <h5 class="title-h5" id="{{ entityName }}-form-title">
    {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.ENTRIES.LIVESTOCK-PRODUCER' | translate }}
  </h5>
</div>

<div
  class="input-wrapper form-container"
  [ngClass]="{ 'no-padding-buttom': isTask == false }"
  [formGroup]="livestockFunctionalButtonsForm"
>
  <div class="form">
    <div [formGroup]="generalForm" *ngIf="isTask">
      <app-mat-input
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.NAME'"
        [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.NAME'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.NAME'"
        [entityName]="entityName"
        [form]="generalForm"
        [controlObject]="generalForm.controls.locationName"
        [controlName]="'locationName'"
        [approvedValue]="null"
        [taskValue]="livestockProducerApproved ? livestockProducerApproved.locationName : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      >
      </app-mat-input>
      <app-mat-input
        *ngIf="generalForm && generalForm.controls && generalForm.controls.locationNumber"
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.VVVO-NR'"
        [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.VVVO-NR'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.VVVO-NR'"
        [entityName]="entityName"
        [form]="generalForm"
        [controlObject]="generalForm.controls.locationNumber"
        [controlName]="'locationNumber'"
        [approvedValue]="null"
        [taskValue]="livestockProducerApproved ? livestockProducerApproved.locationNumber : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      >
      </app-mat-input>
      <app-mat-input
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TYPE-OF-PRODUCTION'"
        [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TYPE-OF-PRODUCTION'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TYPE-OF-PRODUCTION'"
        [entityName]="entityName"
        [form]="generalForm"
        [controlObject]="generalForm.controls.productionType"
        [controlName]="'productionType'"
        [approvedValue]="null"
        [taskValue]="livestockProducerApproved ? livestockProducerApproved.productionType : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
      >
      </app-mat-input>
      <div *ngIf="!editableTask && isTask" class="non-editable-info-container">
        <div class="ng-star-inserted">
          <div class="object-row">
            <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
              <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
                <span class="object-label">
                  {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.ACCOUNT-NUMBER' | translate }}:
                </span>
              </div>
              <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
                <span class="object-value">
                  {{ accountNumber }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="non-editable-info-container"
        *ngIf="taskType == 'AW_RESERVE_BUDGET_STATUS' || taskType == 'AW_ALLOCATE_BUDGET_STATUS'"
      >
        <div class="object-row">
          <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
            <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
              <span class="object-label">
                {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS' | translate }}:
              </span>
            </div>
            <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
              <span class="object-value">
                {{
                  'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-CHANGE'
                    | translate
                      : {
                          newStatus: newStatus | translate,
                          oldStatus: oldStatus | translate
                        }
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [formGroup]="livestockFunctionalButtonsForm"
      *ngIf="!isTask || (isTask && taskType == 'APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS')"
    >
      <div class="information-text">
        <div class="non-editable-info-container">
          <span>{{
            'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.INFORMATION-TEXT' | translate
          }}</span>
        </div>
      </div>
      <app-mat-slider
        *ngIf="!isTask || (isTask && editableTask)"
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.DISPATCHED-DOCUMENTS'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.DISPATCHED-DOCUMENTS'"
        [entityName]="entityName"
        [form]="livestockFunctionalButtonsForm"
        [controlName]="'dispatchedDocuments'"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
      ></app-mat-slider>

      <app-mat-slider
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.ACCOUNT-STATEMENT'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.ACCOUNT-STATEMENT'"
        [entityName]="entityName"
        [form]="livestockFunctionalButtonsForm"
        [controlName]="'accountStatement'"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [taskValue]="draftObject && draftObject.draft ? draftObject.draft.accountStatement : null"
        [approvedValue]="livestockProducerApproved ? livestockProducerApproved.dispatchAccountStatement : null"
      ></app-mat-slider>
      <app-mat-slider
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.QUARTERLY-BILLING'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.QUARTERLY-BILLING'"
        [entityName]="entityName"
        [form]="livestockFunctionalButtonsForm"
        [controlName]="'quarterlyBilling'"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [taskValue]="draftObject && draftObject.draft ? draftObject.draft.quarterlyBilling : null"
        [approvedValue]="livestockProducerApproved ? livestockProducerApproved.dispatchQuarterlyBilling : null"
      ></app-mat-slider>
      <app-mat-slider
        *ngIf="beginningOfTypeOfProduction === '2'"
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.CERTIFICATE-OF-PARTICIPATION'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.CERTIFICATE-OF-PARTICIPATION'"
        [entityName]="entityName"
        [form]="livestockFunctionalButtonsForm"
        [controlName]="'certificateOfParticipation'"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [taskValue]="draftObject && draftObject.draft ? draftObject.draft.certificateOfParticipation : null"
        [approvedValue]="
          livestockProducerApproved ? livestockProducerApproved.dispatchCertificateOfParticipation : null
        "
      ></app-mat-slider>
      <app-mat-slider
        *ngIf="beginningOfTypeOfProduction === '2'"
        [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.PARTICIPATION-OF-REVOCATION'"
        [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.PARTICIPATION-OF-REVOCATION'"
        [entityName]="entityName"
        [form]="livestockFunctionalButtonsForm"
        [controlName]="'participationOfRevocation'"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [taskValue]="draftObject && draftObject.draft ? draftObject.draft.participationOfRevocation : null"
        [approvedValue]="livestockProducerApproved ? livestockProducerApproved.dispatchParticipationRevocation : null"
      ></app-mat-slider>
    </div>
    <div
      class="participation-form-container"
      [formGroup]="participationPeriodForm"
      *ngIf="
        (!isTask && participationPeriodExists) ||
        (isTask &&
          (taskType == 'AW_TERMINATE_DATE_CHANGE' ||
            taskType == 'AW_TERMINATE_DATE_DELETE' ||
            taskType == 'AW_TERMINATE_DATE_SET'))
      "
    >
      <ng-container *hasPermission="'int.aw'">
        <app-mat-select
          *ngIf="!isTask && participationPeriodExists"
          [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS'"
          [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS'"
          [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS'"
          [entityName]="entityName"
          [form]="participationPeriodForm"
          [controlObject]="participationPeriodForm.controls.status"
          [controlName]="'status'"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [options]="budgetStatusOptions"
          [optionValue]="'value'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [approvedValue]="participationPeriod ? participationPeriod.status : null"
          [taskValue]="null"
          [extraOption]="isTerminated"
          [extraOptionValue]="'TERMINATED'"
          [extraOptionTranslateText]="
            'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.BUDGET-STATUS-OPTIONS.TERMINATED'
          "
        >
        </app-mat-select>
      </ng-container>

      <ng-container *hasPermission="'int.aw'">
        <app-mat-input-date
          [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.RELEASE-DATE'"
          [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.RELEASE-DATE'"
          [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.RELEASE-DATE'"
          [entityName]="entityName"
          [form]="participationPeriodForm"
          [controlObject]="participationPeriodForm.controls.releaseDate"
          [controlName]="'releaseDate'"
          [approvedValue]="
            participationPeriod
              ? participationPeriod.releaseDate
              : participationPeriodTask
              ? participationPeriodTask.oldReleaseDate
              : null
          "
          [taskValue]="participationPeriodTask ? participationPeriodTask.newReleaseDate : null"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [compareMode]="true"
        ></app-mat-input-date>
      </ng-container>

      <div
        *ngIf="
          participationPeriodForm.errors?.datesRequiredError &&
          (participationPeriodForm.touched || participationPeriodForm.dirty) &&
          !participationPeriodForm.get('releaseDate').hasError('required') &&
          !participationPeriodForm.get('releaseDate').hasError('invalid')
        "
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.BOTH-DATES-REQUIRED'
            | translate
              : {
                  date_field_name_1:
                    'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.RELEASE-DATE' | translate,
                  date_field_name_2:
                    'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'
                    | translate
                }
        }}
      </div>
      <ng-container *hasPermission="'int.aw'">
        <app-mat-input-date
          [name]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'"
          [placeholder]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'"
          [label]="'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'"
          [entityName]="entityName"
          [form]="participationPeriodForm"
          [controlObject]="participationPeriodForm.controls.releaseExecutionDate"
          [controlName]="'releaseExecutionDate'"
          [approvedValue]="
            participationPeriod
              ? participationPeriod.releaseExecutionDate
              : participationPeriodTask
              ? participationPeriodTask.oldTerminationDate
              : null
          "
          [taskValue]="participationPeriodTask ? participationPeriodTask.newTerminationDate : null"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [compareMode]="true"
        ></app-mat-input-date>
      </ng-container>

      <div
        *ngIf="
          participationPeriodForm.errors?.dateError &&
          (participationPeriodForm.touched || participationPeriodForm.dirty) &&
          !participationPeriodForm.get('releaseExecutionDate').hasError('required') &&
          !participationPeriodForm.get('releaseExecutionDate').hasError('invalid')
        "
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.INVALID'
            | translate
              : {
                  name:
                    'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'
                    | translate
                }
        }}
      </div>
      <div
        *ngIf="
          participationPeriodForm.errors?.datesRequiredError &&
          (participationPeriodForm.touched || participationPeriodForm.dirty) &&
          !participationPeriodForm.get('releaseExecutionDate').hasError('required') &&
          !participationPeriodForm.get('releaseExecutionDate').hasError('invalid')
        "
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.BOTH-DATES-REQUIRED'
            | translate
              : {
                  date_field_name_1:
                    'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.RELEASE-DATE' | translate,
                  date_field_name_2:
                    'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.EARLIEST-TERMINATION-DATE'
                    | translate
                }
        }}
      </div>
    </div>
  </div>
</div>

<div
  class="filter-input button-container"
  *ngIf="!isTask || (isTask && editableTask)"
  [ngClass]="{ 'right-placement': isTask == true }"
>
  <ng-container *ngIf="isTask && editableTask">
    <button
      mat-button
      class="custom-button-cancel margin-button"
      id="{{ entityName }}-document-dispatch-locks-cancel-button"
      (click)="changeTaskState(false)"
    >
      <span>
        {{ 'GENERAL.CANCEL' | translate }}
      </span>
    </button>
  </ng-container>
  <ng-container *hasPermission="'aw.lp.u'; &quot;int.aw&quot;">
    <button
      mat-button
      class="custom-button"
      [disabled]="
        draftExists == true ||
        !livestockFunctionalButtonsForm.valid ||
        checkValidityParticipationPeriodForm() ||
        (checkLivestockFormWithOriginalObject() && checkParticipationPeriodFormWithOriginalObject())
      "
      id="{{ entityName }}-document-dispatch-locks-save-button"
      (click)="saveDocumentDispatchLockSettings()"
    >
      <span>
        {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.FUNCTIONAL-BUTTONS.SAVE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
