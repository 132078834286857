<form [formGroup]="form" class="form" autocomplete="off">
  <table
    aria-hidden="true"
    mat-table
    [dataSource]="editableDataSource"
    class="mat-elevation-z8 table-fixed"
    formArrayName="objects"
  >
    <!-- Column definitions -->
    <ng-container matColumnDef="select">
      <td mat-cell *matCellDef="let row" class="check-box-edit width-table-selection-column">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="clientDebtorId" sticky>
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Client Debtor Id</th>
      <td class="width-table-column" mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field id="{{ entityName }}-form-clientDebtorId">
          <mat-label>Client Debtor Id</mat-label>
          <mat-select formControlName="clientDebtorId" id="{{ entityName }}-form-clientDebtorId-select" required>
            <mat-option
              *ngFor="let cdid of clientDebtorIds; let i = index"
              [value]="cdid"
              id="{{ entityName }}-form-clientDebtorId-option-{{ i }}"
            >
              {{ cdid }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="clientOpenItemId">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Client Open Item Id</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Client Open Item Id</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-clientOpenItemId"
            matInput
            type="text"
            (keyup)="checkAllOpenItemsId($event.target.value)"
            formControlName="clientOpenItemId"
            placeholder="Client Open Item Id"
            required
            [removeHtmlTags]="index.controls.clientOpenItemId"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="errorClientOpenItemId && (form.touched || form.dirty)"
        >
          <span
            class="material-icons error-table"
            title="{{ 'OPEN-TASK.ERROR-MESSAGES.CLIENT-OPEN-ITEM-ID' | translate }}"
          >
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="openItemDate">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Open item Date</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Open Item Date</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-openItemDate"
            [matDatepicker]="datePicker"
            (dateChange)="checkOpenItemDate($event.target.value); checkDueDate(); checkObjectionDate()"
            matInput
            formControlName="openItemDate"
            placeholder="Open Item date"
            name="openItemDate"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="errorOpenItemDate && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.OPEN-ITEM-DATE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="bookingCode">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Transaction Code</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Transaction Code</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-bookingCode"
            matInput
            type="number"
            formControlName="bookingCode"
            placeholder="Transaction Code"
            (input)="checkBookingCode($event.target.value)"
            required
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="!bookingCodeExists && (bookingCodeControl.touched || bookingCodeControl.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.BOOKING-CODE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Debit/Credit Code</th>
      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>Debit/Credit Code</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-debitOrCredit"
            matInput
            type="number"
            formControlName="debitOrCredit"
            placeholder="Debit/Credit Code"
            required
          />
        </mat-form-field>

        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.cardError && (form.touched || form.dirty)"
        >
          <span
            class="material-icons error-table"
            title="{{ 'OPEN-TASK.ERROR-MESSAGES.DEBIT-CREDIT-CODE' | translate }}"
          >
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Amount</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-amount"
            matInput
            type="number"
            formControlName="amount"
            placeholder="Amount"
            required
          />
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Currecy Code</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field id="{{ entityName }}-form-currencyCodes">
          <mat-label>Currency Code</mat-label>
          <mat-select formControlName="currency" id="{{ entityName }}-form-currencyCodes-select" required>
            <mat-option
              *ngFor="let code of currencyCodes | sortBy: { property: null, descending: false }; let i = index"
              [value]="code"
              id="{{ entityName }}-form-currencyCodes-option-{{ i }}"
            >
              {{ code }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="vatAmount">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Vat Amount</th>
      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>Vat Amount</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-vatAmount"
            matInput
            type="number"
            formControlName="vatAmount"
            placeholder="Vat Amount"
            required
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.vatAmountError && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.VAT-AMOUNT' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="vatPercent">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Vat Percent</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Vat Percent</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-vatPercent"
            matInput
            type="number"
            formControlName="vatPercent"
            placeholder="Vat Percent"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.vatCodePercentMandatoryError && (form.touched || form.dirty)
          "
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.VAT-PERCENT' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="vatCode">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Vat Code</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Vat Code</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-vatCode"
            matInput
            type="number"
            formControlName="vatCode"
            placeholder="Vat Code"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.vatCodePercentMandatoryError && (form.touched || form.dirty)
          "
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.VAT-CODE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Due Date</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Due Date</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-dueDate"
            [matDatepicker]="datePicker"
            (dateChange)="checkDueDate()"
            matInput
            formControlName="dueDate"
            placeholder="Due Date"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="errorDueDate && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.DUE-DATE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="referenceClientsOpenItemId">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Reference Clients Open Item Id</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Reference Client Opent Item Id</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-referenceClientsOpenItemId"
            matInput
            type="text"
            formControlName="referenceClientsOpenItemId"
            placeholder="Reference Clients Open Item Id"
            [removeHtmlTags]="index.controls.referenceClientsOpenItemId"
          />
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="additionalInformation">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Additional Information</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Additional Information</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-additionalInformation"
            matInput
            type="text"
            formControlName="additionalInformation"
            placeholder="Aditional Information"
            [removeHtmlTags]="index.controls.additionalInformation"
          />
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="branchId">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Branch ID</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Branch ID</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-branchId"
            matInput
            type="text"
            formControlName="branchId"
            placeholder="Branch Id"
            [removeHtmlTags]="index.controls.branchId"
          />
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="iban">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Currecy Code</th>

      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>IBAN</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-iban"
            matInput
            type="text"
            formControlName="iban"
            placeholder="IBAN"
            [removeHtmlTags]="index.controls.iban"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.ibanMandatoryError && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.IBAN-MANDATORY' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="bic">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">BIC</th>
      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>BIC</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-bic"
            matInput
            type="text"
            formControlName="bic"
            placeholder="BIC"
            [removeHtmlTags]="index.controls.bic"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.bicMandatoryError && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.BIC' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="accountHolder">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Account Holder</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Account Holder</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-accountHolder"
            matInput
            type="text"
            formControlName="accountHolder"
            placeholder="Account Holder"
            [removeHtmlTags]="index.controls.accountHolder"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.accountHolderMandatoryError && (form.touched || form.dirty)
          "
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.ACCOUNT-HOLDER' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentReference">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Pyament Reference</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Payment Reference</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-paymentReference"
            matInput
            type="text"
            formControlName="paymentReference"
            placeholder="Payment Reference"
            [removeHtmlTags]="index.controls.paymentReference"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.paymentReferenceMandatoryError && (form.touched || form.dirty)
          "
        >
          <span
            class="material-icons error-table"
            title="{{ 'OPEN-TASK.ERROR-MESSAGES.PAYMENT-REFERENCE' | translate }}"
          >
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="paymentScheme">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Payment Scheme</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field id="{{ entityName }}-form-paymentScheme">
          <mat-label>Payment Scheme</mat-label>
          <mat-select formControlName="paymentScheme" id="{{ entityName }}-form-paymentScheme-select">
            <mat-option
              *ngFor="let ps of paymentSchemes | sortBy: { property: null, descending: false }; let i = index"
              [value]="ps"
              id="{{ entityName }}-form-paymentScheme-option-{{ i }}"
            >
              {{ ps }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.paymentSchemeMandatoryError && (form.touched || form.dirty)
          "
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.PAYMENT-SCHEME' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Payment Date</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Payment Date</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-paymentDate"
            matInput
            [matDatepicker]="datePicker"
            formControlName="paymentDate"
            placeholder="Payment Date"
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.paymentDateMandatoryError && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.PAYMENT-DATE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="debtCollection">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Debt Collection</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Debt Collection</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-debtCollection"
            matInput
            type="number"
            formControlName="debtCollection"
            placeholder="Debt Collection"
            required
            min="1"
            max="2"
          />
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="debtCollectionTransferDate">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Debt Collection Transfer Date</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Debt Collection Transfer Date</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-debtCollectionTransferDate"
            matInput
            [matDatepicker]="datePicker"
            formControlName="debtCollectionTransferDate"
            placeholder="Debt Collection Tranfer Date"
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="
            form.controls.objects['controls'][0].errors?.debtCollectionTransferDateRequired &&
            (form.touched || form.dirty)
          "
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.DEBT-COLLECTION' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="objectionCode">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Objection Code</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field id="{{ entityName }}-form-objectionCode">
          <mat-label>Objection Code</mat-label>
          <mat-select formControlName="objectionCode" id="{{ entityName }}-form-objectionCode-select">
            <mat-option
              *ngFor="let oc of objectionCode | sortBy: { property: null, descending: false }; let i = index"
              [value]="oc"
              id="{{ entityName }}-form-objectionCode-option-{{ i }}"
            >
              {{ oc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="objectionDate">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Objection Date</th>
      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" class="width-table-column">
        <mat-form-field>
          <mat-label>Objection Date</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-objectionDate"
            (dateChange)="checkObjectionDate()"
            matInput
            [matDatepicker]="datePicker"
            formControlName="objectionDate"
            placeholder="Objection Date"
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="errorObjectionDate && (form.touched || form.dirty)"
        >
          <span class="material-icons error-table" title="{{ 'OPEN-TASK.ERROR-MESSAGES.OBJECTION-DATE' | translate }}">
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="objectionAmount">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Objection Amount</th>
      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>Objection Amount</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-objectionAmount"
            matInput
            type="number"
            formControlName="objectionAmount"
            placeholder="Objection Amount"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.objectionAmountError && (form.touched || form.dirty)"
        >
          <span
            class="material-icons error-table"
            title="{{ 'OPEN-TASK.ERROR-MESSAGES.OBJECTION-AMOUNT' | translate }}"
          >
            priority_high
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="settlementInEUR">
      <th mat-header-cell *matHeaderCellDef class="v25-th" scope="col">Currecy Code</th>
      <td
        class="column-container"
        mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
        class="width-table-column"
      >
        <mat-form-field>
          <mat-label>Settlement Euro</mat-label>
          <input
            autocomplete="off"
            id="{{ entityName }}-form-settlementInEUR"
            matInput
            type="text"
            formControlName="settlementInEUR"
            placeholder="Settlement Euro"
            [removeHtmlTags]="index.controls.settlementInEUR"
          />
        </mat-form-field>
        <div
          class="t-a-c tooltips-form"
          [ngClass]="{ 'tooltips-form-isIE': isIE }"
          *ngIf="form.controls.objects['controls'][0].errors?.settlementError && (form.touched || form.dirty)"
        >
          <span
            class="material-icons error-table"
            title="{{ 'OPEN-TASK.ERROR-MESSAGES.SETTLEMENT-IN-EUR' | translate }}"
          >
            priority_high
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd [sticky]="true">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" scope="col"></th>
      <td mat-cell *matCellDef="let element">
        <button class="menu" mat-icon-button [matMenuTriggerFor]="menu" id="{{ entityName }}-form-moreOptions">
          <span class="material-icons"> more_vert </span>
        </button>
      </td>
    </ng-container>

    <!-- Row definitions -->
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
  </table>
  <mat-menu #menu="matMenu" class="menu-actions">
    <ng-container *hasPermission="'op.c'">
      <button mat-menu-item (click)="addNewItem()" [disabled]="!form.valid" id="{{ entityName }}-form-addOpenItem">
        <span class="material-icons icon-menu"> done </span>
        <span translate>OPEN-TASK.ACTIONS.CREATE-ITEM</span>
      </button>
      <button mat-menu-item (click)="addDeduction()" id="{{ entityName }}-form-addDeduction">
        <span class="material-icons icon-menu"> add </span>
        <span translate>OPEN-TASK.ACTIONS.CREATE-DEDUCTION</span>
      </button>
      <button mat-menu-item (click)="addDunningInfo()" id="{{ entityName }}-form-addDunningInfo">
        <span class="material-icons icon-menu"> add </span>
        <span translate>OPEN-TASK.ACTIONS.CREATE-DUNNING</span>
      </button>
    </ng-container>
  </mat-menu>
</form>
