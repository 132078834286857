import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  animate,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessUnitService } from './shared/services/business-unit/business-unit.service';
import { TaskService } from './shared/services/task/task.service';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DismissChangesDialogComponent } from './shared/dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { SessionExpiredModalComponent } from './shared/modals/session-expired-modal/session-expired-modal.component';
import { UserService } from './shared/services/user/user.service';
import { handleError } from './utils/error-util';
import { ClientDisbursementService } from './shared/services/client-disbursement/client-disbursement.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { productEnum } from './in-memory-data/business-unit/enums/product-enum';
import { TranslationService } from './shared/services/translation/translation.service';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { first, switchMap } from 'rxjs/operators';
import { TablesService } from './shared/tables/tables.service';
import { Meta } from '@angular/platform-browser';
import moment from 'moment';
import { CountryPickerService } from 'src/app/shared/services/countries/country-picker.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

const SESSION_EXTENSION_IN_SEC = 120;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less', './app-sm.component.less'],
  animations: [
    trigger('slideInOut', [
      state(
        'no',
        style({
          width: '3em',
        })
      ),
      state(
        'yes',
        style({
          width: '27em',
        })
      ),
      transition(
        'no => yes',
        group([
          query(
            ':self',
            animate('100ms ease-in-out', style({ width: '27em' }))
          ),
        ])
      ),
      transition(
        'yes => no',
        group([
          query(':self', animate('100ms ease-in-out', style({ width: '6em' }))),
        ])
      ),
    ]),
    trigger('slideOutIn', [
      state(
        'no',
        style({
          'margin-left': '3em',
        })
      ),
      state(
        'yes',
        style({
          'margin-left': '27em',
        })
      ),
      transition('* => *', animate('100ms ease-in-out')),
    ]),
    trigger('lockAnimation', [
      state(
        '0',
        style({
          'text-align': 'left',
        })
      ),
      state(
        '1',
        style({
          'text-align': 'right',
        })
      ),
      transition('* => *', animate('100ms ease-in-out')),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterContentChecked {
  keycloakRefreshTimer;
  sideNavOpen: string;
  showText = false;
  opacity;
  locked = '0';
  title = 'vision25-ui';
  userBusinessUnits: { id: number; name: string; product?: number }[];
  selectedBusinessUnit: number;
  taskNames = '';
  sessionDialog;
  loaderSpinner = false;
  buProducts = productEnum;
  createdLanguages;
  selectedLanguage;
  transparentBackground = false;
  applicationVersion: string;
  rootDomain: string;
  isoCodeEN = 'en';
  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private translate: TranslateService,
    private kcService: KeycloakService,
    private router: Router,
    private countryService: CountryPickerService,
    private businessUnitService: BusinessUnitService,
    private matDialogService: MatDialog,
    private taskService: TaskService,
    private clientDisbursementService: ClientDisbursementService,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>,
    private notificationService: NotificationService,
    private cdref: ChangeDetectorRef,
    private translationService: TranslationService,
    private activatedRoute: ActivatedRoute,
    private tableService: TablesService,
    private meta: Meta,
    private _idle: Idle
  ) {
    if (this.validateExpiredToken() === true) {
      this.kcService.updateToken(SESSION_EXTENSION_IN_SEC).then((refreshed) => {
        window.location.reload();
      });
    } else {
      this.translationService.currentLanguageChange.subscribe((data) => {
        if (data != null) {
          this.loadLanguages();
        }
      });

      router.events.subscribe((val) => this.checkRoute());
      this.loadLanguages();
      this.loaderSpinner = true;

      this.kcService.loadUserProfile(true).then((userProfile) => {
        this.userService.updateLastLogin().pipe(first()).subscribe();
      });

      /**
       * Start of New implementation of idle check
       */
      this._idle.setIdle(this.getSessionExpiredModalShowTimeout());
      this._idle.setTimeout(this.getTokenExpirationInSeconds());
      this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this._idle.onIdleStart.subscribe(() => {
        this.showPopup(this.getTokenExpirationInSeconds());
      });
      this._idle.onTimeout.subscribe(() => {
        this.logout();
      });
      this._idle.watch();
      /**
       * End of New implementation of idle check
       */

      this.businessUnitService.bus =
        this.businessUnitService.refreshBUData.subscribe(() =>
          this.updateBus()
        );

      if (environment.settings.buService) {
        this.loadBuData();
      } else {
        this.setCurrentBu();
      }

      this.countryService.getCountries().subscribe((data) => {
        for (const country of data) {
          const key1 = country.cca3;
          const translationEn = {};
          const translationDe = {};
          translationEn['countries.' + key1] = country.name.common;
          translationDe['countries.' + key1] = country.translations.deu.common;
          this.translate.setTranslation('en', translationEn, true);
          this.translate.setTranslation('de', translationDe, true);
        }
      });
    }

    this.applicationVersion = environment.applicationVersion;
    this.rootDomain = environment.rootDomain
      ? environment.rootDomain
      : 'localhost:*';
    this.loadCSPScript();
  }

  private getSessionExpiredModalShowTimeout() {
    const accessToken = this.kcService.getKeycloakInstance().refreshTokenParsed;
    return (
      (accessToken.exp * 1000 - Date.now()) / 1000 - SESSION_EXTENSION_IN_SEC
    );
  }

  ngOnInit() {
    this.sideNavOpen = 'no';
    this.opacity = '0';
  }

  validateExpiredToken() {
    return this.kcService.isTokenExpired();
  }

  updateCurrentSharedSelectedLanguage() {
    this.translationService.currentSharedSelectedLanguage.subscribe((data) => {
      if (data != null) {
        this.selectedLanguage = data.toString();
      }
    });
  }

  checkRoute() {
    if (this.router.url.includes('dashboardCenter')) {
      this.transparentBackground = true;
    } else {
      this.transparentBackground = false;
    }
  }

  setBusinessUnitAndLanguageFromUrlParameters() {
    this.activatedRoute.queryParams.subscribe((queryParam) => {
      if (queryParam) {
        const businessUnitIdParam = queryParam['bu'];
        let languageIsoCodeParam = queryParam['lang'];
        if (languageIsoCodeParam) {
          languageIsoCodeParam = languageIsoCodeParam.toLowerCase();
          const languageWithPassedIsoCode = this.createdLanguages.find(
            (lang) => lang.isoCode.toLowerCase() === languageIsoCodeParam
          );
          if (languageWithPassedIsoCode) {
            this.selectedLanguage = languageIsoCodeParam.toLowerCase();
            this.useLanguage(languageWithPassedIsoCode);
          }
        }
        if (businessUnitIdParam) {
          const businessUnitWithPassedId = this.businessUnitService
            .getAllBusinessUnitsLocal()
            .find((bu) => bu.id.toString() === businessUnitIdParam);
          if (businessUnitWithPassedId) {
            this.selectedBusinessUnit = businessUnitWithPassedId.id;
            this.businessUnitService.setCurrentBusinessUnit(
              this.selectedBusinessUnit
            );
            this.businessUnitService.setAnimalWelfareBusinessUnit(
              this.selectedBusinessUnit
            );
            if (
              !localStorage.getItem('firstReloadAfterSettingWithUrlParameters')
            ) {
              localStorage['firstReloadAfterSettingWithUrlParameters'] = true;
              window.location.reload();
            } else {
              localStorage.removeItem(
                'firstReloadAfterSettingWithUrlParameters'
              );
            }
          }
        }
      }
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }

  resetTaskList() {
    localStorage.removeItem('filter');
    localStorage.removeItem('pageIndex');
    localStorage.removeItem('pageSize');
    this.notificationService.dismissMessage();
    this.taskService.updateAppliedFilters([]);
    this.taskService.updateChoiceState(null);
    this.taskService.updateFiltersState(null);
    this.tableService.updateFormState({ enable: true });
  }

  loadBusinessUnits() {
    this.selectedBusinessUnit = parseInt(
      this.businessUnitService.getCurrentBusinessUnit(),
      10
    );

    this.businessUnitService.setAnimalWelfareBusinessUnit(
      this.selectedBusinessUnit
    );

    this.businessUnitService.listForUser().subscribe(
      (buList) => {
        this.userBusinessUnits = buList;
        this.businessUnitService.allBusinessUnits = this.userBusinessUnits;
        this.businessUnitService.setAllBusinessUnits(this.userBusinessUnits);
        const hasAtLeastOneBU = buList.length > 0;
        const canAccessSavedBU =
          buList.filter((v) => v.id === this.selectedBusinessUnit).length > 0;

        if (hasAtLeastOneBU && !canAccessSavedBU) {
          this.selectedBusinessUnit = buList[0].id;
          this.businessUnitChanged();
        }

        this.isBUProductAllowed();
      },
      (error) => {
        this.loaderSpinner = false;
      }
    );

    this.businessUnitService.businessUnitSelected.subscribe((data) => {
      const canAccessBU =
        this.userBusinessUnits.filter((v) => v.id === data).length > 0;
      if (canAccessBU) {
        this.selectedBusinessUnit = data;
        this.businessUnitService.setCurrentBusinessUnit(
          this.selectedBusinessUnit
        );
        this.businessUnitService.setAnimalWelfareBusinessUnit(
          this.selectedBusinessUnit
        );
      }
    });
  }

  useLanguage(lang: any) {
    const completeLang = lang['isoCode'];
    const request = {
      language: completeLang,
    };
    this.userService
      .saveLanguage(request)
      .pipe(
        switchMap(() => {
          return this.translationService.getTranslationsByIsoCode(
            lang['isoCode']
          );
        })
      )
      .subscribe(
        () => {
          this.translate.use(lang['isoCode']);
          this.translationService.setCurrentLanguage(lang['isoCode']);
          this.translate.setDefaultLang(lang['isoCode']);
          this.translationService.updateCurrentSelectedLanguage(
            lang['isoCode']
          );
          this.translate.currentLang = lang;
        },
        (err) => {
          console.log('ERROR CHANGING LANGUAGE', err);
        }
      );
    this.translate.reloadLang(lang['isoCode']);
  }

  loadLanguages() {
    if (
      localStorage.currentSelectedLanguage === undefined ||
      localStorage.createdLanguages === undefined
    ) {
      this.translationService
        .getTranslationsByIsoCode(this.isoCodeEN)
        .pipe(
          switchMap(() => {
            return this.translationService.getCreatedLanguages();
          })
        )
        .subscribe((data) => {
          this.createdLanguages = data;
          localStorage.createdLanguages = JSON.stringify(data);
          this.translate.use(this.isoCodeEN);
          this.translate.setDefaultLang(this.isoCodeEN);
          this.translationService.setCurrentLanguage(this.isoCodeEN);
          this.translationService.updateCurrentSelectedLanguage(this.isoCodeEN);
          this.translate.currentLang = data[0];
        });
      this.translate.reloadLang(this.isoCodeEN);
    } else {
      this.createdLanguages = JSON.parse(localStorage.createdLanguages);
      this.createdLanguages.forEach((cl) => {
        this.translate.setDefaultLang(cl['isoCode']);
      });
    }
    this.getInitialUserSettings();
  }

  getInitialUserSettings() {
    if (environment.settings.userService) {
      this.getUserSettings();
    } else {
      this.loaderSpinner = false;
    }

    this.setBusinessUnitAndLanguageFromUrlParameters();
  }

  getUserSettings() {
    this.userService.getSettings().subscribe(
      (data) => {
        if (data && data.profile) {
          const dateFormat = data.profile.dateFormat;
          if (dateFormat === 'BRITISH') {
            this.changeDateFormat('en-GB');
            this.userService.dateFormat = 'BRITISH';
          }
          if (dateFormat === 'AMERICAN') {
            this.changeDateFormat('en-US');
            this.userService.dateFormat = 'AMERICAN';
          }
          if (dateFormat === 'GERMAN') {
            this.changeDateFormat('de');
            this.userService.dateFormat = 'GERMAN';
          }

          this.selectedLanguage = data.profile.language.toString();
          this.translate.use(this.selectedLanguage);
          this.translationService.setCurrentLanguage(this.selectedLanguage);
          this.translationService.updateCurrentSelectedLanguage(
            this.selectedLanguage
          );
          this.loaderSpinner = false;
        } else {
          this.loaderSpinner = false;
        }
      },
      (error) => {
        this.loaderSpinner = false;
      }
    );
  }

  lock() {
    if (this.locked === '0') {
      this.locked = '1';
    } else {
      this.locked = '0';
    }
  }

  open() {
    if (this.locked === '0') {
      this.showText = true;
      this.sideNavOpen = 'yes';
      this.opacity = '1';
    }
  }

  close() {
    if (this.locked === '0') {
      this.showText = false;
      this.sideNavOpen = 'no';
      this.opacity = '0';
    }
  }

  hasRoleForPath(route: string) {
    const currentRoute = this.router.config.find((el) => el.path === route);
    if (!currentRoute) {
      return false;
    }
    if (currentRoute.data.roles.length === 0) {
      return true;
    }
    return currentRoute.data.roles.some((role) =>
      this.kcService.isUserInRole(role)
    );
  }

  goToDashboard() {
    this.router.navigateByUrl('/');
  }

  isBUProductAllowed() {
    if (
      this.router.url.split('/')[1] &&
      this.router.config &&
      this.router.config.length > 0
    ) {
      const currentPath = this.router.config.find(
        (route) => route['path'] === this.router.url.split('/')[1]
      );

      if (
        currentPath &&
        currentPath['data'] &&
        currentPath['data']['products'] &&
        currentPath['data']['products'].length > 0
      ) {
        if (
          currentPath['data']['products'] &&
          currentPath['data']['products'].length > 0
        ) {
          if (!this.isBUProductMatch(currentPath['data']['products'])) {
            this.router.navigateByUrl('/');
            this.loaderSpinner = false;
          } else {
            this.loaderSpinner = false;
          }
        }
      } else {
        this.loaderSpinner = false;
      }
    } else {
      this.loaderSpinner = false;
    }
  }

  isBUProductMatch(productsAllowed: any[]) {
    let result = false;
    if (this.userBusinessUnits && this.userBusinessUnits.length > 0) {
      const currentBU = this.userBusinessUnits.find(
        (bu) =>
          bu['id'] === Number(this.businessUnitService.getCurrentBusinessUnit())
      );

      if (currentBU && currentBU['product'] != null) {
        if (
          productsAllowed.some(
            (prod) =>
              prod ===
              this.buProducts.find(
                (buProd) => buProd['code'] === currentBU['product']
              )['value']
          )
        ) {
          result = true;
        }
      }
    }

    return result;
  }

  logout() {
    this.taskNames = '';
    this.taskService.getUserLockedTasks().subscribe(
      (data) => {
        data.forEach((value) => {
          this.taskService.parseTitle(value);
          this.taskNames += this.translate.instant(value.taskTitle) + ' ';
          if (value.objectId && value.objectName !== '') {
            this.taskNames += value.objectId + ' ' + value.objectName + ', ';
          } else {
            this.taskNames += value.id + ', ';
          }
        });
        if (this.taskNames.length > 0) {
          this.matDialogService
            .open(DismissChangesDialogComponent, {
              panelClass: 'confirmation-logout-popup',
              data: {
                title: 'DIALOGS.APP.LOGOUT.TITLE',
                message: 'DIALOGS.APP.LOGOUT.MESSAGE',
                variable: this.taskNames,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.taskService.unlockUserTasks().subscribe(
                  () => this.kcService.logout(),
                  (err) => handleError(err)
                );
                this.clientDisbursementService
                  .unlockUserDisbursement()
                  .subscribe(() => null);
              }
            });
        } else {
          this.kcService.logout();
        }
      },
      (err) => {
        handleError(err);
        this.kcService.logout();
      }
    );
  }

  businessUnitChanged() {
    this.businessUnitService.setCurrentBusinessUnit(this.selectedBusinessUnit);
    this.businessUnitService.setAnimalWelfareBusinessUnit(
      this.selectedBusinessUnit
    );
    window.location.reload();
  }

  showPopup(secondsToExpireToken: number) {
    if (!this.sessionDialog) {
      this.sessionDialog = this.matDialogService
        .open(SessionExpiredModalComponent, {
          panelClass: 'confirmation-popup',
          data: {
            timeout:
              secondsToExpireToken < SESSION_EXTENSION_IN_SEC
                ? secondsToExpireToken
                : SESSION_EXTENSION_IN_SEC,
          },
        })
        .afterClosed()
        .subscribe((data) => {
          if (data === false) {
            this.logoutUser();
          } else if (data) {
            this.kcService.updateToken(SESSION_EXTENSION_IN_SEC);
          }
          this.sessionDialog = undefined;
        });
    }
  }

  updateBus() {
    this.loadBusinessUnits();
  }

  openDrawerFunction() {
    this.drawer.open();
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.preventScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.preventScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.preventScroll(event);
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    localStorage.removeItem('filter');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('filter');
  }

  preventScroll(event: any) {
    if (event.target.type === 'number') {
      event.preventDefault();
    }
  }

  private loadCSPScript() {
    this.meta.addTag({
      'http-equiv': 'Content-Security-Policy',
      content: `upgrade-insecure-requests;
        block-all-mixed-content;
        default-src 'none';
        script-src 'self' 'unsafe-eval' https://app.powerbi.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self';
        font-src 'self' https://fonts.gstatic.com;
        frame-src 'self' https://app.powerbi.com blob:;
        connect-src http://${this.rootDomain} https://${this.rootDomain}
        ws://${this.rootDomain};`,
    });
  }

  private loadBuData() {
    this.businessUnitService.bus =
      this.businessUnitService.refreshBUData.subscribe(() => {
        this.updateBus();
      });

    this.loadBusinessUnits();
  }

  private setCurrentBu() {
    this.userBusinessUnits = [{ id: 4, name: 'Tierwohl', product: 3 }];

    this.businessUnitService.allBusinessUnits = this.userBusinessUnits;
    this.businessUnitService.setAllBusinessUnits(this.userBusinessUnits);
    this.businessUnitService.setCurrentBusinessUnit(
      Number(environment.settings.animalWelfareBU)
    );
  }

  private getTokenExpirationInSeconds() {
    const kcInstance = this.kcService.getKeycloakInstance();
    if (!!kcInstance) {
      const now = new Date();
      const expDate = new Date(kcInstance.refreshTokenParsed?.exp * 1000);
      return parseInt(
        moment
          .duration(moment(expDate).diff(moment(now)))
          .asSeconds()
          .toString(),
        10
      );
    }
  }

  private logoutUser() {
    this.taskService.unlockUserTasks().subscribe(() => null);
    this.kcService.logout();
  }
}
