<mat-tab-group class="group split-data-tabs">
  <mat-tab>
    <ng-template mat-tab-label>
      <div id="{{ entityName }}-transaction-info-tab">
        {{ 'TRANSACTION.TRANSACTION-INFO' | translate }}
      </div>
    </ng-template>
    <mat-accordion>
      <mat-expansion-panel class="transaction-master-data-accordion" [disabled]="isMoreThanOneSelected">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'TRANSACTION.TRANSACTION-MASTER-DATA' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-form-container">
          <form action="" class="form" [formGroup]="transactionMasterDataForm" autocomplete="off">
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.STATUS-INFORMATION'"
              [placeholder]="'TRANSACTION.STATUS-INFORMATION'"
              [label]="'TRANSACTION.STATUS-INFORMATION'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.statusInformation"
              [controlName]="'statusInformation'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.TRANSACTION-CODE'"
              [placeholder]="'TRANSACTION.TRANSACTION-CODE'"
              [label]="'TRANSACTION.TRANSACTION-CODE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.transactionCode"
              [controlName]="'transactionCode'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.transactionCode : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.TRANSACTION-CODE-NAME'"
              [placeholder]="'TRANSACTION.TRANSACTION-CODE-NAME'"
              [label]="'TRANSACTION.TRANSACTION-CODE-NAME'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.transactionCodeName"
              [controlName]="'transactionCodeName'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.transactionCodeName : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            >
            </app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.DOCUMENTATION-DATE'"
              [placeholder]="'TRANSACTION.DOCUMENTATION-DATE'"
              [label]="'TRANSACTION.DOCUMENTATION-DATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.documentDate"
              [controlName]="'documentDate'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.openItemDate : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
            >
            </app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.OPEN-AMOUNT'"
              [placeholder]="'TRANSACTION.OPEN-AMOUNT'"
              [label]="'TRANSACTION.OPEN-AMOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.openAmount"
              [controlName]="'openAmount'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.openAmount : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.2'"
              [thousandSeparator]="'.'"
              [suffix]="' €'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.CLIENT-ID'"
              [placeholder]="'TRANSACTION.CLIENT-ID'"
              [label]="'TRANSACTION.CLIENT-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.clientId"
              [controlName]="'clientId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.clientId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.DEBTOR-ID'"
              [placeholder]="'TRANSACTION.DEBTOR-ID'"
              [label]="'TRANSACTION.DEBTOR-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.debtorId"
              [controlName]="'debtorId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.debtorId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.DATE-OF-ENTRY'"
              [placeholder]="'TRANSACTION.DATE-OF-ENTRY'"
              [label]="'TRANSACTION.DATE-OF-ENTRY'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.dateOfEntry"
              [controlName]="'dateOfEntry'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.dateOfEntry : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
              [timeStamp]="true"
            ></app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.DOCUMENTATION-ID'"
              [placeholder]="'TRANSACTION.DOCUMENTATION-ID'"
              [label]="'TRANSACTION.DOCUMENTATION-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.documentationId"
              [controlName]="'documentationId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.documentationId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.CLIENTS-DEBTOR-ID'"
              [placeholder]="'TRANSACTION.CLIENTS-DEBTOR-ID'"
              [label]="'TRANSACTION.CLIENTS-DEBTOR-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.clientsDebtorId"
              [controlName]="'clientsDebtorId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.clientsDebtorId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.DUE-DATE'"
              [placeholder]="'TRANSACTION.DUE-DATE'"
              [label]="'TRANSACTION.DUE-DATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.dueDate"
              [controlName]="'dueDate'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.dueDate : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
            >
            </app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.BRANCH-ID'"
              [placeholder]="'TRANSACTION.BRANCH-ID'"
              [label]="'TRANSACTION.BRANCH-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.branchId"
              [controlName]="'branchId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.branchId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.BUSINESS-UNIT-ID'"
              [placeholder]="'TRANSACTION.BUSINESS-UNIT-ID'"
              [label]="'TRANSACTION.BUSINESS-UNIT-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.businessUnitId"
              [controlName]="'businessUnitId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.businessUnitId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.LAST-CHANGE-DATE'"
              [placeholder]="'TRANSACTION.LAST-CHANGE-DATE'"
              [label]="'TRANSACTION.LAST-CHANGE-DATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.lastChangeDate"
              [controlName]="'lastChangeDate'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
              [timeStamp]="true"
            ></app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.VAT-AMOUNT'"
              [placeholder]="'TRANSACTION.VAT-AMOUNT'"
              [label]="'TRANSACTION.VAT-AMOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.vatAmount"
              [controlName]="'vatAmount'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.vatAmount : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.2'"
              [thousandSeparator]="'.'"
              [suffix]="' €'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.VAT-CODE'"
              [placeholder]="'TRANSACTION.VAT-CODE'"
              [label]="'TRANSACTION.VAT-CODE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.vatCode"
              [controlName]="'vatCode'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.vatCode : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.VAT-RATE'"
              [placeholder]="'TRANSACTION.VAT-RATE'"
              [label]="'TRANSACTION.VAT-RATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.vatRate"
              [controlName]="'vatRate'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.vatRate : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.3'"
              [thousandSeparator]="'.'"
              [suffix]="'%'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.TRANSACTION-ID'"
              [placeholder]="'TRANSACTION.TRANSACTION-ID'"
              [label]="'TRANSACTION.TRANSACTION-ID'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.transactionId"
              [controlName]="'transactionId'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.transactionId : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.REFERENCE-DOCUMENTATION'"
              [placeholder]="'TRANSACTION.REFERENCE-DOCUMENTATION'"
              [label]="'TRANSACTION.REFERENCE-DOCUMENTATION'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.referenceDocumentation"
              [controlName]="'referenceDocumentation'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.REFERENCE-TEXT'"
              [placeholder]="'TRANSACTION.REFERENCE-TEXT'"
              [label]="'TRANSACTION.REFERENCE-TEXT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.referenceText"
              [controlName]="'referenceText'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.ORIGINAL-ACCOUNT'"
              [placeholder]="'TRANSACTION.ORIGINAL-ACCOUNT'"
              [label]="'TRANSACTION.ORIGINAL-ACCOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.originalAccount"
              [controlName]="'originalAccount'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [type]="'number'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.ALLOCATION-NUMBER'"
              [placeholder]="'TRANSACTION.ALLOCATION-NUMBER'"
              [label]="'TRANSACTION.ALLOCATION-NUMBER'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.allocationNumber"
              [controlName]="'allocationNumber'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.CURRENCY-RATE-DATE'"
              [placeholder]="'TRANSACTION.CURRENCY-RATE-DATE'"
              [label]="'TRANSACTION.CURRENCY-RATE-DATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.currencyRateDate"
              [controlName]="'currencyRateDate'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
            >
            </app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.CURRENCY'"
              [placeholder]="'TRANSACTION.CURRENCY'"
              [label]="'TRANSACTION.CURRENCY'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.currency"
              [controlName]="'currency'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.currency : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.CURRENCY-AMOUNT'"
              [placeholder]="'TRANSACTION.CURRENCY-AMOUNT'"
              [label]="'TRANSACTION.CURRENCY-AMOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.currencyAmount"
              [controlName]="'currencyAmount'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.2'"
              [thousandSeparator]="'.'"
              [suffix]="' €'"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.CURRENCY-RATE'"
              [placeholder]="'TRANSACTION.CURRENCY-RATE'"
              [label]="'TRANSACTION.CURRENCY-RATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.currencyRate"
              [controlName]="'currencyRate'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.REPURCHASING-DATE'"
              [placeholder]="'TRANSACTION.REPURCHASING-DATE'"
              [label]="'TRANSACTION.REPURCHASING-DATE'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.repurchasingDate"
              [controlName]="'repurchasingDate'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
            >
            </app-mat-input-date>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.REPURCHASING-PERIOD'"
              [placeholder]="'TRANSACTION.REPURCHASING-PERIOD'"
              [label]="'TRANSACTION.REPURCHASING-PERIOD'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.repurchasingPeriod"
              [controlName]="'repurchasingPeriod'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
            >
            </app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.REPURCHASING-REASON'"
              [placeholder]="'TRANSACTION.REPURCHASING-REASON'"
              [label]="'TRANSACTION.REPURCHASING-REASON'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.repurchasingReason"
              [controlName]="'repurchasingReason'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
            ></app-mat-input>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.INVOICE-AMOUNT'"
              [placeholder]="'TRANSACTION.INVOICE-AMOUNT'"
              [label]="'TRANSACTION.INVOICE-AMOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.invoiceAmount"
              [controlName]="'invoiceAmount'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.invoiceAmount : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.2'"
              [thousandSeparator]="'.'"
              [suffix]="' €'"
            ></app-mat-input>
            <app-mat-input-date
              [sideMenu]="true"
              [name]="'TRANSACTION.ALLOCATION-TIMESTAMP'"
              [placeholder]="'TRANSACTION.ALLOCATION-TIMESTAMP'"
              [label]="'TRANSACTION.ALLOCATION-TIMESTAMP'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.allocationTimestamp"
              [controlName]="'allocationTimestamp'"
              [approvedValue]="null"
              [taskValue]="null"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [minDate]="null"
              [maxDate]="null"
              [timeStamp]="true"
            ></app-mat-input-date>
            <app-mat-input
              [sideMenu]="true"
              [name]="'TRANSACTION.ALLOCATION-AMOUNT'"
              [placeholder]="'TRANSACTION.ALLOCATION-AMOUNT'"
              [label]="'TRANSACTION.ALLOCATION-AMOUNT'"
              [entityName]="entityName"
              [form]="transactionMasterDataForm"
              [controlObject]="transactionMasterDataForm.controls.allocationAmount"
              [controlName]="'allocationAmount'"
              [approvedValue]="null"
              [taskValue]="approvedObject ? approvedObject.allocationAmount : ''"
              [titleTask]="false"
              [editableTask]="false"
              [isTask]="true"
              [formGroupCustomName]="null"
              [maskValue]="'separator.2'"
              [thousandSeparator]="'.'"
              [suffix]="' €'"
            ></app-mat-input>
          </form>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="comments-attachments-accordion"
        [expanded]="isMoreThanOneSelected"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'TRANSACTION.COMMENTS-AND-ATTACHMENTS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-form-container comments-and-attachments-form-container">
          <fieldset>
            <legend>
              <h4>{{ 'TRANSACTION.ATTACHMENTS' | translate }}</h4>
            </legend>
            <form action="" class="form" [formGroup]="attachmentsForm">
              <div class="form-row margin-row">
                <div class="spinner-container" *ngIf="uploadProgress">
                  <mat-spinner [diameter]="50"></mat-spinner>
                </div>
                <app-file-upload
                  [hidden]="uploadProgress"
                  [uploadedFiles]="uploadedFiles"
                  formControlName="attachment"
                  [multiple]="multiple"
                  [transaction]="transaction"
                ></app-file-upload>
              </div>
              <app-attachments
                [attachments]="attachments"
                [transactionId]="rowTransactionId"
                [attachmentType]="'transaction'"
                [selectedTransactions]="transactionObject['selectedTransactions']"
              ></app-attachments>
            </form>
          </fieldset>
          <fieldset>
            <legend>
              <h4>{{ 'TRANSACTION.COMMENTS' | translate }}</h4>
            </legend>
            <form action="" class="form" [formGroup]="commentsForm">
              <div class="transaction-comment">
                <app-mat-textarea
                  class="form-element"
                  [name]="'TRANSACTION.COMMENT'"
                  [placeholder]="'TRANSACTION.COMMENT'"
                  [label]="'TRANSACTION.COMMENT'"
                  [entityName]="entityName"
                  [form]="commentsForm"
                  [controlObject]="commentsForm.controls.comment"
                  [controlName]="'comment'"
                  [approvedValue]="null"
                  [taskValue]="null"
                  [titleTask]="false"
                  [editableTask]="false"
                  [isTask]="false"
                  [formGroupCustomName]="''"
                ></app-mat-textarea>
              </div>
              <div class="action-buttons">
                <button
                  id="{{ entityName }}-form-save-button"
                  mat-button
                  class="custom-button save-button"
                  (click)="saveComments()"
                  [disabled]="commentsForm.invalid"
                >
                  {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
                </button>
                <button
                  id="{{ entityName }}-form-cancel-button"
                  mat-button
                  class="custom-button-cancel cancel-button"
                  (click)="clear()"
                >
                  {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
                </button>
              </div>
            </form>
            <span>
              <app-comments
                [transactionId]="rowTransactionId"
                [comments]="comments"
                [commentType]="'transaction-debtor'"
                [selectedTransactions]="transactionObject['selectedTransactions']"
              ></app-comments>
            </span>
          </fieldset>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div id="{{ entityName }}-general-info-tab">
        {{ 'TRANSACTION.TRANSACTION-HISTORY' | translate }}
      </div>
    </ng-template>
    <ng-template matTabContent>
      <app-split-transactions-history [transactionId]="transactionObject.id"></app-split-transactions-history>
    </ng-template>
  </mat-tab>
</mat-tab-group>
