import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountingSharedDataService } from 'src/app/shared/services/accounting/accounting-shared-data.service';
import { AccountingService } from 'src/app/shared/services/accounting/accounting.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { TransactionSearchFilter } from '../accounting-overview-functional-buttons/accounting-overview-functional-buttons.component';
import * as _ from 'lodash';
import { DashboardStateService } from '../services/dashboard-state/dashboard-state.service';
import { AccountingTransactionTabType } from '../accounting-transaction-tab-type';
import { AccountKpiService } from '../accounting-kpi-overview/services/account-kpi.service';

export interface IAccountKPIs {
  type: string;
  balance: number;
  name: string;
  balanceSelected: number;
  id?: string;
  creditNoteAmount: number;
  numberOfOpenItems: number;
  externalId?: string;
  openPaymentAmount: number;
}

@Component({
  selector: 'app-accounting-overview',
  templateUrl: './accounting-overview.component.html',
  styleUrls: ['./accounting-overview.component.less'],
})
export class AccountingOverviewComponent implements OnInit, OnDestroy {
  searchFilters: BehaviorSubject<TransactionSearchFilter> = new BehaviorSubject(
    { freeFormSearch: null, documentDate: null }
  );
  searchForm: UntypedFormGroup;
  entityName = 'accounting-overview';
  state;
  entity = {
    name: '',
    accountNumber: '',
    address: {
      street: '',
      number: '',
      zipCode: '',
      city: '',
      country: '',
    },
    kpiList: [],
    kpis: undefined,
  };
  kpis;
  entityGuidFromUrl;
  entityTypeFromUrl;
  accountingData;
  selectedBalance: any = 0;
  lastSearchTerm;
  currentTab;
  configurationKey;
  displayedColumns;
  originalEntity = {};
  configurationKeyForNonAWOpenTransaction =
    'accounting_transaction_non_aw_table_open_transactions';
  configurationKeyForNonAWClosedTransaction =
    'accounting_transaction_non_aw_table_closed_transactions';
  configurationKeyForNonAWCreditTransaction =
    'accounting_transaction_non_aw_table_credit_transactions';
  configurationKeyForAWOpenTransaction =
    'accounting_transaction_animal_welfare_table_open_transactions';
  configurationKeyForAWClosedTransaction =
    'accounting_transaction_animal_welfare_table_closed_transactions';
  configurationKeyForAWCreditTransaction =
    'accounting_transaction_animal_welfare_table_credit_transactions';
  isLedgerAccount: boolean;

  credits = AccountingTransactionTabType.Credits;
  closed = AccountingTransactionTabType.Closed;
  merged = AccountingTransactionTabType.Merged;
  open = AccountingTransactionTabType.Open;

  constructor(
    private dashboardState: DashboardStateService,
    private accountingSharedDataService: AccountingSharedDataService,
    private tablesService: TablesService,
    private accountingService: AccountingService,
    private route: ActivatedRoute,
    private openItemService: OpenItemService,
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private accountKpiService: AccountKpiService
  ) {}

  ngOnInit() {
    this.state = window.history.state;
    this.entityGuidFromUrl = this.route.snapshot.paramMap.get('id');
    this.entityTypeFromUrl = this.route.snapshot.paramMap.get('type');

    this.isLedgerAccount = this.entityTypeFromUrl === 'LEDGER_ACCOUNT';

    this.buildSearchForm();
    this.loadKPIData();
    this.openItemService.currentSelectedBalance.subscribe(
      (sharedSelectedBalance) => (this.selectedBalance = sharedSelectedBalance)
    );
    this.getCurrentBusinessUnitAndTab();
    this.updateKPIList();
  }

  private buildSearchForm() {
    this.searchForm = new UntypedFormGroup({
      freeFormSearch: new UntypedFormControl('', []),
      documentDate: new UntypedFormGroup({
        startDate: new UntypedFormControl(null, []),
        endDate: new UntypedFormControl(null, []),
        fieldName: new UntypedFormControl('openItemDate'),
      }),
    });
  }

  ngOnDestroy() {
    this.tablesService.updateSharedSelectedRows(null);
    this.accountingService.resetFilters();
  }

  addBalanceSelected() {
    this.openItemService.updateSharedSelectedBalance(0.0);
    this.openItemService.currentSelectedBalance.subscribe(
      (sharedSelectedBalance) => {
        // to be removed
        this.entity.kpiList.forEach((item) => {
          if (item.kpi === 'BALANCE-SELECTED') {
            item.info = sharedSelectedBalance;
          }
        });
        // end to be removed
        this.entity.kpis.balanceSelected = sharedSelectedBalance;
      }
    );
  }

  updateKPIList() {
    this.searchFilters.subscribe((value) => {
      if (value && value !== this.lastSearchTerm) {
        if (!value.freeFormSearch) {
          this.accountingService.resetFilters();
        }
        this.filter(value);
        this.callKPIValues();
        this.lastSearchTerm = value;
      }
    });
  }

  getCurrentBusinessUnitAndTab() {
    this.accountingSharedDataService.currentTab.subscribe((data) => {
      this.currentTab = data;
      if (this.businessUnitService.getCurrentBusinessUnit() === '4') {
        if (this.currentTab === 0) {
          this.configurationKey = this.configurationKeyForAWOpenTransaction;
        } else if (this.currentTab === 1) {
          this.configurationKey = this.configurationKeyForAWClosedTransaction;
        } else if (this.currentTab === 2) {
          this.configurationKey = this.configurationKeyForAWCreditTransaction;
        }
        this.displayedColumns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsDisplayedColumnsStateAW;
      } else {
        if (this.currentTab === 0) {
          this.configurationKey = this.configurationKeyForNonAWOpenTransaction;
        } else if (this.currentTab === 1) {
          this.configurationKey =
            this.configurationKeyForNonAWClosedTransaction;
        } else if (this.currentTab === 2) {
          this.configurationKey =
            this.configurationKeyForNonAWCreditTransaction;
        }
        this.displayedColumns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsColumnsStateNonAW;
      }
    });
  }

  callKPIValues() {
    this.userService
      .getConfiguration(this.configurationKey)
      .subscribe((data) => {
        let columns = Object.assign([], this.displayedColumns);
        if (data) {
          columns = data.displayedColumns;
        }
        if (columns) {
          const indexSelect = columns.indexOf('select', 0);
          columns.splice(indexSelect, 1);
          const indexActions = columns.indexOf('actions', 0);
          columns.splice(indexActions, 1);
          const indexFlags = columns.indexOf('flags', 0);
          columns.splice(indexFlags, 1);
        }
        const closedTransactions = this.currentTab === 1;
        const creditTransactions = this.currentTab === 2;

        this.accountingService
          .getKPIsByEntity(
            this.entityTypeFromUrl,
            this.entityGuidFromUrl,
            columns,
            closedTransactions,
            creditTransactions
          )
          .subscribe((kpis) => {
            this.entity.kpis = kpis;
          });
      });
  }

  filter(searchFilters: TransactionSearchFilter) {
    let freeFormSearchTerms = null;
    if (searchFilters.freeFormSearch) {
      freeFormSearchTerms = searchFilters.freeFormSearch
        .toLowerCase()
        .split(/\s/)
        .filter((term) => term.length > 0);
      this.accountingService.setFilterText(freeFormSearchTerms);
    }
    if (searchFilters.documentDate) {
      this.accountingService.setColumnFilters(
        'openItemDate',
        searchFilters.documentDate
      );
    }
  }

  loadKPIData() {
    this.accountingService
      .getKPIsByEntity(this.entityTypeFromUrl, this.entityGuidFromUrl, null)
      .subscribe((data) => {
        this.accountingService
          .getEntityByGuidAndType(
            this.entityTypeFromUrl,
            this.entityGuidFromUrl
          )
          .subscribe((entityData) => {
            if (entityData) {
              this.accountingData = entityData;
              if (entityData['debtorName']) {
                this.entity['name'] = entityData['debtorName'];
              } else {
                this.entity['name'] = entityData['name'];
              }
              this.entity.accountNumber = entityData['accountNumber'];
              this.entity['address'] = {
                street: entityData['street'],
                number: entityData['number'],
                zipCode: entityData['zipCode'],
                city: entityData['city'],
                country: entityData['country'],
              };
              if (entityData['dataType'] === 'relationship') {
                delete data['id'];
                delete data['name'];
                // @ts-ignore
                delete data['type'];
              } else {
                delete data['platform'];
                delete data['client'];
                delete data['debtor'];
                delete data['debtorLimit'];
              }

              this.entity.kpis = data;

              this.addBalanceSelected();
              this.originalEntity = _.merge({}, this.entity);
              this.accountKpiService.setOriginalKpis(
                _.merge({}, this.entity).kpis
              );
            }
          });
      });
  }

  tabChanged($event) {
    this.accountingSharedDataService.updateSharedTab($event.index);
    this.tablesService.updateSharedSelectedRows(null);
    this.openItemService.updateSharedSelectedBalance(0.0);
  }

  allocationsAllowed() {
    return this.dashboardState.activeTab === 0;
  }
}
