import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class SepaMandateService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.debtorService;
  }

  getSepaMandatesByDebtor(debtorGuid): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'sepa-mandates/by-debtor?debtorGuid=' + debtorGuid,
      this.buildHeaders()
    );
  }

  getSepaMandatesByCompany(companyGuid, page, amount): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl +
        'sepa-mandates/by-company?companyGuid=' +
        companyGuid +
        '&page=' +
        page +
        '&amount=' +
        amount,
      this.buildHeaders()
    );
  }

  getSepaMandateById(id): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'sepa-mandates/' + id,
      this.buildHeaders()
    );
  }

  deactivateSepaMandate(sepaMandate: any): Observable<any> {
    return this.http.put<any>(
      this.serviceUrl + 'sepa-mandates/deactivate/' + sepaMandate.id,
      sepaMandate,
      this.buildHeaders()
    );
  }

  saveApprovalTask(
    formValue: any,
    comment: string,
    taskId: string,
    debtorVisibleId: any,
    companyName: any
  ) {
    const json = {
      comment,
      sepaMandate: formValue,
      taskId,
      debtorVisibleId,
      companyName,
    };
    return this.http.post(
      this.serviceUrl + 'sepa-mandates/',
      json,
      this.buildHeaders()
    );
  }

  closeTask56AndOpen53(
    formValue: any,
    comment: string,
    taskId: string,
    debtorVisibleId: any,
    companyName: any,
    businessUnitId: any
  ) {
    const json = {
      comment,
      sepaMandate: formValue,
      approvalTaskId: taskId,
      debtorVisibleId,
      companyName,
      businessUnitId,
    };
    return this.http.post(
      this.serviceUrl + 'sepa-mandates/complete-task-56',
      json,
      this.buildHeaders()
    );
  }

  closeTask55(formValue: any, comment: string, taskId: string) {
    const json = {
      comment,
      sepaMandate: formValue,
      approvalTaskId: taskId,
    };
    return this.http.post(
      this.serviceUrl + 'sepa-mandates/complete-task-55',
      json,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
