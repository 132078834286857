<div class="period-filter">
  <div fxFlex="100" class="period vertical-center">
    <div class="form-row-selection">
      <mat-form-field appearance="fill" class="form-row document-date-picker">
        <mat-label>All</mat-label>
        <mat-date-range-input
          [formGroup]="searchForm.controls.documentDate"
          [rangePicker]="picker"
          id="period-filter-datepicker-begin-end"
        >
          <input
            matStartDate
            formControlName="startDate"
            id="period-filter-datepicker-begin"
            placeholder="{{ 'GENERAL.START-DATE' | translate }}"
            [formControl]="searchForm.controls.documentDate.get('startDate')"
          />
          <input
            matEndDate
            formControlName="endDate"
            id="period-filter-datepicker-end"
            class="date-picker-end"
            placeholder="{{ 'GENERAL.END-DATE' | translate }}"
            [formControl]="searchForm.controls.documentDate.get('endDate')"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-icon class="close" (click)="clearPeriodFilter()">close</mat-icon>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
</div>
