import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-dashboard-management',
  templateUrl: './dashboard-management.component.html',
  styleUrls: ['./dashboard-management.component.less'],
})
export class DashboardManagementComponent implements OnInit {
  constructor(
    private kcService: KeycloakService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.ngOnInit();
  }

  entityName = 'dashboard';

  ngOnInit() {
    this.navigateToAccessible();
  }

  navigateToAccessible() {
    if (!this.route.firstChild.component) {
      const baseRoutes = this.route.routeConfig.children.filter((el) =>
        this.canNavigate(el)
      );
      if (baseRoutes.length === 0) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl('/dashboard/' + baseRoutes[0].path);
      }
    }
  }

  canNavigate(el: Route): unknown {
    if (el.path === '') {
      return false;
    }
    if ((el.path.match(/\//g) || []).length > 0) {
      return false;
    }
    return el.data.roles.some((role) => this.kcService.isUserInRole(role));
  }

  hasRoleForPath(route: string) {
    const currentRoute = this.route.routeConfig.children.find(
      (el) => el.path === route
    );
    if (!currentRoute) {
      return false;
    }
    return currentRoute.data.roles.some((role) =>
      this.kcService.isUserInRole(role)
    );
  }
}
