<div class="content-container">
  <app-back-button address="accounting"> </app-back-button>

  <app-accounting-kpi-overview
    [entity]="entity"
    [originalEntity]="originalEntity"
    [searchTerm]="searchFilters"
  ></app-accounting-kpi-overview>

  <app-accounting-functional-buttons
    [allocationsAllowed]="allocationsAllowed()"
    [searchTerm]="searchFilters"
  ></app-accounting-functional-buttons>

  <app-accounting-allocation-draft></app-accounting-allocation-draft>

  <div class="tab-container min-tab-height" *ngIf="accountingData">
    <app-accounting-transaction-list
      *ngIf="isLedgerAccount"
      [allocationsAllowed]="allocationsAllowed()"
      [currentTab]="merged"
      [hasThreeDots]="true"
      [searchTerm]="searchFilters"
      [accountingData]="accountingData"
      tableName="openTransaction"
    ></app-accounting-transaction-list>
    <mat-tab-group class="group tab-custom-overflow" (selectedTabChange)="tabChanged($event)" *ngIf="!isLedgerAccount">
      <mat-tab label="{{ 'DEBTOR.ACCOUNT-OVERVIEW.OPEN-TRANSACTION' | translate }}">
        <ng-template matTabContent>
          <app-accounting-transaction-list
            [allocationsAllowed]="allocationsAllowed()"
            [currentTab]="open"
            [hasThreeDots]="true"
            [searchTerm]="searchFilters"
            [accountingData]="accountingData"
            tableName="openTransaction"
          ></app-accounting-transaction-list>
        </ng-template>
      </mat-tab>

      <mat-tab label="{{ 'DEBTOR.ACCOUNT-OVERVIEW.CLOSED-TRANSACTION' | translate }}">
        <ng-template matTabContent>
          <app-accounting-transaction-list
            [allocationsAllowed]="allocationsAllowed()"
            [currentTab]="closed"
            [hasThreeDots]="true"
            [searchTerm]="searchFilters"
            [accountingData]="accountingData"
            tableName="closedTransaction"
          ></app-accounting-transaction-list>
        </ng-template>
      </mat-tab>

      <mat-tab label="{{ 'DEBTOR.ACCOUNT-OVERVIEW.CREDIT-TRANSACTION' | translate }}">
        <ng-template matTabContent>
          <app-accounting-transaction-list
            [allocationsAllowed]="allocationsAllowed()"
            [currentTab]="credits"
            [hasThreeDots]="true"
            [searchTerm]="searchFilters"
            [accountingData]="accountingData"
            tableName="creditTransaction"
          ></app-accounting-transaction-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
