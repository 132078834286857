import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { DashboardStateService } from '../services/dashboard-state/dashboard-state.service';
import { AccountingService } from '../../../shared/services/accounting/accounting.service';

@Component({
  selector: 'app-accounting-management-dashboard',
  templateUrl: './accounting-management-dashboard.component.html',
  styleUrls: ['./accounting-management-dashboard.component.less'],
})
export class AccountingManagementDashboardComponent implements OnInit {
  searchEventEmiiter = new EventEmitter<string>();
  entityName = 'accounting-management-dashboard';
  filterForm: UntypedFormGroup;
  subject = new Subject();
  activeTab;
  activeBusinessUnit;
  searchString = '';
  totals = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    public stateService: DashboardStateService,
    private accountingService: AccountingService,
    private businessUnitService: BusinessUnitService
  ) {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  ngOnInit() {
    this.getCurrentBusinessInit();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.searchString = this.filterForm.get('searchString').value ?? '';
      this.getTotalRecordsForSearch();
    });
    this.getTotalRecordsForSearch();
  }

  private getTotalRecordsForSearch() {
    const customConfig = { filterText: this.searchString };
    this.accountingService
      .getTotalRecordsForSearch(customConfig)
      .subscribe((value) => (this.totals = value));
  }

  getCurrentBusinessInit() {
    if (this.businessUnitService.getCurrentBusinessUnit() !== '4') {
      this.activeBusinessUnit = 'nonAW';
    } else {
      this.activeBusinessUnit = 'AW';
    }
  }

  activeTabChange = (tab: any) => {
    this.stateService.activeTab = tab;
  };

  onFilterChange() {
    this.subject.next(undefined);
  }

  hasFilterChanged() {
    return (
      this.filterForm.get('searchString').value !== null &&
      this.filterForm.get('searchString').value !== ''
    );
  }
}
