import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { sapSubLedgerAccountOptions } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/form-configurations/sapSubLedgerAccounts';
import { salesTaxAccountsOptions } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/form-configurations/salesTaxAccounts';
import { productsEnum } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/enums/products-enums';
import { serviceModelEnum } from 'src/app/in-memory-data/system-configuration/sub-ledger-accounts-list/enums/service-model-enums';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { accountTypes } from 'src/app/in-memory-data/sub-ledger-account/account-types';
import { positiveNumberValidator } from 'src/app/shared/validators/positive-number-validator.derctive';

@Component({
  selector: 'app-sub-ledger-account-create',
  templateUrl: './sub-ledger-account-create.component.html',
  styleUrls: ['./sub-ledger-account-create.component.less'],
})
export class SubLedgerAccountCreateComponent implements OnInit, DirtyComponent {
  entityName = 'app-sub-ledger-account-create';
  errorRequired = 'error-message-required';
  subLedgerAccountForm: UntypedFormGroup;
  editableTask: boolean;
  isTask = false;
  isDirty = false;
  approval = false;
  editMode = false;
  hideProductAndServiceModel = false;
  hideServiceModel = false;
  activateUpdateDraft = false;
  taskView = false;
  draftGuid: string;
  subLedgerAccountApproved;
  subLedgerAccountTask;
  sapSubLedgerAccountOptions = sapSubLedgerAccountOptions;
  salesTaxAccountsOptions = salesTaxAccountsOptions;
  products = productsEnum;
  serviceModels = serviceModelEnum;
  filteredServiceModels = serviceModelEnum;
  state;
  draftExists = false;

  ACTIVE = 'Active';
  INACTIVE = 'Inactive';
  accountTypes = accountTypes;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private subLedgerService: SubLedgerService,
    private matDialogService: MatDialog,
    private draftService: DraftService,
    private taskService: TaskService
  ) {
    this.subLedgerAccountFormBuilder();
  }

  ngOnInit() {
    this.state = window.history.state;
    if (this.state.draft) {
      this.subLedgerAccountForm.get('accountNumber').disable();
      this.subLedgerAccountForm.get('sapSubLedgerAccount').disable();
      this.subLedgerAccountForm.get('salesTaxAccount').disable();
      this.subLedgerAccountForm.get('product').disable();
      this.subLedgerAccountForm.get('serviceModel').disable();
      this.subLedgerAccountForm.get('status').disable();
      this.subLedgerAccountForm.get('type').disable();
    }

    if (this.state.subLedgerAccount) {
      this.subLedgerAccountApproved = this.state.subLedgerAccount;
      this.loadRowValues(this.subLedgerAccountApproved);
      this.checkDraftExist(this.subLedgerAccountApproved);
    }
    if (this.state.task) {
      this.isTask = true;
      this.taskView = true;
      this.loadTaskData();
    }
    this.showOrHideProductAndServiceModel();
    this.showOrHideServiceModelOptions();
    this.validateServiceModel();
    this.uniqueAccountNumberProductAndServiceModelCombination();
  }

  checkDraftExist(subLedgerAccountApproved) {
    this.draftService
      .approvalDraftExistsForEntityId(subLedgerAccountApproved.guid)
      .subscribe((result) => {
        if (result) {
          this.subLedgerAccountForm.disable();
          this.draftExists = true;

          this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        }
      });
  }

  subLedgerAccountFormBuilder() {
    this.subLedgerAccountForm = this.formBuilder.group({
      accountNumber: [
        '',
        {
          validators: [Validators.required, positiveNumberValidator()],
          updateOn: 'blur',
        },
      ],
      sapSubLedgerAccount: [false, Validators.required],
      salesTaxAccount: ['', Validators.required],
      accountNameGerman: ['', Validators.required],
      accountNameEnglish: ['', Validators.required],
      product: [null, Validators.required],
      serviceModel: [null, Validators.required],
      isActive: [true, Validators.required],
      guid: [''],
      id: [''],
      type: [null, Validators.required],
    });
  }

  uniqueAccountNumberProductAndServiceModelCombination() {
    this.subLedgerAccountForm
      .get('accountNumber')
      .valueChanges.subscribe((accountNumber) => {
        this.validateAccountCodeProductsAndServiceModelCombinations(
          accountNumber,
          this.productsControlValue,
          this.serviceModelsControlValue
        );
      });

    this.subLedgerAccountForm
      .get('serviceModel')
      .valueChanges.subscribe((serviceModels) => {
        this.validateAccountCodeProductsAndServiceModelCombinations(
          this.accountNumberControlValue,
          this.productsControlValue,
          this.serviceModelsControlValue
        );
      });
  }

  private validateAccountCodeProductsAndServiceModelCombinations(
    accountNumber: number,
    products: string[],
    serviceModels: string[]
  ) {
    if (!!accountNumber && !!products && !!serviceModels) {
      this.subLedgerService
        .validateAccountCodeProductsAndServiceModelCombinations({
          accountNumber: accountNumber,
          products: products,
          serviceModels: serviceModels,
        })
        .subscribe(
          () => {
            this.subLedgerAccountForm.get('accountNumber').setErrors(null);
          },
          (err) => {
            this.subLedgerAccountForm
              .get('accountNumber')
              .setErrors({ alreadyExists: true });
          }
        );
    }
  }

  get accountNumberControlValue() {
    return this.subLedgerAccountForm.get('accountNumber').value;
  }

  get productsControlValue() {
    return this.subLedgerAccountForm.get('product').value;
  }

  get serviceModelsControlValue() {
    return this.subLedgerAccountForm.get('serviceModel').value;
  }

  canDeactivate() {
    return this.subLedgerAccountForm.dirty && !this.approval;
  }

  makeEditable() {
    this.isTask = false;
    this.editableTask = true;
    this.taskService.updateEditableTask(true);
    this.subLedgerAccountForm.get('accountNumber').disable();
    this.subLedgerAccountForm.get('sapSubLedgerAccount').disable();
    this.subLedgerAccountForm.get('salesTaxAccount').disable();
    this.subLedgerAccountForm.get('product').disable();
    this.subLedgerAccountForm.get('serviceModel').disable();
    this.subLedgerAccountForm.get('isActive').disable();
  }

  loadRowValues(subLedgerAccountApproved) {
    this.editMode = true;
    if (this.state.subLedgerAccount.draftGuid) {
      this.draftGuid = this.state.subLedgerAccount.draftGuid;
      this.activateUpdateDraft = true;
      this.subLedgerAccountForm.patchValue(subLedgerAccountApproved);
      if (this.state.subLedgerAccount.sapSubLedgerAccount) {
        this.hideProductAndServiceModel = true;
      }
    } else {
      this.subLedgerAccountForm.patchValue(subLedgerAccountApproved);
      this.subLedgerAccountForm.get('accountNumber').disable();
      this.subLedgerAccountForm.get('sapSubLedgerAccount').disable();
      this.subLedgerAccountForm.get('salesTaxAccount').disable();
      this.subLedgerAccountForm.get('product').disable();
      this.subLedgerAccountForm.get('serviceModel').disable();
      this.subLedgerAccountForm.get('isActive').disable();
      this.subLedgerAccountForm.get('type').disable();
    }
  }

  changeEditMode() {
    if (this.state.task) {
      this.isTask = true;
      this.editableTask = false;
      this.taskService.updateEditableTask(false);
    } else {
      this.router.navigateByUrl(
        '/systemConfiguration/subLedgerAccounts/subLedgerAccountsList',
        {
          state: {},
        }
      );
    }
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.subLedgerAccountForm.dirty;
  }

  loadTaskData() {
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        if (data.product && data.serviceModel) {
          data.product = [data.product];
          data.serviceModel = [data.serviceModel];
        } else {
          this.hideProductAndServiceModel = true;
        }
        this.subLedgerAccountTask = data;
        this.subLedgerAccountForm.patchValue(data);
        if (data.guid) {
          this.subLedgerService.findByGuid(data.guid).subscribe((account) => {
            this.subLedgerAccountApproved = account;
          });
        }
      });
  }

  showOrHideProductAndServiceModel() {
    if (!this.state.task) {
      this.subLedgerAccountForm
        .get('sapSubLedgerAccount')
        .valueChanges.subscribe((value) => {
          if (value) {
            this.subLedgerAccountForm.get('product').disable();
            this.subLedgerAccountForm.get('product').patchValue(null);
            this.subLedgerAccountForm.get('serviceModel').disable();
            this.hideProductAndServiceModel = true;
          } else {
            this.subLedgerAccountForm.get('product').enable();
            if (this.subLedgerAccountForm.get('product').value == null) {
              this.subLedgerAccountForm.get('serviceModel').disable();
              this.subLedgerAccountForm.get('serviceModel').patchValue(null);
              this.hideServiceModel = true;
            } else {
              this.subLedgerAccountForm.get('serviceModel').enable();
              this.subLedgerAccountForm.get('serviceModel').patchValue(null);
              this.hideServiceModel = false;
            }
            this.hideProductAndServiceModel = false;
          }
        });
    }
  }

  showOrHideServiceModelOptions() {
    const productValue = this.subLedgerAccountForm.get('product').value;
    if (productValue == null) {
      this.subLedgerAccountForm.get('serviceModel').disable();
      this.hideServiceModel = true;
    }
    this.subLedgerAccountForm.get('product').valueChanges.subscribe((value) => {
      if (value && value.length === 0) {
        this.subLedgerAccountForm.get('serviceModel').disable();
        this.subLedgerAccountForm.get('serviceModel').patchValue(null);
        this.hideServiceModel = true;
      } else {
        this.subLedgerAccountForm.get('serviceModel').enable();
        this.hideServiceModel = false;
      }
      if (value) {
        this.filteredServiceModels = [];
        if (value.includes('CENTRAL_SETTLEMENT')) {
          this.filteredServiceModels.push.apply(
            this.filteredServiceModels,
            this.serviceModels.filter((data) =>
              data.product.find((item) => item === 'CENTRAL_SETTLEMENT')
            )
          );
        }
        if (value.includes('FACTORING')) {
          this.filteredServiceModels.push.apply(
            this.filteredServiceModels,
            this.serviceModels.filter((data) =>
              data.product.find((item) => item === 'FACTORING')
            )
          );
        }
        if (value.includes('BACKUP_SERVICING')) {
          this.filteredServiceModels.push.apply(
            this.filteredServiceModels,
            this.serviceModels.filter((data) =>
              data.product.find((item) => item === 'BACKUP_SERVICING')
            )
          );
        }
        if (value.includes('ANIMAL_WELFARE_INITIATIVE')) {
          this.filteredServiceModels.push.apply(
            this.filteredServiceModels,
            this.serviceModels.filter((data) =>
              data.product.find((item) => item === 'ANIMAL_WELFARE_INITIATIVE')
            )
          );
        }

        if (value.length === 0) {
          this.filteredServiceModels = this.serviceModels;
        }
      }
      this.filteredServiceModels = this.filteredServiceModels.filter(
        (v, i, a) => a.findIndex((t) => t.name === v.name) === i
      );
    });
  }

  validateServiceModel() {
    this.subLedgerAccountForm
      .get('serviceModel')
      .valueChanges.subscribe((serviceModelValue) => {
        const productValue = this.subLedgerAccountForm.get('product').value;
        if (productValue && serviceModelValue) {
          if (productValue.includes('FACTORING')) {
            if (
              !serviceModelValue.includes('FULL_SERVICE_FACTORING') &&
              !serviceModelValue.includes('INHOUSE_FACTORING') &&
              !serviceModelValue.includes(0)
            ) {
              this.notificationService.showToast(
                'ERROR-MESSAGES.SUB-LEDGER-ACCOUNT.PRODUCT-ERROR',
                this.notificationService.MESSAGE_TYPE.ERROR,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.FACTORING'
                  ),
                }
              );
              this.subLedgerAccountForm
                .get('serviceModel')
                .setErrors({ incorrect: true });
            }
          }
          if (productValue.includes('CENTRAL_SETTLEMENT')) {
            if (
              !serviceModelValue.includes('CENTRAL_BILLING') &&
              !serviceModelValue.includes('FULL_SERVICE_CS') &&
              !serviceModelValue.includes('INHOUSE_CS') &&
              !serviceModelValue.includes(0)
            ) {
              this.notificationService.showToast(
                'ERROR-MESSAGES.SUB-LEDGER-ACCOUNT.PRODUCT-ERROR',
                this.notificationService.MESSAGE_TYPE.ERROR,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.CENTRAL_SETTLEMENT'
                  ),
                }
              );
              this.subLedgerAccountForm
                .get('serviceModel')
                .setErrors({ incorrect: true });
            }
          }
          if (productValue.includes('BACKUP_SERVICING')) {
            if (
              !serviceModelValue.includes('NOT_RELEVANT') &&
              !serviceModelValue.includes(0)
            ) {
              this.notificationService.showToast(
                'ERROR-MESSAGES.SUB-LEDGER-ACCOUNT.PRODUCT-ERROR',
                this.notificationService.MESSAGE_TYPE.ERROR,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.BACKUP_SERVICING'
                  ),
                }
              );
              this.subLedgerAccountForm
                .get('serviceModel')
                .setErrors({ incorrect: true });
            }
          }
          if (productValue.includes('ANIMAL_WELFARE_INITIATIVE')) {
            if (
              !serviceModelValue.includes('NOT_RELEVANT') &&
              !serviceModelValue.includes(0)
            ) {
              this.notificationService.showToast(
                'ERROR-MESSAGES.SUB-LEDGER-ACCOUNT.PRODUCT-ERROR',
                this.notificationService.MESSAGE_TYPE.ERROR,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCTS.ANIMAL_WELFARE_INITIATIVE'
                  ),
                }
              );
              this.subLedgerAccountForm
                .get('serviceModel')
                .setErrors({ incorrect: true });
            }
          }
        }
      });
  }

  removeUnnecessaryValues() {
    if (this.subLedgerAccountForm.get('product').value.includes(0)) {
      const foundZeroValueInProducts = this.subLedgerAccountForm
        .get('product')
        .value.findIndex((item) => item === 0);
      this.subLedgerAccountForm
        .get('product')
        .value.splice(foundZeroValueInProducts, 1);
    }
    if (this.subLedgerAccountForm.get('serviceModel').value.includes(0)) {
      const foundZeroValueInServiceModel = this.subLedgerAccountForm
        .get('serviceModel')
        .value.findIndex((item) => item === 0);
      this.subLedgerAccountForm
        .get('serviceModel')
        .value.splice(foundZeroValueInServiceModel, 1);
    }
  }

  save() {
    this.approval = true;
    if (this.subLedgerAccountForm.get('product').value) {
      this.removeUnnecessaryValues();
    }
    const json = this.subLedgerAccountForm.getRawValue();
    if (this.state.operation === 'create') {
      const dialog = this.matDialogService.open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          json.comment = result.comment;
          this.subLedgerService.create(json).subscribe(
            (data) => {
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
                  ),
                }
              );
              this.changeEditMode();
              if (this.state.draft) {
                this.deleteDraft(this.state.subLedgerAccount.draftGuid);
              }
            },
            (error) => {
              this.handleError(error);
            }
          );
        }
      });
    } else if (this.state.operation === 'update') {
      const dialog = this.matDialogService.open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          json.comment = result.comment;
          this.subLedgerService.update(json).subscribe(
            (data) => {
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant(
                    'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
                  ),
                }
              );
              this.changeEditMode();
            },
            (error) => {
              this.handleError(error);
            }
          );
        }
      });
    } else if (this.state.task) {
      const dialog = this.matDialogService.open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          json.comment = result.comment;
          this.subLedgerService
            .updateDraft(json, this.state.task.guid)
            .subscribe(
              (data) => {
                this.notificationService.showToast(
                  'NOTIFICATION.CREATED',
                  this.notificationService.MESSAGE_TYPE.SUCCESS,
                  {
                    data: this.translateService.instant(
                      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
                    ),
                  }
                );
                this.changeEditMode();
                setTimeout(() => {
                  this.router.navigateByUrl('/tasks', {
                    state: {},
                  });
                }, 1500);
              },
              (error) => {
                this.handleError(error);
              }
            );
        }
      });
    }
  }

  saveAsDraft() {
    this.approval = true;
    if (this.subLedgerAccountForm.get('product').value) {
      this.removeUnnecessaryValues();
    }
    const subLedgerAccountFormValue = this.subLedgerAccountForm.getRawValue();
    const draftGuid = this.draftGuid;
    const request = {
      element: subLedgerAccountFormValue,
      entityType: 'SUB_LEDGER_ACCOUNT',
      id: draftGuid,
      entityId: subLedgerAccountFormValue.id,
      entityGuid: subLedgerAccountFormValue.guid,
    };
    let update = false;
    if (draftGuid) {
      update = true;
    }
    this.draftService.saveUserDraft(request).subscribe(
      (_) => {
        if (update) {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
              ),
            }
          );
        } else {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SUB-LEDGER-ACCOUNT'
              ),
            }
          );
        }
        setTimeout(() => {
          this.router.navigateByUrl(
            '/systemConfiguration/subLedgerAccounts/subLedgerAccountsList',
            {
              state: {},
            }
          );
        }, 1500);
      },
      (error) => {
        if (error.error) {
          this.handleError(error);
        }
      }
    );
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe();
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  getStatus(isActive: boolean) {
    if (isActive) {
      return this.ACTIVE;
    } else {
      return this.INACTIVE;
    }
  }
}
