<section class="task-section" *ngIf="isTask">
  <app-back-button></app-back-button>
  <div class="edit-task">
    <button
      *hasPermission="'aw.sp.u'"
      id="{{entityName}-edit-task"
      mat-button
      class="edit-task-button"
      (click)="changeEditable(true)"
    >
      <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
    </button>
  </div>
  <div class="title-form">
    <h5 class="title-h5" id="{{ entityName }}-form-title">
      {{ 'ANIMAL-WELFARE.TABS.SYSTEM-CONFIGURATION' | translate }}
    </h5>
  </div>
</section>
<section class="system-config-container">
  <div class="action-wrapper" *ngIf="!showForm && !isTask">
    <div class="search" [formGroup]="filterForm">
      <div class="filter-input">
        <mat-form-field>
          <mat-label>{{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.SEARCH-FIELD' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.SEARCH-FIELD' | translate }}"
            name="name"
            formControlName="searchString"
            [(ngModel)]="searchInput"
            id="{{ entityName }}-search-input-name"
            (keyup)="searchSystemConfigs()"
            autocomplete="off"
            type="text"
            [removeHtmlTags]="filterForm.controls.searchString"
          />
        </mat-form-field>
        <button
          mat-button
          class="custom-button search-button"
          (click)="searchSystemConfigs()"
          id="{{ entityName }}-search-button"
        >
          <span class="material-icons"> search </span>
        </button>
      </div>
    </div>
  </div>

  <div class="animal-welfare-sys-config-list" [hidden]="showForm">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-sys-configs-table"
      multiTemplateDataRows
      [hidden]="isTask"
      aria-hidden="true"
    >
      <ng-container matColumnDef="visibleId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-visibleId"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ entityName }}-table-sys-configs-name-{{ i }}">
          {{ element.visibleId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-name"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ entityName }}-table-sys-configs-address-{{ i }}">
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER.' + element.visibleId | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-description"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DESCRIPTION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-sys-configs-description-{{ i }}"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER.DESCRIPTION.' + element.visibleId | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultValue">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-defaultValue"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.CURRENT-VALUE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-sys-configs-currentValue-{{ i }}"
        >
          {{ element.defaultValue }}
        </td>
      </ng-container>

      <ng-container matColumnDef="validFrom">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-validFrom"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM' | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ entityName }}-table-sys-configs-validFrom-{{ i }}">
          {{ element.validFrom | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="editedBy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-editedBy"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.EDITED-BY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ entityName }}-table-sys-configs-editedBy-{{ i }}">
          {{ element.editedBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastEdit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-lastEdit"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.LAST-EDIT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ entityName }}-table-sys-configs-lastEdit-{{ i }}">
          {{ element.lastEdit | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approvedBy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-approvedBy"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-BY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-sys-configs-approvedBy-{{ i }}"
        >
          {{ element.approvedBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approvedOn">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-sys-configs-approvedOn"
        >
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-ON' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-sys-configs-approvedOn-{{ i }}"
        >
          {{ element.approvedOn | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" scope="col">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-sys-configs-setting">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell class="three-dot-actions" *matCellDef="let row; let i = index">
          <button
            class="menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ element: row }"
            (click)="$event.stopPropagation()"
            id="{{ entityName }}-table-sys-configs-{{ i }}"
          >
            <span class="material-icons"> more_horiz </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = index" class="element-row"></tr>
    </table>
    <mat-paginator *ngIf="!isTask" [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons>
    </mat-paginator>
  </div>

  <mat-menu #menu="matMenu" class="menu-actions">
    <ng-template matMenuContent let-element="element">
      <button
        mat-menu-item
        (click)="edit(element)"
        id="{{ entityName }}-table-sys-configs-action-menu-edit-button"
        *hasPermission="'aw.sp.u'"
      >
        <span translate>
          <mat-icon>edit</mat-icon>
          {{ 'GENERAL.EDIT' | translate }}
        </span>
      </button>
      <button
        mat-menu-item
        (click)="showHistoricalValues(element)"
        id="{{ entityName }}-table-sys-configs-action-menu-shoe-historical-values-button"
      >
        <span translate>
          <mat-icon>update</mat-icon>
          {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.SHOW-HISTORICAL-VALUES' | translate }}
        </span>
      </button>
    </ng-template>
  </mat-menu>
  <mat-menu #appMenu="matMenu" (close)="tableChanged()" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <form
    action=""
    class="form form-container"
    [formGroup]="systemConfigurationForm"
    autocomplete="off"
    *ngIf="showForm || isTask"
  >
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-ID'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-ID'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-ID'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.visibleId"
      [controlName]="'visibleId'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.visibleId : null"
      [taskValue]="sysConfigTask ? sysConfigTask.visibleId : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-NAME'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-NAME'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER-NAME'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.name : null"
      [taskValue]="sysConfigTask ? sysConfigTask.name : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DESCRIPTION'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DESCRIPTION'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DESCRIPTION'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.description"
      [controlName]="'description'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.description : null"
      [taskValue]="sysConfigTask ? sysConfigTask.description : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALUE'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALUE'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALUE'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.defaultValue"
      [controlName]="'defaultValue'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.defaultValue : null"
      [taskValue]="sysConfigTask ? sysConfigTask.defaultValue : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.validFrom"
      [controlName]="'validFrom'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.validFrom : null"
      [taskValue]="sysConfigTask ? sysConfigTask.validFrom : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [minDate]="null"
      [maxDate]="null"
    ></app-mat-input-date>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.EDITED-BY'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.EDITED-BY'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.EDITED-BY'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.editedBy"
      [controlName]="'editedBy'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.editedBy : null"
      [taskValue]="sysConfigTask ? sysConfigTask.editedBy : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.LAST-EDIT'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.LAST-EDIT'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.LAST-EDIT'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.lastEdit"
      [controlName]="'lastEdit'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.lastEdit : null"
      [taskValue]="sysConfigTask ? sysConfigTask.lastEdit : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [minDate]="null"
      [maxDate]="null"
    ></app-mat-input-date>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-BY'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-BY'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-BY'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.approvedBy"
      [controlName]="'approvedBy'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.approvedBy : null"
      [taskValue]="sysConfigTask ? sysConfigTask.approvedBy : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>
    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-ON'"
      [placeholder]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-ON'"
      [label]="'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.APPROVED-ON'"
      [entityName]="entityName"
      [form]="systemConfigurationForm"
      [controlObject]="systemConfigurationForm.controls.approvedOn"
      [controlName]="'approvedOn'"
      [approvedValue]="sysConfigApproved ? sysConfigApproved.approvedOn : null"
      [taskValue]="sysConfigTask ? sysConfigTask.approvedOn : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [minDate]="null"
      [maxDate]="null"
    ></app-mat-input-date>
    <div class="action-buttons" *ngIf="!isTask || showActionButtons">
      <button
        id="{{ entityName }}-form-save-button"
        mat-button
        class="custom-button save-button"
        (click)="$event.preventDefault(); saveChanges()"
        [disabled]="systemConfigurationForm.invalid"
      >
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </button>
      <button
        id="{{ entityName }}-form-cancel-button"
        mat-button
        class="custom-button-cancel cancel-button"
        (click)="$event.preventDefault(); cancel()"
      >
        {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
      </button>
    </div>
  </form>
</section>
