import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { debitCreditCodeOptions } from 'src/app/in-memory-data/open-transaction/enum/debit-credit-code-options';
import { debtCollectionOptions } from 'src/app/in-memory-data/open-transaction/enum/debt-collection-options';
import { settlemenetInEuroOptions } from 'src/app/in-memory-data/open-transaction/enum/settlement-in-eur-options';
import { OpenTransactionBookingTableService } from 'src/app/modules/open-transaction-booking-management/services/open-transaction-booking-table.service';
import { DialogData } from '../../models/dialog-data';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { OpenItemService } from '../../services/open-item/open-item.service';
import { TransactionCodeAllocationService } from '../../services/transaction-code-allocation/transaction-code-allocation.service';
import {
  DateValidator,
  futureDateValidator,
  pastDateValidator,
} from '../../validators/date-validator';
import { GeneralValidator } from '../../validators/general-validator';

@Component({
  selector: 'app-open-transaction-multi-edit-modal',
  templateUrl: './open-transaction-multi-edit-modal.component.html',
  styleUrls: ['./open-transaction-multi-edit-modal.component.less'],
})
export class OpenTransactionMultiEditModalComponent implements OnInit {
  entityName: 'open-transaction-multi-edit';
  openTransactionMultiEditForm: UntypedFormGroup;

  debitCreditCodeOptions = debitCreditCodeOptions;
  debtCollectionOptions = debtCollectionOptions;
  settlemenetInEuroOptions = settlemenetInEuroOptions;

  options = new BehaviorSubject<any[]>([]);
  options$: Observable<any[]>;

  optionsExternalId = new BehaviorSubject<any[]>([]);
  optionsExternalId$: Observable<any[]>;

  transactionCodes: any[] = [];

  businessUnits: any;
  businessUnitProduct: any;
  businessUnitGuid: any;

  pageIndex = 0;
  pageSize = 25;

  pageIndexExternal = 0;

  searchTerm = '';
  partners: any = [];

  columns: any[] = [];

  businessRelationships: any[];
  selectedBusinessPartner: any;

  currencyCodes: string[];
  cc = require('currency-codes');

  fieldsToDisplay = {
    businessPartner: false,
    businessRelationship: false,
    externalId: false,
    clientOpenItemId: false,
    openItemDate: false,
    transactionCode: false,
    debitOrCredit: false,
    amount: false,
    currency: false,
    vatAmount: false,
    vatPercent: false,
    dueDate: false,
    debtCollection: false,
    debtCollectionTransferDate: false,
    settlementInEur: false,
    referenceDocumentationId: false,
    additionalInformation: false,
    branchId: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private businessUnitService: BusinessUnitService,
    private openItemService: OpenItemService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private generalValidator: GeneralValidator,
    private dateValidator: DateValidator,
    private openTransactionBookingTableService: OpenTransactionBookingTableService
  ) {
    this.sortCountryCodes();
    this.openTransactionMultiEditFormBuilder();
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return curr;
      }, [])
    );
    this.optionsExternalId$ = this.optionsExternalId.asObservable().pipe(
      scan((acc, curr) => {
        return curr;
      }, [])
    );
  }

  ngOnInit(): void {
    this.businessUnits = this.businessUnitService.getAllBusinessUnitsLocal();
    this.businessUnitProduct = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).product;
    this.businessUnitGuid = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).guid;

    this.openTransactionMultiEditForm
      .get('businessPartnerSearch')
      .valueChanges.subscribe((data) => {
        this.updateAndSearch(data);
      });

    this.openTransactionMultiEditForm
      .get('externalIdSearch')
      .valueChanges.subscribe((data) => {
        this.updateAndSearch(data);
      });

    this.openTransactionMultiEditForm
      .get('businessPartner')
      .valueChanges.subscribe((data) => {
        this.patchExternalId(data);
      });

    this.openTransactionMultiEditForm
      .get('externalId')
      .valueChanges.subscribe((data) => {
        this.patchBusinessPartner(data);
      });

    this.openTransactionMultiEditForm.get('amount')
      .valueChanges.subscribe((data) => {
        this.changeAmount();
      });

    this.openTransactionMultiEditForm
      .get('vatAmount')
      .valueChanges.subscribe((data) => {
        this.calculateVatPercent();
      });

    this.openTransactionMultiEditForm
      .get('vatPercent')
      .valueChanges.subscribe((data) => {
        this.calculateVatAmount();
      });

    this.columns = this.dialogData.columns;
    this.businessRelationships = this.dialogData.businessRelationships;
    this.selectedBusinessPartner = this.dialogData.selectedBusinessPartner;
    this.checkBusinessPartner();
    this.getTransactionCodesFromBU();
    this.checkFieldsToDisplay();
  }

  sortCountryCodes() {
    this.currencyCodes = this.cc.codes();

    const usdIndex = this.currencyCodes.findIndex((data) => data === 'USD');
    const usd = this.currencyCodes.find((data) => data === 'USD');

    this.currencyCodes.splice(usdIndex, 1);
    this.currencyCodes.unshift(usd);

    const gbpIndex = this.currencyCodes.findIndex((data) => data === 'GBP');
    const gbp = this.currencyCodes.find((data) => data === 'GBP');

    this.currencyCodes.splice(gbpIndex, 1);
    this.currencyCodes.unshift(gbp);

    const eurIndex = this.currencyCodes.findIndex((data) => data === 'EUR');
    const eur = this.currencyCodes.find((data) => data === 'EUR');

    this.currencyCodes.splice(eurIndex, 1);
    this.currencyCodes.unshift(eur);
  }

  checkBusinessPartner() {
    if (this.selectedBusinessPartner) {
      const array: any[] = [];
      array.push(this.selectedBusinessPartner);
      this.optionsExternalId.next(array);
      this.openTransactionMultiEditForm
        .get('externalId')
        .patchValue(this.selectedBusinessPartner, { emitEvent: false });
      this.openTransactionMultiEditForm.get('externalId').disable();
    }
  }

  patchExternalId(data) {
    if (data && data.externalId) {
      const array: any[] = [];
      array.push(data);
      this.optionsExternalId.next(array);
      this.openTransactionMultiEditForm
        .get('externalId')
        .patchValue(data, { emitEvent: false });
    } else {
      this.openTransactionMultiEditForm
        .get('externalId')
        .patchValue(null, { emitEvent: false });
      this.openTransactionMultiEditForm.get('externalId').enable();
    }
  }

  patchBusinessPartner(data) {
    if (data) {
      const array: any[] = [];
      array.push(data);
      this.options.next(array);
      this.openTransactionMultiEditForm
        .get('businessPartner')
        .patchValue(data, { emitEvent: false });
    } else {
      this.openTransactionMultiEditForm
        .get('businessPartner')
        .patchValue(null, { emitEvent: false });
      this.openTransactionMultiEditForm.get('businessPartner').enable();
    }
  }

  changeAmount() {
    const formGroup: UntypedFormGroup = this.openTransactionMultiEditForm;
    formGroup.get('vatAmount').setValue(0.0, { emitEvent: false });
    formGroup.get('vatPercent').setValue(0.0, { emitEvent: false });
  }

  calculateVatPercent() {
    const formGroup: UntypedFormGroup = this.openTransactionMultiEditForm;
    const vatPercentField = formGroup.get('vatPercent');

    let grossAmount = formGroup.get('amount').value;
    let vatAmount = formGroup.get('vatAmount').value;

    if (
      vatAmount === null ||
      isNaN(vatAmount) ||
      grossAmount === null ||
      isNaN(grossAmount) ||
      grossAmount === vatAmount
    ) {
      vatPercentField.patchValue(0.0, { emitEvent: false });
      formGroup.updateValueAndValidity();
      return;
    }

    grossAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(grossAmount);
    vatAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(vatAmount);

    let calculatedVatPercent: number =
      (vatAmount * 100) / (grossAmount - vatAmount);
    calculatedVatPercent =
      this.openTransactionBookingTableService.roundToTwoDecimals(
        calculatedVatPercent
      );

    vatPercentField.patchValue(calculatedVatPercent, { emitEvent: false });
    if (Math.abs(calculatedVatPercent) >= 100) {
      vatPercentField.setErrors({
        max: { max: 99.99, actual: Math.abs(calculatedVatPercent) },
      });
    }
    vatPercentField.markAsTouched();
    formGroup.updateValueAndValidity();
  }

  calculateVatAmount() {
    const formGroup: UntypedFormGroup = this.openTransactionMultiEditForm;
    const vatAmountField: AbstractControl = formGroup.get('vatAmount');

    let grossAmount: number = formGroup.get('amount').value;
    let vatPercent: number = formGroup.get('vatPercent').value;

    if (
      vatPercent === null ||
      isNaN(vatPercent) ||
      grossAmount === null ||
      isNaN(grossAmount) ||
      vatPercent === -100.0
    ) {
      vatAmountField.patchValue(0.0, { emitEvent: false });
      vatAmountField.markAsTouched();
      formGroup.updateValueAndValidity();
      return;
    }

    if (Math.abs(vatPercent) >= 100) {
      formGroup
        .get('vatPercent')
        .setErrors({ max: { max: 99.99, actual: Math.abs(vatPercent) } });
    }

    grossAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(grossAmount);
    vatPercent =
      this.openTransactionBookingTableService.roundToTwoDecimals(vatPercent);

    let calculatedVatAmount: number =
      (grossAmount / (100 + vatPercent)) * vatPercent;
    calculatedVatAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(
        calculatedVatAmount
      );

    vatAmountField.patchValue(calculatedVatAmount, { emitEvent: false });
    formGroup.updateValueAndValidity();
  }

  findColumn(columnName) {
    if (this.columns?.length > 0) {
      return this.columns.find((col) => col === columnName);
    }
    return false;
  }

  openTransactionMultiEditFormBuilder() {
    this.openTransactionMultiEditForm = this.formBuilder.group(
      {
        businessPartner: [''],
        businessPartnerSearch: [''],
        businessRelationship: [''],
        externalId: [''],
        externalIdSearch: [''],
        clientOpenItemId: [''],
        openItemDate: ['', [pastDateValidator()]],
        transactionCode: [''],
        debitOrCredit: [''],
        amount: [''],
        currency: [''],
        vatAmount: [''],
        vatPercent: [''],
        dueDate: ['', [futureDateValidator()]],
        debtCollection: [''],
        debtCollectionTransferDate: [''],
        settlementInEur: [''],
        referenceDocumentationId: [''],
        additionalInformation: [''],
        branchId: [''],
      },
      {
        validators: [
          this.generalValidator.upperLimitValidation('amount', 'vatAmount'),
          this.dateValidator.startEndValidator('openItemDate', 'dueDate'),
        ],
      }
    );
  }

  _openChanged($event) {
    if ($event) {
      this.openTransactionMultiEditForm
        .get('businessPartner')
        .patchValue(null, { emitEvent: false });
      this.searchFromSelect();
    }
  }

  _openChangedExternalId($event) {
    if ($event) {
      this.openTransactionMultiEditForm
        .get('externalId')
        .patchValue(null, { emitEvent: false });
      this.searchFromSelectExternalId();
    }
  }

  searchFromSelect($event?) {
    this.pageIndex = 0;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.openTransactionMultiEditForm.get('businessPartnerSearch')
        .value
        ? this.openTransactionMultiEditForm.get('businessPartnerSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      product: this.businessUnitProduct,
    };
    this.openItemService.listPartners(configObj).subscribe((data) => {
      this.partners = data['content'];
      this.options.next(this.partners);
    });
  }

  searchFromSelectExternalId($event?) {
    this.pageIndexExternal = 0;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.openTransactionMultiEditForm.get('externalIdSearch')
        .value
        ? this.openTransactionMultiEditForm.get('externalIdSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndexExternal,
      product: this.businessUnitProduct,
    };
    this.openItemService.listPartners(configObj).subscribe((data) => {
      this.partners = data['content'];
      let partnersWExternalId = [];
      partnersWExternalId = this.partners.filter((p) => p.externalId !== '');
      this.optionsExternalId.next(partnersWExternalId);
    });
  }

  onScroll() {
    this.pageIndex += this.pageSize;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.openTransactionMultiEditForm.get('businessPartnerSearch')
        .value
        ? this.openTransactionMultiEditForm.get('businessPartnerSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      product: this.businessUnitProduct,
    };
    this.openItemService.listPartners(configObj).subscribe((data) => {
      for (const partner of data['content']) {
        this.partners.push(partner);
      }
      this.options.next(this.partners);
    });
  }

  onScrollExternalId() {
    this.pageIndexExternal += this.pageSize;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: this.openTransactionMultiEditForm.get('externalIdSearch')
        .value
        ? this.openTransactionMultiEditForm.get('externalIdSearch').value
        : '',
      pageSize: this.pageSize,
      pageIndex: this.pageIndexExternal,
      product: this.businessUnitProduct,
    };
    this.openItemService.listPartners(configObj).subscribe((data) => {
      for (const partner of data['content']) {
        this.partners.push(partner);
      }
      this.optionsExternalId.next(this.partners);
    });
  }

  getTransactionCodesFromBU() {
    this.transactionCodeAllocationService
      .retrieveTransactionCodeByEntityId(this.businessUnitGuid)
      .subscribe((data) => {
        data.sort((a, b) => a.transactionCode - b.transactionCode);
        this.transactionCodes = data;
      });
  }

  formatTransactionCode(item) {
    return item?.transactionCode?.toString().padStart(3, '0');
  }

  private updateAndSearch(data) {
    if (data !== '') {
      this.searchTerm = data;
      this.searchFromSelect();
    }
  }

  /**
   * Used to check which fields need to be displayed and avoid the eternal loop with functions.
   */
  private checkFieldsToDisplay() {
    Object.keys(this.fieldsToDisplay).forEach((key) => {
      this.fieldsToDisplay[key] = this.findColumn(key) ? true : false;
    });
  }
}
