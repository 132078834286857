import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-general-delete-confirmation-modal',
  templateUrl: './general-delete-confirmation-modal.component.html',
  styleUrls: ['./general-delete-confirmation-modal.component.less'],
})
export class GeneralDeleteConfirmationModalComponent {
  deleteMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    if (data.deleteMessage) {
      this.deleteMessage = data.deleteMessage;
    }
  }
}
