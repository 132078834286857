export const salesEntryColumnsConf: any[] = [
  {
    name: 'accountNumber',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER',
    locked: true,
  },
  {
    name: 'centralMasterData',
    index: 1,
    checked: true,
    translateName: 'DEBTOR.COMPANY',
    locked: true,
  },
  {
    name: 'type',
    index: 2,
    checked: true,
    translateName: 'GENERAL.TYPE',
    locked: true,
  },
  {
    name: 'advancePayment',
    index: 3,
    checked: false,
    translateName: 'GENERAL.TYPE',
    locked: true,
  },
  {
    version: 2,
  },
];
