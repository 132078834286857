export const taskColumnsConf: any[] = [
  {
    name: 'id',
    index: 0,
    checked: false,
    translateName: 'Id',
    locked: true,
  },
  {
    name: 'type',
    index: 1,
    checked: false,
    translateName: 'TASK.TYPE',
    locked: true,
  },
  {
    name: 'title',
    index: 2,
    checked: false,
    translateName: 'TASK.TITLE',
    locked: true,
  },
  {
    name: 'status',
    index: 3,
    checked: true,
    translateName: 'TASK.STATUS',
    locked: false,
  },
  {
    name: 'createdBy',
    index: 4,
    checked: true,
    translateName: 'TASK.CREATED-BY',
    locked: false,
  },
  {
    name: 'dueDate',
    index: 5,
    checked: true,
    translateName: 'TASK.DUE-DATE',
    locked: false,
  },
  {
    name: 'priority',
    index: 6,
    checked: true,
    translateName: 'TASK.PRIORITY',
    locked: false,
  },
  {
    name: 'assignedUserId',
    index: 7,
    checked: true,
    translateName: 'TASK.ASSIGNED-USER',
    locked: true,
  },
  {
    name: 'createdOn',
    index: 8,
    checked: false,
    translateName: 'GENERAL.CREATED-ON',
    locked: false,
  },
  {
    name: 'businessUnitId',
    index: 9,
    checked: false,
    translateName: 'TASK.BU',
    locked: false,
  },
  {
    name: 'team',
    index: 10,
    checked: false,
    translateName: 'TASK.TEAM',
    locked: false,
  },
  {
    name: 'lockedBy',
    index: 11,
    checked: false,
    translateName: 'TASK.LOCKED-BY',
    locked: false,
  },
  {
    name: 'taskObject',
    index: 12,
    checked: false,
    translateName: 'TASK-CONFIGURATION.OBJECT',
    locked: false,
  },
  {
    name: 'taskObjectId',
    index: 13,
    checked: false,
    translateName: 'TASK-CONFIGURATION.OBJECT-ID',
    locked: false,
  },
  {
    version: 8,
  },
];
