<div class="manual-container">
  <div class="container full-height" fxLayout="row wrap" fxLayoutGap="7px grid">
    <div fxFlex="100">
      <div class="title-faq">{{ 'HOMEPAGE.MANUAL-FAQ' | translate }}</div>
      <div class="centered-row" [ngClass]="{ 'no-margin': isSlaughterhouse() }">
        <div class="manual-container-row">
          <span class="text" (click)="downloadFaq()">{{ 'HOMEPAGE.FAQ' | translate }}</span>
          <span class="material-icons-outlined logo logo-red" (click)="downloadFaq()"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.c', 'ext.aw.s']">
          <span class="text" (click)="downloadManual(1)">{{ 'HOMEPAGE.MANUAL-1' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(1)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.c']">
          <span class="text" (click)="downloadManual(2)">{{ 'HOMEPAGE.MANUAL-2' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(2)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.c']">
          <span class="text" (click)="downloadManual(3)">{{ 'HOMEPAGE.MANUAL-3' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(3)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.c']">
          <span class="text" (click)="downloadManual(4)">{{ 'HOMEPAGE.MANUAL-4' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(4)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.s']">
          <span class="text" (click)="downloadManual(5)">{{ 'HOMEPAGE.MANUAL-5' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(5)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.s']">
          <span class="text" (click)="downloadManual(6)">{{ 'HOMEPAGE.MANUAL-6' | translate }}</span>
          <span class="material-icons-outlined logo logo-red" (click)="downloadManual(6)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.s']">
          <span class="text" (click)="downloadManual(7)">{{ 'HOMEPAGE.MANUAL-7' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(7)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.s']">
          <span class="text" (click)="downloadManual(8)">{{ 'HOMEPAGE.MANUAL-8' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(8)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.ma']">
          <span class="text" (click)="downloadManual(9)">{{ 'HOMEPAGE.MANUAL-9' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(9)"> picture_as_pdf </span>
        </div>
        <div class="manual-container-row" *hasPermission="['ext.aw.lp']">
          <span class="text" (click)="downloadManual(10)">{{ 'HOMEPAGE.MANUAL-10' | translate }}</span>
          <span class="material-icons-outlined logo logo-black" (click)="downloadManual(10)"> picture_as_pdf </span>
        </div>
        <div *hasPermission="['ext.aw.ma']" class="manual-container-row">
          <span (click)="downloadManual(11)" class="text">{{ 'HOMEPAGE.MANUAL-11' | translate }}</span>
          <span (click)="downloadManual(11)" class="material-icons-outlined logo logo-black"> picture_as_pdf </span>
        </div>
      </div>
    </div>
  </div>
</div>
