<div class="animal-welfare-liquidity-plan-filters">
  <div class="loader" *ngIf="loadSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <mat-accordion class="filter-wrapper">
    <mat-expansion-panel
      [expanded]="panelOpenState"
      id="{{ entityName }}-expansionPanel"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header id="{{ entityName }}-expansionPanel-header">
        <mat-panel-title>
          <span>{{ 'SEARCH-PARAM' | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form class="form" [formGroup]="liquidityPlanFilterForm" autocomplete="off">
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.KIND'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.KIND'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.KIND'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.kind"
          [controlName]="'kind'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="kinds"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [noSort]="true"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.TYPE'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.TYPE'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.TYPE'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.type"
          [controlName]="'type'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="types"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <div class="form-row-selection">
          <mat-form-field appDateFormatMonthYear>
            <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.FILTER-PERIOD' | translate }}</mat-label>
            <mat-date-range-input
              [formGroup]="liquidityPlanFilterForm.controls.monthAndYear"
              [rangePicker]="monthAndYearPicker"
              id="{{ entityName }}-monthAndYear-startDate-endDate"
            >
              <input
                matStartDate
                formControlName="startDate"
                id="{{ entityName }}-monthAndYear-startDate"
                placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
              <input
                matEndDate
                formControlName="endDate"
                id="{{ entityName }}-monthAndYear-endDate"
                placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
            </mat-date-range-input>
            <mat-date-range-picker
              #monthAndYearPicker
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
            ></mat-date-range-picker>
            <mat-error
              id="{{ entityName }}-form-filter-period-invalid"
              class="input-error"
              *ngIf="
                liquidityPlanFilterForm.controls.monthAndYear.dirty ||
                liquidityPlanFilterForm.controls.monthAndYear.hasError('required')
              "
            >
              {{
                'ERROR-MESSAGES.INVALID'
                  | translate
                    : {
                        name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.FILTER-PERIOD' | translate
                      }
                  | titlecase
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <app-mat-input
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.COMMENT'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.COMMENT'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.COMMENT'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.comment"
          [controlName]="'comment'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CANCELATION-SELECTION'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CANCELATION-SELECTION'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CANCELATION-SELECTION'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.cancellation"
          [controlName]="'cancellation'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="cancelationSelections"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.STATUS'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.STATUS'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.STATUS'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.status"
          [controlName]="'status'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="statuses"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.PLAN-ACTUAL-VALUE-SELECTION'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.PLAN-ACTUAL-VALUE-SELECTION'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.PLAN-ACTUAL-VALUE-SELECTION'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.planOrActualValue"
          [controlName]="'planOrActualValue'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="planActualValues"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CREATED-BY'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CREATED-BY'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CREATED-BY'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.createdBy"
          [controlName]="'createdBy'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="createdByList"
          [optionValue]="''"
          [selectedOption]="null"
          [translationOption]="''"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <div class="form-row-selection">
          <mat-form-field appDateFormatMonthYearDay>
            <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CREATED-ON' | translate }}</mat-label>
            <mat-date-range-input
              [formGroup]="liquidityPlanFilterForm.controls.inputDate"
              [rangePicker]="createdOnPicker"
              id="{{ entityName }}-createdOn-startDate-endDate"
            >
              <input
                matStartDate
                formControlName="startDate"
                id="{{ entityName }}-inputDate-startDate"
                placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
              <input
                matEndDate
                formControlName="endDate"
                id="{{ entityName }}-inputDate-endDate"
                placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="createdOnPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #createdOnPicker></mat-date-range-picker>
            <mat-error
              id="{{ entityName }}-form-input-date-invalid"
              class="input-error"
              *ngIf="
                liquidityPlanFilterForm.controls.inputDate.dirty ||
                liquidityPlanFilterForm.controls.inputDate.hasError('required')
              "
            >
              {{
                'ERROR-MESSAGES.INVALID'
                  | translate
                    : {
                        name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.CREATED-ON' | translate
                      }
                  | titlecase
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-BY'"
          [placeholder]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-BY'"
          [label]="'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-BY'"
          [entityName]="entityName"
          [form]="liquidityPlanFilterForm"
          [controlObject]="liquidityPlanFilterForm.controls.approvedBy"
          [controlName]="'approvedBy'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="null"
          [formGroupCustomName]="null"
          [options]="approvedByList"
          [optionValue]="''"
          [selectedOption]="null"
          [translationOption]="''"
          [optionTranslationText]="''"
          [customOptionNameFunction]=""
          [customTaskValueFunction]=""
          [textTransform]="'none'"
        ></app-mat-select>
        <div class="form-row-selection">
          <mat-form-field appDateFormatMonthYearDay>
            <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-ON' | translate }}</mat-label>
            <mat-date-range-input
              [formGroup]="liquidityPlanFilterForm.controls.approvedOn"
              [rangePicker]="approvedOnPicker"
              id="{{ entityName }}-approvedOn-startDate-endDate"
            >
              <input
                matStartDate
                formControlName="startDate"
                id="{{ entityName }}-approvedOn-startDate"
                placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
              <input
                matEndDate
                formControlName="endDate"
                id="{{ entityName }}-approvedOn-endDate"
                placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                (keydown.enter)="$event.preventDefault()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="approvedOnPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #approvedOnPicker></mat-date-range-picker>
            <mat-error
              id="{{ entityName }}-form-approved-on-invalid"
              class="input-error"
              *ngIf="
                liquidityPlanFilterForm.controls.approvedOn.dirty ||
                liquidityPlanFilterForm.controls.approvedOn.hasError('required')
              "
            >
              {{
                'ERROR-MESSAGES.INVALID'
                  | translate
                    : {
                        name: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-ON' | translate
                      }
                  | titlecase
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="button-container">
          <button mat-button class="custom-button" (click)="search()" [disabled]="liquidityPlanFilterForm.invalid">
            <span translate>ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.SEARCH</span>
          </button>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
