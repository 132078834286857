import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { roleTypeSenum } from 'src/app/in-memory-data/user-management/enums/role-type-enum';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { first } from 'rxjs/operators';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import * as _ from 'lodash';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-role-configuration',
  templateUrl: './role-configuration.component.html',
  styleUrls: ['./role-configuration.component.less'],
})
export class RoleConfigurationComponent implements OnInit {
  frmRole;
  roleTask;
  entityName = '';
  isTask = false;
  roleTypes = roleTypeSenum;
  permissions = [];
  task;
  taskValue;
  approvedValue;
  searchStringAll: any;
  searchRunning = false;
  selectedPermissions = [];
  selectedActivePermissions: any[] = [];
  searchStringActive: any;
  activePermissions: any;
  state;
  originalPermissions = [];
  formPermissions = [];
  originalFormPermissions = [];
  currentUser: any;
  renderedPermissionList: any[] = [];
  finishLoaded = false;
  @Input() roleType;
  @Input() realm;
  private internalRealm: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private matDialogService: MatDialog,
    private draftService: DraftService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService
  ) {
    this.roleFormBuilder();
  }

  ngOnInit(): void {
    if (this.realm === 'external') {
      this.internalRealm = false;
    } else {
      this.internalRealm = true;
    }
    const snapshot = this.route.snapshot;
    this.state = window.history.state;
    this.getCurrentDate();
    this.getCuurentUserName();
    if (snapshot.params && snapshot.params.guid) {
      if (this.state.param && !this.state.param.internalRole) {
        this.userService
          .findRole(snapshot.params.guid, 'external', this.state.viewMode)
          .pipe(first())
          .subscribe((data) => this.updateApprovedValueAndPatchForm(data));
        this.userService
          .getPermissions(false, 'external')
          .pipe(first())
          .subscribe((data) => {
            this.permissions = data;
            this.originalPermissions = data;
          });
      } else {
        this.userService
          .findRole(snapshot.params.guid, 'internal', this.state.viewMode)
          .pipe(first())
          .subscribe((data) => this.updateApprovedValueAndPatchForm(data));
        this.userService
          .getPermissions(false, 'internal')
          .pipe(first())
          .subscribe((data) => {
            this.permissions = data;
            this.originalPermissions = data;
          });
      }
    } else if (this.state.task) {
      this.task = this.state.task;
      this.draftService
        .retrieveDraftForTask(this.task.guid)
        .pipe(first())
        .subscribe((data) => {
          const realm = data['internalRole'] ? 'internal' : 'external';
          if (data.id) {
            this.userService
              .findRole(data.id, realm, this.state.viewMode)
              .subscribe(
                (approvedData) => {
                  this.approvedValue = approvedData;
                  this.sharedDataService.updateApprovedRole(this.approvedValue);
                  this.finishLoaded = true;
                },
                (error) => {
                  this.finishLoaded = true;
                }
              );
          } else {
            this.finishLoaded = true;
          }
          this.taskValue = data;
          this.roleTask = data;
          this.isTask = true;
          this.frmRole.patchValue(data);
        });
    } else if (this.realm) {
      this.userService
        .getPermissions(false, this.realm)
        .pipe(first())
        .subscribe((data) => {
          this.permissions = data;
          this.originalPermissions = data;
        });
    } else if (this.state.copiedRow) {
      if (this.state.rowData) {
        if (this.state.rowData.internalRole) {
          this.userService
            .getPermissions(false, 'internal')
            .pipe(first())
            .subscribe((data) => {
              this.permissions = data;
              this.originalPermissions = data;
            });
        } else {
          this.userService
            .getPermissions(false, 'external')
            .pipe(first())
            .subscribe((data) => {
              this.permissions = data;
              this.originalPermissions = data;
            });
        }
        if (localStorage.currentSelectedLanguage === 'en') {
          this.frmRole
            .get('description')
            .patchValue('Copy of' + ' ' + this.state.rowData.roleName);
        } else {
          this.frmRole
            .get('description')
            .patchValue('Kopie von' + ' ' + this.state.rowData.roleName);
        }
      }
    }
    if (this.state.param && this.state.param.permissions) {
      this.frmRole.get('permissions').patchValue(this.state.param.permissions);
      this.frmRole.get('updatedOn').patchValue(this.state.param.updatedOn);
      this.frmRole.get('updatedBy').patchValue(this.state.param.updatedBy);
    }
    this.searchInputChangedActivePermissions();
  }

  roleFormBuilder() {
    this.frmRole = this.formBuilder.group({
      roleName: ['', Validators.required],
      roleType: ['', Validators.required],
      description: [''],
      permissions: [[]],
      id: [''],
      createdOn: [{ value: '', disabled: true }],
      createdBy: [{ value: '', disabled: true }],
      updatedOn: [{ value: '', disabled: true }],
      updatedBy: [{ value: '', disabled: true }],
    });
  }

  setUpdateInfo() {
    this.frmRole.get('updatedBy').patchValue(this.currentUser);
    this.frmRole.get('updatedOn').patchValue(new Date());
  }

  getCuurentUserName() {
    this.userService.getSettings().subscribe((data) => {
      this.currentUser = data.user.username;
      const createUser =
        this.state && this.state.param && this.state.param.createdBy
          ? this.state.param.createdBy
          : this.currentUser;
      this.frmRole.get('createdBy').patchValue(createUser);
    });
  }

  getCurrentDate() {
    const currentDate = new Date();
    const createDate =
      this.state && this.state.param && this.state.param.createdOn
        ? this.state.param.createdOn
        : currentDate;
    this.frmRole.get('createdOn').patchValue(createDate);
  }

  createRole() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          const requestJson = {
            comment: result.comment,
            roleType: this.roleType,
            roleDto: {
              ...this.frmRole.getRawValue(),
              ...{ internalRole: this.internalRealm },
            },
          };
          this.userService
            .createRole(requestJson)
            .pipe(first())
            .subscribe(
              (data) => {
                this.notificationService.showToast(
                  'NOTIFICATION.CREATED',
                  this.notificationService.MESSAGE_TYPE.SUCCESS,
                  { data: this.translateService.instant('ENTITIES.ROLE') }
                );
                this.router.navigateByUrl('/userManagement/role');
              },
              (error) => {
                if (error.status === 409) {
                  this.notificationService.showToast(
                    'NOTIFICATION.PERMISSION.EXISTS',
                    this.notificationService.MESSAGE_TYPE.ERROR
                  );
                  this.router.navigateByUrl('/userManagement/role');
                }
              }
            );
        }
      });
  }

  searchInputChangedPermissions() {
    const foundPermissions = [];
    if (this.searchStringAll !== '') {
      this.originalPermissions.forEach((permission) => {
        if (permission.permissionId.includes(this.searchStringAll)) {
          foundPermissions.push(permission);
        }
      });
      this.permissions = foundPermissions;
    } else {
      if (this.realm !== undefined) {
        this.userService
          .getPermissions(false, this.realm.toString())
          .pipe(first())
          .subscribe((data) => {
            this.permissions = data;
          });
      } else {
        if (this.state.param.internalRole) {
          this.userService
            .getPermissions(false, 'internal')
            .pipe(first())
            .subscribe((data) => {
              this.permissions = data;
            });
        } else {
          this.userService
            .getPermissions(false, 'external')
            .pipe(first())
            .subscribe((data) => {
              this.permissions = data;
            });
        }
      }
    }
  }

  replaceAllTheValue(key) {
    if (key) {
      return key
        .replaceAll('|', '')
        .replace('}', '')
        .replace('{', '')
        .toString()
        .toLowerCase();
    }
    return;
  }

  searchInputChangedActivePermissions() {
    const form = this.frmRole.get('permissions').value ?? [];
    if (!this.searchStringActive) {
      this.searchStringActive = '';
    }
    this.renderedPermissionList = form.filter((value) =>
      this.replaceAllTheValue(value).includes(
        this.searchStringActive.toString().toLowerCase()
      )
    );
  }

  isSelected(permission: any) {
    return this.frmRole
      .get('permissions')
      .value.includes(permission.permissionId);
  }

  addToUser() {
    if (this.selectedPermissions && this.selectedPermissions.length > 0) {
      const currentList = this.frmRole.get('permissions').value ?? [];
      this.frmRole
        .get('permissions')
        .patchValue(_.union(currentList, this.selectedPermissions));
    }
    this.searchInputChangedActivePermissions();
  }

  onGroupsChange(options: MatListOption[]) {
    this.selectedActivePermissions = [];
    for (const option of options) {
      if (option.selected) {
        this.selectedActivePermissions.push(option.value);
      }
    }
  }

  removeFromUser() {
    const form = this.frmRole
      .get('permissions')
      .value.filter((value) => !this.selectedActivePermissions.includes(value));
    this.frmRole.get('permissions').patchValue(form);
    this.searchInputChangedActivePermissions();
  }

  private updateApprovedValueAndPatchForm(data: any) {
    this.frmRole.patchValue(data);
    this.frmRole.get('roleType').disable();
    this.approvedValue = data;
    if (this.state.viewMode) {
      this.frmRole.disable();
    }
  }
}
