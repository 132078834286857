import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environmentLoader as environmentLoaderPromise } from './app/environmentLoader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

environmentLoaderPromise.then((env) => {
  if (environment.production) {
    enableProdMode();
  }
  environment.keycloak = env.keycloak;
  environment.settings = env.settings;
  environment.isInternal = env.isInternal;
  environment.rootDomain = env.rootDomain;
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
