import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

// tslint:disable-next-line:max-line-length
import { AwInterfaceTransferProtocolService } from 'src/app/shared/services/animal-welfare/aw-interface-transfer-protocol/aw-interface-transfer-protocol.service';

// tslint:disable-next-line:max-line-length
import { awInterfaceTransferProtocolTableColumns } from '../../../in-memory-data/animal-welfare/aw-interface-transfer-protocol/table-columns';

import { SelectionModel } from '@angular/cdk/collections';
import { AnimalWelfareFile } from '../../../shared/models/animal-welfare-file';

// tslint:disable-next-line:max-line-length
import { AwItpProtocolDetailsModalComponent } from '../../../shared/modals/animal-welfare-modals/aw-itp-protocol-details-modal/aw-itp-protocol-details-modal.component';
import { AwItpProtocolRequestResponseModalComponent } from '../../../shared/modals/animal-welfare-modals/aw-itp-protocol-request-response-modal/aw-itp-protocol-request-response-modal.component';
import { AwXsdModalComponent } from '../../../shared/modals/animal-welfare-modals/aw-xsd-modal/aw-xsd-modal.component';
import { awInterfaceTransferProtocolTableColumnsConf } from 'src/app/in-memory-data/animal-welfare/aw-interface-transfer-protocol/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { formatDate } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-aw-interface-transfer-protocol',
  templateUrl: './aw-interface-transfer-protocol.component.html',
  styleUrls: ['./aw-interface-transfer-protocol.component.less'],
})
export class AwInterfaceTransferProtocolComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('appMenu') clickHoverMenuTrigger: MatMenuTrigger;

  entityName = 'filesTable';

  columns = awInterfaceTransferProtocolTableColumnsConf;
  displayedColumns = awInterfaceTransferProtocolTableColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  CONFIGURATION_KEY = 'animal_welfare_interface_transfer_protocol_table';

  dataSource = new MatTableDataSource();

  selectedRowIndex;

  documents: AnimalWelfareFile[];
  entities: [] = [];
  selection = new SelectionModel(true, []);
  loaderSpinner = true;
  loader: boolean;
  pageSize = 20;
  totalCount;

  dateFormat: string;
  filter;
  searchInput: any = '';
  searchProgress: any;
  searchStatus: any;
  progressValues: string[];
  statusValues: string[];
  creationTimestampRange: any;
  updateTimestampRange: any;
  subject = new Subject();

  constructor(
    private awInterfaceTransferProtocolService: AwInterfaceTransferProtocolService,
    private matDialogService: MatDialog,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.dateFormat = this.userService.getDateFormat(true);
    this.filter = this.formBuilder.group({
      searchString: ['', []],
      progress: ['', []],
      status: ['', []],
      creationTimestamp: new UntypedFormGroup({
        begin: new UntypedFormControl(null, []),
        end: new UntypedFormControl(null, []),
      }),
      updateTimestamp: new UntypedFormGroup({
        begin: new UntypedFormControl(null, []),
        end: new UntypedFormControl(null, []),
      }),
    });
  }

  ngOnInit(): void {
    if (!(window.history.state.task && window.history.state.task.params)) {
      this.subject.pipe(debounceTime(500)).subscribe(() => {
        this.loadDocuments();
      });
    }
    this.getTableConfiguration();
    if (window.history.state.task && window.history.state.task.params) {
      this.loadDocument();
    } else {
      this.loadDocuments();
    }
    this.progressValues = ['OPEN', 'IN_PROGRESS', 'COMPLETED', 'CHECKED'];
    this.statusValues = ['PROCESSED', 'IN_REVIEW', 'ERROR', 'CHECKED'];
    this.filter.controls.creationTimestamp.valueChanges.subscribe((value) => {
      if (value.end) {
        this.creationTimestampRange = value;
      }
    });
    this.filter.controls.updateTimestamp.valueChanges.subscribe((value) => {
      if (value.end) {
        this.updateTimestampRange = value;
      }
    });
  }

  loadDocument() {
    const params = window.history.state.task.params;
    this.awInterfaceTransferProtocolService
      .getDocument(params.fileId)
      .subscribe(
        (document) => {
          this.totalCount = 1;
          const tempDocuments = [];
          tempDocuments.push(document);
          this.documents = this.dataSource.data = tempDocuments;
          this.loaderSpinner = false;
        },
        (error) => {
          this.loaderSpinner = false;
        }
      );
  }

  loadDocuments() {
    const searchString = this.filter.get('searchString').value;
    const progressFilter = this.filter.get('progress').value;
    const statusFilter = this.filter.get('status').value;

    const request: any = {
      page: this.paginator.pageIndex,
      pageSize: this.pageSize,
    };

    if (searchString) {
      request.searchString = searchString;
    }

    if (progressFilter) {
      request.progressFilter = progressFilter;
    }

    if (statusFilter) {
      request.statusFilter = statusFilter;
    }

    if (this.creationTimestampRange) {
      request.creationTimestampFilterBegin = moment(
        this.creationTimestampRange.begin
      ).format('YYYY-MM-DD');
      request.creationTimestampFilterEnd = moment(
        this.creationTimestampRange.end
      ).format('YYYY-MM-DD');
    }

    if (this.updateTimestampRange) {
      request.updateTimestampFilterBegin = moment(
        this.updateTimestampRange.begin
      ).format('YYYY-MM-DD');
      request.updateTimestampFilterEnd = moment(
        this.updateTimestampRange.end
      ).format('YYYY-MM-DD');
    }

    this.awInterfaceTransferProtocolService.filter(request).subscribe(
      (documents) => {
        this.totalCount = documents.total;
        if (window.history.state.task && window.history.state.task.params) {
          const params = window.history.state.task.params;
          this.totalCount = documents.total;
          const document = documents.content.filter(
            (data) => data.id === params.fileId
          );
          this.documents = this.dataSource.data = document;
        } else {
          this.documents = this.dataSource.data = documents.content;
        }
        this.loaderSpinner = false;
      },
      (error) => {
        this.loaderSpinner = false;
      }
    );
  }

  openProtocolDetailsModal(awFile) {
    this.matDialogService.open(AwItpProtocolDetailsModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        awFile,
      },
    });
  }

  openRequestResponseModal(isRequest, awFile) {
    this.matDialogService.open(AwItpProtocolRequestResponseModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        isRequest,
        awFile,
      },
    });
  }

  openXsdRequestModal(awFile) {
    this.matDialogService.open(AwXsdModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        awFile,
      },
    });
  }

  getStatusString(statusInfo) {
    if (statusInfo != null && statusInfo !== '') {
      return (
        'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.STATUS-VALUES.' +
        statusInfo
      );
    } else {
      return '';
    }
  }

  getProgressString(progressInfo) {
    if (progressInfo != null && progressInfo !== '') {
      return (
        'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.PROGRESS-VALUES.' +
        progressInfo
      );
    } else {
      return '';
    }
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    this.loadDocuments();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (this.dataSource.data) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    } else {
      return false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                awInterfaceTransferProtocolTableColumnsConf,
                awInterfaceTransferProtocolTableColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = awInterfaceTransferProtocolTableColumnsConf;
    this.displayedColumns = awInterfaceTransferProtocolTableColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  selectRow(element) {
    this.selection.select(element);
  }

  downloadAttachments() {
    const fileIds = [];
    let zipName = '';
    this.selection.selected.forEach((data) => {
      fileIds.push(data.id);
    });
    this.userService.getSettings().subscribe((userProfile) => {
      const userLanguageSetting = userProfile.profile.language;
      if (this.selection.selected.length === 1) {
        zipName = this.selection.selected[0].filename.slice(0, -4);
      } else {
        const zipNamePrefix =
          userLanguageSetting === 'ENGLISH'
            ? 'Interfacefiles_'
            : 'Schnittstellendateien_';
        zipName = zipNamePrefix + formatDate(new Date(), 'yyyyMMdd', 'de_DE');
      }
      this.awInterfaceTransferProtocolService
        .downloadRequestResponse(fileIds, zipName)
        .subscribe((data: Blob) => {
          const url = window.URL.createObjectURL(data);
          const anchor = document.createElement('a');
          anchor.download = zipName + '.zip';
          anchor.href = url;
          anchor.click();
        });
    });
  }

  resetProgressFilter() {
    this.filter.value.progress = undefined;
    this.filter.get('progress').patchValue(undefined);
  }

  resetStatusFilter() {
    this.filter.value.status = undefined;
    this.filter.get('status').patchValue(undefined);
  }

  clearCreationTimestampFilter() {
    this.filter.controls.creationTimestamp.get('begin').reset();
    this.filter.controls.creationTimestamp.get('end').reset();
    this.creationTimestampRange = undefined;
  }

  clearUpdateTimestampFilter() {
    this.filter.controls.updateTimestamp.get('begin').reset();
    this.filter.controls.updateTimestamp.get('end').reset();
    this.updateTimestampRange = undefined;
  }

  searchProtocol() {
    this.loaderSpinner = true;
    this.paginator.pageIndex = 0;
    this.subject.next(undefined);
  }
}
