import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ibanValidator } from '../../validators/ibanvalidator.directive';
import { bicValidator } from '../../validators/bicvalidator.directive';
import { Observable } from 'rxjs';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { AzureFile } from '../../models/azure-file';
import { UserService } from '../../services/user/user.service';
import { TransactionService } from '../../services/transaction/transaction.service';
import { FileType } from 'src/app/shared/models/file-type';
import { NotificationService } from '../../services/notification/notification.service';
import { FileService } from '../../services/file/file.service';
import { classificationsSelectionEnum } from 'src/app/in-memory-data/split-screen/split-transaction-data/enums/classifications-enum';
import { OpenItemService } from '../../services/open-item/open-item.service';
import { deductionReasonsSelectionEnum } from 'src/app/in-memory-data/split-screen/split-transaction-data/enums/deduction-reasons-enum ';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomErrorHandler } from '../../utils/error-handler/custom-error-handler';
import { amountPercentOptions } from 'src/app/in-memory-data/split-screen/split-transaction-data/enums/amount-percent-enum ';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-split-transactions',
  templateUrl: './split-transactions.component.html',
  styleUrls: ['./split-transactions.component.less'],
})
export class SplitScreenTransactionsComponent implements OnInit {
  panelOpenState = false;
  entityName: 'transactions-information';
  directDebitForm: UntypedFormGroup;
  classificationForm: UntypedFormGroup;
  conditionAndObjectionForm: UntypedFormGroup;
  deductionForm: UntypedFormGroup;
  dunningForm: UntypedFormGroup;
  platformForm: UntypedFormGroup;
  transactionMasterDataForm: UntypedFormGroup;
  commentsForm: UntypedFormGroup;
  attachmentsForm: UntypedFormGroup;
  multiple = true;
  commentsAndAttachmentsClicked = false;
  transaction = true;
  hasComment = false;
  uploadProgress = false;
  nonEditMode = true;
  patchObject: any = {};
  transactionObject;
  rowTransactionId;
  approvedObject: any = {};
  uploadedFiles: AzureFile[];
  comments: any[];
  attachments: [];
  @Input() transactionData: Observable<any>;
  @Input() clientData;
  @Input() debtorData;
  @Input() isMoreThanOneSelected;
  @Input() transactionCodes: any[] = [];
  @Input() animalWelfare;
  classifications = classificationsSelectionEnum;
  deductionReasons = deductionReasonsSelectionEnum;
  amountPercentOptions = amountPercentOptions;
  index = 0;
  objectionCreationDate;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private businessUnitService: BusinessUnitService,
    private userService: UserService,
    private transactionService: TransactionService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private openItemService: OpenItemService,
    private matDialogService: MatDialog,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private kcService: KeycloakService
  ) {
    this.directDebitFormBuilder();
    this.classificationFormBuilder();
    this.deductionsFormBuilder();
    this.dunningFormBuilder();
    this.platformFormBuilder();
    this.transactionMasterDataFormBuilder();
    this.commentsFormBuilder();
    this.attachmentsFormBuilder();
  }

  ngOnInit() {
    this.attachmentsForm
      .get('attachment')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => this.getUploadedFiles());
    this.getTransactionData();
    this.fileService.suscriptionEmitter =
      this.fileService.eventEmiterFunction.subscribe(() => {
        this.listAllFiles();
      });
  }

  patchForm(form: UntypedFormGroup, object) {
    form.patchValue(object);
  }

  getTransactionData() {
    this.transactionData.subscribe((data) => {
      this.transactionObject = data;
      this.getClassificationData();
      this.getDeductionData();
      this.getTransactionMasterData();
      this.getComments();
      this.listAllFiles();
    });
  }

  directDebitFormBuilder() {
    this.directDebitForm = this.formBuilder.group({
      directDebitDate: ['', Validators.required],
      directDebitType: ['', Validators.required],
      bic: ['', [bicValidator()]],
      creditorId: ['', Validators.required],
      iban: ['', [ibanValidator()]],
      accountOwner: ['', Validators.required],
      mandateReference: ['', Validators.required],
      mandateType: ['', Validators.required],
      remittanceInformation: ['', Validators.required],
      paymentAmount: ['', Validators.required],
      sequenceType: ['', Validators.required],
    });
  }

  classificationFormBuilder() {
    this.classificationForm = this.formBuilder.group({
      transactionPayoutActual: ['', Validators.required],
      transactionPayoutActualDate: ['', Validators.required],
      financedReceivables: ['', Validators.required],
      classification: ['', Validators.required],
      nonFinancedReceivables: ['', Validators.required],
      verificationCode: ['', Validators.required],
      tciAmount: ['', Validators.required],
      tciName: ['', Validators.required],
      tciType: ['', Validators.required],
    });
  }

  getClassificationData() {
    this.patchObject.financedReceivables =
      this.transactionObject.financedAmount;
    this.patchObject.nonFinancedReceivables =
      this.transactionObject.nonFinancedAmount;
    this.patchObject.classification = this.transactionObject.classification;
    this.approvedObject = Object.assign({}, this.patchObject);
    this.patchForm(this.classificationForm, this.patchObject);
  }

  saveClassification() {
    const selectedClassification =
      this.classificationForm.get('classification').value;
    const selectedClassificationName = selectedClassification;
    const transactionId = this.transactionObject.id;
    this.openItemService
      .updateClassification(transactionId, selectedClassificationName)
      .subscribe(() => {
        this.transactionObject.classification = selectedClassification;
        this.getClassificationData();
        this.cancelEditing();
      });
  }

  getClassificationNameTranslation(classificationName): string {
    let classificationNameTranslation = '';
    this.classifications.forEach((element) => {
      if (element.name === classificationName) {
        classificationNameTranslation = element.translateName;
      }
    });
    return classificationNameTranslation;
  }

  get formControls() {
    return this.deductionForm.controls;
  }

  get deductionsObject() {
    return this.formControls.deductions as UntypedFormArray;
  }

  deductionsFormBuilder() {
    this.deductionForm = this.formBuilder.group({
      deductions: new UntypedFormArray([]),
    });
  }

  changeAmountPercent(object) {
    if (object.get('percentOrAmount').value === 1) {
      object.get('amount').disable();
      object.get('percent').enable();
    } else {
      object.get('percent').disable();
      object.get('amount').enable();
    }
  }

  getDeduction(field: string, object: any) {
    if (object && object.get('id').value) {
      if (field === 'percentOrAmount') {
        if (object.get('amount').value) {
          return 2;
        } else {
          return 1;
        }
      } else {
        return this.transactionObject.deductions.find(
          (item) => item.id === object.get('id').value
        )[field];
      }
    } else {
      return;
    }
  }

  getDeductionData() {
    if (this.transactionObject.deductions) {
      this.transactionObject.deductions.forEach((deduction) => {
        this.addNewDeduction(deduction);
      });
    }
  }

  addNewDeduction(object?) {
    let result;

    if (object) {
      result = object.percentage ? 1 : 2;
    }

    this.deductionsObject.push(
      this.formBuilder.group({
        reason: [
          { value: object ? object.reasonKey : '', disabled: false },
          Validators.required,
        ],
        reference: [{ value: object ? object.reference : '', disabled: false }],
        percent: [
          { value: object ? object.percentage : '', disabled: false },
          Validators.required,
        ],
        amount: [
          { value: object ? object.amount : '', disabled: false },
          Validators.required,
        ],
        validityDate: [
          {
            value: object ? object.validityDate : '',
            disabled: false,
          },
          Validators.required,
        ],
        additionalInformation: [
          {
            value: object ? object.additionalInformation : '',
            disabled: false,
          },
        ],
        percentOrAmount: [{ value: object ? result : null, disabled: false }],
        id: [{ value: object ? object.id : null, disabled: false }],
        index: [this.index],
      })
    );
    this.index++;
  }

  saveConditionAndObjection() {
    const deductions: any[] = [];
    this.deductionsObject.controls.forEach((element) => {
      if (!element.pristine) {
        deductions.push(element.value);
      }
    });

    deductions.forEach((deduction) => {
      if (typeof deduction.validityDate !== 'string') {
        deduction.validityDate = deduction.validityDate.format('YYYY-MM-DD');
        deduction.validityDate = new Date(deduction.validityDate);
      } else {
        deduction.validityDate = new Date(deduction.validityDate);
      }
    });

    this.openItemService
      .createOrUpdateDeduction(this.transactionObject.id, deductions)
      .subscribe(
        (_) => {
          this.getCreatedDeductions(this.transactionObject.id);
          this.deductionsObject.clear();
          this.cancelEditing();
        },
        (error) => this.errorHandler(error)
      );
  }

  getCreatedDeductions(openItemId) {
    this.openItemService
      .listDeductionsForOpenItem(openItemId)
      .subscribe((values: any) => {
        this.transactionObject.deductions = values;
        this.transactionObject.deductions.forEach((obj) => {
          obj.reasonKey = obj.reason;
          obj.percentage = obj.percent;
        });
        this.openItemService.updateSharedSplitScreenData(
          this.transactionObject
        );
        if (this.router.url.includes('/tasks')) {
          this.getDeductionData();
        }
      });
  }

  deleteDeduction(object) {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.DELETE-ENTRIES';
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        if (!object.value.id) {
          this.deductionsObject.removeAt(object.controls.index);
          if (this.deductionsObject.controls.length === 0) {
            this.deductionsObject.clear();
            this.deductionForm.reset();
            this.index = 0;
          }
        } else {
          this.openItemService.deleteDeduction(object.value.id).subscribe(
            () => {
              this.deductionsObject.removeAt(object.controls.index);
              if (this.deductionsObject.controls.length === 0) {
                this.deductionsObject.clear();
                this.deductionForm.reset();
                this.index = 0;
              }
            },
            (error) => this.errorHandler(error)
          );
        }
      }
    });
  }

  cancelEditingObjectionAndDeduction() {
    this.nonEditMode = true;
    const element = document.getElementsByClassName('object-row');
    const array = Array.prototype.slice.call(element);

    for (const item of array) {
      item.setAttribute('class', 'object-row');
    }
    this.deductionForm.reset();
    this.conditionAndObjectionForm.reset();
    this.deductionsObject.clear();
    this.getDeductionData();
    this.index = 0;
  }

  getFormGroup(element) {
    let formGroup: any = null;
    formGroup = this.deductionsObject.controls.find(
      (item) => item.get('index').value === element.index
    );
    return formGroup;
  }

  validateObjectionAmount() {
    if (
      this.conditionAndObjectionForm.get('objectionAmount').value >
      this.transactionObject.amount
    ) {
      this.notificationService.showToast(
        'NOTIFICATION.OBJECTION.OBJECTION-CREATE',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
      this.conditionAndObjectionForm
        .get('objectionAmount')
        .patchValue(this.transactionObject.amount.toString().replace('.', ','));
    }
  }

  validateDeductionAmount(object) {
    if (object.get('amount').value > this.transactionObject.openAmount) {
      this.notificationService.showToast(
        'NOTIFICATION.DEDUCTION.DEDUCTION-AMOUNT',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
      object
        .get('amount')
        .patchValue(
          this.transactionObject.openAmount.toString().replace('.', ',')
        );
    }
  }

  validateDeductionInPercent(object) {
    if (
      object.get('percent').value.length === 2 ||
      String(object.get('percent').value).length === 2
    ) {
      const dialog = this.matDialogService.open(
        ConfirmationPromptModalComponent
      );
      dialog.componentInstance.messageKey =
        'NOTIFICATION.DEDUCTION.IN-PERCENT-VALUE';
      dialog.afterClosed().subscribe((data) => {
        if (!data) {
          object.get('percent').setValue('');
        }
      });
    }
  }

  get getDeductionReason() {
    return this.findDeductionReason.bind(this);
  }

  findDeductionReason(item) {
    const result = this.deductionReasons.find((reason) => reason.code === item);
    if (result) {
      return result.translateName;
    }
  }

  dunningFormBuilder() {
    this.dunningForm = this.formBuilder.group({
      dateReminders: new UntypedFormArray([]),
      dunningBlock: ['', Validators.required],
      dunningBlockDate: ['', Validators.required],
      dunningLevel: ['', Validators.required],
      debtCollectonAgency: ['', Validators.required],
      debtCollectonStatus: ['', Validators.required],
      debtCollectonTransferDate: ['', Validators.required],
    });
    this.addDateReminder();
  }

  get getDunningFormControls() {
    return this.dunningForm.controls;
  }

  get getDateReminders() {
    return this.getDunningFormControls.dateReminders as UntypedFormArray;
  }

  addDateReminder() {
    this.getDateReminders.push(
      this.formBuilder.group({
        dateReminder: ['', Validators.required],
      })
    );
  }

  platformFormBuilder() {
    this.platformForm = this.formBuilder.group({
      processingFrom: ['', Validators.required],
      processingEnd: ['', Validators.required],
      centralSettlementBonusRateBFS: ['', Validators.required],
      centralSettlementBonusRateClient: ['', Validators.required],
      centralSettlementBonusVAT: ['', Validators.required],
      centralSettlementBonusRatePlatform: ['', Validators.required],
      centralSettlementBonusRateDebtor: ['', Validators.required],
      centralSettlementFeeRateBFS: ['', Validators.required],
      centralSettlementFeeRateClient: ['', Validators.required],
      centralSettlementFeeRateDebtor: ['', Validators.required],
      centralSettlementFeeRateVAT: ['', Validators.required],
      centralSettlementFeeRatePlatform: ['', Validators.required],
    });
  }

  transactionMasterDataFormBuilder() {
    this.transactionMasterDataForm = this.formBuilder.group({
      statusInformation: ['', Validators.required],
      transactionCode: ['', Validators.required],
      transactionCodeName: ['', Validators.required],
      documentDate: ['', Validators.required],
      openAmount: ['', Validators.required],
      clientId: ['', Validators.required],
      debtorId: ['', Validators.required],
      dateOfEntry: ['', Validators.required],
      documentationId: ['', Validators.required],
      clientsDebtorId: ['', Validators.required],
      dueDate: ['', Validators.required],
      branchId: ['', Validators.required],
      businessUnitId: ['', Validators.required],
      lastChangeDate: ['', Validators.required],
      vatAmount: ['', Validators.required],
      vatCode: ['', Validators.required],
      vatRate: ['', Validators.required],
      transactionId: ['', Validators.required],
      referenceDocumentation: ['', Validators.required],
      referenceText: ['', Validators.required],
      originalAccount: ['', Validators.required],
      allocationNumber: ['', Validators.required],
      currencyRateDate: ['', Validators.required],
      currency: ['', Validators.required],
      currencyAmount: ['', Validators.required],
      currencyRate: ['', Validators.required],
      repurchasingDate: ['', Validators.required],
      repurchasingPeriod: ['', Validators.required],
      repurchasingReason: ['', Validators.required],
      invoiceAmount: ['', Validators.required],
      allocationTimestamp: ['', Validators.required],
      allocationAmount: ['', Validators.required],
    });
  }

  getTransactionMasterData() {
    const currentBu = this.businessUnitService.getCurrentBusinessUnit();
    this.patchObject.transactionCode = this.transactionObject.bookingCode;
    this.patchObject.transactionCodeName =
      this.transactionObject.bookingCodeName;
    this.patchObject.clientId = this.transactionObject.clientVisibleId;
    this.patchObject.debtorId = this.transactionObject.debtorVisibleId;
    this.patchObject.documentationId = this.transactionObject.clientOpenItemId;
    this.patchObject.clientsDebtorId = this.transactionObject.debtorId;
    this.patchObject.dueDate = this.transactionObject.dueDate;
    this.patchObject.dateOfEntry = this.transactionObject.creationDate;
    this.patchObject.branchId = this.transactionObject.branchId;
    this.patchObject.businessUnitId = currentBu;
    this.patchObject.vatAmount = this.transactionObject.vatAmount;
    this.patchObject.vatCode = this.transactionObject.vatCode;
    this.patchObject.vatRate = this.transactionObject.vatPercent;
    this.patchObject.transactionId = this.transactionObject.id;
    this.patchObject.currency = this.transactionObject.currencyCode;
    this.patchObject.invoiceAmount = this.transactionObject.amount;
    this.patchObject.allocationAmount = this.transactionObject.allocatedAmount;
    this.patchObject.openAmount = this.transactionObject.openAmount;
    this.patchObject.openItemDate = this.transactionObject.openItemDate;
    this.approvedObject = Object.assign({}, this.patchObject);
    this.patchForm(this.transactionMasterDataForm, this.patchObject);
  }

  commentsFormBuilder() {
    this.commentsForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  attachmentsFormBuilder() {
    this.attachmentsForm = this.formBuilder.group({
      attachment: [''],
    });
  }

  getComments() {
    this.comments = [];
    if (this.isMoreThanOneSelected) {
      return;
    }
    this.getCommentsFromBackend();
  }

  clear() {
    this.commentsForm.get('comment').patchValue('');
    this.resetCommentsForm();
  }

  saveComments() {
    this.getSendComments();
    this.commentsForm.get('comment').patchValue('');
    this.resetCommentsForm();
  }

  resetCommentsForm() {
    this.commentsFormBuilder();
  }

  getSendComments() {
    const content = {
      content: this.commentsForm.get('comment').value,
      transactionIds: this.checkAndGetTransactionIds(),
    };
    this.transactionService
      .sendCommentsForMultipleTransactions(content)
      .subscribe(() =>
        this.isMoreThanOneSelected
          ? this.getCommentsFromForm(content)
          : this.getCommentsFromBackend()
      );
  }

  getCommentsFromBackend() {
    this.transactionService
      .getMultipleTransactionComments(this.checkAndGetTransactionIds())
      .subscribe((comments: any) => {
        this.rowTransactionId = this.transactionObject.id;
        this.comments = comments;
      });
  }

  getCommentsFromForm(content) {
    this.comments.push({
      content: content.content,
      date: new Date(),
      userId: this.kcService.getUsername(),
    });
  }

  getUploadedFiles() {
    const attachments = this.attachmentsForm.get('attachment').value;
    const atLeastOneExists = attachments.some((file) =>
      this.checkIfExistingFile(file.name, this.attachments)
    );
    if (atLeastOneExists) {
      this.notificationService.showToast(
        'NOTIFICATION.TRANSACTION-HISTORY.FILE-EXISTS',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    } else {
      this.uploadFiles(attachments, this.checkAndGetTransactionIds());
    }
  }

  checkAndGetTransactionIds() {
    return this.transactionObject.selectedTransactions &&
      this.isMoreThanOneSelected
      ? this.transactionObject.selectedTransactions.map((t) => t.id)
      : [this.transactionObject.id];
  }

  checkIfExistingFile(nameKey, fileList) {
    if (!fileList) {
      return false;
    }

    for (const file of fileList) {
      if (file.fileName === nameKey) {
        return true;
      } else {
        return false;
      }
    }
  }

  async uploadFiles(attachments: any, transactionIds: any) {
    this.uploadProgress = true;
    const formData: FormData = new FormData();
    attachments.forEach((a) => formData.append('files', a));
    formData.append('fileType', FileType.transaction);
    formData.append('ids', transactionIds);
    try {
      await this.fileService
        .uploadFileBatchForMultipleIds(formData)
        .toPromise();
    } catch (e) {
      this.notificationService.showToast(
        'NOTIFICATION.TRANSACTION-HISTORY.FILE-EXISTS',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    } finally {
      this.listAllFiles();
    }
  }
  listAllFiles() {
    this.uploadProgress = true;
    this.fileService
      .listAllFilesForMultipleIds(
        FileType.transaction,
        this.checkAndGetTransactionIds()
      )
      .subscribe((data: any) => {
        this.attachments = data;
        this.uploadProgress = false;
      });
  }

  makeEditable() {
    this.nonEditMode = false;
    const element = document.getElementsByClassName('object-row');

    const array = Array.prototype.slice.call(element);

    for (const item of array) {
      item.setAttribute('class', 'object-row dimmed');
    }
  }

  cancelEditing() {
    this.nonEditMode = true;
    const element = document.getElementsByClassName('object-row');
    const array = Array.prototype.slice.call(element);

    for (const item of array) {
      item.setAttribute('class', 'object-row');
    }
  }

  private errorHandler(error) {
    if (error.error) {
      this.customErrorHandler.handleError(error.error, this.transactionData);
    }
  }
}
