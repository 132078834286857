export const nonGermanyReferenceAllowCountriesColumnsConf: any[] = [
  {
    name: 'country',
    index: 0,
    checked: true,
    translateName: 'NON-GERMANY-REFERENCE-ALLOW-LIST.COUNTRY',
    locked: true,
  },
  {
    name: 'countryCode',
    index: 1,
    checked: true,
    translateName: 'NON-GERMANY-REFERENCE-ALLOW-LIST.ISO-CODE',
    locked: true,
  },
  {
    name: 'decisionCompliance',
    index: 2,
    checked: true,
    translateName: 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE',
    locked: true,
  },
  {
    name: 'decisionComplianceReason',
    index: 3,
    checked: true,
    translateName:
      'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE-REASON',
    locked: true,
  },
  {
    name: 'decisionRisk',
    index: 4,
    checked: true,
    translateName: 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK',
    locked: true,
  },
  {
    name: 'decisionRiskReason',
    index: 5,
    checked: true,
    translateName: 'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-RISK-REASON',
    locked: true,
  },
  {
    version: '1.2',
  },
];
