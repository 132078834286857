import {
  AfterViewInit,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { productEnum } from 'src/app/in-memory-data/business-unit/enums/product-enum';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';

@Directive({
  selector: '[hasBUProductPermission]',
})
export class HasBuProductPermissionDirective
  implements AfterViewInit, OnDestroy
{
  @Input('hasBUProductPermission')
  buProduct: string | string[];
  private subscription: Subscription;
  userBusinessUnits: any = [];

  buProducts = productEnum;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private businessUnitService: BusinessUnitService
  ) {}

  ngAfterViewInit() {
    this.subscription = this.hasBUProductPermission(this.buProduct).subscribe(
      (hasPermission) => {
        if (hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }

  private hasBUProductPermission(
    buProduct: string | string[]
  ): Observable<boolean> {
    let hasBUProductPermission = false;
    let allBUs: any;
    if (this.userBusinessUnits && this.userBusinessUnits.length > 0) {
      allBUs = this.userBusinessUnits;
    } else if (
      this.businessUnitService.getAllBusinessUnitsLocal() &&
      this.businessUnitService.getAllBusinessUnitsLocal().length > 0
    ) {
      allBUs = this.businessUnitService.getAllBusinessUnitsLocal();
    }

    if (allBUs && allBUs.length > 0) {
      if (buProduct instanceof Array) {
        buProduct.some((prodParam) => {
          if (
            allBUs.find(
              (bu) =>
                bu.id ===
                Number(this.businessUnitService.getCurrentBusinessUnit())
            ).product ===
            this.buProducts.find((prod) => prod.value === prodParam).code
          ) {
            hasBUProductPermission = true;
          } else {
            hasBUProductPermission = false;
          }
          return hasBUProductPermission;
        });
      } else {
        if (
          allBUs.find(
            (bu) =>
              bu.id ===
              Number(this.businessUnitService.getCurrentBusinessUnit())
          ).product ===
          this.buProducts.find((prod) => prod.value === buProduct).code
        ) {
          hasBUProductPermission = true;
        } else {
          hasBUProductPermission = false;
        }
      }
    }

    return of(hasBUProductPermission);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
