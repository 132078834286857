import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskManagementDetailsComponent } from '../risk-management/risk-management-details/risk-management-details.component';
import { RiskManagementRoutingModule } from './risk-management-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { PrescoringTaskComponent } from './prescoring-task/prescoring-task.component';
import { RiskCheckTaskComponent } from './risk-check-task/risk-check-task.component';
import { RatingBarComponent } from './rating-bar/rating-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        RiskManagementDetailsComponent,
        PrescoringTaskComponent,
        RiskCheckTaskComponent,
        RatingBarComponent,
    ],
    imports: [
        CommonModule,
        RiskManagementRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatDialogModule,
        MatPaginatorModule,
        MatButtonModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        MatMenuModule,
        MatIconModule,
        MatCheckboxModule,
        MatTabsModule,
        NgSelectModule,
        BackButtonModule,
        MatProgressBarModule,
        MatSliderModule,
        FlexLayoutModule,
    ]
})
export class RiskManagementModule {}
