<span class="dynamic-loader-table" *ngIf="loaderSpinner">
  <mat-spinner [diameter]="50"></mat-spinner>
</span>
<app-default-table
  *ngIf="dataLoaded && !loaderSpinner"
  [entityName]="entityName"
  [configurationKey]="configurationKey"
  [columns]="columns"
  [displayedColumns]="displayedColumns"
  [originalColumns]="columns"
  [originalDisplayedColumns]="displayedColumns"
  [tableDataSource]="transactionData"
  [threeDots]="hasThreeDots"
  [listOfActionButtons]="listOfActionButtons"
  [listOfActionIcons]="listOfActionIcons"
  [getCustomClass]="getCustomClass"
  (filterEvent)="filter($event)"
  [distinctValues]="getDistinctValues"
  [getCustomColumnClass]="getCustomColumnClass"
  [hasNegatedValue]="getNegatedValue"
  [expanded]="expanded"
  [component]="expandedComponent"
  [selectionAction]="selectionAction"
></app-default-table>

<ng-container>
  <mat-drawer-container
    [hasBackdrop]="true"
    (backdropClick)="openDrawer = false; drawer.toggle()"
    (keydown.escape)="$event.stopPropagation()"
  >
    <mat-drawer #drawer mode="over" position="end">
      <button
        type="button"
        id="{{ entityName }}-split-transactions-close-button"
        mat-button
        (click)="openDrawer = false; drawer.close(); closeSplitScreen()"
        class="close-split"
      >
        <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
      </button>
      <ng-template [ngIf]="openDrawer">
        <app-split-transactions
          *ngIf="hasTransaction"
          [transactionData]="selectedTransactionData"
          [transactionCodes]="transactionCodes"
          [isMoreThanOneSelected]="selectedRows.length > 1"
        >
        </app-split-transactions>
      </ng-template>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
