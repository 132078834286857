import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-dashboard-welcome',
  templateUrl: './dashboard-welcome.component.html',
  styleUrls: [
    './dashboard-welcome.component.less',
    './dashboard-welcome-sm.component.less',
  ],
})
export class DashboardWelcomeComponent implements OnInit {
  errorDate;
  hourStart;
  hourEnd;
  dateFormat;

  warningDisplayed = true;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();

    this.errorDate = new Date('July 23, 2021 23:15:30');
    this.hourStart = new Date('July 23, 2021 18:00:00');
    this.hourEnd = new Date('July 23, 2021 19:00:00');
  }
}
