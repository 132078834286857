import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CountryPickerService } from 'src/app/shared/services/countries/country-picker.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-risk-management-details',
  templateUrl: './risk-management-details.component.html',
  styleUrls: ['./risk-management-details.component.less'],
})
export class RiskManagementDetailsComponent implements OnInit {
  data = [
    {
      name: 'Creditreform',
      date: '02.01.2020',
      singleRating: 'B',
      iconName: 'icon-ic_credirreform',
    },
    {
      name: 'Euler Hermes',
      date: '03.02.2020',
      singleRating: 'N',
      iconName: 'icon-ic_eulerhermes',
    },
    {
      name: 'Finanzinformationen',
      date: '04.03.2020',
      singleRating: 'A',
      iconName: 'icon-ic_financeinformation',
    },
  ];
  pdfData = [
    {
      date: '11.02.2020',
      pdfNumber: 1234,
    },
  ];
  grade = 'CC';
  displayedColumns: string[] = [
    'ratingModule',
    'dateLastRating',
    'singleRating',
  ];
  pdfDisplayedColumns: string[] = ['date', 'pdfNumber'];
  dataSource = new MatTableDataSource<any>();
  client: ClientPrescore;

  credit: CamData;
  euler: EulerData;
  pdfDataSource = new MatTableDataSource<any>();

  constructor(
    protected countryPicker: CountryPickerService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.client = {
      name: 'Client test',
      lastRating: '01.03.2020',
      totalLimit: '1.000 EUR',
      address: {
        street: 'That Street',
        number: '123',
        zipCode: '10124',
        city: 'Berlin',
        country: 'Germany',
      },
    };
    this.credit = {
      crefoId: '12356',
      type: 'Type 1',
      date: '11.01.2020',
      supplementaryPeriod: '30.01.2021',
      monitoring: 'Ja',
      index: '157',
      paymentMethod: 'Payment method Z',
      probabilityDefault: '1.4',
      credit: 'Credit test',
      creditJudgement: 'B',
      expertise: 'EXPERTISE test XYZ',
      creditWorthiness: 'Credit Worthiness XYZ',
      creditLimit: '100.000 EUR',
      pdf: 'PDF',
    };
    this.euler = {
      ehId: '36693',
      date: '10.04.2019',
      currentClientLimit: '0',
      limitation: '',
      additionalLimit: '',
      approvalSum: '88.000 EUR',
      decisionText: 'Administrative withdraw',
      ehRating: '',
      tciInfo: '',
      group: 'Group 5',
      camRating: 'N',
      expertise: 'Rating N; Expertise test XYZ',
      pdf: 'PDF',
    };
    this.dataSource.data = this.data;
    this.pdfDataSource.data = this.pdfData;
  }
}

export interface ClientPrescore {
  name: string;
  lastRating: string;
  totalLimit: string;
  address: {
    street: string;
    number: string;
    zipCode: string;
    city: string;
    country: string;
  };
}

export interface CamData {
  crefoId: string;
  type: string;
  date: string;
  supplementaryPeriod: string;
  monitoring: string;
  index: string;
  paymentMethod: string;
  probabilityDefault: string;
  credit: string;
  creditJudgement: string;
  expertise: string;
  creditWorthiness: string;
  creditLimit: string;
  pdf: string;
}

export interface EulerData {
  ehId: string;
  date: string;
  currentClientLimit: string;
  limitation: string;
  additionalLimit: string;
  approvalSum: string;
  decisionText: string;
  ehRating: string;
  tciInfo: string;
  group: string;
  camRating: string;
  expertise: string;
  pdf: string;
}
