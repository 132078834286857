import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { productionOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/filterProductionOptions';
import { statusOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/statusOptions';
import { transferOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/transferOptions';
import { measureUnitOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods/enum/measureUnitOptions';
import { quantityGoodsColumns } from 'src/app/in-memory-data/animal-welfare/quantity-goods/table-columns';
import { quantityGoodsColumnsConf } from 'src/app/in-memory-data/animal-welfare/quantity-goods/table-columns-configuration';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { TransferQuantityGoodService } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/transfer-quantity-good.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import moment from 'moment';
import { QuantityGoodDeleteResponse } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/quantity-good-delete-response';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';

@Component({
  selector: 'app-transfer-quantity-goods-list',
  templateUrl: './transfer-quantity-goods-list.component.html',
  styleUrls: [
    './transfer-quantity-goods-list.component.less',
    './transfer-quantity-goods-list-md.component.less',
  ],
})
export class TransferQuantityGoodsListComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  entityName = 'quantity-goods';
  configurationKey = 'quantity_goods_list_table';
  columns = quantityGoodsColumnsConf;
  displayedColumns = quantityGoodsColumns;
  checkSumForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  filteredProductionTypes = productionOptions;
  productionTypes = productionOptions;
  measureUnitOptions: any[] = measureUnitOptions;
  transferOptions = transferOptions;
  statusOptions = statusOptions;
  originalData: any[] = [];
  dateFormat: any;
  protected onDestroy = new Subject<void>();
  expandedTransaction: any;
  allQuantityGoods: any;
  totalCount;
  pageSize = 20;
  transferQuantityGoodsList = {};
  sharedSelectedRows: any[] = [];
  configs: any = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
  };
  listOfActionButtons: TableActionButtonsConfiguration[] = [
    {
      name: 'edit',
      permission: 'aw.g.u',
      icon: 'edit',
      translationKey: 'GENERAL.EDIT',
      function: (param) => {
        this.editSelectedRows();
      },
      selectionType: DefaultTableSelectionType.single,
      specialDisable: () => {
        return false;
      },
    },
    {
      name: 'remove',
      permission: 'aw.g.d',
      icon: 'delete_outline',
      translationKey: 'GENERAL.REMOVE',
      function: (param) => {
        this.deleteSelectedRows();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return false;
      },
    },
  ];

  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private transferQuantityGoodService: TransferQuantityGoodService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private tablesService: TablesService
  ) {
    this.filterFormBuilder();
    this.checkSumFormBuilder();
  }

  ngOnInit(): void {
    this.transferQuantityGoodService.resetFilters();
    this.dateFormat = this.userService.getDateFormat();
    this.getSelectedRows();
    this.listenToLanguageChange();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  getThousandSeperator() {
    if (localStorage.currentSelectedLanguage === 'en') {
      return ',';
    } else {
      return '.';
    }
  }

  listenToLanguageChange() {
    this.translationService.currentSelectedLanguage.subscribe((data) => {
      if (data) {
        this.getThousandSeperator();
      }
    });
  }

  loadData(customConfig?) {
    if (!customConfig) {
      customConfig = this.configs;
    }
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    this.transferQuantityGoodService.filterList(customConfig).subscribe(
      (data: any) => {
        this.transferQuantityGoodsList = data;
        this.totalCount = data.total;
        this.allQuantityGoods = data.content;
        this.originalData = this.allQuantityGoods;
        this.checkSumForm.get('checksum').patchValue(data.checksum);
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      },
      (error) => {
        if (this.defaultTableComponent) {
          this.defaultTableComponent.setLoaderSpinner(false);
        }
      }
    );
  }

  findFromArray(masterDatas, id) {
    return masterDatas.find((masterData) => masterData.id === id);
  }

  async mapMasterData() {
    const guidArray = this.allQuantityGoods.map((element) => element.userRole);
    if (guidArray.length > 50) {
      const iterations = Math.trunc(guidArray.length / 50);
      for (let i = 0; i < iterations; i++) {
        this.transferQuantityGoodService
          .findAllMasterDataByID(guidArray.splice(0, 50))
          .subscribe((result) => {
            this.allQuantityGoods.forEach((quantityGood) => {
              const c = this.findFromArray(result, quantityGood.userRole);
              let qsid;
              if (c !== undefined) {
                qsid = c.qsid;

                Object.assign(quantityGood, { qsid });
              }
            });
          });
      }
    } else {
      this.transferQuantityGoodService
        .findAllMasterDataByID(guidArray)
        .subscribe((result) => {
          this.allQuantityGoods = this.allQuantityGoods.map((data) => {
            const c = this.findFromArray(result, data.userRole);
            let qsid;
            if (c !== undefined) {
              qsid = c.qsid;
            }
            Object.assign(data, { qsid });
            return data;
          });
        });
    }
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(false);
    }
  }

  getQSID(element) {
    this.transferQuantityGoodService
      .findMasterDataByID(element.userRole)
      .subscribe((data: any) => {
        if (data) {
          element.qsid = data.qsid;
        }
      });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      locationNumber: [{ value: null, disabled: false }],
      productionType: [{ value: null, disabled: false }],
      productionTypeSearch: [{ value: null, disabled: false }],
      createdOn: [{ value: null, disabled: false }],
      submissionDate: new UntypedFormGroup({
        startDate: new UntypedFormControl(null, []),
        endDate: new UntypedFormControl(null, []),
        fieldName: new UntypedFormControl('submissionDate'),
      }),
      qsid: [{ value: null, disabled: false }],
      measureUnit: [{ value: null, disabled: false }],
      amountGood: [{ value: null, disabled: false }],
      transfer: [{ value: null, disabled: false }],
      status: [{ value: null, disabled: false }],
      createdBy: [{ value: null, disabled: false }],
      approvedBy: [{ value: null, disabled: false }],
      approvedOn: [{ value: null, disabled: false }],
      comment: [{ value: null, disabled: false }],
    });
  }

  clearPeriodFilter() {
    this.filterForm.controls.submissionDate.get('startDate').reset();
    this.filterForm.controls.submissionDate.get('endDate').reset();
  }

  checkSumFormBuilder() {
    this.checkSumForm = this.formBuilder.group({
      checksum: [{ value: null, disabled: true }],
    });
  }

  removeRepeatedProductionTypes(data) {
    return data.filter(
      (v, i, a) => a.findIndex((t) => t.value === v.value) === i
    );
  }

  goToEditView() {
    this.router.navigate(['/quantityGoods/create']);
  }

  editSelectedRows() {
    const checkNonBillingRows = this.checkNonBillingSelectedRows();

    if (checkNonBillingRows) {
      this.router.navigateByUrl(
        '/quantityGoods/create/' + this.sharedSelectedRows[0].id,
        {
          state: { reports: this.sharedSelectedRows },
        }
      );
    } else {
      this.notificationService.showToast(
        'ANIMAL-WELFARE.QUANTITY-GOODS.EDIT-DELETE-BILLING-ERROR',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  checkNonBillingSelectedRows() {
    let isValid = true;

    this.sharedSelectedRows.forEach((item) => {
      if (item.status === 'SETTLED') {
        isValid = false;
      }
    });

    return isValid;
  }

  getSelectedRows() {
    this.tablesService.currentSharedSelectedRows.subscribe((param) => {
      this.sharedSelectedRows = [];
      this.sharedSelectedRows = param;
    });
  }

  deleteSelectedRows() {
    const checkNonBillingRows = this.checkNonBillingSelectedRows();
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    if (checkNonBillingRows) {
      if (this.sharedSelectedRows.length > 1) {
        dialog.componentInstance.messageKey =
          'NOTIFICATION.CONFIRM-DELETE-MULTIPLE-ENTRIES';
        dialog.componentInstance.messageParams['param'] =
          this.sharedSelectedRows.length;
      } else {
        dialog.componentInstance.messageKey =
          'NOTIFICATION.CONFIRM-DELETE-ENTRY';
      }
      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.showCommentModal();
        }
      });
    } else {
      this.notificationService.showToast(
        'ANIMAL-WELFARE.QUANTITY-GOODS.EDIT-DELETE-BILLING-ERROR',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  showCommentModal() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        let deletedQuantityGoods = [];
        deletedQuantityGoods = this.sharedSelectedRows;

        deletedQuantityGoods.forEach((qg) => {
          Object.assign(qg, {
            amount: qg.amountGood,
          });
        });

        this.transferQuantityGoodService
          .deleteQuantityGood(deletedQuantityGoods, result.comment)
          .pipe(first())
          .subscribe((response: QuantityGoodDeleteResponse) => {
            if (response.directDelete) {
              this.loadData();
            }
            this.notificationService.showToast(
              response.directDelete
                ? 'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT'
                : 'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {
                data: this.translateService.instant(
                  'ANIMAL-WELFARE.QUANTITY-GOODS.TITLE'
                ),
              }
            );
            setTimeout(() => {
              this.router.navigate(['/quantityGoods']);
            }, 3000);
          });
      }
    });
  }

  hasExpandableSection(row) {
    return !!(row.copyId || row.reversalId);
  }

  searchData() {
    const value = Object.assign({}, this.filterForm.value);
    if (value.submissionDate && value.submissionDate.startDate != null) {
      value.submissionDate.startDate = moment(value.submissionDate.startDate)
        .toDate()
        .setHours(0, 0, 0, 0);
      value.submissionDate.startDate = moment(
        value.submissionDate.startDate
      ).format('YYYY-MM-DD');
    }
    if (value.submissionDate && value.submissionDate.endDate != null) {
      value.submissionDate.endDate = moment(value.submissionDate.endDate)
        .toDate()
        .setHours(0, 0, 0, 0);

      value.submissionDate.endDate = moment(
        value.submissionDate.endDate
      ).format('YYYY-MM-DD');
    }
    this.transferQuantityGoodService.configObj['submissionDate'] =
      JSON.stringify(value.submissionDate);
    value.submissionDate = null;
    delete value.productionTypeSearch;
    this.transferQuantityGoodService.configObj['searchFilters'] =
      JSON.stringify(value);
    this.loadData();
  }

  clearOptions(field) {
    this.filterForm.get(field).patchValue(null);
  }

  showRemoveOption(field) {
    let result = false;
    if (this.filterForm.get(field).value) {
      result = true;
    }

    return result;
  }

  filter(event) {
    if (event.sortOrder) {
      event.sortDirection = event.sortOrder;
    }
    if (event.choice && event.direction) {
      this.configs['sortField'] = event.choice;
      this.configs['sortDirection'] = event.direction;
    }
    if (event.filteredValues) {
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
    }
    this.loadData();
  }

  getDistinctValues = (choice, column) => {
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    return this.transferQuantityGoodService.getDistinctValues(
      filterOptions,
      choice
    );
  };
}
