import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report/report.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
  declarations: [ReportComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    FormElementsModule,
    ReactiveFormsModule,
    PowerBIEmbedModule,
  ],
})
export class ReportingModule {}
