<div class="dialog-content">
  <div mat-dialog-content class="background-dark" id="dialog-changes-confirmation">
    <p *ngIf="data.title">
      <strong>{{ data.title | translate }}</strong>
    </p>
    <p *ngIf="data.message && data.variable">
      {{ data.message | translate: { value: data.variable } }}
    </p>
    <p *ngIf="data.message && !data.variable">{{ data.message | translate }}</p>
    <p *ngIf="!data.message && !data.variable">
      {{ 'DIALOGS.DISMISS-CHANGES.MESSAGE' | translate }}
    </p>
  </div>
  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false" id="cancel-dismiss-changes-dialog">
      {{ 'GENERAL.FALSE' | translate }}
    </button>
    <button
      id="approve-dismiss-changes-dialog"
      mat-button
      class="custom-button"
      [mat-dialog-close]="true"
      style="margin-right: 0"
    >
      {{ 'GENERAL.TRUE' | translate }}
    </button>
  </div>
</div>
