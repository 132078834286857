<div class="content-container">
  <app-back-button [task]="task"> </app-back-button>

  <div class="info-container" id="{{ entityName }}-general-info">
    <h5 class="title-info">{{ 'TASK.HEADING' | translate }}</h5>

    <div
      *ngIf="task"
      class="container"
      fxLayout="row wrap"
      fxlayout.sm="column"
      fxlayout.xs="column"
      fxLayoutGap="7px grid"
    >
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-title-label">{{ 'TASK.TITLE' | translate }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-title-value">
                {{ task.taskTitle | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-object-label">{{
                'TASK-DETAILS.OBJECT-NAME-ENTITY'
                  | translate
                    : {
                        entity: taskEntity
                      }
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span
                *ngIf="taskConfiguration && taskConfiguration.object"
                name="taskID"
                class="information"
                id="{{ entityName }}-general-info-name-value"
              >
                {{ getObjectName() }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <!--Intentionally this is Empty div due to task object id was here and removed VIS25-9667-->
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-priority-label">{{
                'TASK.PRIORITY' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-priority-value">{{
                task.priority
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-dueDate-label">{{
                'TASK.DUE-DATE' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-dueDate-value">{{
                task.dueDate | date: dateFormat
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-user-label">{{ 'TASK.USER' | translate }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-user-value">{{
                task.assignedUserId
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-role-label">{{ 'TASK.ROLE' | translate }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-role-value">{{
                task.assignedRoleId | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-createdBy-label">{{
                'TASK-CONFIGURATION.TEAM-ID' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span
                name="taskID"
                class="information"
                id="{{ entityName }}-general-info-createdBy-value"
                *ngIf="taskConfiguration"
                >{{ taskConfiguration.teamId }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-createdBy-label">{{ 'TASK.BU' | translate }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span
                *ngIf="bu && bu.name"
                name="taskID"
                class="information"
                id="{{ entityName }}-general-info-createdBy-value"
                >{{ bu.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-createdBy-label">{{
                'TASK.STATUS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-createdBy-value"
                >{{ 'TASK.' + task.status | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-createdOn-label">{{
                'GENERAL.CREATED-ON' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-createdOn-value">{{
                task.creationDate | date: dateFormat + ' HH:mm:ss'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-createdBy-label">{{
                'TASK.CREATED-BY' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-createdBy-value">{{
                task.createdBy
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="task.params?.realm" fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label for="taskID" id="{{ entityName }}-general-info-realm-label">{{ 'TASK.REALM' | translate }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span name="taskID" class="information" id="{{ entityName }}-general-info-realm-value">{{
                'TASK.REALM.' + task.params.realm.toUpperCase() | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!task">No task Currently Selected</div>
  </div>
  <div class="info-container" id="{{ entityName }}-general-info">
    <h5 class="title-info">
      {{ 'TASK-CONFIGURATION.DESCRIPTION' | translate }}
    </h5>

    <div
      *ngIf="task"
      class="container"
      fxLayout="row wrap"
      fxlayout.sm="column"
      fxlayout.xs="column"
      fxLayoutGap="7px grid"
    >
      <div fxFlex="44" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="44">
            <div class="label-container">
              <label id="{{ entityName }}-general-descripton-label">{{
                'TASK-CONFIGURATION.DESCRIPTION' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="66">
            <div class="span-container">
              <span
                *ngIf="taskConfiguration && taskConfiguration.description"
                name="taskID"
                class="information break-line"
                id="{{ entityName }}-general-info-description-value"
                [innerHTML]="taskConfiguration.description | translate: task.params"
              >
              </span>
              <a
                *ngIf="
                  taskConfiguration &&
                  taskConfiguration.description &&
                  this.task &&
                  this.task.params &&
                  this.task.params['link']
                "
                href="{{ getLink() }}"
                >Link</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!task">No task Currently Selected</div>
  </div>

  <div class="information-container table">
    <h5 class="title-info">{{ 'TASK.HISTORY' | translate }}</h5>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
      aria-hidden="true"
    >
      <ng-container matColumnDef="creationDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-date"
          (click)="paginator.toArray()[0].firstPage()"
        >
          {{ 'TASK.COMMENT.CREATED-ON' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-date-{{ paginator.toArray()[0].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.date | date: dateFormat + ' HH:mm:ss' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-creator"
          (click)="paginator.toArray()[0].firstPage()"
        >
          {{ 'TASK.COMMENT.CREATED-BY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-creator-{{ paginator.toArray()[0].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.userId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="eventType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-event-type"
          (click)="paginator.toArray()[0].firstPage()"
        >
          {{ 'TASK.COMMENT.ACTION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-event-type-{{ paginator.toArray()[0].pageIndex + 1 }}-{{ i }}"
        >
          {{ 'TASK.ACTION.' + getEventType(element.eventType) | translate }}{{ getCommentParams(element.params) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-comment"
          (click)="paginator.toArray()[0].firstPage()"
        >
          {{ 'TASK.COMMENT.COMMENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-comment-{{ paginator.toArray()[0].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.content }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>
  <div class="information-container table attachments-table" *hasPermission="'tm.uf.r'">
    <h5 class="title-info">{{ 'TASK.ATTACHMENTS.TITLE' | translate }}</h5>
    <table
      mat-table
      [dataSource]="dataSourceAttachments"
      matSort
      id="{{ entityName }}-search-results-files-table"
      aria-hidden="true"
    >
      <ng-container matColumnDef="fileName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-files-name"
          (click)="paginator.toArray()[1].firstPage()"
        >
          {{ 'TASK.ATTACHMENTS.FILE-NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-files-name-{{ paginator.toArray()[1].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.fileName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-files-date"
          (click)="paginator.toArray()[1].firstPage()"
        >
          {{ 'TASK.ATTACHMENTS.DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-files-date-{{ paginator.toArray()[1].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.date | date: dateFormat + ' HH:mm:ss' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-files-user"
          (click)="paginator.toArray()[1].firstPage()"
        >
          {{ 'TASK.ATTACHMENTS.USER' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-files-user-{{ paginator.toArray()[1].pageIndex + 1 }}-{{ i }}"
        >
          {{ element.userId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-files-actions"
        ></th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-files-actions-{{ paginator.toArray()[1].pageIndex + 1 }}-{{ i }}"
        >
          <button
            (click)="$event.stopPropagation()"
            mat-icon-button
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-{{ displayedColumns[5] }}-{{ paginator.toArray()[1].pageIndex + 1 }}-{{
              i
            }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsFileTable"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFileTable" (click)="openFile(row)"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>

  <app-task-details-buttons
    [task]="task"
    [isDirectApproveOrReject]="isDirectApproveOrReject"
    (buttonClicked)="buttonsHandler($event)"
  ></app-task-details-buttons>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button
      *hasPermission="'tm.uf.d'"
      mat-menu-item
      (click)="deleteImage(element)"
      id="{{ entityName }}-table-results-action-menu-delete-button"
    >
      <mat-icon>delete_outline</mat-icon> {{ 'DELETE' | translate }}
    </button>
  </ng-template>
</mat-menu>
