import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.less'],
})
export class RatingBarComponent {
  @Input() grade: string;
}
