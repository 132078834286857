import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import {
  typeOptions
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/typeOptions';
import { TableTypes } from 'src/app/in-memory-data/default-table/table-types';
import {
  DefaultEditorService
} from '../../services/default-table/editors/default-editor.service';
import {
  TranslationService
} from '../../services/translation/translation.service';
import { UserService } from '../../services/user/user.service';
import { TablesService } from '../tables.service';

@Component({
  selector: 'app-default-table',
  templateUrl: './default-table.component.html',
  styleUrls: ['./default-table.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms')),
      transition('expanded <=> void', animate('150ms')),
    ]),
  ],
})
export class DefaultTableComponent
  implements OnInit, OnChanges, AfterViewInit, AfterViewChecked
{
  @Input() entityName: any;
  @Input() configurationKey: string;
  @Input() displayedColumns: string[];
  @Input() columns: any[];
  @Input() originalColumns: string[];
  @Input() originalDisplayedColumns: string[];
  @Input() tableDataSource: any;
  @Input() editorStrategy = new DefaultEditorService();
  @Input() threeDots: boolean;
  @Input() listOfActionButtons: any[];
  @Input() listOfActionIcons: any[];
  @Input() totalCount = 0;
  @Input() distinctValues: any;
  @Input() selectionAction: () => any;
  @Input() getCustomClass: (element) => any;
  @Input() hasNegatedValue: (element, column) => any;
  @Input() getCustomColumnClass: (element, column) => any;
  @Input() expanded: boolean;
  @Input() expandedComponent: boolean;
  @Input() component: any = null;
  @Input()
  translatePrefixForColumnFilter: string;
  @Input() enableFilter = true;
  @Output() filterEvent = new EventEmitter();

  @Input() insideDialog: boolean;
  @Input() singleSelection: boolean;
  @Input() defaultAngularPagination: boolean;
  @Input() defaultAngularSorting: boolean;
  @Input() customFunction: boolean;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel(true, []);
  displayedColumnsTemp = [];
  columnsTemp = [];
  loaderSpinner = true;
  disableAllSelects = false;
  dateFormat = '';
  state;
  expandedElement: any;
  dynamicComponent = null;
  currentDateFormat;

  // Added because for some reason the input value on columns is changed inside the child component and I could not realize where
  columnConfiguration;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private tablesService: TablesService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    public dialogRef: MatDialogRef<any>,
    private sanitizer: DomSanitizer,
    private kcService: KeycloakService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'tableDataSource' in changes &&
      changes.tableDataSource.currentValue &&
      changes.tableDataSource.currentValue.content
    ) {
      this.loaderSpinner = true;
      this.dataSource.data = changes.tableDataSource.currentValue.content;
      this.loaderSpinner = false;
      if (
        changes.tableDataSource.currentValue.total &&
        changes.tableDataSource.currentValue.total !== this.totalCount
      ) {
        this.totalCount = changes.tableDataSource.currentValue.total;
      }
      if (
        typeof changes.tableDataSource.currentValue === 'object' &&
        typeof changes.tableDataSource.previousValue === 'object'
      ) {
        if (
          changes.tableDataSource.currentValue.total !==
          changes.tableDataSource.previousValue.total
        ) {
          this.paginator.pageIndex = 0;
        }
      }
    }
    if ('distinctValues' in changes) {
      this.distinctValues = changes.distinctValues.currentValue;
    }
  }

  ngOnInit() {
    this.columnConfiguration = this.columns; // adding the default value to avoid changes
    this.state = window.history.state;
    this.getCurrentDateFormat();
    if (this.component) {
      this.dynamicComponent = this.component;
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.tableDataSource && this.tableDataSource.content) {
      this.dataSource.data = this.tableDataSource.content;
      this.totalCount = this.tableDataSource.total;
      this.dataSource.filter = '';
    } else {
      this.dataSource.data = [];
      this.totalCount = 0;
      this.dataSource.filter = '';
    }

    if (this.defaultAngularPagination) {
      this.dataSource.paginator = this.paginator;
    }

    this.getTableConfiguration();
    this.tablesService.currentSharedSelectedRows.subscribe((data) => {
      if (data == null) {
        this.selection.clear();
      }
    });
    this.dateFormat = this.userService.getDateFormat();
    if (
      (this.tableDataSource && !this.tableDataSource.content) ||
      (this.tableDataSource &&
        this.tableDataSource.content &&
        this.tableDataSource.content.length === 0) ||
      this.tableDataSource === undefined
    ) {
      this.loaderSpinner = false;
    }
  }

  getDistinctValues = (choice): Observable<any> => {
    if (this.distinctValues) {
      return this.distinctValues(choice);
    }
  };

  getColumnHeaderValue(column) {
    if (this.columns && this.columns.find((col: any) => col.name === column)) {
      return this.columns.find((col: any) => col.name === column).translateName;
    }
    return column;
  }

  getSliderValue(element, column) {
    const foundColumn = this.originalColumns.find(
      (col: any) => col.name === column
    );
    if (foundColumn['name'] === 'slider') {
      return element[foundColumn['columnValue']];
    } else if (foundColumn['name'] === 'visible') {
      return element[column];
    }
  }

  getColumnValue(element, column) {
    const foundCol = this.columnConfiguration.find(
      (col: any) => col.name === column
    );

    if (foundCol) {
      if (column.toLowerCase().includes('date') &&
          (!foundCol?.type || foundCol?.type !== TableTypes.DATE)) {
        /**
         * The if below is a completly workaround because for some reason is used a "part of string" to check if
         * the column should be a Date type. Have too much content using this "thing" and I'll not change this
         * without time to fix the possible side effects.
         */
        if (column.toLocaleLowerCase() !== 'updatedby') {
          return this.datePipe.transform(
            element[column],
            this.dateFormat
          );
        } else {
          return element[column];
        }
      } else if (foundCol?.type) {
        switch (foundCol?.type) {
          case TableTypes.CURRENCY:
            return this.formatCurrencyValue(element, column);
          case TableTypes.DATE:
            return this.formatDateValue(element, column, foundCol.withHour);
          case TableTypes.MULTI_TRANSLATED_STRING:
            return this.hasMultiTranslateString(foundCol) ?
              this.transformMultiColumnLang(element, foundCol) :
              element[column];
          case TableTypes.SLIDER:
            return this.sanitizer.bypassSecurityTrustHtml(
              this.buildCheckboxElement(element, column)
            );
          default:
            return this.defaultColumnValue(element, column);
        }
      } else {
        return this.defaultColumnValue(element, column);
      }
    }
  }

  private defaultColumnValue(element: any, column: string) {
    if (
      element &&
      (element[column] != null || element[column] !== undefined) &&
      this.originalColumns
    ) {
      const foundColumn = this.originalColumns.find(
        (col: any) => col.name === column
      );

      if (this.hasTranslatePrefix(foundColumn)) {
        let elementValue;
        if (typeof element[column] === 'string') {
          elementValue = element[column].replace(/_/g, '-').toUpperCase();
        } else if (typeof element[column] === 'boolean') {
          elementValue = element[column].toString().toUpperCase();
        } else {
          elementValue = element[column];
        }
        if (foundColumn['prefixCheck']) {
          if (elementValue === foundColumn['flagValue']) {
            return element[foundColumn['columnValue']];
          }
        }
        const translateColumnValue = elementValue
          ? this.translateService.instant(
            foundColumn['translatePrefix'] + elementValue
          )
          : '';
        if (
          foundColumn['multiValueColumn'] &&
          element[foundColumn['multiValueColumn']]
        ) {
          return (
            translateColumnValue +
            foundColumn['multiValueColumnSeparator'] +
            element[foundColumn['multiValueColumn']]
          );
        }
        return translateColumnValue;
      } else if (this.hasDateFormat(foundColumn)) {
        let fullYearFormat;
        let monthAndYearFormat;
        let fullYearWithHoursMinutesSecondsFormat;
        if (this.currentDateFormat === 'GERMAN') {
          fullYearFormat = 'dd.MM.yyyy';
          monthAndYearFormat = 'MM.yyyy';
          fullYearWithHoursMinutesSecondsFormat =
            'dd.MM.yyyy' + ' HH:mm:ss';
        } else if (this.currentDateFormat === 'BRITISH') {
          fullYearFormat = 'dd/MM/yyyy';
          monthAndYearFormat = 'MM/yyyy';
          fullYearWithHoursMinutesSecondsFormat =
            'dd/MM/yyyy' + ' HH:mm:ss';
        } else if (this.currentDateFormat === 'AMERICAN') {
          fullYearFormat = 'MM-dd-yyyy';
          monthAndYearFormat = 'MM-yyyy';
          fullYearWithHoursMinutesSecondsFormat =
            'MM-dd-yyyy' + ' HH:mm:ss';
        }
        if (foundColumn['dateFormat'] === 'monthAndYear') {
          return this.datePipe.transform(
            element[column],
            monthAndYearFormat
          );
        } else if (
          foundColumn['dateFormat'] === 'fullYearWithHoursMinutesSeconds'
        ) {
          return this.datePipe.transform(
            element[column],
            fullYearWithHoursMinutesSecondsFormat
          );
        } else if (foundColumn['dateFormat'] === 'fullYear') {
          return this.datePipe.transform(
            element[column],
            fullYearFormat
          );
        }
      } else if (foundColumn['name'] === 'type') {
        const translateKey = typeOptions.find(
          (type) => type.name === element.type
        )?.translateName;
        return !!translateKey
          ? this.translateService.instant(translateKey)
          : element.type;
      } else {
        if (
          foundColumn &&
          foundColumn['multiValueColumn'] &&
          element[foundColumn['multiValueColumn']]
        ) {
          return (
            element[column] +
            foundColumn['multiValueColumnSeparator'] +
            element[foundColumn['multiValueColumn']]
          );
        }

        const value = element[column];
        let formatValueFunc;
        if (foundColumn && foundColumn['formatValueFunc']) {
          formatValueFunc = foundColumn['formatValueFunc'];
        }
        return !!formatValueFunc ? formatValueFunc(value) : value;
      }
    }
  }

  hasTranslatePrefix(foundColumn) {
    return (
      foundColumn &&
      foundColumn.translatePrefix !== '' &&
      foundColumn.translatePrefix !== null &&
      foundColumn.translatePrefix !== undefined
    );
  }

  hasDateFormat(foundColumn) {
    return (
      foundColumn &&
      foundColumn.dateFormat !== '' &&
      foundColumn.dateFormat !== null &&
      foundColumn.dateFormat !== undefined
    );
  }

  getCurrentDateFormat() {
    this.userService.getSettings().subscribe((data) => {
      if (data && data.profile) {
        this.currentDateFormat = data.profile.dateFormat;
      }
    });
  }

  isSticky(column) {
    return column === 'select';
  }

  isStickyEnd(column) {
    return column === 'actions';
  }

  getTranslatePrefixForColumnFilter(column) {
    const foundColumn = this.originalColumns.find(
      (col: any) => col.name === column
    );
    if (
      foundColumn &&
      foundColumn['translatePrefix'] !== '' &&
      foundColumn['translatePrefix'] != null &&
      foundColumn['translatePrefix'] !== undefined
    ) {
      if (this.translatePrefixForColumnFilter) {
        return this.translatePrefixForColumnFilter;
      } else {
        return foundColumn['translatePrefix'];
      }
    } else {
      return '';
    }
  }

  checkSpecialDisable(button, element?) {
    if (button && button.specialDisable) {
      return button.specialDisable(element);
    }
  }

  changeSharedSelectedRows() {
    this.tablesService.updateSharedSelectedRows(this.selection.selected);
    if (this.selectionAction) {
      return this.selectionAction();
    }
  }

  isColumnNumber(columnName) {
    if (this.columnsTemp && this.columnsTemp.length > 0) {
      return !!(this.columnsTemp.find((col) => col.name === columnName) &&
        this.columnsTemp.find((col) => col.name === columnName).number);
    } else {
      return false;
    }
  }

  isColumnSmall(columnName) {
    let result = false;
    if (this.columns && this.columns.length > 0) {
      if (
        this.columns.find((col) => col.name === columnName) &&
        this.columns.find((col) => col.name === columnName).smallColumn
      ) {
        result = true;
      }
    }
    return result;
  }

  isMoreThanOneSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 1;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  masterToggle(ref) {
    if (this.isSomeSelected()) {
      this.selection.clear();
      ref.checked = false;
    } else {
      if (this.isAllSelected()) {
        this.selection.clear();
      } else {
        this.dataSource.data.forEach((row) => {
          this.selection.select(row);
        });
      }
    }
    this.changeSharedSelectedRows();
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  selectTheRow(row: any, isSelected: any) {
    if (this.selection.selected.length === 0) {
      this.toggleRow(row, isSelected);
    } else if (this.selection.selected.length === 1) {
      this.selection.clear();
      this.selectTheRow(row, false);
      this.changeSharedSelectedRows();
    }
  }

  toggleRow(row: any, isSelected: any) {
    if (!isSelected) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.changeSharedSelectedRows();
  }

  tableChanged(event?) {
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.configurationKey,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.configurationKey).subscribe(
      (data) => {
        if (!data.columns) {
          const configuration = {
            key: this.configurationKey,
            value: {
              columns: this.originalColumns,
              displayedColumns: this.originalDisplayedColumns,
            },
          };
          this.userService.insertConfiguration(configuration).subscribe(() => {
            this.getTableConfiguration();
          });
        }
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
            this.loadColumnConfiguration();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.configurationKey,
                data,
                this.columns,
                this.displayedColumns
              );
            this.setConfiguredColumns(mergedConfig);
            this.loadColumnConfiguration();
          }
        } else {
          this.setDefaultColumnValues();
          this.loadColumnConfiguration();
        }
      },
      () => {
        this.setDefaultColumnValues();
        this.loadColumnConfiguration();
      }
    );
  }

  loadColumnConfiguration() {
    if (this.displayedColumns && this.displayedColumns.length > 0) {
      this.displayedColumns.forEach((dc) => {
        if (
          dc.toLowerCase() !== 'actions' &&
          dc.toLowerCase() !== 'select' &&
          dc.toLowerCase() !== 'flags'
        ) {
          const object = {
            name: String(dc),
            index: this.columns.length - 1,
            checked: false,
            translateName: String(dc),
            locked: false,
          };
          if (!this.columns.find((obj) => obj.name === object.name)) {
            const lastColumn = this.columns[this.columns.length - 1];
            lastColumn.version = Number(lastColumn.version) + 1;
            this.columns[this.columns.length - 1] = object;
            this.columns.push(lastColumn);
          }
        }
      });
      this.displayedColumnsTemp = this.displayedColumns;
      this.columnsTemp = this.columns;
    }
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = this.originalColumns;
    this.displayedColumns = this.originalDisplayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  sortTable(event) {
    this.filterEvent.emit(event);
  }

  paginate(event) {
    this.filterEvent.emit(event);
  }

  filterValues(choice, values) {
    this.filterEvent.emit({
      filteredValues: { choice, values },
    });
  }

  edit(element) {
    if (this.insideDialog && this.singleSelection) {
      this.dialogRef.close({
        result: element,
      });
    } else if (this.customFunction) {
      this.tablesService.triggerCustomEvent(element);
    } else {
      if (this.expanded) {
        this.expandedElement =
          this.expandedElement === element ? null : element;
        this.tablesService.updateSharedSelectedExpandedRows(element);
      } else {
        this.editorStrategy.edit(element);
      }
    }
  }

  getClass(element) {
    if (this.getCustomClass) {
      return this.getCustomClass(element);
    }
    return '';
  }

  getColumnClass(element, column) {
    if (this.columns.find((col: any) => col.name === column)) {
      if (this.getCustomColumnClass) {
        return this.getCustomColumnClass(element, column);
      }
    }
    return '';
  }

  rowExpanded(row) {
    return this.expandedElement === row;
  }

  setLoaderSpinner(value: boolean) {
    this.loaderSpinner = value;
  }

  isDisabledFilter(columnName: string): boolean {
    const column = this.columnConfiguration.find(
      (col) => col.name === columnName
    );
    return column.filterDisabled ? column.filterDisabled : false;
  }

  isDisabledSort(columnName: string): boolean {
    const column = this.columnConfiguration.find(
      (col) => col.name === columnName
    );
    return column.sortDisabled ? column.sortDisabled : false;
  }

  /**
   * If the listOfActionsButtons has one or more active items inside the menu should return false
   * v1.1. After tests we got some empty menus when the item is active but the user doesn't have
   * the correct permissions. So now first we filter what items the user has permissions and after
   * if we have some active items in the menu.
   * @returns true | false
   */
  hasValidItems(element): boolean {
    const userRoles = this.kcService.getUserRoles();
    const hasAllowedItems: any[] = this.listOfActionButtons.filter((action) =>
      userRoles.includes(action.permission)
    );
    return hasAllowedItems?.some(
      (action) => !action.specialDisable(element)
    );
  }

  hasMultiTranslateString(foundColumn) {
    return foundColumn &&
      foundColumn.type &&
      foundColumn.type === TableTypes.MULTI_TRANSLATED_STRING &&
      foundColumn['columnGerman'] &&
      foundColumn['columnEnglish'];
  }

  private transformMultiColumnLang(element,  foundColumn): string {
    return this.translationService.getCurrentLanguage() === 'de' ?
      element[foundColumn['columnGerman']] :
      element[foundColumn['columnEnglish']];
  }

  private formatCurrencyValue(element: any, column: string): any {
    let currencyColumnValue;
    if (this.hasNegatedValue && this.hasNegatedValue(element, column) === '-') {
      currencyColumnValue =
        '-' +
        this.currencyPipe.transform(
          element[column],
          'EUR',
          'symbol',
          '1.2-2',
          'de'
        );
    } else {
      currencyColumnValue = this.currencyPipe.transform(
        element[column],
        'EUR',
        'symbol',
        '1.2-2',
        'de'
      );
    }
    return currencyColumnValue;
  }

  private formatDateValue(
    element: any,
    column: string,
    isTimeStamp: boolean
  ): string {
    let dateFormat;
    if (isTimeStamp) {
      dateFormat = this.userService.getDateFormat(true);
    } else {
      dateFormat = this.userService.getDateFormat();
    }

    return this.datePipe.transform(element[column], dateFormat);
  }

  private buildCheckboxElement(element: any, column: string) {
    const isChecked = !!element[column];
    return `
            <div style="text-align: center;" >
              ${
                isChecked
                  ? '<input class="checkbox-table mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin" \
                  type="checkbox" checked disabled="true">'
                  : '<input class="checkbox-table mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin" \
                  type="checkbox" disabled="true" >'
              }
            </div>
            `;
  }
}
