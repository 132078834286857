import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { bookingOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/bookingOptions';
import { classificationRelevantOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/classificationRelevant';
import { inHouseClearingItemCodes } from 'src/app/in-memory-data/bookingCode/formConfiguration/inHouseClearingItemCodes';
import { relevantForRemainingLimitCalcOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/relevantForRemainingLimitCalc';
import { settlementRelevantOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/settlementRelevant';
import { standards } from 'src/app/in-memory-data/bookingCode/formConfiguration/standards';
import { transactionTypes } from 'src/app/in-memory-data/bookingCode/formConfiguration/transactionTypes';
import { statusTypes } from 'src/app/in-memory-data/business-unit-transaction-codes/enum/statusTypes';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-bu-transaction-codes-create',
  templateUrl: './bu-transaction-codes-create.component.html',
  styleUrls: ['./bu-transaction-codes-create.component.less'],
})
export class BuTransactionCodesCreateComponent implements OnInit {
  transactionCodeForm: UntypedFormGroup;

  transactionTypes = transactionTypes;
  classRelevantOptions = classificationRelevantOptions;
  standards = standards;
  bookingOptions = bookingOptions;
  relevantForRemainingLimitCalcOptions = relevantForRemainingLimitCalcOptions;
  inHouseClearingItemCodes = inHouseClearingItemCodes;
  settlementRelevantOptions = settlementRelevantOptions;

  statusTypes = statusTypes;

  entityName = 'business-unit-transaction-code-create';
  accounts = [
    {
      name: 'NOT_RELEVANT',
      translateName: 'BOOKING-CODE.ACCOUNTS.NOT_RELEVANT',
      account: 'Not Relevant',
      id: 'NOT_RELEVANT',
    },
  ];
  normalAccounts = [];
  salesTaxAccounts = [];
  state: any;

  isTask = false;
  editableTask = false;

  draftExists = false;

  sharedTransactionCode: any;
  transactionCodeTask: any;
  transactionCodeApproved: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private subledgerAccountService: SubLedgerService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    private taskService: TaskService,
    private sharedDataService: SharedDataService,
    private matDialogService: MatDialog,
    private draftService: DraftService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.transactionCodeFormBuilder();
    this.sharedDataService.currentSharedTransactionCode.subscribe(
      (sharedTransactionCode) => {
        this.sharedTransactionCode = sharedTransactionCode;
      }
    );
  }

  ngOnInit(): void {
    this.state = window.history.state;
    if (this.state.task) {
      this.isTask = true;
      if (
        this.state.task.type === 'APPROVAL_TRANSACTION_CODE_ALLOCATION' &&
        (this.state.task.params.eventType === 'INACTIVATE' ||
          this.state.task.params.eventType === 'ACTIVATE')
      ) {
        this.transactionCodeAllocationService
          .retrieveByTransactionCodeId(this.state.task.params.entityId)
          .subscribe((data) => {
            this.setTransactionCodeAllocationForm(data);
          });
      } else {
        this.draftService
          .retrieveDraftForTask(this.state.task.guid)
          .subscribe((data) => {
            this.setTransactionCodeAllocationForm(data);
          });

        this.transactionCodeAllocationService
          .retrieveByTransactionCodeId(this.state.task.params.entityId)
          .subscribe((data) => {
            this.transactionCodeApproved = data;

            Object.assign(this.transactionCodeApproved, {
              transactionType:
                this.transactionCodeApproved &&
                this.transactionCodeApproved.transactionType
                  ? this.transactionTypes.find(
                      (transactionType) =>
                        transactionType.name ===
                        this.transactionCodeApproved.transactionType
                    ).code
                  : null,
            });
          });
      }
      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    } else if (this.sharedTransactionCode) {
      this.transactionCodeApproved = this.sharedTransactionCode;
      if (!this.transactionCodeApproved.entityGuid) {
        Object.assign(this.transactionCodeApproved, {
          transactionType:
            this.transactionCodeApproved &&
            this.transactionCodeApproved.transactionType
              ? this.transactionTypes.find(
                  (transactionType) =>
                    transactionType.name ===
                    this.transactionCodeApproved.transactionType
                ).code
              : null,
        });

        Object.assign(this.transactionCodeApproved, {
          transactionCodeDescriptionEnglish:
            this.transactionCodeApproved
              .originalTransactionCodeDescriptionEnglish,
          transactionCodeDescriptionGerman:
            this.transactionCodeApproved
              .originalTransactionCodeDescriptionGerman,
          transactionCodeNameEnglish:
            this.transactionCodeApproved.originalTransactionCodeNameEnglish,
          transactionCodeNameGerman:
            this.transactionCodeApproved.originalTransactionCodeNameGerman,
          objectAccount: this.transactionCodeApproved.objectionAccountBeVision,
          originalObjectAccount:
            this.transactionCodeApproved.objectionAccountBeVision,
          dueDate: this.transactionCodeApproved.isDueDate,
          originalSapLedgerAccountCredit:
            this.transactionCodeApproved.sapLedgerAccountCredit,
          originalSapLedgerAccountDebit:
            this.transactionCodeApproved.sapLedgerAccountDebit,
          salesTaxAccount: this.transactionCodeApproved.salesTaxAccountGuid,
        });
      } else {
        Object.assign(this.transactionCodeApproved, {
          transactionType:
            this.transactionCodeApproved &&
            this.transactionCodeApproved.transactionTypeEnum
              ? this.transactionTypes.find(
                  (transactionType) =>
                    transactionType.name ===
                    this.transactionCodeApproved.transactionTypeEnum
                ).code
              : null,
        });
      }

      this.transactionCodeForm.patchValue(this.transactionCodeApproved);

      this.checkDraftExist();
    }

    this.getAccounts();
    this.getTaxAccounts();
  }

  changeEditable(value) {
    this.taskService.updateEditableTask(value);
  }

  checkDraftExist() {
    this.draftService
      .approvalDraftExistsForEntityId(this.transactionCodeApproved.id)
      .subscribe((result) => {
        if (result) {
          this.transactionCodeForm.disable();
          this.draftExists = true;

          this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        }
      });
  }

  setTransactionCodeAllocationForm(data) {
    this.transactionCodeTask = data;
    this.transactionCodeForm.patchValue(this.transactionCodeTask);
  }

  getStatusTask(status) {
    if (status && statusTypes) {
      if (this.state.task) {
        if (this.state.task.params.eventType === 'INACTIVATE') {
          return this.statusTypes.find((data) => data.code === 3).translateName;
        } else if (this.state.task.params.eventType === 'ACTIVATE') {
          return this.statusTypes.find((data) => data.code === 1).translateName;
        } else {
          return this.statusTypes.find((data) => data.code === status)
            .translateName;
        }
      } else {
        return this.statusTypes.find((data) => data.code === status)
          .translateName;
      }
    } else {
      return '';
    }
  }

  getAccounts() {
    this.subledgerAccountService.findObjectAccounts().subscribe((data) => {
      data.forEach((element) => {
        this.accounts.unshift({
          name: element.accountNumber,
          translateName: element.accountNumber,
          account: element.accountNumber,
          id: element.guid,
        });
        this.normalAccounts.push({
          name: element.accountNumber,
          account: element.guid,
          id: element.guid,
        });
      });
    });
  }

  getTaxAccounts() {
    this.subledgerAccountService.findSalesTaxAccounts().subscribe((data) => {
      data.forEach((element) => {
        this.salesTaxAccounts.push({
          account: element.accountNumber,
          id: element.guid,
        });
      });
    });
  }

  transactionCodeFormBuilder() {
    this.transactionCodeForm = this.formBuilder.group({
      transactionCode: [{ value: '', disabled: true }],
      originalTransactionCodeNameGerman: [{ value: '', disabled: true }],
      originalTransactionCodeNameEnglish: [{ value: '', disabled: true }],
      originalTransactionCodeDescriptionGerman: [{ value: '', disabled: true }],
      originalTransactionCodeDescriptionEnglish: [
        { value: '', disabled: true },
      ],
      transactionCodeNameGerman: [''],
      transactionCodeNameEnglish: [''],
      transactionCodeDescriptionGerman: [''],
      transactionCodeDescriptionEnglish: [''],
      transactionType: [''],
      transferTransactionCode: [''],
      standard: [''],
      cancellation: [''],
      originalSapLedgerAccountCredit: [{ value: '', disabled: true }],
      originalSapLedgerAccountDebit: [{ value: '', disabled: true }],
      sapLedgerAccountCredit: [''],
      sapLedgerAccountDebit: [''],
      objectAccount: [{ value: '', disabled: true }],
      originalObjectAccount: [''],
      bookingOption: [''],
      dueDate: [''],
      classificationRelevant: [''],
      relevantForRemainingLimitCalc: [''],
      settlementRelevant: [''],
      relevantSales: [''],
      salesTaxAccount: [''],
      balancingTransactionCodeInhouse: [''],
      displayExternally: [''],
      id: [''],
      entityGuid: [''],
      draft: [true],
      approvalTaskId: [''],
      product: [''],
      status: [''],
    });
  }

  restrictIdInputToPositiveIntegers(event: any) {
    let input;
    let reg;
    input = event.target.value + event.key;
    reg = /^[1-9]\d*$/;
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  get getTransactionType() {
    return this.findTransactionType.bind(this);
  }

  findTransactionType(transactionTypeCode) {
    if (!this.transactionTypes) {
      return '';
    }

    const transactionType = this.transactionTypes.find(
      (element) => element.code === transactionTypeCode
    );
    return transactionType ? transactionType.translateName : '';
  }

  get getStandard() {
    return this.findStandard.bind(this);
  }

  findStandard(standardCode) {
    if (!this.standards) {
      return '';
    }

    const standard = this.standards.find(
      (element) => element.code === standardCode
    );
    return standard ? standard.translateName : '';
  }

  get getAccount() {
    return this.findAccount.bind(this);
  }

  findAccount(accountId) {
    if (!this.accounts) {
      return '';
    }

    const account = this.accounts.find((element) => element.id === accountId);
    return account ? account.translateName : '';
  }

  get getBookingOption() {
    return this.findBookingOption.bind(this);
  }

  findBookingOption(bookingOptionCode) {
    if (!this.bookingOptions) {
      return '';
    }

    const bookingOption = this.bookingOptions.find(
      (element) => element.code === bookingOptionCode
    );
    return bookingOption ? bookingOption.translateName : '';
  }

  get getTaxAccount() {
    return this.findTaxAccount.bind(this);
  }

  findTaxAccount(taxAccountName) {
    if (!this.salesTaxAccounts) {
      return '';
    }

    const taxAccount = this.salesTaxAccounts.find(
      (element) => element.account === taxAccountName
    );
    return taxAccount ? taxAccount.account : '';
  }

  get getInHouseItemCode() {
    return this.findInHouseItemCode.bind(this);
  }

  findInHouseItemCode(inHouseClearingItemCode) {
    if (!this.inHouseClearingItemCodes) {
      return '';
    }

    const inHouseItemCode = this.inHouseClearingItemCodes.find(
      (element) => element.code === inHouseClearingItemCode
    );
    return inHouseItemCode ? inHouseItemCode.translateName : '';
  }

  completeTransactionCode() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        const incompleteCodes = [];
        incompleteCodes.push(this.sharedTransactionCode.guid);
        if (this.sharedTransactionCode.new) {
          const request = {
            transactionCodeGuids: incompleteCodes,
            entityGuid: this.state.businessUnit.guid,
            comment: result.comment,
          };
          this.transactionCodeAllocationService
            .allocateIncompleteTransactionCodes(request)
            .subscribe((_) => {
              this.transactionCodeAllocationService
                .retrieveTransactionCodeAllocation(
                  this.state.businessUnit.guid,
                  this.sharedTransactionCode.id
                )
                .subscribe((data) => {
                  this.transactionCodeForm.get('approvalTaskId').patchValue('');
                  this.transactionCodeForm
                    .get('entityGuid')
                    .patchValue(data.entityGuid);
                  this.transactionCodeForm.get('id').patchValue(data.id);

                  this.finishCompleteAllocation(result);
                });
            });
        } else {
          this.finishCompleteAllocation(result);
        }
      }
    });
  }

  finishCompleteAllocation(result) {
    const request = {
      transactionCodeAllocationExtended: this.transactionCodeForm.getRawValue(),
      comment: result.comment,
      taskId: this.isTask ? this.state.task.guid : null,
    };

    this.transactionCodeAllocationService
      .editTransactionCode(request)
      .subscribe(() => {
        this.showSuccessTaskCreated();
      });
  }

  showSuccessTaskCreated() {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      { data: this.translateService.instant('ENTITIES.BU') }
    );

    setTimeout(() => {
      if (this.isTask) {
        this.router.navigateByUrl('/tasks');
      } else {
        this.router.navigateByUrl('/systemConfiguration/bu', {
          state: { success: true },
        });
      }
    }, 1500);
  }

  formatTransactionCodeFormValues() {
    const formatedValues = Object.assign(
      {},
      this.transactionCodeForm.getRawValue()
    );
    Object.assign(formatedValues, {
      originalTransactionCodeNameGerman: this.transactionCodeForm.get(
        'transactionCodeNameGerman'
      ).value,
    });
    Object.assign(formatedValues, {
      originalTransactionCodeNameEnglish: this.transactionCodeForm.get(
        'transactionCodeNameEnglish'
      ).value,
    });
    Object.assign(formatedValues, {
      originalTransactionCodeDescriptionGerman: this.transactionCodeForm.get(
        'transactionCodeNameGermanDescription'
      ).value,
    });
    Object.assign(formatedValues, {
      originalTransactionCodeDescriptionEnglish: this.transactionCodeForm.get(
        'transactionCodeNameEnglishDescription'
      ).value,
    });
    Object.assign(formatedValues, {
      transactionCodeNameGerman: this.transactionCodeForm.get(
        'buTransactionCodeNameGerman'
      ).value,
    });
    Object.assign(formatedValues, {
      transactionCodeNameEnglish: this.transactionCodeForm.get(
        'buTransactionCodeNameEnglish'
      ).value,
    });
    Object.assign(formatedValues, {
      transactionCodeDescriptionGerman: this.transactionCodeForm.get(
        'buTransactionCodeNameGermanDescription'
      ).value,
    });
    Object.assign(formatedValues, {
      transactionCodeDescriptionEnglish: this.transactionCodeForm.get(
        'buTransactionCodeNameEnglishDescription'
      ).value,
    });
    Object.assign(formatedValues, {
      originalSapLedgerAccountDebit: this.transactionCodeForm.get(
        'sapSubLedgerAccountDebit'
      ).value,
    });
    Object.assign(formatedValues, {
      sapLedgerAccountDebit: this.transactionCodeForm.get(
        'sapSubLedgerAccountDebitBU'
      ).value,
    });
    Object.assign(formatedValues, {
      originalSapLedgerAccountCredit: this.transactionCodeForm.get(
        'sapSubLedgerAccountCredit'
      ).value,
    });
    Object.assign(formatedValues, {
      sapLedgerAccountCredit: this.transactionCodeForm.get(
        'sapSubLedgerAccountCreditBU'
      ).value,
    });
    Object.assign(formatedValues, {
      originalObjectAccount:
        this.transactionCodeForm.get('objectAccount').value,
    });
    Object.assign(formatedValues, {
      objectAccount: this.transactionCodeForm.get('objectAccountBU').value,
    });
    Object.assign(formatedValues, {
      relevantForRemainingLimitCalc: this.transactionCodeForm.get(
        'relevantForRemainingLimitCalculation'
      ).value,
    });
    Object.assign(formatedValues, {
      entityGuid: this.sharedTransactionCode
        ? this.sharedTransactionCode.entityGuid
        : null,
    });
    Object.assign(formatedValues, {
      generalLedgerCounterAccount: this.transactionCodeForm.get(
        'transactionCodeNameGerman'
      ).value,
    });
    Object.assign(formatedValues, {
      relevantSales: this.transactionCodeForm.get('relevantForSales').value,
    });

    return formatedValues;
  }

  formatCompleteApprovalTaskData(data) {
    this.setTransactionCodeAllocationForm(data);
  }

  cancelCompleteTransactionCode() {
    if (this.state.task) {
      this.changeEditable(false);
    } else {
      this.sharedDataService.updateSharedEditTransactionMode(false);
    }
    this.notificationService.dismissMessage();
  }
}
