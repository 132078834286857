import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingCodeRoutingModule } from './booking-code-routing.module';
import { BookingCodeCreateComponent } from './booking-code-create/booking-code-create.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BookingCodeListComponent } from './booking-code-list/booking-code-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BookingCodeGeneralInformationComponent } from './booking-code-general-information/booking-code-general-information.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BookingCodeBuOverviewComponent } from './booking-code-bu-overview/booking-code-bu-overview.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [
    BookingCodeCreateComponent,
    BookingCodeListComponent,
    BookingCodeGeneralInformationComponent,
    BookingCodeBuOverviewComponent,
  ],
  imports: [
    CommonModule,
    BookingCodeRoutingModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    BackButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule,
    FormElementsModule,
    FlexLayoutModule,
    MatTabsModule,
    MatProgressSpinnerModule,
  ],
})
export class BookingCodeModule {}
