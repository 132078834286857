import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { reportingPeriodsColumns } from 'src/app/in-memory-data/animal-welfare/reporting-periods/table-columns';
import { reportingPeriodsColumnsConf } from 'src/app/in-memory-data/animal-welfare/reporting-periods/table-columns-configuration';
import { ReportingPeriodService } from 'src/app/shared/services/animal-welfare/reporting-period/reporting-period.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-reporting-periods-list',
  templateUrl: './reporting-periods-list.component.html',
  styleUrls: [
    './reporting-periods-list.component.less',
    './reporting-periods-list-md.component.less',
  ],
})
export class ReportingPeriodsListComponent implements OnInit {
  private CONFIGURAIONT_KEY = 'reporting_periods_table';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'reporting-periods';

  filterForm: UntypedFormGroup;

  columns = reportingPeriodsColumnsConf;
  displayedColumns = reportingPeriodsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;

  dateFormat = '';
  searchableGoodsGroups = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private reportingPeriodService: ReportingPeriodService,
    private searchService: SearchService
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
    this.getTableConfiguration();
    this.loadTable();
    this.filterForm.valueChanges.subscribe(() => this.searchReportingPeriods());
  }

  loadTable() {
    this.reportingPeriodService.findAll().subscribe((data: any) => {
      data.forEach((element) => {
        const reportingPeriod = {
          startDate: element.startDate,
          endDate: element.endDate,
          comment: element.comment,
        };
        this.searchableGoodsGroups.push(reportingPeriod);
      });
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchTerm: null,
    });
  }

  searchReportingPeriods() {
    this.searchService.search(
      this.searchableGoodsGroups,
      this.filterForm.get('searchTerm').value,
      this.dataSource
    );
  }

  goToEditView() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  cancelElement() {
    // TODO: Remove the element backend functionality
  }

  editSelectedRows() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
      state: { reportingPeriods: this.selection.selected },
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                reportingPeriodsColumnsConf,
                reportingPeriodsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = reportingPeriodsColumnsConf;
    this.displayedColumns = reportingPeriodsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
