import { Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-table-menu-columns',
  templateUrl: './table-menu-columns.component.html',
  styleUrls: ['./table-menu-columns.component.less'],
})
export class TableMenuColumnsComponent {
  @Input() columns;
  @Input() displayedColumns;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    let startIndex = 0;
    if (this.hasSelectColumn()) {
      startIndex = 1;
    }
    this.columns.forEach((element) => {
      element.index = startIndex;
      startIndex++;
    });

    const column = this.columns[event.currentIndex];
    if (this.isColumnDisplayed(column.name)) {
      this.columns.forEach((element) => {
        if (element.checked) {
          this.modifyColumn(element.name, element.index + 2, false);
          this.modifyColumn(element.name, element.index + 2, true);
        }
      });
    }
  }

  hasSelectColumn() {
    if (this.displayedColumns[0]) {
      return this.displayedColumns[0] === 'select';
    }
    return false;
  }

  isColumnDisplayed(columnName) {
    for (const displayedColumn of this.displayedColumns) {
      if (displayedColumn === columnName) {
        return true;
      }
    }
    return false;
  }

  modifyColumn(columnName, index, $event) {
    if ($event === true || $event.checked) {
      let prevObjIndex;
      let currentElementIndex;
      let firstGreaterIndex = this.displayedColumns.length - 1;
      this.displayedColumns.forEach((element, elementIndex) => {
        const obj = this.columns.find((column) => column.name === element);
        if (obj && obj.index > index) {
          currentElementIndex = elementIndex - 1;
          firstGreaterIndex =
            prevObjIndex && obj.index < prevObjIndex
              ? firstGreaterIndex
              : currentElementIndex;
          prevObjIndex = obj.index;
        }
      });
      if (index >= this.displayedColumns.length) {
        if (
          this.displayedColumns[this.displayedColumns.length - 2] !== 'locked'
        ) {
          this.displayedColumns.splice(firstGreaterIndex, 0, columnName);
        } else {
          this.displayedColumns.splice(
            this.displayedColumns.length - 2,
            0,
            columnName
          );
        }
      } else {
        this.displayedColumns.splice(index, 0, columnName);
      }
      this.columns.filter((column) => column.name === columnName)[0].checked =
        true;
    } else if (
      ($event === false || !$event.checked) &&
      this.displayedColumns.indexOf(columnName) > -1
    ) {
      this.displayedColumns.splice(
        this.displayedColumns.indexOf(columnName),
        1
      );
      this.columns.filter((column) => column.name === columnName)[0].checked =
        false;
    }
  }
}
