export const sapSubLedgerAccountOptions: any[] = [
  {
    value: true,
    translateName: 'GENERAL.TRUE',
  },
  {
    value: false,
    translateName: 'GENERAL.FALSE',
  },
];
