<div class="content-container" id="content-container-task">
  <ng-template appComponentLoader></ng-template>
</div>
<div class="button-container" *ngIf="!editableTask && !tciTask && !objectionManagementTask && !sepaMandateSetNewTask">
  <span *ngIf="currentTask" class="control-buttons">
    <ng-container *hasPermission="'tm.ft'">
      <button mat-button id="{{ entityName }}-accept-button" class="custom-button" (click)="forwardTask()">
        {{ 'GENERAL.FORWARD' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.uf.c'">
      <button mat-button class="custom-button" (click)="startUploadFile()" id="{{ entityName }}-upload-button">
        {{ 'GENERAL.UPLOAD-FILE' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        *ngIf="showApproveRejectButtons"
        mat-button
        id="{{ entityName }}-reject-button"
        class="custom-button"
        (click)="rejectTask()"
      >
        {{ 'GENERAL.REJECT' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        *ngIf="showApproveRejectButtons"
        mat-button
        id="{{ entityName }}-accept-button"
        class="custom-button"
        (click)="acceptTask()"
      >
        {{ 'GENERAL.ACCEPT' | translate }}
      </button>
    </ng-container>
    <ng-container *hasPermission="'tm.u'">
      <button
        *ngIf="!updateTask && (!isCreateDisbursementTask() || !isAwContactPersonUpdateTask()) && !isApprovalTask"
        mat-button
        id="{{ entityName }}-complete-button"
        class="custom-button"
        (click)="acceptTask()"
        [disabled]="isCompleteButtonDisabled()"
      >
        {{ 'GENERAL.COMPLETE-TASK' | translate }}
      </button>
    </ng-container>
  </span>
</div>
