<section class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <div [hidden]="!dataSource">
      <table
        aria-hidden="true"
        mat-table
        [dataSource]="dataSource"
        matSort
        id="{{ entityName }}-historical-values-table"
        multiTemplateDataRows
        matSortActive="validFrom"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="defaultValue">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            id="{{ entityName }}-table-historical-values-currentValue"
          >
            {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALUE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-historical-values-currentValue-{{ i }}"
          >
            {{ element.defaultValue }}
          </td>
        </ng-container>

        <ng-container matColumnDef="validFrom">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="v25-th"
            id="{{ entityName }}-table-historical-values-validFrom"
          >
            {{ 'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.VALID-FROM' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-historical-values-validFrom-{{ i }}"
          >
            {{ element.validFrom | date: dateFormat }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = index" class="element-row"></tr>
      </table>
      <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</section>
