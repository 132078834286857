export class QuantityLabelledGood {
  id: string;
  suppliedTo: string;
  suppliedFrom: string;
  name: string;
  goodsGroupId: string;
  type: string;
  quantity: number;
  comment: string;
  remove: string;
  index: number;
  receivers: any;
  draftGuid: string;
  draft: any;
  draftId: string;

  filteredReceivers: any;

  constructor(index: number, receivers: any) {
    this.index = index;
    this.receivers = receivers;
    this.filterReceivers('');
  }

  public filterReceivers(search: string) {
    if (!search) {
      this.filteredReceivers = this.receivers;
    } else {
      this.filteredReceivers = this.receivers.filter((element) => {
        return element.accountNumber
          .toString()
          .toUpperCase()
          .includes(search.toUpperCase());
      });
    }
  }
}
