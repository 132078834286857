<div class="content-container">
  <div class="form" [formGroup]="form">
    <app-mat-select
      [name]="'REPORTING.REPORT'"
      [placeholder]="'REPORTING.REPORTPLACEHOLDER'"
      [label]="'REPORTING.REPORT'"
      [entityName]="entityName"
      [form]="form"
      [controlObject]="form.controls.report"
      [controlName]="'report'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [options]="reports"
      [optionValue]="''"
      [selectedOption]="''"
      [translationOption]="'visibleName'"
      [optionTranslationText]="''"
      [customOptionNameFunction]="null"
      [customTaskValueFunction]="null"
    ></app-mat-select>
  </div>
  <powerbi-report
    *ngIf="reportSelectedType === 'PowerBIReport'"
    [embedConfig]="reportConfig"
    [cssClassName]="reportClass"
  >
  </powerbi-report>
  <powerbi-paginated-report
    *ngIf="reportSelectedType === 'PaginatedReport'"
    [embedConfig]="reportConfig"
    [cssClassName]="reportClass"
  >
  </powerbi-paginated-report>
</div>
