import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { models } from 'powerbi-client';
import { PowerbiService } from '../../../../../shared/services/powerbi/powerbi.service';
import { first } from 'rxjs/operators';
import { IReportEmbedConfiguration } from 'embed';
import { FilterType, IBasicFilter } from 'powerbi-models';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-livestock-settlement-periods',
  templateUrl: './livestock-settlement-periods.component.html',
  styleUrls: ['./livestock-settlement-periods.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LivestockSettlementPeriodsComponent implements OnInit, OnDestroy {
  @Input() livestockProducerId: string;

  private languageSubscription: Subscription;
  private reportSuffixesByLanguage = {
    de: 'GER',
    en: 'ENG',
  };

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: '',
    accessToken: '',
    viewMode: models.ViewMode.View,
    tokenType: models.TokenType.Embed,
    filters: [],
    settings: {},
    hostname: 'https://app.powerbi.com',
  };
  reportClass = 'report-container';

  constructor(
    private powerBIService: PowerbiService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.languageSubscription =
      this.translationService.currentSelectedLanguage.subscribe((language) =>
        this.loadPowerBiReportForLanguage(language)
      );
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  /**
   * Embeds the PowerBI report for Settlement Periods in its designated HTML
   * place for the given language. Available versions of this report per
   * language can be found in the PowerBIAPI project's database or ask Volker
   * Greaves for assistance in this matter.
   *
   * @param language
   *   An ISO- two-letter language identifier (e.g. `en` for English, `de` for
   *   German). It should be included in the `reportSuffixesByLanguage`;
   *   otherwise, the English report will be used.
   */
  private loadPowerBiReportForLanguage(language: string) {
    if (!this.livestockProducerId) return;

    const reportNameSuffix = this.reportSuffixesByLanguage[language] || 'ENG';
    this.powerBIService
      .getEmbeddedTokenByName(
        'emb_aw_livestock_producer_UI_Period_Budget_' + reportNameSuffix
      )
      .pipe(first())
      .subscribe((embeddedToken) => {
        this.reportConfig = this.buildEmbedConfiguration(embeddedToken);
      });
  }

  private buildEmbedConfiguration(embedToken): IReportEmbedConfiguration {
    return {
      type: 'report',
      embedUrl: embedToken['embedUrl'],
      accessToken: embedToken['embedToken'],
      viewMode: models.ViewMode.View,
      tokenType: models.TokenType.Embed,
      filters: [this.buildFilter()],
      settings: {
        localeSettings: {
          formatLocale:
            this.translationService.getCurrentLanguage() === 'de' ? 'de' : 'us',
          language: this.translationService.getCurrentLanguage(),
        },
        panes: {
          filters: {
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
      },
      hostname: 'https://app.powerbi.com',
    };
  }

  private buildFilter(): IBasicFilter {
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      filterType: FilterType.Basic,
      target: {
        table: 'Livestock Producer UI Master Data',
        column: 'livestock_producer_id',
      },
      operator: 'In',
      values: [this.livestockProducerId],
    };
  }
}
