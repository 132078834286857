import { Component, OnInit, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatInputComponent implements OnInit {
  @Input() name;
  @Input() placeholder;
  @Input() form;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() formGroupCustomName;
  @Input() maskValue;
  @Input() suffix;
  @Input() thousandSeparator;
  @Input() decimalMarker;
  @Input() labelSuffix;
  @Input() type;
  @Input() changeFunction;
  @Input() appearance;
  @Input() titlecasePipe;
  @Input() decimalLimitation;
  @Input() maxLength;
  @Input() minLength;
  @Input() changeInputValueFunction;
  @Input() textTransform;
  @Input() disabled;
  @Input() sideMenu;
  @Input() formGroupCustomFunction;
  @Input() inline;
  @Input() elementIndex;
  @Input() nameParamsValue;
  @Input() twoColumns;
  @Input() addNumbers;
  @Input() hasSuffixIcon;
  @Input() floatLabel = 'auto';

  errorRequired = 'error-message-required';
  errorInvalid = 'error-message-invalid';
  errorFormat = 'error-message-format';
  passwordConditionsInvalid = 'error-message-password';
  positiveNumberValidationError = 'error-message-positive-number';
  newValue: any;
  formGroup: any;
  visible = false;

  requiredValidator = false;
  changedValue = false;

  constructor(
    private parentForm: FormGroupDirective,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.changedValue = this.hasChanged(this.controlName);
    });

    if (this.parentForm.form.controls[this.formGroupCustomName]) {
      this.formGroup = this.parentForm.form.controls[this.formGroupCustomName];
    } else if (
      this.formGroupCustomName != null &&
      this.formGroupCustomName !== ''
    ) {
      this.formGroup = this.formGroupCustomName;
    } else if (this.formGroupCustomFunction != null) {
      this.formGroup = this.formGroupCustomFunction();
    } else {
      this.formGroup = this.parentForm.form;
    }

    if (this.controlObject && this.controlObject.validator) {
      const validator = this.controlObject.validator({} as AbstractControl);
      if (validator) {
        this.requiredValidator = validator.required;
      }
    }

    this.changedValue = this.hasChanged(this.controlName);
  }

  hasChanged(fieldName: string) {
    if (this.formGroupCustomName) {
      if (
        this.form &&
        this.form.controls[this.formGroupCustomName] &&
        this.form.controls[this.formGroupCustomName][fieldName] &&
        !this.form.controls[this.formGroupCustomName].controls[fieldName]
          .enabled
      ) {
        return false;
      }
    } else {
      if (
        this.form &&
        this.form.controls[fieldName] &&
        !this.form.controls[fieldName].enabled
      ) {
        return false;
      }
    }

    if (this.taskValue && this.approvedValue) {
      let newFieldValue;
      if (this.newValue) {
        if (this.formGroupCustomName) {
          newFieldValue = this.newValue[this.formGroupCustomName][fieldName];
        } else {
          newFieldValue = this.newValue[fieldName];
        }
      }
      if (this.newValue && newFieldValue) {
        return newFieldValue !== this.approvedValue;
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (this.approvedValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.approvedValue !==
          this.newValue[this.formGroupCustomName][fieldName]
        );
      } else {
        return this.approvedValue !== this.newValue[fieldName];
      }
    } else if (
      (this.approvedValue === undefined || this.approvedValue === null) &&
      this.taskValue &&
      this.newValue
    ) {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !== this.taskValue
        );
      } else {
        return this.newValue[fieldName] !== this.taskValue;
      }
    } else if (this.approvedValue === '') {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !==
          this.approvedValue
        );
      } else {
        if (this.newValue) {
          return (
            (this.newValue[fieldName] ? this.newValue[fieldName] : '') !==
            this.approvedValue
          );
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  hasChangedTask() {
    if (this.approvedValue || this.approvedValue === '') {
      return this.approvedValue !== this.taskValue;
    }

    return false;
  }

  change() {
    if (this.changeFunction) {
      return this.changeFunction();
    }
  }

  changeInputValue() {
    this.removeHTMLTags(this.controlObject?.value);
    if (this.changeInputValueFunction) {
      return this.changeInputValueFunction();
    }
  }

  validateSpecialValidations(event: any) {
    let input;
    let reg;

    if (this.decimalLimitation) {
      if (event.target.value.split(this.suffix).length > 1) {
        input = event.target.value.split(this.suffix)[0] + event.key;
      } else {
        input = event.target.value + event.key;
      }

      if (this.decimalLimitation === 0) {
        reg = /^[0-9]*(\[0-9]{0,0})?$/;
      } else if (this.decimalLimitation === 2) {
        reg = /^[0-9]*(\.[0-9]{0,2})?$/;
      } else if (this.decimalLimitation === 4) {
        reg = /^[0-9]*(\.[0-9]{0,4})?$/;
      }
      if (!reg.test(input)) {
        event.preventDefault();
      }
    } else if (this.type === 'number') {
      input = event.target.value + event.key;
      reg = /^[0-9]*\.?[0-9]*$/;

      if (!reg.test(input)) {
        event.preventDefault();
      }
    }
  }

  getLabelWithParams() {
    return this.translateService.instant(this.label, {
      value: this.translateService.instant(this.nameParamsValue),
    });
  }

  removeHTMLTags(inputString: string) {
    if (!!inputString) {
      const pattern = /\<.*?\>/g;
      // Use regex_replace function in regex to erase every tags enclosed in <>
      const normalizedString = String(inputString).replace(pattern, '');
      this.controlObject.setValue(normalizedString);
    }
  }
}
