<div class="content-container">
  <div class="privacy-policy-container">
    <div class="row mt-row title-container underlined">
      <span>Datenschutzhinweise</span>
    </div>
    <div class="row mt-row text-container">
      <span>Sie haben Fragen zum Datenschutz? Hier finden Sie die passenden Informationen.</span>
    </div>
    <div class="row mt-row title-container underlined">
      <span>Datenschutzhinweise zu unserer Webseite</span>
    </div>
    <div class="row mt-row">
      <ul>
        <li>Infos zur Nutzung der Webseite</li>
      </ul>
    </div>
    <div class="row mt-row title-container">
      <span>Datenschutzhinweise zu unserer Webseite</span>
    </div>
    <div class="row mt-row title-container">
      <span>Stand 04/2021</span>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="1">
        <li>Wer ist für die Verarbeitung meiner Daten verantwortlich?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span>Die</span>
    </div>
    <div class="address-container mt-row">
      <div class="row text-container">
        <span>BFS finance GmbH</span>
      </div>
      <div class="row text-container">
        <span>Carl-Bertelsmann-Str.23</span>
      </div>
      <div class="row text-container">
        <span>33332 Gütersloh</span>
      </div>
      <div class="row text-container">
        <span><a href="mailto:info@bfs-finance.com">info@bfs-finance.com</a></span>
      </div>
      <div class="row text-container">
        <span>Tel.: 05241 – 80 43199</span>
      </div>
      <div class="row text-container">
        <span>Fax: 05241 – 80 43130</span>
      </div>
    </div>
    <div class="row mt-row text-container">
      <span
        >(nachfolgend „wir“ oder „<span class="title-container">BFS</span>“ genannt) sind für die Verarbeitung Ihrer
        Daten auf dieser Webseite verantwortlich.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Unseren Datenschutzbeauftragten erreichen Sie unter der oben genannten Postadresse sowie unter der
        E-Mail-Adresse: <a href="mailto:datenschutz@bfs-finance.com">datenschutz@bfs-finance.com</a> mit dem Zusatz „An
        den Datenschutzbeauftragten“.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="2">
        <li>Zu welchen Zwecken werden meine Daten verarbeitet?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Wenn Sie diese Webseite besuchen, werden vom aufrufenden Rechner automatisch Informationen erfasst (im
        Folgenden „<span class="title-container">Zugriffsdaten</span>“). Diese Zugriffsdaten beinhalten
        Server-Log-Files, die in der Regel aus Informationen über den Browsertyp und die Browserversion, dem
        Betriebssystem, dem Internet-Service-Provider, dem Datum und der Uhrzeit der Verwendung der Webseite, die zuvor
        besuchten Webseiten und über die Webseite neu aufgerufene Webseiten und die IP-Adresse des Rechners bestehen.
        Mit Ausnahme der IP-Adresse sind die Server-Log-Files nicht personenbeziehbar.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Wenn Sie die Dienste der Webseite verwenden, werden pseudonyme Nutzungsprofile und/oder die von Ihnen auf der
        Webseite eingegeben Daten (z.B. Suchworte, Login-Daten, Bewertungen, Formular- oder Vertragseingaben,
        Klickdaten) verarbeitet.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Unabhängig davon, ob Sie unsere Webseite als Gast oder als eingeloggter Teilnehmer nutzen, verarbeiten wir die
        oben beschriebenen Zugriffsdaten zu folgenden Zwecken:</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Zur <span class="title-container">Bereitstellung der Webseite</span> und
        <span class="title-container">Gewährleistung der technischen Sicherheit</span>, insbesondere zur Behebung
        technischer Fehler und um sicherzustellen, dass Unbefugte keinen Zugriff auf die Systeme der Webseite
        erlangen.</span
      >
    </div>
    <div class="ordered-list-container list-2 title-container row mt-row">
      <ol start="2">
        <li>Verarbeitung bei eingeloggten Teilnehmern</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Sobald Sie sich auf unserer Webseite einloggen, verarbeiten wir neben den in Ziffer 2 aufgeführten
        Zugriffsdaten auch Ihre <span class="title-container">Teilnehmerdaten</span>. Diese Datenverarbeitung ist
        erforderlich, um ein Benutzerkonto anzulegen und Ihnen im Rahmen der Erstellung, Verwendung, Pflege sowie
        Übersicht Ihrer Daten zu beteiligen.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="3">
        <li>Rechtsgrundlage der Datenverarbeitung</li>
      </ol>
    </div>
    <div class="ordered-list-container list-3 title-container row mt-row">
      <ol start="3">
        <li>Bereitstellung unserer Webseite</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Unsere Rechtsgrundlage für die Erfassung der Server-Log-Files ist Artikel 6 Abs. 1 lit. f DSGVO. In der
        Funktionalität der Webseite, der Durchführung von Sicherheitsanalysen und der Abwehr von Gefahren liegen unsere
        berechtigten Interessen.
      </span>
    </div>
    <div class="ordered-list-container list-4 title-container row mt-row">
      <ol start="3">
        <li>Nutzung der Webseite für eingeloggte Teilnehmer</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Nachdem Sie sich eingeloggt haben, stehen Ihnen alle von uns über unsere Webseite zur Verfügung gestellten
        Funktionen frei zur Nutzung. Unsere Rechtsgrundlage für die Verarbeitung ist Artikel 6 Abs. 1 lit. b DSGVO zur
        Vertragserfüllung, wenn Sie als Firmenkunde selbst handeln, oder Artikel 6 Abs. 1 lit. f DSGVO, wenn Sie als
        Ansprechpartner für einen Firmenkunden tätig werden.</span
      >
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          <span class="title-container">Korrespondenz über Kontaktformular, E-Mail- oder Telefonkontakt</span>: Sie
          erhalten über diese Webseite die Möglichkeit, uns über ein Kontaktformular, eine E-Mail-Adresse, eine Telefon-
          oder Faxnummer zu kontaktieren. Nehmen Sie diese Möglichkeit wahr, so werden hierbei mitgeteilte Daten (z.B.
          Ihre E-Mail-Adresse und/oder Ihre Telefonnummer sowie Ihr Anliegen) an uns übermittelt. Abhängig von dem
          Anliegen (z.B. Geltendmachung von Betroffenenrechten) werden Ihre Kontaktdaten zur Bearbeitung Ihres Anliegens
          verarbeitet. Soweit hierzu erforderlich, können Ihre Angaben auch an Dritte (z.B. Partnerunternehmen)
          weitergeleitet werden.
        </li>
        <li>
          Wenn Sie diese Webseite als Ansprechpartner eines Vertragspartners nutzen (im Folgenden „Ansprechpartner“)
          wird ein <span class="title-container">Benutzerkonto</span> für Ansprechpartner des Vertragspartners (im
          Folgenden „Ansprechpartnerdaten“) angelegt. Dieser Datensatz besteht aus folgenden Pflichtangaben:
          Kontaktdaten, Geschäftsadresse und Anmeldedaten. Ferner können optional weitere geschäftliche Kontaktdaten
          hinterlegt werden.
        </li>
        <li>
          Ansprechpartner / BFS legt/legen einen Firmenkundendatensatz in unserem BeVision-System an. Dieser enthält je
          nach Rechtsform des Vertragspartners variierende Pflichtangaben, die in dem BeVision-System als solche
          gekennzeichnet sind und gemeinsam von BFS und Firmenkunde/Ansprechpartner hinterlegt werden. Die für die
          jeweilige Dienstleistung zu verarbeitenden Datenkategorien finden Sie in den entsprechend
          Datenschutzhinweisen.
        </li>
      </ul>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="4">
        <li>Dauer der Speicherung</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Nach Aufruf der Webseite werden die Server-Log-Files auf dem Webserver gespeichert und die darin enthaltende
        IP-Adresse wird spätestens nach 30 Tagen gelöscht. Eine Auswertung während dieser Speicherdauer erfolgt im Fall
        eines Angriffs oder zur Fehlersuche.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="5">
        <li>Empfängerkategorien hinsichtlich Ihrer Daten</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Bei BFS erhalten diejenigen Stellen Zugriff auf Daten, die diese zur Erfüllung der unter Ziffer 2 dargestellten
        Zwecke benötigen.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Auch können von uns eingesetzte Dienstleister (im Folgenden „<span class="title-container"
          >Auftragsverarbeiter</span
        >“) Zugriff auf Ihre Daten erhalten (wie z.B. Rechenzentrum). Über Verträge zur Auftragsverarbeitung sind die
        Weisungsgebundenheit, die Datensicherheit und der vertrauliche Umgang mit Ihren Daten durch unsere Dienstleister
        sichergestellt.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Zur Identifizierung sowie Komplettierung und Validierung stellen wir ggfs., falls für die
        Verarbeitungstätigkeit notwendig, Anfragen an <span class="title-container">Datenprovider</span> denen wir den
        erforderlichen Minimaldatensatz übermitteln.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span
        >Weitere Empfänger der im BeVision-System gespeicherten Daten können interne und externe Revisoren oder
        Wirtschaftsprüfer sowie Behörden (z.B. BaFin) oder von diesen beauftragte Prüfer (zusammengefasst als „<span
          class="title-container"
          >Prüfer</span
        >“) sein.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="6">
        <li>Werden meine Daten außerhalb der EU bzw. des EWR verarbeitet (Drittlandtransfer)?</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Soweit die unter Ziffer 4 genannten Empfänger außerhalb der EU bzw. des EWR Ihre Daten verarbeiten, kann dies
        dazu führen, dass Ihre Daten in ein Land übermittelt werden, welches nicht den gleichen Datenschutzstandard wie
        die Europäische Union gewährleistet. In diesem Fall stellen wir, außer bei Vorliegen eines Anwendungsfalls des
        Artikel 49 DSGVO, sicher, dass die Empfänger vertraglich oder auf andere Weise ein gleichwertiges
        Datenschutzniveau garantieren. Sie können über die unter Ziffer 7 genannte Kontaktadresse eine Kopie dieser
        Garantien anfordern.</span
      >
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="7">
        <li>Welche Datenschutzrechte habe ich?</li>
      </ol>
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          <span class="title-container">Recht auf Auskunft:</span> Sie haben jederzeit das Recht, Auskunft über die bei
          uns zu Ihrer Person gespeicherten persönlichen Daten zu erhalten.
        </li>
        <li>
          <span class="title-container">Recht auf Berichtigung:</span> Sollten Daten über Ihre Person falsch oder nicht
          mehr aktuell sein, haben Sie das Recht, deren Berichtigung zu verlangen.
        </li>
        <li>
          <span class="title-container">Recht auf Löschung oder Einschränkung der Datenverarbeitung:</span>
          Sie haben außerdem das Recht, die Löschung oder Einschränkung der Verarbeitung Ihrer Daten nach Maßgabe von
          Artikel 17 bzw. Artikel 18 DSGVO zu verlangen.
        </li>
        <li>
          <span class="title-container">Recht auf Datenübertragbarkeit:</span> Sofern Sie uns Daten bereitgestellt
          haben, haben Sie das Recht, die Sie betreffenden Daten in einem strukturierten, gängigen und maschinenlesbaren
          Format zu erhalten und Sie haben das Recht, die Daten einem anderen Verantwortlichen, dem die Daten
          bereitgestellt wurden, zu übermitteln, sofern unsere Verarbeitung auf Ihrer Einwilligung oder auf einem
          Vertrag mit Ihnen beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </li>
        <li>
          <span class="title-container">Widerspruchsrecht:</span> Nach Maßgabe des Artikels 21 Abs. 1 DSGVO haben Sie
          das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          Ihrer Daten, die nach der Rechtsgrundlage des Artikels 6 Abs. 1 lit. f DSGVO erfolgt, Widerspruch einzulegen.
        </li>
      </ul>
    </div>
    <div class="row mt-row text-container">
      <span
        >Bitte wenden Sie sich zur Geltendmachung Ihrer Datenschutzrechte an:
        <a href="mailto:datenschutz@bfs-finance.com">datenschutz@bfs-finance.com</a>.</span
      >
    </div>
    <div class="row mt-row">
      <ul>
        <li>
          Daneben haben Sie die Möglichkeit, sich an eine Datenschutzbehörde zu wenden und dort
          <span class="title-container">Beschwerde </span>
          einzureichen. Die für die Firma zuständige Behörde ist der Landesbeauftragte für Datenschutz und
          Informationsfreiheit NRW, Kavalleriestraße 2-4, 40213 Düsseldorf. Sie können sich aber auch an die für Ihren
          Wohnort zuständige Datenschutzbehörde wenden.
        </li>
      </ul>
    </div>
    <div class="ordered-list-container title-container row mt-row">
      <ol start="8">
        <li>Schluss/Versionierungsinformation</li>
      </ol>
    </div>
    <div class="row mt-row text-container">
      <span
        >Im Zuge der Weiterentwicklung der Webseite und der Implementierung neuer Technologien, um unseren Service für
        Sie zu verbessern, können Änderungen dieser Datenschutzhinweise erforderlich werden. Daher empfehlen wir Ihnen,
        sich diese Datenschutzhinweise von Zeit zu Zeit erneut durchzulesen. Der Stand der Datenschutzhinweise wird hier
        angezeigt: 04/2021.</span
      >
    </div>
    <div class="row mt-row text-container">
      <span>Stand 04/2021 </span>
    </div>
  </div>
</div>
