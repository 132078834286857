import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TaskListComponent } from './task-list/task-list.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TaskOverviewComponent } from './task-overview/task-overview.component';
import { TaskFilterComponent } from './task-filter/task-filter.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatGridListModule } from '@angular/material/grid-list';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatIconModule } from '@angular/material/icon';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { TranslateModule } from '@ngx-translate/core';
import { BuCreateComponent } from '../business-unit/bu-create/bu-create.component';
import { CompanyCreateComponent } from '../company/company-create/company-create.component';
import { SharedModule } from '../../shared/shared.module';
import { CompanyModule } from '../company/company.module';
import { BackButtonModule } from '../../shared/dialogs/back-button/back-button.module';
import { BusinessUnitModule } from '../business-unit/business-unit.module';
import { TaskManagementRoutingModule } from './task-management-routing.module';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { UserSelectModalComponent } from 'src/app/shared/modals/user-select-modal/user-select-modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SaveFavoriteFilterModalComponent } from 'src/app/shared/modals/save-favorite-filter-modal/save-favorite-filter-modal.component';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TaskDetailsButtonsComponent } from './task-details/task-details-buttons/task-details-buttons.component';

@NgModule({
    declarations: [
        TaskListComponent,
        TaskOverviewComponent,
        TaskFilterComponent,
        TaskDetailsComponent,
        TaskEditComponent,
        TaskDetailsButtonsComponent,
    ],
    imports: [
        CommonModule,
        TaskManagementRoutingModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        BusinessUnitModule,
        MatMenuModule,
        MatCheckboxModule,
        TranslateModule,
        MatSnackBarModule,
        SharedModule,
        CompanyModule,
        BackButtonModule,
        MatOptionModule,
        MatDialogModule,
        MatSelectModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        MatExpansionModule,
        FlexLayoutModule,
        MatProgressSpinnerModule,
    ],
    providers: [DatePipe]
})
export class TaskManagementModule {}
