import { debitCreditCodeOptions } from './enum/debit-credit-code-options';
import { debtCollectionOptions } from './enum/debt-collection-options';
import { settlemenetInEuroOptions } from './enum/settlement-in-eur-options';

const cc = require('currency-codes');

export const openTransactionEditableTableFieldsConf: any[] = [
  {
    type: 'select',
    columnName: 'select',
    options: [],
    translateName: null,
  },
  {
    type: 'icons',
    columnName: 'icons',
    translateName: null,
    iconName: 'not_interested',
    class: 'orange-icon',
  },
  {
    type: 'insideSearch',
    columnName: 'searchPartner',
  },
  {
    type: 'selectInfiniteScroll',
    columnName: 'businessPartner',
    translateName: 'OPEN-TRANSACTIONS.TABLE.BUSINESS-PARTNER',
    multipleSelection: false,
    subControls: [
      {
        pageIndex: 0,
        pageSize: 25,
        subControlName: 'businessPartnerSelection',
        selectedOption: null,
        showValue: ['visibleId', 'name'],
        rowNumber: 0,
        options: [],
      },
    ],
  },
  {
    type: 'selectInput',
    columnName: 'businessRelationship',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.BUSINESS-RELATIONSHIP',
    displayOption: 'visibleId',
    optionValue: null,
  },
  {
    type: 'selectInfiniteScroll',
    columnName: 'externalId',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.EXTERNAL-ID',
    multipleSelection: false,
    subControls: [
      {
        pageIndex: 0,
        pageSize: 25,
        subControlName: 'externalIdSelection',
        selectedOption: null,
        showValue: 'externalId',
        rowNumber: 0,
        options: [],
      },
    ],
  },
  {
    type: 'input',
    columnName: 'clientOpenItemId',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-ID',
  },
  {
    type: 'date',
    columnName: 'openItemDate',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-DATE',
  },
  {
    type: 'selectInput',
    columnName: 'transactionCode',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.TRANSACTION-CODE',
    displayOption: 'transactionCode',
    optionValue: 'transactionCode',
    formatDisplayOption: (displayOption) => {
      return displayOption.toString().padStart(3, '0');
    },
  },
  {
    type: 'selectInput',
    columnName: 'debitOrCredit',
    options: debitCreditCodeOptions,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBIT-CREDIT-CODE',
  },
  {
    type: 'input',
    columnName: 'amount',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.AMOUNT',
    maskValue: 'separator.2',
    thousandSeparator: '.',
    decimalMarker: ',',
  },
  {
    type: 'selectInput',
    columnName: 'currency',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.CURRENCY-CODE',
    subType: 'currencyCodes',
  },
  {
    type: 'input',
    columnName: 'vatAmount',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.VAT-AMOUNT',
    maskValue: 'separator.2',
    thousandSeparator: '.',
    decimalMarker: ',',
    min: 0,
    formError: 'limitError',
    keyDownSpecialFunction: 'NO_EMPTY',
  },
  {
    type: 'input',
    columnName: 'vatPercent',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.VAT-PERCENT',
    suffixValue: '%',
    min: 0,
    keyDownSpecialFunction: 'NO_EMPTY',
    thousandSeparator: '.',
    decimalMarker: ',',
    maskValue: 'separator.2',
  },
  {
    type: 'date',
    columnName: 'dueDate',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.DUE-DATE',
    formError: 'dateError',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'selectInput',
    columnName: 'debtCollection',
    options: debtCollectionOptions,
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'date',
    columnName: 'debtCollectionTransferDate',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION-TRANSFER-DATE',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'selectInput',
    columnName: 'settlementInEur',
    options: settlemenetInEuroOptions,
    translateName: 'OPEN-TRANSACTIONS.TABLE.SETTLEMENT-EUR',
    allowNullSelection: true,
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'input',
    columnName: 'referenceDocumentationId',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.REFERENCE-DOCUMENTATION-ID',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'input',
    columnName: 'additionalInformation',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.ADDITIONAL-INFORMATION',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'input',
    columnName: 'branchId',
    options: [],
    translateName: 'OPEN-TRANSACTIONS.TABLE.BRANCH-ID',
    keyDownSpecialFunction: 'NEW_LINE',
  },
  {
    type: 'actions',
    columnName: 'actions',
  },
  {
    version: 2.4,
  },
];
