<!-- <nav mat-tab-nav-bar>
  <a
    mat-tab-link
    [routerLink]="['dashboardCenter']"
    *ngIf="hasRoleForPath('dashboardCenter')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-dashboardCenter"
    >{{ "DASHBOARD.DASHBOARD" | translate }}</a
  >
  <a
    mat-tab-link
    [routerLink]="['messageCenter']"
    *ngIf="hasRoleForPath('messageCenter')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-messageCenter"
    >{{ "DASHBOARD.MESSAGES" | translate }}</a
  >

  <a
    mat-tab-link
    [routerLink]="['reports']"
    *ngIf="hasRoleForPath('reports')"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    id="{{ entityName }}-menu-reports"
    >{{ "DASHBOARD.REPORTING" | translate }}</a
  >
</nav> -->
<div class="full-height">
  <router-outlet></router-outlet>
</div>
