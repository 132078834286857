export const statusTypes: any[] = [
  {
    code: 0,
    name: 'INCOMPLETE',
    translateName: 'BOOKING-CODE.STATUS-TYPES.INCOMPLETE',
  },
  {
    code: 1,
    name: 'USED',
    translateName: 'BOOKING-CODE.STATUS-TYPES.USED',
  },
  {
    code: 2,
    name: 'NOT_USED',
    translateName: 'BOOKING-CODE.STATUS-TYPES.NOT-USED',
  },
  {
    code: 3,
    name: 'INACTIVE',
    translateName: 'BOOKING-CODE.STATUS-TYPES.INACTIVE',
  },
];
