<app-back-button></app-back-button>
<div class="tab-container">
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      [routerLink]="['btc']"
      *hasPermission="'sc.btc.r'"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-btc"
      >{{ 'SYSTEM-CONFIGURATION.BTC.BUSINESS-TRANSACTION-CODES' | translate }}</a
    >
    <a
      mat-tab-link
      [routerLink]="['btcMapping']"
      *hasPermission="'sc.btc.m.r'"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-btc-mapping"
      >{{ 'SYSTEM-CONFIGURATION.BTC-MAPPING.BTC-MAPPING' | translate }}</a
    >
    <a
      mat-tab-link
      [routerLink]="['accountStatementConfiguration']"
      *hasPermission="'sc.asc.r'"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-account-statement-configuration"
      >{{ 'SYSTEM-CONFIGURATION.ACCOUNT-STATEMENT-CONFIGURATION' | translate }}</a
    >
    <a
      mat-tab-link
      [routerLink]="['upload']"
      routerLinkActive
      #rla="routerLinkActive"
      routerLinkActive="active-link"
      [active]="rla.isActive"
      id="{{ entityName }}-menu-general-upload"
      >{{ 'GENERAL.UPLOAD' | translate }}</a
    >
  </nav>
</div>
<div class="content-container">
  <router-outlet></router-outlet>
</div>
