import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessUnitPoolService } from '../../services/business-unit-pool/business-unit-pool.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-new-business-unit-pool-modal',
  templateUrl: './new-business-unit-pool-modal.component.html',
  styleUrls: ['./new-business-unit-pool-modal.component.less'],
})
export class NewBusinessUnitPoolModalComponent implements OnInit {
  newBUPoolForm: UntypedFormGroup;
  entityName = 'poolAllocation';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private businessUnitPoolService: BusinessUnitPoolService,
    private dialogRef: MatDialogRef<NewBusinessUnitPoolModalComponent>
  ) {}

  ngOnInit(): void {
    this.newBUPoolForm = this.formBuilder.group({
      name: ['', Validators.required],
      exclusiveAllocation: [false],
    });
  }

  save() {
    const request = {
      name: this.newBUPoolForm.get('name').value,
      exclusiveAllocation: this.newBUPoolForm.get('exclusiveAllocation').value,
    };
    this.businessUnitPoolService
      .createBusinessUnitPool(request)
      .subscribe((newCreatedPoolId) => {
        this.dialogRef.close({ id: newCreatedPoolId });
      });
  }
}
