import { TableTypes } from '../../../default-table/table-types';

export const userManagementRoleKnownUserColumnsConf: any[] = [
  {
    name: 'flags',
    index: 0,
    checked: true,
    translateName: 'INFO-FLAG',
    locked: true,
    smallColumn: true,
  },
  {
    name: 'id',
    index: 1,
    checked: true,
    translateName: 'ID',
    locked: true,
  },
  {
    name: 'userName',
    index: 3,
    checked: true,
    translateName: 'USER-MANAGEMENT.USER-NAME',
    locked: true,
  },
  {
    name: 'title',
    index: 4,
    checked: true,
    translateName: 'USER-MANAGEMENT.TITLE',
    locked: true,
  },
  {
    name: 'lastName',
    index: 5,
    checked: true,
    translateName: 'USER-MANAGEMENT.FIRST-NAME',
    locked: true,
  },
  {
    name: 'email',
    index: 6,
    checked: true,
    translateName: 'USER-MANAGEMENT.EMAIL',
    locked: true,
  },
  {
    name: 'telephoneNumber',
    index: 7,
    checked: true,
    translateName: 'USER-MANAGEMENT.PHONE-NUMBER',
    locked: true,
  },
  {
    name: 'companyName',
    index: 8,
    checked: true,
    translateName: 'USER-MANAGEMENT.COMPANY-NAME',
    locked: true,
  },
  {
    name: 'lastLogin',
    index: 9,
    checked: true,
    translateName: 'USER-MANAGEMENT.LAST-LOGIN',
    locked: true,
  },
  {
    name: 'createdOn',
    index: 10,
    checked: false,
    translateName: 'GENERAL.CREATED-ON',
    locked: false,
    type: TableTypes.DATE,
    withHour: true,
  },
  {
    name: 'createdBy',
    index: 11,
    checked: false,
    translateName: 'GENERAL.CREATED-BY',
    locked: false,
  },
  {
    name: 'changedBy',
    index: 12,
    checked: false,
    translateName: 'USER-MANAGEMENT.CHANGED-BY',
    locked: false,
  },
  {
    name: 'changedOn',
    index: 13,
    checked: false,
    translateName: 'USER-MANAGEMENT.CHANGED-ON',
    locked: false,
    type: TableTypes.DATE,
    withHour: true,
  },
  {
    name: 'online',
    index: 14,
    checked: true,
    translateName: 'USER-MANAGEMENT.ONLINE',
    locked: true,
    translatePrefix: 'USER-MANAGEMENT.USER.ONLINE.',
  },
  {
    name: 'contactType',
    index: 15,
    checked: false,
    translateName: 'USER-MANAGEMENT.CONTACT-TYPE',
    locked: false,
  },
  {
    name: 'team',
    index: 16,
    checked: false,
    translateName: 'USER-MANAGEMENT.TEAM',
    locked: false,
  },
  {
    name: 'blockingReason',
    index: 18,
    checked: false,
    translateName: 'USER-MANAGEMENT.BLOCKING-REASON',
    locked: false,
  },
  {
    name: 'internalUser',
    index: 19,
    checked: false,
    translateName: 'USER-MANAGEMENT.INTERNAL-EXTERNAL',
    locked: false,
    translatePrefix: 'USER-MANAGEMENT.INTERNAL.',
  },
  {
    name: 'masterLimitApproval',
    index: 20,
    checked: false,
    translateName: 'USER-MANAGEMENT.MASTER-LIMIT-APPROVAL',
    locked: false,
  },
  {
    name: 'masterCompetenceCredit',
    index: 21,
    checked: false,
    translateName: 'USER-MANAGEMENT.MASTER-COMPETENCE-CREDIT',
    locked: false,
  },
  {
    name: 'masterCompetenceDebit',
    index: 22,
    checked: false,
    translateName: 'USER-MANAGEMENT.MASTER-COMPETENCE-DEBIT',
    locked: false,
  },
  {
    name: 'disbursementDebitSideFirst',
    index: 23,
    checked: false,
    translateName: 'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-FIRST',
    locked: false,
  },
  {
    name: 'disbursementDebitSideSecond',
    index: 24,
    checked: false,
    translateName: 'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-SECOND',
    locked: false,
  },
  {
    name: 'disbursementCreditSideFirst',
    index: 25,
    checked: false,
    translateName: 'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-FIRST',
    locked: false,
  },
  {
    name: 'disbursementCreditSideSecond',
    index: 26,
    checked: false,
    translateName: 'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-SECOND',
    locked: false,
  },
  {
    name: 'limitApprovalFirst',
    index: 27,
    checked: false,
    translateName: 'USER-MANAGEMENT.LIMIT-APPROVAL-FIRST',
    locked: false,
  },
  {
    name: 'limitApprovalSecond',
    index: 28,
    checked: false,
    translateName: 'USER-MANAGEMENT.LIMIT-APPROVAL-SECOND',
    locked: false,
  },
  {
    name: 'functionalRoles',
    index: 29,
    checked: false,
    translateName: 'USER-MANAGEMENT.FUNCTIONAL-ROLE',
    locked: false,
  },
  {
    name: 'iniProcessCompleted',
    index: 30,
    checked: false,
    translateName: 'USER-MANAGEMENT.INI-PROCESS-COMPLETED',
    locked: false,
  },
  {
    name: 'tosAccepted',
    index: 31,
    checked: false,
    translateName: 'USER-MANAGEMENT.TOS-ACCEPTED',
    locked: false,
  },
  {
    name: 'ppAccepted',
    index: 32,
    checked: false,
    translateName: 'USER-MANAGEMENT.PP-ACCEPTED',
    locked: false,
  },
  {
    name: 'validTo',
    index: 33,
    checked: false,
    translateName: 'USER-MANAGEMENT.VALID-TO',
    locked: false,
  },
  {
    name: 'servicePin',
    index: 34,
    checked: false,
    translateName: 'USER-MANAGEMENT.SERVICE-PIN',
    locked: false,
  },
  {
    name: 'status',
    index: 35,
    checked: true,
    translateName: 'USER-MANAGEMENT.STATUS',
    locked: true,
    translatePrefix: 'USER-MANAGEMENT.USER.STATUS.',
  },
  {
    name: 'roleId',
    index: 36,
    checked: false,
    translateName: 'USER-MANAGEMENT.ROLE-ID',
    locked: false,
  },
  {
    name: 'roleName',
    index: 37,
    checked: false,
    translateName: 'USER-MANAGEMENT.ROLE-NAME',
    locked: false,
  },
  {
    name: 'roleType',
    index: 38,
    checked: false,
    translateName: 'USER-MANAGEMENT.ROLE-TYPE',
    locked: false,
  },
  {
    version: 2,
  },
];
