<div
  *ngIf="(editableTask && isTask) || !isTask"
  [ngClass]="{
    'inline-display': inline
  }"
>
  <div
    class="form-row"
    [formGroup]="formGroup"
    [ngClass]="{
      'form-row-alternative': sideMenu
    }"
  >
    <mat-form-field
      appearance="{{ appearance ? appearance : 'fill' }}"
      [ngClass]="{ 'val-changed': changedValue }"
      [floatLabel]="floatLabel"
    >
      <mat-label>
        <span *ngIf="nameParamsValue == undefined">
          {{ label | translate }}
        </span>
        <span *ngIf="nameParamsValue != undefined">
          {{ label | translate: { entity: nameParamsValue | translate } }}
        </span>
        <span *ngIf="labelSuffix">({{ labelSuffix | translate }}) </span>
      </mat-label>
      <input
        (keypress)="validateSpecialValidations($event)"
        autocomplete="disabled"
        type="{{ type ? (!visible ? type : 'text') : 'text' }}"
        #input
        matInput
        placeholder=" {{
          nameParamsValue == undefined
            ? (placeholder | translate)
            : (placeholder | translate: { entity: nameParamsValue | translate })
        }}"
        name="{{ name | translate: { entity: nameParamsValue | translate } }}"
        formControlName="{{ controlName }}"
        id="{{ entityName }}-form-{{ controlName }}{{ elementIndex ? '-' + elementIndex : '' }}"
        [required]="requiredValidator"
        mask="{{ maskValue ? maskValue : null }}"
        suffix="{{ suffix ? suffix : null }}"
        thousandSeparator="{{ thousandSeparator ? thousandSeparator : null }}"
        decimalMarker="{{ decimalMarker ? decimalMarker : null }}"
        (change)="change()"
        maxlength="{{ maxLength ? maxLength : 200 }}"
        minlength="{{ minLength ? minLength : 0 }}"
        (input)="changeInputValue()"
        [value]="!this.addNumbers ? '' : (form.get(controlName).value | number: this.addNumbers)"
        [readonly]="disabled"
      />
      <mat-error
        *ngIf="controlObject?.hasError('required')"
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.REQUIRED'
            | translate
              : {
                  name: (input.name | translate)
                }
        }}
      </mat-error>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorInvalid }}"
        class="input-error"
        *ngIf="controlObject?.hasError('IBANInvalid') && !controlObject?.hasError('required')"
      >
        {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'IBAN' | translate } }}
      </mat-error>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorInvalid }}"
        class="input-error"
        *ngIf="controlObject?.hasError('usernameTaken') && !controlObject?.hasError('required')"
      >
        {{ 'ERROR-MESSAGES.ALREADY-EXISTS' | translate: { name: 'USER-MANAGEMENT.USERNAME' | translate } }}
      </mat-error>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorInvalid }}"
        class="input-error"
        *ngIf="controlObject?.hasError('BICInvalid') && !controlObject?.hasError('required')"
      >
        {{ 'ERROR-MESSAGES.INVALID' | translate: { name: 'BIC' | translate } }}
      </mat-error>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorFormat }}"
        class="input-error"
        *ngIf="controlObject?.hasError('email') && !controlObject?.hasError('required')"
      >
        {{ 'ERROR-MESSAGES.INVALID' | translate: { name: input.name | translate } }}
      </mat-error>
      <mat-error
        *ngIf="controlObject?.hasError('alreadyExists')"
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.ALREADY-EXISTS'
            | translate
              : {
                  name: (input.name | translate)
                }
        }}
      </mat-error>

      <mat-error
        *ngIf="controlObject?.hasError('passwordConditionsInvalid')"
        id="{{ entityName }}-form-{{ controlName }}-{{ passwordConditionsInvalid }}"
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.PASSWORD-CONDITIONS'
            | translate
              : {
                  name: (input.name | translate)
                }
        }}
      </mat-error>

      <mat-error
        *ngIf="controlObject?.hasError('servicePinConditionsInvalid')"
        id="{{ entityName }}-form-{{ controlName }}-{{ passwordConditionsInvalid }}"
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.SERVICE-PIN-CONDITIONS'
            | translate
              : {
                  name: (input.name | translate)
                }
        }}
      </mat-error>
      <mat-error
        *ngIf="controlObject?.hasError('positiveNumberValidationError')"
        id="{{ entityName }}-form-{{ controlName }}-{{ positiveNumberValidationError }}"
        class="input-error"
      >
        {{
          'ERROR-MESSAGES.POSITIVE-NUMBER'
            | translate
              : {
                  name: (input.name | translate)
                }
        }}
      </mat-error>
      <mat-icon *ngIf="hasSuffixIcon" matSuffix class="suffix-icon" (click)="visible = !visible">{{
        visible ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': this.approvedValue && approvedValue != taskValue
        }"
        >{{ taskValue }}</span
      >
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="25" fxFlex.sm="30" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="75" fxFlex.sm="70" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': hasChangedTask()
            }"
          >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="addNumbers"
              >{{ taskValue | number: this.addNumbers }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="
                (!thousandSeparator && !addNumbers) ||
                (thousandSeparator && suffix != ' €' && suffix != '€' && !addNumbers)
              "
              >{{ taskValue }}</span
            >

            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="thousandSeparator && (suffix == ' €' || suffix == '€') && !addNumbers"
              >{{ taskValue | currency: '':'' }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="suffix && suffix != '' && taskValue != null && !addNumbers"
              >{{ suffix }}</span
            >
          </span>
        </div>

        <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="60" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
          >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="!thousandSeparator || (thousandSeparator && suffix != ' €' && suffix != '€')"
              >{{ taskValue }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="thousandSeparator && (suffix == ' €' || suffix == '€')"
              >{{ taskValue | currency: '':'' }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="suffix && suffix != '' && taskValue != null"
              >{{ suffix }}</span
            >
          </span>
        </div>

        <div fxFlex="50" fxFlex.sm="30" fxFlex.xs="100" *ngIf="sideMenu && !twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="100" *ngIf="sideMenu && !twoColumns">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
          >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="!thousandSeparator || (thousandSeparator && suffix != ' €' && suffix != '€')"
              >{{ taskValue }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="thousandSeparator && (suffix == ' €' || suffix == '€')"
              >{{ taskValue | currency: '':'' }}</span
            >
            <span
              [ngClass]="{
                'val-changed': this.approvedValue && approvedValue != taskValue
              }"
              *ngIf="suffix && suffix != '' && taskValue != null"
              >{{ suffix }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
