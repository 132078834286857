<nav mat-tab-nav-bar>
  <a
    mat-tab-link
    [routerLink]="['company']"
    *ngIf="hasRoleForPath('company')"
    id="{{ entityName }}-menu-company"
    routerLinkActive
    #rla="routerLinkActive"
    routerLinkActive="active-link"
    [active]="rla.isActive"
    >{{ 'MASTER-DATA.COMPANY' | translate }}</a
  >
</nav>
<div class="content-container">
  <router-outlet></router-outlet>
</div>
