<div class="content-container">
  <div>
    <div class="filters" [formGroup]="filterForm">
      <div class="filter-input">
        <mat-form-field class="example-full-width">
          <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
          <input
            matInput
            formControlName="searchString"
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            id="{{ entityName }}-search-input-name"
            name="search"
            (keyup)="onFilterChange()"
            [removeHtmlTags]="filterForm.controls.searchString"
            type="text"
          />
        </mat-form-field>
        <button
          id="{{ entityName }}-search-button"
          mat-button
          class="custom-button search-button"
          (click)="onFilterChange()"
        >
          <span class="material-icons"> search </span>
        </button>
      </div>
    </div>
  </div>
  <mat-tab-group [selectedIndex]="stateService.activeTab" (selectedIndexChange)="activeTabChange($event)">
    <mat-tab #relationshipTab *ngIf="activeBusinessUnit != 'AW'">
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-relationship-tab">
          <span class="default-totals" [ngClass]="{ 'filter-changed': hasFilterChanged() }">{{
            totals['relationshipTotal']
          }}</span>
          {{ 'ACCOUNTING.RELATIONSHIP' | translate }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-accounting-relationship-list
          [active]="relationshipTab.isActive"
          [searchString]="searchString"
        ></app-accounting-relationship-list>
      </ng-template>
    </mat-tab>
    <mat-tab #partnerTab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-partner-tab">
          <span class="default-totals" [ngClass]="{ 'filter-changed': hasFilterChanged() }">{{
            totals['partnerTotal']
          }}</span>
          {{ 'ACCOUNTING.PARTNER' | translate }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-accounting-partner-list
          [active]="partnerTab.isActive"
          [searchString]="searchString"
        ></app-accounting-partner-list>
      </ng-template>
    </mat-tab>
    <mat-tab #masterDataTab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-master-data-list-tab">
          <span class="default-totals" [ngClass]="{ 'filter-changed': hasFilterChanged() }">{{
            totals['companyTotal']
          }}</span>
          {{ 'ACCOUNTING.MASTER-DATA' | translate }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-accounting-master-data-list
          [active]="masterDataTab.isActive"
          [searchString]="searchString"
        ></app-accounting-master-data-list>
      </ng-template>
    </mat-tab>
    <mat-tab #ledgerAccountTab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-ledger-account-tab">
          <span class="default-totals" [ngClass]="{ 'filter-changed': hasFilterChanged() }">{{
            totals['ledgerTotal']
          }}</span>
          {{ 'ACCOUNTING.LEDGER-ACCOUNT' | translate }}
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-accounting-ledger-account-list
          [active]="ledgerAccountTab.isActive"
          [searchString]="searchString"
        ></app-accounting-ledger-account-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
