import { Component, OnInit, ViewChild } from '@angular/core';
import { TableConfiguration } from '../shared/editable-list-form/table-configuration';
import { EditableListFormComponent } from '../shared/editable-list-form/editable-list-form.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AwQsidValidator } from '../../../../shared/validators/aw-qsid-validator';
import { MasterDataService } from '../../../../shared/services/master-data/master-data.service';
import { FoodProcessingCompanyFiguresService } from '../../../../shared/services/participation-fee/food-processing-company-figures.service';
import { DraftService } from '../../../../shared/services/draft/draft.service';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from '../../../../shared/utils/error-handler/custom-error-handler';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { Router } from '@angular/router';
import { GeneralDeleteConfirmationModalComponent } from '../../../../shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TaskService } from '../../../../shared/services/task/task.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  displayedColumns,
  tableConfiguration,
  // tslint:disable-next-line:max-line-length
} from 'src/app/in-memory-data/animal-welfare/participation-fee/table-configuration/food-processing-company-figures/table-configuration-create-ui';

@Component({
  selector: 'app-food-processing-company-figures-create',
  templateUrl: './food-processing-company-figures-create.component.html',
  styleUrls: ['./food-processing-company-figures-create.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class FoodProcessingCompanyFiguresCreateComponent implements OnInit {
  displayedColumns: string[] = displayedColumns;
  tableConfiguration: TableConfiguration[] = tableConfiguration;
  @ViewChild(EditableListFormComponent)
  editableListFormComponent: EditableListFormComponent;
  form: UntypedFormGroup;
  entities: any[];
  taskView = false;
  guid: any;
  mode: 'CREATE' | 'UPDATE' | 'CANCEL';
  editableTask = false;
  entityName = 'foodProcessingCompanyFigures';
  state = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private foodProcessingCompanyFiguresService: FoodProcessingCompanyFiguresService,
    private draftService: DraftService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private notificationService: NotificationService,
    private router: Router,
    private taskService: TaskService,
    private matDialogService: MatDialog
  ) {
    this.form = this.formBuilder.group({
      objects: new UntypedFormArray([]),
    });
  }

  ngOnInit(): void {
    if (window.history.state.entryRows) {
      this.entities = window.history.state.entryRows;
      this.guid = window.history.state.guid;
      this.mode = 'UPDATE';
      this.state = false;
    } else if (window.history.state.task) {
      this.taskView = true;
      const task = window.history.state.task;
      this.mode = task.params.request;
      this.draftService.retrieveDraftForTask(task.guid).subscribe((data) => {
        const entitiesTmp = [];
        for (const entity of data) {
          entitiesTmp.push({
            data: entity,
            disabled: true,
          });
        }
        this.entities = entitiesTmp;
      });
      this.state = false;
    } else {
      this.entities = [];
      this.mode = 'CREATE';
      this.guid = null;
      this.state = false;
    }
  }

  getNewObject = (index): any => {
    return {
      qsid: '',
      netAmount: '',
      positive: '',
      index,
      foodProcessingCompanyGuid: '',
      id: '',
    };
  };

  getFormRow = (object): any => {
    return this.formBuilder.group({
      // For the sake of easiness, the Validator will also set the GUID of foodProcessingCompany if it is found
      qsId: [
        object.qsid,
        Validators.required,
        AwQsidValidator.createValidator(this.masterDataService),
      ],
      netAmount: [
        { value: object.netAmount, disabled: false },
        Validators.required,
      ],
      positive: [
        { value: object.positive, disabled: false },
        Validators.required,
      ],
      foodProcessingCompanyGuid: [object.foodProcessingCompanyGuid],
      index: [{ value: object.index, disabled: false }, [Validators.required]],
      id: [object.id],
      status: [object.status],
      createdBy: [object.createdBy],
    });
  };

  getDisabledStatus() {
    let result = false;
    const objects = this.form.controls.objects as UntypedFormArray;
    if (this.form.controls.objects && objects.controls.length > 0) {
      objects.controls.forEach((item) => {
        if (!item.valid) {
          result = true;
        }
      });
    } else {
      result = true;
    }
    return result;
  }

  save() {
    if (this.mode === 'UPDATE') {
      this.foodProcessingCompanyFiguresService
        .update(this.form.value, this.guid)
        .subscribe((data) => {
          this.successHandler();
        }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.FOOD-PROCESSING_COMPANY-FIGURES'));
    } else {
      this.foodProcessingCompanyFiguresService
        .create(this.form.value)
        .subscribe((data) => {
          this.successHandler();
        }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.FOOD-PROCESSING_COMPANY-FIGURES'));
    }
  }

  makeEditable() {
    this.editableTask = true;
    this.taskService.updateEditableTask(true);
    if (this.mode === 'UPDATE') {
      this.editableListFormComponent.getFormObjects.controls[2].enable();
    } else if (this.mode === 'CREATE') {
      this.editableListFormComponent.getFormObjects.enable();
    }
  }

  cancelEditable() {
    if (this.editableListFormComponent.getFormObjects.touched) {
      const dialog = this.matDialogService.open(
        GeneralDeleteConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: {
            deleteMessage: this.translateService.instant(
              'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
            ),
          },
        }
      );
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.editableListFormComponent.reloadEditableList();
          this.editableTask = false;
          this.taskService.updateEditableTask(false);
          if (this.mode === 'UPDATE') {
            this.editableListFormComponent.getFormObjects.controls[2].disable();
          } else if (this.mode === 'CREATE') {
            this.editableListFormComponent.getFormObjects.disable();
          }
        }
      });
    } else {
      this.editableTask = false;
      this.taskService.updateEditableTask(false);
      if (this.mode === 'UPDATE') {
        this.editableListFormComponent.getFormObjects.controls[2].disable();
      } else if (this.mode === 'CREATE') {
        this.editableListFormComponent.getFormObjects.disable();
      }
    }
  }

  saveDraft() {
    const task = window.history.state.task;
    const rawValue = this.form.getRawValue();
    this.foodProcessingCompanyFiguresService
      .updateDraft(
        rawValue,
        rawValue.objects[0].id,
        task.guid,
        task.params.request
      )
      .subscribe((_) => {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
        setTimeout(() => {
          this.router.navigateByUrl('tasks', {
            state: { success: true },
          });
        }, 1500);
      }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.FOOD-PROCESSING_COMPANY-FIGURES'));
  }

  addEmptyRow() {
    this.editableListFormComponent.addEmptyRow();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.form.dirty && !this.state;
  }

  private successHandler() {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ENTITIES.PARTICIPATION-FEE.FOOD-PROCESSING_COMPANY-FIGURES'
        ),
      }
    );
    this.state = true;
    this.router.navigateByUrl(
      'awParticipationFee/foodProcessingCompanyFigures',
      {
        state: { success: this.state },
      }
    );
  }
}
