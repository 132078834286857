import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { typeOptions } from 'src/app/in-memory-data/animal-welfare/goods-groups/enum/typeOptions';
import { goodsGroupsColumns } from 'src/app/in-memory-data/animal-welfare/goods-groups/table-columns';
import { goodsGroupsColumnsConf } from 'src/app/in-memory-data/animal-welfare/goods-groups/table-columns-configuration';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { GoodsGroupService } from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-goods-groups-create',
  templateUrl: './goods-groups-create.component.html',
  styleUrls: [
    './goods-groups-create.component.less',
    './goods-groups-create-md.component.less',
  ],
})
export class GoodsGroupsCreateComponent implements OnInit, DirtyComponent {
  private CONFIGURAIONT_KEY = 'goods_groups_table';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'goods-groups';

  filterForm: UntypedFormGroup;

  columns = goodsGroupsColumnsConf;
  displayedColumns = goodsGroupsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  form: UntypedFormGroup;
  typeOptions = typeOptions;

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;

  index = 0;
  deletedRowsIndexes: any[] = [];

  isTask = false;
  editableTask = true;

  state: any;
  approval = false;
  existentGoodsGroups: any[];

  draftObject: any;
  goodGroupInitial: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private goodsGroupsService: GoodsGroupService,
    private taskService: TaskService,
    private draftService: DraftService
  ) {
    this.filterFormBuilder();
    this.goodsGroupsFormBuilder();
  }

  ngOnInit(): void {
    this.state = window.history.state;
    this.getTableConfiguration();
    this.loadTable();
    this.getGoodsGroups();
  }

  canDeactivate() {
    if (
      this.goodsGroupsObjects &&
      this.goodsGroupsObjects.controls.length > 0
    ) {
      return this.goodsGroupsObjects.dirty && !this.approval;
    } else {
      return false;
    }
  }

  get formControls() {
    return this.form.controls;
  }
  get goodsGroupsObjects() {
    return this.formControls.goodsGroups as UntypedFormArray;
  }

  goodsGroupsFormBuilder() {
    this.form = this.formBuilder.group({
      goodsGroups: new UntypedFormArray([]),
    });
  }

  getGoodsGroups() {
    this.goodsGroupsService.findAll().subscribe((data: any) => {
      this.existentGoodsGroups = data;
      this.existentGoodsGroups.sort((a, b) => (a.type > b.type ? 1 : -1));
    });
  }

  loadTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.state.goodsGroups && this.state.goodsGroups.length > 0) {
      this.state.goodsGroups.forEach((element) => {
        this.addRow(element);
      });
    } else if (this.state.task) {
      this.isTask = true;

      this.retrieveDraftObjectTask(this.state.task);

      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    } else {
      this.addEmptyRow();
    }
  }

  retrieveDraftObjectTask(task: any) {
    this.draftService
      .retrieveDraftObjectForTask(task.guid)
      .subscribe((data) => {
        this.draftObject = data;

        if (this.draftObject) {
          const goodGroup = JSON.parse(this.draftObject.draftJson);
          this.addRow(goodGroup);
          this.changeRowsState(false);
        }

        if (this.state.task.params) {
          this.goodGroupInitial = this.state.task.params;
        }
      });
  }

  hasChanged(controlName, element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);

    if (this.goodGroupInitial) {
      return (
        formGroup.get(controlName).value !== this.goodGroupInitial[controlName]
      );
    } else {
      return false;
    }
  }

  changeRowsState(state) {
    this.goodsGroupsObjects.controls.forEach((control) => {
      if (state) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  changeTaskState(state) {
    this.taskService.updateEditableTask(state);
    this.editableTask = state;
    this.changeRowsState(true);
  }

  addRow(element) {
    const object = {
      id: element.id,
      germanName: element.germanName,
      englishName: element.englishName,
      type: element.type,
      comment: element.comment,
      index: this.index,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.index++;
  }

  cancelEdit() {
    if (this.state.task) {
      this.taskService.updateEditableTask(false);
      this.changeRowsState(false);
    } else {
      this.router.navigate(['/quantityLabeledGoods/goodsGroups']);
    }
  }

  addEmptyRow() {
    const object = {
      id: null,
      germanName: null,
      englishName: null,
      type: null,
      comment: null,
      index: this.index,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.index++;
  }

  addFormRow(object) {
    this.goodsGroupsObjects.push(
      this.formBuilder.group({
        id: [{ value: object.id, disabled: false }],
        germanName: [
          { value: object.germanName, disabled: false },
          [Validators.required],
        ],
        englishName: [
          { value: object.englishName, disabled: false },
          [Validators.required],
        ],

        type: [{ value: object.type, disabled: false }, [Validators.required]],

        comment: [{ value: object.comment, disabled: false }],
        edited: [{ value: false, disabled: false }],
        index: [
          { value: object.index, disabled: false },
          [Validators.required],
        ],
      })
    );
  }

  addEmptyFormRow(object) {
    this.goodsGroupsObjects.push(
      this.formBuilder.group({
        id: [{ value: null, disabled: false }],
        germanName: [{ value: null, disabled: false }, [Validators.required]],
        englishName: [{ value: null, disabled: false }, [Validators.required]],
        type: [{ value: null, disabled: false }, [Validators.required]],
        comment: [{ value: null, disabled: false }],
        edited: [{ value: false, disabled: false }],
        index: [
          { value: object.index, disabled: false },
          [Validators.required],
        ],
      })
    );
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchTerm: null,
    });
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ANIMAL-WELFARE.GOODS-GROUPS.TITLE'
        ),
      }
    );
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.goodsGroupsObjects.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        let removed = false;
        if (
          this.goodsGroupsObjects.value[i] &&
          item === this.goodsGroupsObjects.value[i].index &&
          !removed
        ) {
          this.goodsGroupsObjects.removeAt(i);
          removed = true;
        }
      });
    }
  }

  getFormGroup(element) {
    let formGroup: any = null;

    formGroup = this.goodsGroupsObjects.controls.find(
      (item) => item.value.index === element.index
    );

    return formGroup;
  }

  goToEditView() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  onClickSave() {
    const goodsGroups = [];
    this.approval = true;
    let taskId: any;

    if (this.draftObject) {
      taskId = this.draftObject.approvalTaskId;
    }

    this.goodsGroupsObjects.controls.forEach((item: any, index) => {
      goodsGroups.push(item.getRawValue());
    });

    const valid = this.checkSavedGoodsGroups(goodsGroups);

    if (valid) {
      this.goodsGroupsService
        .createGoodsGroup(goodsGroups, '', taskId)
        .subscribe(
          (result) => {
            this.notificationService.showToast(
              'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {
                data: this.translateService.instant(
                  'ANIMAL-WELFARE.GOODS-GROUPS.TITLE'
                ),
              }
            );

            setTimeout(() => {
              this.router.navigate(['/quantityLabeledGoods/goodsGroups']);
            }, 3000);
          },
          (error) => {
            if (error && error.error && error.error.length > 0) {
              if (error.error[0].error === 'already-exist') {
                this.notificationService.showToast(
                  'ANIMAL-WELFARE.GOODS-GROUPS.ALREADY-EXISTS',
                  this.notificationService.MESSAGE_TYPE.ERROR
                );
              }
            }
          }
        );
    } else {
      this.notificationService.showToast(
        'ANIMAL-WELFARE.GOODS-GROUPS.ALREADY-EXISTS',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  checkSavedGoodsGroups(newGoodsGroups) {
    let valid = true;

    const newGoodsGroupsCount = newGoodsGroups.length;
    const newUniqueEnglishNamesCount = newGoodsGroups
      .map((item) => item.englishName)
      .filter((value, index, self) => self.indexOf(value) === index).length;
    const newUniqueGermanNamesCount = newGoodsGroups
      .map((item) => item.germanName)
      .filter((value, index, self) => self.indexOf(value) === index).length;

    if (
      newGoodsGroupsCount !== newUniqueEnglishNamesCount ||
      newGoodsGroupsCount !== newUniqueGermanNamesCount
    ) {
      if (valid) {
        valid = false;
      }
    }

    if (newGoodsGroups && newGoodsGroups.length > 0) {
      newGoodsGroups.forEach((element) => {
        const exist = this.existentGoodsGroups.find(
          (group) =>
            group.englishName === element.englishName &&
            group.germanName === element.germanName &&
            group.type === element.type
        );

        if (valid && exist) {
          valid = false;
        }
      });
    }

    return valid;
  }

  getDisabledStatus() {
    let result = false;

    if (
      this.goodsGroupsObjects.controls &&
      this.goodsGroupsObjects.controls.length > 0
    ) {
      this.goodsGroupsObjects.controls.forEach((item) => {
        if (!item.valid) {
          result = true;
        }
      });
    } else {
      result = true;
    }

    return result;
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                goodsGroupsColumnsConf,
                goodsGroupsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = goodsGroupsColumnsConf;
    this.displayedColumns = goodsGroupsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
