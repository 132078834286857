import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { LivestockProducerService } from 'src/app/shared/services/livestock-producer/livestock-producer.service';
import { livestockProducerColumnsConf } from 'src/app/in-memory-data/animal-welfare/livestock-producer/table-columns-configuration';
import { livestockProducerColumns } from 'src/app/in-memory-data/animal-welfare/livestock-producer/table-columns';
import { UserService } from 'src/app/shared/services/user/user.service';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-livestock-producer-list',
  templateUrl: './livestock-producer-list.component.html',
  styleUrls: ['./livestock-producer-list.component.less'],
})
export class LivestockProducerListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = livestockProducerColumnsConf;
  displayedColumns = livestockProducerColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  draftId: any;
  private CONFIGURAIONT_KEY = 'livestockProcerData_table';

  livestockProducerData = [];
  dataSource = new MatTableDataSource<any>(this.livestockProducerData);
  searchInput: any;
  lpColumnFilterSubscription: Subscription;
  entityName = 'livestockProcerData';
  totalCount: number;
  pageSize = 20;

  loaderSpinner = true;
  filter;
  livestockProducers$: Observable<any>;
  subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private livestockProducerService: LivestockProducerService,
    private userService: UserService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  ngOnInit() {
    this.getTableConfiguration();
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.searchInput = this.livestockProducerService.getSearchString();
    this.getInitialData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getFieldNames = (args): Observable<any> => {
    return this.livestockProducerService.findFilterValues(args);
  };

  sortTable = (sortTableEvent): void => {
    this.loaderSpinner = true;
    this.paginator.firstPage();
    this.livestockProducerService.setSorting(
      sortTableEvent.choice,
      sortTableEvent.direction
    );
    this.getAllLivestockProducers();
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = true;
    this.livestockProducerService.setColumnFilter(choice, selection);
    this.paginator.pageIndex = 0;
    if (this.lpColumnFilterSubscription) {
      this.lpColumnFilterSubscription.unsubscribe();
    }
    this.lpColumnFilterSubscription = this.runSearch().subscribe((data) => {
      this.setTableData(data);
    });
  };

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                livestockProducerColumnsConf,
                livestockProducerColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = livestockProducerColumnsConf;
    this.displayedColumns = livestockProducerColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getInitialData() {
    this.runSearch().subscribe(
      (data) => {
        this.setTableData(data);
        this.setSubscription();
      },
      () => {
        this.loaderSpinner = false;
      }
    );
  }

  getAllLivestockProducers() {
    this.runSearch().subscribe(
      (data) => {
        this.setTableData(data);
      },
      () => {
        this.loaderSpinner = false;
      }
    );
  }

  private setTableData(data) {
    this.livestockProducerData = data.content;
    this.dataSource.data = this.livestockProducerData;
    this.totalCount = data.total;
    this.loaderSpinner = false;
  }

  runSearch() {
    return this.livestockProducerService
      .getAllLivestockProducers(this.paginator.pageIndex, this.pageSize)
      .pipe(first());
  }
  findFromArray(companies, companyGuid) {
    return companies.find((company) => company.guid === companyGuid);
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    this.getAllLivestockProducers();
  }

  goToOverview(row) {
    const state = { livestockProducer: row };
    this.router.navigateByUrl('/livestockProducer/overview', {
      state,
    });
  }

  private setSubscription() {
    this.subscription.add(
      (this.livestockProducers$ = this.filter.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(() => {
            this.loaderSpinner = true;
            this.livestockProducerService.setSearchString(this.searchInput);
            this.paginator.pageIndex = 0;
            return this.livestockProducerService.getAllLivestockProducers(
              this.paginator.pageIndex,
              this.pageSize
            );
          })
        )
        .subscribe(
          (data) => {
            this.setTableData(data);
          },
          () => {
            this.loaderSpinner = false;
          }
        ))
    );
  }
}
