<div class="input-wrapper form-container">
  <div class="draft-author draft-no-tabs" *ngIf="alreadyDraft">
    <span class="draft-author-label">{{ 'GENERAL.CREATED-BY' | translate }}:</span
    ><span class="draft-author-value">{{ getUserInfo() }}</span>
  </div>
  <form class="form" [formGroup]="buForm" autocomplete="off">
    <app-mat-input
      [name]="'BUSINESS-UNIT.CREATE.NAME'"
      [placeholder]="'BUSINESS-UNIT.CREATE.NAME'"
      [label]="'BUSINESS-UNIT.CREATE.NAME'"
      [entityName]="entityName"
      [form]="buForm"
      [controlObject]="buForm.controls.name"
      [controlName]="'name'"
      [approvedValue]="buApproved ? buApproved.name : null"
      [taskValue]="buTask ? buTask.name : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
    ></app-mat-input>

    <div class="non-editable-info-container" *ngIf="!editableTask && isTask">
      <div class="object-row" *ngIf="buTask && buTask.id">
        <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
          <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
            <span class="object-label">Id:</span>
          </div>
          <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
            <span class="object-value">{{ buTask.id }} </span>
          </div>
        </div>
      </div>
    </div>

    <app-mat-slider
      [name]="'BUSINESS-UNIT.CREATE.LEDGER-RELEVANT'"
      [label]="'BUSINESS-UNIT.CREATE.LEDGER-RELEVANT'"
      [entityName]="entityName"
      [form]="buForm"
      [approvedValue]="buApproved ? buApproved.ledgerRelevant : null"
      [taskValue]="buTask ? buTask.ledgerRelevant : null"
      [controlName]="'ledgerRelevant'"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [disabled]="isDraftExists || (!isTask && buApproved)"
    >
    </app-mat-slider>

    <app-mat-slider
      [name]="'BUSINESS-UNIT.CREATE.CAM-RELEVANT'"
      [label]="'BUSINESS-UNIT.CREATE.CAM-RELEVANT'"
      [entityName]="entityName"
      [form]="buForm"
      [controlObject]="buForm.controls.camRelevant"
      [controlName]="'camRelevant'"
      [approvedValue]="buApproved ? buApproved.camRelevant : null"
      [taskValue]="buTask ? buTask.camRelevant : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [disabled]="isDraftExists || (!isTask && buApproved)"
    ></app-mat-slider>

    <app-mat-select
      [name]="'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.PRODUCT-ALLOCATION'"
      [placeholder]="'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.PRODUCT-ALLOCATION'"
      [label]="'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.PRODUCT-ALLOCATION'"
      [entityName]="entityName"
      [form]="buForm"
      [controlObject]="buForm.controls.product"
      [controlName]="'product'"
      [approvedValue]="buApproved ? buApproved.product : null"
      [taskValue]="buTask ? buTask.product : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="products"
      [optionValue]="'code'"
      [selectedOption]="null"
      [translationOption]="'name'"
      [optionTranslationText]="''"
      [disabled]="isDraftExists || (!isTask && buApproved)"
      [titlecasePipe]="false"
      [textTransform]="'none'"
      [customTaskValueFunction]="getProductName"
    ></app-mat-select>

    <app-mat-select
      [name]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-ALLOCATION'"
      [placeholder]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-ALLOCATION'"
      [label]="'BUSINESS-UNIT.POOL-ALLOCATION.POOL-ALLOCATION'"
      [entityName]="entityName"
      [form]="buForm"
      [controlObject]="buForm.controls.poolAllocationId"
      [controlName]="'poolAllocationId'"
      [approvedValue]="buApproved ? buApproved.poolAllocationId : null"
      [taskValue]="buTask ? buTask.poolAllocationId : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="poolsSelectable"
      [optionValue]="'id'"
      [selectedOption]="null"
      [translationOption]="'name'"
      [optionTranslationText]="''"
      [disabled]="isDraftExists || (!isTask && buApproved)"
      [titlecasePipe]="false"
      [textTransform]="'none'"
      [customTaskValueFunction]="getPoolName"
    ></app-mat-select>

    <button
      *ngIf="!isTask"
      mat-button
      class="custom-button new-pool-button"
      id="{{ entityName }}-add-new-pool"
      (click)="addNewPool()"
      [disabled]="isDraftExists || (!isTask && buApproved && buApproved.poolAllocationId)"
    >
      <span>{{ 'BUSINESS-UNIT.POOL-ALLOCATION.NEW-POOL' | translate }}</span>
    </button>

    <div class="external-editing-slider">
      <app-mat-slider
        [name]="'BUSINESS-UNIT.CREATE.EDITING-OF-EXTERNAL-RECORDS'"
        [label]="'BUSINESS-UNIT.CREATE.EDITING-OF-EXTERNAL-RECORDS'"
        [entityName]="entityName"
        [form]="buForm"
        [controlObject]="buForm.controls.editingOfExternalRecords"
        [controlName]="'editingOfExternalRecords'"
        [approvedValue]="buApproved ? buApproved.editingOfExternalRecords : null"
        [taskValue]="buTask ? buTask.editingOfExternalRecords : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [disabled]="isDraftExists"
      ></app-mat-slider>
    </div>

    <div class="external-editing-slider">
      <app-mat-slider
        [name]="'BUSINESS-UNIT.CREATE.MANUAL-BOOKING-APPROVAL'"
        [label]="'BUSINESS-UNIT.CREATE.MANUAL-BOOKING-APPROVAL'"
        [entityName]="entityName"
        [form]="buForm"
        [controlObject]="buForm.controls.manualBookingApproval"
        [controlName]="'manualBookingApproval'"
        [approvedValue]="buApproved ? buApproved.manualBookingApproval : null"
        [taskValue]="buTask ? buTask.manualBookingApproval : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [disabled]="isDraftExists"
      ></app-mat-slider>
    </div>
  </form>
</div>
