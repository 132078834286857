export const contactPersonColumnsConf: any[] = [
  {
    name: 'name',
    index: 0,
    checked: true,
    translateName: 'COMPANY.CONTACT-PERSON.NAME',
    locked: true,
  },
  {
    name: 'address',
    index: 1,
    checked: true,
    translateName: 'COMPANY.CONTACT-PERSON.ADDRESS',
    locked: false,
  },
  {
    name: 'telephoneNumber',
    index: 2,
    checked: true,
    translateName: 'COMPANY.CONTACT-PERSON.TELEPHONE-NUMBER',
    locked: false,
  },
  {
    name: 'email',
    index: 3,
    checked: true,
    translateName: 'COMPANY.CONTACT-PERSON.EMAIL',
    locked: false,
  },
  {
    name: 'numberOfUsers',
    index: 3,
    checked: true,
    translateName: 'COMPANY.CONTACT-PERSON.NUMBER-OF-USERS',
    locked: false,
  },
  {
    name: 'contactType',
    index: 4,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.CONTACT-TYPE',
    locked: false,
  },
  {
    name: 'mainContactPerson',
    index: 5,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.MAIN-CONTACT',
    locked: false,
  },
  {
    name: 'substitute',
    index: 6,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.SUBSTITUTE',
    locked: false,
  },
  {
    name: 'substituteFreeText',
    index: 7,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.SUBSTITUTE-FREE-TEXT',
    locked: false,
  },
  {
    name: 'faxNumber',
    index: 8,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.FAX-NUMBER',
    locked: false,
  },
  {
    name: 'language',
    index: 9,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.LANGUAGE',
    locked: false,
  },
  {
    name: 'preferredContactType',
    index: 10,
    checked: false,
    translateName: 'COMPANY.CONTACT-PERSON.PREFERRED-CONTACT-TYPE',
    locked: false,
  },
  {
    name: 'externalRecord',
    index: 11,
    checked: false,
    translateName: 'COMPANY.EXTERNAL-RECORD',
    locked: false,
  },
  {
    version: 13.1,
  },
];
