import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-mat-errors',
  templateUrl: './mat-errors.component.html',
  styleUrls: ['./mat-errors.component.less'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-50%)' }),
        animate('300ms', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('300ms', style({ transform: 'translateY(-50%)' })),
      ]),
    ]),
  ],
})
export class MatErrorsComponent implements OnChanges {
  @Input() controlObject;
  @Input() controlName;
  @Input() entityName;
  @Input() name;

  errorToShow = '';
  errorName = '';

  ngOnChanges() {
    this.getErrorToShow();
  }

  getErrorToShow = () => {
    if (
      this.controlObject.errors &&
      Object.keys(this.controlObject.errors).length > 0
    ) {
      // Only one error is displayed at a time in the UI.
      const firstError = Object.keys(this.controlObject.errors)[0];
      switch (firstError) {
        case 'invalidAutocompleteObject':
          this.errorToShow = 'ERROR-MESSAGES.INVALID';
          this.errorName = 'error-message-invalid-option';
          break;
        case 'required':
          this.errorToShow = 'ERROR-MESSAGES.REQUIRED';
          this.errorName = 'error-message-required';
          break;
        case 'incorrect':
          this.errorToShow = 'ERROR-MESSAGES.INCORRECT-VALUE';
          this.errorName = 'error-message-incorrect';
          break;
        default:
          break;
      }
    }
  };
}
