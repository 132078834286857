import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { CustomSnackBarComponent } from 'src/app/shared/services/notification/custom-snack-bar/custom-snack-bar.component';
import { BuGeneralInformationComponent } from '../bu-general-information/bu-general-information.component';
import { SharedDataService } from '../services/shared-data.service';
import { BuTransactionCodesComponent } from '../bu-transaction-codes/bu-transaction-codes.component';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';

@Component({
  selector: 'app-bu-create',
  templateUrl: './bu-create.component.html',
  styleUrls: ['./bu-create.component.less'],
})
export class BuCreateComponent
  implements OnInit, DirtyComponent, AfterContentChecked, OnDestroy
{
  @ViewChild(BuGeneralInformationComponent)
  buGeneralInformationComponent: BuGeneralInformationComponent;

  @ViewChild(BuTransactionCodesComponent)
  buTransactionCodesComponent: BuTransactionCodesComponent;

  entityName = 'business-unit';
  errorRequired = 'error-message-required';
  edit = false;
  alreadyDraft = false;
  draftId: number;
  buValue;
  buApproved: any;
  buInitial;
  buTask;
  editableTask: boolean;
  isTask = false;
  isDirty = false;
  approval = false;
  localEntityDraft;
  operationUser: any;
  userList: any;
  isDraftExists = false;
  entitiesLoaded = false;
  state: any;
  editTransactionMode = false;

  businessUnit: any;

  constructor(
    private router: Router,
    private matDialogService: MatDialog,
    private buService: BusinessUnitService,
    private notificationService: NotificationService,
    private taskService: TaskService,
    private draftService: DraftService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private snackBar: MatSnackBar,
    private sharedDataService: SharedDataService,
    private cdref: ChangeDetectorRef,
    private trasactionCodeAllocationService: TransactionCodeAllocationService
  ) {
    this.sharedDataService.currentSharedEditTransactionMode.subscribe(
      (sharedEditTransactionMode) =>
        (this.editTransactionMode = sharedEditTransactionMode)
    );
    this.sharedDataService.updateSharedEditTransactionMode(false);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.state = window.history.state;

    if (this.state.draftId) {
      this.draftId = window.history.state.draftId;
    }

    if (this.state.businessUnit) {
      this.edit = true;
      this.businessUnit = window.history.state.businessUnit;

      if (this.businessUnit && this.businessUnit.id) {
        this.buService.getCurrentApproved(this.businessUnit.id).subscribe(
          (data) => (this.buApproved = data),
          (err) => console.log('ERROR FINDING', err)
        );
        this.buService.retrieveInitialDraft(this.businessUnit.id).subscribe(
          (data) => (this.buInitial = data.businessUnit),
          (err) => console.log('ERROR FINDING INITIAL DRAFT', err)
        );
        this.validateDraftExistence(this.businessUnit.guid);
        if (!this.draftId) {
          this.draftService
            .retrieveLocalEntityDraft(this.businessUnit.guid)
            .subscribe((draft) => {
              this.localEntityDraft = draft;
              const snackBarObject = this.snackBar.openFromComponent(
                CustomSnackBarComponent,
                {
                  data: 'GENERAL-ENTITY.CREATE.MESSAGES.TOAST_MESSAGE_DRAFT_EXISTS',
                  duration: undefined,
                  verticalPosition: 'top',
                  panelClass: [
                    this.notificationService.MESSAGE_TYPE.INFO + '-snackbar',
                    'custom-snackbar',
                  ],
                }
              );

              snackBarObject.afterDismissed().subscribe((data) => {
                if (data.dismissedByAction) {
                  this.redirectToUserDraft();
                }
              });
            });
        } else {
          this.operationUser = this.businessUnit.operationUser;
        }
      }

      this.patchBuFormValues(this.businessUnit);
    } else if (this.state.task !== undefined) {
      this.isTask = true;
      this.edit = true;
      this.buService
        .getBusinessUnitWithTaskId(window.history.state.task.id)
        .subscribe(
          (data) => {
            this.businessUnit = data.businessUnit;

            this.buTask = data.businessUnit;
            this.draftId = data.guid;
            if (this.businessUnit.guid) {
              this.buService.getCurrentApproved(this.businessUnit.id).subscribe(
                (buApprovedResult) => (this.buApproved = buApprovedResult),
                (err) => console.log('ERROR FINDING', err)
              );
            }
            this.patchBuFormValues(this.businessUnit);
            this.buService.retrieveInitialDraft(this.businessUnit.id).subscribe(
              (initialDraft) => (this.buInitial = initialDraft.businessUnit),
              (err) => console.log('ERROR FINDING INITIAL DRAFT', err)
            );
          },
          (err) => console.log(err)
        );
      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
      if (sessionStorage.getItem('taskModify') === 'true') {
        this.taskService.updateEditableTask(true);
      }
    } else {
      this.entitiesLoaded = true;
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('taskModify');
  }

  isModify() {
    if (sessionStorage.getItem('taskModify') === 'true') {
      return true;
    }
  }

  canDeactivate() {
    return this.buGeneralInformationComponent.buForm.dirty && !this.approval;
  }

  private patchBuFormValues(businessUnit: any) {
    this.buApproved = businessUnit;

    if (businessUnit.draft) {
      this.alreadyDraft = true;
    }
  }

  redirectToUserDraft() {
    const businessUnit = JSON.parse(this.localEntityDraft.draftJson);
    businessUnit.draft = true;
    businessUnit.draftId = this.localEntityDraft.guid;
    businessUnit.operationUser = this.localEntityDraft.operationUser;

    const state = { businessUnit };
    state['draftId'] = this.localEntityDraft.guid;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['systemConfiguration/bu/create'], {
      state,
    });
  }

  changeEditable(value: boolean) {
    const state = window.history.state;
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.router.navigateByUrl('/tasks/' + state.task.id, {
        state,
      });
    } else {
      this.taskService.updateEditableTask(value);
    }
  }

  backToList() {
    this.router.navigateByUrl('systemConfiguration/bu');
  }

  onClickApproval() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        this.approval = true;
        if (this.draftId) {
          this.draftService.deleteUserDraft(this.draftId).subscribe();
        }

        if (this.edit) {
          if (!this.formHasChanged()) {
            if (this.buTransactionCodesComponent.dataSourceEdited) {
              this.saveTransactionCodes(result);
            }
          } else {
            this.saveBUGeneralInformation(result);
          }
        } else {
          this.saveBUGeneralInformation(result);
        }
      }
    });
  }

  saveBUGeneralInformation(result) {
    const json = {
      businessUnit: this.buGeneralInformationComponent.buForm.getRawValue(),
      comment: result.comment,
      draftId: this.draftId,
    };
    if (!this.isTask && this.buApproved && this.buApproved.poolAllocationId) {
      json.businessUnit.poolAllocationId = this.buApproved.poolAllocationId;
    }
    this.buService.saveDraftForApproval(json).subscribe(() => {
      if (this.buTransactionCodesComponent.dataSourceEdited) {
        this.saveTransactionCodes(result);
      } else {
        this.showSuccessTaskCreated();
      }
    }, this.customErrorHandler._errorHandler('ENTITIES.BU'));
  }

  showSuccessTaskCreated() {
    if (this.formHasChanged()) {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        { data: this.translateService.instant('ENTITIES.BU') }
      );
    } else {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED-DIRECT',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        { data: this.translateService.instant('ENTITIES.BU') }
      );
    }

    setTimeout(() => {
      if (this.isTask) {
        this.router.navigateByUrl('/tasks');
      } else {
        this.router.navigateByUrl('/systemConfiguration/bu', {
          state: { success: true },
        });
      }
    }, 1500);
  }

  checkDisabledEditButton() {
    if (
      (this.buGeneralInformationComponent &&
        !this.buGeneralInformationComponent.buForm.valid) ||
      this.isDraftExists
    ) {
      return true;
    } else {
      if (this.formHasChanged()) {
        return false;
      } else {
        if (
          this.buTransactionCodesComponent &&
          this.buTransactionCodesComponent.dataSourceEdited
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  formHasChanged() {
    return !(
      this.buApproved &&
      this.buApproved.name ===
        this.buGeneralInformationComponent.buForm.get('name').value &&
      this.buApproved.ledgerRelevant ===
        this.buGeneralInformationComponent.buForm.get('ledgerRelevant').value &&
      this.buApproved.camRelevant ===
        this.buGeneralInformationComponent.buForm.get('camRelevant').value &&
      this.buApproved.product ===
        this.buGeneralInformationComponent.buForm.get('product').value &&
      this.buApproved.poolAllocationId ===
        this.buGeneralInformationComponent.buForm.get('poolAllocationId')
          .value &&
      this.buApproved.editingOfExternalRecords ===
        this.buGeneralInformationComponent.buForm.get(
          'editingOfExternalRecords'
        ).value &&
      this.buApproved.manualBookingApproval ===
        this.buGeneralInformationComponent.buForm.get('manualBookingApproval')
          .value
    );
  }

  saveTransactionCodes(result) {
    const incompleteCodes = [];
    this.buTransactionCodesComponent.dataSource.data.forEach((row) => {
      if (row.status === 0 && row.new === true) {
        incompleteCodes.push(row.guid);
      }
    });
    if (incompleteCodes.length > 0) {
      this.allocateIncompleteTransactionCodes(incompleteCodes, result);
    }
  }

  allocateIncompleteTransactionCodes(incompleteCodes, result) {
    const request = {
      transactionCodeGuids: incompleteCodes,
      entityGuid: this.buApproved.guid,
      comment: result.comment,
    };
    this.trasactionCodeAllocationService
      .allocateIncompleteTransactionCodes(request)
      .subscribe(() => {
        this.showSuccessTaskCreated();
      });
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.buGeneralInformationComponent.buForm.dirty;
  }

  saveAsDraft() {
    this.approval = true;
    const draftId = this.draftId;
    const element = this.buGeneralInformationComponent.buForm.value;
    const request = {
      entityId: element.id,
      entityType: 'BUSINESS_UNIT',
      element,
      id: draftId,
      entityGuid: element.guid,
    };
    const update = !!draftId;
    this.draftService.saveUserDraft(request).subscribe(() => {
      if (update) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      } else {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
      setTimeout(() => {
        this.router.navigateByUrl('/systemConfiguration/bu', {
          state: { success: true },
        });
      }, 1500);
    }, this.customErrorHandler._errorHandler('ENTITIES.BU'));
  }

  validateDraftExistence(guid) {
    this.buService.validateDraftExistence(guid).subscribe(
      (data) => {
        this.isDraftExists = data;
        if (this.isDraftExists) {
          this.buGeneralInformationComponent.buForm.disable();
          this.notificationService.showToastMessage(
            'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        }
      },
      (err) => console.log('ERROR VALIDATING DRAFT', err)
    );
  }
}
