import { TableTypes } from '../default-table/table-types';

export const accountingOpenItemColumnsConf: any[] = [
  {
    name: 'flags',
    index: 1,
    checked: true,
    translateName: 'INFO-FLAG',
    locked: true,
    number: false,
    type: TableTypes.ICON,
  },
  {
    name: 'debtorId',
    index: 2,
    checked: true,
    translateName: 'MASTER-DATA.CLIENT',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },

  {
    name: 'bookingCode',
    index: 3,
    checked: true,
    translateName: 'MASTER-DATA.BOOKING-CODE',
    locked: true,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'clientOpenItemId',
    index: 4,
    checked: true,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.DOCUMENT-NUMBER',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'bookingCodeName',
    index: 5,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.BOOKING-CODE-NAME',
    locked: false,
    number: false,
    columnEnglish: 'bookingCodeNameEn',
    columnGerman: 'bookingCodeNameDe',
    type: TableTypes.MULTI_TRANSLATED_STRING,
  },
  {
    name: 'openItemDate',
    index: 6,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.OPEN-ITEM-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'amount',
    index: 7,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'dueDate',
    index: 8,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.DUE-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'paymentDate',
    index: 9,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.PAYMENT-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'financedAmount',
    index: 10,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.FINANCED',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'nonFinancedAmount',
    index: 11,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.NON-FINANCED',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'objectionReason',
    index: 12,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.OBJECTION-REASON',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'objectionAmount',
    index: 13,
    checked: false,
    translateName: 'DEBTOR.ACCOUNT-OVERVIEW.OBJECTION-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'objectionDate',
    index: 14,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'creditDebitCode',
    index: 15,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT',
    locked: false,
    number: false,
    translatePrefix: 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT-VALUES.',
    type: TableTypes.STRING,
  },
  {
    name: 'discountAmount',
    index: 16,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'discountDate',
    index: 17,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'discountPercent',
    index: 18,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-PERCENT',
    locked: false,
    number: true,
    type: TableTypes.PERCENT,
  },
  {
    name: 'paymentDeductionAmount',
    index: 19,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PAYMENT-DEDUCTION-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'paymentDeductionRate',
    index: 20,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PAYMENT-DEDUCTION-RATE',
    locked: false,
    number: true,
    type: TableTypes.PERCENT,
  },
  {
    name: 'dunningBlockDate',
    index: 21,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUNNING-BLOCK-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'dunningLevel',
    index: 22,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DUNNING-LEVEL',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'debtCollectionAgency',
    index: 23,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-AGENCY',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'debtCollectionStatus',
    index: 24,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-STATUS',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'debtCollectionTransferDate',
    index: 25,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-TRANSFER-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'processingEnd',
    index: 26,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PROCESSING-END',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'openAmount',
    index: 27,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'branchId',
    index: 28,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.BRANCH-ID',
    locked: false,
    number: true,
    type: TableTypes.STRING,
  },
  {
    name: 'lastChangeDate',
    index: 29,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.LAST-CHANGE-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'vatPercent',
    index: 30,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-PERCENT',
    locked: false,
    number: false,
    type: TableTypes.PERCENT,
  },
  {
    name: 'vatAmount',
    index: 31,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.VAT-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'transactionId',
    index: 32,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID',
    locked: false,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'referenceDocumentation',
    index: 33,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-DOCUMENTATION',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'referenceText',
    index: 34,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-TEXT',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'originalAccount',
    index: 35,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ORIGINAL-ACCOUNT',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'allocationNumber',
    index: 36,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-NUMBER',
    locked: false,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'currencyCode',
    index: 37,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'currencyAmount',
    index: 38,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'repurchasingReason',
    index: 39,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REPURCHASING-REASON',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'repurchasingDate',
    index: 40,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.REPURCHASING-DATE',
    locked: false,
    number: false,
    type: TableTypes.DATE,
  },
  {
    name: 'allocationTimestamp',
    index: 41,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-TIMESTAMP',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'allocatedAmount',
    index: 42,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.ALLOCATED-AMOUNT',
    locked: false,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    name: 'clientName',
    index: 43,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CLIENT-NAME',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'transactionType',
    index: 44,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-TYPE',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'platform',
    index: 45,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.PLATFORM',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'client',
    index: 46,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CLIENT',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'centralDebtor',
    index: 47,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CENTRAL-DEBTOR',
    locked: false,
    number: false,
    type: TableTypes.STRING,
  },
  {
    version: 2.1,
  },
];
