export const accountingRelationshipColumnsConf: any[] = [
  {
    name: 'platformId',
    index: 0,
    checked: true,
    translateName: 'DEBTOR.PLATFORM-ID',
    locked: true,
    number: true,
  },
  {
    name: 'platformName',
    index: 1,
    checked: true,
    translateName: 'DEBTOR.PLATFORM-NAME',
    locked: true,
    number: false,
  },
  {
    name: 'clientId',
    index: 2,
    checked: true,
    translateName: 'DEBTOR.CLIENT-ID',
    locked: true,
    number: true,
  },
  {
    name: 'clientName',
    index: 3,
    checked: true,
    translateName: 'DEBTOR.CLIENT-NAME',
    locked: true,
    number: false,
  },
  {
    name: 'visibleId',
    index: 4,
    checked: true,
    translateName: 'DEBTOR.DEBTOR-ID',
    locked: true,
    number: true,
  },
  {
    name: 'debtorName',
    index: 5,
    checked: true,
    translateName: 'DEBTOR.DEBTOR-NAME',
    locked: true,
    number: false,
  },
  {
    name: 'externalId',
    index: 6,
    checked: false,
    translateName: 'DEBTOR.EXTERNAL-ID',
    locked: true,
    number: true,
  },
  {
    name: 'balance',
    index: 7,
    checked: false,
    translateName: 'DEBTOR.BALANCE',
    locked: true,
    number: true,
  },
  {
    version: 2,
  },
];
