export const userManagementRoleKnownUserColumns: string[] = [
  'flags',
  'userName',
  'lastName',
  'email',
  'companyName',
  'lastLogin',
  'online',
  'status',
  'actions',
];
