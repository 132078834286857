<mat-tab-group style="width: 100%">
  <mat-tab label="{{ 'OPEN-TASK.DEDUCTIONS.LABEL-ADD' | translate }}" *ngIf="data.create">
    <div class="title-form">
      <h5 translate>OPEN-TASK.DEDUCTIONS.TITLE-ADD</h5>
    </div>
    <div class="input-wrapper form-container">
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-REASON-KEY' | translate }}</mat-label>
            <input
              autocomplete="off"
              matInput
              placeholder="{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-REASON-KEY' | translate }}"
              name="deductionResonKey"
              [(ngModel)]="deductionResonKey"
              formControlName="deductionResonKey"
              required
              type="text"
              [removeHtmlTags]="form.controls.deductionResonKey"
            />
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-REFERENCE' | translate }}</mat-label>
            <input
              autocomplete="off"
              matInput
              placeholder="{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-REFERENCE' | translate }}"
              name="deductionReference"
              [(ngModel)]="deductionReference"
              formControlName="deductionReference"
              required
              type="text"
              [removeHtmlTags]="form.controls.deductionReference"
            />
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-PERCENT' | translate }}</mat-label>
            <input
              autocomplete="off"
              matInput
              placeholder="{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-PERCENT' | translate }}"
              name="deductionPercent"
              formControlName="deductionPercent"
              [(ngModel)]="deductionPercent"
              mask="percent"
              suffix="%"
              required
            />
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-VALIDITY-DATE' | translate }}</mat-label>
            <input
              autocomplete="off"
              matInput
              [matDatepicker]="pickerFrom"
              placeholder="{{ 'OPEN-TASK.DEDUCTIONS.DEDUCTION-VALIDITY-DATE' | translate }}"
              name="deductionValidityDate"
              [(ngModel)]="deductionValidityDate"
              formControlName="deductionValidityDate"
              required
              type="text"
              [removeHtmlTags]="form.controls.deductionValidityDate"
            />
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="filter-input button-container">
      <button mat-button class="custom-button-cancel" style="margin-right: 10px" [mat-dialog-close]>
        {{ 'cancel' | translate }}
      </button>
      <button mat-button class="custom-button" [disabled]="!form.valid" (click)="saveDeductionAndCloseDialog()">
        {{ 'OPEN-TASK.DEDUCTIONS.ADD' | translate }}
      </button>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'OPEN-TASK.DEDUCTIONS.LABEL-DELETE' | translate }}">
    <div class="height-tab" *ngIf="data.deductions.length > 0">
      <div class="full-width">
        <div class="full-width-list-item" *ngFor="let deduction of data.deductions; let i = index">
          <span matLine>Deduction {{ i + 1 }}: {{ deduction.deductionResonKey }} </span>
          <div class="buttons" *ngIf="data.create">
            <button mat-icon-button (click)="removeDeduction(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <ul>
            <li>
              <span> Deduction Reference: {{ deduction.deductionReference }} </span>
            </li>
            <li>
              <span> Deduction Percent: {{ deduction.deductionPercent }}% </span>
            </li>
            <li>
              <span>
                Deduction Validity Date:
                {{ deduction.deductionValidityDate | date: dateFormat }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="data.deductions.length == 0" class="height-tab">
      <div class="center-text">
        <span translate>OPEN-TASK.DEDUCTIONS.NO-DEDUCTIONS</span>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
