import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler {
  constructor(
    public notificationService: NotificationService,
    public translateService: TranslateService
  ) {}

  handleError(error, data) {
    if (!error) {
      this.notificationService.showToast(
        'ERROR.REQUIRES-ADMIN',
        this.notificationService.MESSAGE_TYPE.ERROR,
        { error: 'unknown', errorId: 'unknown' }
      );
    } else {
      if (Array.isArray(error)) {
        for (const err of error) {
          if (err.error === 'ALREADY-HAS-DRAFT') {
            this.notificationService.showToast(
              'ERROR.ALREADY-HAS-DRAFT',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { data: data }
            );
          } else if (err.error === 'ALREADY-EXISTS-FOR-COMPANY') {
            this.notificationService.showToast(
              'ERROR.ALREADY-EXISTS-FOR-COMPANY',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { data: data }
            );
          } else if (err.error === 'ALREADY-EXISTS') {
            this.notificationService.showToast(
              'ERROR-MESSAGES.ALREADY-EXISTS',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { data: data }
            );
          } else if (err.error === 'OWN-TASK') {
            this.notificationService.showToast(
              'ERROR.OWN-TASK',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { data: err.field }
            );
          } else {
            this.notificationService.showToast(
              'ERROR.GENERAL-ERROR',
              this.notificationService.MESSAGE_TYPE.ERROR,
              { data: data },
              err
            );
          }
        }
      } else {
        if (error.error === 'ALREADY-HAS-DRAFT') {
          this.notificationService.showToast(
            'ERROR.ALREADY-HAS-DRAFT',
            this.notificationService.MESSAGE_TYPE.ERROR,
            { data: data }
          );
        } else if (error.error === 'ALREADY-EXISTS-FOR-COMPANY') {
          this.notificationService.showToast(
            'ERROR.ALREADY-EXISTS-FOR-COMPANY',
            this.notificationService.MESSAGE_TYPE.ERROR,
            { data: data }
          );
        } else {
          this.notificationService.showToast(
            'ERROR.REQUIRES-ADMIN',
            this.notificationService.MESSAGE_TYPE.ERROR,
            { data: data },
            error
          );
        }
      }
    }
  }

  errorHandler(translationKey: string, error: any) {
    if (error.error) {
      this.handleError(
        error.error,
        this.translateService.instant(translationKey)
      );
    }
  }

  _errorHandler(tranlationKey: string) {
    return (error) => {
      return this.errorHandler(tranlationKey, error);
    };
  }

  _errorHandlerToData(data: any) {
    return (error) => {
      return this.handleError(error, data);
    };
  }
}
