<app-back-button *ngIf="isTask"></app-back-button>
<app-back-button *ngIf="!isTask" [address]="'masterdata/company'"></app-back-button>
<div class="edit-task">
  <button
    *hasPermission="'md.u'"
    id="{{ entityName }}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeEditable(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>
<div class="title-form">
  <h3 id="{{ entityName }}-form-title-create" *ngIf="!edit">
    {{ 'COMPANY.CREATION' | translate }}
  </h3>
  <h3 id="{{ entityName }}-form-title-edit" *ngIf="edit">
    {{ 'COMPANY.CONFIGURATION' | translate }}
  </h3>
</div>

<!--Stepper-->
<div class="stepper-container" *ngIf="showStepper">
  <mat-horizontal-stepper #stepper (selectionChange)="stepperChange($event)">
    <ng-template matStepperIcon="done">
      <span class="material-icons"> done </span>
    </ng-template>

    <mat-step [stepControl]="frmGeneralInformation" errorMessage="">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-general-information-step">{{ 'GENERAL-INFORMATION' | translate | titlecase }}</span>
      </ng-template>
      <app-company-general-information></app-company-general-information>
      <div class="stepper-button-container">
        <button
          id="{{ entityName }}-form-next-button-general-information"
          class="custom-button-cancel stepper-next"
          mat-button
          matStepperNext
        >
          {{ 'STEPPER.NEXT-STEP' | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="frmBankAccount" errorMessage="">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-bank-account-step">{{ 'COMPANY.BANK-ACCOUNT' | translate | titlecase }}</span>
      </ng-template>
      <app-company-bank-account></app-company-bank-account>
      <div class="stepper-button-container">
        <button
          id="{{ entityName }}-form-next-button-bank-account"
          class="custom-button-cancel stepper-next"
          mat-button
          matStepperNext
        >
          {{ 'STEPPER.NEXT-STEP' | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="frmContactPerson" errorMessage="">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-contact-person-step"> {{ 'COMPANY.CONTACT-PERSON' | translate | titlecase }}</span>
      </ng-template>
      <app-company-contact-person></app-company-contact-person>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<!--End stepper-->
<!--Tabs-->
<div class="tab-container" *ngIf="!showStepper">
  <mat-tab-group>
    <mat-tab label="{{ 'generalInformation' | translate }}">
      <ng-template mat-tab-label>
        <div [ngClass]="{ 'error-tab': getErrorTab(0) }" id="{{ entityName }}-general-info-tab">
          {{ 'GENERAL-INFORMATION' | translate }}
        </div>
      </ng-template>
      <app-company-general-information
        [companyApproved]="companyApproved"
        [companyTask]="companyTask"
        [companyInitial]="companyInitial"
        [isDraftExists]="isDraftExists"
        *ngIf="companyApproved || entitiesLoaded"
      >
      </app-company-general-information>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-bank-account-tab">
          {{ 'COMPANY.BANK-ACCOUNT' | translate }}
        </div>
      </ng-template>
      <app-company-bank-account
        *ngIf="companyApproved || entitiesLoaded"
        [companyApproved]="companyApproved"
        [isDraftExists]="isDraftExists"
      >
      </app-company-bank-account>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-contact-person-tab">
          {{ 'COMPANY.CONTACT-PERSON' | translate }}
        </div>
      </ng-template>
      <app-company-contact-person
        *ngIf="companyApproved || entitiesLoaded"
        [companyApproved]="companyApproved"
        [isDraftExists]="isDraftExists"
      >
      </app-company-contact-person>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="filter-input button-container" *ngIf="editableTask || !isTask">
  <button
    mat-button
    class="custom-button-cancel"
    id="{{ entityName }}-cancel-button"
    *ngIf="editableTask && isTask"
    (click)="changeEditable(false)"
  >
    {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
  </button>
  <ng-container *hasPermission="['md.u', 'md.r']">
    <button
      mat-button
      class="custom-button-cancel"
      (click)="saveAsDraft()"
      *ngIf="!isModify()"
      id="{{ entityName }}-create-draft-button"
      [disabled]="
        (generalInformationComponent && generalInformationComponent.companyForm
          ? !checkOneItemValue(generalInformationComponent.companyForm.value)
          : false) || isDraftExists
      "
    >
      <span *ngIf="alreadyDraft" id="{{ entityName }}-update-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
      </span>
      <span *ngIf="!alreadyDraft" id="{{ entityName }}-new-draft-button-span">
        {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
      </span>
    </button>

    <button
      mat-button
      class="custom-button"
      (click)="onClickSave()"
      id="{{ entityName }}-create-button"
      [disabled]="saveButtonShouldBeDisabled() && companyApproved && !companyApproved.createdExternally"
    >
      <span *ngIf="save && !alreadyDraft">
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
      <span *ngIf="(!save && !alreadyDraft) || alreadyDraft">
        {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
      </span>
    </button>
  </ng-container>
</div>
