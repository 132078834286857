import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { debtorOpenItemAllocationColumns } from 'src/app/in-memory-data/debtor/payment-allocation/transactions/table-columns';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DialogData } from '../../models/dialog-data';
import { SelectionModel } from '@angular/cdk/collections';
import { billingSlaughterhouseOpenItemAllocationColumns } from 'src/app/in-memory-data/animal-welfare/slaughterhouse-payment-allocation/transactions/table-columns';
import { AccountingService } from '../../services/accounting/accounting.service';

@Component({
  selector: 'app-transaction-selection-modal',
  templateUrl: './transaction-selection-modal.component.html',
  styleUrls: ['./transaction-selection-modal.component.less'],
})
export class TransactionSelectionModalComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel(true, []);
  displayedColumns: any;

  filterForm: UntypedFormGroup;
  entityName = 'transactionSelectionModal';
  data: any;
  debtor;
  existentTransactions: any = [];
  bookingCodeSort = [438, 587, 588, 551, 536, 537, 501];
  openItems: any = [];
  availableTransactions = true;
  currentCurrency: string;
  loader = true;
  masterData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    private accountingService: AccountingService
  ) {}

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.dialogData.selectedDebtors) {
      this.existentTransactions = this.dialogData.selectedDebtors;
    } else if (this.dialogData.selectedTransactions) {
      this.existentTransactions = this.dialogData.selectedTransactions;
    }

    if (this.dialogData.debtor) {
      this.debtor = this.dialogData.debtor;
    } else if (this.dialogData.masterData) {
      this.masterData = this.dialogData.masterData;
    }

    if (this.debtor) {
      this.displayedColumns = debtorOpenItemAllocationColumns;
    } else if (this.masterData) {
      this.displayedColumns = billingSlaughterhouseOpenItemAllocationColumns;
    }

    this.filterForm = this.formBuilder.group({
      searchString: null,
    });

    this.filterForm.valueChanges.subscribe(
      (filters: { searchString: string }) => {
        const searchString = filters.searchString.trim().toUpperCase();

        const filteredList: any[] = this.existentTransactions.filter(
          (item) =>
            item.clientDebtorId
              .toString()
              .toUpperCase()
              .indexOf(searchString) >= 0 ||
            item.bookingCode.toString().toUpperCase().indexOf(searchString) >=
              0 ||
            item.clientOpenItemId
              .toString()
              .toUpperCase()
              .indexOf(searchString) >= 0
        );
        this.dataSource.data = filteredList;
      }
    );

    if (this.debtor) {
      this.getOpenItems(this.debtor.type, this.debtor.guid);
    } else if (this.masterData) {
      this.getOpenItems(this.masterData.type, this.masterData.guid);
    }
  }

  getOpenItems(type, guid) {
    const config = {};
    config['entityType'] = type;
    config['entityGuid'] = guid;
    config['closedTransactions'] = false;
    config['creditTransactions'] = false;
    this.accountingService.filterTransactionList(config).subscribe((data) => {
      const items = this.filteredArray(data['content']);
      this.sortData(items);
      this.openItems = items;
      this.currentCurrency =
        this.existentTransactions[0] && this.existentTransactions[0].currency
          ? this.existentTransactions[0].currency
          : null;
      this.removeTransactions();
    });
  }

  async removeTransactions() {
    let resultData;

    resultData = await this.removeExistentTransactions();
    resultData = await this.removeDifferentCurrencyTransactions(resultData);

    this.dataSource.data = resultData;

    if (this.dataSource.data.length === 0) {
      this.availableTransactions = false;
    } else {
      this.availableTransactions = true;
    }

    this.loader = false;
  }

  async removeExistentTransactions() {
    const resultData: any = Object.assign([], this.openItems);

    for (let i = this.openItems.length - 1; i >= 0; i--) {
      const item = this.existentTransactions.find(
        (data) => data.id === this.openItems[i].id
      );

      if (item != null) {
        resultData.splice(i, 1);
      }
    }
    return resultData;
  }

  async removeDifferentCurrencyTransactions(resultData) {
    for (let i = resultData.length - 1; i >= 0; i--) {
      if (
        this.currentCurrency &&
        resultData[i].currency !== this.currentCurrency
      ) {
        resultData.splice(i, 1);
      }
    }
    return resultData;
  }

  sortData(items: any): any {
    items.sort((a, b) => {
      if (a.bookingCode === b.bookingCode) {
        return this.compareDate(a.openItemDate, b.openItemDate);
      }
      return (
        this.bookingCodeSort.indexOf(a.bookingCode) -
        this.bookingCodeSort.indexOf(b.bookingCode)
      );
    });
  }

  compareDate(date1: Date, date2: Date): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }
    if (d1 > d2) {
      return 1;
    }
    if (d1 < d2) {
      return -1;
    }
  }

  filteredArray(data) {
    return data
      .filter((item) => item.bookingCode === 438)
      .concat(data.filter((item) => item.bookingCode === 587))
      .concat(data.filter((item) => item.bookingCode === 588))
      .concat(data.filter((item) => item.bookingCode === 551))
      .concat(data.filter((item) => item.bookingCode === 536))
      .concat(data.filter((item) => item.bookingCode === 537))
      .concat(data.filter((item) => item.bookingCode === 501))
      .concat(
        data
          .filter(
            (item) =>
              item.bookingCode !== 438 &&
              item.bookingCode !== 587 &&
              item.bookingCode !== 588 &&
              item.bookingCode !== 551 &&
              item.bookingCode !== 536 &&
              item.bookingCode !== 537 &&
              item.bookingCode !== 501
          )
          .sort((a, b) => a.bookingCode > b.bookingCode)
      );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
