<app-back-button></app-back-button>

<mat-selection-list>
  <mat-list-option *ngFor="let returnCode of returnCodes | keyvalue">
    <span>
      {{ returnCode.value }} x {{ returnCode.key }} -
      {{ 'ANIMAL-WELFARE.RETURN-CODE.ACTION.' + returnCode.key | translate | taskParameters:task.params }}
    </span>
  </mat-list-option>
</mat-selection-list>
