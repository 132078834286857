<div class="content-container">
  <div class="movement-data-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
      aria-hidden="true"
    >
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-name" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.NAME' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.reporterName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-type" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.TYPE' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ getType(element.companyLevel) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="submissionDate">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-submission-date" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.SUBMISSION-DATE' | translate }}
          <app-table-menu-sort-filter
            choice="submission_date"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
            [sortableDate]="true"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-submission-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.submissionDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="measureUnit">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-measure-unit" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.MEASURE-UNIT' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-measure-unit-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.translateMeasureUnit | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transfer">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-transfer" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.TRANSFER' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-transfer-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          <span *ngIf="element.transfer === true"> + </span>
          <span *ngIf="element.transfer === false"> - </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-amount"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.AMOUNT' | translate }}
          <app-table-menu-sort-filter
            choice="amount"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          class="number-row"
          id="{{ entityName }}-table-results-amount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.amount }}
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-comment" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.COMMENT' | translate }}
          <app-table-menu-sort-filter
            choice="comment"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.comment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-status" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.STATUS' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-status-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          <span>
            {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.STATUS-OPTIONS.' + element.status | translate }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="paidOutOn">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-paid-out-on" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-ON' | translate }}
          <app-table-menu-sort-filter
            choice="payout_date"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-paid-out-on-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.payoutDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paidOutIn">
        <th *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-paid-out-in" mat-header-cell>
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-IN' | translate }}
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-paid-out-in-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.payoutQuarter }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paidOutAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-paid-out-amount"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-AMOUNT' | translate }}
          <app-table-menu-sort-filter
            choice="payout_amount"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          class="number-row"
          id="{{ entityName }}-table-results-paid-out-amount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.payoutAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentId">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-document-id"
        >
          {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.DOCUMENT-ID' | translate }}
          <app-table-menu-sort-filter
            choice="document_id"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          class="number-row"
          id="{{ entityName }}-table-results-document-id-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          {{ element.documentId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fundingRate">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-funding-rate"
        >
          {{ getFundingRateColumnTitle() | translate }}
          <app-table-menu-sort-filter
            choice="euro_per_unit"
            [dataSource]="dataSource"
            [callback]="filterData"
            [getDistinctValues]="getFieldNames"
            [originalDataSource]="originalDataSource"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="pageSize"
            [sortDirection]="sortDirection"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          *matCellDef="let element; let i = dataIndex"
          class="number-row"
          id="{{ entityName }}-table-results-funding-rate-{{ paginator.pageIndex + 1 }}-{{ i }}"
          mat-cell
        >
          <span>{{ element.euroPerUnit && element.euroPerUnit + ' €' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions" mat-header-cell>
          <button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button" mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td (click)="$event.stopPropagation()" *matCellDef="let element; let i = dataIndex" mat-cell></td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        *matRowDef="let element; columns: displayedColumns; let i = index"
        [hidden]="loaderSpinner"
        class="element-row"
        mat-row
      ></tr>
      <tr
        *matFooterRowDef="['loader']"
        [hidden]="!loaderSpinner"
        class="footer-spinner height-footer"
        mat-footer-row
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
  </div>
  <mat-paginator
    (page)="paginate($event)"
    [length]="totalCount"
    [pageSizeOptions]="[20, 50, 100, 200]"
    [pageSize]="pageSize"
    class="mat-paginator-sticky"
    showFirstLastButtons
  ></mat-paginator>

  <mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>
</div>
