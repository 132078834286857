import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {
  private serviceUrl: string;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.powerBIAPI;
  }

  listAllReports(language: string) {
    const url = this.serviceUrl + 'getAllReports?language=' + language;
    return this.http.get(url, this.buildHeaders());
  }

  getEmbededToken(reportId, groupId, datasetId) {
    const url =
      this.serviceUrl +
      'getEmbedToken?reportId=' +
      reportId +
      '&groupId=' +
      groupId +
      '&datasetId=' +
      datasetId;
    return this.http.get(url, this.buildHeaders());
  }

  getEmbeddedTokenByName(reportName) {
    const url =
      this.serviceUrl + 'getEmbedTokenWithName?reportName=' + reportName;
    return this.http.get(url, this.buildHeaders());
  }
  downloadPdf(documentName: any, type, id) {
    const config = this.buildHeadersPdf();

    config['params'] = new HttpParams()
      .append('filename', documentName)
      .append('documentType', type)
      .append('documentId', id);
    config['responseType'] = 'blob';
    const url = this.serviceUrl + 'downloadPdf';
    return this.http.get(url, config);
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }

  private buildHeadersPdf() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', '*/*'),
    };
  }
}
