import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AWFunctionalButtonsEnum,
  NonAWFunctionalButtonsEnum,
} from 'src/app/in-memory-data/debtor/account/functional-buttons';
import { AccountingSharedDataService } from 'src/app/shared/services/accounting/accounting-shared-data.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TablesService } from 'src/app/shared/tables/tables.service';

@Component({
  selector: 'app-accounting-functional-buttons',
  templateUrl: './accounting-overview-functional-buttons.component.html',
  styleUrls: ['./accounting-overview-functional-buttons.component.less'],
})
export class AccountingOverviewFunctionalButtonsComponent implements OnInit {
  @Input() searchForm: UntypedFormGroup;
  entityName = 'accounting-overview-functional-buttons';
  private FAV_FUNCTIONS_KEY = 'accounting_overview_fav_functions';
  favButtons;
  private maxFavButtons: any = 5;
  sharedSelectedRows: any;
  currentSharedTab: any;
  @Input() searchTerm: BehaviorSubject<TransactionSearchFilter>;
  @Input() allocationsAllowed = true;

  constructor(
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private accountingSharedDataService: AccountingSharedDataService,
    private tablesService: TablesService
  ) {
    this.searchFormBuilder();
    this.accountingSharedDataService.currentTab.subscribe(
      (sharedTab) => (this.currentSharedTab = sharedTab)
    );

    this.tablesService.currentSharedSelectedRows.subscribe(
      (currentSharedSelectedRows) =>
        (this.sharedSelectedRows = currentSharedSelectedRows)
    );
  }

  ngOnInit() {
    this.searchFormChanges();
    this.getCurrentBusinessUnitFunctionalButtons();
  }

  getCurrentBusinessUnitFunctionalButtons() {
    if (this.businessUnitService.getCurrentBusinessUnit() === '4') {
      this.favButtons = AWFunctionalButtonsEnum;
    } else {
      this.favButtons = NonAWFunctionalButtonsEnum;
    }
  }

  searchFormBuilder() {
    this.searchForm = new UntypedFormGroup({
      freeFormSearch: new UntypedFormControl('', []),
    });
  }

  searchFormChanges() {
    this.searchForm.valueChanges.pipe(debounceTime(700)).subscribe((value) => {
      this.accountingSharedDataService.updateSharedSpinnerState(true);
      this.searchTerm.next(value);
    });
  }

  modifyColumn(functionName, $event) {
    if ($event === true || $event.checked) {
      this.favButtons.filter(
        (button) => button.name === functionName
      )[0].checked = true;
    } else if ($event === false || !$event.checked) {
      this.favButtons.filter(
        (button) => button.name === functionName
      )[0].checked = false;
    }

    this.checkDisabledFavButtons();
  }

  checkDisabledFavButtons() {
    let checkedButtons = 0;
    this.favButtons.forEach((button) => {
      if (button.checked) {
        checkedButtons++;
      }
    });

    if (checkedButtons === this.maxFavButtons) {
      this.favButtons.forEach((button) => {
        if (!button.checked) {
          button.disabled = true;
        }
      });
    } else {
      this.favButtons.forEach((button) => {
        button.disabled = false;
      });
    }
  }

  getFavFunctions() {
    this.userService.getConfiguration(this.FAV_FUNCTIONS_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.functions.length === 0) {
            this.setDefaultFavorites();
          } else {
            this.setConfiguredFavories(data);
          }
        } else {
          this.setDefaultFavorites();
        }
      },
      (err) => {
        this.setDefaultFavorites();
      }
    );
  }

  setConfiguredFavories(data) {
    this.favButtons = data.functions;
    this.checkDisabledFavButtons();
  }

  setDefaultFavorites() {
    this.getCurrentBusinessUnitFunctionalButtons();
    this.checkDisabledFavButtons();
  }

  getFunction(button) {
    if (button && button.function) {
      this[button.function](button.name);
    }
  }

  callEmitFunction(functionName) {
    this.accountingSharedDataService.emitFunction(functionName);
  }

  getDisabled(button) {
    switch (button.name) {
      case 'objection':
        if (this.currentSharedTab === 0) {
          if (this.sharedSelectedRows && this.sharedSelectedRows.length > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }

      case 'paymentAllocation':
        if (this.currentSharedTab === 0) {
          if (this.sharedSelectedRows && this.sharedSelectedRows.length > 0) {
            const hasAllocationDraft = this.sharedSelectedRows.find(
              (row) => row.allocationDraft
            );
            if (hasAllocationDraft) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }

      case 'transactionHistory':
        if (this.sharedSelectedRows && this.sharedSelectedRows.length === 1) {
          return false;
        } else {
          return true;
        }

      default:
        return true;
    }
  }

  favButtonsChanged() {
    const configuration = {
      key: this.FAV_FUNCTIONS_KEY,
      value: {
        functions: this.favButtons,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }
}

export interface TransactionSearchFilter {
  freeFormSearch: string;
  documentDate: { begin: Moment; end: Moment };
}
