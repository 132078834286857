import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { salesEntryColumns } from 'src/app/in-memory-data/salesEntry/table-columns';
import { salesEntryColumnsConf } from 'src/app/in-memory-data/salesEntry/table-columns-configuration';
import { SalesEntryService } from 'src/app/shared/services/animal-welfare/sales-entry/sales-entry.service';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sales-entry-list',
  templateUrl: './sales-entry-list.component.html',
  styleUrls: ['./sales-entry-list.component.less'],
})
export class SalesEntryListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() filterEvent = new EventEmitter();

  entityName = 'sales';
  columns = salesEntryColumnsConf;
  displayedColumns = salesEntryColumns;
  searchInput: any = '';
  draftId;
  draftGuid;
  filterForm: UntypedFormGroup;
  tableForm: UntypedFormGroup;
  saleEntries;
  dataSource = new MatTableDataSource<any>([]);
  loaderSpinner = false;

  configs = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
  };
  totalEntries = 0;

  getDistinctValues = (choice, column) => {
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    return this.salesEntryService.getDistinctValues(filterOptions, choice);
  };

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private salesEntryService: SalesEntryService,
    private notificationService: NotificationService,
    private draftService: DraftService,
    private companyService: CompanyService,
    private userService: UserService,
    private kcService: KeycloakService
  ) {
    this.filterForm = this.formBuilder.group({
      searchString: null,
      tciSearchName: null,
    });
    this.tableForm = this.formBuilder.group({
      advancePayment: null,
    });
  }

  ngOnInit(): void {
    this.loaderSpinner = true;
    this.loadData();
    this.filterEvent.subscribe((data) => {
      this.filter(data);
    });
  }

  loadData() {
    const filterOptions = {
      ...this.configs,
      ...{
        filteredValues: JSON.stringify(this.configs.filteredValues),
      },
    };
    this.salesEntryService.findAll(filterOptions).subscribe(
      (data) => {
        this.dataSource.data = this.saleEntries = data['content'];
        this.totalEntries = data['total'];
        this.getDrafts();
        this.showNoDataFound(this.saleEntries);
        this.loaderSpinner = false;
      },
      (error) => {
        this.loaderSpinner = false;
      }
    );
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'centralMasterData') {
        return item.companyName;
      }
      return item[property];
    };
  }

  getDrafts() {
    if (environment.isInternal) {
      const entity = 'AW_SALES_PARTICIPANT';
      this.draftService.retrieveLocalDrafts(entity).subscribe((data) => {
        if (data != null) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);
            object.draft = true;
            object.draftId = draft.id;
            object.draftGuid = draft.guid;
            object.operationUser = draft.operationUser;
            this.addCompanyData(object);
            this.dataSource.data.unshift(object);
          });
          this.dataSource.filter = '';
        }
      });
    }
  }

  addCompanyData(salesParticipant): string {
    this.companyService
      .getCurrentApproved(salesParticipant.companyGuid)
      .subscribe((data) => {
        salesParticipant.companyApproved = data;
        salesParticipant.company =
          data.name +
          ', ' +
          data.address.street +
          ', ' +
          data.address.number +
          ', ' +
          data.address.zipCode +
          ', ' +
          data.address.city;
        salesParticipant.companyName = data.name;
      });
    return salesParticipant.company;
  }

  showNoDataFound(dataList) {
    if (dataList.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-BU-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  searchSalesEntry() {
    this.configs.filterText = this.searchInput;
    this.loadData();
  }

  edit(element) {
    if (this.kcService.isUserInRole('aw.s.u') || this.kcService.isUserInRole('aw.s.r')) {
      const state = {
        salesEntry: element,
        company: element,
        contractType: true,
        hasSalesEntry: true,
      };
      if (element['draft']) {
        state['draftId'] = element.draftId;
        state['draftGuid'] = element.draftGuid;
        state['operationUser'] = element.operationUser;
      }
      this.router.navigateByUrl('/salesEntry/create', {
        state,
      });
    }
  }

  filterValues = (choice, values) => {
    this.filterEvent.emit({
      filteredValues: { choice, values },
    });
  };

  filter(event) {
    if (event.filteredValues) {
      this.configs.filteredValues = this.configs.filteredValues.filter(
        (fv) => fv.choice !== event.filteredValues.choice
      );
      this.configs.filteredValues.push(event.filteredValues);
    } else {
      this.configs = {
        ...this.configs,
        ...event,
      };
      if (event.sortField) {
        if (event.sortField.choice) {
          this.configs.sortField = event.sortField.choice;
        }
        if (event.sortField.direction) {
          this.configs.sortDirection = event.sortField.direction;
        }
      }
    }
    this.loadData();
  }

  sortTable = (choice, direction) => {
    this.filterEvent.emit({ sortOrder: direction, sortField: choice });
  };

  paginate = (event) => {
    this.filterEvent.emit(event);
  };
}
