<div class="content-container">
  <app-back-button></app-back-button>

  <div class="tab-container">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        [routerLink]="['quantity']"
        *ngIf="hasRoleForPath('quantity')"
        routerLinkActive
        #rla="routerLinkActive"
        routerLinkActive="active-link"
        [active]="rla.isActive"
        id="{{ entityName }}-menu-animal-welfare-quantity-labelled"
        >{{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TITLE' | translate }}</a
      >
      <a
        mat-tab-link
        [routerLink]="['reportingPeriods']"
        *ngIf="hasRoleForPath('reportingPeriods')"
        routerLinkActive
        #rla="routerLinkActive"
        routerLinkActive="active-link"
        [active]="rla.isActive"
        id="{{ entityName }}-menu-animal-welfare-reporting-period"
        >{{ 'ANIMAL-WELFARE.REPORTING-PERIODS.TITLE' | translate }}</a
      >
      <a
        mat-tab-link
        [routerLink]="['goodsGroups']"
        *ngIf="hasRoleForPath('goodsGroups')"
        routerLinkActive
        #rla="routerLinkActive"
        routerLinkActive="active-link"
        [active]="rla.isActive"
        id="{{ entityName }}-menu-animal-welfare-goods-groups"
        >{{ 'ANIMAL-WELFARE.GOODS-GROUPS.TITLE' | translate }}</a
      >
    </nav>
  </div>

  <div class="content-container content-container-tab">
    <router-outlet></router-outlet>
  </div>
</div>
