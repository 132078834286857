<div class="content-container">
  <app-back-button></app-back-button>
  <div *ngIf="displaySplit" class="display-split">
    <button id="{{entityName}-display-split-button" mat-button class="display-split-button" (click)="drawer.open()">
      {{ 'GENERAL.DISPLAY-SPLIT' | translate }}
    </button>
  </div>

  <div *ngIf="showSpecialNotification" class="info-container">
    <div class="no-operator">
      {{ 'ANIMAL-WELFARE.SPECIAL-NOTIFICATIONS.UI-1014-ID-1' | translate }}
    </div>
  </div>

  <div class="info-container" id="{{ entityName }}-general-info" *ngIf="companyApproved">
    <h5 class="title-info" id="{{ entityName }}-general-info-company-title">
      {{ companyApproved.name }}
    </h5>

    <div class="container general-display-alignment" fxLayout="row wrap" fxlayout.md="column" fxlayout.sm="column"
      fxlayout.xs="column" fxLayoutGap="7px grid">
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-street-label">{{
                'COMPANY.CONTACT-PERSON.STREET-NAME' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information" id="{{ entityName }}-general-info-company-street-value">{{
                companyApproved.address.street
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-number-label">{{
                'COMPANY.CONTACT-PERSON.HOUSE-NUMBER' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information" id="{{ entityName }}-general-info-company-number-value">{{
                companyApproved.address.number
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-zip-label">{{
                'COMPANY.CONTACT-PERSON.ZIP-CODE' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information" id="{{ entityName }}-general-info-company-zip-value">{{
                companyApproved.address.zipCode
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-city-label">{{
                'COMPANY.CONTACT-PERSON.CITY' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information" id="{{ entityName }}-general-info-company-city-value">{{
                companyApproved.address.city
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-country-label">{{
                'COMPANY.CONTACT-PERSON.COUNTRY' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information" id="{{ entityName }}-general-info-company-country-value">{{
                getCountry(companyApproved.address.country)
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-country-code-label">{{
                'COMPANY.CONTACT-PERSON.COUNTRY-CODE' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information"
                id="{{ entityName }}-general-info-company-country-code-value">{{ companyApproved.address.country
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="companyApproved.address">
        <div fxLayout="row">
          <div fxFlex="30">
            <div class="label-container">
              <label for="companyStreet" id="{{ entityName }}-general-info-company-language-label">{{
                'COMPANY.CONTACT-PERSON.LANGUAGE' | translate
                }}</label>
            </div>
          </div>
          <div fxFlex="30">
            <div class="span-container">
              <span name="companyStreet" class="information"
                id="{{ entityName }}-general-info-company-language-value">{{ getLanguage(companyApproved.language)
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-task">
    <button *hasPermission="'md.u'" id="{{entityName}-edit-task" mat-button class="edit-task-button"
      (click)="changeEditable(true)">
      <img *ngIf="!editableTask && isTask && !disableContractInfo" src="assets/images/ic_edit.svg"
        alt="{{ 'GENERAL.EDIT' | translate }}" />
    </button>
  </div>

  <div class="title-form" [hidden]="disableContractInfo">
    <h3 id="{{ entityName }}-form-title-create" *ngIf="!edit">
      {{ 'ANIMAL-WELFARE.SALES-ENTRY.CREATION' | translate }}
    </h3>
    <h3 id="{{ entityName }}-form-title-edit" *ngIf="edit">
      {{ 'ANIMAL-WELFARE.SALES-ENTRY.CONFIGURATION' | translate }}
    </h3>
  </div>

  <div class="tab-container">
    <mat-tab-group *ngIf="!showSpecialNotification">
      <mat-tab *ngIf="!disableContractInfo">
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-contract-information-tab">
            {{ 'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-INFORMATION' | translate }}
          </div>
        </ng-template>
        <app-sales-entry-contract-information *ngIf="salesEntryApproved || entitiesLoaded"
          [salesEntryApproved]="salesEntryApproved" [salesEntryTask]="salesEntryTask" [draftExists]="draftExists"
          [readOnly]="readOnly">
        </app-sales-entry-contract-information>
      </mat-tab>

      <mat-tab label="{{ 'COMPANY.BANK-ACCOUNT' | translate }}">
        <app-company-bank-account *ngIf="companyApproved" [companyGuid]="companyApproved.guid" [readOnly]="readOnly">
        </app-company-bank-account>
      </mat-tab>

      <mat-tab label="{{ 'COMPANY.CONTACT-PERSON' | translate }}">
        <app-company-contact-person *ngIf="companyApproved" [companyGuid]="companyApproved.guid" [readOnly]="readOnly">
        </app-company-contact-person>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="filter-input button-container">
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" *ngIf="editableTask && isTask"
      (click)="changeEditable(false)">
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <ng-container *hasPermission="['aw.sp.u']">
      <button *ngIf="(editableTask || !isTask) && validForm && !draftExists" [disabled]="readOnly || !validForm.valid"
        mat-button class="custom-button" (click)="onClickSave()" id="{{ entityName }}-create-button">
        <span *ngIf="save">
          {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
        </span>
        <span *ngIf="!save">
          {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
        </span>
      </button>
    </ng-container>
  </div>

  <ng-container>
    <mat-drawer-container>
      <mat-drawer #drawer class="sidenav" mode="over" position="end">
        <button type="button" mat-button (click)="drawer.close()" class="close-split">
          <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
        </button>
        <app-split-central-animal-welfare [splitData]="splitData" [originalData]="originalData"
          [companyName]="companyName">
        </app-split-central-animal-welfare>
      </mat-drawer>
    </mat-drawer-container>
  </ng-container>
</div>