import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  eventEmiterFunction = new EventEmitter();
  suscriptionEmitter: Subscription;

  reloadHistory() {
    this.eventEmiterFunction.emit();
  }
}
