import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class ContactPersonLinkService {
  serviceUrl;
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.companyService;
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  createBatch(list) {
    const request = { contactPersonLinkList: list };
    return this.http.post(
      this.serviceUrl + 'contact-person-links',
      request,
      this.buildHeaders()
    );
  }

  update(contactPersonLink) {
    return this.http.put(
      this.serviceUrl + 'contact-person-links',
      contactPersonLink,
      this.buildHeaders()
    );
  }

  findByEntityGuid(entityGuid) {
    return this.http.get(
      this.serviceUrl + 'contact-person-links/by-entity-guid',
      {
        params: { entityGuid },
        headers: this.buildHeaders().headers,
      }
    );
  }

  getContactPersonInfo(guid) {
    return this.http.get(
      this.serviceUrl + 'contact-person-links/get-contact-person/' + guid,
      this.buildHeaders()
    );
  }

  deleteBatchByIds(ids) {
    const request = { ids };
    return this.http.request(
      'DELETE',
      this.serviceUrl + 'contact-person-links/batch',
      {
        headers: this.buildHeaders().headers,
        body: request,
      }
    );
  }
}
