import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';
import { DEFAULT_MESSAGES, MESSAGE_TYPE } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  MESSAGE_TYPE = MESSAGE_TYPE;
  DEFAULT_MESSAGES = DEFAULT_MESSAGES;

  messageIsShown = false;

  icon = '';
  snack: any;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  showToastMessage(
    messageKey: string,
    type: string,
    showRedirectButton?: boolean
  ) {
    if (this.messageIsShown === false) {
      this.showMessageInternal(
        messageKey,
        type,
        null,
        null,
        null,
        null,
        null,
        true,
        showRedirectButton
      );
    }
  }

  private showMessageInternal(
    messageKey: string,
    type: string,
    entity?: string,
    error?: string,
    name?: string,
    id?: string,
    duration?: number,
    toast?: boolean,
    showRedirectButton?: boolean
  ) {
    const icon: string = this.getIconFromType(type);
    let snack: any;
    if (toast) {
      snack = this.snackBar.openFromComponent(CustomSnackBarComponent, {
        data: {
          messageKey,
          showRedirectButton,
        },
        duration: undefined,
        verticalPosition: 'top',
        panelClass: [type + '-snackbar', 'custom-snackbar'],
      });
    } else {
      snack = this.snackBar.open(
        icon,
        this.translateService.instant(messageKey, {
          data: entity,
          name,
          error,
          id,
          field: name,
          status: error,
        }),
        {
          duration: duration,
          verticalPosition: 'top',
          panelClass: [type + '-snackbar', 'custom-snackbar'],
        }
      );
    }
    snack.afterDismissed().subscribe(() => (this.messageIsShown = false));
  }

  dismissMessage() {
    this.snackBar.dismiss();
  }

  getIconFromType(type: string): string {
    switch (type) {
      case 'success':
        return 'check_circle_outline';
      case 'info':
        return 'info_outline';
      case 'error':
        return 'report_problem';
      default:
        return 'report_problem';
    }
  }

  mountDetails(messageKey, details): any {
    switch (messageKey) {
      case 'ERROR-MESSAGES.INVALID-BACKEND':
        return {
          error: details.errorId ? details.errorId : details.status,
          name: details.errorId
            ? details.status
            : details.message
            ? details.message
            : details.name,
        };
      case 'ERROR-MESSAGES.ERROR-BACKEND':
        return {
          error: details.errorId ? details.errorId : details.status,
          name: details.errorId
            ? details.status
            : details.message
            ? details.message
            : details.name,
        };
      case 'ERROR.REQUIRES-ADMIN':
        return { id: details.errorId ? details.errorId : details.status };
      default:
        return details;
    }
  }

  showToast(
    messageKey: string,
    type: string,
    details?: any,
    duration?: number
  ) {
    this.icon = this.getIconFromType(type);
    this.snackBar.open(
      this.icon,
      this.translateService.instant(
        messageKey,
        this.mountDetails(messageKey, details)
      ),
      {
        duration: duration ? duration : 5000,
        verticalPosition: 'top',
        panelClass: [type + '-snackbar', 'custom-snackbar'],
      }
    );
  }
}
