export const translationListColumnsConf: any[] = [
  {
    name: 'flags',
    index: 0,
    checked: true,
    translateName: 'INFO-FLAG',
    locked: true,
  },
  {
    name: 'id',
    index: 1,
    checked: true,
    translateName:
      'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ID',
    locked: true,
  },
  {
    name: 'german',
    index: 2,
    checked: true,
    translateName: 'GENERAL.LANGUAGE.GERMAN',
    locked: true,
  },
  {
    name: 'english',
    index: 3,
    checked: true,
    translateName: 'GENERAL.LANGUAGE.ENGLISH',
    locked: true,
  },
  {
    version: 1,
  },
];
