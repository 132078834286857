export const permissionOverviewKnownRolesColumnsConf: any[] = [
  {
    name: 'flags',
    index: 0,
    checked: true,
    translateName: 'INFO-FLAG',
    locked: true,
    smallColumn: true,
  },
  {
    name: 'roleName',
    index: 1,
    checked: true,
    translateName: 'USER-MANAGEMENT.ROLE-NAME',
    locked: true,
  },
  {
    name: 'roleType',
    index: 2,
    checked: true,
    translateName: 'USER-MANAGEMENT.ROLE-TYPE',
    locked: true,
  },
  {
    name: 'numberOfUsers',
    index: 3,
    checked: true,
    translateName: 'USER-MANAGEMENT.NUMBER-OF-USERS',
    locked: true,
  },
  {
    version: 3,
  },
];
