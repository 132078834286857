import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import {
  TranslationService
} from '../../../../shared/services/translation/translation.service';

@Component({
  selector: 'app-dashboard-manual',
  templateUrl: './dashboard-manual.component.html',
  styleUrls: ['./dashboard-manual.component.less'],
})
export class DashboardManualComponent {
  constructor(
    private translateService: TranslateService,
    private translationService: TranslationService,
    private keycloakService: KeycloakService
  ) {
  }

  downloadFaq() {
    const lang = this.translationService.getCurrentLanguage();
    let fileLocation: string;
    if (lang === 'en') {
      fileLocation = 'assets/documents/20221116_FAQs_Animal Welfare_EN.pdf';
    } else {
      fileLocation = 'assets/documents/20221116_FAQs_Tierwohl_DE.pdf';
    }

    const link = document.createElement('a');
    link.download = 'FAQs_Animal_Welfare';
    link.href = fileLocation;
    link.click();
    link.remove();
  }

  downloadManual(index) {
    let fileLocation: string;
    const lang = this.translationService.getCurrentLanguage();

    switch (index) {
      case 1:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/coordinatorSlaughterhouse/en/20221116_Animal Welfare Setup FTP Server_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/coordinatorSlaughterhouse/de/20221116_Initiative Tierwohl Einrichtung FTP Server_DE.pdf';
        }
        break;
      case 2:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/coordinator/en/20221116_Animal Welfare Initiative Pig Movement data Coordinator_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/coordinator/de/20221116_Initiative Tierwohl Schwein Bewegungsdaten Bündler_DE.pdf';
        }
        break;
      case 3:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/coordinator/en/' +
            '20221116_Animal Welfare Initiative Poultry Movement data Coordinator_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/coordinator/de/20221116_Initiative Tierwohl Geflügel Bewegungsdaten Bündler_DE.pdf';
        }
        break;
      case 4:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/coordinator/en/20221116_Animal Welfare Manual Coordinator_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/coordinator/de/20221116_Initiative Tierwohl Handbuch Buendler_DE.pdf';
        }
        break;
      case 5:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/en/20221116_ Animal Welfare Initiative Poultry -  Movement data Abbatoir_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/de/' +
            '20221116_Initiative Tierwohl Geflügel - Bewegungsdaten Schlachtbetrieb_DE.pdf';
        }
        break;
      case 6:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/en/' +
            '20221116_Animal Welfare Initiative Pig - Movement Data Abbatoir_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/de/' +
            '20221116_Initiative Tierwohl Schwein - Bewegungsdaten Schlachtbetrieb_DE.pdf';
        }
        break;
      case 7:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/en/20221116_Animal Welfare Initiative Cattle -  Movement data Abbatoir_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/de/20221116_Initiative Tierwohl Rind - Bewegungsdaten Schlachtbetrieb_DE.pdf';
        }
        break;
      case 8:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/en/20221116_Animal Welfare Manual Abattoir_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/slaughterhouse/de/20221116_Initiative Tierwohl Schlachtbetrieb_DE.pdf';
        }
        break;
      case 9:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/marketer/en/20221116_Animal Welfare Manual Marketer_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/marketer/de/20221116_Initiative Tierwohl Handbuch Vermarkter_DE.pdf';
        }
        break;
      case 10:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/livestockOwner/en/20221116_Animal Welfare Manual Livestock Owner_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/livestockOwner/de/20221116_Initiative Tierwohl Handbuch Tierhalter_DE.pdf';
        }
        break;
      case 11:
        if (lang === 'en') {
          fileLocation =
            'assets/documents/manuals/marketer/en/20221116_Animal Welfare Initiative Cattle -  Movement data Marketer_EN.pdf';
        } else {
          fileLocation =
            'assets/documents/manuals/marketer/de/20221116_Initiative Tierwohl Rind - Bewegungsdaten Vermarkter_DE.pdf';
        }
        break;
    }

    if (fileLocation) {
      const link = document.createElement('a');
      link.download = this.translateService.instant('HOMEPAGE.MANUAL-' + index);
      link.href = fileLocation;
      link.click();
      link.remove();
    }
  }

  isSlaughterhouse() {
    return this.keycloakService.isUserInRole('ext.aw.s');
  }
}
