<div class="permission-known-roles-container">
  <mat-form-field appearance="fill" class="search-for-entries">
    <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
    <input
      (ngModelChange)="startSearch()"
      [(ngModel)]="searchString"
      [ngModelOptions]="{ standalone: true }"
      matInput
      placeholder="{{ 'GENERAL.SEARCH' | translate }}"
      type="text"
      removeHtmlTagsForNgModel
    />
  </mat-form-field>
  <span *ngIf="loaderSpinner" class="dynamic-loader-table">
    <mat-spinner [diameter]="50"></mat-spinner>
  </span>
  <app-default-table
    (filterEvent)="filter($event)"
    *ngIf="dataLoaded && !loaderSpinner"
    [columns]="columns"
    [configurationKey]="configurationKey"
    [displayedColumns]="displayedColumns"
    [entityName]="configurationKey"
    [originalColumns]="columns"
    [originalDisplayedColumns]="displayedColumns"
    [tableDataSource]="rolesList"
    [threeDots]="false"
  ></app-default-table>
</div>
