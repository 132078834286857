<div *ngIf="control" class="simple-input">
  <mat-form-field [appearance]="appearance" [class]="classes">
    <mat-label>{{ label | translate }}</mat-label>
    <input
      [type]="type"
      matInput
      [formControl]="control"
      [placeholder]="label | translate"
      [title]="label | translate"
      [readonly]="disabled"
      [attr.disabled]="disabled"
      mask="{{ mask ? mask : null }}"
      suffix="{{ suffix ? suffix : null }}"
      thousandSeparator="{{ thousandSeparator ? thousandSeparator : null }}"
      decimalMarker="{{ decimalMarker ? decimalMarker : null }}"
    />
    <mat-error *ngIf="control.invalid">{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</div>
