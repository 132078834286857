import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private serviceUrlComments;
  private serviceUrlAttachments;
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrlComments = environment.settings.openItemService;
    this.serviceUrlAttachments = environment.settings.fileService;
  }

  eventEmiterFunction = new EventEmitter();
  suscriptionEmitter: Subscription;

  emitFunction() {
    this.eventEmiterFunction.emit();
  }

  getMultipleTransactionComments(transactionIds: any[]) {
    return this.http.get(this.serviceUrlComments + 'comments', {
      params: {
        ids: transactionIds,
      },
      headers: this.buildHeaders().headers,
    });
  }

  sendCommentsForMultipleTransactions(request) {
    return this.http.post<any[]>(
      this.serviceUrlComments + 'comments/batch',
      request,
      this.buildHeaders()
    );
  }

  deleteCommentsForMultipleTransactions(commentIds: any[]) {
    return this.http.delete(this.serviceUrlComments + 'comments/batch', {
      params: {
        ids: commentIds,
      },
      headers: this.buildHeaders().headers,
    });
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
