<div class="dialog-content">
  <form [formGroup]="approvalForm" autocomplete="off">
    <div mat-dialog-content class="background-dark">
      <h1 mat-dialog-title>
        {{ 'COMMENT' | translate }}
      </h1>
      <div *ngIf="hasEnoughCompetence">
        <p class="has-enough-competence">
          {{ 'DISBURSEMENT.HAS-ENOUGH-COMPETENCE' | translate }}
        </p>
      </div>
      <mat-form-field class="white-back">
        <mat-label>{{ 'COMMENT' | translate }}</mat-label>
        <textarea
          [maxlength]="commentingSettings.MAXIMUM_COMMENT_SIZE"
          matInput
          placeholder="{{ 'COMMENT' | translate }}"
          name="comment"
          formControlName="comment"
          [(ngModel)]="data.comment"
          id="{{ entityName }}-comment-textarea"
          [required]="commentRequired"
          (input)="calculateRemainingCommentCharacters()"
        ></textarea>
        <div class="textarea-footer">{{ remainingCharacters }}/{{ commentingSettings.MAXIMUM_COMMENT_SIZE }}</div>
      </mat-form-field>

      <div class="reason-container" *ngIf="approvalInterfaceReasons">
        <mat-form-field id="reson1">
          <mat-label>{{ 'CONFIRMATION-MODAL.REASONS' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="data.rejectionReasonCode"
            placeholder="{{ 'CONFIRMATION-MODAL.REASON' | translate }}"
            formControlName="rejectionReasonCode"
          >
            <mat-option
              *ngFor="let option of reasons | sortBy: { property: 'translateName', descending: false }"
              [value]="option.code"
            >
              {{ option.translateName | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <button id="{{ entityName }}-comment-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
        {{ 'CANCEL' | translate }}
      </button>
      <button
        id="{{ entityName }}-comment-approval-button"
        *ngIf="!dialogData"
        mat-button
        class="custom-button"
        id="sendToApproval"
        [disabled]="approvalForm.invalid"
        [mat-dialog-close]="data"
        style="margin-right: 0"
      >
        {{ 'SUBMIT' | translate }}
      </button>
      <button
        id="{{ entityName }}-comment-positive-button"
        *ngIf="dialogData && dialogData.positiveLabel"
        mat-button
        class="custom-button"
        id="sendToApproval"
        [disabled]="approvalForm.invalid"
        [mat-dialog-close]="data"
        style="margin-right: 0"
      >
        {{ dialogData.positiveLabel | translate }}
      </button>
      <button
        id="{{ entityName }}-comment-submit-button"
        *ngIf="dialogData && !dialogData.positiveLabel"
        mat-button
        class="custom-button"
        id="sendToApproval"
        [disabled]="approvalForm.invalid"
        [mat-dialog-close]="data"
        style="margin-right: 0"
      >
        {{ 'SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
