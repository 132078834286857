import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../services/notification/notification.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleErrorMessage(error);
        return throwError(error);
      })
    );
  }

  handleErrorMessage(err) {
    switch (err.status?.toString()) {
      case '500':
        this.notificationService.showToast(
          'ERROR-MESSAGES.INVALID-BACKEND',
          this.notificationService.MESSAGE_TYPE.ERROR,
          err.error ? err.error : err
        );
        break;
      case '400':
        this.notificationService.showToast(
          'ERROR-MESSAGES.INVALID-BACKEND',
          this.notificationService.MESSAGE_TYPE.ERROR,
          err
        );
        break;
      case '401':
        this.notificationService.showToast(
          'ERROR-MESSAGES.INVALID-BACKEND',
          this.notificationService.MESSAGE_TYPE.ERROR,
          err
        );
        break;
      case '403':
        this.notificationService.showToast(
          'ERROR.REQUIRES-ADMIN',
          this.notificationService.MESSAGE_TYPE.ERROR,
          err
        );
        break;
      default:
        break;
    }
  }
}
