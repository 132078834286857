<div class="row financial-kpis-container" *ngIf="!!kpis && !!originalKpis">
  <div class="col-lg-3 col-md-6 col-xs-12 item">
    <span> {{ 'ACCOUNTING.KPI.BALANCE' | translate }} </span>
    <strong [appHighlightDirective]="kpis?.balance" [originalValue]="originalKpis?.balance">
      {{ kpis?.balance | currency: 'EUR':'symbol':'1.2-2':currentLanguage }}
    </strong>
  </div>
  <div class="col-lg-3 col-md-6 col-xs-12 item">
    <span> {{ 'ACCOUNTING.KPI.BALANCE-SELECTED' | translate }} </span>
    <strong [appHighlightDirective]="kpis?.balanceSelected" [originalValue]="originalKpis?.balanceSelected">
      {{ kpis?.balanceSelected | currency: 'EUR':'symbol':'1.2-2':currentLanguage }}
    </strong>
  </div>
  <div class="col-lg-3 col-md-6 col-xs-12 item">
    <span> {{ 'ACCOUNTING.KPI.CREDIT-NOTE-AMOUNT' | translate }} </span>
    <strong [appHighlightDirective]="kpis?.creditNoteAmount" [originalValue]="originalKpis?.creditNoteAmount">
      {{ kpis?.creditNoteAmount | currency: 'EUR':'symbol':'1.2-2':currentLanguage }}
    </strong>
  </div>
  <div class="col-lg-3 col-md-6 col-xs-12 item">
    <span> {{ 'ACCOUNTING.KPI.OPEN-PAYMENT-AMOUNT' | translate }} </span>
    <strong [appHighlightDirective]="kpis?.openPaymentAmount" [originalValue]="originalKpis?.openPaymentAmount">
      {{ kpis?.openPaymentAmount | currency: 'EUR':'symbol':'1.2-2':currentLanguage }}
    </strong>
  </div>
</div>
