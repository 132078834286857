import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingCodeCreateComponent } from './booking-code-create/booking-code-create.component';
import { BookingCodeListComponent } from './booking-code-list/booking-code-list.component';

const routes: Routes = [
  {
    path: '',
    component: BookingCodeCreateComponent,
  },
  {
    path: 'list',
    component: BookingCodeListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingCodeRoutingModule {}
