import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class AbstractInputDirective implements OnDestroy {
  requiredValidator = false;
  protected unsubscribe = new Subject();

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

  protected initAndUpdateRequiredValidator(controlObject: UntypedFormControl) {
    this.updateRequiredValidator(controlObject);
    // Update validators if needed
    if (controlObject) {
      controlObject.statusChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => this.updateRequiredValidator(controlObject));
    }
  }

  private updateRequiredValidator(controlObject: any) {
    if (controlObject) {
      let validator;
      if (controlObject.validator) {
        validator = controlObject.validator({} as AbstractControl);
      }
      if (validator) {
        this.requiredValidator = validator.required;
      } else if (this.requiredValidator) {
        // There is no validator anymore or required was set to false
        this.requiredValidator = false;
      }
    }
  }
}
