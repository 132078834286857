export const allocationDraftColumnsConfAW: any[] = [
  {
    name: 'operationDate',
    index: 0,
    checked: true,
    translateName: 'GENERAL.DATE',
    locked: true,
    number: false,
  },
  {
    name: 'operationUser',
    index: 1,
    checked: true,
    translateName: 'GENERAL.USER',
    locked: true,
    number: false,
  },
  {
    name: 'avisReference',
    index: 2,
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.DRAFT-TABLE.AVIS-REFERENCE',
    locked: true,
    number: false,
  },
  {
    name: 'balance',
    index: 3,
    checked: true,
    translateName: 'DEBTOR.BALANCE',
    locked: true,
    number: true,
  },
  {
    name: 'currency',
    index: 4,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.TABLE.CURRENCY',
    locked: true,
    number: false,
  },
  {
    name: 'numberTransactions',
    index: 5,
    checked: false,
    translateName: 'TRANSACTION-OVERVIEW.DRAFT-TABLE.NUMBER-TRANSACTIONS',
    locked: true,
    number: true,
  },
  {
    version: 1,
  },
];
