<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchTranslationList()"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
    </div>
    <div class="filter-input button-container">
      <button
        *hasPermission="'sc.t.c'"
        mat-button
        class="custom-button"
        id="{{ entityName }}-create-button"
        (click)="create()"
      >
        {{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.NEW-LANGUAGE' | translate }}
      </button>
    </div>
  </div>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <table aria-hidden="true" mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table">
      <ng-container matColumnDef="flags">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-icons"
        ></th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-icons-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.icons }}
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-account-id"
        >
          {{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-account-id-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.key }}
        </td>
      </ng-container>
      <ng-container matColumnDef="german">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-german"
        >
          {{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.GERMAN' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-german-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.translationValues.german }}
        </td>
      </ng-container>
      <ng-container matColumnDef="english">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-english"
        >
          {{ 'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.TRANSLATION-LIST.ENGLISH' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-english-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.translationValues.english }}
        </td>
      </ng-container>
      <ng-container *ngIf="filteredKeys && filteredKeys.length > 0">
        <ng-container *ngFor="let filteredKey of filteredKeys" [matColumnDef]="filteredKey">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ filteredKey }}"
          >
            {{ 'GENERAL.LANGUAGE.' + filteredKey.toString().toUpperCase() | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-{{ filteredKey }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.translationValues[filteredKey] }}
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="$event.stopPropagation()"></td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [hidden]="loaderSpinner"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          'draft-row': row.draft
        }"
        (click)="edit(row)"
      ></tr>
      <tr [hidden]="loaderSpinner" mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
    </table>
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalCount"
      (page)="paginate($event)"
    ></mat-paginator>
  </div>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
