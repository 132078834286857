import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DirtyComponent } from '../models/dirtyComponent';
import { DismissChangesDialogComponent } from '../dialogs/dismiss-changes-dialog/dismiss-changes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
  constructor(private dialog: MatDialog) {}

  result = false;

  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (component.canDeactivate()) {
      return this.getPromiseFromDialog();
    } else {
      return true;
    }
  }

  async getPromiseFromDialog() {
    const value = await this.dialog
      .open(DismissChangesDialogComponent, {
        width: '20em',
        data: {
          content: 'DIALOGS.DISMISS-CHANGES.MESSAGE',
        },
      })
      .afterClosed()
      .toPromise();
    return value;
  }
}
