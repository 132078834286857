import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { buOverviewTabColumns } from 'src/app/in-memory-data/bookingCode/bu-overview-tab/table-columns';
import { buOverviewTabColumnsConf } from 'src/app/in-memory-data/bookingCode/bu-overview-tab/table-columns-configuration';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TransactionCodeAllocationEntitiesService } from '../../../shared/services/transaction-code-allocation-entities/transaction-code-allocation-entities.service';
import { TransactionCodeAllocationEntity } from '../../../shared/models/transaction-code-allocation-entity';
import { statusTypes } from 'src/app/in-memory-data/business-unit-transaction-codes/enum/statusTypes';
import { first } from 'rxjs/operators';
import { BusinessUnitService } from '../../../shared/services/business-unit/business-unit.service';

@Component({
  selector: 'app-booking-code-bu-overview',
  templateUrl: './booking-code-bu-overview.component.html',
  styleUrls: ['./booking-code-bu-overview.component.less'],
})
export class BookingCodeBuOverviewComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() bookingCodeId: number;
  columns = buOverviewTabColumnsConf;
  displayedColumns = buOverviewTabColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  draftId: any;
  private CONFIGURAIONT_KEY = 'booking_code_bu_overview_table';
  statusTypes = statusTypes;
  data: TransactionCodeAllocationEntity[] = [];
  dataSource = new MatTableDataSource<TransactionCodeAllocationEntity>(
    this.data
  );
  searchInput: any;
  originalDataSource;
  entityName = 'business-unit-overview-record';

  totalCount: number;
  pageSize = 20;
  sortedBy = 'businessUnitId';
  sortDirection = 'asc';

  loaderSpinner = false;
  filter;
  subject = new Subject();
  getFieldNames = (args): Observable<any> => {
    return EMPTY;
  };

  filterData = (choice, selection): void => {
    this.loaderSpinner = false;
    this.paginator.firstPage();
    this.subject.next(undefined);
  };

  sortTable = (choice, direction): void => {
    this.loaderSpinner = false;
    this.paginator.firstPage();
    this.sortedBy = choice;
    this.sortDirection = direction;
    this.subject.next(undefined);
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private businessUnitService: BusinessUnitService,
    private transactionCodeAllocationEntitiesService: TransactionCodeAllocationEntitiesService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
    });
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService
      .getConfiguration(this.CONFIGURAIONT_KEY)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.columns) {
            if (data.columns.length === 0) {
              this.setDefaultColumnValues();
            } else {
              const mergedConfig =
                this.userService.mergeTableConfigurationsFromUIIfNeeded(
                  this.CONFIGURAIONT_KEY,
                  data,
                  buOverviewTabColumnsConf,
                  buOverviewTabColumns
                );
              this.setConfiguredColumns(mergedConfig);
            }
          } else {
            this.setDefaultColumnValues();
          }
        },
        (err) => {
          this.setDefaultColumnValues();
        }
      );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = buOverviewTabColumnsConf;
    this.displayedColumns = buOverviewTabColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  ngOnInit() {
    this.loaderSpinner = true;
    this.getTableConfiguration();
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.loadData();
  }

  clickOnBusinessUnit(businessUnitClicked) {
    this.businessUnitService
      .getCurrentApproved(businessUnitClicked.businessUnitId)
      .subscribe((businessUnit) => {
        const state = { businessUnit };
        this.router.navigateByUrl('systemConfiguration/bu/create', {
          state,
        });
      });
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = false;
  }

  private loadData() {
    this.transactionCodeAllocationEntitiesService
      .retrieveEntitiesForTransactionCode(this.bookingCodeId)
      .pipe(first())
      .subscribe((bookingCodeList) => {
        this.loaderSpinner = false;
        this.data = this.dataSource.data = bookingCodeList.content;
        this.totalCount = bookingCodeList.total;
      });
  }

  getStatusTypeTranslateName(status: number) {
    return this.statusTypes.find((data) => data.code === status).translateName;
  }
}
