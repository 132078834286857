import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { typeOptions } from 'src/app/in-memory-data/animal-welfare/goods-groups/enum/typeOptions';
import { goodsGroupsColumns } from 'src/app/in-memory-data/animal-welfare/goods-groups/table-columns';
import { goodsGroupsColumnsConf } from 'src/app/in-memory-data/animal-welfare/goods-groups/table-columns-configuration';
import { GoodsGroupService } from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-goods-groups-list',
  templateUrl: './goods-groups-list.component.html',
  styleUrls: [
    './goods-groups-list.component.less',
    './goods-groups-list-md.component.less',
  ],
})
export class GoodsGroupsListComponent implements OnInit {
  private CONFIGURAIONT_KEY = 'goods_groups_table';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'goods-groups';

  filterForm: UntypedFormGroup;

  columns = goodsGroupsColumnsConf;
  displayedColumns = goodsGroupsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  typeOptions = typeOptions;

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;

  existentGoodsGroups: any;
  searchableGoodsGroups = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private goodsGroupService: GoodsGroupService,
    private searchService: SearchService
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.getTableConfiguration();
    this.loadTable();
    this.filterForm.valueChanges.subscribe(() => this.searchGoodsGroup());
  }

  loadTable() {
    this.goodsGroupService.findAll().subscribe((data: any) => {
      this.existentGoodsGroups = data;
      this.existentGoodsGroups.forEach((element) => {
        const goodsGroup = {
          germanName: element.germanName,
          englishName: element.englishName,
          type: element.type,
          comment: element.comment,
        };
        this.searchableGoodsGroups.push(goodsGroup);
      });
      this.dataSource.data = this.existentGoodsGroups;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  removeDefaultGoodsGroups() {
    const result = this.existentGoodsGroups.filter(
      (data) => data.default === false
    );
    return result;
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchTerm: null,
    });
  }

  searchGoodsGroup() {
    this.searchService.search(
      this.searchableGoodsGroups,
      this.filterForm.get('searchTerm').value,
      this.dataSource
    );
  }

  goToEditView() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  cancelElement() {
    // TODO: Remove the element backend functionality
  }

  editSelectedRows() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
      state: { goodsGroups: this.selection.selected },
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  findElementType(elementType) {
    const result = this.typeOptions.find((data) => data.name === elementType);

    return result.translateName;
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                goodsGroupsColumnsConf,
                goodsGroupsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = goodsGroupsColumnsConf;
    this.displayedColumns = goodsGroupsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
