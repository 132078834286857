export const btcMappingCodeColumnsConf: any[] = [
  {
    name: 'id',
    index: 0,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.ID',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'SYSTEM-CONFIGURATION.BTC-MAPPING.NAME',
    locked: true,
  },
  {
    version: 1,
  },
];
