export const transferOptions: any[] = [
  {
    value: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER-OPTIONS.POSITIVE',
  },
  {
    value: false,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER-OPTIONS.NEGATIVE',
  },
];
