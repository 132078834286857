export const quantityLabeledGoodsDraftColumnsConf: any[] = [
  {
    name: 'createdBy',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CREATED-BY',
    locked: true,
  },
  {
    name: 'checkSumQuantity',
    index: 1,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CHECK-SUM-QUANTITY',
    locked: true,
  },
  {
    name: 'checkSum',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.CHECK-SUM',
    locked: true,
  },

  {
    name: 'lastEditedOn',
    index: 3,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-ON',
    locked: true,
  },
  {
    name: 'lastEditedBy',
    index: 4,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DRAFTS.LAST-EDITED-BY',
    locked: true,
  },

  {
    version: '1',
  },
];
