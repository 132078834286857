export const openItemsKPIs: any[] = [
  {
    name: 'NUMBER_TRANSACTIONS',
    value: 1,
    translateName: 'OPEN-TRANSACTIONS.KPIS.NUMBER-TRANSACTIONS',
  },
  /*
  This commnent is intentional. This could be inserted again in the future
  {
    name: 'BALANCE',
    value: 0,
    translateName: 'OPEN-TRANSACTIONS.KPIS.BALANCE',
    type: 'currency',
  },
  {
    name: 'BALANCE_DEBIT',
    value: 0,
    translateName: 'OPEN-TRANSACTIONS.KPIS.BALANCE-DEBIT',
    type: 'currency',
  },
  {
    name: 'BALANCE_CREDIT',
    value: 0,
    translateName: 'OPEN-TRANSACTIONS.KPIS.BALANCE-CREDIT',
    type: 'currency',
  }, */
];
