<div class="control-board-wrapper">
  <div class="form-container">
    <form [formGroup]="formGroup" class="form">
      <div class="form-row">
        <mat-radio-group formControlName="target" (change)="changeTargetSelection($event.value)">
          <mat-radio-button value="PIG" class="left-radio">{{
            'ANIMAL-WELFARE.QUARTERLY-BILLING.TARGETS.PIG' | translate
          }}</mat-radio-button>
          <mat-radio-button value="POULTRY">{{
            'ANIMAL-WELFARE.QUARTERLY-BILLING.TARGETS.POULTRY' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="table-container">
        <app-default-table
          *ngIf="dataLoaded"
          [entityName]="entityName"
          [configurationKey]="configurationKey"
          [columns]="columns"
          [displayedColumns]="displayedColumns"
          [originalColumns]="columns"
          [originalDisplayedColumns]="displayedColumns"
          [tableDataSource]="object"
          [totalCount]="object.total"
          [defaultAngularPagination]="true"
          [defaultAngularSorting]="true"
        ></app-default-table>
      </div>

      <div class="form-row">
        <button
          *hasPermission="'aw.cb.c'"
          mat-button
          class="custom-button start-payout"
          (click)="confirmationStartPayout()"
          id="{{ entityName }}-start-payout-button"
          [disabled]="
            formGroup.invalid ||
            sharedSelectedRows == null ||
            sharedSelectedRows == undefined ||
            sharedSelectedRows.length == 0
          "
        >
          {{ 'ANIMAL-WELFARE.QUARTERLY-BILLING.START-PAYOUT' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
