import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SystemParameter } from './system-parameter';

@Injectable({
  providedIn: 'root',
})
export class SystemParameterService {
  private serviceUrl: string;
  private searchApiUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.systemParameterService;
    this.searchApiUrl = environment.settings.searchAPIUrl;
  }

  retrieveSystemParameters(): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'systemParameter',
      this.buildHeaders()
    );
  }

  updateSystemParamater(request) {
    return this.http.put(
      this.serviceUrl + 'systemParameter',
      request,
      this.buildHeaders()
    );
  }

  retrieveSystemParameterHistories(uuid): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'systemParameter/history/' + uuid,
      this.buildHeaders()
    );
  }

  getSystemParameterWithTaskId(id: any): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + id + '/business-unit',
      this.buildHeaders()
    );
  }

  getSystemParemeterByVisibleId(
    visibleId: string
  ): Observable<SystemParameter> {
    return this.http.get<SystemParameter>(
      `${this.serviceUrl}systemParameter/by-visible-id/${visibleId}`,
      this.buildHeaders()
    );
  }

  retrieveApprovedSystemParameters(uuid): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'systemParameter/' + uuid,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'systemParameter/drafts/' + id + '/approval',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any) {
    const json = { comment };
    return this.http.post(
      this.serviceUrl + 'systemParameter/drafts/' + id + '/rejection',
      json,
      this.buildHeaders()
    );
  }

  getFundDepositAmount(): Observable<number> {
    return this.http.get<number>(this.searchApiUrl + 'system-parameters/default-fund-deposit-amount', this.buildHeaders());
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
