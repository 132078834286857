export interface TableActionButtonsConfiguration {
  name: string;
  permission: string;
  icon: string;
  translationKey: string;
  function: any;
  selectionType: string;
  specialDisable: any;
  realm?: string;
}

export enum DefaultTableSelectionType {
  single = 'single',
  multiple = 'multiple',
  singleAndMultiple = 'singleAndMultiple',
}

export interface TableActionIconsConfiguration {
  name: string;
  icon: string;
  translationKey: string;
  function: any;
  class: string;
}
