<div class="content-container">
  <div class="filters" [formGroup]="filterForm">
    <div class="filter-input">
      <mat-form-field class="example-full-width">
        <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'GENERAL.SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          [(ngModel)]="searchInput"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchSalesEntry()"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.searchString"
        />
      </mat-form-field>
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchSalesEntry()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <div class="client-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <div class="sales-table-container">
      <table mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table" aria-hidden="true">
        <ng-container matColumnDef="accountNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
          >
            {{ 'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER' | translate }}
            <app-table-menu-sort-filter
              [choice]="'accountNumber'"
              [sortTable]="sortTable"
              [callback]="filterValues"
              [getDistinctValues]="getDistinctValues"
              [translatePrefix]=""
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.accountNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="centralMasterData">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
          >
            {{ 'ENTITIES.COMPANY' | translate }}
            <app-table-menu-sort-filter
              [choice]="'centralMasterData'"
              [sortTable]="sortTable"
              [callback]="filterValues"
              [getDistinctValues]="getDistinctValues"
              [translatePrefix]=""
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <div class="address-container">
              <div>{{ element.centralMasterData }}</div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
          >
            {{ 'GENERAL.TYPE' | translate }}
            <app-table-menu-sort-filter
              [choice]="'type'"
              [sortTable]="sortTable"
              [callback]="filterValues"
              [getDistinctValues]="getDistinctValues"
              [translatePrefix]=""
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            {{ element.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="advancePayment">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="v25-th"
            (click)="paginator.firstPage()"
            id="{{ entityName }}-table-results-{{ displayedColumns[4] }}"
          >
            {{ 'ANIMAL-WELFARE.SALES-ENTRY.ADVANCE-PAYMENT' | translate }}
            <app-table-menu-sort-filter
              [choice]="'advancePayment'"
              [sortTable]="sortTable"
              [callback]="filterValues"
              [getDistinctValues]="getDistinctValues"
              [translatePrefix]=""
            ></app-table-menu-sort-filter>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-slide-toggle [checked]="element.advancePayment"></mat-slide-toggle>
          </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
              {{ 'ERROR.NO-DATA-FOUND' | translate }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="loader">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <span class="general-loader-table" *ngIf="loaderSpinner">
              <mat-spinner [diameter]="50"></mat-spinner>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="element-row"
          (click)="edit(element)"
          [ngClass]="{ 'draft-row': element.draft }"
          [hidden]="loaderSpinner"
        ></tr>

        <tr
          [hidden]="!loaderSpinner"
          mat-footer-row
          *matFooterRowDef="['loader']"
          class="footer-spinner height-footer"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalEntries"
      (page)="paginate($event)"
    ></mat-paginator>
  </div>
</div>
