import { Component, OnInit } from '@angular/core';
import { DebtorService } from 'src/app/shared/services/debtor/debtor.service';
import { Debtor } from 'src/app/shared/models/debtor';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Client } from 'src/app/shared/models/client';
import { ClientService } from 'src/app/shared/services/client/client.service';

@Component({
  selector: 'app-open-item-header',
  templateUrl: './open-item-header.component.html',
  styleUrls: ['./open-item-header.component.less'],
})
export class OpenItemHeaderComponent implements OnInit {
  debtors: Debtor[];
  clientDebtorIds = new Array<any>();
  clientId: any;
  businessUnit: any;
  entityName = 'openItemCreate';
  errorRequired = 'error-message-required';
  openItemsHeaderForm: UntypedFormGroup;

  clients$: Observable<Client[]>;
  filteredClients: Observable<any[]>;
  clients: Client[] = [];

  constructor(
    private debtorService: DebtorService,
    private openItemService: OpenItemService,
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientService
  ) {
    this.openItemsHeaderForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      businessUnitId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.openItemService.currentClientDebtorIds.subscribe(
      (clientDebtorIds) => (this.clientDebtorIds = clientDebtorIds)
    );

    this.openItemsHeaderForm.get('clientId').valueChanges.subscribe((val) => {
      this.filteredClients = this.openItemsHeaderForm
        .get('clientId')
        .valueChanges.pipe(
          startWith(''),
          map((value) => (typeof val.toString() === 'string' ? val : val.id)),
          map((id) => (id ? this._filter(val) : this.clients.slice()))
        );

      this.searchClient(val);
    });

    this.openItemsHeaderForm
      .get('businessUnitId')
      .valueChanges.subscribe((val) => {
        this.setBuID(val);
      });

    this.openItemService.updateClientDebtorIds(new Array());

    // remove for prod
    this.getClientDebtorIds();

    this.loadClients();
  }

  async loadClients(): Promise<any> {
    const clients: any = await this.clientService
      .listApproved(null, 'id', 'asc', 1, 99999)
      .toPromise();
    this.clients$ = of(clients.content);
    this.clients = clients.content;
  }

  private _filter(id: string): any[] {
    return this.clients.filter(
      (option) => option.visibleId.toString().indexOf(id) === 0
    );
  }

  getClientDebtorIds() {
    if (!this.debtors) {
      return;
    }

    for (const debtor of this.debtors) {
      this.clientDebtorIds.push(debtor.clientDebtorId);
    }
    this.openItemService.updateClientDebtorIds(this.clientDebtorIds);
  }

  searchClient(val) {
    this.clientId = val;
    this.openItemService.setClientId(this.clientId);
    this.openItemService.checkTransactionCode(this.clientId);
    this.debtorService.getDebtorsForClient(this.clientId).subscribe(
      (data) => {
        this.clientDebtorIds = [];

        if (data.length !== 0) {
          this.debtors = data;
          this.getClientDebtorIds();
        } else {
          this.clientDebtorIds = [];
          this.openItemService.updateClientDebtorIds(this.clientDebtorIds);
        }
      },
      (error) => console.log(error)
    );
  }
  setBuID(val) {
    this.openItemService.setBuID(val);
  }
}
