export const typeAssetEnum: any[] = [
  {
    name: 'EARNING',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.EARNING',
  },
  {
    name: 'EXPENSE',
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.EXPENSE',
  },
];
