import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import {
  CountryPickerService,
  ICountry,
} from '../../../shared/services/countries/country-picker.service';
import { Observable, of } from 'rxjs';
import { decisionComplianceOptions } from 'src/app/in-memory-data/non-germany-reference-allow/countries/enum/decisionComplianceOptions';
import { decisionRiskOptions } from 'src/app/in-memory-data/non-germany-reference-allow/countries/enum/decisionRiskOptions';
import { nonGermanyReferenceAllowCountriesColumns } from 'src/app/in-memory-data/non-germany-reference-allow/countries/table-columns';
import { nonGermanyReferenceAllowCountriesColumnsConf } from 'src/app/in-memory-data/non-germany-reference-allow/countries/table-columns-configuration';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TciService } from 'src/app/shared/services/tci/tci.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-non-germany-reference-allow-list-countries',
  templateUrl: './non-germany-reference-allow-list-countries.component.html',
  styleUrls: ['./non-germany-reference-allow-list-countries.component.less'],
})
export class NonGermanyReferenceAllowListCountriesComponent implements OnInit {
  countries: ICountry[] = [];
  countries$: Observable<ICountry[]>;
  countryCode;
  selection = new SelectionModel(true, []);

  dataList: any;
  filterForm: UntypedFormGroup;

  entityName = 'non-germany-reference-allow-list-countries';
  dataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = nonGermanyReferenceAllowCountriesColumnsConf;
  displayedColumns = nonGermanyReferenceAllowCountriesColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  disableAllSelects = false;
  loaderSpinner = true;

  approvedEditedRows: any;
  form: UntypedFormGroup;

  saveMultiple = false;

  decisionComplianceOptions = decisionComplianceOptions;
  decisionRiskOptions = decisionRiskOptions;

  private CONFIGURAIONT_KEY = 'nonGermanReferenceAllow_table';

  newFormGroup;
  openDialog = false;

  constructor(
    private countryPicker: CountryPickerService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translateService: TranslateService,
    private matDialogService: MatDialog,
    private tciService: TciService,
    private notificationService: NotificationService,
    private sharedDataService: SharedDataService,
    private kcService: KeycloakService
  ) {
    this.filterFormBuilder();
    this.countriesFormBuilder();
  }

  ngOnInit(): void {
    this.getCountries();
    this.changeCountry();
    this.getTableConfiguration();

    this.countriesObjects.valueChanges.subscribe((data) => {
      this.checkChangesInform(data);
    });
  }

  countriesFormBuilder() {
    this.form = this.formBuilder.group({
      countries: new UntypedFormArray([]),
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      country: null,
    });
  }

  get formControls() {
    return this.form.controls;
  }

  get countriesObjects() {
    return this.formControls.countries as UntypedFormArray;
  }

  checkChangesInform(data) {
    data.forEach((element) => {
      const formGroup: UntypedFormGroup = this.getFormGroup(element);
      formGroup.valueChanges.subscribe(() => {
        if (this.selection.selected.length > 1) {
          if (this.openDialog === false && !this.saveMultiple) {
            this.openDialog = true;
            this.showConfirmationStartModal(formGroup.value, this.openDialog);
          }
        }
      });
    });
  }

  removeGermanyAndLoadTable() {
    const indexGermany = this.countries.findIndex(
      (data) => data.cca3 === 'DEU'
    );
    this.countries.splice(indexGermany, 1);
    this.loadTableWithCountries();
  }

  loadTableWithCountries() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    const loop = new Promise<void>((resolve, reject) => {
      this.countries.forEach((country, index, array) => {
        const object = {
          index,
          guid: null,
          countryCode: country.cca3,
          country: country.name.common,
          decisionCompliance: null,
          decisionComplianceReason: null,
          decisionRisk: null,
          decisionRiskReason: null,
          edited: true,
        };

        this.dataSource.data.push(object);
        this.addCountryRow(object);

        if (index === array.length - 1) {
          resolve();
        }
      });
    });

    loop.then(() => {
      this.dataList = Object.assign([], this.dataSource.data);
      this.dataSource.filter = '';
      this.getApprovedValues();
      this.loaderSpinner = false;

      this.countriesObjects.controls.forEach((object: any) => {
        object.get('decisionCompliance').valueChanges.subscribe((value) => {
          if (this.selection.selected.length < 1) {
            if (value === 3 || value === 2) {
              object.get('decisionRisk').patchValue(false);
            }
          }
        });
      });
    });
  }

  getApprovedValues() {
    this.tciService.listNonGermanyReferenceApproved().subscribe((data) => {
      this.approvedEditedRows = data;
      this.patchApprovedEditedRows();
    });
  }

  patchApprovedEditedRows() {
    if (this.approvedEditedRows && this.approvedEditedRows.length > 0) {
      this.approvedEditedRows.forEach((element) => {
        const formGroup = this.getFormGroup(element);
        if (formGroup) {
          formGroup.patchValue(element);
        }
      });
    }
  }

  getCountryName(code: string) {
    if (code != null) {
      if (!this.countries) {
        return code;
      }
      const country = this.countries.filter(
        (countryData) => countryData.cca3 === code
      )[0];
      if (country != null) {
        if (this.translateService.currentLang === 'de') {
          return country.translations.deu.common;
        }
        if (country) {
          return country.name.common;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }

  addCountryRow(object) {
    this.newFormGroup = this.formBuilder.group({
      index: object.index,
      countryCode: [
        { value: object.countryCode, disabled: false },
        [Validators.required],
      ],
      country: [
        { value: this.getCountryName(object.countryCode), disabled: true },
      ],
      decisionCompliance: [
        {
          value: 1,
          disabled: this.checkRole('ngral.dc.u'),
        },
        [Validators.required],
      ],
      decisionComplianceReason: [
        {
          value: '',
          disabled: this.checkRole('ngral.dc.u'),
        },
      ],
      decisionRisk: [
        {
          value: true,
          disabled: this.checkRole('ngral.dr.u'),
        },
        [Validators.required],
      ],
      decisionRiskReason: [
        {
          value: '',
          disabled: this.checkRole('ngral.dr.u'),
        },
      ],
      guid: [{ value: null, disabled: false }],
      edited: [{ value: false, disabled: false }],
    });
    this.countriesObjects.push(this.newFormGroup);
  }

  changeCountry() {
    this.filterForm.get('country').valueChanges.subscribe((item) => {
      this.searchCountry();
    });
  }

  searchCountry() {
    const data = this.filterArray(this.dataList);
    this.dataSource.data = data;
    this.dataSource.filter = '';
  }

  filterArray(data) {
    if (!data) {
      return;
    } else {
      let newData = [];
      for (const item of data) {
        for (const [key, value] of Object.entries(item)) {
          if (item.hasOwnProperty(`${key}`)) {
            if (key === 'country' || key === 'countryCode') {
              if (
                value
                  .toString()
                  .toLowerCase()
                  .includes(
                    this.filterForm
                      .get('country')
                      .value.toString()
                      .toLowerCase()
                  )
              ) {
                newData.push(item);
                break;
              }
            }
          }
        }
      }

      newData = this.removeRepeated(newData);

      data = newData;
    }

    return data;
  }

  removeRepeated(data) {
    return data.filter(
      (v, i, a) => a.findIndex((t) => t.countryCode === v.countryCode) === i
    );
  }

  getFormGroup(element) {
    let formGroup: any = null;

    formGroup = this.countriesObjects.controls.find(
      (item) => item.value.countryCode === element.countryCode
    );

    return formGroup;
  }

  getCountries() {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.countries$ = of(countries);
      this.countries = countries;
      this.removeGermanyAndLoadTable();
    });
  }

  showConfirmationStartModal(row, param?) {
    if (param) {
      const dialog = this.matDialogService.open(
        ConfirmationPromptModalComponent
      );

      dialog.componentInstance.messageKey =
        'NOTIFICATION.EDIT-MULTIPLES-ENTRIES';
      dialog.componentInstance.messageParams['param'] =
        this.selection.selected.length;
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          if (this.saveMultiple === false) {
            this.saveMultiple = true;
          }
          this.editSelectedCountries(row);
        }
        this.openDialog = false;
      });
    }
  }

  async editSelectedCountries(row) {
    this.filterForm.get('country').patchValue('');

    await this.disableSelectedRows(row);
    await this.hideUnselectedRows(row);

    this.saveMultiple = true;
    this.dataSource.filter = '';
  }

  async disableSelectedRows(row) {
    this.selection.selected.forEach((item, index, array) => {
      const formGroup = this.getFormGroup(item);
      if (formGroup.value.countryCode !== row.countryCode) {
        formGroup.disable();
      }
    });
  }

  async hideUnselectedRows(row) {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      const fg = this.getFormGroup(this.dataSource.data[i]);

      if (fg && fg.value.countryCode !== row.countryCode) {
        if (fg.enabled) {
          this.dataSource.data.splice(i, 1);
        }
      }
    }
    const formGroup = this.getFormGroup(row);
    const decisionComplianceValue = formGroup.get('decisionCompliance').value;
    if (decisionComplianceValue === 3) {
      formGroup.get('decisionRisk').patchValue(false);
    }
    formGroup.get('decisionCompliance').valueChanges.subscribe((newValue) => {
      if (newValue === 3) {
        formGroup.get('decisionRisk').patchValue(false);
      }
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async cancelEdit(row) {
    this.loaderSpinner = true;
    this.selection = new SelectionModel(true, []);
    await this.delay(300);
    this.openDialog = false;
    await this.enableAllRows();
    this.dataSource.data = Object.assign([], this.dataList);
    this.dataSource.filter = '';
    this.saveMultiple = false;
    this.patchApprovedEditedRows();
    this.loaderSpinner = false;
  }

  saveSingleCountry(row) {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.SAVE-ENTRIES';
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const updatedFormGroup = this.getFormGroup(row);
        this.markFormGroupAsEdited(updatedFormGroup, true);
        this.saveEditedValues();
      }
    });
  }

  saveMultipleCountries(row) {
    if (this.selection.selected.length >= 1) {
      const dialog = this.matDialogService.open(
        ConfirmationPromptModalComponent
      );
      dialog.componentInstance.messageKey = 'NOTIFICATION.SAVE-ENTRIES';
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.saveValuesForSelectedRows(row);
        }
      });
    }
  }

  saveValuesForSelectedRows(row) {
    const updatedFormGroup = this.getFormGroup(row);
    this.markFormGroupAsEdited(updatedFormGroup, true);

    this.selection.selected.forEach((item, index, array) => {
      const otherFormGroup = this.getFormGroup(item);
      if (
        updatedFormGroup.value.countryCode !== otherFormGroup.value.countryCode
      ) {
        this.matchFormsGroups(updatedFormGroup, otherFormGroup);
        this.markFormGroupAsEdited(otherFormGroup, true);
      }
    });

    this.saveEditedValues();
  }

  saveEditedValues() {
    const request = [];
    this.countriesObjects.controls.forEach((element: any) => {
      if (element.get('edited').value === true) {
        // for the time being let's have this code commented just in case this change
        // would not match with business requirement.
        // if (
        //   this.kcService.isUserInRole(
        //     "ngral.dc.u"
        //   )
        // ) {
        //   if (element.get("decisionCompliance").value == 1) {
        //     element.get("decisionRisk").patchValue(true);
        //   } else {
        //     element.get("decisionRisk").patchValue(false);
        //   }
        // }

        request.push(element.getRawValue());
      }
    });

    this.tciService
      .editNonGermanyReferenceCountries(request)
      .subscribe((result) => {
        this.reloadData();
        this.notificationService.showToast(
          'NOTIFICATION.CREATED-DIRECT',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'NON-GERMANY-REFERENCE-ALLOW-LIST.ENTITY'
            ),
          }
        );

        this.sharedDataService.reloadHistory();
      });
  }

  async reloadData() {
    this.countriesObjects.controls.forEach((element) => {
      if (element.get('edited').value === true) {
        element.get('edited').patchValue(false);
      }
    });

    await this.enableAllRows();
    this.selection = new SelectionModel(true, []);
    this.dataSource.data = Object.assign([], this.dataList);
    this.dataSource.filter = '';
    this.saveMultiple = false;
    this.getApprovedValues();
  }

  async enableAllRows() {
    this.countriesObjects.controls.forEach((item, index, array) => {
      item.enable();

      if (!this.kcService.isUserInRole('ngral.dc.u')) {
        item.get('decisionCompliance').disable();
        item.get('decisionComplianceReason').disable();
      }
      if (!this.kcService.isUserInRole('ngral-dc.u')) {
        item.get('decisionRisk').disable();
        item.get('decisionRiskReason').disable();
      }
    });
  }

  matchFormsGroups(updatedFormGroup, otherFormGroup) {
    otherFormGroup
      .get('decisionCompliance')
      .patchValue(updatedFormGroup.get('decisionCompliance').value);
    otherFormGroup
      .get('decisionComplianceReason')
      .patchValue(updatedFormGroup.get('decisionComplianceReason').value);
    otherFormGroup
      .get('decisionRisk')
      .patchValue(updatedFormGroup.get('decisionRisk').value);
    otherFormGroup
      .get('decisionRiskReason')
      .patchValue(updatedFormGroup.get('decisionRiskReason').value);
  }

  markFormGroupAsEdited(formGroup, value) {
    formGroup.get('edited').patchValue(value);
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  getFormValidity(row) {
    const formGroup: UntypedFormGroup = this.getFormGroup(row);
    if (formGroup) {
      if (
        this.kcService.isUserInRole('ngral.dr.u') ||
        this.kcService.isUserInRole('ngral.dc.u')
      ) {
        return formGroup.valid;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkRole(role) {
    if (this.kcService.getUserRoles().includes(role)) {
      return false;
    } else {
      return true;
    }
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                nonGermanyReferenceAllowCountriesColumnsConf,
                nonGermanyReferenceAllowCountriesColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = nonGermanyReferenceAllowCountriesColumnsConf;
    this.displayedColumns = nonGermanyReferenceAllowCountriesColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
