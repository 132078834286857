import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class TablesService {
  private sharedSelectedRows = new BehaviorSubject<any>(null);
  currentSharedSelectedRows = this.sharedSelectedRows.asObservable();

  private sharedSelectedExpandedRows = new BehaviorSubject<any>(null);
  currentSharedSelectedExpandedRows =
    this.sharedSelectedExpandedRows.asObservable();

  private sharedForm = new BehaviorSubject<any>(null);
  currentSharedForm = this.sharedForm.asObservable();

  private sharedEntitiesLoaded = new BehaviorSubject<boolean>(false);
  currentSharedEntitiesLoaded = this.sharedEntitiesLoaded.asObservable();

  private sharedDataSource = new BehaviorSubject<any>(null);
  currentSharedDataSource = this.sharedDataSource.asObservable();

  private sharedSpinnerState = new BehaviorSubject<any>(null);
  currentSharedSpinnerState = this.sharedSpinnerState.asObservable();

  private sharedScrollSpinnerState = new BehaviorSubject<boolean>(true);
  currentSharedScrollSpinnerState = this.sharedSpinnerState.asObservable();

  private displayedColumns = new BehaviorSubject<string[]>([]);
  private displayedColumnsUpdated = this.displayedColumns.asObservable();

  eventEmiterFunction = new EventEmitter();
  suscriptionEmitter: Subscription;

  changesInFormFunction = new EventEmitter();
  suscriptionChangesInFormEmitter: Subscription;

  infiniteScrollFormFunction = new EventEmitter();
  suscriptionInfiniteScrollEmitter: Subscription;

  clearTableFunction = new EventEmitter();
  suscriptionClearTableEmitter: Subscription;

  insideSearchFunction = new EventEmitter();
  suscriptionInsideSearchEmitter: Subscription;

  patchSelectedRowsFunction = new EventEmitter();
  suscriptionPatchSelectedRowsEmitter: Subscription;

  cancelEditModeFunction = new EventEmitter();
  subscriptionCancelEditModeFunctionEmitter: Subscription;

  customEvent = new EventEmitter();
  customEventSubscriptionEmitter: Subscription;

  resetOriginalDataFunction = new EventEmitter();
  subscriptionResetOriginalDataEmitter: Subscription;

  updateScrollSpinnerState(sharedScrollSpinnerState: any) {
    this.sharedScrollSpinnerState.next(sharedScrollSpinnerState);
  }

  updateSharedSpinnerState(sharedSpinnerState: any) {
    this.sharedSpinnerState.next(sharedSpinnerState);
  }

  updateSharedDataSource(sharedDataSource: any) {
    this.sharedDataSource.next(sharedDataSource);
  }

  updateSharedSelectedRows(sharedSelectedRows: any) {
    this.sharedSelectedRows.next(sharedSelectedRows);
  }

  updateSharedSelectedExpandedRows(sharedSelectedExpandedRows: any) {
    this.sharedSelectedExpandedRows.next(sharedSelectedExpandedRows);
  }

  updateFormState(sharedForm: any) {
    this.sharedForm.next(sharedForm);
  }

  updateEntitiesLoadedState(sharedEntitiesLoaded: any) {
    this.sharedEntitiesLoaded.next(sharedEntitiesLoaded);
  }

  changeEditMode(data) {
    this.cancelEditModeFunction.emit(data);
  }

  patchSelectedRows(data) {
    this.patchSelectedRowsFunction.emit(data);
  }

  openInsideSearch(data) {
    this.insideSearchFunction.emit(data);
  }

  addEmptyLinesToTable(lineNumber) {
    this.eventEmiterFunction.emit(lineNumber);
  }

  changesInForm(data) {
    this.changesInFormFunction.emit(data);
  }

  changesInfiniteScroll(data) {
    this.infiniteScrollFormFunction.emit(data);
  }

  clearTable() {
    this.clearTableFunction.emit();
  }

  triggerCustomEvent(element: any) {
    this.customEvent.emit(element);
  }

  resetOriginalData(data) {
    this.resetOriginalDataFunction.emit(data);
  }

  getDisplayedColumns(): Observable<string[]> {
    return this.displayedColumnsUpdated;
  }

  setDisplayedColumns(columns: string[]) {
    this.displayedColumns.next(columns);
  }
}
