import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.less'],
})
export class DashboardCalendarComponent {
  selectedDate: Date = new Date();

  onSelect($event) {
    console.log($event);
  }
}
