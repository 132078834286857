import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { AzureFile } from '../../models/azure-file';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private serviceUrl;
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.fileService;
  }

  eventEmiterFunction = new EventEmitter();
  suscriptionEmitter: Subscription;

  emitFunction() {
    this.eventEmiterFunction.emit();
  }

  uploadFile(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload',
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  uploadFileBatch(formData: FormData, fileType: string, id: any) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl +
        'files/upload-batch?' +
        'fileType=' +
        fileType +
        '&id=' +
        id,
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  uploadFileBatchForMultipleIds(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload-batch-multiple-ids',
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  listAllFiles(fileType: string, id: any) {
    return this.http.get(
      this.serviceUrl + 'files/list' + '?fileType=' + fileType + '&id=' + id,
      this.buildHeaders()
    );
  }

  listAllFilesForMultipleIds(fileType: string, transactionIds: any[]) {
    return this.http.get(this.serviceUrl + 'files/list-multiple-ids', {
      params: {
        fileType,
        ids: transactionIds,
      },
      headers: this.buildHeaders().headers,
    });
  }

  getFile(fileType: string, fileName: string, id: number) {
    return this.http.get(
      this.serviceUrl +
        'files/getFile' +
        '?fileType=' +
        fileType +
        '&fileName=' +
        fileName +
        '&id=' +
        id,
      {
        responseType: 'blob',
      }
    );
  }

  deleteFile(file: AzureFile) {
    return this.http.post(
      this.serviceUrl + 'files/delete',
      file,
      this.buildHeaders()
    );
  }

  deleteFileForMultipleIds(file: AzureFile, transactionIds) {
    return this.http.post(this.serviceUrl + 'files/delete-multiple-ids', file, {
      params: {
        ids: transactionIds,
      },
      headers: this.buildHeaders().headers,
    });
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
