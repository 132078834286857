import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../../utils/can.authentication.guard';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ReportComponent } from '../reporting/report/report.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardManagementComponent,
    children: [
      {
        path: 'dashboardCenter',
        component: DashboardComponent,
        canActivate: [CanAuthenticationGuard],
        data: { roles: ['m.r'] },
      },
      {
        path: 'messageCenter',
        component: MessageListComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['m.r'],
        },
      },
      {
        path: 'reports',
        component: ReportComponent,
        canActivate: [CanAuthenticationGuard],
        data: {
          roles: ['pbi.r.r'],
        },
      },
      { path: '', redirectTo: 'dashboardCenter', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardManagementRoutingModule {
  static e = '';
}
