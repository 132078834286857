export const contactTypeEnum: any[] = [
  {
    name: 'MANAGING_DIRECTOR',
    translateName: 'COMPANY.CONTACT-PERSON.MANAGEING-DIRECTOR',
  },
  {
    name: 'HEAD_FINANCE',
    translateName: 'COMPANY.CONTACT-PERSON.HEAD-FINANCE',
  },
  {
    name: 'COMMERCIAL_MANAGER',
    translateName: 'COMPANY.CONTACT-PERSON.COMMERCIAL-MANAGER',
  },
  {
    name: 'TAX_CONSULTANT',
    translateName: 'COMPANY.CONTACT-PERSON.TAX-CONSULTANT',
  },
  {
    name: 'HEAD_ACCOUNTING',
    translateName: 'COMPANY.CONTACT-PERSON.HEAD-ACCOUNTING',
  },
  { name: 'ACCOUNTING', translateName: 'COMPANY.CONTACT-PERSON.ACCOUNTING' },
  {
    name: 'MANAGEMENT_ASSISTANT',
    translateName: 'COMPANY.CONTACT-PERSON.MANAGEMENT-ASSISTANT',
  },
  {
    name: 'MANAGEMENT_SECRETARIAT',
    translateName: 'COMPANY.CONTACT-PERSON.MANAGEMENT-SECRETARIAT',
  },
  {
    name: 'INSIDE_SALES',
    translateName: 'COMPANY.CONTACT-PERSON.INSIDE-SALES',
  },
  { name: 'SALES', translateName: 'COMPANY.CONTACT-PERSON.SALES' },
  { name: 'IT', translateName: 'COMPANY.CONTACT-PERSON.IT' },
  {
    name: 'FINANCIAL_BROKER',
    translateName: 'COMPANY.CONTACT-PERSON.FINANCIAL-BROKER',
  },
  {
    name: 'EXTERNAL_SERVICE_PROVIDER',
    translateName: 'COMPANY.CONTACT-PERSON.EXTERNAL-SERVICE-PROVIDER',
  },
  { name: 'LAWYER', translateName: 'COMPANY.CONTACT-PERSON.LAWYER' },
];
