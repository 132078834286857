<div class="filters" [formGroup]="filter">
  <div class="filter-input">
    <mat-form-field class="white-back">
      <mat-label>{{ 'BU.SEARCH' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'bu.search' | translate }}"
        name="name"
        formControlName="searchString"
        id="{{ entityName }}-search-input-name"
        (keyup)="searchBU()"
        [(ngModel)]="searchInput"
        autocomplete="off"
        type="text"
        [removeHtmlTags]="filter.controls.searchString"
      />
    </mat-form-field>
    <button mat-button class="custom-button search-button" (click)="searchBU()" id="{{ entityName }}-search-button">
      <span class="material-icons"> search </span>
    </button>
  </div>
  <div class="filter-input button-container">
    <button
      *hasPermission="'bu.c'"
      mat-button
      class="custom-button"
      (click)="createNew()"
      id="{{ entityName }}-create-button"
    >
      {{ 'BU.CREATE' | translate }}
    </button>
  </div>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<div class="task-list">
  <h5 id="bu-search-results-title">{{ 'SEARCH-RESULT' | translate }}</h5>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    id="{{ entityName }}-search-results-table"
    class="general-table-alignment"
    aria-hidden="true"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th number-row-header-sortable id-column"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
      >
        ID
        <app-table-menu-sort-filter
          choice="id"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        >
        </app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        class="number-row id-column"
      >
        {{ element.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
      >
        {{ 'BU.BU-NAME' | translate }}
        <app-table-menu-sort-filter
          choice="name"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        >
        </app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="mainBooking">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
      >
        {{ 'BU.MAIN-BOOKING-RELEVANT' | translate }}
        <app-table-menu-sort-filter
          choice="ledger_relevant"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <span>
          <mat-slide-toggle [checked]="element.ledgerRelevant" [disabled]="true"> </mat-slide-toggle>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="camBooking">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
      >
        {{ 'BU.CAM-RELEVANT' | translate }}
        <app-table-menu-sort-filter
          choice="cam_relevant"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <span>
          <mat-slide-toggle [checked]="element.camRelevant" [disabled]="true"> </mat-slide-toggle>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="product">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[4] }}"
      >
        {{ 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.PRODUCT-ALLOCATION' | translate }}
        <app-table-menu-sort-filter
          choice="product"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ findProductName(element.product) | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="poolAllocation">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[5] }}"
      >
        {{ 'BUSINESS-UNIT.POOL-ALLOCATION.POOL-ALLOCATION' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[5] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ findPoolName(element.poolAllocationId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="editingOfExternalRecords">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[7] }}"
      >
        {{ 'BUSINESS-UNIT.CREATE.EDITING-OF-EXTERNAL-RECORDS' | translate }}
        <app-table-menu-sort-filter
          choice="editing_of_external_records"
          [dataSource]="dataSource"
          [originalDataSource]="originalDataSource"
          [pageIndex]="paginator.pageIndex"
          [pageSize]="pageSize"
          [sortDirection]="sortDirection"
          [callback]="filterData"
          [getDistinctValues]="getFieldNames"
          [sortTable]="sortTable"
        ></app-table-menu-sort-filter>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[7] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <span>
          <mat-slide-toggle [checked]="element.editingOfExternalRecords" [disabled]="true"> </mat-slide-toggle>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="manualBookingApproval">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-{{ displayedColumns[8] }}"
      >
        {{ 'BUSINESS-UNIT.CREATE.MANUAL-BOOKING-APPROVAL' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-{{ displayedColumns[8] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        <span>
          <mat-slide-toggle [checked]="element.manualBookingApproval" [disabled]="true"> </mat-slide-toggle>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="loader">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="general-loader-table" *ngIf="loaderSpinner">
          <mat-spinner [diameter]="50"></mat-spinner>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="v25-th settings"
        id="{{ entityName }}-table-results-{{ displayedColumns[9] }}"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="appMenu"
          id="{{ entityName }}-table-results-{{ displayedColumns[9] }}-button"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          *ngIf="element.draft"
          class="menu"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ element: element }"
          (click)="$event.stopPropagation()"
          id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span class="material-icons"> more_vert </span>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [hidden]="loaderSpinner"
      class="element-row"
      (click)="clickOnBusinessUnit(row)"
      [ngClass]="{ 'draft-row': row.draft }"
    ></tr>
    <tr
      [hidden]="!loaderSpinner"
      mat-footer-row
      *matFooterRowDef="['loader']"
      class="footer-spinner height-footer"
    ></tr>
  </table>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
</div>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <button
      *hasPermission="'bu.u'"
      mat-menu-item
      (click)="clickOnBusinessUnit(element)"
      id="{{ entityName }}-table-results-action-menu-edit-draft-button"
    >
      <img
        class="icon-menu"
        src="assets/images/ic_edit.svg"
        alt="{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}"
      />
      <span translate>GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT</span>
    </button>
    <button
      mat-menu-item
      (click)="deleteDraft(element.draftId)"
      id="{{ entityName }}-table-results-action-menu-delete-draft-button"
    >
      <span class="material-icons icon-menu"> delete_outline </span>
      <span translate>GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT</span>
    </button>
  </ng-template>
</mat-menu>
