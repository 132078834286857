export const quantityGoodsCreateColumns: string[] = [
  'select',
  'vvvoNr',
  'productionType',
  'submissionDate',
  'measureUnit',
  'amount',
  'transfer',
  'comment',
  'actions',
];
