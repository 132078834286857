<div *ngIf="(editableTask && isTask) || !isTask">
  <div class="form-row" [formGroup]="formGroup">
    <mat-form-field
      id="{{ entityName }}-form-{{ controlName }}"
      appearance="{{ appearance ? appearance : 'fill' }}"
      [ngClass]="{ 'val-changed': changedValue }"
    >
      <mat-label>{{ label | translate }}</mat-label>
      <mat-select
        #select
        formControlName="{{ controlName }}"
        placeholder=" {{ placeholder | translate }}"
        multiple
        [required]="requiredValidator"
        id="{{ entityName }}-form-{{ controlName }}-select-multiple"
        [attr.disabled]="disabled"
        (openedChange)="openChange($event)"
      >
        <span *ngIf="selectAll">
          <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">{{
            'GENERAL.ALL' | translate
          }}</mat-option>
        </span>
        <cdk-virtual-scroll-viewport [itemSize]="48" [style.height.px]="5 * 48">
          <mat-option
            (onSelectionChange)="change($event)"
            (click)="selectAll ? togglePerOne(allSelected.viewValue) : null"
            *cdkVirtualFor="let item of options | sortBy: { property: translationOption, descending: false }"
            [value]="getItemValue(item)"
            id="{{ entityName }}-form-{{ controlName }}-selectOption-{{ getItemValue(item) }}"
          >
            <span *ngIf="optionTranslationText != '' && !titleCaseEnabled">{{
              optionTranslationText + '.' + getItem(item) | translate
            }}</span>
            <span *ngIf="optionTranslationText == ''">{{ getItem(item) | translate }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-select>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        id="{{ entityName }}-form-{{ controlName }}-clear"
        (click)="clearOptions(); $event.stopPropagation()"
        *ngIf="showRemoveOption()"
        [disabled]="disabled"
      >
        <mat-icon>close</mat-icon>
      </button>

      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
        *ngIf="controlObject.hasError('required')"
      >
        {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: select.placeholder | translate } }}
      </mat-error>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
        *ngIf="controlObject.hasError('incorrect')"
      >
        {{ 'ERROR-MESSAGES.INVALID' | translate: { name: select.placeholder | translate } }}
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!editableTask && isTask && !taskShowMultipleAttributes" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
        >{{ taskValue }}</span
      >
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div *ngFor="let item of taskValue; index as i">
      <div class="object-row">
        <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
          <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
            <span class="object-label" *ngIf="!selectAll">{{ label | translate }} {{ i + 1 }}:</span>
            <span class="object-label" *ngIf="selectAll">{{ label | translate }}:</span>
          </div>
          <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
            <span
              *ngIf="taskOptionsValue && !selectAll"
              class="object-value"
              [ngClass]="{
                'val-changed': approvedValue && approvedValue != taskValue
              }"
              >{{ getTaskOptionValue(item) | translate }}
            </span>
            <span
              *ngIf="taskOptionsValue && selectAll"
              class="object-value"
              [ngClass]="{
                'val-changed': approvedValue && approvedValue != taskValue
              }"
              >{{ getItem(item) | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
