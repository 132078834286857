<div *ngIf="taskView">
  <app-back-button></app-back-button>
  <span>
    <button
      *hasPermission="'aw.sl.u'"
      id="{{ entityName }}-edit-task"
      mat-button
      class="edit-task-button"
      (click)="makeEditable()"
    >
      <img src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
    </button>
  </span>
</div>
<div class="input-wrapper form-container">
  <form class="form" [formGroup]="subLedgerAccountForm" autocomplete="off">
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NUMBER'"
      [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NUMBER'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NUMBER'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlObject]="subLedgerAccountForm.controls.accountNumber"
      [controlName]="'accountNumber'"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.accountNumber : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.accountNumber : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
    ></app-mat-input>
    <app-mat-select
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE'"
      [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlObject]="subLedgerAccountForm.controls.type"
      [controlName]="'type'"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.type : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.type : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="accountTypes"
      [optionValue]="'value'"
      [selectedOption]="null"
      [translationOption]="'translateName'"
      [optionTranslationText]="''"
    ></app-mat-select>
    <app-mat-radio
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SAP-SUB-LEDGER-ACCOUNT'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SAP-SUB-LEDGER-ACCOUNT'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlName]="'sapSubLedgerAccount'"
      [controlObject]="subLedgerAccountForm.controls.sapSubLedgerAccount"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.sapSubLedgerAccount : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.sapSubLedgerAccount : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [items]="sapSubLedgerAccountOptions"
      [optionValue]="'value'"
      [titlecasePipe]="false"
    ></app-mat-radio>
    <app-mat-radio
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SALES-TAX-ACCOUNT'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SALES-TAX-ACCOUNT'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlName]="'salesTaxAccount'"
      [controlObject]="subLedgerAccountForm.controls.salesTaxAccount"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.salesTaxAccount : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.salesTaxAccount : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [items]="salesTaxAccountsOptions"
      [optionValue]="'value'"
      [titlecasePipe]="false"
    ></app-mat-radio>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-GERMAN'"
      [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-GERMAN'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-GERMAN'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlObject]="subLedgerAccountForm.controls.accountNameGerman"
      [controlName]="'accountNameGerman'"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.accountNameGerman : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.accountNameGerman : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
    ></app-mat-input>
    <app-mat-input
      [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-ENGLISH'"
      [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-ENGLISH'"
      [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-NAME-ENGLISH'"
      [entityName]="entityName"
      [form]="subLedgerAccountForm"
      [controlObject]="subLedgerAccountForm.controls.accountNameEnglish"
      [controlName]="'accountNameEnglish'"
      [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.accountNameEnglish : null"
      [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.accountNameEnglish : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [titlecasePipe]="false"
      [textTransform]="'none'"
    ></app-mat-input>
    <div [hidden]="hideProductAndServiceModel">
      <app-mat-select-multiple
        [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCT'"
        [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCT'"
        [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.PRODUCT'"
        [entityName]="entityName"
        [form]="subLedgerAccountForm"
        [controlObject]="subLedgerAccountForm.controls.product"
        [controlName]="'product'"
        [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.product : null"
        [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.product : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
        [options]="products"
        [optionValue]="'name'"
        [taskOptionsValue]="'name'"
        [selectedOption]="''"
        [translationOption]="'translateName'"
        [optionTranslationText]="''"
        [selectAll]="true"
      ></app-mat-select-multiple>
      <div [hidden]="hideServiceModel">
        <app-mat-select-multiple
          [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODEL'"
          [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODEL'"
          [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.SERVICE-MODEL'"
          [entityName]="entityName"
          [form]="subLedgerAccountForm"
          [controlObject]="subLedgerAccountForm.controls.serviceModel"
          [controlName]="'serviceModel'"
          [approvedValue]="subLedgerAccountApproved ? subLedgerAccountApproved.serviceModel : null"
          [taskValue]="subLedgerAccountTask ? subLedgerAccountTask.serviceModel : null"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [formGroupCustomName]="null"
          [options]="filteredServiceModels"
          [optionValue]="'name'"
          [taskOptionsValue]="'name'"
          [selectedOption]="''"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [selectAll]="true"
        >
        </app-mat-select-multiple>
      </div>
    </div>
    <div [hidden]="!isTask">
      <app-mat-input
        [name]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.STATUS'"
        [placeholder]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.STATUS'"
        [label]="'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.STATUS'"
        [entityName]="entityName"
        [form]="subLedgerAccountForm"
        [controlObject]="subLedgerAccountForm.controls.isActive"
        [controlName]="'isActive'"
        [approvedValue]="subLedgerAccountApproved ? getStatus(subLedgerAccountApproved.isActive) : null"
        [taskValue]="subLedgerAccountTask ? getStatus(subLedgerAccountTask.isActive) : null"
        [titleTask]="false"
        [editableTask]="editableTask"
        [isTask]="isTask"
        [formGroupCustomName]="null"
        [titlecasePipe]="false"
        [textTransform]="'none'"
      ></app-mat-input>
    </div>
  </form>
</div>

<div class="filter-input button-container" [hidden]="isTask">
  <ng-container>
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" (click)="changeEditMode()">
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      [disabled]="!subLedgerAccountForm.valid"
      id="{{ entityName }}-create-button"
      (click)="save()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
      </span>
    </button>
    <button
      *ngIf="!taskView && !activateUpdateDraft"
      mat-button
      class="custom-button"
      [disabled]="!subLedgerAccountForm.valid"
      id="{{ entityName }}-create-button"
      (click)="saveAsDraft()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
      </span>
    </button>
    <button
      *ngIf="activateUpdateDraft"
      mat-button
      class="custom-button"
      [disabled]="!subLedgerAccountForm.valid"
      id="{{ entityName }}-update-button"
      (click)="saveAsDraft()"
    >
      <span>
        {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
      </span>
    </button>
  </ng-container>
</div>
