import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of, Subscription } from 'rxjs';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input('hasPermission')
  checkPermissions: string | string[];
  private subscription: Subscription;
  constructor(
    private templateRef: TemplateRef<any>,
    private kcService: KeycloakService,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.subscription = this.hasPermissions(this.checkPermissions).subscribe(
      (hasPermission) => {
        if (hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }

  private hasPermissions(permissions: string | string[]): Observable<boolean> {
    let hasPermission = false;

    if (permissions) {
      if (permissions instanceof Array) {
        permissions.some((checkPermission) => {
          hasPermission = this.kcService
            .getUserRoles()
            .includes(checkPermission);
          return hasPermission;
        });
      } else {
        hasPermission = this.kcService.getUserRoles().includes(permissions);
      }
    } else {
      hasPermission = true;
    }

    return of(hasPermission);
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
