import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debitOrCreditOptions } from 'src/app/in-memory-data/animal-welfare/open-items/enum/debit-or-credit';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { BillingSlaughterhouseService } from 'src/app/shared/services/billing-slaughterhouse/billing-slaughterhouse.service';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';
import {
  DateValidator,
  futureDateValidator,
  pastDateValidator,
} from 'src/app/shared/validators/date-validator';
import { GeneralValidator } from 'src/app/shared/validators/general-validator';
import { v4 as uuidv4 } from 'uuid';

const debitOrCreditStandard = 3;

@Component({
  selector: 'app-aw-open-item-create',
  templateUrl: './aw-open-item-create.component.html',
  styleUrls: ['./aw-open-item-create.component.less'],
})
export class AwOpenItemCreateComponent implements OnInit, DirtyComponent {
  awOpenItemForm: UntypedFormGroup;
  entityName = 'aw-open-item';

  debitOrCreditOptions = debitOrCreditOptions;
  currencyCodes: any;
  existentBookingCodes: any[] = [];

  slaughterhouseIdOptions: any;
  bookingCodeExists = true;

  operator: any;

  approval = false;

  NOT_USED = 1;
  USED = 2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private billingSlaughterhouseService: BillingSlaughterhouseService,
    private dateValidator: DateValidator,
    private generalValidator: GeneralValidator,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private bookingCodeService: BookingCodeService,
    private liquidityPlanService: LiquidityPlanService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    private businessUnitService: BusinessUnitService
  ) {
    this.awOpenItemFormBuilder();
  }

  ngOnInit(): void {
    const cc = require('currency-codes');
    this.currencyCodes = cc.codes();
    this.getAllSlaughterhouses();
    this.getOperator();
    this.getAllBookingCodes();
    this.checkChanges();
  }

  canDeactivate() {
    return this.awOpenItemForm.dirty && !this.approval;
  }

  awOpenItemFormBuilder() {
    this.awOpenItemForm = this.formBuilder.group(
      {
        id: [''],
        businessUnitId: [
          localStorage.currentBusinessUnitId,
          [Validators.required],
        ],
        entityFromGuid: ['', [Validators.required]],
        entityToGuid: [
          { value: uuidv4(), disabled: true },
          [Validators.required],
        ],
        openItemDate: ['', [Validators.required, pastDateValidator()]],
        bookingCode: ['', [Validators.required]],
        debitOrCredit: [{ value: '', disabled: false }, [Validators.required]],
        amount: [0, [Validators.required]],
        currency: [{ value: 'EUR', disabled: true }, [Validators.required]],
        vatAmount: ['', [Validators.required]],
        vatPercent: ['', [Validators.required]],
        dueDate: ['', [Validators.required, futureDateValidator()]],
        debtCollection: ['1', [Validators.required]],
      },
      {
        validators: [
          this.dateValidator.startEndValidator('openItemDate', 'dueDate'),
          this.generalValidator.upperLimitValidation('amount', 'vatAmount'),
        ],
      }
    );
  }

  getAllBookingCodes() {
    this.bookingCodeService.listAll().subscribe((data) => {
      this.existentBookingCodes = data;
    });
  }

  checkChanges() {
    this.awOpenItemForm.get('bookingCode').valueChanges.subscribe((data) => {
      this.checkBookingCode(data);
    });
  }

  checkBookingCode(value) {
    if (value && this.existentBookingCodes) {
      const validBookingCode = this.existentBookingCodes.find(
        (item) => item.id === value
      );
      if (validBookingCode) {
        this.bookingCodeExists = true;
        this.enableDisableCreditDebitInputAndFillDependOn(
          validBookingCode.cancellation,
          validBookingCode.standard
        );
      } else {
        this.bookingCodeExists = false;
        this.resetCreditDebit();
      }
    } else {
      this.bookingCodeExists = false;
      this.resetCreditDebit();
    }
  }

  private enableDisableCreditDebitInputAndFillDependOn(
    cancellation: boolean,
    bookingCodeStandard: number
  ) {
    if (!cancellation && bookingCodeStandard !== debitOrCreditStandard) {
      const debitOrCreditControl = this.awOpenItemForm.get('debitOrCredit');
      debitOrCreditControl.disable();
      debitOrCreditControl.setValue(bookingCodeStandard.toString());
    } else {
      this.resetCreditDebit();
    }
  }

  private resetCreditDebit() {
    const debitOrCreditControl = this.awOpenItemForm.get('debitOrCredit');
    debitOrCreditControl.enable();
    debitOrCreditControl.setValue('');
  }

  getAllSlaughterhouses() {
    this.billingSlaughterhouseService
      .findAllSlaughterhouse('SCHLACHTBETRIEB')
      .subscribe((data) => {
        this.slaughterhouseIdOptions = data;
      });
  }

  getOperator() {
    this.liquidityPlanService.getOperator().subscribe((data) => {
      this.operator = data;
      this.awOpenItemForm.get('entityToGuid').patchValue(data.id);
    });
  }

  onClickApproval() {
    const openItems: any[] = [];
    openItems.push(this.awOpenItemForm.getRawValue());

    const request = {
      businessUnitId: this.awOpenItemForm.get('businessUnitId').value,
      clientId: null,
      openItems,
    };

    this.validateOpenItemTranactionCodeAllocationAndCrerateOpenItem(request);
  }

  createOpenItem(request) {
    this.billingSlaughterhouseService.createOpenItem(request).subscribe(
      (data) => {
        this.approval = true;
        this.notificationService.showToast(
          'NOTIFICATION.CREATED-DIRECT',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.OPEN-ITEM'
            ),
          }
        );

        setTimeout(() => {
          this.router.navigateByUrl('/billingSlaughterhouse', {
            state: { success: true },
          });
        }, 1500);
      },
      (error) => {
        this.notificationService.showToast(
          'ERROR-MESSAGES.ERROR-BACKEND',
          this.notificationService.MESSAGE_TYPE.ERROR,
          {
            name: error.error.errorId ? error.error.errorId : 'unknown',
            error: error.name,
          }
        );
      }
    );
  }

  validateOpenItemTranactionCodeAllocationAndCrerateOpenItem(request) {
    request.openItems.forEach((element) => {
      this.businessUnitService
        .getCurrentApproved(request.businessUnitId)
        .subscribe(
          (data) => {
            this.transactionCodeAllocationService
              .retrieveTransactionCodeAllocation(data.guid, element.bookingCode)
              .subscribe(
                (transactionCodeAllocation) => {
                  if (
                    transactionCodeAllocation.status === this.NOT_USED ||
                    transactionCodeAllocation.status === this.USED
                  ) {
                    this.createOpenItem(request);
                  } else {
                    this.notificationService.showToast(
                      'ERROR-MESSAGES.TCA-STATUS-NOT-VALID',
                      this.notificationService.MESSAGE_TYPE.ERROR
                    );
                  }
                },
                (error) => {
                  this.notificationService.showToast(
                    'ERROR-MESSAGES.TCA-NOT-FOUND',
                    this.notificationService.MESSAGE_TYPE.ERROR
                  );
                }
              );
          },
          (error) => {
            this.notificationService.showToast(
              'ERROR-MESSAGES.BU-NOT-FOUND',
              this.notificationService.MESSAGE_TYPE.ERROR
            );
          }
        );
    });
  }
}
