export const teamEnum: any[] = [
  {
    name: '',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.NONE',
  },
  {
    name: 'SERVICE_PROVIDING',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.SERVICE-PROVIDING',
  },
  {
    name: 'COMPLIANCE',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.COMPLIANCE',
  },
  {
    name: 'CONTROLLING',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.CONTROLLING',
  },
  {
    name: 'DEBTOR_SERVICE',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.DEBTOR-SERVICE',
  },
  {
    name: 'FACTORING',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.FACTORING',
  },
  { name: 'ITC', translateName: 'USER-MANAGEMENT.TEAM-VALUES.ITC' },
  {
    name: 'RISK',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.RISK',
  },
  {
    name: 'ANIMAL_WELFARE',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.ANIMAL-WELFARE',
  },
  {
    name: 'CS',
    translateName: 'USER-MANAGEMENT.TEAM-VALUES.CS',
  },
  { name: 'EXTERNAL', translateName: 'USER-MANAGEMENT.TEAM-VALUES.EXTERNAL' },
];
