import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  OF_LABEL = 'of';

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate
      .get([
        'PAGINATION.ITEMS_PER_PAGE',
        'PAGINATION.NEXT_PAGE',
        'PAGINATION.PREVIOUS_PAGE',
        'PAGINATION.OF_LABEL',
        'PAGINATION.LAST_PAGE',
        'PAGINATION.FIRST_PAGE',
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['PAGINATION.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['PAGINATION.NEXT_PAGE'];
        this.previousPageLabel = translation['PAGINATION.PREVIOUS_PAGE'];
        this.OF_LABEL = translation['PAGINATION.OF_LABEL'];
        this.lastPageLabel = translation['PAGINATION.LAST_PAGE'];
        this.firstPageLabel = translation['PAGINATION.FIRST_PAGE'];
        this.lastPageLabel = translation['PAGINATION.LAST_PAGE'];
        this.changes.next(undefined);
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  };
}
