export const documentCategories: any[] = [
  {
    code: 0,
    name: 'STRUCTURAL ANALYSIS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.STRUCTURAL-ANALYSIS',
  },
  {
    code: 1,
    name: 'ASSIGNMENT OF DISBURSEMENT CLAIMS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.ASSIGNMENT-DISBURSEMENT-CLAIMS',
  },
  {
    code: 2,
    name: 'AUTHORIZATION ONLINE SERVICES',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.AUTHORIZATION-ONLINE-SERVICES',
  },
  {
    code: 3,
    name: 'AUTHORIZATION INTEGRATION CAM WEBSERVICES/INTERFACE',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.AUTHORIZATION-INTEGRATION-CAM',
  },
  {
    code: 4,
    name: 'ANNUAL FINANCIAL STATEMENTS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.ANNUAL-FINANCIAL-STATEMENTS',
  },
  {
    code: 5,
    name: 'BUSINESS MANAGEMENT EVALUATION',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.BUSINESS-MANAGEMENT-EVALUATION',
  },
  {
    code: 6,
    name: 'CREDITORS OPEN ITEM LIST',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.CREDITORS-OPEN-ITEM-LIST',
  },
  {
    code: 7,
    name: 'DEBTORS OPEN ITEM LIST',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.DEBTORS-OPEN-ITEM-LIST',
  },
  {
    code: 8,
    name: 'SALES LIST OF THE 10-20 LARGEST DEBTORS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.SALES-LIST-OF-THE-10-20-LARGEST-DEBTORS',
  },
  {
    code: 9,
    name: 'BUSINESS PLAN/LIQUIDITY PLAN',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.BUSINESS-PLAN/LIQUIDITY-PLAN',
  },
  {
    code: 10,
    name: 'BANK STATUS SALES',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.BANK-STATUS-SALES',
  },
  {
    code: 11,
    name: 'EXTRACT FROM COMMERCIAL REGISTER',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.EXTRACT-FROM-COMMERCIAL-REGISTER',
  },
  {
    code: 12,
    name: 'ARTICLES OF ASSOCIATION',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.ARTICLES-OF-ASSOCIATION',
  },
  {
    code: 13,
    name: 'SAMPLE OF TERMS AND CONDITIONS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.SAMPLE-OF-TERMS-AND-CONDITIONS',
  },
  {
    code: 14,
    name: 'SAMPLE INVOICE AND SAMPLE PROOF OF DELIVERY',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.SAMPLE-INVOICE-AND-SAMPLE-PROOF-OF-DELIVERY',
  },
  {
    code: 15,
    name: 'COPIES OF THE GENERAL TERMS AND CONDITIONS OF THE TOP 5 SUPPLIERS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.COPIES-OF-THE-GENERAL-TERMS-AND-CONDITIONS-OF-THE-TOP-5-SUPPLIERS',
  },
  {
    code: 16,
    name: 'FRAMEWORK AGREEMENTS CONCLUDED WITH DEBTORS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.FRAMEWORK-AGREEMENTS-CONCLUDED-WITH-DEBTORS',
  },
  {
    code: 17,
    name: 'TRADE CREDIT INSURANCE',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.TRADE-CREDIT-INSURANCE',
  },
  {
    code: 18,
    name: 'LIMIT LIST',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.LIMIT-LIST',
  },
  {
    code: 19,
    name: 'OTHERS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.OTHERS',
  },
  {
    code: 20,
    name: 'NEGATIVE PLEDGE FROM BANKS',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.NEGATIVE-PLEDGE-FROM-BANKS',
  },
  {
    code: 21,
    name: 'LETTER OF COMFORT JOINT LIABILITY',
    translateName:
      'CLIENT.DOCUMENTS.AVAILABLE-TEMPLATES-TABLE.CATEGORIES.LETTER-OF-COMFORT-JOINT-LIABILITY',
  },
];
