import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogData } from '../../models/dialog-data';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company/company.service';
import { clientInterfaceApprovalColumns } from 'src/app/in-memory-data/client/client-interface-approval/client-interface-columns';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-company-selection-modal',
  templateUrl: './company-selection-modal.component.html',
  styleUrls: ['./company-selection-modal.component.less'],
})
export class CompanySelectionModalComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = 20;
  filterForm: UntypedFormGroup;
  searchInput = '';
  companyList: Company[];
  totalCount = 0;
  dataSource = new MatTableDataSource<Company>();
  selection = new SelectionModel(false, []);
  displayedColumns = clientInterfaceApprovalColumns;
  loaderSpinner = true;
  data: any;
  entityName = 'companySelectionModal';
  subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService
  ) {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
    this.data = {
      company: null,
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.companyService.setSearchString('');
  }

  ngOnInit() {
    this.loadCompanyLists();
    this.subscription.add(
      this.filterForm.valueChanges.subscribe(
        (filters: { searchString: string }) => {
          this.companyService.setSearchString(filters.searchString);
          if (this.paginator) {
            this.paginator.firstPage();
          }
          this.loadCompanyLists();
        }
      )
    );
  }

  private loadCompanyLists() {
    let page = 1;
    if (this.paginator) {
      page = this.paginator.pageIndex + 1;
    }
    const pageSize = this.pageSize;
    this.companyService
      .getAllCompanies('id', 'asc', page, pageSize)
      .pipe(first())
      .subscribe((companies) => {
        this.totalCount = companies.total;
        this.companyList = this.dataSource.data = companies.content;
        this.loaderSpinner = false;
      });
  }

  checkboxLabel(row): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  selectCompany(row) {
    this.selection.toggle(row);
    this.data.company = row;
  }

  getAddressString(element) {
    return (
      element.name +
      ', ' +
      element.address.street +
      ', ' +
      element.address.number +
      ', ' +
      element.address.zipCode +
      ', ' +
      element.address.city
    );
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loadCompanyLists();
  }
}
