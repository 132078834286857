export const quantityLabeledGoodsCreateColumns: string[] = [
  'select',
  'suppliedTo',
  'suppliedFrom',
  'name',
  'goodsGroup',
  'type',
  'quantity',
  'comment',
  'actions',
];
