<div class="content-container">
  <app-back-button></app-back-button>

  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.FILENAME' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.FILENAME' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-filename"
          formControlName="searchString"
          [(ngModel)]="searchInput"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filter.controls.searchString"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-progress">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.PROGRESS' | translate }}</mat-label>
        <mat-select
          formControlName="progress"
          placeholder="{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.PROGRESS' | translate }}"
          name="progress"
          [(ngModel)]="searchProgress"
        >
          <mat-option *ngFor="let pv of progressValues" [value]="pv">
            {{ getProgressString(pv) | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchProgress"
          id="{{ entityName }}-search-input-progress-clear"
          (click)="searchProgress = undefined; resetProgressFilter(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-status">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.STATUS' | translate }}</mat-label>
        <mat-select
          formControlName="status"
          placeholder="{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.STATUS' | translate }}"
          name="status"
          [(ngModel)]="searchStatus"
        >
          <mat-option *ngFor="let sv of statusValues" [value]="sv">
            {{ getStatusString(sv) | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchStatus"
          id="{{ entityName }}-search-input-status-clear"
          (click)="searchStatus = undefined; resetStatusFilter(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{
          'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.CREATION-TIMESTAMP' | translate
        }}</mat-label>
        <mat-date-range-input
          [formGroup]="filter.controls.creationTimestamp"
          [rangePicker]="datePickerForCreationTimestamp"
          (click)="$event.stopPropagation()"
          id="{{ entityName }}-custom-filter-creation-timestamp-input-start-end"
        >
          <input
            matStartDate
            formControlName="begin"
            class="begin"
            id="{{ entityName }}-custom-filter-creation-timestamp-input-start"
            placeholder="{{ 'GENERAL.START-DATE' | translate }}"
            [formControl]="filter.controls.creationTimestamp.get('begin')"
          />
          <input
            matEndDate
            formControlName="end"
            id="{{ entityName }}-custom-filter-creation-timestamp-input-end"
            placeholder="{{ 'GENERAL.END-DATE' | translate }}"
            [formControl]="filter.controls.creationTimestamp.get('end')"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="datePickerForCreationTimestamp" class="date-toggle">
        </mat-datepicker-toggle>
        <mat-date-range-picker #datePickerForCreationTimestamp></mat-date-range-picker>
        <mat-icon class="close" (click)="clearCreationTimestampFilter()"> close </mat-icon>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.UPDATE-TIMESTAMP' | translate }}</mat-label>
        <mat-date-range-input
          [formGroup]="filter.controls.updateTimestamp"
          [rangePicker]="datePickerForUpdateTimestamp"
          (click)="$event.stopPropagation()"
          id="{{ entityName }}-custom-filter-update-timestamp-input-start-end"
        >
          <input
            matStartDate
            formControlName="begin"
            class="begin"
            id="{{ entityName }}-custom-filter-update-timestamp-input-start"
            placeholder="{{ 'GENERAL.START-DATE' | translate }}"
            [formControl]="filter.controls.updateTimestamp.get('begin')"
          />
          <input
            matEndDate
            formControlName="end"
            id="{{ entityName }}-custom-filter-update-timestamp-input-end"
            placeholder="{{ 'GENERAL.END-DATE' | translate }}"
            [formControl]="filter.controls.updateTimestamp.get('end')"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="datePickerForUpdateTimestamp" class="date-toggle">
        </mat-datepicker-toggle>
        <mat-date-range-picker #datePickerForUpdateTimestamp></mat-date-range-picker>
        <mat-icon class="close" (click)="clearUpdateTimestampFilter()"> close </mat-icon>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <button mat-button class="custom-button" (click)="searchProtocol()" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <div class="mt-50 table-container">
    <table mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-files-table" aria-hidden="true">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
          <mat-checkbox
            (change)="$event ? masterToggle(true) : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
            class="text-center"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
            [aria-label]="checkboxLabel(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="filename">
        <th
          id="{{ entityName }}-aw-itp-fileName"
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
        >
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.FILENAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-aw-itp-fileName-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.filename }}
        </td>
      </ng-container>
      <ng-container matColumnDef="progress">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-aw-itp-progress"
        >
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.PROGRESS' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-aw-itp-progress-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getProgressString(element.progress) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-aw-itp-status"
        >
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.STATUS' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-aw-itp-status-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getStatusString(element.status) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creationTimestamp">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-aw-itp-creationTimestamp"
        >
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.CREATION-TIMESTAMP' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-aw-itp-creationTimestamp-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.importDate | date: dateFormat  }}
        </td>
      </ng-container>
      <ng-container matColumnDef="updateTimestamp">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-aw-itp-updateTimestamp"
        >
          {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.UPDATE-TIMESTAMP' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-aw-itp-updateTimestamp-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.updateDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          (click)="selectRow(element); $event.stopPropagation()"
          class="three-dot-actions"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [ngClass]="{ highlight: selectedRowIndex == i }"
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [hidden]="loaderSpinner"
      ></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container>
      <button
        mat-menu-item
        (click)="openProtocolDetailsModal(element)"
        id="{{ entityName }}-table-results-action-menu-open-protocol-details-modal"
      >
        {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.OPEN-PROTOCOL-DETAILS' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button
        mat-menu-item
        (click)="openRequestResponseModal(true, element)"
        id="{{ entityName }}-table-results-action-menu-open-request-modal"
      >
        {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.SHOW-REQUEST' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button
        mat-menu-item
        (click)="openRequestResponseModal(false, element)"
        id="{{ entityName }}-table-results-action-menu-open-response-modal"
      >
        {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.SHOW-RESPONSE' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="element.validationError">
      <button
        mat-menu-item
        (click)="openXsdRequestModal(element)"
        id="{{ entityName }}-table-results-action-menu-open-validation-error-modal"
      >
        {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.SHOW-VALIDATION-ERROR' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button
        mat-menu-item
        (click)="downloadAttachments()"
        id="{{ entityName }}-table-results-action-menu-download-attachments"
      >
        {{ 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.DOWNLOAD' | translate }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
