import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GeneralUserInfoComponent } from './general-user-info/general-user-info.component';
import { RoleDataVisibilityComponent } from './role-data-visibility/role-data-visibility.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../shared/services/user/user.service';
import { ConfirmationModalComponent } from '../../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { UserCompetencesComponent } from './user-competences/user-competences.component';
import { DraftService } from '../../../../shared/services/draft/draft.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { RealmSelectionModalComponent } from '../realm-selection-modal/realm-selection-modal.component';
import { CompanySelectionModalComponent } from '../../../../shared/modals/company-selection-modal/company-selection-modal.component';
import { ContactPersonLinkModalComponent } from '../../../../shared/modals/contact-person-link-modal/contact-person-link-modal.component';
import { GeneralUserInfoExternalComponent } from './general-user-info-external/general-user-info-external.component';
import { CompanyService } from 'src/app/shared/services/company/company.service';
import { first } from 'rxjs/operators';
import { ContactPersonLinkService } from 'src/app/shared/services/contact-person-link/contact-person-link.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.less'],
})
export class UserCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(GeneralUserInfoComponent)
  generalUserInformationComponent: GeneralUserInfoComponent;
  @ViewChild(GeneralUserInfoExternalComponent)
  generalUserInformationExternalComponent: GeneralUserInfoExternalComponent;
  @ViewChild(RoleDataVisibilityComponent)
  roleDataVisibilityComponent: RoleDataVisibilityComponent;
  @ViewChild(UserCompetencesComponent)
  userCompetencesComponent: UserCompetencesComponent;
  isTask = false;
  entityName = 'user';
  showStepper = true;
  userInternal = true;
  task;
  taskValue;
  approvedValue;
  realm;
  generalUserExternalValue;
  state;
  isExternal = false;
  loaderSpinner: boolean;
  currentUser: any;
  private canUnlockUser: boolean;

  constructor(
    private matDialogService: MatDialog,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private route: ActivatedRoute,
    private draftService: DraftService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private companyService: CompanyService,
    private contactPersonLinkService: ContactPersonLinkService
  ) {}

  ngOnInit() {
    this.state = window.history.state;
    if (this.state.param && this.state.param.uneditableExternalSource) {
      this.state.viewMode = true;
    }
    this.loaderSpinner = true;
    const snapshot = this.route.snapshot;
    if (!this.state.viewMode) {
      this.canUnlockUser = true;
    }
    if (this.state.param) {
      if (this.state.param.internalUser) {
        this.realm = 'internal';
      } else {
        this.realm = 'external';
      }
    }
    if (
      (snapshot.params &&
        snapshot.params.guid &&
        this.state.param &&
        !this.state.param.companyGuid &&
        !this.state.param.internalUser) ||
      (snapshot.params && snapshot.params.guid && !this.state.param)
    ) {
      this.userService
        .getUser(snapshot.params.guid)
        .pipe(first())
        .subscribe((data) => {
          if (data) {
            if (data['internalUser']) {
              this.realm = 'internal';
              this.state.param = data;
              this.internalUserEdit(snapshot);
            } else {
              this.realm = 'external';
              this.contactPersonLinkService
                .getContactPersonInfo(data['contactPersonId'])
                .pipe(first())
                .subscribe((value) => {
                  this.companyService
                    .getCompanyById(value['companyId'])
                    .pipe(first())
                    .subscribe((centralMasterData) => {
                      this.state.param['companyGuid'] =
                        centralMasterData['guid'];
                    });
                  this.state.param = data;
                  this.state.param['email'] = value['email'];
                  this.state.param['firstName'] = value['name'];
                  this.state.param['lastName'] = value['surname'];
                  this.state.param['title'] = value['title']
                    .toString()
                    .toUpperCase();
                  this.state.param['telephoneNumber'] =
                    value['telephoneNumber'];
                });
              this.externalUserEdit(snapshot);
            }
          }
        });
    } else if (
      snapshot.params &&
      snapshot.params.guid &&
      this.state.param &&
      this.state.param.internalUser
    ) {
      this.internalUserEdit(snapshot);
    } else if (this.state.task) {
      this.loadTaskData();
    } else if (
      this.state.param &&
      !this.state.param.internalUser &&
      !this.state.copied
    ) {
      this.externalUserEdit(snapshot);
    } else if (this.state.param && this.state.copied) {
      this.getSearchApiUser(this.state.param.keycloakUserId);
    } else if (this.state.createExternal) {
      this.realm = 'external';
      this.userInternal = false;
      this.openDialogFromCompanySelect();
    } else {
      const dialog = this.matDialogService.open(RealmSelectionModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.realm = result;
          this.loaderSpinner = false;
          if (result === 'external') {
            this.userInternal = false;
            this.openDialogFromCompanySelect();
          } else {
            this.getCurrentUserNameAndDate();
          }
        } else {
          this.router.navigateByUrl('/userManagement/user');
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unlockUser();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.unlockUser();
  }

  getSearchApiUser(guid) {
    this.userService.getSearchApiUser(guid).subscribe((result) => {
      if (result['internalUser'] === true) {
        this.copyInternalUser(result);
      } else {
        this.copyExternalUser(result);
      }
    });
  }

  getCurrentUserNameAndDate() {
    this.userService.getSettings().subscribe((data) => {
      this.currentUser = data.user.username;
      this.generalUserInformationComponent.frmUser
        .get('createdBy')
        .patchValue(this.currentUser);
      const currentDate = new Date();
      this.generalUserInformationComponent.frmUser
        .get('createdOn')
        .patchValue(currentDate);
    });
  }

  copyInternalUser(data) {
    this.showStepper = false;
    this.userService.getRlsEntries(data.keycloakUserId).subscribe((rlsKeys) => {
      this.roleDataVisibilityComponent.setActivePermissions(rlsKeys);
      this.generalUserInformationComponent.frmUser.patchValue(data);
      this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(data);
      this.userCompetencesComponent.frmUserCompetences.patchValue(data);
      this.disableFields();
    });
  }

  copyExternalUser(data) {
    this.showStepper = false;
    this.isExternal = true;
    this.userInternal = false;
    this.userService.getRlsEntries(data.keycloakUserId).subscribe((rlsKeys) => {
      this.roleDataVisibilityComponent.setActivePermissions(rlsKeys);
      this.generalUserInformationExternalComponent.generalUserInfoExternalForm.patchValue(
        data
      );
      this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(data);
      this.userCompetencesComponent.frmUserCompetences.patchValue(data);
      this.disableFields();
    });
  }

  internalUserEdit(snapshot) {
    this.showStepper = false;
    this.userService
      .getInternalUser(snapshot.params.guid, this.state.viewMode)
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.loaderSpinner = false;
          this.approvedValue = data;
          if (data['internalUser'] === true) {
            this.approvedValue['realm'] = 'internal';
          }
          if (data['status']) {
            this.approvedValue['inIniProcess'] =
              data['status'] !== 'IN_INITIALISATION';
            this.approvedValue['status'] = this.approvedValue['status']
              .replace(/[_]/g, '-')
              .toUpperCase();
          }
          this.userService
            .getRlsEntries(data['keycloakUserId'])
            .subscribe((rlsKeys) => {
              this.generalUserInformationComponent.frmUser.patchValue(data);
              this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(
                this.approvedValue
              );
              this.roleDataVisibilityComponent.frmAdditionalInformation.patchValue(
                this.approvedValue
              );
              this.userCompetencesComponent.frmUserCompetences.patchValue(data);
              this.userCompetencesComponent.frmAdditionalUserCompetences.patchValue(
                data
              );
              this.disableFields();
              this.roleDataVisibilityComponent.setActivePermissions(rlsKeys);
            });
        }
      });
  }

  externalUserEdit(snapshot) {
    this.showStepper = false;
    this.isExternal = true;
    this.userInternal = false;
    this.userService
      .getExternalUser(snapshot.params.guid, this.state.viewMode)
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.loaderSpinner = false;
          this.approvedValue = this.state.param;
          if (
            this.state &&
            this.state.param &&
            this.state.param['internalUser'] === false
          ) {
            this.state.param['realm'] = 'external';
          }
          if (this.state.param && this.state.param.companyGuid) {
            this.companyService
              .getCurrentApproved(this.state.param.companyGuid)
              .subscribe((companyData) => {
                this.state.param['centralMasterData'] =
                  companyData.name +
                  ', ' +
                  companyData.address.street +
                  ', ' +
                  companyData.address.number +
                  ', ' +
                  companyData.address.zipCode +
                  ', ' +
                  companyData.address.city +
                  ', ' +
                  companyData.address.country;
                if (data['status']) {
                  this.approvedValue['inIniProcess'] =
                    data['status'] !== 'IN_INITIALISATION';
                }
                this.userService
                  .getRlsEntries(this.state.param.keycloakUserId)
                  .subscribe((rlsKeys) => {
                    this.generalUserInformationExternalComponent.generalUserInfoExternalForm.patchValue(
                      this.state.param
                    );
                    this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(
                      this.state.param
                    );
                    this.roleDataVisibilityComponent.frmAdditionalInformation.patchValue(
                      this.state.param
                    );
                    if (this.userCompetencesComponent) {
                      this.userCompetencesComponent.frmUserCompetences.patchValue(
                        this.state.param
                      );
                    }
                    this.disableFields();
                    this.roleDataVisibilityComponent.setActivePermissions(
                      rlsKeys
                    );
                  });
              });
          }
        }
      });
  }

  loadTaskData() {
    this.showStepper = false;
    this.isTask = true;
    this.task = window.history.state.task;
    this.loaderSpinner = false;
    this.draftService.retrieveDraftForTask(this.task.guid).subscribe((data) => {
      const matchedRlsKeys = [];
      this.userService.getRlsEntryNames(data.groups).subscribe((rlsKeys) => {
        data.groups.forEach((group) => {
          rlsKeys.forEach((key) => {
            if (key.id === group) {
              matchedRlsKeys.push(key);
            }
          });
        });
        data['matchedGroups'] = matchedRlsKeys;
      });
      if (data.internalUser) {
        this.taskValue = data;
        if (data.id) {
          this.userService.getInternalUser(data.id).subscribe((user) => {
            this.approvedValue = user;
          });
        }
        this.generalUserInformationComponent.frmUser.patchValue(data);
      } else {
        this.companyService
          .getCurrentApproved(data.companyGuid)
          .subscribe((companyData) => {
            data['centralMasterData'] =
              companyData.name +
              ', ' +
              companyData.address.street +
              ', ' +
              companyData.address.number +
              ', ' +
              companyData.address.zipCode +
              ', ' +
              companyData.address.city +
              ', ' +
              companyData.address.country;
          });
        this.taskValue = data;
        this.isExternal = true;
        this.userInternal = false;
        if (data.contactPersonId) {
          this.userService
            .getSearchApiUser(data.contactPersonId)
            .subscribe((user) => {
              this.approvedValue = user;
            });
        }
        if (this.generalUserInformationExternalComponent) {
          this.generalUserInformationExternalComponent.generalUserInfoExternalForm.patchValue(
            data
          );
        }
      }
      this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(data);
      this.userCompetencesComponent.frmUserCompetences.patchValue(data);
    });
  }

  createUser() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        let jsonGeneralInfo;
        if (this.userInternal) {
          jsonGeneralInfo =
            this.generalUserInformationComponent.frmUser.getRawValue();
        } else {
          jsonGeneralInfo =
            this.generalUserInformationExternalComponent.generalUserInfoExternalForm.getRawValue();
        }
        const jsonRolesAndVisibility =
          this.roleDataVisibilityComponent.frmRoleDataVisibility.getRawValue();
        jsonRolesAndVisibility.groups = jsonRolesAndVisibility.groups.map(
          (value) => value.id
        );

        let requestJson;
        if (this.userCompetencesComponent) {
          const jsonUserCompetences =
            this.userCompetencesComponent.frmUserCompetences.getRawValue();
          requestJson = {
            comment: result.comment,
            userObjectDto: {
              ...jsonGeneralInfo,
              ...jsonRolesAndVisibility,
              ...jsonUserCompetences,
            },
          };
        } else {
          requestJson = {
            comment: result.comment,
            userObjectDto: {
              ...jsonGeneralInfo,
              ...jsonRolesAndVisibility,
            },
          };
        }
        if (this.userInternal) {
          this.userService
            .createInternalUser(requestJson)
            .subscribe((data) => this.successUserCreateHandler());
        } else {
          this.userService
            .createExternalUser(requestJson)
            .subscribe((data) => this.successUserCreateHandler());
        }
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    if (window.history.state.userObject) {
      const obj = window.history.state.userObject;
      obj.keycloakUserId = '';
      obj.firstName = '';
      obj.lastName = '';
      obj.email = '';
      obj.telephoneNumber = '';
      obj.title = '';
      obj.faxNumber = '';
      obj.birthday = '';
      obj.servicePin = '';
      obj.userName = '';
      obj.createdOn = '';
      obj.updatedOn = '';
      obj.updatedBy = '';
      obj.createdBy = '';
      this.generalUserInformationComponent.frmUser.patchValue(obj);
      this.roleDataVisibilityComponent.frmRoleDataVisibility.patchValue(obj);
    }
  }

  get frmGeneralInfo() {
    if (this.generalUserInformationComponent) {
      return this.generalUserInformationComponent
        ? this.generalUserInformationComponent.frmUser
        : null;
    } else if (this.generalUserInformationExternalComponent) {
      return this.generalUserInformationExternalComponent
        ? this.generalUserInformationExternalComponent
            .generalUserInfoExternalForm
        : null;
    }
  }

  get frmDataVisibility() {
    return this.roleDataVisibilityComponent
      ? this.roleDataVisibilityComponent.frmRoleDataVisibility
      : null;
  }

  get frmUserCompetences() {
    return this.userCompetencesComponent
      ? this.userCompetencesComponent.frmUserCompetences
      : null;
  }

  get userId() {
    return this.generalUserInformationComponent
      ? this.generalUserInformationComponent.frmUser.value.userId
      : '';
  }

  get userEmail() {
    return this.generalUserInformationComponent
      ? this.generalUserInformationComponent.frmUser.value.email
      : '';
  }

  stepperChange($event) {
    const previousStep = $event.previouslySelectedStep;
    if (
      !previousStep.stepControl ||
      previousStep.stepControl.status !== 'INVALID'
    ) {
      previousStep._completedOverride = true;
      previousStep._displayDefaultIndicatorType = false;
    }
  }

  private disableFields() {
    this.frmGeneralInfo.get('userName').disable();
    if (this.frmGeneralInfo.get('internalUser')) {
      this.frmGeneralInfo.get('internalUser').disable();
    }
  }

  copyUser() {
    const jsonGeneralInfo =
      this.generalUserInformationComponent.frmUser.getRawValue();
    const jsonRolesAndVisibility =
      this.roleDataVisibilityComponent.frmRoleDataVisibility.getRawValue();
    const jsonUserCompetences =
      this.userCompetencesComponent.frmUserCompetences.getRawValue();
    const userObjectDto = {
      ...jsonGeneralInfo,
      ...jsonRolesAndVisibility,
      ...jsonUserCompetences,
    };
    this.router.navigateByUrl('/userManagement/user/create', {
      state: { userObject: userObjectDto },
    });
  }

  private openDialogFromCompanySelect() {
    const dialog = this.matDialogService.open(CompanySelectionModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        this.router.navigateByUrl('/userManagement/user');
      }
      if (result && result.company) {
        this.openDialogForContactPersonSelect(
          result.company,
          result.company.guid
        );
      }
    });
  }

  private openDialogForContactPersonSelect(company, guid) {
    const dialog = this.matDialogService.open(ContactPersonLinkModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        companyGuid: guid,
        multiple: false,
        contactList: [],
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        result['company'] = company;
        result['company']['guid'] = guid;
        this.generalUserExternalValue = result;
      } else {
        this.openDialogFromCompanySelect();
      }
    });
  }

  private unlockUser() {
    if (
      this.canUnlockUser &&
      this.approvedValue &&
      this.approvedValue['keycloakUserId']
    ) {
      const realm = this.userInternal ? 'internal' : 'external';
      this.userService
        .unLockUser(this.approvedValue['keycloakUserId'], realm)
        .subscribe((data) => {
          /**
           * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
           * to much of empty function. So this comment is to makes the sonarqube happy.
           * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
           */
        });
    }
  }

  private successUserCreateHandler() {
    this.canUnlockUser = false;
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      { data: this.translateService.instant('ENTITIES.USER') }
    );
    this.router.navigateByUrl('/userManagement/user');
  }
}
