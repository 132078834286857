import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class BusinessTransactionCodeMappingService {
  private readonly serviceUrl;
  private readonly taskServiceUrl;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.openItemService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  getBusinessTransactionCodesMapping(): Observable<any> {
    return this.http.get(this.serviceUrl + 'btcMapping', this.buildHeaders());
  }

  saveBusinessTransactionCodeMapping(json): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'btcMapping',
      json,
      this.buildHeaders()
    );
  }

  updateBusinessTransactionCodeMapping(json): Observable<any> {
    return this.http.put(
      this.serviceUrl + 'btcMapping',
      json,
      this.buildHeaders()
    );
  }

  deleteBusinessTransactionCodeMapping(request) {
    return this.http.delete(this.serviceUrl + 'btcMapping/' + request.id, {
      params: {
        comment: request.comment,
      },
      headers: this.buildHeaders().headers,
    });
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: any): Observable<any> {
    // Rejection being handled by task service
    return new BehaviorSubject(true);
  }

  retrieveByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/btcMapping',
      this.buildHeaders()
    );
  }

  retrieveApproved(id: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'btcMapping/' + id,
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
