export const decisionComplianceOptions: any[] = [
  {
    value: 1,
    name: 'Yes',
    translateName: 'GENERAL.TRUE',
  },
  {
    value: 2,
    name: 'No',
    translateName: 'GENERAL.FALSE',
  },
  {
    value: 3,
    name: 'complianceApprovalRequired',
    translateName:
      'NON-GERMANY-REFERENCE-ALLOW-LIST.DECISION-COMPLIANCE-REQUIRED',
  },
];
