import { TableTypes } from '../../default-table/table-types';

export const accountingPartnerColumnsConf: any[] = [
  {
    name: 'visibleId',
    index: 0,
    checked: true,
    translateName: 'ACCOUNTING.PARTNER-TABLE.VISIBLE-ID',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'ACCOUNTING.PARTNER-TABLE.NAME',
    locked: true,
    number: false,
    type: TableTypes.STRING,
  },
  {
    name: 'type',
    index: 2,
    checked: true,
    translateName: 'ACCOUNTING.PARTNER-TABLE.TYPE',
    locked: true,
    number: false,
    translatePrefix: 'ACCOUNTING.PARTNER-TABLE.',
    type: TableTypes.STRING,
  },
  {
    name: 'externalId',
    index: 3,
    checked: true,
    translateName: 'ACCOUNTING.PARTNER-TABLE.EXTERNAL-ID',
    locked: true,
    number: true,
    type: TableTypes.NUMBER,
  },
  {
    name: 'balance',
    index: 4,
    checked: true,
    translateName: 'ACCOUNTING.PARTNER-TABLE.BALANCE',
    locked: true,
    number: true,
    type: TableTypes.CURRENCY,
  },
  {
    version: 2,
  },
];
