import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  private readonly serviceUrl;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.participantService;
  }

  findByQsId(qsId, level) {
    return this.http.get(
      this.serviceUrl + 'masterdata/byQsid/' + qsId + '?level=' + level,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }
}
