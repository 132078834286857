import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export function handleError(error: any) {
  if (error instanceof HttpErrorResponse) {
    // The backend returned an unsuccessful response code
    console.error('Status code:', error.status);
    console.error('Response body:', error.message);
  } else if (error.error instanceof ErrorEvent) {
    // A client-side error occurred
    console.error('Error occurred:', error.error.message);
  } else {
    // network or  an other error
    console.error('Unexpected error occurred:', error.status, error.message);
  }
  return throwError(error.message || error);
}
