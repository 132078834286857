<app-back-button></app-back-button>
<div class="edit-task">
  <button
    *hasPermission="'bu.u'"
    id="{{entityName}-edit-task"
    mat-button
    class="edit-task-button"
    (click)="changeEditable(true)"
  >
    <img *ngIf="!editableTask && isTask" src="assets/images/ic_edit.svg" alt="{{ 'GENERAL.EDIT' | translate }}" />
  </button>
</div>

<div class="tab-container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div id="{{ entityName }}-general-information-tab">
          {{ 'GENERAL-INFORMATION' | translate }}
        </div>
      </ng-template>
      <app-bu-general-information
        *ngIf="buApproved || entitiesLoaded"
        [buApproved]="buApproved"
        [buTask]="buTask"
        [buInitial]="buInitial"
        [isDraftExists]="isDraftExists"
        [alreadyDraft]="alreadyDraft"
      >
      </app-bu-general-information>
      <div class="filter-input button-container" *ngIf="editableTask || !isTask">
        <button
          mat-button
          class="custom-button-cancel"
          id="{{ entityName }}-cancel-button"
          *ngIf="editableTask && isTask"
          (click)="changeEditable(false)"
        >
          {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
        </button>
        <ng-container *hasPermission="'bu.u'">
          <button
            *ngIf="edit && !isModify()"
            mat-button
            class="custom-button-cancel"
            (click)="saveAsDraft()"
            id="{{ entityName }}-create-draft-button"
          >
            <span *ngIf="alreadyDraft" id="{{ entityName }}-update-draft-button-span">
              {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
            </span>
            <span *ngIf="!alreadyDraft" id="{{ entityName }}-new-draft-button-span">
              {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
            </span>
          </button>
          <button
            *ngIf="edit && buGeneralInformationComponent && buGeneralInformationComponent.buForm"
            mat-button
            class="custom-button"
            [disabled]="checkDisabledEditButton()"
            id="{{ entityName }}-create-button"
            (click)="onClickApproval()"
          >
            <span *ngIf="!alreadyDraft">
              {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
            </span>
            <span *ngIf="alreadyDraft">
              {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
            </span>
          </button>
        </ng-container>
        <ng-container *hasPermission="'bu.c'">
          <button
            *ngIf="!edit && !isModify()"
            mat-button
            class="custom-button-cancel"
            (click)="saveAsDraft()"
            id="{{ entityName }}-create-draft-button"
          >
            <span *ngIf="alreadyDraft" id="{{ entityName }}-update-draft-button-span">
              {{ 'GENERAL-ENTITY.CREATE.UPDATE-DRAFT' | translate }}
            </span>
            <span *ngIf="!alreadyDraft" id="{{ entityName }}-new-draft-button-span">
              {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
            </span>
          </button>
          <button
            *ngIf="!edit"
            mat-button
            class="custom-button"
            [disabled]="buGeneralInformationComponent ? !buGeneralInformationComponent.buForm.valid : true"
            id="{{ entityName }}-create-button"
            (click)="onClickApproval()"
          >
            <span>
              {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
            </span>
          </button>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab id="{{ entityName }}-clients-tab" label="{{ 'BUSINESS-UNIT.CREATE.TRANSACTION-CODES' | translate }}">
      <app-bu-transaction-codes
        [entity]="buApproved"
        *ngIf="editTransactionMode == false && (buApproved || entitiesLoaded)"
      ></app-bu-transaction-codes>
      <app-bu-transaction-codes-create *ngIf="editTransactionMode == true"></app-bu-transaction-codes-create>
    </mat-tab>
  </mat-tab-group>
</div>
