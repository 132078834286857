<app-back-button *ngIf="!isTask" [address]="'/userManagement/user'"> </app-back-button>
<app-back-button *ngIf="isTask"> </app-back-button>

<span class="dynamic-loader-table" *ngIf="loaderSpinner && !showStepper">
  <mat-spinner [diameter]="50"></mat-spinner>
</span>

<div class="tab-container" *ngIf="!showStepper && !loaderSpinner">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.USER-INFORMATION' | translate }}
        </div>
      </ng-template>
      <app-general-user-info
        *ngIf="!isExternal"
        [isTask]="isTask"
        [userTask]="taskValue"
        [approvedValue]="approvedValue"
      >
      </app-general-user-info>
      <app-general-user-info-external
        *ngIf="isExternal"
        [isTask]="isTask"
        [taskValue]="taskValue"
        [approvedValue]="approvedValue"
      ></app-general-user-info-external>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.ROLES-DATA-VISIBILITY' | translate }}
        </div>
      </ng-template>
      <app-role-data-visibility
        *ngIf="approvedValue || isTask"
        [userId]="userId"
        [userEmail]="userEmail"
        [isTask]="isTask"
        [userTask]="taskValue"
        [approvedValue]="approvedValue"
        [realm]="realm"
      >
      </app-role-data-visibility>
    </mat-tab>
    <mat-tab *ngIf="!isExternal">
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.USER-COMPETENCES' | translate }}
        </div>
      </ng-template>
      <app-user-competences
        [userId]="userId"
        [userEmail]="userEmail"
        [isTask]="isTask"
        [userTask]="taskValue"
        [approvedValue]="approvedValue"
      ></app-user-competences>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="stepper-container" *ngIf="showStepper && ((realm && !loaderSpinner) || state.createExternal)">
  <mat-horizontal-stepper #stepper (selectionChange)="stepperChange($event)">
    <ng-template matStepperIcon="done">
      <span class="material-icons"> done </span>
    </ng-template>

    <mat-step [stepControl]="frmGeneralInfo">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-general-information-step">{{ 'USER-MANAGEMENT.USER-INFORMATION' | translate }}</span>
      </ng-template>
      <app-general-user-info *ngIf="realm === 'internal'"></app-general-user-info>
      <app-general-user-info-external
        *ngIf="realm === 'external' && generalUserExternalValue"
        [approvedValue]="generalUserExternalValue"
      ></app-general-user-info-external>

      <div class="stepper-button-container">
        <button
          id="{{ entityName }}-form-next-button-general-information"
          class="custom-button-cancel stepper-next"
          mat-button
          matStepperNext
        >
          {{ 'STEPPER.NEXT-STEP' | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [stepControl]="frmDataVisibility">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-role-data-visibility-step">{{
          'USER-MANAGEMENT.ROLES-DATA-VISIBILITY' | translate
        }}</span>
      </ng-template>
      <app-role-data-visibility
        *ngIf="realm === 'internal' || realm === 'external'"
        [userId]="userId"
        [userEmail]="userEmail"
        [realm]="realm"
      ></app-role-data-visibility>
      <div class="stepper-button-container">
        <button
          *ngIf="realm === 'internal'"
          id="{{ entityName }}-form-next-button-bank-account"
          class="custom-button-cancel stepper-next"
          mat-button
          matStepperNext
        >
          {{ 'STEPPER.NEXT-STEP' | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step *ngIf="realm === 'internal'" [stepControl]="frmUserCompetences">
      <ng-template matStepLabel>
        <span id="{{ entityName }}-contact-person-step"> {{ 'USER-MANAGEMENT.USER-COMPETENCES' | translate }}</span>
      </ng-template>
      <app-user-competences [userId]="userId" [userEmail]="userEmail"></app-user-competences>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="buttons">
  <div class="save-button">
    <span *ngIf="generalUserInformationComponent && roleDataVisibilityComponent">
      <button
        mat-button
        class="custom-button"
        (click)="createUser()"
        [disabled]="
          generalUserInformationComponent.frmUser.invalid ||
          roleDataVisibilityComponent.frmRoleDataVisibility.invalid ||
          state.viewMode
        "
        *ngIf="!isTask"
      >
        {{ 'GENERAL.SAVE' | translate }}
      </button>
    </span>
    <span *ngIf="generalUserInformationExternalComponent && roleDataVisibilityComponent">
      <button
        mat-button
        class="custom-button"
        (click)="createUser()"
        [disabled]="
          generalUserInformationExternalComponent.generalUserInfoExternalForm.invalid ||
          roleDataVisibilityComponent.frmRoleDataVisibility.invalid ||
          state.viewMode
        "
        *ngIf="!isTask"
      >
        {{ 'GENERAL.SAVE' | translate }}
      </button>
    </span>
  </div>
</div>
