export const productEnum: any[] = [
  {
    code: 0,
    name: 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.FACTORING',
    value: 'FACTORING',
  },
  {
    code: 1,
    name: 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.CENTRAL_SETTLEMENT',
    value: 'CENTRAL_SETTLEMENT',
  },
  {
    code: 2,
    name: 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.BACK_UP_SERVICING',
    value: 'BACKUP_SERVICING',
  },
  {
    code: 3,
    name: 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.ANIMAL_WELFARE',
    value: 'ANIMAL_WELFARE',
  },
];
