import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountingSharedDataService } from 'src/app/shared/services/accounting/accounting-shared-data.service';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
  TableActionIconsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { TablesService } from 'src/app/shared/tables/tables.service';

@Component({
  selector: 'app-accounting-transaction-list-expanded',
  templateUrl: './accounting-transaction-list-expanded.component.html',
  styleUrls: ['./accounting-transaction-list-expanded.component.less'],
})
export class AccountingTransactionListExpandedComponent
  implements OnInit, OnDestroy
{
  entityName = 'accounting-transaction-list-expanded';
  dataLoaded = false;
  loaderSpinner = true;
  hasThreeDots = true;
  configurationKey;
  selectedRows;
  columns: any;
  displayedColumns: any;
  hasTransaction = false;
  openDrawer = false;
  @ViewChild('drawer') drawer: MatDrawer;
  routeParamId;
  routeParamType;
  subscription: Subscription;
  objectionAmountForMultiple = false;
  selectedTransactionData = this.openItemService.sharedSplitScreenData;
  transactionExpandedData = {
    content: [],
  };
  transactionCodes;
  listOfActionButtons: any[] = [];
  currentTab;
  configurationKeyForNonAWOpenTransaction =
    'accounting_transaction_non_aw_table_open_transactions';
  configurationKeyForNonAWClosedTransaction =
    'accounting_transaction_non_aw_table_closed_transactions';
  configurationKeyForNonAWCreditTransaction =
    'accounting_transaction_non_aw_table_credit_transactions';
  configurationKeyForAWOpenTransaction =
    'accounting_transaction_animal_welfare_table_open_transactions';
  configurationKeyForAWClosedTransaction =
    'accounting_transaction_animal_welfare_table_closed_transactions';
  configurationKeyForAWCreditTransaction =
    'accounting_transaction_animal_welfare_table_credit_transactions';
  listOfActionIconsForAccountingOpenTransactionAW: TableActionIconsConfiguration[] =
    [
      {
        name: 'allocationDraft',
        icon: 'all_inclusive',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
        function: (param) => {
          this.editAllocationPaymentDraft(param);
        },
        class: 'material-icons',
      },
    ];
  listOfActionIconsForAccountingNonAW: TableActionIconsConfiguration[] = [
    {
      name: 'allocationDraft',
      icon: 'all_inclusive',
      translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
      function: (param) => {
        this.editAllocationPaymentDraft(param);
      },
      class: 'material-icons',
    },
  ];
  listOfActionButtonsForOpenTransactionsAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'transactionInformationAndHistory',
        permission: null,
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
    ];
  listOfActionButtonsForClosedTransactionsNonAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'transactionInformationAndHistory',
        permission: null,
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
    ];
  listOfActionButtonsForOpenTransactionsNonAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'allocation',
        permission: 'acc.a.c',
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
        function: () => {
          this.allocationAction();
        },
        selectionType: DefaultTableSelectionType.singleAndMultiple,
        specialDisable: () => {
          return this.checkDisableAllocation();
        },
      },
      {
        name: 'transactionInformationAndHistory',
        permission: '',
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
      {
        name: 'commentsAndAttachments',
        permission: '',
        icon: '',
        translationKey: 'TRANSACTION.COMMENTS_AND_ATTACHMENTS',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.multiple,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
    ];

  constructor(
    private businessUnitService: BusinessUnitService,
    private openItemService: OpenItemService,
    private bookingCodeService: BookingCodeService,
    private tablesService: TablesService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialogService: MatDialog,
    private accountingSharedDataService: AccountingSharedDataService
  ) {
    this.tablesService.currentSharedSelectedRows.subscribe(
      (selectedRows) => (this.selectedRows = selectedRows)
    );
  }

  ngOnInit() {
    this.routeParamId = this.route.snapshot.paramMap.get('id');
    this.routeParamType = this.route.snapshot.paramMap.get('type');
    this.getCurrentBusinessUnitAndTab();
    this.generateListOfActionButtons();
    this.loadData();
  }

  getCurrentBusinessUnitAndTab() {
    this.accountingSharedDataService.currentTab.subscribe((data) => {
      this.currentTab = data;
      if (this.businessUnitService.getCurrentBusinessUnit() === '4') {
        if (this.currentTab === 0) {
          this.configurationKey = this.configurationKeyForAWOpenTransaction;
        } else if (this.currentTab === 1) {
          this.configurationKey = this.configurationKeyForAWClosedTransaction;
        } else if (this.currentTab === 2) {
          this.configurationKey = this.configurationKeyForAWCreditTransaction;
        }
        this.columns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsColumnsStateAW;
        this.displayedColumns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsDisplayedColumnsStateAW;
      } else {
        if (this.currentTab === 0) {
          this.configurationKey = this.configurationKeyForNonAWOpenTransaction;
        } else if (this.currentTab === 1) {
          this.configurationKey =
            this.configurationKeyForNonAWClosedTransaction;
        } else if (this.currentTab === 2) {
          this.configurationKey =
            this.configurationKeyForNonAWCreditTransaction;
        }
        this.columns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsColumnsStateNonAW;
        this.displayedColumns =
          this.accountingSharedDataService.sharedAccountingOpenTransactionsDisplayedColumnsStateNonAW;
      }
    });
  }

  generateListOfActionButtons() {
    if (this.businessUnitService.getCurrentBusinessUnit() !== '4') {
      if (this.currentTab === 0) {
        this.listOfActionButtons =
          this.listOfActionButtonsForOpenTransactionsNonAW;
      } else if (this.currentTab === 1) {
        this.listOfActionButtons =
          this.listOfActionButtonsForClosedTransactionsNonAW;
      } else if (this.currentTab === 2) {
        this.listOfActionButtons =
          this.listOfActionButtonsForClosedTransactionsNonAW;
      }
    } else {
      if (this.currentTab === 0) {
        this.listOfActionButtons =
          this.listOfActionButtonsForOpenTransactionsAW;
      } else if (this.currentTab === 1) {
        this.listOfActionButtons =
          this.listOfActionButtonsForOpenTransactionsAW;
      } else if (this.currentTab === 2) {
        this.listOfActionButtons =
          this.listOfActionButtonsForOpenTransactionsAW;
      }
    }
  }

  loadData() {
    this.subscription =
      this.tablesService.currentSharedSelectedExpandedRows.subscribe((row) => {
        this.getTransactionDetailedInfo(row);
      });
  }

  getTransactionDetailedInfo(element) {
    if (element) {
      this.loaderSpinner = true;
      this.dataLoaded = false;
      this.openItemService.findByTransactionId(element.id).subscribe(
        (data) => {
          if (data && data.length > 0) {
            this.transactionExpandedData.content = [];
            data.forEach((transaction) => {
              this.transactionExpandedData.content.push(
                transaction.transaction
              );
            });
            this.mapDebitCreditCode();
            this.mapBookingCodesExpandableTable();
          } else {
            this.loaderSpinner = false;
            this.dataLoaded = true;
          }
        },
        (error) => {
          this.loaderSpinner = false;
          this.dataLoaded = true;
        }
      );
    } else {
      this.loaderSpinner = false;
      this.dataLoaded = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async mapBookingCodesExpandableTable() {
    this.transactionExpandedData.content.forEach((openItem) => {
      this.bookingCodeService
        .retrieveApproved(openItem.bookingCode)
        .subscribe((data) => {
          Object.assign(
            openItem,
            {
              bookingCodeNameDe: data.name,
              bookingCodeNameEn: data.defaultNameEnglish,
            },
            (error) => {
              this.loaderSpinner = false;
              this.dataLoaded = true;
            }
          );
          this.loaderSpinner = false;
          this.dataLoaded = true;
        });
    });
  }

  async mapDebitCreditCode() {
    this.transactionExpandedData.content.forEach((openItem) => {
      Object.assign(openItem, {
        creditDebitCode: openItem.debitOrCredit,
      });
    });
  }

  checkDisableAllocation() {
    let result = false;
    if (this.selectedRows && this.selectedRows.length > 0) {
      this.selectedRows.forEach((row) => {
        if (row.allocationDraft && result === false) {
          result = true;
        }
      });
    }
    return result;
  }

  checkDisable() {
    return false;
  }

  getValuesForSplitScreen(param) {
    this.hasTransaction = true;
    const listOfSelectedTransactions: any[] = [];
    param.clientId = this.transactionExpandedData.content['clientId'];
    if (this.selectedRows.length > 1) {
      this.selectedRows.forEach((selectedRow) => {
        listOfSelectedTransactions.push(selectedRow);
      });
    }
    param.selectedTransactions = listOfSelectedTransactions;
    this.openItemService.updateSharedSplitScreenData(param);
    this.openDrawer = true;
    setTimeout(() => {
      this.drawer.toggle();
    }, 50);
  }

  allocationAction() {
    this.openAllocationPayment();
  }

  openAllocationPayment() {
    this.router.navigateByUrl(
      '/accounting/overview/' +
        this.routeParamId +
        '/' +
        this.routeParamType +
        '/paymentAllocation',
      {
        state: {
          success: true,
          selectedDebtors: this.selectedRows,
        },
      }
    );
  }

  editAllocationPaymentDraft(param) {
    this.router.navigateByUrl(
      '/accounting/overview/' +
        this.routeParamId +
        '/' +
        this.routeParamType +
        '/paymentAllocation',
      {
        state: {
          success: true,
          selectedDebtors: this.selectedRows,
          draftGuid: param.draftId,
        },
      }
    );
  }

  closeSplitScreen() {
    this.tablesService.updateSharedSelectedRows(null);
    this.openItemService.updateSharedSelectedBalance(0.0);
  }
}
