import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { BusinessTransactionCodeService } from 'src/app/shared/services/business-transaction-code/business-transaction-code.service';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { bookOptionsBTCs } from 'src/app/in-memory-data/system-configuration/business-transaction-code-mapping/editable-btc/booked-options';
import { btcMappingEditableBTCColumns } from 'src/app/in-memory-data/system-configuration/business-transaction-code-mapping/editable-btc/table-columns';
import { transactionCodeOptions } from 'src/app/in-memory-data/system-configuration/business-transaction-code-mapping/editable-btc/transaction-code-options';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { BusinessTransactionCodeMappingService } from 'src/app/shared/services/business-transaction-code-mapping/business-transaction-code-mapping.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { Router } from '@angular/router';
import { DirtyComponent } from '../../../shared/models/dirtyComponent';

@Component({
  selector: 'app-btc-mapping-create',
  templateUrl: './btc-mapping-create.component.html',
  styleUrls: ['./btc-mapping-create.component.less'],
})
export class BtcMappingCreateComponent implements OnInit, DirtyComponent {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumnsEditableBTC = btcMappingEditableBTCColumns;
  btcMappingForm: any;
  entityName = 'business-transaction-code-mapping';
  edit = false;
  BTCs: any[];
  transactionCodes: any;
  bookedOptions = bookOptionsBTCs;
  transactionOptions = transactionCodeOptions;
  approval = false;
  draftSave = false;
  dataSourceBTCs = new MatTableDataSource<any>();
  editableTask = false;
  isTask = false;
  btcMappingTask: any;
  btcMappingApproved: any;
  alreadyDraft = false;
  draftId: number;

  constructor(
    private businessTransactionCodeMappingService: BusinessTransactionCodeMappingService,
    private businessTransactionCodeService: BusinessTransactionCodeService,
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog,
    private bookingCodeService: BookingCodeService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private taskService: TaskService,
    private draftService: DraftService,
    private router: Router
  ) {
    this.btcMappingFormBuilder();
    this.getAllTransactionCodes();
  }

  ngOnInit(): void {
    this.getAllBussinessTransactionCodes();
  }

  canDeactivate() {
    return this.btcMappingForm.dirty && !this.approval && !this.draftSave;
  }

  btcMappingFormBuilder() {
    this.btcMappingForm = this.formBuilder.group({
      name: new UntypedFormControl('', Validators.required),
      btcS: new UntypedFormArray([]),
      id: new UntypedFormControl(''),
      mappingId: new UntypedFormControl(''),
    });
  }

  getAllBussinessTransactionCodes() {
    this.businessTransactionCodeService
      .getBusinessTransactionCodes()
      .subscribe((data) => {
        data.sort(this.compareBTCDefinitionsByCode);
        this.BTCs = data;
        this.dataSourceBTCs.paginator = this.paginator;
        this.addValuesToEditableTable();
        this.dataSourceBTCs.data = data;
      });
  }

  addValuesToEditableTable() {
    this.BTCs.forEach((element) => {
      const btc = {
        id: element.id,
        code: element.code,
        name: element.name,
        booked: null,
        credit: null,
        creditTransactionCodeId: null,
        creditOption: null,
        debit: null,
        debitTransactionCodeId: null,
        debitOption: null,
      };

      this.dataSourceBTCs.data.push(btc);
      this.addBTCRow(btc);
    });
  }

  addBTCRow(btc) {
    this.BTCsObjects.push(
      this.formBuilder.group({
        id: new UntypedFormControl(btc.id, Validators.required),
        code: new UntypedFormControl(btc.code, Validators.required),
        name: new UntypedFormControl(btc.name, Validators.required),
        booked: new UntypedFormControl(undefined),
        creditTransactionCodeId: new UntypedFormControl(null),
        creditOption: new UntypedFormControl(null),
        debitTransactionCodeId: new UntypedFormControl(null),
        debitOption: new UntypedFormControl(null),
      })
    );
    this.initView();
  }

  initView() {
    const state = window.history.state;
    if (state.task) {
      this.isTask = true;
      if (state.task.params && state.task.params.entityId) {
        this.businessTransactionCodeMappingService
          .retrieveApproved(state.task.params.entityId)
          .subscribe((result) => {
            this.btcMappingTask = result;
            this.patchFormRows(this.btcMappingTask);
          });
      } else {
        this.draftService
          .retrieveDraftForTask(state.task.guid)
          .subscribe((result) => {
            this.btcMappingTask = result;
            this.patchFormRows(this.btcMappingTask);

            if (this.btcMappingTask.id) {
              this.businessTransactionCodeMappingService
                .retrieveApproved(this.btcMappingTask.id)
                .subscribe((businessTransactionCode) => {
                  this.btcMappingApproved = businessTransactionCode;
                });
            }
          });
      }

      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );

      if (sessionStorage.getItem('taskModify') === 'true') {
        this.taskService.updateEditableTask(true);
      }
    } else if (state.btcMapping) {
      this.btcMappingApproved = state.btcMapping;
      this.edit = true;
      this.patchFormRows(this.btcMappingApproved);

      this.draftService
        .approvalDraftExistsForEntityId(this.btcMappingApproved.id)
        .subscribe(
          (doesDraftExist) => {
            if (doesDraftExist) {
              this.btcMappingForm.disable({
                onlySelf: true,
                emitEvent: false,
              });
              this.notificationService.showToastMessage(
                'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
                this.notificationService.MESSAGE_TYPE.INFO
              );
            } else {
              this.draftService
                .retrieveLocalEntityDraft(this.btcMappingApproved.id)
                .subscribe((draft) => {
                  this.btcMappingForm.disable({
                    onlySelf: true,
                    emitEvent: false,
                  });
                  this.notificationService.showToastMessage(
                    'GENERAL-ENTITY.CREATE.MESSAGES.TOAST-MESSAGE-DRAFT-EXISTS',
                    this.notificationService.MESSAGE_TYPE.INFO
                  );
                });
            }
          },
          (err) => this.handleError(err)
        );

      if (this.btcMappingApproved.draft) {
        this.draftId = this.btcMappingApproved.draftId;
      }
    }
  }

  patchFormRows(btcMapping) {
    this.btcMappingForm.get('name').patchValue(btcMapping.name);
    this.btcMappingForm.get('id').patchValue(btcMapping.id);
    this.btcMappingForm.get('mappingId').patchValue(btcMapping.mappingId);

    btcMapping.btcS.forEach((element) => {
      let formGroup: any = null;
      formGroup = this.BTCsObjects.controls.find(
        (item) => item.value.code === element.code
      );
      if (formGroup) {
        formGroup.patchValue(element);
      }

      if (window.history.state.task) {
        formGroup.disable();
      }
    });

    if (window.history.state.task) {
      this.btcMappingForm.get('name').disable();
    }
  }

  get formControls() {
    return this.btcMappingForm.controls;
  }
  get BTCsObjects() {
    return this.formControls.btcS as UntypedFormArray;
  }

  getFormGroup(element) {
    let formGroup: any = null;
    formGroup = this.BTCsObjects.controls.find(
      (item) => item.value.code === element.code
    );
    return formGroup;
  }

  onBookChange($event, element) {
    let formGroup: any = null;
    formGroup = this.BTCsObjects.controls.find(
      (item) => item.value.code === element.code
    );

    if ($event.value === true) {
      formGroup
        .get('creditTransactionCodeId')
        .setValidators([Validators.required]);
      formGroup.get('creditOption').setValidators([Validators.required]);
      formGroup.get('creditOption').setValue('CREDIT');
      formGroup
        .get('debitTransactionCodeId')
        .setValidators([Validators.required]);
      formGroup.get('debitOption').setValidators([Validators.required]);
      formGroup.get('debitOption').setValue('DEBIT');
    } else {
      formGroup.get('creditTransactionCodeId').setValue(null);
      formGroup.get('creditTransactionCodeId').clearValidators();

      formGroup.get('creditOption').setValue(null);
      formGroup.get('creditOption').clearValidators();

      formGroup.get('debitTransactionCodeId').setValue(null);
      formGroup.get('debitTransactionCodeId').clearValidators();

      formGroup.get('debitOption').setValue(null);
      formGroup.get('debitOption').clearValidators();
    }

    formGroup.get('creditTransactionCodeId').updateValueAndValidity();
    formGroup.get('creditOption').updateValueAndValidity();
    formGroup.get('debitTransactionCodeId').updateValueAndValidity();
    formGroup.get('debitOption').updateValueAndValidity();
  }

  getDisabledStatus(element) {
    let formGroup: any = null;
    formGroup = this.BTCsObjects.controls.find(
      (item) => item.value.code === element.code
    );

    if (formGroup && formGroup.get('booked').value === true) {
      return false;
    } else {
      return true;
    }
  }

  getRequiredStatus(element) {
    let formGroup: any = null;
    formGroup = this.BTCsObjects.controls.find(
      (item) => item.value.code === element.code
    );

    if (formGroup && formGroup.get('booked').value === true) {
      return true;
    } else {
      return false;
    }
  }

  getAllTransactionCodes() {
    this.bookingCodeService.listAll().subscribe((data) => {
      this.transactionCodes = data;
    });
  }

  setItemChecked(item) {
    item.checked = item.value;
  }

  changeEditMode() {
    this.router.navigateByUrl(
      '/systemConfiguration/accountStatement/btcMapping',
      { state: {} }
    );
  }

  changeEditTaskMode(value: boolean) {
    this.taskService.updateEditableTask(value);
    this.setFormEnableMode(value);
  }

  setFormEnableMode(value: boolean) {
    if (value) {
      this.btcMappingForm.get('name').enable();

      this.btcMappingForm.get('btcS').controls.forEach((element) => {
        element.enable();
        if (!element.value.booked) {
          element.get('creditTransactionCodeId').disable();
          element.get('creditOption').disable();
          element.get('debitTransactionCodeId').disable();
          element.get('debitOption').disable();
        }
      });
    } else {
      this.patchFormRows(this.btcMappingTask);
    }
  }

  onClickSave() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        this.approval = true;
        const json = this.btcMappingForm.value;
        const request = {
          comment: result.comment,
          mapping: json,
        };
        if (!this.edit || this.draftId) {
          this.businessTransactionCodeMappingService
            .saveBusinessTransactionCodeMapping(request)
            .subscribe((_) => {
              this.approval = true;
              if (this.draftId) {
                this.draftService.deleteUserDraft(this.draftId).subscribe();
              }
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant('ENTITIES.BTC-MAPPING'),
                }
              );
              setTimeout(() => {
                this.changeEditMode();
              }, 1500);
            }, this.customErrorHandler._errorHandler('ENTITIES.BTC-MAPPING'));
        } else {
          this.businessTransactionCodeMappingService
            .updateBusinessTransactionCodeMapping(request)
            .subscribe((_) => {
              this.approval = true;
              if (this.draftId) {
                this.draftService.deleteUserDraft(this.draftId).subscribe();
              }
              this.notificationService.showToast(
                'NOTIFICATION.CREATED-DIRECT',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                {
                  data: this.translateService.instant('ENTITIES.BTC-MAPPING'),
                }
              );
              setTimeout(() => {
                this.changeEditMode();
              }, 1500);
            }, this.customErrorHandler._errorHandler('ENTITIES.BTC-MAPPING'));
        }
      }
    });
  }

  get getTransactonCodeName() {
    return this.createName.bind(this);
  }

  createName(item) {
    return item.id + ' ' + item.defaultNameEnglish;
  }

  hasChanged(element, property) {
    let formGroup: any = null;
    formGroup = this.BTCsObjects.controls.find(
      (item) => item.value.code === element.code
    );

    if (formGroup) {
      if (this.btcMappingApproved && !this.btcMappingTask) {
        if (
          this.btcMappingApproved.btcS &&
          this.btcMappingApproved.btcS.length > 0
        ) {
          const btc = this.btcMappingApproved.btcS.find(
            (item) => item.code === formGroup.value.code
          );
          if (btc) {
            return btc[property] !== formGroup.value[property];
          } else {
            return false;
          }
        }
      } else if (this.btcMappingTask && !this.btcMappingApproved) {
        if (this.btcMappingTask.btcS && this.btcMappingTask.btcS.length > 0) {
          const btc = this.btcMappingTask.btcS.find(
            (item) => item.code === formGroup.value.code
          );
          if (btc) {
            return btc[property] !== formGroup.value[property];
          } else {
            return false;
          }
        }
      } else if (this.btcMappingTask && this.btcMappingApproved) {
        const btcTask = this.btcMappingTask.btcS.find(
          (item) => item.code === formGroup.value.code
        );

        const btcApproved = this.btcMappingApproved.btcS.find(
          (item) => item.code === formGroup.value.code
        );

        if (btcTask && btcApproved) {
          if (btcTask[property] === btcApproved[property]) {
            return btcTask[property] !== formGroup.value[property];
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  compareBTCDefinitionsByCode(a, b) {
    if (a.code.localeCompare(b.code) < 0) {
      return -1;
    }
    if (a.code.localeCompare(b.code) > 0) {
      return 1;
    }
    return 0;
  }

  handleError(error) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: error.error.errorId ? error.error.errorId : 'unknown',
        error: error.name,
      }
    );
  }

  saveAsDraft() {
    const draftId = this.draftId;
    const element = this.btcMappingForm.value;
    const request = {
      entityId: element.id,
      entityType: 'BTC_MAPPING',
      element,
      id: draftId,
      entityGuid: this.btcMappingApproved ? this.btcMappingApproved.id : null,
    };
    const update = !!draftId;
    this.draftService.saveUserDraft(request).subscribe((_) => {
      this.draftSave = true;
      if (update) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      } else {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
      setTimeout(() => {
        this.changeEditMode();
      }, 1500);
    }, this.customErrorHandler._errorHandler('ENTITIES.BTC-MAPPING'));
  }
}
