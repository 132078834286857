export const limitConditionFields: any[] = [
  {
    name: 'approvalLimitDomestic',
    translateName:
      'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.APPROVAL-LIMIT-DOMESTIC',
    maskValue: 'currency',
  },
  {
    name: 'blindCover',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.BLIND-COVER',
    maskValue: 'currency',
  },
  {
    name: 'unnamedAreaDomestic',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.UNNAMED-AREA-DOMESTIC',
    maskValue: 'currency',
  },
  {
    name: 'conditions',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.CONDITIONS',
  },
  {
    name: 'creditCheck',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.CREDIT-CHECK',
    maskValue: 'currency',
  },
  {
    name: 'creditAgencies',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.CREDIT-AGENCIES',
    methodName: 'getCreditAgencies',
  },
  {
    name: 'maxTotalLimitGranted',
    translateName:
      'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.MAXIMUM-TOTAL-LIMIT-GRANTED',
  },
  {
    name: 'maximumTotalAmountOfTheLimit',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.MAX-AMOUNT',
    maskValue: 'currency',
  },
  {
    name: 'specialLimitProcessing',
    translateName: 'TCI.CREATE.FORM-LIMIT-CONDITION-TAB.SPECIAL-LIMIT',
  },
];
