<div class="object-list">
  <div *ngFor="let item of fieldsConfiguration">
    <div
      class="object-row"
      [ngStyle]="{
        display: specialDisplayValidation(item, item.specialDisplayMethod) ? 'block' : 'none'
      }"
    >
      <div class="container" fxLayout="row wrap" fxLayoutGap="7px grid">
        <div fxFlex="30" fxFlex.sm="30" fxFlex.xs="50">
          <span class="object-label" id="{{ entityName }}-value-label">{{ item.translateName | translate }}:</span>
        </div>

        <div fxFlex="70" fxFlex.sm="70" fxFlex.xs="50">
          <!-- Start old Entity -->
          <span
            id="{{ entityName }}-old-value"
            class="object-value"
            [ngClass]="{
              'old-edited-value': oldEntity[item.name] != newEntity[item.name]
            }"
            [ngStyle]="{
              display: allValuesNull(oldEntity) ? 'none' : 'inline-block'
            }"
          >
            <!-- Existing Values -->
            <span *ngIf="!item.methodName && !item.maskValue">{{ oldEntity[item.name] }}</span>

            <span *ngIf="item.methodName && !item.maskValue">
              <span *ngIf="item.methodParam">
                {{ callMethod(item.methodName, oldEntity[item.methodParam]) }}
              </span>
              <span *ngIf="!item.methodParam">
                {{ callMethod(item.methodName, oldEntity[item.name]) }}
              </span>
            </span>

            <span *ngIf="!item.methodName && item.maskValue && item.maskValue == 'percent'">{{
              oldEntity[item.name] / 100 | percent: '1.0-3'
            }}</span>
            <span *ngIf="!item.methodName && item.maskValue && item.maskValue == 'currency'">{{
              oldEntity[item.name] | currency: 'EUR':'symbol':'1.2-2':'de'
            }}</span>
            <span
              *ngIf="!item.methodName && item.maskValue && item.maskValue != 'currency' && item.maskValue != 'percent'"
              >{{ oldEntity[item.name] }} {{ item.maskValue | translate | titlecase }}</span
            >
            <!-- End Existing Values -->

            <!-- Non Existing Values -->
            <span
              class="undefined-value"
              *ngIf="
                !item.methodName &&
                !item.maskValue &&
                (oldEntity[item.name] == undefined || oldEntity[item.name] == null)
              "
              >{{ 'TCI.CREATE.HISTORY-TAB.UNDEFINED' | translate }}</span
            >
            <span
              class="undefined-value"
              *ngIf="
                !item.methodName &&
                item.maskValue &&
                item.maskValue == 'currency' &&
                (oldEntity[item.name] == undefined || oldEntity[item.name] == null)
              "
              >{{ 0 | currency: 'EUR':'symbol':'1.2-2':'de' }}</span
            >
            <!-- End Non Existing Values -->
          </span>
          <!-- End old Entity -->

          <!-- Start new Entity -->
          <span
            id="{{ entityName }}-new-value"
            class="object-value new-edited-value"
            *ngIf="oldEntity[item.name] != newEntity[item.name]"
          >
            <!-- Start Existing Values -->
            <span *ngIf="!item.methodName && !item.maskValue">{{ newEntity[item.name] }}</span>
            <span *ngIf="item.methodName && !item.maskValue">{{
              callMethod(item.methodName, newEntity[item.name])
            }}</span>

            <span *ngIf="!item.methodName && item.maskValue && item.maskValue == 'percent' && newEntity[item.name]">{{
              newEntity[item.name] / 100 | percent: '1.0-3'
            }}</span>
            <span *ngIf="!item.methodName && item.maskValue && item.maskValue == 'currency'">{{
              newEntity[item.name] | currency: 'EUR':'symbol':'1.2-2':'de'
            }}</span>
            <span
              *ngIf="
                !item.methodName &&
                item.maskValue &&
                item.maskValue != 'currency' &&
                item.maskValue != 'percent' &&
                newEntity[item.name]
              "
              >{{ newEntity[item.name] }} {{ item.maskValue | translate | titlecase }}</span
            >
          </span>
          <!-- End new Entity -->
        </div>
      </div>
    </div>
  </div>
</div>
