import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  quantityLabeledGoodsCreateColumns
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods-create/table-columns';
import {
  quantityLabeledGoodsCreateColumnsConf
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods-create/table-columns-configuration';
import {
  goodsGroupsOptions
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/goodsGroupsOptions';
import {
  reportedByOptions
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/reportedByOptions';
import {
  typeOptions
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/typeOptions';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import {
  GoodsGroupService
} from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import {
  QuatityLabelledGoodService
} from 'src/app/shared/services/animal-welfare/quantity-labelled-good/quatity-labelled-good.service';
import {
  ReportingPeriodService
} from 'src/app/shared/services/animal-welfare/reporting-period/reporting-period.service';
import {
  SalesEntryService
} from 'src/app/shared/services/animal-welfare/sales-entry/sales-entry.service';
import {
  CompanyService
} from 'src/app/shared/services/company/company.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import {
  NotificationService
} from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import {
  CustomErrorHandler
} from 'src/app/shared/utils/error-handler/custom-error-handler';
import { QuantityLabelledGood } from './quantity-labelled-good';
import * as _ from 'lodash';
import {
  TaskTypesQLG
} from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/taskTypes';
import {
  TranslationService
} from '../../../../shared/services/translation/translation.service';

@Component({
  selector: 'app-quantity-labeled-goods-create',
  templateUrl: './quantity-labeled-goods-create.component.html',
  styleUrls: [
    './quantity-labeled-goods-create.component.less',
    './quantity-labeled-goods-create-md.component.less',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityLabeledGoodsCreateComponent
  implements OnInit, DirtyComponent, AfterViewChecked {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  entityName = 'quantity-of-labeled-goods';

  commonForm: UntypedFormGroup;
  form: UntypedFormGroup;
  checkSumForm: UntypedFormGroup;

  periods: any[] = [];
  index = 0;
  deletedRowsIndexes: any[] = [];

  private CONFIGURAIONT_KEY = 'quantity_labeled_goods_create_table';
  private ENTITY_TYPE = 'QUANTITY_LABELLED_GOOD';

  columns = quantityLabeledGoodsCreateColumnsConf;
  displayedColumns = quantityLabeledGoodsCreateColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  goodsGroupsOptionsLocal = [];
  typeOptions = typeOptions;
  reportedByOptions = reportedByOptions;

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;
  state: any;
  isTask = false;
  existentReportingPeriods: any;
  existentGoodsGroups: any;

  editableTask = true;
  quantityLabelledGoodsApproved: any[] = [];
  quantityLabelledGoodsTask: any[] = [];
  isDeleteTask = false;

  editMode = false;
  draftId: any;
  isDraft = false;
  suppliers: any = [];
  filteredSuppliers: any = [];
  filteredReceivers: any = [];
  protected onDestroy = new Subject<void>();
  approval = false;
  receivers: any;

  referenceValueToCheckHighlights: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private router: Router,
    private reportingPeriodService: ReportingPeriodService,
    private goodsGroupsService: GoodsGroupService,
    private quantityLabelledGoodService: QuatityLabelledGoodService,
    private taskService: TaskService,
    private draftService: DraftService,
    private customErrorHandler: CustomErrorHandler,
    private companyService: CompanyService,
    private salesEntryService: SalesEntryService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.commonFormBuilder();
    this.reportFormBuilder();
    this.checkSumFormBuilder();

    if (window.history?.state?.draftId) {
      this.draftId = window.history.state.draftId;
    }
  }

  ngOnInit() {
    this.state = window.history.state;
    this.getSuppliers();
    this.detectChangesCommonForm();
    this.getTableConfiguration();
    this.getGoodGroups();
    this.salesEntryService.findAll({allRows: true}).subscribe((data) => {
      this.receivers = data['content'];
      this.quantityLabelledGoodService
      .findSupplierByLevel('VERARBEITUNGSBETRIEB')
      .subscribe((fpcdata: any) => {
        if (fpcdata && fpcdata.length > 0) {
          fpcdata.forEach((element) => {
            this.receivers.push(element);
          });
        }
      });
      this.getReportingPeriods();
      this.searchReceiverFromSelect();
    });
    this.commonForm
    .get('supplierSearch')
    .valueChanges.pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.searchFromSelect();
    });
    this.commonForm
    .get('receiverSearch')
    .valueChanges.pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.searchReceiverFromSelect();
    });

    this.reportObjects.valueChanges.subscribe((data) => {
      this.checkChangesInForm(data);
    });
  }

  ngAfterViewChecked(): void {
    this.setReferenceToHighLight();
    this.changeDetectorRef.detectChanges();
  }

  canDeactivate() {
    if (
      this.commonForm &&
      this.reportObjects &&
      this.reportObjects.controls.length > 0
    ) {
      return (
        (this.commonForm.dirty || this.reportObjects.dirty) && !this.approval
      );
    } else {
      return false;
    }
  }

  checkChangesInForm(data) {
    data.forEach((element) => {
      const formGroup: UntypedFormGroup = this.getFormGroup(element);

      formGroup
      .get('suppliedFromSearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.searchFromSelectTable(formGroup);
      });

      formGroup
      .get('receiverSearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe((receiverSearch: string) => {
        const index = formGroup.get('index').value;
        this.refreshReceiversForRow(index, receiverSearch);
      });
    });
  }

  refreshReceiversForRow(index: number, receiverSearch: string) {
    const record: QuantityLabelledGood = this.dataSource.data.find((data) => {
      return data.index === index;
    });
    if (record) {
      record.filterReceivers(receiverSearch);
    }
  }

  detectChangesCommonForm() {
    this.commonForm.get('reportedBy').valueChanges.subscribe((value) => {
      this.changeCommonFormValidity();
      this.checkTableColumns();
    });
  }

  checkTableColumns() {
    this.reportObjects.controls.forEach((item) => {
      const suppliedTo = item.get('suppliedTo');
      const suppliedFrom = item.get('suppliedFrom');

      if (this.commonForm.get('reportedBy').value === 1) {
        suppliedTo.setValidators([Validators.required]);
        suppliedFrom.clearValidators();
      } else {
        suppliedFrom.setValidators([Validators.required]);
        suppliedTo.clearValidators();
      }
      suppliedFrom.updateValueAndValidity();
      suppliedTo.updateValueAndValidity();
    });
  }

  changeCommonFormValidity() {
    const supplier = this.commonForm.get('supplier');
    const receiver = this.commonForm.get('receiver');

    if (this.commonForm.get('reportedBy').value === 1) {
      supplier.setValidators([Validators.required]);
      receiver.clearValidators();
    } else {
      receiver.setValidators([Validators.required]);
      supplier.clearValidators();
    }

    supplier.updateValueAndValidity();
    receiver.updateValueAndValidity();
  }

  get getPeriodDate() {
    return this.createPeriod.bind(this);
  }

  createPeriod(item) {
    return item.startDate + ' - ' + item.endDate;
  }

  getSuppliers() {
    this.quantityLabelledGoodService
    .findSupplierByLevel('VERMARKTER')
    .subscribe((data: any) => {
      this.addElementsToLists([this.suppliers, this.filteredSuppliers], data);
    });

    this.quantityLabelledGoodService
    .findSupplierByLevel('ZWISCHENHAENDLER')
    .subscribe((data: any) => {
      this.addElementsToLists([this.suppliers, this.filteredSuppliers], data);
    });

    this.quantityLabelledGoodService
    .findSupplierByLevel('VERARBEITUNGSBETRIEB')
    .subscribe((data: any) => {
      this.addElementsToLists([this.suppliers, this.filteredSuppliers], data);
    });
  }

  searchFromSelectTable(formGroup) {
    if (!this.suppliers) {
      return;
    }
    const searchTerm = formGroup.get('suppliedFromSearch').value;
    const resultSuppliers = [];
    for (const supplier of this.suppliers) {
      if (
        supplier.qsid
        .toString()
        .toUpperCase()
        .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultSuppliers.push(supplier);
      }
    }

    this.filteredSuppliers = resultSuppliers;
  }

  changeNameTable(element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    const guid = this.suppliers.find(
      (data) => data.id === formGroup.get('suppliedFrom').value
    ).companyGuid;

    this.companyService.getCurrentApproved(guid).subscribe((result) => {
      formGroup.get('name').patchValue(result.name);
    });
  }

  changeReceiverNameTable(element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    const supplier = this.receivers.find(
      (data) => data.id === formGroup.get('suppliedTo').value
    );
    const guid = supplier.companyGuid;
    this.companyService.getCurrentApproved(guid).subscribe((result) => {
      formGroup.get('name').patchValue(result.name);
    });
  }

  searchFromSelect() {
    if (!this.suppliers) {
      return;
    }
    const searchTerm = this.commonForm.get('supplierSearch').value;
    const resultSuppliers = [];
    for (const supplier of this.suppliers) {
      if (
        supplier.qsid
        .toString()
        .toUpperCase()
        .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultSuppliers.push(supplier);
      }
    }
    this.filteredSuppliers = resultSuppliers;
  }

  searchReceiverFromSelect() {
    if (!this.receivers) {
      return;
    }
    const searchTerm = this.commonForm.get('receiverSearch').value;
    if (!searchTerm) {
      this.filteredReceivers = this.receivers;
      return;
    }
    const resultReceivers = [];
    for (const receiver of this.receivers) {
      if (receiver.accountNumber) {
        if (
          receiver.accountNumber
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
        ) {
          resultReceivers.push(receiver);
        }
      } else {
        if (
          receiver.qsid
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
        ) {
          resultReceivers.push(receiver);
        }
      }
    }

    this.filteredReceivers = resultReceivers;
  }

  checkSumFormBuilder() {
    this.checkSumForm = this.formBuilder.group({
      checksum: [{value: null, disabled: true}],
    });
  }

  reportFormBuilder() {
    this.form = this.formBuilder.group({
      reports: new UntypedFormArray([]),
    });
  }

  commonFormBuilder() {
    this.commonForm = this.formBuilder.group({
      reportingPeriod: ['', Validators.required],
      reportedBy: [1, Validators.required],
      supplier: [''],
      receiver: [''],
      supplierSearch: [''],
      receiverSearch: [''],
    });
  }

  get formControls() {
    return this.form.controls;
  }

  get reportObjects() {
    return this.formControls.reports as UntypedFormArray;
  }

  getReportingPeriods() {
    this.reportingPeriodService.findAll().subscribe((data: any) => {
      this.existentReportingPeriods = data;
      this.loadTable();
    });
  }

  getGoodGroups() {
    this.goodsGroupsService.findAll().subscribe((data: any) => {
      this.existentGoodsGroups = data;
      this.addGoodsGroupsToOptions();
    });
  }

  addGoodsGroupsToOptions() {
    this.goodsGroupsOptionsLocal = Object.assign([], goodsGroupsOptions);
    this.existentGoodsGroups.forEach((element) => {
      if (element.type === 'PIG') {
        this.goodsGroupsOptionsLocal[0].options.push(element);
      } else if (element.type === 'POULTRY') {
        this.goodsGroupsOptionsLocal[1].options.push(element);
      } else if (element.type === 'CATTLE') {
        this.goodsGroupsOptionsLocal[2].options.push(element);
      }
    });
    this.goodsGroupsOptionsLocal[0].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[0].options
    );
    this.goodsGroupsOptionsLocal[1].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[1].options
    );
    this.goodsGroupsOptionsLocal[2].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[2].options
    );
  }

  getGoodsGroupNameSelect(goodGrupId) {
    let result = '';

    if (this.existentGoodsGroups && this.existentGoodsGroups.length > 0) {
      const goodGroup = this.existentGoodsGroups.find(
        (data) => data.id === goodGrupId
      );

      if (goodGroup) {
        if (this.translationService.getCurrentLanguage() === 'en') {
          result = goodGroup.englishName;
        } else {
          result = goodGroup.germanName;
        }
      }
    }

    return result;
  }

  removeRepeated(data) {
    return data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  }

  loadTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.state.reports && this.state.reports.length > 0) {
      this.editMode = true;
      this.quantityLabelledGoodsApproved = this.state.reports;
      this.patchGeneralForm(this.quantityLabelledGoodsApproved);
    } else if (this.state.task) {
      this.isTask = true;
      if (this.state.task.params) {
        this.draftService
        .retrieveDraftForTask(this.state.task.guid)
        .subscribe((data) => {
          this.quantityLabelledGoodsTask = data;
          this.patchGeneralForm(this.quantityLabelledGoodsTask);
          this.changeRowsState(false);
          this.getApprovedQuantityLabelled();
          if (this.state.task?.params?.eventType === TaskTypesQLG.CANCEL) {
            this.isDeleteTask = true;
            this.addNegativeRows();
          }
        });
      }

      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    } else if (this.state.draft) {
      this.editMode = true;
      this.isDraft = true;
      this.quantityLabelledGoodsApproved =
        this.state.draftObject.quantityLabelledGoods;
      this.patchGeneralForm(this.quantityLabelledGoodsApproved);
    } else {
      this.addEmptyRow();
    }
    this.getCheckSumValue();
  }

  getApprovedQuantityLabelled() {
    this.quantityLabelledGoodsTask.forEach((element) => {
      if (element.id) {
        this.quantityLabelledGoodService
        .findById(element.id)
        .subscribe((data) => {
          this.quantityLabelledGoodsApproved.push(data);
        });
      }
    });
  }

  checkIfNegativeRow(element) {
    return element && (!element.quantity || element.quantity < 0) && !this.isTask;
  }

  patchGeneralForm(quantityLabelledGoods) {
    this.commonForm
    .get('reportedBy')
    .patchValue(parseInt(quantityLabelledGoods[0].reportedBy, 10));
    this.commonForm
    .get('reportingPeriod')
    .patchValue(quantityLabelledGoods[0].reportingPeriodId);

    if (quantityLabelledGoods[0].reportedBy === 1) {
      this.commonForm
      .get('supplier')
      .patchValue(quantityLabelledGoods[0].suppliedFrom);
    } else {
      this.commonForm
      .get('receiver')
      .patchValue(quantityLabelledGoods[0].suppliedTo);
    }

    quantityLabelledGoods.forEach((data) => {
      if (data.reportedBy === 1) {
        data.name = data.suppliedToName;
      } else {
        data.name = data.suppliedFromName;
      }
      this.addRow(data);
    });

    this.getCheckSumValue();
    this.disableReportedByField();
  }

  addNegativeRows() {
    this.reportObjects.controls.forEach((control: UntypedFormGroup) => {
      const negativeControl: UntypedFormGroup = _.cloneDeep(control);
      negativeControl
      .get('quantity')
      .setValue(0 - control.get('quantity').value);

      this.addRow(negativeControl.value);
    });

    this.changeRowsState(false);
    this.getCheckSumValue();
  }

  changeRowsState(state) {
    this.reportObjects.controls.forEach((control) => {
      if (state) {
        control.enable();
      } else {
        control.disable();
      }
      control.get('type').disable();
    });

    if (state) {
      this.commonForm.enable();
    } else {
      this.commonForm.disable();
    }
  }

  disableReportedByField() {
    if (this.editMode || this.isTask) {
      this.commonForm.get('reportedBy').disable();
      this.commonForm.updateValueAndValidity();
    }
  }

  clearName = (event) => {
    this.reportObjects.controls.forEach((item: any, index) => {
      item.get('name').patchValue('');
    });
  };

  changeTaskState(state) {
    this.taskService.updateEditableTask(state);
    this.editableTask = state;
    this.changeRowsState(true);
  }

  addRow(element) {
    const record = new QuantityLabelledGood(this.index, this.receivers);
    record.id = element.id;
    record.suppliedTo = element.suppliedTo;
    record.suppliedFrom = element.suppliedFrom;
    record.name = element.name;
    record.goodsGroupId = element.goodsGroupId;
    record.type = element.type;
    record.quantity = element.quantity;
    record.comment = element.comment;
    record.index = this.index;
    record.draftGuid = element.draftGuid;
    record.draft = element.draft;
    record.draftId = element.draftId;

    this.dataSource.data.push(record);
    this.dataSource.filter = '';
    this.addFormRow(record);
    this.index++;
  }

  getDisabledStatus() {
    let result = false;

    if (!this.commonForm.valid) {
      result = true;
    } else {
      if (
        this.reportObjects.controls &&
        this.reportObjects.controls.length > 0
      ) {
        this.reportObjects.controls.forEach((item) => {
          result = !item.valid;
        });
      } else {
        result = true;
      }
    }

    return result;
  }

  getDisabledStatusDraft() {
    return this.commonForm.get('reportedBy').invalid;
  }

  cancelEdit() {
    if (this.state.task) {
      this.taskService.updateEditableTask(false);
      this.changeRowsState(false);
    } else {
      this.router.navigate(['/quantityLabeledGoods/quantity']);
    }
  }

  addEmptyRow() {
    this.salesEntryService.findAll({allRows: true}).subscribe((data) => {
      const receivers = data['content'];
      this.quantityLabelledGoodService
      .findSupplierByLevel('VERARBEITUNGSBETRIEB')
      .subscribe((fpcdata: any) => {
        if (fpcdata && fpcdata.length > 0) {
          fpcdata.forEach((element) => {
            receivers.push(element);
          });
        }
      });
      const record = new QuantityLabelledGood(this.index, receivers);
      this.dataSource.data.push(record);
      this.dataSource.filter = '';
      this.addFormRow(record);
      this.index++;
    });
  }

  addFormRow(object) {
    this.reportObjects.push(
      this.formBuilder.group({
        id: [{value: object.id, disabled: false}],
        suppliedTo: [{value: object.suppliedTo, disabled: false}],
        receiverSearch: [{value: '', disabled: false}],
        suppliedFrom: [{value: object.suppliedFrom, disabled: false}],
        suppliedFromSearch: [{value: '', disabled: false}],
        name: [{value: object.name, disabled: false}, [Validators.required]],
        goodsGroupId: [
          {value: object.goodsGroupId, disabled: false},
          [Validators.required],
        ],
        type: [{value: object.type, disabled: true}, [Validators.required]],
        quantity: [
          {value: object.quantity, disabled: false},
          [Validators.required],
        ],
        comment: [{value: object.comment, disabled: false}],
        edited: [{value: false, disabled: false}],
        index: [
          {value: object.index, disabled: false},
          [Validators.required],
        ],
        draftGuid: [{value: object.draftGuid, disabled: false}],
        draftId: [{value: object.draftId, disabled: false}],
        draft: [{value: object.draft, disabled: false}],
      })
    );
  }

  addEmptyFormRow(object) {
    this.reportObjects.push(
      this.formBuilder.group({
        id: [{value: null, disabled: false}],
        suppliedTo: [{value: null, disabled: false}],
        suppliedFrom: [{value: null, disabled: false}],
        suppliedFromSearch: [{value: '', disabled: false}],
        name: [{value: null, disabled: false}, [Validators.required]],
        goodsGroupId: [{value: null, disabled: false}, [Validators.required]],
        type: [{value: null, disabled: true}, [Validators.required]],
        quantity: [{value: null, disabled: false}, [Validators.required]],
        comment: [{value: null, disabled: false}],
        edited: [{value: false, disabled: false}],
        index: [
          {value: object.index, disabled: false},
          [Validators.required],
        ],
      })
    );
  }

  getCheckSumValue() {
    let result = 0;
    this.dataSource.data.forEach((element) => {
      result += element.quantity;
    });

    this.checkSumForm.get('checksum').patchValue(result);
  }

  changeQuantity(element) {
    let result = 0;

    this.reportObjects.controls.forEach((item) => {
      result += item.get('quantity').value;
    });

    this.checkSumForm.get('checksum').patchValue(result);
  }

  getFormGroup(element) {
    let formGroup: any = null;

    formGroup = this.reportObjects.controls.find(
      (item) => item.get('index').value === element.index
    );

    return formGroup;
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                quantityLabeledGoodsCreateColumnsConf,
                quantityLabeledGoodsCreateColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = quantityLabeledGoodsCreateColumnsConf;
    this.displayedColumns = quantityLabeledGoodsCreateColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TITLE'
        ),
      }
    );
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.reportObjects.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        const removed = false;
        if (
          this.reportObjects.value[i] &&
          item === this.reportObjects.value[i].index &&
          !removed
        ) {
          this.reportObjects.removeAt(i);
        }
      });
    }
  }

  onClickSave(saveDraft?) {
    this.approval = true;
    const quantityLabeledGoods = [];

    this.reportObjects.controls.forEach((item: any, index) => {
      quantityLabeledGoods.push(item.getRawValue());

      quantityLabeledGoods[index].reportedBy =
        this.commonForm.get('reportedBy').value;
      quantityLabeledGoods[index].reportingPeriodId =
        this.commonForm.get('reportingPeriod').value;

      if (this.commonForm.get('reportedBy').value === 1) {
        quantityLabeledGoods[index].suppliedFrom =
          this.commonForm.get('supplier').value;

        quantityLabeledGoods[index].suppliedTo = item.get('suppliedTo').value;

        quantityLabeledGoods[index].suppliedToName = item.get('name').value;
      } else {
        quantityLabeledGoods[index].suppliedTo =
          this.commonForm.get('receiver').value;

        quantityLabeledGoods[index].suppliedFrom =
          item.get('suppliedFrom').value;

        quantityLabeledGoods[index].suppliedFromName = item.get('name').value;
      }
    });

    if (saveDraft) {
      if (this.editMode && this.isDraft) {
        this.updateDraft(quantityLabeledGoods);
      } else {
        this.saveAsDraft(quantityLabeledGoods);
      }
    } else {
      const taskId =
        this.state.task && this.state.task.guid ? this.state.task.guid : null;
      if (
        this.state.report ||
        (quantityLabeledGoods[0] && quantityLabeledGoods[0].id)
      ) {
        this.updateQuantityLabelledGoods(quantityLabeledGoods, taskId);
      } else {
        this.createQuantityLabelledGoods(quantityLabeledGoods, taskId);
      }
    }
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe((data) => {
      /**
       * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
       * to much of empty function. So this comment is to makes the sonarqube happy.
       * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
       */
    });
  }

  saveAsDraft(quantityLabeledGoods) {
    const request = {
      entityId: null,
      entityType: this.ENTITY_TYPE,
      element: quantityLabeledGoods,
      id: null,
      entityGuid: null,
    };
    this.saveUserDraft(request);
  }

  updateDraft(quantityLabeledGoods) {
    const request = {
      entityId: null,
      entityType: this.ENTITY_TYPE,
      element: quantityLabeledGoods,
      id: this.state.draftObject.draftGuid,
      entityGuid: null,
    };
    this.saveUserDraft(request);
  }

  saveUserDraft(request) {
    this.draftService.saveUserDraft(request).subscribe(
      () => {
        if (this.editMode) {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'ENTITIES.ANIMAL-WELFARE.QUANTITY-LABELLED-GOOD'
              ),
            }
          );
        } else {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'ENTITIES.ANIMAL-WELFARE.QUANTITY-LABELLED-GOOD'
              ),
            }
          );
        }
        setTimeout(() => {
          this.router.navigate(['/quantityLabeledGoods/quantity']);
        }, 3000);
      },
      (error) => {
        if (error.error) {
          this.customErrorHandler.handleError(
            error.error,
            this.translateService.instant(
              'ENTITIES.ANIMAL-WELFARE.QUANTITY-LABELLED-GOOD'
            )
          );
        }
      }
    );
  }

  onGoodGroupChange($event, element) {
    const formGroup = this.getFormGroup(element);

    if (formGroup) {
      formGroup.get('type').patchValue(this.getGoodGroupType($event.value));
      formGroup.get('type').disable();
    }
  }

  getGoodGroupType(id) {
    const goodGroup = this.existentGoodsGroups.find((data) => data.id === id);
    return goodGroup.type.toLowerCase();
  }

  updateQuantityLabelledGoods(quantityLabeledGoods, taskId) {
    this.quantityLabelledGoodService
    .updateQuantityLabelledGood(quantityLabeledGoods, '', taskId)
    .subscribe((result) => {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        {
          data: this.translateService.instant(
            'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TITLE'
          ),
        }
      );

      setTimeout(() => {
        this.router.navigate(['/quantityLabeledGoods/quantity']);
      }, 3000);
    });
  }

  createQuantityLabelledGoods(quantityLabeledGoods, taskId) {
    this.quantityLabelledGoodService
    .createQuantityLabelledGood(quantityLabeledGoods, '', taskId)
    .subscribe((result) => {
      this.notificationService.showToast(
        'NOTIFICATION.CREATED',
        this.notificationService.MESSAGE_TYPE.SUCCESS,
        {
          data: this.translateService.instant(
            'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TITLE'
          ),
        }
      );

      if (this.isDraft && this.state.draft) {
        this.deleteDraft(this.state.draftObject.draftGuid);
      }

      setTimeout(() => {
        this.router.navigate(['/quantityLabeledGoods/quantity']);
      }, 3000);
    });
  }

  isFieldRequired(element, field) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);

    if (formGroup) {
      const control = formGroup.get(field);
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (!validator) {
          return false;
        }
        return validator.required;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isFieldRequiredCommonForm(field) {
    if (this.commonForm) {
      const control = this.commonForm.get(field);
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (!validator) {
          return false;
        }
        return validator.required;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasSupplierOrPurchasedChanged(fieldName) {
    const reportField =
      fieldName === 'supplier' ? 'suppliedFrom' : 'suppliedTo';
    if (this.state.reports && this.commonForm.get(fieldName).value) {
      if (
        this.state.reports[0][reportField] !==
        this.commonForm.get(fieldName).value
      ) {
        return true;
      }
    } else if (this.state.task && this.commonForm.get(fieldName).value) {
      if (
        this.quantityLabelledGoodsTask[0][reportField] !==
        this.commonForm.get(fieldName).value
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * New function to check if needs to be highlighted. The old function to this feature has
   * some "not good pratices", like condictional loops without reason.
   * Ex: If I know what is the line changing, is not necessary to pass all the lines and
   * search again inside the lines to get the correct one.
   *
   */
  checkHighLightField(fieldName: string, index: number) {
    let oldValue;
    let newValue;
    if (
      this.isDraft ||
      (this.quantityLabelledGoodsTask.length === 0 &&
        this.quantityLabelledGoodsApproved.length === 0)
    ) {
      return false;
    } else {
      if (fieldName === 'name') {
        const reportedBy = this.commonForm.get('reportedBy').value;
        fieldName = reportedBy === 2 ? 'suppliedFrom' : 'suppliedTo';
      }
      oldValue =
        this.referenceValueToCheckHighlights &&
        this.referenceValueToCheckHighlights[index] &&
        this.referenceValueToCheckHighlights[index][fieldName]
          ? this.referenceValueToCheckHighlights[index][fieldName]
          : null;
      if (fieldName === 'reportingPeriodId') {
        newValue = this.commonForm.get('reportingPeriod').value;
      } else {
        const lineToCheck = this.reportObjects.controls[index] as UntypedFormGroup;
        newValue = lineToCheck.controls[fieldName]?.value;
      }
      return this.setHighLight(oldValue, newValue);
    }
  }

  private setHighLight(oldValue: any, newValue: any): boolean {
    if (!oldValue && newValue?.toString() === '') {
      return false;
    } else return oldValue?.toString() !== newValue?.toString();
  }

  private setReferenceToHighLight() {
    if (this.isTask) {
      if (this.quantityLabelledGoodsApproved?.length === 0) {
        this.referenceValueToCheckHighlights = this.quantityLabelledGoodsTask;
      } else {
        this.referenceValueToCheckHighlights =
          this.quantityLabelledGoodsApproved;
      }
    } else if (this.quantityLabelledGoodsApproved?.length > 0) {
      this.referenceValueToCheckHighlights = this.quantityLabelledGoodsApproved;
    }
  }

  private addElementsToLists(lists: any[], newElements: any[]) {
    if (lists?.length > 0 && newElements?.length > 0) {
      lists.forEach((list) => {
        list.push(...newElements);
      });
    }
  }
}
