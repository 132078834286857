import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class SalesFiguresService {
  private readonly serviceUrl: string;
  private readonly searchAPIUrl: string;
  private readonly taskServiceUrl: string;
  private pageIndex = '0';
  private pageSize = '20';
  private sortField = 'id';
  private sortDirection = 'ASC';
  private filter: HttpParams = new HttpParams();
  searchStarted = new EventEmitter<boolean>();
  newValuesEmitter = new EventEmitter();
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.liquidityPlanService;
    this.searchAPIUrl = environment.settings.searchAPIUrl;
    this.taskServiceUrl = environment.settings.taskService;
  }

  create(request) {
    const json = {
      comment: '',
      taskId: null,
      salesFigures: request.objects,
    };

    const url = this.serviceUrl + 'sales-figures';
    return this.http.post(url, json, this.buildHeaders());
  }

  cancel(comment, guids) {
    const json = {
      comment,
      taskId: null,
      guids,
    };

    const url = this.serviceUrl + 'sales-figures/cancel';
    return this.http.post(url, json, this.buildHeaders());
  }

  update(request, guid) {
    const json = {
      comment: '',
      guid,
      taskId: null,
      salesFigures: request.objects,
    };

    const url = this.serviceUrl + 'sales-figures';
    return this.http.put(url, json, this.buildHeaders());
  }

  updateDraft(request, guid, taskId, params) {
    const json = {
      comment: '',
      guid,
      taskId,
      param: params,
      salesFigures: request.objects,
    };

    const url = this.serviceUrl + 'sales-figures/draft';
    return this.http.put(url, json, this.buildHeaders());
  }

  list(): Observable<any[]> {
    const config = this.buildHeaders();
    config['params'] = this.filter
      .append('pageIndex', this.pageIndex)
      .append('pageSize', this.pageSize)
      .append('sortField', this.sortField)
      .append('sortDirection', this.sortDirection);
    const url = this.searchAPIUrl + 'participation-fee/sales-figures';
    return this.http.get<any[]>(url, config);
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: any, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  resetFilters() {
    this.pageIndex = '0';
    this.pageSize = '20';
    this.sortField = 'id';
    this.sortDirection = 'ASC';
    this.filter = new HttpParams();
  }

  setPaginator(amountPerPage, pageNr) {
    this.pageSize = amountPerPage;
    this.pageIndex = pageNr;
  }

  setFilter(value: any) {
    this.searchStarted.emit(true);
    this.filter = new HttpParams();
    const keysOfObject = Object.keys(value);
    for (const key of keysOfObject) {
      if (value[key] && value[key] !== '') {
        this.filter = this.filter.append(key, value[key]);
      }
    }
    return this.list();
  }

  emitSearchFinishedEvent() {
    this.searchStarted.emit(false);
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }

  sort(choice, direction) {
    this.sortField = choice;
    this.sortDirection = direction;
  }
}
