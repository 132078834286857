<mat-tab-group style="width: 100%">
  <mat-tab label="{{ 'OPEN-TASK.DUNNING.LABEL-ADD' | translate }}" *ngIf="data.create">
    <div class="title-form">
      <h5 translate>OPEN-TASK.DUNNING.TITLE-ADD</h5>
    </div>
    <div class="input-wrapper form-container">
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.DUNNING.DATE' | translate }}</mat-label>
            <input
              autocomplete="off"
              [matDatepicker]="pickerFrom"
              matInput
              placeholder="{{ 'OPEN-TASK.DUNNING.DATE' | translate }}"
              name="dunningDate"
              [(ngModel)]="dunningDate"
              formControlName="dunningDate"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="filter-input button-container">
      <button mat-button class="custom-button-cancel" style="margin-right: 10px" [mat-dialog-close]>
        {{ 'cancel' | translate }}
      </button>
      <button mat-button class="custom-button" [disabled]="!form.valid" (click)="saveDunningDateCloseDialog()">
        {{ 'OPEN-TASK.DUNNING.ADD' | translate }}
      </button>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'OPEN-TASK.DUNNING.LABEL-DELETE' | translate }}">
    <div class="height-tab" *ngIf="data.dunningInfo.length > 0">
      <div class="full-width">
        <div class="full-width-list-item" *ngFor="let date of data.dunningInfo; let i = index">
          <span matLine>
            {{ date.dunningDate | date: dateFormat }}
          </span>
          <div class="buttons" *ngIf="data.create">
            <button mat-icon-button (click)="removeDunning(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.dunningInfo.length == 0" class="height-tab">
      <div class="center-text">
        <span translate>OPEN-TASK.DUNNING.NO-DUNNING</span>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
