import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TranslationValueSaveRequest } from 'src/app/shared/services/translation/translation-value-save-request';

@Component({
  selector: 'app-translation-edit',
  templateUrl: './translation-edit.component.html',
  styleUrls: ['./translation-edit.component.less'],
})
export class TranslationEditComponent implements OnInit, DirtyComponent {
  entityName = 'app-translation-edit';
  errorRequired = 'error-message-required';
  translationEditForm: UntypedFormGroup;
  isDirty = false;
  approval = false;
  taskView = false;
  isTask = false;
  editableTask = false;
  showBuSpecificTable = false;
  busineesUnits: any[] = [];
  filteredKeys: any[] = [];
  state;
  createdLanguages;
  dataSource = new MatTableDataSource<any>([]);
  translationApproved;
  translationTask;
  maxLengthInputs = 1000;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private buService: BusinessUnitService,
    private translationService: TranslationService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private taskService: TaskService,
    private draftService: DraftService,
    private activatedRoute: ActivatedRoute
  ) {
    this.translationEditFormBuilder();
  }

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    this.state = window.history.state;
    if (
      !this.state.translationValues &&
      !this.state.task &&
      snapshot.params.guid
    ) {
      this.getDataByGuid(snapshot.params.guid);
    } else if (this.state.task) {
      this.taskView = true;
      this.isTask = true;
      this.loadTaskData();
    } else {
      this.getAllBusinessUnits();
      this.showOrHideBUSpecificTable();
      this.loadLanguages(this.state);
    }
  }

  translationEditFormBuilder() {
    this.translationEditForm = this.formBuilder.group({
      key: [{ value: null, disabled: true }, Validators.required],
      german: [{ value: null, disabled: false }, Validators.required],
      english: [{ value: null, disabled: false }, Validators.required],
      deviatingBU: [{ value: null, disabled: true }],
    });
  }

  canDeactivate() {
    return this.translationEditForm.dirty && !this.approval;
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.translationEditForm.dirty;
  }

  checkIfDraftExists() {
    this.draftService
      .approvalDraftExistsForEntityId(this.translationApproved.guid)
      .subscribe(
        (doesDraftExist) => {
          if (doesDraftExist) {
            this.translationEditForm.disable({
              onlySelf: true,
              emitEvent: false,
            });
            this.notificationService.showToastMessage(
              'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
              this.notificationService.MESSAGE_TYPE.INFO
            );
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  getDataByGuid(guid) {
    this.translationService.getTranslationsByGuid(guid).subscribe((data) => {
      this.getAllBusinessUnits();
      this.showOrHideBUSpecificTable();
      this.loadLanguages({ translationValues: data });
    });
  }

  loadLanguages(translationValues) {
    this.translationService.getCreatedLanguages().subscribe((data) => {
      if (data && data.length > 0) {
        this.createdLanguages = data;
        this.buildNewFormElements(data, translationValues);
      }
    });
  }

  buildNewFormElements(values, translationValues) {
    const keys = [];
    values.forEach((val) => {
      keys.push(val.name);
    });

    this.filteredKeys = [];
    keys.forEach((key) => {
      if (key !== 'English' && key !== 'German') {
        this.filteredKeys.push(key.toLowerCase());
      }
    });

    this.filteredKeys.forEach((element) => {
      this.translationEditForm.addControl(element, new UntypedFormControl(''));
    });

    if (this.state.task) {
      this.patchValues(translationValues);
    } else {
      this.patchValues(translationValues.translationValues);
    }
  }

  patchValues(translationValues) {
    this.translationEditForm.get('key').patchValue(translationValues.key);
    if (translationValues.translationValues) {
      this.translationEditForm
        .get('german')
        .patchValue(translationValues.translationValues.german);
      this.translationEditForm
        .get('english')
        .patchValue(translationValues.translationValues.english);
      this.filteredKeys.forEach((fk) => {
        this.translationEditForm
          .get(fk)
          .patchValue(translationValues.translationValues[fk]);
      });
    }
    if (this.state.task) {
      this.translationTask = translationValues;
      this.translationService
        .getApprovedTranslations(translationValues.key)
        .subscribe((data) => {
          this.translationApproved = data;
        });
    } else {
      this.translationApproved = translationValues;
      this.checkIfDraftExists();
    }
  }

  loadTaskData() {
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        this.loadLanguages(data);
      });
  }

  showFilteredKey(filteredKey) {
    if (this.translationEditForm.get(filteredKey)) {
      return true;
    } else {
      return false;
    }
  }

  getControlObject(filteredKey) {
    return this.translationEditForm.get(filteredKey);
  }

  getApprovedValue(filteredKey) {
    return this.translationApproved.translationValues[filteredKey];
  }

  getTaskValue(filteredKey) {
    return this.translationTask.translationValues[filteredKey];
  }

  makeEditable() {
    this.isTask = false;
    this.editableTask = true;
    this.taskService.updateEditableTask(true);
  }

  getLanguages() {
    const languageName = {};
    this.createdLanguages.forEach((lang) => {
      const name = lang.name.toString().toLowerCase();
      const langValue = this.translationEditForm.getRawValue()[name];
      languageName[name] = langValue;
    });
    return languageName;
  }

  save() {
    this.approval = true;

    if (this.state.operation === 'update' || this.state.task) {
      const dialog = this.matDialogService.open(ConfirmationModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.translationService
            .editKey(this.getTranslationValueSaveRequest(result.comment))
            .subscribe(
              (_) => {
                this.notificationService.showToast(
                  'NOTIFICATION.CREATED',
                  this.notificationService.MESSAGE_TYPE.SUCCESS,
                  {
                    data: this.translateService.instant('ENTITIES.TRANSLATION'),
                  }
                );
                this.changeEditMode();
                if (this.state.task) {
                  setTimeout(() => {
                    this.router.navigateByUrl('/tasks');
                  }, 1500);
                }
              },
              (error) => {
                this.handleError(error);
                this.approval = false;
              }
            );
        } else {
          this.approval = false;
        }
      });
    }
  }

  getTranslationValueSaveRequest(comment: string): TranslationValueSaveRequest {
    return {
      key: this.translationEditForm.getRawValue().key,
      translationValues: this.getLanguages(),
      approvalTaskId: this.state.task ? this.state.task.guid : null,
      translationKeyId: this.state.task
        ? this.state.task.params.id
        : this.state.translationValues.guid,
      comment: comment,
    };
  }

  changeEditMode() {
    if (this.state.task) {
      this.isTask = true;
      this.editableTask = false;
      this.taskService.updateEditableTask(false);
      this.loadTaskData();
    } else {
      this.router.navigateByUrl(
        '/systemConfiguration/translationManagement/translationList',
        {
          state: { filteredKeys: this.state.filteredKeys },
        }
      );
    }
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }

  getAllBusinessUnits() {
    this.buService
      .getAllBusinessUnits('id', 'asc', 1, 99999)
      .subscribe((data) => {
        this.busineesUnits = data.content;
      });
  }

  showOrHideBUSpecificTable() {
    this.translationEditForm
      .get('deviatingBU')
      .valueChanges.subscribe((value) => {
        if (value.length > 0) {
          this.showBuSpecificTable = true;
        }
      });
  }
}
