import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { isEmpty } from 'src/app/utils/object-util';
import { UserService } from 'src/app/shared/services/user/user.service';
import { BusinessTransactionCodeService } from 'src/app/shared/services/business-transaction-code/business-transaction-code.service';
import { DraftService } from '../../../shared/services/draft/draft.service';

@Component({
  selector: 'app-btc-create',
  templateUrl: './btc-create.component.html',
  styleUrls: ['./btc-create.component.less'],
})
export class BtcCreateComponent implements OnInit, DirtyComponent {
  entityName = 'business-transaction-code';
  btcForm: UntypedFormGroup;
  edit = false;
  draftId: number;
  approval = false;
  draftSave = false;
  operationUser: any;
  userList: any;
  editMode: any;
  btcApproved: any;
  btcs: any[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private businessTransactionCodeService: BusinessTransactionCodeService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private userService: UserService,
    private draftService: DraftService
  ) {
    this.btcForm = this.formBuilder.group({
      id: [''],
      code: ['', Validators.required],
      name: ['', Validators.required],
    });

    this.businessTransactionCodeService
      .getBusinessTransactionCodes()
      .subscribe((data) => {
        this.btcs = data;
      });
  }

  ngOnInit() {
    const state = window.history.state;
    if (state.btc) {
      this.btcApproved = state.btc;
      this.edit = true;
      this.btcForm.patchValue(this.btcApproved);
      if (!state.btc.draft) {
        this.btcForm.get('code').disable();
      } else {
        this.draftId = state.btc.draftId;
      }

      this.draftService
        .retrieveLocalEntityDraft(this.btcApproved.id)
        .subscribe((draft) => {
          this.btcForm.disable({
            onlySelf: true,
            emitEvent: false,
          });
          this.notificationService.showToastMessage(
            'GENERAL-ENTITY.CREATE.MESSAGES.TOAST-MESSAGE-DRAFT-EXISTS',
            this.notificationService.MESSAGE_TYPE.INFO
          );
        });
    }

    this.btcForm.get('code').valueChanges.subscribe((data) => {
      this.checkExistingCode(data);
    });
  }

  checkExistingCode(data) {
    const result = this.btcs.find((btc) => btc.code === data);
    if (result) {
      this.btcForm.get('code').setErrors({ alreadyExists: true });
    } else {
      this.btcForm.get('code').setErrors(null);
    }
  }

  onClickApproval() {
    this.approval = true;
    const json = {
      id: this.btcForm.value.id,
      code: this.btcForm.get('code').value,
      name: this.btcForm.value.name,
    };
    if (!this.edit || this.draftId) {
      this.businessTransactionCodeService
        .saveBusinessTransactionCode(json)
        .subscribe((_) => {
          this.successSaveHandler();
        }, this.customErrorHandler._errorHandler('ENTITIES.BTC'));
    } else {
      this.businessTransactionCodeService
        .updateBusinessTransactionCode(json)
        .subscribe((_) => {
          this.successSaveHandler();
        }, this.customErrorHandler._errorHandler('ENTITIES.BTC'));
    }
  }

  canDeactivate() {
    return this.btcForm.dirty && !this.approval && !this.draftSave;
  }

  changeEditMode() {
    this.router.navigateByUrl('/systemConfiguration/accountStatement/btc', {
      state: {},
    });
  }

  backToList() {
    this.router.navigateByUrl('systemConfiguration/bu');
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.btcForm.dirty;
  }

  getUserInfo() {
    let userName;
    if (this.operationUser) {
      userName = this.operationUser;
    }

    if (userName && userName.length > 0) {
      const user = this.userService.getAccountManagerName(
        userName,
        this.userList
      );

      return !isEmpty(user)
        ? user.lastName + ', ' + user.firstName + ' (' + user.username + ')'
        : 'system-user';
    } else {
      return userName;
    }
  }

  saveAsDraft() {
    const draftId = this.draftId;
    const element = this.btcForm.value;
    const request = {
      entityId: element.id,
      entityType: 'BUSINESS_TRANSACTION_CODE',
      element,
      id: draftId,
      entityGuid: this.btcApproved ? this.btcApproved.id : null,
    };
    const update = !!draftId;
    this.draftService.saveUserDraft(request).subscribe((_) => {
      this.draftSave = true;
      if (update) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      } else {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
      setTimeout(() => {
        this.changeEditMode();
      }, 1500);
    }, this.customErrorHandler._errorHandler('ENTITIES.BTC'));
  }

  private successSaveHandler() {
    this.approval = true;
    if (this.draftId) {
      this.draftService.deleteUserDraft(this.draftId).subscribe();
    }
    this.notificationService.showToast(
      'NOTIFICATION.CREATED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      { data: this.translateService.instant('ENTITIES.BTC') }
    );
    setTimeout(() => {
      this.changeEditMode();
    }, 1500);
  }
}
