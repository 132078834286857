import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  AccountingSharedDataService
} from 'src/app/shared/services/accounting/accounting-shared-data.service';
import {
  AccountingService
} from 'src/app/shared/services/accounting/accounting.service';
import {
  BusinessUnitService
} from 'src/app/shared/services/business-unit/business-unit.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
  TableActionIconsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import {
  NotificationService
} from 'src/app/shared/services/notification/notification.service';
import { MatDrawer } from '@angular/material/sidenav';
import {
  OpenItemService
} from 'src/app/shared/services/open-item/open-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BookingCodeService
} from 'src/app/shared/services/booking-code/booking-code.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { BehaviorSubject, noop, Subject, Subscription } from 'rxjs';
import {
  TransactionSearchFilter
} from '../accounting-overview-functional-buttons/accounting-overview-functional-buttons.component';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import {
  accountingOpenItemColumns
} from 'src/app/in-memory-data/accounting/table-columns';
import {
  accountingOpenItemColumnsConf
} from 'src/app/in-memory-data/accounting/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import {
  accountingBillingTransactionListColumnsConf
} from 'src/app/in-memory-data/accounting/animal-welfare/table-columns-configuration';
import {
  accountingBillingTransactionListColumns
} from 'src/app/in-memory-data/accounting/animal-welfare/table-columns';
import {
  AccountingTransactionListExpandedComponent
} from '../accounting-transactions-expanded/accounting-transaction-list-expanded.component';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountingTransactionTabType
} from '../accounting-transaction-tab-type';
import { TransactionStatus } from '../transaction-status';
import { TransactionBookingStandart } from '../transaction-booking-standard';
import {
  DefaultTableComponent
} from '../../../shared/tables/default-table/default-table.component';

@Component({
  selector: 'app-accounting-transaction-list',
  templateUrl: './accounting-transaction-list.component.html',
  styleUrls: ['./accounting-transaction-list.component.less'],
})
export class AccountingTransactionListComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;

  constructor(
    private accountingService: AccountingService,
    private businessUnitService: BusinessUnitService,
    private notificationService: NotificationService,
    private accountingSharedDataService: AccountingSharedDataService,
    private tablesService: TablesService,
    private matDialogService: MatDialog,
    private openItemService: OpenItemService,
    private router: Router,
    private bookingCodeService: BookingCodeService,
    private route: ActivatedRoute,
    private userService: UserService,
    private draftService: DraftService,
    private translateService: TranslateService
  ) {
    this.tablesService.currentSharedSelectedRows.subscribe(
      (selectedRows) => (this.selectedRows = selectedRows)
    );

    this.accountingSharedDataService.suscriptionEmitter =
      this.accountingSharedDataService.eventEmiterFunction.subscribe((data) => {
        this.eventReceiver(data);
      });
  }
  @Input() searchEvent: EventEmitter<object>;
  @Input() currentTab?: AccountingTransactionTabType;
  @Input() accountingData;
  @Input() hasThreeDots: boolean;
  @Input() searchTerm: BehaviorSubject<TransactionSearchFilter>;
  @Input() tableName = '';
  @Input() searchString;
  @Input() allocationsAllowed = false;
  @Input() isLedgerAccount: boolean;

  searchConfig = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
  };
  currentConfig = {};
  subject = new Subject();

  entityName = 'accounting-transaction-list';
  configurationKeyForNonAWOpenTransaction =
    'accounting_transaction_non_aw_table_open_transactions';
  configurationKeyForNonAWClosedTransaction =
    'accounting_transaction_non_aw_table_closed_transactions';
  configurationKeyForNonAWCreditTransaction =
    'accounting_transaction_non_aw_table_credit_transactions';
  configurationKeyForNonAWMergedTransactionTable =
    'accounting_transaction_non_aw_table_all_transactions';
  configurationKeyForAWOpenTransaction =
    'accounting_transaction_animal_welfare_table_open_transactions';
  configurationKeyForAWClosedTransaction =
    'accounting_transaction_animal_welfare_table_closed_transactions';
  configurationKeyForAWCreditTransaction =
    'accounting_transaction_animal_welfare_table_credit_transactions';
  configurationKeyForAWMergedTransactionTable =
    'accounting_transaction_animal_welfare_table_all_transactions';
  configurationKey: any;
  columns: any;
  displayedColumns: string[];
  transactionData: any;
  listOfActionButtons: any[] = [];
  listOfActionIcons: any[] = [];
  listOfActionIconsForAccountingOpenTransactionAW: TableActionIconsConfiguration[] =
    [
      {
        name: 'allocationDraft',
        icon: 'all_inclusive',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
        function: (param) => {
          this.editAllocationPaymentDraft(param);
        },
        class: 'material-icons',
      },
    ];
  listOfActionIconsForAccountingNonAW: TableActionIconsConfiguration[] = [
    {
      name: 'allocationDraft',
      icon: 'all_inclusive',
      translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
      function: (param) => {
        this.editAllocationPaymentDraft(param);
      },
      class: 'material-icons',
    },
  ];
  listOfActionButtonsForOpenTransactionsAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'transactionInformationAndHistory',
        permission: null,
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
      {
        name: 'allocation',
        permission: 'acc.a.c',
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
        function: () => {
          this.allocationAction();
        },
        selectionType: DefaultTableSelectionType.singleAndMultiple,
        specialDisable: () => {
          return this.checkDisableAllocation();
        },
      },
    ];
  listOfActionButtonsForClosedTransactionsNonAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'transactionInformationAndHistory',
        permission: null,
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
    ];
  listOfActionButtonsForOpenTransactionsNonAW: TableActionButtonsConfiguration[] =
    [
      {
        name: 'allocation',
        permission: 'acc.a.c',
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
        function: () => {
          this.allocationAction();
        },
        selectionType: DefaultTableSelectionType.singleAndMultiple,
        specialDisable: () => {
          return this.checkDisableAllocation();
        },
      },
      {
        name: 'transactionInformationAndHistory',
        permission: '',
        icon: '',
        translationKey: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.single,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
      {
        name: 'commentsAndAttachments',
        permission: '',
        icon: '',
        translationKey: 'TRANSACTION.COMMENTS_AND_ATTACHMENTS',
        function: (param) => {
          this.getValuesForSplitScreen(param);
        },
        selectionType: DefaultTableSelectionType.multiple,
        specialDisable: () => {
          return this.checkDisable();
        },
      },
    ];
  dataLoaded = false;
  loaderSpinner = true;
  objectionAmountForMultiple = false;
  hasTransaction = false;
  openDrawer = false;
  selectedRows;
  selectedTransactionData = this.openItemService.sharedSplitScreenData;
  state;
  transactionCodes;
  allObjectionCodes;
  @ViewChild('drawer') drawer: MatDrawer;
  routeParamId;
  routeParamType;
  lastSearchTerm;
  expandedComponent;
  expanded: any;
  allocationDrafts = [];
  allocationDraftData: any = [];
  selectedDraftTransactionsList: any = [];
  subscriptions: Subscription[] = [];

  getCustomClass = (element): any => {
    if (this.routeParamType !== 'LEDGER_ACCOUNT') {
      const customClasses = [];
      if (this.checkAllocated(element)) {
        customClasses.push('allocated');
      }
      if (this.checkFullyAllocation(element)) {
        customClasses.push('fully-allocated');
      }

      return customClasses;
    }
  };
  getCustomColumnClass = (element, column): any => {
    const customClasses = [];
    if (column === 'amount' || column === 'openAmount') {
      if (this.getAmountColumnTextColor(element)) {
        customClasses.push(this.getAmountColumnTextColor(element));
      }
    }
    return customClasses;
  };

  getNegatedValue = (element, column): any => {
    let negatedValue = '';
    if (column === 'amount' || column === 'openAmount') {
      if (this.addMinusForAmountColumn(element)) {
        negatedValue = this.addMinusForAmountColumn(element);
      }
    }
    return negatedValue;
  };

  selectionAction = (): any => {
    return this.sumAllBalancesSelected();
  };

  ngOnInit() {
    this.state = window.history.state;
    this.expandedComponent = AccountingTransactionListExpandedComponent;
    this.routeParamId = this.route.snapshot.paramMap.get('id');
    this.routeParamType = this.route.snapshot.paramMap.get('type');
    this.expanded = this.routeParamType !== 'LEDGER_ACCOUNT';
    this.getCurrentBusinessUnitAndTab();
    this.generateListOfActionButtons();
    this.subscriptions.push(
      this.searchTerm.subscribe((value) => {
        if (
          value &&
          value.freeFormSearch != null &&
          value !== this.lastSearchTerm
        ) {
          if (value.freeFormSearch === '') {
            this.accountingService.resetFilters();
          }
          this.searchConfig.filterText = value.freeFormSearch ?? '';
          this.loadData();
          this.lastSearchTerm = value;
        }
      })
    );

    this.loadData();
  }

  ngAfterViewInit() {
    this.getInitialValues();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getInitialValues() {
    if (
      this.selectedRows &&
      this.selectedRows.length > 0 &&
      this.selectedRows[0].clientId
    ) {
      const clientId = this.selectedRows[0].clientId;
      this.bookingCodeService
        .listAllForClient(clientId)
        .subscribe((transactionCodes) => {
          this.transactionCodes = transactionCodes;
        });
    }
  }

  checkDisableAllocation() {
    if (!this.allocationsAllowed) {
      return true;
    }
    let result = false;
    if (this.selectedRows && this.selectedRows.length > 0) {
      this.selectedRows.forEach((row) => {
        if (row.allocationDraft && result === false) {
          result = true;
        }
      });
    }
    return result;
  }

  checkDisable() {
    return false;
  }

  eventReceiver(functionName) {
    switch (functionName) {
      case 'paymentAllocation':
        this.allocationAction();
        break;
      case 'transactionHistory':
        const row = this.transactionData.content.find(
          (item) => this.selectedRows[0].id === item.id
        );
        this.getValuesForSplitScreen(row);
        break;
      case 'deletePaymentAllocationDraft':
        this.loadData();
        break;
      default:
        break;
    }
  }

  allocationAction() {
    let result = 0;
    this.selectedRows.forEach((item) => {
      if (item.creditDebitCode === 'C') {
        result += item.amount;
      } else if (item.creditDebitCode === 'D') {
        result -= item.amount;
      }
    });

    if (result === 0) {
      const transactionIds = [];
      this.selectedRows.forEach((element) => {
        transactionIds.push(element.id);
      });

      const request = {
        transactionIds,
        clientId: null,
      };

      this.openItemService.getPaymentAllocationInformation(request).subscribe(
        (data) => {
          if (data == null) {
            this.notificationService.showToast(
              'NOTIFICATION.SUCCESFULL-DEBTOR-ALLOCATION',
              this.notificationService.MESSAGE_TYPE.SUCCESS
            );
            this.loadData();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.router.navigateByUrl(
        '/accounting/overview/' +
          this.routeParamId +
          '/' +
          this.routeParamType +
          '/paymentAllocation',
        {
          state: {
            success: true,
            selectedDebtors: this.selectedRows,
          },
        }
      );
    }
  }

  addDraftGuid(draftGuid, transactions) {
    return (entry) => {
      return {
        transactionId: entry.id,
        draftId: draftGuid,
        transactions,
      };
    };
  }

  editAllocationPaymentDraft(param) {
    const entity = 'ALLOCATION_PAYMENT';
    this.dataLoaded = false;
    this.draftService
      .retrieveDraftsByEntityIdAndType(this.routeParamId, entity)
      .subscribe((data) => {
        if (data) {
          data.some((draft) => {
            const object = JSON.parse(draft.draftJson);
            draft.numberTransactions = object.transactions.length;
            let result = 0;
            object.transactions.forEach((transaction) => {
              if (transaction.creditDebitCode === 1) {
                result += transaction.amount;
              } else if (transaction.creditDebitCode === 2) {
                result -= transaction.amount;
              }
            });
            draft.balance = result;
            draft.currency = object.transactions[0].currency;

            const transactionDraftArray = object.transactions.map(
              this.addDraftGuid(draft.guid, object.transactions)
            );
            transactionDraftArray.forEach((element) => {
              this.allocationDrafts.push(element);
            });
            if (
              object.transactions.findIndex(
                (transaction) => param.id === transaction.id
              ) > -1
            ) {
              this.selectedDraftTransactionsList = object.transactions;
              return true;
            }
          });
          this.allocationDraftData.content = data;
          this.router.navigateByUrl(
            '/accounting/overview/' +
              this.routeParamId +
              '/' +
              this.routeParamType +
              '/paymentAllocation',
            {
              state: {
                success: true,
                selectedDebtors: this.selectedDraftTransactionsList,
                draftGuid: param.draftId,
              },
            }
          );
        }
      });
  }

  async checkCurrency() {
    let currency;
    let sameCurrency = true;

    this.selectedRows.forEach((item) => {
      if (currency === undefined) {
        currency = item.currency;
      }

      if (currency !== item.currency && sameCurrency) {
        sameCurrency = false;
      }
    });

    return sameCurrency;
  }

  getBalance() {
    let result = 0;

    this.selectedRows.forEach((item) => {
      if (item.creditDebitCode === 'D') {
        result += item.openAmount;
      } else if (item.creditDebitCode === 'C') {
        result -= item.openAmount;
      }
    });

    return result;
  }

  createAllocation() {
    const transactionIds = [];
    this.selectedRows.forEach((element) => {
      transactionIds.push(element.id);
    });

    const request = {
      transactionIds,
    };
    this.openItemService.getPaymentAllocationInformation(request).subscribe(
      (data) => {
        if (data == null) {
          this.notificationService.showToast(
            'NOTIFICATION.SUCCESFULL-DEBTOR-ALLOCATION',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  getValuesForSplitScreen(param) {
    this.hasTransaction = true;
    const listOfSelectedTransactions: any[] = [];
    const row = this.transactionData.content.find(
      (item) => this.selectedRows[0].id === item.id
    );
    param.clientId = row.clientId;
    if (this.selectedRows.length > 1) {
      this.selectedRows.forEach((selectedRow) => {
        listOfSelectedTransactions.push(selectedRow);
      });
    }
    param.selectedTransactions = listOfSelectedTransactions;
    this.openItemService.updateSharedSplitScreenData(param);
    this.openDrawer = true;
    setTimeout(() => {
      this.drawer.toggle();
    }, 50);
  }

  generateListOfActionButtons() {
    const isAW = this.businessUnitService.getCurrentBusinessUnit() === '4';
    switch (this.currentTab) {
      case AccountingTransactionTabType.Open:
        this.listOfActionButtons = isAW
          ? this.listOfActionButtonsForOpenTransactionsAW
          : this.listOfActionButtonsForOpenTransactionsNonAW;
        this.listOfActionIcons = isAW
          ? this.listOfActionIconsForAccountingOpenTransactionAW
          : this.listOfActionIconsForAccountingNonAW;
        break;
      case AccountingTransactionTabType.Closed:
      case AccountingTransactionTabType.Credits:
        this.listOfActionButtons = isAW
          ? this.listOfActionButtonsForOpenTransactionsAW
          : this.listOfActionButtonsForClosedTransactionsNonAW;
        break;
      case AccountingTransactionTabType.Merged:
        this.listOfActionButtons = isAW
          ? this.listOfActionButtonsForOpenTransactionsAW
          : this.listOfActionButtonsForClosedTransactionsNonAW;
        break;
      default:
        console.error(`Unrecognized currentTab value "${this.currentTab}"`);
    }
  }

  getCurrentBusinessUnitAndTab() {
    if (this.businessUnitService.getCurrentBusinessUnit() === '4') {
      switch (this.currentTab) {
        case AccountingTransactionTabType.Open:
          this.configurationKey = this.configurationKeyForAWOpenTransaction;
          break;
        case AccountingTransactionTabType.Closed:
          this.configurationKey = this.configurationKeyForAWClosedTransaction;
          break;
        case AccountingTransactionTabType.Credits:
          this.configurationKey = this.configurationKeyForAWCreditTransaction;
          break;
        case AccountingTransactionTabType.Merged:
          this.configurationKey =
            this.configurationKeyForAWMergedTransactionTable;
          break;
        default:
          console.error(`Unrecognized currentTab value "${this.currentTab}"`);
      }
      this.columns = accountingBillingTransactionListColumnsConf;
      this.accountingSharedDataService.sharedAccountingOpenTransactionsColumnsStateAW =
        accountingBillingTransactionListColumnsConf;
      this.displayedColumns = accountingBillingTransactionListColumns;
      this.accountingSharedDataService.sharedAccountingOpenTransactionsDisplayedColumnsStateAW =
        accountingBillingTransactionListColumns;
    } else {
      switch (this.currentTab) {
        case AccountingTransactionTabType.Open:
          this.configurationKey = this.configurationKeyForNonAWOpenTransaction;
          break;
        case AccountingTransactionTabType.Closed:
          this.configurationKey =
            this.configurationKeyForNonAWClosedTransaction;
          break;
        case AccountingTransactionTabType.Credits:
          this.configurationKey =
            this.configurationKeyForNonAWCreditTransaction;
          break;
        case AccountingTransactionTabType.Merged:
          this.configurationKey =
            this.configurationKeyForNonAWMergedTransactionTable;
          break;
        default:
          console.error(`Unrecognized currentTab value "${this.currentTab}"`);
      }
      this.columns = accountingOpenItemColumnsConf;
      this.accountingSharedDataService.sharedAccountingOpenTransactionsColumnsStateNonAW =
        accountingOpenItemColumnsConf;
      this.displayedColumns = accountingOpenItemColumns;
      this.accountingSharedDataService.sharedAccountingOpenTransactionsDisplayedColumnsStateNonAW =
        accountingOpenItemColumns;
    }
  }

  loadData(filterOptions?) {
    if (this.defaultTableComponent) {
      this.defaultTableComponent.setLoaderSpinner(true);
    }
    let openOrClosed: TransactionStatus;
    let debitsOrCredits: TransactionBookingStandart;

    switch (this.currentTab) {
      case AccountingTransactionTabType.Open:
        openOrClosed = TransactionStatus.Open;
        debitsOrCredits = TransactionBookingStandart.Both;
        break;
      case AccountingTransactionTabType.Closed:
        openOrClosed = TransactionStatus.Closed;
        debitsOrCredits = TransactionBookingStandart.Debits;
        break;
      case AccountingTransactionTabType.Credits:
        openOrClosed = TransactionStatus.Both;
        debitsOrCredits = TransactionBookingStandart.Credits;
        break;
      case AccountingTransactionTabType.Merged:
        openOrClosed = TransactionStatus.Both;
        debitsOrCredits = TransactionBookingStandart.Both;
        break;
    }

    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.loaderSpinner = true;
      this.dataLoaded = false;
      this.filter(filterOptions);
      this.loadTransactions(openOrClosed, debitsOrCredits, filterOptions);
    });

    this.loadTransactions(openOrClosed, debitsOrCredits, filterOptions);
  }

  loadTransactions(
    openOrClosed: TransactionStatus,
    debitsOrCredits: TransactionBookingStandart,
    filterOptions? // Not used here, but probably will become relevant later on.
  ) {
    if (!this.accountingData) {
      this.transactionData = null;
      this.loaderSpinner = false;
      this.dataLoaded = true;
    }

    this.userService
      .getConfiguration(this.configurationKey)
      .pipe(
        map((tableConfigFromBackend) => {
          const uiOnlyColumns = ['select', 'actions', 'flags'];
          const columns: string[] = (
            tableConfigFromBackend.displayedColumns || this.displayedColumns
          ).filter(
            (column: string) => !uiOnlyColumns.find((c) => c === column)
          );

          const paramsForBackend = { ...this.searchConfig };
          paramsForBackend['entityType'] = this.accountingData.type;
          paramsForBackend['entityGuid'] = this.accountingData.guid;
          paramsForBackend['fields'] = columns;

          const closedTransactions =
            this.filterClosedTransactions(openOrClosed);

          if (closedTransactions) {
            paramsForBackend['closedTransactions'] = closedTransactions;
          }

          const bookingStandard = this.filterDebitsOrCredits(debitsOrCredits);
          if (bookingStandard) {
            paramsForBackend['bookingStandard'] = bookingStandard;
          }

          return (this.currentConfig = paramsForBackend);
        }),
        switchMap((paramsForBackend) =>
          this.accountingService.filterTransactionList(paramsForBackend)
        ),
        map((transactionList) => {
          if (!transactionList) {
            this.setToNotLoading();
            return;
          }


          // TODO: Introduce a pipe to format booking codes with 3 digits.

          const key ='DEBTOR.ACCOUNT-OVERVIEW.CANCELLATION';
          let cancellationTranslationDe = this.translateService
          .instant(key);
          let cancellationTranslationEn = this.translateService
          .instant(key);
          this.translateService.getTranslation('de')
          .subscribe((dataDe) => {
            cancellationTranslationDe =
              !!dataDe[key] ?
                dataDe[key] :
                cancellationTranslationDe;

            this.translateService.getTranslation('en')
            .subscribe((dataEn) => {
              cancellationTranslationEn =
                !!dataEn[key] ?
                  dataEn[key] :
                  cancellationTranslationEn;

            transactionList.content.forEach((transaction) => {
              if (transaction.cancellation) {
                transaction['bookingCodeNameEn'] =
                  `${cancellationTranslationEn} ${transaction['bookingCodeNameEn']}`;
                transaction['bookingCodeNameDe'] =
                  `${cancellationTranslationDe} ${transaction['bookingCodeNameDe']}`;
              }});
            });
          });

          this.transactionData = transactionList;
          this.loaderSpinner = false;
          this.dataLoaded = true;
          if (this.defaultTableComponent) {
            this.defaultTableComponent.setLoaderSpinner(false);
          }
        })
      )
      .subscribe(noop, (error) => this.handleError(error));
  }

  filterClosedTransactions(status: TransactionStatus) {
    switch (status) {
      case TransactionStatus.Open:
        return 'false';
      case TransactionStatus.Closed:
        return 'true';
      case TransactionStatus.Both:
        return null;
    }
  }

  filterDebitsOrCredits(bookingStandard: TransactionBookingStandart) {
    switch (bookingStandard) {
      case TransactionBookingStandart.Debits:
        return 'D';
      case TransactionBookingStandart.Credits:
        return 'C';
      case TransactionBookingStandart.Both:
        return null;
    }
  }

  setToNotLoading() {
    this.transactionData = null;
    this.loaderSpinner = false;
    this.dataLoaded = true;
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
    this.setToNotLoading();
  }

  filter(event) {
    if (!event) {
      event = {};
    }

    if (event.filteredValues) {
      this.searchConfig.filteredValues =
        this.searchConfig.filteredValues.filter(
          (fv) => fv.choice !== event.filteredValues.choice
        );
      this.searchConfig.filteredValues.push(event.filteredValues);
    } else {
      this.searchConfig = {
        ...this.searchConfig,
        ...event,
      };
      if (event.direction && event.choice) {
        this.searchConfig.sortDirection = event.direction;
        this.searchConfig.sortField = event.choice;
      }
    }
    this.loadData();
  }

  closeSplitScreen() {
    this.tablesService.updateSharedSelectedRows(null);
    this.openItemService.updateSharedSelectedBalance(0.0);
  }

  checkFullyAllocation(element) {
    return element.openAmount === 0;
  }

  checkAllocated(element) {
    return (
      element.amount &&
      element.openAmount &&
      element.amount - element.openAmount !== 0
    );
  }

  getDistinctValues = (choice, column) => {
    return this.accountingService.getDistinctValuesByEntity(
      this.currentConfig,
      choice
    );
  };

  getAmountColumnTextColor(element): string {
    if (element.transaction) {
      return element.transaction.creditDebitCode &&
        element.transaction.creditDebitCode === 2 &&
        element.transaction.amount !== 0
        ? 'red'
        : 'black';
    } else if (element) {
      return element.creditDebitCode &&
        element.creditDebitCode === 2 &&
        element.amount !== 0
        ? 'red'
        : 'black';
    }
  }

  addMinusForAmountColumn(element) {
    if (element.transaction) {
      return element.transaction.creditDebitCode &&
        element.transaction.creditDebitCode === 2 &&
        element.transaction.amount !== 0
        ? '-'
        : '';
    } else if (element) {
      return element.creditDebitCode &&
        element.creditDebitCode === 2 &&
        element.amount !== 0
        ? '-'
        : '';
    }
  }

  sumAllBalancesSelected() {
    let result = 0.0;
    this.selectedRows.forEach((row) => {
      if (row.openAmount) {
        if (row.creditDebitCode === 'C') {
          result -= row.openAmount;
        } else if (row.creditDebitCode === 'D') {
          result += row.openAmount;
        }
      }
    });

    this.openItemService.updateSharedSelectedBalance(result);
  }
}
