import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private sharedApprovedRole = new BehaviorSubject<any>(null);
  approvedRole = this.sharedApprovedRole.asObservable();

  updateApprovedRole(approvedRole: any) {
    this.sharedApprovedRole.next(approvedRole);
  }
}
