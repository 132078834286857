export const typeOptions: any[] = [
  {
    name: 'pig',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.PIG',
  },
  {
    name: 'poultry',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.POULTRY',
  },
  {
    name: 'cattle',
    translateName: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CATTLE',
  },
];
