import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  AbstractControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-mat-input-date',
  templateUrl: './mat-input-date.component.html',
  styleUrls: ['./mat-input-date.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class MatInputDateComponent implements OnInit {
  @Input() name;
  @Input() placeholder;
  @Input() form: UntypedFormGroup;
  @Input() label;
  @Input() entityName;
  @Input() controlObject;
  @Input() controlName;
  @Input() taskValue;
  @Input() approvedValue;
  @Input() approvedValueObject;
  @Input() titleTask;
  @Input() editableTask;
  @Input() isTask;
  @Input() formGroupCustomName;
  @Input() labelSuffix;
  @Input() minDate;
  @Input() maxDate;
  @Input() appearance;
  @Input() timeStamp;
  @Input() sideMenu;
  @Input() stringDate;
  @Input() inline;
  @Input() titlecasePipe;
  @Input() elementIndex;
  @Input() compareMode;
  @Input() disabled: boolean;

  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();

  errorRequired = 'error-message-required';
  errorInvalid = 'error-message-invalid';
  newValue: any;
  nestedForm: any;
  formGroup: any;
  dateFormat = '';

  formChanged = false;
  requiredValidator = false;
  changedValue = false;

  constructor(
    private parentForm: FormGroupDirective,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.newValue = val;
      this.formChanged = true;
      this.changedValue = this.hasChanged(this.controlName);
    });

    this.getDateFormat();
    const userSpecifiedDateFormat = this.userService
      .getDateFormat()
      .toUpperCase();
    DATE_FORMATS.display.dateInput = userSpecifiedDateFormat;
    DATE_FORMATS.parse.dateInput = userSpecifiedDateFormat;
    if (this.parentForm.form.controls[this.formGroupCustomName]) {
      this.formGroup = this.parentForm.form.controls[this.formGroupCustomName];
    } else {
      this.formGroup = this.parentForm.form;
    }

    if (this.controlObject && this.controlObject.validator) {
      const validator = this.controlObject.validator({} as AbstractControl);
      if (validator) {
        this.requiredValidator = validator.required;
      }
    }

    this.changedValue = this.hasChanged(this.controlName);
  }

  onDateChange(): void {
    if (moment.isMoment(this.form.value[this.controlName])) {
      this.form
        .get(this.controlName)
        .patchValue(
          (this.form.value[this.controlName] as moment.Moment).format(
            'YYYY-MM-DD'
          )
        );
    }
    this.dateChange.emit();
  }

  getDateFormat() {
    this.dateFormat = this.userService.dateFormat;
    if (this.dateFormat === 'BRITISH') {
      this.changeDateFormat('en-GB');
      this.dateFormat = 'dd/MM/yyyy';
    }
    if (this.dateFormat === 'AMERICAN') {
      this.changeDateFormat('en-US');
      this.dateFormat = 'MM-dd-yyyy';
    }
    if (this.dateFormat === 'GERMAN') {
      this.changeDateFormat('de');
      this.dateFormat = 'dd.MM.yyyy';
    }
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }

  hasChanged(fieldName: string) {
    if (this.formGroupCustomName) {
      if (
        this.form &&
        this.form.controls[this.formGroupCustomName] &&
        this.form.controls[this.formGroupCustomName][fieldName] &&
        !this.form.controls[this.formGroupCustomName]['controls'][fieldName]
          .enabled
      ) {
        return false;
      }
    } else {
      if (
        this.form &&
        this.form.controls[fieldName] &&
        !this.form.controls[fieldName].enabled
      ) {
        return false;
      }
    }

    if (this.taskValue && this.approvedValue) {
      let newFieldValue;
      if (this.newValue) {
        if (this.formGroupCustomName) {
          newFieldValue = this.newValue[this.formGroupCustomName][fieldName];
        } else {
          newFieldValue = this.newValue[fieldName];
        }
      }
      if (this.newValue && newFieldValue) {
        return newFieldValue !== this.approvedValue;
      } else {
        return this.taskValue !== this.approvedValue;
      }
    } else if (this.approvedValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.approvedValue !==
          this.newValue[this.formGroupCustomName][fieldName]
        );
      } else {
        return this.approvedValue !== this.newValue[fieldName];
      }
    } else if (!this.approvedValue && this.taskValue && this.newValue) {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !== this.taskValue
        );
      } else {
        return this.newValue[fieldName] === this.taskValue
          ? this.approvedValue !== this.taskValue
          : this.newValue[fieldName] !== this.taskValue;
      }
    } else if (this.approvedValueObject) {
      if (this.formGroupCustomName) {
        return (
          this.newValue[this.formGroupCustomName][fieldName] !==
          this.approvedValue
        );
      } else {
        if (this.newValue) {
          return this.newValue[fieldName] !== this.approvedValue;
        } else {
          return false;
        }
      }
    }
    {
      return false;
    }
  }

  showRemoveOption() {
    let result = false;
    if (
      this.form.value[this.controlName] &&
      this.form.get(this.controlName).enabled
    ) {
      result = true;
    }
    return result;
  }

  isFieldDisabled() {
    return this.disabled || (this.controlObject && this.controlObject.disabled);
  }

  clearOptions() {
    this.form.value[this.controlName] = null;
    this.form.get(this.controlName).patchValue(null);
    this.form.markAsTouched();
  }

  hasTaskValueChanged() {
    return this.approvedValue && this.approvedValue !== this.taskValue;
  }
}
