export interface INotification {
  messageKey: string;
  type: string;
  details?: any;
  duration?: number;
}

export enum MESSAGE_TYPE {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export enum DEFAULT_MESSAGES {
  CREATE_ENTITY = 'NOTIFICATION.CREATED',
  // Another key GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT has the same translation
  CREATE_DRAFT = 'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
  TASK_COMPLETED = 'NOTIFICATION.TASK.COMPLETED',
  TASK_APPROVED = 'NOTIFICATION.TASK.APPROVED',
  TASK_REJECTED = 'NOTIFICATION.TASK.REJECTED',
  CREATE_UPDATE_DATA = 'NOTIFICATION.CREATED-DIRECT',
  DELETE_ENTITY = 'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
}
