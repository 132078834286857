export const reportingPeriodsColumnsConf: any[] = [
  {
    name: 'starDate',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE',
    locked: true,
  },
  {
    name: 'endDate',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE',
    locked: true,
  },
  {
    name: 'comment',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.REPORTING-PERIODS.COMMENT',
    locked: true,
  },
  {
    version: '1',
  },
];
