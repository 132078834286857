import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';

@Component({
  selector: 'app-translation-create',
  templateUrl: './translation-create.component.html',
  styleUrls: ['./translation-create.component.less'],
})
export class TranslationCreateComponent implements OnInit, DirtyComponent {
  entityName = 'app-translation-create';
  errorRequired = 'error-message-required';
  translationForm: UntypedFormGroup;
  isDirty = false;
  approval = false;
  languages: any[];
  filteredLanguages: any[] = [];
  state;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {
    this.translationFormBuilder();
  }

  ngOnInit() {
    this.state = window.history.state;
    this.findLanguages();
    this.filterLanguages();
  }

  filterLanguages() {
    this.translationForm.get('nameSearch').valueChanges.subscribe((data) => {
      if (data !== '') {
        this.searchFromName(this.translationForm);
      } else {
        this.findLanguages();
      }
    });
  }

  findLanguages() {
    this.translationService.getLanguageList().subscribe((languages) => {
      this.languages = languages;
      this.filteredLanguages = languages;
    });
  }

  translationFormBuilder() {
    this.translationForm = this.formBuilder.group({
      name: [{ value: null, disabled: false }, Validators.required],
      nameSearch: [{ value: null, disabled: false }],
    });
  }

  canDeactivate() {
    return this.translationForm.dirty && !this.approval;
  }

  searchFromName(formGroup) {
    if (!this.languages) {
      return;
    }
    const searchTerm = formGroup.get('nameSearch').value;
    let resultLanguages = [];
    for (const language of this.languages) {
      if (
        language.name
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultLanguages.push(language);
      }
    }
    resultLanguages = this.removeRepeatedNames(resultLanguages);
    this.filteredLanguages = resultLanguages;
  }

  removeRepeatedNames(data) {
    return data.filter(
      (v, i, a) => a.findIndex((t) => t.value === v.value) === i
    );
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.translationForm.dirty;
  }

  clearOptions(field) {
    this.translationForm.get(field).patchValue(null);
  }

  showRemoveOption(field) {
    let result = false;
    if (this.translationForm.get(field).value) {
      result = true;
    }
    return result;
  }

  save() {
    this.approval = true;
    const languageName = this.translationForm.get('name').value;
    const object = { isoCode: languageName };
    this.translationService.createLanguage(object).subscribe(
      (_) => {
        this.notificationService.showToast(
          'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.CREATE',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'SYSTEM-CONFIGURATION.TRANSLATION-MANAGEMENT.CREATE'
            ),
          }
        );
        this.translationService.updateNewLanguages('data');
        setTimeout(() => {
          this.changeEditMode();
        }, 1500);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  changeEditMode() {
    this.router.navigateByUrl(
      '/systemConfiguration/translationManagement/translationList',
      {
        state: { filteredKeys: this.state.filteredKeys },
      }
    );
  }

  handleError(err) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: err.error.errorId ? err.error.errorId : 'unknown',
        error: err.message,
      }
    );
  }
}
