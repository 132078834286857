<table
  aria-hidden="true"
  mat-table
  [dataSource]="dataSource"
  matSort
  class="table-fixed"
  id="{{ entityName }}-search-results-table"
>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th
      mat-header-cell
      *matHeaderCellDef
      class="v25-th width-table-selection-column"
      id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
    >
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
      >
      </mat-checkbox>
    </th>
    <td
      mat-cell
      *matCellDef="let row; let i = index"
      class="width-table-selection-column"
      id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ i }}"
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Client Debtor Id Column -->
  <ng-container matColumnDef="clientDebtorId" sticky>
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.CLIENT-DEBTOR-ID' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[1] }}-{{ i }}"
    >
      {{ element.clientDebtorId }}
    </td>
  </ng-container>

  <!-- Client Open Item Id Column -->
  <ng-container matColumnDef="clientOpenItemId">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[2] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.CLIENT-OPEN-ITEM-ID' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[2] }}-{{ i }}"
    >
      {{ element.clientOpenItemId }}
    </td>
  </ng-container>

  <!-- Open Item Date -->
  <ng-container matColumnDef="openItemDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[3] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.OPEN-ITEM-DATE' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[3] }}-{{ i }}"
    >
      {{ element.openItemDate | date: dateFormat }}
    </td>
  </ng-container>

  <!-- Booking Code -->
  <ng-container matColumnDef="bookingCode">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[4] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.BOOKING-CODE' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[4] }}-{{ i }}"
    >
      {{ element.bookingCode }}
    </td>
  </ng-container>

  <!-- Card -->
  <ng-container matColumnDef="card">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[5] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.DEBIT-CREDIT-CODE' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[5] }}-{{ i }}"
    >
      {{ element.debitOrCredit }}
    </td>
  </ng-container>

  <!-- Amount -->
  <ng-container matColumnDef="amount">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[6] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.AMOUNT' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[6] }}-{{ i }}"
    >
      {{ element.amount }}
    </td>
  </ng-container>

  <!-- Currency Column -->
  <ng-container matColumnDef="currency">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[7] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.CURRENCY-CODE' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[7] }}-{{ i }}"
    >
      {{ element.currency }}
    </td>
  </ng-container>

  <!-- Vat Amount Column -->
  <ng-container matColumnDef="vatAmount">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[8] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.VAT-AMOUNT' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[8] }}-{{ i }}"
    >
      {{ element.vatAmount }}
    </td>
  </ng-container>

  <!-- Due Date Column -->
  <ng-container matColumnDef="dueDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[9] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.DUE-DATE' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[9] }}-{{ i }}"
    >
      {{ element.dueDate | date: dateFormat }}
    </td>
  </ng-container>

  <!-- Debt Collection Column -->
  <ng-container matColumnDef="debtCollection">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ displayedColumns[10] }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION' | translate }} *
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ displayedColumns[10] }}-{{ i }}"
    >
      {{ element.debtCollection }}
    </td>
  </ng-container>

  <!-- SettlementEur Column -->
  <ng-container matColumnDef="settlementInEUR">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[10].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.SETTLEMENT-IN-EUR' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[10].name }}-{{ i }}"
    >
      {{ element.settlementInEUR }}
    </td>
  </ng-container>

  <!-- Vat Percent Column -->
  <ng-container matColumnDef="vatPercent">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[11].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.VAT-PERCENT' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[11].name }}-{{ i }}"
    >
      {{ element.vatPercent | percent: '1.0-3' }}
    </td>
  </ng-container>

  <!-- Vat Code Column -->
  <ng-container matColumnDef="vatCode">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[12].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.VAT-CODE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[12].name }}-{{ i }}"
    >
      {{ element.vatCode }}
    </td>
  </ng-container>

  <!-- Vat Amount Column -->
  <ng-container matColumnDef="referenceClientsOpenItemId">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[13].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.REFERENCE-CLIENT-OPEN-ITEM-ID' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[13].name }}-{{ i }}"
    >
      {{ element.referenceClientsOpenItemId }}
    </td>
  </ng-container>

  <!-- Additional Information Column -->
  <ng-container matColumnDef="additionalInformation">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[14].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.ADDITIONAL-INFO' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[14].name }}-{{ i }}"
    >
      {{ element.additionalInformation }}
    </td>
  </ng-container>

  <!-- Branch Id-->
  <ng-container matColumnDef="branchId">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[15].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.BRANCH' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[15].name }}-{{ i }}"
    >
      {{ element.branchId }}
    </td>
  </ng-container>

  <!-- IBAN Column -->
  <ng-container matColumnDef="iban">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[16].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.IBAN' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[16].name }}-{{ i }}"
    >
      {{ element.iban }}
    </td>
  </ng-container>

  <!-- BIC Column -->
  <ng-container matColumnDef="bic">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[17].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.BIC' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[17].name }}-{{ i }}"
    >
      {{ element.bic }}
    </td>
  </ng-container>

  <!-- Account Holder Column -->
  <ng-container matColumnDef="accountHolder">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[18].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.ACCOUNT-HOLDER' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[18].name }}-{{ i }}"
    >
      {{ element.accountHolder }}
    </td>
  </ng-container>

  <!-- Payment Reference Column -->
  <ng-container matColumnDef="paymentReference">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[19].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.PAYMENT-REFERENCE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[19].name }}-{{ i }}"
    >
      {{ element.paymentReference }}
    </td>
  </ng-container>

  <!-- Payment Scheme Column -->
  <ng-container matColumnDef="paymentScheme">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[20].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.PAYMENT-SCHEME' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[20].name }}-{{ i }}"
    >
      {{ element.paymentScheme }}
    </td>
  </ng-container>

  <!-- Account Holder Column -->
  <ng-container matColumnDef="paymentDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[21].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.PAYMENT-DATE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[21].name }}-{{ i }}"
    >
      {{ element.paymentDate | date }}
    </td>
  </ng-container>

  <!-- Debt Collection Tansfer Date Column -->
  <ng-container matColumnDef="debtCollectionTransferDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[22].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.DEBT-COLLECTION-TRANSFER-DATE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[22].name }}-{{ i }}"
    >
      {{ element.debtCollectionTransferDate | date }}
    </td>
  </ng-container>

  <!-- Objection Code Column -->
  <ng-container matColumnDef="objectionCode">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[23].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.OBJECTION-CODE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[23].name }}-{{ i }}"
    >
      {{ element.objectionCode }}
    </td>
  </ng-container>

  <!-- Objection Date Column -->
  <ng-container matColumnDef="objectionDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[24].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.OBJECTION-DATE' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[24].name }}-{{ i }}"
    >
      {{ element.objectionDate | date }}
    </td>
  </ng-container>

  <!-- Objection Amount Column -->
  <ng-container matColumnDef="objectionAmount">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="v25-th open-item-list-columns"
      id="{{ entityName }}-table-results-{{ columns[25].name }}"
    >
      {{ 'OPEN-TASK.TABLE-HEADERS.OBJECTION-AMOUNT' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      id="{{ entityName }}-table-results-{{ columns[25].name }}-{{ i }}"
    >
      {{ element.objectionAmount }}
    </td>
  </ng-container>
  <!--Actions Column-->
  <ng-container matColumnDef="actions" stickyEnd>
    <th
      mat-header-cell
      *matHeaderCellDef
      class="v25-th settings"
      id="{{ entityName }}-table-results-{{ displayedColumns[11] }}"
    >
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>settings</mat-icon>
      </button>
    </th>

    <td mat-cell *matCellDef="let element; let i = index">
      <button
        class="menu"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{ index: i }"
        id="{{ entityName }}-table-results-{{ displayedColumns[11] }}-{{ i }}"
      >
        <span class="material-icons"> more_vert </span>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
</table>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-index="index">
    <button
      *hasPermission="'op.r'"
      mat-menu-item
      (click)="listDeduction(index)"
      id="{{ entityName }}-table-results-addDeduction-button"
    >
      <span class="material-icons icon-menu"> add </span>
      <span translate>OPEN-TASK.ACTIONS.LIST-DEDUCTION</span>
    </button>
    <button
      *hasPermission="'op.r'"
      mat-menu-item
      (click)="listDunningInfo(index)"
      id="{{ entityName }}-table-results-addDunning-button"
    >
      <span class="material-icons icon-menu"> add </span>
      <span translate>OPEN-TASK.ACTIONS.LIST-DUNNING</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" class="select-columns" (close)="tableChanged()">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
