import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(public notificationService: NotificationService) {}

  search(initialData, searchInput, newDataSource) {
    this.notificationService.dismissMessage();
    const newData = [];
    for (const item of initialData) {
      for (const [key, value] of Object.entries(item)) {
        if (item.hasOwnProperty(`${key}`)) {
          if (
            `${value}`
              .toString()
              .toLowerCase()
              .indexOf(searchInput.toString().toLowerCase()) !== -1
          ) {
            newData.push(item);
            break;
          }
          if (`${key}` === 'address') {
            if (
              item.address.city
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1 ||
              item.address.country
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1 ||
              item.address.zipCode
                .toString()
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1 ||
              item.address.street
                .toString()
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1 ||
              item.address.number
                .toString()
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1
            ) {
              newData.push(item);
              break;
            }
          }
        }
      }
    }
    if (newData.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-BU-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
    newDataSource.data = newData;
  }
}
