export const systemConfigurationsColumns: string[] = [
  'visibleId',
  'name',
  'description',
  'defaultValue',
  'validFrom',
  'editedBy',
  'lastEdit',
  'approvedBy',
  'approvedOn',
  'actions',
];
