import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessTransactionCodeService {
  private readonly serviceUrl;
  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.openItemService;
  }

  getBusinessTransactionCodes(): Observable<any> {
    return this.http.get(this.serviceUrl + 'btc', this.buildHeaders());
  }

  saveBusinessTransactionCode(json): Observable<any> {
    return this.http.post(this.serviceUrl + 'btc', json, this.buildHeaders());
  }

  updateBusinessTransactionCode(json): Observable<any> {
    return this.http.put(this.serviceUrl + 'btc', json, this.buildHeaders());
  }

  deleteBtc(id) {
    return this.http.delete(this.serviceUrl + 'btc' + id, this.buildHeaders());
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append('Business-Unit-Id', localStorage.currentBusinessUnitId),
    };
  }
}
