import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AzureFile } from 'src/app/shared/models/azure-file';

@Component({
  selector: 'app-task-details-buttons',
  templateUrl: './task-details-buttons.component.html',
  styleUrls: ['./task-details-buttons.component.less'],
})
export class TaskDetailsButtonsComponent implements OnChanges {
  @Input() task;
  uploadedFiles: AzureFile[];
  taskTypesAllowedForCompletionByCreator: string[] = [
    'OBJECTION_MANAGEMENT',
    'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC',
    'PAYOUT_SUGGESTION_LIST_EMAIL',
    'AW_SEPA_EXCEL_EMAIL',
  ];
  @Input() isDirectApproveOrReject = false;
  @Output() buttonClicked = new EventEmitter();

  taskButtons = taskButtons;
  availableButtons = {
    UNLOCK: false,
    FORWARD: false,
    START: false,
    MODIFY: false,
    REJECT: false,
    COMPLETE: false,
    UPLOAD: true,
  };

  loggedUserName = '';

  constructor(private kcService: KeycloakService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loggedUserName = this.kcService.getUsername();
    if (changes.task && !!this.loggedUserName) {
      this.checkAvailableButtonsOnTaskChange();
    }
  }

  buttomClick(button: taskButtons) {
    this.buttonClicked.emit(button);
  }

  checkAvailableButtonsOnTaskChange(): void {
    // task && task.status != 'COMPLETE' && !checkLocked()
    this.availableButtons.UNLOCK =
      this.task?.status !== 'COMPLETE' && !this.checkLocked();
    // task && task.status != 'COMPLETE' && checkModify() && checkLocked() && !isDirectApproveOrReject
    this.availableButtons.MODIFY =
      this.task?.status !== 'COMPLETE' &&
      this.checkModify() &&
      this.checkLocked() &&
      !this.isDirectApproveOrReject;
    // task && task.status != 'COMPLETE' && checkLocked()
    this.availableButtons.FORWARD =
      this.task?.status !== 'COMPLETE' && this.checkLocked();
    // task && task.status != 'COMPLETE' && !isDirectApproveOrReject && checkAvailable() && checkLocked()
    this.availableButtons.START =
      this.task?.status !== 'COMPLETE' &&
      !this.isDirectApproveOrReject &&
      this.checkLocked() &&
      this.checkAvailable();
    // task && task.status === 'OPEN' && isDirectApproveOrReject && checkAvailable()
    this.availableButtons.REJECT =
      this.task?.status === 'OPEN' &&
      this.isDirectApproveOrReject &&
      this.checkAvailable();
    // task && task.status === 'OPEN' && isDirectApproveOrReject && checkAvailable()
    this.availableButtons.COMPLETE =
      this.task?.status === 'OPEN' &&
      this.isDirectApproveOrReject &&
      this.checkAvailable();
  }

  /**
   * Check if the modify button should be displayed.
   */
  private checkModify(): boolean {
    if (
      this.task?.type === 'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC' ||
      this.task?.type === 'CHECK_ACCOUNT_STATE_PROCESSING_ERROR'
    ) {
      return false;
    }
    if (
      this.task?.createdByUsername === this.loggedUserName &&
      this.task?.updatedByUsername === this.loggedUserName
    ) {
      return true;
    }
    return false;
  }

  /**
   * Check availability of START, REJECT and COMPLETE buttons.
   */
  private checkAvailable(): boolean {
    if (this.taskTypesAllowedForCompletionByCreator.includes(this.task?.type)) {
      return true;
    }
    if (this.loggedUserName === this.task?.updatedByUsername) {
      return false;
    }
    if (
      this.task?.assignedUsername === this.loggedUserName ||
      !this.task?.isLocked
    ) {
      return true;
    }
    return !this.task?.assignedUserId;
  }

  /**
   * Check if the task is locked.
   */
  private checkLocked(): boolean {
    return (
      !this.task?.isLocked ||
      (this.task?.isLocked && this.task?.lockedUsername === this.loggedUserName)
    );
  }
}

export enum taskButtons {
  UNLOCK = 'UNLOCK',
  FORWARD = 'FORWARD',
  START = 'START',
  MODIFY = 'MODIFY',
  REJECT = 'REJECT',
  COMPLETE = 'COMPLETE',
  UPLOAD = 'UPLOAD',
}
