import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const customAmountValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const objectionAmount = control.get('objectionAmount');
  const amount = control.get('amount');

  return objectionAmount && amount && objectionAmount.value > amount.value
    ? { objectionAmountError: true }
    : null;
};

export const customCreditDebitCardValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const cardNumber = control.get('debitOrCredit');
  if (cardNumber && (cardNumber.value === '' || cardNumber.value == null)) {
    return null;
  }
  return cardNumber &&
    cardNumber.value !== 1 &&
    cardNumber.value !== 2 &&
    cardNumber.value !== ''
    ? { cardError: true }
    : null;
};

export const customSettlementValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const settlement = control.get('settlementInEUR');

  return settlement &&
    settlement.value !== 1 &&
    settlement.value !== 2 &&
    settlement.value !== null &&
    settlement.value !== null &&
    settlement.value !== undefined &&
    settlement.value !== ''
    ? { settlementError: true }
    : null;
};

export const vatAmoutValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const vatAmount = control.get('vatAmount');
  const amount = control.get('amount');

  if (vatAmount && (vatAmount.value === '' || vatAmount.value == null)) {
    return null;
  }

  const result =
    vatAmount &&
    amount &&
    vatAmount.value >= amount.value &&
    vatAmount.value !== ''
      ? { vatAmountError: true }
      : null;

  return result;
};

export const vatCodePercentValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const vatCode = control.get('vatCode');
  const vatPercent = control.get('vatPercent');
  return vatCode &&
    vatPercent &&
    vatCode.value == null &&
    vatPercent.value == null &&
    (vatCode.touched || vatPercent.touched)
    ? { vatCodePercentMandatoryError: true }
    : null;
};

export const ibanValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const iban = control.get('iban');
  const bic = control.get('bic');
  const accountHolder = control.get('accountHolder');
  const paymentReference = control.get('paymentReference');
  const paymentScheme = control.get('paymentScheme');
  const paymentDate = control.get('paymentDate');

  return (bic.value == null && !bic.untouched) ||
    (accountHolder.value == null && !accountHolder.untouched) ||
    (paymentReference.value == null && !paymentReference.untouched) ||
    (paymentScheme.value == null && !paymentScheme.untouched) ||
    (bic.value !== '' && !bic.untouched && iban.value == null) ||
    (bic.value !== '' && !bic.untouched && iban.value === '') ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      iban.value == null) ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      iban.value === '') ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      iban.value === '') ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      iban.value == null) ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      iban.value === '') ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      iban.value == null) ||
    (!paymentDate.untouched && iban.value === '') ||
    (!paymentDate.untouched && iban.value == null)
    ? { ibanMandatoryError: true }
    : null;
};

export const debtCollectionTransferDateValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const debtCollection = control.get('debtCollection');
  return debtCollection && debtCollection.value === 2
    ? { debtCollectionTransferDateRequired: true }
    : null;
};

export const bicValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const bic = control.get('bic');
  const iban = control.get('iban');
  const accountHolder = control.get('accountHolder');
  const paymentReference = control.get('paymentReference');
  const paymentScheme = control.get('paymentScheme');
  const paymentDate = control.get('paymentDate');

  return (iban.value == null && !iban.untouched) ||
    (accountHolder.value == null && !accountHolder.untouched) ||
    (paymentReference.value == null && !paymentReference.untouched) ||
    (paymentScheme.value == null && !paymentScheme.untouched) ||
    (iban.value !== '' && !iban.untouched && bic.value == null) ||
    (iban.value !== '' && !iban.untouched && bic.value === '') ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      bic.value == null) ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      bic.value === '') ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      bic.value == null) ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      bic.value === '') ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      bic.value == null) ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      bic.value === '') ||
    (!paymentDate.untouched && bic.value == null) ||
    (!paymentDate.untouched && bic.value === '')
    ? { bicMandatoryError: true }
    : null;
};

export const paymentReferenceValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const paymentReference = control.get('paymentReference');
  const iban = control.get('iban');
  const accountHolder = control.get('accountHolder');
  const bic = control.get('bic');
  const paymentScheme = control.get('paymentScheme');
  const paymentDate = control.get('paymentDate');

  return (iban.value == null && !iban.untouched) ||
    (accountHolder.value == null && !accountHolder.untouched) ||
    (bic.value == null && !bic.untouched) ||
    (paymentScheme.value == null && !paymentScheme.untouched) ||
    (iban.value !== '' && !iban.untouched && paymentReference.value == null) ||
    (iban.value !== '' && !iban.untouched && paymentReference.value === '') ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      paymentReference.value == null) ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      paymentReference.value === '') ||
    (bic.value !== '' && !bic.untouched && paymentReference.value == null) ||
    (bic.value !== '' && !bic.untouched && paymentReference.value === '') ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      paymentReference.value == null) ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      paymentReference.value === '') ||
    (!paymentDate.untouched && paymentReference.value === '') ||
    (!paymentDate.untouched && paymentReference.value == null)
    ? { paymentReferenceMandatoryError: true }
    : null;
};

export const paymentSchemeValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const paymentScheme = control.get('paymentScheme');
  const iban = control.get('iban');
  const accountHolder = control.get('accountHolder');
  const bic = control.get('bic');
  const paymentReference = control.get('paymentReference');
  const paymentDate = control.get('paymentDate');

  return (iban.value == null && !iban.untouched) ||
    (accountHolder.value == null && !accountHolder.untouched) ||
    (bic.value == null && !bic.untouched) ||
    (paymentReference.value == null && !paymentReference.untouched) ||
    (iban.value !== '' && !iban.untouched && paymentScheme.value == null) ||
    (iban.value !== '' && !iban.untouched && paymentScheme.value === '') ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      paymentScheme.value == null) ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      paymentScheme.value === '') ||
    (bic.value !== '' && !bic.untouched && paymentScheme.value == null) ||
    (bic.value !== '' && !bic.untouched && paymentScheme.value === '') ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      paymentScheme.value == null) ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      paymentScheme.value === '') ||
    (!paymentDate.untouched && paymentScheme.value === '') ||
    (!paymentDate.untouched && paymentScheme.value == null)
    ? { paymentSchemeMandatoryError: true }
    : null;
};

export const accountHolderValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const iban = control.get('iban');
  const paymentScheme = control.get('paymentScheme');
  const bic = control.get('bic');
  const paymentReference = control.get('paymentReference');
  const paymentDate = control.get('paymentDate');
  const accountHolder = control.get('accountHolder');

  return (iban.value == null && !iban.untouched) ||
    (paymentScheme.value == null && !paymentScheme.untouched) ||
    (bic.value == null && !bic.untouched) ||
    (paymentReference.value == null && !paymentReference.untouched) ||
    (iban.value !== '' && !iban.untouched && accountHolder.value == null) ||
    (iban.value !== '' && !iban.untouched && accountHolder.value === '') ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      accountHolder.value == null) ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      accountHolder.value === '') ||
    (bic.value !== '' && !bic.untouched && accountHolder.value == null) ||
    (bic.value !== '' && !bic.untouched && accountHolder.value === '') ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      accountHolder.value == null) ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      accountHolder.value === '') ||
    (!paymentDate.untouched && accountHolder.value === '') ||
    (!paymentDate.untouched && accountHolder.value == null)
    ? { accountHolderMandatoryError: true }
    : null;
};

export const paymentDateValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const iban = control.get('iban');
  const paymentScheme = control.get('paymentScheme');
  const bic = control.get('bic');
  const paymentReference = control.get('paymentReference');
  const accountHolder = control.get('accountHolder');
  const paymentDate = control.get('paymentDate');

  return (iban.value == null && !iban.untouched && paymentDate.untouched) ||
    (paymentScheme.value == null &&
      !paymentScheme.untouched &&
      paymentDate.untouched) ||
    (bic.value == null && !bic.untouched && paymentDate.untouched) ||
    (paymentReference.value == null &&
      !paymentReference.untouched &&
      paymentDate.untouched) ||
    (accountHolder.value == null &&
      !accountHolder.untouched &&
      paymentDate.untouched) ||
    (iban.value !== '' && !iban.untouched && paymentDate.untouched) ||
    (paymentScheme.value !== '' &&
      !paymentScheme.untouched &&
      paymentDate.untouched) ||
    (bic.value !== '' && !bic.untouched && paymentDate.untouched) ||
    (paymentReference.value !== '' &&
      !paymentReference.untouched &&
      paymentDate.untouched) ||
    (accountHolder.value !== '' &&
      !accountHolder.untouched &&
      paymentDate.untouched)
    ? { paymentDateMandatoryError: true }
    : null;
};
