export const documentManagementTableColumns: string[] = [
  'select',
  'documentType',
  'qsid',
  'vvvoNumber',
  'typeOfProduction',
  'accountNumber',
  'documentationId',
  'creationTime',
  'name',
  'documentName',
  'visible',
  'actions',
];
