<div class="content-container">
  <app-back-button></app-back-button>
  <div class="aw-open-item-crete-form">
    <div class="title-form">
      <h5 class="title-h5" id="{{ entityName }}-form-title">
        {{ 'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.OPEN-ITEM' | translate }}
      </h5>
    </div>
    <div class="input-wrapper form-container">
      <form class="form" [formGroup]="awOpenItemForm" autocomplete="off">
        <app-mat-select
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.SLAUGHTERHOUSE-QSID'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.SLAUGHTERHOUSE-QSID'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.SLAUGHTERHOUSE-QSID'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.entityFromGuid"
          [controlName]="'entityFromGuid'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="slaughterhouseIdOptions"
          [optionValue]="'id'"
          [selectedOption]="''"
          [translationOption]="'qsid'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'none'"
          [titlecasePipe]="false"
        ></app-mat-select>
        <app-mat-input
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.ENTITY-TO'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.ENTITY-TO'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.ENTITY-TO'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.entityToGuid"
          [controlName]="'entityToGuid'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-input-date
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.OPEN-ITEM-DATE'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.OPEN-ITEM-DATE'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.openItemDate"
          [controlName]="'openItemDate'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        >
        </app-mat-input-date>
        <app-mat-input
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.TRANSACTION-CODE'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.TRANSACTION-CODE'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.TRANSACTION-CODE'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.bookingCode"
          [controlName]="'bookingCode'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>

        <div
          *ngIf="
            !bookingCodeExists &&
            (awOpenItemForm.touched || awOpenItemForm.dirty) &&
            !awOpenItemForm.get('bookingCode').hasError('required')
          "
          class="input-error"
        >
          {{
            'ERROR-MESSAGES.INVALID'
              | translate
                : {
                    name: 'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.TRANSACTION-CODE' | translate
                  }
          }}
        </div>

        <app-mat-radio
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DEBIT-OR-CREDIT'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DEBIT-OR-CREDIT'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlName]="'debitOrCredit'"
          [controlObject]="awOpenItemForm.controls.debitOrCredit"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [items]="debitOrCreditOptions"
          [optionValue]="'value'"
          [titlecasePipe]="false"
        ></app-mat-radio>
        <app-mat-input
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.AMOUNT'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.AMOUNT'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.AMOUNT'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.amount"
          [controlName]="'amount'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [maskValue]="'separator.2'"
          [suffix]="'€'"
          [thousandSeparator]="'.'"
        ></app-mat-input>
        <app-mat-select
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.CURRENCY'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.CURRENCY'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.CURRENCY'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.currency"
          [controlName]="'currency'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="currencyCodes"
          [optionValue]="''"
          [selectedOption]="''"
          [translationOption]="''"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'none'"
        ></app-mat-select>
        <app-mat-input
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-AMOUNT'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-AMOUNT'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-AMOUNT'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.vatAmount"
          [controlName]="'vatAmount'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [maskValue]="'separator.2'"
          [suffix]="'€'"
          [thousandSeparator]="'.'"
        ></app-mat-input>

        <div
          *ngIf="
            awOpenItemForm.errors?.limitError &&
            (awOpenItemForm.touched || awOpenItemForm.dirty) &&
            !awOpenItemForm.get('vatAmount').hasError('required') &&
            !awOpenItemForm.get('vatAmount').hasError('invalid')
          "
          class="input-error"
        >
          {{
            'ERROR-MESSAGES.INVALID'
              | translate
                : {
                    name: 'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-AMOUNT' | translate
                  }
          }}
        </div>

        <app-mat-input
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-PERCENT'"
          [placeholder]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-PERCENT'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.VAT-PERCENT'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.vatPercent"
          [controlName]="'vatPercent'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [maskValue]="'percent'"
          [suffix]="'%'"
        >
        </app-mat-input>
        <app-mat-input-date
          [name]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DUE-DATE'"
          [label]="'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DUE-DATE'"
          [entityName]="entityName"
          [form]="awOpenItemForm"
          [controlObject]="awOpenItemForm.controls.dueDate"
          [controlName]="'dueDate'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        ></app-mat-input-date>

        <div
          *ngIf="
            awOpenItemForm.errors?.dateError &&
            (awOpenItemForm.touched || awOpenItemForm.dirty) &&
            !awOpenItemForm.get('dueDate').hasError('required') &&
            !awOpenItemForm.get('dueDate').hasError('invalid')
          "
          class="input-error"
        >
          {{
            'ERROR-MESSAGES.INVALID'
              | translate
                : {
                    name: 'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.OPEN-ITEM-CREATE.DUE-DATE' | translate
                  }
          }}
        </div>
      </form>
    </div>

    <div class="filter-input button-container">
      <ng-container *hasPermission="'bu.u'">
        <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button">
          {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
        </button>
      </ng-container>
      <ng-container *hasPermission="'bu.c'">
        <button mat-button class="custom-button" id="{{ entityName }}-create-button" (click)="onClickApproval()">
          <span>
            {{ 'GENERAL-ENTITY.CREATE.CREATE' | translate }}
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
