import { Component, OnInit, ViewChild } from '@angular/core';
import { TableConfiguration } from '../shared/editable-list-form/table-configuration';
import { EditableListFormComponent } from '../shared/editable-list-form/editable-list-form.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AwQsidValidator } from '../../../../shared/validators/aw-qsid-validator';
import { MasterDataService } from '../../../../shared/services/master-data/master-data.service';
import { transferOptions } from '../../../../in-memory-data/animal-welfare/quantity-goods-create/enum/transferOptions';
import { typeOptions } from '../../../../in-memory-data/animal-welfare/participation-fee/enum/typeOptions';
import { SlaughterhouseFiguresService } from '../../../../shared/services/participation-fee/slaughterhouse-figures.service';
import { DraftService } from '../../../../shared/services/draft/draft.service';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from '../../../../shared/utils/error-handler/custom-error-handler';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { Router } from '@angular/router';
import { GeneralDeleteConfirmationModalComponent } from '../../../../shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { TaskService } from '../../../../shared/services/task/task.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import moment from 'moment';
import { ChecksumService } from 'src/app/shared/wrappers-components/checksum/checksum.service';

@Component({
  selector: 'app-slaughterhouse-figures-create',
  templateUrl: './slaughterhouse-figures-create.component.html',
  styleUrls: ['./slaughterhouse-figures-create.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class SlaughterhouseFiguresCreateComponent implements OnInit {
  displayedColumns: string[] = [
    'qsId',
    'quantity',
    'positive',
    'quantityType',
    'reportingDate',
    'reportingPeriod',
    'auditorValue',
    'comment',
    'actions',
  ];
  tableConfiguration: TableConfiguration[] = [];
  @ViewChild(EditableListFormComponent)
  editableListFormComponent: EditableListFormComponent;
  form: UntypedFormGroup;
  entities: any[];
  taskView = false;
  guid: any;
  mode: 'CREATE' | 'UPDATE' | 'CANCEL';
  editableTask = false;
  entityName = 'slaughterhouseFigures';
  state = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private slaughterhouseFiguresService: SlaughterhouseFiguresService,
    private draftService: DraftService,
    private translateService: TranslateService,
    private customErrorHandler: CustomErrorHandler,
    private notificationService: NotificationService,
    private router: Router,
    private taskService: TaskService,
    private matDialogService: MatDialog,
    private checksumService: ChecksumService
  ) {
    this.form = this.formBuilder.group({
      objects: new UntypedFormArray([]),
    });

    this.loadTableConfiguration();
  }

  ngOnInit(): void {
    if (window.history.state.entryRows) {
      this.entities = window.history.state.entryRows;
      this.guid = window.history.state.guid;
      this.mode = 'UPDATE';
      this.state = false;
    } else if (window.history.state.task) {
      this.taskView = true;
      const task = window.history.state.task;
      this.mode = task.params.request;
      this.draftService.retrieveDraftForTask(task.guid).subscribe((data) => {
        const entitiesTmp = [];
        for (const entity of data) {
          entitiesTmp.push({
            data: entity,
            disabled: true,
          });
        }
        this.entities = entitiesTmp;
      });
      this.state = false;
    } else {
      this.entities = [];
      this.mode = 'CREATE';
      this.guid = null;
      this.state = false;
    }
    this.subscribeToChangesInForm();
  }

  getNewObject = (index): any => {
    return {
      qsid: '',
      quantity: '',
      quantityType: '',
      positive: '',
      reportingDate: '',
      reportingPeriod: '',
      auditorValue: false,
      comment: '',
      index,
      slaughterHouseGuid: '',
      id: '',
      reportingPeriodStart: '',
      reportingPeriodEnd: '',
    };
  };

  getFormRow = (object): any => {
    return this.formBuilder.group({
      // For the sake of easiness, the Validator will also set the GUID of Slaughterhouse if it is found
      qsId: [
        object.qsid,
        Validators.required,
        AwQsidValidator.createValidator(this.masterDataService),
      ],
      quantity: [
        { value: object.quantity, disabled: false },
        Validators.required,
      ],
      quantityType: [
        { value: object.type, disabled: false },
        Validators.required,
      ],
      positive: [
        { value: object.positive, disabled: false },
        Validators.required,
      ],
      reportingDate: [
        { value: object.reportingDate, disabled: false },
        Validators.required,
      ],
      reportingPeriodStart: [
        { value: object.reportingPeriodStart, disabled: false },
      ],
      reportingPeriodEnd: [
        { value: object.reportingPeriodEnd, disabled: false },
      ],
      auditorValue: [{ value: object.auditorValue, disabled: false }],
      comment: [{ value: object.comment, disabled: false }],
      slaughterHouseGuid: [object.slaughterHouseGuid],
      index: [{ value: object.index, disabled: false }, [Validators.required]],
      id: [object.id],
      status: [object.status],
      createdBy: [object.createdBy],
    });
  };

  setCorrectionOfData(value) {
    value.objects.forEach((val) => {
      if (val.reportingPeriodStart) {
        val.reportingPeriodStart = moment(val.reportingPeriodStart)
          .startOf('month')
          .format('YYYY-MM-DD');
      }
      if (val.reportingPeriodEnd) {
        val.reportingPeriodEnd = moment(val.reportingPeriodEnd)
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (val.reportingDate) {
        val.reportingDate = moment(val.reportingDate)
          .toDate()
          .setHours(0, 0, 0);
        val.reportingDate = moment(val.reportingDate).format('YYYY-MM-DD');
      }
    });
    return value;
  }

  getDisabledStatus() {
    let result = false;
    const objects = this.form.controls.objects as UntypedFormArray;
    if (this.form.controls.objects && objects.controls.length > 0) {
      objects.controls.forEach((item) => {
        if (!item.valid) {
          result = true;
        }
      });
    } else {
      result = true;
    }
    return result;
  }

  save() {
    if (this.mode === 'UPDATE') {
      this.slaughterhouseFiguresService
        .update(this.setCorrectionOfData(this.form.value), this.guid)
        .subscribe((data) => {
          this.successSaveHandler(data);
        }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.SLAUGHTERHOUSE-FIGURES'));
    } else {
      this.slaughterhouseFiguresService
        .create(this.setCorrectionOfData(this.form.value))
        .subscribe((data) => {
          this.successSaveHandler(data);
        }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.SLAUGHTERHOUSE-FIGURES'));
    }
  }

  makeEditable() {
    this.editableTask = true;
    this.taskService.updateEditableTask(true);
    if (this.mode === 'UPDATE') {
      this.editableListFormComponent.getFormObjects.controls[2].enable();
    } else if (this.mode === 'CREATE') {
      this.editableListFormComponent.getFormObjects.enable();
    }
  }

  cancelEditable() {
    if (this.editableListFormComponent.getFormObjects.touched) {
      const dialog = this.matDialogService.open(
        GeneralDeleteConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: {
            deleteMessage: this.translateService.instant(
              'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
            ),
          },
        }
      );
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.editableListFormComponent.reloadEditableList();
          this.editableTask = false;
          this.taskService.updateEditableTask(false);
          if (this.mode === 'UPDATE') {
            this.editableListFormComponent.getFormObjects.controls[2].disable();
          } else if (this.mode === 'CREATE') {
            this.editableListFormComponent.getFormObjects.disable();
          }
        }
      });
    } else {
      this.editableTask = false;
      this.taskService.updateEditableTask(false);
      if (this.mode === 'UPDATE') {
        this.editableListFormComponent.getFormObjects.controls[2].disable();
      } else if (this.mode === 'CREATE') {
        this.editableListFormComponent.getFormObjects.disable();
      }
    }
  }

  saveDraft() {
    const task = window.history.state.task;
    const rawValue = this.setCorrectionOfData(this.form.getRawValue());
    this.slaughterhouseFiguresService
      .updateDraft(
        rawValue,
        rawValue.objects[0].id,
        task.guid,
        task.params.request
      )
      .subscribe((_) => {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
        setTimeout(() => {
          this.router.navigateByUrl('tasks', {
            state: { success: true },
          });
        }, 1500);
      }, this.customErrorHandler._errorHandler('ENTITIES.PARTICIPATION-FEE.SLAUGHTERHOUSE-FIGURES'));
  }

  addEmptyRow() {
    this.editableListFormComponent.addEmptyRow();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.form.dirty && !this.state;
  }

  private successSaveHandler(data) {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ENTITIES.PARTICIPATION-FEE.SLAUGHTERHOUSE-FIGURES'
        ),
      }
    );
    this.state = true;
    this.router.navigateByUrl('awParticipationFee/slaughterhouseFigures', {
      state: { success: this.state },
    });
  }

  private loadTableConfiguration() {
    this.tableConfiguration.push({
      type: 'input',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.QSID',
      columnName: 'qsId',
    });
    this.tableConfiguration.push({
      type: 'input',
      inputType: 'number',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.QUANTITY',
      columnName: 'quantity',
    });
    this.tableConfiguration.push({
      type: 'select',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.TRANSFER',
      columnName: 'positive',
      options: transferOptions,
    });
    this.tableConfiguration.push({
      type: 'select',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.TYPE',
      columnName: 'quantityType',
      options: typeOptions.filter((type) =>
        type.availableIn.includes(this.entityName)
      ),
    });
    this.tableConfiguration.push({
      type: 'monthAndYear',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.DATE',
      columnName: 'reportingDate',
    });
    this.tableConfiguration.push({
      type: 'inputDateRange',
      translateLabel: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REPORTING-PERIOD',
      columnName: 'reportingPeriod',
      rangeStartName: 'reportingPeriodStart',
      rangeEndName: 'reportingPeriodEnd',
    });
    this.tableConfiguration.push({
      type: 'checkbox',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.AUDITOR-VALUE',
      columnName: 'auditorValue',
    });
    this.tableConfiguration.push({
      type: 'input',
      translateLabel: 'ANIMAL-WELFARE.PARTICIPATION-FEE.COMMENT',
      columnName: 'comment',
    });
    this.tableConfiguration.push({
      type: 'actions',
      columnName: 'actions',
    });
  }

  get rows(): UntypedFormArray {
    return this.form.get('objects') as UntypedFormArray;
  }

  /**
   * Subscribing to changes in form and update the checksum component.
   */
  private subscribeToChangesInForm() {
    this.form.valueChanges.subscribe(() => {
      this.checksumService.updateChecksumData(
        this.rows,
        'quantity',
        'positive'
      );
    });
  }
}
