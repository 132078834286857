import { Component } from '@angular/core';

@Component({
  selector: 'app-non-germany-reference-allow-list',
  templateUrl: './non-germany-reference-allow-list.component.html',
  styleUrls: ['./non-germany-reference-allow-list.component.less'],
})
export class NonGermanyReferenceAllowListComponent {
  entityName = 'non-germany-reference-allow-list';
}
