<div class="input-wrapper form-container">
  <form [formGroup]="frmUser" autocomplete="off" class="form">
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.keycloakUserId : null"
      [controlName]="'keycloakUserId'"
      [controlObject]="frmUser.controls.keycloakUserId"
      [disabled]="true"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [taskValue]="userTask ? userTask.keycloakUserId : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.userName : null"
      [controlName]="'userName'"
      [controlObject]="frmUser.controls.userName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.USERNAME'"
      [name]="'USER-MANAGEMENT.USERNAME'"
      [placeholder]="'USER-MANAGEMENT.USERNAME'"
      [taskValue]="userTask ? userTask.userName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.email : null"
      [controlName]="'email'"
      [controlObject]="frmUser.controls.email"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.EMAIL'"
      [name]="'USER-MANAGEMENT.EMAIL'"
      [placeholder]="'USER-MANAGEMENT.EMAIL'"
      [taskValue]="userTask ? userTask.email : null"
      [titleTask]="false"
      [type]="'email'"
    >
    </app-mat-input>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.title : null"
      [controlName]="'title'"
      [controlObject]="frmUser.controls.title"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.TITLE'"
      [name]="'USER-MANAGEMENT.TITLE'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="titles"
      [placeholder]="'USER-MANAGEMENT.TITLE'"
      [selectedOption]="null"
      [taskValue]="userTask ? userTask.title : null"
      [textTransform]="'none'"
      [titleTask]="false"
      [titlecasePipe]="false"
      [translationOption]="'translateName'"
    ></app-mat-select>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.academicTitle : null"
      [controlName]="'academicTitle'"
      [controlObject]="frmUser.controls.academicTitle"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [name]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="academicTitles"
      [placeholder]="'USER-MANAGEMENT.ACADEMIC-TITLE'"
      [selectedOption]="null"
      [taskValue]="userTask ? userTask.academicTitle : null"
      [titleTask]="false"
      [translationOption]="'translateName'"
    ></app-mat-select>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.firstName : null"
      [controlName]="'firstName'"
      [controlObject]="frmUser.controls.firstName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.FIRST-NAME'"
      [name]="'USER-MANAGEMENT.FIRST-NAME'"
      [placeholder]="'USER-MANAGEMENT.FIRST-NAME'"
      [taskValue]="userTask ? userTask.firstName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.lastName : null"
      [controlName]="'lastName'"
      [controlObject]="frmUser.controls.lastName"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.LAST-NAME'"
      [name]="'USER-MANAGEMENT.LAST-NAME'"
      [placeholder]="'USER-MANAGEMENT.LAST-NAME'"
      [taskValue]="userTask ? userTask.lastName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.telephoneNumber : null"
      [controlName]="'telephoneNumber'"
      [controlObject]="frmUser.controls.telephoneNumber"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [name]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [placeholder]="'USER-MANAGEMENT.PHONE-NUMBER'"
      [taskValue]="userTask ? userTask.telephoneNumber : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.faxNumber : null"
      [controlName]="'faxNumber'"
      [controlObject]="frmUser.controls.faxNumber"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.FAX-NUMBER'"
      [name]="'USER-MANAGEMENT.FAX-NUMBER'"
      [placeholder]="'USER-MANAGEMENT.FAX-NUMBER'"
      [taskValue]="userTask ? userTask.faxNumber : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.birthday : null"
      [controlName]="'birthday'"
      [controlObject]="frmUser.controls.birthday"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.BIRTHDAY'"
      [maxDate]="null"
      [minDate]="null"
      [name]="'USER-MANAGEMENT.BIRTHDAY'"
      [placeholder]="'USER-MANAGEMENT.BIRTHDAY'"
      [taskValue]="userTask ? userTask.birthday : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-select
      [approvedValue]="approvedValue ? approvedValue.team : null"
      [controlName]="'team'"
      [controlObject]="frmUser.controls.team"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.TEAM'"
      [name]="'USER-MANAGEMENT.TEAM'"
      [optionTranslationText]="''"
      [optionValue]="'name'"
      [options]="teams"
      [placeholder]="'USER-MANAGEMENT.TEAM'"
      [selectedOption]="null"
      [taskValue]="userTask ? userTask.team : null"
      [titleTask]="false"
      [translationOption]="'translateName'"
    >
    </app-mat-select>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.servicePin : null"
      [controlName]="'servicePin'"
      [controlObject]="frmUser.controls.servicePin"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.SERVICE-PIN'"
      [name]="'USER-MANAGEMENT.SERVICE-PIN'"
      [placeholder]="'USER-MANAGEMENT.SERVICE-PIN'"
      [taskValue]="userTask ? userTask.servicePin : null"
      [titleTask]="false"
    ></app-mat-input>
    <span
      (click)="showServicePinPolicy()"
      *ngIf="!isTask"
      class="extra-info"
      id="{{ entityName }}-service-pin-info"
      mat-icon-button
    >
      <mat-icon>info</mat-icon>
    </span>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.createdOn : null"
      [controlName]="'createdOn'"
      [controlObject]="frmUser.controls.createdOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-ON'"
      [name]="'USER-MANAGEMENT.CREATED-ON'"
      [placeholder]="'USER-MANAGEMENT.CREATED-ON'"
      [taskValue]="userTask ? userTask.createdOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.createdBy : null"
      [controlName]="'createdBy'"
      [controlObject]="frmUser.controls.createdBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.CREATED-BY'"
      [name]="'USER-MANAGEMENT.CREATED-BY'"
      [placeholder]="'USER-MANAGEMENT.CREATED-BY'"
      [taskValue]="userTask ? userTask.createdBy : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.updatedOn : null"
      [controlName]="'updatedOn'"
      [controlObject]="frmUser.controls.updatedOn"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.UPDATED-ON'"
      [name]="'USER-MANAGEMENT.UPDATED-ON'"
      [placeholder]="'USER-MANAGEMENT.UPDATED-ON'"
      [taskValue]="userTask ? userTask.updatedOn : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.updatedBy : null"
      [controlName]="'updatedBy'"
      [controlObject]="frmUser.controls.updatedBy"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.UPDATED-BY'"
      [name]="'USER-MANAGEMENT.UPDATED-BY'"
      [placeholder]="'USER-MANAGEMENT.UPDATED-BY'"
      [taskValue]="userTask ? userTask.updatedBy : null"
      [titleTask]="false"
    ></app-mat-input>
    <div *ngIf="approvedValue && !isTask" class="status">
      <div class="form-row">
        <mat-slide-toggle [checked]="approvedValue.online" [disabled]="true"
          ><span class="material-icons material-check-icon"> done </span></mat-slide-toggle
        >
        <label class="label-slide-toggle">{{ 'USER-MANAGEMENT.ONLINE' | translate }}</label>
      </div>
      <div class="form-row">
        <mat-form-field appearance="fill">
          <mat-label
            ><span>{{ 'USER-MANAGEMENT.STATUS' | translate }}</span></mat-label
          >
          <input
            [disabled]="true"
            matInput
            value="{{ 'USER-MANAGEMENT.STATUS-VALUES.' + approvedValue.status | translate }}"
          />
        </mat-form-field>
      </div>
    </div>

    <app-mat-input-date
      [approvedValue]="approvedValue ? approvedValue.validTo : null"
      [controlName]="'validTo'"
      [controlObject]="frmUser.controls.validTo"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmUser"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.VALID-TO'"
      [maxDate]="null"
      [minDate]="null"
      [name]="'USER-MANAGEMENT.VALID-TO'"
      [placeholder]="'USER-MANAGEMENT.VALID-TO'"
      [taskValue]="userTask ? userTask.validTo : null"
      [titleTask]="false"
    ></app-mat-input-date>
    <div *ngIf="approvedValue && !isTask" class="status">
      <div class="form-row">
        <mat-slide-toggle [checked]="approvedValue.termsAndServicesAccepted" [disabled]="true"
          ><span class="material-icons material-check-icon"> done </span></mat-slide-toggle
        >
        <label class="label-slide-toggle">{{ 'USER-MANAGEMENT.TERMS-AND-SERVICES-ACCEPTED' | translate }}</label>
      </div>
      <div class="form-row">
        <mat-slide-toggle [checked]="approvedValue.inIniProcess" [disabled]="true"
          ><span class="material-icons material-check-icon"> done </span></mat-slide-toggle
        >
        <label class="label-slide-toggle">{{ 'USER-MANAGEMENT.IN-INI-PROCESS' | translate }}</label>
      </div>
      <div class="form-row">
        <mat-slide-toggle [checked]="false" [disabled]="true"
          ><span class="material-icons material-check-icon"> done </span></mat-slide-toggle
        >
        <label class="label-slide-toggle">{{ 'USER-MANAGEMENT.EXTERNAL-SOURCE' | translate }}</label>
      </div>
    </div>
  </form>
</div>
