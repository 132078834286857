<div class="dialog-content">
  <div mat-dialog-content class="background-dark margin-container">
    <span class="material-icons custom-icon"> help </span>
    <span class="delete-message">{{ deleteMessage }}</span>
  </div>

  <div class="buttons">
    <button mat-button class="custom-button-cancel" [mat-dialog-close]="false">
      {{ 'GENERAL.FALSE' | translate }}
    </button>
    <button mat-button class="custom-button" [mat-dialog-close]="true" style="margin-right: 0">
      {{ 'GENERAL.TRUE' | translate }}
    </button>
  </div>
</div>
