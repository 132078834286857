<div class="mat-menu-container">
  <div class="table-conf-header">
    <span>{{ 'CONFIGURE-TABLE' | translate }}</span>
  </div>
  <div cdkDropList class="mat-menu-top-border" (cdkDropListDropped)="drop($event)" [cdkDropListData]="columns">
    <div cdkDrag class="border-row" *ngFor="let column of columns">
      <div *ngIf="!column.locked && column.hasOwnProperty('name')">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [checked]="column.checked"
          (change)="modifyColumn(column.name, column.index, $event)"
          class="config-table-checkbox"
        >
          {{ column.translateName | translate: { entity: column.translateParams | translate } }}
        </mat-checkbox>
        <div class="div-drag-handle">
          <mat-icon inline="true">drag_handle</mat-icon>
        </div>
      </div>
      <div *ngIf="column.locked && column.hasOwnProperty('name')">
        <div class="mat-checkbox config-table-checkbox">
          <div class="disabled-menu-item">
            <mat-icon inline="true">lock</mat-icon>
            <span> {{ column.translateName | translate }}</span>
          </div>
        </div>
        <div class="div-drag-handle">
          <mat-icon inline="true">drag_handle</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
