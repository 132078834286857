import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Debtor } from '../../models/debtor';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { TaskEventType } from '../../models/task-event-type';

@Injectable({
  providedIn: 'root',
})
export class DebtorService {
  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.debtorService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  serviceUrl;
  private readonly taskServiceUrl;

  mapOfColumnFilters = [];
  private searchString = '';

  pageSize;
  sortDirection;
  sortOrder;

  setSearchString(searchString: any) {
    this.searchString = searchString;
  }

  setColumnFilter(choice, selection) {
    const index = this.mapOfColumnFilters.findIndex(
      (el) => el.choice === choice
    );
    if (index !== -1) {
      this.mapOfColumnFilters[index] = {
        choice,
        values: selection,
      };
    } else {
      this.mapOfColumnFilters.push({
        choice,
        values: selection,
      });
    }
  }

  getColumnFilters() {
    let searchString = '';
    this.mapOfColumnFilters.forEach((entry) => {
      searchString = searchString + '&' + entry.choice + '=' + entry.values;
    });
    return searchString;
  }

  createDebtor(debtor) {
    return this.http.post(
      this.serviceUrl + 'debtors',
      debtor,
      this.buildHeaders()
    );
  }

  uploadSepaMandateFiles(formData: FormData[]) {
    const req = new HttpRequest(
      'POST',
      this.serviceUrl + 'files/upload',
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  getAllDebtors(sortedBy, sortDirection, page, pageSize): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'debtors/filter?sortedBy=' +
        sortedBy +
        '&sortDirection=' +
        sortDirection +
        '&page=' +
        page +
        '&pageSize=' +
        pageSize +
        '&searchString=' +
        this.searchString +
        this.getColumnFilters(),
      this.buildHeaders()
    );
  }

  getAllDebtorsForAccounting(
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'debtors/list?sortedBy=' +
        sortedBy +
        '&sortDirection=' +
        sortDirection +
        '&page=' +
        page +
        '&pageSize=' +
        pageSize +
        '&searchString=' +
        this.searchString +
        this.getColumnFilters(),
      this.buildHeaders()
    );
  }

  getDebtorsForCompany(id): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'debtors/companyDebtors?companyId=' + id,
      this.buildHeaders()
    );
  }

  updateDebtor(debtor) {
    return this.http.put(
      this.serviceUrl + 'debtors/' + debtor.debtor.id,
      debtor,
      this.buildHeaders()
    );
  }

  getDebtorsForClient(id): Observable<any[]> {
    return this.http.get<Debtor[]>(
      this.serviceUrl + 'debtors/clientDebtors?clientId=' + id,
      this.buildHeaders()
    );
  }

  getDebtorsForPlatform(id): Observable<any[]> {
    return this.http.get<Debtor[]>(
      this.serviceUrl + 'debtors/platformDebtors?platformId=' + id,
      this.buildHeaders()
    );
  }

  getDebtorById(id): Observable<any> {
    return this.http.get<Debtor>(
      this.serviceUrl + 'debtors/' + id,
      this.buildHeaders()
    );
  }

  getDebtorByGuid(guid) {
    return this.http.get<Debtor>(
      this.serviceUrl + `debtors/by-guid/${guid}`,
      this.buildHeaders()
    );
  }

  getDebtorByClientDebtorIdAndClientId(id, clientId) {
    return this.http.get<Debtor>(
      this.serviceUrl +
        'debtors/by-client-debtor-id-client-id/' +
        id +
        '/' +
        clientId,
      this.buildHeaders()
    );
  }

  clientDebtorIdExists(id, clientId) {
    return this.http.get<Debtor>(
      this.serviceUrl +
        'debtors/client-debtor-id-exists/' +
        id +
        '/' +
        clientId,
      this.buildHeaders()
    );
  }

  retrieveImportedDebtorByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/' + taskId + '/imported-debtor',
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }

  addLimit(value: any) {
    return this.http.put(
      this.serviceUrl + 'debtors/limit',
      {
        debtorId: value.element.debtorId,
        clientId: value.element.id,
        limitAmount: value.limitAmount,
      },
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      environment.settings.taskService + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  filter(sortedBy, sortDirection, page, pageSize): Observable<any> {
    let url = this.serviceUrl + 'debtors/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  filterForAccounting(
    sortedBy,
    sortDirection,
    page,
    pageSize
  ): Observable<any> {
    let url = this.serviceUrl + 'debtors/list/filter';
    url +=
      '?sortedBy=' +
      sortedBy +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize +
      '&searchString=' +
      this.searchString +
      this.getColumnFilters();

    return this.http.get<any>(url, this.buildHeaders());
  }

  getFilterFieldNameValues(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'debtors/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }

  getFilterFieldNameValuesForAccounting(fieldName: string) {
    return this.http.get(
      this.serviceUrl + 'debtors/list/filter-values?fieldName=' + fieldName,
      this.buildHeaders()
    );
  }
}
