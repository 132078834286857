<app-back-button [address]="'userManagement/permission'"> </app-back-button>

<div *ngIf="realm" class="input-wrapper form-container">
  <form [formGroup]="frmExcludedPermission" autocomplete="off" class="form">
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.id : null"
      [controlName]="'id'"
      [controlObject]="frmExcludedPermission.controls.id"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmExcludedPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [taskValue]="excludedPermissionTask ? excludedPermissionTask.id : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.name : null"
      [controlName]="'name'"
      [controlObject]="frmExcludedPermission.controls.name"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmExcludedPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.EXCLUDED-PERMISSION-NAME'"
      [name]="'USER-MANAGEMENT.EXCLUDED-PERMISSION-NAME'"
      [placeholder]="'USER-MANAGEMENT.EXCLUDED-PERMISSION-NAME'"
      [taskValue]="excludedPermissionTask ? excludedPermissionTask.name : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-textarea
      [approvedValue]="approvedValue ? approvedValue.description : null"
      [controlName]="'description'"
      [controlObject]="frmExcludedPermission.controls.description"
      [editableTask]="false"
      [entityName]="entityName"
      [form]="frmExcludedPermission"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.DESCRIPTION'"
      [name]="'USER-MANAGEMENT.DESCRIPTION'"
      [placeholder]="'USER-MANAGEMENT.DESCRIPTION'"
      [taskValue]="excludedPermissionTask ? excludedPermissionTask.description : null"
      [titleTask]="false"
    ></app-mat-textarea>
    <span *ngIf="approvedValue">
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.createdOn : null"
        [controlName]="'createdOn'"
        [controlObject]="frmExcludedPermission.controls.createdOn"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmExcludedPermission"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.CREATED-ON'"
        [name]="'USER-MANAGEMENT.CREATED-ON'"
        [placeholder]="'USER-MANAGEMENT.CREATED-ON'"
        [taskValue]="excludedPermissionTask ? excludedPermissionTask.createdOn : null"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.createdBy : null"
        [controlName]="'createdBy'"
        [controlObject]="frmExcludedPermission.controls.createdBy"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmExcludedPermission"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.CREATED-BY'"
        [name]="'USER-MANAGEMENT.CREATED-BY'"
        [placeholder]="'USER-MANAGEMENT.CREATED-BY'"
        [taskValue]="excludedPermissionTask ? excludedPermissionTask.createdBy : null"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.updatedOn : null"
        [controlName]="'updatedOn'"
        [controlObject]="frmExcludedPermission.controls.updatedOn"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmExcludedPermission"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.UPDATED-ON'"
        [name]="'USER-MANAGEMENT.UPDATED-ON'"
        [placeholder]="'USER-MANAGEMENT.UPDATED-ON'"
        [taskValue]="excludedPermissionTask ? excludedPermissionTask.updatedOn : null"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.updatedBy : null"
        [controlName]="'updatedBy'"
        [controlObject]="frmExcludedPermission.controls.updatedBy"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmExcludedPermission"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.UPDATED-BY'"
        [name]="'USER-MANAGEMENT.UPDATED-BY'"
        [placeholder]="'USER-MANAGEMENT.UPDATED-BY'"
        [taskValue]="excludedPermissionTask ? excludedPermissionTask.updatedBy : null"
        [titleTask]="false"
      ></app-mat-input>
    </span>
    <div *ngIf="!isTask">
      <div class="all-permissions">
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              [(ngModel)]="searchStringAll"
              [ngModelOptions]="{ standalone: true }"
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              type="text"
              removeHtmlTagsForNgModel
            />
          </mat-form-field>
          <div class="entries">
            <span *ngIf="searchRunning">
              <mat-spinner [diameter]="20"></mat-spinner>
            </span>
            <mat-selection-list
              #selectedGroups
              *ngIf="!searchRunning"
              [(ngModel)]="selectedPermissions"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-list-option
                *ngFor="let permission of permissions"
                [disabled]="isSelected(permission)"
                [selected]="isSelected(permission)"
                [value]="permission.permissionId"
              >
                {{ permission.permissionId }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div class="buttons">
          <button (click)="addToUser()" class="add-button" mat-button>
            {{ 'GENERAL.ADD' | translate }}
          </button>
          <button (click)="removeFromUser()" class="add-button" mat-button>
            {{ 'GENERAL.REMOVE' | translate }}
          </button>
        </div>
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              [(ngModel)]="searchStringActive"
              [ngModelOptions]="{ standalone: true }"
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              type="text"
              removeHtmlTagsForNgModel
            />
          </mat-form-field>
          <div class="entries">
            <mat-selection-list
              #selectedGroups
              (selectionChange)="onGroupsChange(selectedGroups.selectedOptions.selected)"
            >
              <mat-list-option *ngFor="let permission of formPermissions" [value]="permission">
                {{ permission }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<button (click)="createExcludedPermission()" *ngIf="!isTask && realm" class="custom-button create" mat-button>
  {{ 'GENERAL.CREATE' | translate }}
</button>
