<section class="bank-account-container">
  <div *ngIf="!isTask || (isTask && editableTask)">
    <div class="input-wrapper form-container">
      <button
        mat-button
        class="custom-button add-button"
        *ngIf="!showForm"
        [disabled]="isDraftExists || animalWelfareEditView || isEditDeniedDueToOrigin() || readOnly"
        (click)="showBankAccountForm()"
      >
        {{ 'COMPANY.ADD-BANK-ACCOUNT' | translate }}
      </button>
      <form action="" class="form" [formGroup]="bankAccountForm" autocomplete="off" *ngIf="showForm">
        <app-mat-input
          [name]="'COMPANY.BANK-ACCOUNT.ACCOUNT-HOLDER'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.ACCOUNT-HOLDER'"
          [label]="'COMPANY.BANK-ACCOUNT.ACCOUNT-HOLDER'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.accountHolder"
          [controlName]="'accountHolder'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.accountHolder : null"
          [taskValue]="bankAccountTask ? bankAccountTask.accountHolder : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.BANK-ACCOUNT.IBAN'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.IBAN'"
          [label]="'COMPANY.BANK-ACCOUNT.IBAN'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.iban"
          [controlName]="'iban'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.iban : null"
          [taskValue]="bankAccountTask ? bankAccountTask.iban : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [titlecasePipe]="false"
          [textTransform]="'uppercase'"
          [disabled]="readOnly"
        >
        </app-mat-input>
        <app-mat-input
          [name]="'COMPANY.BANK-ACCOUNT.BIC'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.BIC'"
          [label]="'COMPANY.BANK-ACCOUNT.BIC'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.bic"
          [controlName]="'bic'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.bic : null"
          [taskValue]="bankAccountTask ? bankAccountTask.bic : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [titlecasePipe]="false"
          [textTransform]="'uppercase'"
          [disabled]="readOnly"
        >
        </app-mat-input>
        <app-mat-input
          [name]="'COMPANY.BANK-ACCOUNT.BANK'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.BANK'"
          [label]="'COMPANY.BANK-ACCOUNT.BANK'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.bank"
          [controlName]="'bank'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.bank : null"
          [taskValue]="bankAccountTask ? bankAccountTask.bank : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input-date
          [name]="'COMPANY.BANK-ACCOUNT.VALID-FROM'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.VALID-FROM'"
          [label]="'COMPANY.BANK-ACCOUNT.VALID-FROM'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.validFrom"
          [controlName]="'validFrom'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.validFrom : null"
          [taskValue]="bankAccountTask ? bankAccountTask.validFrom : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
          [disabled]="readOnly"
        ></app-mat-input-date>
        <app-mat-input-date
          [name]="'COMPANY.BANK-ACCOUNT.VALID-TO'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.VALID-TO'"
          [label]="'COMPANY.BANK-ACCOUNT.VALID-TO'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.validTo"
          [controlName]="'validTo'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.validTo : null"
          [taskValue]="bankAccountTask ? bankAccountTask.validTo : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
          [disabled]="readOnly"
        ></app-mat-input-date>
        <app-mat-select
          [name]="'COMPANY.BANK-ACCOUNT.DISBURSEMENT-TYPE'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.DISBURSEMENT-TYPE'"
          [label]="'COMPANY.BANK-ACCOUNT.DISBURSEMENT-TYPE'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.disbursementType"
          [controlName]="'disbursementType'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.disbursementType : null"
          [taskValue]="bankAccountTask ? bankAccountTask.disbursementType : null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="disbursementTypes"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'none'"
          [disabled]="readOnly"
        ></app-mat-select>
        <app-mat-select
          [name]="'COMPANY.BANK-ACCOUNT.CURRENCY'"
          [placeholder]="'COMPANY.BANK-ACCOUNT.CURRENCY'"
          [label]="'COMPANY.BANK-ACCOUNT.CURRENCY'"
          [entityName]="entityName"
          [form]="bankAccountForm"
          [controlObject]="bankAccountForm.controls.currency"
          [controlName]="'currency'"
          [approvedValue]="bankAccountApproved ? bankAccountApproved.currency : null"
          [taskValue]="bankAccountTask ? bankAccountTask.currency : null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="currencies"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'uppercase'"
          [disabled]="readOnly"
        >
        </app-mat-select>
        <div class="form-row margin-row">
          <div class="spinner-container" *ngIf="uploadProgress">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
          <app-file-upload
            [hidden]="uploadProgress"
            [uploadedFiles]="uploadedFiles"
            formControlName="attachment"
            [multiple]="multiple"
            [transaction]="showAttachments"
          ></app-file-upload>
          <div class="upload-attachment-container">
            <app-attachments
              [attachments]="attachments"
              [transactionId]="bankAccountId"
              [attachmentType]="'companyBankAccount'"
            ></app-attachments>
          </div>
        </div>
        <div class="action-buttons">
          <button
            id="{{ entityName }}-form-save-button"
            mat-button
            class="custom-button save-button"
            (click)="$event.preventDefault(); saveBankAccount()"
            [disabled]="bankAccountForm.invalid || readOnly"
          >
            {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
          </button>
          <button
            id="{{ entityName }}-form-cancel-button"
            mat-button
            class="custom-button-cancel cancel-button"
            (click)="$event.preventDefault(); clear()"
          >
            {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="company-bank-account-list" [hidden]="showForm || noExistingData">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-bank-accounts-table"
      multiTemplateDataRows
      aria-hidden="true"
    >
      <ng-container matColumnDef="accountHolder">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[0] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.ACCOUNT-HOLDER' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'accountHolder')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[0] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.accountHolder }}
        </td>
      </ng-container>

      <ng-container matColumnDef="iban">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[1] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.IBAN' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'iban')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[1] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.iban }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bic">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[2] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.BIC' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'bic')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[2] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.bic }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bank">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[3] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.BANK' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'bank')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[3] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.bank }}
        </td>
      </ng-container>

      <ng-container matColumnDef="validFrom">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[4] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.VALID-FROM' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'validFrom')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[4] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.validFrom | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="validTo">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[5] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.VALID-TO' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'validTo')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[5] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.validTo | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="disbursementType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[6] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.DISBURSEMENT-TYPE-HEADER' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'disbursementType')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[6] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.disbursementType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="currency">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[7] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.CURRENCY' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasBankAccountChanged(element, 'currency')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[7] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.currency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attachment">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[8] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.ATTACHMENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[8] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <img src="../../../../../assets/images/ic_attachment.svg" alt="attachment icon" class="attachment-icon" />
          <span *ngIf="element && element.attachment">
            {{ '(' + element.attachment.length + ')' }}
          </span>
          <span *ngIf="!element.attachment">(0)</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="isCreatedExternally">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          scope="col"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[9] }}"
        >
          {{ 'COMPANY.BANK-ACCOUNT.IS-CREATED-EXTERNALLY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[9] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-slide-toggle [checked]="element.createdExternally" [disabled]="true"> </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" scope="col">
          <button
            mat-icon-button
            [matMenuTriggerFor]="appMenu"
            id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[10] }}"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell class="three-dot-actions" *matCellDef="let row; let i = index">
          <button
            *ngIf="!isTask || (isTask && editableTask)"
            class="menu"
            [disabled]="animalWelfareEditView || isEditDeniedDueToOrigin()"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ element: row }"
            (click)="$event.stopPropagation()"
            id="{{ entityName }}-table-bank-accounts-{{ displayedColumns[10] }}-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <span class="material-icons"> more_horiz </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="expanded-row" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="attachment-container">
              <ng-container>
                <app-attachments
                  [attachments]="attachments"
                  [transactionId]="bankAccountId"
                  [attachmentType]="'companyBankAccount'"
                ></app-attachments>
              </ng-container>
              <div *ngIf="attachments.length == 0">
                <span class="no-attachment">
                  {{ 'COMPANY.BANK-ACCOUNT.NO-ATTACHMENT' | translate }}
                </span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns; let i = index"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element; loadAttachments(element)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>

  <mat-menu #menu="matMenu" class="menu-actions">
    <ng-template matMenuContent let-element="element">
      <button
        mat-menu-item
        (click)="edit(element)"
        [disabled]="isDraftExists || readOnly"
        id="{{ entityName }}-table-bank-accounts-action-menu-edit-button"
      >
        <span translate> <mat-icon>edit</mat-icon>GENERAL.EDIT </span>
      </button>
    </ng-template>
  </mat-menu>
  <mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>
</section>
