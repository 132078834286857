import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { stringToKeyValue } from '@angular/flex-layout/extended/style/style-transforms';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { openTransactionEditableTableFieldsConf } from 'src/app/in-memory-data/open-transaction/editable-table-configuration';
import { openTransactionColumns } from 'src/app/in-memory-data/open-transaction/table-columns';
import { openTransactionColumnsConf } from 'src/app/in-memory-data/open-transaction/table-columns-configuration';
import { ChecksumService } from 'src/app/shared/wrappers-components/checksum/checksum.service';
import { AddDeductionModalComponent } from 'src/app/shared/modals/add-deduction-modal/add-deduction-modal.component';
import { AddDunningModalComponent } from 'src/app/shared/modals/add-dunning-modal/add-dunning-modal.component';
import { BusinessPartnerSelectionModalComponent } from 'src/app/shared/modals/business-partner-selection-modal/business-partner-selection-modal.component';
import { BusinessRelationshipSelectionModalComponent } from 'src/app/shared/modals/business-relationship-selection-modal/business-relationship-selection-modal.component';
import { OpenTransactionMultiEditModalComponent } from 'src/app/shared/modals/open-transaction-multi-edit-modal/open-transaction-multi-edit-modal.component';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { DebtorService } from 'src/app/shared/services/debtor/debtor.service';
import { DefaultTableSelectionType } from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { TransactionCodeAllocationService } from 'src/app/shared/services/transaction-code-allocation/transaction-code-allocation.service';
import { DefaultEditableTableComponent } from 'src/app/shared/tables/default-editable-table/default-editable-table.component';
import { DefaultTableConfiguration } from 'src/app/shared/tables/default-editable-table/table-configuration';
import { TablesService } from 'src/app/shared/tables/tables.service';
import {
  DateValidator,
  pastDateValidator,
} from 'src/app/shared/validators/date-validator';
import { GeneralValidator } from 'src/app/shared/validators/general-validator';
import { OpenTransactionBookingTableService } from '../services/open-transaction-booking-table.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-open-transaction-booking-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './open-transaction-booking-table.component.html',
  styleUrls: ['./open-transaction-booking-table.component.less'],
})
export class OpenTransactionBookingTableComponent implements OnInit, OnDestroy {
  entityName = 'open-transaction-booking-table';

  tabularForm: UntypedFormGroup;
  entities: any[] = [];
  mode = 'CREATE';
  form: UntypedFormGroup;
  columns = openTransactionColumnsConf;
  displayedColumns = openTransactionColumns;
  @ViewChild(DefaultEditableTableComponent)
  defaultEditableTableComponent;
  isTask = false;
  editableTask;
  state;
  businessUnits: any;
  businessUnitProduct: any;
  businessUnitGuid: any;
  businessRelationships: any[];
  pageSize = 25;

  CONFIGURATION_KEY = 'open_transaction_editable_table';

  tableFieldsConfiguration: DefaultTableConfiguration[] =
    openTransactionEditableTableFieldsConf;
  listOfActionsButtons = [
    {
      name: 'addDeduction',
      hasPermission: null,
      icon: 'edit',
      translationKey: 'ADD-DEDUCTION',
      function: (param) => {
        this.addDeduction(param);
      },
      selectionType: DefaultTableSelectionType.single,
    },
    {
      name: 'delete',
      hasPermission: null,
      icon: 'delete_outline',
      translationKey: 'ADD-DUNNING',
      function: (param) => {
        this.addDunningDate(param);
      },
      selectionType: DefaultTableSelectionType.single,
    },
  ];

  countryCodes: string[];
  cc = require('currency-codes');

  sharedOpenTransactionRows: any;
  sharedDataSource: any;
  sharedCommonForm: any;
  sharedSelectedRows: any[] = [];
  selectedBusinessPartner: any;
  entitiesLoaded = false;

  taskValue: any[] = [];
  subscription: Subscription;

  @ViewChild('inputScrollSelect') toggleButton: ElementRef;
  @ViewChild('infiniteScrollContainerSelect') menu: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog,
    private tableService: TablesService,
    private generalValidator: GeneralValidator,
    private dateValidator: DateValidator,
    public sharedDataService: SharedDataService,
    private businessUnitService: BusinessUnitService,
    private openItemService: OpenItemService,
    private transactionCodeAllocationService: TransactionCodeAllocationService,
    private debtorService: DebtorService,
    private changeDetectorRef: ChangeDetectorRef,
    private taskService: TaskService,
    private draftService: DraftService,
    private openTransactionBookingTableService: OpenTransactionBookingTableService,
    private checksumService: ChecksumService
  ) {}

  ngOnDestroy(): void {
    this.tableService.suscriptionChangesInFormEmitter.unsubscribe();
    this.tableService.suscriptionInfiniteScrollEmitter.unsubscribe();
    this.sharedDataService.changesInFormFunction.unsubscribe();
    this.tableService.suscriptionInsideSearchEmitter.unsubscribe();
    this.sharedDataService.suscriptionClearTableEmitter.unsubscribe();
    this.tableService.updateEntitiesLoadedState(false);
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.state = window.history.state;
    if (this.state.task) {
      this.isTask = true;
      this.tableService.updateFormState({ disable: true });
      this.loadTaskData();
    } else {
      this.tableService.updateEntitiesLoadedState(true);
    }
    this.businessUnits = this.businessUnitService.getAllBusinessUnitsLocal();
    this.businessUnitProduct = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).product;
    this.businessUnitGuid = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).guid;
    this.tabularFormBuilder();
    this.editableTableFormBuilder();
    this.listenToSubbscriptions();
    this.sortCountryCodes();
    this.subscription = this.tableService.currentSharedEntitiesLoaded.subscribe(
      (data) => {
        this.entitiesLoaded = data;
      }
    );
    this.getTransactionCodes();
    this.subscribeToDisplayedColumnsChange();
  }

  loadTaskData() {
    this.draftService
      .retrieveDraftForTask(this.state.task.guid)
      .subscribe((data) => {
        data['openItemsBatch']['openItems'].map((row) => {
          this.entities.push({
            data: row,
          });
        });
        this.tableService.updateEntitiesLoadedState(true);
        this.changeDetectorRef.detectChanges();
      });
  }

  sortCountryCodes() {
    this.countryCodes = this.cc.codes();

    const usdIndex = this.countryCodes.findIndex((data) => data === 'USD');
    const usd = this.countryCodes.find((data) => data === 'USD');

    this.countryCodes.splice(usdIndex, 1);
    this.countryCodes.unshift(usd);

    const gbpIndex = this.countryCodes.findIndex((data) => data === 'GBP');
    const gbp = this.countryCodes.find((data) => data === 'GBP');

    this.countryCodes.splice(gbpIndex, 1);
    this.countryCodes.unshift(gbp);

    const eurIndex = this.countryCodes.findIndex((data) => data === 'EUR');
    const eur = this.countryCodes.find((data) => data === 'EUR');

    this.countryCodes.splice(eurIndex, 1);
    this.countryCodes.unshift(eur);

    this.tableFieldsConfiguration.forEach((conf) => {
      if (conf['subType'] === 'currencyCodes') {
        conf['options'] = this.countryCodes;
      }
    });
  }

  makeEditable() {
    this.tableService.changeEditMode(true);
  }

  changeEditTableMode(data) {
    this.editableTask = data;
    this.taskService.updateEditableTask(data);
    this.disableAndResetTaskData(data);
  }

  disableAndResetTaskData(data) {
    if (!data) {
      this.tableService.resetOriginalData(this.entities);
    }
    if (this.defaultEditableTableComponent) {
      this.defaultEditableTableComponent?.formArrayVar?.controls.forEach(
        (formControl: UntypedFormGroup) => {
          data
            ? formControl.enable({ emitEvent: false })
            : formControl.disable({ emitEvent: false });
        }
      );
    }
  }

  listenToSubbscriptions() {
    this.sharedDataService.currenSharedOpenTransactionRows.subscribe(
      (sharedOpenTransactionRows) =>
        (this.sharedOpenTransactionRows = sharedOpenTransactionRows)
    );

    this.tableService.currentSharedSelectedRows.subscribe(
      (sharedSelectedRows) => (this.sharedSelectedRows = sharedSelectedRows)
    );

    this.tableService.currentSharedDataSource.subscribe(
      (sharedDataSource) => (this.sharedDataSource = sharedDataSource)
    );

    this.sharedDataService.currenSharedCommonForm.subscribe(
      (sharedCommonForm) => (this.sharedCommonForm = sharedCommonForm)
    );

    this.tableService.suscriptionChangesInFormEmitter =
      this.tableService.changesInFormFunction.subscribe((data) => {
        this.checkChangesInForm(data);
      });

    this.tableService.suscriptionInfiniteScrollEmitter =
      this.tableService.infiniteScrollFormFunction.subscribe((data) => {
        this.checkInfiniteScrollFunction(data);
      });

    this.sharedDataService.changesInFormFunction =
      this.sharedDataService.changesInCommonFormFunction.subscribe(() => {
        this.changesInGeneralForm();
      });

    this.tableService.suscriptionInsideSearchEmitter =
      this.tableService.insideSearchFunction.subscribe((data) => {
        this.openSearchDialog(data);
      });

    this.sharedDataService.suscriptionClearTableEmitter =
      this.sharedDataService.clearTableFunction.subscribe((data) => {
        this.clearForm();
      });

    this.tableService.subscriptionCancelEditModeFunctionEmitter =
      this.tableService.cancelEditModeFunction.subscribe((data) => {
        this.changeEditTableMode(data);
      });

    this.tableService.currentSharedForm.subscribe((data) => {
      if (data && data.disable) {
        this.editableTask = false;
      }
    });

    /**
     * Subscribe to changes in deleteSelectedRowEvent. This should be trigger only in
     * open-transaction-functinal-buttons.
     */
    this.sharedDataService.suscriptionDeleteSelectedRowsEmitter =
      this.sharedDataService.deleteSelectedRowsFunction.subscribe((data) => {
        this.deleteSelectedRows();
      });
  }

  checkChangesInForm(data) {
    if (data && data.formGroup) {
      if (data.key) {
        switch (data.key) {
          case 'debtCollection':
            this.changeDebtCollection(data.formGroup);
            break;
          case 'businessPartnerSelection':
            if (data.value !== '') {
              this.loadBusinessPartners(data.field, data.formGroup);
            }
            break;
          case 'externalIdSelection':
            if (data.value !== '') {
              this.loadBusinessPartners(data.field, data.formGroup);
            }
            break;
          case 'businessPartner':
            if (this.state.task) {
              this.loadBusinessPartnerFromTaskValue(
                data.field,
                data.value,
                data.formGroup
              );
            } else if (data.value) {
              this.patchExternalId(data.value, data.formGroup);
              this.sharedDataService.disableFields(data.value);
            }
            break;
          case 'externalId':
            if (data.value) {
              this.patchBusinessPartner(data.value, data.formGroup, data.field);
              this.sharedDataService.disableFields(data.value);
            }
            break;
          case 'amount':
            this.checksumService.updateChecksumData(
              this.rows,
              'amount',
              'debitOrCredit'
            );
            this.changeAmount(data);
            break;
          case 'vatAmount':
            this.calculateVatPercent(data);
            break;
          case 'vatPercent':
            this.calculateVatAmount(data);
            break;
          case 'transactionCode':
            this.setDueDateRequired(data);
            this.disableVatFields(data);
            break;
          case 'debitOrCredit':
            this.checksumService.updateChecksumData(
              this.rows,
              'amount',
              'debitOrCredit'
            );
            break;
        }
      }
    }
  }

  loadBusinessPartnerFromTaskValue(field, value, formGroup) {
    this.loadBusinessPartners(field, formGroup, value);
  }

  setDueDateRequired(data) {
    const fg: UntypedFormGroup = data.formGroup;
    const fieldConf = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'transactionCode'
    );
    if (fieldConf && fieldConf.options?.length > 0) {
      if (fg.get('transactionCode').value) {
        this.transactionCodeAllocationService
          .retrieveTransactionCodeAllocation(
            this.businessUnitGuid,
            fg.get('transactionCode').value
          )
          .subscribe((tca) => {
            if (tca.dueDate === true) {
              fg.get('dueDate').setValidators([Validators.required]);
              this.addDueDateColumnConfiguration();
            } else {
              fg.get('dueDate').clearValidators();
            }

            fg.get('dueDate').updateValueAndValidity();
            this.changeDetectorRef.detectChanges();
          });
      }
    }
  }

  disableVatFields(data) {
    const fg: UntypedFormGroup = data.formGroup;
    const fieldConf = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'transactionCode'
    );
    this.tableService.currentSharedForm.subscribe(form => {
      if (fieldConf && fieldConf.options?.length > 0 && !form?.disable) {
        if (fg.get('transactionCode').value) {
          this.transactionCodeAllocationService
            .retrieveTransactionCodeAllocation(
              this.businessUnitGuid,
              fg.get('transactionCode').value
            )
            .subscribe((tca) => {
              if (tca.salesTaxAccount && tca.salesTaxAccount === 'NOT_RELEVANT') {
                fg.get('vatAmount').disable();
                fg.get('vatPercent').disable();
              } else {
                fg.get('vatAmount').enable();
                fg.get('vatPercent').enable();
              }
            });
        }
      }
    });
  }

  addDueDateColumnConfiguration() {
    const dueDateColumn = this.defaultEditableTableComponent.columnsTemp.find(
      (col) => col.name === 'dueDate'
    );

    if (dueDateColumn.checked === false) {
      this.defaultEditableTableComponent.displayedColumnsTemp.splice(
        dueDateColumn.index - 1,
        0,
        'dueDate'
      );
      dueDateColumn.checked = true;
      this.defaultEditableTableComponent.tableChanged();
      this.changeDetectorRef.detectChanges();
    }
  }

  checkInfiniteScrollFunction(data) {
    if (data && data.field && data.field.type === 'selectInfiniteScroll') {
      if (
        data.field.columnName === 'businessPartner' ||
        data.field.columnName === 'externalId'
      ) {
        if (data.scroll) {
          this.scrollBusinessPartners(data.field, data.formGroup);
        } else {
          this.loadBusinessPartners(data.field, data.formGroup);
        }
      }
    }
  }

  loadBusinessPartners(field, formGroup, taskValue?) {
    const subControlToEdit = field.subControls[formGroup.get('index').value];
    subControlToEdit.pageIndex = 0;
    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText: '',
      pageSize: subControlToEdit.pageSize,
      pageIndex: subControlToEdit.pageIndex,
      product: this.businessUnitProduct,
      filteredValues: [
        {
          choice: field.columnName,
          values: this.getValuesFromColumns(
            field,
            formGroup,
            taskValue ? taskValue : null
          ),
        },
      ],
    };

    this.openItemService.listPartners(configObj, true).subscribe((data) => {
      subControlToEdit['options'] = data['content'];
      if (field.columnName === 'externalId') {
        subControlToEdit.options = subControlToEdit.options.filter(
          (partner) => partner.externalId != null && partner.externalId !== ''
        );
      }
      if (
        this.state.task &&
        taskValue &&
        subControlToEdit['options'].length === 1
      ) {
        formGroup
          .get('businessPartner')
          .patchValue(subControlToEdit['options'][0], { emitEvent: false });
        this.patchExternalId(subControlToEdit['options'][0], formGroup);
      }

      this.tableService.updateScrollSpinnerState(false);
      this.changeDetectorRef.detectChanges();
    });
  }

  private getValuesFromColumns(field, formGroup, taskValue?): string[] {
    switch (field.columnName) {
      case 'businessPartner':
        return [
          formGroup.get('businessPartnerSelection').value
            ? formGroup.get('businessPartnerSelection').value
            : taskValue
            ? taskValue
            : '',
        ];
      case 'externalId':
        return [formGroup.get('externalIdSelection').value];
      default:
        return [''];
    }
  }

  scrollBusinessPartners(field, formGroup) {
    const subControlToEdit = field.subControls[formGroup.get('index').value];
    subControlToEdit.pageIndex += subControlToEdit.pageSize;

    const configObj = {
      sortField: '',
      sortDirection: 'DESC',
      filterText:
        field.columnName === 'businessPartner'
          ? formGroup.get('businessPartnerSelection').value
          : field.columnName === 'externalId'
          ? formGroup.get('externalIdSelection').value
          : '',
      pageSize: subControlToEdit.pageSize,
      pageIndex: subControlToEdit.pageIndex,
      product: this.businessUnitProduct,
    };

    this.openItemService.listPartners(configObj).subscribe((data) => {
      if (data && data['content']) {
        data['content'].forEach((element) => {
          subControlToEdit['options'].push(element);
        });
        this.tableService.updateScrollSpinnerState(false);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  addDeduction(params) {
    let deductions = [];

    params.form.controls.objects.controls.forEach((fg) => {
      const selected = params.selection.selected.find(
        (element) => element.index === fg.value.index
      );
      /**
       * We can change this
       * if (selected && selected && selected.deductions.length > 0)
       * to
       * if (selected?.deductions?.length > 0)
       *
       * The first and second check is the same... and
       *
       * The ? will check if selected exists before the other tests and avoid
       * undefined/null errors and is more readable
       *
       */
      if (selected?.deductions?.length > 0) {
        deductions = selected.deductions;
      }
    });

    const dialog = this.matDialogService.open(AddDeductionModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        deductions,
        create: true,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        params.form.controls.objects.controls.forEach((fg) => {
          const selected = params.selection.selected.find(
            (element) => element.index === fg.value.index
          );
          if (selected) {
            fg.value.deductions.push(data.data);
          }
        });
      }
      this.checkIconStatus(params.form.controls.objects);
    });
  }

  addDunningDate(params) {
    let dunningInfo = [];

    params.form.controls.objects.controls.forEach((fg) => {
      const selected = params.selection.selected.find(
        (element) => element.index === fg.value.index
      );
      if (selected?.dunningInfo?.length > 0) {
        dunningInfo = selected.dunningInfo;
      }
    });

    const dialog = this.matDialogService.open(AddDunningModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        dunningInfo,
        create: true,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        params.form.controls.objects.controls.forEach((fg) => {
          const selected = params.selection.selected.find(
            (element) => element.index === fg.value.index
          );
          if (selected) {
            fg.value.dunningInfo.push(data.data);
          }
        });
      }
      this.checkIconStatus(params.form.controls.objects);
    });
  }

  checkIconStatus(formGroups) {
    formGroups.controls.forEach((fg) => {
      if (
        fg.get('dunningInfo').value.length > 0 ||
        fg.get('deductions').value.length > 0
      ) {
        fg.get('icon').patchValue(true, { emitEvent: false });
      } else {
        fg.get('icon').patchValue(false, { emitEvent: false });
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  changeDebtCollection(formGroup: UntypedFormGroup) {
    if (formGroup.get('debtCollection').value === 2) {
      formGroup.get('debtCollectionTransferDate').clearValidators();
      formGroup.updateValueAndValidity();
    } else {
      formGroup
        .get('debtCollectionTransferDate')
        .setValidators(Validators.required);
      formGroup.updateValueAndValidity();
    }
  }

  changeAmount(data) {
    const formGroup: UntypedFormGroup = data.formGroup;
    formGroup.get('vatAmount').setValue(0.0, { emitEvent: false });
    formGroup.get('vatPercent').setValue(0.0, { emitEvent: false });
  }

  calculateVatPercent(data) {
    const formGroup: UntypedFormGroup = data.formGroup;
    const vatPercentField = formGroup.get('vatPercent');

    let grossAmount = formGroup.get('amount').value;
    let vatAmount = formGroup.get('vatAmount').value;

    if (
      vatAmount === null ||
      isNaN(vatAmount) ||
      grossAmount === null ||
      isNaN(grossAmount) ||
      grossAmount === vatAmount
    ) {
      vatPercentField.patchValue(0.0, { emitEvent: false });
      formGroup.updateValueAndValidity();
      return;
    }

    grossAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(grossAmount);
    vatAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(vatAmount);

    let calculatedVatPercent: number =
      (vatAmount * 100) / (grossAmount - vatAmount);
    calculatedVatPercent =
      this.openTransactionBookingTableService.roundToTwoDecimals(
        calculatedVatPercent
      );

    vatPercentField.patchValue(calculatedVatPercent, { emitEvent: false });
    if (Math.abs(calculatedVatPercent) >= 100) {
      vatPercentField.setErrors({
        max: { max: 99.99, actual: Math.abs(calculatedVatPercent) },
      });
    }
    vatPercentField.markAsTouched();
    formGroup.updateValueAndValidity();
  }

  calculateVatAmount(data) {
    const formGroup: UntypedFormGroup = data.formGroup;
    const vatAmountField: AbstractControl = formGroup.get('vatAmount');

    let grossAmount: number = formGroup.get('amount').value;
    let vatPercent: number = formGroup.get('vatPercent').value;

    if (
      vatPercent === null ||
      isNaN(vatPercent) ||
      grossAmount === null ||
      isNaN(grossAmount) ||
      vatPercent === -100.0
    ) {
      vatAmountField.patchValue(0.0, { emitEvent: false });
      vatAmountField.markAsTouched();
      formGroup.updateValueAndValidity();
      return;
    }

    if (Math.abs(vatPercent) >= 100) {
      formGroup
        .get('vatPercent')
        .setErrors({ max: { max: 99.99, actual: Math.abs(vatPercent) } });
    }

    grossAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(grossAmount);
    vatPercent =
      this.openTransactionBookingTableService.roundToTwoDecimals(vatPercent);

    let calculatedVatAmount: number =
      (grossAmount / (100 + vatPercent)) * vatPercent;
    calculatedVatAmount =
      this.openTransactionBookingTableService.roundToTwoDecimals(
        calculatedVatAmount
      );

    vatAmountField.patchValue(calculatedVatAmount, { emitEvent: false });
    formGroup.updateValueAndValidity();
  }

  changeBusinessSelectionPartner(key: string, formGroup: UntypedFormGroup) {
    const tableConfiguration = this.tableFieldsConfiguration.find(
      (conf) => conf['subControlName'] === key
    );

    if (tableConfiguration && tableConfiguration.options) {
      const selectedOption = tableConfiguration.options.find(
        (opt) => opt.guid === formGroup.get('businessPartnerSelection').value[0]
      );
      if (selectedOption) {
        formGroup.get('businessPartner').patchValue(selectedOption.name);
        formGroup.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  addLines() {
    this.tableService.updateSharedSpinnerState(true);
    setTimeout(() => {
      this.tableService.addEmptyLinesToTable(
        this.tabularForm.get('newLines').value ||
          this.tabularForm.get('newLines').value !== ''
          ? this.tabularForm.get('newLines').value
          : 1
      );
      this.tableService.changesInForm(null);
      this.tabularForm.get('newLines').patchValue('');
    }, 500);
  }

  openMultiEditTransactionModal() {
    const dialog = this.matDialogService.open(
      OpenTransactionMultiEditModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          columns: this.displayedColumns,
          businessRelationships: this.businessRelationships,
          selectedBusinessPartner: this.selectedBusinessPartner,
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.tableService.patchSelectedRows(result);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getTransactionCodes() {
    this.transactionCodeAllocationService
      .retrieveTransactionCodeByEntityId(this.businessUnitGuid)
      .subscribe((data) => {
        const fieldConf = this.tableFieldsConfiguration.find(
          (conf) => conf.columnName === 'transactionCode'
        );
        data.sort((a, b) => a.transactionCode - b.transactionCode);
        fieldConf.options = data;
      });
  }

  patchExternalId(businessPartner, formGroup: UntypedFormGroup) {
    const fieldConfiguration = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'externalId'
    );

    const subControlToEdit =
      fieldConfiguration['subControls'][formGroup.get('index').value];

    if (businessPartner) {
      if (businessPartner.externalId) {
        subControlToEdit.options.push(businessPartner);
        formGroup
          .get('externalId')
          .patchValue(businessPartner, { emitEvent: false });
      } else {
        formGroup.get('externalId').patchValue(null, { emitEvent: false });
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  patchAllExternalId(businessPartner) {
    const fieldConfiguration = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'externalId'
    );

    if (businessPartner) {
      fieldConfiguration['subControls'].forEach((fg) => {
        if (businessPartner) {
          fg.options.push(businessPartner);
        }
      });

      if (businessPartner.externalId) {
        this.sharedOpenTransactionRows.controls.forEach((fg) => {
          fg.get('externalId').patchValue(businessPartner, {
            emitEvent: false,
          });
        });
      } else {
        this.sharedOpenTransactionRows.controls.forEach((fg) => {
          fg.get('externalId').patchValue(null, { emitEvent: false });
        });
      }

      this.sharedOpenTransactionRows.controls.forEach((fg) => {
        fg.get('externalId').disable({ emitEvent: false });
      });
    } else {
      this.sharedOpenTransactionRows.controls.forEach((fg) => {
        fg.get('externalId').patchValue(null, { emitEvent: false });
        fg.get('externalId').enable({ emitEvent: false });
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  patchBusinessPartner(businessPartner, formGroup: UntypedFormGroup, field) {
    const fieldConfiguration = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'businessPartner'
    );

    const subControlToEdit =
      fieldConfiguration['subControls'][formGroup.get('index').value];

    if (businessPartner) {
      subControlToEdit.options.push(businessPartner);
      formGroup
        .get('businessPartner')
        .patchValue(businessPartner, { emitEvent: false });
      this.changeDetectorRef.detectChanges();
    }
  }

  openSearchDialog(fg: UntypedFormGroup) {
    if (this.sharedCommonForm && this.sharedCommonForm.get('partner').value) {
      this.openBusinessRelationship(fg);
    } else {
      this.openBusinessPartner(fg);
    }
  }

  openBusinessRelationship(fg: UntypedFormGroup) {
    const dialog = this.matDialogService.open(
      BusinessRelationshipSelectionModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          selectedBusinessPartner: this.sharedCommonForm.get('partner').value,
        },
      }
    );

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const fieldConfigurationBP = this.tableFieldsConfiguration.find(
          (conf) => conf.columnName === 'businessRelationship'
        );

        fieldConfigurationBP.options.push(result.result);
        fg.get('businessRelationship').patchValue(result.result);
        this.sharedDataService.disableFields(result.result);
      }
    });
  }

  openBusinessPartner(fg: UntypedFormGroup) {
    const dialog = this.matDialogService.open(
      BusinessPartnerSelectionModalComponent
    );

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const fieldConfigurationBP = this.tableFieldsConfiguration.find(
          (conf) => conf.columnName === 'businessPartner'
        );

        const subControlToEdit =
          fieldConfigurationBP['subControls'][fg.get('index').value];

        subControlToEdit.options.push(result.result);
        fg.get('businessPartner').patchValue(result.result);
        this.sharedDataService.disableFields(result.result);
      }
    });
  }

  changesInGeneralForm() {
    if (this.sharedCommonForm && this.sharedCommonForm.get('partner').value) {
      this.displayedColumns.splice(
        this.displayedColumns.findIndex(
          (column) => column === 'businessPartner'
        ),
        1
      );

      if (this.businessUnitProduct !== 3) {
        if (
          !this.displayedColumns.find(
            (column) => column === 'businessRelationship'
          )
        ) {
          this.displayedColumns.splice(3, 0, 'businessRelationship');
        }

        this.loadBusinessRelationship();
      }

      this.patchAllExternalId(this.sharedCommonForm.get('partner').value);
      this.selectedBusinessPartner = this.sharedCommonForm.get('partner').value;
    } else if (
      this.sharedCommonForm &&
      !this.sharedCommonForm.get('partner').value
    ) {
      if (
        !this.displayedColumns.find((column) => column === 'businessPartner')
      ) {
        this.displayedColumns.splice(3, 0, 'businessPartner');

        if (this.businessUnitProduct !== 3) {
          this.displayedColumns.splice(
            this.displayedColumns.findIndex(
              (column) => column === 'businessRelationship'
            ),
            1
          );
        }

        this.patchAllExternalId(this.sharedCommonForm.get('partner').value);
        this.selectedBusinessPartner = null;
      }
    }
  }

  loadBusinessRelationship() {
    const fieldConfigurationBP = this.tableFieldsConfiguration.find(
      (conf) => conf.columnName === 'businessRelationship'
    );

    this.debtorService
      .getDebtorsForClient(this.sharedCommonForm.get('partner').value.id)
      .subscribe((result) => {
        fieldConfigurationBP.options = result;
        this.businessRelationships = result;
      });
  }

  editableTableFormBuilder() {
    this.form = this.formBuilder.group({
      objects: new UntypedFormArray([]),
    });
  }

  tabularFormBuilder() {
    this.tabularForm = this.formBuilder.group({
      newLines: [''],
    });
  }

  clearForm() {
    this.tableService.updateSharedSpinnerState(true);
    this.form.enable();
    this.form = null;
    this.editableTableFormBuilder();
    this.tableService.clearTable();
    this.changeDetectorRef.detectChanges();
  }

  getNewObject = (index): any => {
    let businessPartner = '';

    if (this.sharedCommonForm && this.sharedCommonForm.get('partner').value) {
      businessPartner = this.sharedCommonForm.get('partner').value;

      const fieldExternalId = this.tableFieldsConfiguration.find(
        (field) => field.columnName === 'externalId'
      );
      if (fieldExternalId) {
        fieldExternalId['subControls'].push(fieldExternalId['subControls'][0]);
        fieldExternalId['subControls'][
          fieldExternalId['subControls'].length - 1
        ]['rowNumber'] = fieldExternalId['subControls'].length - 1;
      }
    }

    const initialNumber = 0;

    return {
      businessPartner: '',
      businessPartnerSelection: '',
      businessRelationship: '',
      externalId: businessPartner,
      externalIdSelection: '',
      clientOpenItemId: '',
      openItemDate: '',
      transactionCode: '',
      debitOrCredit: '',
      amount: initialNumber,
      currency: '',
      vatAmount: initialNumber,
      vatPercent: initialNumber,
      dueDate: '',
      debtCollection: 2,
      debtCollectionTransferDate: '',
      settlementInEur: '',
      referenceDocumentationId: '',
      additionalInformation: '',
      branchId: '',
      deductions: [],
      dunningInfo: [],
      icon: false,
      index,
      actionSelected: false,
    };
  };

  getFormRow = (object): any => {
    let disabledFields = false;
    if (this.sharedCommonForm && this.sharedCommonForm.get('partner').value) {
      disabledFields = true;
    }

    return this.formBuilder.group(
      {
        businessPartner: [object.businessPartner],
        businessPartnerSelection: [object.businessPartnerSelection],
        businessRelationship: [object.businessRelationship],
        externalId: [{ value: object.externalId, disabled: disabledFields }],
        externalIdSelection: [object.externalIdSelection],
        clientOpenItemId: [object.clientOpenItemId, [Validators.required]],
        openItemDate: [
          object.openItemDate,
          [Validators.required, pastDateValidator()],
        ],
        transactionCode: [object.transactionCode, [Validators.required]],
        debitOrCredit: [object.debitOrCredit, [Validators.required]],
        amount: [object.amount, [Validators.required]],
        currency: [object.currency, [Validators.required]],
        vatAmount: [object.vatAmount, [Validators.required]],
        vatPercent: [
          object.vatPercent,
          [Validators.required, Validators.max(99.99)],
        ],
        dueDate: [object.dueDate],
        debtCollection: [object.debtCollection],
        debtCollectionTransferDate: [object.debtCollectionTransferDate],
        settlementInEur: [object.settlementInEur],
        referenceDocumentationId: [object.referenceDocumentationId],
        additionalInformation: [object.additionalInformation],
        branchId: [object.branchId],
        index: [object.index],
        deductions: [object.deductions],
        dunningInfo: [object.dunningInfo],
        icon: [object.icon],
        actionSelected: [object.actionSelected],
      },
      {
        validators: [
          this.generalValidator.upperLimitValidation('amount', 'vatAmount'),
          this.dateValidator.startEndValidator('openItemDate', 'dueDate'),
        ],
      }
    );
  };

  /**
   * Used to make simple to access the FormArray instance in a simple and type-safe way.
   */
  get rows(): UntypedFormArray {
    return this.form.controls['objects'] as UntypedFormArray;
  }

  /**
   * So we need to delete the selected row(s) in the Booking UI.
   * This is trigged subscribing to the delete event.
   *
   * 1. Gets all indexes that should be removed;
   * 2. Detele the values from the Form;
   * 3. Update the indexes. This is important because the indexes needs to be the same number of the row in the table;
   * 4. Update the selected rows;
   * 5. Update the dataSet. This will be used to feed the dataSet of the material table;
   */
  private deleteSelectedRows() {
    let indexesToRemove: number[] = [];
    this.tableService.currentSharedSelectedRows.subscribe((rows) => {
      if (rows?.length > 0) {
        indexesToRemove = rows.map((row) => row?.index);
      }
    });
    if (indexesToRemove?.length > 0) {
      this.deleteValuesFromForm(indexesToRemove);
      this.updateIndexesFromRows();
      this.tableService.updateSharedSelectedRows(null);
      this.sharedDataService.setDataSet(this.rows.value);
    }
  }

  /**
   * Used to remove rows from the formArray and update values and validity the form;
   *
   */
  deleteValuesFromForm(indexesToRemove: number[]) {
    // how this occurs really fast, we need to remove in reverse order to avoid index changing during the process.
    indexesToRemove
      .sort((a, b) => b - a)
      .forEach((idx) => {
        this.deleteLine(idx);
      });
    this.form.updateValueAndValidity();
  }

  /**
   * Used to remove line from the formArray:
   *
   */
  private deleteLine(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }

  /**
   * When you remove some elements in the midle of array is needed to update indexes to avoid
   * weird or wrong behavior from this Material Table and to garantee the correct indexes will be
   * updated when necessary.
   */
  private updateIndexesFromRows() {
    this.rows.controls.forEach((row, index) => {
      row['controls']['index'].patchValue(index);
    });
  }

  /**
   * When the displayedColumns change at DefaultEditableTable component this needs to be update here
   * to use the multiEdit modal correctly
   */
  private subscribeToDisplayedColumnsChange() {
    this.tableService
      .getDisplayedColumns()
      .subscribe((currentDisplayedColumns) => {
        if (currentDisplayedColumns.length > 0) {
          this.displayedColumns = currentDisplayedColumns;
        }
      });
  }
}
