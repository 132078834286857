import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { typeOptions } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/typeOptions';
import { reportingPeriodsColumns } from 'src/app/in-memory-data/animal-welfare/reporting-periods/table-columns';
import { reportingPeriodsColumnsConf } from 'src/app/in-memory-data/animal-welfare/reporting-periods/table-columns-configuration';
import { DATE_FORMATS } from 'src/app/modules/form-elements/mat-input-date/mat-input-date.component';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { ReportingPeriodService } from 'src/app/shared/services/animal-welfare/reporting-period/reporting-period.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { DateValidator } from 'src/app/shared/validators/date-validator';
import { DraftService } from 'src/app/shared/services/draft/draft.service';

@Component({
  selector: 'app-reporting-periods-create',
  templateUrl: './reporting-periods-create.component.html',
  styleUrls: [
    './reporting-periods-create.component.less',
    './reporting-periods-create-md.component.less',
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class ReportingPeriodsCreateComponent implements OnInit, DirtyComponent {
  private CONFIGURAIONT_KEY = 'reporting_periods_table';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'reporting-periods';

  filterForm: UntypedFormGroup;

  columns = reportingPeriodsColumnsConf;
  displayedColumns = reportingPeriodsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  form: UntypedFormGroup;
  typeOptions = typeOptions;

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;

  index = 0;
  deletedRowsIndexes: any[] = [];

  errorDate = true;

  state: any;

  isTask = false;
  editableTask = true;

  dateFormat = '';
  existentDates: any;
  approval = false;
  draftObject: any;
  reportingPeriodInitial: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private reportingPeriodService: ReportingPeriodService,
    private taskService: TaskService,
    private dateAdapter: DateAdapter<Date>,
    private dateValidator: DateValidator,
    private draftService: DraftService
  ) {
    this.filterFormBuilder();
    this.goodsGroupsFormBuilder();
  }

  ngOnInit(): void {
    this.getDateFormat();
    const userSpecifiedDateFormat = this.userService
      .getDateFormat()
      .toUpperCase();
    DATE_FORMATS.display.dateInput = userSpecifiedDateFormat;
    DATE_FORMATS.parse.dateInput = userSpecifiedDateFormat;

    this.state = window.history.state;
    this.getTableConfiguration();
    this.getExistentDates();
  }

  canDeactivate() {
    if (
      this.reportingPeriodsObjects &&
      this.reportingPeriodsObjects.controls.length > 0
    ) {
      return this.reportingPeriodsObjects.dirty && !this.approval;
    } else {
      return false;
    }
  }

  get formControls() {
    return this.form.controls;
  }
  get reportingPeriodsObjects() {
    return this.formControls.reportingPeriods as UntypedFormArray;
  }

  goodsGroupsFormBuilder() {
    this.form = this.formBuilder.group({
      reportingPeriods: new UntypedFormArray([]),
    });
  }

  getDisabledStatus() {
    let result = false;

    if (
      this.reportingPeriodsObjects.controls &&
      this.reportingPeriodsObjects.controls.length > 0
    ) {
      this.reportingPeriodsObjects.controls.forEach((item) => {
        if (!item.valid) {
          result = true;
        }
      });
    } else {
      result = true;
    }

    return result;
  }

  getExistentDates() {
    this.reportingPeriodService.findAll().subscribe((data: any) => {
      this.existentDates = data;
      this.loadTable();
    });
  }

  loadTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.state.reportingPeriods && this.state.reportingPeriods.length > 0) {
      this.state.reportingPeriods.forEach((element) => {
        this.addRow(element);
      });
    } else if (this.state.task) {
      this.isTask = true;
      this.retrieveDraftObjectTask(this.state.task);
      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    } else {
      this.addEmptyRow();
    }
  }

  retrieveDraftObjectTask(task: any) {
    this.draftService
      .retrieveDraftObjectForTask(task.guid)
      .subscribe((data) => {
        this.draftObject = data;
        if (this.draftObject) {
          const reportingPeriod = JSON.parse(this.draftObject.draftJson);
          this.addRow(reportingPeriod);
          this.changeRowsState(false);
        }

        if (this.state.task.params) {
          this.reportingPeriodInitial = this.state.task.params;
        }
      });
  }

  hasChanged(controlName, element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    if (this.reportingPeriodInitial) {
      return (
        formGroup.get(controlName).value !==
        this.reportingPeriodInitial[controlName]
      );
    } else {
      return false;
    }
  }

  getDateFormat() {
    this.dateFormat = this.userService.dateFormat;
    if (this.dateFormat === 'BRITISH') {
      this.changeDateFormat('en-GB');
      this.dateFormat = 'dd/MM/yyyy';
    }
    if (this.dateFormat === 'AMERICAN') {
      this.changeDateFormat('en-US');
      this.dateFormat = 'MM-dd-yyyy';
    }
    if (this.dateFormat === 'GERMAN') {
      this.changeDateFormat('de');
      this.dateFormat = 'dd.MM.yyyy';
    }
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }

  changeTaskState(state) {
    this.taskService.updateEditableTask(state);
    this.editableTask = state;
    this.changeRowsState(true);
  }

  changeRowsState(state) {
    this.reportingPeriodsObjects.controls.forEach((control) => {
      if (state) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  addRow(element) {
    const object = {
      id: element.id,
      startDate: element.startDate,
      endDate: element.endDate,
      comment: element.comment,
      index: this.index,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.index++;
  }

  cancelEdit() {
    if (this.state.task) {
      this.taskService.updateEditableTask(false);
      this.changeRowsState(false);
    } else {
      this.router.navigate(['/quantityLabeledGoods/reportingPeriods']);
    }
  }

  addEmptyRow() {
    const object = {
      id: null,
      startDate: null,
      endDate: null,
      comment: null,
      index: this.index,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.index++;
  }

  addFormRow(object) {
    this.reportingPeriodsObjects.push(
      this.formBuilder.group(
        {
          id: [{ value: object.id, disabled: false }],
          startDate: [
            { value: object.startDate, disabled: false },
            [Validators.required],
          ],
          endDate: [
            { value: object.endDate, disabled: false },
            [Validators.required],
          ],
          comment: [{ value: object.comment, disabled: false }],
          edited: [{ value: false, disabled: false }],
          index: [
            { value: object.index, disabled: false },
            [Validators.required],
          ],
        },
        {
          validators: [
            this.dateValidator.startEndValidator('startDate', 'endDate'),
            this.dateValidator.sameDatesValidator(this.existentDates),
          ],
        }
      )
    );
  }

  addEmptyFormRow(object) {
    this.reportingPeriodsObjects.push(
      this.formBuilder.group(
        {
          id: [{ value: null, disabled: false }],
          startDate: new UntypedFormControl(null, [Validators.required]),
          endDate: new UntypedFormControl(null, [Validators.required]),
          comment: [{ value: null, disabled: false }],
          edited: [{ value: false, disabled: false }],
          index: [
            { value: object.index, disabled: false },
            [Validators.required],
          ],
        },
        {
          validators: [
            this.dateValidator.startEndValidator('startDate', 'endDate'),
            this.dateValidator.inBetweenDatesValidator(this.existentDates),
          ],
        }
      )
    );
  }

  checkErrorDate(element) {
    let result = false;
    const formGroup = this.getFormGroup(element);

    if (formGroup) {
      if (
        formGroup.errors &&
        formGroup.errors.dateError === true &&
        formGroup.get('endDate').touched
      ) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  }

  checkRangeDate(element) {
    let result = false;
    const formGroup = this.getFormGroup(element);

    if (formGroup) {
      if (formGroup.errors && formGroup.errors.dateError === true) {
        result = false;
      } else if (formGroup.errors && formGroup.errors.inBetweenError === true) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  }

  checkSameDate(element) {
    let result = false;
    const formGroup = this.getFormGroup(element);

    if (formGroup) {
      if (formGroup.errors && formGroup.errors.dateError === true) {
        result = false;
      } else if (formGroup.errors && formGroup.errors.sameError === true) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchTerm: null,
    });
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ANIMAL-WELFARE.REPORTING-PERIODS.TITLE'
        ),
      }
    );
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.reportingPeriodsObjects.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        let removed = false;
        if (
          this.reportingPeriodsObjects.value[i] &&
          item === this.reportingPeriodsObjects.value[i].index &&
          !removed
        ) {
          this.reportingPeriodsObjects.removeAt(i);
          removed = true;
        }
      });
    }
  }

  getFormGroup(element) {
    let formGroup: any = null;

    formGroup = this.reportingPeriodsObjects.controls.find(
      (item) => item.value.index === element.index
    );

    return formGroup;
  }

  goToEditView() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  formatDate(dateP) {
    const date = new Date(dateP);
    const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1; // convert to positive value.
    date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);

    return date;
  }

  onClickSave() {
    const reportingPeriods = [];
    this.approval = true;
    let taskId: any;

    if (this.draftObject) {
      taskId = this.draftObject.approvalTaskId;
    }

    this.reportingPeriodsObjects.controls.forEach((item: any, index) => {
      reportingPeriods.push(item.getRawValue());
      reportingPeriods[index].startDate = this.formatDate(
        reportingPeriods[index].startDate
      );
      reportingPeriods[index].endDate = this.formatDate(
        reportingPeriods[index].endDate
      );
    });

    this.reportingPeriodService
      .createReportingPeriod(reportingPeriods, '', taskId)
      .subscribe((result) => {
        this.notificationService.showToast(
          'NOTIFICATION.CREATED',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ANIMAL-WELFARE.REPORTING-PERIODS.TITLE'
            ),
          }
        );

        setTimeout(() => {
          this.router.navigate(['/quantityLabeledGoods/reportingPeriods']);
        }, 3000);
      });
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                reportingPeriodsColumnsConf,
                reportingPeriodsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = reportingPeriodsColumnsConf;
    this.displayedColumns = reportingPeriodsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
