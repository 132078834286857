<div class="dropdown-container">
  <label for="file">Choose File</label>
  <input type="file" id="file" (change)="handleFileInput($event.target.files)" />

  <mat-form-field appearance="fill">
    <mat-label>Type</mat-label>
    <mat-select [(ngModel)]="selectedItem">
      <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.value">
        {{ fileType.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button class="custom-button" id="{{ entityName }}-create-button" (click)="uploadFile()">
    {{ 'upload-file' | translate }}
  </button>
</div>
