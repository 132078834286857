<section class="dialog-content">
  <div mat-dialog-content>
    <div class="form-container">
      <form class="form" [formGroup]="openTransactionMultiEditForm" autocomplete="on">
        <!-- businessPartner -->
        <div class="form-row" *ngIf="fieldsToDisplay.businessPartner">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TRANSACTIONS.TABLE.BUSINESS-PARTNER' | translate }}</mat-label>
            <mat-select
              formControlName="businessPartner"
              (infiniteScroll)="onScroll()"
              msInfiniteScroll
              (openedChange)="_openChanged($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'search' | translate }}"
                  noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                  formControlName="businessPartnerSearch"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of options$ | async" [value]="option">
                <span> {{ option.visibleId }} {{ option.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- businessRelationship -->
        <!--   <app-mat-select
          *ngIf="fieldsToDisplay.businessRelationship"
          [name]="'OPEN-TRANSACTIONS.TABLE.BUSINESS-RELATIONSHIP'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.BUSINESS-RELATIONSHIP'"
          [label]="'OPEN-TRANSACTIONS.TABLE.BUSINESS-RELATIONSHIP'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.businessRelationship"
          [controlName]="'businessRelationship'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
          [options]="businessRelationships"
          [optionValue]="null"
          [translationOption]="'visibleId'"
          [optionTranslationText]="''"
          [noSort]="true"
        >
        </app-mat-select> -->
        <!-- externalId -->
        <div class="form-row" *ngIf="fieldsToDisplay.externalId">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TRANSACTIONS.TABLE.EXTERNAL-ID' | translate }}</mat-label>
            <mat-select
              formControlName="externalId"
              (infiniteScroll)="onScrollExternalId()"
              msInfiniteScroll
              (openedChange)="_openChangedExternalId($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'search' | translate }}"
                  noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                  formControlName="externalIdSearch"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of optionsExternalId$ | async" [value]="option">
                <span> {{ option.externalId }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- clientOpenItemId -->
        <app-mat-input
          *ngIf="fieldsToDisplay.clientOpenItemId"
          [name]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-ID'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-ID'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-ID'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.clientOpenItemId"
          [controlName]="'clientOpenItemId'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <!-- openItemDate -->
        <app-mat-input-date
          *ngIf="fieldsToDisplay.openItemDate"
          [name]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-DATE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-DATE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DOCUMENTATION-DATE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.openItemDate"
          [controlName]="'openItemDate'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        >
        </app-mat-input-date>
        <!-- transactionCode -->
        <app-mat-select
          *ngIf="fieldsToDisplay.transactionCode"
          [name]="'OPEN-TRANSACTIONS.TABLE.TRANSACTION-CODE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.TRANSACTION-CODE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.TRANSACTION-CODE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.transactionCode"
          [controlName]="'transactionCode'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
          [options]="transactionCodes"
          [optionValue]="'transactionCode'"
          [optionTranslationText]="''"
          [translationOption]="'transactionCode'"
          [noSort]="true"
          [customOptionNameFunction]="formatTransactionCode"
        >
        </app-mat-select>
        <!-- debitOrCredit -->
        <app-mat-select
          *ngIf="fieldsToDisplay.debitOrCredit"
          [name]="'OPEN-TRANSACTIONS.TABLE.DEBIT-CREDIT-CODE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.DEBIT-CREDIT-CODE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DEBIT-CREDIT-CODE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.debitOrCredit"
          [controlName]="'debitOrCredit'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
          [options]="debitCreditCodeOptions"
          [optionValue]="'value'"
          [optionTranslationText]="''"
          [translationOption]="'translateName'"
          [noSort]="true"
        >
        </app-mat-select>
        <!-- amount -->
        <app-currency-input
          class="currency-inputs"
          [labelKey]="'OPEN-TRANSACTIONS.TABLE.AMOUNT'"
          [control]="openTransactionMultiEditForm.get('amount')"
        >
        </app-currency-input>
        <!-- currency -->
        <app-mat-select
          *ngIf="currencyCodes && fieldsToDisplay.currency"
          [name]="'OPEN-TRANSACTIONS.TABLE.CURRENCY-CODE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.CURRENCY-CODE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.CURRENCY-CODE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.currency"
          [controlName]="'currency'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
          [options]="currencyCodes"
          [optionValue]="null"
          [optionTranslationText]="''"
          [noSort]="true"
          [customOptionNameFunction]="null"
        >
        </app-mat-select>
        <!-- vatAmount -->
        <app-currency-input
          class="currency-inputs"
          [labelKey]="'OPEN-TRANSACTIONS.TABLE.VAT-AMOUNT'"
          [control]="openTransactionMultiEditForm.get('vatAmount')"
        >
        </app-currency-input>
        <div
          *ngIf="
            openTransactionMultiEditForm.errors?.limitError &&
            (openTransactionMultiEditForm.touched || openTransactionMultiEditForm.dirty) &&
            !openTransactionMultiEditForm.get('vatAmount').hasError('required') &&
            !openTransactionMultiEditForm.get('vatAmount').hasError('invalid') &&
            !openTransactionMultiEditForm.get('vatAmount').hasError('pattern')
          "
          class="input-error"
        >
          {{
            'ERROR-MESSAGES.INVALID'
              | translate
                : {
                    name: 'OPEN-TRANSACTIONS.TABLE.VAT-AMOUNT' | translate
                  }
          }}
        </div>
        <!-- vatPercent -->
        <app-currency-input
          class="currency-inputs"
          [labelKey]="'OPEN-TRANSACTIONS.TABLE.VAT-PERCENT'"
          [control]="openTransactionMultiEditForm.get('vatPercent')"
          [suffix]="'%'"
        ></app-currency-input>
        <!-- dueDate -->
        <app-mat-input-date
          *ngIf="fieldsToDisplay.dueDate"
          [name]="'OPEN-TRANSACTIONS.TABLE.DUE-DATE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.DUE-DATE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DUE-DATE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.dueDate"
          [controlName]="'dueDate'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        ></app-mat-input-date>
        <!-- debtCollection -->
        <app-mat-radio
          *ngIf="fieldsToDisplay.debtCollection"
          [name]="'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.debtCollection"
          [controlName]="'debtCollection'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [items]="debtCollectionOptions"
          [optionValue]="'value'"
        >
        </app-mat-radio>
        <!-- debtCollectionTransferDate -->
        <app-mat-input-date
          *ngIf="fieldsToDisplay.debtCollectionTransferDate"
          [name]="'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION-TRANSFER-DATE'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION-TRANSFER-DATE'"
          [label]="'OPEN-TRANSACTIONS.TABLE.DEBT-COLLECTION-TRANSFER-DATE'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.debtCollectionTransferDate"
          [controlName]="'debtCollectionTransferDate'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
        >
        </app-mat-input-date>
        <!-- settlementInEur -->
        <app-mat-select
          *ngIf="fieldsToDisplay.settlementInEur"
          [name]="'OPEN-TRANSACTIONS.TABLE.SETTLEMENT-EUR'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.SETTLEMENT-EUR'"
          [label]="'OPEN-TRANSACTIONS.TABLE.SETTLEMENT-EUR'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.settlementInEur"
          [controlName]="'settlementInEur'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
          [options]="settlemenetInEuroOptions"
          [optionValue]="'value'"
          [optionTranslationText]="''"
          [translationOption]="'translateName'"
          [noSort]="true"
        >
        </app-mat-select>
        <!-- referenceDocumentationId -->
        <app-mat-input
          *ngIf="fieldsToDisplay.referenceDocumentationId"
          [name]="'OPEN-TRANSACTIONS.TABLE.REFERENCE-DOCUMENTATION-ID'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.REFERENCE-DOCUMENTATION-ID'"
          [label]="'OPEN-TRANSACTIONS.TABLE.REFERENCE-DOCUMENTATION-ID'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.referenceDocumentationId"
          [controlName]="'referenceDocumentationId'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
        ></app-mat-input>
        <!-- additionalInformation -->
        <app-mat-input
          *ngIf="fieldsToDisplay.additionalInformation"
          [name]="'OPEN-TRANSACTIONS.TABLE.ADDITIONAL-INFORMATION'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.ADDITIONAL-INFORMATION'"
          [label]="'OPEN-TRANSACTIONS.TABLE.ADDITIONAL-INFORMATION'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.additionalInformation"
          [controlName]="'additionalInformation'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
        ></app-mat-input>
        <!-- branchId -->
        <app-mat-input
          *ngIf="fieldsToDisplay.branchId"
          [name]="'OPEN-TRANSACTIONS.TABLE.BRANCH-ID'"
          [placeholder]="'OPEN-TRANSACTIONS.TABLE.BRANCH-ID'"
          [label]="'OPEN-TRANSACTIONS.TABLE.BRANCH-ID'"
          [entityName]="entityName"
          [form]="openTransactionMultiEditForm"
          [controlObject]="openTransactionMultiEditForm.controls.branchId"
          [controlName]="'branchId'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="''"
        ></app-mat-input>
      </form>
    </div>
  </div>
  <div class="buttons">
    <button mat-button class="custom-button-cancel" id="{{ entityName }}-cancel-button" [mat-dialog-close]>
      {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
    </button>
    <button
      mat-button
      class="custom-button"
      id="{{ entityName }}-save-button"
      [mat-dialog-close]="openTransactionMultiEditForm.getRawValue()"
    >
      {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
    </button>
  </div>
</section>
