export const AWFunctionalButtonsEnum: any[] = [
  {
    name: 'paymentAllocation',
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
    function: 'callEmitFunction',
    disabled: false,
  },
  {
    name: 'transactionHistory',
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
    function: 'callEmitFunction',
    disabled: false,
  },
];

export const NonAWFunctionalButtonsEnum: any[] = [
  {
    name: 'objection',
    checked: true,
    translateName: 'OBJECTION.OBJECTION',
    function: 'callEmitFunction',
    disabled: false,
  },
  {
    name: 'paymentAllocation',
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.ACTION.ALLOCATION',
    function: 'callEmitFunction',
    disabled: false,
  },
  {
    name: 'transactionHistory',
    checked: true,
    translateName: 'TRANSACTION-OVERVIEW.ACTION.TRANSACTION-INFO',
    function: 'callEmitFunction',
    disabled: false,
  },
];
