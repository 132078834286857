<div class="modal-container dialog-content">
  <form class="form" [formGroup]="countryForm" autocomplete="off">
    <div mat-dialog-content class="background-dark">
      <div class="title-container">
        <h1 mat-dialog-title>
          {{ 'COUNTRY-SELECTION-MODAL.TITLE' | translate }}
        </h1>
      </div>
      <div class="form-container-modal">
        <div class="form-row" *ngIf="!loader">
          <mat-form-field>
            <mat-label>{{ 'COUNTRY-SELECTION-MODAL.SELECT-COUNTRY' | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'COUNTRY-SELECTION-MODAL.SELECT-COUNTRY' | translate }}"
              multiple
              formControlName="country"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'search' | translate }}"
                  noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                  formControlName="countrySearch"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let option of filteredCountries | sortBy: { property: 'translateName', descending: false }"
                [value]="option.cca3"
              >
                {{ 'countries.' + option.cca3 | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="spinner-container" *ngIf="loader">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button id="{{ entityName }}-countries-close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
        {{ 'cancel' | translate }}
      </button>
      <button
        id="{{ entityName }}-comment-approval-button"
        mat-button
        class="custom-button"
        id="sendToApproval"
        [disabled]="countryForm.invalid"
        [mat-dialog-close]="countryForm.value.country"
        style="margin-right: 0"
      >
        {{ 'COUNTRY-SELECTION-MODAL.ADD-BUTTON' | translate }}
      </button>
    </div>
  </form>
</div>
