import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { systemConfigurationsColumns } from 'src/app/in-memory-data/animal-welfare/system-configurations/table-columns';
import { systemConfigurationsColumnsConf } from 'src/app/in-memory-data/animal-welfare/system-configurations/table-columns-configuration';
import { UserService } from 'src/app/shared/services/user/user.service';
import { GeneralDeleteConfirmationModalComponent } from 'src/app/shared/modals/general-delete-confirmation-modal/general-delete-confirmation-modal.component';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { ShowHistoricalValuesModalComponent } from 'src/app/shared/modals/animal-welfare-modals/animal-welfare-system-config-show-historical-values-modal/show-historical-values-modal.component';
import { SystemParameterService } from '../../../shared/services/animal-welfare/system-parameter/system-parameter.service';
import { ConfirmationModalComponent } from '../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { Router } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';

@Component({
  selector: 'app-animal-welfare-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.less'],
})
export class SystemConfigurationComponent implements OnInit, AfterViewInit {
  entityName = 'animal-welfare-sys-config';
  filterForm: UntypedFormGroup;
  systemConfigurationForm: UntypedFormGroup;
  searchInput: any = '';
  systemConfigList = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.systemConfigList);
  columns = systemConfigurationsColumnsConf;
  displayedColumns = systemConfigurationsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  private CONFIGURATION_KEY = 'animal_welfare_system_configurations_table';

  showForm = false;
  showActionButtons = false;
  editableTask: boolean;
  isTask = false;
  approvedSysConfigs = [];
  sysConfigTask;
  sysConfigApproved;
  state;
  draftId;
  dateFormat = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private searchService: SearchService,
    private systemParameterService: SystemParameterService,
    private taskService: TaskService,
    private customErrorHandler: CustomErrorHandler
  ) {
    this.filterFormBuilder();
    this.systemConfigurationFormBuilder();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.notificationService.dismissMessage();
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);

    this.state = window.history.state;
    this.dataSource.sort = this.sort;
    this.getTableConfiguration();
    this.loadSystemParameters();
    if (this.state.task) {
      this.isTask = true;
      this.systemParameterService
        .getSystemParameterWithTaskId(this.state.task.id)
        .subscribe((data) => {
          this.draftId = data.guid;
          this.systemParameterService
            .retrieveApprovedSystemParameters(data.entityGuid)
            .subscribe((val) => {
              this.sysConfigApproved = Object.assign([], val);
            });
          this.sysConfigTask = Object.assign([], JSON.parse(data.draftJson));
          this.systemConfigurationForm.patchValue(this.sysConfigTask);
        });
      this.taskService.currentEditableTask.subscribe(
        (editableTask) => (this.editableTask = editableTask)
      );
    }
  }

  loadSystemParameters() {
    this.systemParameterService.retrieveSystemParameters().subscribe((data) => {
      this.dataSource.data = data;
      this.systemConfigList = data;
      this.approvedSysConfigs = Object.assign([], data);
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  systemConfigurationFormBuilder() {
    this.systemConfigurationForm = this.formBuilder.group({
      visibleId: [{ value: '', disabled: true }],
      name: [{ value: '', disabled: true }],
      description: [{value: '', disabled: true }],
      defaultValue: ['', Validators.required],
      validFrom: ['', Validators.required],
      editedBy: [{ value: '', disabled: true }],
      lastEdit: [{ value: '', disabled: true }],
      approvedBy: [{ value: '', disabled: true }],
      approvedOn: [{ value: '', disabled: true }],
      guid: [''],
    });
  }

  searchSystemConfigs() {
    this.searchService.search(
      this.systemConfigList,
      this.searchInput,
      this.dataSource
    );
  }

  edit(row) {
    this.showForm = true;
    this.sysConfigApproved = null;
    this.sysConfigApproved = this.approvedSysConfigs.find(
      (element) => element.guid === row.guid
    );
    if (this.sysConfigApproved === -1) {
      this.sysConfigApproved = row;
    }
    row.name = this.translateService.instant('ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER.' + row.visibleId);
    row.description = this.translateService.instant('ANIMAL-WELFARE.SYSTEM-CONFIGURATION.PARAMETER.DESCRIPTION.' + row.visibleId);
    this.systemConfigurationForm.patchValue(row);
  }

  cancel() {
    const dialog = this.matDialogService.open(
      GeneralDeleteConfirmationModalComponent,
      {
        panelClass: 'confirmation-popup',
        data: {
          deleteMessage: this.translateService.instant(
            'ANIMAL-WELFARE.SYSTEM-CONFIGURATION.DISMISS'
          ),
        },
      }
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.showForm = false;
        if (this.showActionButtons === false) {
          this.systemConfigurationForm.reset();
        }
        if (this.state.task) {
          this.editableTask = false;
          this.isTask = true;
          this.changeEditable(false);
          this.showActionButtons = false;
        }
      }
    });
  }

  saveChanges() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        const json = {
          systemParameter: this.systemConfigurationForm.getRawValue(),
          comment: result.comment,
          draftId: this.draftId,
        };
        this.systemParameterService
          .updateSystemParamater(json)
          .subscribe(() => {
            this.showForm = false;
            this.notificationService.showToast(
              'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {
                data: this.translateService.instant(
                  'ENTITIES.ANIMAL-WELFARE.SYSTEM-CONFIGURATION'
                ),
              }
            );
            setTimeout(() => {
              this.router.navigateByUrl('/tasks', {
                state: { success: true },
              });
            }, 500);
          }, this.customErrorHandler._errorHandler('ENTITIES.ANIMAL-WELFARE.SYSTEM-CONFIGURATION'));
      }
    });
  }

  showHistoricalValues(element) {
    this.matDialogService.open(ShowHistoricalValuesModalComponent, {
      panelClass: 'confirmation-popup',
      data: {
        uuid: element.guid,
      },
    });
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };

    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                systemConfigurationsColumnsConf,
                systemConfigurationsColumns
              );

            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = systemConfigurationsColumnsConf;
    this.displayedColumns = systemConfigurationsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  changeEditable(value: boolean) {
    this.taskService.updateEditableTask(value);
    this.showActionButtons = true;
  }
}
