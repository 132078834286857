import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PermissionConfigurationComponent } from './permission-configuration/permission-configuration.component';
import { RealmSelectionModalComponent } from '../realm-selection-modal/realm-selection-modal.component';
import { UserService } from '../../../../shared/services/user/user.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-permission-create',
  templateUrl: './permission-create.component.html',
  styleUrls: ['./permission-create.component.less'],
})
export class PermissionCreateComponent implements OnInit, OnDestroy {
  isTask = false;
  entityName = 'permission-create';
  editMode = false;
  realm = undefined;
  state;
  permission: {};
  @ViewChild(PermissionConfigurationComponent)
  PermissionConfigurationComponent: PermissionConfigurationComponent;
  private internalPermission: boolean;
  private canUnlockPermission: boolean;

  constructor(
    private matDialogService: MatDialog,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.state = window.history.state;
    this.editMode = this.state.editMode;
    this.permission = this.state.param;
    if (!this.state.viewMode) {
      this.canUnlockPermission = true;
    }
    if (this.state.param && this.state.param['internalPermission'] !== null) {
      this.internalPermission = this.state.param['internalPermission'];
    }
    if (!this.state.editMode && !this.state.task && !this.state.viewMode) {
      const dialog = this.matDialogService.open(RealmSelectionModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.realm = result;
        } else {
          this.router.navigateByUrl('/userManagement/permission');
        }
      });
    } else if (this.state.task) {
      this.isTask = true;
    }
  }

  ngOnDestroy(): void {
    this.unlockPermission();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.unlockPermission();
  }

  savePermission() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          if (this.PermissionConfigurationComponent) {
            const requestJson = {
              comment: result.comment,
              permissionDto: {
                ...this.PermissionConfigurationComponent.frmPermission.getRawValue(),
                ...{ internalPermission: this.internalPermission },
              },
            };
            this.userService.updatePermission(requestJson).subscribe((data) => {
              this.canUnlockPermission = false;
              this.notificationService.showToast(
                'NOTIFICATION.CREATED',
                this.notificationService.MESSAGE_TYPE.SUCCESS,
                { data: this.translateService.instant('ENTITIES.PERMISSION') }
              );
              this.router.navigateByUrl('/userManagement/permission');
            });
          }
        }
      });
  }

  private unlockPermission() {
    if (this.canUnlockPermission && this.permission && this.permission['id']) {
      const realm = this.permission['internalPermission']
        ? 'internal'
        : 'external';
      this.userService
        .unLockPermission(this.permission['id'], realm)
        .subscribe((_data) => {
          /**
           * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
           * to much of empty function. So this comment is to makes the sonarqube happy.
           * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
           */
        });
    }
  }
}
