import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { teamEnum } from '../../../in-memory-data/user-management/enums/team-enum';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.less'],
})
export class UserSelectModalComponent implements OnInit {
  userSelect;
  roles;
  teams;
  users;
  filteredUsers: Observable<any>;
  entityName = 'user-select';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    this.loadTeams();
    this.userSelect = this.formBuilder.group({
      user: '',
      team: '',
      forwardOption: ['', Validators.required],
    });

    this.userSelect.controls.forwardOption.valueChanges.subscribe((val) => {
      if (this.isUserOption()) {
        this.userSelect.controls.user.setValidators([Validators.required]);
        this.userSelect.controls.team.clearValidators();
      } else {
        this.userSelect.controls.user.clearValidators();
        this.userSelect.controls.team.setValidators([Validators.required]);
      }
      this.userSelect.controls.user.updateValueAndValidity();
    });
  }

  loadTeams() {
    this.teams = teamEnum;
  }

  private loadUsers() {
    this.userService.listAll().subscribe((data) => {
      this.users = data;
      this.filteredUsers = this.userSelect.get('user').valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    });
  }

  private _filter(value): string[] {
    if (value) {
      const filterValue = value.toString().toLowerCase();

      return this.users.filter((option) => {
        const nameTemplate =
          option.lastName +
          ', ' +
          option.firstName +
          ' (' +
          option.username +
          ')';
        return nameTemplate.toLowerCase().includes(filterValue);
      });
    }
    return this.users;
  }

  displayFn(user: any) {
    if (user) {
      return user.lastName + ', ' + user.firstName + ' (' + user.username + ')';
    }
  }

  isUserOption() {
    return this.userSelect.controls.forwardOption.value === 'USER';
  }

  isTeamOption() {
    return this.userSelect.controls.forwardOption.value === 'TEAM';
  }
}
