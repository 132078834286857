import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteEditorService {
  route = '';
  constructor(private router: Router) {}

  edit(element) {
    this.router.navigateByUrl(
      this.route + '/' + element.guid + '/' + element.type,
      {
        state: { accounting: element },
      }
    );
  }
}
