<div class="title-form">
  <h3 id="{{ entityName }}-title">
    {{ 'NON-GERMANY-REFERENCE-ALLOW-LIST.TITLE' | translate }}
  </h3>
</div>

<div class="history-table-container">
  <app-non-germany-reference-allow-list-history></app-non-germany-reference-allow-list-history>
</div>

<div class="countries-table-container">
  <app-non-germany-reference-allow-list-countries></app-non-germany-reference-allow-list-countries>
</div>
