<div
  *ngIf="(editableTask && isTask) || !isTask"
  [ngClass]="{
    'inline-display': inline
  }"
>
  <div class="form-row radio">
    <label class="label-radio">
      <span>
        {{ label | translate }}
      </span>

      <span *ngIf="requiredValidator">{{ '*' }}</span>
    </label>
    <mat-radio-group
      [ngClass]="{ 'val-changed': changedValue }"
      #radio="matRadioGroup"
      name="{{ name }}-{{ elementIndex }}"
      formControlName="{{ controlName }}"
      [required]="requiredValidator"
      (change)="change($event)"
      id="{{ entityName }}-form-{{ controlName }}{{ elementIndex ? '-' + elementIndex : '' }}"
      [attr.disabled]="disabled"
    >
      <mat-radio-button
        *ngFor="let item of items; let i = index"
        id="{{ entityName }}-form-{{ controlName }}{{ elementIndex ? '-' + elementIndex : '' }}-{{ i }}"
        [value]="getItemValue(item)"
        >{{ item.translateName | translate }}</mat-radio-button
      >
      <button
        mat-button
        matSuffix
        mat-icon-button
        class="inline-button"
        aria-label="Clear"
        id="{{ entityName }}-form-{{ controlName }}-clear{{ elementIndex ? '-' + elementIndex : '' }}"
        [disabled]="isFieldDisabled()"
        (click)="clearOptions(); $event.stopPropagation()"
        *ngIf="showRemoveOption() && !isFieldDisabled() && !requiredValidator"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-radio-group>

    <mat-error *ngIf="controlObject.hasError('required') && controlObject.touched" class="input-error">
      {{
        'ERROR-MESSAGES.REQUIRED'
          | translate
            : {
                name: radio.name | translate
              }
      }}
    </mat-error>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
      >
        {{ taskValue }}
      </span>
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row" *ngIf="taskValue !== false && taskValue !== true">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div
          fxFlex="20"
          fxFlex.sm="30"
          fxFlex.xs="100"
          *ngIf="!sideMenu && (twoColumns == null || twoColumns == undefined || twoColumns == '')"
        >
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div
          fxFlex="80"
          fxFlex.sm="70"
          fxFlex.xs="100"
          *ngIf="!sideMenu && (twoColumns == null || twoColumns == undefined || twoColumns == '')"
        >
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue != undefined && approvedValue != taskValue
            }"
            >{{ getTranslatedValue() }}
          </span>
        </div>
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue != undefined && approvedValue != taskValue
            }"
            >{{ getTranslatedValue() }}
          </span>
        </div>
        <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns == false">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns == false">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue != undefined && approvedValue != taskValue
            }"
            >{{ getTranslatedValue() }}
          </span>
        </div>
        <div fxFlex="50" fxFlex.sm="30" fxFlex.xs="100" *ngIf="sideMenu && twoColumns">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="100" *ngIf="sideMenu && twoColumns">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue != undefined && approvedValue != taskValue
            }"
            >{{ getTranslatedValue() }}
          </span>
        </div>
      </div>
    </div>
    <div class="object-row" *ngIf="taskValue === false || taskValue === true">
      <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
        <span
          class="material-icons icon icon-good"
          [ngClass]="{
            'val-changed': approvedValue != undefined && taskValue != approvedValue
          }"
          *ngIf="taskValue == true"
        >
          done
        </span>
        <span
          class="material-icons icon"
          [ngClass]="{
            'val-changed': approvedValue && taskValue != approvedValue
          }"
          *ngIf="taskValue == false"
        >
          remove
        </span>
        <span class="object-label">{{ label | translate }}</span>
      </div>
      <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
        {{ getTranslatedValue() }}
      </div>
      <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
        <span
          class="material-icons icon icon-good"
          [ngClass]="{
            'val-changed': approvedValue != undefined && taskValue != approvedValue
          }"
          *ngIf="taskValue == true"
        >
          done
        </span>
        <span
          class="material-icons icon"
          [ngClass]="{
            'val-changed': approvedValue && taskValue != approvedValue
          }"
          *ngIf="taskValue == false"
        >
          remove
        </span>
        <span class="object-label">{{ label | translate }}</span>
      </div>
      <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
        {{ getTranslatedValue() }}
      </div>
    </div>
  </div>
</div>
