import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from 'src/app/shared/models/dialog-data';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-enter-actual-value-modal',
  templateUrl: './enter-actual-value-modal.component.html',
  styleUrls: ['./enter-actual-value-modal.component.less'],
})
export class AWEnterActualValueModalComponent implements OnInit {
  entityName = 'enterActualValueModal';
  enterActualValueForm: UntypedFormGroup;
  state;
  data: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AWEnterActualValueModalComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.data = {
      actualValue: '',
      comment: '',
      event: '',
    };
  }

  ngOnInit() {
    this.enterActualValueFormBuilder();
  }

  enterActualValueFormBuilder() {
    this.enterActualValueForm = this.formBuilder.group({
      actualValue: ['', Validators.required],
      comment: ['', Validators.required],
    });
  }

  save() {
    this.data = {
      actualValue: this.enterActualValueForm.get('actualValue').value,
      comment: this.enterActualValueForm.get('comment').value,
      event: 'save',
    };
    this.dialogData = this.data;
    this.dialogRef.close(this.dialogData);
  }
}
