import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { MasterDataService } from '../services/master-data/master-data.service';

export class AwQsidValidator {
  static createValidator(
    masterDataService: MasterDataService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.parent) {
        return of(null);
      }
      let formField = null;
      let level = null;
      if (control.parent.get('slaughterHouseGuid') != null) {
        formField = control.parent.get('slaughterHouseGuid');
        level = 'SCHLACHTBETRIEB';
      } else {
        formField = control.parent.get('foodProcessingCompanyGuid');
        level = 'VERARBEITUNGSBETRIEB';
      }
      formField.setValue('');
      if (!control.value) {
        return of({ qsIdError: true });
      }
      if (control.value.length > 13) {
        return of({ qsIdError: true });
      }
      return masterDataService.findByQsId(control.value, level).pipe(
        map((result) => {
          if (result == null) {
            return { qsIdError: true };
          }
          formField.setValue(result['id']);
          return null;
        })
      );
    };
  }
}
