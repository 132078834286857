import { Component, OnInit } from '@angular/core';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';

@Component({
  selector: 'app-open-item-management',
  templateUrl: './open-item-management.component.html',
  styleUrls: ['./open-item-management.component.less'],
})
export class OpenItemManagementComponent implements OnInit {
  tableData: any;

  sharedTableData: any;

  constructor(private openItemService: OpenItemService) {}

  ngOnInit() {
    this.openItemService.currentSharedTableData.subscribe(
      (sharedTableData) => (this.sharedTableData = sharedTableData)
    );
  }
}
