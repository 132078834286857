<div class="dialog-content">
  <div mat-dialog-content class="background-dark">
    <span class="material-icons custom-icon"> help </span>
    <span class="message" *ngIf="!messageParams">{{ messageKey | translate }}</span>
    <span class="message" *ngIf="messageParams">{{ getMessage() }} </span>
  </div>
  <div></div>
  <div class="buttons">
    <button id="close-button" mat-button class="custom-button-cancel" [mat-dialog-close]>
      {{ 'GENERAL.FALSE' | translate }}
    </button>
    <button
      id="confirm-button"
      mat-button
      class="custom-button"
      id="confirmButton"
      style="margin-right: 0"
      [mat-dialog-close]="true"
    >
      {{ 'GENERAL.TRUE' | translate }}
    </button>
  </div>
</div>
