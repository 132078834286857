<span class="dynamic-loader-table" *ngIf="loaderSpinner && !isTask">
  <mat-spinner [diameter]="50"></mat-spinner>
</span>
<div *ngIf="!loaderSpinner">
  <form class="form dark-background booking-code" [formGroup]="bookingCodeForm" autocomplete="off">
    <div
      class="container general-display-alignment"
      fxLayout="row wrap"
      fxlayout.md="column"
      fxlayout.sm="column"
      fxlayout.xs="column"
      fxLayoutGap="7px grid"
    >
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'MASTER-DATA.BOOKING-CODE'"
              [placeholder]="'MASTER-DATA.BOOKING-CODE'"
              [label]="'MASTER-DATA.BOOKING-CODE'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.id"
              [controlName]="'id'"
              [approvedValue]="getApprovedValue('id')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.id : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [type]="'number'"
              [addNumbers]="'3.0'"
              (keypress)="restrictIdInputToPositiveIntegers($event)"
            >
            </app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'BOOKING-CODE.DEFAULT-BOOKING-CODE-NAME'"
              [placeholder]="'BOOKING-CODE.DEFAULT-BOOKING-CODE-NAME'"
              [label]="'BOOKING-CODE.DEFAULT-BOOKING-CODE-NAME'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.name"
              [controlName]="'name'"
              [approvedValue]="getApprovedValue('name')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.name : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
            >
            </app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'BOOKING-CODE.DEFAULT-NAME-ENGLISH'"
              [placeholder]="'BOOKING-CODE.DEFAULT-NAME-ENGLISH'"
              [label]="'BOOKING-CODE.DEFAULT-NAME-ENGLISH'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.defaultNameEnglish"
              [controlName]="'defaultNameEnglish'"
              [approvedValue]="getApprovedValue('defaultNameEnglish')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.defaultNameEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
            >
            </app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-input
              [name]="'BOOKING-CODE.DESCRIPTION'"
              [placeholder]="'BOOKING-CODE.DESCRIPTION'"
              [label]="'BOOKING-CODE.DESCRIPTION'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.description"
              [controlName]="'description'"
              [approvedValue]="getApprovedValue('description')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.description : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
            ></app-mat-input>
          </div>
          <div fxFlex="50">
            <app-mat-input
              [name]="'BOOKING-CODE.DESCRIPTION-ENGLISH'"
              [placeholder]="'BOOKING-CODE.DESCRIPTION-ENGLISH'"
              [label]="'BOOKING-CODE.DESCRIPTION-ENGLISH'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.descriptionEnglish"
              [controlName]="'descriptionEnglish'"
              [approvedValue]="getApprovedValue('descriptionEnglish')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.descriptionEnglish : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
            >
            </app-mat-input>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.TRANSACTION-TYPE'"
              [placeholder]="'BOOKING-CODE.TRANSACTION-TYPE'"
              [label]="'BOOKING-CODE.TRANSACTION-TYPE'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.transactionType"
              [controlName]="'transactionType'"
              [approvedValue]="getApprovedValue('transactionType')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.transactionType : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="transactionTypes"
              [optionValue]="'name'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getTransactionType"
              [disabled]="disableSpecialFields || isDraftExists"
            >
            </app-mat-select>
          </div>

          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.TRANSFER-TRANSACTION-CODE'"
              [label]="'BOOKING-CODE.TRANSFER-TRANSACTION-CODE'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('transferTransactionCode')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.transferTransactionCode : null"
              [controlName]="'transferTransactionCode'"
              [controlObject]="bookingCodeForm.controls.transferTransactionCode"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="isDraftExists"
            ></app-mat-radio>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.STANDARD'"
              [placeholder]="'BOOKING-CODE.STANDARD'"
              [label]="'BOOKING-CODE.STANDARD'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.standard"
              [controlName]="'standard'"
              [approvedValue]="getApprovedValue('standard')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.standard : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="standards"
              [optionValue]="'code'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getStandard"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-select>
          </div>
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.CANCELLATION'"
              [label]="'BOOKING-CODE.CANCELLATION'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('cancellation')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.cancellation : null"
              [controlName]="'cancellation'"
              [controlObject]="bookingCodeForm.controls.cancellation"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="isDraftExists"
            >
            </app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-DEBIT'"
              [placeholder]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-DEBIT'"
              [label]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-DEBIT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.sapLedgerAccountDebit"
              [controlName]="'sapLedgerAccountDebit'"
              [approvedValue]="getSubledgerAccount('sapLedgerAccountDebit')"
              [taskValue]="bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.sapLedgerAccountDebit) : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [disabled]="disableSpecialFields || isDraftExists"
              [noSort]="true"
            ></app-mat-select>
          </div>

          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-CREDIT'"
              [placeholder]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-CREDIT'"
              [label]="'BOOKING-CODE.SAP-SUB-LEDGER-ACCOUNT-CREDIT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.sapLedgerAccountCredit"
              [controlName]="'sapLedgerAccountCredit'"
              [approvedValue]="getSubledgerAccount('sapLedgerAccountCredit')"
              [taskValue]="bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.sapLedgerAccountCredit) : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [disabled]="disableSpecialFields || isDraftExists"
              [noSort]="true"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.OBJECTION-ACCOUNT-BE-VISION'"
              [placeholder]="'BOOKING-CODE.OBJECTION-ACCOUNT-BE-VISION'"
              [label]="'BOOKING-CODE.OBJECTION-ACCOUNT-BE-VISION'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.objectionAccountBeVision"
              [controlName]="'objectionAccountBeVision'"
              [approvedValue]="getSubledgerAccount('objectionAccountBeVision')"
              [taskValue]="bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.objectionAccountBeVision) : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="objectAccounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [disabled]="disableSpecialFields || isDraftExists"
              [noSort]="true"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.BOOKING-OPTION'"
              [placeholder]="'BOOKING-CODE.BOOKING-OPTION'"
              [label]="'BOOKING-CODE.BOOKING-OPTION'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.bookingOption"
              [controlName]="'bookingOption'"
              [approvedValue]="getApprovedValue('bookingOption')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.bookingOption : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="bookingOptions"
              [optionValue]="'code'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getBookingOption"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.IS-DUE-DATE'"
              [label]="'BOOKING-CODE.IS-DUE-DATE'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('isDueDate')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.isDueDate : null"
              [controlName]="'isDueDate'"
              [controlObject]="bookingCodeForm.controls.isDueDate"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="isDraftExists"
            >
            </app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.CLASSIFICATION-RELEVANT'"
              [label]="'BOOKING-CODE.CLASSIFICATION-RELEVANT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('classificationRelevant')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.classificationRelevant : null"
              [controlName]="'classificationRelevant'"
              [controlObject]="bookingCodeForm.controls.classificationRelevant"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.RELEVANT-FOR-REMAINING-LIMIT-CALC'"
              [label]="'BOOKING-CODE.RELEVANT-FOR-REMAINING-LIMIT-CALC'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('relevantForRemainingLimitCalc')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.relevantForRemainingLimitCalc : null"
              [controlName]="'relevantForRemainingLimitCalc'"
              [controlObject]="bookingCodeForm.controls.relevantForRemainingLimitCalc"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="relevantForRemainingLimitCalcOptions"
              [optionValue]="'value'"
              [titlecasePipe]="false"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-radio>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.SETTLEMENT-RELEVANT'"
              [label]="'BOOKING-CODE.SETTLEMENT-RELEVANT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('settlementRelevant')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.settlementRelevant : null"
              [controlName]="'settlementRelevant'"
              [controlObject]="bookingCodeForm.controls.settlementRelevant"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="settlementRelevantOptions"
              [optionValue]="'value'"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.RELEVANT-SALES'"
              [label]="'BOOKING-CODE.RELEVANT-SALES'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('relevantSales')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.relevantSales : null"
              [controlName]="'relevantSales'"
              [controlObject]="bookingCodeForm.controls.relevantSales"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="isDraftExists"
            >
            </app-mat-radio>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.GENERAL-LEDGER-ACCOUNT'"
              [placeholder]="'BOOKING-CODE.GENERAL-LEDGER-ACCOUNT'"
              [label]="'BOOKING-CODE.GENERAL-LEDGER-ACCOUNT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.generalLedgerAccount"
              [controlName]="'generalLedgerAccount'"
              [approvedValue]="getSubledgerAccount('generalLedgerAccount')"
              [taskValue]="bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.generalLedgerAccount) : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getAccount"
              [disabled]="disableSpecialFields || isDraftExists"
              [noSort]="true"
            ></app-mat-select>
          </div>
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.GENERAL-LEDGER-COUNTER-ACCOUNT'"
              [placeholder]="'BOOKING-CODE.GENERAL-LEDGER-COUNTER-ACCOUNT'"
              [label]="'BOOKING-CODE.GENERAL-LEDGER-COUNTER-ACCOUNT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.generalLedgerCounterAccount"
              [controlName]="'generalLedgerCounterAccount'"
              [approvedValue]="getSubledgerAccount('generalLedgerCounterAccount')"
              [taskValue]="
                bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.generalLedgerCounterAccount) : null
              "
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="accounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'translateName'"
              [optionTranslationText]="''"
              [disabled]="disableSpecialFields || isDraftExists"
              [noSort]="true"
            >
            </app-mat-select>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-select
              [name]="'BOOKING-CODE.SALES-TAX-ACCOUNT'"
              [placeholder]="'BOOKING-CODE.SALES-TAX-ACCOUNT'"
              [label]="'BOOKING-CODE.SALES-TAX-ACCOUNT'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [controlObject]="bookingCodeForm.controls.salesTaxAccount"
              [controlName]="'salesTaxAccount'"
              [approvedValue]="getSubledgerAccount('salesTaxAccount')"
              [taskValue]="bookingCodeTask ? getSubledgerAccountInfo(bookingCodeTask.salesTaxAccount) : null"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [formGroupCustomName]="null"
              [options]="salesTaxAccounts"
              [optionValue]="'id'"
              [selectedOption]="null"
              [translationOption]="'account'"
              [optionTranslationText]="''"
              [customTaskValueFunction]="getTaxAccount"
              [disabled]="disableSpecialFields || isDraftExists"
            ></app-mat-select>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <app-mat-radio
              [name]="'BOOKING-CODE.DISPLAY-EXTERNALLY'"
              [label]="'BOOKING-CODE.DISPLAY-EXTERNALLY'"
              [entityName]="entityName"
              [form]="bookingCodeForm"
              [approvedValue]="getApprovedValue('displayExternally')"
              [taskValue]="bookingCodeTask ? bookingCodeTask.displayExternally : null"
              [controlName]="'displayExternally'"
              [controlObject]="bookingCodeForm.controls.displayExternally"
              [titleTask]="false"
              [editableTask]="editableTask"
              [isTask]="isTask"
              [items]="classRelevantOptions"
              [optionValue]="'value'"
              [disabled]="isDraftExists"
            ></app-mat-radio>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
