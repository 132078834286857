<div class="control-board-wrapper">
  <div class="form-container">
    <form [formGroup]="formGroup" class="form">
      <mat-form-field class="form-row" appearance="fill">
        <mat-label>{{ 'ANIMAL-WELFARE.ACCOUNT-STATEMENT.QUARTER' | translate }}</mat-label>
        <mat-select formControlName="billingIds" (selectionChange)="onChangeQuarter($event.value)">
          <mat-option *ngFor="let billing of billings" [value]="billing">
            Q{{ billing.quarter }} / {{ billing.year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-row">
        <mat-checkbox (change)="setPig($event.checked)" [checked]="isPig">
          {{ 'ANIMAL-WELFARE.ACCOUNT-STATEMENT.PIG' | translate }}
        </mat-checkbox>
        <mat-checkbox (change)="setPoultry($event.checked)" [checked]="isPoultry">
          {{ 'ANIMAL-WELFARE.ACCOUNT-STATEMENT.POULTRY' | translate }}
        </mat-checkbox>
      </div>
      <app-mat-input-date
        [name]="'ANIMAL-WELFARE.ACCOUNT-STATEMENT.OBJECTION-PERIOD'"
        [placeholder]="'date'"
        [label]="'ANIMAL-WELFARE.ACCOUNT-STATEMENT.OBJECTION-PERIOD'"
        [entityName]="entityName"
        [form]="formGroup"
        [controlObject]="formGroup.controls.objectionPeriod"
        [controlName]="'objectionPeriod'"
        [approvedValue]="null"
        [taskValue]="null"
        [titleTask]="false"
        [editableTask]="false"
        [isTask]="false"
        [formGroupCustomName]="null"
        [minDate]="null"
        [maxDate]="null"
      ></app-mat-input-date>
      <div class="form-row">
        <button
          *hasPermission="'aw.cb.c'"
          mat-button
          class="custom-button start-simulation"
          [disabled]="isDisabled()"
          (click)="startSimulation()"
          id="{{ entityName }}-account-statement-button"
        >
          {{ 'ANIMAL-WELFARE.ACCOUNT-STATEMENT.START-ACCOUNT-STATEMENT' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
