import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { goodsGroupsOptions } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/goodsGroupsOptions';
import { reportedByOptions } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/reportedByOptions';
import { typeOptions } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/enum/typeOptions';
import { quantityLabeledGoodsColumns } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/table-columns';
import { quantityLabeledGoodsColumnsConf } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods/table-columns-configuration';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { GoodsGroupService } from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import { QuatityLabelledGoodService } from 'src/app/shared/services/animal-welfare/quantity-labelled-good/quatity-labelled-good.service';
import { ReportingPeriodService } from 'src/app/shared/services/animal-welfare/reporting-period/reporting-period.service';
import {
  DefaultTableSelectionType,
  TableActionButtonsConfiguration,
} from 'src/app/shared/services/default-table/interfaces/table-action-buttons-configuration';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-quantity-labeled-goods-list',
  templateUrl: './quantity-labeled-goods-list.component.html',
  styleUrls: [
    './quantity-labeled-goods-list.component.less',
    './quantity-labeled-goods-list-md.component.less',
  ],
})
export class QuantityLabeledGoodsListComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private quantityLabelledGoodService: QuatityLabelledGoodService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private goodsGroupsService: GoodsGroupService,
    private translateService: TranslateService,
    private matDialogService: MatDialog,
    private notificationService: NotificationService,
    private reportingPeriodService: ReportingPeriodService,
    private tablesService: TablesService
  ) {
    this.filterFormBuilder();
    this.reportFormBuilder();
    this.checkSumFormBuilder();
    this.tablesService.currentSharedSelectedRows.subscribe(
      (selectedRows) => (this.selectedRows = selectedRows)
    );
  }

  get getPeriodDate() {
    return this.getReportingPeriod.bind(this);
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'quantity-of-labeled-goods';

  filterForm: UntypedFormGroup;
  form: UntypedFormGroup;
  checkSumForm: UntypedFormGroup;
  relationshipData = [];

  index = 0;
  deletedRowsIndexes: any[] = [];

  private CONFIGURAIONT_KEY = 'quantity_labeled_goods_table';

  configurationKey = this.CONFIGURAIONT_KEY;

  columns = quantityLabeledGoodsColumnsConf;
  displayedColumns = quantityLabeledGoodsColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  goodsGroupsOptions = goodsGroupsOptions;
  typeOptions = typeOptions;
  reportedByOptions = reportedByOptions;

  selection = new SelectionModel(true, []);

  selectionDraft = new SelectionModel(true, []);

  dataSource = new MatTableDataSource<any>([]);

  dataSourceDraft = new MatTableDataSource<any>([]);

  existentGoodsGroups: any;

  loaderSpinner = false;
  existentReportingPeriods: any;
  originalData: any;

  goodsGroupsOptionsLocal = [];
  dateFormat: any;

  filterObject = {
    sortField: '',
    sortDirection: 'DESC',
    filterText: '',
    pageSize: '20',
    pageIndex: '0',
    filteredValues: [],
    dateFormat: '',
  };
  totalCount = 0;
  selectedRows: any;

  listOfActionButtons: TableActionButtonsConfiguration[] = [
    {
      name: 'edit',
      permission: 'aw.lg.u',
      icon: 'mode_edit',
      translationKey: 'GENERAL.EDIT',
      function: () => {
        this.edit();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return false;
      },
    },
    {
      name: 'remove',
      permission: 'aw.lg.d',
      icon: 'delete_outline',
      translationKey: 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.REMOVE',
      function: () => {
        this.cancelElement();
      },
      selectionType: DefaultTableSelectionType.singleAndMultiple,
      specialDisable: () => {
        return false;
      },
    },
  ];

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
    this.filterObject.dateFormat = this.dateFormat;
    this.getTableConfiguration();
    this.getReportingPeriods();
    this.getGoodGroups();
    this.loadTable();
    this.filterTable();

    this.translateService.onLangChange.subscribe((lang) => {
      this.loaderSpinner = true;
      this.loadTable();
    });
  }

  checkSumFormBuilder() {
    this.checkSumForm = this.formBuilder.group({
      checksum: [{ value: null, disabled: true }],
    });
  }

  reportFormBuilder() {
    this.form = this.formBuilder.group({
      reports: new UntypedFormArray([]),
    });
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      reportingPeriodId: null,
      purchaserId: null,
      purchaserName: null,
      supplierId: null,
      supplierName: null,
      type: null,
      comment: null,
      goodsGroupId: null,
      createdOn: null,
      approvedOn: null,
      createdBy: null,
      approvedBy: null,
    });
  }

  clearGoodsGroupSearch() {
    this.filterForm.get('goodsGroupId').setValue(null);
  }

  filterTable() {
    this.filterForm.valueChanges.subscribe((_) => {
      this.loadTable();
    });
  }

  getReportingPeriods() {
    this.reportingPeriodService.findAll().subscribe((data: any) => {
      this.existentReportingPeriods = data;
    });
  }

  goToEditView() {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
    });
  }

  cancelElement() {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    if (this.selectedRows.length > 1) {
      dialog.componentInstance.messageKey =
        'NOTIFICATION.CONFIRM-DELETE-MULTIPLE-ENTRIES';
      dialog.componentInstance.messageParams['param'] =
        this.selectedRows.length;
    } else {
      dialog.componentInstance.messageKey = 'NOTIFICATION.CONFIRM-DELETE-ENTRY';
    }
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.showCommentModal();
      }
    });
  }

  showCommentModal() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        let deletedQuantityLabelledGoods = [];
        deletedQuantityLabelledGoods = this.selectedRows;
        deletedQuantityLabelledGoods.forEach((qlg) => {
          qlg.approvedOn = moment(
            qlg.approvedOn,
            this.dateFormat.toUpperCase()
          );
          qlg.createdOn = moment(qlg.createdOn, this.dateFormat.toUpperCase());
          qlg.suppliedTo = qlg.salesEntryId;
          qlg.suppliedToName = qlg.purchaserName;
        });

        this.quantityLabelledGoodService
          .cancelQuantityLabelledGood(
            deletedQuantityLabelledGoods,
            result.comment
          )
          .subscribe(() => {
            this.selection = new SelectionModel(true, []);
            this.notificationService.showToast(
              'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {
                data: this.translateService.instant(
                  'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.TITLE'
                ),
              }
            );
            setTimeout(() => {
              this.router.navigate(['/quantityLabeledGoods/quantity']);
            }, 3000);
          });
      }
    });
  }

  editSelectedRows() {
    const isValidToEdit = this.checkReportedBySelectedRows();
    if (isValidToEdit) {
      this.selectedRows.forEach((element) => {
        element.suppliedTo = element.salesEntryId;
        element.suppliedToName = element.purchaserName;
        element.suppliedFromName = element.supplierName;
      });

      this.router.navigate(['create'], {
        relativeTo: this.activatedRoute,
        state: { reports: this.selectedRows },
      });
    } else {
      this.notificationService.showToast(
        'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.DIFFERENT-REPORTED-BY',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  checkReportedBySelectedRows() {
    let isValid = true;
    let result = this.selectedRows.filter(
      (data) => data.reportedBy === this.selectedRows[0].reportedBy
    );

    if (result.length === this.selectedRows.length) {
      if (this.selectedRows[0].reportedBy === 1) {
        result = this.selectedRows.filter(
          (data) => data.supplierId === this.selectedRows[0].supplierId
        );

        if (result.length !== this.selectedRows.length) {
          isValid = false;
        }
      } else {
        result = this.selectedRows.filter(
          (data) => data.purchaserId === this.selectedRows[0].purchaserId
        );
        if (result.length !== this.selectedRows.length) {
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }

    return isValid;
  }

  removeRepeated(data) {
    return data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  }

  getReportingPeriod(reportingPeriodP) {
    let result = '';
    let reportingPeriod;
    if (this.existentReportingPeriods) {
      reportingPeriod = this.existentReportingPeriods.find(
        (data) => data.id === reportingPeriodP.id
      );

      if (!reportingPeriod) {
        reportingPeriod = this.existentReportingPeriods.find(
          (data) => data.id === reportingPeriodP.reportingPeriodId
        );
      }
    }

    if (reportingPeriod) {
      reportingPeriod.startDate = moment(reportingPeriod.startDate);
      reportingPeriod.endDate = moment(reportingPeriod.endDate);
      result =
        reportingPeriod.startDate.format(this.dateFormat.toUpperCase()) +
        ' - ' +
        reportingPeriod.endDate.format(this.dateFormat.toUpperCase());
    }

    return result;
  }

  getName(element) {
    let result = '';
    if (element.reportedBy === 1) {
      result = element.suppliedFromName;
    } else {
      result = element.suppliedToName;
    }
    return result;
  }

  getGoodGroups() {
    this.goodsGroupsService.findAll().subscribe((data: any) => {
      this.existentGoodsGroups = data;
      this.addGoodsGroupsToOptions();
    });
  }

  addGoodsGroupsToOptions() {
    this.goodsGroupsOptionsLocal = Object.assign([], goodsGroupsOptions);
    this.existentGoodsGroups.forEach((element) => {
      if (element.type === 'PIG') {
        this.goodsGroupsOptionsLocal[0].options.push(element);
      } else if (element.type === 'POULTRY') {
        this.goodsGroupsOptionsLocal[1].options.push(element);
      } else if (element.type === 'CATTLE') {
        this.goodsGroupsOptionsLocal[2].options.push(element);
      }
    });
    this.goodsGroupsOptionsLocal[0].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[0].options
    );
    this.goodsGroupsOptionsLocal[1].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[1].options
    );
    this.goodsGroupsOptionsLocal[2].options = this.removeRepeated(
      this.goodsGroupsOptionsLocal[2].options
    );
  }

  getGoodsGroupName(goodGrupId) {
    let result = '';

    if (this.existentGoodsGroups && this.existentGoodsGroups.length > 0) {
      const goodGroup = this.existentGoodsGroups.find(
        (data) => data.id === goodGrupId
      );

      if (goodGroup) {
        if (this.translateService.currentLang === 'en') {
          result = goodGroup.englishName;
        } else {
          result = goodGroup.germanName;
        }
      }
    }

    return result;
  }

  getCheckSumValue() {
    let result = 0;
    this.dataSource.data.forEach((element) => {
      result += parseFloat(element.quantity);
    });

    this.checkSumForm.get('checksum').patchValue(result);
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                quantityLabeledGoodsColumnsConf,
                quantityLabeledGoodsColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = quantityLabeledGoodsColumnsConf;
    this.displayedColumns = quantityLabeledGoodsColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getReportedBy(reportedByValue) {
    let result = '';

    if (this.reportedByOptions && this.reportedByOptions.length > 0) {
      result = this.reportedByOptions.find(
        (data) => data.value === reportedByValue
      ).translateName;
    }

    return result;
  }

  downloadXslxFile() {
    const filterValues = { ...this.filterForm.value, ...this.filterObject };
    Object.keys(filterValues).forEach(
      (key) => (filterValues[key] = filterValues[key] ?? '')
    );
    this.quantityLabelledGoodService
      .downloadXslxFile(
        filterValues,
        this.displayedColumnsTemp.slice(
          1,
          this.displayedColumnsTemp.length - 1
        ),
        this.translateService.currentLang
      )
      .subscribe((data) => {
        saveAs(data, 'search_result.xlsx');
      });
  }

  loadTable() {
    const filterValues = { ...this.filterForm.value, ...this.filterObject };
    Object.keys(filterValues).forEach(
      (key) => (filterValues[key] = filterValues[key] ?? '')
    );
    this.quantityLabelledGoodService
      .findAll(
        filterValues,
        this.displayedColumnsTemp.slice(
          1,
          this.displayedColumnsTemp.length - 1
        ),
        this.translateService.currentLang
      )
      .pipe(first())
      .subscribe((data: any) => {
        data.content.forEach((element) => {
          element.quantity = parseFloat(element.quantity);
        });
        this.loaderSpinner = false;
        this.originalData = data;
        this.dataSource.data = data.content;
        this.totalCount = data.total;
        this.getCheckSumValue();
      });
  }

  edit() {
    this.editSelectedRows();
  }

  filter(event) {
    if (event.sortOrder) {
      this.filterObject.sortDirection = event.sortOrder;
    }
    this.filterObject = { ...this.filterObject, ...event };
    if (event.choice) {
      this.filterObject.sortField = event.choice;
    }
    if (event.direction) {
      this.filterObject.sortDirection = event.direction;
    }

    this.loadTable();
  }
}
