export const buTransactionCodesColumnsConf: any[] = [
  {
    name: 'transactionCode',
    index: 0,
    checked: true,
    translateName: 'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ID',
    locked: true,
  },
  {
    name: 'originalTransactionCodeNameGerman',
    index: 2,
    checked: true,
    translateName: 'TRANSACTION-CODE-ALLOCATION.DEFAULT-TRANSACTION-CODE-NAME',
    locked: true,
  },
  {
    name: 'originalTransactionCodeNameEnglish',
    index: 3,
    checked: true,
    translateName: 'TRANSACTION-CODE-ALLOCATION.DEFAULT-NAME-ENGLISH',
    locked: true,
  },
  {
    name: 'originalTransactionCodeDescriptionGerman',
    index: 4,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.DESCRIPTION',
    locked: false,
  },
  {
    name: 'originalTransactionCodeDescriptionEnglish',
    index: 5,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.DESCRIPTION-ENGLISH',
    locked: false,
  },
  {
    name: 'transactionCodeNameGerman',
    index: 6,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-GERMAN-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'transactionCodeNameEnglish',
    index: 7,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-ENGLISH-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'transactionCodeDescriptionGerman',
    index: 8,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-GERMAN-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'transactionCodeDescriptionEnglish',
    index: 9,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.TRANSACTION-CODE-DESCRIPTION-ENGLISH-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'transactionType',
    index: 10,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.TRANSACTION-TYPE',
    locked: false,
  },
  {
    name: 'transferTransactionCode',
    index: 11,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.TRANSFER-TRANSACTION-CODE',
    locked: false,
  },
  {
    name: 'standard',
    index: 12,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.STANDARD',
    locked: false,
  },
  {
    name: 'cancellation',
    index: 13,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.CANCELLATION',
    locked: false,
  },
  {
    name: 'originalSapLedgerAccountCredit',
    index: 14,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT',
    locked: false,
  },
  {
    name: 'originalSapLedgerAccountDebit',
    index: 15,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-DEBIT',
    locked: false,
  },
  {
    name: 'sapLedgerAccountCredit',
    index: 16,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'sapLedgerAccountDebit',
    index: 17,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.SAP-SUBLEDGER-ACCOUNT-CREDIT-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'objectAccount',
    index: 18,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT',
    locked: false,
  },
  {
    name: 'originalObjectAccount',
    index: 19,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.OBJECT-ACCOUNT-ENTITY',
    locked: false,
    translateParams: 'ENTITIES.BU',
  },
  {
    name: 'bookingOption',
    index: 20,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.BOOKING-OPTION',
    locked: false,
  },
  {
    name: 'dueDate',
    index: 21,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.IS-DUE-DATE',
    locked: false,
  },
  {
    name: 'classificationRelevant',
    index: 22,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.CLASSIFICATION-RELEVANT',
    locked: false,
  },
  {
    name: 'relevantForRemainingLimitCalc',
    index: 23,
    checked: false,
    translateName:
      'TRANSACTION-CODE-ALLOCATION.RELEVANT-FOR-REMAINING-LIMIT-CALC',
    locked: false,
  },
  {
    name: 'settlementRelevant',
    index: 24,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.SETTLEMENT-RELEVANT',
    locked: false,
  },
  {
    name: 'relevantSales',
    index: 25,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.RELEVANT-SALES',
    locked: false,
  },
  {
    name: 'salesTaxAccount',
    index: 26,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.SALES-TAX-ACCOUNT',
    locked: false,
  },
  {
    name: 'balancingTransactionCodeInhouse',
    index: 27,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.IN-HOUSE-BALANCING-ITEM-CODE',
    locked: false,
  },
  {
    name: 'displayExternally',
    index: 28,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.DISPLAY-EXTERNALLY',
    locked: false,
  },
  {
    name: 'status',
    index: 29,
    checked: false,
    translateName: 'TRANSACTION-CODE-ALLOCATION.STATUS',
    locked: false,
  },
  {
    version: '2',
  },
];
