import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { businessRelationshipSelectionColumns } from 'src/app/in-memory-data/business-relationship/table-columns';
import { businessRelationshipSelectionColumnsConf } from 'src/app/in-memory-data/business-relationship/table-columns-configuration';
import { productEnum } from 'src/app/in-memory-data/business-unit/enums/product-enum';
import { Client } from '../../models/client';
import { DialogData } from '../../models/dialog-data';
import { DebtorService } from '../../services/debtor/debtor.service';

@Component({
  selector: 'app-business-relationship-selection-modal',
  templateUrl: './business-relationship-selection-modal.component.html',
  styleUrls: ['./business-relationship-selection-modal.component.less'],
})
export class BusinessRelationshipSelectionModalComponent implements OnInit {
  filterForm: UntypedFormGroup;
  searchInput: any = '';
  clientList: Client[];
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<Client>();

  columns = businessRelationshipSelectionColumnsConf;
  displayedColumns = businessRelationshipSelectionColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  types = [];
  transactionData: any;

  threeDots = false;
  data: any;
  entityName = 'business-relationship-selection-modal';
  configurationKey = 'business-relationship-selection-modal-table';

  noClients = false;

  errorBU = false;

  productTypes = productEnum;

  businessUnits: any;
  businessUnitProduct: any;

  sharedDataSource: any;
  dataLoaded = false;
  partnerId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<BusinessRelationshipSelectionModalComponent>,
    private debtorService: DebtorService
  ) {
    this.filterFormBuilder();
  }

  ngOnInit() {
    this.selection = new SelectionModel(true, []);

    this.partnerId = this.dialogData.selectedBusinessPartner.id;
    this.loadRelationships();
  }

  loadRelationships(event?) {
    this.debtorService.getDebtorsForClient(this.partnerId).subscribe((data) => {
      data = this.formatData(data);
      const result = {
        page: 0,
        pageSize: 0,
        total: 1,
        content: data,
      };
      this.transactionData = result;
      this.dataLoaded = true;
    });
  }

  formatData(data) {
    data.forEach((element) => {
      Object.assign(element, {
        formatAddress: this.getAddressString(element),
      });

      Object.assign(element, {
        name: element.companyName,
      });
    });

    return data;
  }

  getAddressString(element) {
    if (!element.address) {
      return '';
    }
    return (
      element.address.street +
      ', ' +
      element.address.number +
      ', ' +
      element.address.zipCode +
      ', ' +
      element.address.city
    );
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }
}
