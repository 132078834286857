import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any, args?: any, buList?: any): any {
    if (!value) {
      return null;
    }
    if (!args) {
      return value;
    }

    args = args.toLowerCase();

    if (buList) {
      const result = [];
      buList.forEach((element) => {
        value.forEach((item) => {
          if (item === element.id) {
            if (JSON.stringify(element.name).toLowerCase().includes(args)) {
              result.push(element.id);
            }
          }
        });
      });
      return result;
    } else {
      return value.filter((item) => {
        return JSON.stringify(item).toLowerCase().includes(args);
      });
    }
  }
}
