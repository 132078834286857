<div class="filters" [formGroup]="filterForm">
  <div class="filter-input">
    <app-mat-input
      [name]="'ANIMAL-WELFARE.REPORTING-PERIODS.SEARCH'"
      [placeholder]="'ANIMAL-WELFARE.REPORTING-PERIODS.SEARCH'"
      [label]="'ANIMAL-WELFARE.REPORTING-PERIODS.SEARCH'"
      [entityName]="entityName"
      [form]="filterForm"
      [controlObject]="filterForm.controls.searchTerm"
      [controlName]="'searchTerm'"
      [approvedValue]="null"
      [taskValue]="null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="false"
      [formGroupCustomName]="null"
      [inline]="true"
    ></app-mat-input>
    <button
      mat-button
      class="custom-button search-button"
      (click)="searchReportingPeriods()"
      id="{{ entityName }}-search-button"
    >
      <span class="material-icons"> search </span>
    </button>
  </div>
  <div class="filter-input button-container-end">
    <button
      *hasPermission="'aw.lg.c'"
      (click)="goToEditView()"
      mat-button
      class="custom-button"
      id="{{ entityName }}-create-button"
    >
      <span>
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.CREATE' | translate }}
      </span>
    </button>
  </div>
</div>

<div class="table-list">
  <h5 id="{{ entityName }}-search-results-title">
    {{ 'SEARCH-RESULT' | translate }}
  </h5>
  <table aria-hidden="true" mat-table [dataSource]="dataSource" matSort id="{{ entityName }}-search-results-table">
    <ng-container matColumnDef="startDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-start-date"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.START-DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-start-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.startDate | date: dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-end-date"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.END-DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-end-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.endDate | date: dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="v25-th"
        (click)="paginator.firstPage()"
        id="{{ entityName }}-table-results-comment"
      >
        {{ 'ANIMAL-WELFARE.REPORTING-PERIODS.COMMENT' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
      >
        {{ element.comment }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
        <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
          <mat-icon>settings</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let i = index"></td>
    </ng-container>
    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
          {{ 'ERROR.NO-DATA-FOUND' | translate }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [hidden]="loaderSpinner"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'draft-row': row.draft }"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
  </table>
</div>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
