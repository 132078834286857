import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Quarter as Billing } from 'src/app/in-memory-data/animal-welfare/quarterly-billing/quarter';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ConfirmationPromptModalComponent } from '../../../../shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { quaterlyBillingPayoutColumnsConf } from 'src/app/in-memory-data/control-board/quaterly-billing-payout/columns-configuration';
import { quaterlyBillingPayoutColumns } from 'src/app/in-memory-data/control-board/quaterly-billing-payout/table-columns';
import { DefaultTableComponent } from 'src/app/shared/tables/default-table/default-table.component';
import { TablesService } from 'src/app/shared/tables/tables.service';

@Component({
  selector: 'app-quarterly-billing-payout',
  templateUrl: './quarterly-billing-payout.component.html',
  styleUrls: ['./quarterly-billing-payout.component.less'],
})
export class QuarterlyBillingPayoutComponent {
  formGroup: UntypedFormGroup;
  entityName = 'quarterlyBillingPayout';
  billings: Billing[] = [];
  disableQuarterCaps = true;
  disableSimulation = true;
  disablePayout = true;

  columns = quaterlyBillingPayoutColumnsConf;
  displayedColumns = quaterlyBillingPayoutColumns;
  @ViewChild(DefaultTableComponent)
  defaultTableComponent: DefaultTableComponent;
  configurationKey = 'quaterly_billing_payout_table';
  dataLoaded = false;
  totalCount = 0;
  object;
  sharedSelectedRows;

  constructor(
    private liquidityPlanService: LiquidityPlanService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private tableService: TablesService
  ) {
    this.formBuilder();

    this.tableService.currentSharedSelectedRows.subscribe(
      (currentSharedSelectedRows) =>
        (this.sharedSelectedRows = currentSharedSelectedRows)
    );
  }

  formBuilder() {
    this.formGroup = new UntypedFormGroup({
      target: new UntypedFormControl(null, Validators.required),
    });
  }

  changeTargetSelection(newValue: 'PIG' | 'POULTRY') {
    this.disableQuarterCaps = newValue !== 'PIG';

    this.liquidityPlanService
      .retrieveBillingsForPayout(newValue)
      .subscribe((billings) => {
        billings.forEach((item) => {
          Object.assign(item, { deadline_date: item.deadline });
        });

        this.object = {
          content: billings,
          total: billings.length,
          pageSize: 20,
          page: 0,
        };

        this.dataLoaded = true;
      });
  }

  startPayout() {
    this.liquidityPlanService
      .startPayout(this.sharedSelectedRows.map((b) => b.id))
      .subscribe((result) => {
        this.notificationService.showToast(
          'ANIMAL-WELFARE.PAYOUT-PROCESS-STARTED',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
        setTimeout(() => {
          this.formBuilder();
          this.dataLoaded = false;
        }, 3000);
      });
  }

  confirmationStartPayout() {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.CONFIRM-START-PAYOUT';
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.startPayout();
      }
    });
  }
}
