<div class="content-container">
  <div class="filters" [formGroup]="filter">
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'SYSTEM-CONFIGURATION.CONFIGURATION.SEARCH' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'SEARCH' | translate }}"
          name="name"
          formControlName="searchString"
          id="{{ entityName }}-search-input-name"
          (keyup)="searchAccountStatement()"
          [(ngModel)]="searchInput"
          autocomplete="off"
          [removeHtmlTags]="filter.controls.searchString"
          type="text"
        />
      </mat-form-field>
      <button
        mat-button
        class="custom-button search-button"
        (click)="searchAccountStatement()"
        id="{{ entityName }}-search-button"
      >
        <span class="material-icons"> search </span>
      </button>
    </div>
    <div class="filter-input button-container">
      <button
        *hasPermission="'sc.asc.c'"
        mat-button
        class="custom-button"
        id="{{ entityName }}-create-button"
        (click)="create()"
      >
        {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.CREATE' | translate }}
      </button>
    </div>
  </div>

  <mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>

  <div class="task-list">
    <h5 class="subheading">{{ 'SEARCH-RESULT' | translate }}</h5>
    <table
      aria-hidden="true"
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
    >
      <ng-container matColumnDef="createAccountStatementConfigurationId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-createAccountStatementConfigurationId"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT-STATEMENT-CONFIGURATION-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-createAccountStatementConfigurationId-{{ paginator.pageIndex + 1 }}-{{
            i
          }}"
          class="number-row"
        >
          {{ element.visibleSeqId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-name"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="account">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-account"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-account-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.account }}
        </td>
      </ng-container>
      <ng-container matColumnDef="beasRelevant">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-beasRelevant"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.BEAS-RELEVANT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-beasRelevant-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.beasRelevant }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fiAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-fiAccount"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.FI-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-fiAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.fiAccount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-currency"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.CURRENCY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-currency-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="businessUnitId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-businessUnitId"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.BUSINESS-UNIT-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-businessUnitId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.businessUnitId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="platformId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-platformId"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.PLATFORM-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-platformId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.platformId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clientId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-clientId"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.CLIENT-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-clientId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.clientId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="debtorId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-debtorId"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.DEBTOR-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-debtorId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.debtorId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="btcMapping">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-btcMapping"
        >
          {{ 'SYSTEM-CONFIGURATION.CONFIGURATION.BTC-MAPPING' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-btcMapping-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.btcMapping }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index" (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'draft-row': row.draft }"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container>
      <button
        *hasPermission="'sc.asc.u'"
        mat-menu-item
        (click)="editAccountStatementConfiguration(element)"
        id="{{ entityName }}-table-results-action-menu-edit-button"
      >
        <mat-icon>edit</mat-icon>
        <span *ngIf="!element.draft">{{ 'edit' | translate }}</span>
        <span *ngIf="element.draft">{{ 'GENERAL-ENTITY.LIST.ACTIONS.EDIT-DRAFT' | translate }}</span>
      </button>
      <button
        *hasPermission="'sc.asc.d'"
        mat-menu-item
        (click)="deleteAccountStatement(element)"
        id="{{ entityName }}-table-results-action-menu-delete-button"
      >
        <mat-icon>delete</mat-icon>
        <span *ngIf="!element.draft">{{ 'delete' | translate }}</span>
        <span *ngIf="element.draft">{{ 'GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" (closed)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
