<div *ngIf="(editableTask && isTask) || !isTask">
  <div class="form-row">
    <mat-form-field appearance="{{ appearance ? appearance : 'fill' }}" [ngClass]="{ 'val-changed': changedValue }">
      <mat-label>{{ label | translate }}</mat-label>
      <textarea
        [maxlength]="matTextAreaSettings.MAXIMUM_COMMENT_SIZE"
        #textArea
        matInput
        placeholder="{{ placeholder | translate }}"
        name="{{ name | translate }}"
        formControlName="{{ controlName }}"
        id="{{ entityName }}-form-{{ controlName }}"
        [required]="requiredValidator"
        (input)="calculateRemainingCommentCharacters()"
      >
      </textarea>
      <div class="textarea-footer">{{ remainingCharacters }}/{{ matTextAreaSettings.MAXIMUM_COMMENT_SIZE }}</div>
      <mat-error
        *ngIf="controlObject.hasError('required') && form.touched"
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
      >
        {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: textArea.name | translate } }}
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': this.approvedValue && approvedValue != taskValue
        }"
        >{{ taskValue }}</span
      >
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ taskValue }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
