import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  CountryPickerService,
  ICountry,
} from 'src/app/shared/services/countries/country-picker.service';
import { of, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-country-selection-modal',
  templateUrl: './country-selection-modal.component.html',
  styleUrls: ['./country-selection-modal.component.less'],
})
export class CountrySelectionModalComponent implements OnInit {
  countryForm: UntypedFormGroup;
  countries$: Observable<ICountry[]>;
  selectedCountryCode: any;
  countryCode;
  filteredCountries: ICountry[] = [];
  countries: ICountry[] = [];
  entityName = 'countrySelectionModal';
  loader = true;
  existentCountries: any[];

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected countryPicker: CountryPickerService,
    @Inject(MAT_DIALOG_DATA) data,
    private translateService: TranslateService
  ) {
    this.countryForm = this.formBuilder.group({
      country: [null, Validators.required],
      countrySearch: [''],
    });

    if (data.existentCountries) {
      this.existentCountries = data.existentCountries;
    }
  }

  ngOnInit(): void {
    this.countryPicker.getCountries().subscribe((countries: ICountry[]) => {
      this.countries$ = of(countries);
      this.countries = countries;
      this.filteredCountries = Object.assign([], this.countries);

      this.formatCountries();

      if (this.existentCountries && this.existentCountries.length > 0) {
        this.removeExistentCountries();
      } else {
        this.loader = false;
      }
    });

    this.countryForm
      .get('countrySearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.searchFromSelect();
      });
  }

  searchFromSelect() {
    if (!this.countries) {
      return;
    }
    const searchTerm = this.countryForm.get('countrySearch').value;
    const resultCountries = [];
    for (const country of this.countries) {
      if (
        this.translateService
          .instant('countries.' + country.cca3)
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultCountries.push(country);
      }
    }
    this.filteredCountries = resultCountries;
  }

  formatCountries() {
    this.countries.forEach((element) => {
      element['translateName'] = 'countries.' + element.cca3;
    });
  }

  removeExistentCountries() {
    for (let i = this.countries.length - 1; i >= 0; i--) {
      const item = this.existentCountries.find(
        (data) => data === this.countries[i].cca3
      );

      if (item != null) {
        this.countries.splice(i, 1);
      }
    }

    this.loader = false;
  }
}
