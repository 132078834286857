<div class="non-editable-info-container mt-25">
  <div class="object-row" *ngIf="status == 'UPDATE'">
    <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <span class="object-label">{{ 'ENTITIES.PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="sameRoles !== ''">
        <span class="object-label">{{ 'ENTITIES.SAME-PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="sameRoles !== ''">
        <span class="object-value"> {{ sameRoles }} </span>
      </div>
      <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="newRoles !== ''">
        <span class="object-label">{{ 'ENTITIES.NEW-PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="newRoles !== ''">
        <span class="object-value new-role"> {{ newRoles }} </span>
      </div>
      <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100" *ngIf="deletedRoles !== ''">
        <span class="object-label">{{ 'ENTITIES.DELETED-PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100" *ngIf="deletedRoles !== ''">
        <span class="object-value deleted-role"> {{ deletedRoles }} </span>
      </div>
    </div>
  </div>
  <div class="object-row" *ngIf="status == 'CREATE'">
    <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
      <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <span class="object-label">{{ 'ENTITIES.PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
        <span class="object-label">{{ 'ENTITIES.NEW-PERMISSION' | translate }}</span>
      </div>
      <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
        <span class="object-value"> {{ newRoles }} </span>
      </div>
    </div>
  </div>
</div>
