<div class="content-container">
  <app-back-button></app-back-button>

  <div *ngIf="displaySplit" class="display-split">
    <button id="{{entityName}-display-split-button" mat-button class="display-split-button" (click)="drawer.open()">
      {{ 'GENERAL.DISPLAY-SPLIT' | translate }}
    </button>
  </div>

  <div *hasPermission="'aw.lp.r'">
    <app-livestock-information-box *ngIf="livestockProducer" [livestockProducer]="livestockProducer">
    </app-livestock-information-box>
  </div>

  <div class="tab-container">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-documents-tab">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TABS.DOCUMENTS' | translate }}
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-livestock-documents *ngIf="livestockProducer" [livestockProducer]="livestockProducer">
          </app-livestock-documents>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-blocking-indicator-tab">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TABS.BLOCKING-INDICATOR' | translate }}
          </div>
        </ng-template>
        <app-livestock-blocking-indicators
          *hasPermission="'aw.lp.r'"
          [livestockProducer]="livestockProducer"
          [livestockProducerId]="livestockProducerId"
        ></app-livestock-blocking-indicators>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-settlement-period-tab">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TABS.SETTLEMENT-PERIOD' | translate }}
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-livestock-settlement-periods
            *hasPermission="'aw.lp.r'"
            [livestockProducerId]="livestockProducer.id"
          ></app-livestock-settlement-periods>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-movement-data-tab">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TABS.MOVEMENT-DATA' | translate }}
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-livestock-movement-data *hasPermission="'aw.lp.r'" [livestockProducer]="livestockProducer">
          </app-livestock-movement-data>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div id="{{ entityName }}-functional-buttons-tab">
            {{ 'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.TABS.FUNCTIONAL-BUTTONS' | translate }}
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-livestock-functional-buttons [livestockProducer]="livestockProducer"> </app-livestock-functional-buttons>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-container>
    <mat-drawer-container>
      <mat-drawer #drawer class="sidenav" mode="over" position="end">
        <button type="button" mat-button (click)="drawer.close()" class="close-split">
          <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
        </button>
        <app-split-central-animal-welfare
          [splitData]="splitData"
          [originalData]="originalData"
          [companyName]="companyTitle"
        >
        </app-split-central-animal-welfare>
      </mat-drawer>
    </mat-drawer-container>
  </ng-container>
</div>
