export const accountTypes: any[] = [
  {
    value: 'ASSET',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.ASSET',
  },
  {
    value: 'EXPENSE',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.EXPENSE',
  },
  {
    value: 'INCOME',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.INCOME',
  },
  {
    value: 'LIABILITY',
    translateName:
      'SYSTEM-CONFIGURATION.SUB-LEDGER-ACCOUNTS.ACCOUNT-TYPE.LIABILITY',
  },
];
