<div class="faq-container">
  <div class="container full-height" fxLayout="row wrap" fxLayoutGap="7px grid">
    <div fxFlex="100">
      <div class="centered-row">
        <div class="texts-container-row">
          <div class="question">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-1' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-1' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-2' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-2' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-3' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-3' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-4' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-4' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-5' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-5' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-6' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-6' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-7' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-7' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-8' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-8' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-9' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-9' | translate }}
          </div>
          <div class="question next">
            {{ 'HOMEPAGE.DASHBOARD.QUESTION-10' | translate }}
          </div>
          <div class="answer">
            {{ 'HOMEPAGE.DASHBOARD.ANSWER-10' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
