export const productionOptions: any[] = [
  {
    value: '2001',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.2001',
    description: '',
    id: 0,
  },
  {
    value: '2004',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.2004',
    description: '',
    id: 1,
  },
  {
    value: '2008',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.2008',
    description: '',
    id: 2,
  },
  {
    value: '3001',
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.3001',
    description: '',
    id: 3,
  },
  {
    value: '3004(F)',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.3004F',
    description: '',
    id: 4,
  },
  {
    value: '3004(M)',
    translateName:
      'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE-OPTIONS.3004M',
    description: '',
    id: 5,
  },
];
