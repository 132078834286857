<div
  *ngIf="(editableTask && isTask) || !isTask"
  [ngClass]="{
    'inline-display': inline
  }"
>
  <div class="form-row" [formGroup]="formGroup">
    <mat-form-field appearance="{{ appearance ? appearance : 'fill' }}" [ngClass]="{ 'val-changed': changedValue }">
      <mat-label [attr.disabled]="disabled">{{ label | translate }}</mat-label>
      <input
        #autocompleteInput
        autocomplete="disabled"
        name="{{ name | translate }}"
        formControlName="{{ controlName }}"
        id="{{ entityName }}-form-{{ controlName }}"
        type="text"
        matInput
        [matAutocomplete]="auto"
        placeholder=" {{ placeholder | translate }}"
        [required]="requiredValidator"
        [attr.disabled]="disabled"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let item of filteredOptions | async" [value]="getItemValue(item)">
          <span *ngIf="optionTranslationText != ''">{{ optionTranslationText + '.' + getItem(item) | translate }}</span>
          <span *ngIf="optionTranslationText == ''">{{ getItem(item) | translate }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <app-mat-errors
      [controlObject]="controlObject"
      [controlName]="controlName"
      [entityName]="entityName"
      [name]="autocompleteInput.name"
    ></app-mat-errors>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
        >{{ taskValue }}</span
      >
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
          <span class="object-label">{{ label | translate }}:</span>
        </div>
        <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
          <span
            *ngIf="!optionTranslationText"
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue && approvedValue != taskValue
            }"
            >{{ taskValue }}</span
          >
          <span
            *ngIf="optionTranslationText"
            class="object-value"
            [ngClass]="{
              'val-changed': approvedValue && approvedValue != taskValue
            }"
            >{{ optionTranslationText + '.' + taskValue | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
