import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { UserService } from '../../../../../shared/services/user/user.service';

@Component({
  selector: 'app-role-data-visibility',
  templateUrl: './role-data-visibility.component.html',
  styleUrls: ['./role-data-visibility.component.less'],
})
export class RoleDataVisibilityComponent implements OnInit, AfterViewInit {
  entityName = 'user-role-data-visibility';
  @Input() userId: string;
  @Input() userEmail: string;
  @Input() isTask: boolean;
  @Input() userTask;
  @Input() approvedValue;
  @Input() realm;
  functionalRoles = [];
  organisationalRoles = [];
  searchRunning = false;
  groups;
  frmRoleDataVisibility;
  frmAdditionalInformation;
  scrollUpDistance = 2;
  amount = 20;
  lastAmount = 0;
  searchStringAll;
  selectedPermissions;
  activePermissions = [];
  searchStringActive;
  selectedActivePermissions: any[] = [];
  state;
  renderedPermissionList: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {
    this.roleDataVisibbiltyFormBuilder();
    this.additionalInformationFormBuilder();
  }

  roleDataVisibbiltyFormBuilder() {
    this.frmRoleDataVisibility = this.formBuilder.group({
      organisationalRole: [
        { value: null, disabled: false },
        Validators.required,
      ],
      functionalRole: [{ value: null, disabled: false }],
      groups: [{ value: [], disabled: true }],
    });
  }

  additionalInformationFormBuilder() {
    this.frmAdditionalInformation = this.formBuilder.group({
      userName: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }],
    });
  }

  getRealmUpdates(realm) {
    this.organisationalRoles = [];
    this.functionalRoles = [];
    if (realm === 'internal') {
      this.userService
        .listCompositeInternalRoles()
        .subscribe((internalRoles) => {
          if (internalRoles) {
            for (const role of internalRoles) {
              if (role['roleType'] === 'ORGANISATIONAL') {
                this.organisationalRoles.push(role);
              } else if (role['roleType'] === 'FUNCTIONAL') {
                this.functionalRoles.push(role);
              }
            }
          }
        });
    } else {
      this.userService
        .listCompositeExternalRoles()
        .subscribe((externalRoles) => {
          for (const role of externalRoles) {
            if (role['roleType'] === 'ORGANISATIONAL') {
              this.organisationalRoles.push(role);
            } else if (role['roleType'] === 'FUNCTIONAL') {
              this.functionalRoles.push(role);
            }
          }
        });
    }
  }

  onScroll() {
    this.lastAmount += this.amount;
    this.userService
      .listRlsEntries(this.amount, this.lastAmount, this.searchStringAll)
      .subscribe((rlsEntries) => {
        for (const group of rlsEntries) {
          this.groups.push(group);
        }
      });
  }

  ngOnInit() {
    this.state = window.history.state;
    if (this.state.viewMode) {
      this.frmRoleDataVisibility.disable();
    }
    this.userService
      .listRlsEntries(this.amount, this.lastAmount, this.searchStringAll)
      .subscribe((rlsEntries) => {
        this.groups = rlsEntries;
        if (!this.isTask) {
          this.addToUser();
        }
      });
  }

  ngAfterViewInit() {
    if (this.realm) {
      this.getRealmUpdates(this.realm);
    } else if (this.approvedValue && this.approvedValue['realm']) {
      this.getRealmUpdates(this.approvedValue['realm']);
    }
  }

  addToUser() {
    if (this.selectedPermissions && this.selectedPermissions.length > 0) {
      for (const permission of this.selectedPermissions) {
        for (const group of this.groups) {
          if (group.id === permission) {
            if (!this.isSelected(group)) {
              const currentList =
                this.frmRoleDataVisibility.get('groups').value ?? [];
              currentList.push(group);
              this.frmRoleDataVisibility.get('groups').patchValue(currentList);
              break;
            }
          }
        }
      }
    }
    this.searchInputChangedActivePermissions();
  }

  isSelected(group) {
    if (this.frmRoleDataVisibility.get('groups').value) {
      return this.frmRoleDataVisibility
        .get('groups')
        .value.find((activeGroup) => activeGroup.id === group.id);
    }
  }

  onGroupsChange(options: MatListOption[]) {
    this.selectedActivePermissions = [];
    for (const option of options) {
      if (option.selected) {
        this.selectedActivePermissions.push(option.value);
      }
    }
  }

  removeFromUser() {
    const form = this.frmRoleDataVisibility
      .get('groups')
      .value.filter((value) => !this.selectedActivePermissions.includes(value));
    this.frmRoleDataVisibility.get('groups').patchValue(form);

    this.searchInputChangedActivePermissions();
  }

  searchInputChanged() {
    this.lastAmount = 0;

    this.userService
      .listRlsEntries(this.amount, this.lastAmount, this.searchStringAll)
      .subscribe((rlsEntries) => {
        this.groups = rlsEntries;
      });
  }

  replaceAllTheValue(key) {
    if (key) {
      return key
        .replaceAll('|', '')
        .replace('}', '')
        .replace('{', '')
        .toString()
        .toLowerCase();
    }
    return;
  }

  searchInputChangedActivePermissions() {
    const form = this.frmRoleDataVisibility.get('groups').value ?? [];
    if (!this.searchStringActive) {
      this.searchStringActive = '';
    }
    this.renderedPermissionList = form.filter((value) =>
      this.replaceAllTheValue(value.rlsKey).includes(
        this.searchStringActive.toString().toLowerCase()
      )
    );
  }

  setActivePermissions(activePermissions: any[]) {
    this.activePermissions = activePermissions;
    for (const rlsKey of activePermissions) {
      this.renderedPermissionList.push(rlsKey);
    }
    this.frmRoleDataVisibility.get('groups').patchValue(activePermissions);
  }
}
