<div class="content-container">
  <div class="back-button-container">
    <app-back-button *ngIf="isTask"></app-back-button>
    <app-back-button *ngIf="!isTask" [address]="'quantityGoods'"></app-back-button>
  </div>
  <div class="edit-task">
    <button
      *hasPermission="'aw.g.u'"
      id="{{entityName}-edit-task"
      mat-button
      class="edit-task-button"
      (click)="changeTaskState(true)"
    >
      <img
        *ngIf="!isDeleteOperation && !editableTask && isTask"
        src="assets/images/ic_edit.svg"
        alt="{{ 'GENERAL.EDIT' | translate }}"
      />
    </button>
  </div>

  <span class="dynamic-loader-table" *ngIf="loaderSpinner">
    <mat-spinner [diameter]="50"></mat-spinner>
  </span>

  <div class="table-list" [ngClass]="{ 'back-button-exists': isTask }" [hidden]="loaderSpinner">
    <form class="form" [formGroup]="qsidForm">
      <div class="form-row">
        <mat-form-field appearance="fill" class="form-row">
          <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate }}</mat-label>
          <mat-select
            #selectQSID
            placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate }}"
            formControlName="qsid"
            id="{{ entityName }}-table-results-qsid-{{ paginator.pageIndex + 1 }}"
            required
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="{{ 'search' | translate }}"
                noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                formControlName="userRoleSearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredUserRoles" [value]="option">
              {{ option.qsid }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="qsidForm.controls.qsid.errors && qsidForm.controls.qsid.errors.required"
            class="input-error"
          >
            {{
              'ERROR-MESSAGES.REQUIRED'
                | translate
                  : {
                      name: 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate
                    }
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <table
      mat-table
      [dataSource]="dataSource"
      *ngIf="!loaderSpinner"
      matSort
      id="{{ entityName }}-search-results-table"
      aria-hidden="true"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
          <mat-checkbox
            id="{{ entityName }}-table-results-select-all"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox
            id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
            [aria-label]="checkboxLabel(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="qsid">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-name-german"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-qsid-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged('userRole', element) }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate }}</mat-label>
              <mat-select
                #selectQSID
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate }}"
                formControlName="userRole"
                [required]="isFieldRequired(element, 'userRole')"
                id="{{ entityName }}-table-results-qsid-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="{{ 'search' | translate }}"
                    noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                    formControlName="userRoleSearch"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredUserRoles" [value]="option.id">
                  {{ option.qsid }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.userRole.errors &&
                  getFormGroup(element).controls.userRole.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.QSID' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vvvoNr">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-vvvoNr"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-vvvoNr-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="vvvoNr-input"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged('vvvoNr', element) }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR' | translate }}</mat-label>
              <input
                autocomplete="off"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-vvvoNr"
                matInput
                type="text"
                formControlName="vvvoNr"
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR' | translate }}"
                [required]="isFieldRequired(element, 'vvvoNr')"
                [removeHtmlTags]="getFormGroup(element).controls.vvvoNr"
              />
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.vvvoNr.errors &&
                  getFormGroup(element).controls.vvvoNr.errors.invalid &&
                  !getFormGroup(element).controls.vvvoNr.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.INVALID'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR' | translate
                        }
                }}
              </mat-error>

              <mat-error
                *ngIf="
                  getFormGroup(element).controls.vvvoNr.errors && getFormGroup(element).controls.vvvoNr.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.VVVO-NR' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
            <div
              *ngIf="
                getFormGroup(element).controls.vvvoNr.errors &&
                getFormGroup(element).controls.vvvoNr.errors.inexistent &&
                !getFormGroup(element).controls.vvvoNr.errors.invalid &&
                getFormGroup(element).controls.vvvoNrLoader.value == false
              "
              class="input-error custom-error-vvvoNr"
            >
              {{
                'ERROR-MESSAGES.INEXISTENT'
                  | translate
                    : {
                        name: 'ANIMAL-WELFARE.QUANTITY-GOODS.LIVESTOCK-PRODUCER' | translate
                      }
              }}
            </div>
            <span
              *ngIf="
                getFormGroup(element).controls.vvvoNr.value &&
                getFormGroup(element).controls.productionType.value &&
                getFormGroup(element).controls.submissionDate.value &&
                getFormGroup(element).controls.vvvoNr.errors == null &&
                getFormGroup(element).controls.vvvoNrLoader.value == false
              "
              class="material-icons valid-icon"
            >
              check_circle
            </span>
            <span class="spinner-loader-container" *ngIf="getFormGroup(element).controls.vvvoNrLoader.value == true">
              <mat-spinner class="spinner-loader" [diameter]="20"></mat-spinner>
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="productionType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-production-type"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-production-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{
                'val-changed': hasChanged('productionType', element)
              }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate }}</mat-label>
              <mat-select
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate }}"
                formControlName="productionType"
                [required]="isFieldRequired(element, 'productionType')"
                (selectionChange)="changeMeasureUnit(element); checkVVVONrError(element)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="{{ 'search' | translate }}"
                    noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                    formControlName="productionTypeSearch"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredProductionTypes" [value]="option.value">
                  {{ option.value }} {{ option.translateName | translate }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.productionType.errors &&
                  getFormGroup(element).controls.productionType.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.PRODUCTION-TYPE' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="submissionDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-submission-date"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-submission-date-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              [formGroup]="getFormGroup(element)"
              appearance="fill"
              [ngClass]="{
                'val-changed': hasChanged('submissionDate', element)
              }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate }}</mat-label>
              <input
                #input
                autocomplete="off"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-submission-date"
                matInput
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate }}"
                name="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate }}"
                formControlName="submissionDate"
                [matDatepicker]="datePicker"
                [required]="isFieldRequired(element, 'submissionDate')"
              />
              <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.submissionDate.errors &&
                  getFormGroup(element).controls.submissionDate.errors.invalid &&
                  !getFormGroup(element).controls.submissionDate.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.INVALID'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate
                        }
                }}
              </mat-error>
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.submissionDate.errors &&
                  getFormGroup(element).controls.submissionDate.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.SUBMISSION-DATE' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="measureUnit">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-measure-unit"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-measure-unit-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div
            class="form-row form-row-radio"
            *ngIf="getFormGroup(element) != undefined"
            [formGroup]="getFormGroup(element)"
          >
            <mat-radio-group
              [ngClass]="{ 'val-changed': hasChanged('measureUnit', element) }"
              name="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT' | translate }}-{{ paginator.pageIndex + 1 }}-{{
                i
              }}"
              formControlName="measureUnit"
              id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-measure-unit"
              [required]="isFieldRequired(element, 'measureUnit')"
            >
              <mat-radio-button
                *ngFor="let item of measureUnitOptions; let x = index"
                id="{{ entityName }}-form-measure-unit-{{ paginator.pageIndex + 1 }}-{{ i }}-{{ x }}"
                [value]="item.name"
                >{{ item.translateName | translate | titlecase }}</mat-radio-button
              >
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.measureUnit.errors &&
                  getFormGroup(element).controls.measureUnit.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.MEASURE-UNIT' | translate
                        }
                }}
              </mat-error>
            </mat-radio-group>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-amount"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-amount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged('amount', element) }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY' | translate }}</mat-label>
              <input
                autocomplete="off"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-amount"
                matInput
                min="1"
                formControlName="amount"
                (input)="checkNegative($event)"
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY' | translate }}"
                [required]="isFieldRequired(element, 'amount')"
                mask="separator.3"
                [thousandSeparator]="getThousandSeperator()"
              />
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.amount.errors && getFormGroup(element).controls.amount.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.QUANTITY' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="transfer">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-tranfer"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transfer-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged('transfer', element) }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER' | translate }}</mat-label>
              <mat-select
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER' | translate }}"
                formControlName="transfer"
                [required]="isFieldRequired(element, 'transfer')"
                (selectionChange)="onTransferChange($event, element)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="{{ 'search' | translate }}"
                    noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                    formControlName="transferSearch"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let option of filteredTransferOptions" [value]="option.value">
                  {{ option.translateName | translate }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.transfer.errors &&
                  getFormGroup(element).controls.transfer.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.TRANSFER' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          (click)="paginator.firstPage()"
          id="{{ entityName }}-table-results-comment"
        >
          {{ 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-comment-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <div *ngIf="getFormGroup(element) != undefined">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged('comment', element) }"
            >
              <mat-label>{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT' | translate }}</mat-label>
              <input
                (keydown.Tab)="onKey()"
                autocomplete="off"
                id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-comment"
                matInput
                type="text"
                formControlName="comment"
                placeholder="{{ 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT' | translate }}"
                [required]="isFieldRequired(element, 'comment')"
                [removeHtmlTags]="getFormGroup(element).controls.comment"
              />
              <mat-error
                *ngIf="
                  getFormGroup(element).controls.comment.errors &&
                  getFormGroup(element).controls.comment.errors.required
                "
                class="input-error"
              >
                {{
                  'ERROR-MESSAGES.REQUIRED'
                    | translate
                      : {
                          name: 'ANIMAL-WELFARE.QUANTITY-GOODS.COMMENT' | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button
            *ngIf="!isTask"
            [disabled]="editMode && !isDraft"
            mat-button
            class="remove-button"
            id="{{ entityName }}-delete-button"
            (click)="deleteSelectedRows(element)"
          >
            <mat-icon class="add-icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [hidden]="loaderSpinner"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'draft-row': row.draft }"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
    </table>
  </div>

  <div [hidden]="loaderSpinner">
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons> </mat-paginator>

    <div class="filters checksum-container" [formGroup]="checkSumForm">
      <div class="filter-input">
        <app-mat-input
          style="margin-left: 40px"
          [name]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
          [placeholder]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
          [label]="'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.CHECKSUM'"
          [entityName]="entityName"
          [form]="checkSumForm"
          [controlObject]="checkSumForm.controls.checksum"
          [controlName]="'checksum'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        >
        </app-mat-input>
      </div>
    </div>

    <div class="filter-input button-container">
      <ng-container>
        <button
          *ngIf="!isTask || (isTask && editableTask)"
          (click)="cancelEdit()"
          mat-button
          class="custom-button-cancel"
          id="{{ entityName }}-cancel-button"
        >
          <span>
            {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
          </span>
        </button>
      </ng-container>
      <ng-container *hasPermission="'aw.g.c'">
        <button
          *ngIf="!isTask && (!editMode || isDraft)"
          mat-button
          class="custom-button"
          id="{{ entityName }}-create-button"
          (click)="addEmptyRow()"
        >
          <span>
            {{ 'ANIMAL-WELFARE.QUANTITY-LABELLED-GOODS.ADD-NEW' | translate }}
          </span>
        </button>
      </ng-container>
      <ng-container *hasPermission="'aw.g.c'">
        <button
          *ngIf="!isTask || (isTask && editableTask)"
          [disabled]="getDisabledStatus() || qsidForm.invalid"
          (click)="onClickSave(false)"
          mat-button
          class="custom-button"
          id="{{ entityName }}-save-button"
        >
          <span>
            {{ 'GENERAL.SAVE' | translate }}
          </span>
        </button>
      </ng-container>
      <ng-container *hasPermission="'aw.g.c.d'">
        <button
          [disabled]="getDisabledStatus() || qsidForm.invalid"
          *ngIf="!isTask"
          (click)="onClickSave(true)"
          mat-button
          class="custom-button"
          id="{{ entityName }}-save-button"
        >
          <span>
            {{ 'GENERAL-ENTITY.CREATE.CREATE-DRAFT' | translate }}
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #appMenu="matMenu" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
