import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { nonGermanyReferenceAllowColumns } from 'src/app/in-memory-data/non-germany-reference-allow/history/table-columns';
import { User } from 'src/app/shared/models/user';
import { TciService } from 'src/app/shared/services/tci/tci.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { isEmpty } from 'src/app/utils/object-util';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-non-germany-reference-allow-list-history',
  templateUrl: './non-germany-reference-allow-list-history.component.html',
  styleUrls: ['./non-germany-reference-allow-list-history.component.less'],
})
export class NonGermanyReferenceAllowListHistoryComponent implements OnInit {
  entityName = 'non-germany-reference-allow-list-history';

  displayedColumns = nonGermanyReferenceAllowColumns;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  dateFormat: any;
  userList: User[];

  constructor(
    private userService: UserService,
    private tciService: TciService,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.suscriptionEmitter =
      this.sharedDataService.eventEmiterFunction.subscribe((data) => {
        this.getHistory();
      });
  }

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
    this.userService.listAll().subscribe((data) => {
      this.userList = data;
      this.getHistory();
    });
  }

  getHistory() {
    this.tciService.listHistoryNonGermanyReference().subscribe((data) => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = data;
      this.dataSource.filter = '';
    });
  }

  getUserInfo(userName: string) {
    if (userName && userName.length > 0) {
      const user = this.userService.getAccountManagerName(
        userName,
        this.userList
      );
      return !isEmpty(user)
        ? user.lastName + ', ' + user.firstName + ' (' + user.username + ')'
        : '';
    }
  }
}
