import { AfterContentInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { TransactionSearchFilter } from '../accounting-overview-functional-buttons/accounting-overview-functional-buttons.component';

@Component({
  selector: 'app-accounting-kpi-overview',
  templateUrl: './accounting-kpi-overview.component.html',
  styleUrls: ['./accounting-kpi-overview.component.less'],
})
export class AccountingKPIOverviewComponent implements AfterContentInit {
  @Input() entity: any;
  @Input() originalEntity: any;
  dateFormat = '';
  entityName = 'accounting-kpi-overview';
  businessUnit: string;
  @Input() searchTerm: BehaviorSubject<TransactionSearchFilter>;

  constructor(private userService: UserService, private router: Router) {}

  ngAfterContentInit() {
    let format;
    this.userService.getSettings().subscribe((data) => {
      if (data && data.profile) {
        format = data.profile.dateFormat;
      }
      if (format === 'GERMAN') {
        this.dateFormat = 'dd.MM.yyyy';
      }
      if (format === 'BRITISH') {
        this.dateFormat = 'dd/MM/yyyy';
      }
      if (format === 'AMERICAN') {
        this.dateFormat = 'MM-dd-yyyy';
      }
    });
  }

  redirect(kpi) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(
      '/accounting/overview/' + kpi.entityGuid + '/' + kpi.entityType
    );
  }
}
