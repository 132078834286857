import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { classificationRelevantOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/classificationRelevant';
import { relevantForRemainingLimitCalcOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/relevantForRemainingLimitCalc';
import { settlementRelevantOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/settlementRelevant';
import { transactionTypes } from 'src/app/in-memory-data/bookingCode/formConfiguration/transactionTypes';
import { standards } from 'src/app/in-memory-data/bookingCode/formConfiguration/standards';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { KeycloakService } from 'keycloak-angular';
import { bookingOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/bookingOptions';
import { SubLedgerService } from 'src/app/shared/services/sub-ledger/sub-ledger.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

const NOT_RELEVANT = 'NOT_RELEVANT';
@Component({
  selector: 'app-booking-code-general-information',
  templateUrl: './booking-code-general-information.component.html',
  styleUrls: ['./booking-code-general-information.component.less'],
})
export class BookingCodeGeneralInformationComponent implements OnInit {
  @Input() bookingCodeTask;
  @Input() bookingCodeApproved;
  @Input() bookingCodeInitial;
  @Input() isDraftExists: boolean;
  bookingCodeForm;
  isTask = false;
  editableTask: any;
  subledgerAccounts = [];
  currentSelectedLanguage;
  classRelevantOptions = classificationRelevantOptions;
  relevantForRemainingLimitCalcOptions = relevantForRemainingLimitCalcOptions;
  settlementRelevantOptions = settlementRelevantOptions;
  transactionTypes = transactionTypes;
  standards = standards;
  bookingOptions = bookingOptions;
  disableSpecialFields = false;
  loaderSpinner = true;
  editMode = false;
  state;
  entityName = 'booking-code-general-information';
  errorRequired = 'error-message-required';

  accounts = [
    {
      name: NOT_RELEVANT,
      translateName: this.translateService.instant(
        'BOOKING-CODE.ACCOUNTS.NOT_RELEVANT'
      ),
      account: 'Not Relevant',
      id: NOT_RELEVANT,
      accountNameEnglish: '',
      accountNameGerman: '',
    },
  ];

  salesTaxAccounts = [
    {
      name: NOT_RELEVANT,
      translateName: 'BOOKING-CODE.ACCOUNTS.NOT_RELEVANT',
      account: 'Not Relevant',
      id: NOT_RELEVANT,
      accountNameEnglish: '',
      accountNameGerman: '',
    },
  ];

  objectAccounts = [
    {
      name: NOT_RELEVANT,
      translateName: 'BOOKING-CODE.ACCOUNTS.NOT_RELEVANT',
      account: 'Not Relevant',
      id: NOT_RELEVANT,
      accountNameEnglish: '',
      accountNameGerman: '',
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private taskService: TaskService,
    private kcService: KeycloakService,
    private subledgerAccountService: SubLedgerService,
    private transationService: TranslationService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.disableSpecialFields = !this.kcService
      .getUserRoles()
      .includes('bc.sf.u');
    this.bookingCodeFormBuilder();
  }

  ngOnInit(): void {
    this.initView();
    this.recalculateTransaltionNameForAccounts();
  }

  recalculateTransaltionNameForAccounts() {
    this.transationService.currentSelectedLanguage.subscribe(
      (currentSelectedLanguage) => {
        if (currentSelectedLanguage == null) {
          currentSelectedLanguage = localStorage.currentSelectedLanguage;
        }
        this.currentSelectedLanguage = currentSelectedLanguage;

        this.accounts
          .filter((acc) => acc.name !== NOT_RELEVANT)
          .forEach((account) => {
            account.translateName = this.getAccountTranslateName(account);
          });

        this.salesTaxAccounts
          .filter((acc) => acc.name !== NOT_RELEVANT)
          .forEach((account) => {
            account.translateName = this.getAccountTranslateName(account);
          });

        this.objectAccounts
          .filter((acc) => acc.name !== NOT_RELEVANT)
          .forEach((account) => {
            account.translateName = this.getAccountTranslateName(account);
          });
      }
    );
  }

  initView() {
    this.state = window.history.state;
    this.transactionTypes.sort((a, b) => {
      return this.translateService
        .instant(a.translateName)
        .localeCompare(this.translateService.instant(b.translateName));
    });

    if (this.state.task) {
      this.patchTaskValues();
    } else if (this.state.booking) {
      this.patchStateValues();
    } else {
      this.patchRouteParamValues();
    }

    this.findSubledgerAccountNonAW();
    this.findSalesTaxAccount();
    this.findObjectAccounts();
  }

  patchTaskValues() {
    this.isTask = true;
    this.loaderSpinner = false;
    this.patchFormValue(this.bookingCodeTask);
    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );
  }

  patchStateValues() {
    this.loaderSpinner = false;
    if (this.state.booking.draft) {
      this.patchFormValue(this.state.booking);
    } else {
      this.editMode = true;
      this.bookingCodeForm.get('id').disable();
      if (this.isDraftExists) {
        this.bookingCodeForm.get('name').disable();
        this.bookingCodeForm.get('description').disable();
        this.bookingCodeForm.get('transactionType').disable();
      }
      this.disableSpecialFields = !this.kcService
        .getUserRoles()
        .includes('bc.sf.u');
      this.patchFormValue(this.bookingCodeApproved);
    }
  }

  patchRouteParamValues() {
    if (
      this.bookingCodeApproved !== null &&
      this.bookingCodeApproved !== undefined
    ) {
      this.patchFormValue(this.bookingCodeApproved);
      this.loaderSpinner = false;
    } else if (!this.state.createMode) {
      this.router.navigateByUrl('/systemConfiguration/booking');
    }
    this.loaderSpinner = false;
  }

  findSubledgerAccountNonAW() {
    this.subledgerAccountService
      .findSubledgerAccountsNotAw()
      .subscribe((data) => {
        data.forEach((element) => {
          this.accounts.push({
            name: element.accountNumber,
            translateName: this.getAccountTranslateName(element),
            account: element.accountNumber,
            id: element.guid,
            accountNameEnglish: element.accountNameEnglish,
            accountNameGerman: element.accountNameGerman,
          });
        });
      });
  }

  findSalesTaxAccount() {
    this.subledgerAccountService.findSalesTaxAccounts().subscribe((data) => {
      data.forEach((element) => {
        this.salesTaxAccounts.push({
          name: element.accountNumber,
          translateName: this.getAccountTranslateName(element),
          account: element.accountNumber,
          id: element.guid,
          accountNameEnglish: element.accountNameEnglish,
          accountNameGerman: element.accountNameGerman,
        });
      });
    });
  }

  findObjectAccounts() {
    this.subledgerAccountService.findObjectAccounts().subscribe((accounts) => {
      accounts.forEach((account) => {
        this.objectAccounts.push({
          name: account.accountNumber,
          translateName: this.getAccountTranslateName(account),
          account: account.accountNumber,
          id: account.guid,
          accountNameEnglish: account.accountNameEnglish,
          accountNameGerman: account.accountNameGerman,
        });
      });
    });
  }

  getAccountTranslateName(account) {
    const accountTransalatedName =
      this.currentSelectedLanguage === 'en'
        ? account.accountNameEnglish
        : account.accountNameGerman;
    return `${account.accountNumber} ${accountTransalatedName}`;
  }

  bookingCodeFormBuilder() {
    this.bookingCodeForm = this.formBuilder.group({
      guid: [''],
      id: [null, Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      approvalTaskId: [''],
      classificationRelevant: [null, Validators.required],
      relevantForRemainingLimitCalc: [null, Validators.required],
      settlementRelevant: [null, Validators.required],
      transactionType: [
        {
          value: '',
          disabled: this.disableSpecialFields
            ? this.disableSpecialFields
            : false,
        },
        Validators.required,
      ],
      defaultNameEnglish: [null, Validators.required],
      descriptionEnglish: [null, Validators.required],
      transferTransactionCode: [null],
      standard: [null, Validators.required],
      cancellation: [null],
      sapLedgerAccountDebit: ['NOT_RELEVANT', Validators.required],
      sapLedgerAccountCredit: ['NOT_RELEVANT', Validators.required],
      objectionAccountBeVision: [null, Validators.required],
      bookingOption: [null, Validators.required],
      isDueDate: [null, Validators.required],
      relevantSales: [null],
      generalLedgerAccount: ['NOT_RELEVANT', Validators.required],
      generalLedgerCounterAccount: [null, Validators.required],
      salesTaxAccount: [null],
      displayExternally: [null],
    });

    this.bookingCodeForm.controls.standard.valueChanges.subscribe((val) => {
      if (val === 1 || val === 2) {
        this.bookingCodeForm.controls.cancellation.setValidators([
          Validators.required,
        ]);
      } else {
        this.bookingCodeForm.controls.cancellation.setValidators(null);
      }
      this.bookingCodeForm.controls.cancellation.updateValueAndValidity();
    });
  }

  patchFormValue(data) {
    this.bookingCodeForm.patchValue(data);
    this.bookingCodeForm.get('id').patchValue(String(data.id).padStart(3, '0'));
  }

  get getTransactionType() {
    return this.findTransactionType.bind(this);
  }

  findTransactionType(transactionTypeName) {
    if (!this.transactionTypes) {
      return '';
    }

    const transactionType = this.transactionTypes.find(
      (element) => element.name === transactionTypeName
    );
    return transactionType ? transactionType.translateName : '';
  }

  get getBookingOption() {
    return this.findBookingOption.bind(this);
  }

  findBookingOption(bookingOptionCode) {
    if (!this.bookingOptions) {
      return '';
    }

    const bookingOption = this.bookingOptions.find(
      (element) => element.code === bookingOptionCode
    );
    return bookingOption ? bookingOption.translateName : '';
  }

  get getStandard() {
    return this.findStandard.bind(this);
  }

  findStandard(standardCode) {
    if (!this.standards) {
      return '';
    }

    const standard = this.standards.find(
      (element) => element.code === standardCode
    );
    return standard ? standard.translateName : '';
  }

  get getAccount() {
    return this.findAccount.bind(this);
  }

  findAccount(accountName) {
    if (!this.accounts) {
      return '';
    }

    const account = this.accounts.find(
      (element) => element.name === accountName
    );
    return account ? account.translateName : '';
  }

  get getTaxAccount() {
    return this.findTaxAccount.bind(this);
  }

  findTaxAccount(taxAccountName) {
    if (!this.salesTaxAccounts) {
      return '';
    }

    const taxAccount = this.salesTaxAccounts.find(
      (element) => element.account === taxAccountName
    );
    return taxAccount ? taxAccount.account : '';
  }

  restrictIdInputToPositiveIntegers(event: any) {
    let input;
    let reg;
    input = event.target.value + event.key;
    reg = /^\d+$/;
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  getApprovedValue(fieldName) {
    if (this.bookingCodeApproved) {
      if (this.bookingCodeApproved[fieldName]) {
        return this.bookingCodeApproved[fieldName];
      } else {
        return '';
      }
    } else if (this.bookingCodeInitial) {
      if (this.bookingCodeInitial[fieldName]) {
        return this.bookingCodeInitial[fieldName];
      } else {
        return '';
      }
    } else {
      return null;
    }
  }

  getSubledgerAccount(fieldName) {
    if (!fieldName || !this.getApprovedValue(fieldName)) {
      return this.getApprovedValue(fieldName);
    }
    return this.getSubledgerAccountInfo(this.getApprovedValue(fieldName));
  }

  getSubledgerAccountInfo(guid) {
    if (!guid) {
      return '';
    }
    if (guid === 'NOT_RELEVANT') {
      return 'NOT_RELEVANT';
    }
    if (this.subledgerAccounts[guid]) {
      return this.subledgerAccounts[guid].accountNumber;
    }
    this.subledgerAccounts[guid] = { accountNumber: guid };
    this.subledgerAccountService.findByGuid(guid).subscribe((data) => {
      this.subledgerAccounts[guid] = data;
    });
    return guid;
  }
}
