<div class="task-list">
  <h5 class="subheading" id="{{ entityName }}-search-results-table-header">
    {{ 'SEARCH-RESULT' | translate }}
  </h5>
  <div class="table-container">
    <table
      aria-hidden="true"
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
          <mat-checkbox
            #ref
            (change)="$event ? masterToggle(ref) : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selectTask(row, selection.isSelected(row)) : null; isSomeSelected()"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
            [disabled]="isDisabled(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-id"
        >
          {{ 'TASK.ID' | translate }}
          <app-table-menu-sort-filter choice="id" [sortTable]="sortTable"></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-id-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-type">
          {{ 'TASK.TYPE' | translate }}
          <app-table-menu-sort-filter choice="type" [sortTable]="sortTable" [getDistinctValues]="getFieldNames">
            ></app-table-menu-sort-filter
          >
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.type | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-title">
          {{ 'TASK.TITLE' | translate }}
          <app-table-menu-sort-filter choice="title" [sortTable]="sortTable"></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-title-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getTaskTitle(element) | translate }}

          <span *ngIf="element.params && element.params['objectName']">
            {{ getObjectName(element) }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-status">
          {{ 'TASK.STATUS' | translate }}
          <app-table-menu-sort-filter
            choice="status"
            [status]="true"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-status-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ 'TASK.' + element.status | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-createdBy">
          {{ 'TASK.CREATED-BY' | translate }}
          <app-table-menu-sort-filter
            [userList]="userList"
            choice="created_by"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-createdBy-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getUserInfo(element.createdBy) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-dueDate">
          {{ 'TASK.DUE-DATE' | translate }}
          <app-table-menu-sort-filter
            date="dueDate"
            choice="due_date"
            [sortTable]="sortTable"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-dueDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dueDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priority">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th number-row-header-sortable"
          id="{{ entityName }}-table-results-priority"
        >
          {{ 'TASK.PRIORITY' | translate }}
          <app-table-menu-sort-filter
            choice="priority"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-priority-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.priority }}
        </td>
      </ng-container>
      <ng-container matColumnDef="assignedUserId">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-assignedUserId">
          {{ 'TASK.ASSIGNED-USER' | translate }}
          <app-table-menu-sort-filter
            [userList]="userList"
            choice="assigned_user_id"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-assignedUserId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getUserInfo(element.assignedUserId) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-createdOn">
          {{ 'GENERAL.CREATED-ON' | translate }}
          <app-table-menu-sort-filter
            date="dueDate"
            choice="creation_date"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-createdOn-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.creationDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-team">
          {{ 'TASK.TEAM' | translate }}
          <app-table-menu-sort-filter
            choice="team_id"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-team-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.teamId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="businessUnitId">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-businessUnitId">
          {{ 'TASK.BU' | translate }}
          <app-table-menu-sort-filter
            [buList]="buList"
            choice="business_unit_id"
            [sortTable]="sortTable"
            [getDistinctValues]="getFieldNames"
          ></app-table-menu-sort-filter>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-businessUnitId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getBusinessUnit(element.businessUnitId) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="locked">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-locked"></th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-locked-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-icon *ngIf="element.isLocked" color="{{ getLockedColor(element) }}">lock</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="lockedBy">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-lockedBy">
          {{ 'TASK.LOCKED-BY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-lockedBy-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span *ngIf="element.isLocked">
            {{ element.lockedUserId }} {{ ' ' }}
            {{ element.updateDate | date: dateFormat + ' HH:mm:ss' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="taskObject">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-taskObject">
          {{ 'TASK-CONFIGURATION.OBJECT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-taskObject-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span *ngIf="element && element.params">
            {{
              'TASK-CONFIGURATION.OBJECT-NAME-ENTITY'
                | translate
                  : {
                      entity: getTaskEntity(element, 'name')
                    }
            }}
            {{ getObjectName(element) }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="taskObjectId">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-taskObjectId">
          {{ 'TASK-CONFIGURATION.OBJECT-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-taskObjectId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span *ngIf="element && element.params">
            {{
              'TASK-CONFIGURATION.OBJECT-ID-ENTITY'
                | translate
                  : {
                      entity: getTaskEntity(element, 'id')
                    }
            }}
            {{ getObjectId(element) }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="clickOnTask(row)"
        [hidden]="loaderSpinner"
        class="element-row"
      ></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="[20, 50, 100, 200]"
    showFirstLastButtons
    [length]="totalCount"
    (page)="paginate($event)"
  ></mat-paginator>

  <mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
    <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
  </mat-menu>
  <div class="buttons">
    <ng-container *hasPermission="'tm.ft'">
      <button
        mat-button
        class="custom-button"
        (click)="forwardTask()"
        id="{{ entityName }}-forward-button"
        [disabled]="selection.isEmpty()"
      >
        {{ 'TASK.FORWARD' | translate }}
      </button>
    </ng-container>
  </div>
</div>
