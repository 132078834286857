import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DefaultEditorService {
  edit(element) {
    /**
     * I couldn't undestand what this do exaclty, but the sonarqube don't like
     * to much of empty function. So this comment is to makes the sonarqube happy.
     * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
     */
  }
}
