import { commentEnum } from './commentEnum';
export const liquidityPlanAssetChangeColumnsConf: any[] = [
  {
    name: 'planAmount',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.PLAN-AMOUNT',
    locked: false,
  },
  {
    name: 'actualAmount',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.ACTUAL-AMOUNT',
    locked: false,
  },
  {
    name: 'kind',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.KIND',
    locked: false,
    translatePrefix: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.',
    columnValue: 'commentOthers',
    prefixCheck: true,
    flagValue: commentEnum.OTHERS,
  },
  {
    name: 'type',
    index: 4,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.TYPE',
    locked: false,
    translatePrefix: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSETS.',
  },
  {
    name: 'monthAndYear',
    index: 5,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.MONTH-YEAR',
    locked: false,
    dateFormat: 'monthAndYear',
  },
  {
    name: 'slider',
    index: 6,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CANCELLATION',
    locked: false,
    columnValue: 'cancellation',
  },
  {
    name: 'comment',
    index: 7,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.COMMENT',
    locked: false,
  },
  {
    name: 'status',
    index: 8,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.STATUS',
    locked: false,
    translatePrefix: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.',
  },
  {
    name: 'createdBy',
    index: 9,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CREATED-BY',
    locked: false,
  },
  {
    name: 'createdOn',
    index: 10,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.CREATED-ON',
    locked: false,
    dateFormat: 'fullYearWithHoursMinutesSeconds',
  },
  {
    name: 'approvedBy',
    index: 11,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.APPROVED-BY',
    locked: false,
  },
  {
    name: 'approvedOn',
    index: 12,
    checked: true,
    translateName: 'ANIMAL-WELFARE.LIQUIDITY-PLAN.ASSET-CHANGE.APPROVED-ON',
    locked: false,
    dateFormat: 'fullYearWithHoursMinutesSeconds',
  },
  {
    version: 1.2,
  },
];
