<div class="content-container">
  <div class="message-list">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matSort
      id="{{ entityName }}-search-results-table"
      [class.has-expanded-row]="expandedMessage !== null"
      aria-hidden="true"
    >
      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="date-column v25-th"
          id="{{ entityName }}-table-results-{{ displayedColumns[0] }}"
        >
          {{ 'MESSAGE.DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          class="date-column"
          id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ i }}"
        >
          {{ element.date | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="subject">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-{{ displayedColumns[1] }}"
        >
          {{ 'MESSAGE.SUBJECT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          id="{{ entityName }}-table-results-{{ displayedColumns[0] }}-{{ i }}"
        >
          {{ getTranslatedMessage(element) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="no-results-table" *ngIf="dataSource.data.length == 0 && !loaderSpinner">
            {{ 'ERROR.NO-DATA-FOUND' | translate }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="content">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="element == expandedMessage ? 'expanded' : 'collapsed'">
            <div
              class="element-description"
              [innerHTML]="element.contentTranslationKey | translate: element.params"
            ></div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [hidden]="loaderSpinner"
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="expandedMessage === element"
        [class.unread]="!element.markedAsRead"
        (click)="expand(element)"
      ></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="['noResults']" class="height-footer"></tr>
      <tr mat-row *matRowDef="let row; columns: ['content']" class="detail-row"></tr>
    </table>
  </div>
</div>
