import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TaskEventType } from '../../models/task-event-type';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionCodeAllocationService {
  readonly searchServiceUrl: string;
  readonly serviceUrl: string;
  readonly taskServiceUrl: string;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.searchServiceUrl = environment.settings.searchAPIUrl;
    this.serviceUrl = environment.settings.companyService;
    this.taskServiceUrl = environment.settings.taskService;
  }

  retrieveTransactionCodeByEntityId(businessUnitId): Observable<any> {
    return this.http.get<any>(
      this.searchServiceUrl + 'transaction-code-allocation/' + businessUnitId,
      this.buildHeaders()
    );
  }

  retrieveByTransactionCodeId(transactionCodeId): Observable<any> {
    return this.http.get<any>(
      this.searchServiceUrl +
        'transaction-code-allocation/allocation/' +
        transactionCodeId,
      this.buildHeaders()
    );
  }

  setTransactionCodeActive(request): Observable<any> {
    return this.http.post<any>(
      this.serviceUrl + 'transaction-code-allocation/set-to-active',
      request,
      this.buildHeaders()
    );
  }

  setTransactionCodeInactive(request): Observable<any> {
    return this.http.post<any>(
      this.serviceUrl + 'transaction-code-allocation/set-to-inactive',
      request,
      this.buildHeaders()
    );
  }

  editTransactionCode(request): Observable<any> {
    return this.http.post<any>(
      this.serviceUrl + 'transaction-code-allocation/edit',
      request,
      this.buildHeaders()
    );
  }

  deleteTransactionCodeAllocation(transactionCodeId) {
    return this.http.delete<any>(
      this.serviceUrl + 'transaction-code-allocation/' + transactionCodeId,
      this.buildHeaders()
    );
  }

  allocateIncompleteTransactionCodes(request) {
    return this.http.post(
      this.serviceUrl + 'transaction-code-allocation/add',
      request,
      this.buildHeaders()
    );
  }

  approveTask(id: any, comment: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + id + '/approve',
      json,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string, taskGuid?: string) {
    const json = {
      taskId: id,
      content: comment,
      eventType: TaskEventType.COMPLETE_TASK,
    };
    return this.http.post(
      this.taskServiceUrl + 'tasks/' + taskGuid + '/reject',
      json,
      this.buildHeaders()
    );
  }

  retrieveTransactionCodeAllocation(
    businessUnitId,
    transactionCode
  ): Observable<any> {
    return this.http.get<any>(
      this.serviceUrl +
        'transaction-code-allocation/' +
        businessUnitId +
        '/' +
        transactionCode,
      this.buildHeaders()
    );
  }

  private buildHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
