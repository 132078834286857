import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BusinessUnitService } from 'src/app/shared/services/business-unit/business-unit.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { OpenItemService } from 'src/app/shared/services/open-item/open-item.service';
import { TablesService } from 'src/app/shared/tables/tables.service';
import { SharedDataService } from '../services/shared-data.service';
import { v4 as uuidv4 } from 'uuid';
import { LiquidityPlanService } from 'src/app/shared/services/animal-welfare/liquidity-plan/liquidity-plan.service';
import { UntypedFormGroup } from '@angular/forms';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-transaction-booking-functional-buttons',
  templateUrl: './open-transaction-booking-functional-buttons.component.html',
  styleUrls: ['./open-transaction-booking-functional-buttons.component.less'],
})
export class OpenTransactionBookingFunctionalButtonsComponent
  implements OnInit
{
  entityName = 'open-transaction-booking-functional-button';

  sharedOpenTransactionRows;
  sharedCommonForm;

  businessUnits: any;
  businessUnitProduct: any;
  businessUnitManualBookingApproval: any;
  businessUnitGuid: any;

  operator: any;

  loaderAdd = false;

  state;
  isTask;
  editableTask;

  isDeleteRowsDisabled = true;

  constructor(
    private sharedDataService: SharedDataService,
    private tableService: TablesService,
    private openItemService: OpenItemService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private businessUnitService: BusinessUnitService,
    private liquidityPlanService: LiquidityPlanService,
    private taskService: TaskService,
    private router: Router
  ) {
    this.sharedDataService.currenSharedOpenTransactionRows.subscribe(
      (sharedOpenTransactionRows) =>
        (this.sharedOpenTransactionRows = sharedOpenTransactionRows)
    );

    this.sharedDataService.currenSharedCommonForm.subscribe(
      (sharedCommonForm) => (this.sharedCommonForm = sharedCommonForm)
    );
  }

  ngOnInit(): void {
    this.state = window.history.state;
    if (this.state.task) {
      this.isTask = true;
    }
    this.businessUnits = this.businessUnitService.getAllBusinessUnitsLocal();
    this.businessUnitProduct = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).product;
    this.businessUnitGuid = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).guid;
    this.businessUnitManualBookingApproval = this.businessUnits.find(
      (bu) =>
        bu.id.toString() === this.businessUnitService.getCurrentBusinessUnit()
    ).manualBookingApproval;

    if (this.businessUnitProduct === 3) {
      this.getOperator();
    }

    this.tableService.currentSharedSelectedRows.subscribe((rows) => {
      this.isDeleteRowsDisabled = !rows || rows?.length === 0;
    });
  }

  loadValidOpenItems() {
    const openItems = [];
    this.sharedOpenTransactionRows.controls.forEach((formGroup) => {
      if (formGroup.status === 'VALID') {
        openItems.push(formGroup.getRawValue());
      }
    });
    return openItems;
  }

  updateFormObjectsTask() {
    this.loaderAdd = true;
    const openItems = this.loadValidOpenItems();

    if (openItems.length > 0) {
      openItems.forEach((oi) => {
        this.formatObject(oi);
      });

      const taskGuid = this.state.task.guid;

      const request = {
        openItemsBatch: { openItems },
        taskGuid,
        sentFromUI: true,
      };

      this.openItemService.updateOpenItem(request).subscribe(
        (result) => {
          this.notificationService.showToast(
            'NOTIFICATION.CREATED',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant('OPEN-TRANSACTIONS.TITLE'),
            }
          );
          this.router.navigateByUrl('tasks', {
            state: { success: true },
          });
        },
        (error) => {
          this.showOpenItemsErrorMessage(error);
        }
      );
    } else {
      this.showNoOpenItemsErrorMessage();
    }
  }

  saveFormObjects() {
    this.loaderAdd = true;
    const openItems = this.loadValidOpenItems();

    if (openItems.length > 0) {
      openItems.forEach((oi) => {
        this.formatObject(oi);
      });

      const request = {
        openItems,
        sentFromUI: true,
      };

      this.openItemService.saveOpenItem(request).subscribe(
        (result) => {
          if (this.businessUnitManualBookingApproval) {
            this.notificationService.showToast(
              'NOTIFICATION.CREATED',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              {
                data: this.translateService.instant('OPEN-TRANSACTIONS.TITLE'),
              }
            );
          } else {
            this.notificationService.showToast(
              'NOTIFICATION.CREATED-DIRECT',
              this.notificationService.MESSAGE_TYPE.SUCCESS,
              { data: this.translateService.instant('OPEN-TRANSACTIONS.TITLE') }
            );
          }
          this.clearAll();
          this.loaderAdd = false;
        },
        (error) => {
          this.showOpenItemsErrorMessage(error);
        }
      );
    } else {
      this.showNoOpenItemsErrorMessage();
    }
  }

  showNoOpenItemsErrorMessage() {
    this.sharedOpenTransactionRows.controls.forEach((formGroup: UntypedFormGroup) => {
      if (formGroup.status === 'INVALID') {
        Object.values(formGroup.controls).forEach((control) => {
          control.markAsTouched();
          control.markAsPristine();
          control.updateValueAndValidity();
        });
      }
    });

    this.notificationService.showToast(
      'ERROR-MESSAGES.INVALID-VALUES',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        data: this.translateService.instant('OPEN-TRANSACTIONS.TITLE'),
      }
    );
    this.loaderAdd = false;
  }

  showOpenItemsErrorMessage(error) {
    if (error.error && error.error.status === 'BAD_REQUEST') {
      this.notificationService.showToast(
        'OPEN-TRANSACTION.ERROR-NOTIFICATION.BAD-REQUEST',
        this.notificationService.MESSAGE_TYPE.ERROR,
        error
      );
      this.loaderAdd = false;
    } else {
      this.notificationService.showToast(
        'ERROR-MESSAGES.ERROR-ON-SAVE',
        this.notificationService.MESSAGE_TYPE.ERROR,
        Array.isArray(error) ? error[0] : error
      );
      this.loaderAdd = false;
    }
  }

  formatObject(oi) {
    if (this.businessUnitProduct === 0) {
      Object.assign(oi, {
        clientId: oi.businessPartner.id
          ? oi.businessPartner.id
          : this.sharedCommonForm.get('partner').value.id,
      });

      Object.assign(oi, {
        clientDebtorId: oi.businessRelationship.clientDebtorId
          ? oi.businessRelationship.clientDebtorId
          : '000',
      });
    } else if (this.businessUnitProduct === 3) {
      Object.assign(oi, {
        entityFromGuid: oi.businessPartner.guid
          ? oi.businessPartner.guid
          : this.sharedCommonForm?.get('partner').value?.guid,
      });

      Object.assign(oi, {
        entityToGuid:
          this.operator && this.operator.id ? this.operator.id : uuidv4(),
      });
    }

    Object.assign(oi, {
      bookingCode: oi.transactionCode,
    });

    oi.businessPartner = oi.businessPartner
      ? `${oi.businessPartner.visibleId} ${oi.businessPartner.name}`
      : '';
    oi.externalId = oi.externalId ? `${oi.externalId.externalId}` : '';
    oi.dueDate = this.formatDate(oi.dueDate);
    oi.openItemDate = this.formatDate(oi.openItemDate);
    oi.debtTransferDate = this.formatDate(oi.debtTransferDate);

    oi.vatAmount = this.round(oi.vatAmount.toString().replace(',', ''), 2)
      .toFixed(2)
      .toString();
    oi.vatPercent = this.round(oi.vatPercent.toString().replace(',', ''), 2)
      .toFixed(2)
      .toString();

    const amount = oi.amount.toString().replace(',', '');
    const vatPerResult: any = this.round(
      (Number(oi.vatAmount) * 100) / (Number(amount) - Number(oi.vatAmount)),
      5
    );
    oi.vatPrecisePercent = vatPerResult.toFixed(5);
  }

  round(value, decimals) {
    return Number(Math.round(Number(`${value}e${decimals}`)) + `e-${decimals}`);
  }

  getOperator() {
    this.liquidityPlanService.getOperator().subscribe((data) => {
      this.operator = data;
    });
  }

  formatDate(dateP) {
    const date = new Date(dateP);
    const timeZoneDifference = (date.getTimezoneOffset() / 60) * -1; // convert to positive value.
    date.setTime(date.getTime() + timeZoneDifference * 60 * 60 * 1000);

    return date;
  }

  clearAll() {
    this.sharedDataService.clearTable();
  }

  cancelEditMode() {
    this.tableService.changeEditMode(false);
  }

  deleteSelectedRows() {
    this.sharedDataService.deleteSelectedRows();
  }

  checkIfThereIsInvalidForm(): boolean {
    return this.sharedOpenTransactionRows?.controls.some(
      (formGroup) => !formGroup.valid
    );
  }
}
