export const subLedgerAccountsListColumns: string[] = [
  'code',
  'name_german',
  'name_english',
  'product',
  'service_model',
  'type',
  'sap_sub_ledger_account',
  'sales_tax_account',
  'actions',
];
