export const buOverviewTabColumnsConf: any[] = [
  {
    name: 'businessUnitId',
    index: 0,
    checked: true,
    translateName: 'BU.BUSINESS-UNIT-ID',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'BU.BU-NAME',
    locked: true,
  },
  {
    name: 'status',
    index: 2,
    checked: true,
    translateName: 'GENERAL.STATUS',
    locked: false,
  },
  {
    version: 2,
  },
];
