<div class="role-known-user-container">
  <mat-form-field appearance="fill" class="search-for-entries">
    <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
    <input
      (ngModelChange)="startSearch()"
      [(ngModel)]="searchString"
      [ngModelOptions]="{ standalone: true }"
      matInput
      placeholder="{{ 'GENERAL.SEARCH' | translate }}"
      type="text"
      removeHtmlTagsForNgModel
    />
  </mat-form-field>
  <app-default-table
    (filterEvent)="filter($event)"
    [columns]="columns"
    [configurationKey]="configurationKey"
    [displayedColumns]="displayedColumns"
    [entityName]="configurationKey"
    [getCustomColumnClass]="getCustomColumnClass"
    [originalColumns]="columns"
    [originalDisplayedColumns]="displayedColumns"
    [tableDataSource]="userList"
    [threeDots]="false"
  >
  </app-default-table>
</div>
