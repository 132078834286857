<div class="table-container">
  <form class="form" [formGroup]="form">
    <table mat-table [dataSource]="dataSource" id="{{ entityName }}-search-results-table" aria-hidden="true">
      <ng-container
        *ngFor="let field of tableFieldsConfiguration; trackBy: trackByFunction; let indexOfelement = index"
        matColumnDef="{{ field.columnName }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="v25-th"
          id="{{ entityName }}-table-results-{{ field.columnName }}"
          [ngClass]="{
            'small-column':
              field['type'] == 'select' ||
              field['type'] === 'icons' ||
              field['type'] == 'actions' ||
              field['type'] == 'insideSearch'
          }"
        >
          <!--Normal Table Field, it will depend on wich type of input you need-->
          <ng-container
            *ngIf="
              field['type'] != 'select' &&
              field['type'] != 'icons' &&
              field['type'] != 'actions' &&
              field['type'] != 'insideSearch'
            "
          >
            <span> {{ field.translateName | translate }}</span>
          </ng-container>
          <!--End Normal Table Field-->
          <!--Select Field-->
          <ng-container *ngIf="field['type'] == 'select'">
            <mat-checkbox
              #ref
              (change)="$event ? masterToggle() : null; changeSharedSelectedRows()"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [disabled]="!editableTable"
            >
            </mat-checkbox>
          </ng-container>
          <!--End Select Field-->
          <!--Icons Field-->
          <ng-container *ngIf="field['type'] == 'icons'"> </ng-container>
          <!--End Icons Field-->
          <!--Actions Field: Normally reserved for the Gearwheel and the 3 dots menu-->
          <ng-container *ngIf="field['type'] == 'actions'">
            <button mat-icon-button [matMenuTriggerFor]="appMenu" id="{{ entityName }}-table-results-actions-button">
              <mat-icon>settings</mat-icon>
            </button>
          </ng-container>
          <!--End Actions Field-->
        </th>

        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngClass]="{
            'small-column':
              field['type'] == 'select' ||
              field['type'] == 'icons' ||
              field['type'] == 'actions' ||
              field['type'] == 'insideSearch'
          }"
        >
          <!--Normal Table Field, it will depend on wich type of input you need-->
          <ng-container
            *ngIf="
              field['type'] != 'select' &&
              field['type'] != 'icons' &&
              field['type'] != 'actions' &&
              field['type'] != 'insideSearch'
            "
          >
            <!--Input: CheckBox-->
            <ng-container *ngIf="field.type == 'checkbox'">
              <div
                *ngIf="formArrayVar.controls[element.index] != undefined"
                [formGroup]="formArrayVar.controls[element.index]"
              >
                <mat-checkbox
                  id="{{ entityName }}-{{ field.columnName }}-checkbox-{{ i }}"
                  formControlName="{{ field.columnName }}"
                >
                </mat-checkbox>
              </div>
            </ng-container>
            <!--End Input: CheckBox-->

            <!--Input: Select-->
            <ng-container *ngIf="field.type == 'selectInput'">
              <div *ngIf="formArrayVar.controls[element.index] != undefined && field.type == 'selectInput'">
                <mat-form-field appearance="fill" [formGroup]="formArrayVar.controls[element.index]">
                  <mat-label>{{ field.translateName | translate }}</mat-label>
                  <mat-select
                    placeholder="{{ field.translateName | translate }}"
                    formControlName="{{ field.columnName }}"
                    [required]="
                      formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                      formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                    "
                    [errorStateMatcher]="errorMatcher"
                  >
                    <span *ngIf="!field.subType">
                      <mat-option *ngIf="field.allowNullSelection" [value]="undefined"
                        >{{ 'GENERAL.NONE' | translate }}
                      </mat-option>
                      <mat-option
                        *ngFor="let option of field.options"
                        [value]="
                          field['optionValue'] != null
                            ? option[field['optionValue']]
                            : option['value'] != null
                            ? option['value']
                            : option
                        "
                      >
                        {{ getOptionDisplayProperty(option, field) | translate }}
                      </mat-option>
                    </span>
                    <span *ngIf="field.subType && field.subType == 'currencyCodes'">
                      <mat-option *ngFor="let option of field.options" [value]="option">
                        {{ option | translate }}
                      </mat-option>
                    </span>
                  </mat-select>

                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!--End Input: Select-->

            <!--Input: Input-->
            <ng-container *ngIf="field.type == 'input'">
              <div *ngIf="formArrayVar?.controls[element.index] != undefined">
                <mat-form-field appearance="fill" [formGroup]="formArrayVar.controls[element.index]">
                  <mat-label>{{ field.translateName | translate }}</mat-label>
                  <input
                    (keydown.Tab)="keydownFunction(field)"
                    autocomplete="off"
                    id="{{ entityName }}-form-{{ i }}-{{ field.columnName }}"
                    matInput
                    type="{{ field.number ? 'number' : 'text' }}"
                    formControlName="{{ field.columnName }}"
                    placeholder="{{ field.translateName | translate }}"
                    [required]="
                      formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                      formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                    "
                    mask="{{ field.maskValue ? field.maskValue : null }}"
                    thousandSeparator="{{ field.thousandSeparator ? field.thousandSeparator : undefined }}"
                    decimalMarker="{{ field.decimalMarker ? field.decimalMarker : undefined }}"
                    (keydown.backspace)="
                      keydownFunction(field, formArrayVar?.controls[element.index], field.columnName, $event)
                    "
                    (keydown)="checkRules(field, $event)"
                    [errorStateMatcher]="errorMatcher"
                  />
                  <span *ngIf="field.suffixValue">{{ field.suffixValue }}</span>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('max')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID-MAXIMUM'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate,
                              value: formArrayVar.controls[element.index]?.get(field.columnName)?.errors['max']?.max
                            }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!--End Input: Input-->

            <!--Input: Radio-->
            <ng-container *ngIf="field.type == 'radio'">
              <div
                *ngIf="formArrayVar.controls[element.index] != undefined"
                [formGroup]="formArrayVar.controls[element.index]"
                class="form-row"
              >
                <mat-radio-group
                  formControlName="{{ field.columnName }}"
                  id="{{ entityName }}-form-{{ i }}-{{ field.columnName }}"
                  [required]="
                    formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                    formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                  "
                >
                  <mat-radio-button
                    *ngFor="let option of field.options; let x = index"
                    id="{{ entityName }}-form-measure-unit-{{ i }}-{{ x }}"
                    [value]="option.value"
                    >{{ option.translateName | translate | titlecase }}</mat-radio-button
                  >
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                </mat-radio-group>
              </div>
            </ng-container>
            <!--End Input: Radio-->

            <!--Input: Date-->
            <ng-container *ngIf="field.type == 'date'">
              <div *ngIf="formArrayVar.controls[element.index] != undefined">
                <mat-form-field [formGroup]="formArrayVar.controls[element.index]" appearance="fill">
                  <mat-label>{{ field.translateName | translate }}</mat-label>
                  <input
                    #input
                    autocomplete="off"
                    id="{{ entityName }}-form-{{ i }}-{{ field.columnName }}"
                    matInput
                    formControlName="{{ field.columnName }}"
                    [matDatepicker]="datePicker"
                    [required]="
                      formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                      formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                    "
                    (keydown.Tab)="keydownFunction(field)"
                    [errorStateMatcher]="errorMatcher"
                  />
                  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                  <mat-datepicker #datePicker></mat-datepicker>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!--End Input: Date-->

            <!--Input: Date Range-->
            <ng-container *ngIf="field.type == 'dateRange'">
              <div *ngIf="formArrayVar.controls[element.index] != undefined">
                <mat-form-field appDateFormatMonthYear appearance="fill">
                  <mat-label>{{ field.translateName | translate }}</mat-label>
                  <mat-date-range-input
                    [formGroup]="formArrayVar.controls[element.index]"
                    [rangePicker]="createdOnPicker"
                    id="{{ entityName }}-createdOn-begin-end"
                  >
                    <input
                      matStartDate
                      formControlName="{{ field.rangeStartName }}"
                      id="{{ entityName }}-{{ field.columnName }}-begin"
                      placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                      (keydown.enter)="$event.preventDefault()"
                      [errorStateMatcher]="errorMatcher"
                    />
                    <input
                      matEndDate
                      formControlName="{{ field.rangeEndName }}"
                      id="{{ entityName }}-{{ field.columnName }}-end"
                      placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                      (keydown.enter)="$event.preventDefault()"
                      [errorStateMatcher]="errorMatcher"
                    />
                  </mat-date-range-input>
                  <mat-date-range-picker
                    matSuffix
                    #createdOnPicker
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, element, field.columnName)"
                  ></mat-date-range-picker>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!--End Input: Date Range-->

            <!--Input: Date Month-->
            <ng-container *ngIf="field.type == 'dateMonthYear'">
              <div *ngIf="formArrayVar.controls[element.index] != undefined">
                <mat-form-field
                  appearance="fill"
                  [formGroup]="formArrayVar.controls[element.index]"
                  id="{{ entityName }}-{{ field.columnName }}-{{ i }}-{{ field.columnName }}"
                >
                  <mat-label>{{ field.translateName | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="monthAndYeardp"
                    formControlName="{{ field.columnName }}"
                    [required]="
                      formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                      formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                    "
                    [errorStateMatcher]="errorMatcher"
                  />
                  <mat-datepicker-toggle matSuffix [for]="monthAndYeardp"></mat-datepicker-toggle>
                  <mat-datepicker
                    #monthAndYeardp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, element, field.columnName)"
                    (monthSelected)="chosenMonthHandler($event, monthAndYeardp, element, field.columnName)"
                  >
                  </mat-datepicker>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('required')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.REQUIRED'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="formArrayVar.controls[element.index].get(field.columnName).hasError('invalid')"
                    class="input-error"
                  >
                    {{
                      'ERROR-MESSAGES.INVALID'
                        | translate
                          : {
                              name: field.translateName.toUpperCase() | translate
                            }
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!--End Input: Date Month Only-->

            <!--Input: Select Infinite Scroll-->
            <ng-container *ngIf="field.type == 'selectInfiniteScroll'">
              <div
                class="infinite-scroll-search-wrapper-table"
                *ngIf="formArrayVar.controls[element.index] != undefined"
              >
                <div [formGroup]="formArrayVar.controls[element.index]">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ field.translateName | translate }}</mat-label>
                    <mat-select
                      formControlName="{{ field.columnName }}"
                      (infiniteScroll)="showInfiniteScroll(field, element, true, true)"
                      msInfiniteScroll
                      (openedChange)="showInfiniteScroll(field, element, $event, false)"
                      [required]="
                        formArrayVar.controls[element.index].get(field.columnName)?.errors !== null &&
                        formArrayVar.controls[element.index].get(field.columnName)?.errors.required
                      "
                      [errorStateMatcher]="errorMatcher"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="{{ 'search' | translate }}"
                          noEntriesFoundLabel="{{ 'error.noSearchResulstFound' | translate }}"
                          formControlName="{{ getFieldSubControlName(field, i) }}"
                        ></ngx-mat-select-search>
                      </mat-option>

                      <ng-container>
                        <mat-option *ngFor="let option of field.subControls[i].options" [value]="option">
                          <span> {{ getOptionDisplayValues(option, field, i) }}</span>
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="loaderScroll">
                        <mat-option [value]="null">
                          {{ loaderScroll }}
                          <mat-spinner class="spinner-table" [diameter]="30"></mat-spinner>
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
            <!--End Input: Select Infinite Scroll-->

            <div *ngIf="formArrayVar.controls[element.index]?.errors" class="special-error-wrapper">
              <div *ngFor="let error of getSpecialErrors(element, field.columnName)" class="input-error special-error">
                <span *ngIf="showError(field, error, element, indexOfelement)">
                  {{
                    'ERROR-MESSAGES.' + error.toUpperCase()
                      | translate
                        : {
                            name: field.translateName.toUpperCase() | translate
                          }
                  }}
                </span>
              </div>
            </div>
          </ng-container>
          <!--End Normal Field-->

          <!--Icons Field-->
          <ng-container *ngIf="field['type'] == 'icons'">
            <div
              *ngIf="formArrayVar.controls[element.index] != undefined"
              [formGroup]="formArrayVar.controls[element.index]"
            >
              <span
                ngClass="{{ field.class }}"
                *ngIf="formArrayVar.controls[element.index].get('icon').value == true"
                class="material-icons"
              >
                {{ field.iconName }}
              </span>
            </div>
          </ng-container>
          <!--End Icons Field-->

          <!--Select Field-->
          <ng-container *ngIf="field['type'] == 'select'">
            <mat-checkbox
              id="{{ entityName }}-table-results-select-{{ i }}"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null; changeSharedSelectedRows()"
              [checked]="selection.isSelected(element)"
              [aria-label]="checkboxLabel(element)"
              [disabled]="!editableTable"
            >
            </mat-checkbox>
          </ng-container>
          <!--End Select Field-->

          <!--Inside Search Field-->
          <ng-container *ngIf="field['type'] == 'insideSearch'">
            <button
              mat-button
              class="custom-button search-button search-button-table"
              id="{{ entityName }}-search-button-{{ i }}"
              (click)="openSearchDialog(element)"
              [disabled]="!editableTable"
            >
              <span class="material-icons"> search </span>
            </button>
          </ng-container>
          <!--End Inside Search Field-->

          <!--Actions Field-->
          <ng-container *ngIf="field['type'] == 'actions'">
            <button
              class="menu"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ element: element }"
              (click)="$event.stopPropagation(); selectTheRow(element, selection.isSelected(element))"
              id="{{ entityName }}-table-results-actions-{{ i }}"
              [disabled]="!editableTable"
            >
              <span class="material-icons"> more_vert </span>
            </button>
          </ng-container>
          <!--End Actions Field-->
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <span class="general-loader-table" *ngIf="loaderSpinner">
            <mat-spinner [diameter]="50"></mat-spinner>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="loaderSpinner"></tr>
      <tr
        [hidden]="!loaderSpinner"
        mat-footer-row
        *matFooterRowDef="['loader']"
        class="footer-spinner height-footer"
      ></tr>
    </table>
  </form>
</div>

<mat-menu #appMenu="matMenu" (closed)="tableChanged()" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>

<mat-menu #menu="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <div *ngFor="let button of listOfActionsButtons">
      <ng-container *hasPermission="button.hasPermission">
        <button
          mat-menu-item
          id="{{ entityName }}-table-results-action-menu-{{ button.name }}-button"
          (click)="$event.preventDefault(); button.function(this)"
          [disabled]="
            (button.selectionType == 'single' && isMoreThanOneSelected()) ||
            (button.selectionType == 'multiple' && !isMoreThanOneSelected()) ||
            (button.selectionType != 'singleAndMultiple' &&
              button.selectionType != 'single' &&
              button.selectionType != 'multiple')
          "
        >
          <span *ngIf="button.icon" class="material-icons icon-menu button-icon">{{ button.icon }}</span>
          <span>{{ button.translationKey | translate }}</span>
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>
