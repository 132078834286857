export const awInterfaceTransferProtocolTableColumnsConf: any[] = [
  {
    name: 'filename',
    index: 1,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.FILENAME',
    locked: true,
  },
  {
    name: 'progress',
    index: 2,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.PROGRESS',
    locked: true,
  },
  {
    name: 'status',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.STATUS',
    locked: true,
  },
  {
    name: 'creationTimestamp',
    index: 4,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.CREATION-TIMESTAMP',
    locked: true,
  },
  {
    name: 'updateTimestamp',
    index: 5,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.AW-INTERFACE-TRANSFER-PROTOCOL.FILES.UPDATE-TIMESTAMP',
    locked: true,
  },
  {
    version: '2',
  },
];
