import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { productionOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/filterProductionOptions';
import { transferOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/enum/transferOptions';
import { quantityGoodsCreateColumns } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/table-columns';
import { quantityGoodsCreateColumnsConf } from 'src/app/in-memory-data/animal-welfare/quantity-goods-create/table-columns-configuration';
import { measureUnitOptions } from 'src/app/in-memory-data/animal-welfare/quantity-goods/enum/measureUnitOptions';
import { DATE_FORMATS } from 'src/app/modules/form-elements/mat-input-date/mat-input-date.component';
import { TransferQuantityGoodService } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/transfer-quantity-good.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { pastDateValidator } from 'src/app/shared/validators/date-validator';
import {
  maxLengthValidator,
  minLengthValidator,
} from 'src/app/shared/validators/general-validator';
import { KeycloakService } from 'keycloak-angular';
import { LivestockProducerService } from 'src/app/shared/services/livestock-producer/livestock-producer.service';
import { DirtyComponent } from '../../../../shared/models/dirtyComponent';
import moment from 'moment';
import { QuantityGoodUpdateResponse } from 'src/app/shared/services/animal-welfare/transfer-quantity-good/quantity-good-update-response';
import { ExistsFilterModel } from './exists-filter-model';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';

@Component({
  selector: 'app-transfer-quantity-goods-create',
  templateUrl: './transfer-quantity-goods-create.component.html',
  styleUrls: [
    './transfer-quantity-goods-create.component.less',
    './transfer-quantity-goods-create-md.component.less',
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class TransferQuantityGoodsCreateComponent
  implements OnInit, OnDestroy, DirtyComponent
{
  private CONFIGURAIONT_KEY = 'quantity_goods_create_table';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChildren('selectQSID') components: QueryList<MatSelect>;

  entityName = 'quantity-goods';
  private ENTITY_TYPE = 'QUANTITY_GOOD';
  alreadySaved = false;
  filterForm: UntypedFormGroup;
  qsidForm: UntypedFormGroup;
  form: UntypedFormGroup;
  checkSumForm: UntypedFormGroup;

  columns = quantityGoodsCreateColumnsConf;
  displayedColumns = quantityGoodsCreateColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  filteredUserRoles: any[] = [];
  userRoles: any[] = [];

  filteredProductionTypes = productionOptions;
  productionTypes = productionOptions;

  filteredTransferOptions = transferOptions;
  transferOptions = transferOptions;

  measureUnitOptions = measureUnitOptions;

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = true;

  index = 0;
  deletedRowsIndexes: any[] = [];

  isTask = false;
  editableTask = true;
  isDeleteOperation = false;

  state: any;
  snapshot: any;
  canDismiss: any = false;
  protected onDestroy = new Subject<void>();

  quantityGoodsTask: any[] = [];
  quantityGoodsApproved: any[] = [];
  editMode = false;

  isDraft = false;
  dateFormat = '';
  userRole;
  qsId;
  pageSize = 20; // to be reverted to 20
  requestSent = false;
  private readonly internalRole = 'int.aw';

  subject = new Subject();

  selectedFormGroup: UntypedFormGroup;
  startingEdit = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private taskService: TaskService,
    private transferQuantityGoodService: TransferQuantityGoodService,
    private draftService: DraftService,
    private customErrorHandler: CustomErrorHandler,
    private dateAdapter: DateAdapter<Date>,
    private kcService: KeycloakService,
    private livestockProducerService: LivestockProducerService,
    private translationService: TranslationService
  ) {
    this.quantityGoodsFormBuilder();
    this.qsIdFormBuilder();
    this.checkSumFormBuilder();
  }

  ngOnInit(): void {
    this.state = window.history.state;
    this.snapshot = this.activatedRoute.snapshot;
    this.getDateFormat();
    this.getUserRolesQSIDs();
    this.getTableConfiguration();
    this.fillQsId();
    this.getLivestockProducers();
    this.listenToLanguageChange();
  }

  getLivestockProducers() {
    this.subject
      .pipe(debounceTime(500))
      .subscribe((value: ExistsFilterModel) => {
        this.livestockProducerService
          .exists(value.vvvoNumber, value.productionType, value.searchDate)
          .subscribe((data) => {
            this.startingEdit = false;
            if (data) {
              this.selectedFormGroup.get('vvvoNr').setErrors(null);
              this.selectedFormGroup.get('vvvoNrLoader').setValue(false);
              this.selectedFormGroup.get('livestockProducerId').setValue(data);
              this.selectedFormGroup.updateValueAndValidity();
            } else {
              this.selectedFormGroup
                .get('vvvoNr')
                .setErrors({ inexistent: true });
              this.selectedFormGroup.get('vvvoNrLoader').setValue(false);
              this.selectedFormGroup.get('livestockProducerId').setValue(null);
              this.selectedFormGroup.updateValueAndValidity();
            }
          });
      });
  }

  fillQsId() {
    this.qsidForm.get('qsid').valueChanges.subscribe((value) => {
      this.userRole = value.id;
      this.qsId = value.qsid;
      this.quantityGoodsObjects.controls.forEach((item: any) => {
        item.get('userRole').patchValue(value.id);
      });
    });

    this.qsidForm
      .get('userRoleSearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.searchFromSelectTableUserRoles(this.qsidForm);
      });
  }

  getThousandSeperator() {
    if (localStorage.currentSelectedLanguage === 'en') {
      return ',';
    } else {
      return '.';
    }
  }

  listenToLanguageChange() {
    this.translationService.currentSelectedLanguage.subscribe((data) => {
      if (data) {
        this.getThousandSeperator();
      }
    });
  }

  qsIdFormBuilder() {
    this.qsidForm = this.formBuilder.group({
      qsid: ['', Validators.required],
      userRoleSearch: [''],
    });
  }

  checkSumFormBuilder() {
    this.checkSumForm = this.formBuilder.group({
      checksum: [{ value: 0, disabled: true }],
    });
  }

  get formControls() {
    return this.form.controls;
  }
  get quantityGoodsObjects() {
    return this.formControls.quantityGoods as UntypedFormArray;
  }

  quantityGoodsFormBuilder() {
    this.form = this.formBuilder.group({
      quantityGoods: new UntypedFormArray([]),
    });
  }

  loadTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if (this.state.reports && this.state.reports.length > 0) {
      this.editMode = true;
      this.quantityGoodsApproved = this.state.reports;
      this.addRows(this.quantityGoodsApproved);
      this.patchQSID(this.quantityGoodsApproved);
    } else if (this.state.task) {
      this.isTask = true;
      this.editMode = true;
      if (this.state.task.params) {
        this.draftService
          .retrieveDraftForTask(this.state.task.guid)
          .subscribe((data) => {
            this.quantityGoodsTask = data;
            this.patchQSIDDraft(this.quantityGoodsTask);
            this.addRows(this.quantityGoodsTask);
            this.isDeleteOperation =
              this.state.task.params.eventType &&
              this.state.task.params.eventType.toUpperCase() === 'DELETE';
            if (
              !this.isDeleteOperation &&
              sessionStorage.getItem('taskModify') === 'true'
            ) {
              this.changeTaskState(true);
            } else {
              this.changeRowsState(false);
            }
            this.getApprovedQuantityGood();
          });
      } else {
        if (sessionStorage.getItem('taskModify') === 'true') {
          this.changeTaskState(true);
        }
      }
      this.taskService.currentEditableTask.subscribe((editableTask) => {
        this.editableTask = editableTask;
      });
    } else if (this.state.draft) {
      this.editMode = true;
      this.isDraft = true;
      this.quantityGoodsApproved = this.state.draftObject.quantityGoods;
      this.addRows(this.quantityGoodsApproved);
      this.patchQSIDDraft(this.quantityGoodsApproved);
    } else if (this.snapshot.params.guid) {
      this.transferQuantityGoodService
        .findQuantityGoodByGuid(this.snapshot.params.guid)
        .subscribe((data) => {
          this.editMode = true;
          this.quantityGoodsApproved = [];
          this.quantityGoodsApproved.push(data[0]);
          this.addRows(this.quantityGoodsApproved);
          this.patchQSID(this.quantityGoodsApproved);
        });
    } else {
      this.addEmptyRow();
    }
  }

  patchQSID(quantityGoodsApproved) {
    if (quantityGoodsApproved && quantityGoodsApproved.length > 0) {
      this.userRole = this.filteredUserRoles.find(
        (role) => role.qsid === quantityGoodsApproved[0].qsid
      );

      if (this.userRole) {
        this.qsidForm.get('qsid').patchValue(this.userRole);
      }
    }
  }

  patchQSIDDraft(quantityGoodsApproved) {
    if (quantityGoodsApproved && quantityGoodsApproved.length > 0) {
      this.userRole = this.filteredUserRoles.find(
        (role) => role.id === quantityGoodsApproved[0].userRole
      );

      if (this.userRole) {
        this.qsidForm.get('qsid').patchValue(this.userRole);
      }
    }
  }

  getDateFormat() {
    this.dateFormat = this.userService.dateFormat;
    if (this.dateFormat === 'BRITISH') {
      this.changeDateFormat('en-GB');
      this.dateFormat = 'dd/MM/yyyy';
    }
    if (this.dateFormat === 'AMERICAN') {
      this.changeDateFormat('en-US');
      this.dateFormat = 'MM-dd-yyyy';
    }
    if (this.dateFormat === 'GERMAN') {
      this.changeDateFormat('de');
      this.dateFormat = 'dd.MM.yyyy';
    }
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }

  getApprovedQuantityGood() {
    this.quantityGoodsTask.forEach((element) => {
      if (element.id) {
        this.transferQuantityGoodService
          .findById(element.id)
          .subscribe((data) => {
            this.quantityGoodsApproved.push(data);
          });
      }
    });
  }

  addRows(quantityGoods) {
    quantityGoods.forEach((data) => {
      if (data.amountGood && !data.amount) {
        Object.assign(data, {
          amount: data.amountGood,
        });
      }
      this.addRow(data);
    });
    this.calculateCheckSum();
  }

  getUserRolesQSIDs() {
    this.transferQuantityGoodService
      .findAllMasterDataCoordinatorsAndSlaughterhouses()
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          data.forEach((element) => {
            this.userRoles.push(element);
            this.filteredUserRoles.push(element);
          });
        }
        this.loadTable();
      });
  }

  checkChangesInForm(element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    this.startingEdit = false;

    formGroup
      .get('productionTypeSearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.searchFromSelectTableProductionType(formGroup);
      });

    formGroup
      .get('transferSearch')
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.searchFromSelectTableTransfer(formGroup);
      });

    formGroup
      .get('vvvoNr')
      .valueChanges.pipe(debounceTime(500))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.checkVVVONrError(formGroup.getRawValue());
      });

    formGroup
      .get('submissionDate')
      .valueChanges.pipe(debounceTime(500))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.checkVVVONrError(formGroup.getRawValue());
      });
  }

  searchFromSelectTableUserRoles(formGroup) {
    if (!this.userRoles) {
      return;
    }
    const searchTerm = formGroup.get('userRoleSearch').value;
    const resultRoles = [];
    for (const role of this.userRoles) {
      if (
        role.qsid
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultRoles.push(role);
      }
    }
    this.filteredUserRoles = resultRoles;
  }

  searchFromSelectTableTransfer(formGroup) {
    if (!this.userRoles) {
      return;
    }
    const searchTerm = formGroup.get('transferSearch').value;
    const resultTransferOptions = [];
    for (const transfer of this.transferOptions) {
      if (
        this.translateService
          .instant(transfer.translateName)
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultTransferOptions.push(transfer);
      }
    }
    this.filteredTransferOptions = resultTransferOptions;
  }

  searchFromSelectTableProductionType(formGroup) {
    if (!this.productionTypes) {
      return;
    }
    const searchTerm = formGroup.get('productionTypeSearch').value;
    let resultProductionTypes = [];
    for (const type of this.productionTypes) {
      if (
        type.value
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultProductionTypes.push(type);
      }
      if (
        this.translateService
          .instant(type.translateName)
          .toString()
          .toUpperCase()
          .indexOf(searchTerm.toString().toUpperCase()) > -1
      ) {
        resultProductionTypes.push(type);
      }
    }
    resultProductionTypes = this.removeRepeatedProductionTypes(
      resultProductionTypes
    );
    this.filteredProductionTypes = resultProductionTypes;
  }

  removeRepeatedProductionTypes(data) {
    return data.filter(
      (v, i, a) => a.findIndex((t) => t.value === v.value) === i
    );
  }

  changeRowsState(state) {
    this.changeQsIdState(state);
    this.quantityGoodsObjects.controls.forEach((control) => {
      if (state) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  changeQsIdState(state) {
    if (state) {
      this.qsidForm.enable();
    } else {
      this.qsidForm.disable();
    }
  }

  changeTaskState(state) {
    this.taskService.updateEditableTask(state);
    this.changeRowsState(state);
    this.startingEdit = true;
  }

  addRow(element) {
    const object = {
      id: element.id,
      userRole: element.userRole,
      qsId: element.qsId,
      vvvoNr: element.vvvoNr,
      productionType: element.productionType,
      submissionDate: element.submissionDate,
      measureUnit: element.measureUnit,
      amount: element.amount,
      transfer: element.transfer,
      comment: element.comment,
      createdBy: element.createdBy,
      createdOn: element.createdOn,
      approvedBy: element.approvedBy,
      approvedOn: element.approvedOn,
      status: element.status,
      livestockProducerId: element.livestockProducerId,
      index: this.index,
      operation: element.operation,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.checkChangesInForm(object);
    this.index++;
    this.loaderSpinner = false;
  }

  cancelEdit() {
    if (this.state.task) {
      this.taskService.updateEditableTask(false);
      this.changeRowsState(false);
      this.startingEdit = false;
    } else {
      this.router.navigate(['/quantityGoods/']);
    }
  }

  changeMeasureUnit(element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    const productionType = formGroup.get('productionType').value;
    switch (productionType) {
      case '2001':
      case '2004':
      case '2008':
        formGroup.get('measureUnit').disable();
        formGroup.get('measureUnit').patchValue('Unit');
        break;
      case '3001':
      case '3004(F)':
      case '3004(M)':
        formGroup.get('measureUnit').disable();
        formGroup.get('measureUnit').patchValue('Kg');
        break;
      default:
        formGroup.get('measureUnit').enable();
    }
  }

  onKey() {
    if (!this.isTask && !this.editMode) {
      this.addEmptyRow();
    }
  }

  addEmptyRow() {
    const object = {
      id: null,
      userRole: this.userRole,
      qsId: this.qsId,
      vvvoNr: null,
      productionType: null,
      submissionDate: null,
      measureUnit: null,
      amount: null,
      transfer: null,
      comment: null,
      createdBy: null,
      createdOn: null,
      approvedBy: null,
      approvedOn: null,
      status: null,
      livestockProducerId: null,
      index: this.index,
      operation: null,
    };

    this.dataSource.data.push(object);
    this.dataSource.filter = '';
    this.addFormRow(object);
    this.checkChangesInForm(object);
    setTimeout(() => {
      this.setFocusOnFirstElement();
    }, 500);
    this.index++;
    this.loaderSpinner = false;
  }

  setFocusOnFirstElement() {
    if (this.components && this.components.length > 0) {
      this.components.toArray()[this.components.length - 1].focus();
    }
  }

  addFormRow(object) {
    this.quantityGoodsObjects.push(
      this.formBuilder.group({
        id: [{ value: object.id, disabled: false }],
        userRole: [
          { value: object.userRole, disabled: true },
          [Validators.required],
        ],
        qsId: [{ value: object.qsId, disabled: true }],
        userRoleSearch: [{ value: '', disabled: false }],
        vvvoNr: [
          { value: object.vvvoNr, disabled: false },
          [Validators.required, maxLengthValidator(15), minLengthValidator(1)],
        ],
        productionType: [
          { value: object.productionType, disabled: false },
          [Validators.required],
        ],
        productionTypeSearch: [{ value: '', disabled: false }],
        submissionDate: [
          { value: object.submissionDate, disabled: false },
          [Validators.required, pastDateValidator()],
        ],
        measureUnit: [
          { value: object.measureUnit, disabled: true },
          [Validators.required],
        ],
        amount: [
          { value: object.amount, disabled: false },
          [Validators.required],
        ],
        transfer: [
          { value: object.transfer, disabled: false },
          [Validators.required],
        ],
        transferSearch: [{ value: '', disabled: false }],
        comment: [{ value: object.comment, disabled: false }],
        createdBy: [{ value: object.createdBy, disabled: false }],
        createdOn: [{ value: object.createdOn, disabled: false }],
        approvedBy: [{ value: object.approvedBy, disabled: false }],
        approvedOn: [{ value: object.approvedOn, disabled: false }],
        status: [{ value: object.status, disabled: false }],
        edited: [{ value: false, disabled: false }],
        index: [
          { value: object.index, disabled: false },
          [Validators.required],
        ],
        vvvoNrLoader: [{ value: false, disabled: false }],
        livestockProducerId: [
          { value: object.livestockProducerId, disabled: false },
        ],
        operation: [{ value: object.operation, disabled: false }],
      })
    );
  }

  checkVVVONrError(element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);
    this.selectedFormGroup = formGroup;
    if (
      (formGroup.get('vvvoNr').value &&
        formGroup.get('vvvoNr').valid &&
        formGroup.get('productionType').value &&
        formGroup.get('submissionDate').value) ||
      (formGroup.get('vvvoNr').value &&
        formGroup.get('vvvoNr').errors != null &&
        formGroup.get('vvvoNr').errors.inexistent &&
        formGroup.get('productionType').value &&
        formGroup.get('submissionDate').value)
    ) {
      if (this.startingEdit === false) {
        this.selectedFormGroup.get('vvvoNrLoader').setValue(true);
      }
      const vvvoNr = formGroup.get('vvvoNr').value.toString();
      const submissionDate = moment(
        formGroup.get('submissionDate').value
      ).format('YYYY-MM-DD');
      const productionType = formGroup
        .get('productionType')
        .value.replace(/\D/g, '');
      this.subject.next({
        vvvoNumber: vvvoNr,
        productionType: productionType,
        searchDate: submissionDate,
      });
    }
  }

  calculateCheckSum() {
    let checksum = 0;
    this.quantityGoodsObjects.getRawValue().forEach((value) => {
      if (value.transfer) {
        checksum += Number(value.amount);
      } else {
        checksum -= Number(value.amount);
      }
    });
    this.checkSumForm.get('checksum').patchValue(checksum);
  }

  deleteSelectedRows(row) {
    this.selection.select(row);
    this.selection.selected.forEach((item) => {
      this.deletedRowsIndexes.push(item.index);
    });

    this.deleteValuesFromDataSource();
    this.deleteValuesFromForm();
    this.selection = new SelectionModel(true, []);
    this.dataSource.filter = '';

    this.notificationService.showToast(
      'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DIRECT',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      {
        data: this.translateService.instant(
          'ANIMAL-WELFARE.QUANTITY-GOODS.TITLE'
        ),
      }
    );
    this.calculateCheckSum();
  }

  deleteValuesFromDataSource() {
    for (let i = this.dataSource.data.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        if (item === this.dataSource.data[i].index) {
          this.dataSource.data.splice(i, 1);
        }
      });
    }
  }

  deleteValuesFromForm() {
    for (let i = this.quantityGoodsObjects.value.length - 1; i >= 0; i--) {
      this.deletedRowsIndexes.forEach((item) => {
        let removed = false;
        if (
          this.quantityGoodsObjects.value[i] &&
          item === this.quantityGoodsObjects.value[i].index &&
          !removed
        ) {
          this.quantityGoodsObjects.removeAt(i);
          removed = true;
        }
      });
    }
  }

  getFormGroup(element) {
    let formGroup: any;
    formGroup = this.quantityGoodsObjects.controls.find(
      (item) => item.value.index === element.index
    );
    return formGroup;
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  onTransferChange($event, element) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);

    if (formGroup) {
      if (!$event.value) {
        formGroup.get('comment').setValidators(Validators.required);
      } else {
        formGroup.get('comment').setValidators(null);
      }
      formGroup.get('comment').updateValueAndValidity();
    }
    this.calculateCheckSum();
  }

  onClickSave(saveDraft) {
    if (this.alreadySaved) {
      return;
    }
    this.alreadySaved = true;
    const quantityGoods = [];

    this.quantityGoodsObjects.controls.forEach((item: any) => {
      quantityGoods.push(item.getRawValue());
    });

    quantityGoods.forEach((item: any) => {
      Object.assign(item, {
        amountGood: item.amount,
      });
      if (item.submissionDate) {
        item.submissionDate = moment(item.submissionDate)
          .startOf('date')
          .format('YYYY-MM-DD');
      }
    });

    if (saveDraft) {
      if (this.editMode && this.isDraft) {
        this.saveOrUpdateDraft(quantityGoods, true);
      } else {
        this.saveOrUpdateDraft(quantityGoods, false);
      }
    } else {
      const taskId =
        this.state.task && this.state.task.guid ? this.state.task.guid : null;

      if (this.editMode && !this.isDraft) {
        this.updateQuantityGoods(quantityGoods, taskId);
      } else {
        this.createQuantityGoods(quantityGoods);
      }
    }
  }

  createQuantityGoods(quantityGoods) {
    quantityGoods.forEach((element) => {
      if (this.qsidForm.get('qsid').value) {
        element.qsId = this.qsidForm.get('qsid').value.qsid;
      }
    });

    const notification = this.kcService.isUserInRole(this.internalRole)
      ? 'NOTIFICATION.CREATED'
      : 'NOTIFICATION.CREATED-DIRECT';

    this.transferQuantityGoodService
      .createQuantityGood(
        quantityGoods,
        '',
        this.qsidForm.get('qsid').value.qsid,
        this.qsidForm.get('qsid').value.level
      )
      .subscribe(() => {
        this.notificationService.showToast(
          notification,
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ANIMAL-WELFARE.QUANTITY-GOODS.TITLE'
            ),
          }
        );

        if (this.isDraft && this.state.draft) {
          this.deleteDraft(this.state.draftObject.draftGuid);
        }
        this.canDismiss = true;

        setTimeout(() => {
          this.router.navigate(['/quantityGoods/']);
        }, 3000);
      });
  }

  updateQuantityGoods(quantityGoods, taskId) {
    this.transferQuantityGoodService
      .updateQuantityGood(
        quantityGoods,
        '',
        taskId,
        this.qsidForm.get('qsid').value.qsid,
        this.qsidForm.get('qsid').value.level
      )
      .pipe(first())
      .subscribe((response: QuantityGoodUpdateResponse) => {
        this.notificationService.showToast(
          response.directUpdate
            ? 'NOTIFICATION.UPDATED-DIRECT'
            : 'NOTIFICATION.CREATED',
          this.notificationService.MESSAGE_TYPE.SUCCESS,
          {
            data: this.translateService.instant(
              'ANIMAL-WELFARE.QUANTITY-GOODS.TITLE'
            ),
          }
        );
        this.canDismiss = true;
        setTimeout(() => {
          if (this.isTask) {
            this.router.navigateByUrl('/tasks');
          } else {
            this.router.navigate(['/quantityGoods/']);
          }
        }, 3000);
      });
  }

  saveUserDraft(request) {
    this.draftService.saveUserDraft(request).subscribe(
      (_) => {
        if (this.editMode) {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.ToastS.SUCCESS-MESSAGES.UPDATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'ENTITIES.ANIMAL-WELFARE.QUANTITY-GOOD'
              ),
            }
          );
        } else {
          this.notificationService.showToast(
            'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
            this.notificationService.MESSAGE_TYPE.SUCCESS,
            {
              data: this.translateService.instant(
                'ENTITIES.ANIMAL-WELFARE.QUANTITY-GOOD'
              ),
            }
          );
        }
        this.canDismiss = true;
        setTimeout(() => {
          this.router.navigate(['/quantityGoods/']);
        }, 3000);
      },
      (error) => {
        if (error.error) {
          this.customErrorHandler.handleError(
            error.error,
            this.translateService.instant(
              'ENTITIES.ANIMAL-WELFARE.QUANTITY-GOOD'
            )
          );
        }
      }
    );
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe(() => {
      /**
       * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
       * to much of empty function. So this comment is to makes the sonarqube happy.
       * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
       */
    });
  }

  getDisabledStatus() {
    let result = false;
    if (
      this.quantityGoodsObjects.controls &&
      this.quantityGoodsObjects.controls.length > 0
    ) {
      this.quantityGoodsObjects.controls.forEach((item) => {
        if (!item.valid) {
          result = true;
        }
      });
    } else {
      result = true;
    }

    return result;
  }

  isFieldRequired(element, field) {
    const formGroup: UntypedFormGroup = this.getFormGroup(element);

    if (formGroup) {
      const control = formGroup.get(field);
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (!validator) {
          return false;
        }
        return validator.required;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasChanged(fieldName: string, element) {
    if (!this.isDraft) {
      const formGroup = this.getFormGroup(element);
      if (
        this.quantityGoodsTask &&
        this.quantityGoodsTask.length > 0 &&
        this.quantityGoodsApproved &&
        this.quantityGoodsApproved.length > 0
      ) {
        let approvedValue;

        approvedValue = this.quantityGoodsApproved.find(
          (el) => el.id === formGroup.controls.id.value
        )[fieldName];

        const taskValue = this.quantityGoodsTask.find(
          (el) => el.id === formGroup.controls.id.value
        )[fieldName];

        const newValue = formGroup.controls[fieldName].value;

        if (taskValue !== newValue) {
          return true;
        } else if (taskValue !== approvedValue) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.quantityGoodsApproved &&
        this.quantityGoodsApproved.length > 0 &&
        this.quantityGoodsTask &&
        this.quantityGoodsTask.length === 0
      ) {
        let approvedValue;

        approvedValue = this.quantityGoodsApproved.find(
          (el) => el.id === formGroup.controls.id.value
        )[fieldName];

        const newValue = formGroup.controls[fieldName].value;

        return approvedValue !== newValue;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                quantityGoodsCreateColumnsConf,
                quantityGoodsCreateColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      () => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = quantityGoodsCreateColumnsConf;
    this.displayedColumns = quantityGoodsCreateColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  ngOnDestroy() {
    sessionStorage.removeItem('taskModify');
  }

  isModify() {
    if (sessionStorage.getItem('taskModify') === 'true') {
      return true;
    }
  }

  checkNegative(event) {
    const input = event.target.value;
    if (input <= 0) {
      event.target.value = null;
    }
    if (input.length === 0 && event.which === 48) {
      event.preventDefault();
    }
    this.calculateCheckSum();
  }

  canDeactivate() {
    if (
      this.quantityGoodsObjects &&
      this.quantityGoodsObjects.controls.length > 0
    ) {
      return (
        this.quantityGoodsObjects.dirty && !this.isTask && !this.canDismiss
      );
    } else {
      return this.qsidForm.dirty && !this.isTask && !this.canDismiss;
    }
  }

  private saveOrUpdateDraft(quantityGoods, isUpdate: boolean) {
    quantityGoods.forEach((element) => {
      if (this.qsidForm.get('qsid').value) {
        element.qsid = this.qsidForm.get('qsid').value;
      }
    });

    const request = {
      entityId: null,
      entityType: this.ENTITY_TYPE,
      element: quantityGoods,
      id: isUpdate ? this.state.draftObject.draftGuid : null,
      entityGuid: null,
    };
    this.saveUserDraft(request);
  }
}
