export const quaterlyBillingPayoutColumnsConf: any[] = [
  {
    name: 'quarter',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.QUARTER',
    locked: true,
    number: false,
    translatePrefix: 'Q',
    multiValueColumn: 'year',
    multiValueColumnSeparator: ' / ',
  },
  {
    name: 'deadline_date',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.DEADLINE',
    locked: true,
    number: false,
  },
  {
    name: 'openPayouts',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.OPEN-PAYOUTS',
    locked: true,
    number: true,
  },
  {
    name: 'blockedPayouts',
    index: 3,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUARTERLY-BILLING.BLOCKED-PAYOUTS',
    locked: true,
    number: true,
  },

  {
    version: 1,
  },
];
