export const buColumnsConf: any[] = [
  {
    name: 'id',
    index: 0,
    checked: true,
    translateName: 'id',
    locked: true,
  },
  {
    name: 'name',
    index: 1,
    checked: true,
    translateName: 'BU.BU-NAME',
    locked: true,
  },
  {
    name: 'mainBooking',
    index: 2,
    checked: true,
    translateName: 'BU.MAIN-BOOKING-RELEVANT',
    locked: false,
  },
  {
    name: 'camBooking',
    index: 3,
    checked: true,
    translateName: 'BU.CAM-RELEVANT',
    locked: false,
  },
  {
    name: 'product',
    index: 4,
    checked: true,
    translateName: 'BUSINESS-UNIT.CREATE.PRODUCT-ALLOCATION.PRODUCT-ALLOCATION',
    locked: false,
  },
  {
    name: 'poolAllocation',
    index: 5,
    checked: true,
    translateName: 'BUSINESS-UNIT.POOL-ALLOCATION.POOL-ALLOCATION',
    locked: false,
  },
  {
    name: 'editingOfExternalRecords',
    index: 6,
    checked: true,
    translateName: 'BUSINESS-UNIT.CREATE.EDITING-OF-EXTERNAL-RECORDS',
    locked: false,
  },
  {
    name: 'manualBookingApproval',
    index: 7,
    checked: false,
    translateName: 'BUSINESS-UNIT.CREATE.MANUAL-BOOKING-APPROVAL',
    locked: false,
  },
  {
    version: 10.1,
  },
];
