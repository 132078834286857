import { SalesEntryService } from '../services/animal-welfare/sales-entry/sales-entry.service';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

export class AwAccountNrValidator {
  static createValidator(
    salesEntryService: SalesEntryService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.parent) {
        return of(null);
      }
      const formField = control.parent.get('salesEntryGuid');
      formField.setValue('');
      if (!control.value) {
        return of({ accountNumberError: true });
      }
      if (control.value.length !== 8) {
        return of({ accountNumberError: true });
      }
      return salesEntryService.getForAccountNumber(control.value).pipe(
        map((result) => {
          if (result == null) {
            formField.setValue('');
            return { accountNumberError: true };
          }
          formField.setValue(result['id']);
          return null;
        })
      );
    };
  }
}
