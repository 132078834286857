<div class="input-wrapper form-container">
  <form [formGroup]="frmAdditionalUserCompetences" autocomplete="off" class="form">
    <div class="competence-section">
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.userName : null"
        [controlName]="'userName'"
        [controlObject]="frmAdditionalUserCompetences.controls.userName"
        [disabled]="true"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmAdditionalUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
        [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
        [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
        [taskValue]="userTask ? userTask.userName : null"
        [titleTask]="false"
      >
      </app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.email : null"
        [controlName]="'email'"
        [controlObject]="frmAdditionalUserCompetences.controls.email"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmAdditionalUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.EMAIL'"
        [name]="'USER-MANAGEMENT.EMAIL'"
        [placeholder]="'USER-MANAGEMENT.EMAIL'"
        [taskValue]="userTask ? userTask.email : null"
        [titleTask]="false"
        [type]="'email'"
      >
      </app-mat-input>
    </div>
  </form>
  <form [formGroup]="frmUserCompetences" autocomplete="off" class="form">
    <div class="competence-section">
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.disbursementDebitSideFirst : null"
        [controlName]="'disbursementDebitSideFirst'"
        [controlObject]="frmUserCompetences.controls.disbursementDebitSideFirst"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-FIRST'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-FIRST'"
        [placeholder]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-FIRST'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.disbursementDebitSideFirst : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.disbursementDebitSideSecond : null"
        [controlName]="'disbursementDebitSideSecond'"
        [controlObject]="frmUserCompetences.controls.disbursementDebitSideSecond"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-SECOND'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-SECOND'"
        [placeholder]="'USER-MANAGEMENT.DISBURSEMENT-DEBIT-SIDE-SECOND'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.disbursementDebitSideSecond : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-slider
        [approvedValue]="approvedValue ? approvedValue.masterCompetenceDebit : null"
        [controlName]="'masterCompetenceDebit'"
        [editableTask]="false"
        [entityName]="entityName"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.MASTER-COMPETENCE-DEBIT'"
        [name]="'USER-MANAGEMENT.MASTER-COMPETENCE-DEBIT'"
        [taskValue]="userTask ? userTask.masterCompetenceDebit : null"
        [titleTask]="false"
      >
      </app-mat-slider>
    </div>
    <div class="competence-section">
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.disbursementCreditSideFirst : null"
        [controlName]="'disbursementCreditSideFirst'"
        [controlObject]="frmUserCompetences.controls.disbursementCreditSideFirst"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-FIRST'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-FIRST'"
        [placeholder]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-FIRST'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.disbursementCreditSideFirst : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.disbursementCreditSideSecond : null"
        [controlName]="'disbursementCreditSideSecond'"
        [controlObject]="frmUserCompetences.controls.disbursementCreditSideSecond"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-SECOND'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-SECOND'"
        [placeholder]="'USER-MANAGEMENT.DISBURSEMENT-CREDIT-SIDE-SECOND'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.disbursementCreditSideSecond : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-slider
        [approvedValue]="approvedValue ? approvedValue.masterCompetenceCredit : null"
        [controlName]="'masterCompetenceCredit'"
        [editableTask]="false"
        [entityName]="entityName"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.MASTER-COMPETENCE-CREDIT'"
        [name]="'USER-MANAGEMENT.MASTER-COMPETENCE-CREDIT'"
        [taskValue]="userTask ? userTask.masterCompetenceCredit : null"
        [titleTask]="false"
      >
      </app-mat-slider>
    </div>
    <div class="competence-section">
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.limitApprovalFirst : null"
        [controlName]="'limitApprovalFirst'"
        [controlObject]="frmUserCompetences.controls.limitApprovalFirst"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.LIMIT-APPROVAL-FIRST'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.LIMIT-APPROVAL-FIRST'"
        [placeholder]="'USER-MANAGEMENT.LIMIT-APPROVAL-FIRST'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.limitApprovalFirst : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-input
        [approvedValue]="approvedValue ? approvedValue.limitApprovalSecond : null"
        [controlName]="'limitApprovalSecond'"
        [controlObject]="frmUserCompetences.controls.limitApprovalSecond"
        [editableTask]="false"
        [entityName]="entityName"
        [formGroupCustomName]="null"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.LIMIT-APPROVAL-SECOND'"
        [maskValue]="'separator.0'"
        [name]="'USER-MANAGEMENT.LIMIT-APPROVAL-SECOND'"
        [placeholder]="'USER-MANAGEMENT.LIMIT-APPROVAL-SECOND'"
        [suffix]="' €'"
        [taskValue]="userTask ? userTask.limitApprovalSecond : null"
        [thousandSeparator]="'.'"
        [titleTask]="false"
      ></app-mat-input>
      <app-mat-slider
        [approvedValue]="approvedValue ? approvedValue.masterLimitApproval : null"
        [controlName]="'masterLimitApproval'"
        [editableTask]="false"
        [entityName]="entityName"
        [form]="frmUserCompetences"
        [isTask]="isTask"
        [label]="'USER-MANAGEMENT.MASTER-COMPETENCE-LIMIT'"
        [name]="'USER-MANAGEMENT.MASTER-COMPETENCE-LIMIT'"
        [taskValue]="userTask ? userTask.masterLimitApproval : null"
        [titleTask]="false"
      >
      </app-mat-slider>
    </div>
  </form>
</div>
