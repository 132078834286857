import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { BookingCode } from 'src/app/shared/models/booking-code';
import { Router } from '@angular/router';
import { bookingCodeColumns } from 'src/app/in-memory-data/bookingCode/table-columns';
import { bookingCodeColumnsConf } from 'src/app/in-memory-data/bookingCode/table-columns-configuration';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SearchService } from 'src/app/shared/services/search/search-service';
import { transactionTypes } from 'src/app/in-memory-data/bookingCode/formConfiguration/transactionTypes';
import { bookingOptions } from 'src/app/in-memory-data/bookingCode/formConfiguration/bookingOptions';
import { inHouseClearingItemCodes } from 'src/app/in-memory-data/bookingCode/formConfiguration/inHouseClearingItemCodes';
import { standards } from 'src/app/in-memory-data/bookingCode/formConfiguration/standards';

@Component({
  selector: 'app-booking-code-list',
  templateUrl: './booking-code-list.component.html',
  styleUrls: ['./booking-code-list.component.less'],
})
export class BookingCodeListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  private CONFIGURATION_KEY = 'booking_code_table';

  dataSource = new MatTableDataSource<BookingCode>();
  bookingCodeList: BookingCode[];
  searchInput: any;
  draftId: any;
  draftGuid: any;

  filterForm: UntypedFormGroup;
  entityName = 'booking-code';
  displayedColumns = bookingCodeColumns;
  columns = bookingCodeColumnsConf;
  transactionTypes = transactionTypes;

  displayedColumnsTemp = [];
  columnsTemp = [];

  standards = standards;
  bookingOptions = bookingOptions;
  inHouseClearingItemCodes = inHouseClearingItemCodes;

  accounts = [
    {
      name: 'NOT_RELEVANT',
      translateName: 'BOOKING-CODE.ACCOUNTS.NOT_RELEVANT',
      account: 'Not Relevant',
    },
  ];
  normalAccounts = [
    {
      name: 'DE12258522554554',
      account: 'DE12258522554554',
    },
  ];
  salesTaxAccounts = [
    {
      account: '123456789123',
    },
  ];

  constructor(
    private bookingCodeService: BookingCodeService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private draftService: DraftService,
    private userService: UserService,
    private searchService: SearchService
  ) {
    this.filterForm = this.formBuilder.group({
      searchString: null,
    });
  }

  ngOnInit(): void {
    this.onFilterChange();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.bookingCodeService.listAll().subscribe((bookingCodeList) => {
      this.notificationService.dismissMessage();
      bookingCodeList.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.bookingCodeList = this.dataSource.data = bookingCodeList;
      if (bookingCodeList.length === 0) {
        this.notificationService.showToast(
          'ERROR.NO-BOOKING-CODE-FOUND',
          this.notificationService.MESSAGE_TYPE.INFO
        );
      }
      this.getDrafts();
    });
    this.getTableConfiguration();
  }

  onFilterChange(): void {
    this.filterForm.get('searchString').valueChanges.subscribe((val) => {
      if (val && val.length < 3 && val.length !== 0) {
        return;
      }
      this.notificationService.dismissMessage();
      const newData = [];
      if (!this.bookingCodeList) {
        return;
      }
      for (const data of this.bookingCodeList) {
        let index = data.bookingCodeDefaultName
          .toUpperCase()
          .indexOf(val.toUpperCase());
        if (index !== -1) {
          newData.push(data);
          continue;
        }
        index = data['bookingCodeId']
          .toString()
          .toUpperCase()
          .indexOf(val.toUpperCase());
        if (index !== -1) {
          newData.push(data);
          continue;
        }
      }
      this.dataSource.data = newData;
    });
  }

  getDrafts() {
    const entity = 'BOOKING_CODE';
    this.draftService.retrieveLocalDrafts(entity).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);
            object.bookingCodeId = data.entityId;
            object.draft = true;
            object.draftId = draft.id;
            object.draftGuid = draft.guid;
            object.operationUser = draft.operationUser;
            this.adaptTableListToObject(object);
          });
        } else {
          this.showNoDataFound();
        }
      },
      (error) => {
        this.showNoDataFound();
      }
    );
  }

  showNoDataFound() {
    if (this.dataSource.data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  adaptTableListToObject(object) {
    object.bookingCodeDefaultName = object.name;
    if (object.selectedPlatforms && object.selectedPlatforms.length > 0) {
      object.platformName = object.selectedPlatforms[0].name;
    }

    if (object.platforms && object.platforms.length > 0) {
      object.bookingCodeName = object.platforms[0].bookingCodeName;
    }

    this.dataSource.data.unshift(object);
    this.dataSource.filter = '';
  }

  deleteDraft(element) {
    this.draftService.deleteUserDraft(element.draftGuid).subscribe((data) => {
      if (data == null) {
        this.dataSource.data.splice(0, 1);
        this.dataSource.filter = '';
        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
    });
  }

  edit(element) {
    const state = { booking: element };
    if (element['draft']) {
      state['draftId'] = element.draftId;
      state['draftGuid'] = element.draftGuid;
      state['operationUser'] = element.operationUser;
    }
    this.router.navigateByUrl(
      '/systemConfiguration/booking/create/' + element.guid,
      {
        state,
      }
    );
  }

  searchBooking() {
    this.searchService.search(
      this.bookingCodeList,
      this.searchInput,
      this.dataSource
    );
  }

  createNew() {
    this.router.navigateByUrl('/systemConfiguration/booking/create', {
      state: { createMode: true },
    });
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                bookingCodeColumnsConf,
                bookingCodeColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = bookingCodeColumnsConf;
    this.displayedColumns = bookingCodeColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged() {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  findTransactionType(transactionTypeName) {
    if (!this.transactionTypes) {
      return '';
    }

    const transactionType = this.transactionTypes.find(
      (element) => element.name === transactionTypeName
    );
    return transactionType ? transactionType.translateName : '';
  }

  findBookingOption(bookingOptionCode) {
    if (!this.bookingOptions) {
      return '';
    }

    const bookingOption = this.bookingOptions.find(
      (element) => element.code === bookingOptionCode
    );
    return bookingOption ? bookingOption.translateName : '';
  }

  findStandard(standardCode) {
    if (!this.standards) {
      return '';
    }

    const standard = this.standards.find(
      (element) => element.code === standardCode
    );
    return standard ? standard.translateName : '';
  }

  findAccount(accountName) {
    if (!this.accounts) {
      return '';
    }

    const account = this.accounts.find(
      (element) => element.name === accountName
    );
    return account ? account.translateName : '';
  }

  findTaxAccount(taxAccountName) {
    if (!this.accounts) {
      return '';
    }

    const taxAccount = this.accounts.find(
      (element) => element.account === taxAccountName
    );
    return taxAccount ? taxAccount.account : '';
  }

  findInHouseItemCode(inHouseClearingItemCode) {
    if (!this.inHouseClearingItemCodes) {
      return '';
    }

    const inHouseItemCode = this.inHouseClearingItemCodes.find(
      (element) => element.code === inHouseClearingItemCode
    );
    return inHouseItemCode ? inHouseItemCode.translateName : '';
  }
}
