import { ValidatorFn, UntypedFormGroup, AbstractControl } from '@angular/forms';

export class DateValidator {
  public startEndValidator(
    startDateName: string,
    endDateName: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const startDate = new Date(formGroup.get(startDateName)?.value);
      const endDate = new Date(formGroup.get(endDateName)?.value);

      return startDate && endDate && endDate < startDate
        ? { dateError: true }
        : null;
    };
  }

  public validateDateNullAndNotInThePast(
    firstDate: string,
    secondDate: string
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const fDate = new Date(formGroup.get(firstDate).value);
      fDate.setHours(0, 0, 0, 0);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (formGroup.get(firstDate).value && formGroup.get(secondDate).value) {
        if (fDate.getTime() < currentDate.getTime()) {
          return { dateError: true };
        }
      } else if (
        formGroup.get(firstDate).value ||
        formGroup.get(secondDate).value
      ) {
        return { datesRequiredError: true };
      } else {
        return null;
      }
    };
  }

  public inBetweenDatesValidator(existentDates): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const startDate = new Date(formGroup.get('startDate').value);
      const endDate = new Date(formGroup.get('endDate').value);

      let inBetween = false;

      if (existentDates && existentDates.length > 0) {
        existentDates.forEach((element) => {
          if (!inBetween) {
            const startExistendDate = new Date(element.startDate);
            const endExistendDate = new Date(element.endDate);

            if (
              (startDate >= startExistendDate &&
                startDate <= endExistendDate) ||
              (endDate >= startExistendDate && endDate <= endExistendDate)
            ) {
              inBetween = true;
            }
          }
        });

        return inBetween ? { inBetweenError: true } : null;
      } else {
        return null;
      }
    };
  }

  public sameDatesValidator(existentDates): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const startDate = new Date(formGroup.get('startDate').value);
      const endDate = new Date(formGroup.get('endDate').value);

      let errorSame = false;

      if (existentDates && existentDates.length > 0) {
        existentDates.forEach((element) => {
          if (!errorSame) {
            const startExistendDate = new Date(element.startDate);
            const endExistendDate = new Date(element.endDate);

            if (
              startDate.getDay() === startExistendDate.getDay() &&
              startDate.getMonth() === startExistendDate.getMonth() &&
              startDate.getFullYear() === startExistendDate.getFullYear() &&
              endDate.getDay() === endExistendDate.getDay() &&
              endDate.getMonth() === endExistendDate.getMonth() &&
              endDate.getFullYear() === endExistendDate.getFullYear()
            ) {
              errorSame = true;
            }
          }
        });

        return errorSame ? { sameError: true } : null;
      } else {
        return null;
      }
    };
  }
}

export function pastDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return new Date(control.value).getTime() > Date.now()
      ? { invalid: true }
      : null;
  };
}

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return new Date(control?.value)?.getTime() < Date.now()
      ? { invalid: true }
      : null;
  };
}
