<div class="content-container">
  <app-back-button></app-back-button>

  <div
    *ngIf="currentBusinessUnit == 'nonAW' && kpiLoaded == true"
    class="info-container"
    id="{{ entityName }}-general-info"
  >
    <h3 class="title-info" id="{{ entityName }}-general-info--header">
      {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.HEADLINE' | translate }}
    </h3>

    <div
      class="container"
      fxLayout="row wrap"
      fxlayout.sm="column"
      fxlayout.xs="column"
      fxLayoutGap="7px grid"
      fxLayoutGap="7px grid"
    >
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="debtorName" id="{{ entityName }}-general-info-debtorName-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DEBTOR-NAME' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="debtorName" class="information" id="{{ entityName }}-general-info-debtorName-value">{{
                masterData.debtorName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="clientDebtorId" id="{{ entityName }}-general-info-clientDebtorId-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.CLIENT-DEBTOR-ID' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="clientDebtorId" class="information" id="{{ entityName }}-general-info-clientDebtorId-value">{{
                masterData.externalId
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="debtorId" id="{{ entityName }}-general-info-debtorId-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DEBTOR-ID' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="debtorId" class="information" id="{{ entityName }}-general-info-debtorId-value">{{
                masterData.visibleId
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="clientName" id="{{ entityName }}-general-info-clientName-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.CLIENT-NAME' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="clientName" class="information" id="{{ entityName }}-general-info-clientName-value">{{
                masterData.clientName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="date" id="{{ entityName }}-general-info-date-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DATE' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="date" class="information" id="{{ entityName }}-general-info-date-value">
                {{ getToday() | date: dateFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="numberTransactions" id="{{ entityName }}-general-info-numberTransactions-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.NUMBER-TRANSACTIONS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="numberTransactions"
                class="information"
                id="{{ entityName }}-general-info-numberTransactions-value"
                >{{ getNumberTransactions() }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="allocationBalance" id="{{ entityName }}-general-info-allocationBalance-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.ALLOCATION-BALANCE' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="allocationBalance"
                class="information"
                id="{{ entityName }}-general-info-allocationBalance-value"
                >{{ getAllocationBallance() | currency: 'EUR':'symbol':'1.2-2':'de' }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="discount" id="{{ entityName }}-general-info-discount-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DISCOUNT' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="discount" class="information" id="{{ entityName }}-general-info-discount-value">{{
                allocationInfo.discount
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="currency" id="{{ entityName }}-general-info-currency-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.CURRENCY' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="currency" class="information" id="{{ entityName }}-general-info-currency-value">{{
                getCurrency()
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="bonus" id="{{ entityName }}-general-info-bonus-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.BONUS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="bonus" class="information" id="{{ entityName }}-general-info-bonus-value">{{
                allocationInfo.bonus
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="otherDeductions" id="{{ entityName }}-general-info-otherDeductions-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.OTHER-DEDUCTIONS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="otherDeductions"
                class="information"
                id="{{ entityName }}-general-info-otherDeductions-value"
                >{{ allocationInfo.otherDeductions }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="smallestAmount" id="{{ entityName }}-general-info-smallestAmount-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.SMALLEST-AMOUNT' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="smallestAmount" class="information" id="{{ entityName }}-general-info-smallestAmount-value">{{
                getSmallestAmount() | currency: 'EUR':'symbol':'1.2-2':'de'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="isDraft">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="draftExpirationTime" id="{{ entityName }}-general-info-draftExpirationTime-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DRAFT-EXPIRATION-TIME' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="draftExpirationTime"
                class="information"
                id="{{ entityName }}-general-info-draftExpirationTime-value"
                >{{ (getDraftExpirationTime() | date: dateFormat + ' HH:mm:ss') + ' ' }}</span
              >
              <span class="material-icons"> notifications_active </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="currentBusinessUnit == 'AW' && kpiLoaded == true"
    class="info-container"
    id="{{ entityName }}-general-info"
  >
    <h3 class="title-info" id="{{ entityName }}-general-info--header">
      {{ 'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.HEADLINE' | translate }}
    </h3>

    <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="companyName" id="{{ entityName }}-general-info-company-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.COMPANY' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="companyName" class="information" id="{{ entityName }}-general-info-company-value">{{
                masterData.companyName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="qsid" id="{{ entityName }}-general-info-qsid-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.QSID' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="qsid" class="information" id="{{ entityName }}-general-info-qsid-value">{{
                masterData.qsid
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="date" id="{{ entityName }}-general-info-date-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.DATE' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="date" class="information" id="{{ entityName }}-general-info-date-value">
                {{ getToday() | date: dateFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="numberTransactions" id="{{ entityName }}-general-info-numberTransactions-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.NUMBER-TRANSACTIONS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="numberTransactions"
                class="information"
                id="{{ entityName }}-general-info-numberTransactions-value"
                >{{ getNumberTransactions() }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="allocationBalance" id="{{ entityName }}-general-info-allocationBalance-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.BALANCE' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="allocationBalance"
                class="information"
                id="{{ entityName }}-general-info-allocationBalance-value"
                >{{ getAllocationBallance() | currency: 'EUR':'symbol':'1.2-2':'de' }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="discount" id="{{ entityName }}-general-info-discount-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.DISCOUNT' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container"></div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="currency" id="{{ entityName }}-general-info-currency-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.CURRENCY' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="currency" class="information" id="{{ entityName }}-general-info-currency-value">{{
                getCurrency()
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="bonus" id="{{ entityName }}-general-info-bonus-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.BONUS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container"></div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="otherDeductions" id="{{ entityName }}-general-info-otherDeductions-label">{{
                'ANIMAL-WELFARE.BILLING-SLAUGHTERHOUSE.BILLING-ALLOCATION.OTHER-DEDUCTIONS' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container"></div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="smallestAmount" id="{{ entityName }}-general-info-smallestAmount-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.SMALLEST-AMOUNT' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span name="smallestAmount" class="information" id="{{ entityName }}-general-info-smallestAmount-value">{{
                getSmallestAmount() | currency: 'EUR':'symbol':'1.2-2':'de'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" *ngIf="isDraft">
        <div fxLayout="row">
          <div fxFlex="50">
            <div class="label-container">
              <label for="draftExpirationTime" id="{{ entityName }}-general-info-draftExpirationTime-label">{{
                'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.INFORMATION.DRAFT-EXPIRATION-TIME' | translate
              }}</label>
            </div>
          </div>
          <div fxFlex="50">
            <div class="span-container">
              <span
                name="draftExpirationTime"
                class="information"
                id="{{ entityName }}-general-info-draftExpirationTime-value"
                >{{ (getDraftExpirationTime() | date: dateFormat + ' HH:mm:ss') + ' ' }}</span
              >
              <span class="material-icons" *ngIf="isTwoDaysLeftTillExpiration()"> notifications_active </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-search-results-table"
      class="general-table-alignment"
      aria-hidden="true"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-select">
          <mat-checkbox
            (change)="$event ? masterToggle(true) : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-select-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
            [aria-label]="checkboxLabel(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="creditDebitCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-debitOrCredit"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-debitOrCredit-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DEBIT-CREDIT-VALUES.' + '' + element.creditDebitCode.toString() | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-transactionCode"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.bookingCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentationId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-documentationId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-documentationId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.clientOpenItemId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentationDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-documentationDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DOCUMENTATION-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-documentationDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.openItemDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="v25-th" id="{{ entityName }}-table-results-client">
          {{ 'TRANSACTION-OVERVIEW.TABLE.CLIENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-client-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.client }}
        </td>
      </ng-container>

      <ng-container matColumnDef="externalDebtorId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-externalDebtorId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.EXTERNAL-DEBTOR-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-externalDebtorId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.externalDebtorId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="internalDebtorId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-internalDebtorId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.INTERNAL-DEBTOR-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-internalDebtorId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.internalDebtorId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-amount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-amount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="vatPercent">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-vatPercent"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.VAT-PERCENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-vatPercent-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.vatPercent | percent: '1.0-3' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="vatAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-vatAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.VAT-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-vatAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.vatAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discountDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-discountDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-discountDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.discountDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discountPercent">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-discountPercent"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-PERCENT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-discountPercent-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.discountPercent | percent: '1.0-3' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discountAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-discountAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-discountAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.discountAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-dueDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DUE-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-dueDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dueDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="financed">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-financed"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.FINANCED' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-financed-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row-header"
        >
          {{ element.financedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approvalCode">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-approvalCode"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.APPROVAL-CODE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-approvalCode-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.approvalCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nonFinanced">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-nonFinanced"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.NON-FINANCED' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-nonFinanced-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.nonFinancedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="objectionAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-objectionAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-objectionAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          <span *ngIf="element.objection">{{ element.objection.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="objectionReason">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-objectionReason"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-REASON' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-objectionReason-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span *ngIf="element.objection">{{ element.objection.reason }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="objectionDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-objectionDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.OBJECTION-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-objectionDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span *ngIf="element.objection"> {{ element.objection.date | date: dateFormat }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="discountRate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-discountRate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DISCOUNT-RATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-discountRate-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.discountRate | percent: '1.0-3' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentDeductionAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-paymentDeductionAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.PAYMENT-DEDUCTION-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-paymentDeductionAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.paymentDeductionAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentDeductionRate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-paymentDeductionRate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.PAYMENT-DEDUCTION-RATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-paymentDeductionRate-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.paymentDeductionRate | percent: '1.0-3' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dunningBlockDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-dunningBlockDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DUNNING-BLOCK-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-dunningBlockDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dunningBlockDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dunningLevel">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-dunningLevel"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DUNNING-LEVEL' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-dunningLevel-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.dunningLevel }}
        </td>
      </ng-container>

      <ng-container matColumnDef="debtCollectionAgency">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-debtCollectionAgency"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-AGENCY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-debtCollectionAgency-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.debtCollectionAgency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="debtCollectionStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-debtCollectionStatus"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-STATUS' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-debtCollectionStatus-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.debtCollectionStatus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="debtCollectionTransferDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-debtCollectionTransferDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.DEBT-COLLECTION-TRANSFER-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-debtCollectionTransferDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.debtCollectionTransferDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="processingEnd">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-processingEnd"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.PROCESSING-END' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-processingEnd-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.processingEnd | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionCodeName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-transactionCodeName"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-CODE-NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionCodeName-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.bookingCodeName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="openAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-openAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.OPEN-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-openAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.openAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clientId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-clientId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.CLIENT-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-clientId-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.clientVisibleId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="entryDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-entryDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.ENTRY-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-entryDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.entryDate | date: dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="branchId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-branchId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.BRANCH-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-branchId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.branchId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastChangeDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-lastChangeDate"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.LAST-CHANGE-DATE' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-lastChangeDate-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.lastChangeDate | date: dateFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-transactionId"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.TRANSACTION-ID' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-transactionId-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="referenceDocumentation">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-referenceDocumentation"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-DOCUMENTATION' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-referenceDocumentation-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.referenceDocumentation }}
        </td>
      </ng-container>
      <ng-container matColumnDef="referenceText">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-referenceText"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.REFERENCE-TEXT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-referenceText-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.referenceText }}
        </td>
      </ng-container>
      <ng-container matColumnDef="originalAccount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-originalAccount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.ORIGINAL-ACCOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-originalAccount-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.originalAccount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="allocationNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-allocationNumber"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-NUMBER' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-allocationNumber-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.allocationNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-currency"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.CURRENCY' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-currency-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.currencyCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="currencyAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-currencyAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.CURRENCY-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-currencyAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.currencyAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="repurchasingReason">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-repurchasingReason"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.REPURCHASING-REASON' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-repurchasingReason-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.repurchasingReason }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoiceAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-invoiceAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.INVOICE-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-invoiceAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.invoiceAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="allocationTimestamp">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-results-allocationTimestamp"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-TIMESTAMP' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-allocationTimestamp-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.allocationTimestamp | date: dateFormat + ' HH:mm:ss' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="allocationAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th number-row-header"
          id="{{ entityName }}-table-results-allocationAmount"
        >
          {{ 'TRANSACTION-OVERVIEW.TABLE.ALLOCATION-AMOUNT' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-results-allocationAmount-{{ paginator.pageIndex + 1 }}-{{ i }}"
          class="number-row"
        >
          {{ element.allocatedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex" (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            (click)="selectRow(element)"
            [matMenuTriggerFor]="action"
            [matMenuTriggerData]="{ element: element }"
            id="{{ entityName }}-table-results-actions-{{ paginator.pageIndex + 1 }}-{{ i }}"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; let i = index; columns: displayedColumns" class="element-row"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
</div>

<div class="button-container">
  <span class="control-buttons">
    <ng-container>
      <button
        (click)="saveAsDraft()"
        [disabled]="dataSource && dataSource.data.length == 0"
        mat-button
        id="{{ entityName }}-save-draft-button"
        class="custom-button"
      >
        {{ 'GENERAL.SAVE-AS-DRAFT' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button
        (click)="confirmAllocation()"
        [disabled]="(dataSource && dataSource.data.length == 0) || buttonsAreDisabled.save"
        mat-button
        id="{{ entityName }}-save-button"
        class="custom-button"
      >
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.SAVE-ALLOCATION' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="isDraft">
      <button (click)="deleteDraft()" mat-button id="{{ entityName }}-delete-draft-button" class="custom-button">
        {{ 'GENERAL-ENTITY.LIST.ACTIONS.DELETE-DRAFT' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button
        (click)="openTransactionSelection()"
        mat-button
        id="{{ entityName }}-newTransaction-button"
        class="custom-button"
      >
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.NEW-TRANSACTION' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button mat-button class="custom-button" id="{{ entityName }}-selectionTransaction-button">
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.TRANSACTION-SELECTION' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button mat-button id="{{ entityName }}-writeOffBfs-button" class="custom-button">
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.WRITE-OFF-BFS' | translate }}
      </button>
    </ng-container>
    <ng-container>
      <button mat-button id="{{ entityName }}-writeOffClient-button" class="custom-button">
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.WRITE-OFF-CLIENT' | translate }}
      </button>
    </ng-container>
  </span>
</div>

<mat-menu #action="matMenu" class="menu-actions">
  <ng-template matMenuContent let-element="element">
    <ng-container>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-bank-info-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.BANK-INFO' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.BANK-INFO' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-split-payment-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.SPLIT-PAYMENT' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.SPLIT-PAYMENT' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-discount-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.DISCOUNT' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.DISCOUNT' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-write-off-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.WRITE-OFF' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.WRITE-OFF' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-booking-deduction-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.BOOKING-DEDUCTION' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.BOOKING-DEDUCTION' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-rebooking-transaction-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REBOOKING-TRANSACTION' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REBOOKING-TRANSACTION' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-overpayment-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.OVERPAYMENT' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.OVERPAYMENT' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-repurchase-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REPURCHASE' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REPURCHASE' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-client-deduction-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.CLIENT-DEDUCTION' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.CLIENT-DEDUCTION' | translate }}
      </button>
      <button mat-menu-item id="{{ entityName }}-table-results-action-menu-rate-difference-button">
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.CURRENCY-RATE-DIFFERENCE' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.CURRENCY-RATE-DIFFERENCE' | translate }}
      </button>
      <button
        (click)="removeTransaction()"
        mat-menu-item
        id="{{ entityName }}-table-results-action-menu-remove-transaction-button"
      >
        <img
          class="icon-menu"
          src="../../../../assets/images/ic_edit.svg"
          alt="{{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REMOVE-TRANSACTION' | translate }}"
        />
        {{ 'TRANSACTION-OVERVIEW.OPEN-ALLOCATION.ACTIONS.REMOVE-TRANSACTION' | translate }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
