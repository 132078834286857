import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-prompt-modal',
  templateUrl: './confirmation-prompt-modal.component.html',
  styleUrls: ['./confirmation-prompt-modal.component.less'],
})
export class ConfirmationPromptModalComponent {
  messageKey;
  messageParams = [];

  constructor(private translateService: TranslateService) {}

  getMessage() {
    return this.translateService.instant(this.messageKey, this.messageParams);
  }
}
