<mat-icon id="password-policy-modal-close-button" mat-button [mat-dialog-close]>close</mat-icon>
<div class="dialog-content">
  <div mat-dialog-content class="background-dark margin-container">
    <p class="message-key-one">
      {{ 'PASSWORD.POLICY.MESSAGE-KEY-ONE' | translate }}
    </p>
    <p class="message-key-two">
      {{ 'PASSWORD.POLICY.MESSAGE-KEY-TWO' | translate }}
    </p>
    <p class="message-key-three">
      {{ 'PASSWORD.POLICY.MESSAGE-KEY-THREE' | translate }}
    </p>
    <ul>
      <li class="message-key-four">
        {{ 'PASSWORD.POLICY.MESSAGE-KEY-FOUR' | translate }}
      </li>
      <li class="message-key-five">
        {{ 'PASSWORD.POLICY.MESSAGE-KEY-FIVE' | translate }}
      </li>
      <li class="message-key-six">
        {{ 'PASSWORD.POLICY.MESSAGE-KEY-SIX' | translate }}
      </li>
      <li class="message-key-seven">
        {{ 'PASSWORD.POLICY.MESSAGE-KEY-SEVEN' | translate }}
        <ul>
          <li class="message-key-eight">
            {{ 'PASSWORD.POLICY.MESSAGE-KEY-EIGHT' | translate }}
          </li>
          <li class="message-key-nine">
            {{ 'PASSWORD.POLICY.MESSAGE-KEY-NINE' | translate }}
          </li>
          <li class="message-key-ten">
            {{ 'PASSWORD.POLICY.MESSAGE-KEY-TEN' | translate }}
          </li>
          <li class="message-key-eleven">
            {{ 'PASSWORD.POLICY.MESSAGE-KEY-ELEVEN' | translate }}
          </li>
        </ul>
      </li>
      <li class="message-key-twelve">
        {{ 'PASSWORD.POLICY.MESSAGE-KEY-TWELVE' | translate }}
      </li>
    </ul>
  </div>
</div>
