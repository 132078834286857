import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../../shared/services/user/user.service';
import { ConfirmationModalComponent } from '../../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DraftService } from '../../../../shared/services/draft/draft.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RealmSelectionModalComponent } from '../realm-selection-modal/realm-selection-modal.component';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';

@Component({
  selector: 'app-excluded-permission-create',
  templateUrl: './excluded-permission-create.component.html',
  styleUrls: ['./excluded-permission-create.component.less'],
})
export class ExcludedPermissionCreateComponent implements OnInit {
  frmExcludedPermission;
  approvedValue;
  excludedPermissionTask;
  isTask = false;
  searchStringAll: any;
  searchRunning = false;
  selectedPermissions = [];
  selectedActivePermissions: any[] = [];
  searchStringActive: any;
  activePermissions: any;
  permissions;
  task;
  taskValue;
  state;
  realm = undefined;
  entityName = 'excluded-permissions';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private matDialogService: MatDialog,
    private draftService: DraftService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.excludedPermissionFormBuilder();
  }

  get formPermissions() {
    return this.frmExcludedPermission.get('excludedPermissions').value;
  }

  ngOnInit(): void {
    this.state = window.history.state;
    const snapshot = this.route.snapshot;

    if (snapshot.params && snapshot.params.guid) {
      this.userService.findExclusion(snapshot.params.guid).subscribe((data) => {
        this.frmExcludedPermission.patchValue(data);
        this.approvedValue = data;
      });
    } else if (this.state.task) {
      this.task = this.state.task;
      this.draftService
        .retrieveDraftForTask(this.task.guid)
        .subscribe((data) => {
          this.taskValue = data;
          this.excludedPermissionTask = data;
          this.isTask = true;
          this.frmExcludedPermission.patchValue(data);
        });
    } else {
      const dialog = this.matDialogService.open(RealmSelectionModalComponent, {
        panelClass: 'confirmation-popup',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.realm = result;
        } else {
          this.router.navigateByUrl('/userManagement/permission');
        }
      });
    }
    this.userService.getPermissions(false).subscribe((data) => {
      this.permissions = data;
    });
  }

  excludedPermissionFormBuilder() {
    this.frmExcludedPermission = this.formBuilder.group({
      id: [{ value: '', disabled: true }],
      name: ['', Validators.required],
      description: [''],
      excludedPermissions: [[]],
      createdOn: [{ value: '', disabled: true }],
      createdBy: [{ value: '', disabled: true }],
      updatedOn: [{ value: '', disabled: true }],
      updatedBy: [{ value: '', disabled: true }],
    });
  }

  isSelected(permission: any) {
    if (!this.formPermissions) {
      return false;
    }
    for (const selectedPermission of this.formPermissions) {
      if (selectedPermission === permission.permissionId) {
        return true;
      }
    }
    return false;
  }

  addToUser() {
    this.frmExcludedPermission
      .get('excludedPermissions')
      .patchValue(this.selectedPermissions);
  }

  onGroupsChange(options: MatListOption[]) {
    this.selectedActivePermissions = [];
    for (const option of options) {
      if (option.selected) {
        this.selectedActivePermissions.push(option.value);
      }
    }
  }

  removeFromUser() {
    this.selectedActivePermissions.forEach((sap) => {
      const foundIndex = this.formPermissions.findIndex(
        (ap) => ap.toString() === sap.toString()
      );
      if (foundIndex > -1) {
        this.formPermissions.splice(foundIndex, 1);
      }
    });
  }

  createExcludedPermission() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const requestJson = {
          comment: result.comment,
          excludedPermissionDto: {
            ...this.frmExcludedPermission.getRawValue(),
          },
        };

        this.userService
          .createExcludedPermission(requestJson)
          .subscribe((_) => {
            /**
             * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
             * to much of empty function. So this comment is to makes the sonarqube happy.
             * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
             */
          });
      }
    });
  }
}
