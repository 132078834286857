import { Component } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmailService } from '../shared/services/email/email.service';
import { NotificationService } from '../shared/services/notification/notification.service';

@Component({
  selector: 'app-email-receiving-mock',
  templateUrl: './email-receiving-mock.component.html',
  styleUrls: ['./email-receiving-mock.component.less'],
})
export class EmailReceivingMockComponent {
  emailForm: UntypedFormGroup;
  entityName = 'emailMock';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private emailService: EmailService,
    private notificationService: NotificationService
  ) {
    this.emailForm = this.formBuilder.group({
      tciId: ['', Validators.required],
    });
  }

  receiveEmail() {
    this.emailService
      .simulateReceiveEmailTCI(this.emailForm.value.tciId)
      .subscribe((data) => {
        this.notificationService.showToast(
          'NOTIFICATION.EMAIL-ID-SUBMITTED',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      });
  }
}
