import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Email } from '../../models/email';
import { Observable } from 'rxjs';
import { EmailTemplate } from '../../models/email-template';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private serviceUrl;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.emailService + 'email';
  }

  send(request: Email): Observable<any> {
    const formData: FormData = new FormData();
    if (request.attachment && request.attachment.length) {
      request.attachment.forEach((file) => formData.append('files', file));
    }
    formData.append(
      'email',
      new Blob([JSON.stringify(request)], { type: 'application/json' })
    );

    const req = new HttpRequest('POST', this.serviceUrl + '/send', formData, {
      reportProgress: false,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  find(emailTemplate: EmailTemplate): Observable<any> {
    return this.http.post(
      this.serviceUrl + '/find',
      emailTemplate,
      this.buildHeaders()
    );
  }

  simulateReceiveEmailTCI(tciId) {
    return this.http.post(
      this.serviceUrl + '/email-received/' + tciId,
      this.buildHeaders()
    );
}

  download(id: any) {
  return this.http.get(this.serviceUrl + '/attachment/' + id, {
      responseType: 'blob' as 'json',
      observe: 'response',
      headers: this.buildHeadersForFileDownload().headers,
    });
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }

  private buildHeadersForFileDownload() {
    return {
      headers: new HttpHeaders().append('Content-type', 'application/json'),
    };
  }
}
