<div class="input-wrapper form-container">
  <form class="form" [formGroup]="contractInformationForm" autocomplete="off">
    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.contractDate"
      [controlName]="'contractDate'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.contractDate : null"
      [taskValue]="salesEntryTask ? salesEntryTask.contractDate : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [minDate]="null"
      [maxDate]="null"
      [stringDate]="true"
      [disabled]="readOnly"
    ></app-mat-input-date>

    <!-- start of contract date cattle -->
    <app-mat-input-date
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE-CATTLE'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE-CATTLE'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-DATE-CATTLE'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.contractDateCattle"
      [controlName]="'contractDateCattle'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.contractDateCattle : null"
      [taskValue]="salesEntryTask ? salesEntryTask.contractDateCattle : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [minDate]="null"
      [maxDate]="null"
      [stringDate]="true"
      [disabled]="readOnly"
    ></app-mat-input-date>
    <!-- end of contract date cattle -->

    <app-mat-select
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-TYPE'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-TYPE'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.CONTRACT-TYPE'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.type"
      [controlName]="'type'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.type : null"
      [taskValue]="salesEntryTask ? salesEntryTask.type : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="contractTypes"
      [optionValue]="'name'"
      [selectedOption]="null"
      [translationOption]="'translateName'"
      [optionTranslationText]="''"
      [customTaskValueFunction]="getContractType"
      [disabled]="readOnly || draftExists"
    ></app-mat-select>

    <app-mat-input
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.ACCOUNT-NUMBER'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.accountNumber"
      [controlName]="'accountNumber'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.accountNumber : null"
      [taskValue]="salesEntryTask ? salesEntryTask.accountNumber : null"
      [titleTask]="false"
      [maskValue]=""
      [thousandSeparator]="'.'"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [editableTask]="editableTask"
      [titlecasePipe]="false"
      [minLength]="6"
      [maxLength]="10"
      [disabled]="readOnly"
    ></app-mat-input>
    <app-mat-select
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.PAYMENT-METHOD'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.PAYMENT-METHOD'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.PAYMENT-METHOD'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.paymentMethod"
      [controlName]="'paymentMethod'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.paymentMethod : null"
      [taskValue]="salesEntryTask ? salesEntryTask.paymentMethod : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="paymentMethodTypes"
      [optionValue]="'name'"
      [selectedOption]="null"
      [translationOption]="'translateName'"
      [optionTranslationText]="''"
      [customTaskValueFunction]="getPaymentMethod"
      [disabled]="readOnly || draftExists"
    ></app-mat-select>
    <app-mat-input
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.TAX-ID'"
      [placeholder]="'ANIMAL-WELFARE.SALES-ENTRY.TAX-ID'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.TAX-ID'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.taxId"
      [controlName]="'taxId'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.taxId : null"
      [taskValue]="salesEntryTask ? salesEntryTask.taxId : null"
      [titleTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [editableTask]="editableTask"
      [titlecasePipe]="false"
      [disabled]="readOnly"
    ></app-mat-input>
    <app-mat-slider
      [form]="contractInformationForm"
      [controlName]="'advancePayment'"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.ADVANCE-PAYMENT'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.advancePayment : null"
      [taskValue]="salesEntryTask ? salesEntryTask.advancePayment : null"
      [titleTask]="false"
      [editableTask]="editableTask"
      [isTask]="isTask"
      [disabled]="readOnly"
    >
    </app-mat-slider>
    <app-mat-input
      (keyup)="checkBillingrateValue($event)"
      [name]="'ANIMAL-WELFARE.SALES-ENTRY.BILLING-RATE'"
      [placeholder]="getBillingRateLabel()"
      [label]="'ANIMAL-WELFARE.SALES-ENTRY.BILLING-RATE'"
      [floatLabel]="'always'"
      [entityName]="entityName"
      [form]="contractInformationForm"
      [controlObject]="contractInformationForm.controls.billingRate"
      [controlName]="'billingRate'"
      [approvedValue]="salesEntryApproved ? salesEntryApproved.billingRate : null"
      [taskValue]="salesEntryTask ? salesEntryTask.billingRate : null"
      [titleTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [editableTask]="editableTask"
      [titlecasePipe]="false"
      [disabled]="readOnly"
    >
    </app-mat-input>
  </form>
</div>
