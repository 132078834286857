<ng-container *ngIf="control">
  <app-simple-input
    [control]="control"
    [disabled]="isDisabled"
    [label]="labelKey"
    [type]="type"
    [mask]="mask"
    [suffix]="suffix"
    [thousandSeparator]="thousandSeparator"
    [decimalMarker]="decimalMarker"
  >
  </app-simple-input>
</ng-container>
