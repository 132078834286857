import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[removeHtmlTags]',
})
export class RemoveHtmlTagsDirective {
  @Input('removeHtmlTags') formControl: UntypedFormControl;
  pattern = /\<.*?\>/g;
  patternDoubleOpenAngleBrackets = /\<.*?\</g;

  constructor(private elmt: ElementRef<HTMLInputElement>) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    if (!!value) {
      value = value.replace(this.pattern, '');
      value = value.replace(this.patternDoubleOpenAngleBrackets, '');
      this.formControl.setValue(value);
    }
  }
}
