<div class="entry-list">
  <form class="form" [formGroup]="form">
    <table mat-table [dataSource]="dataSource" id="{{ entityName }}-search-results-table" aria-hidden="true">
      <ng-container *ngFor="let el of tableConfiguration" matColumnDef="{{ el.columnName }}">
        <th mat-header-cell *matHeaderCellDef class="v25-th" id="{{ entityName }}-table-results-{{ el.columnName }}">
          <span *ngIf="el.translateLabel">
            {{ el.translateLabel | translate }}
          </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div *ngIf="getFormGroup(element) != undefined && el.type == 'checkbox'" [formGroup]="getFormGroup(element)">
            <mat-checkbox
              id="{{ entityName }}-{{ el.columnName }}-checkbox-{{ paginator.pageIndex + 1 }}-{{ i }}"
              formControlName="{{ el.columnName }}"
              (change)="checkReportingDate(element, entityName)"
            >
            </mat-checkbox>
          </div>
          <div *ngIf="getFormGroup(element) != undefined && el.type == 'input'">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged(el.columnName, element) }"
            >
              <mat-label>{{ el.translateLabel | translate }}</mat-label>
              <div *ngIf="el.inputType && el.inputType == 'number'; else typeText">
                <input
                  autocomplete="off"
                  id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-{{ el.columnName }}"
                  matInput
                  type="number"
                  formControlName="{{ el.columnName }}"
                  placeholder="{{ el.translateLabel | translate }}"
                  [required]="isFieldRequired(element, el.columnName)"
                />
              </div>
              <ng-template #typeText>
                <input
                  autocomplete="off"
                  id="{{ entityName }}-form-{{ paginator.pageIndex + 1 }}-{{ i }}-{{ el.columnName }}"
                  matInput
                  type="text"
                  formControlName="{{ el.columnName }}"
                  placeholder="{{ el.translateLabel | translate }}"
                  [required]="isFieldRequired(element, el.columnName)"
                  [removeHtmlTags]="getFormGroup(element).controls[el.columnName]"
                />
              </ng-template>
              <mat-error *ngFor="let error of getErrors(element, el.columnName)" class="input-error">
                {{
                  'ERROR-MESSAGES.' + error.toUpperCase()
                    | translate
                      : {
                          name: el.translateLabel.toUpperCase() | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="getFormGroup(element) != undefined && el.type == 'select'">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{ 'val-changed': hasChanged(el.columnName, element) }"
            >
              <mat-label>{{ el.translateLabel | translate }}</mat-label>
              <mat-select
                placeholder="{{ el.translateLabel | translate }}"
                formControlName="{{ el.columnName }}"
                [required]="isFieldRequired(element, el.columnName)"
                (selectionChange)="onSelectChange(i, el.columnName, $event.value)"
              >
                <mat-option *ngFor="let option of el.options" [value]="option.value">
                  {{ option.translateName | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngFor="let error of getErrors(element, el.columnName)" class="input-error">
                {{
                  'ERROR-MESSAGES.' + error.toUpperCase()
                    | translate
                      : {
                          name: el.translateLabel.toUpperCase() | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="getFormGroup(element) != undefined && el.type == 'monthAndYear'">
            <mat-form-field
              appearance="fill"
              [formGroup]="getFormGroup(element)"
              [ngClass]="{
                'val-changed': hasChanged(element, el.columnName)
              }"
              id="{{ entityName }}-{{ el.columnName }}-{{ i }}"
            >
              <mat-label>{{ el.translateLabel | translate }}</mat-label>
              <input matInput [matDatepicker]="monthAndYeardp" formControlName="{{ el.columnName }}" required />
              <mat-datepicker-toggle matSuffix [for]="monthAndYeardp"></mat-datepicker-toggle>
              <mat-datepicker
                #monthAndYeardp
                startView="multi-year"
                (yearSelected)="chosenYearHandler($event, element, el.columnName)"
                (monthSelected)="chosenMonthHandler($event, monthAndYeardp, element, el.columnName, entityName)"
              >
              </mat-datepicker>
              <mat-error *ngFor="let error of getErrors(element, el.columnName)" class="input-error">
                {{
                  'ERROR-MESSAGES.' + error.toUpperCase()
                    | translate
                      : {
                          name: el.translateLabel.toUpperCase() | translate
                        }
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="getFormGroup(element) != undefined && el.type == 'inputDateRange'">
            <mat-form-field appDateFormatMonthYear appearance="fill">
              <mat-label>{{ el.translateLabel | translate }}</mat-label>
              <mat-date-range-input
                [formGroup]="getFormGroup(element)"
                [rangePicker]="createdOnPicker"
                id="{{ entityName }}-createdOn-begin-end"
              >
                <input
                  matStartDate
                  formControlName="{{ el.rangeStartName }}"
                  id="{{ entityName }}-inputDate-begin"
                  placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                  (keydown.enter)="$event.preventDefault()"
                />
                <input
                  matEndDate
                  formControlName="{{ el.rangeEndName }}"
                  id="{{ entityName }}-inputDate-end"
                  placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                  (keydown.enter)="$event.preventDefault()"
                />
              </mat-date-range-input>
              <mat-date-range-picker
                matSuffix
                #createdOnPicker
                startView="multi-year"
                (yearSelected)="chosenYearHandler($event, element, el.columnName)"
              ></mat-date-range-picker>
            </mat-form-field>
          </div>
          <!--ACTIONS-->
          <div *ngIf="el.type == 'actions' && mode == 'CREATE' && !getFormObjects.disabled">
            <button
              mat-button
              class="remove-button"
              id="{{ entityName }}-delete-button"
              (click)="deleteSelectedRows(element)"
            >
              <mat-icon class="add-icon">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
    </table>
  </form>
</div>
