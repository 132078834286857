import { ValidatorFn, AbstractControl } from '@angular/forms';
import { IBAN } from 'ibankit';
export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }
    if (
      IBAN.isValid(control.value) &&
      control.value === control.value.replace(/\s/g, '')
    ) {
      return null;
    } else {
      return { IBANInvalid: true };
    }
  };
}
