import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models/user';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { SortService } from '../../services/sort/sort-service';
import { UserService } from '../../services/user/user.service';
import { SortTableEvent } from './sort-table-event';
import { debounceTime } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-menu-sort-filter',
  templateUrl: './table-menu-sort-filter.component.html',
  styleUrls: ['./table-menu-sort-filter.component.less'],
})
export class TableMenuSortFilterComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  options: any[];
  searchTerm: string;
  imageSrc = 'ic_sort';
  sortAsceSrc = 'ic_sort_ascending';
  sortDescSrc = 'ic_sort_abusive';
  appliedFilters = [];
  filter = false;
  entityName = 'menu-sort-filter';
  dateFormat: any;
  loaderSpinner = true;
  allFetchedOptions: any[];

  @Input() sortableDate: boolean;
  @Input() choice: string;
  @Input() date: string;
  @Input() userList: User[];
  @Input() buList: any[];
  @Input() dataSource;
  @Input() originalDataSource;
  @Input() sidebar;
  @Input() status: boolean;
  @Input() sortDisabled: boolean;
  @Input() filterDisabled: boolean;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() sortDirection: string;
  @Input() callback: (choice, values) => void;
  @Input() getDistinctValues: (choice) => Observable<any[]>;
  @Input() sortTable: (event: SortTableEvent) => Observable<any[]>;
  @Input() translatePrefix = '';
  @Input() filterEvent;
  @Input() enableFilter = true;
  @Input() disableFilterByColumn = false;
  @Input() disableSortByColumn = false;
  value = {
    options: [],
    filteredOptions: [],
    filteredObjects: [],
    sortedObjects: [],
  };

  form: UntypedFormGroup;
  searchValueSubject = new Subject<string>();

  constructor(
    private sortService: SortService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) {
    this.form = formBuilder.group({
      checkboxValue: [],
    });
    this.searchValueSubject.pipe(debounceTime(500)).subscribe(() => {
      this.performFiltering(this.searchTerm);
    });
  }

  ngOnInit() {
    this.dateFormat = this.userService.getDateFormat();
  }

  searchValueChanged() {
    this.loaderSpinner = true;
    this.searchValueSubject.next(undefined);
  }

  performFiltering(searchValue) {
    this.options = [];
    if (searchValue) {
      const newOptions = this.allFetchedOptions.filter((option) =>
        option
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase())
      );
      this.options = [...newOptions];
    } else {
      this.options = [...this.allFetchedOptions];
    }
    this.virtualScroll.setRenderedRange({
      start: 0,
      end: this.options.length + 1,
    });
    setTimeout(() => {
      this.virtualScroll.checkViewportSize();
    }, 400);
    this.loaderSpinner = false;
  }

  applyFilter(item: any, event) {
    this.resetSortImage();
    const index = this.appliedFilters.indexOf(item);
    if (event && event.checked) {
      this.appliedFilters.push(item);
    } else {
      if (index > -1) {
        this.appliedFilters.splice(index, 1);
      }
    }
    if (this.sidebar) {
      this.sortService.applyColumnFiltersInSplitScreen(
        this.choice,
        this.appliedFilters,
        this.dataSource
      );
    } else {
      this.callback(this.choice, this.appliedFilters);
    }
  }

  clearFilters() {
    this.form.reset();
    this.appliedFilters = [];
    this.value.filteredObjects = [];
    this.searchTerm = '';
    this.callback(this.choice, []);
    this.resetSortImage();
  }

  setDesc() {
    this.resetSortImage();
    if (this.sidebar) {
      this.imageSrc = this.sortDescSrc;
      this.sortService.sortDescInSplitScreen(this.choice, this.dataSource);
    } else {
      this.imageSrc = this.sortDescSrc;
      this.sortTable(this.getSortTableEvent('desc'));
    }
  }

  setAsce() {
    this.resetSortImage();
    if (this.sidebar) {
      this.imageSrc = this.sortAsceSrc;
      this.sortService.sortAsceInSplitScreen(this.choice, this.dataSource);
    } else {
      this.imageSrc = this.sortAsceSrc;
      this.sortTable(this.getSortTableEvent('asc'));
    }
  }

  private getSortTableEvent(direction: string): SortTableEvent {
    return {
      choice: this.choice,
      direction: direction,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    };
  }

  getAdditionalOptions() {
    if (this.sidebar) {
      this.options = new Array();
      this.sortService.sortedListInSplitScreen = this.dataSource;
      for (const element of this.sortService.sortedListInSplitScreen) {
        this.options.push(element);
      }
      this.loaderSpinner = false;
    } else {
      if (
        this.getDistinctValues &&
        this.getDistinctValues(this.choice) &&
        this.choice &&
        !this.allFetchedOptions
      ) {
        this.getDistinctValues(this.choice).subscribe(
          (data) => {
            this.options = data.filter((value) => value);
            this.allFetchedOptions = data.filter((value) => value);
            this.loaderSpinner = false;
          },
          (error) => {
            this.loaderSpinner = false;
          }
        );
      } else {
        this.loaderSpinner = false;
      }
    }
  }

  getValueForSplitScreenFilters(item) {
    return {
      id: item.id,
      name: item.name,
      visibleId: item.visibleId,
    };
  }

  getitem(item) {
    if (item && typeof item !== 'boolean' && isNaN(item)) {
      const itemValue = item.toString().replace(/[_]/g, '-').toUpperCase();
      return itemValue;
    } else if (!isNaN(item)) {
      return item === '0' ? 'FALSE' : item === '1' ? 'TRUE' : item;
    }
  }

  resetSortImage() {
    const element = document.getElementsByClassName('sort-image');
    const array = Array.prototype.slice.call(element);
    for (const item of array) {
      item.setAttribute(
        'src',
        '../../../../assets/fonts/custom-icons/icons/ic_sort.svg'
      );
    }
  }

  isValueApplied(value) {
    return this.appliedFilters.includes(value);
  }
}
