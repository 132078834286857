import { Directive, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { UserService } from '../../services/user/user.service';

export const DATE_FORMAT_MONTH_YEAR = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMAT_MONTH_YEAR_DAY = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Directive({
  selector: '[appDateFormatMonthYear]',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_MONTH_YEAR }],
})
export class CustomDateFormatMonthYearDirective implements OnInit {
  constructor(
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit() {
    this.getDateFormat();
  }

  getDateFormat() {
    let format;
    this.userService.getSettings().subscribe((data) => {
      if (data && data.profile) {
        format = data.profile.dateFormat;
        if (format === 'GERMAN') {
          this.changeDateFormat('de');
          DATE_FORMAT_MONTH_YEAR.display.dateInput = 'MM.YYYY';
          DATE_FORMAT_MONTH_YEAR.parse.dateInput = 'MM.YYYY';
          return 'MM.yyyy';
        }
        if (format === 'BRITISH') {
          this.changeDateFormat('en-GB');
          DATE_FORMAT_MONTH_YEAR.display.dateInput = 'MM/YYYY';
          DATE_FORMAT_MONTH_YEAR.parse.dateInput = 'MM/YYYY';
          return 'MM/yyyy';
        }
        if (format === 'AMERICAN') {
          this.changeDateFormat('en-US');
          DATE_FORMAT_MONTH_YEAR.display.dateInput = 'MM-YYYY';
          DATE_FORMAT_MONTH_YEAR.parse.dateInput = 'MM-YYYY';
          return 'MM-yyyy';
        }
      }
    });
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }
}

@Directive({
  selector: '[appDateFormatMonthYearDay]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_MONTH_YEAR_DAY },
  ],
})
export class CustomDateFormatMonthYearDayDirective implements OnInit {
  constructor(
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit() {
    this.getDateFormat();
  }

  getDateFormat() {
    let format;
    this.userService.getSettings().subscribe((data) => {
      if (data && data.profile) {
        format = data.profile.dateFormat;
        if (format === 'GERMAN') {
          this.changeDateFormat('de');
          DATE_FORMAT_MONTH_YEAR_DAY.display.dateInput = 'DD.MM.YYYY';
          DATE_FORMAT_MONTH_YEAR_DAY.parse.dateInput = 'DD.MM.YYYY';
          return 'DD.MM.yyyy';
        }
        if (format === 'BRITISH') {
          this.changeDateFormat('en-GB');
          DATE_FORMAT_MONTH_YEAR_DAY.display.dateInput = 'DD/MM/YYYY';
          DATE_FORMAT_MONTH_YEAR_DAY.parse.dateInput = 'DD/MM/YYYY';
          return 'DD/MM/yyyy';
        }
        if (format === 'AMERICAN') {
          this.changeDateFormat('en-US');
          DATE_FORMAT_MONTH_YEAR_DAY.display.dateInput = 'MM-DD-YYYY';
          DATE_FORMAT_MONTH_YEAR_DAY.parse.dateInput = 'MM-DD-YYYY';
          return 'MM-DD-yyyy';
        }
      }
    });
  }

  changeDateFormat(dateFormat) {
    this.dateAdapter.setLocale(dateFormat);
  }
}
