import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-quaterly-settlement',
  templateUrl: './dashboard-quaterly-settlement.component.html',
  styleUrls: [
    './dashboard-quaterly-settlement.component.less',
    './dashboard-quaterly-settlement-md.component.less',
  ],
})
export class DashboardQuaterlySettlementComponent {
  constructor(private router: Router) {}

  goToQuaterlySettlement() {
    this.router.navigateByUrl('/quarterlyBilling');
  }
}
