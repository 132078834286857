<div class="content-container document-management-container">
  <app-back-button></app-back-button>

  <div class="filters" [formGroup]="filterForm">
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-document-type">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-TYPE' | translate }}</mat-label>
        <mat-select
          formControlName="documentType"
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-TYPE' | translate }}"
          name="documentType"
          [(ngModel)]="searchDocumentType"
        >
          <mat-option *ngFor="let dt of documentTypes" [value]="dt">
            {{ getDocumentTypeString(dt) | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchDocumentType"
          id="{{ entityName }}-search-input-document-type-clear"
          (click)="searchDocumentType = undefined; resetDocumentTypeFilter(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.QSID' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.QSID' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-qsid"
          formControlName="qsid"
          [(ngModel)]="searchQsId"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.qsid"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VVVO-NR' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VVVO-NR' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-vvvoNumber"
          formControlName="vvvoNumber"
          [(ngModel)]="searchVvvoNr"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.vvvoNumber"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-type-of-production">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.PRODUCTION-TYPE' | translate }}</mat-label>
        <mat-select
          formControlName="typeOfProduction"
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.PRODUCTION-TYPE' | translate }}"
          name="typeOfProduction"
          [(ngModel)]="searchTypeOfProduction"
        >
          <mat-option *ngFor="let tp of typeOfProductions" [value]="tp">
            {{ getTypeOfProductionString(tp) | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchTypeOfProduction"
          id="{{ entityName }}-search-input-type-of-production-clear"
          (click)="searchTypeOfProduction = undefined; resetTypeOfProductFilter(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.ACCOUNT-NUMBER' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.ACCOUNT-NUMBER' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-accountNumber"
          formControlName="accountNumber"
          [(ngModel)]="searchAccountNr"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.accountNumber"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENTATION-ID' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENTATION-ID' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-documentationId"
          formControlName="documentationId"
          [(ngModel)]="searchDocumentationId"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.documentationId"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.CREATION-TIME' | translate }}</mat-label>
        <mat-date-range-input
          [formGroup]="filterForm.controls.creationTime"
          [rangePicker]="datePickerForCreationTimestamp"
          (click)="$event.stopPropagation()"
          id="{{ entityName }}-custom-filter-creation-timestamp-input-start-end"
        >
          <input
            matStartDate
            formControlName="startDate"
            class="begin"
            id="{{ entityName }}-custom-filter-creation-timestamp-input-start"
            placeholder="{{ 'GENERAL.START-DATE' | translate }}"
            [formControl]="filterForm.controls.creationTime.get('startDate')"
          />
          <input
            matEndDate
            formControlName="endDate"
            id="{{ entityName }}-custom-filter-creation-timestamp-input-end"
            placeholder="{{ 'GENERAL.END-DATE' | translate }}"
            [formControl]="filterForm.controls.creationTime.get('endDate')"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="datePickerForCreationTimestamp" class="date-toggle">
        </mat-datepicker-toggle>
        <mat-date-range-picker #datePickerForCreationTimestamp></mat-date-range-picker>
        <mat-icon class="close" (click)="clearCreationTimestampFilter()"> close </mat-icon>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.NAME' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.NAME' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-name"
          formControlName="name"
          [(ngModel)]="searchName"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.name"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field>
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-NAME' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.DOCUMENT-NAME' | translate }}"
          name="name"
          id="{{ entityName }}-search-input-document-name"
          formControlName="documentName"
          [(ngModel)]="searchDocumentName"
          autocomplete="off"
          type="text"
          [removeHtmlTags]="filterForm.controls.documentName"
        />
      </mat-form-field>
    </div>
    <div class="filter-input">
      <mat-form-field id="{{ entityName }}-search-input-type-of-production">
        <mat-label>{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY' | translate }}</mat-label>
        <mat-select
          formControlName="visible"
          placeholder="{{ 'ANIMAL-WELFARE.AW-DOCUMENT-MANAGEMENT.VISIBILITY' | translate }}"
          name="visible"
        >
          <mat-option *ngFor="let option of visibilityOptions" [value]="option.value">
            {{ option.translateKey | translate }}
          </mat-option>
        </mat-select>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="filterForm.get('visible').value !== ''"
          id="{{ entityName }}-search-input-type-of-production-clear"
          (click)="filterForm.get('visible').setValue(''); resetVisibilityFilter(); $event.stopPropagation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="filter-input">
      <button mat-button class="custom-button" (click)="searchDocument()" id="{{ entityName }}-search-button">
        <span class="material-icons"> search </span>
      </button>
    </div>
  </div>

  <app-default-table
    [entityName]="entityName"
    [configurationKey]="configurationKey"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [originalColumns]="columns"
    [originalDisplayedColumns]="displayedColumns"
    [tableDataSource]="documentManagementData"
    [threeDots]="true"
    [listOfActionButtons]="listOfActionButtons"
    (filterEvent)="filter($event)"
    [distinctValues]="getDistinctValues"
  >
  </app-default-table>

  <app-simple-input
  [label]="hiddenDocumentsConfiguration.label"
  [control]="hiddenDocumentsConfiguration.total"
  [type]="hiddenDocumentsConfiguration.type"
  [disabled]="hiddenDocumentsConfiguration.disabled"
  [classes]="hiddenDocumentsConfiguration.classes"
  ></app-simple-input>
</div>
