export const companyColumnsConf: any[] = [
  { name: 'id', index: 1, checked: true, translateName: 'id', locked: true },
  {
    name: 'address',
    index: 2,
    checked: true,
    translateName: 'COMPANY.ADDRESS',
    locked: true,
  },
  {
    name: 'country',
    index: 3,
    checked: true,
    translateName: 'ADDRESS.COUNTRY',
    locked: true,
  },
  {
    name: 'isInternalRecord',
    index: 4,
    checked: true,
    translateName: 'COMPANY.INTERNAL-RECORD',
    locked: true,
  },
  {
    name: 'countryCode',
    index: 5,
    checked: false,
    translateName: 'ADDRESS.COUNTRY-CODE',
    locked: false,
  },
  {
    name: 'language',
    index: 6,
    checked: false,
    translateName: 'COMPANY.LANGUAGE',
    locked: false,
  },
  {
    name: 'timezone',
    index: 7,
    checked: false,
    translateName: 'ADDRESS.TIMEZONE',
    locked: false,
  },
  {
    version: '1.5',
  },
];
