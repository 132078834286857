import { Component, Input, OnInit } from '@angular/core';
import { titlesEnum } from '../../../../../in-memory-data/user-management/enums/title-enum';
import { teamEnum } from '../../../../../in-memory-data/user-management/enums/team-enum';
import { academicTitlesEnum } from '../../../../../in-memory-data/user-management/enums/academic-title-enum';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { contactTypeEnum } from '../../../../../in-memory-data/company/contact-person/enums/contact-types-enums';
import { ServicePinConditionsValidator } from 'src/app/shared/validators/general-validator';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ServicePinPolicyModalComponent } from 'src/app/shared/modals/service-pin-policy-modal/service-pin-policy-modal.component';
import { UserService } from '../../../../../shared/services/user/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-general-user-info',
  templateUrl: './general-user-info.component.html',
  styleUrls: ['./general-user-info.component.less'],
})
export class GeneralUserInfoComponent implements OnInit {
  frmUser;
  entityName = 'user-general-info';
  @Input() isTask: boolean;
  @Input() userTask;
  @Input() approvedValue;
  titles = titlesEnum;
  academicTitles = academicTitlesEnum;
  contactTypes = contactTypeEnum;
  teams = teamEnum;
  state;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.frmUserBuilder();
    this.onUsernameChange();
    this.state = window.history.state;
    if (this.state.viewMode) {
      this.frmUser.disable();
    }
    this.onEmailInput();
  }

  showServicePinPolicy() {
    const dialog = this.matDialogService.open(ServicePinPolicyModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((_) => {
      /**
       * The subscribe is necessary to trigger the Obersable, but the sonarqube don't like
       * to much of empty function. So this comment is to makes the sonarqube happy.
       * The ticket about this is https://jira.bfs-finance.de/browse/VIS25-9345
       */
    });
  }

  frmUserBuilder() {
    this.frmUser = this.formBuilder.group({
      userName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      title: [''],
      academicTitle: [''],
      firstName: [''],
      lastName: ['', Validators.required],
      telephoneNumber: [''],
      faxNumber: [''],
      birthday: [''],
      validTo: [''],
      team: [''],
      companyGuid: [''],
      servicePin: [
        { value: '', disabled: true },
        [ServicePinConditionsValidator()],
      ],
      internalUser: [],
      id: [''],
      keycloakUserId: [{ value: '', disabled: true }],
      createdOn: [{ value: '', disabled: true }],
      createdBy: [{ value: '', disabled: true }],
      updatedOn: [{ value: '', disabled: true }],
      updatedBy: [{ value: '', disabled: true }],
    });
  }

  onUsernameChange(): void {
    this.frmUser.get('userName').valueChanges.subscribe((username) => {
      this.checkUsernameTaken(username);
    });
  }

  onEmailInput(): void {
    this.frmUser.get('email').valueChanges.subscribe((text) => {
      this.frmUser.get('email').setValue(text.toLowerCase(), {emitEvent: false})
    });
  }

  private checkUsernameTaken(username) {
    if (username) {
      this.userService
        .isUsernameTaken(username, false)
        .pipe(take(1))
        .subscribe((isUserNameTaken) => {
          if (isUserNameTaken) {
            this.frmUser.get('userName').setErrors({ usernameTaken: true });
          }
        });
    }
  }
}
