export const settlemenetInEuroOptions: any[] = [
  {
    name: 'NO',
    value: false,
    translateName: 'GENERAL.FALSE',
  },
  {
    name: 'YES',
    value: true,
    translateName: 'GENERAL.TRUE',
  },
];
