import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { quantityLabeledGoodsDraftColumns } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods-draft/table-columns';
import { quantityLabeledGoodsDraftColumnsConf } from 'src/app/in-memory-data/animal-welfare/quantity-labeled-goods-draft/table-columns-configuration';
import { ConfirmationPromptModalComponent } from 'src/app/shared/modals/confirmation-prompt-modal/confirmation-prompt-modal.component';
import { GoodsGroupService } from 'src/app/shared/services/animal-welfare/goods-group/goods-group.service';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-quantity-labeled-goods-draft',
  templateUrl: './quantity-labeled-goods-draft.component.html',
  styleUrls: [
    './quantity-labeled-goods-draft.component.less',
    './quantity-labeled-goods-draft-md.component.less',
  ],
})
export class QuantityLabeledGoodsDraftComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  entityName = 'quantity-of-labeled-goods-drafts';

  periods: any[] = [];

  private CONFIGURAIONT_KEY = 'quantity_labeled_goods_draft_table';
  private ENTITY_TYPE = 'QUANTITY_LABELLED_GOOD';

  columns = quantityLabeledGoodsDraftColumnsConf;
  displayedColumns = quantityLabeledGoodsDraftColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>([]);

  loaderSpinner = false;
  existentGoodsGroups: any;
  dateFormat: any;
  filterForm: UntypedFormGroup;
  searchableDrafts = [];

  constructor(
    private draftService: DraftService,
    private userService: UserService,
    private goodsGroupsService: GoodsGroupService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private matDialogService: MatDialog
  ) {
    this.filterFormBuilder();
  }

  ngOnInit(): void {
    this.dateFormat = this.userService.getDateFormat();
    this.getTableConfiguration();
    this.getGoodGroups();
    this.loadDraftTable();
    this.filterForm.valueChanges.subscribe(() => this.searchDraft());
  }

  filterFormBuilder() {
    this.filterForm = this.formBuilder.group({
      createdBy: null,
      editedBy: null,
      editedFrom: null,
      editedTo: null,
    });
  }

  loadDraftTable() {
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.draftService
      .retrieveLocalDrafts(this.ENTITY_TYPE)
      .subscribe((data) => {
        if (data != null && data.length > 0) {
          data.forEach((draft) => {
            const object = JSON.parse(draft.draftJson);

            draft.quantityLabelledGoods = object;
            draft.draft = true;
            draft.draftId = draft.id;
            draft.draftGuid = draft.guid;
            draft.checkSumQuantity = this.getCheckSumQuantity(draft);
            draft.checkSum = this.getCheckSum(draft);

            this.addObjectToDraftTable(draft);
            this.searchableDrafts.push(draft);
          });
        }
      });
  }

  getGoodGroups() {
    this.goodsGroupsService.findAll().subscribe((data: any) => {
      this.existentGoodsGroups = data;
    });
  }

  addObjectToDraftTable(object) {
    this.dataSource.data.push(object);
    this.dataSource.filter = '';
  }

  getGoodsGroupName(goodGrupId) {
    let result = '';

    if (this.existentGoodsGroups && this.existentGoodsGroups.length > 0) {
      const goodGroup = this.existentGoodsGroups.find(
        (data) => data.id === goodGrupId
      );

      if (goodGroup) {
        if (this.translateService.currentLang === 'en') {
          result = goodGroup.englishName;
        } else {
          result = goodGroup.germanName;
        }
      }
    }

    return result;
  }

  getCheckSumQuantity(element) {
    const arrayQ = element.quantityLabelledGoods;
    let result = 0;
    if (arrayQ.length > 0) {
      arrayQ.forEach((item) => {
        result += item.quantity;
      });
    }

    return result;
  }

  getCheckSum(element) {
    return element.quantityLabelledGoods.length;
  }

  getName(element) {
    let result = '';
    if (element.reportedBy === 1) {
      result = element.suppliedFromName;
    } else {
      result = element.suppliedToName;
    }
    return result;
  }

  confirmationDeleteDraft(draftGuid) {
    const dialog = this.matDialogService.open(ConfirmationPromptModalComponent);
    dialog.componentInstance.messageKey = 'NOTIFICATION.CONFIRM-DELETE-DRAFT';
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.deleteDraft(draftGuid);
      }
    });
  }

  deleteDraft(draftGuid) {
    this.draftService.deleteUserDraft(draftGuid).subscribe((data) => {
      if (data == null) {
        const index = this.dataSource.data.findIndex(
          (row) => row.draftGuid === draftGuid
        );
        this.dataSource.data.splice(index, 1);
        this.dataSource.filter = '';

        this.notificationService.showToast(
          'GENERAL-ENTITY.LIST.MESSAGES.SUCCESS-MESSAGES.DELETED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );

        this.selection = new SelectionModel(true, []);
      }
    });
  }

  selectTheRow(row: any) {
    this.selection.select(row);
  }

  editSelectedRows(element) {
    this.router.navigate(['create'], {
      relativeTo: this.activatedRoute,
      state: { draft: true, draftObject: element },
    });
  }

  searchDraft() {
    this.dataSource.data = this.searchableDrafts.filter((draft) => {
      let add = true;
      if (this.filterForm.controls.createdBy.value) {
        add = draft.createdBy.includes(
          this.filterForm.controls.createdBy.value
        );
      }
      if (this.filterForm.controls.editedBy.value) {
        add = draft.operationUser.includes(
          this.filterForm.controls.editedBy.value
        );
      }
      if (this.filterForm.controls.editedFrom.value) {
        const operationDate = new Date(draft.operationDate);
        add = operationDate >= this.filterForm.controls.editedFrom.value;
      }
      if (this.filterForm.controls.editedTo.value) {
        const operationDate = new Date(draft.operationDate);
        add = operationDate <= this.filterForm.controls.editedTo.value;
      }

      return add;
    });
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURAIONT_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURAIONT_KEY,
                data,
                quantityLabeledGoodsDraftColumnsConf,
                quantityLabeledGoodsDraftColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = quantityLabeledGoodsDraftColumnsConf;
    this.displayedColumns = quantityLabeledGoodsDraftColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURAIONT_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(selected) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
