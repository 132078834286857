<div class="input-wrapper form-container">
  <form class="form" [formGroup]="frmAdditionalInformation" autocomplete="off">
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.userName : null"
      [controlName]="'userName'"
      [controlObject]="frmAdditionalInformation.controls.userName"
      [disabled]="true"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmAdditionalInformation"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [name]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [placeholder]="'USER-MANAGEMENT.KEYCLOAK-USER-ID'"
      [taskValue]="userTask ? userTask.userName : null"
      [titleTask]="false"
    ></app-mat-input>
    <app-mat-input
      [approvedValue]="approvedValue ? approvedValue.email : null"
      [controlName]="'email'"
      [controlObject]="frmAdditionalInformation.controls.email"
      [editableTask]="false"
      [entityName]="entityName"
      [formGroupCustomName]="null"
      [form]="frmAdditionalInformation"
      [isTask]="isTask"
      [label]="'USER-MANAGEMENT.EMAIL'"
      [name]="'USER-MANAGEMENT.EMAIL'"
      [placeholder]="'USER-MANAGEMENT.EMAIL'"
      [taskValue]="userTask ? userTask.email : null"
      [titleTask]="false"
      [type]="'email'"
    ></app-mat-input>
  </form>
  <form class="form" [formGroup]="frmRoleDataVisibility" autocomplete="off">
    <app-mat-select
      [name]="'USER-MANAGEMENT.ORGANISATIONAL-ROLE'"
      [placeholder]="'USER-MANAGEMENT.ORGANISATIONAL-ROLE'"
      [label]="'USER-MANAGEMENT.ORGANISATIONAL-ROLE'"
      [entityName]="entityName"
      [form]="frmRoleDataVisibility"
      [controlObject]="frmRoleDataVisibility.controls.organisationalRole"
      [controlName]="'organisationalRole'"
      [approvedValue]="approvedValue ? approvedValue.organisationalRole : null"
      [taskValue]="userTask ? userTask.organisationalRole : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="organisationalRoles"
      [optionValue]="'role'"
      [selectedOption]="null"
      [optionTranslationText]="''"
      [translationOption]="'role'"
    ></app-mat-select>
    <app-mat-select
      [name]="'USER-MANAGEMENT.FUNCTIONAL-ROLE'"
      [placeholder]="'USER-MANAGEMENT.FUNCTIONAL-ROLE'"
      [label]="'USER-MANAGEMENT.FUNCTIONAL-ROLE'"
      [entityName]="entityName"
      [form]="frmRoleDataVisibility"
      [controlObject]="frmRoleDataVisibility.controls.functionalRole"
      [controlName]="'functionalRole'"
      [approvedValue]="approvedValue ? approvedValue.functionalRole : null"
      [taskValue]="userTask ? userTask.functionalRole : null"
      [titleTask]="false"
      [editableTask]="false"
      [isTask]="isTask"
      [formGroupCustomName]="null"
      [options]="functionalRoles"
      [optionValue]="'role'"
      [selectedOption]="null"
      [optionTranslationText]="''"
      [translationOption]="'role'"
    ></app-mat-select>
    <div *ngIf="!isTask">
      <div class="all-permissions">
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchStringAll"
              (keyup)="searchInputChanged()"
              [disabled]="state.viewMode"
              type="text"
              removeHtmlTags
            />
          </mat-form-field>
          <div
            class="entries"
            infinite-scroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [scrollWindow]="false"
          >
            <span *ngIf="searchRunning">
              <mat-spinner [diameter]="20"></mat-spinner>
            </span>
            <mat-selection-list
              *ngIf="!searchRunning"
              [(ngModel)]="selectedPermissions"
              [ngModelOptions]="{ standalone: true }"
              #selectedGroups
            >
              <mat-list-option
                [disabled]="isSelected(group)"
                [selected]="isSelected(group)"
                [value]="group.id"
                *ngFor="let group of groups"
              >
                {{ group.rlsKey.replaceAll('\|', '').replace('}', '').replace('{', '') }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div class="buttons">
          <button mat-button class="add-button" (click)="addToUser()" [disabled]="state.viewMode">
            {{ 'GENERAL.ADD' | translate }}
          </button>
          <button mat-button class="add-button" (click)="removeFromUser()" [disabled]="state.viewMode">
            {{ 'GENERAL.REMOVE' | translate }}
          </button>
        </div>
        <div class="permissions-wrapper">
          <mat-form-field appearance="fill" class="search-for-entries">
            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'GENERAL.SEARCH' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="searchStringActive"
              (keyup)="searchInputChangedActivePermissions()"
              [disabled]="state.viewMode"
              type="text"
              removeHtmlTags
            />
          </mat-form-field>
          <div class="entries">
            <mat-selection-list
              #selectedGroups
              (selectionChange)="onGroupsChange(selectedGroups.selectedOptions.selected)"
            >
              <mat-list-option *ngFor="let group of renderedPermissionList" [value]="group">
                {{ group.rlsKey.replaceAll('\|', '').replace('}', '').replace('{', '') }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTask && userTask" class="non-editable-info-container">
      <div class="object-row">
        <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
          <div fxFlex="20" fxFlex.sm="30" fxFlex.xs="100">
            <span class="object-label">{{ 'USERMANAGEMENT-DATA-VISIBILITY' | translate }}:</span>
          </div>
          <div fxFlex="80" fxFlex.sm="70" fxFlex.xs="100">
            <div *ngFor="let group of userTask.matchedGroups" class="object-value">
              {{ group.rlsKey }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
