import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  RippleGlobalOptions,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import {
  MatLegacyOptionModule as MatOptionModule,
} from '@angular/material/legacy-core';
import { HttpClientModule } from '@angular/common/http';
import { initializer } from './utils/app-init';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { HomeManagementModule } from './modules/home-management/home-management.module';
import { TaskManagementModule } from './modules/task-management/task-management.module';
import { RiskManagementModule } from './modules/risk-management/risk-management.module';
import { MasterDataManagementModule } from './modules/master-data-management/master-data-management.module';
import { OpenItemManagementModule } from './modules/open-item-management/open-item-management.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  MatLegacyPaginatorIntl as MatPaginatorIntl,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import localeEnUs from '@angular/common/locales/en';
import localEnUsExtra from '@angular/common/locales/extra/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import {
  CurrencyPipe,
  registerLocaleData,
  CommonModule,
} from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TaskConfigurationModule } from './modules/task-configuration/task-configuration.module';
import { UserProfileManagementModule } from './modules/user-profile-management/user-profile-management.module';
import { EmailReceivingMockComponent } from './email-receiving-mock/email-receiving-mock.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { BackButtonModule } from './shared/dialogs/back-button/back-button.module';
import { InMemoryDataModule } from './in-memory-data/in-memory-data.module';
import { FormElementsModule } from './modules/form-elements/form-elements.module';
import { SalesEntryModule } from './modules/sales-entry/sales-entry.module';
import { SystemConfigurationManagementModule } from './modules/system-configuration-management/system-configuration-management.module';
import { ReportingModule } from './modules/reporting/reporting.module';
import { NonGermanyReferenceAllowModule } from './modules/non-germany-reference-allow/non-germany-reference-allow.module';
import { BillingSlaughterhouseManagementModule } from './modules/billing-slaughterhouse-management/billing-slaughterhouse-management.module';
import { AccountingManagementModule } from './modules/accounting-management/accounting-management.module';
import { DateValidator } from './shared/validators/date-validator';
import { VVVONrValidator } from './modules/animal-welfare-management/transfer-quantity-goods/validator/general-validator';
import { GeneralValidator } from './shared/validators/general-validator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ControlBoardManagementModule } from './modules/animal-welfare-management/control-board-management/control-board-management.module';
import { BusinessUnitModule } from './modules/business-unit/business-unit.module';
import { AnimalWelfareManagementModule } from './modules/animal-welfare-management/animal-welfare-management.module';
import { DashboardManagementModule } from './modules/dashboard-management/dashboard-management.module';
import { TranslationHttpLoader } from './translation-http-loader';
import { OpenTransactionBookingManagementModule } from './modules/open-transaction-booking-management/open-transaction-booking-management.module';
import { ExportingPrintingTaskComponent } from './modules/exporting-printing-task/exporting-printing-task.component';
import { PasswordValidator } from './shared/validators/password-validator';
import { DashboardFaqComponent } from './modules/dashboard-management/dashboard/dashboard-faq/dashboard-faq.component';
import { WebsiteCreditsComponent } from './modules/website-credits/website-credits.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './modules/terms-of-service/terms-of-service.component';
import { WebsiteCreditsEnComponent } from './modules/website-credits/components/website-credits-en/website-credits-en.component';
import { WebsiteCreditsDeComponent } from './modules/website-credits/components/website-credits-de/website-credits-de.component';
import { PrivacyPolicyEnComponent } from './modules/privacy-policy/components/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyDeComponent } from './modules/privacy-policy/components/privacy-policy-de/privacy-policy-de.component';
import { TermsOfServiceDeComponent } from './modules/terms-of-service/components/terms-of-service-de/terms-of-service-de.component';
import { TermsOfServiceEnComponent } from './modules/terms-of-service/components/terms-of-service-en/terms-of-service-en.component';
import { ApprovalTaskInterceptor } from './shared/interceptors/task-interceptor.interceptor';
import { HttpHeadersInterceptor } from './shared/interceptors/http-headers.interceptor';
import { ErrorHandlerInterceptor } from './shared/interceptors/custom-error-handler/custom-error-handler.interceptor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CustomMatPaginatorIntl } from './shared/CustomMatPaginatorIntl/custom-mat-paginator-intl';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

registerLocaleData(localeEnGb, 'en-GB', localeEnGbExtra);
registerLocaleData(localeEnUs, 'en-US', localEnUsExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);

@NgModule({
    declarations: [
        AppComponent,
        EmailReceivingMockComponent,
        ExportingPrintingTaskComponent,
        DashboardFaqComponent,
        WebsiteCreditsComponent,
        PrivacyPolicyComponent,
        TermsOfServiceComponent,
        WebsiteCreditsEnComponent,
        WebsiteCreditsDeComponent,
        PrivacyPolicyEnComponent,
        PrivacyPolicyDeComponent,
        TermsOfServiceDeComponent,
        TermsOfServiceEnComponent,
    ],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        KeycloakAngularModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatSortModule,
        MatTableModule,
        MatSlideToggleModule,
        MatGridListModule,
        HomeManagementModule,
        TaskManagementModule,
        TaskConfigurationModule,
        RiskManagementModule,
        MasterDataManagementModule,
        OpenItemManagementModule,
        MatMenuModule,
        TranslateModule,
        MatSelectModule,
        MatOptionModule,
        FlexLayoutModule,
        UserProfileManagementModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatCheckboxModule,
        SharedModule,
        BackButtonModule,
        MatInputModule,
        InMemoryDataModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSliderModule,
        MatStepperModule,
        MatTooltipModule,
        MatDividerModule,
        FormElementsModule,
        SalesEntryModule,
        SystemConfigurationManagementModule,
        ReportingModule,
        NonGermanyReferenceAllowModule,
        BillingSlaughterhouseManagementModule,
        AccountingManagementModule,
        CommonModule,
        AnimalWelfareManagementModule,
        BusinessUnitModule,
        NgSelectModule,
        CurrencyMaskModule,
        DragDropModule,
        NgxMaskModule,
        NgxMatSelectSearchModule,
        ControlBoardManagementModule,
        DashboardManagementModule,
        OpenTransactionBookingManagementModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        CurrencyPipe,
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService],
        },
        DateValidator,
        VVVONrValidator,
        GeneralValidator,
        PasswordValidator,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApprovalTaskInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHeadersInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslationHttpLoader(httpClient);
}
