export const quantityGoodsDraftColumnsConf: any[] = [
  {
    name: 'createdBy',
    index: 0,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.DRAFTS.CREATED-BY',
    locked: true,
  },
  {
    name: 'lastEditedOn',
    index: 1,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.DRAFTS.LAST-EDITED-ON',
    locked: true,
  },
  {
    name: 'lastEditedBy',
    index: 2,
    checked: true,
    translateName: 'ANIMAL-WELFARE.QUANTITY-GOODS.DRAFTS.LAST-EDITED-BY',
    locked: true,
  },

  {
    version: '1',
  },
];
