<div class="header">
  <div class="header-form">
    <div class="form-container">
      <form [formGroup]="openItemsHeaderForm" autocomplete="off">
        <div class="form-row">
          <mat-form-field id="{{ entityName }}-form-clientId" appearance="fill">
            <mat-label>{{ 'OPEN-TASK.HEADER.CLIENT-ID' | translate | titlecase }}</mat-label>
            <mat-select
              #select
              formControlName="clientId"
              required
              placeholder="{{ 'OPEN-TASK.HEADER.CLIENT-ID' | translate | titlecase }}"
              id="{{ entityName }}-form-clientId-select"
            >
              <mat-option
                *ngFor="let item of clients"
                [value]="item.id"
                id="{{ entityName }}-form-clientId-selectOption-{{ item.id }}"
              >
                {{ item.visibleId }}
              </mat-option>
            </mat-select>
            <mat-error
              id="{{ entityName }}-form-clientId-{{ errorRequired }}"
              class="input-error"
              *ngIf="openItemsHeaderForm.controls.clientId.hasError('required')"
            >
              {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: select.placeholder | translate } | titlecase }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'OPEN-TASK.HEADER.BUSINESS-UNIT' | translate }}</mat-label>
            <input
              autocomplete="off"
              required
              #businessUnitId
              matInput
              placeholder="{{ 'OPEN-TASK.HEADER.BUSINESS-UNIT' | translate }}"
              name="{{ 'OPEN-TASK.HEADER.BUSINESS-UNIT' | translate }}"
              formControlName="businessUnitId"
              id="{{ entityName }}-form-businessUnit"
            />
            <mat-error
              id="{{ entityName }}-form-name-{{ errorRequired }}"
              class="input-error"
              *ngIf="openItemsHeaderForm.controls.businessUnitId.hasError('required')"
            >
              {{ 'ERROR-MESSAGES.REQUIRED' | translate: { name: businessUnitId.name | translate } }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
