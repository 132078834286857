import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from '../../models/dialog-data';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-add-deduction-modal',
  templateUrl: './add-deduction-modal.component.html',
  styleUrls: ['./add-deduction-modal.component.less'],
})
export class AddDeductionModalComponent implements OnInit {
  form: UntypedFormGroup;
  deductionResonKey: any;
  deductionReference: any;
  deductionPercent: any;
  deductionValidityDate: any;
  showTabContent: boolean;
  dateFormat = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<AddDeductionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = this.formBuilder.group({
      deductionResonKey: ['', Validators.required],
      deductionReference: ['', Validators.required],
      deductionPercent: ['', Validators.required],
      deductionValidityDate: ['', Validators.required],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);
  }

  saveDeductionAndCloseDialog() {
    const newDeduction = {
      deductionResonKey: this.deductionResonKey,
      deductionReference: this.deductionReference,
      deductionPercent: this.deductionPercent,
      deductionValidityDate: this.deductionValidityDate,
    };

    this.dialogRef.close({ data: newDeduction });
  }

  reloadTabs() {
    this.showTabContent = false;
    setTimeout(() => (this.showTabContent = true), 5000);
  }

  removeDeduction(index) {
    this.data.deductions.splice(index, 1);
  }
}
