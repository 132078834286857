import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BusinessUnitService } from './services/business-unit/business-unit.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientGroupDisbursementService {
  private serviceUrl;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.serviceUrl = environment.settings.openItemService;
  }

  // Client group
  findClientGroupDisbursementsByTaskId(taskId: number): Observable<any> {
    return this.http.get(
      this.serviceUrl + 'tasks/list/' + taskId,
      this.buildHeaders()
    );
  }

  findByClientGroup(id): Observable<any> {
    return this.http.get(this.serviceUrl + 'disbursements/for-group', {
      params: {
        clientGroup: id,
      },
      headers: this.buildHeaders().headers,
    });
  }

  createDisbursement(taskId: number, data: any): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskId + '/complete-task-37',
      data,
      this.buildHeaders()
    );
  }

  approveDisbursement(taskId: number, data: any): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + taskId + '/complete-task-38',
      data,
      this.buildHeaders()
    );
  }
  lockDisbursement(id: number): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'disbursements/' + id + '/lock',
      this.buildHeaders()
    );
  }

  unlockDisbursement(id: number): Observable<any> {
    return this.http.delete(
      this.serviceUrl + 'disbursements/' + id + '/lock',
      this.buildHeaders()
    );
  }

  approveTask(id, comment): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + id + '/complete-task-38',
      comment,
      this.buildHeaders()
    );
  }

  rejectTask(id: number, comment: string): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'tasks/' + id + '/reject-task-38',
      { comment },
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json')
        .append(
          'Business-Unit-Id',
          this.businessUnits.getCurrentBusinessUnit()
        ),
    };
  }
}
