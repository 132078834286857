import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskConfigurationService } from '../../../shared/services/task-configuration/task-configuration.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { taskConfigColumnsConf } from '../../../in-memory-data/task-configuration/table-columns-configuration';
import { taskConfigColumns } from '../../../in-memory-data/task-configuration/table-columns';
import { Observable } from 'rxjs';
import { TaskConfiguration } from '../../../shared/models/task-configuration';
import {
  Role,
  TaskType,
} from '../../task-management/task-filter/task-filter.component';
import { isPresentInInput } from '../../../utils/object-util';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { teamEnum } from '../../../in-memory-data/user-management/enums/team-enum';

@Component({
  selector: 'app-task-configuration-list',
  templateUrl: './task-configuration-list.component.html',
  styleUrls: ['./task-configuration-list.component.less'],
})
export class TaskConfigurationListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  taskConfigList: TaskConfiguration[] = [];
  dataSource = new MatTableDataSource<any>(this.taskConfigList);
  columns = taskConfigColumnsConf;
  displayedColumns = taskConfigColumns;
  displayedColumnsTemp = [];
  columnsTemp = [];
  roles: Observable<Role[]>;
  teams = teamEnum;
  taskTypes: Observable<TaskType[]>;
  selectedRoles: any;
  selectedTeams: any;
  selectedTaskTypes: any;
  entityName = 'task-configuration';
  searchInput: any;
  object: any;
  objectId: any;
  filter: any;
  private CONFIGURATION_KEY = 'task_configuration_table';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private taskConfigurationService: TaskConfigurationService,
    private notificationService: NotificationService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.filter = this.formBuilder.group({
      searchString: ['', []],
      taskType: ['', []],
      objectId: ['', []],
      object: ['', []],
      team: ['', []],
      role: ['', []],
    });
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };
    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                taskConfigColumnsConf,
                taskConfigColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.showErrorMessage(err);
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = taskConfigColumnsConf;
    this.displayedColumns = taskConfigColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  ngOnInit() {
    this.notificationService.dismissMessage();
    this.getTableConfiguration();
    this.taskTypes = this.getTaskTypes();
    this.roles = this.getRoles();
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.retrieveAll();
  }

  getTaskTypes(): Observable<any[]> {
    return this.taskConfigurationService
      .getTaskTypes()
      .pipe(
        map((results) =>
          results.sort((a, b) =>
            this.translate.instant(a) > this.translate.instant(b) ? 1 : -1
          )
        )
      );
  }

  getRoles(): Observable<Role[]> {
    return this.userService
      .listRoles()
      .pipe(
        map((results) => results.sort((a, b) => (a.role > b.role ? 1 : -1)))
      );
  }

  resetTeam() {
    this.filter.value.team = [];
    this.filter.get('team').patchValue([]);
    this.search();
  }

  resetRole() {
    this.filter.value.role = [];
    this.filter.get('role').patchValue([]);
    this.search();
  }

  resetTaskType() {
    this.filter.value.taskType = [];
    this.filter.get('taskType').patchValue([]);
    this.search();
  }

  clickOnRow(taskConfiguration: TaskConfiguration) {
    this.router.navigate([
      '/systemConfiguration/taskConfigurations/',
      taskConfiguration.id,
    ]);
  }

  retrieveAll() {
    this.taskConfigurationService.retrieveAll().subscribe(
      (data) => {
        this.taskConfigList = data.content;
        this.taskConfigList.sort((a, b) => (a.id > b.id ? 1 : -1));
        this.showErrorMessage(data.content);
        this.dataSource.data = this.taskConfigList;
      },
      (error) => this.handleError(error)
    );
  }

  search() {
    this.notificationService.dismissMessage();
    this.taskConfigurationService.retrieveAll().subscribe(
      (allData) => {
        this.taskConfigList = allData.content;
        this.taskConfigList.sort((a, b) => (a.id > b.id ? 1 : -1));
        if (!this.taskConfigList) {
          return;
        }
        this.searchInput = this.searchInput ? this.searchInput : '';
        const filteredList: TaskConfiguration[] = this.taskConfigList.filter(
          (data) =>
            ((data.id &&
              isPresentInInput(data.id.toString(), this.searchInput)) ||
              (data.priority &&
                isPresentInInput(data.priority.toString(), this.searchInput)) ||
              (data.object &&
                isPresentInInput(data.object.toString(), this.searchInput))) &&
            (this.selectedRoles && this.selectedRoles.length !== 0
              ? this.selectedRoles.some((role) => data.roleId === role)
              : true) &&
            (this.selectedTaskTypes && this.selectedTaskTypes.length !== 0
              ? this.selectedTaskTypes.some((type) => data.type === type)
              : true) &&
            (this.selectedTeams && this.selectedTeams.length !== 0
              ? this.selectedTeams.some((team) => data.teamId === team)
              : true) &&
            (data.objectId && this.objectId
              ? isPresentInInput(data.objectId, this.objectId)
              : true) &&
            (data.object && this.object
              ? isPresentInInput(data.object, this.object)
              : true)
        );
        this.showErrorMessage(filteredList);
        this.dataSource.data = filteredList;
      },
      (error) => this.handleError(error)
    );
  }

  handleError(error) {
    this.notificationService.showToast(
      'ERROR-MESSAGES.ERROR-BACKEND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      {
        name: error.error.errorId ? error.error.errorId : 'unknown',
        error: error.name,
      }
    );
  }

  showErrorMessage(data) {
    if (data.length === 0) {
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    }
  }

  create() {
    this.router.navigateByUrl('systemConfiguration/taskConfigurations/create');
  }
}
