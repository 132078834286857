import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaskConfiguration } from '../../models/task-configuration';
import { handleError } from '../../../utils/error-util';
import { TaskConfigurationTypeRequest } from '../../models/task-configuration-type-request';

@Injectable({
  providedIn: 'root',
})
export class TaskConfigurationService {
  private serviceUrl;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.settings.taskConfigurationService;
  }

  @Output() taskConfigurationList: EventEmitter<any> = new EventEmitter();
  @Output() allTaskConfigurationEmitter: EventEmitter<any> = new EventEmitter();
  allTaskConfiguration: TaskConfiguration[];

  getTaskConfiguration(taskConfigurationId: any) {
    return this.http.get(
      this.serviceUrl + 'task-configurations/' + taskConfigurationId,
      this.buildHeaders()
    );
  }

  filterTaskConfig(filters: any) {
    if (!this.allTaskConfiguration) {
      return;
    }
    const newTasks = this.allTaskConfiguration.filter((taskConfiguration) => {
      if (!this.applyCustomFilters(filters.customFilters, taskConfiguration)) {
        return false;
      }
      if (
        filters.role &&
        filters.role.length !== 0 &&
        filters.role.every((role) => taskConfiguration.roleId !== role)
      ) {
        return false;
      }
      if (
        filters.team &&
        filters.team.length !== 0 &&
        filters.team.every((role) => taskConfiguration.teamId !== role)
      ) {
        return false;
      }
      if (
        filters.taskType &&
        filters.taskType.length !== 0 &&
        filters.taskType.every(
          (taskType) => taskConfiguration.type !== taskType
        )
      ) {
        return false;
      }
      return true;
    });

    this.taskConfigurationList.emit({ content: newTasks });
  }

  applyCustomFilters(filters: any, task): boolean {
    if (filters) {
      for (const filter of filters) {
        if (filter.type === 'date') {
          if (!this.filterDate(filter, task)) {
            return false;
          }
          continue;
        }
        let match = false;
        if (!filter.inputForm.value) {
          continue;
        }
        if (filter.inputForm.value.length === 0) {
          continue;
        }
        if (!task[filter.columnProp]) {
          return false;
        }

        for (const value of filter.inputForm.value) {
          if (
            value.toString().toUpperCase() ===
            task[filter.columnProp].toString().toUpperCase()
          ) {
            match = true;
            break;
          }
        }
        if (!match) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  filterDate(filter: any, task: any): boolean {
    if (filter.inputForm.value) {
      const startDate = filter.inputForm.value.begin;
      const endDate = filter.inputForm.value.end;
      const creationDate = new Date(task.creationDate);
      startDate.setHours(0, 0, 0);
      endDate.setHours(0, 0, 0);
      creationDate.setHours(0, 0, 0);

      startDate.setMilliseconds(0);
      endDate.setMilliseconds(0);
      creationDate.setMilliseconds(0);

      if (
        (creationDate.getTime() === endDate.getTime()) ===
        startDate.getTime()
      ) {
        return true;
      }
      if (creationDate > endDate || creationDate < startDate) {
        return false;
      }
      return true;
    }
    return true;
  }

  getTaskConfigurationList(pageSize: number, page: number) {
    this.http
      .get(
        this.serviceUrl +
          'task-configurations?pageSize=' +
          pageSize +
          '&page=' +
          page,
        this.buildHeaders()
      )
      .subscribe(
        (data: TaskConfiguration[]) => {
          this.allTaskConfiguration = data;
          this.allTaskConfigurationEmitter.emit(this.allTaskConfiguration);
        },
        (error) => handleError(error)
      );
  }

  retrieveAll(): Observable<any> {
    return this.retrieve(9999, 1);
  }

  retrieve(pageSize: number, page: number): Observable<any> {
    return this.http.get(
      this.serviceUrl +
        'task-configurations?pageSize=' +
        pageSize +
        '&page=' +
        page,
      this.buildHeaders()
    );
  }

  save(request: TaskConfiguration): Observable<any> {
    return this.http.post(
      this.serviceUrl + 'task-configurations',
      request,
      this.buildHeaders()
    );
  }

  update(request: TaskConfiguration): Observable<any> {
    return this.http.put(
      this.serviceUrl + 'task-configurations',
      request,
      this.buildHeaders()
    );
  }

  retrieveByType(
    request: TaskConfigurationTypeRequest
  ): Observable<TaskConfiguration> {
    return this.http.get<TaskConfiguration>(
      this.serviceUrl +
        'task-configurations/findByObjectAndType?type=' +
        request.type,
      this.buildHeaders()
    );
  }

  getTaskTypes(): Observable<any[]> {
    return this.http.get<any[]>(
      this.serviceUrl + 'task-types',
      this.buildHeaders()
    );
  }

  private buildHeaders() {
    return {
      headers: new HttpHeaders()
        .append('Content-type', 'application/json')
        .append('Accept', 'application/json'),
    };
  }
}
