import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-general-info-modal',
  templateUrl: './general-info-modal.component.html',
  styleUrls: ['./general-info-modal.component.less'],
})
export class GeneralInfoModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GeneralInfoModalComponent>
  ) {}
}
