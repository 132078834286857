<app-back-button *ngIf="!isTask" [address]="'userManagement/permission'"> </app-back-button>
<app-back-button *ngIf="isTask"> </app-back-button>

<div *ngIf="editMode || isTask" class="tab-container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.PERMISSION-CONFIGURATION' | translate }}
        </div>
      </ng-template>
      <app-permission-configuration></app-permission-configuration>
    </mat-tab>
    <mat-tab *ngIf="!isTask">
      <ng-template mat-tab-label>
        <div>
          {{ 'USER-MANAGEMENT.PERMISSION-KNOWN-ROLES' | translate }}
        </div>
      </ng-template>
      <app-permission-known-roles></app-permission-known-roles>
    </mat-tab>
  </mat-tab-group>
  <div class="save-container">
    <span *ngIf="PermissionConfigurationComponent">
      <button
        (click)="savePermission()"
        *ngIf="editMode"
        [disabled]="PermissionConfigurationComponent.frmPermission.invalid || state.viewMode"
        class="custom-button save"
        mat-button
      >
        {{ 'GENERAL.SAVE' | translate }}
      </button>
    </span>
  </div>
</div>

<div *ngIf="!editMode" class="stepper-container">
  <app-permission-configuration *ngIf="realm" [realm]="realm"></app-permission-configuration>
</div>
