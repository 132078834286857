import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonModule } from 'src/app/shared/dialogs/back-button/back-button.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { AccountingRelationshipListComponent } from './relationship/relationship-list.component';
import { AccountingPartnerListComponent } from './partner/partner-list.component';
import { AccountingMasterDataListComponent } from './master-data/master-data-list.component';
import { AccountingLedgerAccountListComponent } from './ledger-account/ledger-account-list.component';
import { AccountingManagementDashboardComponent } from './accounting-management-dashboard/accounting-management-dashboard.component';
import { AccountingOverviewComponent } from './accounting-overview/accounting-overview.component';
import { AccountingKPIOverviewComponent } from './accounting-kpi-overview/accounting-kpi-overview.component';
import { AccountingTransactionListComponent } from './accounting-transactions/accounting-transaction-list.component';
import { AccountingOverviewFunctionalButtonsComponent } from './accounting-overview-functional-buttons/accounting-overview-functional-buttons.component';
import { AccountingAllocationDraftComponent } from './accounting-allocation-draft/accounting-allocation-draft.component';
import { AccountingTransactionListExpandedComponent } from './accounting-transactions-expanded/accounting-transaction-list-expanded.component';
import { InfoContainerComponent } from './accounting-kpi-overview/info-container/info-container.component';
import { FinancialContainerComponent } from './accounting-kpi-overview/financial-container/financial-container.component';
import { AccountPeriodFilterComponent } from './account-period-filter/period-filter.component';
import {
  DebtorAccountPaymentAllocationComponent
} from './debtor-account-payment-allocation/debtor-account-payment-allocation.component';

@NgModule({
    declarations: [
        AccountingManagementDashboardComponent,
        AccountingRelationshipListComponent,
        AccountingPartnerListComponent,
        AccountingMasterDataListComponent,
        AccountingLedgerAccountListComponent,
        AccountingOverviewComponent,
        AccountingKPIOverviewComponent,
        AccountingTransactionListComponent,
        AccountingTransactionListExpandedComponent,
        AccountingOverviewFunctionalButtonsComponent,
        AccountingAllocationDraftComponent,
        InfoContainerComponent,
        FinancialContainerComponent,
        AccountPeriodFilterComponent,
        DebtorAccountPaymentAllocationComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatDialogModule,
        MatPaginatorModule,
        MatButtonModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        MatMenuModule,
        MatIconModule,
        MatCheckboxModule,
        NgSelectModule,
        BackButtonModule,
        MatTabsModule,
        MatDatepickerModule,
        DragDropModule,
        SharedModule,
        MatAutocompleteModule,
        FlexLayoutModule,
        FormElementsModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatExpansionModule,
        MatStepperModule,
        FormElementsModule,
    ],
    exports: []
})
export class AccountingManagementModule {}
