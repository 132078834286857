import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BookingCodeService } from 'src/app/shared/services/booking-code/booking-code.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DraftService } from 'src/app/shared/services/draft/draft.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DirtyComponent } from 'src/app/shared/models/dirtyComponent';
import { EntityType } from '../../../shared/models/entity-type';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorHandler } from 'src/app/shared/utils/error-handler/custom-error-handler';
import { CustomSnackBarComponent } from 'src/app/shared/services/notification/custom-snack-bar/custom-snack-bar.component';
import { isEmpty } from 'src/app/utils/object-util';
import { BookingCodeGeneralInformationComponent } from '../booking-code-general-information/booking-code-general-information.component';
import { GenericUtilsService } from 'src/app/shared/services/utils/generic-utils.service';

@Component({
  selector: 'app-booking-code-create',
  templateUrl: './booking-code-create.component.html',
  styleUrls: ['./booking-code-create.component.less'],
})
export class BookingCodeCreateComponent
  implements OnInit, DirtyComponent, AfterContentChecked, OnDestroy
{
  @ViewChild(BookingCodeGeneralInformationComponent)
  bookingCodeGeneralInformationComponent: BookingCodeGeneralInformationComponent;
  taskView = false;
  bookingCodeApproved: any;
  bookingCodeTask: any;
  editMode = false;
  alreadyDraft: boolean;
  draftId: any;
  draftGuid: any;
  settlementRelevant;
  entityName = 'booking-code';
  errorRequired = 'error-message-required';
  editableTask: boolean;
  isTask = false;
  approval = false;
  bookingCodeInitial: any;
  localEntityDraft;
  operationUser;
  snapshot;
  entitiesLoaded = false;
  isDraftExists: boolean;
  state;

  constructor(
    private bookingCodeService: BookingCodeService,
    private notificationService: NotificationService,
    private matDialogService: MatDialog,
    private router: Router,
    private draftService: DraftService,
    private taskService: TaskService,
    private customErrorHandler: CustomErrorHandler,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private cdref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private genericUtils: GenericUtilsService
  ) {}

  ngOnInit(): void {
    this.snapshot = this.activatedRoute.snapshot;
    this.state = window.history.state;
    this.initView();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('taskModify');
  }

  isModify() {
    if (sessionStorage.getItem('taskModify') === 'true') {
      return true;
    }
  }

  initView() {
    if (this.state.task) {
      this.loadTaskData();
    } else if (this.state.booking) {
      this.retrieveTransactionCodeByState();
    } else {
      this.retrieveTransactionByGuid();
    }

    if (this.state.draftId) {
      this.draftId = this.state.draftId;
    }
    if (this.state.draftGuid) {
      this.draftGuid = this.state.draftGuid;
    }
  }

  loadTaskData() {
    this.isTask = true;
    this.taskView = true;
    this.bookingCodeService
      .retrieveByTaskId(this.state.task.id)
      .subscribe((data) => {
        this.setBookingTask(data);
        this.bookingCodeService.retrieveApproved(data.id).subscribe(
          (bookingCode) => {
            this.editMode = true;
            this.bookingCodeApproved = bookingCode;
          },
          (err) => {
            this.editMode = false;
            this.entitiesLoaded = true;
          }
        );
        this.retrieveInitialDraft(data.id);
      });

    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.taskService.updateEditableTask(true);
    }
  }

  retrieveTransactionCodeByState() {
    if (this.state.booking.draft) {
      this.alreadyDraft = true;
      this.operationUser = this.state.booking.operationUser;
      if (this.state.booking.id && this.state.booking.guid) {
        this.validateDraftExistence(
          this.state.booking.id,
          this.state.booking.guid
        );
        this.bookingCodeService
          .retrieveApproved(this.state.booking.id)
          .subscribe(
            (data) => {
              this.bookingCodeApproved = data;
            },
            (err) => {
              this.entitiesLoaded = true;
            }
          );
      } else {
        this.entitiesLoaded = true;
      }
    } else {
      this.bookingCodeService
        .retrieveApproved(this.state.booking.id)
        .subscribe((data) => {
          this.editMode = true;
          this.bookingCodeApproved = data;
          this.validateDraftExistence(data.id, data['guid']);
          this.draftService
            .retrieveLocalEntityDraft(data['guid'])
            .subscribe((draft) => {
              this.updateLocalEntityDraft(draft);
            });
          this.retrieveInitialDraft(data.id);
        });
    }
  }

  retrieveTransactionByGuid() {
    if (this.snapshot.params.guid) {
      this.bookingCodeService
        .retrieveApprovedByGuid(this.snapshot.params.guid)
        .subscribe(
          (data) => {
            this.bookingCodeApproved = data;
            this.entitiesLoaded = true;
            this.editMode = true;
            this.validateDraftExistence(data.id, data['guid']);
            this.draftService
              .retrieveLocalEntityDraft(data['guid'])
              .subscribe((draft) => {
                this.updateLocalEntityDraft(draft);
              });
            this.retrieveInitialDraft(data.id);
          },
          (error) => {
            if (error.error) {
              this.customErrorHandler.handleError(error.error, null);
              this.entitiesLoaded = true;
            }
          }
        );
    } else {
      this.entitiesLoaded = true;
    }
  }

  retrieveInitialDraft(id) {
    this.draftService
      .retrieveInitialEntityDraft(EntityType.BOOKING_CODE, id)
      .subscribe(
        (data) => {
          this.bookingCodeInitial = JSON.parse(data.draftJson);
        },
        (err) => console.log('ERROR FINDING INITIAL DRAFT', err)
      );
  }

  validateDraftExistence(id, guid) {
    this.draftService
      .validateDraftExistence(EntityType.BOOKING_CODE, id, guid)
      .subscribe(
        (data) => {
          this.isDraftExists = data;
          if (this.isDraftExists) {
            this.notificationService.showToastMessage(
              'ERROR.APPROVAL-TASK-ALREADY-EXISTS',
              this.notificationService.MESSAGE_TYPE.INFO
            );
          }
        },
        (err) => console.log('ERROR VALIDATING DRAFT', err)
      );
  }

  redirectToUserDraft() {
    const bookingCode = JSON.parse(this.localEntityDraft.draftJson);
    bookingCode.draft = true;
    bookingCode.draftId = this.localEntityDraft.guid;
    bookingCode.operationUser = this.localEntityDraft.operationUser;

    const state = { booking: bookingCode };
    state['draftGuid'] = this.localEntityDraft.guid;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['systemConfiguration/booking/create'], {
      state,
    });
  }

  canDeactivate() {
    if (
      this.bookingCodeGeneralInformationComponent &&
      this.bookingCodeGeneralInformationComponent.bookingCodeForm
    ) {
      return (
        this.bookingCodeGeneralInformationComponent.bookingCodeForm.dirty &&
        !this.approval
      );
    } else {
      return false;
    }
  }

  setBookingTask(booking) {
    this.bookingCodeTask = booking;
  }

  changeEditable(value: boolean) {
    const state = window.history.state;
    if (sessionStorage.getItem('taskModify') === 'true') {
      this.router.navigateByUrl('/tasks/' + state.task.id, {
        state,
      });
    } else {
      this.taskService.updateEditableTask(value);
    }
  }

  onClickSave() {
    const dialog = this.matDialogService.open(ConfirmationModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'save') {
        this.approval = true;
        if (this.draftGuid) {
          this.draftService.deleteUserDraft(this.draftGuid).subscribe();
        }
        if (this.taskView) {
          this.bookingCodeGeneralInformationComponent.bookingCodeForm
            .get('approvalTaskId')
            .patchValue(this.bookingCodeTask.id);
          this.updateTask(result.comment);
        } else {
          if (this.editMode) {
            this.updateBookingCode(result.comment);
          } else {
            this.saveBookingCode(result.comment);
          }
        }
      }
    });
  }

  updateTask(comment: string) {
    const json = {
      comment: comment,
      bookingCode:
        this.bookingCodeGeneralInformationComponent.bookingCodeForm.getRawValue(),
      taskId: this.bookingCodeTask.approvalTaskId,
    };
    this.bookingCodeService.updateDraft(json).subscribe((data) => {
      this.successHandler();
      setTimeout(() => {
        this.router.navigateByUrl('/tasks', {
          state: { success: true },
        });
      }, 3000);
    }, this.customErrorHandler._errorHandler('MASTER-DATA.BOOKING-CODE'));
  }

  saveBookingCode(comment: string) {
    const json = {
      comment: comment,
      bookingCode:
        this.bookingCodeGeneralInformationComponent.bookingCodeForm.getRawValue(),
      draftId: this.draftGuid,
    };
    this.bookingCodeService.create(json).subscribe((data) => {
      this.successHandler();
      this.redirectAfterTimeout(3000);
    }, this.customErrorHandler._errorHandler('MASTER-DATA.BOOKING-CODE'));
  }

  updateBookingCode(comment: string) {
    const json = {
      comment: comment,
      bookingCode:
        this.bookingCodeGeneralInformationComponent.bookingCodeForm.getRawValue(),
      draftId: this.draftGuid,
    };
    this.bookingCodeService.update(json).subscribe(
      (data) => {
        this.successHandler();
        this.redirectAfterTimeout(3000);
      },
      (error) => {
        // TODO: Should be moved to this.customErrorHandler.handleError after live
        // for now it will affect a lot of places because into method passed error.error
        if (error.status === 409 && error.error && error.error.translationKey) {
          this.notificationService.showToast(
            error.error.translationKey,
            this.notificationService.MESSAGE_TYPE.ERROR,
            { data: this.translateService.instant('ENTITIES.COMPANY') }
          );
          this.redirectAfterTimeout(3000);
        } else {
          this.customErrorHandler._errorHandler('MASTER-DATA.BOOKING-CODE');
        }
      }
    );
  }

  promptBeforeDiscardingChanges(): boolean {
    return this.bookingCodeGeneralInformationComponent.bookingCodeForm.dirty;
  }

  saveAsDraft() {
    this.approval = true;
    const draftGuid = this.draftGuid;
    const id =
      this.bookingCodeGeneralInformationComponent.bookingCodeForm.controls.id
        .value;
    const element =
      this.bookingCodeGeneralInformationComponent.bookingCodeForm.value;
    element.id = id;
    const request = {
      element,
      entityType: 'BOOKING_CODE',
      id: draftGuid,
      entityId: element.id,
      entityGuid: element.guid,
    };
    let update = false;
    if (draftGuid) {
      update = true;
    }

    this.draftService.saveUserDraft(request).subscribe((_) => {
      if (update) {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.UPDATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      } else {
        this.notificationService.showToast(
          'GENERAL-ENTITY.CREATE.MESSAGES.SUCCESS-MESSAGES.CREATED-DRAFT',
          this.notificationService.MESSAGE_TYPE.SUCCESS
        );
      }
      this.redirectAfterTimeout(3000);
    }, this.customErrorHandler._errorHandler('MASTER-DATA.BOOKING-CODE'));
  }

  checkOneItemValue(object) {
    return this.genericUtils.checkOneItemValue(object);
  }

  getUserInfo() {
    return !isEmpty(this.operationUser) ? this.operationUser : 'system-user';
  }

  getErrorTab(indexTab) {
    if (!this.isDraftExists) {
      // if (indexTab == 0 && this.bookingCodeGeneralInformationComponent) {
      //   return !this.bookingCodeGeneralInformationComponent.bookingCodeForm
      //     .valid;
      // }
      // else if (indexTab == 2 && this.contractComponent) {
      //   return !this.contractComponent.contractForm.valid;
      // } else {
      //   return false;
      // }
    }
  }

  private updateLocalEntityDraft(draft) {
    this.localEntityDraft = draft;
    const snackBarObject = this.snackBar.openFromComponent(
      CustomSnackBarComponent,
      {
        data: 'GENERAL-ENTITY.CREATE.MESSAGES.TOAST_MESSAGE_DRAFT_EXISTS',
        duration: undefined,
        verticalPosition: 'top',
        panelClass: [
          this.notificationService.MESSAGE_TYPE.INFO + '-snackbar',
          'custom-snackbar',
        ],
      }
    );

    snackBarObject.afterDismissed().subscribe((result) => {
      if (result.dismissedByAction) {
        this.redirectToUserDraft();
      }
    });
  }

  private redirectAfterTimeout(time?: number) {
    setTimeout(
      () => {
        this.router.navigateByUrl('/systemConfiguration/booking', {
          state: { success: true },
        });
      },
      time ? time : 3000
    );
  }

  private successHandler() {
    this.notificationService.showToast(
      'NOTIFICATION.CREATED',
      this.notificationService.MESSAGE_TYPE.SUCCESS,
      { data: this.translateService.instant('MASTER-DATA.BOOKING-CODE') }
    );
  }
}
