<div class="content-container">
  <div class="website-credits-container">
    <div class="row mt-row title-container">
      <span>Impressum</span>
    </div>
    <div class="row mt-row text-container">
      <span>Verantwortliche juristische Person nach § 5 Abs. 1 Nr. 1 TMG:</span>
    </div>
    <div class="address-container mt-row">
      <div class="row title-container">
        <span>BFS finance GmbH</span>
      </div>
      <div class="row text-container">
        <span>Carl-Bertelsmann-Str. 23</span>
      </div>
      <div class="row text-container">
        <span>33332 Gütersloh</span>
      </div>
      <div class="row text-container">
        <span>Deutschland</span>
      </div>
    </div>

    <div class="phones-container mt-row">
      <div class="row text-container">
        <span>Telefon: +49 (0)5241 80-43199</span>
      </div>
      <div class="row text-container">
        <span>Telefax: +49 (0)5241 80-43130</span>
      </div>
      <div class="row text-container">
        <span>E-Mail: <a href="mailto:info@bfs-finance.com">info@bfs-finance.com</a> </span>
      </div>
    </div>

    <div class="vat-container mt-row">
      <div class="row text-container">
        <span>Umsatzsteuer-Identifikations-Nr.: DE 812731086</span>
      </div>
      <div class="row text-container">
        <span>Handelsregister: Amtsgericht Gütersloh, HRB 3823</span>
      </div>
    </div>

    <div class="row mt-row text-container">
      <span>Geschäftsführung:</span>
    </div>
    <div class="row text-container">
      <span>Rudolf Gellrich, Hendrik Lamers</span>
    </div>

    <div class="row mt-row text-container">
      <span>Qualifizierte Person im Sinne von § 12 Abs. 4 RDG: Stefan Lang</span>
    </div>
    <div class="mt-row">
      <div class="row text-container">
        <span
          >Aufsichtsbehörde/Registrierungsbehörde im Sinne des Rechtsdienstleistunsgesetzes ist der Präsident des
          Oberlandesgerichts Hamm, Heßlerstraße 53, 59065 Hamm</span
        >
      </div>
    </div>
    <div class="row mt-row text-container">
      <span
        >Die BFS finance GmbH ist zur Erbringung von Finanzdienstleistungen von der Bundesanstalt für
        Finanzdienstleistungsaufsicht (BaFin), Graurheindorfer Straße 108, 53117 Bonn zugelassen.</span
      >
    </div>
    <div class="row mt-row title-container">
      <span>Haftungshinweise</span>
    </div>
    <div class="row mt-row text-container">
      <span
        >Alle Angaben dieses Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot
        stetig zu erweitern und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte
        Aktualisierung kann jedoch nicht übernommen werden. Die BFS finance GmbH stellt diese Informationen ohne
        jegliche Zusicherung oder Gewährleistung jedweder Art, sei sie ausdrücklich oder stillschweigend, zur Verfügung.
        Die BFS finance GmbH schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser
        Website entstehen, aus, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit der BFS finance GmbH beruhen.
        Die BFS finance GmbH ist als Inhaltsanbieter nach § 7 Abs. 1 TMG für die eigenen Inhalte, die auf
        tierwohl.BeVison.app zur Nutzung bereitgehalten werden, nach den allgemeinen Gesetzen verantwortlich. Von diesen
        eigenen Inhalten sind Querverweise („Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu
        unterscheiden. Durch den Querverweis hält die BFS finance GmbH insofern fremde Inhalte zur Nutzung bereit, die
        als solche entsprechend gekennzeichnet sind. Diese fremden Inhalte wurden bei der erstmaligen Link-Setzung
        daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit
        ausgelöst wird. Es ist jedoch nicht auszuschließen, dass die Inhalte im Nachhinein von den jeweiligen Anbietern
        verändert werden. Die BFS finance GmbH überprüft die Inhalte, auf die sie in ihrem Angebot verweist, nicht
        ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen könnten. Sollten Sie der Ansicht sein, dass
        die verlinkten externen Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen
        Sie uns dies bitte mit.</span
      >
    </div>
    <div class="row mt-row title-container">
      <span>Urheberrecht</span>
    </div>
    <div class="row mt-row text-container">
      <span
        >Copyright 2019 / BFS finance GmbH. Alle Rechte vorbehalten. Alle Inhalte (Texte, Bilder, Grafiken, Ton-, Video-
        und Animationsdateien sowie deren Anordnung u. a.) auf der Website der BFS finance GmbH unterliegen dem Schutz
        des Urheberrechts und anderer Schutzgesetze. Der Rechtsschutz gilt auch gegenüber Datenbanken und ähnlichen
        Einrichtungen. Die Inhalte sind nur für den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte
        dieser Website dürfen außerhalb der Grenzen des Urheberrechts ohne schriftliche Genehmigung der BFS finance GmbH
        nicht in irgendeiner Form vervielfältigt, verbreitet, verändert oder Dritten zugänglich gemacht werden. Einige
        Bereiche der Website der BFS finance GmbH enthalten außerdem Bilder, die dem Copyright Dritter unterliegen.
        Soweit nicht anders angegeben, sind alle Markenzeichen auf der Website der BFS finance GmbH markenrechtlich
        geschützt.</span
      >
    </div>
  </div>
</div>
