import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { contractTypes } from 'src/app/in-memory-data/salesEntry/enum/contractTypes';
import { SalesEntryService } from '../../../shared/services/animal-welfare/sales-entry/sales-entry.service';
import { paymentMethodTypes } from '../../../in-memory-data/salesEntry/enum/paymentMethodTypes';
import { SystemParameterService } from 'src/app/shared/services/animal-welfare/system-parameter/system-parameter.service';
import { SystemParameter } from 'src/app/shared/services/animal-welfare/system-parameter/system-parameter.enum';
import { SalesParticipantType } from './sales-participant-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-entry-contract-information',
  templateUrl: './sales-entry-contract-information.component.html',
  styleUrls: ['./sales-entry-contract-information.component.less'],
})
export class SalesEntryContractInformationComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private taskService: TaskService,
    private salesEntryService: SalesEntryService,
    private systemParameterService: SystemParameterService,
    private translateService: TranslateService
  ) {
    this.taskService.currentEditableTask.subscribe(
      (editableTask) => (this.editableTask = editableTask)
    );
    this.contractInformationFormBuilder();
  }

  get getContractType() {
    return this.findContractType.bind(this);
  }

  get getPaymentMethod() {
    return this.findPaymentMethod.bind(this);
  }
  contractInformationForm: UntypedFormGroup;
  isTask = false;
  editableTask = false;
  entityName = 'company-contract-information';
  contractTypes = contractTypes;
  paymentMethodTypes = paymentMethodTypes;

  @Input() salesEntryApproved;
  @Input() salesEntryTask;
  @Input() draftExists: boolean;
  @Input() readOnly;
  state;

  isValid = false;
  defaultBillingRate;

  ngOnInit(): void {
    this.state = window.history.state;
    if (this.state.task) {
      this.isTask = true;
      this.formatAndPatchForm(this.salesEntryTask);
      if (this.salesEntryTask?.id) {
        this.salesEntryService
          .getSalesEntry(this.salesEntryTask.id)
          .subscribe((salesEntry) => {
            this.salesEntryApproved = salesEntry;
            if (!this.salesEntryApproved.billingRate) {
              this.salesEntryApproved.billingRate = '';
            }
          });
      }
    } else if (this.state.salesEntry) {
      this.formatAndPatchForm(this.state.salesEntry);
    } else {
      this.setInitialContractType();
    }
    const accountNumberValue =
      this.contractInformationForm.get('accountNumber').value;
    if (accountNumberValue == null || accountNumberValue === '') {
      this.state.salesEntryFormNotValid = true;
    } else {
      this.state.salesEntryFormNotValid = false;
    }
    if (
      this.contractInformationForm.get('accountNumber').value == null ||
      this.contractInformationForm.get('accountNumber').value === ''
    ) {
      this.salesEntryService
        .getNextAvailableManualAccountNumber()
        .subscribe((data) => {
          this.contractInformationForm.get('accountNumber').setValue(data);
        });
    }
    this.contractInformationForm
      .get('accountNumber')
      .valueChanges.subscribe((value) => {
        let salesEntryId;
        if (this.salesEntryApproved) {
          salesEntryId = this.salesEntryApproved.id;
        }
        this.salesEntryService
          .isAccountNumberAvailable(value)
          .subscribe((result) => {
            if (result) {
              this.contractInformationForm
                .get('accountNumber')
                .setErrors({ alreadyExists: null });
              this.isValid = true;
            } else {
              this.contractInformationForm
                .get('accountNumber')
                .setErrors({ alreadyExists: true });

              this.isValid = false;
            }

            this.contractInformationForm
              .get('accountNumber')
              .updateValueAndValidity({ emitEvent: false });
          });
        this.state.salesEntryFormNotValid = !!this.isValid;
      });

    const contractdateValue =
      this.contractInformationForm.get('contractDate').value;
    if (contractdateValue == null || contractdateValue === '') {
      this.state.salesEntryFormNotValid = true;
    } else {
      this.state.salesEntryFormNotValid = false;
    }

    this.contractInformationForm
      .get('contractDate')
      .valueChanges.subscribe((value) => {
        if (value == null || value === '') {
          this.state.salesEntryFormNotValid = true;
        } else {
          this.state.salesEntryFormNotValid = false;
        }
      });

    if (
      this.contractInformationForm.get('billingRate').value == null ||
      this.contractInformationForm.get('billingRate').value === ''
    ) {
      this.systemParameterService
        .getFundDepositAmount()
        .subscribe((data) => {
          this.defaultBillingRate = data;
        });
    }
  }

  formatAndPatchForm(salesEntry) {
    this.contractInformationForm
      .get('contractDate')
      .patchValue(salesEntry.contractDate);
    this.contractInformationForm.get('type').patchValue(salesEntry.type);
    if (salesEntry.id) {
      this.contractInformationForm.get('id').patchValue(salesEntry.id);
    }
    if (salesEntry.accountNumber) {
      this.contractInformationForm
        .get('accountNumber')
        .patchValue(salesEntry.accountNumber);
    }
    this.contractInformationForm
      .get('paymentMethod')
      .patchValue(salesEntry.paymentMethod);
    this.contractInformationForm
      .get('advancePayment')
      .patchValue(salesEntry.advancePayment);
    if (salesEntry.taxId) {
      this.contractInformationForm.get('taxId').patchValue(salesEntry.taxId);
    }
    if (!salesEntry.billingRate) {
      salesEntry.billingRate = '';
    }
    if (this.salesEntryApproved && !this.salesEntryApproved.billingRate) {
      this.salesEntryApproved.billingRate = '';
    }

    this.contractInformationForm
      .get('billingRate')
      .patchValue(salesEntry.billingRate);
    this.contractInformationForm
      .get('contractDateCattle')
      .patchValue(salesEntry.contractDateCattle);
  }

  contractInformationFormBuilder() {
    this.contractInformationForm = this.formBuilder.group({
      id: [''],
      advancePayment: [''],
      contractDate: ['', Validators.required],
      contractDateCattle: [''],
      type: ['', [Validators.required]],
      accountNumber: [
        '',
        [Validators.required],
      ],
      paymentMethod: ['', Validators.required],
      taxId: [''],
      billingRate: ['', Validators.pattern('^[0-9]*[.,]?[0-9]*$')],
    });
  }

  checkBillingrateValue($event: any) {
    const input = $event.target.value;
    const pattern = /^[0-9]*[.,]?[0-9]*$/;
    if (input !== '' && !pattern.test(input)) {
      if (typeof input !== 'number') {
        $event.target.value = $event.target.value.replace(
          /[^.,0123456789]/g,
          ''
        );
        this.contractInformationForm
          .get('billingRate')
          .setValue($event.target.value);
      }
    } else {
      this.contractInformationForm.get('billingRate').setErrors(null);
      this.contractInformationForm.get('billingRate').updateValueAndValidity();
    }
    this.contractInformationForm.updateValueAndValidity();
  }

  setInitialContractType() {
    if (
      this.state.contractType &&
      this.state.contractType === SalesParticipantType.GASTRONOMY
    ) {
      this.contractInformationForm
        .get('type')
        .patchValue(this.state.contractType);
    } else if (
      this.state.contractType &&
      this.state.contractType === SalesParticipantType.FRT
    ) {
      this.contractInformationForm
        .get('type')
        .patchValue(this.state.contractType);
    }
  }

  findContractType(contractName) {
    if (!this.contractTypes) {
      return '';
    }

    const contractType = this.contractTypes.find(
      (element) => element.name === contractName
    );
    return contractType ? contractType.translateName : '';
  }

  private findPaymentMethod(param) {
    if (!this.paymentMethodTypes) {
      return '';
    }
    const paymentMethod = this.paymentMethodTypes.find(
      (element) => element.name === param
    );
    return paymentMethod ? paymentMethod.translateName : '';
  }

  getBillingRateLabel() {
    return (
      this.translateService.instant(
        'ANIMAL_WELFARE.SALES_ENTRY.BILLING_RATE.DEFAULT'
      ) +
      ': ' +
      this.defaultBillingRate
    );
  }
}
