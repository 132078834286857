<div class="filters-history">
  <mat-accordion class="margin-accordion">
    <mat-expansion-panel class="filters" id="{{ entityName }}-expansionPanel">
      <mat-expansion-panel-header id="{{ entityName }}-expansionPanel-header">
        <mat-panel-title>
          <span class="subheading filters-title">
            {{ 'TRANSACTION.HISTORY.FILTERS.TITLE' | translate }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="filter-params fav-filters" [formGroup]="filter">
        <app-mat-select-multiple
          [name]="'TRANSACTION.HISTORY.FILTERS.TYPE'"
          [placeholder]="'TRANSACTION.HISTORY.FILTERS.TYPE'"
          [label]="'TRANSACTION.HISTORY.FILTERS.TYPE'"
          [entityName]="entityName"
          [form]="filter"
          [controlObject]="filter.controls.type"
          [controlName]="'type'"
          [approvedValue]="null"
          [taskValue]="null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="transactionTypes"
          [optionValue]="'name'"
          [selectedOption]="''"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
        ></app-mat-select-multiple>
        <div class="form-row-selection">
          <mat-form-field>
            <mat-label>{{ 'ANIMAL-WELFARE.LIQUIDITY-PLAN.FILTERS.APPROVED-ON' | translate }}</mat-label>
            <mat-date-range-input
              [formGroup]="filter.controls.dateRange"
              [rangePicker]="picker"
              id="{{ entityName }}-date-range-begin-end"
            >
              <input
                matStartDate
                formControlName="begin"
                id="{{ entityName }}-date-range-begin"
                placeholder="{{ 'GENERAL.START-DATE' | translate }}"
                [formControl]="filter.controls.dateRange.get('begin')"
              />
              <input
                matEndDate
                formControlName="end"
                id="{{ entityName }}-date-range-end"
                placeholder="{{ 'GENERAL.END-DATE' | translate }}"
                [formControl]="filter.controls.dateRange.get('end')"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker" class="date-toggle"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-icon class="close" (click)="clearFilters()">close</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<mat-accordion>
  <mat-expansion-panel
    *ngFor="let transaction of transactionHistory; index as i"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="transaction-header-container">
          <span
            *ngIf="
              transaction.operation == 'SET' ||
              transaction.operation == 'ALLOCATE' ||
              transaction.operation == 'TRIGGER'
            "
            >{{ 'TRANSACTION.HISTORY.HEADER.ADD' | translate: { name: transaction.type | titlecase } }}</span
          >
          <span *ngIf="transaction.operation == 'REMOVE' || transaction.operation == 'DELETE'">{{
            'TRANSACTION.HISTORY.HEADER.DELETE' | translate: { name: transaction.type | titlecase }
          }}</span>
          <span *ngIf="transaction.operation.includes('UPDATE')">{{
            'TRANSACTION.HISTORY.HEADER.UPDATE' | translate
          }}</span>
          <span *ngIf="transaction.operation == 'CREATE'">{{
            'TRANSACTION.HISTORY.HEADER.CREATE' | translate: { name: transaction.type | titlecase }
          }}</span>
          <span *ngIf="transaction.operation == 'UPLOAD'">{{
            'TRANSACTION.HISTORY.HEADER.UPLOAD' | translate: { name: transaction.type | titlecase }
          }}</span>

          <span class="date-history-header">{{ transaction.operationDate | date: dateFormat + ' HH:mm:ss' }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel-form-container">
      <div class="details-row">
        <div class="label-details">
          {{ 'TRANSACTION.HISTORY.DETAILS.USER' | translate }}
        </div>
        <div class="value-details">
          {{ getUserInfo(transaction) | translate }}
        </div>
      </div>

      <div class="details-row new-section" *ngIf="transaction.detailsObject && transaction.detailsObject.before">
        <div *ngIf="transaction.type == 'OBJECTION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-OBJECTION-CODE' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.objectionCode != null">
            {{ transaction.detailsObject.before.objectionCode }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.objectionCode == null">
            {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'CLASSIFICATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-FINANCED-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.financedAmount != null">
            {{ transaction.detailsObject.before.financedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'UPDATE'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-VALUE' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before != null">
            {{ getClassificationNameTranslation(transaction.detailsObject.before) | translate }}
          </div>
        </div>
      </div>

      <div class="details-row" *ngIf="transaction.detailsObject && transaction.detailsObject.after">
        <div *ngIf="transaction.type == 'OBJECTION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-OBJECTION-CODE' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.objectionCode != null">
            {{ transaction.detailsObject.after.objectionCode }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.objectionCode == null">
            {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'CLASSIFICATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-FINANCED-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.financedAmount != null">
            {{ transaction.detailsObject.after.financedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'UPDATE'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-VALUE' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after != null">
            {{ getClassificationNameTranslation(transaction.detailsObject.after) | translate }}
          </div>
        </div>
      </div>

      <div class="details-row new-section" *ngIf="transaction.detailsObject && transaction.detailsObject.before">
        <div *ngIf="transaction.type == 'OBJECTION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.amount != null">
            {{ transaction.detailsObject.before.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.amount == null">
            {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'CLASSIFICATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-NON-FINANCED-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.before.nonFinancedAmount != null">
            {{ transaction.detailsObject.before.nonFinancedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'UPDATE'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.UPDATED-FIELD' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.operation == 'UPDATE_CLASSIFICATION'">
            {{ 'TRANSACTION.HISTORY.DETAILS.CLASSIFICATION' | translate }}
          </div>
        </div>
      </div>

      <div
        class="details-row new-section"
        *ngIf="transaction.detailsObject && !transaction.detailsObject.before && transaction.type == 'UPDATE'"
      >
        <div class="label-details">
          {{ 'TRANSACTION.HISTORY.DETAILS.UPDATED-FIELD' | translate }}
        </div>
        <div class="value-details" *ngIf="transaction.operation == 'UPDATE_CLASSIFICATION'">
          {{ 'TRANSACTION.HISTORY.DETAILS.CLASSIFICATION' | translate }}
        </div>
        <div class="value-details" *ngIf="transaction.operation == 'UPDATE_DEDUCTIONS'">
          {{ 'TRANSACTION.HISTORY.DETAILS.DEDUCTIONS' | translate }}
        </div>
      </div>
      <div class="details-row" *ngIf="transaction.detailsObject && transaction.detailsObject.after">
        <div *ngIf="transaction.type == 'OBJECTION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.amount != null">
            {{ transaction.detailsObject.after.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.amount == null">
            {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
          </div>
        </div>
        <div *ngIf="transaction.type == 'CLASSIFICATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-NON-FINANCED-AMOUNT' | translate }}
          </div>
          <div class="value-details" *ngIf="transaction.detailsObject.after.nonFinancedAmount != null">
            {{ transaction.detailsObject.after.nonFinancedAmount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
        </div>
      </div>

      <div class="details-row">
        <div *ngIf="transaction.type == 'DEDUCTION'">
          <div class="object-row deduction-reason">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-REASON' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.reason != null"
            >
              {{ getDeductionReasonTranslation(transaction.detailsObject.before.reason) | translate }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.before">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-REASON' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.reason != null"
            >
              {{ getDeductionReasonTranslation(transaction.detailsObject.after.reason) | translate }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.after">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>

          <div class="object-row deduction-reference">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-REFERENCE' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.reference != null"
            >
              {{ transaction.detailsObject.before.reference }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.before">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-REFERENCE' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.reference != null"
            >
              {{ transaction.detailsObject.after.reference }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.after">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>

          <div class="object-row deduction-percent">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-PERCENT' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.percent != null"
            >
              {{ transaction.detailsObject.before.percent }}
            </div>
            <div
              class="value-details"
              *ngIf="!transaction.detailsObject.before || transaction.detailsObject.before.percent == null"
            >
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-PERCENT' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.percent != null"
            >
              {{ transaction.detailsObject.after.percent }}
            </div>
            <div
              class="value-details"
              *ngIf="!transaction.detailsObject.after || transaction.detailsObject.after.percent == null"
            >
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>

          <div class="object-row deduction-amount">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-AMOUNT' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.amount != null"
            >
              {{ transaction.detailsObject.before.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
            </div>
            <div
              class="value-details"
              *ngIf="!transaction.detailsObject.before || transaction.detailsObject.before.amount == null"
            >
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-AMOUNT' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.amount != null"
            >
              {{ transaction.detailsObject.after.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
            </div>
            <div
              class="value-details"
              *ngIf="!transaction.detailsObject.after || transaction.detailsObject.after.amount == null"
            >
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>

          <div class="object-row deduction-validity-date">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-VALIDITY-DATE' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.validityDate != null"
            >
              {{ transaction.detailsObject.before.validityDate | date: dateFormat }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.before">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-VALIDITY-DATE' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.validityDate != null"
            >
              {{ transaction.detailsObject.after.validityDate | date: dateFormat }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.after">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>

          <div class="object-row deduction-additional-information">
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.PREVIOUS-DEDUCTION-ADDITIONAL-INFORMATION' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.before && transaction.detailsObject.before.additionalInformation != null"
            >
              {{ transaction.detailsObject.before.additionalInformation }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.before">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
            <div class="label-details">
              {{ 'TRANSACTION.HISTORY.DETAILS.AFTER-DEDUCTION-ADDITIONAL-INFORMATION' | translate }}
            </div>
            <div
              class="value-details"
              *ngIf="transaction.detailsObject.after && transaction.detailsObject.after.additionalInformation != null"
            >
              {{ transaction.detailsObject.after.additionalInformation }}
            </div>
            <div class="value-details" *ngIf="!transaction.detailsObject.after">
              {{ 'TRANSACTION.HISTORY.DETAILS.NO-VALUE' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="details-row new-section" *ngIf="transaction.detailsObject.amount">
        <div *ngIf="transaction.type == 'ALLOCATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.AMOUNT' | translate }}
          </div>
          <div class="value-details">
            {{ transaction.detailsObject.amount | currency: 'EUR':'symbol':'1.2-2':'de' }}
          </div>
        </div>
      </div>

      <div class="details-row" *ngIf="transaction.detailsObject.creditId">
        <div *ngIf="transaction.type == 'ALLOCATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.CREDIT-ID' | translate }}
          </div>
          <div class="value-details">
            {{ transaction.detailsObject.creditId }}
          </div>
        </div>
      </div>

      <div class="details-row" *ngIf="transaction.detailsObject.debitId">
        <div *ngIf="transaction.type == 'ALLOCATION'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.DEBIT-ID' | translate }}
          </div>
          <div class="value-details">
            {{ transaction.detailsObject.debitId }}
          </div>
        </div>
      </div>

      <div class="details-row new-section" *ngIf="transaction.detailsObject.comment">
        <div *ngIf="transaction.type == 'COMMENT'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.COMMENT' | translate }}
          </div>
          <div class="value-details">
            {{ transaction.detailsObject.comment }}
          </div>
        </div>
      </div>

      <div class="details-row new-section" *ngIf="transaction.detailsObject.fileName">
        <div *ngIf="transaction.type == 'ATTACHMENT'">
          <div class="label-details">
            {{ 'TRANSACTION.HISTORY.DETAILS.ATTACHMENT' | translate }}
          </div>
          <div class="value-details">
            <span *ngIf="transaction.operation !== 'UPLOAD'">
              {{ transaction.detailsObject.fileName }}
            </span>
            <a
              *ngIf="transaction.operation == 'UPLOAD'"
              class="content file-name"
              (click)="downloadAttachment(transaction.detailsObject)"
              id="{{ entityName }}-attachment-{{ i }}"
            >
              {{ transaction.detailsObject.fileName }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <div class="loader" *ngIf="loader">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="loader" *ngIf="!loader && transactionHistory && transactionHistory.length == 0">
    {{ 'ERROR.NO-DATA-FOUND' | translate }}
  </div>
</mat-accordion>
