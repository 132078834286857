<div
  *ngIf="(editableTask && isTask) || !isTask"
  [ngClass]="{
    'inline-display': inline
  }"
>
  <div
    class="form-row"
    [formGroup]="formGroup"
    [ngClass]="{
      'form-row-alternative': sideMenu
    }"
  >
    <mat-form-field
      id="{{ entityName }}-form-{{ controlName }}{{ elementIndex ? '-' + elementIndex : '' }}"
      appearance="{{ appearance ? appearance : 'fill' }}"
      [ngClass]="{ 'val-changed': changedValue }"
    >
      <mat-label>
        <span>
          {{ label | translate }}
        </span>
        <span *ngIf="nameParamsValue != undefined">
          {{ label | translate: { entity: nameParamsValue | translate } }}
        </span>
      </mat-label>
      <mat-select
        #select
        formControlName="{{ controlName }}"
        [required]="requiredValidator"
        placeholder="{{
          nameParamsValue == undefined
            ? (placeholder | translate)
            : (placeholder | translate: { entity: nameParamsValue | translate })
        }}"
        id="{{ entityName }}-form-{{ controlName }}-select{{ elementIndex ? '-' + elementIndex : '' }}"
        [disabled]="disabled"
        panelClass="custom-select"
      >
        <mat-option *ngIf="allowNullSelection" [value]="undefined">{{ 'GENERAL.NONE' | translate }}</mat-option>
        <mat-option *ngIf="extraOption" [value]="extraOptionValue">{{
          extraOptionTranslateText | translate
        }}</mat-option>
        <mat-option
          *ngFor="let item of options"
          [value]="getItemValue(item)"
          id="{{ entityName }}-form-{{ controlName }}-selectOption-{{ getItemValue(item) }}{{
            elementIndex ? '-' + elementIndex : ''
          }}"
          [disabled]="!!item.enable"
        >
          <span *ngIf="optionTranslationText != ''">{{ optionTranslationText + '.' + getItem(item) | translate }}</span>
          <span *ngIf="optionTranslationText == '' && customOptionNameFunction == null">{{
            getItem(item) | translate
          }}</span>
          <span *ngIf="optionTranslationText == '' && customOptionNameFunction != null">{{ getCustomName(item) }}</span>
        </mat-option>
      </mat-select>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        id="{{ entityName }}-form-{{ controlName }}-clear{{ elementIndex ? '-' + elementIndex : '' }}"
        (click)="clearOptions(); $event.stopPropagation()"
        *ngIf="showRemoveOption() && !isFieldDisabled()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error
        id="{{ entityName }}-form-{{ controlName }}-{{ errorRequired }}"
        class="input-error"
        *ngIf="controlObject.hasError('required')"
      >
        {{
          'ERROR-MESSAGES.REQUIRED'
            | translate
              : {
                  name: (select.placeholder | translate)
                }
        }}
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div *ngIf="!editableTask && isTask" class="non-editable-info-container">
  <div *ngIf="titleTask">
    <div class="object-title">
      <span
        [ngClass]="{
          'val-changed': approvedValue && approvedValue != taskValue
        }"
        >{{ taskValue }}</span
      >
    </div>
  </div>
  <div *ngIf="!titleTask">
    <div class="object-row">
      <div class="container" fxLayout="row wrap" fxlayout.sm="column" fxlayout.xs="column" fxLayoutGap="7px grid">
        <div fxFlex="25" fxFlex.sm="30" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="75" fxFlex.sm="70" fxFlex.xs="100" *ngIf="!sideMenu && !twoColumns">
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText != '' && !textTransform"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ optionTranslationText + '.' + taskValue | translate | titlecase }}</span
          >
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText == ''"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ taskValue | translate }}</span
          >
          <span
            *ngIf="customTaskValueFunction"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ getCustomTaskName(taskValue) | translate }}</span
          >
        </div>

        <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="40" fxFlex.sm="50" fxFlex.xs="100" *ngIf="!sideMenu && twoColumns">
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText != '' && !textTransform"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ optionTranslationText + '.' + taskValue | translate }}</span
          >
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText == ''"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ taskValue | translate }}</span
          >
          <span
            *ngIf="customTaskValueFunction"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ getCustomTaskName(taskValue) | translate }}</span
          >
        </div>

        <div fxFlex="50" fxFlex.sm="30" fxFlex.xs="100" *ngIf="sideMenu && !twoColumns">
          <span class="object-label">{{ label | translate: { entity: nameParamsValue | translate } }}:</span>
        </div>
        <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="100" *ngIf="sideMenu && !twoColumns">
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText == ''"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ taskValue | translate }}</span
          >
          <span
            *ngIf="!customTaskValueFunction && optionTranslationText != '' && textTransform != 'none'"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ optionTranslationText + '.' + taskValue | translate }}</span
          >
          <span
            *ngIf="customTaskValueFunction && textTransform == 'none'"
            class="object-value"
            [ngClass]="{
              'val-changed': this.approvedValue && approvedValue != taskValue
            }"
            >{{ getCustomTaskName(taskValue) | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
