import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TransactionCodeAllocationEntity } from '../../models/transaction-code-allocation-entity';
import { Observable } from 'rxjs/internal/Observable';
import { SearchPage } from '../../models/search-page';
import { BusinessUnitService } from '../business-unit/business-unit.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionCodeAllocationEntitiesService {
  private readonly searchServiceUrl: string;

  constructor(
    private http: HttpClient,
    private businessUnits: BusinessUnitService
  ) {
    this.searchServiceUrl = environment.settings.searchAPIUrl;
  }

  retrieveEntitiesForTransactionCode(
    transactionCodeId: number
  ): Observable<SearchPage<TransactionCodeAllocationEntity>> {
    return this.http.get<SearchPage<TransactionCodeAllocationEntity>>(
      this.searchServiceUrl +
        'transaction-code-allocation/for-transaction-code',
      {
        params: new HttpParams().set(
          'transactionCodeId',
          String(transactionCodeId)
        ),
        headers: this.buildHeaders(),
      }
    );
  }

  private buildHeaders() {
    return new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Accept', 'application/json')
      .append('Business-Unit-Id', this.businessUnits.getCurrentBusinessUnit());
  }
}
