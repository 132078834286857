import {
  Component,
  OnInit,
  Input,
  IterableDiffers,
  KeyValueDiffers,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { TranslateService } from '@ngx-translate/core';
import { customFilterConfiguration } from 'src/app/in-memory-data/task/custom-filter-configuration';
import { User } from 'src/app/shared/models/user';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SaveFavoriteFilterModalComponent } from 'src/app/shared/modals/save-favorite-filter-modal/save-favorite-filter-modal.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { isEmpty } from 'src/app/utils/object-util';
import { debounceTime } from 'rxjs/operators';

export interface TaskType {
  type: string;
}
export interface Role {
  role: string;
}

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.less'],
})
export class TaskFilterComponent implements OnInit {
  @Input() userList: User[];
  @Input() buList: any[];
  tasks$: Observable<any[]>;
  filterSelectObj = customFilterConfiguration;
  roles$: Observable<any[]>;
  selectedTasks = [];
  selectedRoles = [];
  selectedTaskRole: any;
  selectedTaskType: any;

  private CONFIGURATION_KEY = 'task_table_favorites';

  filter: UntypedFormGroup;
  creationDateFilterForm: UntypedFormGroup;
  isIE = false;
  entityName = 'task';
  taskList;
  favFilterGroups: [];
  favFiltersNames: any[] = [];
  favFilterParams: [];
  favFilterMore: [];
  favoriteFilter = false;
  panelOpenState = false;
  saveAs: boolean;
  save: boolean;
  successfulSave = false;
  favFilters = [];
  favFName = '';
  selectedIndex: number;
  indexFav: number;
  nameRepeated = false;
  teams: any[] = [];
  priorityFilters: any;
  values: any;
  typeValues: any;
  allFilters = [];
  filterInitialized = [];
  iterableDiffer: any;
  subject = new Subject();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private taskService: TaskService,
    private translateService: TranslateService,
    private userService: UserService,
    private matDialogService: MatDialog,
    private notificationService: NotificationService,
    private iterableDiffers: IterableDiffers,
    private differs: KeyValueDiffers,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.iterableDiffer = differs.find([]).create();
    this.filter = this.formBuilder.group({
      searchString: ['', []],
      taskType: ['', []],
      taskRole: ['', []],
      onlyMyTasks: [true, []],
      onlyActive: [true, []],
      onlyLocked: [false, []],
      customFilters: [],
      favorite: [],
      lastUsed: [],
      name: ['', []],
      teamId: ['', []],
      'filter-more.priority': [],
      'filter-more.searchString.priority': [],
      'filter-more.creation_date': [],
      'filter-more.searchString.creation_date': [],
      'filter-more.assigned_user_id': [],
      'filter-more.searchString.assigned_user_id': [],
      'filter-more.business_unit_id': [],
      'filter-more.searchString.business_unit_id': [],
      'filter-more.created_by': [],
      'filter-more.searchString.created_by': [],
      'filter-more.due_date': [],
      'filter-more.searchString.due_date': [],
      'filter-more.status': [],
      'filter-more.searchString.status': [],
    });
    this.creationDateFilterFormBuilder();
  }

  creationDateFilterFormBuilder() {
    this.creationDateFilterForm = this.formBuilder.group({
      searchDate: new UntypedFormGroup({
        begin: new UntypedFormControl(null, []),
        end: new UntypedFormControl(null, []),
      }),
    });
  }

  ngOnInit() {
    this.getFavFilters();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.taskService.setFilter(this.filter.value);
    });
    this.taskService.filter = this.filter.value;
  }

  setChangeListeners() {
    this.filter.valueChanges.subscribe((form) => {
      this.taskService.startSearch();
      const customFilterChange = this.iterableDiffer.diff(form);
      if (customFilterChange) {
        if (this.favFilters && this.favFilters.length > 0) {
          this.save = true;
        }
        localStorage.setItem('filter', JSON.stringify(form));
        this.subject.next(undefined);
      }
    });
    this.creationDateFilterForm.controls.searchDate.valueChanges.subscribe(
      (value) => {
        this.taskService.startSearch();
        this.filter.value.customFilters.forEach((filter) => {
          if (filter.type === 'date') {
            filter.inputForm['value'] = value;
          }
        });
        this.subject.next(undefined);
      }
    );
  }

  change(event) {
    if (event.source.selected) {
      if (event.source.value.inputForm) {
        return;
      } else {
        const formControl = new UntypedFormControl('');
        const formControlSearch = new UntypedFormControl('');

        event.source.value.inputForm = formControl;
        formControlSearch.valueChanges.subscribe((filter) => {
          this.searchFromSelect(filter, event.source.value);
        });

        this.filter.addControl(
          'filter-more.' + event.source.value.columnProp,
          formControl
        );
        this.filter.addControl(
          'filter-more.searchString.' + event.source.value.columnProp,
          formControlSearch
        );
      }
    } else {
      this.filter.controls[
        'filter-more.' + event.source.value.columnProp
      ].reset();
      this.filter.controls[
        'filter-more.searchString.' + event.source.value.columnProp
      ].reset();
    }
  }

  searchFromSelect(filter, value) {
    /*if (!filter) {
      value.filteredOptions = Object.assign([], value.options);
      return;
    }
    value.filteredOptions = [];

    if ((value.type = "bu")) {
      for (let option of value.options) {
        if (
          this.getBusinessUnit(option)
            .toString()
            .toUpperCase()
            .indexOf(filter.toString().toUpperCase()) > -1
        ) {
          value.filteredOptions.push(option);
        }
      }
    } else {
      for (let option of value.options) {
        if (
          this.translateService
            .instant(value.translatePrefix + option)
            .toString()
            .toUpperCase()
            .indexOf(filter.toString().toUpperCase()) > -1
        ) {
          value.filteredOptions.push(option);
        }
      }
    }*/
  }

  getUserInfo(userName: string) {
    if (userName && userName.length > 0) {
      const user = this.userService.getAccountManagerName(
        userName,
        this.userList
      );

      return !isEmpty(user)
        ? user.lastName + ', ' + user.firstName + ' (' + user.username + ')'
        : 'system-user';
    }
  }

  getBusinessUnit(businessUnitId) {
    if (businessUnitId) {
      const bu = this.getBuName(businessUnitId);
      if (bu) {
        return bu.name;
      } else {
        return businessUnitId;
      }
    }
  }

  getBuName(businessUnitId) {
    if (this.buList) {
      return this.buList.find((el) => el.id === businessUnitId);
    }
  }

  getFavFilters() {
    this.saveAs = true;
    this.save = false;

    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data && data.data && data.data.length > 0) {
          this.setCustomConfiguration(data);
        } else {
          if (this.priorityFilters) {
            this.filter.patchValue(this.priorityFilters);
          } else {
            this.setDefaultConfiguration();
          }
        }
        this.subject.next(undefined);
        this.setChangeListeners();
      },
      (err) => {
        if (this.priorityFilters) {
          this.filter.patchValue(this.priorityFilters);
        } else {
          this.setDefaultConfiguration();
        }
        this.subject.next(undefined);
        this.setChangeListeners();
      }
    );
  }

  setCustomConfiguration(data) {
    this.favFilters = data.data;
    this.selectUsingFilter();
  }

  selectUsingFilter() {
    this.favFiltersNames = [];
    let favorite = false;
    if (this.priorityFilters) {
      this.favFName = this.priorityFilters.name;
      this.save = true;
      this.changeSearchForm(this.priorityFilters);
      this.favFilters.forEach((filter) => {
        this.favFiltersNames.push(filter.name);
      });
    } else {
      this.favFilters.forEach((item, index) => {
        if (item.favorite && !this.indexFav) {
          this.favFName = item.name;
          this.setNames(item.name);
          this.changeFavorite(null, item.favorite);
          this.selectedIndex = index;
          this.changeSearchForm(item);
          favorite = true;
          this.indexFav = index;
        } else if (item.lastUsed) {
          if (!favorite) {
            this.favFName = item.name;
            this.setNames(item.name);
            this.changeFavorite(null, item.favorite);
            this.selectedIndex = index;
            this.changeSearchForm(item);
          } else {
            this.favFiltersNames.push(item.name);
          }
        } else {
          this.favFiltersNames.push(item.name);
        }
      });
    }
  }

  changeSearchForm(favoriteFormValue) {
    let filter = favoriteFormValue;
    if (localStorage.getItem('filter')) {
      filter = JSON.parse(localStorage.getItem('filter'));
    }
    this.allFilters['type'] = filter.taskType;
    this.allFilters['team_id'] = filter.teamId;

    this.filter.patchValue(filter);
    this.patchMoreFilters(filter);
    this.filter.patchValue({ taskType: filter.taskType });
    this.filter.markAsPristine();
  }

  patchMoreFilters(favoriteFormValue) {
    let fields = [];
    if (favoriteFormValue && favoriteFormValue !== '') {
      Object.keys(favoriteFormValue).forEach((key, index) => {
        if (key.includes('filter-more') && !key.includes('searchString')) {
          const value = favoriteFormValue[key];
          const keyProp = key.split('.')[1];
          this.allFilters[keyProp] = value;
          if (value && !value.isEmpty && value.length !== 0) {
            this.filterSelectObj.forEach((item) => {
              if (item.columnProp === keyProp) {
                fields.push(item);
              }
            });

            setTimeout(() => {
              this.filter.controls[key].patchValue(value);
              this.filter.markAsPristine();
            }, 200);
          }
        }
      });
    }

    fields = this.removeDuplicates(fields);
    this.filter.patchValue({
      customFilters: fields,
    });
    this.filter.markAsPristine();
  }

  removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) === index);
  }

  setNames(name) {
    this.favFiltersNames.push(name);
    this.filter.patchValue({
      name,
    });

    this.filter.markAsPristine();
  }

  setDefaultConfiguration() {
    if (localStorage.getItem('filter')) {
      const filter = JSON.parse(localStorage.getItem('filter'));
      this.changeSearchForm(filter);
    } else {
      this.favFName = '';
      this.favFiltersNames = [];
      this.favoriteFilter = false;
      this.taskService.updateChoiceState(null);
      this.taskService.updateFiltersState(null);
    }
  }

  checkIfFavorite(itemName) {
    let result = false;
    if (this.favFilters && this.favFilters.length > 0) {
      this.favFilters.forEach((item) => {
        if (item.favorite) {
          if (item.name === itemName) {
            result = true;
          }
        }
      });
    }
    return result;
  }

  changeFavorite($event, val) {
    this.favoriteFilter = val;
    if (this.favFilters.length > 0 && $event && this.favFName !== '') {
      this.save = true;
    }
  }

  changeFilterName($event) {
    localStorage.removeItem('filter');
    this.favFilters.forEach((item, index) => {
      if (item.name === $event.value) {
        this.favFName = item.name;
        this.changeFavorite(null, item.favorite);
        this.selectedIndex = index;
        // this.save = false;
        this.changeSearchForm(item);
        item.lastUsed = true;
      } else {
        item.lastUsed = false;
      }
      this.saveConfiguration(this.favFilters, false);
    });
  }

  checkSpecialParams() {
    if (
      this.filter.value.customFilters &&
      this.filter.value.customFilters.length > 0
    ) {
      this.filter.value.customFilters = null;
    }
  }

  saveFavorite() {
    if (this.favFilters.length === 0) {
      this.saveAsFavorite();
    } else {
      const favorite = this.filter.get('favorite');
      favorite.setValue(this.favoriteFilter);
      favorite.updateValueAndValidity();

      const lastUsed = this.filter.get('lastUsed');
      lastUsed.setValue(true);
      lastUsed.updateValueAndValidity();

      if (this.favoriteFilter) {
        this.favFilters.forEach((item, index) => {
          if (this.selectedIndex !== index) {
            item.favorite = false;
          }
        });
      }

      this.checkSpecialParams();

      this.favFilters[this.selectedIndex] = this.filter.value;
    }

    this.saveConfiguration(this.favFilters, true);
  }

  checkName(name) {
    let result: boolean;
    if (this.favFilters.length > 0) {
      this.favFilters.forEach((item) => {
        if (item.name === name) {
          result = true;
        }
      });
    } else {
      result = false;
    }

    return result;
  }

  saveAsFavorite() {
    if (this.favFilters.length === 10) {
      this.notificationService.showToast(
        'ERROR.FAV-FILTERS.MAX-10-ITEMS',
        this.notificationService.MESSAGE_TYPE.ERROR
      );
    } else {
      const dialog = this.matDialogService.open(
        SaveFavoriteFilterModalComponent,
        {
          panelClass: 'confirmation-popup',
        }
      );
      dialog.afterClosed().subscribe((result) => {
        if (result && result.name) {
          if (!this.checkName(result.name)) {
            const name = this.filter.get('name');
            name.setValue(result.name);
            name.updateValueAndValidity();

            const favorite = this.filter.get('favorite');
            favorite.setValue(this.favoriteFilter);
            favorite.updateValueAndValidity();

            const lastUsed = this.filter.get('lastUsed');
            lastUsed.setValue(true);
            lastUsed.updateValueAndValidity();

            if (this.favoriteFilter) {
              if (this.favFilters.length > 0) {
                this.favFilters.forEach((item) => {
                  item.favorite = false;
                });
              }
            }

            const filters = [];
            if (this.favFilters) {
              this.favFilters.forEach((item) => {
                item.lastUsed = false;
                filters.push(item);
              });
            }

            filters.push(this.filter.value);

            this.checkSpecialParams();

            this.saveConfiguration(filters, true);
          } else {
            this.notificationService.showToast(
              'ERROR.FAV-FILTERS.REPEATED-NAME',
              this.notificationService.MESSAGE_TYPE.ERROR
            );
          }
        }
      });
    }
  }

  saveConfiguration(filters, reload) {
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        data: filters,
      },
    };

    this.userService.insertConfiguration(configuration).subscribe(
      (data) => {
        if (reload) {
          this.notificationService.showToast(
            'task.favFilters.successfulSave',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );

          this.getFavFilters();
        }
      },
      (err) => {
        if (err.error) {
          this.handleError(err.error);
        }
      }
    );
  }

  handleError(error) {
    this.notificationService.showToast(
      'ERROR.NO-DATA-FOUND',
      this.notificationService.MESSAGE_TYPE.ERROR,
      error
    );
  }

  checkOriginalFilters($event) {
    // this.resetSort();
  }

  checkFiltersGeneric() {
    // this.resetSort();
  }

  deleteFilter() {
    this.favFilters.splice(this.selectedIndex, 1);
    this.favFiltersNames.splice(this.selectedIndex, 1);
    if (this.favFilters.length > 0) {
      this.favFilters[0].lastUsed = true;
    }
    this.saveConfiguration(this.favFilters, true);
  }

  resetTaskType() {
    this.filter.value.taskType = [];
    this.filter.get('taskType').patchValue([]);
  }

  resetTaskRole() {
    /*this.filter.value.taskRole = [];
    this.filter.get("taskRole").patchValue([]);
    this.taskService.filterTasks(this.filter.value);*/
  }

  resetForm() {
    localStorage.removeItem('filter');
    this.favFName = '';
    this.changeFavorite(null, false);
    this.save = false;
    this.filter.reset(this.filter);

    this.filter.get('onlyMyTasks').setValue(true);
    this.filter.get('onlyActive').setValue(true);
    this.filter.get('onlyLocked').setValue(false);
    this.filter.get('searchString').setValue('');
  }

  hasFilter(fieldName: string) {
    if (!this.allFilters[fieldName]) {
      return false;
    }
  }

  opened(event, fieldName) {
    if (
      event &&
      !this.filterInitialized[fieldName] &&
      fieldName !== null &&
      fieldName !== ''
    ) {
      this.allFilters[fieldName] = undefined;
      this.taskService.getFilterFieldNameValues(fieldName).subscribe((data) => {
        const newData = [];
        const keys = Object.keys(data);
        keys.forEach((key) => {
          if (data[key] != null && data[key] !== '') {
            newData.push(data[key]);
          }
        });
        this.allFilters[fieldName] = newData;
        this.filterInitialized[fieldName] = true;
        this.changeDetectorRef.detectChanges();
      });
    }
  }
}
