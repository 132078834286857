import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { taskColumnsConf } from 'src/app/in-memory-data/task/table-columns-configuration';
import { taskColumns } from 'src/app/in-memory-data/task/table-columns';
import { UserService } from 'src/app/shared/services/user/user.service';
import { UserSelectModalComponent } from 'src/app/shared/modals/user-select-modal/user-select-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/shared/models/user';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListComponent implements OnInit {
  displayedColumns = taskColumns;
  columns = taskColumnsConf;
  entityName = 'task';

  displayedColumnsTemp = [];
  columnsTemp = [];
  taskList = [];
  private CONFIGURATION_KEY = 'task_table';

  dataSource = new MatTableDataSource(this.taskList);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel(true, []);
  displayCreatedBy = true;
  displayCreatedOn = false;
  displayDueDate = true;
  @Input() userList: User[];
  @Input() buList: any[];
  displayPriority = true;
  displayResponsibleRole = false;
  selectedTasks: any[] = [];
  dateFormat = '';
  currentPageIndex;

  totalCount: number;
  pageSize = 20;
  sortedBy = 'id';
  sortDirection = 'asc';
  subject = new Subject();
  loaderSpinner = true;

  sortTable = (choice): void => {
    this.taskService.startSearch();
    this.taskService.setSorting(choice);
    this.paginator.firstPage();
    this.taskService.getTaskList(this.pageSize, this.paginator.pageIndex);
  };

  getFieldNames = (args): Observable<any> => {
    return this.taskService.getFilterFieldNameValues(args);
  };

  constructor(
    private taskService: TaskService,
    private router: Router,
    private notificationService: NotificationService,
    private userService: UserService,
    private kcService: KeycloakService,
    private matDialogService: MatDialog,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.notificationService.dismissMessage();
    setTimeout(() => {
      this.dateFormat = this.userService.getDateFormat();
    }, 500);

    this.getTableConfiguration();
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'createdOn':
          return item.creationDate;
        default:
          return item[property];
      }
    };

    this.taskService.taskList.subscribe((data) => {
      this.taskList = data.content;
      this.dataSource.data = data.content;
      this.totalCount = data.total;
      this.loaderSpinner = false;
      if (this.paginator.pageIndex !== data.page) {
        this.paginator.pageIndex = data.page;
      }
      this.showMessage(data);
      this.changeDetectorRef.detectChanges();
    });

    this.taskService.searchStarted.subscribe((data) => {
      if (data) {
        this.loaderSpinner = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  tableChanged(event) {
    this.columnsTemp.forEach((val) =>
      this.columns.push(Object.assign({}, val))
    );
    this.displayedColumns = Object.assign([], this.displayedColumnsTemp);
    const configuration = {
      key: this.CONFIGURATION_KEY,
      value: {
        columns: this.columnsTemp,
        displayedColumns: this.displayedColumnsTemp,
      },
    };

    this.userService.triggerInsertConfiguration(configuration);
  }

  getTableConfiguration() {
    this.userService.getConfiguration(this.CONFIGURATION_KEY).subscribe(
      (data) => {
        if (data.columns) {
          if (data.columns.length === 0) {
            this.setDefaultColumnValues();
          } else {
            const mergedConfig =
              this.userService.mergeTableConfigurationsFromUIIfNeeded(
                this.CONFIGURATION_KEY,
                data,
                taskColumnsConf,
                taskColumns
              );
            this.setConfiguredColumns(mergedConfig);
          }
        } else {
          this.setDefaultColumnValues();
        }
      },
      (err) => {
        this.setDefaultColumnValues();
      }
    );
  }

  setConfiguredColumns(data) {
    this.columns = data.columns;
    this.displayedColumns = data.displayedColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  setDefaultColumnValues() {
    this.columns = taskColumnsConf;
    this.displayedColumns = taskColumns;
    this.columnsTemp = [];
    this.displayedColumnsTemp = [];
    this.columns.forEach((val) =>
      this.columnsTemp.push(Object.assign({}, val))
    );
    this.displayedColumnsTemp = Object.assign([], this.displayedColumns);
  }

  getUserInfo(userName: string) {
    return userName;
  }

  getBusinessUnit(businessUnitId) {
    if (businessUnitId) {
      const bu = this.getBuName(businessUnitId);
      if (bu) {
        return bu.name;
      } else {
        return businessUnitId;
      }
    }
  }

  getBuName(businessUnitId) {
    if (this.buList) {
      return this.buList.find((el) => el.id === businessUnitId);
    }
  }

  getLockedColor(element): string {
    return this.kcService.getUsername() === element.assignedUsername
      ? 'warn'
      : 'black';
  }

  onPaginateChange(event) {
    this.taskService.updateCurrentPageIndex(event.pageIndex);
  }

  showMessage(data) {
    if (data.length === 0) {
      this.notificationService.dismissMessage();
      this.notificationService.showToast(
        'ERROR.NO-DATA-FOUND',
        this.notificationService.MESSAGE_TYPE.INFO
      );
    }
  }

  getTaskTitle(element) {
    if (element && element.title) {
      const splitTitle = element.title.split(' ');
      if (splitTitle.length > 1) {
        if (
          element.type === 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL'
        ) {
          return (
            this.translateService.instant(splitTitle[0]) + ' ' + splitTitle[1]
          );
        } else {
          return splitTitle[0];
        }
      } else {
        return element.title;
      }
    } else {
      return null;
    }
  }

  getObjectName(element) {
    return element && element.params.objectName
      ? this.translateService.instant(
          element.params.objectName,
          this.getFormatedParams(element)
        )
      : '';
  }

  getFormatedParams(element) {
    const params = Object.assign({}, element.params);
    Object.entries(element.params).forEach((data) => {
      if (data[0].toString().toLowerCase().includes('date')) {
        const date = new Date(element.params[data[0]]);

        if (this.dateFormat && date.toString() !== 'Invalid Date') {
          params[data[0]] = this.datePipe.transform(date, this.dateFormat);
        }
      } else if (element.params[data[0]] && data[0] !== 'objectName') {
        params[data[0]] = this.translateService.instant(
          element.params[data[0]]
        );
      }
    });
    return params;
  }

  clickOnTask(task) {
    if (!task.isLocked) {
      this.taskService.lock(task.id).subscribe(() => null);
    }
    this.router.navigate(['/tasks', task.id]);
  }

  isDisabled(task) {
    return (
      task.isLocked && task.lockedUsername !== this.kcService.getUsername()
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref) {
    if (this.isSomeSelected()) {
      this.selection.clear();
      this.selectedTasks = [];
      ref.checked = false;
    } else {
      if (this.isAllSelected()) {
        this.selection.clear();
        this.selectedTasks = [];
      } else {
        this.dataSource.data.forEach((row) => {
          this.selection.select(row);
          this.selectedTasks.push(row);
        });
      }
    }
  }

  forwardTask() {
    const dialogSelect = this.matDialogService.open(UserSelectModalComponent, {
      panelClass: 'confirmation-popup',
    });
    dialogSelect.afterClosed().subscribe((userInfo) => {
      if (!userInfo) {
        return;
      }
      const dialogConfirm = this.matDialogService.open(
        ConfirmationModalComponent,
        {
          panelClass: 'confirmation-popup',
          data: { action: ConfirmationModalComponent.ACTION_APPROVE_TASK },
        }
      );
      dialogConfirm.afterClosed().subscribe((result) => {
        if (result && result.event === 'save') {
          const taskIds = this.selection.selected.map((obj) => obj.id);
          this.notificationService.showToast(
            'NOTIFICATION.SUCCESS',
            this.notificationService.MESSAGE_TYPE.SUCCESS
          );
          let name;
          if (userInfo.forwardOption === 'USER') {
            name = userInfo.user.username;
          } else if (userInfo.forwardOption === 'TEAM') {
            name = userInfo.team;
          }
          this.taskService
            .forwardTask(taskIds, userInfo.forwardOption, name, result.comment)
            .subscribe(() => {
              setTimeout(() => {
                if (this.selectedTasks.length > 0) {
                  this.selectedTasks.forEach((item) => {
                    this.selection.toggle(item);
                  });
                }
                this.refreshTasks();
              }, 3000);
            });
        }
      });
    });
  }

  refreshTasks() {
    this.taskService.changeTasksDataSourceFunction();
  }

  selectTask(row, isSelected) {
    this.selection.toggle(row);
    if (!isSelected) {
      this.selectedTasks.push(row);
    } else {
      for (let i = 0; i < this.selectedTasks.length; i++) {
        if (this.selectedTasks[i] === row) {
          this.selectedTasks.splice(i, 1);
          i--;
        }
      }
    }
  }

  paginate(event) {
    this.pageSize = event.pageSize;
    this.loaderSpinner = true;
    localStorage.setItem('pageIndex', String(this.paginator.pageIndex));
    localStorage.setItem('pageSize', String(this.paginator.pageSize));
    this.taskService.getTaskList(this.pageSize, this.paginator.pageIndex);
  }

  getTaskEntity(element, entity) {
    let result = '';

    switch (element.type) {
      case 'APPROVE_BUSINESS_UNIT_INFORMATION':
        result = 'ENTITIES.BU';
        break;

      case 'APPROVE_CENTRAL_MASTER_DATA_INFORMATION':
        result = 'ENTITIES.COMPANY';
        break;

      case 'APPROVE_PLATFORM_INFORMATION':
        result = 'ENTITIES.PLATFORM';
        break;

      case 'APPROVE_CLIENT_INFORMATION':
        result = 'ENTITIES.CLIENT';
        break;

      case 'APPROVE_CLIENT_GROUP_INFORMATION':
        result = 'ENTITIES.CLIENT-GROUP';
        break;

      case 'APPROVE_BOOKING_CODE_INFORMATION':
      case 'SPECIAL_APPROVE_BOOKING_CODE_INFORMATION':
        result = 'ENTITIES.BOOKING-CODE';
        break;

      case 'APPROVE_OBJECTION_CODE_INFORMATION':
        result = 'ENTITIES.OBJECTION-CODE';
        break;

      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_INTERNAL_INFORMATION':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_CONTRACT_EXTERNAL_INFORMATION':
      case 'PRECHECK_TCI_EXTERNAL':
        result = 'ENTITIES.TRADE-CREDIT-INSURANCE';
        break;

      case 'CREATE_DISBURSEMENT':
      case 'APPROVE_DISBURSEMENT':
        result = 'ENTITIES.DISBURSEMENT';
        break;

      case 'CREATE_DISBURSEMENT_GROUP':
      case 'APPROVE_DISBURSEMENT_GROUP':
        result = 'ENTITIES.DISBURSEMENT-GROUP';
        break;

      case 'CLIENT_INTERFACE_APPROVAL':
        result = 'ENTITIES.CLIENT-INTERFACE';
        break;

      case 'DEBTOR_INTERFACE_APPROVAL':
        result = 'ENTITIES.DEBTOR-INTERFACE';
        break;

      case 'PROCESS_ACQUISITION_CLIENT_DOCUMENT_CHECK':
        result = 'ENTITIES.ACQUISITION';
        break;

      case 'CREATION_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
      case 'APPROVE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL':
        result = 'ENTITIES.TRADE-CREDIT-INSURANCE-SUBCONTRACT';
        break;

      case 'APPROVE_ANIMAL_WELFARE_SYSTEM_PARAMETER':
        result = 'ENTITIES.SYSTEM-PARAMETER';
        break;

      case 'APPROVAL_ANIMAL_WELFARE_SALES_PARTICIPANT':
        result = 'ENTITIES.ANIMAL-WELFARE.SALES-ENTRY';
        break;

      case 'OBJECTION_MANAGEMENT':
        result = 'ENTITIES.OBJECTION-MANAGEMENT';
        break;

      case 'APPROVAL_ACCOUNT_STATEMENT_CONFIGURATION':
        result = 'ENTITIES.ACCOUNT-STATEMENT-CONFIGURATION';
        break;

      case 'APPROVAL_BTC_MAPPING':
        result = 'ENTITIES.BTC-MAPPING';
        break;

      case 'APPROVAL_ANIMAL_WELFARE_LIQUIDITY_PLAN':
        result = 'ENTITIES.LIQUIDITY-PLAN';
        break;

      case 'APPROVAL_REPORTING_PERIOD':
        result = 'ENTITIES.REPORTING-PERIOD';
        break;

      case 'APPROVAL_GOODS_GROUP':
        result = 'ENTITIES.GOODS-GROUP';

        break;

      case 'APPROVAL_QUANTITY_LABELLED_GOOD':
        result = 'ENTITIES.ANIMAL-WELFARE.QUANTITY-LABELLED-GOOD';
        break;

      case 'APPROVAL_QUANTITY_GOOD':
        result = 'ENTITIES.ANIMAL-WELFARE.QUANTITY-GOOD';
        break;

      case 'SEPA_MANDATE_CREATION':
      case 'SEPA_MANDATE_CONFIGURATION':
      case 'SEPA_MANDATE_EXPIRED_CREATE_NEW':
      case 'SEPA_MANDATE_EXPIRED_SET_NEW':
        result = 'ENTITIES.SEPA-MANDATE';
        break;

      case 'MASTER_DATA_RECORD_WAS_MARKED_AS_OPERATOR':
        result = 'ENTITIES.MASTER-DATA-RECORD';
        break;

      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CREATION':
      case 'APPROVAL_SUB_LEDGER_ACCOUNT_CONFIGURATION':
        result = 'ENTITIES.SUB-LEDGER-ACCOUNT';
        break;

      case 'TRANSLATION_APPROVAL':
        result = 'ENTITIES.TRANSLATION';
        break;

      case 'ACTIVATE_MANUAL_S_BLOCK':
      case 'DEACTIVATE_MANUAL_S_BLOCK':
        result = 'ENTITIES.MANUAL-S-BLOCK';
        break;

      case 'APPROVAL_LIVESTOCK_PROD_DOCUMENT_DISPATCH_LOCKS':
        result = 'ENTITIES.LIVESTOCK-PRODUCER-DOCUMENT';
        break;

      case 'AW_RESERVE_BUDGET_STATUS':
      case 'AW_ALLOCATE_BUDGET_STATUS':
        result = 'ENTITIES.BUDGET-STATUS';
        break;

      case 'APPROVAL_TRANSACTION_CODE_ALLOCATION':
      case 'APPROVAL_NEW_TRANSACTION_CODE_ALLOCATION':
        result = 'ENTITIES.TRANSACTION-CODE-ALLOCATION';
        break;

      case 'AW_TERMINATE_DATE_CHANGE':
      case 'AW_TERMINATE_DATE_DELETE':
      case 'AW_TERMINATE_DATE_SET':
        result = 'ENTITIES.AW-TERMINATE-DATE';
        break;

      case 'PROCESS_ACQUISITION_CLIENT_PRESCORING':
        result = 'ENTITIES.ACQUISITION-CLIENT-PRESCORING';
        break;
      case 'PROCESS_CLIENT_RISK_CHECK':
        result = 'ENTITIES.CLIENT-RISK-CHECK';
        break;

      case 'AW_XML_GENERAL_TECHNICAL_ERROR':
        result = 'ENTITIES.XML';
        break;

      case 'AW_ALREADY_PROCESSED_FILE':
      case 'AW_ALREADY_PROCESSED_FILE_DESCRIPTION':
      case 'AW_INVALID_FILE':
      case 'AW_FILE_SET_NOT_NEXT_IN_LINE':
      case 'AW_INCOMPLETE_FILE_SET':
        result = 'ENTITIES.FILE';
        break;
      case 'AW_INCORRECT_PROCESSING_SEQ':
        result = 'ENTITIES.SEQ';
        break;

      case 'AW_RECORD_WITH_MISSING_MASTER_DATA':
      case 'AW_RECORD_WITH_CHANGED_VVVO':
      case 'AW_MATCHING_RECORD_COLLIDING':
      case 'AW_MATCHING_RECORD_USING_CLOSED_QUARTER':
      case 'AW_INVALID_RECORD':
      case 'AW_MISSING_MATCHING_ID':
        result = 'ENTITIES.RECORD';
        break;

      case 'CHECK_ACCOUNT_STATE_PROCESSING_ERROR':
      case 'PROCESS_ACCOUNT_STATEMENT_UNSPECIFIED_BTC':
        result = 'ENTITIES.ACCOUNT-STATEMENT';
        break;

      case 'AW_BUDGET_EQUAL_ZERO':
        result = 'ENTITIES.BUDGET';
        break;

      case 'APPROVAL_PAYOUT_SUGGESTION_LIST':
      case 'PAYOUT_SUGGESTION_LIST_EMAIL':
      case 'PAYOUT_SUGGESTION_LIST_PAYOUT':
        result = 'ENTITIES.PAYOUT-SUGGESTION-LIST';
        break;

      case 'AW_SEPA_EXCEL_EMAIL':
        result = 'ENTITIES.SEPA-EXCEL';
        break;

      case 'AW_SUSPENSION_BLOCK_MODIFIED':
        result = 'ENTITIES.AW-SUSPENSION-BLOCK';
        break;
      default:
        result = 'UNDEFINED';
        break;
    }

    return this.translateService.instant(result);
  }

  getObjectId(task) {
    return task.params
      ? task.params.objectId
      : task.type === 'UPDATE_TRADE_CREDIT_INSURANCE_SUBCONTRACT_INTERNAL'
      ? task.objectId
      : '';
  }
}
