import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from 'src/app/shared/models/dialog-data';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { clientInterfaceRejectionReasons } from 'src/app/in-memory-data/task/interface-rejection-reasons/client-interface';
import { CommentSettings } from '../../../in-memory-data/textarea-settings/comment-settings';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.less'],
})
export class ConfirmationModalComponent implements OnInit {
  public static ACTION_APPROVE_TASK = 0;
  public static ACTION_APPROVE_DISBURSEMENT_APPROVAL_TASK = 1;

  commentRequired = true;
  data: any;
  entityName = 'confirmationModal';
  approvalForm: UntypedFormGroup;
  approvalInterfaceReasons = false;
  hasEnoughCompetence = false;
  reasons = clientInterfaceRejectionReasons;
  state;
  remainingCharacters: number;
  commentingSettings = CommentSettings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.data = {
      comment: '',
      event: 'save',
      rejectionReasonCode: '',
    };
  }

  ngOnInit() {
    this.remainingCharacters = CommentSettings.MAXIMUM_COMMENT_SIZE;
    this.state = window.history.state;
    if (this.state.hasEnoughCompetence) {
      this.hasEnoughCompetence = true;
    } else {
      this.hasEnoughCompetence = false;
    }
    this.approvalForm = this.formBuilder.group({
      comment: [null],
    });
    if (this.dialogData) {
      if (
        this.dialogData.action ===
        ConfirmationModalComponent.ACTION_APPROVE_TASK
      ) {
        this.commentRequired = false;
      } else {
        this.commentRequired = true;
        this.approvalForm.get('comment').setValidators([Validators.required]);
      }
      if (
        this.dialogData.type === 'CLIENT_INTERFACE_APPROVAL' ||
        this.dialogData.type === 'DEBTOR_INTERFACE_APPROVAL'
      ) {
        this.approvalForm.addControl(
          'rejectionReasonCode',
          new UntypedFormControl('', Validators.required)
        );

        this.approvalInterfaceReasons = true;
      }
    } else if (
      this.state.task &&
      this.state.task.type === 'CREATE_DISBURSEMENT'
    ) {
      if (this.state.manualChargesIsZero) {
        this.commentRequired = false;
      } else {
        this.commentRequired = true;
        this.approvalForm.get('comment').setValidators([Validators.required]);
      }
    } else {
      this.commentRequired = true;
      this.approvalForm.get('comment').setValidators([Validators.required]);
    }
  }

  toggle(event) {
    this.data['masterCompetence'] = event.checked;
  }

  calculateRemainingCommentCharacters() {
    this.remainingCharacters =
      CommentSettings.MAXIMUM_COMMENT_SIZE - this.data.comment.length;
  }
}
