export const livestockOverviewMovementDataColumnsConf: any[] = [
  {
    name: 'name',
    index: 0,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.NAME',
    locked: true,
  },
  {
    name: 'type',
    index: 1,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.TYPE',
    locked: true,
  },
  {
    name: 'submissionDate',
    index: 2,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.SUBMISSION-DATE',
    locked: true,
  },
  {
    name: 'measureUnit',
    index: 3,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.MEASURE-UNIT',
    locked: true,
  },
  {
    name: 'transfer',
    index: 4,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.TRANSFER',
    locked: true,
  },
  {
    name: 'amount',
    index: 5,
    checked: true,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.AMOUNT',
    locked: true,
  },
  {
    name: 'comment',
    index: 6,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.COMMENT',
    locked: false,
  },
  {
    name: 'status',
    index: 7,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.STATUS',
    locked: false,
  },
  {
    name: 'paidOutOn',
    index: 8,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-ON',
    locked: false,
  },
  {
    name: 'paidOutIn',
    index: 9,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-IN',
    locked: false,
  },
  {
    name: 'paidOutAmount',
    index: 10,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.PAID-OUT-AMOUNT',
    locked: false,
  },
  {
    name: 'documentId',
    index: 11,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.DOCUMENT-ID',
    locked: false,
  },
  {
    name: 'fundingRate',
    index: 12,
    checked: false,
    translateName:
      'ANIMAL-WELFARE.LIVESTOCK-PRODUCER.OVERVIEW.MOVEMENT-DATA.FUNDING-RATE',
    locked: false,
  },
  {
    version: '2.0',
  },
];
