import { ValidatorFn, UntypedFormGroup } from '@angular/forms';

export class VVVONrValidator {
  public vvvoNrValidator(livestockProducers, productionTypes): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const vvvoNr = formGroup.get('vvvoNr').value;
      const productionType = formGroup.get('productionType').value;

      const vvvoNrExists = livestockProducers.find(
        (item) => item.locationNumber === vvvoNr
      );

      if (vvvoNrExists) {
        if (productionType) {
          const prodType = productionTypes.find(
            (item) => item.value === productionType
          );

          if (prodType.value !== vvvoNrExists.productionType) {
            return { inexistent: true };
          }
        } else {
          return null;
        }
      } else {
        return { inexistent: true };
      }
    };
  }
}
