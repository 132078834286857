<section class="contact-person-container">
  <div *ngIf="!isTask || (isTask && editableTask)">
    <div class="filters form action-wrapper" *ngIf="!showForm">
      <div class="search" [formGroup]="filterForm">
        <div class="filter-input">
          <mat-form-field>
            <mat-label>{{ 'COMPANY.CONTACT-PERSON.SEARCH' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'COMPANY.CONTACT-PERSON.SEARCH' | translate }}"
              name="name"
              formControlName="searchString"
              [(ngModel)]="searchInput"
              id="{{ entityName }}-search-input-name"
              (keyup)="searchContactPerson()"
              autocomplete="off"
              [removeHtmlTags]="filterForm.controls.searchString"
              type="text"
            />
          </mat-form-field>
          <button
            mat-button
            class="custom-button search-button"
            (click)="searchContactPerson()"
            id="{{ entityName }}-search-button"
          >
            <span class="material-icons"> search </span>
          </button>
        </div>
      </div>
      <button
        mat-button
        class="custom-button add-button"
        [disabled]="isDraftExists || animalWelfareEditView || readOnly"
        (click)="showContactPersonForm()"
      >
        {{ 'COMPANY.ADD-CONTACT-PERSON' | translate }}
      </button>
    </div>
    <div class="input-wrapper form-container" *ngIf="showForm">
      <form action="" class="form" [formGroup]="contactPersonForm" autocomplete="off">
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.TITLE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.TITLE'"
          [label]="'COMPANY.CONTACT-PERSON.TITLE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.title"
          [controlName]="'title'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.title : null"
          [taskValue]="contactPersonTask ? contactPersonTask.title : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.SURNAME'"
          [placeholder]="'COMPANY.CONTACT-PERSON.SURNAME'"
          [label]="'COMPANY.CONTACT-PERSON.SURNAME'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.surname"
          [controlName]="'surname'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.surname : null"
          [taskValue]="contactPersonTask ? contactPersonTask.surname : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.NAME'"
          [placeholder]="'COMPANY.CONTACT-PERSON.NAME'"
          [label]="'COMPANY.CONTACT-PERSON.NAME'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.name"
          [controlName]="'name'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.name : null"
          [taskValue]="contactPersonTask ? contactPersonTask.name : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-select
          [name]="'COMPANY.CONTACT-PERSON.CONTACT-TYPE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.CONTACT-TYPE'"
          [label]="'COMPANY.CONTACT-PERSON.CONTACT-TYPE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.contactType"
          [controlName]="'contactType'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.contactType : null"
          [taskValue]="contactPersonTask ? contactPersonTask.contactType : null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="contactTypes"
          [optionValue]="'name'"
          [selectedOption]="null"
          [translationOption]="'translateName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'none'"
          [disabled]="readOnly"
        ></app-mat-select>
        <app-mat-slider
          [name]="'COMPANY.CONTACT-PERSON.MAIN-CONTACT'"
          [label]="'COMPANY.CONTACT-PERSON.MAIN-CONTACT'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlName]="'defaultContact'"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [taskValue]="contactPersonTask ? contactPersonTask.defaultContact : null"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.defaultContact : null"
          [disabled]="readOnly"
        ></app-mat-slider>
        <app-mat-select
          [name]="'COMPANY.CONTACT-PERSON.SUBSTITUTE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.SUBSTITUTE'"
          [label]="'COMPANY.CONTACT-PERSON.SUBSTITUTE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.contactPersonSubstituteId"
          [controlName]="'contactPersonSubstituteId'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.contactPersonSubstituteId : null"
          [taskValue]="contactPersonTask ? contactPersonTask.contactPersonSubstituteId : null"
          [titleTask]="false"
          [editableTask]="null"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="contactPersonsList"
          [optionValue]="'id'"
          [selectedOption]="null"
          [translationOption]="'fullName'"
          [optionTranslationText]="''"
          [customOptionNameFunction]="null"
          [customTaskValueFunction]="null"
          [textTransform]="'none'"
          [disabled]="readOnly"
        ></app-mat-select>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.SUBSTITUTE-FREE-TEXT'"
          [placeholder]="'COMPANY.CONTACT-PERSON.SUBSTITUTE-FREE-TEXT'"
          [label]="'COMPANY.CONTACT-PERSON.SUBSTITUTE-FREE-TEXT'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.representative"
          [controlName]="'representative'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.representative : null"
          [taskValue]="contactPersonTask ? contactPersonTask.representative : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.TELEPHONE-NUMBER'"
          [placeholder]="'COMPANY.CONTACT-PERSON.TELEPHONE-NUMBER'"
          [label]="'COMPANY.CONTACT-PERSON.TELEPHONE-NUMBER'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.telephoneNumber"
          [controlName]="'telephoneNumber'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.telephoneNumber : null"
          [taskValue]="contactPersonTask ? contactPersonTask.telephoneNumber : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.FAX-NUMBER'"
          [placeholder]="'COMPANY.CONTACT-PERSON.FAX-NUMBER'"
          [label]="'COMPANY.CONTACT-PERSON.FAX-NUMBER'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.faxNumber"
          [controlName]="'faxNumber'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.faxNumber : null"
          [taskValue]="contactPersonTask ? contactPersonTask.faxNumber : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.MOBILE-NUMBER'"
          [placeholder]="'COMPANY.CONTACT-PERSON.MOBILE-NUMBER'"
          [label]="'COMPANY.CONTACT-PERSON.MOBILE-NUMBER'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.mobileNumber"
          [controlName]="'mobileNumber'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.mobileNumber : null"
          [taskValue]="contactPersonTask ? contactPersonTask.mobileNumber : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.EMAIL'"
          [placeholder]="'COMPANY.CONTACT-PERSON.EMAIL'"
          [label]="'COMPANY.CONTACT-PERSON.EMAIL'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.email"
          [controlName]="'email'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.email : null"
          [taskValue]="contactPersonTask ? contactPersonTask.email : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [type]="'email'"
          [titlecasePipe]="false"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input-date
          [name]="'COMPANY.CONTACT-PERSON.DATE-OF-BIRTH'"
          [placeholder]="'COMPANY.CONTACT-PERSON.DATE-OF-BIRTH'"
          [label]="'COMPANY.CONTACT-PERSON.DATE-OF-BIRTH'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.dateOfBirth"
          [controlName]="'dateOfBirth'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.dateOfBirth : null"
          [taskValue]="contactPersonTask ? contactPersonTask.dateOfBirth : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [minDate]="null"
          [maxDate]="null"
          [titlecasePipe]="false"
          [disabled]="readOnly"
        ></app-mat-input-date>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.STREET-NAME'"
          [placeholder]="'COMPANY.CONTACT-PERSON.STREET-NAME'"
          [label]="'COMPANY.CONTACT-PERSON.STREET-NAME'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.address['controls'].street"
          [controlName]="'street'"
          [approvedValue]="
            contactPersonApproved && contactPersonApproved.address ? contactPersonApproved.address.street : null
          "
          [taskValue]="contactPersonTask && contactPersonTask.address ? contactPersonTask.address.street : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="'address'"
          [disabled]="readOnly"
        >
        </app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.HOUSE-NUMBER'"
          [placeholder]="'COMPANY.CONTACT-PERSON.HOUSE-NUMBER'"
          [label]="'COMPANY.CONTACT-PERSON.HOUSE-NUMBER'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.address['controls'].number"
          [controlName]="'number'"
          [approvedValue]="
            contactPersonApproved && contactPersonApproved.address ? contactPersonApproved.address.number : null
          "
          [taskValue]="contactPersonTask && contactPersonTask.address ? contactPersonTask.address.number : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="'address'"
          [disabled]="readOnly"
        >
        </app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.ZIP-CODE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.ZIP-CODE'"
          [label]="'COMPANY.CONTACT-PERSON.ZIP-CODE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.address['controls'].zipCode"
          [controlName]="'zipCode'"
          [approvedValue]="
            contactPersonApproved && contactPersonApproved.address ? contactPersonApproved.address.zipCode : null
          "
          [taskValue]="contactPersonTask && contactPersonTask.address ? contactPersonTask.address.zipCode : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="'address'"
          [titlecasePipe]="false"
          [disabled]="readOnly"
        ></app-mat-input>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.CITY'"
          [placeholder]="'COMPANY.CONTACT-PERSON.CITY'"
          [label]="'COMPANY.CONTACT-PERSON.CITY'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.address['controls'].city"
          [controlName]="'city'"
          [approvedValue]="
            contactPersonApproved && contactPersonApproved.address ? contactPersonApproved.address.city : null
          "
          [taskValue]="contactPersonTask && contactPersonTask.address ? contactPersonTask.address.city : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="'address'"
          [disabled]="readOnly"
        >
        </app-mat-input>
        <app-mat-input-autocomplete
          [name]="'COMPANY.CONTACT-PERSON.COUNTRY'"
          [placeholder]="'COMPANY.CONTACT-PERSON.COUNTRY'"
          [label]="'COMPANY.CONTACT-PERSON.COUNTRY'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.address['controls'].country"
          [controlName]="'country'"
          [approvedValue]="
            contactPersonApproved && contactPersonApproved.address ? contactPersonApproved.address.country : null
          "
          [taskValue]="contactPersonTask && contactPersonTask.address ? contactPersonTask.address.country : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="'address'"
          [options]="countries"
          [filterName]="'cca3'"
          [optionValue]="'cca3'"
          [translationOption]="'cca3'"
          [optionTranslationText]="''"
          [disabled]="readOnly"
        ></app-mat-input-autocomplete>
        <div class="form-row" *ngIf="!isTask">
          <label for="countryCode">{{ 'ADDRESS.COUNTRY-CODE' | translate }}</label>
          <div name="countryCode" class="div-custom-input">
            {{ countryCode }}
          </div>
        </div>
        <app-mat-select
          [name]="'COMPANY.CONTACT-PERSON.LANGUAGE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.LANGUAGE'"
          [label]="'COMPANY.CONTACT-PERSON.LANGUAGE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.language"
          [controlName]="'language'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.language : null"
          [taskValue]="contactPersonTask ? contactPersonTask.language : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
          [options]="languages"
          [optionValue]="'code'"
          [translationOption]="'code'"
          [optionTranslationText]="''"
        ></app-mat-select>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.POST-OFFICE'"
          [placeholder]="'COMPANY.CONTACT-PERSON.POST-OFFICE'"
          [label]="'COMPANY.CONTACT-PERSON.POST-OFFICE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.postOfficeBox"
          [controlName]="'postOfficeBox'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.postOfficeBox : null"
          [taskValue]="contactPersonTask ? contactPersonTask.postOfficeBox : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <app-mat-radio
          [name]="'COMPANY.CONTACT-PERSON.PREFERRED-CONTACT-TYPE'"
          [label]="'COMPANY.CONTACT-PERSON.PREFERRED-CONTACT-TYPE'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlName]="'preferredContactType'"
          [controlObject]="contactPersonForm.controls.preferredContactType"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.preferredContactType : null"
          [taskValue]="contactPersonTask ? contactPersonTask.preferredContactType : null"
          [titleTask]="false"
          [editableTask]="editableTask"
          [isTask]="isTask"
          [items]="contactTypePreferences"
          [optionValue]="'name'"
          [titlecasePipe]="false"
        ></app-mat-radio>
        <app-mat-input
          [name]="'COMPANY.CONTACT-PERSON.ADDITIONAL-INFO'"
          [placeholder]="'COMPANY.CONTACT-PERSON.ADDITIONAL-INFO'"
          [label]="'COMPANY.CONTACT-PERSON.ADDITIONAL-INFO'"
          [entityName]="entityName"
          [form]="contactPersonForm"
          [controlObject]="contactPersonForm.controls.additionalInfo"
          [controlName]="'additionalInfo'"
          [approvedValue]="contactPersonApproved ? contactPersonApproved.additionalInfo : null"
          [taskValue]="contactPersonTask ? contactPersonTask.additionalInfo : null"
          [titleTask]="false"
          [editableTask]="false"
          [isTask]="false"
          [formGroupCustomName]="null"
        ></app-mat-input>
        <div class="action-buttons">
          <button
            id="{{ entityName }}-form-save-button"
            mat-button
            class="custom-button save-button"
            (click)="$event.preventDefault(); saveContactPerson()"
            [disabled]="disableSave || contactPersonForm.invalid"
          >
            {{ 'GENERAL-ENTITY.CREATE.SAVE' | translate }}
          </button>
          <button
            id="{{ entityName }}-form-cancel-button"
            mat-button
            class="custom-button-cancel cancel-button"
            (click)="$event.preventDefault(); clear()"
          >
            {{ 'GENERAL-ENTITY.CREATE.CANCEL' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="company-contact-person-list" [hidden]="showForm || noExistingData">
    <h5>{{ 'SEARCH-RESULT' | translate }}</h5>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      id="{{ entityName }}-contact-persons-table"
      multiTemplateDataRows
      aria-hidden="true"
    >
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-name"
        >
          {{ 'COMPANY.CONTACT-PERSON.NAME' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'name')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-name-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.name + ' ' + element.surname }}
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-address"
        >
          {{ 'COMPANY.CONTACT-PERSON.ADDRESS' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'address')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-address-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <ng-container *ngIf="element.address">
            <div>{{ getStreetAndNumber(element.address) }}</div>
            <div>{{ getZipCodeAndCity(element.address) }}</div>
            <div>{{ getCountry(element.address) }}</div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="telephoneNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-telephoneNumber"
        >
          {{ 'COMPANY.CONTACT-PERSON.TELEPHONE-NUMBER' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'telephoneNumber')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-telephoneNumber-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.telephoneNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-email"
        >
          {{ 'COMPANY.CONTACT-PERSON.EMAIL' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'email')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-email-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfUsers">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-number-of-users"
        >
          {{ 'COMPANY.CONTACT-PERSON.NUMBER-OF-USERS' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'numberOfUsers')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-number-of-users-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.numberOfUsers }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contactType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-contact-type"
        >
          {{ 'COMPANY.CONTACT-PERSON.CONTACT-TYPE' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'contactType')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-contact-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.contactType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mainContactPerson">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-main-contact-person"
        >
          {{ 'COMPANY.CONTACT-PERSON.MAIN-CONTACT' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'defaultContact')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-main-contact-person-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ isMainContactPersonYesOrNo(element.defaultContact) | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="substitute">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-main-substitute"
        >
          {{ 'COMPANY.CONTACT-PERSON.SUBSTITUTE' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'contactPersonSubstituteId')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-substitute-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ getSubstituteName(element.contactPersonSubstituteId) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="substituteFreeText">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-main-substitute-free-text"
        >
          {{ 'COMPANY.CONTACT-PERSON.SUBSTITUTE-FREE-TEXT' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'representative')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-substitute-free-text-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.representative }}
        </td>
      </ng-container>

      <ng-container matColumnDef="faxNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-fax-number"
        >
          {{ 'COMPANY.CONTACT-PERSON.FAX-NUMBER' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'faxNumber')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-fax-number-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.faxNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-language"
        >
          {{ 'COMPANY.CONTACT-PERSON.LANGUAGE' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'language')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-language-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.language }}
        </td>
      </ng-container>

      <ng-container matColumnDef="preferredContactType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-preferred-contact-type"
        >
          {{ 'COMPANY.CONTACT-PERSON.PREFERRED-CONTACT-TYPE' | translate }}
        </th>
        <td
          [ngClass]="{
            'val-changed': hasContactPersonChanged(element, 'preferredContactType')
          }"
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-preferred-contact-type-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          {{ element.preferredContactType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="externalRecord">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="v25-th"
          id="{{ entityName }}-table-contact-persons-external-record"
        >
          {{ 'COMPANY.EXTERNAL-RECORD' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ entityName }}-table-contact-persons-external-record-{{ paginator.pageIndex + 1 }}-{{ i }}"
        >
          <span>
            <mat-slide-toggle [checked]="element.uneditableExternalSource" [disabled]="true"> </mat-slide-toggle>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="v25-th settings" id="{{ entityName }}-table-results-actions">
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>settings</mat-icon>
          </button>
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          (click)="$event.stopPropagation()"
          class="three-dot-actions"
        ></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns; let i = index"
        class="element-row"
        (click)="edit(element)"
      ></tr>
    </table>
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100, 200]"
      showFirstLastButtons
      [length]="totalCount"
    ></mat-paginator>
  </div>
</section>

<mat-menu #appMenu="matMenu" (close)="tableChanged($event)" class="select-columns">
  <app-table-menu-columns [columns]="columnsTemp" [displayedColumns]="displayedColumnsTemp"></app-table-menu-columns>
</mat-menu>
