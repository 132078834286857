import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from './utils/can.authentication.guard';
import { OpenItemManagementComponent } from './modules/open-item-management/open-item-management.component';
import { UserProfileComponent } from './modules/user-profile-management/user-profile/user-profile.component';
import { EmailReceivingMockComponent } from './email-receiving-mock/email-receiving-mock.component';
import { AwOpenItemCreateComponent } from './modules/billing-slaughterhouse-management/aw-open-item-create/aw-open-item-create.component';
import { DirtyCheckGuard } from './shared/guards/dirty-check.guard';
import { AccountingManagementDashboardComponent } from './modules/accounting-management/accounting-management-dashboard/accounting-management-dashboard.component';
import { AccountingOverviewComponent } from './modules/accounting-management/accounting-overview/accounting-overview.component';
import { LivestockProducerListComponent } from './modules/animal-welfare-management/livestock-producer/livestock-producer-list/livestock-producer-list.component';
import { LivestockProducerOverviewComponent } from './modules/animal-welfare-management/livestock-producer/livestock-producer-overview/livestock-producer-overview.component';
import { SalesEntryListComponent } from './modules/sales-entry/sales-entry-list/sales-entry-list.component';
import { SalesEntryCreateComponent } from './modules/sales-entry/sales-entry-create/sales-entry-create.component';
import { LiquidityPlanComponent } from './modules/animal-welfare-management/liquidity-plan/liquidity-plan.component';
import { TransferQuantityGoodsListComponent } from './modules/animal-welfare-management/transfer-quantity-goods/transfer-quantity-goods-list/transfer-quantity-goods-list.component';
import { TransferQuantityGoodsCreateComponent } from './modules/animal-welfare-management/transfer-quantity-goods/transfer-quantity-goods-create/transfer-quantity-goods-create.component';
import { ControlBoardManagementComponent } from './modules/animal-welfare-management/control-board-management/control-board-management/control-board-management.component';
import { AccountStatementComponent } from './modules/animal-welfare-management/account-statement/account-statement/account-statement.component';
import { AwInterfaceTransferProtocolComponent } from './modules/animal-welfare-management/aw-interface-transfer-protocol-management/aw-interface-transfer-protocol.component';
import { DocumentManagementComponent } from './modules/animal-welfare-management/document-management/document-management.component';
import { EmailAttachmentDownloadComponent } from './modules/email-attachment-download/email-attachment-download.component';
import { OpenTransactionBookingManagementComponent } from './modules/open-transaction-booking-management/open-transaction-booking-management/open-transaction-booking-management.component';
import { QuarterlyBillingSimulationComponent } from './modules/animal-welfare-management/quarterly-billing/quarterly-billing-simulation/quarterly-billing-simulation.component';
import { QuarterlyBillingPayoutComponent } from './modules/animal-welfare-management/quarterly-billing/quarterly-billing-payout/quarterly-billing-payout.component';
import { ReportComponent } from './modules/reporting/report/report.component';
import { WebsiteCreditsComponent } from './modules/website-credits/website-credits.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './modules/terms-of-service/terms-of-service.component';
import { LiquidityPlanAssetsComponent } from './modules/animal-welfare-management/liquidity-plan/liquidity-plan-assets/liquidity-plan-assets.component';
import { ReleaseUnusedBudgetComponent } from './modules/animal-welfare-management/release-unused-budget/release-unused-budget.component';
import {
  DebtorAccountPaymentAllocationComponent
} from './modules/accounting-management/debtor-account-payment-allocation/debtor-account-payment-allocation.component';

const routes: Routes = [
  {
    path: 'tasks',
    loadChildren: () =>
      import('./modules/task-management/task-management.module').then(
        (m) => m.TaskManagementModule
      ),
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['tm.r'] },
  },

  /*************** New UI Structure ********************/
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['pbi.r.r'],
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard-management/dashboard-management.module').then(
        (m) => m.DashboardManagementModule
      ),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['m.r', 'pbi.r.r'],
    },
  },
  {
    path: 'masterdata',
    loadChildren: () =>
      import(
        './modules/master-data-management/master-data-management.module'
      ).then((m) => m.MasterDataManagementModule),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['bu.r', 'md.r', 'p.r', 'c.r', 'cg.r', 'd.r', 'oc.r'],
    },
  },
  {
    path: 'booking',
    component: OpenItemManagementComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['op.r'] },
  },
  {
    path: 'openTransactionBooking',
    component: OpenTransactionBookingManagementComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['op.r'] },
  },

  /*************** End New UI Structure ********************/
  {
    path: 'riskmanagement',
    loadChildren: () =>
      import('./modules/risk-management/risk-management.module').then(
        (m) => m.RiskManagementModule
      ),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: [
        'md.r',
        'p.r',
        'c.r',
        'cg.r',
        'd.r',
        'bc.r',
        'oc.r',
        'tci.e.r',
        'tci.i.r',
      ],
    },
  },

  {
    path: 'awOpenItem',
    component: AwOpenItemCreateComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['aw.acc.r'] },
    canDeactivate: [DirtyCheckGuard],
  },
  {
    path: 'accounting',
    component: AccountingManagementDashboardComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['acc.r'] },
  },
  {
    path: 'accounting/overview/:id/:type',
    component: AccountingOverviewComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['acc.r'] },
  },
  {
    path: 'accounting/overview/:id/:type/paymentAllocation',
    component: DebtorAccountPaymentAllocationComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['acc.r'] },
  },
  {
    path: 'emailReceiveSimulation',
    component: EmailReceivingMockComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['tci.i.r'] },
  },
  {
    path: 'awInterfaceTransferProtocol',
    component: AwInterfaceTransferProtocolComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['aw.tp.r'] },
  },
  {
    path: 'documentManagement',
    component: DocumentManagementComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['dm.r'] },
  },
  {
    path: 'livestockProducer',
    component: LivestockProducerListComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.lp.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'livestockProducer/overview',
    component: LivestockProducerOverviewComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.lp.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'salesEntry',
    component: SalesEntryListComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.s.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'salesEntry/create',
    component: SalesEntryCreateComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.s.c', 'aw.s.r', 'aw.s.u'],
      products: ['ANIMAL_WELFARE'],
    },
    canDeactivate: [DirtyCheckGuard],
  },
  {
    path: 'liquidityPlan',
    component: LiquidityPlanComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.l.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'liquidityPlan/create',
    component: LiquidityPlanAssetsComponent,
    canActivate: [CanAuthenticationGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      roles: ['aw.l.r', 'aw.l.u'],
      products: ['ANIMAL_WELFARE'],
    },
  },

  {
    path: 'liquidityPlan/create/:guid',
    component: LiquidityPlanAssetsComponent,
    canActivate: [CanAuthenticationGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      roles: ['aw.l.r', 'aw.l.u'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'quantityGoods',
    component: TransferQuantityGoodsListComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.g.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'quantityGoods/create',
    component: TransferQuantityGoodsCreateComponent,
    canActivate: [CanAuthenticationGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      roles: ['aw.g.r', 'aw.g.c', 'aw.g.u'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'quantityGoods/create/:guid',
    component: TransferQuantityGoodsCreateComponent,
    canActivate: [CanAuthenticationGuard],
    canDeactivate: [DirtyCheckGuard],
    data: {
      roles: ['aw.g.r', 'aw.g.c', 'aw.g.u'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'quantityLabeledGoods',
    loadChildren: () =>
      import(
        './modules/animal-welfare-management/transfer-quantity-labeled-goods-management/transfer-quantity-labeled-goods-management.module'
      ).then((m) => m.TransferQuantityLabeledGoodsManagementModule),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.lg.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'awParticipationFee',
    loadChildren: () =>
      import('./modules/aw-participation-fee/aw-participation-fee.module').then(
        (m) => m.AwParticipationFeeModule
      ),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.pf.s.r', 'aw.pf.sh.r', 'aw.pf.fd.r'],
      products: ['ANIMAL_WELFARE'],
    },
  },
  {
    path: 'quarterlyBilling',
    component: ControlBoardManagementComponent,
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['aw.cb.r'],
      products: ['ANIMAL_WELFARE'],
    },
    children: [
      {
        path: 'quarterlyBillingSimulation',
        component: QuarterlyBillingSimulationComponent,
        data: { roles: ['aw.cb.r'] },
      },
      {
        path: 'quarterlyBillingPayout',
        component: QuarterlyBillingPayoutComponent,
        data: { roles: ['aw.cb.r'] },
      },
      {
        path: 'accountStatement',
        component: AccountStatementComponent,
        data: { roles: ['aw.cb.r'] },
      },
      {
        path: 'releaseUnusedBudget',
        component: ReleaseUnusedBudgetComponent,
        data: { roles: ['aw.cb.r'] },
      },
      { path: '', redirectTo: 'quarterlyBillingSimulation', pathMatch: 'full' },
    ],
  },
  {
    path: 'operatingCompany',
    component: SalesEntryCreateComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['aw.o.r'] },
  },
  {
    path: 'systemConfiguration',
    loadChildren: () =>
      import(
        './modules/system-configuration-management/system-configuration-management.module'
      ).then((m) => m.SystemConfigurationManagementModule),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: [
        'sc.btc.r',
        'sc.btc.m.r',
        'sc.asc.r',
        'sc.tc.r',
        'aw.sl.r',
        'sc.t.r',
        'aw.sp.r',
        'bc.r',
      ],
    },
  },
  {
    path: 'userProfile',
    component: UserProfileComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['pr.r'] },
  },
  {
    path: 'email/attachment/:id',
    component: EmailAttachmentDownloadComponent,
    canActivate: [CanAuthenticationGuard],
    data: { roles: ['pr.r'] },
  },
  {
    path: 'userManagement',
    loadChildren: () =>
      import('./modules/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
    canActivate: [CanAuthenticationGuard],
    data: {
      roles: ['u.u.r', 'u.r.r', 'u.p.r', 'u.u.bd.r'],
    },
  },
  {
    path: 'websiteCredits',
    component: WebsiteCreditsComponent,
    canActivate: [CanAuthenticationGuard],
  },
  {
    path: 'privacyPolicy',
    component: PrivacyPolicyComponent,
    canActivate: [CanAuthenticationGuard],
  },
  {
    path: 'termsOfService',
    component: TermsOfServiceComponent,
    canActivate: [CanAuthenticationGuard],
  },

  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
