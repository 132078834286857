export const quantityGoodsColumns: string[] = [
  'select',
  'submissionDate',
  'vvvoNr',
  'productionType',
  'amountGood',
  'measureUnit',
  'qsid',
  'transfer',
  'comment',
  'status',
  'createdBy',
  'createdOn',
  'approvedBy',
  'approvedOn',
  'actions',
];
